import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router, Params} from "@angular/router";
import { Vendor } from "../../../../../shared/models/Vendor";
import { TenantVendors } from "../../../../../libs/api/tenant/tenantVendors";
import {Link} from '../../../../../shared/ng-models/Link.interface';
import {environment} from '../../../../../environments/environment';
import { TermsAndConditions } from "../../../../../shared/models/TermsAndConditions";
import linkFormatter from "src/common/utils/linkFormatter";


@Component({
  templateUrl: './terms-and-conditions-create.component.html',
  styleUrls: ['./terms-and-conditions-create.component.scss']
})

export class TermAndConditionCreateComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  error: string;
  supportLink: string = linkFormatter.getSupportLink();
  vendor: Vendor;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantVendors: TenantVendors,
              private router: Router) {
    super();
  }

  ngOnInit() {

    const vendorId: string = this.activatedRoute.snapshot.params.vendor_display_id;

    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Vendors',
        url: '/vendors',
        internal: true
      },
      {
        label: `Vendor Details ${vendorId}`,
        url: `/vendors/${vendorId}/details`,
        internal: true
      },
      {
        label: `Create Terms & Conditions`,
        url: `/vendors/${vendorId}/terms-and-conditions/create`,
        internal: true
      }
    ];

    this.activatedRoute.params
    .takeUntil(this.destroy$)
    .switchMap(params => this.tenantVendors.getVendorByDisplayId(vendorId))
    .subscribe(vendor => this.vendor=vendor);
  }

  onTermsAndConditionsCancelled() {
    this.router.navigateByUrl(`/vendors/${this.vendor.display_id}`);
  }

  onTermsAndConditionsCreated(termandcondition:TermsAndConditions):void {
    // The change has been added.
    this.router.navigateByUrl(`/terms-and-conditions/${termandcondition.display_id}/details`);
  }

}
