<div class="row">

  <div class="col-1"></div>

  <div class="col-2"></div>

  <div class="col-3">

    <ng-select
      #recipientSelect
      class="mi-select"
      (change)="onRecipientSelected($event)"
      bindLabel="label"
      bindValue="email_recipient"
      [clearSearchOnAdd]="true"
      [closeOnSelect]="true"
      [hideSelected]="true"
      [items]="recipients"
      ngClass="mi-select"
      [searchFn]="recipientSearch"
      placeholder="{{'EMAIL_RECIPIENT_SEARCH' | translate}}">
    </ng-select>

  </div>

</div>
