function _each(obj, func) {
  for (const [key, value] of Object.entries(obj)) {
    func(value, key);
  }
}

function _deepClone(any) {
  return JSON.parse(JSON.stringify(any));
}

function objCompare(obj1, obj2) {
  //Loop through properties in object 1
  for (const p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof (obj1[p])) {
      //Deep compare objects
      case 'object':
        if (!objCompare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case 'function':
        if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (const p in obj2) {
    if (typeof (obj1[p]) == 'undefined') return false;
  }
  return true;
}

/**
 * Takes a bound function as the first parameter and uses a callback pattern on it with further parameters
 * @returns {Promise}
 *
 * @example
 *
 * callbackToPromise(
 *  instance.someFunctionWhichTakesACallback.bind(instance), someParam)
 *  .then(result => console.log(result));
 */

function callbackToPromise(...allArgs) {
  const args = Array.from(allArgs);
  const fn = args.shift();
  return new Promise((resolve, reject) => {
    fn(...args, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

/**
 * Given an array of items, or array of IDs, gather only the IDs
 * @param items
 * @returns {Array}
 */
function getOnlyIds(items = []) {
  let ids = Array.isArray(items) ? items : [items];
  ids = Array.from(new Set(ids.map(item => {
    if (item._id) {
      return item._id + '';
    } else if (item.id) {
      return item.id + '';
    }
    return item;
  })));

  return ids;
}

export {
  _deepClone,
  _each,
  objCompare,
  callbackToPromise,
  getOnlyIds
};
