<div class="" *ngIf="loaded; else loading">
  <mi-sortable-table *ngIf="contactRows.length" [columns]="contactColumns" [rows]="contactRows">
  </mi-sortable-table>
</div>

<ng-template #emailTmpl let-row="row" let-value="value">
  <a *ngIf="value; else noData" href="mailto:{{value}}">{{value}}</a>
</ng-template>

<ng-template #actionTmpl let-row="row" let-value="value">
  <div class="button-container">
    <!-- <button *ngIf="row.email != null" mi-button buttonTheme="light-theme" class="foalt-left"
      (click)="openSelectUserModalHandler()">
      Update
    </button>
    <button *ngIf="res.email === null" mi-button buttonTheme="light-theme" class="foalt-left"
      (click)="openSelectUserModalHandler()">
      Add
    </button>
    <button *ngIf="row.email != null" mi-button buttonTheme="alert-theme" (click)="removeUserHandler(row)">
      Remove
    </button> -->
    <a *ngIf="row.id != null" mi-button buttonTheme="light-theme" class="foalt-left"
      (click)="openSelectUserModalHandler(true)">
      Update
    </a>
    <a *ngIf="row.id != null" mi-button buttonTheme="light-theme" class="remove-user-button" (click)="removeUserHandler()">
      Remove</a>
    <a *ngIf="row.id == null" mi-button buttonTheme="light-theme" class="foalt-left"
      (click)="openSelectUserModalHandler(true)">
      Add
    </a>
  </div>
</ng-template>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>