export interface Address {
    reso_inital_value: any,
    reso_resolve_value: null,
    resolved_property: string,
    service_count: number,
    status: AddressStatus,
    unresolved_property: string,
    upload_exist_value: any,
    resolved_loc_id?: string,
    location_id?: string,
    system_id? : string
}

export interface MiyagiAddress {
    vendor: string;
    unresolvedValue: string;
    resolvedValue: string | null;
    requestedOn: string;
    updatedOn: string | null;
    updatedBy: string | null;
    requestedBy: string;
    tenantId: string;
    docId: string;
    status: string;
    vendorId: string;
    tenantName: string;
  }

export interface MiyagiAddress {
    vendor: string,
    unresolvedValue: string,
    resolvedValue: string,
    requestedOn: string,
    updatedOn: string,
    updatedBy: string,
    requestedBy: string,
    tenantId: string,
    docId: string,
    status: string,
    vendorId: string,
    tenantName: string,
    location_id: string
}

export interface AddressData {
    columns: []
    data: Address[]
    moreAvailable: boolean
    pageNumber: number
    pageSize: number
    total: number
}

export enum AddressStatus {
    Unresolved= "unresolved",
    Resolved= "resolved",
    Ignored= "ignored",
};


export interface StatusOption{
    value: any
}


export interface AddressPayload {
    status: AddressStatus,
    tenant_id: string,
    resolved_property?: string,
    unresolved_property: string,
    location_id?: string
}