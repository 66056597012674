<div>

    <mi-advanced-search-filter-group-v2
      title="Organization"
      filter_name="vendor_name"
      *ngIf="!pageLockFilters.includes('vendor_name')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-group-v2
      title="Organization Role"
      filter_name="role"
      translatePrefix="CONTACT_ROLES."
      *ngIf="!pageLockFilters.includes('role')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-group-v2
      title="Service Association"
      filter_name="service_associations"
      *ngIf="!pageLockFilters.includes('service_associations')">
    </mi-advanced-search-filter-group-v2 >

    <mi-advanced-search-filter-group-v2
      title="Equipment Association"
      filter_name="equipment_associations"
      *ngIf="!pageLockFilters.includes('equipment_associations')">
    </mi-advanced-search-filter-group-v2 >

    <div class="separated-filter-group">

      <mi-advanced-search-single-checkbox
        param="inactive"
        label="{{ 'FILTER_LABELS.SHOW_ONLY_INACTIVE_CONTACTS' | translate }}">
      </mi-advanced-search-single-checkbox>

    </div> 

</div>
