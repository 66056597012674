import { Component, Input, OnInit } from '@angular/core';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@independer/ng-modal';
import { Store } from '@ngrx/store';
import * as SDReducer from '../state/reducers';
import * as SDActions from '../state/actions';
import { MiService } from 'src/shared/models/MiService';
import { TermsAndConditions } from 'src/shared/models/TermsAndConditions';

@Component({
  templateUrl: './modal-cancellation-instruction.component.html'
})
export class ModalCancellationComponent extends AbstractPageComponent implements OnInit {
  miservice : MiService;
  terms_and_conditions: TermsAndConditions;
  loaded: boolean = false;
  
  constructor(private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private store: Store<SDReducer.State>,) {
super();

}

ngOnInit() {
  this.store.select(SDReducer.getDSData)
    .takeUntil(this.destroy$)
    .subscribe(
    data => {
      if (data.miService) {
        this.miservice= data.miService;
        if (this.miservice.current_financial && this.miservice.current_financial.terms_and_conditions) {
          this.terms_and_conditions = this.miservice.current_financial.terms_and_conditions;
        }
      }
      this.loaded = true;
    });
  }


}
