import {Injectable} from '@angular/core';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {HttpClient} from '@angular/common/http';
import {throwError, Observable} from 'rxjs';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class MiyagiConfigService {

  private apiUrlUpdateServiceEvents = '/miyagi/update-service-events';
  private apiUrlSendWeeklyEventEmail = '/miyagi/send-weekly-events-email/';
  private apiUrlUpdateAllAlerts = '/miyagi/update-all-alerts';
  private apiUrlUpdateAllEquipment = '/miyagi/reindex-equipment';
  private apiUrlUpdateServiceTerms = '/miyagi/update-service-terms';
  private apiUrlReIndex = '/miyagi/reindex';
  private apiUrlStopIndex = '/miyagi/stopindex';


  constructor(private http: HttpClient) {
  }

  sendWeeklyEventEmail(tenant: string): Observable<any> {
    return this.http
      .post(`${this.apiUrlSendWeeklyEventEmail}${tenant}`, {})
      .catch(e => throwError(e));
  }

  updateServiceEvents(): Observable<any> {
    return this.http
      .post(this.apiUrlUpdateServiceEvents, {})
      .catch(e => throwError(e));
  }

  updateAllAlerts(): Observable<any> {
    return this.http
      .post(this.apiUrlUpdateAllAlerts, {})
      .catch(e => throwError(e));
  }

  updateAllEquipment(): Observable<any> {
    return this.http
      .post(this.apiUrlUpdateAllEquipment, {})
      .catch(e => throwError(e));
  }

  updateAllServiceTerms(): Observable<any> {
    return this.http
      .post(this.apiUrlUpdateServiceTerms, {})
      .catch(e => throwError(e));
  }

  reIndex(model: string, tenantId: string = "", userEmail: string = ""): Observable<any> {
    let body = {model: model};
    if(tenantId.length > 0) {
      body["tenant_id"] = tenantId;
    }
    body["user_email"] = userEmail;
    return this.http
      .post(this.apiUrlReIndex, body)
      .catch(e => throwError(e));
  }

  stopIndex(): Observable<any> {
    return this.http
      .post(this.apiUrlStopIndex, {})
      .catch(e => throwError(e));
  }

}
