import {Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import * as SDReducer from '../../state/reducers';
import {AbstractPageComponent} from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import {Observable} from 'rxjs';
import {MiServiceDetails} from "../../../../../../shared/models/MiService";

export const VENDOR_LOGO_PLACEHOLDER: string = '/shared/assets/images/vendor-logo-placeholder.svg';

const AWSPath = "https://miso3-uploads-public.s3.amazonaws.com";


@Component({
  selector: 'mi-service-details-header-vendor',
  templateUrl: './service-details-header-vendor.component.html',
  styleUrls: ['../service-details-header-items.scss', './service-details-header-vendor.component.scss']
})

export class ServiceDetailsHeaderVendorComponent extends AbstractPageComponent implements OnInit {

  productNameClasses: any;
  titleClasses: any;
  vendorNameClasses: any;
  serviceDetails: MiServiceDetails;
  pageLoaded$: Observable<boolean>;
  vendorLogoUrl: string; //  = VENDOR_LOGO_PLACEHOLDER;
  showSubline: boolean = false;

  vendorLogoRepository: string = "https://miso3-uploads-public.s3.amazonaws.com";
// @Input() data: ServiceDetailsHeaderData;

  constructor(private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit() {

    this.pageLoaded$ = this.store.select(SDReducer.DSPageLoaded);
    this.store
      .select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails
            
            if ( this.serviceDetails.vendor.purchasing_vendor_name !== this.serviceDetails.vendor.purchasing_vendor_name /*||
              this.serviceDetails.service_data.product_name !== this.miService.product_v2.actual_product.product_name*/) {
              //either the product_name or vendor name is different.
              this.showSubline = true;
            }

            // this.serviceDetails.vendor.purchasing_vendor_logo = "https://miso3-uploads-public.s3.amazonaws.com/miyagi/vendor-logo/f97d3573-e57e-4b13-adb3-d78c0a30b4a8_arelion logo.png"

            // if (this.serviceDetails.vendor.purchasing_vendor_logo) {
            //   this.vendorLogoUrl = AWSPath + this.serviceDetails.vendor.purchasing_vendor_logo;
            // }


            if (this.serviceDetails.vendor.purchasing_vendor_logo) {
              if (this.serviceDetails.vendor.purchasing_vendor_logo.includes(AWSPath)) {
                this.vendorLogoUrl = this.serviceDetails.vendor.purchasing_vendor_logo
              } else {
                this.vendorLogoUrl = AWSPath + this.serviceDetails.vendor.purchasing_vendor_logo;
              }
            }

            const charCount = this.serviceDetails.vendor.purchasing_vendor_name.length + this.serviceDetails.service_data.product_name.length;

            this.titleClasses = {
              small: charCount < 40,
              med: charCount >= 40,
              large: charCount > 80
            };

            this.productNameClasses = {
              truncate: this.serviceDetails.service_data.product_name.length > 20
            };

            this.vendorNameClasses = {
              truncate: this.serviceDetails.vendor.purchasing_vendor_name.length > 5
            };


          }
        }
      );
  }
}
