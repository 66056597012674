<modal class="extra-wide" [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content >

      <mi-find-or-create-vendor
        [searchString] = "defaultSearchText"
        [showCreateVendor] = "showCreateVendor"
        (vendorSelected)="onVendorSelect($event)"
      >
      </mi-find-or-create-vendor>

  </modal-content>
</modal>
