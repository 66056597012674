<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <form [formGroup]="serviceTermForm" (ngSubmit)="onFormSubmit(serviceTermForm.value, $event)">

      <ng-container *ngIf="serviceTermDefined; else formInput">

        <ul class="terms-and-conditions">
          <li>
            <dl>
              <dt>End of Term Action</dt>
              <dd *ngIf="serviceTerm">
                {{'TENANT.SERVICE_DETAILS.EVENTS.' + serviceTerm.event_type | translate}}
              </dd>
              <dd *ngIf="!serviceTerm">
                {{NO_DATA}}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Auto Renew Length</dt>
              <dd *ngIf="serviceTerm">
                {{serviceTerm.auto_renew_length}}
              </dd>
              <dd *ngIf="!serviceTerm">
                {{NO_DATA}}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Days Required to Notify</dt>
              <dd *ngIf="serviceTerm">
                {{serviceTerm.days_to_notify}}
              </dd>
              <dd *ngIf="!serviceTerm">
                {{NO_DATA}}
              </dd>
            </dl>
          </li>
        </ul>

      </ng-container>
      <ng-template #formInput>
        <div class="clear-fix">
          <p class="hint col-1">
            {{'SERVICE_CREATE.SERVICE_TERM_LABEL_HINT' | translate}}
          </p>
        </div>
        <mi-extended-input labelText="Terms &amp; Conditions"
                           [labelRequired]="false"
                           [(inputControl)]="serviceTermForm.controls['service_term']"
                           [(inputErrors)]="serviceTermForm.controls['service_term'].errors"
                           [(inputTouched)]="serviceTermForm.controls['service_term'].touched">
          <mi-find-or-create-service-term
            [serviceTerm]="serviceTermForm.get('service_term').value"
            (selectedServiceTerm)="onSelectServiceTerm($event)"
            (canceled)="onStepChanged()"
          ></mi-find-or-create-service-term>
        </mi-extended-input>

      </ng-template>

      <div class="button-container">
        <a mi-button
           buttonTheme="light-theme"
           (click)="onChangeClicked()"
           *ngIf="!formDisabled">
          Change
        </a>
        <button type="submit"
                mi-button
                buttonTheme="dark-theme"
                >
          Next
        </button>
      </div>
    </form>

  </ng-container>

  <ng-template #static>

    <dl class="section-details">

      <ng-container *ngIf="serviceTerm; else noTerms">
        <div>
          <dt>
            MISO ID
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm && serviceTerm.display_id; else noTermsAndConditionsDoc">
              <mi-id-link [id]="serviceTerm.id" [display_id]="serviceTerm.display_id"></mi-id-link>
            </ng-container>
            <ng-template #noTermsAndConditionsDoc>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>
            End of Term Action
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm; else noEot">
              {{'END_OF_TERM.' + serviceTerm.event_type | translate}}
            </ng-container>
            <ng-template #noEot>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>
            Auto-Renew Period
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm && serviceTerm.auto_renew_length; else noArp">
              {{('MONTHS_QTY'
              | translate:{count: serviceTerm.auto_renew_length}
              | translateSelector: serviceTerm.auto_renew_length ) || NO_DATA}}
            </ng-container>
            <ng-template #noArp>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>{{ 'DAYS_TO_NOTIFY' | translate}}</dt>
          <dd>
            <ng-container *ngIf="serviceTerm; else noDtn">
              {{serviceTerm.days_to_notify|| 0}}
            </ng-container>
            <ng-template #noDtn>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>Origin of Term</dt>
          <dd>
            Service
          </dd>
        </div>
      </ng-container>

      <ng-template #noTerms>
        <div>
          There is no agreement, end of term action, auto-renew period or days to notifiy for this service.
        </div>
      </ng-template>

    </dl>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


