<div class="tab-title-header">
  <h2>{{title}}</h2>

  <a class="edit-link button dark-theme" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>
</div>
<mi-server-error [error]="error"></mi-server-error>
<mi-opacity-loader [ready]="searchLoaded">
  <div class="open" *ngIf="isOpen; else static">

  </div>
</mi-opacity-loader>

<ng-template #static>
  <div class="section-details card-1">
    <div class="associated-documents">
      <mi-sortable-draggable-table *ngIf="associatedDocumentRows.length; else noAttachments"
        [columns]="associatedDocumentColumns" [rows]="associatedDocumentRows" externalClasses="serviceDetails">
      </mi-sortable-draggable-table>

      <ng-template #noAttachments>
        <span class="no-results">
          {{'ORDER_INSTRUCTIONS.NO_ATTACHMENT' | translate }}
        </span>
      </ng-template>
      <ng-container *ngIf="(totalRow && totalPages) ">
        <mi-pagination totalRow="{{ totalRow }}" totalPages="{{totalPages}}"
          (paginationChangeHandler)="this.paginationChangeHandler($event)">
        </mi-pagination>
      </ng-container>
    </div>
  </div>
</ng-template>
<!-- <ng-container *ngIf="miService; else loading">

  <div class="open" *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">

      <mi-doc-find-and-select [selectDocuments]="this.miService.associated_documents"
        [accountId]="this.miService.account_id_v2" [vendor]="this.miService.purchasing_vendor"
        (selectedVendorDocuments)="onDocumentSelected($event)" (cancel)="onToggleExpand($event)">
      </mi-doc-find-and-select>

    </mi-opacity-loader>
  </div>

  <ng-template #static>

    <div class="section-details">

      <div class="associated-documents">

        <mi-sortable-table *ngIf="this.miService.associated_documents.length; else noAttachments"
          [columns]="associatedDocumentColumns" [rows]="associatedDocumentRows" externalClasses="serviceDetails">
        </mi-sortable-table>

        <ng-template #noAttachments>
          <span class="no-results">
            {{'ORDER_INSTRUCTIONS.NO_ATTACHMENT' | translate }}
          </span>
          </ng-template>

      </div>
    </div>

  </ng-template>

</ng-container> -->

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #dateTmpl let-row="row" let-value="value">
  {{value | date:'short'}}
</ng-template>

<ng-template #fileLink let-row="row" let-value="value">
  <a *ngIf="row.is_v1" href="{{getV1FileUrl(row)}}" target="_blank">{{ shortenFileName(row.file_name) }}</a>
  <a *ngIf="!row.is_v1&&!row.is_url" (click)="downloadDocument(row.id)">{{ shortenFileName(row.file_name) }}</a>
  <a *ngIf="!row.is_v1&&row.is_url" [href]="row.file_name" target="_blank">{{ shortenFileName(row.file_name) }}</a>
</ng-template>

<ng-template #fileType let-row="row" let-value="value">
  {{ 'UPLOAD_DOC_TYPES.' + DOC_TYPES[row.file_type] | translate }}
</ng-template>

<ng-template #fileSize let-row="row" let-value="value">
  {{ value | filesize }}
</ng-template>

<ng-template #documentDetailTpl let-row="row" let-value="value">
  <a routerLink="/associated-documents/{{row.id}}/details">{{ value }}</a>
</ng-template>