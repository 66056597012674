import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {TenantCustomFieldService} from "../../../libs/api/tenant/tenantCustomFields.service";
import {CustomFieldProperty, VendorCustomField} from "../../../shared/models/CustomFieldProperty";
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-custom-field-service-static',
  templateUrl: './custom-field-service-static.component.html',
  styleUrls: ['./custom-field-service-static.component.scss']
})

export class CustomFieldServiceStaticComponent extends AbstractPageComponent implements OnInit {


  currentFieldProperties: CustomFieldProperty[] = [];
  @Input() currentFieldData:  VendorCustomField[]


  loading: boolean = true;
  loadError: string = '';
  readonly NO_DATA: string = NO_DATA;

  constructor(private formBuilder: FormBuilder,
              private tenantCustomFieldsService: TenantCustomFieldService) {
    super();
  }

  @Input('currentFieldPropertiesSet')
  set currentFieldPropertiesSet(val) {
    if (val) {
      this.currentFieldProperties = JSON.parse(JSON.stringify(val));
      this.currentFieldProperties = this.currentFieldProperties.filter(option=> {
          return (option.value || option.value === 0) && option.custom_field.type != 'CONTACT';
        });
    }

  }

  ngOnInit() {
    this.loading = false;
  }

}

