import {Action} from '@ngrx/store';

export const ActionTypes = {
  LOAD_AS_NEW: '[Miso: Service Create Load As New]',
  UPDATE_DRAFT_SERVICE:  '[Miso: Order Create UPDATE_DRAFT_SERVICE]',

  SIMPLE_SERVICE_CREATE: '[Miso: Service Create: SIMPLE_SERVICE_CREATE]',
  SERVICE_CREATE_ERROR: '[Miso: Service Create:: SERVICE_CREATE_ERROR]',
  SERVICE_CREATE_SUCCESS: '[Miso: Service Create:: SERVICE_CREATE_SUCCESS]'
};

export class LoadAsNewAction implements Action {
  type = ActionTypes.LOAD_AS_NEW;
  constructor(public payload) {}
}

export class UpdateDraftService implements Action {
  type = ActionTypes.UPDATE_DRAFT_SERVICE;

  constructor(public payload) {
  }
}

export class SimpleServiceCreate implements Action{
  type = ActionTypes.SIMPLE_SERVICE_CREATE;
  constructor(public payload){
  }
}

export class ServiceCreateError implements Action{
  type = ActionTypes.SERVICE_CREATE_ERROR;
  constructor(public payload){
  }
}

export class ServiceCreateSuccess implements Action{
  type = ActionTypes.SERVICE_CREATE_SUCCESS;
  constructor(public payload){
  }
}



export type Actions
  = LoadAsNewAction |
  UpdateDraftService |
  SimpleServiceCreate |
  ServiceCreateError |
  ServiceCreateSuccess;