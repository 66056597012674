import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AddressV2} from '../../../libs/api2/types/AddressV2.interface';

const MISSING_IMAGE: string = '/shared/assets/images/missing-image.svg';

@Component({
  selector: 'mi-static-map',
  templateUrl: './static-map.component.html'
})

export class StaticMapComponent implements OnInit, OnChanges {
  @Input() address: AddressV2;
  @Input() width: number = 600;
  @Input() height: number = 400;
  @Input() zoom: number = 14;
  @Input() markerColor: string = '#0090C9';
  @Input() showMarker: boolean = true;

  href: string;

  constructor() {
  }

  ngOnInit() {
    this.markerColor = this.markerColor.replace('#', '0x');
    this.href = this.getStaticMapUrl();
  }

  ngOnChanges(data: any) {
    this.href = this.getStaticMapUrl();
  }

  getStaticMapUrl() {
    if (this.address && this.address.latitude != null && this.address.longitude != null) {
      let url = 'https://maps.googleapis.com/maps/api/staticmap?center='
        + this.address.latitude
        + '%2c%20'
        + this.address.longitude
        + '&zoom=14&size='
        + this.width
        + 'x'
        + this.height
        + '&maptype=roadmap';

      if (this.showMarker) {
        url += '&markers=color:0x0090C9%7C'
          + this.address.latitude
          + ','
          + this.address.longitude
      }

      url += '&key='
        + environment.GOOGLE_MAP_API_KEY;

      return url;
    }
    return MISSING_IMAGE;
  }
}
