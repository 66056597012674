import { Action } from '@ngrx/store';

export const ActionTypes = {
  SET_DATA: '[Miso: TenantBillingAccount] SET_DATA',
  GET_DATA: '[Miso: TenantBillingAccount] GET_DATA',
  RESET: '[Miso: TenantBillingAccount] RESET'
};

export class SetTenantBillingAccountData implements Action {
  type = ActionTypes.SET_DATA;

  constructor(public payload) { }
}

export class GetTenantBillingAccountData implements Action {
  type = ActionTypes.GET_DATA;

  constructor(public payload) { }
}

export class ResetTenantBillingAccountData implements Action {
  type = ActionTypes.RESET;

  constructor(public payload) { }
}

export type Actions
  = SetTenantBillingAccountData
  | GetTenantBillingAccountData
  | ResetTenantBillingAccountData