<div class="tab-title-header">
  <h2>{{title}}</h2>
<!-- <a class="edit-link" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a> -->
</div>

<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="serviceDetails; else loading">

  <!-- <div class="open" *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
      <form [formGroup]="formGroup" (submit)="onUpdateSubmit(formGroup.value)">
        <dl class="section-details">

          <div class="one-third-edit">
            <dt>Order Date</dt>
            <dd>
              <mi-date-picker [currentDate]="serviceDetails.vendor.order_date" (dateChange)="onDateSelected($event)">
              </mi-date-picker>
            </dd>
          </div>

          <div class="one-third-edit">
            <dd>
              <mi-extended-input labelText="Order ID" class="service-details" [labelRequired]="false"
                [(inputControl)]="formGroup.controls['service_order_number']"
                [(inputErrors)]="formGroup.controls['service_order_number'].errors"
                [(inputTouched)]="formGroup.controls['service_order_number'].touched">
                <input type="text" class="form-control" formControlName="service_order_number" placeholder="{{this.NO_DATA}}"
                  mi-restrict-input miRestrictType="OrderId">
              </mi-extended-input>
            </dd>
          </div>

          <div class="one-third-edit">
            <dd>
              <mi-extended-input labelText="Service ID" class="service-details" [labelRequired]="false"
                [(inputControl)]="formGroup.controls['service_identifier']"
                [(inputErrors)]="formGroup.controls['service_identifier'].errors"
                [(inputTouched)]="formGroup.controls['service_identifier'].touched">
                <mi-service-id-form (complete)='serviceIdChange($event)' completeServiceId="true"
                  [actual_vendor_id]='serviceDetails.vendor.purchasing_vendor_id' completeButton='Confirm' title=""
                  [service_id]="service_id">
                </mi-service-id-form>
              </mi-extended-input>
            </dd>
          </div>

          <!-- <div [ngStyle]="{'margin': '0 0 20px 0'}">
            <div class="non-input-label">Order Date</div>
            <mi-date-picker
              [currentDate]="miService.current_financial.service_order_date"
              (dateChange)="onDateSelected($event)">

            </mi-date-picker>
          </div> -->

          <!-- <mi-extended-input labelText="Order ID"
                            class="service-details"
                            [labelRequired]="false"
                            [(inputControl)]="formGroup.controls['service_order_number']"
                            [(inputErrors)]="formGroup.controls['service_order_number'].errors"
                            [(inputTouched)]="formGroup.controls['service_order_number'].touched">
            <input type="text"
                  class="form-control"
                  formControlName="service_order_number"
                  placeholder="{{this.NO_DATA}}"
                  mi-restrict-input miRestrictType="OrderId">
          </mi-extended-input> -->


          <!-- 
          <mi-extended-input labelText="Service ID"
                            class="service-details"
                            [labelRequired]="false"
                            [(inputControl)]="formGroup.controls['service_identifier']"
                            [(inputErrors)]="formGroup.controls['service_identifier'].errors"
                            [(inputTouched)]="formGroup.controls['service_identifier'].touched">

            <mi-service-id-form (complete)='serviceIdChange($event)'
                    completeServiceId= "true"
                    [actual_vendor_id]='miService.purchasing_vendor.actual_vendor_id'
                    completeButton='Confirm'
                    title= ""
                    [service_id]="service_id">
            </mi-service-id-form>
          </mi-extended-input>      -->
        <!-- </dl>

        <div class="button-container">
          <button type="button" mi-button buttonTheme="light-theme" (click)="onToggleExpand()">Cancel</button>
          <button mi-button type="submit">Apply</button>
        </div>

      </form>
    </mi-opacity-loader>
  </div> -->

  <ng-container>
    <dl class="section-details card-1">
      <div>
        <dt>Order Date</dt>
        <dd>
          {{( serviceDetails.vendor.order_date | date:'shortDate') || NO_DATA}}
        </dd>
      </div>
      <div>
        <dt>Order Identifier</dt>
        <dd>
          {{ serviceDetails.vendor.order_number || NO_DATA}}
        </dd>
      </div>
      <div>
        <dt>Service Identifier</dt>
        <dd>
          {{ serviceDetails.service_data.service_identifier || NO_DATA}}
        </dd>
      </div>
    </dl>
  </ng-container>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>