import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createSecureUser} from "../../../common/security/secureUser";
import {clearAuthCookie, getAuthCookie, getAuthCookieV2} from "../../user-store/state/auth-cookie";
import {throwError} from "rxjs/index";
import {AuthServiceReturn} from "../../../shared/ng-models/AuthServiceReturn.interface";
import {TenantV2Service} from "../tenant-v2/tenant-v2.service";

import {combineLatest } from 'rxjs';
import {catchError, map} from "rxjs/operators";



export interface SetPasswordParams {
  password: string;
  reset_token: string;
}

export interface SetPasswordResponse {
  email: string,
  first_name: string,
  last_name: string
}

export interface SignInParams {
  email: string,
  password: string
}

export interface SignInResponse {
  access_token: string,
  token_type: string
}


export interface AgreementResponse {
  agreement_version: string
}


@Injectable()
export class AuthV2Service {

  private host = '_MICRO_SERVICE_/auth';
  private apiUrlGetUserInfoById = "_MICRO_SERVICE_/auth/user-info-by-id";
  private apiUrlGetUserInfoByEmail = "_MICRO_SERVICE_/auth/user-info";

  constructor(
    private http: HttpClient,
    private tenantV2Service: TenantV2Service) {

  }

  setPassword(params: SetPasswordParams): Observable<SetPasswordResponse> {
    return this.http
      .post(`${this.host}/reset-password`, params)
      .map((response: any) => response as SetPasswordResponse);
  }

  signIn(params: SignInParams): Observable<SignInResponse> {
    return this.http
      .post(`${this.host}/signin`, params)
      .map((response: any) => response as SignInResponse);
  }

  getIdentity() {
    return this.http
      .get(`${this.host}/identify`);
  }

  getIdentityByTenantId(tenantId: string) {
    return this.http
      .get(`${this.host}/identify?tenant_id=${tenantId}`);
  }

  getLoggedInUser(): Observable<AuthServiceReturn> {
    if (getAuthCookieV2() !== null) {
      return combineLatest(
          this.tenantV2Service.getUserBusinessUnits(),
          this.getIdentity()
        )
        .pipe(
          map( ([tenants,identity]) => {
            return {
              secureUser: createSecureUser({
                ...identity,
                microTenants:tenants || [],
                isV2: true
              })
            };
          }),
          catchError(e => {
              clearAuthCookie();
              return throwError(e);
            }
          )
        )
    } else {
      // debugger;
      return throwError('there was not a cookie set');
    }
  }

  acceptPrivacyAgreement(privacyUrl: string): Observable<AgreementResponse> {
    const params = {
      agreement_version: privacyUrl
    };
    return this.http
      .post(`${this.host}/accept-agreement`, params)
      .map((response: any) => response as AgreementResponse);
  }

  initResetPassword(email: String): Promise<any> {
    return this.http
      .post<any>(`${this.host}/forgot-password`, {email: email})
      .toPromise();
  } 

  getUserInfoById(id: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlGetUserInfoById}?id=${id}`);
  }

  getUserInfoByEmail(email: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlGetUserInfoByEmail}?email=${email}`);
  }

  //
  // getMyAccess(params: SimplePaginationParams = {}): Observable<TenantV2[]> {
  //   return of([
  //     {
  //       name: "Tenant 1",
  //       active: true,
  //       parent: {
  //         name: "tenant 1",
  //         active: true
  //       },
  //       created_at: "1/12/2020",
  //       poc: {
  //         first_name: "kris",
  //         email: "kris.meister@gmail.com",
  //         phone: "989-392-3828"
  //       }
  //     },
  //     {
  //       name: "Tenant 2",
  //       active: true,
  //       created_at: "1/12/2020",
  //       parent: {
  //         name: "tenant 1",
  //         active: true
  //       },
  //       poc: {
  //         first_name: "jane",
  //         last_name: "doe",
  //         email: "kris.meister@gmail.com",
  //         phone: "989-392-3828"
  //       }
  //     }
  //   ]);
  // }

}
