<div>
  <mi-page-header
    title="Documents"
    [breadcrumbs]="breadcrumbs"
    [links]="headerLinks">
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>

  <mi-page-alert></mi-page-alert>
  <ng-container *ngIf="loaded; else loading">
    <mi-document-upload-drag-drop
             accept="{{ UPLOAD_TYPES.TENANT_DOCUMENT_FILE.CONTENT_TYPE }}"
             [tenantData]="tenantData"></mi-document-upload-drag-drop>
  </ng-container>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
