<div class="container">
    <div class="row">
        <div class="col-sm d-flex">
            <div class="bg-primary flex-fill">
                <label>Select Tenants Combination</label>
                <select class="mt-1" (change)="onChangesHandler($event.target.value)">
                    <option *ngFor="let config of this.tenantConfig" value="{{config.config_id}}">
                        {{config.config_name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="iframe-container mt-3">
    <mi-opacity-loader [ready]="!showLoader">
        <iframe class="iframe-container" id="metabase-iframe-{{metaBaseDashboardId}}" src="" title="metabase"></iframe>
    </mi-opacity-loader>
</div>