import {NgModule} from '@angular/core';
import {IsUserAuthGuard} from "./guards/IsUserAuth.guard";
import {SuperUserAuthGuard} from "./guards/SuperUserAuth.guard";
import {TenantUserAuthGuard} from "./guards/TenantUserAuth.guard";
import {UserStoreModule} from "../user-store/user-store.module";
import {ApiModule} from "../api/api.module";
import { TenantSuperAdminAuthGuard } from './guards/TenantSuperAdminAuth.guard';
import { TenantAdminAuthGuard } from './guards/TenantAdminAuth.guard';
import { TenantAlignAuthGuard } from './guards/TenantAlignAuth.guard';
import { TenantReportsAuthGuard } from './guards/TenantReportsAuth.guard';

@NgModule({
  imports: [
    UserStoreModule,
    ApiModule
  ],
  declarations: [],
  providers: [
    IsUserAuthGuard,
    SuperUserAuthGuard,
    TenantUserAuthGuard,
    TenantAlignAuthGuard,
    TenantReportsAuthGuard,
    TenantAdminAuthGuard,
    TenantSuperAdminAuthGuard
  ],
  exports: []
})
export class AuthGuardsModule {
}
