/**
 * Fixes and angular 4 bug where arrays are not serialized correctly
 * @param {Object} query
 * @returns {Object}
 */
import {HttpParams} from '@angular/common/http';

export const fixAngularArrayQuery = (query: object): any => {

  const newQuery = {};

  for (let [key, value] of Object.entries(query)) {
    if (Array.isArray(value)&&!key.endsWith('[]')) {
      newQuery[key+'[]'] = value;
    } else {
      newQuery[key] = value;
    }
  }

  return newQuery;

};
