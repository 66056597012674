import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../../create/state/reducer';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {UpdateOrderAction} from '../../../create/state/actions';
import {integerValidator, numberValidator} from '../../../../../../../shared/shared.validators';
import { Subject } from 'rxjs';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';

@Component({
  selector: 'mi-service-identifier',
  styleUrls: ['./service-identifier.component.scss'],
  templateUrl: './service-identifier.component.html'
})

export class ServiceIdentifierComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  commandGetAccountId:Subject<any> = new Subject();
  currentDate: string;
  error: string;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  serviceId:string;
  serviceIdentifierForm:FormGroup;
  step: string = OrderSteps.ServiceIdentifier;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
  ) {
    super();
  }

  ngOnInit() {

    this.serviceIdentifierForm = this.formBuilder.group({
      service_identifier: [null]
    });

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state:OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });

  }

  onCancelCLicked():void{
    this.store.dispatch(new UpdateOrderAction(
      {
        index: this.orderState.maxIndex > this.index ? this.orderState.maxIndex : this.index - 1
      }
    ));
  }

  onFormSubmit(data: any) {
    // this.serviceIdentifierForm.value.service_identifier = data
    this.serviceIdentifierForm.controls.service_identifier.setValue(data)
    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);
    // this.serviceIdentifierForm =
    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          service_identifier: data
        }
      }
    ));

  }

}
