import {Component, Input} from "@angular/core";

import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {createLogger, LOG_LEVELS} from "../../../logger";
import {Observable} from "rxjs";

import {FormBuilder, FormGroup} from "@angular/forms";

const log = createLogger(LOG_LEVELS.COMPONENT);


@Component({
  selector: 'mi-advanced-search-load-more',
  templateUrl: './advanced-search-load-more.component.html',
  styleUrls: ['./advanced-search-load-more.component.scss']
})

export class AdvancedSearchLoadMoreComponent extends AbstractPageComponent {

  showAll: boolean = false;
  loadMoreLoading$: Observable<boolean>;
  hasLoadMore$: Observable<boolean>;

  constructor(
              private store: Store<ASReducer.State>) {
    super()
  }

  ngOnInit() {

    this.loadMoreLoading$ =  this.store.select(ASReducer.ASLoadMoreLoading);
    this.hasLoadMore$ =  this.store.select(ASReducer.ASHasLoadMore);

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe((asData) => {

        if (asData.resultsLoaded) {
          this.showAll = asData.results.meta.params.inactive === 'true';
        }

      });
  }

  loadMore(evt) {
    // send action to load qty of 20 more
    this.store.dispatch(new ASActions.AsLoadMore({qty: 20}));
  }

}
