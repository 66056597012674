<div class="event">

  <div class="event-avatar"
       title="{{'EVENTS.' + this.event.event_type | translate}}"
       [ngClass]="{
         'service-start': this.event.event_type === EVENT_ACTIVATION || this.event.event_type === EVENT_SERVICE_START,
         'service-auto-renew': this.event.event_type === EVENT_AUTO_RENEW,
         'service-reterm': this.event.event_type === EVENT_RE_TERM,
         'service-cancel': this.event.event_type === EVENT_AUTO_CANCEL || this.event.event_type === EVENT_MANUAL_CANCEL
         }">
  </div>

  <div class="event-details">
    <div class="status">
      {{'EVENTS.' + this.event.event_type | translate}}
    </div>
    <div class="when">
      {{ this.relativeTime }} <span class="light-gray smaller">{{this.event.end_date | date:'shortDate' }}</span>
    </div>
    <div *ngIf="this.event.realized_saving && !hideSavings">
      <span class="light-gray">Details:</span> Realized Savings: {{this.event.realized_saving|round|currency:'USD':'symbol':'1.2-2'}}
    </div>
  </div>

</div>
