import { Action } from '@ngrx/store';

export const ActionTypes = {
  RESET: '[Miso:Alerts] Reset',
  LOAD_ALERTS: '[Miso:Alerts] Load',
  LOAD_ALERTS_SUCCESS: '[Miso:Alerts] Load Success',
  LOAD_ALERTS_FAIL: '[Miso:Alerts] Load Fail'
};

export class ResetAlertsListAction implements Action {
  type = ActionTypes.RESET;
  constructor(public payload: any) { }
}

export class LoadAlertsAction implements Action {
  type = ActionTypes.LOAD_ALERTS;
  constructor(public payload: any) { }
}

export class LoadAlertsSuccessAction implements Action {
  type = ActionTypes.LOAD_ALERTS_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadAlertsFailAction implements Action {
  type = ActionTypes.LOAD_ALERTS_FAIL;
  constructor(public payload: any) { }
}

export type Actions
  = ResetAlertsListAction
  | LoadAlertsAction
  | LoadAlertsSuccessAction
  | LoadAlertsFailAction
