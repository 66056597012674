import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router, Params} from "@angular/router";
import { CanDelete } from "../../../../shared/models/CanDelete";
import { PageAlertAddAction } from "../../../../shared/state/page-alert/actions";
import { Store } from "@ngrx/store";
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import { TenantContactsService } from "../../../../libs/api/tenant/tenantContacts.service";
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';

@Component({
  templateUrl: './contact-delete.component.html',
  styleUrls: ['./contact-delete.component.scss']
})

export class ContactDeleteComponent extends AbstractPageComponent implements OnInit {

  display_id: string;
  error: string;
  exitRoute: string;
  canDelete: CanDelete;
  canDeleteMode: boolean = true;
  loaded: boolean = true;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantConactService: TenantContactsService,
              private tenantConactV2Service: ContactV2Service,
              private router: Router,
              private store: Store<ASReducer.State>) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.parent.params
      .takeUntil(this.destroy$)
      .subscribe((params: Params) => {
        this.display_id= params.display_id;
        this.exitRoute = `/contacts/${this.display_id}/edit`;
        this.tenantConactV2Service.getContactById(this.display_id)
          .toPromise()
          .then((contact: VendorContact) => this.tenantConactService.canDelete(contact.id).toPromise())
          .then((res: CanDelete) => {
            this.canDelete = res;
            if(res.dependents.length){
              this.canDeleteMode = false;
            }
          })
          .catch(e => this.error = e)
      });
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete(){
    this.tenantConactV2Service.deleteContact( this.display_id )
    .subscribe(res =>{
      this.loaded = false;
        this.store.dispatch(
          new PageAlertAddAction({
            message: `Contact was successfully deleted.`,
            alertType: "SUCCESS"
          })
        );
        setTimeout(()=>{
          this.router.navigate([`/vendors`]);
        }, 1000);
      },
      e => this.error = e
    )
  }

}
