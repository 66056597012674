import {Component, OnInit} from '@angular/core';
import {OrderSteps, OrderState, OrderProgression} from '../../state/reducer';
import {Store} from '@ngrx/store';
import {UpdateOrderAction} from '../../state/actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';

@Component({
  selector: 'mi-service-order-date',
  styleUrls: ['./service-order-date.component.scss'],
  templateUrl: './service-order-date.component.html'
})
export class ServiceOrderDateComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  currentDate: string;
  error: string;
  index: number;
  readonly NO_DATA: string = NO_DATA;
  orderState: OrderState;
  orderDateForm: FormGroup;
  readonly OrderSteps: any = OrderReducers.OrderSteps;
  step: string = OrderSteps.OrderDate;
  today: string;
  dateKnown: number = 0;
  startDate: any = {
    UNKNOWN: 0,
    KNOWN: 1
  };
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
  ) {
    super();
  }

  ngOnInit() {

    const date: Date = new Date();
    this.today = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state as OrderState;
          this.currentDate = state.order_date || this.today;
          this.orderDateForm = this.formBuilder.group({
            order_date: this.currentDate
          });
          // this.dateKnown = this.currentDate ? this.startDate.KNOWN : this.startDate.UNKNOWN;
          // Find the index belonging to the state's current step:
          this.index = OrderProgression.findIndex((a) => {
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;

          this.onToggleDateKnown(this.startDate.UNKNOWN);
        },
        (e) => {
          this.error = e;
        });
  }

  onToggleDateKnown(dateKnown: number) {
    this.dateKnown = dateKnown;
    if (this.dateKnown === this.startDate.UNKNOWN) {
      this.orderDateForm.get('order_date').setValue(null);
    }
  }

  onDateChange(e): void {
    this.currentDate = e;
    this.orderDateForm.get('order_date').setValue(this.currentDate);
    this.orderDateForm.updateValueAndValidity();
  }

  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        order_date: this.dateKnown === this.startDate.KNOWN ? data.order_date : null
      }
    ));

  }

}
