import { Component, OnInit } from "@angular/core";
import { Store } from "../../../../node_modules/@ngrx/store";
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../shared/state/advanced-search/actions';
import { TenantCustomFieldService } from "../../../libs/api/tenant/tenantCustomFields.service";
import { AbstractPageComponent } from "../../../shared/pages/abstract-page/abstract-page.component";
import { CustomField } from "../../../shared/models/CustomField";

@Component({
  selector: 'mi-standard-tenant-search-filters-v1',
  templateUrl: './standard-tenant-search-filters.component.html',
  styleUrls: ['./standard-tenant-search-filters.component.scss']
})

export class StandardTenantSearchFiltersV1Component extends AbstractPageComponent {

  loading: boolean = true;
  loadError: string;
  ShowConfigure: boolean = false;
  customFields: CustomField[] = [];
  pageLockFilters: string[] = [];

  constructor(private store: Store<ASReducer.State>,
              private tenantCustomFieldService: TenantCustomFieldService) {

    super();

    this.store
      .select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe((data) => {
        this.pageLockFilters = data.pageLockFilters;
      });

    this.tenantCustomFieldService.listCustomFields().subscribe(
      (allCustomFields) => {
        this.customFields=[...allCustomFields];
        this.loading = false;
      },
      (e) => this.loadError = e
    );

  }

  onConfigurationComplete(data) {
    this.store.dispatch(new ASActions.AsAppliedVisibleFilters(data));
    this.ShowConfigure = false;
  }
}
