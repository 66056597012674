<mi-page-header title="{{ 'TENANT.VENDOR_LIST_TITLE' | translate }}" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>


<!-- <div class="report-box">
    <b class="title">Standard Report</b>
    <ul class="service-data-list">
        <li><a routerLink="/tenant-report" class="link">Run Report</a></li>
    </ul>
</div>

<div class="report-box">
    <b class="title">Custom Report</b>
    <ul class="service-data-list">
        <li><a routerLink="/tenant-report" class="link">Your future</a></li>
    </ul>
</div> -->

<section class="main-container" *ngIf="loaded; else loading">
    <ng-container>
        <div class="report-logo-container">
            <div class="box">
                <!-- <select (change)="onVendorChanges($event.target.value)" *ngIf="tenantReportDetails.tenantVendorCount > 1">
                                                    <option value="-1">{{tenantData.display_name}} Report</option>
                    <option *ngFor="let vendor of this.tenantReportDetails.tenantVendors" value="{{vendor.tenantVendorId}}">
                        {{vendor.vendorName}}
                    </option>
                </select> -->
            </div>
            <div class="box">
                <div>
                    <button mi-button class="download-report-button" id="downloadButton" (click)="downloadPDF()">
                        Download Report
                    </button>

                </div>
            </div>
        </div>
    </ng-container>

    <div id="pEl" #pdfElement>
        <ng-container>
            <div class="report-logo-container">
                <div class="box"><img class="report-logo" src="/shared/assets/images/miso3-logo.svg"></div>
                <div class="box">
                    <h4><b>Vendor Service Summary</b></h4>
                    <div>{{tenantData.display_name}} | <a target="_blank" href="/dashboard"> {{baseUrl}} </a>
                    </div>
                    <div>{{tenantReportDetails.reportDate | date: 'MM/dd/yyyy'}}</div>
                </div>
            </div>
        </ng-container>

        <div class="report-body">
            <ng-container>
                <h4 class="report-heading"><b>Top 5 Vendors by Spend</b>
                </h4>
                <mi-sortable-table *ngIf="topFiveVendors && topFiveVendors.length" [rows]="topFiveVendors" [columns]="vendorsColumns">
                </mi-sortable-table>
            </ng-container>

            <ng-container>
                <div class="report-note">
                    MiSO3 is monitoring a total of <b>{{tenantReportDetails.tenantVendorCount}}</b>
                    {{pluralizeWord("vendor",
                    tenantReportDetails.tenantVendorCount)}} with a
                    normalized monthly cost of <b>{{ tenantReportDetails.totalCmrc | round | tenantcurrency:
                        '0.0-0'}}</b>. For a
                    comprehensive list of vendors, please visit <a target="_blank" href="/vendors"> {{vendorUrl}} </a>
                </div>
            </ng-container>

            <ng-container>
                <span class="pdf-spacing">
                    <h4 class="report-heading"><b>Spend by Service Category</b></h4>
                    <div class="service-details">
                        <ul class="category-spend">
                            <li *ngFor="let item of topFiveCategories">
                                <div><b>{{item.totalCmrcPercentage+'%'}}</b></div>
                                <span>{{item.productCategoryName}}</span>
                            </li>
                        </ul>
                    </div>
                </span>
            </ng-container>

            <ng-container>
                <span class="pdf-spacing">
                    <h4 class="report-heading"><b>Changes to Existing Services < 90-days </b>
                    </h4>

                    <div class="container">
                        <div class="column1">
                            <ul class="cost-change">
                                <li>
                                    <div class="title">
                                        <span class="link">
                                            <div class="link link--cost-increased">
                                                {{getPercentage(tenantReportDetails.netCostChangeValues.costIncreasedPercentage)}}
                                            </div>
                                        </span>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        <div class="column2" *ngIf="tenantReportDetails.netCostChangeValues.costIncreasedServiceCount">
                            <b>{{tenantReportDetails.netCostChangeValues.costIncreasedServiceCount}}</b>
                            {{pluralizeWord("service",
                            tenantReportDetails.netCostChangeValues.costIncreasedServiceCount)}}
                            increased
                            in price, adding <b>{{tenantReportDetails.netCostChangeValues.costIncreasedValue |
                                tenantcurrency:
                                '0.0-0'}}</b> per month
                        </div>
                        <div class="column2" *ngIf="!tenantReportDetails.netCostChangeValues.costIncreasedServiceCount">
                            No services increased in price in the last 90 days
                        </div>
                    </div>

                    <div class="container">
                        <div class="column1">
                            <ul class="cost-change">
                                <li>
                                    <div class="title">
                                        <span class="link">
                                            <div class="link link--cost-decreased">
                                                {{getPercentage(tenantReportDetails.netCostChangeValues.costDecreasedPercentage)}}
                                            </div>
                                        </span>
                                    </div>
                                </li>
                                </ul>

                        </div>
                        <div class="column2" *ngIf="tenantReportDetails.netCostChangeValues.costDecreasedServiceCount">
                            <b>{{tenantReportDetails.netCostChangeValues.costDecreasedServiceCount}}</b>
                            {{pluralizeWord("service",
                            tenantReportDetails.netCostChangeValues.costDecreasedServiceCount)}}
                            decreased
                            in price, reducing <b>{{tenantReportDetails.netCostChangeValues.costDecreasedValue |
                                tenantcurrency:
                                '0.0-0'}}</b> per month
                        </div>
                        <div class="column2" *ngIf="!tenantReportDetails.netCostChangeValues.costDecreasedServiceCount">
                            No services decreased in price in the last 90 days
                        </div>
                    </div>
                </span>
            </ng-container>

            <ng-container>
                <span class="pdf-spacing">
                    <h4 class="report-heading "><b>Spend by Data Integrity </b></h4>
                    <!-- Spend by Term data integrity -->
                    <div class="container">
                        <div class="column1"><b
                                [ngClass]="getDataIntegrityGrade(tenantReportDetails.totalDataIntegrityCmrcPercentage).colorClass">{{getDataIntegrityGrade(tenantReportDetails.totalDataIntegrityCmrcPercentage).grade}}</b>
                        </div>
                        <div class="column2">
                            Your current data integrity score is
                            <b>{{getPercentage(tenantReportDetails.totalDataIntegrityCmrcPercentage)}}</b>. This score
                            serves as
                            a
                            directional compass guiding your ability to make timely
                            decisions regarding your vendors. Currently
                            <b>{{tenantReportDetails.annualSpendPoorDataIntegrity
                                | tenantcurrency:
                                '0.0-0'}}</b> annually falls
                            under the classification of poor data
                            integrity.
                        </div>
                    </div>
                    </span>
            </ng-container>
            <ng-container>
                <div id="page-2">
                    <h4 class="report-heading"><b>Spend by Term</b></h4>
                    <!-- Spend by Term coming out-of-term -->
                    <div class="container">
                        <div class="column1">{{tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrc
                            |
                            tenantcurrency: '0.0-0'}}
                        </div>
                        <div class="column2" *ngIf="tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrc > 0 ">
                            In the next <b>90-days</b>, <b>
                                {{tenantReportDetails.comingOutOfTerm90DaysServiceCount}}</b>
                            {{pluralizeWord("service", tenantReportDetails.comingOutOfTerm90DaysServiceCount)}} across
                            <b>{{tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysVendorCount}}</b>
                            {{pluralizeWord("vendor",
                            tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysVendorCount)}}
                            , or
                            <b>{{getPercentage(tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrcPercentage)}}</b>
                            of your
                            monthly spend is coming out-of-term
                        </div>

                        <!-- <div class="column2" *ngIf="tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrc == 0 ">Not
                        Available – MiSO currently cannot detect any vendor spend coming out of term within the next 90
                        days.
                        This value is
                        directly influenced by both service terms and your current data integrity score.
                    </div> -->
                        <div class="column2" *ngIf="tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrc == 0 ">
                            There are no services coming out-of-term in the next 90 days.
                        </div>
                    </div>
                    <hr>
                    <!-- Spend by Term Currently Out of Term -->
                    <div class="container">
                        <div class="column1">{{tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc |
                            tenantcurrency: '0.0-0'}}
                        </div>
                        <div class="column2" *ngIf="tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc > 0 ">
                            <b>{{tenantReportDetails.currentlyOutOfTermServiceCount}}</b> services across
                            <b>{{tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermVendorCount}}</b>
                            {{pluralizeWord("vendor",
                            tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermVendorCount)}},
                            or
                            <b>{{getPercentage(tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrcPercentage)}}</b>
                            of your monthly
                            spend, is currently out-of-term. The average duration of your out-of-term spending is
                            <b><b>{{getFormattedFloatNumber(tenantReportDetails.currentlyOutOfTermValues.cmrcWeightedTotalAutoRenewMonths)}}</b></b>
                            months.

                            <!-- <b>{{tenantReportDetails.currentlyOutOfTermServiceCount}}</b> services across
                                                                                                                        <b>{{tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermVendorCount}}</b> vendors,
                                                                                                                        and accounting
                        for <b>{{tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc |
                            tenantcurrency: '0.0-0' }}</b> or
                        <b>{{getPercentage(tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrcPercentage)}}</b>
                        of your
                        monthly
                        spend
                        are
                        <b>currently out of term</b>. The average duration of your out of term is spend is
                        <b>{{getFormattedFloatNumber(tenantReportDetails.currentlyOutOfTermValues.cmrcWeightedTotalAutoRenewMonths)}}</b>
                        months. -->
                        </div>
                        <div class="column2"
                            *ngIf="tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  0 || tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  null  || tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  undefined ">
                            There are no services out-of-term
                        </div>
                        <!-- <div class="column2"
                                                                        *ngIf="tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  0 || tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  null  || tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  undefined ">
                        <b>Not
                            Available</b> – MiSO3 currently cannot detect any vendor spend
                        currently out of term. This value is directly influenced by both
                        service terms and your current data integrity score.
                    </div> -->
                        <!-- <div class="column2"
                                                                                    *ngIf="tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  0 || tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  null  || tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc ===  undefined ">
                                                                                    There are no services out-of-term
                                                                                </div> -->


                    </div>
                    <hr>
                    <!-- Spend by Term Unknown Term Status -->

                    <div class="container">
                        <div class="column1">{{tenantReportDetails.unknownTermValues.unknownTermCmrc | tenantcurrency:
                            '0.0-0'
                            }}
                        </div>
                        <div class="column2" *ngIf="tenantReportDetails.unknownTermValues.unknownTermCmrc > 0">
                            <b>{{tenantReportDetails.unknownTermServiceCount}}</b> {{pluralizeWord("service",
                            tenantReportDetails.unknownTermServiceCount)}} across
                            <b>{{tenantReportDetails.unknownTermValues.unknownTermVendorCount }} </b>
                            {{pluralizeWord("vendor",
                            tenantReportDetails.unknownTermValues.unknownTermVendorCount)}}, or
                            <b>{{getPercentage(tenantReportDetails.unknownTermValues.unknownTermCmrcPercentage)}}</b> of
                            your monthly spend, has an
                            unknown term due to poor data integrity.
                        </div>
                        <div class="column2" *ngIf="tenantReportDetails.unknownTermValues.unknownTermCmrc === 0">
                            There are no services with an unknown term (nice job!)
                        </div>
                    </div>
                </div>
            </ng-container>


            <!-- Spend by Term Vendor Spend by Location -->

            <ng-container>
                <span class="pdf-spacing">
                    <h4 class="report-heading "><b>Spend by Location </b></h4>
                    <div class="container">
                        <div class="column1">{{tenantReportDetails.addresses.countries.length > 1 ? 'Countries' : '1
                            Country'}} </div>
                        <div class="column2 max-allowed-size">{{tenantReportDetails.addresses.countries.length > 0 ?
                            getSpacingArrayData(tenantReportDetails.addresses.countries) :
                            'There are no countries aligned with your services' }}
                        </div>
                    </div>
                    <hr>
                    <div class="container">
                        <div class="column1">
                            {{tenantReportDetails.addresses.states.length}}
                            {{pluralizeWord("State", tenantReportDetails.addresses.states.length)}}
                        </div>
                        <div class="column2 max-allowed-size">{{tenantReportDetails.addresses.states.length > 0 ?
                            getSpacingArrayData(tenantReportDetails.addresses.states) : 'There are no states aligned
                            with
                            your services'}}</div>
                    </div>
                    <hr>
                    <div class="container">
                        <div class="column1">{{tenantReportDetails.addresses.cities.length > 1 ?
                            tenantReportDetails.addresses.cities.length +
                            ' Cities' : '1 City' }}
                        </div>
                        <div class="column2 max-allowed-size">{{tenantReportDetails.addresses.cities.length > 0 ?
                            getSpacingArrayData(tenantReportDetails.addresses.cities) : 'There are no cities aligned
                            with
                            your services'}}</div>
                    </div>
                </span>
            </ng-container>
            <ng-container>
                <div class="report-note">
                    <div>To see your complete list of addresses, go to <a target="_blank" href="/addresses">
                            {{addressUrl}} </a>
                    </div>
                </div>
            </ng-container>

            <!-- Spend by Term Vendor Spend by Location -->

            <ng-container>
                <span class="pdf-spacing">
                    <h4 class="report-heading "><b>Subscription Services</b></h4>
                    <div class="no-subscription" *ngIf="tenantReportDetails.subscriberValues.subscriberVendorNames.length === 0">
                        No subscription services are being monitored by MiSO3 </div>
                    <div *ngIf="tenantReportDetails.subscriberValues.subscriberVendorNames.length > 0">
                        <div class="container">
                            <div class="column1">{{tenantReportDetails.subscriberValues.subscriberVendorNames.length}}
                                {{pluralizeWord("Vendor",
                                tenantReportDetails.subscriberValues.subscriberVendorNames.length)}}
                                
                                </div>
                            <div class="column2 max-allowed-size">{{tenantReportDetails.subscriberValues.subscriberVendorNames }}</div>
                        </div>
                        <hr>
                        <div class="container">
                            <div class="column1">{{tenantReportDetails.subscriberValues.subscriberProductNames.length}}
                                {{pluralizeWord("Product",
                                tenantReportDetails.subscriberValues.subscriberProductNames.length)}}
                                </div>
                                <div class="column2 max-allowed-size">
                                    {{getSpacingArrayData(tenantReportDetails.subscriberValues.subscriberProductNames)}}
                                </div>
                                </div>
                                <hr>
                        <div class="container">
                            <div class="column1">{{tenantReportDetails.subscriberValues.subscriberNames.length}}
                                {{pluralizeWord("User",
                                tenantReportDetails.subscriberValues.subscriberNames.length)}}
                            </div>
                            <div class="column2 max-allowed-size">
                                {{getSpacingArrayData(tenantReportDetails.subscriberValues.subscriberNames)}}
                            </div>
                        </div>
                        <hr>
                        <div class="container">
                            <div class="column1">
                                {{getPercentage(tenantReportDetails.inactiveUsersValues.inactiveSubscribersCmrcPercentage)}}
                                Unused
                            </div>
                            <div class="column2 max-allowed-size">
                                <b>{{tenantReportDetails.inactiveUsersValues.inactiveSubscribersServiceCount}}</b> {{pluralizeWord("subscription", 4)}}
                                aligned to
                                <b>{{tenantReportDetails.inactiveUsersValues.inactiveSubscriberNames.length}}</b>
                                {{pluralizeWord("user",
                                tenantReportDetails.inactiveUsersValues.inactiveSubscriberNames.length)}} are considered
                                unused
                                due to non-usage. This accounts for
                                <b>{{tenantReportDetails.inactiveUsersValues.inactiveSubscribersAnnualSpend |
                                    tenantcurrency: '0.0-0'}}</b> annually. These subscriptions are aligned to
                                {{getSpacingArrayData(tenantReportDetails.inactiveUsersValues.inactiveSubscriberNames)}}
                            </div>
                        </div>
                    </div>
                </span>
            </ng-container>
        </div>
    </div>
</section>
<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>
<ng-template #dataIntegrityPercentageTempl let-row="row" let-value="value">
    {{ getPercentage(value)}}
</ng-template>
<ng-template #mrcTmpl let-row="row" let-value="value">
    {{ value | round | tenantcurrency: '0.0-0'}}
</ng-template>
<ng-template #content>
    <!-- Your HTML content to be converted to PDF goes here -->
    <h1>Hello, World!</h1>
    <p>This is a sample content to be converted to PDF.</p>
</ng-template>