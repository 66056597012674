import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as psReducer from '../state/reducers';
import * as psActions from '../state/actions';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import {MiParentService} from '../../../../../shared/models/MiParentService';
import {TenantMiServiceService} from '../../../../../libs/api/tenant/tenantMiService.service';
import {MiService} from '../../../../../shared/models/MiService';
import {ActivatedRoute} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../../../shared/logger';
import {ADVANCED_SEARCH_TYPES} from '../../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../../shared/state/advanced-search/actions';
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import {TenantMiParentServiceService} from '../../../../../libs/api/tenant/tenantMiParentService.service';
import {HAS_NO_PARENT} from '../../../../../common/utils/sharedConstants';

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  selector: 'mi-parent-service-add-service',
  templateUrl: './parent-service-add-service.component.html',
  styleUrls: ['../detail-miparent-service.component.scss']
})

export class ParentServiceAddServiceComponent extends AbstractPageComponent implements OnInit {

  error: string;
  loaded: boolean = false;
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  searchLoaded$: Observable<boolean>;

  constructor(
    private store: Store<psReducer.State>,
    private tenantServiceService: TenantMiServiceService,
    private tpsService:TenantMiParentServiceService,
    private activatedRoute: ActivatedRoute,
    private searchStore: Store<ASReducer.State>
  ) {
    super();
  }

  ngOnInit() {

    this.loaded = false;
    this.pageLoaded$ = this.store.select(psReducer.ParentServicePageLoaded);
    this.searchLoaded$ = this.store.select(ASReducer.ASPageLoaded);
    this.searchStore.dispatch(new ASActions.AsReset({
      searchType: ADVANCED_SEARCH_TYPES.MI_SERVICE,
      pageLockFilters: ['vendor_name', 'parent_service_status']
    }));

    this.store
      .select(psReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe((data) => {
        if (data.parentService) {

          let subscribeToSearch: boolean = !this.parentService;

          this.parentService = data.parentService;

          if (subscribeToSearch) {
            this.activatedRoute.queryParams
              .takeUntil(this.destroy$)
              .subscribe((queryParams) => {

                const newParams = {
                  ...queryParams,
                  'vendor_name[]': this.parentService.product.product_vendor.vendor_name,
                  'parent_service_status[]': HAS_NO_PARENT
                };

                this.searchStore.dispatch(new ASActions.AsDeeplinkChanged(newParams));

                this.loaded = true;
              });
          }

        }

      });
  }

  onDeselect(miService: MiService): void {
    this.loaded = false;
    this.tpsService.removeService(this.parentService._id, miService._id)
      .toPromise()
      .then((parentService)=>{
        this.store.dispatch(new psActions.ParentServiceLoadSuccess({
          parentService: parentService
        }));
        this.loaded = true;
      })
      .catch(e=>this.error = e);
  }

}
