import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TenantComponentsModule} from '../../components/tenant-components.module';
import {RouterModule} from '@angular/router';
import {ModalModule} from '@independer/ng-modal';
import {Api2Module} from "../../../libs/api2/api2.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormComponentsModule} from "../../../libs/form-components/form-components.module";
import { TermRenewalRoutes } from './term-renewal.router';
import { VendorRenewalTermComponent } from './vendor-renewal-term/vendor-renewal-term.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AccountEndOfTermsComponent } from './account-end-of-term/account-end-of-term.component';
import { ConfirmEOTDeleteModalComponent } from './confirm-eot-delete-modal/confirm-eot-delete-modal.component';
import { ActionVendorAccountEndOfTermComponent } from './delete-vendor-accounts-end-of-term/delete-vendor-accounts-end-of-term.component';
import { ViewVendorAccountEndOfTermComponent } from './view-vendor-accounts-end-of-term/view-vendor-accounts-end-of-term.component';
import { EditVendorEndOfTermModal } from './edit-vendor-end-of-term-modal/edit-vendor-end-of-term-modal.component';
import { ActionAccountEndOfTermComponent } from './delete-accounts-end-of-term/delete-accounts-end-of-term.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    Api2Module,
    FormComponentsModule,
    CommonModule,
    TranslateModule,
    ModalModule,
    TenantComponentsModule,
    NgSelectModule,
    Ng2SmartTableModule,
    RouterModule.forChild(TermRenewalRoutes)
  ],
  declarations: [
    VendorRenewalTermComponent,
    AccountEndOfTermsComponent,
    ConfirmEOTDeleteModalComponent,
    ActionVendorAccountEndOfTermComponent,
    ActionAccountEndOfTermComponent,
    ViewVendorAccountEndOfTermComponent,
    EditVendorEndOfTermModal
  ],
  providers: [],
  exports: []
})
export class TermRenewalModule {
}
