import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TenantComponentsModule} from "../../../components/tenant-components.module";
import {SharedModule} from "../../../../shared/shared.module";
import {VendorMissingDataByAccountModule} from './missing-data/missing-data-by-account.module';
import {VendorDetailsComponent} from "./detail/vendor-details.component";
import {VendorSearchEventsComponent} from "./search-events/vendor-search-events.component";
import {VendorSearchMiServicesComponent} from "./search-mi-services/vendor-search-mi-services.component";
import {VendorEditComponent} from "./edit/vendor-edit.component";
import { VendorDeleteComponent } from './delete/vendor-delete.component';
import { VendorEquipmentComponent } from './vendor-equipment/vendor-equipment.component';
import { VendorServiceTermComponent } from './detail/sections/vendor-service-term/vendor-service-term.component';
import { VendorHeaderDataComponent } from './header/vendor-header-data/vendor-header-data.component';
import { VendorHeaderLogoComponent } from './header/vendor-header-logo/vendor-header-logo.component';
import { VendorHeaderPortalAndManagerComponent } from './header/vendor-header-portal-and-manager/vendor-header-portal-and-manager.component';
import { VendorHeaderServiceSummaryComponent } from './header/vendor-header-service-summary/vendor-header-service-summary.component';
import { VendorDetailsHeaderItemsComponent } from './header/vendor-details-header-items.component';
import { VendorSearchAccountIdComponent } from './search-account-id/vendor-search-account-id.component';
import { VendorDetailContactComponent } from './vendor-detail-contact/vendor-detail-contact.component';
import { VendorDetailDocumentComponent } from './vendor-detail-document/vendor-detail-document.component';
import { VendorDetailProductComponent } from './vendor-detail-product/vendor-detail-product.component';
import { VendorSearchMiServicesV2Component } from './search-mi-services-v2/vendor-search-mi-services-v2.component';
import { VendorOwnershipComponent } from './vendor-ownership/vendor-ownership.component';


@NgModule({
  declarations: [
    VendorHeaderDataComponent,
    VendorHeaderLogoComponent,
    VendorHeaderPortalAndManagerComponent,
    VendorHeaderServiceSummaryComponent,
    VendorDetailsHeaderItemsComponent,
    VendorDetailsComponent,
    VendorDetailProductComponent,
    VendorSearchEventsComponent,
    VendorSearchMiServicesComponent,
    VendorEditComponent,
    VendorDeleteComponent,
    VendorDetailContactComponent,
    VendorOwnershipComponent,
    VendorDetailDocumentComponent,
    VendorEquipmentComponent,
    VendorServiceTermComponent,
    VendorSearchAccountIdComponent,
    VendorSearchMiServicesV2Component
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    VendorMissingDataByAccountModule,
  ],
  providers: [],
  exports: [
    VendorHeaderDataComponent,
    VendorHeaderLogoComponent,
    VendorHeaderPortalAndManagerComponent,
    VendorHeaderServiceSummaryComponent,
    VendorDetailsHeaderItemsComponent,
    VendorDetailProductComponent,
    VendorDetailsComponent,
    VendorSearchEventsComponent,
    VendorSearchMiServicesComponent,
    VendorEditComponent,
    VendorDeleteComponent,
    VendorDetailContactComponent,
    VendorOwnershipComponent,
    VendorDetailDocumentComponent,
    VendorEquipmentComponent,
    VendorSearchAccountIdComponent,
    VendorSearchMiServicesV2Component
  ]
})
export class VendorInstanceWrapModule {
}
