import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import { AbstractPageComponent } from "../../../shared/pages/abstract-page/abstract-page.component";

@Component({
  selector: 'mi-tenant-contact-search-filters',
  templateUrl: './tenant-contact-search-filters.component.html',
  styleUrls: ['./tenant-contact-search-filters.component.scss']
})

export class TenantContactSearchFiltersComponent extends AbstractPageComponent {

  loading: boolean = true;
  loadError: string;
  pageLockFilters: string[] = [];

  constructor(private store: Store<ASReducer.State>) {
    super();
    this.store
      .select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe((data) => {
        this.pageLockFilters = data.pageLockFilters;
      });

  }
}