import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {SearchFilter} from "../../ng-models/SearchFilter.interface";
import { Contact } from "../../models/Contact";
import { CustomField } from "src/shared/models/CustomField";

@Component({
  selector: 'mi-applied-search-filter-item',
  styleUrls: ['./applied-search-filter-item.component.scss'],
  templateUrl: './applied-search-filter-item.component.html'
})

export class AppliedSearchFilterItemComponent implements OnInit {
  @Input() displayNone: boolean = false;
  @Input() filter: SearchFilter;
  @Input() customField: any = {};
  @Input() businessUnits: any = {};
  @Input() serviceGroupByIDs: any = {};
  @Input() accountIdsByIDs: any = {};
  @Input() contactsByIDs: any = {};
  @Output() remove:EventEmitter<SearchFilter> = new EventEmitter<SearchFilter>();
  isArray: boolean = false;

  constructor() {

  }

  ngOnInit() {
    if(this.filter){
      this.isArray = this.filter.value instanceof Array;
    }
  }

  onRemoveClick(filterValue: any): void {
    this.remove.emit({
      filterName: this.filter.filterName,
      value: filterValue
    });
  }

}
