import {state} from '@angular/animations';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mi-alert-item',
  template: `
    <div class="{{ className }}" role="alert">
      <ng-content></ng-content>
    </div>
  `
})

export class MiAlertItemComponent implements OnInit {

  @Input() alertType: String;
  className = '';

  constructor() {
  }

  ngOnInit() {
    this.className = this.getClassNames();
  }

  getClassNames() {
    // return css class based on alert type
    switch (this.alertType) {
      case 'ERROR':
        return 'mi-alert mi-alert--error';
      case 'INFO':
        return 'mi-alert mi-alert--info';
      case 'SUCCESS':
        return 'mi-alert mi-alert--success';
      case 'QUESTION':
        return 'mi-alert mi-alert--question';
    }
  }
}
