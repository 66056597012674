<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <div>
        <mi-manage-service-contacts
        [hideCancel]="true"
        [account_id]="orderState.account_id"
        [custom_data]="orderState.custom_data"
        [vendorName]="orderState.vendor.vendor_name"
        (serviceContactsComplete)="onContactsSubmit($event)">
      </mi-manage-service-contacts>

    </div>
 
  </ng-container>
  <ng-template #static>
    <dl>
      <dt>
        Contacts
      </dt>
      <dd>
        <mi-service-contacts-static
          [account_id]="orderState.account_id"
          [custom_data]="orderState.custom_data">
        </mi-service-contacts-static>

      </dd>
    </dl>
  </ng-template>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
