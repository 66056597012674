import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VendorHeaderComponent} from './vendor-header/vendor-header.component';
import {InstructionalMesssageComponent} from './instructional-message/instructional-messsage.component';
import {FindOrCreateContactComponent} from './find-or-create-contact/find-or-create-contact.component';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {ChosenContactComponent} from './chosen-contact/chosen-contact.component';
import {AddressFormComponent} from './address-form/address-form.component';
import {LocationFormComponent} from './find-or-create-location/location-form/location-form.component';
import {FindOrCreateLocationComponent} from './find-or-create-location/find-or-create-location.component';
import {AddressDisplayComponent} from './address-display/address-display.component';
import {CustomFieldFormComponent} from './custom-field-form/custom-field-form.component';
import {TenantTermsAndConditionsFormComponent} from '../pages/services/tenant-terms-and-conditions-form/tenant-terms-and-conditions-form.component';
import {FindOrCreateTermsAndConditionsComponent} from './find-or-create-terms-and-conditions/find-or-create-terms-and-conditions.component';
import {FindOrCreateVendorDocumentsComponent} from './find-or-create-vendor-documents/find-or-create-vendor-documents.component';
import {VendorDetailsHeaderComponent} from './vendor-details-header/vendor-details-header.component';
import {DetailsHeaderNavComponent} from './details-header-nav/details-header-nav.component';
import {StandardTenantEventFiltersComponent} from './standard-tenant-event-filters/standard-tenant-event-filters.component';
import {TenantEquipmentSearchFiltersComponent} from './tenant-equipment-search-filters/tenant-equipment-search-filters.component';
import {TenantVendorSearchFiltersComponent} from './tenant-vendor-search-filters/tenant-vendor-search-filters.component';
import {TenantAddressSearchFiltersComponent} from './tenant-address-search-filters/tenant-address-search-filters.component';
import {LocationsEditFormComponent} from './locations-edit-form/locations-edit-form.component';
import {VendorsEditFormComponent} from './vendors-edit-form/vendors-edit-form.component';
import {AccountIdEditFormComponent} from './accountId-edit-form/accountId-edit-form.component';
import {DetailSearchComponent} from './detail-search/detail-search.component';
import {DashboardDataIntegrityComponent} from './dashboard-data-integrity/dashboard-data-integrity.component';
import {EmailComposerComponent} from './email-composer/email-composer.component';
import {EmailRecipientSearchComponent} from './email-recipients/email-recipient-search/email-recipient-search.component';
import {EmailRecipientSelectComponent} from './email-recipients/email-recipient-select/email-recipient-select.component';
import {EmailRecipientComponent} from './email-recipients/email-recipient/email-recipient.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {miTinymceDefaultSettings} from '../../shared/utils/miTinymceDefaultSettings';
import {TinyMceModule} from 'angular-tinymce';
import {ServiceFinancialFormComponent} from './service-financial-form/service-financial-form.component';
import {CustomFieldServiceFormComponent} from './custom-field-service-form/custom-field-service-form.component';
import {CustomFieldServiceStaticComponent} from './custom-field-service-static/custom-field-service-static.component';
// import {SimpleLocationComponent} from './simple-location/simple-location.component';
import {SelectContactModalComponent} from './select-contact-modal/select-contact-modal.component';
import {ModalModule} from '@independer/ng-modal';
import {EmailServiceRepModalComponent} from './email-service-rep-modal/email-service-rep-modal.component';
import {SelectServiceEquipments} from './select-service-equipments/select-service-equipments.component';
import {SelectEquipmentModalComponent} from './select-equipment-modal/select-equipment-modal.component';
import {FindOrCreateEquipmentComponent} from './find-or-create-equipment/find-or-create-equipment.component';
import {EquipmentsServiceStaticComponent} from './equipments-service-static/equipments-service-static.component';
import {EquipmentsServiceFormComponent} from './equipments-service-form/equipments-service-form.component';
import {ServiceIdFormComponent} from './service-id-form/service-id-form.component';
import {AccountIdRepCommponent} from './account-id-rep/account-id-rep.component';
import {ServiceContactsStaticComponent} from './service-contacts-static/service-contacts-static.component';
import {ManageServiceContactsComponent} from './manage-service-contacts/manage-service-contacts.component';
import {ServiceRequestorCommponent} from './service-requester/service-requester.component';
import {EquipmentFormComponent} from './equipment-form/equipment-form.component';
import {ParentServiceAddedServiceComponent} from './parent-service-added-service/parent-service-added-service.component';
import {ServiceGroupsEditFormComponent} from './service-groups-edit-form/service-groups-edit-form.component';
import {TenantServiceGroupSearchFiltersComponent} from './tenant-service-group-search-filters/tenant-service-group-search-filters.component';
import {FindOrCreateServiceGroupsComponent} from './find-or-create-service-groups/find-or-create-service-groups.component';
import {SelectServiceGroupModalComponent} from './select-service-group-modal/select-service-group-modal.component';
import {ServiceGroupServiceFormComponent} from './service-group-service-form/service-group-service-form.component';
import {SelectServiceGroup} from './select-service-group/select-service-group.component';
import {DetailAlertComponent} from './detail-alert/detail-alert.component';
import {SelectAllOrOneVendorComponent} from './select-all-or-one-vendor/select-all-or-one-vendor.component';
import {ApiConnectorTileComponent} from './api-connector-tile/api-connector-tile.component';
import {DataConnectorFormComponent} from './data-connector-form/data-connector-form.component';
import {NgxSmoothDnDModule} from 'ngx-smooth-dnd';
import {OrderableSelectComponent} from './orderable-select/orderable-select.component';
import { BulkActionButtonComponent } from './bulk-action-button/bulk-action-button.component';
import { FindOrCreateProductModalComponent } from './find-or-create-product-modal/find-or-create-product-modal.component';
import {FindOrCreateAccountIdModalComponent} from './find-or-create-account-id-modal/find-or-create-account-id-modal.component';
import { FindOrCreateCategoryComponent } from './find-or-create-category/find-or-create-category.component';
import { FindOrCreateCategoryrModalComponent } from './find-or-create-category-modal/find-or-create-category-modal.component';
import { TenantCategoryFormComponent } from './category-from/find-or-create-category.component';
import { FindOrCreateLocationrModalComponent } from './find-or-create-location-modal/find-or-create-location-modal.component';
import { FindOrCreateParentServiceComponent } from './find-or-create-parent-service/find-or-create-parent-service.component';
import { FindOrCreateParentServiceModalComponent } from './find-or-create-parent-service-modal/find-or-create-parent-service-modal.component';
import { ParentServicesFormComponent } from './parent-service-form/parent-service-form.component';
import {UploadUsersModalComponent} from './upload-users-modal/upload-users-modal.component';
import {DivisionUsersManagementModalComponent} from './division-users-management-modal/division-users-management-modal.component';
import {UsersV2ListComponent} from './users-v2-list/users-v2-list.component';
import {SelectServiceOwnershipComponent} from './select-service-ownership/select-service-ownership.component';
import {FormComponentsModule} from '../../libs/form-components/form-components.module';
import {SelectServiceOwnershipModalComponent} from './select-service-ownership-modal/select-service-ownership-modal.component';
import { ServiceTermFormComponent } from './find-or-create-service-term/service-term-form/service-term-form.component';
import { FindOrCreateServiceTermComponent } from './find-or-create-service-term/find-or-create-service-term.component';
import { FindOrCreateServiceTermrModalComponent } from './find-or-create-service-term-modal/find-or-create-service-term-modal.component';
import { SectionDataAccountIdComponent } from './section-data-account-id/section-data-account-id.component';
import { FindOrCreateTenantUserComponent } from './find-or-create-tenant-user/find-or-create-tenant-user.component';
import { ServiceOwnershipStaticComponent } from './service-ownership-static/service-ownership-static.component';
import { SelectEmployeeModalComponent } from './select-employee-modal/select-employee-modal.component';
import { SelectRequestorCommponent } from './select-requester/select-requester.component';
import { StandardTenantSearchFiltersV1Component } from './standard-tenant-search-filters-v1/standard-tenant-search-filters.component';
import { VendorReportFormComponent } from './vendor-report-form/vendor-report-form.component';
import { ServiceParentStaticComponent } from './service-parent-static/service-parent-static.component';
import { SelectChildrenServiceModalComponent } from './select-children-service-modal/select-children-service-modal.component';
import { ConfirmRelationshipLocationRemovalModalComponent } from './confirm-relationship-removal-address-modal/confirm-relationship-removal-address-modal.component';
import { ConfirmDeleteVendorTermsModalComponent } from './confirm-delete-vendor-term-modal/confirm-delete-vendor-term-modal.component';
import { BulkIgnoreAddressModalComponent } from './bulk-ignore-modal/bulk-ignore-modal.component';

@NgModule({
  declarations: [
    ServiceIdFormComponent,
    EmailServiceRepModalComponent,
    SelectContactModalComponent,
    SelectEquipmentModalComponent,
    SelectServiceEquipments,
    SelectAllOrOneVendorComponent,
    FindOrCreateEquipmentComponent,
    InstructionalMesssageComponent,
    VendorHeaderComponent,
    FindOrCreateContactComponent,
    ServiceFinancialFormComponent,
    ContactFormComponent,
    ChosenContactComponent,
    LocationFormComponent,
    ServiceParentStaticComponent,
    FindOrCreateLocationComponent,
    AddressFormComponent,
    AddressDisplayComponent,
    CustomFieldFormComponent,
    CustomFieldServiceFormComponent,
    CustomFieldServiceStaticComponent,
    TenantTermsAndConditionsFormComponent,
    FindOrCreateTermsAndConditionsComponent,
    FindOrCreateVendorDocumentsComponent,
    VendorDetailsHeaderComponent,
    DetailsHeaderNavComponent,
    StandardTenantEventFiltersComponent,
    StandardTenantSearchFiltersV1Component,
    TenantEquipmentSearchFiltersComponent,
    TenantVendorSearchFiltersComponent,
    TenantAddressSearchFiltersComponent,
    TenantServiceGroupSearchFiltersComponent,
    LocationsEditFormComponent,
    VendorReportFormComponent,
    VendorsEditFormComponent,
    AccountIdEditFormComponent,
    DetailSearchComponent,
    DashboardDataIntegrityComponent,
    EmailComposerComponent,
    EmailRecipientSelectComponent,
    EmailRecipientComponent,
    EmailRecipientSearchComponent,
    EquipmentsServiceStaticComponent,
    EquipmentsServiceFormComponent,
    AccountIdRepCommponent,
    ServiceContactsStaticComponent,
    ManageServiceContactsComponent,
    ServiceRequestorCommponent,
    EquipmentFormComponent,
    ParentServiceAddedServiceComponent,
    ServiceGroupsEditFormComponent,
    FindOrCreateServiceGroupsComponent,
    SelectServiceGroupModalComponent,
    ServiceGroupServiceFormComponent,
    SelectServiceGroup,
    DetailAlertComponent,
    ApiConnectorTileComponent,
    DataConnectorFormComponent,
    OrderableSelectComponent,
    BulkActionButtonComponent,
    UploadUsersModalComponent,
    DivisionUsersManagementModalComponent,
    FindOrCreateProductModalComponent,
    FindOrCreateAccountIdModalComponent,
    FindOrCreateCategoryComponent,
    FindOrCreateCategoryrModalComponent,
    TenantCategoryFormComponent,
    FindOrCreateLocationrModalComponent,
    BulkIgnoreAddressModalComponent,
    ConfirmRelationshipLocationRemovalModalComponent,
    ConfirmDeleteVendorTermsModalComponent,
    FindOrCreateParentServiceComponent,
    FindOrCreateParentServiceModalComponent,
    ParentServicesFormComponent,
    UsersV2ListComponent,
    SelectServiceOwnershipComponent,
    SelectServiceOwnershipModalComponent,
    ServiceTermFormComponent,
    FindOrCreateServiceTermComponent,
    FindOrCreateServiceTermrModalComponent,
    FindOrCreateTenantUserComponent,
    ServiceOwnershipStaticComponent,
    SelectEmployeeModalComponent,
    SelectChildrenServiceModalComponent,
    SelectRequestorCommponent,
    SectionDataAccountIdComponent
  ],
  imports: [
      ModalModule,
      FormsModule,
      NgSelectModule,
      ReactiveFormsModule,
      RouterModule,
      SharedModule,
      CommonModule,
      TranslateModule,
      TinyMceModule.forRoot(miTinymceDefaultSettings()),
      NgxSmoothDnDModule,
      FormComponentsModule,
      // BusinessUnitsModule,
  ],
  providers: [],
  exports: [
    ServiceIdFormComponent,
    UploadUsersModalComponent,
    DivisionUsersManagementModalComponent,
    EmailServiceRepModalComponent,
    SelectContactModalComponent,
    SelectEquipmentModalComponent,
    SelectServiceEquipments,
    SelectAllOrOneVendorComponent,
    FindOrCreateEquipmentComponent,
    InstructionalMesssageComponent,
    VendorHeaderComponent,
    FindOrCreateContactComponent,
    ServiceFinancialFormComponent,
    ContactFormComponent,
    ChosenContactComponent,
    LocationFormComponent,
    ServiceParentStaticComponent,
    FindOrCreateLocationComponent,
    AddressFormComponent,
    AddressDisplayComponent,
    CustomFieldFormComponent,
    CustomFieldServiceFormComponent,
    CustomFieldServiceStaticComponent,
    TenantTermsAndConditionsFormComponent,
    FindOrCreateTermsAndConditionsComponent,
    FindOrCreateVendorDocumentsComponent,
    VendorDetailsHeaderComponent,
    DetailsHeaderNavComponent,
    StandardTenantEventFiltersComponent,
    StandardTenantSearchFiltersV1Component,
    TenantEquipmentSearchFiltersComponent,
    TenantVendorSearchFiltersComponent,
    TenantAddressSearchFiltersComponent,
    TenantServiceGroupSearchFiltersComponent,
    LocationsEditFormComponent,
    VendorReportFormComponent,
    VendorsEditFormComponent,
    AccountIdEditFormComponent,
    DetailSearchComponent,
    DashboardDataIntegrityComponent,
    // SimpleLocationComponent,
    EmailComposerComponent,
    EmailRecipientSelectComponent,
    EmailRecipientComponent,
    EmailRecipientSearchComponent,
    EquipmentsServiceStaticComponent,
    EquipmentsServiceFormComponent,
    AccountIdRepCommponent,
    ServiceContactsStaticComponent,
    ManageServiceContactsComponent,
    ServiceRequestorCommponent,
    EquipmentFormComponent,
    ParentServiceAddedServiceComponent,
    ServiceGroupsEditFormComponent,
    FindOrCreateServiceGroupsComponent,
    SelectServiceGroupModalComponent,
    ServiceGroupServiceFormComponent,
    SelectServiceGroup,
    DetailAlertComponent,
    ApiConnectorTileComponent,
    DataConnectorFormComponent,
    BulkActionButtonComponent,
    FindOrCreateProductModalComponent,
    FindOrCreateAccountIdModalComponent,
    FindOrCreateCategoryComponent,
    FindOrCreateCategoryrModalComponent,
    TenantCategoryFormComponent,
    FindOrCreateLocationrModalComponent,
    BulkIgnoreAddressModalComponent,
    ConfirmRelationshipLocationRemovalModalComponent,
    ConfirmDeleteVendorTermsModalComponent,
    FindOrCreateParentServiceComponent,
    FindOrCreateParentServiceModalComponent,
    ParentServicesFormComponent,
    UsersV2ListComponent,
    SelectServiceOwnershipComponent,
    SelectServiceOwnershipModalComponent,
    ServiceTermFormComponent,
    FindOrCreateServiceTermComponent,
    FindOrCreateServiceTermrModalComponent,
    FindOrCreateTenantUserComponent,
    ServiceOwnershipStaticComponent,
    SelectEmployeeModalComponent,
    SelectChildrenServiceModalComponent,
    SelectRequestorCommponent,
    SectionDataAccountIdComponent
  ]
})

export class TenantComponentsModule {
}
