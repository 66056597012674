import {
    createFeatureSelector,
} from '@ngrx/store';
import {OrderActions} from './actions';
import {Vendor} from '../../../../../../shared/models/Vendor';
import {Contact} from '../../../../../../shared/models/Contact';
import {AssociatedDocument} from '../../../../../../shared/models/AssociatedDocument';
import {AccountId} from '../../../../../../shared/models/AccountId';
import {MiOrderService} from '../../../../../../shared/models/MiOrderService';
import { CustomFieldProperty } from '../../../../../../shared/models/CustomFieldProperty';
import { Document } from 'src/libs/api2/types/Document.interface';

export enum OrderSteps {
    AccountID = 'account-id',
    Contacts = 'contacts',
    OrderDate = 'order-date',
    OrderID = 'order-id',
    Uploads = 'uploads',
    Vendor = 'vendor',
    AddOrder = 'add-order',
    // Service-Specifics:
    Product = 'product',
    Dates = 'dates',
    Cost = 'cost',
    ServiceIdentifier = 'service-identifier',
    Locations = 'locations',
    CustomData = 'custom-data',
    ServiceDocs = 'service-documents',
    TermsAndConditions = 'terms-and-conditions',
    AssignedTo = 'assigned-to',
    ServiceContacts = 'service-contacts',
    AddService = 'AddService'
}

export const OrderProgression: string[] = [
    OrderSteps.Vendor,
    OrderSteps.OrderID,
    OrderSteps.OrderDate,
    OrderSteps.AccountID,
    OrderSteps.Contacts,
    OrderSteps.Uploads,
    OrderSteps.AddOrder,
    // Service-Specific:
    OrderSteps.Product,
    OrderSteps.Dates,
    OrderSteps.Cost,
    OrderSteps.ServiceIdentifier,
    OrderSteps.Locations,
    OrderSteps.CustomData,
    OrderSteps.ServiceDocs,
    OrderSteps.TermsAndConditions,
    OrderSteps.AssignedTo,
    OrderSteps.ServiceContacts,
    OrderSteps.AddService
];

export interface OrderState {
    _id?: string;
    account_id?: AccountId;
    associated_documents: Document[];
    display_id?: string;
    index: number;
    maxIndex: number;
    order_date?: string;
    order_id?: string;
    service?: MiOrderService;
    vendor?: Vendor;
    requestor?: Contact;
    custom_data: CustomFieldProperty[];
}

const initialState: OrderState = {
    account_id: null,
    associated_documents: [],
    index: 0,
    maxIndex: 0,
    order_date: null,
    order_id: null,
    vendor: null,
    custom_data: [],
    service: <MiOrderService>{
        locations: [],
        custom_data: [],
        associated_documents: []
    },
};

export function reducer(state: OrderState = initialState, action): OrderState {
    switch (action.type) {
        case OrderActions.ResetOrderAction:
            return JSON.parse(JSON.stringify(initialState));
        case OrderActions.UpdateOrderAction:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export const getOrderData = createFeatureSelector<OrderState>('orders');

