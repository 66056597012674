<modal>
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <p>
      {{message}}
    </p>
    </modal-content>
    <modal-footer>
    <div class="button-container">
      <a mi-button buttonTheme="light-theme" (click)="onCancel()">
        {{noButtonLabel}}
      </a>
      <a mi-button buttonTheme="dark-theme" (click)="onYes()">
        {{yesButtonLabel}}
      </a>
    </div>
  </modal-footer>
</modal>
