import {
    ActionReducer,
    MetaReducer,
} from '@ngrx/store';

import { environment } from '../../environments/environment';

import { LOG_LEVELS, createLogger} from '../../shared/logger';
const log = createLogger(LOG_LEVELS.REDUX_DEBUG);

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
// import { storeFreeze } from 'ngrx-store-freeze';

// export const reducers = fromUser.reducer;

// console.log all actions
export function logger(reducer: ActionReducer<any, any>): ActionReducer<any, any> {
    return function(state: any, action: any): any {
      log(action.type);
      //log('action', action, 'state', state);
      return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: any = !environment.production ? [logger] : [logger];
// ? [logger, storeFreeze]

