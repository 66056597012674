import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import {MiService} from '../../../../shared/models/MiService';
import {AccountId} from '../../../../shared/models/AccountId';
import {Observable} from 'rxjs';
import {SERVICE_CURRENT, SERVICE_ARCHIVED} from '../../../../../src/common/utils/sharedConstants';
import {TenantMiParentServiceService} from '../../../../libs/api/tenant/tenantMiParentService.service';
import {MiParentService} from '../../../../shared/models/MiParentService';
import * as psdReducer from './state/reducers';
import * as psdActions from './state/actions';
import {Store} from '@ngrx/store';
import * as asReducer from '../../../../shared/state/advanced-search/reducers';
import {LoadParentServiceEmailsAction} from '../../../../shared/state/email-list/actions';
import {MiServiceGroup} from '../../../../shared/models/MiServiceGroup';

@Component({
  templateUrl: './detail-miparent-service.component.html'
})

export class ParentServiceDetailPageComponent extends AbstractPageComponent implements OnInit {

  account_id: AccountId;
  breadcrumbs: Link[];
  customFields: any[];
  error: string = '';
  loaded: Boolean = false;
  miService: MiService;
  modalId: string;
  NO_DATA: string = NO_DATA;
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  parentServiceId: string = '';
  serviceGroups: MiServiceGroup[];
  title: string;

  readonly SERVICE_STATUS: any = {
    SERVICE_CURRENT: SERVICE_CURRENT,
    SERVICE_ARCHIVED: SERVICE_ARCHIVED
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private searchStore: Store<asReducer.State>,
    private tpsService: TenantMiParentServiceService,
  ) {
    super();
  }

  ngOnInit() {

    // Retrieve the display ID from the route:
    this.parentServiceId = this.activatedRoute.snapshot.params.display_id || null;

    // Re-sets the current Parent Service Store:
    this.store.dispatch(new psdActions.ParentServiceReset({}));

    this.pageLoaded$ = this.store.select(psdReducer.ParentServicePageLoaded)
      .takeUntil(this.destroy$);

    this.store.select(psdReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe((data) => {

        if (data.parentService) {
          this.parentService = data.parentService;
          this.setBreadCrumbs(this.parentService.display_id);
          // load the Emails
          this.store.dispatch(
            new LoadParentServiceEmailsAction({
              display_id: this.parentService.display_id
            })
          );
        } else {
          this.loadServicePageData(this.parentServiceId);
        }

      });
  }

  loadServicePageData(display_id: string): void {
    this.tpsService.getByDisplayId(this.parentServiceId)
      .toPromise()
      .then((parentService: MiParentService) => {
        this.store.dispatch(new psdActions.ParentServiceLoadSuccess({
          parentService: parentService,
          pageLoaded: true,
          update: false
        }));
      })
      .catch((e) => {
        this.error = e;
        this.loaded = true;
      });
  }

  setBreadCrumbs(display_id: string): void {
    this.breadcrumbs = [{
      url: '',
      label: 'Dashboard',
      internal: true
    }, {
      url: '/services',
      label: 'Services',
      internal: true
    }, {
      url: `/parent-services/${display_id}`,
      label: `Parent Service ${display_id}`,
      internal: true
    }];
  }

}
