import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiService, MiServiceDetails, ServiceCalculationDetails } from '../../../../../../shared/models/MiService';
import { NO_DATA } from '../../../../../../shared/ng-models/SectionDataTable.model';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import * as SDReducer from '../../state/reducers';
import { AbstractPageComponent } from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { integerValidator, numberValidator } from '../../../../../../shared/shared.validators';
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import { ToastrService } from 'ngx-toastr';
import * as appDataReducer from '../../../../../../shared/state/app-data/reducers';
import FeDateUtil from 'src/shared/utils/FeDate.util';

@Component(
  {
    selector: 'mi-subsection-terms-details',
    templateUrl: './subsection-terms-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionTermsDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: Boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiService> = new EventEmitter<MiService>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  error: string = '';
  isOpen: boolean = false;
  current_end_of_term: string = '';
  readonly NO_DATA: string = NO_DATA;
  serviceDetails: MiServiceDetails;
  serviceCalculationDetails: ServiceCalculationDetails
  pageLoaded$: Observable<boolean>;
  formGroup_service_dates: FormGroup;
  status: string;
  updating: boolean = false;
  timeZone: string = "America/New_York"
  formattedEndDate: string
  formattedStartDate: string
  readonly DONT_KNOW: string = "don't know";
  readonly START_AND_TERM: string = "start and term";
  readonly END_ONLY: string = "end only";

  constructor(
    private serviceV2Service: ServiceV2Service,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    // Get tenant timezone
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    tenantStateData.first()
      .subscribe(data => {
        if (data && data.tenantData && data.tenantData.timezone) {
          this.timeZone = data.tenantData.timezone;
        }
      })

    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {

          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
            console.log("here  this.serviceDetails.dates_and_locations.initial_end_date ",  this.serviceDetails.dates_and_locations.initial_end_date )
            this.formattedEndDate = this.serviceDetails.dates_and_locations.initial_end_date != null ? this.changeTimezone(this.convertTimeZone(this.serviceDetails.dates_and_locations.initial_end_date)) : null
            this.formattedStartDate = this.serviceDetails.dates_and_locations.initial_start_date != null ? this.changeTimezone(this.convertTimeZone(this.serviceDetails.dates_and_locations.initial_start_date)) : null
            this.formGroup_service_dates = this.formBuilder.group({
              end_date: [this.formattedEndDate],
              start_date: [this.formattedStartDate],
              term_length: [this.serviceDetails.dates_and_locations.initial_term_length]
            });
            if (data.serviceCalculationDetails) {
              this.serviceCalculationDetails = data.serviceCalculationDetails
            }
            this.calculateFormState();
          }
        });
  }

  convertTimeZone(time) {
    return FeDateUtil.applyTimeZoneFromISO(time, this.timeZone)
  } 

  changeTimezone(dateDetails) {
    console.log("here changeTimezone ",  dateDetails )

    let date = new Date(dateDetails.ts);
    const originalDate = new Date(date);

    // Extract the required components
    const month = originalDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 for January)
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();
    const hours = originalDate.getHours();
    const minutes = originalDate.getMinutes();
    const seconds = originalDate.getSeconds();

    // Format the date in MM/DD/YYYY HH:mm:ss format
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    console.log("here formattedDate", formattedDate);

    return formattedDate
  }

  onCancel() {
    this.calculateFormState();
    this.updateForms();
    this.isOpen = false;
  }

  onDateChange(newDate: string) {
    if (this.status === this.START_AND_TERM) {
      this.formGroup_service_dates.get('start_date').setValue(newDate);
      this.formGroup_service_dates.get('end_date').setValue(null);
      this.formGroup_service_dates.updateValueAndValidity();
    } else {
      this.formGroup_service_dates.get('end_date').setValue(newDate);
      this.formGroup_service_dates.updateValueAndValidity();
    }
  }

  onRadioChanged(e: any): void {
    this.status = e.target.value;
    //reset form
    this.formGroup_service_dates.get('end_date').setValue(null);
    this.formGroup_service_dates.get('start_date').setValue(null);
    this.formGroup_service_dates.get('term_length').setValue(null);
    //reset validator as per selection
    this.applyValidator();
  }

  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

  updateData(data: any): void {
    this.updating = true;
    this.error = '';
    this.serviceV2Service.updateServiceDates(data, this.serviceDetails.service_data.service_id)
      .toPromise()
      .then(res => {
        this.updating = false;
        this.toastr.success(`Service dates updated successfully`);
        this.onToggleExpand();
        this.updateForms();
        this.dataUpdate.emit(res);
        this.updateServiceDetailsStore.emit();
      })
      .catch(e => {
        this.updating = false;
        this.toastr.error(`Facing issue while updating service dates, Please try again`);
        this.error = e;
      });
  }

  updateForms() {
    // Cycle through the three forms and re-set their data accordingly.
    // NOTE: The first group shouldn't ever change and not need updating.

    //fill form
    this.formGroup_service_dates.get('start_date').setValue(this.serviceDetails.dates_and_locations.initial_start_date);
    this.formGroup_service_dates.get('term_length').setValue(this.serviceDetails.dates_and_locations.initial_term_length);
    this.formGroup_service_dates.get('end_date').setValue(this.serviceDetails.dates_and_locations.initial_end_date);
    //reset validator as per selection this.status
    this.applyValidator();
  }

  //this will populate this.status for cancel to reset selection also
  calculateFormState() {
    if (this.serviceDetails.dates_and_locations.initial_start_date && this.serviceDetails.dates_and_locations.initial_term_length) {
      this.status = this.START_AND_TERM;
    } else if (this.serviceDetails.dates_and_locations.initial_end_date) {
      this.status = this.END_ONLY;
    } else {
      this.status = this.DONT_KNOW;
    }
  }

  //validator based on selection
  applyValidator() {

    const endDateControl: AbstractControl = this.formGroup_service_dates.get('end_date');
    const startDateControl: AbstractControl = this.formGroup_service_dates.get('start_date');
    const termLengthControl: AbstractControl = this.formGroup_service_dates.get('term_length');

    endDateControl.markAsUntouched();
    startDateControl.markAsUntouched();
    termLengthControl.markAsUntouched();

    this.formGroup_service_dates.clearValidators();

    switch (this.status) {
      case this.END_ONLY:
        endDateControl.setValidators([Validators.required]);
        endDateControl.updateValueAndValidity();

        startDateControl.setValidators(null);
        startDateControl.updateValueAndValidity();

        termLengthControl.setValidators(null);
        termLengthControl.updateValueAndValidity();

        break;

      case this.DONT_KNOW:

        endDateControl.setValidators(null);
        endDateControl.updateValueAndValidity();

        startDateControl.setValidators(null);
        startDateControl.updateValueAndValidity();

        termLengthControl.setValidators(null);
        termLengthControl.updateValueAndValidity();

        break;
      case this.START_AND_TERM:
      default:

        endDateControl.setValidators(null);
        endDateControl.updateValueAndValidity();

        startDateControl.setValidators([Validators.required]);
        startDateControl.updateValueAndValidity();

        termLengthControl.setValidators([Validators.required, Validators.min(1), integerValidator, numberValidator]);
        termLengthControl.updateValueAndValidity();

    }

  }
  getNotSureLabel(): string {
    return this.serviceDetails.dates_and_locations.initial_end_date ||
           this.serviceDetails.dates_and_locations.initial_term_length ||
           this.serviceDetails.dates_and_locations.initial_start_date ? 'Unknown' : 'Not sure';
  }
}