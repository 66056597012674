import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as Chart from 'chart.js';
import {Router} from '@angular/router';
import { ChartData } from 'src/shared/ng-models/ChartData.interface';

@Component({
  selector: 'mi-chart',
  styleUrls: ['./chart.component.scss'],
  templateUrl: './chart.component.html'
})

export class ChartComponent implements OnInit {

  @Input() data: any;
  @Input() chartType: string = 'line';
  @Input() title: string;
  @Input() tooltipSuffix: string = "";
  @ViewChild('canvas', {static: true}) canvas: ElementRef;
  renderChart: boolean = true;
  chart: any;

  constructor(private router: Router) {
    // NOTHING! Nada! Nil! Zip!
  }

  ngOnInit() {

    let ctx: CanvasRenderingContext2D = this.canvas.nativeElement.getContext('2d');

    let colors: string[] = ["#2880D0", "#0090C9", "#33A6D4", "#99D3E9", "#CCE9F4", "#E5F3F9", "#f2f9fc", "#788088", "#DCDBDC", "#ededed", "#182028", "#D0021B", "#F8DBE1", "#F5A623", "#FDEDD3", "#396A0C", "#249518", "#76B333", "#76B333"];

    this.chart = new Chart(ctx, { 
      type: this.chartType,
      data: {
        labels: this.data.labels,
        datasets: this.data.values.map((node, index) => {
          return {
            data: node.values,
            label: node.type,
            backgroundColor: this.chartType==="line" ? "rgba(0, 0, 0, 0)": colors,
            borderColor:  this.chartType==="line" ? '#0091D5': "rgba(0, 0, 0, 0)",
          };
        })
      },
      options: {
        legend: {
          labels: {
            fontFamily: 'Roboto Condensed',
            fontStyle: 'bold'
          },
          onHover: function (e) {
            e.target.style.cursor = 'pointer';
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          callbacks: {
            label: (value, data) => {
              return data.labels[value.index] + ": " + data.datasets[value.datasetIndex].data[value.index] + this.tooltipSuffix;
            }
          }
        }
      }
    });

  }

}
