<div class="filter-group" *ngIf="topLevelFilters.length && isVisible">
    <h2 *ngIf="title">{{title}}</h2>
  
    <ul>
      <span *ngIf="topLevelFilters.length && isVisible && selectedFilters.length === 0">
      <li *ngFor="let filter of topLevelFilters" class="filter-item">
  
        <label *ngIf= "filter.key">
  
          <input type="checkbox"
                 [checked]="filter.selected"
                 (change)="toggle($event, filter)"/>
  
          <div>
            <span *ngIf="translatePrefix; else textBlock">{{ translatePrefix + filter.key | translate}}</span>
            <ng-template #textBlock>{{filter.label? filter.label : filter.key}}</ng-template>
            <span *ngIf="includeCount">({{filter.doc_count}})</span>
          </div>
  
        </label>
  
      </li>
      </span>

      <span *ngIf="selectedFilters.length && isVisible">
        <ng-container *ngFor="let filter of selectedFilters">
          <li class="filter-item">
  
            <label *ngIf= "filter.key">
      
              <input type="checkbox"
                      [checked]="filter.selected"
                      (change)="toggle($event, filter)"/>
      
              <div>
                <span *ngIf="translatePrefix; else textBlock">{{ translatePrefix + filter.key | translate}}</span>
                <ng-template #textBlock>{{filter.label? filter.label : filter.key}}</ng-template>
                <span *ngIf="includeCount">({{filter.doc_count}})</span>
              </div>
      
            </label>

          </li>

          <ng-container *ngFor="let subfilter of toArray(filter.children)">
            <li class="filter-item sub-filter">
    
              <label *ngIf= "subfilter.key && selectedFilters.length===1" [style.margin-left]="'10px'">
        
                <input type="checkbox"
                        [checked]="subfilter.selected"
                        (change)="toggle($event, subfilter)"/>
        
                <div>
                  <span *ngIf="translatePrefix; else textBlock">{{ translatePrefix + subfilter.key | translate}}</span>
                  <ng-template #textBlock>{{subfilter.label? subfilter.label : subfilter.key}}</ng-template>
                  <span *ngIf="includeCount">({{subfilter.doc_count}})</span>
                </div>
        
              </label>
        
            </li>
          </ng-container>
        </ng-container>
      </span>

      <!-- <li *ngIf="showAll"><a class="showAll" (click)="onShowAllClicked()">Show All</a></li> -->
    </ul>
  </div>