import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Tenant} from '../../shared/models/Tenant';

@Injectable()
export class HelloWorldService {

  private hello = '/';

  constructor(private http: HttpClient) {
  }

  getHelloWorld(): Observable<Tenant> {
    return this.http
      .get<Tenant>(this.hello)
      .map((response: any) => response.data);
  }
}

