<div>
    <mi-page-header
      [title]="title"
      [breadcrumbs]="breadcrumbs"
      [links]="headerLinks">
    </mi-page-header> 
    <ng-container *ngIf="loaded; else loading">

      <mi-account-id-rep
                          [service]="service"
                          (complete)="next($event)"
                          cancelRoute="../../">
      </mi-account-id-rep>
      
    </ng-container>

    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  
  </div>
  