import {
  Component,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { Vendor } from '../../../models/Vendor';

@Component({
  selector: 'product-vendor-header',
  styleUrls:['./product-vendor-header.component.scss'],
  templateUrl: './product-vendor-header.component.html'
})

export class ProductVendorHeaderComponent {
  @Input() vendor: Vendor;
  @Input() theme: string = '';
  @Input() locked: boolean = false;
  @Output() onUnSelectVendor: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(){
  }

  unSelectVendor(){
    this.onUnSelectVendor.emit(true);
  }

}
