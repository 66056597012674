import {Component, Input, OnInit} from '@angular/core';
import {MisoActivity} from '../../models/MisoActivity.interface';
import {AUTH} from '../../../common/security/secureUser';
import {
  EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_RE_TERM
} from '../../../common/utils/sharedConstants';
import * as moment from 'moment';

@Component({
  selector: 'mi-event-item',
  styleUrls: ['./event-item.component.scss'],
  templateUrl: './event-item.component.html'
})

export class EventItemComponent implements OnInit {

  readonly EVENT_ACTIVATION = EVENT_TYPE_ACTIVATION;
  readonly EVENT_AUTO_CANCEL = EVENT_TYPE_AUTO_CANCELLATION;
  readonly EVENT_MANUAL_CANCEL = EVENT_TYPE_CANCELLATION;
  readonly EVENT_AUTO_RENEW = EVENT_TYPE_AUTO_RENEW;
  readonly EVENT_RE_TERM = EVENT_TYPE_RE_TERM;
  readonly EVENT_SERVICE_START = 'SERVICE_START';

  @Input() event: MisoActivity;
  @Input() hideSavings: boolean = false;

  details: string;
  showDetails: boolean = false;
  relativeTime: string;

  constructor() {

  }

  ngOnInit() {
    this.relativeTime = moment(this.event.end_date).fromNow();
    /* TODO: Create logic to show Details when needed. */
  }
}
