import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { Store} from '@ngrx/store';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import {
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_AUTO_CANCELLATION} from '../../../common/utils/sharedConstants';
import { SortableTableColumn } from 'src/shared/ng-models/SortableTableColumn.interface';
import { FormGroup, FormBuilder } from '@angular/forms';
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";
import { ModalService } from "@independer/ng-modal";
import { ConfirmDeleteVendorTermsModalComponent } from '../confirm-delete-vendor-term-modal/confirm-delete-vendor-term-modal.component';
import { MiServiceDetails } from 'src/shared/models/MiService';


export enum FindOrCreateComponentSteps {
  CREATE_STATE,
  SEARCH_STATE
}

export enum ServiceTermEventType {
  EVENT_TYPE_AUTO_RENEW = "EVENT_TYPE_AUTO_RENEW",
  EVENT_TYPE_AUTO_CANCELLATION = "EVENT_TYPE_AUTO_CANCELLATION"
}

interface ServiceTermFilter {
  event_type?:ServiceTermEventType,
  auto_renew_length?: number,
  days_to_notify?: number,
}

@Component({
  selector: 'mi-find-or-create-service-term',
  templateUrl: './find-or-create-service-term.component.html',
  styleUrls: ['./find-or-create-service-term.component.scss']
})

export class FindOrCreateServiceTermComponent extends AbstractPageComponent implements OnInit {

  CREATE_STATE = FindOrCreateComponentSteps.CREATE_STATE;
  SEARCH_STATE = FindOrCreateComponentSteps.SEARCH_STATE;
  EVENT_TYPE_AUTO_RENEW: string = EVENT_TYPE_AUTO_RENEW;
  EVENT_TYPE_AUTO_CANCELLATION: string = EVENT_TYPE_AUTO_CANCELLATION;
  readonly NO_DATA: string = NO_DATA;

  @Output() selectedServiceTerm: EventEmitter<ServiceTerm> = new EventEmitter<ServiceTerm>();
  @Output() serviceTermRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() modeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  tenantId: string = '';
  @Input() serviceTermId: string;
  @Input() serviceTerm: ServiceTerm;
  @Input() service: MiServiceDetails;
  error: string = '';
  serviceTerms: ServiceTerm[] = [];
  step: FindOrCreateComponentSteps = this.SEARCH_STATE;
  filter: ServiceTermFilter = {};
  autoRenewSelected: boolean = true;
  serviceTermColumn: SortableTableColumn[];
  searchTermForm: FormGroup;
  editMode: boolean = false;

  @Input() allowNull: boolean = true;

  @ViewChild('checkboxTpl', {static: true}) checkboxTpl: TemplateRef<any>;
  @ViewChild('eventTypeTpl', {static: true}) eventTypeTpl: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private serviceTermService: ServiceTermService,
    private modalService: ModalService,
    private store: Store<any>)  {
      super();
  }

  ngOnInit() {
    this.serviceTermColumn = [
      {
        name: 'Select',
        prop: 'id',
        cellTemplate: this.checkboxTpl
      },
      {
        name: 'End of Term Action',
        prop: 'event_type',
        cellTemplate: this.eventTypeTpl
      },
      {
        name: 'Auto-Renew Length (Months)',
        prop: 'auto_renew_length'
      },
      {
        name: 'Advanced Notification (Days)',
        prop: 'days_to_notify'
      }
    ];
    

    this.searchTermForm = this.formBuilder.group({
      event_type: ['EVENT_TYPE_AUTO_RENEW'],
      auto_renew_length: [null],
      days_to_notify: [null]
    });

    if(!this.serviceTerm){
      this.store.select(appDataReducer.tenantDomain)
      .takeUntil(this.destroy$)
      .subscribe(
        tenantDomain => {
          this.tenantId = tenantDomain;
          if(this.serviceTermId){
  
            if(this.service){ // this service added as a PRITI requested
              this.serviceTermService.getServiceTermByIdForService(this.serviceTermId,this.service.general.service_display_id)
              .takeUntil(this.destroy$)
              .subscribe(serviceTerm=> this.serviceTerm = serviceTerm,
                e=> this.error = e);
            }else{
              this.serviceTermService.getServiceTermById(this.serviceTermId)
              .takeUntil(this.destroy$)
              .subscribe(serviceTerm=> this.serviceTerm = serviceTerm,
                e=> this.error = e);
            }
           
          } else {
            if(!this.allowNull){
              return this.changeMode(true);
            };
          }
        }
      );
    } else {
      this.tenantId = this.serviceTerm.tenant_id;
    }

    this.search();
  }

  onFormSubmit(filter){
    this.autoRenewSelected = filter ? filter.event_type === EVENT_TYPE_AUTO_RENEW : true;
    if (filter.event_type === EVENT_TYPE_AUTO_CANCELLATION) {
      this.searchTermForm.get('days_to_notify').setValue(null);
      this.searchTermForm.get('auto_renew_length').setValue(null);
    }
    this.search(this.searchTermForm.value);
  }

  search(filter={}){
    let filterKeys = Object.keys(filter);
    filterKeys.map(key => {
      if(!filter[key]){
        delete filter[key];
      }
    })
    this.serviceTermService.searchServiceTerms(this.tenantId, filter)
    .takeUntil(this.destroy$)
    .subscribe(serviceTerms => {
      this.serviceTerms = serviceTerms;
    },
    e=> this.error = e
  );
  }

  swapTo(newState: FindOrCreateComponentSteps) {
    this.step = newState;
  }

  changeMode(editMode){
    this.step = this.SEARCH_STATE
    this.editMode = editMode;
    this.modeChange.emit(this.editMode);
  }

  rowSelected(serviceTerm) {
    this.serviceTerm = serviceTerm;
    this.selectedServiceTerm.emit(this.serviceTerm);
    this.serviceTermRemoved.emit(false)
    this.changeMode(false);
  }


  deleteTermModalHandler() {
    const modalRef = this.modalService.open(ConfirmDeleteVendorTermsModalComponent);
    modalRef.closed.subscribe(({ reason, result = [] }) => {
      if(result[0]){
        this.removeServiceTerm()
      }
    });
}

  removeServiceTerm(){
    this.editMode = !this.editMode;
    this.modeChange.emit(this.editMode);
    this.selectedServiceTerm.emit(null);
    this.serviceTermRemoved.emit(true)
    this.serviceTerm = null;
    if(!this.allowNull){
      return this.changeMode(true);
    }
    this.changeMode(false);
  }

  onUpdate(){
    this.selectedServiceTerm.emit(this.serviceTerm);
  }

  onCancel() {
    this.canceled.emit();
  }

}
