import {Component, OnInit} from '@angular/core';
import {SEARCH_TYPE_PRODUCTS} from '../../../../components/detail-search/detail-search.component';
import {combineLatest, Observable} from "rxjs";
import {ActivatedRoute, Params} from '@angular/router';
import * as ASActions from '../../../../../shared/state/advanced-search/actions';
import {ADVANCED_SEARCH_TYPES} from '../../../../../shared/state/advanced-search/reducers';
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import {Store} from '@ngrx/store';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {NO_DATA} from '../../../../../shared/ng-models/SectionDataTable.model';
import {TenantProductsService} from '../../../../../libs/api/tenant/tenantProducts';

@Component({
  templateUrl: './product-search-events.component.html'
})

export class ProductSearchEventsComponent extends AbstractPageComponent implements OnInit{

  display_id: string;
  error: string = '';
  loaded: boolean = false;
  pageLoaded$: Observable<boolean>;
  queryParams: any = {
    "product_id[]": null
  };
  readonly NO_DATA: string = NO_DATA;
  readonly SEARCH_TYPE_PRODUCTS: string = SEARCH_TYPE_PRODUCTS;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantProductService: TenantProductsService,
              private store: Store<ASReducer.State>) {
    super();

  }

  ngOnInit() {

    this.store.dispatch(new ASActions.AsReset({searchType: ADVANCED_SEARCH_TYPES.MI_SERVICE}));

    // listen for page loaded
    this.pageLoaded$ = this.store.select(ASReducer.ASPageLoaded);

    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.display_id = queryParams.display_id;
        return combineLatest(
          this.tenantProductService.getProductByDisplayId(this.display_id),
          this.activatedRoute.queryParams
        );
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe( ([product, queryParams]:any[]) => {

        this.queryParams = {
          ... this.queryParams,
          //"product_id[]": product._id
          "product_id[]": product.id
        };

        this.store.dispatch(new ASActions.AsDeeplinkChanged(this.queryParams));

      });
  }

}
