<mi-page-header
      [breadcrumbs]="breadcrumbs"
      [showShortcutMenu]="false">
</mi-page-header>
<!--
<div class="header-label-container">
  <label>{{headerLabel}}</label>
</div>
-->
<div class="body-container" *ngIf="loaded; else loading">
  <div class="plan-container">
    <div>
      <label>Current Plan</label>
    </div>
    <div class="table-container">
      <table>
        <tr>
          <td>
            Plan Name
          </td>
          <td>
            {{accountPlanDetails.planName}}
          </td>
        </tr>
        <tr>
          <td>
            Billed
          </td>
          <td>
            <a (click)="showBillingBreakdown()">
              {{accountPlanDetails.billingAmount | tenantcurrencyusd:'1.0'}} / <sub>{{accountPlanDetails.billingInterval}}</sub>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            Templates Included
          </td>
          <td>
            {{accountPlanDetails.templatesIncluded}}
          </td>
        </tr>
        <tr>
          <td>
            Additional Templates Charge
          </td>
          <td>
            {{accountPlanDetails.additionalTemplatesCost | tenantcurrencyusd:'1.0'}} / <sub>{{accountPlanDetails.additionalTemplatesQuantity}} template(s)</sub> / <sub>{{accountPlanDetails.billingInterval}}</sub>
          </td>
        </tr>
        <tr>
          <td>
            Services Included
          </td>
          <td>
            {{accountPlanDetails.servicesIncluded}}
          </td>
        </tr>
        <tr>
          <td>
            Additional Services Charge
          </td>
          <td>
            {{accountPlanDetails.additionalServicesCost | tenantcurrencyusd:'1.0'}} / <sub>{{accountPlanDetails.additionalServicesQuantity}} service(s)</sub> / <sub>{{accountPlanDetails.billingInterval}}</sub>
          </td>
        </tr>
        <tr>
          <td>
            Template Setup Charge
          </td>
          <td>
            {{accountPlanDetails.templateSetupFee | tenantcurrencyusd:'1.0'}} / <sub>1 template</sub>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="usage-container">
    <div>
      <label>Current Usage</label>
    </div>
    <div class="table-container">
      <table>
        <tr>
          <td>
            Billing Period
          </td>
          <td>
            {{getHumanReadableTimestamp(tenantCurrentBillingCycle.currentBillingCycleStartDate)}} - {{getHumanReadableTimestamp(tenantCurrentBillingCycle.currentBillingCycleEndDate)}}
          </td>
        </tr>
        <tr>
          <td>
            Maximum Templates Used
          </td>
          <td>
            {{tenantCurrentBillingCycle.maximumTemplates}}
          </td>
        </tr>
        <tr>
          <td>
            Maximum Services Used
          </td>
          <td>
            {{tenantCurrentBillingCycle.maximumServices}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>