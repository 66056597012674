<mi-section-title [title]="title">
  <ng-content></ng-content>
</mi-section-title>

<div *ngIf="data && data.rows.length; else noResults"> 
  <mi-sortable-draggable-table 
    [rows]="data.rows"
    externalClasses="fullWidth"
    [searchType]="searchType"
    [columns]="data.columns">
  </mi-sortable-draggable-table>
</div>

<ng-template #noResults>
      <span class="no-results">
        <ng-container *ngIf="noResultsText; else defaultNoResultsText">
          {{noResultsText}}
        </ng-container>
        <ng-template #defaultNoResultsText>
          {{'VENDORS.DEFAULT'|translate: {'title': title} }}
        </ng-template>
      </span>
</ng-template>

<ng-template #fileLink let-row="row" let-value="value">
    <a *ngIf="row.is_v1"href="{{getV1FileUrl(row)}}" target="_blank">{{ shortenFileName(row.file_name) }}</a>
    <a *ngIf="!row.is_v1&&!row.is_url" (click)="downloadDocument(row.id)">{{ shortenFileName(row.file_name) }}</a>
    <a *ngIf="!row.is_v1&&row.is_url" [href]="row.file_name" target="_blank">{{ shortenFileName(row.file_name) }}</a>
  </ng-template>

<ng-template #fileType let-row="row" let-value="value">
  {{ 'UPLOAD_DOC_TYPES.' + DOC_TYPES[row.file_type] | translate }}
</ng-template>
<ng-template #fileSize let-row="row" let-value="value">
  {{ value | filesize }}
</ng-template>
<ng-template #vendorTmpl let-row="row" let-value="value">
  <mi-id-link [id]="getVendorDisplayId(row)">
  </mi-id-link>
  {{getVendorDisplayName(row)}}
</ng-template>