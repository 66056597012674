<div>
    <mi-page-header
      [title]="title"
      [breadcrumbs]="breadcrumbs"
      [links]="headerLinks">
    </mi-page-header> 
    <ng-container *ngIf="loaded; else loading">

      <mi-service-requestor skipRoute="../../{{type}}"
                          [service]="parentService.current_notification_service"
                          (complete)="next($event)"
                          cancelRoute="../../">
      </mi-service-requestor>
      
    </ng-container>

    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  
  </div>
  