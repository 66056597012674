import {User} from './User';
import {UserTenantProfile} from './UserTenantProfile';
import {NoteConnection} from './Note.interface';

export enum MisoActivityTypes{
  EVENT = "MISO-Event",
  NOTE = "MISO-Note"
}

export interface MisoActivity {

  sort_date?: string; // Date used to sort items.
  type?: MisoActivityTypes;

  _id?: string;
  owner?: string;
  connections?: NoteConnection[];
  user?: User;
  user_tenant_profile?: UserTenantProfile;
  html?: string;
  updated_at?: string;
  created_at?: string;

  auto_renew_count?: number;
  end_date?: string;
  event_type?: string;
  days_until_event?: number;
  latest_cancellation_date?: string;
  notification_date?: string;
  notification_days_until_earliest_cancellation_date?: number;
  notification_days_until_event?: number,
  notification_earliest_cancellation_date?:string;
  notification_event_type?: string;
  notification_status?: string;
  notification_term_length?: number;
  start_date?: string;
  status?: string;
  term_length?: number;
  realized_saving?: number;

}
