import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { UserV2 } from '../types/UserV2.interface';
import {createSecureUser} from "../../../common/security/secureUser";

@Injectable()
export class ProfileV2Service {

  private host = '_MICRO_SERVICE_/auth';

  constructor(private http: HttpClient) {

  }

  create(params: UserV2): Observable<any> {
    return this.http
      .post(`${this.host}/profile`, params)
      .map((data: any) => {
        return {
          secureUser: createSecureUser({...data, isV2: true})
        };
      });
  }


  changePassword(params: any): Observable<any> {
    return this.http
      .post(`${this.host}/change-password`, params)
      .map((response: any) => response);
  }
  

}
