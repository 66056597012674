import {Component, OnInit} from '@angular/core';
import {TenantSearchService} from '../../../../libs/api/tenantSearch/tenantSearch.service';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {ActivatedRoute, Params} from '@angular/router';
import {ADVANCED_SEARCH_TYPES} from '../../../../shared/state/advanced-search/reducers';
import {MiModalService} from '../../../../shared/services/Modal.service';
import {FILTER_GROUP_MODAL_ID} from '../../../../shared/ng-models/FilterGroupModalId';
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';
import { ModalService } from '@independer/ng-modal';

@Component({
  templateUrl: './events-list-page.component.html'
})

export class EventsListPageComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  error: any;
  filterOverlayId: string = 'filterOverlay';
  pageLoaded$: Observable<boolean>;
  supportLink: Link;

  constructor(
    private store: Store<ASReducer.State>,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private tenantSearchService: TenantSearchService,
    private modalService: ModalService) {
    super();
  }

  ngOnInit() {

    this.breadcrumbs = [{
      url: '',
      label: 'Dashboard',
      internal: true
    }, {
      url: '/services',
      label: 'Events',
      internal: true
    }];

    // send a reset.
    this.store.dispatch(new ASActions.AsReset({
      searchType: ADVANCED_SEARCH_TYPES.EVENT,
      pageLockFilters: ['time_range', 'table_view', 'is_group_parent']
    }));    // listen for page loaded
    this.pageLoaded$ = this.store.select(ASReducer.ASPageLoaded);


    // listen for route changes
    this.activatedRoute
      .queryParams
      .takeUntil(this.destroy$)
      .subscribe((queryParams: Params) => {
        const newParams = {
          ...queryParams,
          'is_group_parent': true
        };

        this.store.dispatch(new ASActions.AsDeeplinkChanged(newParams));
      });

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.filterGroup) {
            // Get the data and open the modal:
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.filterOverlayId;
            })
          }
        });
  }
}

