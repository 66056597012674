import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Router } from '@angular/router';

@Component({
  selector: 'mi-bar-line-chart',
  styleUrls: ['./bar-line-chart.component.scss'],
  templateUrl: './bar-line-chart.component.html'
})

export class BarLineChartComponent implements OnInit {

  @Input() data: any;
  @Input() chartType: string = 'line';
  @Input() title: string;
  @Input() yAxesPrefix: string = "";
  @Input() tooltipPrefix: string = "";
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  renderChart: boolean = true;
  chart: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
    console.log("here BarLineChartComponent", this.data)
    console.log("here BarLineChartComponent comingOutOfTerm90DaysCmrc", Math.round(this.data.currentlyOutOfTermCmrc))
    let ctx: CanvasRenderingContext2D = this.canvas.nativeElement.getContext('2d');

    let colors: string[] = ["#2880D0", "#0090C9", "#33A6D4", "#99D3E9"];

    this.chart = new Chart(ctx,
      {
        type: 'bar',
        data: {
          labels: this.data.months,
          datasets: [
            {
              label: this.data.barChartHeader,
              data: [ Math.round(this.data.currentlyOutOfTermCmrc)],
              borderColor: colors,
              backgroundColor: colors,
              order: 1
            },
            {
              label: this.data.lineChartHeader,
              data: this.data.lineChartValue,
              borderColor: '#000',
              // backgroundColor: colors.map(color => color + '20'), // Adding some transparency to differentiate from the bar chart
              type: 'line',
              order: 0
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                callback: value => {
                  return this.yAxesPrefix + this.numberWithCommas(value);
                }
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: value => {
                return this.tooltipPrefix + this.numberWithCommas(value.value);
              }
            }
          }
        },
      }
    );

  }

  numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
}
