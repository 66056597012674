import {Component, Input} from "@angular/core";
import {
  DetailHeaderNavData,
  HeaderNavSubNavTypes,
  HeaderNavSpecTypes
} from "../../../shared/ng-models/DetailHeaderNavData.interface";
import {Contact} from "../../../shared/models/Contact";
import {Address} from "../../../shared/models/Address";

@Component({
  selector: 'mi-details-header-nav',
  templateUrl: './details-header-nav.component.html',
  styleUrls: ['./details-header-nav.component.scss']
})

export class DetailsHeaderNavComponent{
  @Input() missingDataTotal: number = 0;
  @Input() missingDataDocCount: number = 0;
  @Input() equipmentCount: number = -1;
  @Input() contact: Contact;
  @Input() address: Address;
  @Input() data: DetailHeaderNavData;
  @Input() showEdit: boolean = true;
  @Input() navType:HeaderNavSubNavTypes = HeaderNavSubNavTypes.STANDARD;
  @Input() showAddAndRemoveService: boolean = false;

  DATE = HeaderNavSpecTypes.DATE;
  CURRENCY = HeaderNavSpecTypes.CURRENCY;
  NAV_TYPE_STANDARD = HeaderNavSubNavTypes.STANDARD;


  constructor(){

  }

}
