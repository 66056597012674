<ng-container *ngIf="list && list.length">
  <ng-container *ngIf="list.length <= max">
    ({{list.length}})
  </ng-container>
  <ng-container *ngIf="list.length > max">
    <ng-container *ngIf="isOpen">
      ({{list.length}})
    </ng-container>
    <ng-container *ngIf="!isOpen">
      ({{max}} {{'SHOW.OF'|translate}} {{list.length}})
    </ng-container>
    <a (click)="toggle()">
      {{this.isOpen ? ('SHOW.SHOW_LESS' | translate) : ('SHOW.SHOW_ALL' | translate) }}
    </a>
  </ng-container>
</ng-container>

