import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {DashboardPageComponent} from "./dashboard-page/dashboard-page.component";
import {TenantComponentsModule} from "../../components/tenant-components.module";
import {SharedModule} from "../../../shared/shared.module";
import {SimpleSummaryComponent} from "./dashboard-page/simpleSummary/simple-summary.component";
import {DashboardChartComponent} from "./dashboard-chart/dashboard-chart.component";
import {DashboardMissedOpportunitiesComponent} from './dashboard-missed-opportunities/dashboard-missed-opportunities.component';
import {DashboardPotentialSavingsComponent} from './dashboard-savings/dashboard-potential-savings/dashboard-potential-savings.component';
import {DashboardRealizedSavingsComponent} from './dashboard-savings/dashboard-realized-savings/dashboard-realized-savings.component';
import {CountUpModule} from 'countup.js-angular2';
import {DashboardMissedOppNotificationComponent} from './dashboard-missed-opp-notification/dashboard-missed-opp-notification.component';
import { DashboardPieChartComponent } from './dashboard-chart/dashboard-pie-chart/dashboard-pie-chart.component';
import { DashboardMrcChartComponent } from './dashboard-chart/dashboard-mrc-chart/dashboard-mrc-chart.component';
import { DashboarTimeChartsComponent } from './dashboard-page/dashboardTimeCharts/dashboard-time-charts.component';
import {ModalModule} from "@independer/ng-modal";
import { DashboardDoughnutChartComponent } from './dashboard-chart/dashboard-pie-chart/dashboard-doughnut-chart.component';

@NgModule({
  declarations: [
    DashboarTimeChartsComponent,
    DashboardMrcChartComponent,
    DashboardDoughnutChartComponent,
    DashboardPieChartComponent,
    DashboardPageComponent,
    DashboardChartComponent,
    SimpleSummaryComponent,
    DashboardMissedOpportunitiesComponent,
    DashboardPotentialSavingsComponent,
    DashboardRealizedSavingsComponent,
    DashboardMissedOppNotificationComponent
  ],
  imports: [
    ModalModule,
    CountUpModule,
    FormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    // StoreModule.forFeature('order-create-store', createOrderReducers),
    // EffectsModule.forFeature([ContainedStoreEffects])
  ],
  providers: [],
  exports: [
    DashboarTimeChartsComponent,
    DashboardMrcChartComponent,
    DashboardPieChartComponent,
    DashboardDoughnutChartComponent,
    DashboardPageComponent,
    DashboardChartComponent,
    SimpleSummaryComponent,
    DashboardMissedOpportunitiesComponent,
    DashboardPotentialSavingsComponent,
    DashboardRealizedSavingsComponent,
    DashboardMissedOppNotificationComponent
  ]
})
export class DashboardModule {
}
