import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {EmailFormRecipient} from "../../../../../shared/ng-models/EmailFormRecipient.interface";
import {Link} from "../../../../../shared/ng-models/Link.interface";
import {MiService} from "../../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../../libs/api/tenant/tenantMiService.service";
import {Contact} from "../../../../../shared/models/Contact";
import * as userReducer from "../../../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../../../shared/state/app-data/reducers';
import {Store} from "@ngrx/store";
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {TenantNoteService} from "../../../../../libs/api/tenant/tenantNote.service";
import * as ParentServicesReducer from '../state/reducers';
import { MiParentService } from "src/shared/models/MiParentService";
import { TenantMiParentServiceService } from "src/libs/api/tenant/tenantMiParentService.service";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  styleUrls: ['./account-representatitve-email.component.scss'],
  templateUrl: './account-representatitve-email.component.html'
})

export class ParentServiceAccountRepEmailComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true
    }
  ];
  cancelUrl: string;

  error: string;
  headerLinks: Link[];
  loaded: boolean = false;
  current_notification_service: MiService;
  tenantContacts: EmailFormRecipient[];
  userData: any;
  attachment: any[];
  tenantData: any;
  title: string = "Account Representitive";
  type: string;
  contact: Contact;
  newAccountId: any;
  parentService: MiParentService;
  serviceList: MiService[] = [];
  pageLoaded$: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<userReducer.State>,
    private router: Router,
    private tpsService: TenantMiParentServiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.pageLoaded$ = this.store.select(ParentServicesReducer.ParentServicePageLoaded);

    // Find the current service ID:
    let parentServiceId = this.activatedRoute.snapshot.params['display_id'];
    this.type = this.activatedRoute.snapshot.params['type'];
    console.log("service id : ",parentServiceId, this.type);
    // Update breadcrumbs:
    let newBreadcrumbs = [
      {
        url: `/parent-services/${parentServiceId}/details`,
        label: parentServiceId,
        internal: true
      },
      {
        url: `/parent-services/${parentServiceId}/account-rep`,
        label: 'Account Representitive',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];

    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState);
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    const parentService = this.tpsService.getByDisplayId(parentServiceId).toPromise()

    combineLatest(userStateData, tenantStateData, parentService)
      .subscribe((data)=>{
        this.tenantData = data[1].tenantData;
        this.userData = data[0].secureUser.getSafeData();
        this.parentService = data[2];
        if (data[2].services) {
          this.serviceList = this.parentService.services.sort((a, b) => {
            return a.mrc < b.mrc ? 1 : -1;
          });
        }
        this.current_notification_service = this.parentService.current_notification_service;
        this.loaded= true;
      });
  }

  next(){
    if(this.type==="request-a-quote-miso"){
      this.router.navigate([`../../request-a-quote/miso-quote`], {relativeTo: this.activatedRoute});
    }else{
      this.router.navigate([`../../${this.type}`], {relativeTo: this.activatedRoute});
    }
  }
}
