import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/index";
import { CurrencyV2 } from '../types/CurrencyV2.interface';
import { ResolutionPayload, TenantCurrencyV2 } from '../types/TenantCurrencyV2.interface';
import { CurrencyResolutionResponse } from 'src/shared/ng-models/currency-resolutions.interface';


@Injectable()
export class CurrencyService {

  private apiUrl = '_MICRO_SERVICE_/currency';
  private apiCurrencyResolutionUrl = '_MICRO_SERVICE_/buquery/resolution/update-resolution';
  private apiTenantServiceUrl = '_MICRO_SERVICE_/upload/service';
  private apiCurrencyResolutionServiceUrl = '_MICRO_SERVICE_/buquery/resolution/get-currency-resolutions';
  private getCurrencyDetailsByCurrencyIdAPI = `_MICRO_SERVICE_/currency/currency`;
  
  constructor(private http: HttpClient) {

  }

  getCurrencyCodes(): Observable<CurrencyV2[]> {
    return this.http
      .get(`${this.apiUrl}/all-currencies`)
      .map((response: any) => response as CurrencyV2[]);
  }

  getTenantCurrency(id: string): Observable<TenantCurrencyV2> {
    const data = {
      params: {
        currency_id: id
      }
    };
    return this.http
      .get(`${this.apiUrl}/currency`, data)
      .map((response: any) => response as TenantCurrencyV2);
  }

  saveTenantCurrency(tenantCurrency: any): Observable<TenantCurrencyV2> {
    return this.http
      .post(this.apiUrl + '/currency', tenantCurrency)
      .map((response: any) => response as TenantCurrencyV2);
  }

  getTenantCurrencies(tenant_id: string, search: string): Observable<TenantCurrencyV2[]> {
    const data = {
      params: {
        tenant_id,
        search
      }
    };
    return this.http
      .get(`${this.apiUrl}/currencies`, data)
      .map((response: any) => response as TenantCurrencyV2[]);
  }

  getTenantServiceCurrencies(tenantId: string): Observable<TenantCurrencyV2[]> {
    const data = {
      params: {
        tenantId,
      }
    };
    return this.http
      .get(`${this.apiTenantServiceUrl}/currencies`, data)
      .map((response: any) => response as TenantCurrencyV2[]);
  }

  deleteCurrency(id: string) {
    return this.http
      .post(this.apiUrl + '/delete-currency', [id])
      .map((response: any) => response);
  }

  getCurrencyDetailsByCurrencyId(currencyId: string) {
    return this.http.get<any>(`${this.getCurrencyDetailsByCurrencyIdAPI}?currency_id=${currencyId}`)
  }

  getCurrencyResolutions(pageNumber: number = 0, pageSize: number = 10): Observable<CurrencyResolutionResponse> {
    let url = `${this.apiCurrencyResolutionServiceUrl}/?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    // if (tenantId != null && tenantId.length > 0) {
    //   url += `&tenantId=${tenantId}`;
    // }

    // console.log("here status", status)
    // if (status != null && status.length > 0) {
    //   url += `&type=${status}`;
    // }
    return this.http
      .get<any>(`${url}`)
      .map((response: any) => response as CurrencyResolutionResponse);
  }

  resolveCurrencyService(resolutionPayload: ResolutionPayload): Observable<any> {
    return this.http
      .post(this.apiCurrencyResolutionUrl, resolutionPayload)
      .map((response: any) => response);
  }
  
}
