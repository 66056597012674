import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Product} from 'src/shared/models/Product';
import {ProductsService} from '../interfaces/ProductsService.interface';
import {Observable} from 'rxjs';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class MiyagiProductService implements ProductsService {

  private apiUrlCrud = '/miyagi/products';
  private apiUrlUnNormalized = '/miyagi/products/un-normalized';
  private apiUrlListByVendor = '/miyagi/products/by-vendor-id';
  private apiUrlListByCategory = '/miyagi/products/by-category-id';
  private apiUrlIgnore = '/miyagi/products/ignore';
  private apiUrlToNormalized = '/miyagi/products/to-normalized';

  constructor(private http: HttpClient) {
  }

  createProduct(product: Product): Promise<Product> {
    return this.http
      .post(this.apiUrlCrud, product)
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }

  editProduct(product: Product): Promise<Product> {
    return this.http
      .put(`${this.apiUrlCrud}/${product._id}`, product)
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }

  deleteProduct(id: String): Observable<Product> {
    return this.http
      .delete(`${this.apiUrlCrud}/${id}`)
      .map((response: any) => response.data.product as Product);
  }

  ignoreProduct(productId: string): Promise<Product> {
    return this.http
      .put(`${this.apiUrlIgnore}/${productId}`, {})
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }

  getProduct(productId: string): Promise<Product> {
    return this.http
      .get(`${this.apiUrlCrud}/${productId}`)
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }

  getProductToNormalized(productId: string): Promise<Product> {
    return this.http
      .get(`${this.apiUrlToNormalized}/${productId}`)
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }

  listProducts(): Promise<Array<Product>> {
    return this.http
      .get(this.apiUrlCrud)
      .toPromise()
      .then((response: any) => response.data.products as Array<Product>);
  }

  listProductsByVendor(vendorId: string): Promise<Array<Product>> {
    return this.http
      .get(`${this.apiUrlListByVendor}/${vendorId}`)
      .toPromise()
      .then((response: any) => response.data.products as Array<Product>);
  }


  listProductsByCategory(categoryId: string): Promise<Array<Product>> {
    return this.http
      .get(`${this.apiUrlListByCategory}/${categoryId}`)
      .toPromise()
      .then((response: any) => response.data.products as Array<Product>);
  }

  listUnNormalized(start: Number, limit: Number, created_at: Number, status: String): Promise<Array<Product>> {
    let submissionUrl = this.apiUrlCrud + '?unNormalized=true';

    if (start) {
      submissionUrl += `&start=${start}`;
    }
    if (limit) {
      submissionUrl += `&limit=${limit}`;
    }
    if (created_at) {
      submissionUrl += `&created_at=${created_at}`;
    }
    if (status) {
      submissionUrl += `&status=${status}`;
    }

    return this.http
      .get(submissionUrl)
      .toPromise()
      .then((response: any) => {
        return response.data.products.map((product, index) => {
          product.count = index + start + 1;
          product.status = product.status || (product.normalized ? 'Resolved' : 'Open');
          return product;
        }) as Array<Product>;
      });
  }

  getUnNormalized(): Promise<Array<Product>> {
    return this.http
      .get(this.apiUrlUnNormalized)
      .toPromise()
      .then((response: any) => response.data.products as Array<Product>);
  }


  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
