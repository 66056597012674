import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent,HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Document} from '../../api2/types/Document.interface';

@Injectable()
export class TenantDocumentManagementService {
  private apiUrl = '_MICRO_SERVICE_/document';
  private apiUrlDocumentCurd = '/{tenant}/associated-documents';

  constructor(private http: HttpClient) {}

  save(data: any) {
    /*
    if(data.is_url){
      return this.http
        .post(this.apiUrl + '/document', data)
        .switchMap((response: any) => {
            return this.updateDocumentIndex(response);
        });
    }
    */
    return this.http
      .post(this.apiUrl + '/document', data)
      .map((response: any) => response);
  }


  uploadDataIntegrityFile(formData: FormData): Observable<HttpEvent<any>> {
    return this.http.post(this.apiUrl + '/document', formData, {
      reportProgress: true, // Enable progress tracking
      observe: 'events' // Receive events to track progress
    });
  }


  updateDocumentIndex(document: any) {
    return this.http
      .put(`${this.apiUrlDocumentCurd}/${document.id}/index`, document)
      .map((response: any) => document);
  }

  deleteDocumentIndex(id: String) {
    return this.http
      .delete(`${this.apiUrlDocumentCurd}-delete/${id}`)
      .map((response: any) => response);
  }

  update(data: any) {
    return this.http
      .post(this.apiUrl + '/document-permission', data)
      /*
      .switchMap((response: any) => {
        return this.updateDocumentIndex(response);
    });
    */
      .map((response: any) => response);
  }

  getDocuments(business_unit_id: string, search: string): Observable<Array<Document>> {
    const url = `${this.apiUrl}/documents?business_unit_id=${business_unit_id}&search=${search ? search : ''}`;
    return this.http
      .get(url)
      .map((response: any) => response);
  }

  confirmUpload(id: string) {
    const url = this.apiUrl + '/confirm-document-upload?id=' + id;
    return this.http
      .get(url)
      /*
      .switchMap((response: any) => {
        //debugger;
        return this.updateDocumentIndex(response);
    });
    */
      .map((response: any) => response);
  }

  delete(id: string, ids: string[]) {
    return this.http
      .post(this.apiUrl + '/delete-document?id=' + id, ids)
      .switchMap((response: any) => {
        return this.deleteDocumentIndex(id);
      });
  }

  download(document_id: string) {
    const url = this.apiUrl + '/download?id=' + document_id;
    return this.http
      .get(url)
      .map((response: any) => response);
  }

  upload(url, file) {
    return this.http
      .post(url, file)
      .map((response: any) => response);
  }

  getDocumentsByVendorId(tenant_vendor_id: string, owner: string, type = "INVOICE", pageNumber: number = 0, pageSize: number = 0, search: string = "", exclude_props: string = ''): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        owner: owner,
        tenant_vendor_id: tenant_vendor_id,
        page_number: pageNumber.toString(),
        size: pageSize.toString(),
        q: search,
        type: type,
        exclude_props: exclude_props
      }
    });
    return this.http
      .get(`${this.apiUrl}/vendor-documents`, { params })
      .map((response: any) => response);
  }

  getDocumentById(id: string) {
    const url = this.apiUrl + `/document?id=${id}`;
    return this.http
      .get(url)
      .map((response: any) => response);
  }

}
