import { settings } from '../../../common/utils/SettingsUtil';
import MiCookies from "../../../shared/utils/MiCookies";

const COOKIE_NAME = settings.AUTH_COOKIE;
const COOKIE_NAME_V2 = `${COOKIE_NAME}_V2`;
const MI_MISO_AUTH_COOKIE = `${settings.MI_MISO_AUTH_COOKIE}`
const MI_MISO_LOGGED_IN_USER_COOKIE = `${settings.MI_MISO_LOGGED_IN_USER_COOKIE}`
const MI_MISO_USER_SELECTED_TENANT_COOKIE = `${settings.MI_MISO_USER_SELECTED_TENANT_COOKIE}`
const TENANT_DUAL_PLATFORM_ACCESS_COOKIE = `${settings.TENANT_DUAL_PLATFORM_ACCESS_COOKIE}`

export function getAuthCookie(): string {
  return MiCookies.getItem(COOKIE_NAME);
}

export function getAuthCookieV2(): string {
  return MiCookies.getItem(COOKIE_NAME_V2);
}

export function getDualPlatformAccessV2(): string {
  return MiCookies.getItem(TENANT_DUAL_PLATFORM_ACCESS_COOKIE);
}


export function clearAuthCookie() {
  MiCookies.removeItem(COOKIE_NAME, '/', settings.COOKIE_CROSS_DOMAIN);
  MiCookies.removeItem(COOKIE_NAME_V2, '/', settings.COOKIE_CROSS_DOMAIN);
}

export function clearAllCookies() {
  MiCookies.removeItem(COOKIE_NAME, '/', settings.COOKIE_CROSS_DOMAIN);
  MiCookies.removeItem(COOKIE_NAME_V2, '/', settings.COOKIE_CROSS_DOMAIN);
  MiCookies.removeItem(MI_MISO_AUTH_COOKIE, '/', settings.COOKIE_CROSS_DOMAIN);
  MiCookies.removeItem(MI_MISO_LOGGED_IN_USER_COOKIE, '/', settings.COOKIE_CROSS_DOMAIN);
  MiCookies.removeItem(MI_MISO_USER_SELECTED_TENANT_COOKIE, '/', settings.COOKIE_CROSS_DOMAIN);
  MiCookies.removeItem(TENANT_DUAL_PLATFORM_ACCESS_COOKIE, '/', settings.COOKIE_CROSS_DOMAIN);
}

export function setAuthCookie(value) {
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  MiCookies.setItem(COOKIE_NAME, value, new Date(res),  '/', settings.COOKIE_CROSS_DOMAIN, settings.COOKIE_HTTPS_ONLY);
}

export function setAuthCookieV2(value) {
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  MiCookies.setItem(COOKIE_NAME_V2, value, new Date(res),  '/', settings.COOKIE_CROSS_DOMAIN, settings.COOKIE_HTTPS_ONLY);
}

export function setMiMisoAuthCookie(value) {
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  MiCookies.setItem(MI_MISO_AUTH_COOKIE, value, new Date(res),  '/', settings.COOKIE_CROSS_DOMAIN, settings.COOKIE_HTTPS_ONLY);
}

export function setMiMisoLoggedInUser(value) {
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  MiCookies.setItem(MI_MISO_LOGGED_IN_USER_COOKIE, value, new Date(res),  '/', settings.COOKIE_CROSS_DOMAIN, settings.COOKIE_HTTPS_ONLY);
}

export function setMiMisoUserSelectedTenant(value) {
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  MiCookies.setItem(MI_MISO_USER_SELECTED_TENANT_COOKIE, value, new Date(res),  '/', settings.COOKIE_CROSS_DOMAIN, settings.COOKIE_HTTPS_ONLY);
}

export function setTenantDualPlatformAccess() {
  const days = 7;
  const date = new Date();
  const res = date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  MiCookies.setItem(TENANT_DUAL_PLATFORM_ACCESS_COOKIE, "true", new Date(res),  '/', settings.COOKIE_CROSS_DOMAIN, settings.COOKIE_HTTPS_ONLY);
}