<div class="card-1">
  <mi-server-error [error]="error"></mi-server-error>

  <mi-sortable-table
    *ngIf="contactRows.length > 0; else noContacts"
    [columns]="contactColumns"
    [rows]="contactRows"
    externalClasses="serviceDetails">
  </mi-sortable-table>

  <ng-template #noContacts>
    {{'TENANT.SERVICE_DETAILS.NO_CONTACTS' | translate }}
  </ng-template>
</div>

<ng-template #emailTmpl let-row="row" let-value="value">
  <a *ngIf="value; else noData" href="mailto:{{value}}">{{value}}</a>
</ng-template>

<ng-template #buTmpl let-row="row" let-value="value">
  <span *ngIf="value; else noData">
    <mi-simple-bu [id]="value"></mi-simple-bu>
  </span>
</ng-template>

<ng-template #editOwnershipTmpl let-row="row" let-value="value">
  <ng-container [ngSwitch]="row.service_association">
    <!-- <ng-container *ngSwitchCase="'M3 Owner'">
      <a mi-button  buttonTheme="light-theme" (click)="onEdit(row)">{{value? 'Edit': 'Add'}}</a>
    </ng-container> -->
    <ng-container *ngSwitchCase="'Mi-MiSO Owner'">
      <a *ngIf="row.origin === 'vendor'" mi-button buttonTheme="light-theme" (click)="onEdit(row)">{{row.origin ? 'Edit' : 'Add'}}</a>
      <a *ngIf="row.origin === 'account' || row.origin === 'service'" class="deleteContact" (click)="onRemoveEmployee(row)"></a>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <a *ngIf="value"
        class="deleteContact"
        (click)="onRemoveEmployee(row)">
      </a>
      <a *ngIf="!value" mi-button  buttonTheme="light-theme" (click)="onEdit(row)">Add</a>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noData>
  {{NO_DATA}}
</ng-template>