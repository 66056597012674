import { Component, OnInit } from '@angular/core';
import { Link } from 'src/shared/ng-models/Link.interface';
import { LocalDataSource } from 'ng2-smart-table';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { Store } from '@ngrx/store';
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { first } from 'rxjs/operators';
import { Invoice } from 'src/shared/models/unpaid-invoice';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractPageComponent } from '../../../shared/pages/abstract-page/abstract-page.component';
import { DocumentV2Service } from 'src/libs/api2/document-v2/document-v2.service';

@Component({
  selector: 'mi-unpaid-invoice-report',
  templateUrl: './unpaid-invoice-report.component.html',
  styleUrls: ['./unpaid-invoice-report.component.scss']
})

export class UnpaidInvoiceReportComponent extends AbstractPageComponent implements OnInit {

  breadCrumbs: Link[] = [
    { label: "Dashboard", url: "/", internal: true },
    // { label: "Report Summary", url: "/report-summary", internal: true },
    { label: "Unpaid Invoice Report", url: "/unpaid-invoice", internal: true }
  ];
  headerLinks: Link[];
  lastUpdateDates: string[] = [];
  unpaidInvoices: Invoice[] = [];
  selectedDate: string;
  unpaidInvoiceSource: LocalDataSource = new LocalDataSource();
  tenantId: string;
  vendorId: string;
  pageNumber: number = 0;
  pageSize: number = 200;
  noDataMessage: string = '';
  totalCurrentDue: number = 0;
  uniqueCustomerAccountCount: number = 0;
  uniqueInvoiceCount: number = 0;
  isLoading: boolean = true;
  isLoadingDates: boolean = true;
  isFiltered: boolean = false;
  vendorName = "Bloomberg";

  settings = {
    hideSubHeader: false,
    columns: {
      invoice_number: { title: 'Invoice', filter: true, sortable: true },
      display_id: {
        title: 'DOC-ID',
        filter: true,
        sortable: true,
        type: 'html',
        valuePrepareFunction: (value, row) => {
          return `<a>${value}</a>`;
        }
      },
      invoice_date: { title: 'Invoice Date', filter: false, sortable: false },
      days_out_standing: { title: 'Days Outstanding', filter: false, sortable: false },
      po: { title: 'PO', filter: false, sortable: false },
      bil_to_attention: { title: 'Bill to Attention', filter: false, sortable: false },
      customer_attention: { title: 'Customer Attention', filter: false, sortable: false },
      customer: { title: 'Customer', filter: false, sortable: false },
      customer_hash: {
        title: 'Account No.',
        filter: false,
        sortable: false,
        valuePrepareFunction: (value) => {
          return value !== null && value !== undefined ? value : '-';
        }
      },
      currency: { title: 'Currency', filter: false, sortable: false },
      current_due: {
        title: 'Current Due',
        filter: false,
        sortable: false,
        valuePrepareFunction: (value) => {
          return value !== null && value !== undefined ? value : '-';
        }
      }
    },
    pager: {
      display: false,
      perPage: this.pageSize
    },
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    noDataMessage: 'No data found'
  };
  error: any;

  constructor(
    private tenantService: TenantV2Service,
    private store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    public documentV2Service: DocumentV2Service,
  ) {
    super();
    // this.route.queryParams.subscribe((params) => {
    //   if (this.router.getCurrentNavigation().extras.state) {
    //     this.vendorId = this.router.getCurrentNavigation().extras.state.vendorId;
    //   }
    //   if (this.vendorId === undefined || this.vendorId === null) {
    //     router.navigate(["/vendor-normalization"]);
    //   }
    // });
  }

  ngOnInit(): void {
    // if (!this.vendorId) {
    //   this.fetchVendorId(); 
    // } else {
    //   this.loadInitialInvoices();
    // }
    this.fetchVendorId();
  }

  fetchVendorId(): void {
    const tenantData = this.store.select(appDataReducer.getAppData);
    tenantData.pipe(first()).subscribe(
      (data) => {
        this.tenantId = data.tenantData.domain;
        if (this.tenantId) {
          this.tenantService.getVendorIdForInvoice(this.tenantId, this.vendorName)
            .toPromise()
            .then((res: any) => {
              this.vendorId = res[0];
              if (this.vendorId) {
                this.loadInitialInvoices();
              }
            })
            .catch((error) => {
              this.error = error;
            });
        }
      },
      (e) => this.error = e
    );
  }

  loadInitialInvoices(): void {

    this.tenantService.getUnpaidInvoiceDates(this.tenantId, this.vendorId)
      .toPromise()
      .then((res: any) => {
        this.lastUpdateDates = res;
        this.selectedDate = this.getLatestDate(this.lastUpdateDates);
        this.isLoadingDates = false;
        this.loadInvoices();
      });

    // const tenantData = this.store.select(appDataReducer.getAppData);
    // tenantData.pipe(first()).subscribe(
    //   (data) => {
    //     this.tenantId = data.tenantData.domain;
    //     if (this.tenantId) {
    //       this.tenantService.getUnpaidInvoiceDates(this.tenantId, this.vendorId)
    //         .toPromise()
    //         .then((res: any) => {
    //           this.lastUpdateDates = res;
    //           this.selectedDate = this.getLatestDate(this.lastUpdateDates);
    //           this.isLoadingDates = false;
    //           this.loadInvoices();
    //         });
    //     }
    //   },
    //   (e) => this.error = e
    // );
  }

  loadInvoices(): void {
    this.isLoading = true;
    if (this.tenantId && this.selectedDate) {
      this.tenantService.getUnpaidInvoiceDetails(this.pageNumber, this.pageSize, this.tenantId, this.vendorId, this.selectedDate)
        .toPromise()
        .then((response: any) => {
          if (response.invoice_data) {
            this.unpaidInvoices = response.invoice_data;
            this.totalCurrentDue = response.total_invoice_value;
            this.uniqueCustomerAccountCount = response.unpaid_accounts;
            this.uniqueInvoiceCount = response.total_invoice;
            // this.unpaidInvoiceSource.load(this.unpaidInvoices);
            this.unpaidInvoiceSource = new LocalDataSource(this.unpaidInvoices);
          } else {
            this.unpaidInvoiceSource.empty();
            this.unpaidInvoices = [];
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.status === 204) {
            this.unpaidInvoiceSource.empty();
            this.unpaidInvoices = [];
          } else {
            this.error = error;
          }
          this.isLoading = false;
        });
    }
  }

  getLatestDate(dates: string[]): string {
    return dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
  }

  onDateChange(event: Event): void {
    this.selectedDate = (event.target as HTMLSelectElement).value;
    this.unpaidInvoiceSource.reset();
    this.isLoading = true;
    this.loadInvoices();
  }

  downloadReport() {
    this.tenantService.download(this.selectedDate, this.tenantId, this.vendorId).subscribe(
      (response: ArrayBuffer) => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        const a = document.createElement('a');
        a.href = url;
        a.download = `Bloomberg_Unpaid_Invoice_Details_${this.selectedDate}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      (error) => {
        console.error('Error downloading the report:', error);
      }
    );
  }

  onRowSelectHandler(event: any) {
    console.log('here the event on rowSelect', event);
    this.documentV2Service
      .getDocumentUrlService(event.data.document_id)
      .toPromise()
      .then(response => {
        window.open(response.download_url, "_blank");
      })
      .catch((e) => console.warn(e));
  }

  onChange(): void {
    setTimeout(() => {
      const filteredData = this.unpaidInvoiceSource['filteredAndSorted'];
      console.log('here the filteredData', filteredData);
      this.totalCurrentDue = this.calculateTotalCurrentDue(filteredData);
      this.uniqueInvoiceCount = this.countUniqueInvoiceNumbers(filteredData);
      this.uniqueCustomerAccountCount = this.countUniqueCustomerHashes(filteredData);

    }, 1000);
  }


  calculateTotalCurrentDue(invoices) {
    let total = 0;

    invoices.forEach(invoice => {
      // Replace commas, parse the amount to float, and add to total
      let amount = parseFloat(invoice.current_due.replace(/,/g, ''));
      total += amount;
    });

    return total; // Return the total as a number
  }
  get formattedTotalCurrentDue(): string {
    return this.totalCurrentDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  countUniqueInvoiceNumbers(invoices) {
    let uniqueInvoiceNumbers = new Set();

    invoices.forEach(invoice => {
      uniqueInvoiceNumbers.add(invoice.invoice_number);
    });

    return uniqueInvoiceNumbers.size;
  }
  countUniqueCustomerHashes(invoices) {
    let uniqueCustomerHashes = new Set();

    invoices.forEach(invoice => {
      uniqueCustomerHashes.add(invoice.customer_hash);
    });

    return uniqueCustomerHashes.size;
  }

}
