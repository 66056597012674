import {Directive, ElementRef, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[mi-button]'
})

export class MiButton {

  @Input() buttonDisabled: boolean;
  @Input() buttonTheme: string;
  @Input() 
  @HostBinding('disabled')
  disabled: boolean;
  
  constructor(private el: ElementRef) {
    if (this.el.nativeElement.tagName == "A") {
      this.el.nativeElement.className += (this.el.nativeElement.className.length === 0 ? '' : ' ') + 'button';
    }
  }
  ngOnInit(){
    this.el.nativeElement.className += (this.el.nativeElement.className.length === 0 ? '' : ' ') + (this.buttonTheme || 'default');
    this.el.nativeElement.disabled = this.buttonDisabled;

    if (this.el.nativeElement.tagName == "A") {
      this.buttonDisabled ? this.el.nativeElement.classList.add('inactive') : this.el.nativeElement.classList.remove('inactive');
    }
  }
}
