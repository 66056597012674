import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Product} from '../../../shared/models/Product';
import {ProductSearchResults} from '../../../shared/models/SearchResults';
import {ProductsService} from '../interfaces/ProductsService.interface';
import {SearchProductsService} from '../interfaces/SearchProductsService.interface';
import {ProductSearchQueryParams} from '../../../shared/ng-models/search/SearchQueryParams.interface';
import {Observable} from 'rxjs';
import {fixAngularArrayQuery} from '../../../shared/utils/fixAngularArrayQuery';

const log = createLogger(LOG_LEVELS.HTTP_API);


@Injectable()
export class TenantProductsService implements ProductsService, SearchProductsService {

  private apiUrlSearchAllProducts = '/{tenant}/search/allProducts';
  private apiUrlCreateNormalizedProduct = '/{tenant}/products/normalized/create';
  private apiUrlCreateUnNormalizedProduct = '/{tenant}/products/un-normalized/create';
  private apiUrLProducts = '/{tenant}/products';
  private apiUrLProductsByIds = '/{tenant}/products/by-ids';
  private apiUrlIndexProduct = '/{tenant}/product/index';
  private apiUrlDeleteIndexProduct = '/{tenant}/product/deleteindex';
  private apiUrlMicroService = '_MICRO_SERVICE_/product/products';
  private apiUrLProductsByIdsV2 = '_MICRO_SERVICE_/product/products/list';
  private apiUrLProductsByTenantVendorV2 = '_MICRO_SERVICE_/product/products/tenants/vendor';

  constructor(private http: HttpClient) {

  }

  async createProductSQL(product: Product): Promise<Product> {
    return this.http
      .post(this.apiUrlMicroService, product)
      .toPromise()
      .then(async (response: any) => {
        //feed elastic
        //this.createUnNormalizedProduct(product);
        //console.log("Indexing product", response.id);
        //await this.indexProduct(response.id);
        return response as Product });
  }

  indexProduct(id: String) {
    return this.http
      .get(`${this.apiUrlIndexProduct}/${id}`)
      .toPromise()
      .then((response: any) => response);
  }

  deleteIndexProduct(id: String, tenantVendorId: String) {
    return this.http
      .get(`${this.apiUrlDeleteIndexProduct}/${id}/vendor/${tenantVendorId}`)
      .toPromise()
      .then((response: any) => response);
  }

  getProductByDisplayId(display_id: string): Promise<Product> {
    return this.http
      .get(`${this.apiUrlMicroService}/tenants/_TENANT_ID_/displays/${display_id}`)
      .toPromise()
      .then((response: any) => response as Product);
  }

  getProductByProductDisplayId(display_id: string): Observable<Product> {
    return this.http
      .get(`${this.apiUrlMicroService}/tenants/_TENANT_ID_/displays/${display_id}`)
      .map((response: any) =>  response as Product);
  }

  getProductsByIdsSQL(ids: Array<string>): Promise<Product[]> {
    return this.http
      .get(`${this.apiUrlMicroService}`, {
        params: new HttpParams({
          fromObject: { 'ids[]': ids }
        })
      })
      .toPromise()
      .then((response: any) => response as Product[]);
  }

  deleteProduct(id: String): Promise<Product> {
    //delete product in SQL
    return this.http
      .delete(`${this.apiUrlMicroService}/${id}`)
      .toPromise()
      .then((response: any) => {
        //delete product in elastic
        this.deleteProductElastic(id);
        return null
      });
  }


  createProduct(product: Product): Promise<Product> {
    //create in SQL
    return this.createProductSQL(product);
  }

  createNormalizedProduct(actual_product_id: string, vendor_id: string): Promise<Product> {
    return this.http
      .post(this.apiUrlCreateNormalizedProduct, { actual_product_id, vendor_id })
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }

  editProduct(product: Product): Promise<Product> {
      //update in SQL
      /*
      return this.http
        .put(this.apiUrlMicroService, product)
        .toPromise()
        .then((response: any) => {
          //update in elastic
          this.http
            .post(this.apiUrlEditUnNormalizedProduct, product)
            .toPromise()
            .then((response: any) => response.data.product as Product);
          return response as Product
        });
      */
      return this.createProductSQL(product);
    }

  searchProducts(queryParams: ProductSearchQueryParams): Observable<ProductSearchResults> {
    //search from elastic
    return this.http
      .get(this.apiUrlSearchAllProducts, {params: fixAngularArrayQuery(queryParams)})
      .map((response: any) => response.data as ProductSearchResults);
  }

  searchAllProducts(actual_vendor_id: string = '', q: string = '', from: number = 0, size: number = 6): Promise<ProductSearchResults> {
    return this.http
      .get(this.apiUrlSearchAllProducts, {
        params: new HttpParams({
          fromObject: {
            actual_vendor_id,
            q,
            from: from.toString(),
            size: size.toString()
          }
        })
      })
      .toPromise()
      .then((response: any) => response.data as ProductSearchResults);
  }

  createUnNormalizedProduct(product: Product): Promise<Product> {
    return this.http
      .post(this.apiUrlCreateUnNormalizedProduct, product)
      .toPromise()
      .then((response: any) => response.data.product as Product);
  }


  getProductsByIds(ids: Array<string>): Observable<Product[]> {
    return this.http
      .get(`${this.apiUrLProductsByIds}`, {
        params: new HttpParams({
          fromObject: {'ids[]': ids}
        })
      })
      .map((response: any) => response.data.products as Product[]);
  }

  getProductsByIdsV2(ids: Array<string>): Observable<Product[]> {
    return this.http
      .post(`${this.apiUrLProductsByIdsV2}`, [...ids])
      .map((response: any) => response as Product[]);
  }

  getTenantVendorProductsV2(tenant_vendor_id: string): Observable<Product[]> {
    return this.http
      .get(`${this.apiUrLProductsByTenantVendorV2}/${tenant_vendor_id}`)
      .map((response: any) => response as Product[]);
  }

  deleteProductElastic(id: String): Observable<Product> {
    return this.http
      .delete(`${this.apiUrLProducts}/${id}`)
      .map((response: any) => {
        return response.data.product as Product
      });
  }

}
