<div>
  <mi-server-error [error]="error"></mi-server-error>

  <mi-opacity-loader [ready]="searchLoaded">
    <div class="mi-grid-aside-basic">
      <div class="col-all">
        <mi-advanced-search-header-v2
          searchType="SERVICE"
          saveSearch="true">
        </mi-advanced-search-header-v2>

        
        <mi-applied-search-filters-v2 [nonRemovableFilters]="['service_status']"></mi-applied-search-filters-v2>
        <mi-advanced-search-service-results-table-v2></mi-advanced-search-service-results-table-v2>
        <mi-advanced-search-load-more-v2></mi-advanced-search-load-more-v2>
      </div>
    </div>
  </mi-opacity-loader>

</div>