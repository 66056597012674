import {
  Component,
  Input
} from "@angular/core";

const AWSPath = "https://miso3-uploads-public.s3.amazonaws.com";

@Component({
  selector: 'mi-vendor-logo',
  template: `
    <div [ngClass]="{'mi-vendor-logo': true, 'no-logo': !_url}">
      <img *ngIf="_url" src="{{_url}}" alt="{{name}}" >
    </div>
  `
})

export class VendorLogoComponent {
  _url: String = '';
  @Input() name: String = '';

  @Input()
  set url(value: any) {
    if (value) {
      if (value.full_path) {
        this._url = value.full_path;
      } else if (value.indexOf("/miyagi") == 0) {
        this._url = AWSPath + value;
      }
      else {
        this._url = value;
      }
    } else {
      this._url = '';
    }
  }

}
