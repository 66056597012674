import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as pageAlertReducer from '../../state/page-alert/reducers';

@Component({
  selector: 'mi-page-alert',
  template: `
  <mi-alert-item
    *ngFor="let alert of alerts$ | async"
    [alertType]="alert.alertType">
    <span [innerHTML]="alert.message"></span>
  </mi-alert-item>
`,
})
export class MiPageAlertComponent implements OnInit {

  alerts$: Observable<Array<any>>;

  constructor(
    private store: Store<pageAlertReducer.State>
  ) {}

  ngOnInit() {
    this.alerts$ =  this.store.select(pageAlertReducer.getCurrentPageAlerts);
  }
}
