import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { MiServiceDetails } from "src/shared/models/MiService";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ParentServiceGroupService } from 'src/libs/api2/parent-service-group-v2/parent-service-group.service';
import { SortableTableColumn, SortableTemplatesEnum } from "src/shared/ng-models/SortableTableColumn.interface";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import { Store } from "@ngrx/store";
import * as SDReducer from "../../../../src/tenant/pages/services/detail/state/reducers";
import { ToastrService } from 'ngx-toastr';
import { ServiceListingColumnsInitial } from "src/shared/models/ServiceV2";

@Component({
  templateUrl: './select-children-service-modal.component.html',
  styleUrls: ['./select-children-service-modal.component.scss']
})

export class SelectChildrenServiceModalComponent extends AbstractPageComponent implements OnInit {
  parentServiceGroup: any;
  vendorName: string;
  error: string = '';
  serviceRow: any[];
  serviceColumns: any[];
  selectedServiceDisplayIds: string[] = [];
  title: string = "Select Services";
  loadingMore: boolean = false;
  resultsLoadedFirstTime: boolean = false;
  resultsLoaded: boolean = false;
  resultsError: any = '';
  results: any;
  lastQuery: any = {};
  total: number = 0;
  page: number = 0;
  pageSize: number = 5;
  cursor: number = 0;
  searchTimeout: any;
  searchInput = new Subject<string>();
  serviceDetails: MiServiceDetails
  serviceList: any[]
  services: any[]
  addingServicesInProgress: boolean = false
  searchParam: string
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  hasLoadMore: boolean = true;

  constructor(
    private modal: ModalRef,
    private parentServiceGroupService: ParentServiceGroupService,
    private serviceV2Service: ServiceV2Service,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
          }
        });

    this.serviceColumns = [
      {
        name: "MISO ID",
        prop: 'display_id',
        cellTemplate: this.checkboxTpl,
        className: 'no-wrap'
      },
      {
        name: 'Vendor',
        prop: 'vendor_name',
      },
      {
        name: 'Product',
        prop: 'product_name',
        sortable: true
      },
      {
        name: 'Account ID',
        prop: 'account_id_label',
        sortable: true
      },
      {
        name: 'Service ID',
        prop: 'service_identifier',
        sortable: true
      },
      {
        name: "CMRC",
        prop: 'cmrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: true
      },
      {
        name: "Currency",
        prop: 'currency_code',
        sortable: true
      }
    ] as Array<SortableTableColumn>;

    this.searchInput.pipe(debounceTime(1500))
      .subscribe(q => {
        this.searchParam = q
        this.fetchResults();
      });
    this.fetchResults();
  }

  close() {
    this.modal.close({ display_ids: this.selectedServiceDisplayIds });
  }

  onAddServices(): void {
    this.addingServicesInProgress = true
    this.parentServiceGroupService.addServiceToParent(this.serviceDetails.general.parent_service_group_id, this.selectedServiceDisplayIds)
      .takeUntil(this.destroy$)
      .subscribe(parent => {
        this.toastr.success(`${this.selectedServiceDisplayIds.length} Service added successfully`);
        this.addingServicesInProgress = false
        this.close()
      }, e => {
        this.error
        this.toastr.error(`Facing issue while Adding services to parent`);
        this.addingServicesInProgress = false
      });
  }

  onSelect(evt, display_id) {
    if (evt.target.checked) {
      this.selectedServiceDisplayIds.push(display_id);
    } else {
      this.selectedServiceDisplayIds = this.selectedServiceDisplayIds.filter(value => {
        return value !== display_id;
      });
    }
  }

  onSearchTermChanged(q = '') {
    this.loadingMore = true;
    this.resultsLoadedFirstTime = false;
    this.hasLoadMore = true;
    this.page = 0
    this.serviceList = []
    if ((this.lastQuery.q || "") == (q || "")) {
      this.fetchResults()
    };
    this.searchInput.next(q);
  }

  onClearSearch() {
    this.fetchResults();
    this.searchInput.next('');
  }

  loadMoreClicked($event) {
    $event.preventDefault();
    this.loadingMore = true;
    this.fetchResults(this.page + 1);
  }

  fetchResults(page: number = 0) {
    this.loadingMore = true
    this.resultsLoaded = false;
    this.resultsError = false;
    this.page = page;

    const filters = {
      "pageSize": 5,
      "page_number": this.page,
      "tenant_id": this.serviceDetails.general.tenant_id,
      "search": this.searchParam,
      "sort": {
        "field_name": null,
        "sort_order": null
      },
      "filters": [
        {
          "field_name": "purchasing_vendor_name",
          "type": "option",
          "range": null,
          "is_custom_field": false,
          "is_tag": false,
          "is_location": false,
          "locations": null,
          "range_min": null,
          "range_max": null,
          "date_range_min": null,
          "date_range_max": null,
          "values": [
            this.serviceDetails.vendor.purchasing_vendor_name
          ]
        },
        {
          "field_name": "service_status",
          "type": "option",
          "range": null,
          "is_custom_field": false,
          "is_tag": false,
          "is_location": false,
          "locations": null,
          "range_min": null,
          "range_max": null,
          "date_range_min": null,
          "date_range_max": null,
          "values": [
            "active"
          ]
        },
        {
          "field_name": "family_count",
          "type": "range",
          "is_custom_field": false,
          "is_tag": false,
          "is_location": false,
          "range_max": 1,
          "range_min": 1
        }
      ]
    }

    //filters["cursor"] = 0;
    if(this.resultsLoadedFirstTime) {
      filters["cursor"] = this.cursor;
    }    
    filters["columns"] = ServiceListingColumnsInitial;
    this.serviceV2Service.getServices(filters)
      .subscribe(results => {
        this.resultsLoaded = true;
        this.resultsLoadedFirstTime = true;
        this.total = results.total;
        this.loadingMore = false;
        this.loadingMore = false;
        this.cursor = results.cursor;
        if(results.data.length == 0) {
          this.hasLoadMore = false;
        }

        const modifiedServices = results.data.map((result) => {
          return {
            display_id: result.miso_service.general.service_display_id,
            vendor_name: result.miso_service.vendor.purchasing_vendor_name,
            product_name: result.miso_service.service_data.product_name,
            account_id_label: result.miso_service.vendor.account_id_label,
            service_identifier: result.miso_service.service_data.service_identifier,
            cmrc: result.miso_service.service_data.cmrc,
            currency_code: result.miso_service.service_data.currency
          }
        })

        if (page > 0) {
          this.serviceList.push(...modifiedServices);
        } else {
          this.serviceList = modifiedServices
        }
      },
        (e => {
          this.resultsLoaded = true;
          this.resultsError = e
        })
      );
  }
}