<mi-page-header
  title="Create Attachment"
  [breadcrumbs]="breadcrumbs">
  <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<mi-associated-document-form
  *ngIf="vendor"
  [vendor]="vendor"
  (associatedDocumentCreated)="onAssociatedDocumentCreated($event)"
  (canceled)="onAssociatedDocumentCancelled()">
</mi-associated-document-form>
