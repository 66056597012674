<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide no-padding-top">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <div class="add-payment-container" id="add-payment-container">

    </div>
    <div class="default-payment-selection-container">
      <div class="checkbox-container">
        <input type="checkbox" (change)="toggleDefaultPaymentSelection($event)" [disabled]="defaultLocked" [checked]="defaultLocked">
      </div>
      <div class="default-payment-text-container">
        <label>Set this as my default payment method</label>
      </div>      
    </div>
  </modal-content>
  <modal-footer>
    <div class="cta">
      <button (click)="createPaymentMethod()" [disabled]="!cardValid || loading"
              mi-button buttonTheme="dark-theme">
        {{addText}}
      </button>
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        Cancel
      </button>
    </div>
  </modal-footer>
</modal>