import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {Store} from '@ngrx/store';
import {TenantEmailService} from '../../../../libs/api/tenant/tenantEmail.service';
import {TenantNoteService} from '../../../../libs/api/tenant/tenantNote.service';
import {
  EMAIL_CC,
  EMAIL_BCC,
  EMAIL_TO
} from '../../../../common/utils/sharedConstants';
import {ActivatedRoute, Params} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../../shared/logger';
import {Email} from '../../../../shared/models/Email.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import { Title } from '@angular/platform-browser';

const log = createLogger(LOG_LEVELS.PAGE);


@Component({
  templateUrl: './email-detail.component.html',
  styleUrls: ['./email-detail.component.scss']
})

export class EmailDetailComponent extends AbstractPageComponent implements OnInit {

  protected readonly NO_DATA: string = NO_DATA;
  blockerStyle: any = {};
  breadcrumbs: Link[];
  error: string;
  emailId: string;
  email: Email;
  frameStyle: any = {};
  headerLinks: any[] = [];
  loaded: boolean = false;
  pageTitle: string;
  sentEmail: any;
  ccEmails: Array<object>;
  toEmails: Array<object>;
  title: string = 'Email Detail';
  showServiceIds: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private store: Store<ASReducer.State>,
              private tenantEmailService: TenantEmailService,
              private tenantNoteService: TenantNoteService,
              private titleService: Title) {
    super();
  }

  getSentFromEmail() {
    this.sentEmail = this.email.recipients.find(recipient => recipient.type === EMAIL_BCC).email;
  }


  getCCEmails() {
    this.ccEmails = this.email.recipients.filter(recipient => recipient.type === EMAIL_CC)
      .map(recipient => {
        return {
          display_id: recipient.display_id || '',
          email: recipient.email
        };
      });
  }

  getToEmails() {
    this.toEmails = this.email.recipients.filter(recipient => recipient.type === EMAIL_TO)
      .map(recipient => {
        return {
          display_id: recipient.display_id || '',
          email: recipient.email
        };
      });
  }


  ngOnInit(): void {

    this.loaded = false;
    this.activatedRoute
      .params
      .switchMap((queryParams: Params) => {
        this.emailId = queryParams._id;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            internal: true, url: '/'
          },
          {
            label: 'Email Detail',
            internal: true, url: '/emails'
          }
        ];
        return this.tenantEmailService.getEmail(this.emailId);
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((email: Email) => {
        this.titleService.setTitle(`Email - ${email.type.replace("_", " ")} - Services (${email.service_display_ids.join(" ")}) - MISO3`);
        this.email = email;
        this.getSentFromEmail();
        this.getCCEmails();
        this.getToEmails();
        if (this.email.service_display_ids) {
          this.title = `Email Regarding ${this.email.service_display_ids.join(', ')}`;
        }
        this.loaded = true;
      });


  }

  showServices(boolean) {
    this.showServiceIds = boolean;
  }

}
