import { Vendor } from "./Vendor";

export const event_types = [{
  key: 'Auto Renew',
  value: 'EVENT_TYPE_AUTO_RENEW'},
  {key: 'Cancel',
  value: 'EVENT_TYPE_AUTO_CANCELLATION'}];

export class TermsAndConditions {
  _id?: string;
  agreement_link?: string;
  cancellation_instructions: string;
  days_to_notify?: number;
  display_id?: string;
  event_type: string;
  label: string;
  owner?: string;
  tenant_vendor?: string;
  auto_renew_length?: number;
  updated_at?: Date;
  vendor?: Vendor;
}
