import { Component, TemplateRef, ViewChild, OnInit } from "@angular/core";
import { SectionDataTableColumn } from "../../../ng-models/SectionDataColumn.interface";
import { AbstractPageComponent } from "../../../pages/abstract-page/abstract-page.component";
import { combineLatest } from "rxjs";
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { ActivatedRoute, Params } from '@angular/router';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { PaginationPayload } from 'src/shared/models/MiPagination';

@Component({
  selector: 'mi-advanced-search-contact-results',
  templateUrl: './advanced-search-contact-results.component.html',
  styleUrls: ['./advanced-search-contact-results.component.scss']
})

export class AdvancedSearchContactResultsComponent extends AbstractPageComponent implements OnInit {

  hits: any[];
  resultsLoaded: boolean;
  searchString: string = ''
  pageNumber: number
  pageSizeData: number[] = [10, 20, 30, 40, 50];
  selectedPageSize: number = this.pageSizeData[0];
  totalRow: number
  totalPages: number = 1
  error: string
  tenantVendorId: string
  contactsColumns: SectionDataTableColumn[] = [];

  @ViewChild('contactTmpl', { static: true }) contactTmpl: TemplateRef<any>;
  @ViewChild('contactStatusTmpl', { static: true }) contactStatusTmpl: TemplateRef<any>;
  @ViewChild('officePhoneTmpl', { static: true }) officePhoneTmpl: TemplateRef<any>;
  @ViewChild('mobilePhoneTmpl', { static: true }) mobilePhoneTmpl: TemplateRef<any>;


  constructor(
    private contactV2Service: ContactV2Service,
    private activatedRoute: ActivatedRoute,
    private tenantVendorService: TenantVendors,
  ) { super() }

  ngOnInit() {
    this.activatedRoute.parent.params
      .switchMap((queryParams: Params) => { return combineLatest(this.tenantVendorService.getVendorByDisplayId(queryParams.display_id)) })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe(([vendorData]: any[]) => {
        this.tenantVendorId = vendorData.tenant_vendor_id
        this.getTenantVendorContacts()
      });
  }

  getTenantVendorContacts() {
    this.resultsLoaded = false;
    this.contactV2Service
      .getContactsByVendor(this.tenantVendorId, this.searchString, this.pageNumber, this.selectedPageSize)
      .toPromise()
      .then(data => {
        this.initializeTable();
        this.resultsLoaded = true;
        this.hits = data.data
        this.totalRow = data.total
        this.totalPages = this.totalRow > 0 ? Math.ceil(this.totalRow / this.selectedPageSize) : 1
      }
      )
      .catch((e) => (this.error = e));
  }

  initializeTable() {
    this.contactsColumns = [
      {
        name: "Contact Name",
        prop: 'full_name',
        cellTemplate: this.contactTmpl,
        sortable: true
      },
      {
        name: "Email",
        prop: 'email',
        sortable: true
      },
      {
        name: "Mobile Phone",
        prop: 'mobile_phone',
        sortable: false,
        cellTemplate: this.mobilePhoneTmpl,
      },
      {
        name: "Office Phone",
        prop: 'office_phone',
        cellTemplate: this.officePhoneTmpl,
        sortable: false
      },
      {
        name: "Role",
        prop: 'function',
        sortable: true
      },
      {
        name: 'Contact Status',
        prop: 'is_active',
        cellTemplate: this.contactStatusTmpl,
        sortable: true
      },
    ];
  }

  paginationChangeHandler(paginationPayload: PaginationPayload) {
    this.pageNumber = paginationPayload.selectedPageNumber - 1
    this.selectedPageSize = paginationPayload.selectedPageSize
    this.getTenantVendorContacts()
  }

  searchParamChangeHandler(event) {
    this.pageNumber = 0
    this.searchString = event
    this.getTenantVendorContacts()
  }

}