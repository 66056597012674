<modal class="wide">
  <modal-header>
    <h2>
      {{items && items.length? "Select Report": "Create a New Report"}}
    </h2>
  </modal-header>
  <modal-content>
    <div *ngIf="loaded; else loading">

      <div class="results" *ngIf="items && items.length; else noReport">
        <mi-sortable-table 
          [rows]="items" 
          [columns]="reportTableColumns">
        </mi-sortable-table>

        <hr/>
      </div>

      <form (ngSubmit)="onFormSubmit(form.value, $event)" *ngIf="form !== null" [formGroup]="form" autocomplete="off">
        <h3 class="report-select">
          Create a New Report
        </h3>
        <mi-extended-input
                            [inputControl]="form.controls['label']"
                            [inputErrors]="form.controls['label'].errors"
                            [inputTouched]="form.controls['label'].touched"
                            class="half-column"
                            [errorDefs]="{
                              required: 'Please enter a label.',
                              maxlength: 'Label cannot be longer than 200 characters long.',
                              minlength: 'Label must be at least 2 characters long'}">
          <input class="form-control"
                  formControlName="label"
                  placeholder="Please enter label for the Report."
                  type="text"
                  tabindex="1">
        </mi-extended-input>
        
        <div class="button-container">
          <a (click)="onCancel(false)"
              buttonTheme="light-theme"
              class="button--cancel"
              mi-button>
            Cancel
          </a>
      
          <button
                  [disabled]="!form.valid"
                  mi-button
                  type="submit">
            {{action_type==="SERVICE_REPORT"? "Create Report": "Save View"}}
          </button>
        </div>
      </form>

    </div>

  </modal-content>
</modal>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #noReport>
  <p>
    You are about the create new report utilizing current search criteria.
  </p>
  <p>
    Please enter your report name below.
  </p>
</ng-template>

<ng-template #changeViewTpl let-row="row" let-value="value">
  <a class="report-select" (click)="applySelection(row)">
    {{value}}
  </a>
</ng-template>

<ng-template #updateReportTpl let-row="row" let-value="value">
  <a class="report-select" (click)="updateReport(row)">
    Update
  </a>
</ng-template>

<ng-template #DeleteTpl let-row="row" let-value="value">
  <a class="report-delete" (click)="updateReport(row)">
    Delete
  </a>
</ng-template>