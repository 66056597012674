import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import { CanDelete } from "../../../../../shared/models/CanDelete";
import { PageAlertAddAction } from "../../../../../shared/state/page-alert/actions";
import { Store } from "@ngrx/store";
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import { TenantProductsService } from "../../../../../libs/api/tenant/tenantProducts";
import { Product } from '../../../../../shared/models/Product';
import { TenantVendors } from '../../../../../libs/api/tenant/tenantVendors';

@Component({
  templateUrl: './product-delete.component.html',
  styleUrls: ['./product-delete.component.scss']
})

export class ProductDeleteComponent extends AbstractPageComponent implements OnInit {

  productId: string;
  error: string;
  exitRoute: string;
  canDelete: any;
  canDeleteMode: boolean = true;
  loaded:boolean = true;
  product:Product;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantProductService: TenantProductsService,
              private router: Router,
              private tenantVendorsService: TenantVendors,
              private store: Store<ASReducer.State>) {
    super();
  }

  ngOnInit() {
    this.productId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    this.exitRoute = `/products/${this.productId}/edit`;

    this.tenantProductService.getProductByDisplayId(this.productId)
      .then((res: Product) => {
        this.product = res;
        //check product to have dependents
        if (this.product.dependents == null) {
          this.product.dependents = [];
        } else {
          this.canDeleteMode = false;
        }
        this.tenantVendorsService.getVendorBytenantVendorId(this.product.tenant_vendor_id).
          then((tenantVendor) => {
            this.product.product_vendor = {
              ...tenantVendor
            }
          });
      })
      .catch(e => this.error = e)

   
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete(){
    this.tenantProductService.deleteProduct(this.product.id )
    .then(async () =>{
      await this.tenantProductService.deleteIndexProduct(this.product.id, this.product.tenant_vendor_id);
      this.loaded = false;
      this.store.dispatch(
        new PageAlertAddAction({
          message: `Product was successfully deleted.`,
          alertType: "SUCCESS"
        })
      );
        setTimeout(()=>{
          this.router.navigate([`/vendors/${this.product.product_vendor.display_id}/products`]);
        }, 1000);
      },
      e=> this.error = e
    )
  }

}
