import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as OrderReducers from '../../../create/state/reducer';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {UpdateOrderAction} from '../../../create/state/actions';


@Component({
  selector: 'mi-service-contacts',
  templateUrl: './service-contacts.component.html',
  styleUrls: ['./service-contacts.component.scss']
})

export class ServiceContactsComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  step: string = OrderSteps.ServiceContacts;
    
  constructor(
    private store: Store<OrderState>
  ) {
    super();
  }


  ngOnInit() {

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => this.error = e
      );

  }


  onContactsComplete(data: any) {
    this.store.dispatch(new UpdateOrderAction(
      {
        index: this.index + 1,
        maxIndex: Math.max(this.orderState.maxIndex, this.index + 1),
        service: {
          ...this.orderState.service,
          ...data
        }
      }
    ));
  }

}
