<div class="parent-service-details-header">

  <div class="header-item">
    <mi-parent-service-details-header-vendor>
    </mi-parent-service-details-header-vendor>
  </div>

  <div class="header-item info">
    <mi-parent-service-details-header-info>
    </mi-parent-service-details-header-info>
  </div>

</div>
