<div class="tile">

  <div class="header"
       [ngClass]="{'archived': (service.current_notification && service.current_notification.status === ARCHIVED)}">

    <div class="content-left">
      <ng-container *ngIf="service.equipments.length===0">
        <div class="logo-container">
          <mi-vendor-logo
            *ngIf="service.purchasing_vendor.logo"
            [url]="service.purchasing_vendor.logo"></mi-vendor-logo>
        </div>
  
        <span class="vendor">{{service.purchasing_vendor.vendor_name}}</span>
  
        <mi-id-link
          [id]="service.display_id">
        </mi-id-link>
      </ng-container>

      <ng-container *ngIf="service.equipments_v2.length!==0">
        <div class="logo-container">
          <mi-vendor-logo
            *ngIf="service.equipments_v2[0].equipment_catalog.vendor && service.equipments_v2[0].equipment_catalog.vendor.logo"
            [url]="service.equipments_v2[0].equipment_catalog.vendor.logo"></mi-vendor-logo>
        </div>
        <span class="vendor">{{service.equipments_v2[0].equipment_catalog.vendor.vendor_name}} | {{service.equipments_v2[0].name}}</span> &mdash;
        <mi-id-link [id]="service.display_id"></mi-id-link>
      </ng-container>

    </div>

    <div class="content-right">

      <ng-container [ngSwitch]="mode">
        <div *ngSwitchCase="parentServiceTileMode.NORMAL">
          <mi-toggle toggleTitle="Notifications:"
                     [showToggleText]="true"
                     [selected]="!service.notification_preferences.ignore"
                     (onChange)="onToggleNotification($event)"></mi-toggle>
        </div>
        <a class="add-to-parent"
           *ngIf="mode !== parentServiceTileMode.NORMAL"
           [ngClass]="statusStyles"
           (click)="onAddService()">
          Add to Parent
        </a>
        <a class="remove-from-parent"
           *ngIf="mode !== parentServiceTileMode.NORMAL"
           [ngClass]="statusStyles"
           (click)="onRemoveService()">
          Remove From Parent
        </a>
      </ng-container>

      <a class="view-details" routerLink="/services/{{service.display_id}}">View Details</a>

    </div>

  </div>

  <mi-advanced-search-service-details
    [miService]="service"
    class="searchResult event-tile"
    [ngClass]="statusStyles">
  </mi-advanced-search-service-details>

  <div class="tile-overlay" *ngIf="!loaded">
    <mi-loading-animation></mi-loading-animation>
  </div>

</div>



