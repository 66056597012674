import { Component, OnInit } from "@angular/core";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Store } from "@ngrx/store";
import * as SDReducer from '../../state/reducers';
import { MiServiceDetails, ServiceCalculationDetails } from "../../../../../../shared/models/MiService";

@Component({
  selector: 'mi-service-details-group-economics',
  templateUrl: './service-details-header-group-economics.component.html',
  styleUrls: ['./service-details-header-group-economics.component.scss']
})

export class ServiceDetailsHeaderGroupEconomicsComponent extends AbstractPageComponent implements OnInit {
  serviceDetails: MiServiceDetails;
  serviceCalculationDetails: ServiceCalculationDetails
  parentServiceCalculationDetails: ServiceCalculationDetails
  constructor(private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit() {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails && data.parentServiceCalculationDetails) {
            this.serviceDetails = data.serviceDetails
            this.parentServiceCalculationDetails = data.parentServiceCalculationDetails
          }
        }
      );
  }
}
