<p *ngIf="!order.miservices.length" class="no-results">
    {{'ORDER_INSTRUCTIONS.NO_SERVICE_ASSOCIATED' | translate }}
</p>
<table class="services" *ngIf="order.miservices.length">
  <thead>
  <td>Services</td>
  <td>Cost Type</td>
  <td>Unit Cost USD</td>
  <td>Totals</td>
  </thead>
  <tbody>
  <tr *ngFor="let miservice of order.miservices">
    <td>
      <table class="service-props main-service-props">
        <tr>
          <td class="strong">MISO ID</td>
          <td>
            <mi-id-link [id]="miservice.display_id"></mi-id-link>
          </td>
          <td class="strong">Units</td>
          <td>{{miservice.current_financial.quantity}}</td>
        </tr>
        <tr>
          <td class="strong">Vendor</td>
          <td>
            <mi-id-link [id]="miservice.purchasing_vendor.display_id"></mi-id-link>
            {{miservice.purchasing_vendor.vendor_name}}
          </td>
          <td class="strong">Service ID</td>
          <td>{{miservice.service_identifier ? miservice.service_identifier : '---'}}</td>
        </tr>
        <tr>
          <td class="strong">Product</td>
          <td>{{miservice.product ? miservice.product.product_name : '---'}}</td>
          <td class="strong">Billing Frequency</td>
          <td>
            {{'MONTHS_QTY'
            | translate:{count: miservice.current_financial.billing_frequency}
            | translateSelector: miservice.current_financial.billing_frequency}}
          </td>
        </tr>
      </table>
    </td>
    <td>
      <table class="service-props">
        <tr>
          <td>Recurring Unit Cost</td>
        </tr>
        <tr>
          <td>Non Recurring Cost</td>
        </tr>
      </table>
    </td>
    <td>
      <table class="service-props">
        <tr>
          <td>{{miservice.current_financial.cost | round | currency:'USD':'symbol':'1.00'}}</td>
        </tr>
        <tr>
          <td>{{ (miservice.current_financial.non_recurring_cost || 0) | round | currency:'USD':'symbol':'1.00'}}</td>
        </tr>
      </table>
    </td>
    <td>
      <table class="service-props">
        <tr>
          <td>{{ (miservice.current_financial.cost * miservice.current_financial.quantity) | round |
            currency:'USD':'symbol':'1.00'}}
          </td>
        </tr>
        <tr>
          <td>{{((miservice.current_financial.non_recurring_cost || 0) * miservice.current_financial.quantity ) | round
            | currency:'USD':'symbol':'1.00'}}
          </td>
        </tr>
      </table>
    </td>
  </tr>
  <tbody>
</table>

<a class="add-service-cta"
   mi-button
   routerLink="./add-service">
  Add Service
</a>

