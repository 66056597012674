import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Timezone} from './ng-models/Timezone.interface';
import {MiService} from './models/MiService';
import {ServiceStatus} from './models/ServiceStatus.interface';
import {
  DATA_INTEGRITY_COMPLETE,
  EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_RE_TERM,
  SERVICE_ARCHIVED,
  SERVICE_UNKNOWN
} from '../common/utils/sharedConstants';
import {MiParentService} from './models/MiParentService';
import {ParentServiceStatus} from './models/ParentServiceStatus.interface';
import { getCurrencySymbol, formatCurrency } from '@angular/common';
import FrontEndSettingsUtil from './utils/frontEndSettings.util';

export function guid(useShort: boolean = false) {

  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return useShort ? s4() + s4() : s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();

}

export function scrollTo(hash: string, showHighlight: boolean = true, useParent: boolean = false) {

  if (hash) {
    let elemHidden: any = document.getElementById("changeTab");
    if(elemHidden){
      //debugger;
      elemHidden.value=hash;
      var event = new Event('change');
      elemHidden.dispatchEvent(event);
    }
    let elem = document.getElementById(hash);

    if (useParent) {
      elem = elem.parentElement;
    }

    if (elem) {

      elem.style.display = elem.style.display || 'block';

      if (showHighlight) {
        elem.classList.remove('highlight');
        elem.classList.add('highlight');
      }

      (function (element) {
        setTimeout(function () {
          if (detectIE()) {
            setTimeout(() => {
              window.scroll(0, element.offsetTop);
            }, 500);
          } else {
            window.scroll(
              {
                top: element.offsetTop,
                behavior: 'smooth'
              });
          }
        }, 100);
      })(elem);

      if (showHighlight) {
        setTimeout(() => {
          elem.classList.remove('highlight');
        }, 2500);
      }

    } else {
      // console.warn('Could not find an element named', hash);
    }
  }
}

export function print(e: any = null): void {
  if (e && e.preventDefault && e.stopPropagation) {
    e.preventDefault();
    e.stopPropagation();
  }
  window.print();
}

@Pipe({name: 'numberToText'})
export class NumberToTextPipe implements PipeTransform {
  transform(value: number): string {
    if(value < 1000) {
      return value.toString();
    }
    else if(value < 1000000) {
      return (value / 1000).toString() + "K";
    }
    else if(value < 1000000000) {
      return (value / 1000000).toString() + "M";
    }
  }
}

@Pipe({name: 'round'})
export class RoundPipe implements PipeTransform {
  transform(value: number): number {
    return Math.round(value);
  }
}

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    // console.log(this.sanitized.bypassSecurityTrustHtml(value));
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({name: 'limitText'})
export class LimitTextPipe implements PipeTransform {
  transform(value: string, args: string = '100'): string {
    const limit = args ? parseInt(args, 100) : 100;
    const trail = '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

@Pipe({name: 'safeSourceUrl'})
export class SafeSourceUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({name: 'abs'})
export class AbsolutePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(input: number) {
    return Math.abs(input);
  }
}

@Pipe({name: 'reverse'})
export class ReversePipe implements PipeTransform {
  transform(value) {
    return value.slice().reverse();
  }
}

@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {
  transform(size: any) {
    if(isNaN(size))
      return size;
    if(size>1024*1024)
      return (size / (1024 * 1024)).toFixed(2) + ' MB';
    else
      return (size / (1024)).toFixed(2) + ' KB';
  }
}

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(code: any) {
    let symbol = code;
    if(code)
      symbol = getCurrencySymbol(code, "wide");
    return symbol;
  }
}

@Pipe({
  name: 'tenantcurrency',
})
export class TenantCurrencyPipe implements PipeTransform {
  transform(
      value: number,
      digitsInfo: string = '3.2-2',
      locale: string = 'en',
  ): string | null {
    //debugger;
      return formatCurrency(
        value,
        locale,
        getCurrencySymbol(FrontEndSettingsUtil.getCurrencyCode(), 'wide'),
        FrontEndSettingsUtil.getCurrencyCode(),
        digitsInfo,
      );
  }
}

@Pipe({
  name: 'tenantcurrencyusd',
})
export class TenantCurrencyUsdPipe implements PipeTransform {
  transform(
      value: number,
      digitsInfo: string = '3.2-2',
      locale: string = 'en',
  ): string | null {
    //debugger;
      return formatCurrency(
        value,
        locale,
        getCurrencySymbol("USD", 'wide'),
        "USD",
        digitsInfo,
      );
  }
}

@Pipe({
  name: "trackingPageCodeValue"
})
export class TrackingPageCodeValuePipe implements PipeTransform {
  transform(
    code: number
  ): string | null {
    switch(code) {
      case 0: return "Getting Started";
      case 1: return "About Your Organization";
      case 2: return "About You";
      case 3: return "Select Your Plan";
      case 4: return "Checkout - Plan Summary";
      case 5: return "Checkout - Payment Information";
      case 6: return "Checkout - Billing Address";
      case 7: return "Checkout - Finalize";
      case 8: return "Checkout - Account Creation Started";
      case 9: return "Checkout - Account Creation Completed";
      default: return "Unknown";
    }
  }
}

export function searchTimezone(term: string, item: Timezone) {
  /*term = term.toLocaleLowerCase().replace(/ /g, '_');
  return item.utc.some(utc => {
    return utc.toLocaleLowerCase().indexOf(term) > -1;
  });*/
  return item.text.toLowerCase().indexOf(term.toLowerCase()) > -1;
}

function detectIE() {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result ...

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf('MSIE ');

  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;

}

export function serviceStatusHelper(service: MiService): ServiceStatus {

  const dataIncomplete = service &&
    service.data_integrity &&
    service.data_integrity.overall_status !== DATA_INTEGRITY_COMPLETE;
  const isArchived = service &&
    service.current_notification &&
    service.current_notification.status === SERVICE_ARCHIVED;
  const pendingActivation = service &&
    service.current_notification &&
    service.current_notification.event_type === EVENT_TYPE_ACTIVATION;
  const pendingAutoCancellation = service &&
    service.current_notification &&
    service.current_notification.event_type === EVENT_TYPE_AUTO_CANCELLATION;
  const pendingCancellation = service &&
    service.current_notification &&
    service.current_notification.event_type === EVENT_TYPE_CANCELLATION;
  const canCreateParent = !service.parent_service &&
    !pendingCancellation;
  const pendingAutoRenew = service &&
    service.current_notification &&
    service.current_notification.event_type === EVENT_TYPE_AUTO_RENEW;
  const statusUnknown = service &&
    service.current_notification &&
    service.current_notification.status === SERVICE_UNKNOWN;
  const canReterm = !statusUnknown &&
    !pendingActivation &&
    !pendingCancellation;
  const pendingReterm: boolean = service &&
    service.current_notification &&
    service.current_notification.event_type === EVENT_TYPE_RE_TERM;
  const nextEventTooDistant = service
    && service.current_notification
    && service.current_notification.notification_days_until_event > 180;
  return {
    isArchived,
    dataIncomplete,
    canCreateParent,
    canReterm,
    nextEventTooDistant,
    pendingActivation,
    pendingAutoCancellation,
    pendingAutoRenew,
    pendingCancellation,
    pendingReterm,
    statusUnknown
  };

}

export function parentServiceStatusHelper(parentService: MiParentService): ParentServiceStatus {

  let cancellationDate: string;

  const allPendingActivation: boolean = (() => {
    return !parentService.services
      .filter((service: MiService) => {
        return service.current_notification &&
          (service.current_notification.event_type !== EVENT_TYPE_ACTIVATION);
      }).length;
  })();

  const allPendingCancellation: boolean = parentService.services.filter((service: MiService) => {
    if (!cancellationDate) {
      cancellationDate = service.current_notification.start_date || null;
    }
    return service &&
      service.current_notification &&
      service.current_notification.start_date &&
      service.current_notification.start_date === cancellationDate &&
      service.current_notification.event_type === 'EVENT_TYPE_CANCELLATION';

  }).length === parentService.services.length;

  const allArchived: boolean = !parentService.services.filter((service: MiService) => {
    return service &&
      service.current_notification &&
      service.current_notification.status !== SERVICE_ARCHIVED;
  }).length;

  const hasAnyStatusUnknown: boolean = !!parentService.services.filter((service: MiService) => {
    return service &&
      service.current_notification &&
      service.current_notification.status === SERVICE_UNKNOWN;
  }).length;

  const nextEventTooDistant: boolean = !parentService.services.filter((service: MiService) => {
    return service
      && service.current_notification
      && service.current_notification.notification_days_until_event < 180;
  }).length;

  const hasFullDataIntegrity: boolean = !parentService.services.filter((service: MiService) => {
    return service &&
      service.data_integrity &&
      service.data_integrity.overall_status !== 'COMPLETE';
  }).length;

  return {
    allArchived,
    allPendingActivation,
    allPendingCancellation,
    hasAnyStatusUnknown,
    hasFullDataIntegrity,
    nextEventTooDistant
  };

}
