import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";

@Component({
  templateUrl: './change-payment-method-modal.component.html',
  styleUrls: ['./change-payment-method-modal.component.scss']
})

export class ChangePaymentMethodModalComponent implements OnInit {

  //error: string = "";
  title: string = "Change Payment Method";

  constructor(
    private modal: ModalRef
  ) {
  }

  ngOnInit(): void {
  }

  onModalClose() {
    this.modal.close({reason: 'User closed', result: false});
  }

  changePaymentMethod() {
    this.modal.close({reason: 'User closed', result: true});
  }
}