<div class="nav-flyout">

  <!-- <div class="logo-container">

    <a [routerLink]="logoUrl">
      <ng-container *ngIf="!isMiyagi;else miyagiLogo">
        <ng-container *ngIf="isBranded;else unbranded">
          <img class="nav-flyout-logo" src="{{tenant.logo.full_path}}">
        </ng-container>
        <ng-template #unbranded>
          <img class="nav-flyout-logo" src="/shared/assets/images/miso3-logo.svg">
        </ng-template>
      </ng-container>
      <ng-template #miyagiLogo>
        <img class="nav-flyout-logo miyagi" src="/shared/assets/images/miyagi-logo.svg">
      </ng-template>
    </a>

  </div> -->

  <div class="main-nav-standard-flyout-links">
    <mi-main-nav-link
      *ngFor="let item of links"
      [linkData]="item"></mi-main-nav-link>
  </div>

  <!-- <div *ngIf="tenant && tenant.logo" class="branded miso-logo">
    <img class="nav-flyout-logo" src="/shared/assets/images/miso3-logo.svg">
  </div> -->

</div>
