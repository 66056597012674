import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TenantMiParentServiceService} from '../../../../../../../libs/api/tenant/tenantMiParentService.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as PSReducer from '../../../state/reducers';
import {Store} from '@ngrx/store';
import {EVENT_TYPE_ACTIVATION} from '../../../../../../../../src/common/utils/sharedConstants';
import {NoteHtml} from '../../../../../../../shared/models/NoteHtml.enum';
import FeDateUtil from '../../../../../../../shared/utils/FeDate.util';
import {MisoCollection} from '../../../../../../../shared/models/MisoCollection.enum';
import {MiParentService} from '../../../../../../../shared/models/MiParentService';
import {MiService} from '../../../../../../../shared/models/MiService';
import {Note} from '../../../../../../../shared/models/Note.interface';
import {ParentServiceLoadSuccess} from '../../../state/actions';
import {TenantNoteService} from '../../../../../../../libs/api/tenant/tenantNote.service';

@Component({
  selector: 'mi-parent-service-confirm-cancellation-form',
  templateUrl: './parent-service-confirm-cancellation-form.component.html',
})

export class ParentServiceConfirmCancellationFormComponent implements OnInit {

  readonly FORM_CONFIRM: string = '[form] confirm';
  readonly FORM_EDIT: string = '[form] edit';
  readonly EVENT_TYPES: any = {
    EVENT_TYPE_ACTIVATION: EVENT_TYPE_ACTIVATION
  };

  @Input() parentService: MiParentService;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();

  confirmCancellationFormGroup: FormGroup;
  error: string = '';
  mode: string = this.FORM_EDIT;
  cancelDateIsBeforeToday: boolean = false;
  defaultCancellationDate: string;

  constructor(private tenantMiParentService: TenantMiParentServiceService,
              private formBuilder: FormBuilder,
              private store: Store<PSReducer.State>,
              private noteService: TenantNoteService) {
    // Nothing.
  }

  ngOnInit() {

    if (this.parentService) {
      this.defaultCancellationDate = this.parentService.services[0].current_notification.start_date;
      this.confirmCancellationFormGroup = this.formBuilder.group({
        service_id: this.parentService.display_id,
        cancellation_date: [this.defaultCancellationDate, Validators.required]
      });
    }

  }

  onDateChange(e): void {
    this.confirmCancellationFormGroup.get('cancellation_date').setValue(e);
    this.cancelDateIsBeforeToday = FeDateUtil.isBeforeToday((new Date(e)));
  }

  onCancelClicked(e): void {
    e.stopPropagation();
    e.preventDefault();
    this.confirmCancellationFormGroup
      .get('cancellation_date')
      .setValue(this.defaultCancellationDate);
    this.canceled.emit();
  }

  isBeforeToday(str: string): boolean {
    const today = new Date();
    const then = new Date(str);

    today.setHours(0, 0, 0, 0);

    return today.getTime() > then.getTime();
  }

  onEditCancellationRequestFormSubmit(data: any) {

    const cancellationDate = this.confirmCancellationFormGroup.get('cancellation_date').value;

    let updatedParentService: MiParentService;

    this.error = '';

    this.tenantMiParentService
      .addCancellation(this.parentService.display_id, cancellationDate)
      .toPromise()
      .then((res) => {
        updatedParentService = res;
        /*
        * Add notes to all the services that are now cancelled.
        */
        let promises = res.services.map((service: MiService) => {
          const note: Note = {
            connections: [{
              document_id: service._id,
              document_collection: MisoCollection.SERVICES
            }],
            html: NoteHtml.CANCELLATION_SCHEDULED_NOTE_HTML
          };
          return this.noteService.createNote(note)
            .toPromise();
        });
        return Promise.all(promises);
      })
      .then(() => {
        // Update the Parent Service:
        this.store.dispatch(new ParentServiceLoadSuccess({parentService: updatedParentService}));
        this.canceled.emit();
      })
      .catch(e => this.error = e);

  }

  onContinueClick() {
    this.mode = this.FORM_CONFIRM;
  }

  onBackClick() {
    this.mode = this.FORM_EDIT;
  }
}
