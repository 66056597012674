import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import { Contact } from '../../../shared/models/Contact';
import { CONTACT_SEARCH_TYPE } from '../../../common/utils/sharedConstants';
// import { SearchQueryParams } from '../../../shared/ng-models/search/SearchQueryParams.interface';
// import { takeUntil } from 'rxjs/operators';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
// import { TenantSearchService } from 'src/libs/api/tenantSearch/tenantSearch.service';
// import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
// import { Store } from "@ngrx/store";
// import * as SDReducer from "../../pages/services/detail/state/reducers";
// import * as appDataReducer from "src/shared/state/app-data/reducers";
import { UsersV2Service } from 'src/libs/api2/users-v2/users-v2.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
// const log = createLogger(LOG_LEVELS.COMPONENT);

export enum FindOrCreateContactComponentSteps {
  CREATE_STATE,
  SEARCH_STATE
};

@Component({
  selector: 'mi-find-or-create-tenant-user',
  templateUrl: './find-or-create-tenant-user.component.html',
  styleUrls: ['./find-or-create-tenant-user.component.scss']
})

export class FindOrCreateTenantUserComponent extends AbstractPageComponent implements OnInit {

  @Output() contactSelected: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() stepChanged: EventEmitter<FindOrCreateContactComponentSteps> = new EventEmitter<FindOrCreateContactComponentSteps>();

  @Input() startState: FindOrCreateContactComponentSteps;
  @Input() createMode: boolean = true;
  @Input() showVendorTab: boolean = false;

  readonly CREATE_STATE = FindOrCreateContactComponentSteps.CREATE_STATE;
  readonly SEARCH_STATE = FindOrCreateContactComponentSteps.SEARCH_STATE;

  readonly INACTIVE: string = "INACTIVE";

  searchTypes: Array<string> = ['', CONTACT_SEARCH_TYPE.ONLY_RECENT_CONTACTS];

  step: FindOrCreateContactComponentSteps = this.SEARCH_STATE;
  @Input() searchString: string = '';
  contacts: any;
  error: string;
  loaded: boolean = false;
  loadingMore: boolean = true;
  searching: boolean = false;
  page: number = 0;
  totalContacts: number;
  totalAllContacts: number;
  totalVendorContacts: number;
  totalRecentContacts: number;
  recentContactIds: Array<string> = [];
  selectedTab: string = '';
  tenant_id: string;
  searchParam = new Subject<string>();
  constructor(
    private usersV2Service: UsersV2Service,
  ) {
    super();
  }

  ngOnInit() {
    // Load the saved contacts:
    this.searchParam.pipe(debounceTime(1000))
      .subscribe(param => {
        this.searchString = param
        this.searchContacts();
      });
    this.searchContacts();
  }

  onLoadMoreClicked($event) {
    $event.preventDefault();
    this.loadingMore = true;
    this.searchContacts(this.page + 1);
  }

  onContactCreated(contact: Contact) {
    this.onSelectContact(contact);
  }

  onSelectContact(contact: Contact) {
    if (contact.status === this.INACTIVE) {
      return;
    }
    this.contactSelected.emit(contact);
  }

  onUpdateSearchString(str = '') {
    this.searchParam.next(str);
  }

  searchContacts(page: number = 1) {
    this.loaded = false;
    const options = { from: 0, size: 10 };
    if (page > 0) {
      options.from = 10 + ((page - 2) * 10);
      options.size = 10;
    }
    // this.page = page;
    this.error = '';
    this.usersV2Service
      .getUsersByVendor(options.from, options.size, this.searchString, '', "email", "ASC", 'cmrc')
      .toPromise()
      .then(data => {
        const contactResponse = data.data
        if (contactResponse.length) {
          this.contacts = contactResponse.map((contact) => {
            return {
              ...contact.user,
              emailLabel: contact.user.email.match(/([A-Z0-9\w.+]+)/)[0],
              emailDomain: contact.user.email.match(/@.*/)[0]
            };
          });
          this.loadingMore = false;
          this.searching = false;
        }
        else {
          this.contacts = []
        }
        this.loaded = true;

      })
      .catch((e) => (this.error = e, this.loaded = true));
  }

  swapTo(newState: FindOrCreateContactComponentSteps) {
    this.stepChanged.emit(newState);
    this.step = newState;
  }

  toggle(index) {
    this.selectedTab = this.searchTypes[index];
    this.page = 1;
    this.searching = true;
    this.searchContacts();
  }

}
