import * as TinyMce from 'tinymce';

export function miTinymceDefaultSettings(): TinyMce.Settings | any {
  return {
    baseURL: '/shared/assets/tinymce',
    tinymceScriptURL: '/shared/assets/tinymce/tinymce.min.js',
    theme_url: '/shared/assets/tinymce/themes/modern/theme.min.js',
    skin_url: '/shared/assets/tinymce/skins/lightgray',
    content_css: '/shared/assets/mi-tiny-content.css',
    plugins : 'anchor autolink link lists',
    branding: false,
    menubar: false,
    statusbar: true,
    elementpath: false,
    //toolbar: false
    toolbar: 'bold italic | bullist numlist | link unlink',
    min_height : "180",
    link_assume_external_targets: true,
    target_list: false,
    link_title: false,
    default_link_target: "_blank"
  };
}
