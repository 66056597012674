import {Component, OnInit} from '@angular/core';
import {TenantMiOrderService} from '../../../../../libs/api/tenant/tenantMiOrder.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MiOrder} from '../../../../../shared/models/MiOrder';
import {Vendor} from '../../../../../shared/models/Vendor';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {minLengthWithTrimmedWhitespace} from "../../../../../shared/shared.validators";
import { Subject } from "rxjs";
import {Link} from "../../../../../shared/ng-models/Link.interface";
import { AccountId } from '../../../../../shared/models/AccountId';

@Component({
  templateUrl: './service-order-edit.component.html'
})
export class ServiceOrderEditComponent implements OnInit {

  error: any;
  submitError: any;
  loaded: boolean = false;
  order: MiOrder;
  vendor: Vendor;
  display_id: string = '';
  breadcrumbs: Link[];
  orderForm: FormGroup;
  commandGetAccountId: Subject<any> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: TenantMiOrderService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {

  }


  onDateChange(date): void {
    this.orderForm.get('order_date').setValue(date);
    this.orderForm.updateValueAndValidity();
  }

  ngOnInit() {

    this.activatedRoute
      .params
      .switchMap( params => this.orderService.getByDisplayId(params.display_id))
      .subscribe(
        (order: MiOrder) => {
          this.order = order;
          this.display_id = order.display_id;

          this.orderForm = this.formBuilder.group({
            vendor: [order.vendor.vendor_name, Validators.required],
            order_date: [order.order_date],
            order_id: [order.order_id || '', Validators.compose([
              Validators.minLength(2),
              minLengthWithTrimmedWhitespace(2)
            ])],
            account_id: [order.account_id, Validators.required]
          });


          this.breadcrumbs = [
            {
              url: '',
              label: 'Dashboard',
              internal: true
            },
            {
              url: '/services',
              label: 'Services',
              internal: true
            },
            {
              url: `/services/orders/summary/${order.display_id}`,
              label: `Order ${order.display_id} Summary`,
              internal: true
            },
            {
              url: `/services/orders/${order.display_id}/summary/edit`,
              label: 'Edit Order',
              internal: true
            }
          ];


          this.loaded = true;
        },
        e => this.error = e);
  }

  onFormSubmit(data: any) {
    // R/T to the component, checking if the selected ID is valid or not:
    this.commandGetAccountId.next('');
  }

  onAccountIdSelected(account_id: AccountId): void {

    const {
      order_date,
      order_id
    } =this.orderForm.value;
    this.submitError = '';

    const newOrder: MiOrder = {
      ...this.order,
      account_id: account_id,
      order_date,
      order_id,
    };

    this.orderService.updateOrder(newOrder)
      .subscribe(
        (order) => {
          this.router.navigateByUrl(`/services/orders/summary/${order.display_id}`);
        },
        (e) => this.submitError = e
      );
  }
}
