<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="vendor; else loading">

  <div class="vendor">
    <mi-vendor-logo [url]="vendor.logo"></mi-vendor-logo>
    {{vendor.vendor_name}}
    - {{vendor.display_id}}
  </div>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
