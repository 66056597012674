import {Component} from "@angular/core";

@Component({
  selector: 'mi-service-details-header',
  templateUrl: './service-details-header.component.html',
  styleUrls: ['./service-details-header.component.scss']
})

export class ServiceDetailsHeaderComponent{
  // Nothing.
}
