import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MiServiceDetails } from "../../../../../../shared/models/MiService";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Store } from "@ngrx/store";
import { CalculationFinancial, Financial } from "../../../../../../shared/models/Financial";
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import { ToastrService } from 'ngx-toastr';
import * as SDActions from '../../state/actions';

@Component(
  {
    selector: 'mi-subsection-economic-details',
    templateUrl: './subsection-economic-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionEconomicDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: Boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  serviceDetails: MiServiceDetails;
  formReady = false;
  updating: boolean = false;
  financialDetails: any
  tenantCurrencyId: string
  constructor(
    private toastr: ToastrService,
    private serviceV2Service: ServiceV2Service,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {

    // this.pageLoaded$ = this.store.select(SDReducer.DSPageLoaded);
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
            this.tenantCurrencyId = this.serviceDetails.service_data.tenant_currency_id
            this.financialDetails = {
              quantity: this.serviceDetails.service_data.quantity,
              non_recurring_cost: this.serviceDetails.service_data.unit_non_recurring_cost != null  ? this.serviceDetails.service_data.unit_non_recurring_cost : 0,
              billing_frequency: this.serviceDetails.service_data.billing_frequency,
              cost: this.serviceDetails.service_data.unit_recurring_cost,
            } as CalculationFinancial
            this.updateFormGroup();
          }
        });
  }

  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

  onUpdateService(data: any): void {
    this.updating = true;
    let financial: Financial = {
      ...data
    };

    this.serviceV2Service.updateServiceEconomics(financial, this.serviceDetails.service_data.service_id)
      .toPromise()
      .then(res => {
        this.dataUpdate.emit(res);
        this.updateServiceDetailsStore.emit();
        this.onToggleExpand();
        this.toastr.success(`Service economics updated successfully`);
      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while updating service economics, Please try again`);
      })
      .finally(() => {
        this.updating = false;
      });
  }

  updateFormGroup(): void {
    this.formReady = true;
  }
}