<ng-container *ngIf="loaded; else loading">
  <div *ngIf="!newAccountId">
    <p>
        <mi-id-link id="{{service.account_id_v2.display_id}}"></mi-id-link>
        {{service.purchasing_vendor.vendor_name}} Account "{{service.account_id_v2.label}}" does not have an assigned account representative.

        Would you like to assign a contact as the account representative?
    </p>
    <button mi-button buttonTheme="dark-theme" 
            (click)="openContactModal(service.account_id_v2)">
      Select a Contact
    </button>

    <div class="button-container">
      <button mi-button
              buttonTheme="light-theme"
              [routerLink]="cancelRoute">
        Cancel
      </button>
      <button mi-button
              buttonTheme="dark-theme"
              (click)="next($event)">
        Skip
      </button>
    </div>
  </div>


  <div *ngIf="newAccountId">
    <p>
      The email will be sent to the Account Representative for 
      <mi-id-link id="{{service.display_id}}"></mi-id-link>
    </p>

    <table class="mi-sort-table fullWidth">

      <thead>
        <tr>
          <th>
            Company
          </th>
          <th>
            Account ID
          </th>
          <th>
            Account Rep
          </th>
        </tr>
      </thead>

        <!-- now do the rows -->
      <tbody> 
        <tr>
          <td>
            <div class="vendor-logo-td">
              <mi-vendor-logo class="vendor-logo"
                *ngIf="service.purchasing_vendor.logo && service.purchasing_vendor.logo.full_path"
                url="{{service.purchasing_vendor.logo.full_path}}" 
                name="{{service.purchasing_vendor.vendor_name}}">
              </mi-vendor-logo> 
              <span class="limit">{{service.purchasing_vendor.vendor_name}}</span>
            </div>
          </td>
          <td>
            <div class="text-align">
              <mi-id-link id="{{service.account_id_v2.display_id}}"></mi-id-link><span class="limit">{{service.account_id_v2.label}}</span>
            </div>
          </td>
          <td>
            <div class="text-align">
              <mi-id-link id="{{newAccountId.contact.display_id}}"></mi-id-link><span class="limit">{{newAccountId.contact.full_name}}</span>
            </div>
          </td>
        </tr>
      </tbody>

    </table>

    <div class="button-container">
      <button mi-button
              buttonTheme="light-theme"
              [routerLink]="cancelRoute">
        Cancel
      </button>
      <button mi-button
              buttonTheme="dark-theme"
              (click)="next($event)">
        Continue
      </button>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>