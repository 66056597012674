import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as routerReducer from '../../../../shared/state/router/reducers';
import {AbstractPageComponent} from 'src/shared/pages/abstract-page/abstract-page.component';

@Component({
  selector: 'mi-main-nav-link',
  templateUrl: './main-nav-link.component.html',
  styleUrls: ['./main-nav-link.component.scss'],
  animations: [trigger(
    'openClose',
    [
      state('collapsed, void', style({height: '0px'})),
      state('expanded', style({height: '*'})),
      transition('collapsed => expanded', [animate('100ms ease-out')]),
      transition('expanded => collapsed', [animate('100ms ease-out')])
    ])]
})

export class MainNavLinkComponent extends AbstractPageComponent implements OnInit {

  @Input() linkData: any;

  isOpen: boolean;
  stateExpression: string;
  expandable: any = {
    Notifications: [
      '/notifications/vendors',
      '/notifications/products'
    ],
    Vendors: ['/vendors/list', '/vendors/create'],
    Products: [
      '/products',
      '/products/create',
      '/categories',
      '/product-fields'
    ]
  };

  constructor(private el: ElementRef,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store<routerReducer.State>) {
    super();
    // TODO: Decide if we need to force open the active link bucket:
    this.collapse();
  }

  ngOnInit() {
    this.store.select(routerReducer.getRouterData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (Object.keys(this.expandable).indexOf(this.linkData.label) >= 0) {
            const url = (data.router && data.router.state) ? data.router.state.url : '';
            if (this.expandable[this.linkData.label].indexOf(url) >= 0) {
              this.expand();
            } else if (this.isOpen) {
              this.collapse();
            }
          }
        });
  }

  toggleLinks(e) {
    e.stopPropagation();
    e.preventDefault();
    e.target.classList.toggle('open');
    this.el.nativeElement.querySelector('ul').classList.toggle('open');
    this.isOpen ? this.collapse() : this.expand();
  }

  collapse() {
    this.isOpen = false;
    this.stateExpression = 'collapsed';
  }

  expand() {
    this.isOpen = true;
    this.stateExpression = 'expanded';
  }

}
