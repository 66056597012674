import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserManagement} from '../../../shared/models/UserManagement.interface';

@Injectable()
export class TenantUserManagementService {
  private apiUrlCreate = '_MICRO_SERVICE_/tenant/user';
  private apiUrlChangePassword = '_MICRO_SERVICE_/auth/change-password';
  private apiGetBusinessUnits = '_MICRO_SERVICE_/tenant/business-units';
  private apiUrlDelete = '_MICRO_SERVICE_/tenant/delete-user';

  constructor(private http: HttpClient) {}

  save(data: object): Observable<UserManagement> {
    return this.http
      .post(this.apiUrlCreate, data)
      .map((response: any) => response);
  }

  delete(id: string, ids: string[]) {
    return this.http
      .post(this.apiUrlDelete + '?id=' + id, ids)
      .map((response: any) => response);
  }

  getUser(userId: string): Observable<Array<UserManagement>> {
    const url = this.apiUrlCreate + '?id=' + userId;
    return this.http
      .get(url)
      .map((response: any) => response);
  }

  getBusinessUnits() {
    return this.http
      .get(this.apiGetBusinessUnits)
      .map((response: any) => response);
  }

  changePassword(data: object) {
    return this.http
      .post(this.apiUrlChangePassword, data)
      .map((response: any) => response);
  }
}
