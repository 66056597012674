<ng-container>
    <div class="pagination-buttons">
        <span class="page-size-dropdown">
            Page Size:
            <ng-select (change)="onPageSizeChangeHandler()" [(ngModel)]="selectedPageSize" [clearable]="false"
                [searchable]="false">
                <ng-option [disabled]="option  > totalRow + 10 && option != pageSizeData[0]" [value]="option"
                    *ngFor="let option of pageSizeData">
                    <span> {{option}}</span>
                </ng-option>
            </ng-select>
        </span>
        <button mi-button buttonTheme="light-theme" (click)="previousPageClickHandler()"
            [disabled]="isPreviousButtonDisabled">Previous</button>
        Page
        <input type="number" [min]="1" [max]="totalPages" [(ngModel)]="selectedPageNumber"
            (change)="setPage($event.target.value)">
        of {{totalPages}}
        <button mi-button buttonTheme="light-theme" (click)="nextPageClickHandler()"
            [disabled]="isNextButtonDisabled">Next</button>
        <span class="page-row-container">
            Total Rows: {{totalRow}}
        </span>
    </div>
</ng-container>