import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './tenant.module';
import {environment} from '../environments/environment';
import {settings} from '../common/utils/SettingsUtil';

import {createLogger, LOG_LEVELS} from '../shared/logger';
import * as linkFormatter from '../common/utils/linkFormatter';

// Setup logger
const log = createLogger(LOG_LEVELS.CORE);
log('Bootstrapping tenant main.ts');

// Setup link formater
linkFormatter.configure(settings);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    log.error('there was an error starting tenant');
    throw err;
  });
