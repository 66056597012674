import {createFeatureSelector, createSelector,} from '@ngrx/store';
import * as noteListActions from './actions';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Note} from '../../models/Note.interface';
import {MisoActivity, MisoActivityTypes} from '../../models/MisoActivity.interface';
import {MisoCollection} from '../../models/MisoCollection.enum';
import {MiServiceEvent} from '../../models/MiServiceEvent';
import {EVENT_TYPE_AUTO_CANCELLATION} from '../../../common/utils/sharedConstants';

const log = createLogger(LOG_LEVELS.REDUX);

export interface State {
  all_activity?: MisoActivity[];
  document_collection: MisoCollection;
  document_id: string;
  error: any;
  events: MiServiceEvent[];
  loaded: boolean;
  notes: Note[];
}

const initialState: State = {
  all_activity: [],
  document_collection: null,
  document_id: '',
  error: null,
  events: [],
  loaded: false,
  notes: []
};

const eventToMisoActivity = (event: MiServiceEvent): MisoActivity => {
  return {
    ...event,
    sort_date: event.end_date,
    type: MisoActivityTypes.EVENT
  };
};

const noteToMisoActivity = (note: Note): MisoActivity => {
  return {
    ...note,
    sort_date: note.updated_at || note.created_at,
    type: MisoActivityTypes.NOTE
  };
};

const sortActivities = function (a: MisoActivity, b: MisoActivity): number {
  return a.sort_date < b.sort_date ? 1 : -1;
};

const joinEventsAndNotes = (serviceEvents: MiServiceEvent[], serviceNotes: Note[]): MisoActivity[] => {
  return [
    ...serviceEvents.map(eventToMisoActivity),
    ...serviceNotes.map(noteToMisoActivity),
  ]
    .sort(sortActivities);
};

export function reducer(state = initialState, action: noteListActions.Actions): State {
  // log('inside of user reducer', state, action);
  switch (action.type) {
    case noteListActions.ActionTypes.RESET: {
      return {
        ...initialState
      };
    }
    case noteListActions.ActionTypes.LOAD_NOTES: {
      return {
        ...state,
        loaded: false,
        error: false,
        events: action.payload.events || [],
        document_id: action.payload.document_id,
        document_collection: action.payload.document_collection
      };
    }
    case noteListActions.ActionTypes.LOAD_NOTES_FAIL: {
      log('note-list failed');
      return {
        ...state,
        loaded: true,
        error: action.payload
      };
    }
    case noteListActions.ActionTypes.LOAD_NOTES_SUCCESS: {
      return {
        ...state,
        all_activity: joinEventsAndNotes(state.events || [], action.payload || []),
        loaded: true,
        notes: action.payload,
        error: null
      };
    }
    default: {
      return state;
    }
  }
}

export const getNoteList = createFeatureSelector<State>('noteList');

export const allActivity = createSelector(
  getNoteList,
  (state) => state.all_activity
);
export const events = createSelector(
  getNoteList,
  (state) => state.events
);
export const notes = createSelector(
  getNoteList,
  (state) => state.notes
);
export const loaded = createSelector(
  getNoteList,
  (state) => state.loaded
);
export const error = createSelector(
  getNoteList,
  (state) => state.error
);
