<mi-page-header title="{{ 'TENANT.VENDOR_LIST_TITLE' | translate }}" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>
<div class="ml-2">
    <div class="note-info d-flex justify-content-between align-items-center my-3">
        <div class="dropdown-dates">
            <select id="date-select" (change)="onDateChange($event)">
                <option *ngIf="isLoadingDates">Loading Dates...</option>
                <option *ngFor="let date of lastUpdateDates" [value]="date">{{ date }}</option>
            </select>
        </div>
        <div class="download-button">
            <button (click)="downloadReport()" class="btn btn-primary btn-sm">Download All</button>
        </div>
    </div>
    <div class="report-summary card my-4">
        <h4 class="note-info">
            <b>Unpaid Invoice Report</b>
        </h4>
        <ul class="d-flex flex-row justify-content-start p-2">
            <li class="text-center w-20">
                <h2 class="text-bold fs-24">Bloomberg</h2>
                <p>Vendor</p>
            </li>
            <li class="text-center w-20">
                <h2 class="text-bold fs-24">${{ formattedTotalCurrentDue }}</h2>
                <p>Unpaid Value</p>
            </li>
            <li class="text-center w-20">
                <h2 class="text-bold fs-24">{{ uniqueInvoiceCount }}</h2>
                <p>Unpaid Invoices</p>
            </li>
            <li class="text-center w-20">
                <h2 class="text-bold fs-24">{{ uniqueCustomerAccountCount }}</h2>
                <p>Unpaid Accounts</p>
            </li>
            <li class="text-center w-20">
                <h2 class="text-bold fs-24">{{ selectedDate }}</h2>
                <p>Last Update</p>
            </li>
        </ul>
    </div>
    <div class="sticky-header">
        <mi-opacity-loader [ready]="!isLoading">
        <div class="mt-3 w-100 card scrollable-table">
            <div class="custom-report-table">
            <ng2-smart-table [settings]="settings" (mouseenter)="onChange()" (mouseleave)="onChange()"
            (keypress)="onChange()" (keyup)="onChange()" (keydown)="onChange()" (click)="onChange()" (userRowSelect)="onRowSelectHandler($event)" (filter)="onChange()" [source]="unpaidInvoiceSource">
            </ng2-smart-table>
            </div>
        </div>
        </mi-opacity-loader>
    </div>
</div>