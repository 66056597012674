import {Component, OnInit} from '@angular/core';
import {Vendor} from '../../../../../../../shared/models/Vendor';
import {Store} from '@ngrx/store';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import * as OrderReducers from '../../state/reducer';
import {UpdateOrderAction} from '../../state/actions';
import {OrderState, OrderProgression} from '../../state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';

@Component({
  selector: 'mi-service-vendor-select',
  templateUrl: './service-order-vendor-select.component.html'
})

export class ServiceOrderVendorSelectComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  readonly NO_DATA: string = NO_DATA;
  orderState: OrderState;
  pageLoaded$: Observable<boolean>;
  step: string = OrderReducers.OrderSteps.Vendor;
  vendor: Vendor = null;

  constructor(
    private store: Store<OrderState>
  ) {
    super();
  }

  ngOnInit() {

    this.store.select(OrderReducers.getOrderData).subscribe(
      (state) => {
        this.orderState = state as OrderState;
        // Local necessities:
        this.vendor = this.orderState.vendor;
        // Find the index belonging to the state's current step:
        this.index = OrderProgression.findIndex((a) => {
          return a === this.step;
        });
        // Switch whether we're active or not:
        this.activeIndex = this.index === this.orderState.index;
      },
      (e) => {
        this.error = e;
      });
  }

  onDeselectVendor(): void {
    this.vendor = null;
  }

  onVendorSelected(e: Vendor): void {
    this.vendor = e;
  }

  onContinue(): void {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction({
      index: maxIndex,
      maxIndex: maxIndex,
      vendor: this.vendor
    }));

  }

}
