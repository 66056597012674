import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {Equipment} from '../../../shared/models/Equipment';
import {SectionDataTableColumn} from '../../../shared/ng-models/SectionDataColumn.interface';
import {SelectEquipmentModalComponent} from '../select-equipment-modal/select-equipment-modal.component';
import {ModalService} from '@independer/ng-modal';
import {takeUntil} from 'rxjs/operators';
import {SortableTemplatesEnum} from '../../../shared/ng-models/SortableTableColumn.interface';


@Component({
  selector: 'mi-select-service-equipments',
  templateUrl: './select-service-equipments.component.html',
  styleUrls: ['./select-service-equipments.component.scss']
})

export class SelectServiceEquipments extends AbstractPageComponent implements OnInit {

  @Input() selectedEquipments: Array<Equipment> = [];
  @Input() isDetailPage: boolean = false;

  @Output() equipmentSelected: EventEmitter<Equipment> = new EventEmitter<Equipment>();
  @Output() equipmentRemoved: EventEmitter<Equipment> = new EventEmitter<Equipment>();

  selectedEquipmentsColumns: SectionDataTableColumn[] = [];
  @ViewChild('removeTmpl', {static: true}) removeTmpl: TemplateRef<any>;

  constructor(private modalService: ModalService) {
    super();
  }

  ngOnInit() {
    this.selectedEquipments = this.selectedEquipments ||[];
    this.selectedEquipmentsColumns = [
      {
        name: "MISO ID",
        prop:'display_id',
        sortable: true
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: true
      },
      {
        name: "Manufacturer",
        prop: 'equipment_catalog.vendor.vendor_name',
        sortable: true
      },
      {
        name: "Model #",
        prop: 'equipment_catalog.category_name',
        sortable: true
      },
      {
        name: 'Serial Number or Tag',
        prop: 'serial',
        sortable: false,
        cellTemplate: SortableTemplatesEnum.truncate
      },
      {
        name: 'Price $',
        prop: 'price',
        sortable: true,
        cellTemplate: SortableTemplatesEnum.currency
      },
      {
        name: '',
        prop: '',
        cellTemplate: this.removeTmpl,
        sortable: false
      }
    
    ];
  }

  onEquipmentSelected(data) {
    this.selectedEquipments = this.selectedEquipments.filter(eq => eq.display_id != data.display_id);
    this.selectedEquipments.push(data);
    this.selectedEquipments
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    this.equipmentSelected.emit(data);
  }

  onEquipmentRemoved(data) {
    this.selectedEquipments = this.selectedEquipments.filter(eq => eq.display_id != data.display_id);
    this.equipmentRemoved.emit(data);
  }

  openEquipmentModal() {
    const modalRef = this.modalService.open(SelectEquipmentModalComponent, m => {
    });
    modalRef.closed
      .pipe(takeUntil(this.destroy$))
      .subscribe(args => {
        if(args.result && args.result.length && args.result[0].equipment) {
          this.onEquipmentSelected(args.result[0].equipment)
        }
    });
  }
}
