<mi-page-header
  title="Create New Product"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<div>

  <mi-product-search *ngIf="vendor"
  [vendor]="vendor"
  [locked]="true"
  (selecteProduct)="onProductSelected($event)"
  ></mi-product-search>

</div>
