import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as ServiceV2Reducer from '../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../shared/state/service-v2/actions';
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import { ServiceV2Filter } from "src/shared/models/ServiceV2";
import { ModalService } from "@independer/ng-modal";
import { ExcelEmailNotificationModalComponent } from "../excel-email-notification-modal/excel-email-notification-modal.component";
import { ServiceReportsV2Service } from "src/libs/api2/service-v2/service-reports-v2.service";

@Component({
  selector: 'mi-excel-button-v2',
  styleUrls: ['./excel-button-v2.component.scss'],
  templateUrl: './excel-button-v2.component.html'
})

export class ExcelButtonV2Component extends AbstractPageComponent implements OnInit {
  @Input() theme: string = 'dark-theme';
  @Input() buttonLabel: string = "Download";
  @Input() printButton: boolean = false;
  @Input() dropdownItem: boolean = false;

  loaded = false;
  resultsLoaded: boolean;
  hasResults: boolean = false;
  searchType: string;
  error: string;
  queryString: string;
  url: string;
  owner: string;
  readableFilter: string;
  result: any;
  tenantId: string;
  queryParams: any;
  columns: ASReducer.IColumn[];
  filters: ServiceV2Filter[];
  search: string;

  constructor(
    private serviceReportsV2Service: ServiceReportsV2Service,
    private store: Store<ServiceV2Reducer.State>,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.tenantId = FrontEndSettingsUtil.getDomain();
    this.store.select(ServiceV2Reducer.getServiceV2Data)
    .takeUntil(this.destroy$)
    .subscribe(
      data => {
        console.log("store data", data);
        this.hasResults = data.results.total > 0;
        this.filters = data.results.filters;
        this.search = data.results.search;
      }
    );

    this.store
      .select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(data => {
        console.log("asData", data);
        this.searchType = data.searchType;
        this.columns = data.columns;
      });
  }

  print() {
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Email Notification";
      m.message = "Your request to download Services Inventory is in progress. Please check your email in a few minutes to receive the requested information (it may also be in your Spam folder).";
      m.closeButtonLabel = "Confirm";
    });

    this.serviceReportsV2Service.printServices(this.columns, this.filters, this.tenantId, this.search)
    .toPromise()
    .then(res => {
      console.log("print res", res);
    });
  }
}