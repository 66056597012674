import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import {environment} from '../../../../environments/environment';
import {TenantSearchService} from '../../../../libs/api/tenantSearch/tenantSearch.service';
import {DetailHeaderNavData, HeaderNavSpecTypes} from '../../../../shared/ng-models/DetailHeaderNavData.interface';
import {combineLatest} from "rxjs";
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {Store} from '@ngrx/store';
import {FILTER_GROUP_MODAL_ID} from '../../../../shared/ng-models/FilterGroupModalId';
import { ModalService } from '@independer/ng-modal';
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';
import linkFormatter from 'src/common/utils/linkFormatter';

@Component({
  templateUrl: './employee-instance-wrap.component.html'
})

export class EmployeeInstanceWrapComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  employee: TenantUser;
  detailHeaderNavData: DetailHeaderNavData;
  display_id: string;
  error: string = '';
  filterOverlayId: string = FILTER_GROUP_MODAL_ID;
  loaded: boolean = false;
  supportLink: string = linkFormatter.getSupportLink();
  title: string;
  equipmentCount: number = 0;

  NO_DATA: string = NO_DATA;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantContactService: ContactV2Service,
              private tenantSearchService: TenantSearchService,
              private store: Store<ASReducer.State>,
              private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {

    this.loaded = false;

    this.activatedRoute.params
    .takeUntil(this.destroy$)
    .subscribe(params => {
      console.log("params", params);
      this.display_id = params.display_id;
      this.tenantContactService.getTenantUser(this.display_id)
      .toPromise()
      .then(res => {
        console.log("res", res);
        this.employee = <TenantUser>res;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Employee List',
            url: '/employees',
            internal: true
          },
          {
            label: `${this.employee.email}`,
            url: '/employees/' + this.display_id,
            internal: true
          }
        ];

        this.detailHeaderNavData = <DetailHeaderNavData> {
          headline: this.employee.first_name + ' ' + this.employee.last_name,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: {display_id: this.employee.email}
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: "N/A"
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: "N/A"
              //value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        this.loaded = true;
      })      
    });

    /*
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .distinctUntilChanged((prev, curr) => prev[1].update === curr[1].update)
      .switchMap(([params, queryParams]) => {
        this.display_id = params.display_id;
        return this.tenantContactService.getTenantUser(this.display_id);
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .switchMap(res => {
        this.employee = <TenantUser>res;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Employee List',
            url: '/employees',
            internal: true
          },
          {
            label: `${this.employee.email}`,
            url: '/employees/' + this.display_id,
            internal: true
          }
        ];

        return this.tenantSearchService.typeSummary({'employee[]': this.employee.email.toLowerCase()});
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((contactResults: any) => {
        
        let correctBucket = contactResults.aggregations.employee.buckets.find(bucket => bucket.key === this.employee.email.toLowerCase());
        let serviceCount = correctBucket ? correctBucket.doc_count : 0;

        this.detailHeaderNavData = <DetailHeaderNavData>{
          headline: this.employee.first_name + ' ' + this.employee.last_name,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: {display_id: this.employee.email}
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: serviceCount
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: contactResults.aggregations.total_mrc.value || 0,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        this.loaded = true;
      });
    */

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.filterGroup) {
            // Get the data and open the modal:
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.filterOverlayId;
            })
          }
        });
  }

}
