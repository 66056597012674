<div *ngIf="hasLoadMore" class="load-more">

  <mi-loading-animation *ngIf="loadMoreLoading; else loaded"></mi-loading-animation>
  <ng-template #loaded>
    <a mi-button (click)="loadMore()">Load More</a>
  </ng-template>

</div>



