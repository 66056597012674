import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {Actions, Effect} from '@ngrx/effects';
import * as noteListActions from './actions';
import {TenantNoteService} from "../../../libs/api/tenant/tenantNote.service";

@Injectable()
export class NoteListEffects {

  @Effect()
  loadNotes$: Observable<noteListActions.Actions> = this.actions$
    .ofType(noteListActions.ActionTypes.LOAD_NOTES)
    .switchMap((action: noteListActions.Actions) => this.tenantNoteService.listNotesById(action.payload.document_id))
    .map(response => new noteListActions.LoadNotesSuccessAction(response))
    .catch(e => {
      return of(new noteListActions.LoadNotesFailAction(e))
    });

  constructor(
    private actions$: Actions,
    private tenantNoteService: TenantNoteService
  ) {
  }

}
