import { Component } from '@angular/core';
import {Store} from "@ngrx/store";
import * as userReducer from "../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {combineLatest} from 'rxjs';
import {AUTH} from '../../../common/security/secureUser';
import * as linkFormatter from '../../../common/utils/linkFormatter';


@Component({
  templateUrl: './account-locked.component.html',
  styleUrls: ['./account-locked.component.scss']
})

export class AccountLockedComponent extends AbstractPageComponent {
  loaded:boolean = false;
  isAccountHolder = false;
  accountLink = linkFormatter.getAccountsLink();

  constructor(
    private store: Store<userReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest(
      this.store.select(userReducer.getUserState),
      this.store.select(appDataReducer.getAppData)
    )
      .takeUntil(this.destroy$)
      .subscribe( ([userState, appData]) => {

        const domain= appData.tenantData.domain;


        if (userState.secureUser.isSuperUser() === false &&
            userState.secureUser.hasTenantAccess(domain, AUTH.ACCOUNT_HOLDER)
        ) {
          this.accountLink = linkFormatter.getManageAccountLink(domain);
          this.isAccountHolder = true;

        }
        this.loaded = true;
      })
  }

}
