import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
// import { AddressSearchResults, MiServiceSearchResults, SearchResults } from '../../../shared/models/SearchResults';
import { Address } from '../../../shared/models/Address';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
// import { TenantSearchService } from '../../../libs/api/tenantSearch/tenantSearch.service';
// import { MiServiceSearchQueryParams } from '../../../shared/ng-models/search/SearchQueryParams.interface';
import { combineLatest, Observable } from 'rxjs';
import { AddressV2 } from 'src/libs/api2/types/AddressV2.interface';
import { AddressV2Service } from '../../../libs/api2/address-v2/address-v2.service';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import { LocationV2 } from '../../../libs/api2/types/LocationV2.interface';
import { AddressResolutionService } from 'src/libs/api2/resolution/address-resolution.service';
import { AddressStatus } from 'src/shared/ng-models/Address';
import { Subject } from "rxjs";
//import googleMapsClient from "../../../shared/utils/googleMaps";
//import * as GoogleMaps from '@google/maps';
//import {MapGeocoder} from '@angular/google-maps';
import { MapsAPILoader } from '@agm/core';
// import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';

export enum FindOrCreateLocationComponentSteps {
  CREATE_STATE,
  SEARCH_STATE,
  CREATE_LOCATION_STATE
}

@Component({
  selector: 'mi-find-or-create-location',
  templateUrl: './find-or-create-location.component.html',
  styleUrls: ['./find-or-create-location.component.scss']
})

export class FindOrCreateLocationComponent extends AbstractPageComponent implements OnInit {

  @Output() addressSelected: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() baseAddressSelected: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() updateButtonToggle: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() locationSelected: EventEmitter<LocationV2> = new EventEmitter<LocationV2>();
  @Output() stepChanged: EventEmitter<FindOrCreateLocationComponentSteps> = new EventEmitter<FindOrCreateLocationComponentSteps>();
  @Output() newCreated: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  @Input() searchString: string = '';
  @Input() resetOnSelect: boolean = false;
  @Input() isTenantAddressResolution: boolean = false;
  @Input() isAdditionalAddressDetailsVisible: boolean = false;
  @Input() isAddressIgnoreChecked: boolean = false;
  @Input() tenantId: string;

  CREATE_STATE = FindOrCreateLocationComponentSteps.CREATE_STATE;
  SEARCH_STATE = FindOrCreateLocationComponentSteps.SEARCH_STATE;
  CREATE_LOCATION_STATE = FindOrCreateLocationComponentSteps.CREATE_LOCATION_STATE;

  step: FindOrCreateLocationComponentSteps = this.SEARCH_STATE;
  results: any;
  error: string;
  loaded: boolean = false;
  loadingMore: boolean = true;
  updatingAddress: boolean = false;
  // originalAddresses: any;
  // originalTotal: number;
  page: number = 0;
  selectedAddress: AddressV2;
  total: number;
  owner: string;
  ignoreSubmitButtonDisable: Boolean = true
  searchParam: Subject<string> = new Subject<string>();
  
  //googleMapsClient;
  autocompleteService

  constructor(
    // private tenantSearchService: TenantSearchService,
    private tenantAddressV2Service: AddressV2Service,
    private activatedRoute: ActivatedRoute,
    private addressResolution: AddressResolutionService,
    private store: Store<any>,
    private mapsAPILoader: MapsAPILoader) {
    super();
  }

  public reset() {
    // console.log('RESET');
  }


  ngOnInit() {
    console.log("here in FindOrCreateLocationComponent", this.searchString)
    console.log("here tenantId", this.tenantId)
    this.mapsAPILoader.load()
    .then(() => {
      this.autocompleteService  = new google.maps.places.AutocompleteService();
    });
    

    this.isAddressIgnoreChecked = this.isAddressIgnoreChecked ? true : false

    const tenantStateData: Observable<string> = this.store.pipe(select(appDataReducer.tenantDomain), first());
    this.store.select(appDataReducer.tenantDomain)
      .takeUntil(this.destroy$)
      .first()
      .subscribe(
        tenantDomain => {
          console.log("here tenantDomain", tenantDomain)
          this.owner = tenantDomain ?  tenantDomain  : this.tenantId;
        }
      );

    combineLatest(
      this.tenantAddressV2Service.getAllAddressByOwner(this.owner, this.searchString, 0, 6, 'cmrc'),
      // this.tenantAddressV2Service.getAllAddressByOwner(this.owner, "", 0, 6, 'cmrc')
    )
      .subscribe({
        next: (addresses: any[]) => this.onResults(addresses as any[]),
        error: (e) => this.onResultError(e)
      });

    this.activatedRoute.queryParams.takeUntil(this.destroy$).subscribe((query: Params) => {
      if (query.create) {
        this.swapTo(this.CREATE_STATE);
      }
    });

    this.searchParam.pipe(debounceTime(1500))
      .subscribe(searchParam => {
        this.onUpdateSearchString(searchParam);
      });
  }

  onResultError(e: any): void {
    this.error = e;
    this.loaded = true;
  }

  ignoreAddressToggleHandler() {
    this.isAddressIgnoreChecked = !this.isAddressIgnoreChecked
    this.ignoreSubmitButtonDisable = !this.ignoreSubmitButtonDisable
  }

  updateAddressHandler() {
    this.updatingAddress = true
    const payload = {
      status: this.isAddressIgnoreChecked ? AddressStatus.Ignored : AddressStatus.Unresolved,
      tenant_id: this.owner,
      unresolved_property: this.searchString,
    }

    this.addressResolution
      .bulkIgnoreAddress([payload])
      .toPromise()
      .then((response) => {
    this.updatingAddress = false

        const responseData = { response: response, addressStatus: payload.status }
        this.closeModal.emit(responseData);
      })
      .catch((e) =>{ this.error = e
    this.updatingAddress = false
      
      });
  }

  onResults([addresses]: any[]): void {
    console.log("here addresses", addresses);
    /*
    this.results = addresses.hits as Address[];
    if (this.searchString) {
      this.results.push(...addresses.additionalHits);
    }
    this.originalAddresses = emptySearchData.hits as Address[];
    this.originalTotal = this.total = this.calculateTotal(emptySearchData);
    this.loadingMore = false;
    this.loaded = true;
    */
    this.results =  addresses.data;
    if (this.searchString) { 
      //this.results.push(...addresses.additionalHits);
      this.getGoogleHits();
    }
    // this.originalAddresses = addresses.data as Address[];
    // this.originalTotal = this.calculateTotal(addresses);
    this.total = this.calculateTotal(addresses);
    this.loadingMore = false;
    this.loaded = true;
  }

  getGoogleHits(): void {
    console.log("here get google hits");
    //let googleSearchPromise = new Promise((resolve, reject) => {
      /*
      if (!req.query.q) {
        resolve({
          json: {
            predictions: []
          }
        });
      }
      */
      /*

      console.log("googleMapsClient", this.googleMapsClient);
      
      this.googleMapsClient.placesAutoComplete({
        types: 'geocode',
        input: this.searchString
      }, function (err, results) {
        if (err) {
          reject(err);
        } else {
          resolve(results);
        }
      })
    });
    */
    /*
    this.geocoder.geocode({
      address: this.searchString
    })
    .subscribe(results => {
      console.log("gmap results", results);
    });
    */
    let resultCount =  this.results ? this.results.length : 0;
    this.autocompleteService.getPlacePredictions({input: this.searchString}, data => {
      console.log("here gmap results", data);
      data.forEach(result => {
        if(resultCount <= 10) {
          this.results.push({
            formatted_address: result.description,
            place_id: result.place_id
          });
          resultCount++;
        }        
      });
    });

    //console.log("googleSearchPromise", googleSearchPromise);
  }

  onResultsWithPage(addresses: any, page: number): void {    

    this.total = addresses.total;

    if (page > 0) {
      this.results.push(...addresses.data);
    } else {
      this.results = addresses.data;
      if(addresses.data.length < 10) {
        this.getGoogleHits();
      }
    }

    this.loadingMore = false;
    this.loaded = true;
    /*
    if (page > 1) {
      this.results.push(...addresses.hits);
    } else {
      this.results = addresses.hits;
      if (addresses.hits.length < 10) {
        // we should also push the google items.
        this.results.push(...addresses.additionalHits);
      }
    }
    this.total = this.calculateTotal(addresses);
    this.loadingMore = false;
    this.loaded = true;
    */
  }

  onLoadMoreClicked($event) {
    $event.preventDefault();
    this.loadingMore = true;
    this.onUpdateSearchString(this.searchString, this.page + 1);
  }

  onLocationSelected(location: LocationV2) {
    this.locationSelected.emit(location);
    // console.log('onLocationSelected location', location);
    if (this.resetOnSelect) {
      this.swapTo(this.SEARCH_STATE);
    }
  }

  onAddressSelected(address: AddressV2 | any) {
    this.updateButtonToggle.emit(true);
    if (address.id) {
      // this item is already created
      this.baseAddressSelected.emit(address);
      this.gotoSelectLocation(address);
    } else {
      // console.log('address on google address click', address);
      this.tenantAddressV2Service.saveGoogleAddress(this.owner, address.place_id)
        .toPromise()
        .then((addr) => {
          console.log("here addr", addr)
          //this.indexAddress(addr);
          this.processAddress(addr);
        })
        .catch(error => {
          console.log("here error", error)

          if (error.data.address) {
            // this item was matched to an already existing address
            //this.indexAddress(error.data.address, true);
            this.processAddress(error.data.address, true);
          } else {
            this.error = error;
          }
        });
    }
  }

  gotoSelectLocation(address) {
    console.log('address in goToSelectLocation', address);
    this.selectedAddress = address;
    this.addressSelected.emit(address);
    this.swapTo(this.CREATE_LOCATION_STATE);
  }

  // onSearchSubmit() {
  //   this.searchParam.next(this.searchString);
  //   // this.onUpdateSearchString(this.searchString, this.page);
  // }

  onUpdateSearchStringHandler(str, page: number = 0){
    this.loadingMore = true
    this.searchParam.next(str);
  }

  onUpdateSearchString(str, page: number = 0) {
    this.loaded = false;
    this.searchString = str;

    let size = 6;
    if(page > 0) {
      size = 10;
    }
    this.page = page;

    if (this.searchString.length > 2 || page > 0) {
      this.error = '';
      this.tenantAddressV2Service.getAllAddressByOwner(this.owner, this.searchString, page, size,'cmrc')
      .subscribe({
        next: (addresses) => this.onResultsWithPage(addresses, page),
        error: (e) => this.onResultError(e)
      });
    }
    else {
      // this.results = this.originalAddresses;
      // this.total = this.originalTotal;
      this.loadingMore = false;
      this.loaded = true;
    }
    
    
    /*
    const options = { from: 0, size: 6 };

    if (page > 1) {
      options.from = 6 + ((page - 2) * 10);
      options.size = 10;
    }

    this.page = page;

    if (this.searchString.length > 2 || page > 1) {

      this.error = '';

      const queryParams: MiServiceSearchQueryParams = {
        q: this.searchString,
        from: options.from,
        size: options.size
      };

      this.tenantSearchService.searchTanentAddress(queryParams)
        .subscribe({
          next: (addresses) => this.onResultsWithPage(addresses, page),
          error: (e) => this.onResultError(e)
        });
    } else {
      this.results = this.originalAddresses;
      this.total = this.originalTotal;
    }
    */
  }

  calculateTotal(addressResult: any): number {
    //return addressResult.total.value + addressResult.additionalHits.length;
    return addressResult.total;
  }

  swapTo(newState: FindOrCreateLocationComponentSteps) {
    this.stepChanged.emit(newState);
    this.step = newState;
  }

  onLocationCanceled() {
    this.selectedAddress = null;
    this.swapTo(this.SEARCH_STATE);
  }

  onNewCreated() {
    this.newCreated.emit();
  }

  indexAddress(addr, exists = false) {
    this.tenantAddressV2Service.indexAddress(addr.id).subscribe((response) => {
      if (!exists) {
        this.newCreated.emit();
        this.baseAddressSelected.emit(addr);
      }
      this.gotoSelectLocation(addr);
    }, (error) => {
      this.error = error;
    });
  }

  processAddress(addr, exists = false) {
    if (!exists) {
      this.newCreated.emit();
      this.baseAddressSelected.emit(addr);
    }
    this.gotoSelectLocation(addr);
  }
}