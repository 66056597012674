import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mi-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})

export class ToggleComponent{

  _selected: boolean = false;

  @Output() onChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() disabled: boolean = false;
  @Input()
  get selected(){
    return this._selected;
  }
  set selected(bool){
    this._selected = bool;
  }
  @Input() showToggleText: boolean = false;
  @Input() titleOnRight: boolean = false;
  @Input() toggleOffText: string = "Off";
  @Input() toggleOnText: string = "On";
  @Input() toggleTitle: string;

  @Input() sliderColor: string = "";

  constructor(){}

  onToggle(e){
    this.selected = e.target.checked;
    this.onChange.emit(this._selected);
  }

}
