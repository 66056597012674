import {Directive, forwardRef, ElementRef, Input, HostListener} from '@angular/core';

import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

interface ValidatorInfo{
  pattern?: RegExp,
  typingpattern?: RegExp,
  keys?: RegExp,
  validator?(val: string): boolean,
  max?: string
  }

interface ValidatorCollection{
  [key: string]: ValidatorInfo
}

@Directive({
  selector: '[mi-restrict-input]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MiRestrictInput), multi: true }
    ]
  })

export class MiRestrictInput implements Validator {

  @Input() miRestrictType: string;
  @Input() miRestrictKey: string;
  @Input() miRestrictMax: string;
  @Input() miRestrictPattern: string;
  @Input() miRestrictTypingPattern: string;

  // These help a firefox detect non character keys
  private eventCodeToAlwaysAccept: string[] = ['ArrowRight', 'ArrowLeft', 'Backspace'];

  private validatorCollection: ValidatorCollection = {
    "Date": {
      keys: /[0-9\/]/,
      typingpattern: /^((\d{1,2}\/\d{1,2}\/\d{4})|(\d{1,2}\/$)|(\d{1,2}\/\d{1,2}\/)|(\d{1,2}\/\d{1,2})|(\d{1,2}\/\d{1,2}\/)|(\d{1,2}\/\d{1,2}\/\d{1,4})|(\d{1,2}))$/,
      validator: this.dateValidator
    },
    "Name": {
      pattern: /^[a-zA-Z][a-zA-Z\s\.,\'`\-_]*[a-zA-Z\.]$/,
      keys: /[a-zA-Z\s\.,\'`\-_]/
    },
    "PositiveNumber": {
      pattern: /^\d*\.?\d+$/,
      typingpattern: /^\d*\.?\d*$/,
      keys: /[0-9\.]/,
      max: "99999999999999999999"
    },
    "Number": {
      pattern: /^[+-]?\d*\.?\d+$/,
      typingpattern: /^[+-]?\d*\.?\d*$/,
      keys: /[+\-0-9\.]/,
      max: "99999999999999999999"
    },
    "Integer": {
      pattern: /^\d+$/,
      keys: /[0-9]/,
      max: "99999999999999999999"
    },
    "Phone": {
      pattern: /^[1-9][0-9]{9}$/,
      keys: /[0-9]/
    },
    "ServiceId": {
      pattern: /^[a-zA-Z0-9\.':\-@\(\)]{0,100}$/,
      typingpattern: /^[a-zA-Z0-9\.':\-@\(\)]{0,100}$/,
      keys: /[a-zA-Z0-9\.':\-@\(\)]/
    },
    "OrderId": {
      pattern: /^[a-zA-Z0-9\.':\-@\(\)]{0,100}$/,
      typingpattern: /^[a-zA-Z0-9\.':\-@\(\)]{0,100}$/,
      keys: /[a-zA-Z0-9\.':\-@\(\)]/
    },
    "Custom": {
    }
  };

  private dateValidator(input: string) {
    const dateChars = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return dateChars.test(input) && !isNaN(Date.parse(input));
  }

  constructor(private el: ElementRef) {
  }


  validate(c: AbstractControl): { [key: string]: any } {
    if(this.isValid(c.value))
        return null;
      else
      return {
        invalidFormat: true
    };
  }

  getValidator(){
    let validator = {...this.validatorCollection[this.miRestrictType]};
    
    if(validator){
      if(this.miRestrictPattern){
        validator.pattern = new RegExp(this.miRestrictPattern);
      }

      if(this.miRestrictKey){
        validator.keys = new RegExp(this.miRestrictKey);
      }

      if(this.miRestrictTypingPattern){
        validator.typingpattern = new RegExp(this.miRestrictTypingPattern);
      }
    }

    return validator;
  } 

  private isValidKey(val: string): any{
    let validator = this.getValidator();
    if(!validator) return true;
    let valid = true;
    if(validator.keys)
      valid = validator.keys.test(val);

    let newVal = this.getText(val);  
    if(valid && validator.typingpattern)
      valid = valid && validator.typingpattern.test(newVal);

    if(valid && (this.miRestrictMax||validator.max))  {
      valid = Number.parseFloat("0" + newVal) <= Number.parseFloat((this.miRestrictMax||validator.max));
    }

      return valid;
  }

  private isValid(val: string): any{
    if(!val) return true;
    let validator = this.getValidator();
    if(!validator) return true;

    if(validator.validator)
      return validator.validator(val);

    if(validator.pattern)
      return validator.pattern.test(val);
    
    if(this.miRestrictMax||validator.max)  {
      return Number.parseFloat(val) <= Number.parseFloat((this.miRestrictMax||validator.max));
    }

    return true;
  }

  private getText(ch: string)
  {
    let input = this.el.nativeElement.value;
    let positionStart =this.el.nativeElement.selectionStart;
    let positionEnd =this.el.nativeElement.selectionEnd;
    return input.substr(0, positionStart) + ch + input.substr(positionEnd);
  }

  @HostListener('keypress', ['$event']) onKeyPress(e) {

    let keyCode = e.which || e.charCode || e.keyCode;


    if(this.eventCodeToAlwaysAccept.includes(e.code)||keyCode===13) {
      // firefox specifically includes these e.code
      return true;
    }

    let ch = String.fromCharCode(e.which || e.charCode || e.keyCode);
    if(!this.isValidKey(ch))
         e.preventDefault();
  }

  @HostListener('paste', ['$event']) onPaste(e) {
    let clipboardData = window["clipboardData"] || e.clipboardData || (e.originalEvent ? e.originalEvent.clipboardData : null);
    if(clipboardData){
      let ch = clipboardData.getData('text');
      if(!ch)
        e.preventDefault();
      let output = this.getText(ch);
      if(!this.isValid(output))
        e.preventDefault();
    }
  }
}
