<mi-page-header title="{{title}}" [breadcrumbs]="breadcrumbs" *ngIf="title">
</mi-page-header>

<div class="card mt-2">
    <mi-opacity-loader [ready]="isDataLoaded">
        <div class="sticky-header top-pagination custom-hover-effect">
            <div class="note note-info">
                {{ this.formattedData.length }} accounts
            </div>
            <ng2-smart-table (click)="onItemsPerPageChange()" [settings]="settings"
                [source]="DataSource" class="table-hover"></ng2-smart-table>
        </div>
    </mi-opacity-loader>
</div>