import { Observable } from "rxjs";
import { ProductPayload } from 'src/shared/ng-models/products-normalization';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class ProductResolutionService {
  private getProductUrl = "_MICRO_SERVICE_/upload/property/address/resolution/search";
  private updateProductUrl = "_MICRO_SERVICE_/upload/property/product/resolution";
  constructor(private http: HttpClient) { }
  list(tenant_id: string, search: string, pageNumber: number, pageSize: number, statuses: string[]): Observable<any> {
    return this.http.get(`${this.getProductUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&tenantId=${tenant_id}&statuses=${statuses}`);
  }
  
  updateProduct(payload: ProductPayload): Observable<any> {
    return this.http
      .put(`${this.updateProductUrl}`, payload)
      .map((response: any) => response);
  }
}
