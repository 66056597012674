import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {Vendor} from '../../../shared/models/Vendor';
import {TenantVendors} from '../../../libs/api/tenant/tenantVendors';
import {SectionDataTableColumn} from '../../../shared/ng-models/SectionDataColumn.interface';
import {EQUIPMENT_STATUSES} from '../../../common/utils/sharedConstants';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import {LocationV2} from 'src/libs/api2/types/LocationV2.interface';
import {EquipmentCatalogV2} from 'src/libs/api2/types/EquipmentCatalogV2.interface';
import {EquipmentV2} from 'src/libs/api2/types/EquipmentV2.interface';
import {Status} from 'src/libs/api2/types/Status.interface';
import { SortableTemplatesEnum } from 'src/shared/ng-models/SortableTableColumn.interface';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss']
})

export class EquipmentFormComponent extends AbstractPageComponent implements OnInit {

  @Input() equipmentId: string;
  @Input() showrecentlyAdded: boolean = true;
  @Input() lockedVendor: Vendor;

  @Output() equipmentCreated: EventEmitter<EquipmentV2> = new EventEmitter<EquipmentV2>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  editEquipment: EquipmentV2;
  editEquipmentForm: FormGroup = null;
  statuses: Status[] = [];
  submitError: any = '';
  loaded: boolean = false;
  vendors: Array<Vendor>;
  catalogs: Array<EquipmentCatalogV2> = [];
  selectedCatalog: EquipmentCatalogV2;
  selectedVendor: Vendor;
  step: number = 0;
  recentlyAddedEquipments: Array<EquipmentV2> = [];
  location: LocationV2;
  showButtons: boolean = true;
  showFindLocation: boolean = false;
  end_user: TenantUser;
  end_user_email: string;
  owner: string;

  @ViewChild('cloneTmpl', {static: true}) cloneTmpl: TemplateRef<any>;
  @ViewChild('serialTmpl', {static: true}) serialTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
  @ViewChild('vendorTmpl', {static: true}) vendorTmpl: TemplateRef<any>;
  @ViewChild('locationTmpl', {static: true}) locationTmpl: TemplateRef<any>;
  @ViewChild('nameTmpl', {static: true}) nameTmpl: TemplateRef<any>;
  recentlyAddedEquipmentsColumns: SectionDataTableColumn[] = [];

  onAddCatalogRef: (name) => void;

  readonly EQUIPMENT_STATUSES = EQUIPMENT_STATUSES;

  constructor(private formBuilder: FormBuilder,
              private tenantVendorService: TenantVendors,
              private store: Store<appDataReducer.State>) {
    super();
    this.onAddCatalogRef = (name) => this.onAddCatalog(name);
  }

  ngOnInit() {

    for (let i = 0; i < EQUIPMENT_STATUSES.length; i++) {
      this.statuses.push({
        name: EQUIPMENT_STATUSES[i],
        value: i === 0
      });
    }

    this.recentlyAddedEquipmentsColumns = [
      {
        name: "MISO ID",
        prop:'display_id',
        cellTemplate: SortableTemplatesEnum.idLink2,
        sortable: false
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: false,
      },
      {
        name: 'Manufacturer',
        prop: '',
        cellTemplate: this.vendorTmpl,
        sortable: false
      },
      {
        name: 'Model #',
        prop: 'equipment_catalog.category_name',
        sortable: false
      },
      {
        name: 'Serial Number or Tag',
        prop: 'serial',
        sortable: false,
        cellTemplate: this.serialTmpl,
      },
      {
        name: 'Status',
        prop: 'is_active',
        sortable: false,
        cellTemplate: this.statusTmpl,
      },
      {
        name: 'Price $',
        prop: 'price',
        sortable: false
      },
      {
        name: 'Subscriber',
        prop: 'end_user.email',
        sortable: false
      },
      {
        name: 'Location',
        prop: 'location',
        sortable: false,
        cellTemplate: this.locationTmpl,
      },
      {
        name: '',
        prop: '',
        cellTemplate: this.cloneTmpl,
        sortable: false
      }];

    this.store.select(appDataReducer.getAppData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (tenant) => {
          this.owner = tenant.tenantData.domain;

          this.tenantVendorService.listVendors()
            .then(vendors => {
              this.vendors = vendors;
              // console.log('all vendors', this.vendors);
              this.createForm();
              if (this.equipmentId) {
              } else if (this.lockedVendor) {
                this.editEquipmentForm.controls.vendor_id.setValue(this.lockedVendor._id);
                this.onSelectVendor(this.lockedVendor);
              } else {
                this.loaded = true;
              }
            })
            .catch(e => this.submitError = e);
        });
  }

  createForm() {
    this.editEquipmentForm = this.formBuilder.group({
      vendor_id: [null, Validators.required],
      equipment_catalog_id: [{value: null, disabled: true}, Validators.required],
      description: [null],
      serial: [null],
      name: [null, Validators.required],
      price: [null],
      location: [null],
      end_user_email: [null],
      status: [true, Validators.required]
    });
  }

  onSelectVendor(vendor: Vendor) {
    // console.log('vendor on select', vendor);
    this.submitError = null;
    if (vendor !== undefined && vendor != null) {
      // console.log('VENDOR EXISTS', vendor);
      this.populateVendorModelNumbers(vendor);
    } else {
      this.step = 0;
      this.editEquipmentForm.controls.equipment_catalog_id.disable();
      this.catalogs = [];
      // console.log('catalogs if there are no vendors', this.catalogs);
      this.selectedVendor = null;
      this.selectedCatalog = null;
      this.editEquipmentForm.controls.equipment_catalog_id.setValue(null);
      this.editEquipmentForm.controls.description.setValue(null);
    }
    this.editEquipmentForm.updateValueAndValidity();
  }

  populateVendorModelNumbers(vendor) {
    this.selectedVendor = vendor;
    this.selectedCatalog = null;
    this.editEquipmentForm.controls.equipment_catalog_id.disable();
    this.step = 1;
  }

  onSelectCatalog(catalog, cloneName = null, clonePrice = null, cloneSerial = null) {
    this.submitError = null;
    if (catalog) {
      this.selectedCatalog = catalog;
      const name = cloneName !== null ? cloneName : this.selectedCatalog.name || this.editEquipmentForm.get('name').value;
      const price = clonePrice !== null ? clonePrice : this.selectedCatalog.price || this.editEquipmentForm.get('price').value;
      const status = true;
      this.step = 2;
      this.editEquipmentForm.controls.description.setValue(this.selectedCatalog.description || '');
      this.editEquipmentForm.controls.name.setValue(name);
      this.editEquipmentForm.controls.price.setValue(price);
      this.editEquipmentForm.controls.serial.setValue(cloneSerial);
      this.editEquipmentForm.controls.status.setValue(status);
    } else {
      this.selectedCatalog = null;
      this.step = 1;
      this.editEquipmentForm.controls.description.setValue(null);
    }
  }

  onAddCatalog(category_name) {
    const equipmentCatalog = {
      vendor: this.selectedVendor,
      category_name,
      vendor_id: this.selectedVendor._id,
      price: null,
      owner: this.owner,
      name: null,
      description: null,
    };

  }

  indexEquipmentCatalog(catalog) {

  }

  locationOnToggleChange(toggle) {
    this.showFindLocation = toggle;
    if (!toggle) {
      this.showButtons = true;
      this.location = undefined;
      this.editEquipmentForm.controls.location.setValue(this.location);
    }
  }

  onLocationSelected(location: LocationV2) {
    this.editEquipmentForm.controls.location.setValue(location);
    this.location = location;
    this.showButtons = true;
  }

  selectEndUser(evt){
    this.end_user = evt;
    let email = evt? evt.email : undefined;
    this.editEquipmentForm.controls.end_user_email.setValue(email);
  }

  stepChanged(step) {
    this.showButtons = !(step === 0 || step === 2);
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  onCreateEquipment(data) {
    this.loaded = false;
    this.submitError = null;
    const newCatalog = {
      ...this.selectedCatalog,
      description: data.description,
      name: data.name,
      price: data.price
    };

  }

  createEquipment(equipmentCatalog, data) {
    console.log('data on create equipment', data);
    const newEquipment: EquipmentV2 = {
      id: this.equipmentId,
      equipment_catalog_id: equipmentCatalog ? equipmentCatalog.id : null,
      address_location_id: data.location ? data.location.id : null,
      description: data.description,
      end_user_email: data.end_user_email? data.end_user_email: null,
      serial: data.serial,
      name: data.name,
      price: parseFloat(data.price),
      is_active: data.status,
      owner: this.owner
    };

    if (data.hasOwnProperty('id')) {
      newEquipment.id = data.id;
    }
  }

  onClone(row, setSerial = true): void {
    console.log('clonedRow', row);
    if (row.location) {
      this.onLocationSelected(row.location);
    }

    if (row.end_user) {
      this.selectEndUser(row.end_user);
    }

    this.editEquipmentForm.controls.vendor_id.setValue(row.equipment_catalog.vendor._id);
  }
}
