<mi-page-header
      [breadcrumbs]="breadcrumbs"
      [showShortcutMenu]="false">
</mi-page-header>
<!--
<div class="header-label-container">
  <label>{{headerLabel}}</label>
</div>
-->
<div class="body-container" *ngIf="loaded; else loading">
  <div class="left">
    <div class="spacer-3"></div>
    <div class="header-text-container">
      <label>Current Plan</label>
    </div>
    <div class="account-plan-card-container-box">
      <div class="account-plan-card-container">
        <mi-account-plan-card-v2 [viewMode]="'view'" [planBillingTime]="currentPlanBillingInterval" [accountPlan]="currentAccountPlan">
        </mi-account-plan-card-v2>
      </div>
    </div>
  </div>
  <div class="right" id="billingPlansContainer">
    <div class="spacer-3"></div>
    <div class="header-group-container">
      <div class="header-text-container">
        <label>Available Plans to Upgrade</label>
      </div>
      <div class="plan-billing-time-select-container" *ngIf="trimmedAccountPlans.length > 0">
        <label>Billed</label>
        <select [ngModel]="newPlanBillingInterval" (change)="changePlanBillingInterval($event)">
          <option value="month" *ngIf="currentPlanBillingInterval == 'month'">Monthly</option>
          <option value="year">Yearly</option>
        </select>
      </div>
    </div>    
    <div class="account-plan-card-container-box" *ngIf="trimmedAccountPlans.length > 0">
      <div class="account-plan-card-container" *ngFor="let accountPlan of trimmedAccountPlans">
        <mi-account-plan-card-v2 [viewMode]="'select'" [planBillingTime]="newPlanBillingInterval" [accountPlan]="accountPlan" (selectedPlanCode)="selectPlan($event)">
        </mi-account-plan-card-v2>
      </div>
    </div>
    <div class="account-plan-card-container-box" *ngIf="trimmedAccountPlans.length == 0">
      <label>No plans available right now</label>
    </div>
  </div>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>