import {Component, OnInit} from '@angular/core';
import {Vendor} from '../../../../../shared/models/Vendor';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {TenantCustomerImportService} from '../../../../../libs/api/tenant/tenantCustomerImport.service';
import {PageAlertAddAction} from '../../../../../shared/state/page-alert/actions';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {getSupportTicketLink} from '../../../../../common/utils/linkFormatter';
import {CUSTOMER_IMPORT_DOCUMENT_TYPES, UPLOAD_TYPES} from '../../../../../common/utils/sharedConstants';
import {FindOrCreateVendorModalComponent} from '../../../../../shared/components/find-or-create-vendor-modal/find-or-create-vendor-modal.component';
import {ModalService} from '@independer/ng-modal';

@Component({
  selector: 'mi-data-import-api-integration',
  styleUrls: ['./data-import-api-integration.component.scss'],
  templateUrl: './data-import-api-integration.component.html'
})

export class DataImportApiIntegrationComponent extends AbstractPageComponent implements OnInit {

  readonly DOC_TYPES: any = CUSTOMER_IMPORT_DOCUMENT_TYPES;
  readonly UPLOAD_TYPES: any = UPLOAD_TYPES;

  busy: boolean = false;
  docForm: FormGroup;
  error: string;
  objectKeys = Object.keys;
  selectedVendor: Vendor;

  constructor(
    private formBuilder: FormBuilder,
    private tenantCustomerImport: TenantCustomerImportService,
    private store: Store<any>,
    private router: Router,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.docForm = this.formBuilder.group({
      vendor: [null, Validators.required],
      comment: ''
    });

  }

  onFormSubmit(): void {
    this.busy = true;
    this.tenantCustomerImport
      .createAPIRequest(this.docForm.value)
      .subscribe((api_integration_request) => {
        // Round-trip complete; the object has been made.
        // Now create some data to send along for ticket creation:
        const ticketNumber = api_integration_request.ticket.id;
        const ticketLink = getSupportTicketLink(ticketNumber);
        const message = `<a href="${ticketLink}" target="_blank">Ticket ${ticketNumber}</a> has been created.<br />
          One of our customer service representatives will contact you shortly.`;
        this.store.dispatch(
          new PageAlertAddAction({
            message: message,
            alertType: 'SUCCESS'
          })
        );
        this.router.navigate([`/data-import`]);
      });
  }

  onVendorSelect(vendor: Vendor): void {
    this.selectedVendor = vendor;
    this.docForm.get('vendor').setValue(vendor ? vendor : null);
    this.docForm.updateValueAndValidity();
  }

  openSelectVendor(evt) {

    evt.preventDefault();

    const modalRef = this.modalService.open(FindOrCreateVendorModalComponent, m => {
      m.title = 'Select Vendor or Organization';
      this.error = '';
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [vendor] = result;
      this.onVendorSelect(vendor);
    });
  }
}
