<form *ngIf="editEquipmentForm && loaded; else loading" [formGroup]="editEquipmentForm"
      (ngSubmit)="onCreateEquipment(editEquipmentForm.value)">

  <mi-server-error [error]="submitError"></mi-server-error>

  <div class='input-container'>
    <table class="formTable">
      <tbody>
      <tr>
        <td>
          <mi-extended-input [labelText]="'Manufacturer'" [labelRequired]="true"
                             [inputControl]="editEquipmentForm.controls['vendor_id']"
                             [inputErrors]="editEquipmentForm.controls['vendor_id'].errors"
                             [inputTouched]="editEquipmentForm.controls['vendor_id'].touched"
                             [errorDefs]="{required: 'Please enter a Equipment Vendor.'}">

            <ng-select *ngIf="!lockedVendor" class="mi-select" [items]="vendors" bindLabel="vendor_name" bindValue="_id"
                       [multiple]="false"
                       [closeOnSelect]="true" formControlName="vendor_id" (change)="onSelectVendor($event)"
                       placeholder='Choose Manufacturer'>
            </ng-select>

            <ng-container *ngIf="lockedVendor">
              <product-vendor-header [locked]="true" [vendor]="lockedVendor">
              </product-vendor-header>
              <input type="hidden" formControlName="vendor_id" autocomplete="off">
            </ng-container>

          </mi-extended-input>
        </td>
        <td>
          <mi-extended-input [labelText]="'Model #'" [labelRequired]="true"
                             [inputControl]="editEquipmentForm.controls['equipment_catalog_id']"
                             [inputErrors]="editEquipmentForm.controls['equipment_catalog_id'].errors"
                             [inputTouched]="editEquipmentForm.controls['equipment_catalog_id'].touched"
                             [errorDefs]="{required: 'Please enter a Equipment Model.'}">

            <ng-select class="mi-select" [items]="catalogs" bindLabel="category_name" bindValue="id" [multiple]="false"
                       [closeOnSelect]="true" formControlName="equipment_catalog_id" [addTag]="onAddCatalogRef"
                       (change)="onSelectCatalog($event)"
                       placeholder='Choose Model'>
            </ng-select>
          </mi-extended-input>
        </td>
      </tr>
      <tr>
        <td>
          <mi-extended-input [labelText]="'Model Description'"
                             [inputControl]="editEquipmentForm.controls['description']"
                             [inputErrors]="editEquipmentForm.controls['description'].errors"
                             [inputTouched]="editEquipmentForm.controls['description'].touched"
                             [errorDefs]="{required: 'Please enter cancellation instructions.'}">
              <textarea id="description" name="description" formControlName="description"
                        placeholder='set a Model Description'
                        type="text" [attr.disabled]="step>1?null:''"></textarea>
          </mi-extended-input>
        </td>
        <td>
          <mi-extended-input labelText="Serial Number or Tag" [inputControl]="editEquipmentForm.controls['serial']"
                             [inputErrors]="editEquipmentForm.controls['serial'].errors"
                             [inputTouched]="editEquipmentForm.controls['serial'].touched"
                             [errorDefs]="{required: 'Please enter a Serial Number or Tag.'}">
            <input id="serial" name="serial" formControlName="serial" placeholder='set a Serial Number or Tag'
                   type="text"
                   autocomplete="off"
                   [attr.disabled]="step>1?null:''">
          </mi-extended-input>
          <mi-extended-input labelText="Name" [labelRequired]="true" [inputControl]="editEquipmentForm.controls['name']"
                             [inputErrors]="editEquipmentForm.controls['name'].errors"
                             [inputTouched]="editEquipmentForm.controls['name'].touched"
                             [errorDefs]="{required: 'Please enter a name.'}">
          <input id="name" name="name" formControlName="name" placeholder='set a Name' type="text"
                 [attr.disabled]="step>1?null:''" autocomplete="off">
          </mi-extended-input>
        </td>
      </tr>
      <tr>
        <td>
          <mi-extended-input labelText="Status" [labelRequired]="true"
                            [inputControl]="editEquipmentForm.controls['status']"
                            [inputErrors]="editEquipmentForm.controls['status'].errors"
                            [inputTouched]="editEquipmentForm.controls['status'].touched"
                            [errorDefs]="{required: 'Equipment status is required.'}">
            <select formControlName="status">
              <option *ngFor="let status of this.statuses;"
                      value="{{ status.value }}">
                {{ 'EQUIPMENT_STATUSES.' + status.name | translate}}
              </option>
            </select>
          </mi-extended-input>
          </td>
        <td>
          <mi-extended-input labelText="Price $" [inputControl]="editEquipmentForm.controls['price']"
                             [inputErrors]="editEquipmentForm.controls['price'].errors"
                             [inputTouched]="editEquipmentForm.controls['price'].touched"
                             [errorDefs]="{required: 'Please enter a Price.'}">
            <input id="price" name="price" formControlName="price" placeholder='set a Price' type="text" autocomplete="off"
                   [attr.disabled]="step>1?null:''" mi-restrict-input miRestrictType="PositiveNumber">
          </mi-extended-input>
        </td>
      </tr>
      <tr>
        <td class="end-user" colspan="2">
          <h4>Subscriber</h4>
          <mi-select-requestor
            [tenantUser]="end_user"
            (selectRequestor)="selectEndUser($event)">
          </mi-select-requestor>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="select-location">
      <ng-container *ngIf="location; else createLocation">
        <h3 class="non-input-label">Address</h3>
        <mi-address-display
          [location]="location"
          (onDeselect)="locationOnToggleChange(false)">
        </mi-address-display>
      </ng-container>
      <ng-template #createLocation>
        <h3>Location</h3>
        <ng-container>
          <mi-toggle
            toggleTitle="This Equipment has a Location"
            [showToggleText]="true"
            toggleOffText="No"
            toggleOnText="Yes"
            (onChange)="locationOnToggleChange($event)">
          </mi-toggle>
        </ng-container>
        <ng-container *ngIf="showFindLocation">
          <mi-find-or-create-location
            (locationSelected)="onLocationSelected($event)"
            (stepChanged)="stepChanged($event)">
          </mi-find-or-create-location>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div class="button-container" *ngIf="showButtons">
    <ng-container *ngIf="equipmentId">
      <a mi-button
         class="button-alternate"
         buttonTheme="alert-light-theme"
         routerLink="../delete">
        Delete
      </a>
    </ng-container>
    <a mi-button class="button--cancel" buttonTheme="light-theme" (click)="onCancel($event)">
      Cancel
    </a>

    <button mi-button type="submit" [disabled]="!editEquipmentForm.valid">
      Save Equipment
    </button>
  </div>
</form>


<div class="sections recentlyAddedEquipments" *ngIf="recentlyAddedEquipments && recentlyAddedEquipments.length>0">
  <section class="data-section">
    <h2>Recently Added Equipment</h2>
    <mi-sortable-table [rows]="recentlyAddedEquipments"
                       [columns]="recentlyAddedEquipmentsColumns">
    </mi-sortable-table>
  </section>
</div>

<ng-template #cloneTmpl let-row="row" let-value="value">
  <a (click)="onClone(row)" mi-button class="button dark-theme">Clone</a>
</ng-template>

<ng-template #vendorTmpl let-row="row" let-value="value">
  <mi-id-link
    [id]="row.equipment_catalog.vendor.display_id"
    title="{{row.equipment_catalog.vendor.display_id}} - {{row.equipment_catalog.vendor.vendor_name}}">
  </mi-id-link>
  {{row.equipment_catalog.vendor.vendor_name}}
</ng-template>

<ng-template #serialTmpl let-row="row" let-value="value">
    <span class="serialNumber" title="{{value}}">
      {{value}}
    </span>
</ng-template>

<ng-template #statusTmpl let-row="row" let-value="value">
  {{'EQUIPMENT_STATUSES.' + ( row.is_active ? 'ACTIVE': 'INACTIVE' ) | translate}}
</ng-template>

<ng-template #locationTmpl let-row="row" let-value="value">
  <div *ngIf="value" class="location-value">
    <mi-simple-location [compact]="true" [location]="value"></mi-simple-location>
  </div>
</ng-template>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
