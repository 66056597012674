import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Email} from '../../../shared/models/Email.interface';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantEmailService {

  private apiUrlCrud = '/{tenant}/account/email/';
  private apiImage = `/{tenant}/images/`;
  private apiUrlByService = '/{tenant}/emails/service/by-display-id/';
  private apiUrlByParentService = '/{tenant}/emails/parent-service/by-display-id/';

  constructor(private http: HttpClient) {
  }

  createEmail(email: Email): Observable<Email> {
    return this.http
      .post(`${this.apiUrlCrud}`, email)
      .map((response:any) => response.data.email as Email);
  }

  getEmail(email_id: string): Observable<Email> {
    return this.http
      .get(`${this.apiUrlCrud}${email_id}`)
      .map((response: any) => response.data.email as Email);
  }

  getAccessFeature(access_feature: any): Observable<any> {
    return this.http
      .get(`/access-file?access_feature=${access_feature}`)
      .map((response: any) => response.data);
  }

  getTenantLogo(): Observable<any> {
    return this.http
      .get(`${this.apiImage}`)
      .map((response: any) => response.data);
  }

  getEmailByService(display_id: string, size: number = 3, from: number = 0): Observable<Email> {
    return this.http
      .get(`${this.apiUrlByService}${display_id}?size=${size}&from=${from}`)
      .map((response: any) => {
        return response.data.emails as Email;
      });
  }

  getEmailByParentService(display_id: string, size: number = 3, from: number = 0): Observable<Email> {
    return this.http
      .get(`${this.apiUrlByParentService}${display_id}?size=${size}&from=${from}`)
      .map((response: any) => {
        return response.data.emails as Email;
      });
  }
}
