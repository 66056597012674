import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MiService, MiServiceDetails, ParentServiceGroup } from "../../../../../../shared/models/MiService";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Store } from "@ngrx/store";
import { ModalService } from "@independer/ng-modal";
import { SelectChildrenServiceModalComponent } from "src/tenant/components/select-children-service-modal/select-children-service-modal.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ParentServiceGroupService } from "src/libs/api2/parent-service-group-v2/parent-service-group.service";
import { ToastrService } from 'ngx-toastr';

@Component(
  {
    selector: 'mi-subsection-parent-details',
    templateUrl: './subsection-parent-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionParentsDetailsComponent extends AbstractPageComponent implements OnInit {

  editable: boolean = false;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiService> = new EventEmitter<MiService>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();
  
  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  pageLoaded: boolean;
  updating: boolean = false;
  contactError: string = '';
  parentService: MiService;
  parentName: string;
  newParentName: string;
  parentNameForm: FormGroup;
  alerts: any[] = [];
  serviceDetails: MiServiceDetails
  parentServiceGroup :  ParentServiceGroup

  @ViewChild('optionInput', { static: true }) optionInput: ElementRef;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private parentServiceGroupService: ParentServiceGroupService,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit() {
    this.parentNameForm = this.formBuilder.group({
      name: [null]
    });
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
            this.getParentService()
          }
        });
  }

  onUpdate(){
    this.updateServiceDetailsStore.emit()
  }

  parentNameFormSubmit(data) {
    this.parentServiceGroupService.editParentName(this.serviceDetails.general.parent_service_group_id, data.name)
      .first()
      .subscribe(newParent => {
        this.toastr.success(`Parent Group name has been updated`);
        this.updateServiceDetailsStore.emit();
      }, e => {
        this.toastr.error(`Facing issue while updating Parent Group Name`);
        this.error = e
      });
  }

  getParentService() {
    this.parentServiceGroupService.getParentServiceGroup(this.serviceDetails.general.parent_service_display_id, this.serviceDetails.general.tenant_id)
      .toPromise()
      .then(res=> {
        this.pageLoaded = true
        this.parentServiceGroup =  res
        this.parentNameForm.setValue({ name: res.name });
      })
      .catch(e => {
        this.error = e;
      });
  }

  onToggleExpand(): void {
    this.contactError = '';
    this.isOpen = !this.isOpen;
  }

  addServiceModal() {
    const modalRef = this.modalService.open(SelectChildrenServiceModalComponent, m => {
      m.vendorName = this.serviceDetails.vendor.purchasing_vendor_name;
    });
    modalRef.closed.subscribe(({ reason, result = [] }) => {
      if (result[0] && result[0].display_ids && result[0].display_ids.length) {
        this.updateServiceDetailsStore.emit();
      }
    });
  }
}
