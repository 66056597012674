import {Component, Input, OnInit} from "@angular/core";
import { SiteV2Service } from '../../../libs/api2/site-v2/site-v2.service';
import { BusinessUnitBaseV2 } from 'src/libs/api2/types/BusinessUnitBaseV2.interface';

@Component({
  selector: 'mi-simple-bu',
  templateUrl: './simple-business-unit.component.html'
})

export class SimpleBusinessUnitComponent implements OnInit {
  @Input() bu: BusinessUnitBaseV2;
  @Input() id: string;
  error:any;

  constructor(private buService: SiteV2Service){}

  ngOnInit() {
    if(!this.bu) {
      // we need to load data.
      this.buService.getBusinessUnit(this.id)
        .first()
        .subscribe((bu) => this.bu = bu,  e=> this.error = e)
    }
  }
}
