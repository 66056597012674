<ng-container>
  <div
    class="{{ endOfTermAction === 'TERMINATED' ? 'service-detail-header-events terminated' : 'service-detail-header-events' }}">
    <ng-container>
      <div style="width: 100%">
        <table>
          <tr>
            <td [ngClass]="{'expired': endOfTermAction === 'EXPIRED'}">
              <div class="section-title">{{title}}</div>
            </td>
          </tr>
        </table>
        <hr>
        <span
          *ngIf="(this.serviceStatus === 'AUTO_RENEWAL_COMPLETE' ||  this.serviceStatus === 'EXPIRED' || this.serviceStatus === 'EXPIRING_COMPLETE' || this.serviceStatus === 'PENDING_TERMINATION')">
          <span>
            <table>
              <tr>
                <td>Term Status</td>
                <td>
                  {{termStatus}}
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  Inaction Value
                </td>
                <td>
                  {{inActionValue | tenantcurrency:'1.0-0'}}
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  Inaction Count
                </td>
                <td>
                  {{inActionCount}}
                </td>
              </tr>
            </table>
          </span>
        </span>
        <table
          *ngIf="(this.serviceStatus === 'AUTO_RENEWAL_INCOMPLETE' || this.serviceStatus === 'EXPIRING_INCOMPLETE' || this.serviceStatus === 'TERMINATED' || this.serviceStatus === 'UNKNOWN' || this.serviceStatus === 'NULL'  )">
          <tr>
            <td>
              <p class="un-managed-service">
                {{bottomMessage}}
              </p>
            </td>
            </tr>
            </table>
      </div>
    </ng-container>
    <div class="parent-bottom">
    </div>
  </div>
</ng-container>