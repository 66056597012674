<mi-sortable-table *ngIf="selectedServiceGroups && selectedServiceGroups.length>0; else nonSelected" [rows]="selectedServiceGroups"
  [columns]="selectedServiceGroupsColumns">
</mi-sortable-table>

<ng-template #nonSelected>
  <div class="no-results">
    {{'TENANT.SERVICE_DETAILS.SERVICE_GROUP.SERVICE_GROUP_EMPTY_TITLE' | translate}}
  </div>
</ng-template>
<div class="button-container {{isDetailPage? 'detail-page': ''}}">
  <button type="button" mi-button buttonTheme="light-theme" (click)="openServiceGroupModal()"> 
    Add Service Group
  </button>
</div>

<ng-template #removeTmpl let-row="row" let-value="value">
  <a (click)="onServiceGroupRemoved(row)" class="deleteServiceGroup"></a>
</ng-template>


<ng-template #alertStatusTmpl let-row="row" let-value="value">
  {{ 'ALERT_STATUS.' + (row.alert_status||"ALERT_STATUS_NONE") | translate}}
</ng-template>
