export const environment = {
    production: true,
    CURRENT_PRIVACY_POLICY_URL: 'https://miso3.com/privacy-policy/',
    CURRENT_TERMS_AND_CONDITIONS_URL: 'https://miso3.com/terms/',
    MICRO_SERVICE_BASE_URL: 'https://api2.miso3app.com',
    MICRO_SERVICE_UPLOAD_URL: 'https://api2.miso3app.com/upload',
    GOOGLE_MAP_API_KEY: 'AIzaSyCfeyoklqc-VUWcsnR8G-K6aci_EAQCENw',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_51NAjkGGFLtAUYF7ov9yWELFHlY2g2bKSynYMhC5osIkZbdWX54dTHPwniW2w1PtzChzFiCm1d0tgj9KBMlWaGfM800R5DCyZII',
    AWS_PATH: 'https://miso3-uploads-public.s3.amazonaws.com'
};
