import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {UserUpload} from '../../../shared/models/UserUpload.interface';

@Injectable()
export class TenantUserUploadService {
  private apiUrlUpload = '_MICRO_SERVICE_/tenant/upload-users';

  constructor(private http: HttpClient) {}

  uploadFile(file: FormData, business_unit_id: string, process_id: string): Observable<UserUpload> {
    const url = this.apiUrlUpload + '?business_unit_id=' + business_unit_id + '&process_id=' + process_id;

    const httpOptions = {
      headers: new HttpHeaders({
        'ignore-content-type-inject': 'ignore-content-type-inject',
      })
    };

    return this.http
      .post(url , file, httpOptions)
      .map((response: any) => response);
  }
}
