<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <mi-service-financial-form [financial]="financial" ctaLabel="Next"(updateCompleted)="onFormSubmit($event)"></mi-service-financial-form>

  </ng-container>

  <ng-template #static>

    <ul *ngIf="ready" class="horizontal">
      <li>
        <dl>
          <dt>{{'TENANT.SERVICE_DETAILS.QUANTITY' | translate}}</dt>
          <dd>{{financial.quantity | round}}</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>{{ 'TENANT.SERVICE_DETAILS.RECURRING_COSTS_LABEL' | translate | translateSelector: financial.billing_frequency }}</dt>
          <dd>{{financial.cost | round | currency:'USD':'symbol':'1.0'}}</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>{{'TENANT.SERVICE_DETAILS.BILLING_FREQUENCY_LABEL' | translate}}</dt>
          <dd>
            {{('MONTHS_QTY'
            | translate:{count: financial.billing_frequency}
            | translateSelector: financial.billing_frequency) || NO_DATA }}
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>{{ 'TENANT.SERVICE_DETAILS.TOTAL_NON_RECURRING_COSTS_LABEL' | translate }}</dt>
          <dd>
            {{ (financial.quantity * financial.non_recurring_cost) | round | currency:'USD':'symbol':'1.00'}}
          </dd>
        </dl>
      </li>
    </ul>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

