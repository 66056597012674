import { TenantCreationV2Data } from 'src/shared/models/TenantCreationV2';
import * as TenantCreationV2Actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";

export interface State {
  data: TenantCreationV2Data;
}

const initialState: State = {
  data: {
    localData: {
      stepNumber: 0
    }
  }
};

export function reducer(state = initialState, action: TenantCreationV2Actions.Actions): State {
  switch (action.type) {
    case TenantCreationV2Actions.ActionTypes.RESET: {
      return {
        ...initialState
      };
    }
    case TenantCreationV2Actions.ActionTypes.SET_DATA: {
      console.log("action", action);
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    }
    case TenantCreationV2Actions.ActionTypes.GET_DATA: {
      return {
        ...state
      };
    }
  }
}

export const getTenantCreationV2Data = createFeatureSelector<State>('tenantCreationV2');
export const getStep = createSelector(
  getTenantCreationV2Data,
  (state) => state?.data.localData.stepNumber
);