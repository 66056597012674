import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import { ReverseTableRowTemplate } from 'src/shared/models/ReverseTable';
import { ServiceAudit, ServiceAuditData, ServiceAuditTableColumnOrder, ServiceAuditTableColumns } from 'src/shared/models/ServiceAudit';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';

@Component({
  selector: 'mi-subsection-service-audit-table',
  styleUrls: ['subsection-service-audit-table.component.scss'],
  templateUrl: 'subsection-service-audit-table.component.html'
})
export class SubsectionServiceAuditTableComponent extends AbstractPageComponent implements OnInit {
  
  columns: ServiceAuditTableColumns[] = [];
  rows: ReverseTableRowTemplate[] = [];

  costChangeSelected: boolean = false;
  currencyChangeSelected: boolean = false;
  productChangeSelected: boolean = false;
  ownerChangeSelected: boolean = false;
  subscriberChangeSelected: boolean = false;

  toggleDataLayerTrigger: EventEmitter<string> = new EventEmitter<string>();
  changeDataTrigger: EventEmitter<ReverseTableRowTemplate[]> = new EventEmitter<ReverseTableRowTemplate[]>();

  dataLayersSelected: any[] = [];

  @Input() serviceAuditData: ServiceAudit;
  fullData: ServiceAudit = new ServiceAudit();

  columnOrder: ServiceAuditTableColumnOrder[] = [];

  selectDropdownItems: any[] = [];
  dropdownSelectedValue: any;

  currentMonth: string;
  currentYear: string;

  customRange: boolean = false;
  filterDisabled: boolean = true;

  filterFromValue: string = "";
  filterToValue: string = "";

  customFieldDisplay: boolean = true;
  lastSectionIndex: number = 0;

  additionalInfoFields: any[] = [];
  customFieldData : any[]

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.currentMonth = new Date().toLocaleString('default', {month: 'long'});
    this.currentYear = new Date().toLocaleString('default', {year: 'numeric'});
    this.selectDropdownItems = [
      {
        name: "Since inception",
        id: "inception"
      },
      {
        name: "This month ("+this.currentMonth+")",
        id: "month"
      },
      {
        name: "Year to date ("+this.currentYear+")",
        id: "year"
      },
      // {
      //   name: "Custom defined",
      //   id: "custom"
      // }
    ];

    this.dropdownSelectedValue = "inception";

    this.additionalInfoFields.push(
      { 
        label: "Last Extraction Date of Job",
        value: this.serviceAuditData.job_extraction_date == null ? "" : new Date(this.serviceAuditData.job_extraction_date).toLocaleString('default', {
                                                                                                                    year: 'numeric',
                                                                                                                    month: '2-digit',
                                                                                                                    day: '2-digit',
                                                                                                                    hour: '2-digit',
                                                                                                                    minute: '2-digit',
                                                                                                                    second: '2-digit'
                                                                                                                  })
      },
      { 
        label: "Service Validation Flag",
        value: this.serviceAuditData.is_validation_required
      },
      { 
        label: "Last DOC-ID of Job",
        value: this.serviceAuditData.last_job_doc_id
      }
    );

    this.columnOrder.push(
      {
        id: "service_identifier",
        value: "Service ID",
        order: 12,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "requestor_email",
        //value: "Service Owner",
        value: "Accountable Executive",
        order: 18,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "update_by",
        value: "Updated By",
        order: 3,
        section_id: "section_2",
        section_order: 2,
        display: true
      },
      {
        id: "currency",
        value: "Currency",
        order: 16,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        //id: "assigned_email",
        id: "mi_miso_user_email",
        //value: "M3 Owner",
        value: "Mi-MiSO Owner",
        order: 17,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "display_id",
        value: "MISO ID",
        order: 6,
        section_id: "section_4",
        section_order: 4,
        display: false
      },
      {
        id: "service_start_date",
        value: "Start Date",
        order: 19,
        section_id: "section_4",
        section_order: 4,
        display: false,
        is_date: true
      },
      /* {
        id: "connector_name",
        value: "Name of Connector",
        order: 5,
        section_id: "section_2",
        section_order: 2,
        display: true
      }, */
      {
        id: "template_code",
        value: "Template Code",
        order: 5,
        section_id: "section_2",
        section_order: 2,
        display: true
      },
      {
        id: "extraction_date",
        value: "Last Extraction Date of Service",
        order: 2,
        section_id: "section_2",
        section_order: 2,
        display: true,
        is_date: true
      },
      {
        id: "end_user_email",
        value: "Subscriber",
        order: 20,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "account_identifier",
        value: "Account ID",
        order: 9,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "update_date",
        value: "Last Service Update",
        order: 1,
        section_id: "section_1",
        section_order: 1,
        display: true,
        is_date: true
      },
      {
        id: "product_name",
        value: "Product",
        order: 11,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "parent_group",
        value: "Parent Group",
        order: 7,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "doc_id",
        value: "Last DOC-ID of Service",
        order: 4,
        section_id: "section_2",
        section_order: 2,
        display: true
      },
      {
        id: "end_of_term",
        value: "End of Term",
        order: 21,
        section_id: "section_4",
        section_order: 4,
        display: false
      },
      {
        id: "cancellation_date",
        value: "Termination Date",
        order: 22,
        section_id: "section_4",
        section_order: 4,
        display: false,
        is_date: true
      },
      {
        id: "vendor_manufacturing",
        value: "Vendor (Manufacturing)",
        order: 10,
        section_id: "section_4",
        section_order: 4,
        display: false
      },
      {
        id: "vendor_purchasing",
        value: "Vendor (Purchasing)",
        order: 8,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "service_quantity",
        value: "Quantity",
        order: 13,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "unit_recurring_cost",
        value: "Unit Cost",
        order: 14,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "billing_frequency",
        value: "Billing Frequency",
        order: 15,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "unit_non_recurring_cost",
        value: "Unit Non-recurring Cost",
        order: 23,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "active_usage",
        value: "Activity Alert",
        order: 24,
        section_id: "section_4",
        section_order: 4,
        display: true
      },
      {
        id: "price_increase_percentage",
        value: "Price Increase %",
        order: 25,
        section_id: "section_4",
        section_order: 4,
        display: true
      }
    );

    this.columnOrder.sort((a, b) => {
      if(a.section_order != b.section_order) {
        return a.section_order - b.section_order;
      }
      else {
        return a.order - b.order
      }
    });

    this.columnOrder.forEach(column => {
      if(column.display) {
        let indexOfSection = this.columns.findIndex(col => col.section_id == column.section_id);

        if(indexOfSection == -1) {
          this.columns.push(
            {
              section_id: column.section_id,
              data: []
            }
          );
          indexOfSection = this.columns.findIndex(col => col.section_id == column.section_id);
          this.lastSectionIndex = indexOfSection;
        }
        if(column.is_date) {
          this.columns[indexOfSection].data.push(
            {
              id: column.id,
              value: column.value,
              is_date: true
            }
          );
        }
        else {
          this.columns[indexOfSection].data.push(
            {
              id: column.id,
              value: column.value
            }
          );
        }        
      }
    });

    if (this.customFieldDisplay) {
      this.serviceAuditData.data.forEach(row => {
        row.data.forEach(item => {
          if (item.is_custom_field) {
            if (this.columns[this.lastSectionIndex].data.findIndex(col => col.id == item.id) == -1) {
              this.columns[this.lastSectionIndex].data.push(
                {
                  id: item.id,
                  value: item.id,
                }
              );
            }
          }
        });
      });
    }
    
    this.serviceAuditData.data.sort((a, b) => {
      let dateA = new Date(a.extraction_date).getTime();
      let dateB = new Date(b.extraction_date).getTime();
      return dateB - dateA;
    });

    this.fullData.display_id = this.serviceAuditData.display_id;
    this.fullData.miso_service_id = this.serviceAuditData.miso_service_id;
    this.fullData.data = [...this.serviceAuditData.data];

    this.setRowData(this.serviceAuditData);

    this.dataLayersSelected.push(
      {
        data_layer: "color_red",
        value: true
      },
      {
        data_layer: "color_apple_green",
        value: true
      },
      {
        data_layer: "color_mid_blue",
        value: true
      },
      {
        data_layer: "color_orange",
        value: true
      },
      {
        data_layer: "color_purple",
        value: true
      }
    );

    this.selectLayerToggles();
  }

  selectLayerToggles(): void {
    this.costChangeSelected = true;
    this.currencyChangeSelected = true;
    this.productChangeSelected = true;
    this.ownerChangeSelected = true;
    this.subscriberChangeSelected = true;
  }

  onToggleNotification(event: any, dataLayer: string): void {
    this.toggleDataLayer(dataLayer);
  }

  toggleDataLayer(dataLayer: string): void {
    switch(dataLayer) {
      case "cost_change":
        this.toggleDataLayerTrigger.next("color_purple");
        break;
      case "owner_change":
        this.toggleDataLayerTrigger.next("color_orange");
        break;
      case "subscriber_change":
        this.toggleDataLayerTrigger.next("color_apple_green");
        break;
      case "product_change":
        this.toggleDataLayerTrigger.next("color_red");
        break;
    }
  }

  dropdownChanged(): void {
    this.customRange = false;

    if(this.dropdownSelectedValue == "inception") {
      this.serviceAuditData.data = [...this.fullData.data];
      this.setRowData(this.serviceAuditData);
    }
    else if(this.dropdownSelectedValue == "month") {
      this.serviceAuditData.data = [...this.fullData.data];
      let tempData:ServiceAuditData[] = [];

      this.serviceAuditData.data.forEach(row => 
      {
        let updateYear = new Date(row.update_date).toLocaleString('default', {year: 'numeric'});
        let updateMonth = new Date(row.update_date).toLocaleString('default', {month: 'long'});

        if(updateYear == this.currentYear && updateMonth == this.currentMonth) {
          tempData.push(row);
        }
      });

      this.serviceAuditData.data = [...tempData];
      this.setRowData(this.serviceAuditData);
    }
    else if(this.dropdownSelectedValue == "year") {
      this.serviceAuditData.data = [...this.fullData.data];
      let tempData:ServiceAuditData[] = [];

      this.serviceAuditData.data.forEach(row => 
      {
        let updateYear = new Date(row.update_date).toLocaleString('default', {year: 'numeric'});

        if(updateYear == this.currentYear) {
          tempData.push(row);
        }
      });

      this.serviceAuditData.data = [...tempData];
      this.setRowData(this.serviceAuditData);
    }
    else if(this.dropdownSelectedValue == "custom") {
      this.customRange = true;
    }
  }

  setRowData(data: ServiceAudit): void {
    this.rows = [];

    data.data.forEach((row, index) => {
      this.rows.push(
        {
          id:"id" + (index+1).toString(),
          data:[]
        }
      )
      row.data.forEach(item => {
        if(item.changed == true) {
          switch(item.id) {
            case "unit_recurring_cost":
              this.rows[index].data.push(
                {
                  column_id: item.id,
                  value: item.value,
                  color_purple: true
                }
              );
              break;
            case "service_owner":
              this.rows[index].data.push(
                {
                  column_id: item.id,
                  value: item.value,
                  color_orange: true
                }
              );
              break;
            case "end_user_email":
              this.rows[index].data.push(
                {
                  column_id: item.id,
                  value: item.value,
                  color_apple_green: true
                }
              );
              break;
            case "product_name":
              this.rows[index].data.push(
                {
                  column_id: item.id,
                  value: item.value,
                  color_red: true
                }
              );
              break;
            default:
              this.rows[index].data.push(
                {
                  column_id: item.id,
                  value: item.value
                }
              );
              break;              
          }
        }
        else {
          this.rows[index].data.push(
            {
              column_id: item.id,
              value: item.value
            }
          )
        }
      })
    });

    this.changeDataTrigger.next(this.rows);
  }

  // onFromChange(event: string): void {
  //   if(event) {
  //     this.filterFromValue = event;

  //     if(this.filterFromValue.length > 0 && this.filterToValue.length > 0) {
  //       this.filterDisabled = false;
  //     }
  //   }
  // }

  // onToChange(event: string): void {
  //   if(event) {
  //     this.filterToValue = event;

  //     if(this.filterFromValue.length > 0 && this.filterToValue.length > 0) {
  //       this.filterDisabled = false;
  //     }
  //   }
  // }

  // filterData(): void {
  //   let filterFromDate = new Date(this.filterFromValue).getTime();
  //   let filterToDate = new Date(this.filterToValue).getTime() + (((60 * 60 * 24) - 1) * 1000);

  //   this.serviceAuditData.data = [...this.fullData.data];

  //   let tempData: ServiceAuditData[] = [];

  //   this.serviceAuditData.data.forEach(row => 
  //   {
  //     let updateTime = new Date(row.update_date).getTime();

  //     if(updateTime >= filterFromDate && updateTime <= filterToDate) {
  //       tempData.push(row);
  //     }
  //   });

  //   this.serviceAuditData.data = [...tempData];

  //   this.setRowData(this.serviceAuditData);
  // }

  
}

