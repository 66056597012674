<div>

  <mi-page-header
    title="Incomplete Data By Vendor"
    [breadcrumbs]="breadcrumbs">
    <a href="{{supportLink}}">Get MISO Support</a>
  </mi-page-header>

  <p class=half-page>The following vendors are missing important data that may be costing you some money. We predict that fix these missing data issues will save you both in the short and long term period. Click on the Vendor to generate a missing data report to help you fix these issues.</p>

  <h2>Incomplete Data</h2>
  <div class="clear-fix">
  <ng-container *ngFor="let vendor of vendorMissingData">
    <div class="tile">

      <div class="header">

        <div class="logo-container">
          <mi-vendor-logo
            *ngIf="vendor.vendor.logo"
            [url]="vendor.vendor.logo"></mi-vendor-logo>
        </div>

        <span class="vendor">
          {{vendor.vendor_name}}
        </span> 

        <mi-id-link
            [id]="vendor.vendor.display_id">
        </mi-id-link>

      </div>

      <div class="missing-data-details-wrap">
        <div class="missing-data-details">

          <div>
            <dt>Service Count</dt>
            <dd>
                {{vendor.total_count}}
            </dd>
          </div>
          <div>
            <dt>Yearly Spending</dt>
            <dd>
              {{vendor.total_mrc * 12 | tenantcurrency:'1.2-2'}}
            </dd>
          </div>
        </div>
        <div>
          <a
            routerLink="/vendors/{{vendor.vendor.display_id}}/missing-data"
            mi-button buttonTheme="dark-theme" class="cta">
            View Incomplete Data
          </a>
        </div>
      </div>


    </div>
  </ng-container>
  </div>
</div>
