<modal class="wide" [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>All Filters</h4>
  </modal-header>
  <modal-content>
    <ng-container [ngSwitch]="searchType">
      <ng-container *ngSwitchCase="'CONTACT'">
        <mi-tenant-contact-search-filters></mi-tenant-contact-search-filters>
      </ng-container>
      <ng-container *ngSwitchCase="'TENANT_USER'">
        <mi-tenant-user-search-filters></mi-tenant-user-search-filters>
      </ng-container>
      <ng-container *ngSwitchCase="'SERVICE'">
        <mi-standard-tenant-search-filters></mi-standard-tenant-search-filters>
      </ng-container>
    </ng-container>

    <div class="button-container">
      <button
        class="modal-cancel"
        mi-button
        buttonTheme="light-theme"
        (click)="close()">
        Close
      </button>
    </div>

  </modal-content>
</modal>