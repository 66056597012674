import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ServiceV2Service } from "src/libs/api2/service-v2/service-v2.service";
import { ServiceListingColumnsCustom, ServiceListingColumnsInitial, ServiceV2Filter, ServiceV2Request, ServiceV2Response, ServiceV2ResponseData } from "src/shared/models/ServiceV2";
import { Link } from "src/shared/ng-models/Link.interface";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../../shared/state/service-v2/actions';

@Component({
  templateUrl: './mi-service-list-v2.component.html',
  styleUrls: ['./mi-service-list-v2.component.scss']
})

export class MiServiceListV2Component extends AbstractPageComponent implements OnInit {
  breadcrumbs: Link[];
  headerLinks: Link[];
  error: any;
  searchLoaded: boolean = false;

  request: ServiceV2Request = {
    pageSize: 0,
    page_number: 0,
    tenant_id: "",
    search: "",
    sort: {},
    filters: [],
    columns: []
  };
  serviceData: ServiceV2Response;
  videoModalTitle: string = "How To Manage Services";
  videoLink: string = "https://play.vidyard.com/bQZy1VTFubSsHT1v2GCpin";

  constructor(private serviceV2Service: ServiceV2Service,
              private store: Store<ServiceV2Reducer.State>
   ) {
    super();
  }

  ngOnInit(): void {
    this.breadcrumbs = [{
      url: '',
      label: 'Dashboard',
      internal: true
    }, {
      url: '/services',
      label: 'Services',
      internal: true
    }];

    this.setInitialConfig();
    this.getServices();
    this.startListen();
  }

  startListen() {
    this.store.select(ServiceV2Reducer.getLoaded)
      .subscribe(
        data => {
          console.log("data loaded", data);
          if(data == true) {
            this.searchLoaded = true;
          }
          else {
            this.searchLoaded = false;
          }
        }
      );
  }

  setInitialConfig(): void {
    this.request.pageSize = 25;
    this.request.page_number = 0;
    this.request.tenant_id = FrontEndSettingsUtil.getDomain();
    //this.request.cursor = 0;
    this.setActiveFilter();
    this.request.columns = ServiceListingColumnsInitial;
    console.log("setInitialConfig", this.request);
  }

  setActiveFilter(): void {
    let newFilter: ServiceV2Filter = {
      field_name: "service_status",
      type: "option",
      is_custom_field: false,
      is_tag: false,
      is_location: false,
      values: [
        "active"
      ],
      removable: false
    };

    this.request.filters.push(newFilter);
  }

  combineData(data1: ServiceV2ResponseData[], data2: ServiceV2ResponseData[]): any {
    let combinedData: ServiceV2ResponseData[] = [];
    /*
    data1.sort( (a,b) =>
      (a.vendorName > b.vendorName) ? 1 : -1
    );
    */
   //console.log("data1", data1);
   //console.log("data2", data2);
   
   data1.forEach((data, index) => {
    data.miso_service.custom_fields = data2[index].miso_service.custom_fields;
    data.miso_service.tags = data2[index].miso_service.tags;
   });

   return data1;
  }

  formatData(data: ServiceV2ResponseData[]): any {
    //console.log("formatData", data);

    data.map(service => {
      if(service.miso_service.tags.length > 0) {
        let spliceList: number[] = [];
        
        for(let i=0; i<service.miso_service.tags.length; i++) {
          let tagId = service.miso_service.tags[i].tag_id;
          for(let j=0; j<service.miso_service.tags.length; j++) {
            let comparedTagId = service.miso_service.tags[j].tag_id;
            //console.log("formatData tagId", tagId);
            //console.log("formatData comparedTagId", comparedTagId);
            if(j == i) continue;
            if((tagId == comparedTagId) && (spliceList.indexOf(i) == -1)) {
              service.miso_service.tags[i].tag_value += ", " + service.miso_service.tags[j].tag_value;
              spliceList.push(j);
            }
          }
        }

        //console.log("formatData spliceList", spliceList);

        spliceList.map(spliceIndex => {
          service.miso_service.tags.splice(spliceIndex, 1);
        });
      }
    });

    return data;
  }

  setLoadMoreStatus(data: any[]): void {
    if(data.length > 0) {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(true));
    }
    else {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(false));
    }
  }

  getServices(): void {
    this.store.dispatch(new ServiceV2Actions.SetSearchLoading(this.serviceData));
    this.serviceV2Service.getServices(this.request)
    .toPromise()
    .then((response: ServiceV2Response) => {
      console.log("response", response);
      this.setLoadMoreStatus([...response.data]);
      this.serviceData = response;
      this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
      this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
      this.serviceV2Service.getTotalCmrc(this.request)
      .toPromise()
      .then((totalCmrc: any) => {
        this.serviceData.total_mrc = totalCmrc.total_cmrc;
        this.serviceData.total = totalCmrc.total_service_count;
        this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
        
        this.request.columns = ServiceListingColumnsCustom;
        this.serviceV2Service.getServices(this.request)
        .toPromise()
        .then((response: ServiceV2Response) => {
          let combinedData = this.combineData([...this.serviceData.data], [...response.data]);
          //console.log("combinedData", combinedData);
          let formattedData = this.formatData([...combinedData]);
          console.log("formattedData", formattedData);
          this.serviceData.data = formattedData;
          this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
        })
        .catch(error => {
          console.log("error while getServices_2", error);
          this.error = error;
        });
        
      })
      .catch(error => {
        console.log("error while getCmrc", error);
        this.error = error;
      });      
    })
    .catch((error) => {
      console.log("error while getServices", error);
      this.error = error;
    })
  }
}