import {Component} from "@angular/core";

@Component({
  selector: 'mi-parent-service-details-header',
  templateUrl: './parent-service-details-header.component.html',
  styleUrls: ['./parent-service-details-header.component.scss']
})

export class ParentServiceDetailsHeaderComponent{
  // Nothing.
}
