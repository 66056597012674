import * as orderCreateActions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {
  OrderDraft,
  OrderDetailsState,
  ServiceDetailsState,
  VendorAndServiceState,
  ServiceTermState
} from "../../../../../../shared/models/OrderDraft";
import {createLogger, LOG_LEVELS} from "../../../../../../shared/logger";
import {Contact} from "../../../../../../shared/models/Contact";

const log = createLogger(LOG_LEVELS.REDUX_DEBUG);

export const OrderSteps: any = {
  CREATE_ORDER: 0,
  ORDER_DETAILS: 1,
  SERVICE_DETAILS: 2,
  SERVICE_TERM: 3,
};

export const VendorAndServiceSteps: any = {

  SEARCH_PURCHASING_VENDOR: 0,
  SELECT_PURCHASHING_VENDOR: 1,

  EDIT_ACCOUNT_ID: 10,
  CONFIRM_ACCOUNT_ID: 11,
  SUMMARY_ACCOUNT_ID: 12,

  SELECT_MANUFACTURING_VENDOR: 24,
  SELECT_VENDOR_PRODUCTS: 25,
  CREATE_PURCHASING_PRODUCT: 30,
  PRODUCT_SUMMARY: 50,

};

export const OrderDetailsSteps: any = {

  ORDER_DETAILS_START: 0,
  ORDER_DETAILS_DATE_CONFIRM: 1,
  ORDER_DETAILS_DATE_SAVED: 2,
  ORDER_DETAILS_SHOW_CONTACTS: 3,
  ORDER_DETAILS_SEARCH_CONTACTS: 4,
  ORDER_DETAILS_CONTACTS_COMPLETE: 5,

};

export const ServiceDetailsSteps: any = {
  SERVICE_DETAILS_START: 0,
  SERVICE_DETAILS_STEP_TRACKING_DETAILS_SUMMARY: 1,
  SERVICE_DETAILS_STEP_FINANCIALS: 2,
  SERVICE_DETAILS_STEP_FINANCIALS_SUMMARY: 3,
  SERVICE_DETAILS_STEP_USAGE: 4,
  SERVICE_DETAILS_STEP_USAGE_SUMMARY: 5,
  SERVICE_DETAILS_STEP_ADDRESS: 6,
  SERVICE_DETAILS_STEP_ADDRESS_SUMMARY: 7,
  SERVICE_DETAILS_STEP_CUSTOM: 8,
  SERVICE_DETAILS_STEP_CUSTOM_SUMMARY: 9,
};

export const ServiceTermSteps: any = {
  START: 0,
  FORM_SUMMARY: 1,
  DOCUMENT_UPLOAD: 2,
  DOCUMENT_UPLOAD_SUMMARY: 3,
};

const initialState: OrderDraft = {
  activeStep: OrderSteps.CREATE_ORDER,
  activeMarketingMessage: 'ORDER_INSTRUCTIONS.LOW_COST_BULK_IMPORTING',
  error: null,
  loaded: false,
  vendor_and_service: <VendorAndServiceState>{
    completed: false,
    currentStep: VendorAndServiceSteps.SEARCH_PURCHASING_VENDOR,
    nextActivePath: 'order-details',
    purchasing_vendor: null,
    manufacturing_vendor: null,
    account_id: null
  },
  order_details: <OrderDetailsState>{
    completed: false,
    currentStep: OrderDetailsSteps.ORDER_DETAILS_SUCCESS,
    nextActivePath: 'service-details',
  },
  service_details:<ServiceDetailsState>{
    completed: false,
    currentStep: ServiceDetailsSteps.SERVICE_DETAILS_START,
    //currentStep: ServiceDetailsSteps.SERVICE_DETAILS_STEP_CUSTOM,
    nextActivePath: 'terms-and-conditions',
    service_tracking_details: {}
  },
  service_term:<ServiceTermState>{
    currentStep: ServiceTermSteps.START,
    //currentStep: TermsAndConditionsSteps.DOCUMENT_UPLOAD,
    completed: false
  }
};

export function reducer(state = initialState, action: orderCreateActions.Actions): OrderDraft {
  switch (action.type) {

    case orderCreateActions.ActionTypes.LOAD_AS_NEW: {
      return {
        ...initialState,
        loaded: true
      };
    }

    case orderCreateActions.ActionTypes.LOAD_DRAFT:
    case orderCreateActions.ActionTypes.SAVE_DRAFT:
      return {
        ...state,
        loaded: false,
        error: null,
        activeStep: action.payload.activeStep || initialState.activeStep
      };

    case orderCreateActions.ActionTypes.SAVE_DRAFT_SUCCESS:
      //log('Save draft completed', action.payload);
      return state;

    case orderCreateActions.ActionTypes.LOAD_DRAFT_SUCCESS:
      return {
        ...initialState,
        activeMarketingMessage: state.activeMarketingMessage,
        activeStep: state.activeStep,
        loaded: true,
        error: null,
        _id: action.payload.order_draft._id,
        vendor_and_service: {
          ...state.vendor_and_service,
          ...action.payload.order_draft.vendor_and_service,
          currentStep: VendorAndServiceSteps.SEARCH_PURCHASING_VENDOR
        },
        order_details: {
          ...state.order_details,
          ...action.payload.order_draft.order_details,
          currentStep: OrderDetailsSteps.ORDER_DETAILS_START
        },
        service_details: {
          ...state.service_details,
          ...(action.payload.order_draft.service_details[0] || {}),
          currentStep: ServiceDetailsSteps.SERVICE_DETAILS_START
        },
        service_term: {
          ...state.service_term,
          ...action.payload.order_draft.service_term,
          currentStep: ServiceTermSteps.START
        }
      };
    case orderCreateActions.ActionTypes.LOAD_DRAFT_ERROR:
    case orderCreateActions.ActionTypes.SAVE_DRAFT_ERROR:

      let error:any = 'Error Saving Draft';
      if (action.payload.error.message) {
        error = {
          message: action.payload.error.message,
          stack: action.payload.error.stack
        }
      };

      return {
        ...state,
        loaded: true,
        error
      };

    case orderCreateActions.ActionTypes.SELECT_PURCHASHING_VENDOR: {
      return {
        ...state,
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: VendorAndServiceSteps.SELECT_PURCHASHING_VENDOR,
          purchasing_vendor: action.payload.vendor,
          manufacturing_vendor: action.payload.vendor
        }
      }
    }
    case orderCreateActions.ActionTypes.CONFIRM_PURCHASING_VENDOR: {
      return {
        ...state,
        activeMarketingMessage: 'ORDER_INSTRUCTIONS.DIFFERENCE',
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: VendorAndServiceSteps.EDIT_ACCOUNT_ID
        }
      }
    }

    case orderCreateActions.ActionTypes.RESET_PURCHASING_VENDOR: {
      return {
        ...state,
        activeMarketingMessage: 'ORDER_INSTRUCTIONS.LOW_COST_BULK_IMPORTING',
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: VendorAndServiceSteps.SEARCH_PURCHASING_VENDOR,
          manufacturing_vendor: null,
          purchasing_vendor: null
        }
      };
    }

    case orderCreateActions.ActionTypes.UPDATE_VENDOR_STEP: {
      return {
        ...state,
        activeMarketingMessage: action.payload.instructions ? action.payload.instructions : state.activeMarketingMessage,
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: action.payload.step || state.vendor_and_service.currentStep,
          purchasing_vendor: action.payload.purchasing_vendor || state.vendor_and_service.purchasing_vendor,
          account_id: (action.payload.account_id === null)? null : (action.payload.account_id || state.vendor_and_service.account_id)
        }
      }
    }

    case orderCreateActions.ActionTypes.CREATE_PURCHASING_PRODUCT: {
      return {
        ...state,
        activeMarketingMessage: 'ORDER_INSTRUCTIONS.CHOOSING_PRODUCT_TYPE',
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: VendorAndServiceSteps.CREATE_PURCHASING_PRODUCT
        }
      }
    }

    case orderCreateActions.ActionTypes.CREATE_PURCHASING_VENDOR: {
      return {
        ...state,
        activeMarketingMessage: 'ORDER_INSTRUCTIONS.NEW_VENDOR_FOUND',
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: VendorAndServiceSteps.CREATE_PURCHASING_VENDOR
        }
      }
    }

    case orderCreateActions.ActionTypes.SET_MANUFACTURING_VENDOR: {
      const newProps: any = {
        manufacturing_vendor: action.payload.vendor
      };

      if (action.payload.vendor === null) {
        // this came from an "unset"
        newProps.currentStep = VendorAndServiceSteps.SELECT_MANUFACTURING_VENDOR
      } else {
        newProps.currentStep = VendorAndServiceSteps.SELECT_VENDOR_PRODUCTS;
      }

      return {
        ...state,
        activeMarketingMessage: action.payload.marketingMessage || state.activeMarketingMessage,
        vendor_and_service: {
          ...state.vendor_and_service,
          ...newProps
        }
      }
    }

    case orderCreateActions.ActionTypes.VIEW_PRODUCT_SUMMARY: {
      return {
        ...state,
        activeMarketingMessage: action.payload.instructions || state.activeMarketingMessage,
        vendor_and_service: {
          ...state.vendor_and_service,
          currentStep: VendorAndServiceSteps.PRODUCT_SUMMARY,
          product: action.payload.product
        }
      }
    }

    case orderCreateActions.ActionTypes.EDIT_SERVICE_DETAILS: {
      return {
        ...state,
        order_details: {
          ...state.order_details,
          currentStep: OrderDetailsSteps.ORDER_DETAILS_START
        }
      }
    }

    case orderCreateActions.ActionTypes.CONFIRM_SERVICE_DETAILS: {
      return {
        ...state,
        order_details: {
          ...state.order_details,
          currentStep: OrderDetailsSteps.ORDER_DETAILS_DATE_CONFIRM,
          service_order_date: action.payload.service_order_date,
          service_order_number: action.payload.service_order_number
        }
      }
    }

    case orderCreateActions.ActionTypes.SAVE_SERVICE_DETAILS: {

      /* TODO: Hook in Save at this point? */
      log('Save Progress');

      return {
        ...state,
        activeMarketingMessage: 'ORDER_INSTRUCTIONS.WHY_CONTACTS',
        order_details: {
          ...state.order_details,
          currentStep: OrderDetailsSteps.ORDER_DETAILS_DATE_SAVED
        }
      }
    }

    case orderCreateActions.ActionTypes.CONTACT_SELECTED_SERVICE_DETAILS: {
      return {
        ...state,
        order_details: {
          ...state.order_details,
          currentStep: OrderDetailsSteps.ORDER_DETAILS_SHOW_CONTACTS,
          requestor: action.payload.service_contacts.requestor
        },
        service_details:{
          ...state.service_details,
          custom_data:/*new_custom_data*/action.payload.service_contacts.custom_data
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_START: {
      return {
        ...state,
        activeMarketingMessage: 'ORDER_INSTRUCTIONS.INSTALLATIONS',
        activeStep: action.payload.activeStep
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_STEP_ONE: {
      return {
        ...state,
        activeMarketingMessage: '',
        service_details:{
          ...state.service_details,
          currentStep: ServiceDetailsSteps.SERVICE_DETAILS_STEP_TRACKING_DETAILS_SUMMARY,
          service_tracking_details:{
            ...state.service_details.service_tracking_details,
            assigned_email: action.payload.assignee.email,
            division: action.payload.division,
            team: action.payload.team,
            requestor_email: action.payload.requestor_email,
            end_user_email: action.payload.end_user_email,
            term_length_answer: action.payload.term_length_answer,
            start_date: action.payload.termStartDate,
            term_length: action.payload.termLength,
            end_date: action.payload.termEndDate,
          }
        },
        service_details_service_tracking_details_assigned_user: action.payload.assignee
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_STEP_FINANCIALS: {
      return {
        ...state,
        service_details:{
          ...state.service_details,
          currentStep: ServiceDetailsSteps.SERVICE_DETAILS_STEP_FINANCIALS_SUMMARY,
          recurring_cost: action.payload.recurring_costs,
          quantity: action.payload.quantity,
          billing_frequency: action.payload.billing_frequency,
          non_recurring_cost: action.payload.non_recurring_cost
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_STEP_USAGE: {
      return {
        ...state,
        service_details:{
          ...state.service_details,
          currentStep: ServiceDetailsSteps.SERVICE_DETAILS_STEP_USAGE_SUMMARY,
          service_identifier: action.payload.service_identifier
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_STEP_ADDRESS: {
      return {
        ...state,
        service_details:{
          ...state.service_details,
          currentStep: ServiceDetailsSteps.SERVICE_DETAILS_STEP_ADDRESS_SUMMARY,
          locations: action.payload.locations
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_STEP_CUSTOM: {
      return {
        ...state,
        service_details:{
          ...state.service_details,
          currentStep: ServiceDetailsSteps.SERVICE_DETAILS_STEP_CUSTOM_SUMMARY,
          custom_data: action.payload.custom_data
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_DETAILS_STEP_SET: {
      return {
        ...state,
        activeMarketingMessage: action.payload.message || state.activeMarketingMessage,
        service_details: {
          ...state.service_details,
          currentStep: action.payload.step
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_TERM_STEP_SET: {
      return {
        ...state,
        activeMarketingMessage: action.payload.message || state.activeMarketingMessage,
        service_term: {
          ...state.service_term,
          currentStep: action.payload.step
        }
      }
    }

    case orderCreateActions.ActionTypes.SERVICE_TERM_STEP_FORM_SUMMARY: {
      return {
        ...state,
        activeMarketingMessage: action.payload.message || state.activeMarketingMessage,
        service_term: {
          ...state.service_term,
          ...action.payload.service_term
        }
      }
    }

    case orderCreateActions.ActionTypes.UP_DATE_MARKETING_MESSAGE: {
      return {
        ...state,
        activeMarketingMessage: action.payload.message || state.activeMarketingMessage
      }
    }

    default: {
      return state;
    }
  }
}

export const getOrderCreateStore = createFeatureSelector<OrderDraft>('order-create-store');
export const getStepNumber = createSelector(getOrderCreateStore, (state: OrderDraft) => state.activeStep);
