import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { createLogger, LOG_LEVELS } from '../../../shared/logger';
import { catchError, map } from "rxjs/operators";
import { UserStoreService } from "../../user-store/user-store.service";
import FrontEndSettingsUtil from '../../../shared/utils/frontEndSettings.util';

const log = createLogger(LOG_LEVELS.AUTH_GUARD);

@Injectable()
export class TenantAlignAuthGuard implements CanActivate {

  constructor(
    private userStoreService: UserStoreService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean> {
    // debugger;
    return this.userStoreService.getLoggedInService()
      .pipe(
        map((res) => {
          const getTenantConfigData = FrontEndSettingsUtil.getTenantConfig()
          // console.log("here TenantAlignAuthGuard", getTenantConfigData)
          if (!getTenantConfigData || getTenantConfigData.length === 0 || getTenantConfigData === undefined) {
            // console.log("here in if condition")
            this.router.navigate(['/dashboard']);
          }
          else{
            return true
          }

        }),
        catchError((error: any) => {
          log('this person tried to access Align');
          console.log("here catchError")
          this.router.navigate(['/dashboard']);
          // window.location.href = linkFormatter.getLoginLink(window.location.href);
          return of(false);
        })
      );
  }
}
