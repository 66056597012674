import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TenantComponentsModule} from "../../../components/tenant-components.module";
import {SharedModule} from "../../../../shared/shared.module";
import {ServiceTermInstanceWrapComponent} from "./service-term-instance-wrap.component";
import {ServiceTermDetailsComponent} from "./detail/service-term-details.component";
import {ServiceTermSearchEventsComponent} from "./search-events/service-term-search-events.component";
import {ServiceTermSearchMiServicesComponent} from "./search-mi-services/service-term-search-mi-services.component";
import {TermAndConditionCreateComponent} from "./create/terms-and-conditions-create.component";


@NgModule({
  declarations: [
    ServiceTermInstanceWrapComponent,
    ServiceTermDetailsComponent,
    ServiceTermSearchEventsComponent,
    ServiceTermSearchMiServicesComponent,
    TermAndConditionCreateComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule
  ],
  providers: [],
  exports: [
    ServiceTermInstanceWrapComponent,
    ServiceTermDetailsComponent,
    ServiceTermSearchEventsComponent,
    ServiceTermSearchMiServicesComponent,
    TermAndConditionCreateComponent,
  ]
})
export class TermAndConditionInstanceWrapModule {
}
