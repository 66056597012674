<mi-server-error [error]="error"></mi-server-error>
<ng-container *ngIf="loaded; else loading">
  <ng-container *ngIf="canDeleteMode && canDelete">
    <h2>Delete Associated Document</h2>
    <mi-side-notice noticeType="WARNING">
        <strong>Do you want to delete Associated Document "{{associatedDocument.name}}" ?</strong>
        <p>Remember, this action cannot be reversed.</p>
        <p>Press delete to remove this Associated Document permanently</p>
        <div class="button-container">
          <a mi-button
            class="button--cancel"
            buttonTheme="alert-light-theme"
            (click)="onCancel($event)">
            Cancel
          </a>
          <a mi-button
            buttonTheme="alert-theme"
            (click)="permanentlyDelete()">
            Delete
          </a>
        </div>
      </mi-side-notice>
  </ng-container>

</ng-container>

  <ng-container *ngIf="!canDeleteMode && canDelete.dependents.length">
    <mi-cascade-need-replace
      title= "Cannot Delete Associated Document"
      subtitle= "The following items are associated with this Associated Document."
      [dependents]= "canDelete.dependents"
      (canceled)="onCancel($event)">
      <mi-alert-item alertType="ERROR">
        Before deleting the Associated Document, please remove the Associated Document from the associated items above.
      </mi-alert-item>
    </mi-cascade-need-replace>
  </ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
