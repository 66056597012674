<form *ngIf="createForm"
      [formGroup]="createForm"
      (ngSubmit)="onFormSubmit(createForm.value)"
      autocomplete="off">

  <mi-extended-input
        class="label--large"
        labelText="Name"
        required="true"
        hint="Service group names must be unique."
        [errorDefs]="{required: 'A unique name is required.'}">

    <input
          formControlName="name"
          type="text"
          name="name"
          placeholder="Service Group Name"/>

  </mi-extended-input>

  <mi-extended-input
        class="label--large"
        labelText="Description">

    <angular-tinymce formControlName="description"></angular-tinymce>
    
  </mi-extended-input>

  <div class="button-container">

    <ng-container *ngIf="inEditMode; else createMode">
      <a mi-button
        class="button-alternate"
        buttonTheme="alert-light-theme"
        routerLink="../delete">
        Delete
      </a>

      <a routerLink=".." mi-button buttonTheme="light-theme">Cancel</a>

      <button type="submit"
              mi-button
              [disabled]="!createForm.valid">
        Update Service Group
      </button>
    </ng-container>

    <ng-template #createMode>
      <a 
        (click)="onCancel()">
       Cancel
     </a>

     <button type="submit"
             mi-button
             [disabled]="!createForm.valid">
       Create Service Group
     </button>
    </ng-template>

  </div>

</form>
