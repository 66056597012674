<ng-container *ngIf="step===SEARCH_STATE">

  <mi-search placeholder="Find Contact"
             value="{{searchString}}"
             ngClass="contact-search compact"
             (searchSubmit)="onSearchSubmit()"
             (searchTermChanged)="onUpdateSearchString($event)">
  </mi-search>

  <ng-container *ngIf="loaded; else loading">

    <mi-tabs-container externalClasses="reverse-color" class="full-width" (indexChanged)="toggle($event)" [subnav]="true">
      <mi-tab *ngIf="showVendorTab && vendorName" tabTitle="{{vendorName}} ({{totalVendorContacts}})" [withPane]="false"  [active]="selectedTab=='ONLY_VENDOR_CONTACTS'">
      </mi-tab>
      <mi-tab tabTitle="{{'CONTACTS.COMPONENT.ALL_CONTACTS' | translate}} ({{totalAllContacts}})" [withPane]="false"  [active]="selectedTab==''">
      </mi-tab>
      <mi-tab *ngIf="recentContactIds.length" tabTitle="{{'CONTACTS.COMPONENT.RECENT' | translate}} ({{totalRecentContacts}})" [withPane]="false"  [active]="selectedTab=='ONLY_RECENT_CONTACTS'">
      </mi-tab>
    </mi-tabs-container>
    <ng-container *ngIf="!searching; else searchingContacts">
      <table class="results contacts" width="100%" *ngIf="contacts.length > 0; else noContacts">
        <tr>
          <th>
            {{'CONTACTS.COMPONENT.NAME' | translate}}
          </th>
          <th>
            {{'CONTACTS.COMPONENT.EMAIL' | translate}}
          </th>
          <th>
            {{'CONTACTS.COMPONENT.VENDOR' | translate}}
          </th>
          <!-- <th class="service_total_count">
            {{'CONTACTS.COMPONENT.SERVICE_TOTAL_COUNT' | translate}}
          </th>
          <th class="service_mrc_total">
            {{'CONTACTS.COMPONENT.SERVICE_MRC_TOTAL' | translate}} 
          </th> -->
        </tr>
        <ng-container *ngFor="let contact of contacts">
          <tr (click)="onSelectContact(contact)" [ngClass]="{inactive:contact.status === INACTIVE}">
            <td>
              <div class="contact" title="{{contact.full_name}} <{{contact.email}}>">
                <span class="limit">{{contact.first_name}}</span>&nbsp;
                <span class="limit">{{contact.last_name}}</span>
              </div>
            </td>
            <td>
              <div class="contact" title="{{contact.full_name}} <{{contact.email}}>">
                <<span class="limit">{{contact.emailLabel}}</span>{{contact.emailDomain}}>
              </div>
            </td>            
            <td>
              <div class="contact--company">
                {{contact.vendor.vendor_name}} ({{ 'CONTACT_ROLES.' + contact.role | translate}})
              </div>
            </td>
            <!-- <td>
              <div class="contact--service_total_count">
                {{contact.service_total_count}} 
              </div>
            </td>
            <td>
              <div class="contact--service_mrc_total">
                {{contact.service_mrc_total | currency:'USD':symbol:'1.0-0'}}
              </div>
            </td> -->
          </tr>
        </ng-container>
      </table>

      <div class="load-more"
          *ngIf="!loadingMore && (contacts.length < totalContacts)">
        <a href="#" (click)="onLoadMoreClicked($event)">Load More</a>
      </div>

      <ng-template #noContacts>
        <div *ngIf="!searchString || searchString.length <= 2">
          {{'CONTACTS.COMPONENT.NO_CONTACTS' | translate}}
        </div>
        <div *ngIf="searchString && searchString.length > 2">
          {{ 'CONTACTS.COMPONENT.NO_CONTACTS_MATCHING' | translate:{search_string: searchString} }}
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="createMode===true">
      <div class="centered-cta-message">
        <span class="create-for-title">Can't find your contact?</span><br>
        <button
          mi-button
          (click)="swapTo(CREATE_STATE)">
          Create New Contact
        </button>
      </div>
    </ng-container>
    <ng-template #searchingContacts>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</ng-container>

<ng-container *ngIf="step===CREATE_STATE">
  <h3>Create a new Contact</h3>
  <mi-contact-form
    (canceled)="swapTo(SEARCH_STATE)"
    (contactComplete)="onContactCreated($event)"
    [activeLocked]="true"
  ></mi-contact-form>
</ng-container>
