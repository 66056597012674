import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'mi-tab',
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.scss']
})

export class TabContainerComponent {
  @Input() tabTitle: string;
  @Input() active: boolean= false;
  @Input() route: string = '';
  @Input() withPane: boolean = true;
  @Input() noBorder: boolean = false;
}
