import {FormControl, AbstractControl, ValidatorFn} from '@angular/forms';
import PasswordUtil from '../common/utils/passwordUtil';

export function emailValidator(input: FormControl) {
  if (input.value === '' || input.value === null) {
    return null;
  } else {
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // Mongoose Email Validation: /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    const isValid = emailRegEx.test(input.value || '');
    return isValid ? null : {emailValidator: true};
  }
}

export function noWhitespaceValidator(input: FormControl) {
  const noSpace = /^(.*\s+.*)+$/;
  const isValid = !noSpace.test((input.value || ''));
  return isValid ? null : {'whitespace': true};
}

export function alphanumericValidator(input: FormControl) {
  const validChars = /^[a-zA-Z0-9\-]*$/;
  const isValid = validChars.test((input.value || ''));
  return isValid ? null : {'alphanumeric': true};
}

export function passwordValidator(input: FormControl) {
  return PasswordUtil.isValid(input.value) ? null : {
    passwordValidator: true
  };
}

export class passwordMatchValidator {
  static matchPassword(AC: AbstractControl) {
    if (AC) {
      let password = AC.get('password').value; // to get value in input tag
      let confirmPassword = AC.get('password_confirm').value; // to get value in input tag
      if (password != confirmPassword) {
        AC.get('password_confirm').setErrors({matchPassword: true});
      }
      return null;
    }
  }
}

export function urlValidator(input: FormControl) {
  if (input.value === '' || input.value === null) {
    return null;
  } else {
    const urlRegEx = /^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const isValid = urlRegEx.test((String(input.value).toLowerCase() || ''));
    return isValid ? null : {'url': true};
  }
}

export function integerValidator(input: FormControl) {
  const integer = /^-?[0-9][^\.]*$/;
  const isValid = integer.test((input.value || '')) || input.value === 0;
  return isValid ? null : {'integer': true};
}

export function numberValidator(input: FormControl) {
  let numAsString: string = String(input.value); // Cast as string.
  if (!input.value || input.value === '') {
    return null;
  }
  if (+numAsString === 0) {
    return null;
  } else if (((numAsString.match(/\./gm) || []).length > 1) || !+numAsString) {
    return {
      notANumber: true
    };
  }
  return null;
}

export function minLengthWithTrimmedWhitespace(minLength: number = 10) {
  return (input: FormControl) => {
    return input && input.value ? input.value.trim().length >= minLength ? null : {minLengthWithTrimmedWhiteSpace: true} : null;
  };
}

export function maxLengthWithTrimmedWhitespace(minLength: number = 10) {
  return (input: FormControl) => {
    return input && input.value ? input.value.trim().length <= minLength ? null : {maxLengthWithTrimmedWhiteSpace: true} : null;
  };
}

export function htmlEmptyValidator(input: FormControl) {

  if (input.value === '' || input.value === null) {
    return null;
  }

  let str: string = input.value + '';
  const stripTags = /(<([^>]+)>)/ig;
  const stripWhiteSpace = /\s/g;
  const stripHtmlSpace = /&nbsp;/g;

  str = str.replace(stripTags, '');
  str = str.replace(stripWhiteSpace, '');
  str = str.replace(stripHtmlSpace, '');

  return str.length > 0 ? null : {emptyHtml: true};
}

export function dateStringValidator(input: FormControl) {
  if (input.value === '' || input.value === null) {
    return null;
  }
  const dateChars = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  const isValid = dateChars.test(input.value) && !isNaN(Date.parse(input.value));
  return isValid ? null : {date: true};
}

export function lessThenValidator(control: string, controlToCompare: string) : ValidatorFn{
  return (form: AbstractControl): {[key: string]: any} | null => {
    let v = form.get(control);
    let v2 = form.get(controlToCompare);
    //debugger;
    if (v && v2 && v.value && v2.value && Number.parseFloat(v.value) > Number.parseFloat(v2.value)) return {
        validateLessThen: true
    }
    return null;
  }
}


export function lessThenDateValidator(control: string, controlToCompare: string) : ValidatorFn{
  return (form: AbstractControl): {[key: string]: any} | null => {
    let v = form.get(control);
    let v2 = form.get(controlToCompare);
    //debugger;
    if (v && v2 && v.value && v2.value && Date.parse(v.value) > Date.parse(v2.value)) return {
        validateLessThen: true
    }
    return null;
  }
}

export function companyCodeValidator(input: FormControl) {
  if (input.value === '' || input.value === null) {
    return null;
  } else {
    const urlRegEx = /^([a-z]|[0-9]){4}$/;
    const isValid = urlRegEx.test((String(input.value).toLowerCase() || ''));
    return isValid ? null : {'code': true};
  }
}
