import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild, OnInit, ElementRef, AfterViewInit} from '@angular/core';
import {SortableTableColumn, SortableTemplatesEnum} from '../../ng-models/SortableTableColumn.interface';
import {NO_DATA} from '../../ng-models/SectionDataTable.model';
import * as appDataReducer from '../../state/app-data/reducers';
import * as ASActions from '../../state/advanced-search/actions';
import * as ASReducer from '../../state/advanced-search/reducers';
import {AsSimpleStateUpdate} from "../../state/advanced-search/actions";
import { environment } from 'src/environments/environment';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import FeDateUtil from 'src/shared/utils/FeDate.util';
import { ModalService } from '@independer/ng-modal';
import { SortableTableColumnSelectModalComponent } from '../sortable-table-column-select-modal/sortable-table-column-select-modal.component';
import { TenantCustomFieldService } from 'src/libs/api/tenant/tenantCustomFields.service';
import { CustomField } from 'src/shared/models/CustomField';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { DecimalPipe } from '@angular/common';
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import { CUSTOMER_DOCUMENT_UPLOAD_TYPES } from 'src/common/utils/sharedConstants';
import { ActivatedRoute } from '@angular/router';
import { getTableView } from './table-view-options';
import { CustomFieldsV2Service } from 'src/libs/api2/service-v2/custom-fields-v2.service';
import { tenantBridgeCustomFieldsResponse, tenantBridgesResponse } from 'src/shared/models/ServiceV2';
import { SortableTableColumnSelectModalV2Component } from '../sortable-table-column-select-modal-v2/sortable-table-column-select-modal-v2.component';
import { TagsService } from 'src/libs/api2/tags/tags.service';
import { TagDefinition } from 'src/shared/models/Tags';

export enum TagTypes {
  TEXT = 'TEXT',
  OPTION = 'OPTION'
}

type SortableTableVariant =
  | 'NORMAL'
  | 'SMALL';


@Component({
  selector: 'mi-sortable-draggable-table-v2',
  templateUrl: './sortable-draggable-table-v2.component.html',
  styleUrls: ['./sortable-draggable-table-v2.component.scss']
})

export class SortableDraggableTableV2Component extends AbstractPageComponent implements OnInit, AfterViewInit {

  protected readonly NO_DATA: string = NO_DATA;
  private originalSortChanged: EventEmitter<any> = new EventEmitter();
  readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;
  _rows: Array<any> = [];
  _columns: Array<SortableTableColumn> = [];
  _classes: any = {};
  _rowsSelectable: boolean = false;
  _selectedRow: any = null;
  timeZone: string = "America/New_York"
  dragStart:number;
  customFieldById: any = {};
  error: any;
  loaded: boolean = false;
  tenantId: string = '';
  table_view: string = '';
  numberOfStickyColumn: number = 1;

  allCustomFields: tenantBridgeCustomFieldsResponse[] = [];
  allTags: TagDefinition[] = [];
  tagById: any = {};
  
  @Input() public externalClasses: string = '';
  @Output() public sortChanged: EventEmitter<any> = this.originalSortChanged;
  @Output() public rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() public rowIdProp: string = 'display_id';
  @Input() searchType: string = '';
  @Input() hasCustomFields: boolean = false;
  @Input() editableColumns: boolean = true;
  @Output() public onFilterClick: EventEmitter<any> = new EventEmitter<any>();

  _columnClicked: string = '';

  @ViewChild('defaultCellTpl', {static: true}) defaultCellTpl: TemplateRef<any>;
  @ViewChild('defaultHeaderTpl', {static: true}) defaultHeaderTpl: TemplateRef<any>;
  @ViewChild('idLinkTpl', {static: true}) idLinkTpl: TemplateRef<any>;
  @ViewChild('idLinkTpl2', {static: true}) idLinkTpl2: TemplateRef<any>;
  @ViewChild('contactTpl', {static: true}) contactTpl: TemplateRef<any>;
  @ViewChild('shortDateTpl', {static: true}) shortDateTpl: TemplateRef<any>;
  @ViewChild('shortDateTimeTpl', {static: true}) shortDateTimeTpl: TemplateRef<any>;
  @ViewChild('shortISODateTimeTpl', {static: true}) shortISODateTimeTpl: TemplateRef<any>;
  @ViewChild('trimmedTextTpl', {static: true}) trimmedTextTpl: TemplateRef<any>;
  @ViewChild('currencyTpl', {static: true}) currencyTpl: TemplateRef<any>;
  @ViewChild('roundedCurrencyTpl', {static: true}) roundedCurrencyTpl: TemplateRef<any>;
  @ViewChild('truncateTpl', {static: true}) truncateTpl: TemplateRef<any>;
  @ViewChild('numberTpl', {static: true}) numberTpl: TemplateRef<any>;
  @ViewChild('locationsTpl', {static: true}) locationsTpl: TemplateRef<any>;
  @ViewChild('customFieldTpl', {static: true}) customFieldTpl: TemplateRef<any>;
  @ViewChild('tagTpl', {static: true}) tagTpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
  @ViewChild('countTmpl', {static: true}) countTmpl: TemplateRef<any>;
  @ViewChild('tenantCurrencyTpl', {static: true}) tenantCurrencyTpl: TemplateRef<any>;
  @ViewChild('serviceCurrencyTpl', {static: true}) serviceCurrencyTpl: TemplateRef<any>;
  @ViewChild('fileType', {static: true}) fileType: TemplateRef<any>;
  @ViewChild('fileSize', {static: true}) fileSize: TemplateRef<any>;
  @ViewChild('fileLink', {static: true}) fileLink: TemplateRef<any>;
  @ViewChild('idLinkTpl2Edit', {static: true}) idLinkTpl2Edit: TemplateRef<any>;
  
  @ViewChild('tableWrap') tableWrap!: ElementRef;
  @ViewChild('topScroll') topScroll!: ElementRef;
  // @ViewChild('customFieldHeaderTpl', {static: true}) customFieldHeaderTpl: TemplateRef<any>;

  constructor(private store: Store<appDataReducer.State>,
    private modalService: ModalService,
    private tenantCustomFieldService: TenantCustomFieldService,
    private customFieldsV2Service: CustomFieldsV2Service,
    private _decimalPipe: DecimalPipe,
    private tenantDocumentManagementService: TenantDocumentManagementService,
    private activatedRoute: ActivatedRoute,
    private tagsService: TagsService
    ) {
    super();
  }

  ngAfterViewInit(): void {
    const tableWrap = this.tableWrap.nativeElement;
    const topScroll = this.topScroll.nativeElement;
  
    const updateScrollWidth = () => {
      const table = tableWrap.querySelector('table') as HTMLTableElement;
      const scrollContent = topScroll.querySelector('.top-scroll-inner') as HTMLElement;
      scrollContent.style.width = `${table.offsetWidth}px`;
    };
  
    updateScrollWidth();
  
    topScroll.addEventListener('scroll', () => {
      tableWrap.scrollLeft = topScroll.scrollLeft;
    });
  
    tableWrap.addEventListener('scroll', () => {
      topScroll.scrollLeft = tableWrap.scrollLeft;
    });
  
    tableWrap.addEventListener('wheel', (event: WheelEvent) => {
      if (event.deltaX !== 0) {
        event.preventDefault();
        topScroll.scrollLeft += event.deltaX;
      }
    });
  
    const mutationObserver = new MutationObserver(() => {
      updateScrollWidth();
    });
  
    const table = tableWrap.querySelector('table') as HTMLTableElement;
    mutationObserver.observe(table, { childList: true, subtree: true });
  }

  ngOnInit(){
    //console.log("_columns", this._columns);
    //console.log("_rows", this._rows);
    if(this.searchType === "SERVICE" ){
      this.numberOfStickyColumn = 3;
    }

    this.activatedRoute.queryParams
    .subscribe( queryParmas => {
      if(queryParmas['table_view']){
        this.table_view = queryParmas['table_view'];
      } else {
        this.table_view = '';
      }
    });

    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    tenantStateData.first()
      .subscribe(data => {
        this.tenantId = data.tenantData.domain;
        if(data && data.tenantData && data.tenantData.timezone) {
          this.timeZone =  data.tenantData.timezone;
        }
        /*
        if(this.hasCustomFields){
          this.getCustomFields();
        }
        else{
          if(this.searchType && this.searchType !== "NO_REDUX"){
            this._columns = this.retrieveViewableColumn(this.searchType);
            this.updateColumnsState();
          }
          this.loaded = true;
        }
        */
        this.getTags();
        //this.getCustomFields();
      });

    if(this.searchType && this.searchType !== "NO_REDUX"){
      this.store.select(ASReducer.ASColumns)
      .takeUntil(this.destroy$)
      .subscribe(
        columns => {
          this._columns = this.changeColumnsLayout(columns);
        }
      ); 
    }
  }

  saveViewableColumn(searchType: string, tenantId:string, columns: any) {
    //console.log("saveViewableColumn");
    //console.log("columns", columns);
    //console.log("saveViewableColumn _columns", this._columns);
    //console.log("customFieldById", this.customFieldById);

    let currentColumns = this._columns.map(item => {
      let column : ASReducer.IColumn = {
        name: item.name,
        hidden: item.hidden ? true : false,
        is_custom_data: false,
        is_tag_data: false
      }
      if(this.customHeaderName(item.name)) {
        column.name = this.customFieldById[item.name];
        column.is_custom_data = true;
        column.is_tag_data = false;
        column.prop = item.name;
      }
      else if(this.tagHeaderName(item.name)) {
        column.name = this.tagById[item.name];
        column.is_custom_data = false;
        column.is_tag_data = true;
        column.prop = item.name;
      }
      else {
        column.prop = item.prop.split(".")[2];
      }
      return column;
    });
    //console.log("saveViewableColumn currentColumns", currentColumns);
    const valuetosave = JSON.stringify({[tenantId]: currentColumns});
    //console.log("valuetosave", valuetosave);
    /// save to session with  searchType identifier
    window.localStorage.setItem(searchType, valuetosave);
    //console.log("calling updateColumnsState");
    this.updateColumnsState();
  }
  
  retrieveViewableColumn(searchType: string) {
    //console.log("retrieveViewableColumn", searchType);
    if(!searchType){
      return this._columns;
    } else if(this.table_view) {
      return this.changeColumnsLayout(getTableView(this.searchType, this.table_view));
    }
    /// retrieve from session with searchType identifier
    const data = window.localStorage.getItem(searchType);
    if (data) {
      //console.log("localStorage data", data);
      const viewableColumn = JSON.parse(data);
      //console.log("localStorage viewableColumn", viewableColumn);
      // Use saved session when user has saved new filter structure in session persons.some(person => person.name === "Peter")
      if (viewableColumn && viewableColumn[this.tenantId]) {
        let storedColumn = viewableColumn[this.tenantId]
        //console.log("searchType", searchType);
        //console.log("numberOfStickyColumn", this.numberOfStickyColumn);
        if(searchType === "SERVICE" && this.numberOfStickyColumn===3 && (storedColumn[0].name !== "Parent Group" || storedColumn[2].name !== "MISO ID")){
          storedColumn = this._columns;
          this.saveViewableColumn(searchType, this.tenantId, this._columns);
        }
        return this.changeColumnsLayout(storedColumn);
      }
    }
    return this._columns;
  }

  changeColumnsLayout(newColumns){
    //console.log("changeColumnsLayout", newColumns);
    //console.log("customFieldById", this.customFieldById);
    let result = [];
    newColumns.forEach((key) => {
      var found = false;
      this._columns = this._columns.filter((item) => {
          if(!found && (item.name == key.name || this.customFieldById[item.name] == key.name || this.tagById[item.name] == key.name)) {
              item.hidden = key.hidden;
              result.push(item);
              found = true;
              return false;
          } else {
            return true;
          }
      })
    })
    return [...result, ...this._columns];
  }
  
  

  allowDrop(e) {
    e.stopPropagation();
    e.srcElement.classList.add('drag-over');
    e.preventDefault();
  }
  onDragout(e) {
    e.stopPropagation();
    e.srcElement.classList.remove('drag-over');
    e.preventDefault();
  }

  drag(e) {
    if(e.target && e.target.attributes &&  e.target.attributes["data-index"]){
      this.dragStart = e.target.attributes["data-index"].value;
    }
  }

  move(input, from, to) {
    let numberOfDeletedElm = 1;
    const elm = input.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;

    if(to < this.numberOfStickyColumn){
      to = this.numberOfStickyColumn;
    }
  
    input.splice(to, numberOfDeletedElm, elm);
  }

  drop(e) {
    e.preventDefault();
    e.srcElement.classList.remove('drag-over');
    if(e.target && e.target.attributes &&  e.target.attributes["data-index"]){
      this.move(this._columns, this.dragStart, e.target.attributes["data-index"].value);
      if(this.searchType){
        this.saveViewableColumn(this.searchType, this.tenantId, this._columns);
        //console.log("calling updateColumnsState");
        this.updateColumnsState();
      }    
    }
  }

  hide(e){
    const columnIndex = e.target.parentElement.attributes["data-index"].value;
    this._columns[columnIndex].hidden = true;
  }

  updateColumnsState() {
    //console.log("updateColumnsState");
    //console.log("updateColumnsState this._columns", this._columns);
    // let currentColumns: any = this._columns.filter(column => !column.hidden);
    let currentColumns = this._columns.map(item => {

      let column : ASReducer.IColumn = {
        name: item.name,
        hidden: item.hidden? true : false,
        is_custom_data: false,
        is_tag_data: false
      }
      if(this.customHeaderName(item.name)) {
        column.name = this.customFieldById[item.name];
        column.is_custom_data = true;
        column.is_tag_data = false;
        column.prop = item.name;
        if(this.table_view) {
          column.hidden = true;
        }
      }
      else if(this.tagHeaderName(item.name)) {
        column.name = this.tagById[item.name];
        column.is_custom_data = false;
        column.is_tag_data = true;
        column.prop = item.name;
        if(this.table_view) {
          column.hidden = true;
        }
      }
      else {
        column.prop = item.prop.split(".")[2];
      }
      return column;
    });
    //console.log("updateColumnsState currentColumns", currentColumns);
    this.store.dispatch(new AsSimpleStateUpdate({columns: currentColumns}))
  }

  addColumn(){

    const modalRef = this.modalService.open(SortableTableColumnSelectModalV2Component, m => {
      m._columns = this._columns;
      m.customFieldById = this.customFieldById;
      m.tagById = this.tagById;
      m.searchType = this.searchType;
      m.numberOfStickyColumn = this.numberOfStickyColumn;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0] && result[0].columns){
        //this._columns= result[0].columns;
        //console.log("addColumn result[0].columns", result[0].columns);
        for(let i=0; i < this._columns.length; i++) {
          this._columns[i].hidden = result[0].columns.find(resultColumn => resultColumn.name == this._columns[i].name).hidden;
        }
        //console.log("addColumn this._columns", this._columns);
        if(this.searchType){
          this.saveViewableColumn(this.searchType, this.tenantId, this._columns);
        }
      }

    });
  }

  convertTimeZone(time){
    return FeDateUtil.applyTimeZoneFromISO(time, this.timeZone)
  }

  @Input()
  public set variant(value: SortableTableVariant) {
    if (value === 'SMALL') {
      this._classes['mi-sort-table__small'] = true;
    }
  }

  @Input()
  public set rowSelectable(value: boolean) {
    this._classes['mi-sort-table__row-selectable'] = true;
    this._rowsSelectable = value;
  }

  @Input()
  public set columns(values: Array<SortableTableColumn>) {
    //console.log("input set columns");
    // loop through the passed data and resolve the templates

    const templatedColumns = values.map(item => {
      //console.log("item", item);

      switch (item.cellTemplate) {
        case SortableTemplatesEnum.shortDate:
          item.cellTemplate = this.shortDateTpl;
          break;
        case SortableTemplatesEnum.shortDateTime:
          item.cellTemplate = this.shortDateTimeTpl;
          break;
        case SortableTemplatesEnum.shortISODateTime:
          item.cellTemplate = this.shortISODateTimeTpl;
          break;
        case SortableTemplatesEnum.idLink:
          item.cellTemplate = this.idLinkTpl;
          break;
        case SortableTemplatesEnum.location:
          item.cellTemplate = this.locationsTpl; 
          break;
        case SortableTemplatesEnum.idLink2:
          item.cellTemplate = this.idLinkTpl2;
          break;
        case SortableTemplatesEnum.idLink2Edit:
          item.cellTemplate = this.idLinkTpl2Edit;
          break;          
        case SortableTemplatesEnum.contact:
          item.cellTemplate = this.contactTpl;
          break;
        case SortableTemplatesEnum.trimmedText:
          item.cellTemplate = this.trimmedTextTpl;
          break;
        case SortableTemplatesEnum.currency:
          item.cellTemplate = this.currencyTpl;
          break;
        case SortableTemplatesEnum.roundedCurrency:
          item.cellTemplate = this.roundedCurrencyTpl;
          break;
        case SortableTemplatesEnum.tenantCurrency:
          item.cellTemplate = this.tenantCurrencyTpl;
          break;
        case SortableTemplatesEnum.serviceCurrency:
          item.cellTemplate = this.serviceCurrencyTpl;
          break;
        case SortableTemplatesEnum.truncate:
          item.cellTemplate = this.truncateTpl;
          break;
        case SortableTemplatesEnum.number:
          item.cellTemplate = this.numberTpl;
          break;
        case SortableTemplatesEnum.customField:
          item.cellTemplate = this.customFieldTpl;
          break;
        case SortableTemplatesEnum.tag:
          item.cellTemplate = this.tagTpl;
          break;
        case SortableTemplatesEnum.status:
          item.cellTemplate = this.statusTmpl;
          break;
        case SortableTemplatesEnum.count:
          item.cellTemplate = this.countTmpl;
          break;
        case SortableTemplatesEnum.fileType:
          item.cellTemplate = this.fileType;
          break;
        case SortableTemplatesEnum.fileSize:
          item.cellTemplate = this.fileSize;
          break;
        case SortableTemplatesEnum.fileLink:
          item.cellTemplate = this.fileLink;
          break;
        default:
          if (!item.cellTemplate) {
            item.cellTemplate = this.defaultCellTpl;
          }
          break;
      }
      if (!item.headerTemplate) {
        item.headerTemplate = this.defaultHeaderTpl;
      }

      return item;

    });

    this._columns = templatedColumns;
  }

  @Input()
  public set rows(values: Array<any>) {
    this._rows = values;

    if (!this.hasExternalSortListener()) {
      this.internalDataSort();
    }
  }

  /**
   * Take a row and dig deep into its properties recursively by key name
   * @param row
   * @param {string} propertyName
   * @returns {string}
   * @example
   *  getData({test:{foo:5}}, 'test.foo');
   */

  public getData(row: any, propertyName: string): string {
    if (!propertyName) {
      return '';
    }
    if (propertyName === '...') {
      return row;
    }
    return propertyName.split('.').reduce(
      (prev: any, curr: string) => {
        let ret: any = prev[curr];

        if (!prev[curr] && !(prev[curr] === 0)) {
          // anything falsy other than the number zero,
          // is set to empty string
          ret = '';
        }
        return ret;
      },
      row);
  }

  public onChangeTable(data: any): void {
    this._columns.forEach((col: any) => {
      // Remove sorting from the other tables
      if (col.name !== data.column.name && col.sortable) {
        col.sort = '';
      }
    });
    if (!!this.hasExternalSortListener()) {
      this.sortChanged.emit(data);
    } else {
      // We want to sort internally
      this.internalDataSort();
    }
  }

  hasExternalSortListener(): boolean {
    return this.sortChanged !== this.originalSortChanged;
  }

  internalDataSort() {
    this._columns.forEach((col: any) => {
      if (col.sortable && col.sort) {
        this.sort(col);
      }
    });
  }

  sort(column) {
    this._rows.sort((rowA, rowB) => {
      let prop = column.prop;
      if(prop === 'locations_v2.0' || prop === 'locations_v2.1'){
        prop = column.prop + ".address.formatted_address"
      }
      const aValue = this.getData(rowA, prop);
      const bValue = this.getData(rowB, prop);

      if (aValue > bValue) {
        return column.sort === 'asc' ? 1 : -1;
      } else if (aValue < bValue) {
        return column.sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  onRowClick(value: any) {
    if(this._rowsSelectable) {
      this._selectedRow= value;
      this.rowSelected.emit(value);
    }
  }

  stringToNumber(value: any) {
    if (value) {
      value = ('' + value).replace(/[^0-9.-]/g, '');
    }
    return Number(value);
  }

  filterCustomFields(allCustomFields: tenantBridgeCustomFieldsResponse[]): tenantBridgeCustomFieldsResponse[] {
    let uniqueCustomFieldIds: string[] = [];
    let filteredCustomFields: tenantBridgeCustomFieldsResponse[] = [];
    allCustomFields.map(cf => {
      if(uniqueCustomFieldIds.indexOf(cf.id) == -1) {
        uniqueCustomFieldIds.push(cf.id);
        filteredCustomFields.push(cf);
      }
      else {
        console.log("duplicate custom field", cf);        
      }
    });
    //console.log("filteredCustomFields", filteredCustomFields);

    return filteredCustomFields;
  }

  populateTags(): void {
    this.allTags.map(tag => this.tagById[tag.tagId] = tag.tagName);

    if(this.searchType){
      this._columns = this.retrieveViewableColumn(this.searchType);
      //console.log("populateCustomFields _columns", this._columns);
      this.updateColumnsState();
    }

    this.getCustomFields();
  }

  populateCustomFields() {
    this.allCustomFields = this.filterCustomFields([...this.allCustomFields]);
    console.log("allCustomFields", this.allCustomFields);
    this.allCustomFields.map(cf => this.customFieldById[cf.id] = cf.customFieldLabel);
    //console.log("this.customFieldById", this.customFieldById);
    if(this.searchType){
      this._columns = this.retrieveViewableColumn(this.searchType);
      //console.log("populateCustomFields _columns", this._columns);
      //console.log("calling updateColumnsState");
      this.updateColumnsState();
    }
    this.loaded = true;
  }

  filterTenantBridges(tenantBridges: tenantBridgesResponse[]): tenantBridgesResponse[] {
    let filteredTenantBridges: tenantBridgesResponse[] = [];
    let uniqueTenantBridgeIds: string[] = [];
    
    tenantBridges.map(tb => {
      if(uniqueTenantBridgeIds.indexOf(tb.bridgeId) == -1) {
        uniqueTenantBridgeIds.push(tb.bridgeId);
        filteredTenantBridges.push(tb);
      }
      else {
        console.log("duplicate bridge", tb);        
      }
    });

    return filteredTenantBridges;
  }

  getTags(): void {
    this.tagsService.getTags(this.tenantId)
    .subscribe(res => {
      console.log("getTags", res);
      this.allTags = res;

      this.populateTags();
    });
  }

  getCustomFields() {
    this.customFieldsV2Service.getTenantBridges(this.tenantId)
    .toPromise()
    .then((tenantBridges: tenantBridgesResponse[]) => {
      if(tenantBridges.length == 0){
        //console.log("calling updateColumnsState");
        this.updateColumnsState();
        this.loaded = true;
        return;
      }
      //console.log("tenant bridges", tenantBridges);
      tenantBridges = this.filterTenantBridges([...tenantBridges]);
      let bridgeCount = 0;
      tenantBridges.forEach((tenantBridge: tenantBridgesResponse) => {
        this.customFieldsV2Service.getTenantBridgeCustomFields(this.tenantId, tenantBridge.bridgeId, tenantBridge.vendorId)
        .toPromise()
        .then((customFields: tenantBridgeCustomFieldsResponse[]) => {
          bridgeCount++;
          customFields.forEach((customField: tenantBridgeCustomFieldsResponse) => {
            customField.vendorName = tenantBridge.vendorName;
          });
          this.allCustomFields.push(...customFields);
          if(bridgeCount == tenantBridges.length) {
            this.populateCustomFields();
          }
        });
      });
    });
    /*
    this.tenantCustomFieldService.listCustomFields()
    .toPromise()
    .then((customFields: CustomField[]) => {
      if(!customFields.length){
        this.updateColumnsState();
        this.loaded = true;
        return;
      }
      customFields.map(cf => this.customFieldById[cf._id] = cf.label);
      if(this.searchType){
        this._columns = this.retrieveViewableColumn(this.searchType);
        this.updateColumnsState();
      }
      this.loaded = true;
    })
    .catch(e => this.error = e);
    */
  }

  /*
  getCfCellValue(columnName, customDatas){
    //console.log("in getCfCellValue");
    //console.log("columnName", columnName);
    //console.log("customDatas", customDatas);
    const customData = this.getCFData(columnName, customDatas);
    //console.log("customData", customData);
    let cellValue = '';
    if(!customData || !customData.custom_field_type){
      return cellValue;
    }

    switch (customData.custom_field_type) {
      case 'CONTACT':
        if(customData.value && customData.value.length){
          cellValue = customData.value.map(contact => contact.full_name).join(', ')
        }
        break;
      case 'PHONE':
        if(customData.value && customData.value.display){
          cellValue = customData.value.display;
        }
        break;

      case 'NUMBER':
        if(customData.value && customData.value){
          cellValue = this._decimalPipe.transform(customData.value, '1.2-2');
        }
        break;
    
      default:
        cellValue = customData.value;
        break;
    }

    return cellValue;
  }
  */

  getCfCellValue(columnName, customDatas) {
    return customDatas.find(cd => cd.id===columnName).value;
  }

  getTagCellValue(columnName, tagData) {
    //console.log("getTagCellValue columnName", columnName);
    //console.log("getTagCellValue tagData", tagData);
    return tagData.find(td => td.tag_id===columnName).tag_value;
  }

  getCFData(columnName, customDatas){
    //console.log("getCFData columnName", columnName);
    //console.log("getCFData customDatas", customDatas);
    //return customDatas.find(cd => cd.custom_field.id===columnName);
    return customDatas.find(cd => cd.id===columnName);
  }

  getTagData(columnName, tagData) {
    //console.log("getTagData columnName", columnName);
    //console.log("getTagData tagData", tagData);
    return tagData.find(td => td.tag_id===columnName);
  }

  customHeaderName(columnName){
    //console.log("customHeaderName", columnName);
    const thisCustomField = this.customFieldById[columnName];
    //console.log("thisCustomField", thisCustomField);
    if(!thisCustomField){
      return '';
    }
    return true;
  }

  tagHeaderName(columnName){
    //console.log("customHeaderName", columnName);
    const thisTag = this.tagById[columnName];
    //console.log("thisCustomField", thisCustomField);
    if(!thisTag){
      return '';
    }
    return true;
  }

  isTagTypeOption(columnName) {
    const thisTag = this.tagById[columnName];

    if(!thisTag){
      return false;
    }
    else {
      let tag = this.allTags.find(tag => tag.tagId == columnName);
      if(tag.tagType == TagTypes.OPTION) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  downloadDocument(id) {
    return this.tenantDocumentManagementService.download(id).subscribe((response) => {
      window.open(response.download_url, "_blank");
    }, (e) => {

    });
  }

  getV1FileUrl(row){
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }

  shortenFileName(name) {
    if (name && name.length > 40) {
      return name.slice(0, 40) + '...';
    }
    return name;
  }
}