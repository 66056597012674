import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationModel} from '../../../../../shared/models/Location';
import { CanDelete } from '../../../../../shared/models/CanDelete';
import { PageAlertAddAction } from '../../../../../shared/state/page-alert/actions';
import { Store } from '@ngrx/store';
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import { TenantLocationService } from '../../../../../libs/api/tenant/tenantLocation.service';
import {AddressV2Service} from '../../../../../libs/api2/address-v2/address-v2.service';
import * as appDataReducer from '../../../../../shared/state/app-data/reducers';
import {LocationV2} from '../../../../../libs/api2/types/LocationV2.interface';

@Component({
  templateUrl: './location-delete.component.html',
  styleUrls: ['./location-delete.component.scss']
})

export class LocationDeleteComponent extends AbstractPageComponent implements OnInit {

  Location: string = null;
  location: LocationV2 = null;
  error: string;
  exitRoute: string;
  canDelete: any;
  canDeleteMode: boolean = true;
  owner: string = null;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantLocationService: TenantLocationService,
              private router: Router,
              private store: Store<ASReducer.State>,
              private tenantAddressV2Service: AddressV2Service
              ) {
    super();
  }

  ngOnInit() {
    this.store.select(appDataReducer.getAppData).subscribe(
        (tenant) => {
          this.owner = tenant.tenantData.domain;
        });
    this.Location = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    this.exitRoute = `/locations/${this.Location}/edit`;
    /*this.tenantAddressV2Service.deleteLocations(this.owner, this.Location).subscribe((res) => {
      console.log('result on location delete', res);
    });*/
    this.tenantAddressV2Service.getLocationById(this.Location)
      .toPromise()
      .then((location: LocationV2) => {
        this.location = location;
        return this.tenantLocationService.canDelete(location.id).toPromise();
      })
      .then((res: CanDelete) => {
        this.canDelete = res;
        if (res.dependents.length) {
          this.canDeleteMode = false;
        }
      })
      .catch(e => this.error = e);
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete() {
    this.tenantAddressV2Service.deleteLocations( this.location.address.id, [this.Location])
    .subscribe(() => {
        this.deleteIndex(this.Location);
      },
      e => this.error = e
    );
  }

  deleteIndex(id: string) {
    this.tenantLocationService.deleteLocation(id)
      .subscribe(() => {
        this.store.dispatch(
          new PageAlertAddAction({
            message: `Location was successfully deleted.`,
            alertType: 'SUCCESS'
          })
        );

        this.router.navigate([`/addresses/${this.location.address.id}/locations`]);
      },
      e => this.error = e
      );
  }




  onUpdateLocation(location: LocationModel): void {
    // The change has been added.
    this.router.navigate([this.exitRoute], {
      queryParams: {update: new Date().getTime()},
      relativeTo: this.activatedRoute
    });
  }
}
