<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="loaded; else loading">

  <div class="sections">
    <section class="data-section">
      <mi-section-data-addresses
        title="{{'TENANT.LOCATIONS.DETAIL_TITLE' | translate }}"
        [addresses]="addressTableData">
      </mi-section-data-addresses>
    </section>

    <section class="data-section">
      <mi-section-title title="Status">
        <ng-content></ng-content>
      </mi-section-title>

      <div [ngClass]="{active: location.is_active === true, inactive: location.is_active === false }">
        {{ 'LOCATION_STATUSES.' + (location.is_active ? LOCATION_STATUS_ACTIVE : LOCATION_STATUS_INACTIVE) | translate }}
      </div>
    </section>
  </div>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

