<ng-container *ngIf="loaded; else loading">
  <div *ngIf="!serviceWithRequestor">
    <p>
      <mi-id-link id="{{service.display_id}}"></mi-id-link>
      does not have a requestor.
    </p>
    <p>
      Would you like to assign a contact as the requestor?
    </p>
    <button mi-button buttonTheme="dark-theme" 
            (click)="openEmployeeModal()">
      Select an Employee
    </button>

    <div class="button-container">
      <button mi-button
              buttonTheme="light-theme"
              [routerLink]="cancelRoute">
        Cancel
      </button>
      <button mi-button
              buttonTheme="dark-theme"
              [routerLink]="skipRoute">
        Skip
      </button>
    </div>
  </div>


  <div *ngIf="serviceWithRequestor">
    <p>
      The Re-Assessment request will be sent to the Requestor for
      <mi-id-link id="{{snapshot}}"></mi-id-link>
    </p>


    <table class="mi-sort-table fullWidth">

        <thead>
          <tr>
            <th>
              Service ID
            </th>
            <th>
              Requestor
            </th>
          </tr>
        </thead>
  
          <!-- now do the rows -->
        <tbody> 
          <tr>
            <td>
              <mi-id-link id="{{serviceWithRequestor.display_id}}"></mi-id-link>
            </td>
            <td>
              <div class="text-align">
                {{serviceWithRequestor.requestor_email}}
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>

    <div class="button-container">
      <button mi-button
              buttonTheme="light-theme"
              [routerLink]="cancelRoute">
        Cancel
      </button>
      <button mi-button
              buttonTheme="dark-theme"
              (click)="next($event)">
        Continue
      </button>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
