import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {ServiceDetailsHeaderComponent} from './header/service-details-header.component';
import {ServiceDetailsHeaderVendorComponent} from './header/vendor/service-details-header-vendor.component';
import {ServiceDetailsHeaderDataIntegrityComponent} from './header/data-integrity/service-details-header-data-integrity.component';
import {ServiceDetailsHeaderEventsComponent} from './header/events/service-details-header-events.component';
import {ServiceDetailsDataIntegrityOverlayComponent} from './data-integrity-overlay/service-details-data-integrity-overlay.component';
import {ServiceDetailsDataIntegrityDialComponent} from './data-integrity-meter/data-integrity-meter.component';
import {SubsectionVendorAndProductComponent} from './sections/sub-sections/subsection-vendor-and-product.component';
import {SubsectionOrderDetailsComponent} from './sections/sub-sections/subsection-order-details.component';
import {SubsectionAddressDetailsComponent} from './sections/sub-sections/subsection-address-details.component';
import {TenantComponentsModule} from '../../../components/tenant-components.module';
import {SubsectionEconomicDetailsComponent} from './sections/sub-sections/subsection-economic-details.component';
import {SubsectionTermsDetailsComponent} from './sections/sub-sections/subsection-terms-details.component';
import {SubsectionOwnershipDetailsComponent} from './sections/sub-sections/subsection-ownership-details.component';
import {SubsectionServiceTermsDetailsComponent} from './sections/sub-sections/subsection-service-terms-details.component';
import {SubsectionContactsDetailsComponent} from './sections/sub-sections/subsection-contacts-details.component';
import {SubsectionCustomFieldDetailsComponent} from './sections/sub-sections/subsection-custom-field-details.component';
import {SubsectionAssociatedDocumentsDetailsComponent} from './sections/sub-sections/subsection-associated-documents-details.component';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {reducer as serviceDetailsReducer} from './state/reducers';
import {ServiceDetailsEffects} from './state/effects';
import {ServiceSuperEventsComponent} from './sections/service-super-events/service-super-events.component';
import {CancellationFormComponent} from './summary/forms/cancellation-form.component';
import {CancellationRequestFormComponent} from './summary/forms/cancellation-request-form.component';
import {RemoveCancellationFormComponent} from './summary/forms/remove-cancellation-form.component';
import {ConfirmCancellationFormComponent} from './summary/forms/confirm-cancellation-form.component';
import {RetermFormComponent} from './summary/forms/reterm-form.component';
import {ServiceSummaryComponent} from './summary/service-summary.component';
import {SubsectionEquipmentsDetailsComponent} from './sections/sub-sections/subsection-equipments-details.component';
import {ServiceDetailsHeaderSavingsComponent} from './header/savings/service-details-header-savings.component';
import {ServiceActionsComponent} from './actions/service-actions.component';
import {ServiceDetailsMetricsComponent} from './metrics/service-details-metrics.component';
import {ServiceDetailsMetricsActivateComponent} from './metrics/activate/service-details-metrics-activate.component';
import {ServiceDetailsMetricsArchivedComponent} from './metrics/archived/service-details-metrics-archived.component';
import {ServiceDetailsMetricsAutoRenewComponent} from './metrics/auto-renew/service-details-metrics-auto-renew.component';
import {ServiceDetailsMetricsCancellationComponent} from './metrics/cancellation/service-details-metrics-cancellation.component';
import {ServiceDetailsMetricsRetermComponent} from './metrics/reterm/service-details-metrics-reterm.component';
import { SubsectionServiceGroupDetailsComponent } from './sections/sub-sections/subsection-service-group-details.component';
import {DetailAlertComponent} from '../../../components/detail-alert/detail-alert.component';
import {ServiceDetailsHeaderEconomicsComponent} from './header/economics/service-details-header-economics.component';
import { GroupFormComponent } from './summary/forms/group-form.component';
import { SubsectionParentsDetailsComponent } from './sections/sub-sections/subsection-parent-details.component';
import { ServiceDetailsHeaderGroupEconomicsComponent } from './header/parent-group-economics/service-details-header-group-economics.component';
import { ServiceDetailsHeaderGroupEventsComponent } from './header/parent-group-events/service-details-header-group-events.component';
import { SubsectionServiceAuditContainerComponent } from './sections/sub-sections/subsection-service-audit/subsection-service-audit-container/subsection-service-audit-container.component';
import { SubsectionServiceAuditTableComponent } from './sections/sub-sections/subsection-service-audit/subsection-service-audit-table/subsection-service-audit-table.component';
import { SubsectionServiceAuditGraphComponent } from './sections/sub-sections/subsection-service-audit/subsection-service-audit-graph/subsection-service-audit-graph.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from '@independer/ng-modal';
import { SubsectionTagsComponent } from './sections/sub-sections/subsection-tags.component';


@NgModule({
  declarations: [
    ServiceDetailsHeaderComponent,
    ServiceDetailsHeaderVendorComponent,
    ServiceDetailsHeaderDataIntegrityComponent,
    ServiceDetailsHeaderEconomicsComponent,
    ServiceDetailsHeaderGroupEventsComponent,
    ServiceDetailsHeaderEventsComponent,
    ServiceDetailsHeaderGroupEconomicsComponent,
    ServiceDetailsMetricsComponent,
    ServiceDetailsDataIntegrityOverlayComponent,
    ServiceDetailsDataIntegrityDialComponent,
    ServiceDetailsHeaderSavingsComponent,
    SubsectionVendorAndProductComponent,
    SubsectionOrderDetailsComponent,
    SubsectionAddressDetailsComponent,
    SubsectionEconomicDetailsComponent,
    SubsectionTermsDetailsComponent,
    SubsectionOwnershipDetailsComponent,
    SubsectionServiceTermsDetailsComponent,
    SubsectionParentsDetailsComponent,
    SubsectionContactsDetailsComponent,
    SubsectionCustomFieldDetailsComponent,
    SubsectionAssociatedDocumentsDetailsComponent,
    ServiceSuperEventsComponent,
    ServiceDetailsMetricsAutoRenewComponent,
    ServiceDetailsMetricsActivateComponent,
    ServiceDetailsMetricsArchivedComponent,
    CancellationFormComponent,
    CancellationRequestFormComponent,
    ServiceDetailsMetricsCancellationComponent,
    ServiceDetailsMetricsRetermComponent,
    RemoveCancellationFormComponent,
    ConfirmCancellationFormComponent,
    RetermFormComponent,
    ServiceSummaryComponent,
    ServiceActionsComponent,
    SubsectionEquipmentsDetailsComponent,
    SubsectionServiceGroupDetailsComponent,
    GroupFormComponent,
    SubsectionServiceAuditContainerComponent,
    SubsectionServiceAuditGraphComponent,
    SubsectionServiceAuditTableComponent,
    SubsectionTagsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    ModalModule,
    TenantComponentsModule,
    StoreModule.forFeature('serviceDetails', serviceDetailsReducer),
    EffectsModule.forFeature([ServiceDetailsEffects]),
    NgSelectModule
  ],
  providers: [],
  exports: [
    ServiceDetailsHeaderComponent,
    ServiceDetailsHeaderVendorComponent,
    ServiceDetailsHeaderDataIntegrityComponent,
    ServiceDetailsHeaderEconomicsComponent,
    ServiceDetailsHeaderGroupEconomicsComponent,
    ServiceDetailsHeaderGroupEventsComponent,
    ServiceDetailsHeaderEventsComponent,
    ServiceDetailsMetricsComponent,
    ServiceDetailsDataIntegrityOverlayComponent,
    ServiceDetailsDataIntegrityDialComponent,
    ServiceDetailsHeaderSavingsComponent,
    SubsectionVendorAndProductComponent,
    SubsectionOrderDetailsComponent,
    SubsectionAddressDetailsComponent,
    SubsectionEconomicDetailsComponent,
    SubsectionTermsDetailsComponent,
    SubsectionOwnershipDetailsComponent,
    SubsectionServiceTermsDetailsComponent,
    SubsectionParentsDetailsComponent,
    SubsectionContactsDetailsComponent,
    SubsectionCustomFieldDetailsComponent,
    SubsectionAssociatedDocumentsDetailsComponent,
    ServiceSuperEventsComponent,
    ServiceDetailsMetricsAutoRenewComponent,
    ServiceDetailsMetricsActivateComponent,
    ServiceDetailsMetricsArchivedComponent,
    CancellationFormComponent,
    CancellationRequestFormComponent,
    ServiceDetailsMetricsCancellationComponent,
    ServiceDetailsMetricsRetermComponent,
    RemoveCancellationFormComponent,
    ConfirmCancellationFormComponent,
    RetermFormComponent,
    ServiceSummaryComponent,
    ServiceActionsComponent,
    SubsectionEquipmentsDetailsComponent,
    SubsectionServiceGroupDetailsComponent,
    GroupFormComponent,
    SubsectionServiceAuditContainerComponent,
    SubsectionServiceAuditGraphComponent,
    SubsectionServiceAuditTableComponent,
    SubsectionTagsComponent
  ]
})

export class ServiceDetailsModule {

}
