import {Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {SectionDataTableColumn} from '../../../shared/ng-models/SectionDataColumn.interface';
import {SelectServiceGroupModalComponent} from '../select-service-group-modal/select-service-group-modal.component';
import {ModalService, ModalCloseReason} from '@independer/ng-modal';
import {takeUntil} from 'rxjs/operators';
import {MiServiceGroup} from '../../../shared/models/MiServiceGroup';
import {SortableTemplatesEnum} from '../../../shared/ng-models/SortableTableColumn.interface';


@Component({
  selector: 'mi-select-service-group',
  templateUrl: './select-service-group.component.html',
  styleUrls: ['./select-service-group.component.scss']
})

export class SelectServiceGroup extends AbstractPageComponent implements OnInit {

  @Input() selectedServiceGroups: Array<MiServiceGroup> = [];
  @Input() isDetailPage: boolean = false;

  @Output() serviceGroupSelected: EventEmitter<MiServiceGroup> = new EventEmitter<MiServiceGroup>();
  @Output() serviceGroupRemoved: EventEmitter<MiServiceGroup> = new EventEmitter<MiServiceGroup>();

  selectedServiceGroupsColumns: SectionDataTableColumn[] = [];
  @ViewChild('removeTmpl', {static: true}) removeTmpl: TemplateRef<any>;
  @ViewChild('alertStatusTmpl', {static: true}) alertStatusTmpl: TemplateRef<any>;

  constructor(private modalService: ModalService) {
    super();
  }


  ngOnInit() {
    this.selectedServiceGroups = this.selectedServiceGroups || [];
    this.selectedServiceGroupsColumns = [
      {
        name: 'MISO ID',
        prop: 'display_id',
        cellTemplate: SortableTemplatesEnum.idLink,
        sortable: false
      },
      {
        name: 'Group Name',
        prop: 'name',
        sortable: false
      },
      {
        name: 'Status',
        prop: 'status',
        cellTemplate: this.alertStatusTmpl,
        sortable: false
      },
      {
        name: 'Services',
        prop: 'service_count',
        sortable: false
      },
      {
        name: 'Spend (cMRC)',
        prop: 'mrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      },
      {
        name: '',
        prop: '',
        cellTemplate: this.removeTmpl,
        sortable: false
      }

    ];
  }

  onServiceGroupSelected(data) {
    this.selectedServiceGroups = this.selectedServiceGroups.filter(eq => eq.display_id != data.display_id);
    this.selectedServiceGroups.push(data);
    this.selectedServiceGroups
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

    this.serviceGroupSelected.emit(data);
  }

  onServiceGroupRemoved(data) {
    this.selectedServiceGroups = this.selectedServiceGroups.filter(eq => eq.display_id != data.display_id);
    this.serviceGroupRemoved.emit(data);
  }

  openServiceGroupModal() {
    const modalRef = this.modalService.open(SelectServiceGroupModalComponent, m => {
    });
    modalRef.closed
      .pipe(takeUntil(this.destroy$))
      .subscribe(args => {
        if (args.result && args.result.length && args.result[0]) {
          this.onServiceGroupSelected(args.result[0]);
        }
      });
  }
}
