
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';

import {Product} from '../../../models/Product';
import {MiyagiCategoryService} from '../../../../libs/api/miyagi/miyagiCategories';
import {ProductCategory} from '../../../models/ProductCategory';
import {Vendor} from '../../../models/Vendor';
import {event_types} from '../../../models/TermsAndConditions';
import {ProductCategoryFieldProperty} from '../../../models/ProductCategoryFieldProperty';
import {ProductServices, ProductServicesModes} from '../../../models/ProductServices.interface';
import {TenantProductsService} from '../../../../libs/api/tenant/tenantProducts';
import {TenantVendors} from '../../../../libs/api/tenant/tenantVendors';
import {MiyagiProductService} from '../../../../libs/api/miyagi/miyagiProducts';
import {VendorService} from '../../../../libs/api/miyagi/miyagiVendors';
import {MiyagiSearchService} from '../../../../libs/api/miyagiSearch/miyagiSearch.service';

import { ToastrService } from 'ngx-toastr';


export enum CreateEditProductMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
};

@Component({
  selector: 'mi-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})

export class ProductFormComponent extends AbstractPageComponent implements OnInit {

  @Input() lockVendorTheme: string;
  @Input() productInsert: any;
  @Input() lockVendor: boolean;
  @Input() productServicesMode: ProductServicesModes = ProductServicesModes.TENANT;
  @Output() productCompleted: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  mode: CreateEditProductMode;
  event_types = event_types;
  editProductForm: FormGroup;
  // editAgreementForm: FormGroup;
  editProductFieldsForm: FormGroup;
  categories: Array<ProductCategory> = [];
  loading: boolean = false;
  loadError: string = '';
  submitError: string = '';
  error = null;
  vendors: Array<Vendor> = [];
  // addAgreement: boolean = false;
  allProductCategoryFieldsProperties: ProductCategoryFieldProperty[] = [];
  currentProductCategoryFieldProperty: ProductCategoryFieldProperty[] = [];
  miyagiProductServicesMode: ProductServicesModes = ProductServicesModes.MIYAGI;
  services: ProductServices;

  constructor(
    private toastr: ToastrService,
    private categoryService: MiyagiCategoryService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private tenantProductsService: TenantProductsService,
    private tenantVendorsService: TenantVendors,
    private miyagiProductsService: MiyagiProductService,
    private miyagiVendorsService: VendorService,
    private miyagiSearchProductsService: MiyagiSearchService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.productServicesMode === ProductServicesModes.MIYAGI) {
      this.services = {
        productsService: this.miyagiProductsService,
        vendorsService: this.miyagiVendorsService,
        searchProductsService: this.miyagiSearchProductsService
      };
    } else {
      this.services = {
        productsService: this.tenantProductsService,
        vendorsService: this.tenantVendorsService,
        searchProductsService: this.tenantProductsService
      };
    }

    this.editProductForm = this.formBuilder.group({
      product_name: [this.productInsert.product_name, Validators.compose([Validators.required, Validators.maxLength(250)])],
      vendor_id: [null],
      category_id: [null, Validators.required]
    });

    // this.editAgreementForm = this.formBuilder.group({
    //   event_type: ['EVENT_TYPE_AUTO_RENEW', Validators.required],
    //   auto_renew_length: [null, Validators.compose([Validators.required, Validators.min(0)])],
    //   days_to_notify: [null, Validators.compose([Validators.required, Validators.min(0)])],
    //   cancellation_instructions: [null],
    //   label: [null, Validators.required],
    //   agreement_link: [null, Validators.compose([urlValidator])]
    // });

    this.editProductFieldsForm = this.formBuilder.group({});

    this.activatedRoute.params.subscribe((params: Params) => {

      this.loadCategoriesTree();

      if (!this.lockVendor) {
        this.services.vendorsService.listVendors().then(vendors => {
          this.vendors = vendors;
        });
      }

      if (!this.productInsert || !this.productInsert.id) {
        this.startAsCreateMode(this.productInsert);
      } else {
        this.startAsEditMode(this.productInsert);
      }
    });

  }

  fieldName(product_field_property: any): string {
    return 'product_field_' + product_field_property.id;
  }
  fieldUnitName(product_field_property: any): string {
    return 'product_unit_field_' + product_field_property.id;
  }

  getproductCategoryField(index: number = 0): any {
    return this.allProductCategoryFieldsProperties[index];
  }

  populateAllProductCategoryFieldsProperties(id: string) {
    if (id) {
      this.categoryService.getCategory(id).then(category => {
        let formData: any = {};
        let allProductCategoryFieldsProperties = [];
        ///FIXME update this when products migrate to SQL

        allProductCategoryFieldsProperties = category.categoryProperties.map(item => {
          let fieldProperty: ProductCategoryFieldProperty = this.currentProductCategoryFieldProperty.find(
            (itemProductField: ProductCategoryFieldProperty): boolean => {
              return itemProductField.product_field_property._id === item.id;
            }
          );
          if (!fieldProperty) {
            fieldProperty = {
              value: '',
              unit: '',
              product_field_property: {
                ...item.property_type,
                _id: item.id,
                product_field: {
                  ...item.property_type,
                  label: item.category_name,
                  _id: item.property_type.id,
                  id: item.property_type.id,
                  product_field_type: item.property_type.property_type,

                },
              }
            };
          };
          return fieldProperty;
        });
        allProductCategoryFieldsProperties.forEach((productField, index) => {
          formData[this.fieldName(productField.product_field_property)] = productField.value;
          formData[this.fieldUnitName(productField.product_field_property)] = productField.unit;
        });
        this.editProductFieldsForm = this.formBuilder.group(formData);
        this.allProductCategoryFieldsProperties = allProductCategoryFieldsProperties;
      });
    } else {
      this.allProductCategoryFieldsProperties = [];
    }
  }

  showProductCategoryFields($event) {
    if ($event) {
      this.populateAllProductCategoryFieldsProperties($event.id);
    } else {
      this.allProductCategoryFieldsProperties = [];
    }
  }

  loadCategoriesTree() {
    this.categoryService.getCategoryTree()
      .then(
        categories => {
          this.categories = this.recursiveFlatten(categories, 0);
        },
        e => this.error = e
      );
  }

  recursiveFlatten(categories: ProductCategory[], depth: number): ProductCategory[] {
    const cats: ProductCategory[] = [];
    if (categories.length) {
      let categoriesSorted = categories.sort((a, b) => {
        return a.category_name.toLowerCase() < b.category_name.toLowerCase() ? -1 :
          (a.category_name.toLowerCase() > b.category_name.toLowerCase() ? 1 : 0);
      });
      //there are children here.
      categoriesSorted.forEach(cat => {
        cat.category_name = cat.category_name;
        cat.level = depth;
        cats.push(cat);
        if (cat.children) {
          cats.push(...this.recursiveFlatten(cat.children, depth + 10));
        }
      });
    }
    return cats;
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  startAsCreateMode(productInsert: Product): void {
    this.mode = CreateEditProductMode.CREATE;
    if (productInsert) this.setFormValues(productInsert);
  }

  setFormValues(productInsert: Product) {
    productInsert.tenant_vendor_id = productInsert.product_vendor.tenant_vendor_id;
    productInsert.vendor_id = productInsert.product_vendor.vendor.id;
    //debugger;
    if (productInsert.product_category != null) {
      this.editProductForm.setValue({
        product_name: productInsert.product_name || '',
        vendor_id: productInsert.tenant_vendor_id || '',
        category_id: productInsert.product_category.id || ''
      });
    } else {
      this.editProductForm.setValue({
        product_name: productInsert.product_name || '',
        vendor_id: productInsert.vendor_id || '',
        category_id: productInsert.category_id || ''
      });
    }
    this.editProductForm.setValue({
      product_name: productInsert.product_name || '',
      vendor_id: productInsert.tenant_vendor_id || productInsert.vendor_id || '',
      category_id: productInsert.product_category?.id || productInsert.category_id || ''
    });

    if (productInsert.product_category) {
      this.populateAllProductCategoryFieldsProperties(productInsert.product_category.id || productInsert.product_category.id);
    }
    this.currentProductCategoryFieldProperty = productInsert.product_fields || [];

    // if (productInsert.miyagi_terms && this.productServicesMode === this.miyagiProductServicesMode) {
    //   this.addAgreement = true;

    //   const {
    //     event_type,
    //     auto_renew_length,
    //     days_to_notify,
    //     cancellation_instructions,
    //     label,
    //     agreement_link
    //   } = productInsert.miyagi_terms;

    //   this.editAgreementForm.setValue({
    //     event_type,
    //     auto_renew_length,
    //     days_to_notify,
    //     cancellation_instructions,
    //     label,
    //     agreement_link
    //   });
    // }

  }

  startAsEditMode(productInsert: Product): void {
    this.mode = CreateEditProductMode.EDIT;
    this.setFormValues(productInsert);
  }

  productToSQLProduct(product: any): Product {
    console.log("here product", product)
    var SQLProduct = {
      product_category_id: product.category_id,
      product_name: product.product_name,
      tenant_vendor_id: product.vendor_id ? product.vendor_id  : this.productInsert.tenant_vendor_id,
      product_properties: []
    } as Product;
    product.product_fields.forEach((property) => {
      if (property.product_field_property.property_type == "DROPDOWN") {
        SQLProduct.product_properties.push(
          {
            product_category_id: property.product_field_property._id,
            value: property.value
          } as ProductCategoryFieldProperty
        )
      } else {
        if (property.product_field_property.options.length > 0) {
          SQLProduct.product_properties.push(
            {
              product_category_id: property.product_field_property._id,
              unit: property.unit,
              value: property.value
            } as ProductCategoryFieldProperty
          )
        } else {
          SQLProduct.product_properties.push(
            {
              product_category_id: property.product_field_property._id,
              value: property.value
            } as ProductCategoryFieldProperty
          )
        }
      }
    });

    return SQLProduct;
  }

  submit = (form: any) => {

    let newProduct = form as Product;
    // if (this.productServicesMode === this.miyagiProductServicesMode) {
    //   let newTerms = this.addAgreement ? this.editAgreementForm.value as TermsAndConditions : null;
    //   if (newTerms && newTerms.label) {
    //     newProduct.miyagi_terms = {
    //       ...newTerms,
    //       owner: 'miyagi'
    //     };
    //   }
    // }

    const newProductCategoryFieldsProps: ProductCategoryFieldProperty[] = [...this.allProductCategoryFieldsProperties];

    let data = this.editProductFieldsForm.value;

    Object.keys(data).forEach(key => {
      if (key.startsWith("product_unit_field_")) {
        const index = key.substr(key.lastIndexOf('_') + 1);
        let prop = newProductCategoryFieldsProps.find((x) => {
          return x.product_field_property.id == index
        });
        prop['unit'] = this.editProductFieldsForm.get(key).value;
      } else {
        const index = key.substr(key.lastIndexOf('_') + 1);
        let prop = newProductCategoryFieldsProps.find((x) => {
          return x.product_field_property.id == index
        });
        prop['value'] = this.editProductFieldsForm.get(key).value;
      }

    });

    newProduct.product_fields = newProductCategoryFieldsProps;
    if (this.mode === CreateEditProductMode.EDIT) {
      this.services.productsService.editProduct({
        ...this.productToSQLProduct(newProduct),
        id: this.productInsert.id
      }).then(product => {
        this.productCompleted.emit(product);
        this.toastr.success(`${this.productInsert.display_id} Product update successfully`);
      }).catch(e => this.error = e);
    } else {
      this.services.productsService.createProduct(this.productToSQLProduct(newProduct)).then(product => {
        this.productCompleted.emit(product);
        this.toastr.success('Product created successfully');
      }).catch(e => this.error = e);
    }


  };
}
