import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {Actions, Effect} from '@ngrx/effects';
import * as advancedSearchActions from './actions';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {TenantSearchService} from '../../../libs/api/tenantSearch/tenantSearch.service';
import {SearchQueryParams} from '../../ng-models/search/SearchQueryParams.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {ADVANCED_SEARCH_TYPES} from './reducers';
import {MiServiceSearchResults} from '../../models/SearchResults';
import {MiyagiSearchService} from '../../../libs/api/miyagiSearch/miyagiSearch.service';
import {fixAngularArrayQuery} from '../../utils/fixAngularArrayQuery';
import {removeNonUrlSearchParams} from '../../utils/unsafeUrlProperties';
import {enforceQueryArrayProperties} from '../../utils/enforceQueryArrayProperties';

const log = createLogger(LOG_LEVELS.REDUX_ADVANCED_SEARCH);

@Injectable()
export class AdvancedSearchEffects {

  /**
   * depeplinkChanged listens for AS_DEEP_LINK_CHANGED, this is when the URL changes.
   */
  @Effect()
  deepLinkChanged: Observable<advancedSearchActions.Actions> = this.actions$
  // only listen to this type:
    .ofType(advancedSearchActions.ActionTypes.AS_DEEP_LINK_CHANGED)
    // switchMap, allows getCorrectEndpoint() to return an HTTP observable,
    // but will wait until the flattened results before moving onto the next .map call
    .switchMap((action: advancedSearchActions.Actions) => this.getCorrectEndpoint(action.payload))
    // map modifies a flat response, here we are returning the AsDataLoadSuccess action
    .map((response) => {
      // log('response is', response);
      return new advancedSearchActions.AsDataLoadSuccess(response);
    });


  /**
   * loadMore listens for AS_LOAD_MORE, this is when the user clicks the Load More button.
   */
  @Effect()
  loadMore: Observable<advancedSearchActions.Actions> = this.actions$
  // only listen to this type:
    .ofType(advancedSearchActions.ActionTypes.AS_LOAD_MORE)
    // mergeMap takes the observable from mergeWithState and waits for a flattened object for the next .map
    .mergeMap((action) => this.mergeWithState(action))
    // we now have both the full AdvancedSearchState and the original action
    .map(({asState, action}) => {

      // combined now has a shape with params(from current state) and action combined.
      const newParams = {...asState.results.meta.params};
      const qtyToAdd = (<any>action).payload.qty;

      //newParams.from = Number(newParams.from) + Number(newParams.size);
      //newParams.size = Number(qtyToAdd);
      newParams.size += Number(newParams.size);

      return newParams;

    })
    // this fixes an angular 2 bug in arrays
    .map(query => fixAngularArrayQuery(query))
    // here we
    .switchMap((newParams) => {
      // switchMap, allows getCorrectEndpoint() to return an HTTP observable,
      // but will wait until the flattened results before moving onto the next .map call
      return this.getCorrectEndpoint(newParams);
    })
    .map((response) => {
      // map modifies a flat response, here we are returning the AsLoadMoreSuccess action
      return new advancedSearchActions.AsLoadMoreSuccess(response);
    });

  /**
   * filterAdd - Add or remove filters
   */
  @Effect({dispatch: false})
  filterAdd: Observable<advancedSearchActions.Actions> = this.actions$
    .ofType(advancedSearchActions.ActionTypes.AS_FILTER_UPDATE)
    .mergeMap((action) => this.mergeWithState(action))
    .map(({asState, action}) => {

      const filtersToAdd: any = (<any>action).payload;

      // combined now has a shape with params(from current state) and action combined.
      const newParams = asState.results ?
        {...asState.results.meta.params}
        :
        {};

      // for each new filter key, we apply the value
      for (const [filterKey, filterValue] of Object.entries(filtersToAdd)) {
        newParams[filterKey] = filterValue;
      }

      return newParams;

    })
    // this fixes an angular 2 bug in arrays
    .map(query => fixAngularArrayQuery(query))
    .map((newParams) => {
      this.navigate(newParams);
      return null;
    });

  /**
   * replaceAllFilters - Replace all filters
   */
  @Effect({dispatch: false})
  filterReplace: Observable<advancedSearchActions.Actions> = this.actions$
    .ofType(advancedSearchActions.ActionTypes.AS_FILTER_REPLACE)
    .map((action) => {
      const newParams = {};
      // combined now has a shape with params(from current state) and action combined.
      const filtersToAdd = (<any>action).payload;

      // for each new filter key, we apply the value
      for (const [filterKey, filterValue] of Object.entries(filtersToAdd)) {
        newParams[filterKey] = filterValue;
      }
      return newParams;
    })
    // this fixes an angular 2 bug in arrays
    .map(query => fixAngularArrayQuery(query))
    .map((newParams) => {
      this.navigate(newParams);
      return null;
    });

  /**
   * Returns an observable which when resolved will return an new combine object
   * @param {any} action The original advancedSearchActions.Actions
   * @returns {Observable<any>} Resolves to an object with shape {asState, action}
   */
  mergeWithState(action: any): Observable<any> {
    return this.store.first().map(
      (state) => {
        return {asState: state.advancedSearch, action};
      }
    );
  }

  /**
   * Decides which search endpoint to hit
   * @param newParams The new parameters to send to that endpoint
   * @returns {Observable<MiServiceSearchResults>}
   */
  getCorrectEndpoint(newParams): Observable<MiServiceSearchResults> {
    return this.store.first()
      .switchMap((state) => {
          let correctEndpoint: Observable<MiServiceSearchResults>;
          switch (state.advancedSearch.searchType) {
            case ADVANCED_SEARCH_TYPES.EVENT:
              // this is the search for events endpoint
              correctEndpoint = this.tenantSearchService.searchTenantEvents(newParams as SearchQueryParams);
              break;
            case ADVANCED_SEARCH_TYPES.MIYAGI_ANALYSIS:
              // this is the search for events endpoint
              correctEndpoint = this.miyagiSearchService.searchMiService(newParams as SearchQueryParams);
              break;
            case ADVANCED_SEARCH_TYPES.EQUIPMENT:
              // this is the search for equipment endpoint
              correctEndpoint = this.tenantSearchService.searchTenantEquipment(newParams as SearchQueryParams);
              break;
            case ADVANCED_SEARCH_TYPES.VENDOR:
              // this is the search for vendor endpoint
              correctEndpoint = this.tenantSearchService.searchTanentVendors(newParams as SearchQueryParams);
              break;
            case ADVANCED_SEARCH_TYPES.CONTACT:
              // this is the search for vendor endpoint
              correctEndpoint = this.tenantSearchService.searchTanentContact(newParams as SearchQueryParams);
              break;
            case ADVANCED_SEARCH_TYPES.TENANT_USER:
              // this is the search for vendor endpoint
              // Rohit correctEndpoint = this.tenantSearchService.searchTanentUser(newParams as SearchQueryParams);
              break;
            case ADVANCED_SEARCH_TYPES.DOCUMENT:
              // this is the search for vendor endpoint
              correctEndpoint = this.tenantSearchService.searchDocument(newParams as SearchQueryParams);
              break;              
            case ADVANCED_SEARCH_TYPES.ACCOUNT_ID:
              // this is the search for vendor endpoint
              // correctEndpoint = this.tenantSearchService.searchAccountId(newParams as SearchQueryParams);
              break;      
            case ADVANCED_SEARCH_TYPES.PRODUCT:
              // this is the search for vendor endpoint
              correctEndpoint = this.tenantSearchService.searchProduct(newParams as SearchQueryParams);
              break;             
            // case ADVANCED_SEARCH_TYPES.ADDRESS:
            //   // this is the search for vendor endpoint
            // Rohit   correctEndpoint = this.tenantSearchService.searchTanentAddress(newParams as SearchQueryParams);
            //   break;
            case ADVANCED_SEARCH_TYPES.MI_SERVICE_GROUP:
              // this is the search for vendor endpoint
              correctEndpoint = this.tenantSearchService.searchTenantMiServiceGroups(newParams as SearchQueryParams);
              break;
            default:
              // this is the default
              // MI_SERVICE is the default:
            // rohit // correctEndpoint = this.tenantSearchService.searchTenantMiServices(newParams as SearchQueryParams);
              break;
          }
          return correctEndpoint;
        }
      );
  }

  /**
   * Will update the current page URL query
   * @param newQuery {object} The object serialize for the query.
   */
  navigate(newQuery: object): void {

    const safeQuery = removeNonUrlSearchParams(newQuery);
    const currentProps = enforceQueryArrayProperties(this.route.snapshot.queryParams);

    let hasNonQChanges: boolean = false;

    Object.keys(safeQuery).forEach((key) => {

      const oldParam = (currentProps[key] || '').toString();
      const newParam = (safeQuery[key] || '').toString();

      if (key !== 'q') {
        if (newParam !== oldParam) {
          hasNonQChanges = true;
        }
      } else {
        if (oldParam.length === 0 || newParam.length === 0) {
          // Transitioning either from or to a state of no text query.
          hasNonQChanges = true;
          return;
        }
      }
    });

    hasNonQChanges
      ?
      this.router.navigate([], {relativeTo: this.route, queryParams: safeQuery})
      :
      this.router.navigate([], {relativeTo: this.route, queryParams: safeQuery, replaceUrl: true});

  }

  constructor(
    private actions$: Actions,
    private tenantSearchService: TenantSearchService,
    private miyagiSearchService: MiyagiSearchService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>
  ) {
  }

}
