<ng-container *ngIf="!showAddEquipment">
  <div class="equipment-search-wrapper">
    <div class="equipment-search">
        <mi-search placeholder="Search for Equipment" qtyMessage="{{ 'SEARCH_QUANTITIES.EQUIPMENT' | translate:{count:total} | translateSelector:total }}"
          (searchTermChanged)="onSearchTermChanged($event)" (clearSearch)="onClearSearch()"></mi-search>
        <mi-server-error [error]="equipmentFindError"></mi-server-error>
    </div>
    <div class="equipment-search-vendor">
      <ng-select class="mi-select" [items]="vendors" bindLabel="key" bindValue="key" [multiple]="false" [closeOnSelect]="true"
        (change)="filterEquipmentByVendor($event)" placeholder="Filter Vendor">
      </ng-select>
    </div>
  </div>
  <div class="mi-grid-std search-results">
    <div class="scrollableSubTable" *ngIf="(hits && hits.length); else noContent">
      <mi-sortable-table [rows]="hits" [columns]="generalInformationTableColumns" variant="SMALL"
      externalClasses="fullWidth">
      </mi-sortable-table>
    </div>

    <mi-loading-animation class="first-load" *ngIf="!resultsLoadedFirstTime">
    </mi-loading-animation>

    <div class="load-more" *ngIf="(hits.length < total)">
      <a href="#" *ngIf="(page*pageSize) <= total" (click)="loadMoreClicked($event)">Load More</a>
    </div>

    <ng-template #noContent>
      <div *ngIf="resultsLoaded">No equipment found</div>
    </ng-template>
  </div>
</ng-container>

<div class="add-equipment">
  <div class="create-container"
       *ngIf="!showAddEquipment">
    <a
      (click)="onClickCreateNew()"
      class="link--after">
      Create a new equipment
    </a>
  </div>
  <ng-container *ngIf="showAddEquipment">
    <h3>Add Equipment</h3>
    <br />
    <div>
      <mi-equipment-form
      [showrecentlyAdded]="false"
      (equipmentCreated)="onAddComplete($event)"
      (canceled)="onAddEquipmentCancel()">
      </mi-equipment-form>
    </div>
  </ng-container>
</div>

<ng-template #equipmentTpl let-row="row" let-value="value">
  <label>
    <input type="checkbox" value="{{row.id}}" (click)="onEquipmentSelect($event)">
    {{row.display_id}}
  </label>
</ng-template>
