import {Component} from '@angular/core';
import {ID_KEY, SEARCH_TYPE_LOCATIONS} from "../../../../components/detail-search/detail-search.component";

@Component({
  templateUrl: './location-search-events.component.html'
})
export class LocationSearchEventsComponent {
  readonly SEARCH_TYPE_LOCATIONS: string = SEARCH_TYPE_LOCATIONS;
  queryParams: any = {
    "location_id[]": ID_KEY
  }
}
