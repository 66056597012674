<ng-container *ngIf="step===SEARCH_STATE">
  <div class="card px-3 pt-3 pb-1 mb-2">
    <div class="w-45">
  <mi-search placeholder="Find User or Employee"
             value="{{searchString}}"
             ngClass="contact-search"
             (searchTermChanged)="onUpdateSearchString($event)">
  </mi-search>
    </div>
  </div>
  <div class="card">
  <ng-container *ngIf="loaded; else loading">

    <ng-container *ngIf="!searching; else searchingContacts">
      <table class="results contacts" width="100%" *ngIf="contacts.length > 0; else noContacts">
        <tr>
          <th>
            {{'CONTACTS.COMPONENT.NAME' | translate}}
          </th>
          <th>
            {{'CONTACTS.COMPONENT.EMAIL' | translate}}
          </th>
          <th>
            {{'CONTACTS.COMPONENT.COST_CENTER' | translate}}
          </th>
        </tr>
        <ng-container *ngFor="let contact of contacts">
          <tr (click)="onSelectContact(contact)" [ngClass]="{inactive:contact.status === INACTIVE}">
            <td>
              <div class="contact">
                {{contact.first_name + ' ' +contact.last_name}}
              </div>
            </td>
            <td>
              <div class="contact" title="{{contact.full_name}} <{{contact.email}}>">
                <<span class="limit">{{contact.emailLabel}}</span>{{contact.emailDomain}}>
              </div>
            </td>
            <td>
              <div class="contact--company">
                {{contact.cost_center}}
              </div>
            </td>
          </tr>
        </ng-container>
      </table>

      <div class="load-more"
          *ngIf="!loadingMore && (contacts.length < totalContacts)">
        <a href="#" (click)="onLoadMoreClicked($event)">Load More</a>
      </div>

      <ng-template #noContacts>
        <div *ngIf="!searchString || searchString.length <= 2">
          {{'CONTACTS.COMPONENT.NO_CONTACTS' | translate}}
        </div>
        <div *ngIf="searchString && searchString.length > 2">
          {{ 'CONTACTS.COMPONENT.NO_CONTACTS_MATCHING' | translate:{search_string: searchString} }}
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="createMode===true">
      <div class="centered-cta-message">
        <span class="create-for-title">Can't find your employee?</span><br>
        <button
          mi-button
          (click)="swapTo(CREATE_STATE)">
          Create New employee
        </button>
      </div>
    </ng-container>
    <ng-template #searchingContacts>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  </ng-container>
  </div>
  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</ng-container>

<ng-container *ngIf="step===CREATE_STATE">
  <h3>Create a new Contact</h3>

  <mi-user-management-form
    (canceled)="swapTo(SEARCH_STATE)"
    (userUpdated)="onContactCreated($event)">
  </mi-user-management-form>

</ng-container>
