import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../../../shared/ng-models/SectionDataTable.model";
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import {ADVANCED_SEARCH_TYPES} from '../../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../../shared/state/advanced-search/actions';
import {combineLatest, Observable} from "rxjs";
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';

@Component({
  templateUrl: './service-term-search-mi-services.component.html'
})

export class ServiceTermSearchMiServicesComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  error: string = '';
  display_id: string;
  pageLoaded$: Observable<boolean>;
  searchLoaded$: Observable<boolean>;

  constructor(              
    private serviceTermService: ServiceTermService,
    private activatedRoute: ActivatedRoute,
    private store: Store<ASReducer.State>) {
    super();

  }

  ngOnInit() {
    //debugger;
    this.store.dispatch(new ASActions.AsReset({searchType: ADVANCED_SEARCH_TYPES.MI_SERVICE, pageLockFilters: ['service_term']}));

    // listen for page loaded
    this.pageLoaded$ = this.store.select(ASReducer.ASPageLoaded);

    // Listen for search loaded
    this.searchLoaded$ = this.store.select(ASReducer.ASSearchLoaded);

    this.activatedRoute
      .parent
      .params
      .switchMap((params: Params) => {
        // get the address data
        return combineLatest(
          this.serviceTermService.getServiceTermById(params.display_id),
          this.activatedRoute.queryParams
        );
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe( ([serviceTerm, queryParams]:any[]) => {
        this.display_id = serviceTerm.display_id;
        const newParams = {
          ...queryParams,
          "service_term[]": serviceTerm.id
        };

        this.store.dispatch(new ASActions.AsDeeplinkChanged(newParams));

      });
  }
}
