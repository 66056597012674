import { MiService } from "../models/MiService";
import * as linkFormatter from '../../common/utils/linkFormatter';
import {DateTime} from 'luxon';

interface emailData{
  vendor_name: string;
  user_name: string;
  user_email: string;
  recipient_first_name: string;
  services?: MiService[];
  service_end_date?: string;
  tenant_display_name: string;
}

const loopService = function(services){
  let rowArray = [];
  let moreCount = 0;
  if(services && services.length > 5){
    moreCount = services.length - 5;
    services = services.slice(0, 5);
  }
  services.forEach(element => {
    rowArray.push(`<li>
      <strong>${element.product.product_name}</strong><br/> Service ID: ${element.service_identifier || '--'}<br/> Account Number: ${element.account_id_v2.label || '--'}<br/>  MISO ID: ${element.display_id}
    </li>`)
  });
  if(moreCount){
    rowArray.push(`<li><strong>And ${moreCount} more Service(s)</strong></li>`)
  }
  return rowArray.join("")
}

const loopProperty = function(properties){
  let rowArray = [];
  properties.forEach(element => {
    rowArray.push(`<li>
      ${element.product_field_property.product_field.label}: ${element.value || '--'}
    </li>`)
  });
  return rowArray.join("")
}

const loopLocation = function(locations){
  let rowArray = [];
  locations.forEach(element => {
    rowArray.push(`<li>
      ${element.address.display_id} ${element.address.formatted_address}, ${element.label}
    </li>`)
  });
  return rowArray.join("")
}

const marketQuoteService = function(services){
  let rowArray = [];
  services.forEach(element => {
    rowArray.push(`<p><strong>Current Service </strong><br/>
    
    <a href= "${linkFormatter.getTenantLink(element.owner)+"/services/"+element.display_id}" target="_blank">${element.display_id}</a> <br/>
    Current Vendor:  ${element.purchasing_vendor.vendor_name} <br/>
    Current Product: ${element.product.product_name} <br/>
    Current Location:  ${element.locations && element.locations.length? '<ul>'+loopLocation(element.locations)+'</ul>': '--'}</ul><br/>
    ------------------------
    </p>
    <p><strong>Service to Quote </strong> <br/>
    Product Category: ${element.product.product_category.category_name} <br/>
    Product Property: 
    ${element.product.product_fields && element.product.product_fields.length? '<ul>'+loopProperty(element.product.product_fields)+'<ul>':'--'}</p>
    
    <p>------------------------<br/>
    <strong>Additional Information </strong>(response needed by, alternative services requested, etc.)<br/><br/><br/><br/><br/>
    
    
    
    
    
    ------------------------</p>`)
  });
  return rowArray.join("")
}

const getFriendlyDate = function (date){
  // Ex: 2021-01-21T05:00:00.000Z
  const eot = new Date(date);
  return DateTime.fromJSDate(eot).toLocaleString(DateTime.DATE_MED);
}

//account rep
const missingDataBody = function (data: emailData) { 
  return `<p>Hello ${data.recipient_first_name},</p> 

    <p>We are missing certain information regarding products or services purchased from ${data.vendor_name}.  The attached MS Excel Missing Data Report details the missing information.  Please provide in the Excel sheet as much of the missing information as possible and return the spreadsheet to this email.</p> 
    
    <p>Please contact me with any questions.</p> 

    <p>Thank you,<br /><br />
    ${data.user_name}<br />
    ${data.user_email}</p>`
 }

//requestor
const reAssessmentRequestBody = function (data: emailData) {
  return `<p>Hello ${data.recipient_first_name},</p> 

    <p>The below service(s) require your input in the next few days. You are listed as the contact responsible for, or most knowledgeable of the service(s).

    Please contact me soon and let me know how to proceed. The options are:</p> 

    <ol>
    <li>Good as is and no changes should be made, or</li>
    <li>Still in use but we should consider changing them in some way (i.e. increasing or decreasing size or number), or</li>
    <li>Are no longer in use or useful and should be cancelled.</li>
    </ol>
    <p>An MS Excel spreadsheet with additional detail for the service(s) is also attached for your convenience.</p>
    <strong>Service(s) List:</strong>
    <ul>
    ${loopService(data.services)}
    </ul>

    <p>Please call or email me with any questions.</p> 

    <p>Thank you,<br /><br />
    ${data.user_name}<br />
    ${data.user_email}</p>`
 }
 //account rep
const requestQuoteBody = function (data: emailData){
  return `<p>Hello ${data.recipient_first_name},</p> 

    <p>The below service(s) are coming to the end of the current term${data.service_end_date? " in "+getFriendlyDate(data.service_end_date):''}. ${data.tenant_display_name} is looking at new quotes for the service(s) and would like to receive new pricing for 1, 3, and 5 year terms from <VENDOR DISPLAY NAME>.</p> 

    <p>An MS Excel spreadsheet with additional detail for the service(s) is also attached for your convenience.</p>

    <strong>Service(s) List:</strong>
    <ul>
    ${loopService(data.services)}
    </ul>

    <p>Please contact me with any questions.</p> 

    <p>Thank you,<br /><br />
    ${data.user_name}<br />
    ${data.user_email}</p>`
 }
//account rep
const cancellationRequestBody = function(data: emailData){

  let dateMessage = '';
  if(data.service_end_date) {
    dateMessage = `Our records indicate the terms end on ${getFriendlyDate(data.service_end_date)}. Please notify us immediately if your records indicate otherwise.`;
  }

  return `<p>Hello ${data.recipient_first_name},</p> 
    <p>The below service(s) are coming to the end of the current term. This email provides Notice that ${data.tenant_display_name} is terminating these service(s) at the end of the current term. ${dateMessage}</p>
    <p>An MS Excel spreadsheet with additional detail for the service(s) is also attached for your convenience.</p>

    <strong>Service(s) List:</strong>
    <ul>
    ${loopService(data.services)}
    </ul>

    <p>Please confirm receipt of this email.<br />
    Contact me with any questions.</p> 

    <p>Thank you,<br /><br />
    ${data.user_name}<br />
    ${data.user_email}</p>`
 }

 const misoReQuoteBody = function(data: emailData){
  let dateMessage = '';
  if(data.service_end_date) {
    dateMessage = `End of Term: ${getFriendlyDate(data.service_end_date) || '--'}`;
  }

  return `<p>MISO Marketplace,</p><br/>
    ${dateMessage}
    ${marketQuoteService(data.services)}

  </p>
  <p>Please contact me with any questions.</p> 
   
  <p>Thank you,<br /><br />
  ${data.user_name}<br />
  ${data.user_email}</p>`
 }

 export {
   missingDataBody,
   reAssessmentRequestBody,
   requestQuoteBody,
   cancellationRequestBody,
   misoReQuoteBody
 }
