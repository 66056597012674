import { Component, Input, OnInit } from "@angular/core";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";

@Component({
  selector: 'mi-advanced-search-sort-v2-component',
  templateUrl: './advanced-search-sort-v2.component.html',
  styleUrls: ['./advanced-search-sort-v2.component.scss']
})

export class AdvancedSearchSortV2Component extends AbstractPageComponent implements OnInit {
  @Input() searchType: string = "SERVICE";
  title: string = 'Sort';
  serviceOptions: string[] = [
    "mrc_desc",
    "mrc_asc"
  ];
  documentOptions: string[] = [
    "date_desc",
    "date_asc",
    "name_desc",
    "name_asc",
    "mrc_desc",
    "mrc_asc"
  ];
  selected: string = '';

  ngOnInit(): void {
    switch(this.searchType) {
      case "SERVICE":
        this.selected = this.serviceOptions[0];
        break;
      case "DOCUMENT":
        this.selected = this.documentOptions[0];
        break;
    }    
  }

  toggle(evt): void {
    
  }
}