<mi-server-error [error]="error"></mi-server-error>

<mi-detail-alert></mi-detail-alert>

<div class="sections">
  <section class="data-section">
    <h2>Details</h2>
    <mi-sortable-table *ngIf="contact" [columns]="contactColumns" [rows]="contactRows">
    </mi-sortable-table>
  </section>
  <!-- <section class="data-section">
    <mi-note-list></mi-note-list>
  </section> -->
</div>

<ng-template #companyTmpl let-row="row" let-value="value">
  <mi-id-link [title]="row.vendor_id" [id]="row.vendor_id"></mi-id-link>
  <span class="vendor-name">{{value}}</span>
</ng-template>

<ng-template #emailTmpl let-row="row" let-value="value">
  <a href="mailto:{{value}}">{{value}}</a>
</ng-template>

<ng-template #roleTmpl let-row="row" let-value="value">
  {{'CONTACT_ROLES.' + value | translate}}
</ng-template>

<ng-template #statusTmpl let-row="row" let-value="value">
  <span [ngClass]="{active: value === 'ACTIVE', inactive: value === 'INACTIVE'}">
        {{'CONTACT_STATUSES.' + value | translate}}</span>
</ng-template>

<ng-template #phoneTmpl let-row="row" let-value="value">
  <a type="tel" href="tel:{{value}}">{{value}}</a>
</ng-template>
