
<mi-page-header
      [breadcrumbs]="breadcrumbs"
      [showShortcutMenu]="false">
</mi-page-header>
<!--
<div class="header-label-container">
  <label>{{headerLabel}}</label>
</div>
-->
<div class="body-container" *ngIf="loaded; else loading">
  <div class="spacer-3"></div>
  <div class="header-text-container">
    <label>Invoice Email</label>
  </div>
  <div class="spacer-2"></div>
  <div class="text-container" *ngIf="!changeEmail">
    <label>{{stripeCustomer.email}}</label> &nbsp;&nbsp;<a (click)="showInvoiceEmailChange()">Change</a>
    <label class="default-payment-label" *ngIf="stripeCustomer.invoice_settings.default_payment_method == null">Default Payment Method</label>
  </div>
  <div class="text-container" *ngIf="changeEmail">
    <input class="email-input" [(ngModel)]="newInvoiceEmail" type="text" (keyup)="validateEmail($event)" (focusout)="validateEmail($event)"> &nbsp;&nbsp;<a (click)="cancelInvoiceEmailChange()">Cancel</a>
    <label class="default-payment-label" *ngIf="stripeCustomer.invoice_settings.default_payment_method == null">Default Payment Method</label>
  </div>
  <div class="spacer-2" *ngIf="changeEmail"></div>
  <div class="change-email-button-container" *ngIf="changeEmail">
    <button mi-button buttonTheme="dark-theme" (click)="changeInvoiceEmailPrompt()" [disabled]="!emailValid">Change Invoice Email</button>
  </div>
  <div class="spacer-5"></div>

  <div class="header-text-container">
    <label>Saved Cards</label>
  </div>
  <div class="spacer-2"></div>
  <div class="saved-cards-container">
    <div class="text-container" *ngIf="stripeCustomerPaymentMethods.data.length == 0">
      <label>No saved cards</label>
    </div>
    <table class="saved-cards-table" *ngIf="stripeCustomerPaymentMethods.data.length > 0">
      <tr *ngFor="let paymentMethod of stripeCustomerPaymentMethods.data">
        
        <td>
          <input type="radio" name="savedCardsRadio" value="{{paymentMethod.id}}" (change)="selectCard($event)" [checked]="paymentMethod.id == selectedPaymentMethodId">
        </td>
        <td>
          <!--
          {{paymentMethod.card.brand.toUpperCase()}} ending with <b>{{paymentMethod.card.last4}}</b>          
           -->
           <mi-payment-card-display [provider]="paymentMethod.card.brand" [last4]="paymentMethod.card.last4"></mi-payment-card-display>
           <label class="default-payment-label" *ngIf="paymentMethod.id == defaultPaymentMethodId">Default Payment Method</label>
        </td>
       
        
      </tr>
    </table>
  </div>
  <div class="spacer-2"></div>
  <div class="payment-button-container" *ngIf="paymentMethodChanged">
    <button mi-button buttonTheme="dark-theme" (click)="changePaymentMethodPrompt()">Change Payment Method</button>
  </div>
  <div class="spacer-2" *ngIf="paymentMethodChanged"></div>
  <div class="text-container">
    <a (click)="addNewCardPrompt()">Add a new card</a>
  </div>
  <div class="spacer-2"></div>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>