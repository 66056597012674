<div class="product-vendor {{theme}}" [ngClass]="{'locked': locked}" (click)="unSelectVendor()" >
  <div class="vendor">
      <input type="checkbox"
      value="{{vendor._id}}"
      checked="true"
      />
    <mi-vendor-logo [url]="vendor.logo"></mi-vendor-logo>
    <span class="vendor-name">{{vendor.vendor_name}}</span>
  </div>
  <span *ngIf="vendor.display_id">{{vendor.display_id}}</span>
</div>
