<mi-page-header
  title="Create Account Id"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<mi-accountId-edit-form
  *ngIf="vendor"
  [vendor]="vendor"
  (canceled)="onCanceled()"
  (updateCompleted)="onUpdateAccountId($event)">
</mi-accountId-edit-form>
