import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {minLengthWithTrimmedWhitespace} from '../../../shared/shared.validators';
import {LOCATION_STATUS_INACTIVE, LOCATION_STATUS_ACTIVE, LOCATION_STATUSES} from '../../../common/utils/sharedConstants';
import {LocationV2} from '../../../libs/api2/types/LocationV2.interface';
import {AddressV2Service} from '../../../libs/api2/address-v2/address-v2.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'mi-locations-edit-form',
  templateUrl: './locations-edit-form.component.html',
  styleUrls: ['./locations-edit-form.component.scss']
})

export class LocationsEditFormComponent extends AbstractPageComponent implements OnInit {

  @Output() updateCompleted: EventEmitter<LocationV2> = new EventEmitter<LocationV2>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  @Input('editId')
  set editId(val) {
    if (val) {
      this.startAsEditMode(<string>val);
    }
  }

  _editId: string;
  ctaLabel: string;
  loading: boolean = false;
  loadError: string = '';
  inEditMode: boolean = false;
  location: LocationV2;
  locationForm: FormGroup;

  readonly LOCATION_STATUSES = LOCATION_STATUSES;

  constructor(
    private tenantAddressV2Service: AddressV2Service,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService) {
    super();
  }

  ngOnInit() {
    console.log('locations edit form');
  }

  createForm(loc: LocationV2) {
    this.location = loc;
    this.locationForm = this.formBuilder.group({
      label: [this.location.label || '', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        minLengthWithTrimmedWhitespace(2)
      ])],
      nick_name: [this.location.nick_name || ''],
      status: [this.location.is_active ? LOCATION_STATUS_ACTIVE: LOCATION_STATUS_INACTIVE, Validators.required]
    });
  }

  onUpdateLocation(data: any): void {
    const newLabel = data.label.trim();

    this.locationForm.get('label').setValue(newLabel);

    const newLocation = {
      label: newLabel,
      is_active: data.status === LOCATION_STATUS_ACTIVE,
      nick_name: data.nick_name,
      address_id: this.location.address.id
    };

    if (this.location.id != null) {
      Object.assign(newLocation, {id: this.location.id});
    }

    this.tenantAddressV2Service.saveLocation(newLocation).subscribe((res) => {
      //this.indexLocation(res);
      this.toastrService.success("Location updated successfully");
      this.updateCompleted.emit(res);
    }, (error) => {
      this.loadError = error;
    });
  }

  indexLocation(data) {
    this.tenantAddressV2Service.indexLocation(data.id).subscribe((response) => {
      this.updateCompleted.emit(data);
    }, (error) => {
      this.loadError = error;
    });
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  startAsEditMode(locationId: string): void {
    this.inEditMode = true;
    this.ctaLabel = 'Continue';
    this._editId = locationId;
    this.loading = true;
    this.loadError = '';

    this.tenantAddressV2Service.getLocationById(locationId).subscribe((res) => {
      this.createForm(res);
    }, (error) => {
      this.loadError = error;
    });
  }
}
