import {Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Vendor} from '../../../shared/models/Vendor';
import { Document } from '../../../libs/api2/types/Document.interface';
import { TenantDocumentManagementService } from '../../../libs/api/tenant/tenantDocumentManagement.service';
import {CUSTOMER_DOCUMENT_UPLOAD_TYPES} from '../../../common/utils/sharedConstants';
import { DocumentUploadModalComponent } from '../../../shared/components/document-upload-modal/document-upload-modal.component';
import {ModalService} from '@independer/ng-modal';
import { environment } from 'src/environments/environment';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import FeDateUtil from 'src/shared/utils/FeDate.util';

const log = createLogger(LOG_LEVELS.COMPONENT);

export enum FindOrCreateVendorDocumentsComponentModes {
  SELECT_STATE,
  CREATE_UPLOAD_STATE,
  CREATE_LINK_STATE,
};

@Component({
  selector: 'mi-find-or-create-vendor-documents',
  templateUrl: './find-or-create-vendor-documents.component.html',
  styleUrls: ['./find-or-create-vendor-documents.component.scss']
})

export class FindOrCreateVendorDocumentsComponent implements OnInit {

  @Input() vendor: Vendor;
  @Output() selectedVendorDocuments: EventEmitter<Document[]> = new EventEmitter<Document[]>();
  
  _selectedDocuments: Document[] = [];
  _selectedDocumentsIds: string[] = [];
  allDocs: Document[] = [];
  error: string;
  loaded: boolean = false;
  loadError: any = '';
  documentsAndLinksForm: FormGroup;

  readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;
  timeZone: string = "America/New_York"

  constructor(
    private tenantAssociatedDocumentsService: TenantDocumentManagementService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
              private store: Store<appDataReducer.State>
  ) {

  }

  ngOnInit() {
    // Default search for folk;
    this.loadDocumentsForVendor(this.vendor);

    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    tenantStateData.first()
      .subscribe(data => {
        if(data && data.tenantData && data.tenantData.timezone) {
          this.timeZone =  data.tenantData.timezone;
        }
      })
  }

  convertTimeZone(time){
    return FeDateUtil.applyTimeZoneFromISO(time, this.timeZone)
  }

  @Input('selectDocuments')
  set selectDocuments(documents: string[]) {
    this._selectedDocumentsIds = documents;
  }

  isSelectedDoc(doc1: Document): boolean {
    return this._selectedDocuments.some(doc => doc.id === doc1.id);
  }

  loadDocumentsForVendor(vendor: Vendor): void {

    this.loaded = true;
    this.tenantAssociatedDocumentsService.getDocumentsByVendorId(vendor._id, vendor.owner || vendor.tenant_id)
      .toPromise()
      .then((documents) => {
        this.allDocs = [...this._selectedDocuments];
        documents.forEach((ad) => {
          if(this._selectedDocumentsIds.find(d => d === ad.id)){
            this.allDocs.push(ad);
            this._selectedDocuments.push(ad);

          }
          if (!this.allDocs.some((d) => {
            return d.id === ad.id;
          })) {
            this.allDocs.push(ad);
          }
        });
        this.documentsAndLinksForm = this.formBuilder.group(
          {
            selected_documents: [this._selectedDocuments, [Validators.required]]
          }
        );
      })
      .catch(e => this.loadError = e);
  }

  onDocumentSelect(document) {
    // Handle selection of doc/link selection.
    if (this._selectedDocuments.some(doc => doc.id === document.id)) {
      // Remove it from the list.
      this._selectedDocuments = this._selectedDocuments.filter(doc => {
        return doc.id !== document.id;
      });
    } else {
      this._selectedDocuments.push(document);
    }
    this._selectedDocumentsIds = this._selectedDocuments.map(ad => ad.id);
    this.documentsAndLinksForm.controls.selected_documents.setValue(this._selectedDocuments);
    this.documentsAndLinksForm.updateValueAndValidity();
  }

  onContinueClick(event) {
    event.preventDefault();
    this.selectedVendorDocuments.emit(this._selectedDocuments);
  }

  shortenFileName(name) {
    if (name && name.length > 40) {
      return name.slice(0, 40) + '...';
    }
    return name;
  }

  downloadDocument(id) {
    return this.tenantAssociatedDocumentsService.download(id).subscribe((response) => {
      //debugger;
      console.log(response.download_url);
      window.open(response.download_url, "_blank");
    }, (e) => {

    });
  }

  getV1FileUrl(row){
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }

  uploadDocument() {
    const modalRef = this.modalService.open(DocumentUploadModalComponent, m => {
      m.vendor = this.vendor;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      

      if (result.length) {
        this.tenantAssociatedDocumentsService.getDocumentsByVendorId(this.vendor._id, this.vendor.owner || this.vendor.tenant_id)
      .toPromise()
      .then((documents) => {
        this.allDocs = [];
        this._selectedDocuments = [];
        documents.forEach((ad) => {
          if(this._selectedDocumentsIds.find(d => d === ad.id)){
            this.allDocs.push(ad);
            this._selectedDocuments.push(ad);

          }
          if (!this.allDocs.some((d) => {
            return d.id === ad.id;
          })) {
            this.allDocs.push(ad);
          }
        });
        
      })
      .catch(e => this.loadError = e);
      }
    });
  }

  /*onAssociatedDocumentCreated(doc: AssociatedDocument) {
    log('onAssociatedDocumentCreated');
    // log(doc);
    this.allDocs.push(doc);
    this._selectedDocuments.push(doc);
    this.swapTo(this.MODE_SELECT_STATE);
  }

  swapTo(newState: FindOrCreateVendorDocumentsComponentModes) {
    this.stepChanged.emit(newState);
    this.step = newState;
  }*/

}
