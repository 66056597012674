
<mi-server-error [error]="error"></mi-server-error>
<ng-container *ngIf="resultsLoaded$; else loading">
  <div class="results" id="AdvancedSearchServiceResultsTableComponent">
    <mi-sortable-draggable-table 
      *ngIf="hits$ && hits$.length;else noResult" 
      [rows]="hits$" 
      [columns]="serviceColumns"
      externalClasses="fullWidth" 
      searchType="SERVICE" 
      [hasCustomFields]="true">
    </mi-sortable-draggable-table>  
    <ng-template #noResult><p>No matching results found.</p></ng-template>
  </div>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #mrcTmpl let-row="row" let-value="value">
  {{row.calculations_v2.monthly_recurring_cost | currency:'USD':'symbol':'1.2-2'}}
</ng-template>

<ng-template #serviceStatusTmpl let-row="row" let-value="value">
  <span *ngIf="value">
    {{'SERVICE_STATUSES_IS_ACTIVE.' + value | translate}}
  </span>
</ng-template>

<ng-template #orderTmpl let-row="row" let-value="value">
  {{value.service_order_date + ' - ' + value.service_order_number}}
</ng-template>

<ng-template #financialTmpl let-row="row" let-value="value">
  {{value.cost}} /
  {{'MONTHS_QTY' | translate:{count: value.billing_frequency} | translateSelector: value.billing_frequency }}
</ng-template>

<ng-template #nextEventTmpl let-row="row" let-value="value">
  <ng-container *ngIf="value">
    {{'TENANT.SERVICE_DETAILS.EVENTS.'+value | translate}}
  </ng-container>
  <ng-container *ngIf="!value">
    {{'GLOBAL.UNKNOWN' | translate }}
  </ng-container>
</ng-template>

<ng-template #buTmpl let-row="row" let-value="value">
  <ng-container *ngIf="value && row.division_property">
    {{row.division_property.name}} - {{row.team_property.name}}
  </ng-container>
  <ng-container *ngIf="!value">
    {{row.division_property? row.division_property.name : ''}}
  </ng-container>
</ng-template>

<ng-template #divisionTmpl let-row="row" let-value="value">
  <ng-container *ngIf="value && row.division_property">
    {{row.division_property.name}}
  </ng-container>
</ng-template>

<ng-template #teamTmpl let-row="row" let-value="value">
  <ng-container *ngIf="value && row.team_property">
    {{row.team_property.name}}
  </ng-container>
</ng-template>

<ng-template #customFieldHeaderTpl let-column="column">
  {{customFieldById[column.name]}}
</ng-template>

<ng-template #groupTmpl let-row="row" let-value="value">
  <ng-container *ngFor="let group of value">
    <!-- <mi-id-link id="{{group.display_id}}"></mi-id-link> -->
    {{group.name}} <br>
  </ng-container>
</ng-template>