<mi-server-error [error]="error"></mi-server-error>
<ng-container *ngIf="loaded; else loading">
  <ng-container *ngIf="canDeleteMode && product">
    <h2>Delete Product</h2>
    <mi-side-notice noticeType="WARNING">
      <strong>Do you want to delete Product "{{product.product_name}}" ?</strong>
        <p>Remember, this action cannot be reversed.</p>
        <p>Press delete to remove this Product permanently</p>
        <div class="button-container">
          <a mi-button
            class="button--cancel"
            buttonTheme="alert-light-theme"
            (click)="onCancel($event)">
            Cancel
          </a>
          <a mi-button
            buttonTheme="alert-theme"
            (click)="permanentlyDelete()">
            Delete
          </a>
        </div>
      </mi-side-notice>
  </ng-container>

</ng-container>

  <ng-container *ngIf="!canDeleteMode && product.dependents.length"><--sdf--/>
    <mi-cascade-need-replace
      title= "Cannot Delete Product"
      subtitle= "The following items are associated with this Product."
      [dependents]= "product.dependents"
      (canceled)="onCancel($event)">
      <mi-alert-item alertType="ERROR">
        Before deleting the Product, please remove the Product from the associated items above.
      </mi-alert-item>
    </mi-cascade-need-replace>
  </ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
