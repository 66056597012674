import {Telephone} from "./Telephone";

export class Contact {
  id?: string;
  _id?: string;
  display_id: string;
  email?: string;
  fax?: Telephone;
  first_name: string;
  last_name: string;
  mobile_phone?: Telephone;
  office_phone?: Telephone;
  owner: string;
  role?: string;
  service_count?: number;
  service_mrc?: number;
  status: string;
  vendor: any;
  full_name?: string;
  service_association? : any[];
  service_total_count? : number;
  service_mrc_total? : number;
  equipment_count?: number;
}

export enum ServiceAssociationType {
  ACCOUNT_REP = 'Acc Rep',
  //REQUESTOR = 'Service Owner',
  REQUESTOR = 'Accountable Executive',
  ASSIGNEE = 'M3 Owner',
  //MI_OWNER = 'Mi-Service Owner',
  MI_OWNER = 'Mi-MiSO Owner',
  ENDUSER = 'Subscriber',
  ADDITIONAL_CONTACT = 'Additional Contact'
}