import {Component} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';


@Component({
  templateUrl: './excel-email-notification-modal.component.html',
  styleUrls: ['./excel-email-notification-modal.component.scss']
})

export class ExcelEmailNotificationModalComponent {

  title:string = "Notification"
  message:string = "Notification"
  closeButtonLabel:string = "Confirm"

  constructor(
    private modal: ModalRef,
   ) {
  }

  onCancel() {
    this.modal.close({});
  }
}
