<div class="filter-container">
  <div class="short-cut-icon">
    <a *ngIf="!ShowConfigure" (click)="ShowConfigure=true" [ngClass]="'micon-settings'">
      <span>Configure</span>
    </a>
    <a (click)="ShowConfigure=false" *ngIf="ShowConfigure" class="cancelFilterConfiguration">
      <span>Cancel Configure</span>
    </a>
  </div>
  <ng-container *ngIf="!ShowConfigure">

    <ng-container *ngIf="customFields && customFields.length > 0">
      <ng-container *ngFor="let cf of customFields">
        <ng-container [ngSwitch]="cf.type">

          <ng-container *ngSwitchCase="'NUMBER'">
            <mi-advanced-search-filter-number filter_name="{{cf._id}}" title="{{cf.label}}">
            </mi-advanced-search-filter-number>
          </ng-container>

          <ng-container *ngSwitchCase="'DATE'">
            <mi-advanced-search-filter-date filter_name="{{cf._id}}" title="{{cf.label}}">
            </mi-advanced-search-filter-date>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <mi-advanced-search-filter-group-v2 title="{{cf.label}}" filter_name="{{cf._id}}" [isCustomField]="true">
            </mi-advanced-search-filter-group-v2>
          </ng-container>

        </ng-container>
      </ng-container>
    </ng-container>

    <mi-advanced-search-filter-assigned-to title="{{'FILTER_TITLES.ASSIGNED_TO' | translate}}">
    </mi-advanced-search-filter-assigned-to>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.BUSINESS_UNIT_ID' | translate}}"
                                     filter_name="business_unit_id"
                                     *ngIf="!pageLockFilters.includes('business_unit_id')">
    </mi-advanced-search-filter-group-v2>


    <mi-advanced-search-filter-category *ngIf="!pageLockFilters.includes('product_category')">
    </mi-advanced-search-filter-category>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.VENDOR' | translate}}"
                                     filter_name="vendor_name"
                                     *ngIf="!pageLockFilters.includes('vendor_name')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-requestor title="{{'FILTER_TITLES.REQUESTOR' | translate}}"
                                     filter_name="requestor"
                                     *ngIf="!pageLockFilters.includes('requestor')">
    </mi-advanced-search-filter-requestor>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.DATA_INTEGRITY' | translate}}"
                                     translatePrefix="TENANT.SERVICE_DETAILS.STATUS."
                                     filter_name="data_integrity_overall_status"
                                     *ngIf="!pageLockFilters.includes('data_integrity_overall_status')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-number filter_name="mrc" min_label="{{'TENANT.SERVICE_DETAILS.MIN_MRC' | translate}}"
                                      max_label="{{'TENANT.SERVICE_DETAILS.MAX_MRC' | translate}}"
                                      min_placeholder="{{'TENANT.SERVICE_DETAILS.MIN_MRC_PLACEHOLDER' | translate}}"
                                      max_placeholder="{{'TENANT.SERVICE_DETAILS.MAX_MRC_PLACEHOLDER' | translate}}"
                                      filter_button_label="Filter by cMRC"
                                      title="{{'FILTER_TITLES.MONTHLY_COST' | translate}}">
    </mi-advanced-search-filter-number>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.COUNTRY' | translate}}"
                                     filter_name="country_long"
                                     *ngIf="!pageLockFilters.includes('country_long')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.STATE' | translate}}"
                                     filter_name="state_long"
                                     *ngIf="!pageLockFilters.includes('state_long')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.CITY' | translate}}"
                                     filter_name="city_long"
                                     *ngIf="!pageLockFilters.includes('city_long')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.PARENT_SERVICE_STATUS' | translate}}"
                                     translatePrefix="TENANT.SERVICE_DETAILS.PARENT_SERVICE_STATUS."
                                     filter_name="parent_service_status"
                                     *ngIf="!pageLockFilters.includes('parent_service_status')">
    </mi-advanced-search-filter-group-v2>

    <mi-advanced-search-filter-group-v2 title="{{'FILTER_TITLES.NEXT_DECISION' | translate}}"
                                     translatePrefix="TENANT.SERVICE_DETAILS.NEXT_DECISION."
                                     filter_name="next_decision"
                                     *ngIf="!pageLockFilters.includes('next_decision')">
    </mi-advanced-search-filter-group-v2>

    <div class="separated-filter-group">
      <mi-advanced-search-single-checkbox
        param="inactive"
        label="Show Only Inactive Services">
      </mi-advanced-search-single-checkbox>

      <mi-advanced-search-single-checkbox
        param="parents_only"
        label="Show Only Parent Services">
      </mi-advanced-search-single-checkbox>
    </div>

  </ng-container>
  <ng-container *ngIf="ShowConfigure">
    <advanced-search-fiilter-configuration
      (configurationComplete)="onConfigurationComplete($event)"></advanced-search-fiilter-configuration>
  </ng-container>
</div>