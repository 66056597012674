<div class="service-details-header" *ngIf="data; else loading">

  <div class="header-item">
    <div class="service-details-header-item">
      <mi-contact-details-avatar [contact]="employee">
      </mi-contact-details-avatar>
    </div>
  </div>

  <div class="header-item">
    <div class="address-header-item">
      <div class="stats">
        <div class="vendor-details--data-point">
          <dl>
            <dt class="formatted-address">
              {{employee.first_name}} {{employee.last_name}}
            </dt>
            <dd class="contact-detail">
              {{employee.email}}
              <br>
              <div *ngIf="employee.mobile_phone">
                M:{{employee.mobile_phone}}
              </div>
              <div *ngIf="employee.office_phone">
                O:{{employee.office_phone}}
              </div>
            </dd>
            <dd class="edit-bottom">
              <a class="edit formatted-address"
                *ngIf="employee"
                routerLink="/employees/{{employee.id}}/edit"
                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">Edit
              </a>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div class="header-item">
    <div class="service-details-header-item">
      <div class="stats">
        <div class="vendor-details--data-point">
          <dl>
            <dt>
              Cost Center
            </dt>
            <dd>
              {{employee.cost_center|| "None"}}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div class="header-item">
    <div class="service-details-header-item">
      <div class="stats">
        <div class="vendor-details--data-point">
          <dl>
            <dt>
              {{data.specs[0].label | translate }}
            </dt>
            <dd>
              {{data.specs[0].value}}
            </dd>
            <!-- <dt>
              {{data.specs[1].label | translate }}
            </dt>
            <dd>
              {{data.specs[1].value | tenantcurrency:'1.2-2'}}
            </dd> -->
          </dl>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>