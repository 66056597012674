<mi-server-error [error]="error"></mi-server-error>
  <div *ngIf="!editMode; else findOrCreate">
    <div class="section-details ml-2">
      <ng-container>
        <div>
          <dt>
            Term ID
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm && serviceTerm.display_id; else noTermsAndConditionsDoc">
              <mi-id-link [id]="serviceTerm.id" [display_id]="serviceTerm.display_id"></mi-id-link>
            </ng-container>
            <ng-template #noTermsAndConditionsDoc>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>
            End of Term Action
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm; else noEot">
              {{'END_OF_TERM.' + serviceTerm.event_type | translate}}
            </ng-container>
            <ng-template #noEot>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>
            Auto-Renew Period
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm && serviceTerm.auto_renew_length; else noArp">
              {{('MONTHS_QTY'
              | translate:{count: serviceTerm.auto_renew_length}
              | translateSelector: serviceTerm.auto_renew_length ) || NO_DATA}}
            </ng-container>
            <ng-template #noArp>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>{{ 'DAYS_TO_NOTIFY' | translate}}</dt>
          <dd>
            <ng-container *ngIf="serviceTerm; else noDtn">
              {{serviceTerm.days_to_notify|| 0}}
            </ng-container>
            <ng-template #noDtn>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>Action</dt>
          <dd>
            <a *ngIf="serviceTerm; else searchButton"
              class="deleteServiceTerm"
              (click)="deleteTermModalHandler()">
            </a>
            <ng-template #searchButton>
              <a (click)="changeMode(true)"> 
                Add
              </a>
            </ng-template>
          </dd>
        </div>
      </ng-container>
    </div>

</div>

<ng-template #findOrCreate>
  <h3 class="note-info p-2 mb-2">Selected Service Term</h3>

  <ng-container *ngIf="step===SEARCH_STATE">
    <div class="card p-4 mb-3">
    <div>
      <h2>Search</h2>
      <form *ngIf="searchTermForm"
        [formGroup]="searchTermForm"
        (submit)="onFormSubmit(searchTermForm.value)">
        <div class="service-term-filter">
          <mi-extended-input
            labelText="End of Term Action"
            [(inputControl)]="searchTermForm.controls['event_type']"
            [(inputErrors)]="searchTermForm.controls['event_type'].errors"
            [(inputTouched)]="searchTermForm.controls['event_type'].touched">
            <select (change)="onFormSubmit(searchTermForm.value)" formControlName="event_type">
              <option [value]="EVENT_TYPE_AUTO_RENEW">Auto-Renew</option>
              <option [value]="EVENT_TYPE_AUTO_CANCELLATION">Cancel</option>
            </select>
          </mi-extended-input>
        </div>
        <div class="service-term-filter">
          <mi-extended-input labelText="Auto-Renew Length (Months)"
            [(inputControl)]="searchTermForm.controls['auto_renew_length']"
            [(inputErrors)]="searchTermForm.controls['auto_renew_length'].errors"
            [(inputTouched)]="searchTermForm.controls['auto_renew_length'].touched">
            <input type="number" [attr.disabled]="searchTermForm.value.event_type===EVENT_TYPE_AUTO_CANCELLATION? '' : null"
              formControlName="auto_renew_length"
              placeholder="Ex. 3"
              min="0"
              (change)="onFormSubmit(searchTermForm.value)" 
              mi-restrict-input miRestrictType="Integer"/>
          </mi-extended-input>
        </div>
        <div class="service-term-filter">
          <mi-extended-input
          labelText="Advanced Notification (Days)"
          [(inputControl)]="searchTermForm.controls['days_to_notify']"
          [(inputErrors)]="searchTermForm.controls['days_to_notify'].errors"
          [(inputTouched)]="searchTermForm.controls['days_to_notify'].touched">
            <input type="number" [attr.disabled]="searchTermForm.value.event_type===EVENT_TYPE_AUTO_CANCELLATION? '' : null"
              formControlName="days_to_notify"
              placeholder="Ex. 30"
              min="0"
              (change)="onFormSubmit(searchTermForm.value)" 
              mi-restrict-input miRestrictType="Integer"/>
          </mi-extended-input>
        </div>
      </form>
    </div>
    </div>
    <div>
      <div class="d-flex note-info justify-content-between align-items-center mb-2">
      <h2 class="m-0">Service Terms</h2>
      <div>
        <button class="btn btn-primary btn-sm" (click)="swapTo(CREATE_STATE)">Add New</button>
      </div>
      </div>
      <div class="card">
      <mi-sortable-table 
        [columns]="serviceTermColumn" 
        [rows]="serviceTerms" 
        [rowSelectable]="true" 
        (rowSelected)="rowSelected($event);">
      </mi-sortable-table>
      <p *ngIf="!serviceTerms">
        There are no matching service term.
      </p>
    </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="step===CREATE_STATE">
    <mi-service-term-form
      [owner]="tenantId"
      (canceled)="swapTo(SEARCH_STATE)"
      (serviceTermCreated)="rowSelected($event)"
      >
  
    </mi-service-term-form>
  </ng-container>
</ng-template>

<ng-template #checkboxTpl let-row="row" let-value="value">

  <label>
    <input type="checkbox" value="{{row._id}}">
    {{row.display_id}}
  </label>

</ng-template>

<ng-template #eventTypeTpl let-row="row" let-value="value">
  {{'END_OF_TERM.' + value | translate}}
</ng-template>
