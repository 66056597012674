
import {Injectable} from '@angular/core';

@Injectable() export class ServiceDetailsEffects {

  constructor() {

  }

}
