<ng-container *ngIf="hasResults && !dropdownItem; else dropdownItem"
  class="not-dropdown">
  <a *ngIf="printButton"
    class="print-button dark-theme" 
    (click)="print()">
     Print
  </a>

  <a *ngIf="!printButton"
    [ngClass]="{theme:theme?true:false,download:true}" 
    (class)="theme" 
    download="download"  
    href="{{url}}">
    {{buttonLabel}}
  </a>
</ng-container>
<ng-template #dropdownItem>
  <ng-container *ngIf="hasResults">
    <a *ngIf="!printButton"
      href="{{url}}">
      <li class="dropdown-item">
        <span class="dropdown-item-title"  
          [ngClass]="{theme:theme?true:false, download:true}" 
          (class)="theme" 
          download="download" 
        >
          {{buttonLabel}}
        </span>
      </li>
    </a>
    <a *ngIf="printButton" 
      (click)="print()">
      <li class="dropdown-item">
        <span 
          class="dropdown-item-title print-button dark-theme"
        >
          Print
        </span>
      </li>
    </a>
  </ng-container>
</ng-template>
