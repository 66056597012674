import {Component, Directive, ElementRef, HostListener, Input} from "@angular/core";

@Directive({
  selector: '[miOnlyNumbers]'
})

export class NumbersOnlyInputComponent {

  regexStr = '^[0-9]';
  regexStrAsthmatic = '^[\\d\\(\\)\\+\\-\\(\\) .]';
  constructor(private el: ElementRef) { }
  private eventCodeToAlwaysAccept: string[] = ['ArrowRight', 'ArrowLeft', 'Backspace'];
  @Input() miOnlyNumbers: boolean;
  @Input() allowArithmetic: boolean;

  @HostListener('keypress', ['$event']) onKeyPress(e) {
    //let e = <KeyboardEvent> event;
    if (this.miOnlyNumbers) {
      // detecting paste on mac:
      let keyCode = e.which || e.charCode || e.keyCode;


    if(this.eventCodeToAlwaysAccept.includes(e.code)||keyCode===13) {
      // firefox specifically includes these e.code
      return true;
    }

      let ch = String.fromCharCode(keyCode);
      // console.log(`KeyCode: ${ch}`);
      let regEx =  new RegExp( this.allowArithmetic ? this.regexStrAsthmatic : this.regexStr);
      if(regEx.test(ch))
        return;
      else
        e.preventDefault();
    }
  }
}
