import {RouterStateSerializer} from '@ngrx/router-store';
import {RouterStateSnapshot, Params, ActivatedRouteSnapshot} from '@angular/router';

import {createLogger, LOG_LEVELS} from '../../../shared/logger';


const log = createLogger(LOG_LEVELS.CORE);

const DATA_SHOW_FOOTER: string = 'show_footer';
const DATA_SHOW_NAV: string = 'show_nav';
const DATA_TEMPLATE: string = 'template';
const DATA_PAGE_DATA: string = 'pageTitle';
const DATA_INHERITED: string = 'inheritedPageData';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  queryParams: Params;
  showFooter: boolean;
  showNav: boolean;
  template: string;
  url: string;
  inheritedData: any;
  pageTitle: string;
}


export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const {url} = routerState;
    const showFooter = defaultValue(getRouteData(routerState, DATA_SHOW_FOOTER), true);
    const showNav = defaultValue(getRouteData(routerState, DATA_SHOW_NAV), true);
    const queryParams = routerState.root.queryParams;
    const template = getRouteData(routerState, DATA_TEMPLATE) || null;
    const inheritedData = getAllData(routerState)
    const pageTitle = getRouteData(routerState, DATA_PAGE_DATA);
    return {
      queryParams,
      showFooter,
      showNav,
      template,
      url,
      inheritedData,
      pageTitle
    };
  }
}

function defaultValue(source, defaultValue): any {
  return source === undefined ? defaultValue : source;
}

function getAllData(routerState: RouterStateSnapshot): any {
  let root = routerState.root;
  let array=[];
  while (root) {
    if (root['data']) {
      array.push( root['data'])
    }
    if (root.children && root.children.length) {
      root = root.children[0];
    } else if (array !== undefined) {
      return array;
    } else {
      return;
    }
  }
}

function getRouteData(routerState: RouterStateSnapshot, key: string): any {
  let root = routerState.root;
  let value;
  while (root) {
    if (root.data && key in root.data) {
      value = root.data[key];
    }
    if (root.children && root.children.length) {
      root = root.children[0];
    } else if (value !== undefined) {
      return value;
    } else {
      return;
    }
  }
}