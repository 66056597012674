import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmbedUrlRequest } from "src/shared/models/MetabaseReports";

@Injectable()
export class MetabaseReportsV2Service {
  private host = '_MICRO_SERVICE_';

  constructor(private http: HttpClient) {
  }

  getMetabaseLink(params: EmbedUrlRequest): Observable<string> {
    return this.http
      .post(`${this.host}/connector/metabase-embed-url?dashboard=5`, {...params}, {responseType: "text"})
      .map((response: any) => {
        return response;
      });
  }
}