import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {TenantTermsAndConditionsService} from "../../../libs/api/tenant/tenantTermsAndConditions.service";
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';

const log = createLogger(LOG_LEVELS.COMPONENT);


export enum FindOrCreateTermsAndConditionsComponentSteps {
  CREATE_STATE,
  SEARCH_STATE
};

@Component({
  selector: 'mi-find-or-create-terms-and-conditions',
  templateUrl: './find-or-create-terms-and-conditions.component.html',
  styleUrls: ['./find-or-create-terms-and-conditions.component.scss']
})

export class FindOrCreateTermsAndConditionsComponent implements OnInit {

  @Output() termsAndConditionsSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() stepChanged: EventEmitter<FindOrCreateTermsAndConditionsComponentSteps> = new EventEmitter<FindOrCreateTermsAndConditionsComponentSteps>();
  @Output() termsAndConditionsCancel: EventEmitter<void> = new EventEmitter<void>();
  @Input() selectedItem: ServiceTerm;;
  @Input() allowNull:boolean = true;
  @Input() showCancel:boolean = false;

  CREATE_STATE = FindOrCreateTermsAndConditionsComponentSteps.CREATE_STATE;
  SEARCH_STATE = FindOrCreateTermsAndConditionsComponentSteps.SEARCH_STATE;
  step: FindOrCreateTermsAndConditionsComponentSteps = this.SEARCH_STATE;
  error: string;
  loadError: any = '';

  duplicateTerms = {};

  selectionForm: FormGroup;

  constructor(
  ) {

  }


  ngOnInit() {
    this.selectionForm = new FormGroup({
      selection: new FormControl(null, [Validators.required])
    });
  }

  onFormSubmit(value) {
    this.termsAndConditionsSelected.emit(value.selection);
  }

  onOptionSelected(value: any) {
    // Handle selection of doc/link selection.
    this.selectionForm.controls.selection.setValue(value);
  }

  swapTo(newState: FindOrCreateTermsAndConditionsComponentSteps) {
    this.stepChanged.emit(newState);
    this.step = newState;
  }

  onCancel(){
    this.termsAndConditionsCancel.emit();
  }
}
