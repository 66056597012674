import {Component, Input} from '@angular/core';
import {MiService} from '../../../../../../shared/models/MiService';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as ASReducer from '../../../../../../shared/state/advanced-search/reducers';
import {NO_DATA} from '../../../../../../shared/ng-models/SectionDataTable.model';
import {NOTIFICATION_SEVERITY} from '../../../../../../shared/utils/notificationSeverity';
import {MiParentService} from '../../../../../../shared/models/MiParentService';
import {ParentServiceTileMode} from '../parent-service-tile/parent-service-tile.component';

@Component({
  selector: 'mi-parent-service-search-results',
  templateUrl: './parent-service-search-results.component.html',
  styleUrls: ['./parent-service-search-results.component.scss']
})

export class ParentServiceSearchResultsComponent {

  readonly parentServiceTileMode: any = ParentServiceTileMode;

  @Input() parentService: MiParentService;

  hits$: Observable<MiService[]>;
  resultsLoaded$: Observable<boolean>;

  readonly NO_DATA: string = NO_DATA;
  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;

  constructor(
    private store: Store<ASReducer.State>
  ) {
  }

  ngOnInit() {

    this.hits$ = this.store.select(ASReducer.ASHits)
      .map(response => <MiService[]>response);

    this.resultsLoaded$ = this.store.select(ASReducer.ASResultsLoaded);
  }

}
