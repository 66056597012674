import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class FreshdeskService {

  private getRedirectUrl = '/auth/freshdesk';

  constructor(private http: HttpClient) {
  }

  getRedirect(): Observable<any> {
    return this.http
      .get<any>(this.getRedirectUrl)
      .map((response: any) => response.data);
  }

}
