<mi-server-error [error]="loadError"></mi-server-error>
<mi-server-error [error]="saveError"></mi-server-error>
<div>
    <form *ngIf="accountIdForm" [formGroup]="accountIdForm" (submit)="onUpdateAccountId(accountIdForm.value)">
      <div class="half-column">
        <mi-extended-input
          labelText="Account ID"
          [labelRequired]="true"
          [(inputControl)]="accountIdForm.controls['label']"
          [(inputErrors)]="accountIdForm.controls['label'].errors"
          [(inputTouched)]="accountIdForm.controls['label'].touched"
          [errorDefs]='{required: "An account ID is required.", minLength: "An account ID must be at least one characters long", minLengthWithTrimmedWhiteSpace: "Please enter a valid Account ID."}'>
          <input type="text"
                formControlName="label" />
        </mi-extended-input>

        <mi-extended-input
          labelText="Vendor"
          [labelRequired]="true"
          [(inputControl)]="accountIdForm.controls['vendor']"
          [(inputErrors)]="accountIdForm.controls['vendor'].errors"
          [(inputTouched)]="accountIdForm.controls['vendor'].touched">
          <input type="text"
                formControlName="vendor" />
        </mi-extended-input>

        <mi-extended-input
          labelText="Account Representative"
          [(inputControl)]="accountIdForm.controls['contact']"
          [(inputErrors)]="accountIdForm.controls['contact'].errors"
          [(inputTouched)]="accountIdForm.controls['contact'].touched">
          <div class="chosen-contacts">
            <mi-chosen-contact
              *ngIf="contact"
              [contact]="contact"
              [locked]="true"
              (deselect)="onContactDeselect($event)">
            </mi-chosen-contact>
          </div>
  
          <p *ngIf="contact" class="hint">Click the red button to remove Account Representative.</p>
  
          <a mi-button
              class="button dark-theme"
              (click)="onShowAddContact()"
              *ngIf="!showAddContact">
            Select Account Representative
          </a>
  
          <mi-find-or-create-contact
            (contactSelected)="onAddContact($event)"
            *ngIf="showAddContact">
          </mi-find-or-create-contact>
        </mi-extended-input>
      </div>

        <mi-extended-input
            labelText="Default Service Term"
            [labelRequired]="true"
            [(inputControl)]="accountIdForm.controls['service_term']">
          <mi-find-or-create-service-term
            [serviceTermId] = "accountIdForm.value.service_term"
            (selectedServiceTerm)="onSelectServiceTerm($event)"
            (canceled)="close()"
          ></mi-find-or-create-service-term>
        </mi-extended-input>

      <div class="button-container">
        <ng-container *ngIf=inEditMode>
          <a mi-button
            class="button-alternate"
            buttonTheme="alert-light-theme"
            routerLink="../delete">
            Delete
          </a>
        </ng-container>
        
        <button mi-button
                buttonTheme="light-theme"
                (click)="onCancel($event)">
          Cancel
        </button>
        <button mi-button
                type="submit"
                buttonTheme="dark-theme"
                [disabled]="!accountIdForm.valid">
          Continue
        </button>
      </div>
    </form>
</div>

