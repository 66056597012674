<div>

  <mi-page-header
    title="Email Sent"
    [breadcrumbs]="breadcrumbs">
  </mi-page-header> 

  <mi-server-error [error]="error"></mi-server-error>

  <div *ngIf="loaded; else loading">
    <p class="message">  {{alertMassage}}</p>
  
    <div class="button-container">
      <a mi-button
        buttonTheme="dark-theme"
        (click)="backToOriginal()">
        Back to Service
      </a>
      <a mi-button
        buttonTheme="dark-theme"
        (click)="viewActionableEvent()">
        Your Actionable Events
      </a>
    </div>
  </div> 

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</div>
