import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {EmailFormRecipient} from "../../../../shared/ng-models/EmailFormRecipient.interface";
import {Link} from "../../../../shared/ng-models/Link.interface";
import {MiService} from "../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../libs/api/tenant/tenantMiService.service";
import * as userReducer from "../../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import {Store} from "@ngrx/store";
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {NO_DATA} from "../../../../shared/ng-models/SectionDataTable.model";
import {MisoCollection} from "../../../../shared/models/MisoCollection.enum";
import {Email} from "../../../../shared/models/Email.interface";
import {createLogger, LOG_LEVELS} from "../../../../shared/logger";
import {Note} from "../../../../shared/models/Note.interface";
import {EmailRecipient} from "../../../../shared/models/EmailRecipient.interface";
import {TenantNoteService} from "../../../../libs/api/tenant/tenantNote.service";
import {PageAlertAddAction} from "../../../../shared/state/page-alert/actions";
import {EMAIL_TYPE_RE_ASSESSMENT_REQUEST} from '../../../../common/utils/sharedConstants';
import {reAssessmentRequestBody } from "src/shared/utils/emailContent";
import { Report } from "src/shared/models/Report";
import { TenantReportsService } from "src/libs/api/tenant/tenantReports.service";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  styleUrls: ['./re-assessment-email.component.scss'],
  templateUrl: './re-assessment-email.component.html'
})

export class ReAssessmentEmailComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true
    }
  ];
  cancelUrl: string;
  emailBody: string;
  emailRecipients: EmailFormRecipient[];
  emailSubject: string;
  emailType: string = EMAIL_TYPE_RE_ASSESSMENT_REQUEST;
  error: string;
  headerLinks: Link[];
  loaded: boolean = false;
  service: MiService;
  tenantContacts: EmailFormRecipient[];
  userData: any;
  tenantData: any;
  report: Report;
  attachment: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceService: TenantMiServiceService,
    private store: Store<userReducer.State>,
    private tenantNoteService: TenantNoteService,
    private router: Router,
    private tenantReportService: TenantReportsService
  ) {
    super();
  }

  ngOnInit(): void {

    // Find the current service ID:
    let serviceId = this.activatedRoute.snapshot.params['display_id'];

    // Update breadcrumbs:
    let newBreadcrumbs = [
      {
        url: `/services/${serviceId}`,
        label: serviceId,
        internal: true
      },
      {
        url: `/services/${serviceId}/re-assessment`,
        label: 'Re-Assessment Request',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];

    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState).takeUntil(this.destroy$);
    const serviceData: Observable<MiService> = this.serviceService.getMiService(serviceId).takeUntil(this.destroy$);
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

    combineLatest(userStateData, serviceData, tenantStateData)
      .subscribe((data)=>{

        this.userData = data[0].secureUser.getSafeData();
        this.service = data[1];
        this.tenantData = data[2].tenantData;
        let emailBodyComponent = {
          user_email:this.userData.email,
          user_name: `${this.userData.first_name} ${this.userData.last_name}`,
          vendor_name: this.service.product.product_vendor.vendor_name,
          recipient_first_name: this.service.requestor && this.service.requestor.first_name? this.service.requestor.first_name: '',
          tenant_display_name: this.tenantData.display_name,
          services: [this.service],
          service_end_date: this.service.current_financial.end_date
        }
        this.cancelUrl = `/services/${this.service.display_id}`;
        this.emailBody = reAssessmentRequestBody(emailBodyComponent)
        let recipientArray = [];
        if(this.service.requestor){
          let contactAR = this.service.requestor;
            recipientArray.push({
              _id: contactAR._id,
              first_name: contactAR.first_name,
              last_name: contactAR.last_name,
              email: contactAR.email,
              role: contactAR.role,
              type: MisoCollection.CONTACTS,
              display_id: contactAR.display_id||""
            });
        }
        this.emailRecipients = recipientArray;
        this.emailSubject = `Evaluation of Services, ${this.service.display_id}`;
        if(!this.report){
          this.tenantReportService.createDownloadDetailSingleReport(this.service._id)
          .toPromise()
          .then((res: any) => {
              this.report = res || null;
              this.attachment= [];
              if(this.report){
                this.attachment.push(this.report.excel_file);
              }
              this.loaded = true;
          })
          .catch(e => this.error = e);
        }
      },
        e => this.error = e);


  }

  onEmailCreated(email: Email) {

    const note:Note = {
      connections: [],
      html: `<p><strong>MISO Action</strong> — <em>Request Re-Assessment</em><br><a href="/emails/${email._id}">View Email</a></p>`
    };

    note.connections.push({
      document_id: this.service._id,
      document_collection: MisoCollection.SERVICES
    });

    email.recipients.forEach( (recipient:EmailRecipient) => {
      if (recipient.collection_ref === MisoCollection.CONTACTS) {
        note.connections.push({
          document_id: recipient.collection_id,
          document_collection: MisoCollection.CONTACTS
        });
      }
    });

    this.tenantNoteService.createNote(note)
      .subscribe(
        note => {
          //log('note saved', note);
          this.store.dispatch(
            new PageAlertAddAction({
              message: `Re-Assessment Request Email has been sent.`,
              alertType: "SUCCESS"
            })
          );
          return this.router.navigate([`email-sent`], {relativeTo: this.activatedRoute});
        },
          e => this.error = `The email has been sent, but a note error occurred: ${e}`
      )

  }

}
