import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../../create/state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {Product} from '../../../../../../../shared/models/Product';
import {UpdateOrderAction} from '../../../create/state/actions';
import {integerValidator, numberValidator} from '../../../../../../../shared/shared.validators';

@Component({
  selector: 'mi-service-order-dates',
  styleUrls: ['./service-dates.component.scss'],
  templateUrl: './service-dates.component.html'
})

export class ServiceDatesComponent extends AbstractPageComponent implements OnInit {

  readonly END_DATE:string = "[Service Date] End Date";
  readonly NOT_SURE:string = "[Service Date] Not Sure";
  readonly START_AND_LENGTH:string = "[Service Date] Start and Length";

  readonly NO_DATA: string = NO_DATA;

  activeIndex: boolean = false;
  activeStep: boolean = false;
  currentDate: string;
  datesForm: FormGroup;
  endDate: string;
  error: string;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  product:Product;
  radioSelect: string = this.NOT_SURE;
  startDate: string;
  step: string = OrderSteps.Dates;
  termLength: number;
  today: string;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
  ) {
    super();
  }

  ngOnInit() {

    let now = new Date();
    this.today = `${now.getMonth()+1}/${now.getDate()}/${now.getFullYear()}`;

    this.datesForm = this.formBuilder.group({
      end_date: null,
      start_date: null,
      term_length: null,
    });

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state:OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });

  }

  onCancelCLicked():void{
    this.store.dispatch(new UpdateOrderAction(
      {
        index: this.orderState.maxIndex > this.index ? this.orderState.maxIndex : this.index - 1
      }
    ));
  }

  onEndDateChange(evt):void{
    const control:AbstractControl = this.datesForm.get('end_date');
    this.endDate = evt;
    control.setValue(this.endDate);
    control.updateValueAndValidity();
  }

  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          end_date: data.end_date,
          start_date: data.start_date,
          term_length: data.term_length,
        }
      }
    ));

  }

  onRadioSelect(evt):void {

    this.radioSelect = evt.target.value;

    const endDateControl:AbstractControl = this.datesForm.get('end_date');
    const startDateControl:AbstractControl = this.datesForm.get('start_date');
    const termLengthControl:AbstractControl = this.datesForm.get('term_length');

    if(termLengthControl.value){
      this.termLength = termLengthControl.value
    }

    // Clear out local properties to scuttle current dates on pickers:
    this.endDate = this.startDate = null;

    this.datesForm.clearValidators();

    switch (this.radioSelect) {
      case this.END_DATE:

        endDateControl.setValidators([Validators.required]);
        endDateControl.updateValueAndValidity();

        startDateControl.setValue(null);
        startDateControl.setValidators(null);
        startDateControl.updateValueAndValidity();

        termLengthControl.setValue(null);
        termLengthControl.setValidators(null);
        termLengthControl.updateValueAndValidity();

        break;

      case this.NOT_SURE:

        endDateControl.setValue(null);
        endDateControl.setValidators(null);
        endDateControl.updateValueAndValidity();

        startDateControl.setValue(null);
        startDateControl.setValidators(null);
        startDateControl.updateValueAndValidity();

        termLengthControl.setValue(null);
        termLengthControl.setValidators(null);
        termLengthControl.updateValueAndValidity();

        break;
      case this.START_AND_LENGTH:
      default:

        endDateControl.setValue(null);
        endDateControl.setValidators(null);
        endDateControl.updateValueAndValidity();

        startDateControl.setValidators([Validators.required]);
        startDateControl.updateValueAndValidity();

        termLengthControl.setValidators([Validators.required, Validators.min(1), integerValidator, numberValidator]);
        // termLengthControl.setValue(this.termLength);
        termLengthControl.markAsUntouched();
        termLengthControl.updateValueAndValidity();

    }

  }

  onStartDateChange(evt):void{
    this.startDate = evt;
    this.datesForm.get('start_date').setValue(this.startDate);
  }
}
