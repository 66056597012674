import { Component, Input, OnInit} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { SortableTableColumn } from 'src/shared/ng-models/SortableTableColumn.interface';
import {AsSimpleStateUpdate} from "../../state/advanced-search/actions";
import * as appDataReducer from '../../state/app-data/reducers';
import { Store } from '@ngrx/store';
import * as ASReducer from '../../state/advanced-search/reducers';
import { BulkUploadTemplateField } from "src/shared/models/BulkUploadTemplateSection.interface";


@Component({
  templateUrl: './sortable-table-column-select-modal.component.html',
  styleUrls: ['./sortable-table-column-select-modal.component.scss']
})

export class SortableTableColumnSelectModalComponent implements OnInit {

  data: any;
  _columns: Array<SortableTableColumn> = [];
  customFieldById: any;
  searchType: string = '';
  fields: BulkUploadTemplateField[]= [];
  cfFields: BulkUploadTemplateField[]= [];
  numberOfStickyColumn: number = 1;

  constructor(
    private modal: ModalRef,
    private store: Store<appDataReducer.State>,) {
  }

  ngOnInit(): void {
    if(this.searchType === "SERVICE"){
      this.fields = [];
      this._columns.map((item, i) => {
        if(!this.customHeaderName(item.name)) {
          this.fields.push({
            field_id: item.name,
            display: item.name,
            selected: item.hidden? false : true,
            index: i,
            required: i<this.numberOfStickyColumn? true: false
          })
        } else {
          this.cfFields.push({
            field_id: item.name,
            display: item.name,
            selected: item.hidden? false : true,
            index: i,
            required: i===0? true: false
          })
        }
      })      
    }
  }

  close() {
    this.modal.close({columns:this._columns});
  }

  onFieldsSelected(select){
    this._columns = select.map(item => {
  
      if(item.column_name === item.field_id){
        let currentColumns = this._columns.find(c => c.name === item.field_id);

        return {
          ...currentColumns,
          name: item.column_name,
          hidden: item.selected? false : true,
          is_custom_data: false,
        }
      } else {
        let currentColumns = this._columns.find(c => c.name === item.field_id.substring(3));

        return {
          ...currentColumns,
          name: item.field_id.substring(3),
          hidden: item.selected? false : true,
          is_custom_data: true
        }
      }
    })
    this.updateColumnsState();
    this.close();
  }

  updateColumnsState(){
    let currentColumns = this._columns.map(item => {
      let column : ASReducer.IColumn = {
        name:item.name,
        hidden: item.hidden? true : false,
        is_custom_data: false
      }
      if(this.customHeaderName(item.name)){
        column.name = this.customFieldById[item.name];
        column.is_custom_data = true;
      }
      return column;
    })
    this.store.dispatch( new AsSimpleStateUpdate({columns: currentColumns}))
  }

  onToggleSelect(i){
   this._columns[i].hidden = !this._columns[i].hidden;
   this.updateColumnsState();
  }

  selectAll(select:boolean){
    console.log("numberOfStickyColumn", this.numberOfStickyColumn)
    this._columns = this._columns.map((column, i)=> {
      if(i>this.numberOfStickyColumn-1){
        column.hidden = select;
      }
      return column;
    })
    this.updateColumnsState();
  }
  
  customHeaderName(columnName){
    const thisCustomField = this.customFieldById[columnName];
    if(!thisCustomField){
      return '';
    }
    return true;
  }

}

