import {Component} from '@angular/core';
import {Link} from '../../../shared/ng-models/Link.interface';
import * as linkFormatter from '../../../common/utils/linkFormatter';

@Component({
  styleUrls: ['./data-import.component.scss'],
  templateUrl: './data-import.component.html'
})

export class DataImportComponent {

  breadcrumbs: Link[] = [
    {
      url: '',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/data-import',
      label: 'Data Import',
      internal: true
    }
  ];

  error: string;

  readonly VENDOR_TEMPLATE_URL: string = linkFormatter.getUploadTemplateLink('VENDORS');
  readonly CONTACT_TEMPLATE_URL: string = linkFormatter.getUploadTemplateLink('CONTACTS');
  readonly EQUIPMENT_TEMPLATE_URL: string = linkFormatter.getUploadTemplateLink('EQUIPMENT');
  readonly LOCATION_TEMPLATE_URL: string = linkFormatter.getUploadTemplateLink('LOCATIONS');
  readonly SERVICE_TEMPLATE_URL: string = linkFormatter.getUploadTemplateLink('SERVICES');

}
