import {Component, Input} from "@angular/core";

import * as ASActions from '../../../state/advanced-search/actions';

import {Store} from '@ngrx/store';
import * as ASReducer from '../../../state/advanced-search/reducers';
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {createLogger, LOG_LEVELS} from "../../../logger";
import { MiyagiCategoryService } from "../../../../libs/api/miyagi/miyagiCategories";
import { FilterBucket } from "src/shared/ng-models/search/FilterBucket.interface";
import {switchMap, takeUntil} from "rxjs/operators";
import {ProductCategory} from "../../../models/ProductCategory";

const log = createLogger(LOG_LEVELS.COMPONENT);


@Component({
  selector: 'mi-advanced-search-filter-category',
  templateUrl: './advanced-search-filter-category.component.html',
  styleUrls: ['./advanced-search-filter-category.component.scss']
})

export class AdvancedSearchFilterCategoryComponent extends AbstractPageComponent {

  @Input() title: string = 'Service Category';
  filter_name: string = 'all_product_categories';
  selected: string = '';
  isVisible:boolean = true;
  error: any;
  selectedFilters: any[] = [];
  asDataCats: FilterBucket[] = [];
  includeCount: boolean = true;
  showAll: boolean = false;
  subLevel:number= 0;


  allFilters: any[] = [];
  topLevelFilters: any[] = [];

  categories: ProductCategory[];


  constructor(
    private store: Store<ASReducer.State>,
    private miyagiCategoryService: MiyagiCategoryService) {
    super()
  }

  ngOnInit() {
    /*
    this.miyagiCategoryService.getCategoryTree()
      .pipe(
        switchMap(categories => {
          this.categories = categories;
          return this.store.select(ASReducer.getASData);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((asData) => {

          let ok = asData.resultsLoaded &&
            asData.results.aggregations &&
            asData.results.aggregations[this.filter_name] &&
            asData.results.aggregations[this.filter_name].buckets;

          if (asData.resultsLoaded && ok) {

            this.selected = null;
            this.asDataCats = asData.results.aggregations[this.filter_name].buckets as FilterBucket[];

            if (asData.resultsLoaded && asData.results && asData.results.meta.params.product_category) {
              this.selected = asData.results.meta.params.product_category[0];
            }
            this.allFilters = this.recursiveFlatten(this.categories);
            this.topLevelFilters = this.allFilters.filter(filter => filter.level === 0)

            this.selectedFilters = this.allFilters.filter(bucket => bucket.selected);
            this.showAll = this.topLevelFilters.length > 5;

            // if(this.topLevelFilters.length > 5){
            //   this.topLevelFilters = this.topLevelFilters.slice(0, 5);
            // }
            if (this.selectedFilters.length) {
              this.showAll = true;
            }else  if(this.topLevelFilters < this.allFilters){
              this.showAll = true;
            }
          }

          if(asData.searchType !=='SERVICE'){
            this.isVisible = asData.visibleFilters[this.filter_name];
          }

      },
      e => this.error = e
    );
    */
  }

  recursiveFlatten(categories: any[], depth: number=0): any[] {
    const cats: any[] = [];
    if (categories.length) {

      // only care about categories that are actually in the current results.
      const relevantCats = categories.filter(cat => {
        return this.asDataCats.some(asDataCat => asDataCat.key === cat.category_name);
      });
      //there are children here.
      relevantCats.forEach(cat => {
        if (this.asDataCats && this.asDataCats.find(asDataCat => asDataCat.key === cat.category_name)) {
          cat = {
            ...cat,
            ...this.asDataCats.find(asDataCat => asDataCat.key === cat.category_name)
          }
        }
        cat.level = depth;
        cats.push(cat);
        if (cat.children) {
          cats.push(...this.recursiveFlatten(cat.children, depth + 10))
        }
      })
    }
    cats.sort((a,b) => b.doc_count - a.doc_count);
    return cats;
  }
  toggle(evt, filter) {
    let newFilters: string[] = this.selectedFilters.map(bucket => bucket.key);

    if (evt.target.checked) {
      newFilters = [];
      newFilters.push(filter.key);
    } else {
      newFilters = newFilters.filter(item => item !== filter.key);
    }

    let newQuery = {};
    newQuery[this.filter_name] = newFilters;
    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }

  toArray(array) {
    let newArrayWithKey = [];

    array.forEach(element => {
      if (this.asDataCats && this.asDataCats.find(asDataCat => asDataCat.key === element.category_name)) {
          element.key = element.category_name
          element.doc_count = this.asDataCats.find(asDataCat => asDataCat.key === element.category_name).doc_count;
          newArrayWithKey.push(element)
        }
    });
    newArrayWithKey.sort((a,b) => b.doc_count - a.doc_count);

    return newArrayWithKey;
  }


  onShowAllClicked() {
    this.store.dispatch(new ASActions.AsFilterGroupShowAll({filter: this.filter_name, selectedFilters: this.selectedFilters, filterLabel: this.title}));
  }
}
