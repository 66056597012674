import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../../shared/logger';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import {environment} from '../../../../environments/environment';
import {TenantSearchService} from '../../../../libs/api/tenantSearch/tenantSearch.service';
import {MiServiceSearchResults} from '../../../../shared/models/SearchResults';
import {DetailHeaderNavData, HeaderNavSpecTypes} from '../../../../shared/ng-models/DetailHeaderNavData.interface';
import {combineLatest} from "rxjs";
import {Store} from "@ngrx/store";
import * as ASReducer from "../../../../shared/state/advanced-search/reducers";
import {FILTER_GROUP_MODAL_ID} from "../../../../shared/ng-models/FilterGroupModalId";
import { TermsAndConditions } from '../../../../shared/models/TermsAndConditions';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';
import { ModalService } from '@independer/ng-modal';
import linkFormatter from 'src/common/utils/linkFormatter';

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  templateUrl: './service-term-instance-wrap.component.html'
})

export class ServiceTermInstanceWrapComponent extends AbstractPageComponent implements OnInit {

  serviceTerm: ServiceTerm;
  breadcrumbs: Link[];
  detailHeaderNavData: DetailHeaderNavData;
  display_id: string;
  serviceTermId: string;
  error: string = '';
  filterOverlayId: string = FILTER_GROUP_MODAL_ID;
  supportLink: string = linkFormatter.getSupportLink();
  loaded: boolean = false;
  title: string;

  NO_DATA: string = NO_DATA;

  constructor(private activatedRoute: ActivatedRoute,
              private serviceTermService: ServiceTermService,
              private tenantSearchService: TenantSearchService,
              private store: Store<ASReducer.State>,
              private modalService: ModalService) {
    super();
  }

  ngOnInit() {
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .switchMap(([params, queryParams]) => {
        this.loaded = false;
        this.serviceTermId = params.display_id;
        return this.serviceTermService.getServiceTermById(this.serviceTermId);
      })
      .switchMap((res: ServiceTerm) => {
        this.display_id = res.display_id;
        this.serviceTerm = res;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: `Terms And Condition ${res.display_id}`,
            url: `/vendor-account-ids/${this.serviceTermId}/details`,
            internal: true
          }
        ];

        // build a location typeSummary query

        this.detailHeaderNavData = <DetailHeaderNavData> {
          headline: this.serviceTerm.label,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: {display_id: this.serviceTerm.display_id}
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: 0
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: 0,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        // return this.loaded = true;
        return this.tenantSearchService.typeSummary({'service_term[]': this.serviceTerm.id});
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((serviceTermResults: MiServiceSearchResults) => {
        // // we can now find the correct bucket for this address
        let correctBucket = serviceTermResults.aggregations.service_term.buckets.find(bucket=> bucket.key === this.serviceTerm.id);
        let serviceCount = correctBucket ? correctBucket.doc_count : 0;

        this.detailHeaderNavData = <DetailHeaderNavData> {
          headline: this.serviceTerm.label,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: {display_id: this.serviceTerm.display_id}
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: serviceCount
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: serviceTermResults.aggregations.total_mrc.value || 0,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        this.loaded = true;
      });

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if(data.filterGroup){
            // Get the data and open the modal:
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.filterOverlayId;
            })
          }
        });
  }

}
