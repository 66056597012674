<form *ngIf="!loading && form !== null" [(formGroup)]="form" (ngSubmit)="onFormSubmit(form.value)">

  <mi-extended-input [(inputControl)]="form.controls['html']"
                     [(inputErrors)]="form.controls['html'].errors"
                     [(inputTouched)]="form.controls['html'].touched"
                     [errorDefs]='{}'>

    <angular-tinymce formControlName="html"></angular-tinymce>
  </mi-extended-input>
  <mi-server-error [error]="error"></mi-server-error>
  <div class="button-container">
    <button type="submit"
            mi-button
            [disabled]="!form.valid"
            buttonTheme="dark-theme">Add Note
    </button>
  </div>
</form>

