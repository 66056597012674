import { Directive, EventEmitter, Input, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[miSortableColumn]'
})

export class SortableColumn {
  @Input() public miSortableColumn: Boolean;
  @Input() public column: any;
  @Output() public sortChanged: EventEmitter<any> = new EventEmitter();

  @HostListener('click', ['$event'])
  public onToggleSort(event: any): void {
    if (event) {
      event.preventDefault();
    }

    if (this.miSortableColumn && this.column && this.column.sort !== false) {
      switch (this.column.sort) {
        case 'asc':
          this.column.sort = 'desc';
          break;
        case 'desc':
          this.column.sort = 'asc';
          break;
        default:
          this.column.sort = 'asc';
          break;
      }

      this.sortChanged.emit({
        column: this.column,
        sort: this.column.sort
      });
    }
  }
}
