<ng-container *ngIf="parentServiceState; else loading">
  <mi-server-error [error]="error"></mi-server-error>
  <ng-container *ngIf="activeIndex; else static">

    <h2>Choose a product</h2>

    <form *ngIf="componentForm"
          [formGroup]="componentForm"
          (submit)="onFormSubmit(componentForm.value)">

      <mi-product-search [vendor]="parentServiceState.vendor"
                         [locked]="true"
                         (selecteProduct)="onAddedSelected($event)"
                         [defaultSearchText]="defaultProductName"></mi-product-search>

      <div class="button-container">
        <a
          mi-button
          buttonTheme="light-theme"
          [routerLink]="cancelLink">
          Cancel
        </a>
        <button
          mi-button
          buttonTheme="dark-theme" [disabled]="!componentForm.valid">
          Next
        </button>
      </div>

    </form>

  </ng-container>

  <ng-template #static>
    <dl>
      <dt>Product</dt>
      <dd>
        <ng-container *ngIf="this.parentServiceState.product;else noData">
          {{this.parentServiceState.product.product_name}}
        </ng-container>
        <ng-template #noData>
          {{NO_DATA}}
        </ng-template>
      </dd>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


