import { Injectable } from '@angular/core';
import { Observable} from "rxjs";
import {of} from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import * as OrderServiceActions from './actions';
import {TenantOrderDraftsService} from "../../../../../../libs/api/tenant/tenantOrderDrafts.service";
import {OrderDraft} from "../../../../../../shared/models/OrderDraft";
import {Router} from "@angular/router";
import {createLogger, LOG_LEVELS} from "../../../../../../shared/logger";
import {TenantMiServiceService} from "../../../../../../libs/api/tenant/tenantMiService.service";

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable() export class ContainedStoreEffects {

  @Effect()
  saveOrder$: Observable<Action> = this.actions$.ofType(OrderServiceActions.ActionTypes.SAVE_DRAFT)
    .mergeMap( (action: OrderServiceActions.SaveDraftAction) => {
      if( (action.payload.data as OrderDraft)._id ) {
        return this.tenantOrderDraftsService.updateOrderDraft( action.payload.data as OrderDraft)
          .map( data => this.saveOrUpdateHandler(data, action))
          .catch((error) => this.saveOrUpdateErrorHandler(error))
      } else {
      return this.tenantOrderDraftsService.createOrderDraft( action.payload.data as OrderDraft)
        .map( data => this.saveOrUpdateHandler(data, action))
        .catch((error) => this.saveOrUpdateErrorHandler(error))
      }
    });
  @Effect()
  saveService: Observable<Action> = this.actions$.ofType(OrderServiceActions.ActionTypes.SERVICE_CREATE)
    .mergeMap( (action: OrderServiceActions.LoadDraftAction) => {
      //debugger
      return this.tenantOrderDraftsService.updateOrderDraft( action.payload )
        .mergeMap( data => {
          //log('data here', data);
          return this.tenantMiServiceService.createMiServiceFromOrderDraft(action.payload._id)
            .map( miServiceData => {
              //log("created service", miServiceData)
              this.router.navigate([`/services/${miServiceData.display_id}`]);
              return( new OrderServiceActions.ServiceCreateSuccess({mi_service: miServiceData}) )
            })
        })
        .catch((error) => this.saveOrUpdateErrorHandler(error));
    });
  @Effect()
  loadOrder$: Observable<Action> = this.actions$.ofType(OrderServiceActions.ActionTypes.LOAD_DRAFT)
    .mergeMap( (action: OrderServiceActions.LoadDraftAction) => {
      return this.tenantOrderDraftsService.getOrderDraft( action.payload.id )
        .map( data => {
          return( new OrderServiceActions.LoadDraftSuccessAction({order_draft: data}) )
        })
        .catch((error) => of( new OrderServiceActions.LoadDraftErrorAction({error})));
    });

  saveOrUpdateHandler(data:OrderDraft, action):OrderServiceActions.Actions {
    this.router.navigate([`/services/single/create/${data._id}/${action.payload.data.activeStepPath}`]);
    return( new OrderServiceActions.SaveDraftSuccessAction({order_draft: data}));
  }

  saveOrUpdateErrorHandler(error):Observable<OrderServiceActions.Actions> {
    return of( new OrderServiceActions.SaveDraftErrorAction({error}));
  }

  constructor(
    private tenantOrderDraftsService: TenantOrderDraftsService,
    private tenantMiServiceService: TenantMiServiceService,
    private router: Router,
    private actions$: Actions
  ) {}
}

