import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import * as OrderReducers from '../../state/reducer';
import {OrderState, OrderSteps} from '../../state/reducer';
import {ActivatedRoute, Router} from '@angular/router';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {UpdateOrderAction} from '../../state/actions';
import {OrderProgression} from '../../state/reducer';

@Component({
  selector: 'mi-service-order-contacts',
  styleUrls: ['../service-segments.scss'],
  templateUrl: './service-order-contacts.component.html'
})
export class ServiceOrderContactsComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  orderState: OrderState;
  readonly orderSteps: any = OrderSteps;
  step: string = OrderReducers.OrderSteps.Contacts;

  pageYOffset: number = 0;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<OrderState>
  ) {
    super();
  }

  ngOnInit() {

    const path = this.activatedRoute.snapshot.routeConfig.path;

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state as OrderState;
          // Find the index belonging to the state's current step:
          this.index = OrderProgression.findIndex((a) => {
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });
  }

  onContactsSubmit(data) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction({
      index: maxIndex,
      maxIndex: maxIndex,
      ...data
    }));

  }

}
