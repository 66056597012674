<ng-container *ngIf="orderState;else loading">

  <ng-container *ngIf="activeIndex; else static">
    <form [formGroup]="orderIdForm"
          (submit)="onFormSubmit(orderIdForm.value)">

      <mi-extended-input
        labelText="Enter Order ID"
        hint="If you do not have an Order ID, please skip this step."
        [(inputControl)]="orderIdForm.controls['order_id']"
        [(inputErrors)]="orderIdForm.controls['order_id'].errors"
        [(inputTouched)]="orderIdForm.controls['order_id'].touched"
        [errorDefs]='{required: "Please enter your Order ID.", pattern: "Special Characters are not Allowed"}'>
        <input type="text"
               class="form-control"
               formControlName="order_id"
               placeholder="ex. 10001"
               mi-restrict-input miRestrictType="OrderId"/>
      </mi-extended-input>

      <div class="button-container">
        <button mi-button
                buttonTheme="dark-theme"
                type="submit"
                [disabled]="!orderIdForm.valid">
          Next
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template #static>
    <dl>
      <dt>Order ID</dt>
      <dd>{{orderState.order_id || NO_DATA}}</dd>
    </dl>
  </ng-template>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
