
  <mi-server-error [error]="loadError"></mi-server-error>
  <ng-container *ngIf="loaded; else loading">

    <ng-container *ngIf="allDocs.length; else noDocuments">
        <dl class="active">
          <dt>
            Documents
          </dt>
          <dd>

          <table class="standard">
            <tr>
              <th>MISO ID</th>
              <th>Document Name</th>
              <th>File Name</th>
              <th>File Type</th>
              <th>File Size</th>
              <th>File Uploaded</th>
              <th>Division/Team</th>
            </tr>
            <ng-container *ngFor="let document of allDocs">
              <tr data-id="{{document.id}}" class="selectable" (click)="onDocumentSelect(document)">
                <td class="label" align="left">
                  <div class="centered">
                    <input type="checkbox"
                           value="{{document}}"
                           [checked]="isSelectedDoc(document)"/>
                    <mi-id-link id="{{document.id}}" display_id="{{document.display_id}}"></mi-id-link>       
                  </div>
                </td>
                <td align="left">
                    {{document.name}}
                </td>
                <td align="left">
                    <a *ngIf="document.is_v1"href="{{getV1FileUrl(document)}}" target="_blank">{{ shortenFileName(document.file_name) }}</a>
                    <a *ngIf="!document.is_v1&&!document.is_url" (click)="downloadDocument(document.id)">{{ shortenFileName(document.file_name) }}</a>
                    <a *ngIf="!document.is_v1&&document.is_url" [href]="document.file_name" target="_blank">{{ shortenFileName(document.file_name) }}</a>
                </td>
                <td align="left">
                    {{ 'UPLOAD_DOC_TYPES.' + DOC_TYPES[document.type] | translate }}
                </td>
                <td align="right">
                    {{document.file_size | filesize}}
                </td>
                <td align="right">
                    {{convertTimeZone(document.created_on_utc) | date:'short' }}
                </td>
                <td align="left">
                  {{ document.business_unit_name }}
                </td>
              </tr>
            </ng-container>
          </table>

        </dd>
      </dl>
    </ng-container>

    <ng-template #noDocuments>
      <p>There are no saved documents for {{vendor.vendor_name}}. Upload a new document or link below.</p>
    </ng-template>

  </ng-container>
  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

<ng-container>

  <div class="button-container">
    <a mi-button 
      buttonTheme="light-theme" 
      class="button-alternate"
      (click)="this.uploadDocument()">
      Upload a Document
    </a>

    <ng-content>
    </ng-content>
    <button mi-button
            type="submit"
            buttonTheme="dark-theme"
            (click)="onContinueClick($event)">
      Apply
    </button>

  </div>
</ng-container>


