import {
  Component,
  OnInit,
  Input} from '@angular/core';
import { AbstractPageComponent } from '../../../pages/abstract-page/abstract-page.component';
import { ProductCategoryFieldProperty } from '../../../models/ProductCategoryFieldProperty';

@Component({
  selector: '[mi-product-field-static-item]',
  templateUrl: './product-field-static-item.component.html',
  styleUrls: ['./product-field-static-item.component.scss']
})

export class ProductFieldStatisItemComponent extends AbstractPageComponent implements OnInit {

  @Input() productField: ProductCategoryFieldProperty;
  @Input() horizontal: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
