import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiServiceSearchResults, SearchResults} from "../../../shared/models/SearchResults";
import {MiServiceSearchQueryParams} from "../../../shared/ng-models/search/SearchQueryParams.interface";

const log = createLogger(LOG_LEVELS.HTTP_API);


@Injectable()
export class TenantSearchService {

  private apiUrlSearchTenantMiService = '/{tenant}/search/tenant-mi-services';
  // private apiUrlExportTenantMiService = '/{tenant}/export/tenant-mi-services';
  private apiUrlSearchTenantMiServiceGroup = '/{tenant}/search/tenant-mi-service-groups';
  private apiUrlSearchTenantEvent = '/{tenant}/search/tenant-events';
  private apiUrlDashboard = '/{tenant}/search/dashboard';
  private apiUrlTypeSummary = '/{tenant}/search/type-summary';
  private apiUrlAllOfFilter = '/{tenant}/search/all-of-filter';
  private apiUrlAllOfEventFilter = '/{tenant}/search/all-of-event-filter';
  private apiUrlAllOfAddressFilter = '/{tenant}/search/all-of-address-filter';
  private apiUrlAllOfEquipmentFilter = '/{tenant}/search/all-of-equipment-filter';
  private apiUrlAllOfContactFilter = '/{tenant}/search/all-of-contact-filter';
  private apiUrlSearchTenantEquipment = '/{tenant}/search/equipments';
  private apiUrlSavingsSummary = '/{tenant}/search/savings-summary';
  private apirUrlSearchAllVendors = '/{tenant}/search/allVendors';
  private apirUrlSearchTanentVendors = '/{tenant}/search/tenantVendors';
  private apiUrlSerachAllEvents = '/{tenant}/search/all-events';
  private apiUrlAdvancedSerachContacts = '/{tenant}/search/advanced-contacts';
  private apiUrlAdvancedSerachTenantUsers = '/{tenant}/search/tenant-users';
  private apiUrlAdvancedSerachAddress = '/{tenant}/search/advanced-addresses';
  private apiUrlAdvancedSerachDocuments = '/{tenant}/search/documents';
  private apiUrlAdvancedSerachAccountIds = '/{tenant}/search/account-ids';
  private apiUrlAdvancedSerachProducts = '/{tenant}/search/advanced-products';

  constructor(private http: HttpClient) {
  }

  getQueryParams(queryParams: MiServiceSearchQueryParams): HttpParams {
    return new HttpParams({fromObject: queryParams as any});
  }

  searchDocument(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlAdvancedSerachDocuments, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  // searchAccountIdRohit(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
  //   return this.http
  //     .get(this.apiUrlAdvancedSerachAccountIds, {params: this.getQueryParams(queryParams)})
  //     .map((value: any) => value.data);
  // }

  searchProduct(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlAdvancedSerachProducts, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }


  searchTanentContact(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlAdvancedSerachContacts, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  searchTanentUser(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlAdvancedSerachTenantUsers, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  searchTanentAddress(queryParams: MiServiceSearchQueryParams = {}): Observable<SearchResults> {
    return this.http
      .get(this.apiUrlAdvancedSerachAddress, {params: this.getQueryParams(queryParams)})
      .map( (value: any) => {
        return value.data as SearchResults;
      });
  }
  searchAllVendors(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apirUrlSearchAllVendors, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }



  /*
  searchTanentVendors(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apirUrlSearchTanentVendors, {params: this.getQueryParams(queryParams)})
      .map((value: any) =>  value.data);
  }
  
  searchAllVendors(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    console.log('ggg');
    console.log(this.getQueryParams(queryParams));
    return this.http
      .get(`${this.accountHost}/tenants/_TENANT_ID_/search`)
      .map((response: any) => {
        console.log('here2?');
        return response;
      });
  }
  */
  private accountHost = '_MICRO_SERVICE_/vendor';
  searchTanentVendors1(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    console.log(this.getQueryParams(queryParams));
    return this.http
      .get(`${this.accountHost}/tenants/_TENANT_ID_/search?size=100`, { params: this.getQueryParams(queryParams) })
      .map((response: any) => {
        return response;
      });
  }

  searchTanentVendors(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apirUrlSearchTanentVendors, { params: this.getQueryParams(queryParams) })
      .map((value: any) => value.data);
  }

  searchTenantEquipment(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlSearchTenantEquipment, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  searchTenantMiServices(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlSearchTenantMiService, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  // exportTenantMiServices(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
  //   return this.http
  //     .get(this.apiUrlExportTenantMiService, {params: this.getQueryParams(queryParams)})
  //     .map((value: any) => value.data);
  // }

  searchTenantMiServiceGroups(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlSearchTenantMiServiceGroup, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  searchTenantEvents(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlSearchTenantEvent, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  searchDashboard(): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlDashboard)
      .map((value: any) => value.data);
  }

  searchAllEvents(queryParams: any): Observable<any> {
    return this.http
      .get(this.apiUrlSerachAllEvents, {params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  savingsSummary(): Observable<any> {
    return this.http
      .get(this.apiUrlSavingsSummary)
      .map((value: any) => value.data);
  }

  typeSummary(queryParams: any = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlTypeSummary,{params: this.getQueryParams(queryParams)})
      .map((value: any) => value.data);
  }

  allOfFilterByName(filterName: string = ''): Observable<MiServiceSearchResults> {
    let params = new HttpParams({fromString: `filter_type=${filterName}`});
    return this.http
      .get(this.apiUrlAllOfFilter, {params})
      .map((value: any) => value.data);
  }

  allOfContactFilterByName(filterName: string = ''): Observable<MiServiceSearchResults> {
    let params = new HttpParams({fromString: `filter_type=${filterName}`});
    return this.http
      .get(this.apiUrlAllOfContactFilter, {params})
      .map((value: any) => value.data);
  }

  allOfAddressFilterByName(filterName: string = ''): Observable<MiServiceSearchResults> {
    let params = new HttpParams({fromString: `filter_type=${filterName}`});
    return this.http
      .get(this.apiUrlAllOfAddressFilter, {params})
      .map((value: any) => value.data);
  }
  allOfEventFilterByName(filterName: string = '', time_range: string): Observable<MiServiceSearchResults> {
    let params = new HttpParams({fromString: `filter_type=${filterName}&time_range=${time_range}`});
    return this.http
      .get(this.apiUrlAllOfEventFilter, {params})
      .map((value: any) => value.data);
  }

  allOfEquipmentFilterByName(filterName: string = ''): Observable<MiServiceSearchResults> {
    let params = new HttpParams({fromString: `filter_type=${filterName}`});
    return this.http
      .get(this.apiUrlAllOfEquipmentFilter, {params})
      .map((value: any) => value.data);
  }
}
