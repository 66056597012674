import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {AddressSearchResults} from '../../../shared/models/SearchResults';
import {Address} from '../../../shared/models/Address';
import {CanDelete} from '../../../shared/models/CanDelete';

const log = createLogger(LOG_LEVELS.HTTP_API);


@Injectable()
export class TenantAddressService {

  private apiUrlGet = '/{tenant}/addresses';
  private apiUrlGetByDisplayId = '/{tenant}/addresses/display-id';
  private apiUrlCreate = '/{tenant}/addresses/';
  private apiUrlCreateNormalized = '/{tenant}/addresses/create-google-normalized';

  constructor(private http: HttpClient) {
  }

  createAddress(address: Address): Observable<Address> {
    return this.http
      .post(this.apiUrlCreate, address)
      .map((response: any) => response.data.address as Address);
  }

  getAddressByDisplayId(display_id: string): Observable<Address> {
    return this.http
      .get(`${this.apiUrlGetByDisplayId}/${display_id}`)
      .map((response: any) => response.data.address as Address);
  }

  getAddressById(id: string): Observable<Address> {
    return this.http
      .get(`${this.apiUrlGet}/${id}`)
      .map((response: any) => response.data.address as Address);
  }

  createGoogleNormalized(placeId: string): Observable<Address> {
    return this.http
      .post(`${this.apiUrlCreateNormalized}/${placeId}`, {})
      .map((response: any) => response.data.address as Address);
  }

  updateAddress(address: Address): Observable<Address> {
    return this.http
      .put(`${this.apiUrlGet}/${address._id}`, address)
      .map((response: any) => response.data.address as Address);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlGet}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteAddress(id: String): Observable<Address> {
    return this.http
      .delete(`${this.apiUrlGet}/${id}`)
      .map((response: any) => response.data.location as Address);
  }

}
