<!-- <div [ngClass]="{primary: type === 'primary', empty: searchString.length === 0}"> -->
<div [ngClass]="{primary: type === 'primary', empty: !_lastValue}">
  <form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit(searchForm.value)">
    <div class="form-group">
      <input type="text"
             class="form-control"
             placeholder="{{placeholder}}"
             formControlName="search">
      <div class="search__qty-message" *ngIf="qtyMessage">{{qtyMessage}}</div>
      <!-- <div class="search--clear" (click)="clearSearchEvent()" *ngIf="searchString.length > 0">Clear</div> -->
      <div class="search--clear" (click)="clearSearchEvent()" *ngIf="_lastValue.length > 0">Clear</div>
      <button class="search--submit"
              type="submit"
              [disabled]="!searchForm.valid">
        <div class="submit--label">Search</div>
      </button>
    </div>
  </form>
</div>
