import {Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef} from '@angular/core';
import * as ParentServicesReducer from '../state/reducers';
import * as AlertReducer from "../../../../../shared/state/alerts/reducers";
import {NO_DATA} from '../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {combineLatest, Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {MiServiceGroup} from 'src/shared/models/MiServiceGroup';
import {SectionDataTableColumn} from 'src/shared/ng-models/SectionDataColumn.interface';
import {SortableTemplatesEnum} from 'src/shared/ng-models/SortableTableColumn.interface';
import {TenantMiParentServiceService} from 'src/libs/api/tenant/tenantMiParentService.service';
import {MiParentService} from 'src/shared/models/MiParentService';
import { ALERT_STATUSES } from '../../../../../common/utils/sharedConstants';
import { takeUntil } from 'rxjs/operators';


@Component(
  {
    selector: 'mi-parent-subsection-service-group',
    templateUrl: './subsection-service-group-details.component.html'
  }
)

export class ParentSubsectionServiceGroupDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiParentService> = new EventEmitter<MiParentService>();
  @ViewChild('alertStatusTmpl', {static: true}) alertStatusTmpl: TemplateRef<any>;


  error: any = '';
  saveError: any;

  isOpen: boolean = false;
  parentService: MiParentService;
  pageLoaded$: Observable<boolean>;
  serviceGroups: MiServiceGroup[];
  serviceGroupsLoaded: boolean = false;
  serviceGroupColumns: SectionDataTableColumn[] = [];

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private store: Store<ParentServicesReducer.State>,
    private parentServiceService: TenantMiParentServiceService) {
    super();
  }

  ngOnInit(): void {

    this.createServiceGroupColumns();
    this.pageLoaded$ = this.store.select(ParentServicesReducer.ParentServicePageLoaded);

    this.store
      .select(ParentServicesReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentService) {
            this.parentService = data.parentService;
            this.updateFromContent(data.parentService);
          }
        }
      );
  }
  createServiceGroupColumns() {

    this.serviceGroupColumns = [
      {
        name: 'MISO ID',
        prop: 'display_id',
        cellTemplate: SortableTemplatesEnum.idLink,
        sortable: false
      },
      {
        name: 'Group Name',
        prop: 'name',
        sortable: false
      },
      {
        name: 'Status',
        prop: 'status',
        cellTemplate: this.alertStatusTmpl,
        sortable: false
      },
      {
        name: 'Services',
        prop: 'service_count',
        sortable: false
      },
      {
        name: 'Spend (CMRC)',
        prop: 'mrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      }];
  }

  onServiceGroupComplete(serviceGroups: MiServiceGroup[]): void {
    this.saveError = false;
    let serviceGroupsIds = serviceGroups.map(serviceGroup => serviceGroup._id);
    this.parentServiceService
      .updateParentService(this.parentService._id, {
        assigned_email: this.parentService.assigned_user.email,
        service_groups: serviceGroupsIds
      })
      .toPromise()
      .then(res => {
        this.parentService = res;
        // this.updateService();
        this.dataUpdate.emit(res);
        this.onToggleExpand();
      })
      .catch(e => this.saveError = e);
  }

  updateFromContent(res: MiParentService) {

    this.parentService = res;

    const getServiceGroups$ = this.parentServiceService.getServiceGroups(this.parentService._id, this.parentService.service_groups);
    const getAlerts$ = this.store.pipe(select(AlertReducer.getAlertsList));

    const combinedServiceGroups$ = combineLatest(getServiceGroups$, getAlerts$)
      .map(([mi_service_groups, alerts]) => {
        return mi_service_groups.map((mi_service_group) => {
          const sgEffected = Object.keys(alerts.effected).some(id => id === mi_service_group.display_id);
          let alert_status = sgEffected? ALERT_STATUSES.ALERT_STATUS_BROKEN : ALERT_STATUSES.ALERT_STATUS_NONE;
          return {
            ...mi_service_group,
            alert_status: alert_status,
            service_count: (mi_service_group.services || []).length
            + (mi_service_group.parent_services || []).length
          };
        })
      }
    );

    combinedServiceGroups$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        serveGroups=> {
          this.serviceGroups = serveGroups;
          this.serviceGroupsLoaded = true;
        },
        (e)=> {
          this.error = e;
          this.serviceGroupsLoaded = true;
        });
  }

  onServiceGroupCanceled(event): void {
    this.isOpen = false;
  }


  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

}
