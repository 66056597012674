import { Action } from '@ngrx/store';

export const ActionTypes = {
  SET_RESULTS: '[Miso: ServiceV2] SET_RESULTS',
  GET_DATA: '[Miso: ServiceV2] GET_DATA',
  SEARCH_LOADING: '[Miso: ServiceV2] SEARCH_LOADING',
  SEARCH_LOADED: '[Miso: ServiceV2] SEARCH_LOADED',
  SET_LOAD_MORE: '[Miso: ServiceV2] SET_LOAD_MORE'
};


export class SetServiceResults implements Action {
  type = ActionTypes.SET_RESULTS;

  constructor(public payload) { }
}

export class GetServiceData implements Action {
  type = ActionTypes.GET_DATA;

  constructor(public payload) { }
}

export class SetSearchLoading implements Action {
  type = ActionTypes.SEARCH_LOADING;

  constructor(public payload) { }
}

export class SetSearchLoaded implements Action {
  type = ActionTypes.SEARCH_LOADED;

  constructor(public payload) { }
}

export class SetLoadMoreStatus implements Action {
  type = ActionTypes.SET_LOAD_MORE;

  constructor(public payload) { }
}

export type Actions
  = SetServiceResults
  | GetServiceData
  | SetSearchLoading
  | SetSearchLoaded
