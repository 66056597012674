import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateServiceTagRequest, CreateTagDefinitionRequest, CreateTagOptionRequest, ModifyServiceTagRequest, ModifyTagDefinitionRequest, ModifyTagOptionRequest, ServiceTag, TagDefinition, TagOption } from "src/shared/models/Tags";

@Injectable()
export class TagsService {
  private apiUrlTagDefinition = '_MICRO_SERVICE_/tags/tag-definition';
  private apiUrlTagOptionValues = '_MICRO_SERVICE_/tags/tag-option-values';
  private apiUrlMisoServiceTags = "_MICRO_SERVICE_/tags/miso-service-tags";

  constructor(private http: HttpClient) {}

  //TAG DEFS

  getTags(tenantId: string): Observable<TagDefinition[]> {
    return this.http
      .get(`${this.apiUrlTagDefinition}/get?tenantId=${tenantId}`)
      .map((response: any) => {
        return response as TagDefinition[];
      });
  }

  getTagsAdmin(tenantId: string): Observable<TagDefinition[]> {
    return this.http
      .get(`${this.apiUrlTagDefinition}/get?tenantId=${tenantId}&admin=true`)
      .map((response: any) => {
        return response as TagDefinition[];
      });
  }

  createTag(request: CreateTagDefinitionRequest) {
    return this.http
      .post(`${this.apiUrlTagDefinition}/create`, request)
      .map((response: any) => {
        return response;
      });
  }

  renameTag(request: ModifyTagDefinitionRequest) {
    return this.http
    .post(`${this.apiUrlTagDefinition}/rename`, request)
    .map((response: any) => {
      return response;
    });
  }

  disableTag(request: ModifyTagDefinitionRequest) {
    return this.http
    .post(`${this.apiUrlTagDefinition}/disable`, request)
    .map((response: any) => {
      return response;
    });
  }

  enableTag(request: ModifyTagDefinitionRequest) {
    return this.http
    .post(`${this.apiUrlTagDefinition}/enable`, request)
    .map((response: any) => {
      return response;
    });
  }

  deleteTag(request: ModifyTagDefinitionRequest) {
    return this.http
    .post(`${this.apiUrlTagDefinition}/delete`, request)
    .map((response: any) => {
      return response;
    });
  }

  //TAG OPTS

  getTagOptions(tagId: string): Observable<TagOption[]> {
    return this.http
      .get(`${this.apiUrlTagOptionValues}/get?tagId=${tagId}`)
      .map((response: any) => {
        return response as TagOption[];
      });
  }

  getTagOptionsAdmin(tagId: string): Observable<TagOption[]> {
    return this.http
      .get(`${this.apiUrlTagOptionValues}/get?tagId=${tagId}&admin=true`)
      .map((response: any) => {
        return response as TagOption[];
      });
  }

  createTagOption(request: CreateTagOptionRequest) {
    return this.http
      .post(`${this.apiUrlTagOptionValues}/create`, request)
      .map((response: any) => {
        return response;
      });
  }

  renameTagOption(request: ModifyTagOptionRequest) {
    return this.http
      .post(`${this.apiUrlTagOptionValues}/rename`, request)
      .map((response: any) => {
        return response;
      });
  }

  disableTagOption(request: ModifyTagOptionRequest) {
    return this.http
      .post(`${this.apiUrlTagOptionValues}/disable`, request)
      .map((response: any) => {
        return response;
      });
  }

  enableTagOption(request: ModifyTagOptionRequest) {
    return this.http
      .post(`${this.apiUrlTagOptionValues}/enable`, request)
      .map((response: any) => {
        return response;
      });
  }

  deleteTagOption(request: ModifyTagOptionRequest) {
    return this.http
      .post(`${this.apiUrlTagOptionValues}/delete`, request)
      .map((response: any) => {
        return response;
      });
  }

  //SERVICE TAGS

  getMisoServiceTags(misoServiceId: string): Observable<ServiceTag[]> {
    return this.http
      .get(`${this.apiUrlMisoServiceTags}/get?misoServiceId=${misoServiceId}`)
      .map((response: any) => {
        return response as ServiceTag[];
      });
  }

  createMisoServiceTag(request: CreateServiceTagRequest) {
    return this.http
      .post(`${this.apiUrlMisoServiceTags}/create`, request)
      .map((response: any) => {
        return response;
      });
  }

  renameMisoServiceTag(request: ModifyServiceTagRequest) {
    return this.http
      .post(`${this.apiUrlMisoServiceTags}/rename`, request)
      .map((response: any) => {
        return response;
      });
  }

  deleteMisoServiceTag(request: ModifyServiceTagRequest) {
    return this.http
      .post(`${this.apiUrlMisoServiceTags}/delete`, request)
      .map((response: any) => {
        return response;
      });
  }
}