export interface ServiceV2Locations {
  country: string[];
  state: string[];
  city: string[];
}

export interface ServiceV2Filter {
  field_name: string;
  type: string;
  is_custom_field: boolean;
  is_tag: boolean;
  is_location: boolean;
  locations?: ServiceV2Locations;
  range_min?: number;
  range_max?: number;
  date_range_min?: string;
  date_range_max?: string;
  values?: string[];
  removable?: boolean;
}

export interface ServiceV2ResponsePartGeneral {
  parent_service_group_name: string;
  pcmrc: number;
  service_display_id: string;
}

export interface ServiceV2ResponsePartVendor {
  purchasing_vendor_name: string;
  purchasing_vendor_id: string;
  account_id_label: string;
  manufacturing_vendor_name: string;
  order_date: string;
  order_number: string;
}

export interface ServiceV2ResponsePartServiceData {
  product_name: string;
  product_category_name: string;
  service_id: string;
  division_name: string;
  team: string;
  service_group: string;
  quantity: string;
  unit_recurring_cost: number;
  billing_frequency: number;
  currency: string;
  unit_non_recurring_cost: number;
  cmrc: number;
  service_owner_fname: string;
  service_owner_lname: string;
  service_owner_email: string;
  subscriber_owner_fname: string;
  subscriber_owner_lname: string;
  subscriber_email: string;
  m3_owner_fname: string;
  m3_owner_lname: string;
  m3_owner_email: string;
  validation_flag: string;
  days_since_last_update: number;
  service_identifier: string
}

export interface ServiceV2ResponsePartEventsAndServiceStatus {
  next_event: string;
  event_date: string;
  days_to_event: number;
  next_event_value: number;
  number_of_auto_renew: number;
  data_integrity_status: string;
  service_status: string;
}

export interface ServiceV2ResponsePartDatesAndLocations {
  initial_start_date: string;
  current_term: number;
  end_of_term: string;
  location_a_name: string;
  location_b_name: string;
}

export interface ServiceV2ResponsePartCustomFields {
  
}

export interface ServiceV2ResponsePartCustomFieldIds {

}

export interface ServiceV2Sort {
  field_name?: string;
  sort_order?: string;
}

export interface ServiceV2CustomFieldsResponse {
  id: string;
  value: string;
}

export interface TagResponse {
  tag_id: string;
  tag_value: string;
}

export interface ServiceV2ResponseData {
  miso_service: {
    general: ServiceV2ResponsePartGeneral;
    vendor: ServiceV2ResponsePartVendor;
    service_data: ServiceV2ResponsePartServiceData;
    events_and_service_status: ServiceV2ResponsePartEventsAndServiceStatus;
    dates_and_locations: ServiceV2ResponsePartDatesAndLocations;
    custom_fields: ServiceV2CustomFieldsResponse[];
    custom_field_ids: string[];
    tags: TagResponse[];
  }
}

export interface ServiceV2Response {
  moreAvailable: boolean;
  pageNumber: number;
  pageSize: number;
  total: number;
  total_mrc: number;
  tenantId: string;
  search: string;
  sort: ServiceV2Sort;
  filters: ServiceV2Filter[];
  data: ServiceV2ResponseData[];
  cursor?: number;
}

export interface ServiceV2Request {
  pageSize: number;
  page_number: number;
  tenant_id: string;
  search: string;
  sort: ServiceV2Sort;
  filters: ServiceV2Filter[];
  cursor?: number;
  columns?: string[];
}

export interface ServiceV2FilterRequestCurrentFilter {
  field: string;
  is_custom_field: boolean;
  is_tag: boolean;
}

export interface ServiceV2FilterRequest {
  current_filter: ServiceV2FilterRequestCurrentFilter;
  existing_filters: ServiceV2Filter[];
  search: string;
  tenant_id: string;
}

export interface ServiceV2FilterResponseOptions {
  label: string;
  count: number;
  selected?: boolean;
}

export interface ServiceV2FilterResponse {
  field: string;
  options: ServiceV2FilterResponseOptions[];
}

export interface ServiceV2LocationFilterResponseDisplay {
  field: string;
  options: ServiceV2LocationFilterResponseItem[];
}

export interface SearchFilterV2 {
  filterName: string;
  filterLabel?: string;
  value: any;
  field_name: string;
  field_type: string;
  location_type?: string;
  removable: boolean;
}

export interface ServiceV2LocationFilterResponseItem {
  label: string;
  count: number;
  selected?: boolean;
}

export interface ServiceV2LocationFilterResponse {
  country: ServiceV2LocationFilterResponseItem[];
  state: ServiceV2LocationFilterResponseItem[];
  city: ServiceV2LocationFilterResponseItem[];
}

export interface tenantBridgesResponse {
  id: string;
  vendorUrl: string;
  vendorName: string;
  bridgeId: string;
  vendorId: string;
}

export interface tenantBridgeCustomFieldsResponse {
  id: string;
  updatedByUserId: string;
  updatedOnUtc: string;
  customFieldLabel: string;
  customFieldType: string;
  bridgeId: string;
  vendorName?: string;
}

export const ServiceListingColumnsInitial = [
  "purchasing_vendor_name",
  "product_name",
  "account_id_label",
  "data_integrity_status",
  "end_of_term_action",
  "validation_flag",
  "unit_recurring_cost",
  "unit_non_recurring_cost",
  "cmrc",
  "in_term",
  "liability",
  "parent_service_group_name",
  "p_cmrc",
  "days_since_last_update",
  "latest_extraction_date",
  "currency",
  "billing_frequency",
  "location_a",
  "location_b",
  "display_id",
  "initial_start_date",
  "end_of_term_date",
  "current_term_length",
  "service_identifier",
  "termination_date",
  //"m3_owner_email",
  "service_owner_email",
  "subscriber_email",
  "mi_miso_user_email",
  "number_of_auto_renew",
  "quantity",
  "days_to_event",
  "product_category_name",
  "active_usage",
  "price_increase_percentage"
];

export const ServiceListingColumnsSecondary = [
  "manufacturing_vendor_name",
  "order_date",
  "order_number",
  "service_identifier",
  "quantity",
  "unit_non_recurring_cost",
  "event_date",
  "next_event",
  "next_event_value",
  "number_of_auto_renew",
  "service_status",
  //"m3_owner_email",
  //"service_owner_email",
  //"subscriber_email",
  "division_name",
  "team"
];

export const ServiceListingColumnsCustom = [
  "custom_fields",
  "tags"
];