import {Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import * as ParentServicesReducer from '../../state/reducers';
import {AbstractPageComponent} from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import {Observable} from 'rxjs';
import {MiParentService} from '../../../../../../shared/models/MiParentService';

@Component({
  selector: 'mi-parent-service-details-header-vendor',
  templateUrl: './parent-service-details-header-vendor.component.html',
  styleUrls: ['../parent-service-details-header-items.scss', './parent-service-details-header-vendor.component.scss'],
  preserveWhitespaces: true
})

export class ParentServiceDetailsHeaderVendorComponent extends AbstractPageComponent implements OnInit {

  productNameClasses: any;
  titleClasses: any;
  vendorNameClasses: any;

  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;

  vendorLogoUrl: string; //  = VENDOR_LOGO_PLACEHOLDER;
  showSubline: boolean = false;

// @Input() data: ServiceDetailsHeaderData;

  constructor(private store: Store<ParentServicesReducer.State>) {
    super();
  }

  ngOnInit() {
    this.pageLoaded$ = this.store.select(ParentServicesReducer.ParentServicePageLoaded);
    this.store
      .select(ParentServicesReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if(data.parentService){
            this.parentService = data.parentService;
            if(this.parentService.product.product_vendor.logo){
              this.vendorLogoUrl = this.parentService.product.product_vendor.logo.full_path || null;
            }
          }
        }
      );
  }
}
