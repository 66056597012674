<mi-page-header
  title="Create Terms & Conditions"
  [breadcrumbs]="breadcrumbs">
  <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<mi-tenant-terms-and-conditions-form
*ngIf="vendor"
[vendor]="vendor"
(termsAndConditionsCreated)="onTermsAndConditionsCreated($event)"
(canceled)="onTermsAndConditionsCancelled()">
</mi-tenant-terms-and-conditions-form>
