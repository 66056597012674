import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Contact} from '../../../shared/models/Contact';
import {ContactSearchResults} from '../../../shared/models/SearchResults';
import {CanDelete} from '../../../shared/models/CanDelete';
import {fixAngularArrayQuery} from '../../../shared/utils/fixAngularArrayQuery';
import {ContactSearchQueryParams} from '../../../shared/ng-models/search/SearchQueryParams.interface';

const log = createLogger(LOG_LEVELS.HTTP_API);


@Injectable()
export class TenantContactsService {

  private apiUrlCreateContact = '/{tenant}/contacts';
  private apiUrlGetContact = '/{tenant}/contacts';
  private apiUrlUpdateContact = '/{tenant}/contacts';
  private apirUrlSearchAllContacts = '/{tenant}/search/tenantContacts';
  private apiUrlDeleteContact = '/{tenant}/contacts/delete';

  constructor(private http: HttpClient) {
  }

  getAllContacts(): Observable<Contact[]> {
    return this.http
      .get(this.apiUrlGetContact)
      .map((response: any) => response.data.contacts as Contact[]);
  }

  getContact(displayId: String): Observable<Contact> {
    return this.http
      .get(`${this.apiUrlGetContact}/${displayId}`)
      .map((response: any) => response.data.contact as Contact);
  }

  getContactById(id: String): Observable<Contact> {
    return this.http
      .get(`${this.apiUrlGetContact}/by-id/${id}`)
      .map((response: any) => response.data.contact as Contact);
  }

  getContactByEmail(email: String): Observable<Contact> {
    return this.http
      .get(`${this.apiUrlGetContact}/by-email/${email}`)
      .map((response: any) => response.data.contact as Contact);
  }

  createContact(contact: Contact): Observable<Contact> {
    return this.http
      .post(this.apiUrlCreateContact, contact)
      .map((response: any) => response.data.contact as Contact);
  }

  updateContact(contact: Contact): Observable<Contact> {
    return this.http
      .put(`${this.apiUrlUpdateContact}/${contact.display_id}`, contact)
      .map((response: any) => response.data.contact as Contact);
  }

  searchTenantContacts(queryParams: ContactSearchQueryParams): Observable<ContactSearchResults> {
    return this.http
      .get(this.apirUrlSearchAllContacts, {params: fixAngularArrayQuery(queryParams)})
      .map((response: any) => response.data as ContactSearchResults);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlGetContact}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteContact(id: String): Observable<Contact> {
    return this.http
      .delete(`${this.apiUrlDeleteContact}/${id}`)
      .map((response: any) => response.data.Contact as Contact);
  }

}
