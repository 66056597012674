import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {ApiConnector} from 'src/shared/models/ApiConnectors';
import {DataConnector} from 'src/shared/models/DataConnector';

@Injectable()
export class TenantDataConnectorsService {

  private apiUrl = '/{tenant}/data-connectors';
  /*
  apiUrlGetAllConfigured = apiUrl/api_connector_id/manage';
  */
  constructor(private http: HttpClient) {

  }

  listAll(): Observable<Array<ApiConnector>> {
    return this.http
      .get(`${this.apiUrl}`)
      .map((response: any) => response.data.miyagiConnectors as Array<ApiConnector>);
  }

  listConfigured(api_connector_id): Observable<Array<DataConnector>> {
    return this.http
      .get(`${this.apiUrl}/${api_connector_id}/managed`)
      .map((response: any) => response.data.dataConnectors as Array<DataConnector>);
  }

  detail(data_connector_id: string): Observable<DataConnector> {
    return this.http
      .get(`${this.apiUrl}/${data_connector_id}`)
      .map((response: any) => response.data.dataConnector as DataConnector);
  }

  getDataConnector(data_connector_id: string): Observable<DataConnector> {
    return this.http
      .get(`${this.apiUrl}/${data_connector_id}`)
      .map((response: any) => response.data.dataConnector as DataConnector);
  }

  create(dataConnector: DataConnector): Observable<DataConnector> {
    return this.http
      .post(`${this.apiUrl}`, dataConnector)
      .map((response: any) => response.data.dataConnector as DataConnector);
  }

  update(dataConnector: DataConnector): Observable<DataConnector> {
    return this.http
      .put(`${this.apiUrl}/${dataConnector._id}`, dataConnector)
      .map((response: any) => response.data.dataConnector as DataConnector);
  }

}
