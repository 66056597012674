import {Component} from '@angular/core';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {OrderState} from '../../services/orders/create/state/reducer';

@Component({
  styleUrls: ['./upload-document.component.scss'],
  templateUrl: './upload-document.component.html'
})

export class UploadDocumentComponent {

  breadcrumbs: Link[] = [
    {
      url: '',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/data-import',
      label: 'Data Import',
      internal: true
    },
    {
      url: '/data-import/upload',
      label: 'Document Upload',
      internal: true
    }
  ];

  error: string;
  order: OrderState;

}
