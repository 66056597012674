
<mi-page-header
  title="{{ 'CONTACTS.CONTACT_DETAILS_TITLE' | translate:{contact_id:contact.display_id} }}"
  [breadcrumbs]="breadcrumbs"
  *ngIf="contact">
  <ng-container>
    <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
  </ng-container>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<div *ngIf="contact">
  <mi-contact-details-header-items
    [contact]="contact"
    [data]="detailHeaderNavData"
    >
  </mi-contact-details-header-items>
</div>

<mi-detail-alert [display_id]="display_id" [self_alert]="true"></mi-detail-alert>
<mi-detail-alert [display_id]="display_id"></mi-detail-alert>

<div class="sections reverse">
<mi-tabs-container [wrap]="true" [full]="true" externalClasses="detail-page-wide-tab">
  <mi-tab tabTitle="Services" route="services" [withPane]="false"></mi-tab>
  <!-- <mi-tab tabTitle="Locations" route="locations" [withPane]="false"></mi-tab> -->
  <mi-tab tabTitle="Events" route="events" [withPane]="false"></mi-tab>
  <!-- <mi-tab tabTitle="Equipment" route="equipment" [withPane]="false"></mi-tab> -->
</mi-tabs-container>
</div>

<br/>

<div class="tab-details-container">
  <router-outlet></router-outlet>
</div>
