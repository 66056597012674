<div>

  <mi-page-header
    title="{{ 'TENANT.EMAIL_DETAILS_TITLE' | translate }}"
    [breadcrumbs]="breadcrumbs"
    [links]="headerLinks">
  </mi-page-header> 
  <p *ngIf="loaded && email.service_display_ids && email.service_display_ids.length"><strong>Email Regarding: </strong> 
    <a *ngIf="email.service_display_ids.length>5 && !showServiceIds; else showDisplayIds" (click)="showServices(true)">
      {{email.service_display_ids.length}} Services
    </a>
    <ng-template #showDisplayIds>
      <span *ngFor= "let service of email.service_display_ids">
        <mi-id-link id="{{service}}"></mi-id-link>&nbsp;
      </span> 
      <a *ngIf= "email.service_display_ids.length>5" (click)="showServices(false)">Show less</a>
    </ng-template>
  </p>

  <mi-server-error [error]="error"></mi-server-error>
  <mi-page-alert></mi-page-alert>

  <div class="" *ngIf="loaded; else loading">

    <div class="contact-container">
      <div class="to-contact">
          <strong>To:&nbsp;</strong>
          <span class="email-address" *ngFor="let email of toEmails">
              <mi-id-link *ngIf="email.display_id" id="{{email.display_id}}"></mi-id-link>{{email.email}}&nbsp;&nbsp;&nbsp;
          </span>
      </div>
      <div class="cc-contact">
          <strong>Cc:&nbsp;</strong>
        <ng-container *ngIf="ccEmails && ccEmails.length > 0; else noCC">
          <span class="email-address"
                *ngFor="let email of ccEmails">
            <mi-id-link *ngIf="email.display_id" id="{{email.display_id}}"></mi-id-link>{{email.email}}&nbsp;&nbsp;&nbsp;
          </span>
        </ng-container>
      </div>
    </div>
    <div class="subject-container">
      <strong>Sent By:&nbsp;</strong>
      {{sentEmail}}
    </div>
    <div class="subject-container">
      <strong>Sent On:&nbsp;</strong>
      {{email.updated_at | date:'MM/dd/yyyy hh:mm:ss a'}}
    </div>    
    <div class="subject-container">
      <strong>Subject:&nbsp;</strong>
      {{email.subject}}
    </div>
    <div class="subject-container" *ngIf='email.attachments && email.attachments.length'>
      <strong>Attachments:&nbsp;</strong>
      <span
        *ngFor="let attachment of email.attachments">
        <a
          href="{{attachment.full_path}}"
          download="download"
          class="report-download">
          {{attachment.file_name}}
        </a>
      </span>
    </div>
    <div class="body-container">
      <span class="email-body" [innerHtml]="email.body"></span>
    </div>
    <ng-template #noCC>
      {{NO_DATA}}
    </ng-template>

  </div>


  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</div>
