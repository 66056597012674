import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ServiceOrderCreateComponent} from './create/service-order-create.component';
import {SharedModule} from '../../../../shared/shared.module';
import {ServiceOrderVendorSelectComponent} from './create/service-segments/vendor-select/service-order-vendor-select.component';
import {TenantComponentsModule} from '../../../components/tenant-components.module';
import {reducer as orderReducer} from './create/state/reducer';
import {StoreModule} from '@ngrx/store';
import {ServiceOrderIdComponent} from './create/service-segments/order-id/service-order-id.component';
import {OrderCreateSectionComponent} from './create/order-create-section/order-create-section.component';
import {ServiceOrderCreateFormComponent} from './create/service-segments/service-order-create-form.component';
import {ServiceOrderDateComponent} from './create/service-segments/order-date/service-order-date.component';
import {ServiceOrderAccountIdComponent} from './create/service-segments/account-id/service-order-account-id.component';
import {ServiceOrderContactsComponent} from './create/service-segments/contacts/service-order-contacts.component';
import {ServiceOrderDocumentsComponent} from './create/service-segments/documents/service-order-documents.component';
import {ServiceOrderAddOrderComponent} from './create/service-segments/add-order/service-order-add-order.component';
import {ServiceOrderSummaryComponent} from './summary/service-order-summary.component';
import {ServiceOrderAddServiceComponent} from './add-service/service-order-add-service.component';
import {ServiceProductComponent} from './add-service/service-segments/product/service-product.component';
import {ServiceSingleCreateModule} from '../single/create/service-single-create.module';
import {ServiceOrderEditComponent} from "./service-order-edit/service-order-edit.component";
import {ServiceDatesComponent} from './add-service/service-segments/service-dates/service-dates.component';
import {ServiceCostComponent} from './add-service/service-segments/costs/service-cost.component';
import {ServiceIdentifierComponent} from './add-service/service-segments/service-identifier/service-identifier.component';
import {ServiceOrderSummaryDocumentsComponent} from "./service-order-summary-documents/service-order-summary-documents.component";
import {ServiceLocationsComponent} from './add-service/service-segments/service-locations/service-locations.component';
import {ServiceCustomFieldsComponent} from "./add-service/service-segments/custom-fields/service-custom-fields.component";
import {ServiceDocumentsComponent} from "./add-service/service-segments/service-documents/service-documents.component";
import {ServiceAssignedToUserComponent} from "./add-service/service-segments/service-assigned-to-user/service-assigned-to-user.component";
import {ServiceTermsAndConditionsComponent} from './add-service/service-segments/terms-and-conditions/service-terms-and-conditions.component';
import {ServiceContactsComponent} from "./add-service/service-segments/service-contacts/service-contacts.component";
import {AddServiceButtonComponent} from "./add-service/service-segments/add-service-button/add-service-button.component";
import {ServiceOrderSummaryMiServicesComponent} from "./service-order-summary-services/service-order-summary-services.component"
import { VendorProductComponent } from '../single/create/vendor-product/vendor-product.component';

@NgModule({
  declarations: [
    OrderCreateSectionComponent,
    ServiceOrderEditComponent,
    ServiceContactsComponent,
    ServiceOrderSummaryDocumentsComponent,
    ServiceOrderSummaryMiServicesComponent,
    ServiceOrderAddOrderComponent,
    ServiceOrderAccountIdComponent,
    ServiceOrderContactsComponent,
    ServiceOrderCreateComponent,
    ServiceOrderCreateFormComponent,
    ServiceOrderDateComponent,
    ServiceOrderCreateFormComponent,
    ServiceOrderDocumentsComponent,
    ServiceOrderIdComponent,
    ServiceOrderSummaryComponent,
    ServiceOrderVendorSelectComponent,
    ServiceOrderAddServiceComponent,
    ServiceProductComponent,
    ServiceDatesComponent,
    ServiceCostComponent,
    ServiceCustomFieldsComponent,
    ServiceDocumentsComponent,
    ServiceIdentifierComponent,
    ServiceAssignedToUserComponent,
    ServiceLocationsComponent,
    AddServiceButtonComponent,
    ServiceTermsAndConditionsComponent,
    VendorProductComponent
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    ServiceSingleCreateModule,
    StoreModule.forFeature('orders', orderReducer),
  ],
  providers: [],
  exports: [
    OrderCreateSectionComponent,
    ServiceProductComponent,
    ServiceOrderVendorSelectComponent,
    VendorProductComponent,
  ]
})
export class ServiceOrderCreateModule {
}
