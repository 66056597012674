import {Component, EventEmitter, Input, Output} from "@angular/core";



const YYYYMMDDFormatTest = /^\d{4}-((0\d)|(1[012]))-(([012]\d)|3[01])$/;

@Component({
  selector: 'mi-date-picker',
  styleUrls: ['./date-picker.component.scss'],
  templateUrl: './date-picker.component.html'
})

export class DatePickerComponent {

  @Output() dateChange = new EventEmitter<string>();
  @Input()
  get currentDate(): string{
    return this.chosenDate;
  };
  set currentDate(str: string){
    this.chosenDate = this.formatDate(str);
    this.dateChange.emit(this.chosenDate);
  }
  @Input() placeholder: string = 'MM/DD/YYYY';
  @Input() dateFormat: string = 'm/d/Y';
  @Output() focus = new EventEmitter();
  @Input() maxDate: string = '';
  @Input() minDate: string = '';
  @Input() enabled: boolean = true;

  chosenDate: string;

  constructor() {

  }

  _pickerFocus() {
    this.focus.emit(this.currentDate);
  }

  _dateChange() {
    this.dateChange.emit(this.currentDate);
  }

  formatDate(date:any):string{

    let realDate = date;
    let formattedDate: string = date;

    if (realDate) {

      if (typeof realDate === 'string' && YYYYMMDDFormatTest.test(realDate)) {
        // YYYYMMDDFormatTest format needs space in from to be consistent

        realDate = new Date(` ${formattedDate}`);
      }

      if(typeof realDate === "object" && realDate.getMonth){
        // The date picker will send a date object on initialization;
        // this has been causing issues.

        formattedDate = (realDate.getMonth() + 1) + "/" + realDate.getDate() + "/" + realDate.getFullYear();

      }
    }

    return formattedDate;
  }

  enableDate(key){
    return true;
  }
}
