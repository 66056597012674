import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { IEventTypeEnum, ITenantServiceTerm, IVendorAccountDetails, IVendorTermFormatted } from 'src/shared/models/Vendor';
import { IUserFormattedData } from 'src/shared/models/User';
import { Link } from 'src/shared/ng-models/Link.interface';
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { Store, select } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { ActionAccountEndOfTermComponent } from '../delete-accounts-end-of-term/delete-accounts-end-of-term.component';

@Component({
    selector: 'app-account-end-of-term',
    templateUrl: './account-end-of-term.component.html',
})

export class AccountEndOfTermsComponent extends AbstractPageComponent implements OnInit {
    selectedUser: IUserFormattedData
    selectedAccount: any;
    tenantServiceTerms: ITenantServiceTerm[];
    selectVendor: IVendorTermFormatted= null;
    accounts: IVendorAccountDetails[];
    isDataLoaded: boolean = false;
    highlightAccount: any = null
    accountUpdateSuccessful: boolean = false
    selectedPageNumber: number = 1
    DataSource: LocalDataSource

    breadcrumbs: Link[];
    title: string;

    formattedData = [];
    settings = {
        actions: {
            add: false,
            delete: false,
            edit: false,
            position: 'right',
        },
        noDataMessage: 'No Accounts Found',
        columns: {
            vendor_name: {
                title: 'Vendor',
                editable: false,
                filter: false,
                sort: false,
                width: '28%'
            },
            account_identifier: {
                title: 'Account Identifier',
                editable: false,
                filter: true,
                sortDirection: 'asc',
                width: '25%'
            },
            account_end_of_term: {
                title: 'End Of Term (Account)',
                editable: false,
                filter: false,
                type: 'html',
                valuePrepareFunction: (cell: any, row: any) => this.getFormattedEOT(row.account_end_of_term.service_term_event_type, row.account_end_of_term.auto_renew_length, row.account_end_of_term.days_to_notify),
                width: '46%',
                sortable: false,
                class: 'disable-sorting',
            },
            accountAction: {
                title: 'Action',
                editable: false,
                filter: false,
                type: 'custom',
                renderComponent: ActionAccountEndOfTermComponent,
                onComponentInitFunction: (instance) => {
                    instance.customEvent.subscribe(() => {
                        this.getVendorAccounts()
                    });
                },
                width: '6%',
                sortable: false,
                class: 'disable-sorting',
            },
        }
    };

    tenantData: any = null;


    constructor(
        public router: Router,
        private store: Store<any>,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private serviceTermService: ServiceTermService,
        private tenantAccountIdService: TenantAccountIdService
    ) {
        super();

        this.route.queryParams.subscribe((params) => {
            console.log("here this.router.getCurrentNavigation().extras.state.vendorRowData, ", this.router.getCurrentNavigation().extras.state)
            if (this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.vendorRowData) {

            
                this.selectVendor = this.router.getCurrentNavigation().extras.state.vendorRowData;
                this.setBreadcrumbDetails()
                this.DataSource = new LocalDataSource([]);
                this.getVendorAccounts()
            } else {
                router.navigate(["/renewal-term"]);
            }
        });

        this.store.pipe(
            select(appDataReducer.getAppData),
            takeUntil(this.destroy$),
            map((appData) => {
                this.tenantData = appData.tenantData;
            })
        ).subscribe();

        if(this.selectVendor  === null ){
            router.navigate(["/renewal-term"]);

        }
    }



    ngOnInit() {




    }



    setBreadcrumbDetails() {
        this.title = `${this.selectVendor.vendor_name} accounts renewal term`;

        this.breadcrumbs = [
            {
                label: 'Dashboard',
                url: '/',
                internal: true
            },
            {
                label: 'Vendors renewal term',
                url: '/renewal-term',
                internal: true
            },
            {
                label: this.title,
                url: './',
                internal: true
            }
        ];
    }


    getTenantServiceTerm() {
        this.serviceTermService
            .getTenantRetermListService(this.tenantData.domain)
            .toPromise()
            .then((data: any) => {
                this.tenantServiceTerms = data
                this.getFormattedVendors()
            })
            .catch((e) => console.warn(e));

    }

    getVendorAccounts() {
        this.isDataLoaded = false
        this.tenantAccountIdService.getVendorAccountsListByTenantVendorIdService(this.selectVendor.vendor_id, this.selectVendor.account_qty)
            .toPromise()
            .then(data => {
                this.accounts = data.data
                this.isDataLoaded = true
                this.accountUpdateSuccessful && this.toastr.success(`${this.selectedAccount.account_identifier} ownership changes to  ${this.selectedUser.user_name}`);
                this.highlightAccount = this.selectedAccount
                this.getTenantServiceTerm()
            })
            .catch((e) => console.warn(e));
    }

    getFormattedVendors() {
        this.formattedData = this.accounts.map(row => {
            console.log("here selectVendor", this.selectVendor)
            return {
                vendor_name: `${this.selectVendor.vendor_name}`,
                account_dsp_id: row.account.display_id,
                account_identifier: row.account.label,
                account_end_of_term: row,
                id: row.account.id,
                tenantServiceTerms: this.selectVendor.tenantServiceTerms,
            }
        })
        this.DataSource = new LocalDataSource(this.formattedData);
        setTimeout(() => this.DataSource.setPage(this.selectedPageNumber), 50)

    }

    onItemsPerPageChange() {
        this.selectedPageNumber = this.DataSource.getPaging().page
    }

    getFormattedEOT(EOTType: IEventTypeEnum, autoRenewalLength: number, daysToNotify: string) {
        switch (EOTType) {
            case IEventTypeEnum.EVENT_TYPE_AUTO_RENEW:
                return "Auto-Renew (" + autoRenewalLength + " Month" + (autoRenewalLength > 1 ? "'s" : "") + " " + daysToNotify + " Days Advance Notification)";
            case IEventTypeEnum.EVENT_TYPE_AUTO_CANCELLATION:
                return "Cancel " + "(" + daysToNotify + " Days Advance Notification" + ")";
            default:
                return "-";
        }
    }


}
