import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OrderSteps, OrderState, OrderProgression} from '../../state/reducer';
import {Store} from '@ngrx/store';
import {UpdateOrderAction} from '../../state/actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';

@Component({
  selector: 'mi-service-order-id',
  styleUrls: ['../service-segments.scss'],
  templateUrl: './service-order-id.component.html'
})
export class ServiceOrderIdComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  NO_DATA: string = NO_DATA;
  readonly OrderSteps: any = OrderReducers.OrderSteps;
  orderState: OrderState;
  orderIdForm: FormGroup;
  step: string = OrderSteps.OrderID;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store<OrderState>
  ) {
    super();
  }

  ngOnInit() {

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state as OrderState;
          this.orderIdForm = this.formBuilder.group({
            order_id: [this.orderState.order_id, Validators.pattern("[a-zA-Z0-9-]+")]
          });
          // Find the index belonging to the state's current step:
          this.index = OrderProgression.findIndex((a)=>{
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });
  }
  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction({
      index: maxIndex,
      maxIndex: maxIndex,
      order_id: data.order_id
    }));

  }

}
