import { Component, Input, OnInit } from "@angular/core";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";

@Component({
  selector: 'mi-validation-flag',
  templateUrl: './validation-flag.component.html',
  styleUrls: ['./validation-flag.component.scss']
})

export class ValidationFlagComponent extends AbstractPageComponent implements OnInit {

  @Input() validationFlag;
  @Input() daysSinceLastUpdate;

  constructor() {
    super();
  }

  ngOnInit(): void {
    console.log("here validationFlag", this.validationFlag)
    // switch (this.validationFlag) {
    //   case "up":
    //     document.getElementById("border-div").classList.add("up");
    //     break;
    //   case "down":
    //     document.getElementById("border-div").classList.add("down");
    //     break;
    //   case "not-applicable":
    //     document.getElementById("border-div").classList.add("not-applicable");
    //   case null:
    //     document.getElementById("border-div").classList.add("not-applicable");
    //     break;
    // }
  }
}