import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {TenantComponentsModule} from '../../../components/tenant-components.module';
import {ParentServiceDetailsHeaderComponent} from './header/parent-service-details-header.component';
import {StoreModule} from '@ngrx/store';
import {reducer as parentServiceDetailsReducer} from './state/reducers';
import {ParentServiceDetailsHeaderVendorComponent} from './header/vendor/parent-service-details-header-vendor.component';
import {ParentServiceDetailsHeaderInfoComponent} from './header/parent-service-info/parent-service-details-header-info.component';
import {ParentServiceEditComponent} from './edit/parent-service-edit.component';
import {ParentServiceAddServiceComponent} from './add-service/parent-service-add-service.component';
import {ParentServiceGetQuoteComponent} from './get-quote/parent-service-get-quote.component';
import {ParentServiceDetailsComponent} from './details/parent-service-details.component';
import {ParentServiceDetailPageComponent} from './detail-miparent-service.component';
import {ParentServiceTileComponent} from './components/parent-service-tile/parent-service-tile.component';
import {ParentServiceSearchResultsComponent} from './components/parent-service-search-results/parent-service-search-results.component';
import {ParentServiceVendorAndProductDetailsComponent} from './components/parent-service-vendor-and-product-details/parent-service-vendor-and-product-details.component';
import {ParentServiceDeleteComponent} from './delete/parent-service-delete.component';
import {ParentServiceRemoveServiceComponent} from './remove-service/parent-service-remove-service.component';
import {ParentServiceAccountRepEmailComponent} from './account-representatitve-email/account-representatitve-email.component';
import {ParentServiceSummaryComponent} from './components/parent-service-summary/parent-service-summary.component';
import {ParentServiceActionsComponent} from './components/parent-service-actions/parent-service-actions.component';
import {ParentServiceRequestCancellationFormComponent} from './components/forms/cancellationRequest/parent-service-request-cancellation-form.component';
import {ParentServiceRemoveCancellationFormComponent} from './components/forms/removeCancellation/parent-service-remove-cancellation-form.component';
import {ParentServiceConfirmCancellationFormComponent} from './components/forms/confirmEditCancellation/parent-service-confirm-cancellation-form.component';
import {ParentServiceActivationFormComponent} from './components/forms/activationForm/parent-service-activation-form.component';
import { ParentRequestMissingDataComponent } from './request-missing-data/request-missing-data.component';
import { ParentQuoteMisoMarketplaceComponent } from './request-a-quote-miso-marketplace/quote-miso-marketplace.component';
import { ParentRequestAQuoteComponent } from './request-a-quote/request-a-quote.component';
import { ParentReAssessmentEmailComponent } from './re-assessment-email/re-assessment-email.component';
import { miTinymceDefaultSettings } from 'src/shared/utils/miTinymceDefaultSettings';
import { TinyMceModule } from 'angular-tinymce';
import { ParentRequestorEmailComponent } from './parent-requestor-email/parent-requestor-email.component';
import { ParentCancellationReqeustEmailComponent } from './cancellation-request-email/cancellation-request-email.component';
import { ParentSubsectionServiceGroupDetailsComponent } from './sections/subsection-service-group-details.component';
@NgModule({
  declarations: [
    ParentServiceDetailsHeaderInfoComponent,
    ParentServiceDetailsHeaderVendorComponent,
    ParentServiceDetailsHeaderComponent,
    ParentServiceDetailPageComponent,
    ParentServiceDetailsComponent,
    ParentServiceAddServiceComponent,
    ParentServiceRemoveServiceComponent,
    ParentServiceEditComponent,
    ParentServiceGetQuoteComponent,
    ParentServiceTileComponent,
    ParentServiceSearchResultsComponent,
    ParentServiceVendorAndProductDetailsComponent,
    ParentServiceDeleteComponent,
    ParentServiceAccountRepEmailComponent,
    ParentServiceSummaryComponent,
    ParentServiceActionsComponent,
    ParentServiceRequestCancellationFormComponent,
    ParentServiceRemoveCancellationFormComponent,
    ParentServiceConfirmCancellationFormComponent,
    ParentServiceActivationFormComponent,
    ParentRequestMissingDataComponent,
    ParentQuoteMisoMarketplaceComponent,
    ParentRequestAQuoteComponent,
    ParentReAssessmentEmailComponent,
    ParentRequestorEmailComponent,
    ParentCancellationReqeustEmailComponent,
    ParentSubsectionServiceGroupDetailsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    StoreModule.forFeature('parentServiceDetails', parentServiceDetailsReducer),
    /*StoreModule.forFeature('serviceDetails', serviceDetailsReducer),
    EffectsModule.forFeature([ServiceDetailsEffects]),*/
    TinyMceModule.forRoot(miTinymceDefaultSettings()),
  ],
  providers: [],
  exports: [
    ParentServiceDetailsHeaderComponent
  ]
})

export class ParentServiceDetailsModule {

}
