import {Component, Input, ViewChild, TemplateRef} from "@angular/core";
import {MiService} from "../../../models/MiService";
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ASReducer from '../../../state/advanced-search/reducers';
import {NO_DATA} from "../../../ng-models/SectionDataTable.model";
import {NOTIFICATION_SEVERITY} from "../../../utils/notificationSeverity";
import { SectionDataTableColumn } from 'src/shared/ng-models/SectionDataColumn.interface';
import { SortableTemplatesEnum } from 'src/shared/ng-models/SortableTableColumn.interface';
import { TenantCustomFieldService } from 'src/libs/api/tenant/tenantCustomFields.service';
import { CustomField } from 'src/shared/models/CustomField';
import { scrollTo } from 'src/shared/shared.functions';
import FeDateUtil from "src/shared/utils/FeDate.util";
import { EVENT_TYPE_ACTIVATION } from "src/common/utils/sharedConstants";

@Component({
  selector: 'mi-advanced-search-service-results-table',
  templateUrl: './advanced-search-service-results-table.component.html',
  styleUrls: ['./advanced-search-service-results-table.component.scss']
})

export class AdvancedSearchServiceResultsTableComponent{

  // hits$: Observable<MiService[]>;
  hits$: any[];

  resultsLoaded$: Observable<boolean>;
  serviceColumns: SectionDataTableColumn[] = [];
  customFieldById: any = {};
  customFieldsList: any;

  @ViewChild('mrcTmpl', {static: true}) mrcTmpl: TemplateRef<any>;
  @ViewChild('nextEventTmpl', {static: true}) nextEventTmpl: TemplateRef<any>;
  @ViewChild('serviceStatusTmpl', {static: true}) serviceStatusTmpl: TemplateRef<any>;
  @ViewChild('orderTmpl', {static: true}) orderTmpl: TemplateRef<any>;
  @ViewChild('financialTmpl', {static: true}) financialTmpl: TemplateRef<any>;
  @ViewChild('buTmpl', {static: true}) buTmpl: TemplateRef<any>;
  @ViewChild('customFieldHeaderTpl', {static: true}) customFieldHeaderTpl: TemplateRef<any>;
  @ViewChild('divisionTmpl', {static: true}) divisionTmpl: TemplateRef<any>;
  @ViewChild('teamTmpl', {static: true}) teamTmpl: TemplateRef<any>;
  @ViewChild('groupTmpl', {static: true}) groupTmpl: TemplateRef<any>;
  @ViewChild('parentNameTmpl', {static: true}) parentNameTmpl: TemplateRef<any>;

  error: any = '';

  readonly NO_DATA: string = NO_DATA;
  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;

  constructor(
    private store: Store<ASReducer.State>,
    private tenantCustomFieldService: TenantCustomFieldService,
  ){}

  ngOnInit() {
    this.getCustomFields();

    // this.hits$ = this.store.select(ASReducer.ASHits)
    //   .map( response => <MiService[]>response);

    this.resultsLoaded$ =  this.store.select(ASReducer.ASResultsLoaded);
    // this.setup();
  }
  
  setup() {
    this.serviceColumns = [
      {
        name: "Parent Group",
        prop:'parent_group_name',
        sortable: true
      },
      {
        name: "P-CMRC",
        prop:'calculations_v2.monthly_recurring_cost',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      },
      {
        name: "MISO ID",
        prop:'display_id',
        cellTemplate: SortableTemplatesEnum.idLink,
        sortable: false
      },
      {
        name: "Vendor (Purchasing)",
        prop:'purchasing_vendor.vendor_name',
        sortable: true
      },
      {
        name: "Vendor (Manufacturer)",
        prop:'product.product_vendor.vendor_name',
        sortable: true
      },
      {
        name: "Order Date",
        prop:'current_financial.service_order_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: false
      },
      {
        name: "Order Number",
        prop:'current_financial.service_order_number',
        sortable: true
      },
      {
        name: "Product",
        prop:'product.product_name',
        sortable: true
      },
      {
        name: "Service Category",
        prop:'product.product_category.category_name',
        sortable: false
      },
      {
        name: "Service ID",
        prop:'service_identifier',
        sortable: true
      },
      {
        name: "Account ID",
        prop:'account_id_v2.label',
        sortable: true
      },
      {
        name: "Currency",
        prop:'currency_code',
        sortable: true
      },
      {
        name: "Quantity",
        prop:'current_financial.quantity',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false
      },
      {
        name: "Unit Recurring Cost",
        prop:'current_financial.cost',
        cellTemplate: SortableTemplatesEnum.serviceCurrency,
        sortable: true
      },
      {
        name: "Billing Frequency",
        prop:'current_financial.billing_frequency',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false
      },
      {
        name: "Unit Non-Recurring Cost",
        prop:'current_financial.non_recurring_cost',
        cellTemplate: SortableTemplatesEnum.serviceCurrency,
        sortable: false
      },
      {
        name: "CMRC",
        prop:'calculations_v2.current_service.monthly_recurring_cost',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: true
      },
      {
        name: "Location A",
        prop:'locations_v2.0',
        cellTemplate: SortableTemplatesEnum.location,
        sortable: true
      },
      {
        name: "Location B",
        prop:'locations_v2.1',
        cellTemplate: SortableTemplatesEnum.location,
        sortable: true
      },
      {
        name: "Start Date",
        prop:'current_financial.start_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: true
      },
      {
        name: "End of Term",
        prop:'current_end_of_term',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: true
      },
      {
        name: "Current Term",
        prop:'current_notification.term_length',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: true
      },
      {
        name: "Event Date",
        prop:'current_notification.notification_date',
        cellTemplate: SortableTemplatesEnum.shortDate,
        sortable: false
      },
      {
        name: "Days to Event",
        prop:'current_notification.notification_days_until_event',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false
      },
      {
        name: "Next Event",
        prop:'current_notification.notification_event_type',
        cellTemplate: this.nextEventTmpl,
        sortable: false
      },
      // {
      //   name: "Next Event Length",
      //   prop:'current_notification.notification_term_length',
      //   cellTemplate: SortableTemplatesEnum.number,
      //   sortable: false
      // },
      {
        name: "Next Event Value",
        prop:'financial_commitment',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      },
      {
        name: "Number of Auto Renew",
        prop:'current_notification.auto_renew_count',
        cellTemplate: SortableTemplatesEnum.number,
        sortable: false
      },
      // {
      //   name: "Renewal Length (Months)",
      //   prop:'current_financial.service_term_v2.auto_renew_length',
      //   cellTemplate: SortableTemplatesEnum.number,
      //   sortable: false
      // },
      {
        name: "Data Integrity",
        prop:'data_integrity.overall_status',
        sortable: false
      },
      {
        name: "Service Status",
        prop:'current_notification.status',
        cellTemplate: this.serviceStatusTmpl,
        sortable: false
      },
      {
        name: "M3 Owner",
        prop:'assigned_email',
        sortable: true
      },
      {
        name: "Service Owner",
        prop:'requestor_email',
        sortable: true
      },
      {
        name: "Subscriber",
        prop:'end_user_email',
        sortable: true
      },
      {
        name: "Division",
        prop:'division',
        cellTemplate: this.divisionTmpl,
        sortable: false
      },
      {
        name: "Team",
        prop:'team',
        cellTemplate: this.teamTmpl,
        sortable: false
      },
      {
        name: "Service Group",
        prop:'service_groups',
        cellTemplate: this.groupTmpl,
        sortable: false
      },
      {
        name: "Validation Flag",
        prop:'is_validation_required',
        sortable: false
      },
      {
        name: "Days Since Last Update",
        prop:'days_since_last_update',
        sortable: false
      }
    ];
    this.store.select(ASReducer.getASData)
    .subscribe(
      data => {
        if (data.results && data.resultsLoaded && (data.searchType == "SERVICE" || data.searchType == "EVENT")) {
          this.hits$ = data.results.hits as any[];
          this.hits$.forEach(async (hit) => {
            hit.current_end_of_term = hit.current_notification && 
            hit.current_notification.event_type != EVENT_TYPE_ACTIVATION &&
            (hit.current_notification.end_date || hit.current_notification.start_date) ? 
            (hit.current_notification.end_date||hit.current_notification.start_date) : FeDateUtil.isBeforeToday(new Date(hit.current_financial.end_date))? '' : hit.current_financial.end_date;
            hit = await this.mapCustomField(hit);
          });
          //console.log("AS hits$",this.hits$);
          scrollTo('AdvancedSearchServiceResultsTableComponent');
        }
      }
    );
  }

  mapCustomField(hit) {
    hit.custom_data.map(cd => {
      this.customFieldsList.map(cf => {
        if(cd.custom_field == cf.id) {
          cd.custom_field = cf;
        }
      });
    });

    return hit;
  }

  getCustomFields(){
    this.tenantCustomFieldService.listCustomFields()
    .toPromise()
    .then((customFields: CustomField[]) => {
      if(!customFields.length){
        this.setup(); 
      }
      this.customFieldsList = customFields;

      let customFieldColumns = customFields.map(cf => {
        this.customFieldById[cf._id] = cf.label;
        return {
          name: cf._id,
          prop:'custom_data',
          headerTemplate: this.customFieldHeaderTpl,
          cellTemplate: SortableTemplatesEnum.customField,
          sortable: false,
          hidden: true
        }
      });
      this.setup();
      this.serviceColumns.push(...customFieldColumns);
    })
    .catch(e => this.error = e);
  }
}
