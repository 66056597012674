import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";

@Component({
  templateUrl: './change-invoice-email-modal.component.html',
  styleUrls: ['./change-invoice-email-modal.component.scss']
})

export class ChangeInvoiceEmailModalComponent implements OnInit {

  //error: string = "";
  title: string = "Change Invoice Email";
  newInvoiceEmail: string;

  constructor(
    private modal: ModalRef
  ) {
  }

  ngOnInit(): void {
  }

  onModalClose() {
    this.modal.close({reason: 'User closed', result: false});
  }

  changePaymentMethod() {
    this.modal.close({reason: 'User closed', result: true});
  }
}