<ng-container *ngIf="resultsLoaded$">
  <div class="results" id="AdvancedSearchEquipmentResultsComponent">
    <mi-sortable-draggable-table
      *ngIf="hits$ && hits$.length"
      [rows]="hits$"
      [columns]="equipmentsColumns"
      searchType="EQUIPMENT"
      externalClasses="fullWidth nowrap">
    </mi-sortable-draggable-table>
  </div>
  <ng-container *ngIf="!hits$ || !hits$.length">
    <p>No matching results found.</p>
  </ng-container>
</ng-container>

<ng-template #vendorTmpl
             let-row="row"
             let-value="value">
  <span class="overflow-controlled"
        title="{{row.equipment_catalog.vendor.display_id}} - {{row.equipment_catalog.vendor.vendor_name}}">
    <mi-id-link [id]="row.equipment_catalog.vendor.display_id"
                title="{{row.equipment_catalog.vendor.display_id}} - {{row.equipment_catalog.vendor.vendor_name}}">
    </mi-id-link>
    {{row.equipment_catalog.vendor.vendor_name}}
  </span>

</ng-template>