<mi-page-header
  title="Create Equipment"
  [breadcrumbs]="breadcrumbs">
  <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<div *ngIf="vendor; else loading">
    <mi-equipment-form
    [lockedVendor]="vendor"
    [showrecentlyAdded]="false"
    (equipmentCreated)="onEquipmentAdded($event)"
    (canceled)="onCanceled()">
    </mi-equipment-form>
</div>
<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>