import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as PSReducer from '../../../state/reducers';
import {Store} from '@ngrx/store';
import {EVENT_TYPE_ACTIVATION} from '../../../../../../../../src/common/utils/sharedConstants';
import {MiParentService} from '../../../../../../../shared/models/MiParentService';
import {TenantMiParentServiceService} from '../../../../../../../libs/api/tenant/tenantMiParentService.service';
import {ParentServiceLoadSuccess} from '../../../state/actions';
import {TenantNoteService} from '../../../../../../../libs/api/tenant/tenantNote.service';

@Component({
  selector: 'mi-parent-service-activation-form',
  templateUrl: './parent-service-activation-form.component.html',
})

export class ParentServiceActivationFormComponent implements OnInit {

  confirmationFormGroup: FormGroup;
  defaultActivationDate: string;


  readonly FORM_CONFIRM: string = '[form] confirm';
  readonly FORM_EDIT: string = '[form] edit';
  readonly EVENT_TYPES: any = {
    EVENT_TYPE_ACTIVATION: EVENT_TYPE_ACTIVATION
  };

  @Input() parentService: MiParentService;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();

  cancelDateIsBeforeToday: boolean = false;
  cancellationRequestFormGroup: FormGroup;

  error: string = '';
  minDate: string;
  mode: string = this.FORM_EDIT;

  constructor(private tenantMiParentService: TenantMiParentServiceService,
              private formBuilder: FormBuilder,
              private store: Store<PSReducer.State>,
              private noteService: TenantNoteService) {
  }

  ngOnInit() {

    let today = new Date();
    this.defaultActivationDate = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;

    this.confirmationFormGroup = this.formBuilder.group({
      activation_date: [this.defaultActivationDate, Validators.required]
    });

  }

  onDateChanged(newDate: string): void {
    this.confirmationFormGroup.get('activation_date').setValue(newDate);
    this.confirmationFormGroup.updateValueAndValidity();
  }

  onCancelClicked():void{
    this.confirmationFormGroup
      .get('activation_date')
      .setValue(this.defaultActivationDate);
    this.canceled.emit();
  }

  onFormSubmit(data:any):void{
    this.error = '';
    this.tenantMiParentService.confirmActivation(this.parentService.display_id, data.activation_date)
      .toPromise()
      .then((res)=>{
        this.store.dispatch(new ParentServiceLoadSuccess({
          parentService: res
        }));
        this.canceled.emit();
      })
      .catch(e=>this.error = e);
  }

}
