import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, Output} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { ActivatedRoute } from '@angular/router';
import { UploadV2Service } from 'src/libs/api2/upload-v2/upload-v2.service';
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import { TypeReferenceEnum, switchTypeReferenceEnum } from 'src/shared/ng-models/TypeReference.enum';
import { BulkUploadTemplateField } from 'src/shared/models/BulkUploadTemplateSection.interface';
import { Tenant } from 'src/shared/models/Tenant';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import * as userReducer from "../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../shared/state/app-data/reducers';

// import * as $ form jquery;


@Component({
  templateUrl: './service-download-modal.component.html',
  styleUrls: ['./service-download-modal.component.scss']
})

export class ServiceDownloadModalComponent extends AbstractPageComponent implements OnInit {

  loaded: boolean = false;
  type_reference_enum: TypeReferenceEnum;
  fields: BulkUploadTemplateField[];
  selected: string[] = [];
  error: any;
  userId: string;
  tenantData: Tenant;
  type_reference: string = "service"

  constructor(
    private modal: ModalRef,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private tenantBulkUploadService: UploadV2Service
   ) {
    super();
  }

  ngOnInit() {
    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState);
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

  combineLatest(userStateData, tenantStateData)
    .subscribe((data)=>{

      let userId = data[0].secureUser.getUserId();
      if(!this.tenantData || !this.userId || userId !== this.userId){
        this.tenantData = data[1].tenantData;
        this.userId = userId;
        this.getTemplate();
      }
    },
    e => this.error = e);
  }

  getTemplate(){
    this.type_reference_enum = switchTypeReferenceEnum(this.type_reference);

    return this.tenantBulkUploadService.getTemplateByType(this.tenantData.domain, this.type_reference_enum)
      .subscribe(
        (fields: BulkUploadTemplateField[]) => {
          this.fields = fields;
          this.loaded = true;
        },
        (e: any) => this.error = e
      );
  }

  onCancel() {
    this.modal.close({filters: null});
  }

  onFieldsSelected(fieldsSelected) {
    let tenantId = this.tenantData.domain;
    this.modal.close({filters: fieldsSelected});
    return
  }
}
