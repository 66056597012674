import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {Actions, Effect} from '@ngrx/effects';
import * as alertListActions from './actions';
import {TenantAlertService} from '../../../libs/api/tenant/tenantAlert.service';

@Injectable()
export class AlertListEffects {

  @Effect()
  loadAlert$: Observable<alertListActions.Actions> = this.actions$
    .ofType(alertListActions.ActionTypes.LOAD_ALERTS)
    .switchMap((action: alertListActions.Actions) => this.tenantAlertService.list())
    .map(response => new alertListActions.LoadAlertsSuccessAction(response))
    .catch(e => {
      return of(new alertListActions.LoadAlertsFailAction(e));
    });

  constructor(
    private actions$: Actions,
    private tenantAlertService: TenantAlertService
  ) {
  }

}
