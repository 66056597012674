<nav class="main-nav">
  <!-- <button class="nav-toggle" [class.closed]="!isOpen" (click)="toggleNav()"></button> -->
  <div class="short-cuts" *ngIf="!isOpen">
    <ul class="upper">
      <li *ngFor="let item of structure.shortCuts.upperLinks" [ngClass]="item.icon +' short-cut-icon '+ item.isActive">
        <a [ngClass]="'micon-'+item.icon"
           [routerLink]="['/'+item.path]"
           [queryParams]="item.queryParams || {}">
          <span>{{item.label}}</span></a>
      </li>
    </ul>
  </div>

  <div class="no-print">
    <mi-main-nav-standard-flyout
    class="nav-flyout"
    [ngClass]="{isOpen:isOpen}"
    [links]="structure.defaultLinks"></mi-main-nav-standard-flyout>
  </div>

</nav>
