
import {NgModule} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpBackend} from "@angular/common/http";
import {MisoHttpBackEndInterceptor, MisoHttpInterceptor} from "./interceptors/interceptors/http.interceptor";

@NgModule({
  imports: [
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MisoHttpInterceptor,
      multi: true
    },
    {
      provide: HttpBackend,
      useClass: MisoHttpBackEndInterceptor
    },
  ],
  exports: []
})
export class ApiInterceptorsModule {
}
