import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges } from "@angular/core";
import { PaginationPayload } from 'src/shared/models/MiPagination';

@Component({
  selector: "mi-pagination",
  templateUrl: './mi-pagination.html',
  styleUrls: ["./mi-pagination.scss"]
})

export class MiPaginationComponent implements OnInit {

  @Input() totalRow: number;
  @Input() totalPages: number;
  @Input() pageNumber: number;

  @Output() paginationChangeHandler: EventEmitter<PaginationPayload> = new EventEmitter<PaginationPayload>();

  pageSizeData: number[] = [10, 20, 30, 40, 50];
  selectedPageSize: number = this.pageSizeData[0];
  selectedPageNumber: number = 1;
  paginationPayload: PaginationPayload = {
    selectedPageSize: this.pageSizeData[0],
    selectedPageNumber: 1
  };
  isPreviousButtonDisabled: boolean = true;
  isNextButtonDisabled: boolean = true;

  ngOnInit(): void {
    this.paginationChangeHandler.emit(this.paginationPayload);
    this.pageButtonStatusHandler()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pageButtonStatusHandler()
    if (changes.hasOwnProperty('pageNumber')) {
      if (this.pageNumber == 0) {
        this.selectedPageNumber = 1
      }
    }
  }

  onPageSizeChangeHandler() {
    console.log("here selectedPageSize", this.selectedPageSize)
    console.log("here totalRow", this.totalRow)
    // if (this.selectedPageSize <= this.totalRow) {
      this.selectedPageNumber = 1
      this.paginationPayload = {
        selectedPageSize: this.selectedPageSize,
        selectedPageNumber: this.selectedPageNumber
      }
    // }
    this.totalPages = Math.ceil(this.totalRow / this.selectedPageSize)
    this.paginationChangeHandler.emit(this.paginationPayload);
    this.pageButtonStatusHandler()
  }

  pageButtonStatusHandler() {
    this.isPreviousButtonDisabled = this.selectedPageNumber === 1
    this.isNextButtonDisabled = this.selectedPageNumber == this.totalPages || this.selectedPageNumber === 0
  }

  setPage(page: number) {
    page = Math.round(page);
    if (page > this.totalPages) {
      this.selectedPageNumber = this.totalPages
      this.paginationPayload = {
        selectedPageSize: this.selectedPageSize,
        selectedPageNumber: this.totalPages
      }
    }
    if (page <= this.totalPages && page >= 1) {
      this.selectedPageNumber = page
      this.paginationPayload = {
        selectedPageSize: this.selectedPageSize,
        selectedPageNumber: this.selectedPageNumber
      }
    }
    if (page < 1) {
      this.selectedPageNumber = 1
      this.paginationPayload = {
        selectedPageSize: this.selectedPageSize,
        selectedPageNumber: this.totalPages
      }
    }

    this.paginationChangeHandler.emit(this.paginationPayload);
    this.pageButtonStatusHandler()
  }

  nextPageClickHandler() {
    if (this.selectedPageNumber < this.totalPages) {
      this.selectedPageNumber = Number(this.selectedPageNumber) + 1;
      this.paginationPayload = {
        selectedPageSize: this.selectedPageSize,
        selectedPageNumber: this.selectedPageNumber
      }
      this.paginationChangeHandler.emit(this.paginationPayload);
    }
    this.pageButtonStatusHandler()
  }

  previousPageClickHandler() {
    if (this.selectedPageNumber > 1) {
      this.selectedPageNumber = Number(this.selectedPageNumber) - 1;
      this.paginationPayload = {
        selectedPageSize: this.selectedPageSize,
        selectedPageNumber: this.selectedPageNumber
      }
      this.paginationChangeHandler.emit(this.paginationPayload);
    }
    this.pageButtonStatusHandler()
  }

}