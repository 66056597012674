import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params,} from '@angular/router';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../../../shared/ng-models/Link.interface';
import {Contact} from '../../../../../../shared/models/Contact';
import {NO_DATA} from '../../../../../../shared/ng-models/SectionDataTable.model';
import {environment} from '../../../../../../environments/environment';
import * as NoteListActions from '../../../../../../shared/state/note-list/actions';
import {MisoCollection} from '../../../../../../shared/models/MisoCollection.enum';
import {Store} from '@ngrx/store';
import {Alert} from '../../../../../../shared/models/Alert.interface';
import * as AlertReducer from '../../../../../../shared/state/alerts/reducers';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';
import { Vendor } from 'src/shared/models/Vendor';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { CONTACT_STATUSES, CONTACT_STATUS_ACTIVE, CONTACT_STATUS_INACTIVE } from 'src/common/utils/sharedConstants';
import linkFormatter from 'src/common/utils/linkFormatter';

@Component({
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})

export class ContactDetailComponent extends AbstractPageComponent implements OnInit {

  contactAlert: Alert;
  error: string = '';
  title: string;
  loaded: boolean = false;
  breadcrumbs: Link[];
  contactId: string;
  contact: VendorContact;
  contacts: Contact[];
  NO_DATA: string = NO_DATA;
  supportLink: string = linkFormatter.getSupportLink();
  vendorLink: string;

  contactColumns: any[];
  contactRows: any[];

  @ViewChild('emailTmpl', {static: true}) emailTmpl;
  @ViewChild('companyTmpl', {static: true}) companyTmpl;
  @ViewChild('statusTmpl', {static: true}) statusTmpl;
  @ViewChild('roleTmpl', {static: true}) roleTmpl;
  @ViewChild('phoneTmpl', {static: true}) phoneTmpl;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantConactService: ContactV2Service,
              private tenantVendors: TenantVendors,
              private store: Store<any>) {
    super();
    //this.contactId = this.activatedRoute.parent.snapshot.params.display_id;
    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Contacts',
        url: '/contacts',
        internal: true
      },
      {
        label: this.contactId,
        url: '/addresses/' + this.contactId,
        internal: true
      }
    ];
  }

  ngOnInit() {

    // reset the notes store:
    this.store.dispatch(new NoteListActions.ResetNoteListAction({}));

    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.loaded = false;
        this.contactId = queryParams.display_id;
        return this.tenantConactService.getContactById(this.contactId);
      })
      .switchMap((contact: VendorContact) => {
        this.contact = contact;
        return this.tenantVendors.getVendorById(this.contact.tenant_vendor_id);
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((vendor: Vendor) => {
        this.contact.vendor = vendor;
        this.contact.status = this.contact.is_active ? CONTACT_STATUS_ACTIVE : CONTACT_STATUS_INACTIVE;
        /*
        this.store.select(AlertReducer.getAlertsList)
        .takeUntil(this.destroy$)
        .subscribe((alerts) => {
          this.contactAlert = this.getAlert(alerts, 'contact');
        });

      // load the notes for this contact.
      this.store.dispatch(
        new NoteListActions.LoadNotesAction({
          document_id: this.contact.id,
          document_collection: MisoCollection.CONTACTS
        })
      );*/

      this.contactColumns = [
        {
          name: 'Status',
          prop: 'status',
          cellTemplate: this.statusTmpl,
          sortable: false
        },
        {
          name: 'Company',
          prop: 'company_name',
          cellTemplate: this.companyTmpl,
          sortable: false
        },
        {
          name: 'Office Phone',
          prop: 'office_phone',
          cellTemplate: this.contact.office_phone ? this.phoneTmpl : null,
          sortable: false
        },
        {
          name: 'Mobile Phone',
          prop: 'mobile_phone',
          cellTemplate: this.contact.mobile_phone ? this.phoneTmpl : null,
          sortable: false
        },
        {
          name: 'Fax',
          prop: 'fax',
          cellTemplate: this.contact.fax ? this.phoneTmpl : null,
          sortable: false
        },
        {
          name: 'Email',
          prop: 'email',
          cellTemplate: this.emailTmpl,
          sortable: false
        },
        {
          name: 'Role',
          prop: 'role',
          cellTemplate: this.roleTmpl,
          sortable: false
        }
      ];
      this.contactRows = [
        {
          status: this.contact.status,
          company_name: this.contact.vendor.vendor_name,
          office_phone: this.contact.office_phone ? this.contact.office_phone : NO_DATA,
          mobile_phone: this.contact.mobile_phone ? this.contact.mobile_phone : NO_DATA,
          fax:  NO_DATA,
          email: this.contact.email,
          role: this.contact.function,
          vendor_id: this.contact.vendor.display_id
        }
      ];

      this.vendorLink = '/vendor/details/' + this.contact.vendor.display_id + '/';

        this.loaded = true;
        // console.log('this.equipment', this.equipment);
      });

/*
    this.tenantConactService.getContactById(this.contactId)
      .toPromise()
      .then(res => {

        this.contact = res;

        this.store.select(AlertReducer.getAlertsList)
          .takeUntil(this.destroy$)
          .subscribe((alerts) => {
            this.contactAlert = this.getAlert(alerts, 'contact');
          });

        // load the notes for this contact.
        this.store.dispatch(
          new NoteListActions.LoadNotesAction({
            document_id: res._id,
            document_collection: MisoCollection.CONTACTS
          })
        );

        this.contactColumns = [
          {
            name: 'Status',
            prop: 'status',
            cellTemplate: this.statusTmpl,
            sortable: false
          },
          {
            name: 'Company',
            prop: 'company_name',
            cellTemplate: this.companyTmpl,
            sortable: false
          },
          {
            name: 'Office Phone',
            prop: 'office_phone',
            cellTemplate: this.contact.office_phone ? this.phoneTmpl : null,
            sortable: false
          },
          {
            name: 'Mobile Phone',
            prop: 'mobile_phone',
            cellTemplate: this.contact.mobile_phone ? this.phoneTmpl : null,
            sortable: false
          },
          {
            name: 'Fax',
            prop: 'fax',
            cellTemplate: this.contact.fax ? this.phoneTmpl : null,
            sortable: false
          },
          {
            name: 'Email',
            prop: 'email',
            cellTemplate: this.emailTmpl,
            sortable: false
          },
          {
            name: 'Role',
            prop: 'role',
            cellTemplate: this.roleTmpl,
            sortable: false
          }
        ];
        this.contactRows = [
          {
            status: this.contact.status,
            company_name: this.contact.vendor_id,
            office_phone: this.contact.office_phone ? this.contact.office_phone : NO_DATA,
            mobile_phone: this.contact.mobile_phone ? this.contact.mobile_phone : NO_DATA,
            fax:  NO_DATA,
            email: this.contact.email,
            role: this.contact.function,
            vendor_id: this.contact.vendor.display_id
          }
        ];

        this.vendorLink = '/vendor/details/' + this.contact.vendor.display_id + '/';
      });*/
  }

  getAlert(data: any, type: string): Alert {
    if (data.loaded) {
      if (this[type].display_id in data.effected) {
        return data.alerts.find((alert) => {
          return alert.effects.find((effect) => {
            return effect.display_id === this[type].display_id;
          });
        });
      }
    }
    return null;
  }


}
