import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {Actions, Effect} from '@ngrx/effects';
import * as emailListActions from './actions';
import {TenantEmailService} from '../../../libs/api/tenant/tenantEmail.service';

@Injectable()
export class EmailListEffects {

  @Effect()
  serviceEmail$: Observable<emailListActions.Actions> = this.actions$
    .ofType(emailListActions.ActionTypes.LOAD_SERVICE_EMAILS)
    .switchMap((action: emailListActions.Actions) => this.tenantEmailService.getEmailByService(action.payload.display_id))
    .map(response => new emailListActions.LoadEmailsSuccessAction(response))
    .catch(e => of(new emailListActions.LoadEmailsFailAction(e)));


  @Effect()
  parentServiceEmails$: Observable<emailListActions.Actions> = this.actions$
    .ofType(emailListActions.ActionTypes.LOAD_PARENT_SERVICE_EMAILS)
    .switchMap((action: emailListActions.Actions) => this.tenantEmailService.getEmailByParentService(action.payload.display_id))
    .map(response => new emailListActions.LoadEmailsSuccessAction(response))
    .catch(e => of(new emailListActions.LoadEmailsFailAction(e)));

  constructor(
    private actions$: Actions,
    private tenantEmailService: TenantEmailService
  ) {
  }

}
