import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {reducer as serviceDetailsReducer} from './state/reducer';
import {ParentServicesCreateComponent} from './create/parent-services-create.component';
import {ParentServiceDetailsModule} from './detail/parent-service-details.module';
import {ServiceOrderCreateModule} from '../services/orders/service-order-create.module';
import {TenantComponentsModule} from '../../components/tenant-components.module';
import {ServiceSingleCreateModule} from '../services/single/create/service-single-create.module';
import {ParentServiceProductComponent} from './create/sections/product/parent-service-product.component';
import {ParentServiceNicknameComponent} from './create/sections/nickname/parent-service-nickname.component';
import {ParentServiceAssigneeComponent} from './create/sections/assignee/parent-service-assignee.component';
import {ParentServiceAddComponent} from './create/sections/create-service/parent-service-add.component';
import {TenantMiParentServiceService} from '../../../libs/api/tenant/tenantMiParentService.service';

@NgModule({
  declarations: [
    ParentServicesCreateComponent,
    ParentServiceNicknameComponent,
    ParentServiceProductComponent,
    ParentServiceAssigneeComponent,
    ParentServiceAddComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    ParentServiceDetailsModule,
    ServiceOrderCreateModule,
    TenantComponentsModule,
    ServiceSingleCreateModule,
    StoreModule.forFeature('parent-service', serviceDetailsReducer),
  ],
  providers: [
    TenantMiParentServiceService,
  ],
  exports: []
})

export class ParentServiceModule {

}
