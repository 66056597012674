import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CreateCustomerRequest, CreateSubscriptionRequest, TenantBillingAccount, TenantGroup, GetPaymentMethodsByIdsRequest, SignupPageTracking, CancelInviteRequest, ResendInviteRequest, DeleteTenantBillingAccountRequest, UpdateCustomerRequest } from "src/shared/models/Billing";
import { SendTenantInviteEmailRequest, TenantGroupMember, TenantGroupPayments, TenantGroupPlans } from "src/shared/models/TenantCreationV2";

@Injectable()
export class BillingService {
  private apiUrl: string = "_MICRO_SERVICE_/billing";

  constructor(private http: HttpClient) {
  }

  getProduct(productId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/product/get?productId=${productId}`);
  }

  getAllProducts(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/product/list`);
  }

  getUnassignedProducts(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/product/get-unassigned-products`);
  }

  getProductsByTenantBillingAccountId(tenantBillingAccountId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/product/get-products-by-tenant-billing-account-id?tenantBillingAccountId=${tenantBillingAccountId}`);
  }

  getPrices(): Observable<any> {
    return this.http
    .get(`${this.apiUrl}/price/list`);
  }

  getCustomer(customerId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/customer/get?customerId=${customerId}`);
  }

  getCustomerPaymentMethods(customerId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/customer/get-payment-methods?customerId=${customerId}`);
  }

  createCustomer(requestBody: CreateCustomerRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/customer/create`, {...requestBody});
  }

  attachCustomerNewPaymentMethod(requestBody: UpdateCustomerRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/customer/attach-new-payment-method`, {...requestBody});
  }
  
  changeCustomerDefaultPaymentMethod(requestBody: UpdateCustomerRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/customer/change-default-payment-method`, {...requestBody});
  }

  changeCustomerInvoiceEmail(requestBody: UpdateCustomerRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/customer/change-invoice-email`, {...requestBody});
  }

  getSubscription(subscriptionId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/subscription/get?subscriptionId=${subscriptionId}`);
  }

  createSubscription(requestBody: CreateSubscriptionRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/subscription/create`, {...requestBody});
  }

  changeSubscription(requestBody: CreateSubscriptionRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/subscription/change`, {...requestBody});
  }
/*
  getPlans(): Observable<any> {
    const headers = new HttpHeaders()
      .set("Authorization", "Basic " + btoa(environment.RECURLY_PUBLIC_KEY))
      .set("Accept", "application/vnd.recurly.latest");
    //headers.append("Authorization", "Basic" + btoa(environment.RECURLY_PUBLIC_KEY));
    console.log("headers", headers);

    return this.http
      .get(`${this.apiUrl}/plans`, {headers: headers});
  }
*/

  //non stripe related
  
  listTenantGroups(page: number = 0, size: number = 20): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group/list?page=${page}&size=${size}`);
  }

  createTenantGroup(requestBody: TenantGroup): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-group/create`, {...requestBody});
  }

  updateTenantGroup(requestBody: TenantGroup): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-group/update`, {...requestBody});
  }

  listTenantBillingAccounts(page: number = 0, size: number = 20): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-billing-account/list?page=${page}&size=${size}`);
  }

  getTenantBillingAccount(requestBody: TenantBillingAccount): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/get`, {...requestBody});
  }

  createTenantBillingAccount(requestBody: TenantBillingAccount): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/create`, {...requestBody});
  }

  createTenantInviteCode(requestBody: TenantBillingAccount): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/create-tenant-invite-code`, {...requestBody});
  }

  setTenantOrgInfo(requestBody: TenantBillingAccount): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/set-tenant-org-info`, {...requestBody});
  }

  setTenantPersonalInfo(requestBody: TenantBillingAccount): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/set-tenant-personal-info`, {...requestBody});
  }

  setTenantSubscriptionInfo(requestBody: TenantBillingAccount): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/set-tenant-subscription-info`, {...requestBody});
  }

  getAllPaymentMethods(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/payment-methods/list`);
  }

  getPaymentMethodsByIds(requestBody: GetPaymentMethodsByIdsRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/payment-methods/get-payment-methods-by-ids`, {...requestBody});
  }

  sendTenantInviteEmail(requestBody: SendTenantInviteEmailRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-invite-email/send`, {...requestBody});
  }

  createTenantGroupMember(requestBody: TenantGroupMember): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-group-members/create`, {...requestBody});
  }

  getTenantGroupMembersByTenantGroupId(tenantGroupId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group-members/get-by-tenant-group-id?tenantGroupId=${tenantGroupId}`);
  }

  createTenantGroupPayments(requestBody: TenantGroupPayments): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-group-payments/create`, {...requestBody});
  }

  getTenantGroupPaymentsByTenantGroupId(tenantGroupId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group-payments/get-by-tenant-group-id?tenantGroupId=${tenantGroupId}`);
  }

  createTenantGroupPlans(requestBody: TenantGroupPlans): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-group-plans/create`, {...requestBody});
  }

  getTenantGroupPlansByTenantGroupId(tenantGroupId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group-plans/get-by-tenant-group-id?tenantGroupId=${tenantGroupId}`);
  }

  getTenantBillingAccountByInviteCode(tenantInviteCode: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-billing-account/get-tenant-billing-account-by-invite-code?tenantInviteCode=${tenantInviteCode}`);
  }

  getTenantBillingAccountByTenantCode(tenantCode: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-billing-account/get-tenant-billing-account-by-tenant-code?tenantCode=${tenantCode}`);
  }

  getTenantGroupByTenantBillingAccountId(tenantBillingAccountId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group/get-tenant-group-by-billing-account-id?tenantBillingAccountId=${tenantBillingAccountId}`);
  }

  deleteTenantGroupMember(requestBody: TenantGroupMember): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-group-members/delete`, {...requestBody});
  }

  deleteTenantGroupPlans(tenantGroupId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group-plans/delete-by-tenant-group-id?tenantGroupId=${tenantGroupId}`);
  }

  deleteTenantGroupPayments(tenantGroupId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-group-payments/delete-by-tenant-group-id?tenantGroupId=${tenantGroupId}`);
  }

  trackSignupPageUsage(requestBody: SignupPageTracking): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/usage/info`, {...requestBody});
  }

  getTrackingInfoByInviteCode(inviteCode: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/usage/get-by-invite-code?inviteCode=${inviteCode}`);
  }

  resendInvite(requestBody: ResendInviteRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/resend-invite`, {...requestBody});
  }

  cancelInvite(requestBody: CancelInviteRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/cancel-invite`, {...requestBody});
  }
  
  deleteTenantBillingAccount(requestBody: DeleteTenantBillingAccountRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/tenant-billing-account/delete`, {...requestBody});
  }

  getTenantInviteHistoryByTenantBillingAccountId(tenantBillingAccountId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-invite-history/get-by-tenant-billing-account-id?tenantBillingAccountId=${tenantBillingAccountId}`);
  }

  getTenantInviteHistoryPaymentsByTenantInviteHistoryId(tenantInviteHistoryId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-invite-history-payments/get-by-tenant-invite-history-id?tenantInviteHistoryId=${tenantInviteHistoryId}`);
  }

  getTenantInviteHistoryPlansByTenantInviteHistoryId(tenantInviteHistoryId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-invite-history-plans/get-by-tenant-invite-history-id?tenantInviteHistoryId=${tenantInviteHistoryId}`);
  }

  getTemplateSetupProduct(planCode: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/product/get-template-setup-product?planCode=${planCode}`);
  }

  getTenantCurrentBillingCycle(tenantCode: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/tenant-current-billing-cycle/get-by-tenant-code?tenantCode=${tenantCode}`);
  }
}