<div class="create-section" [ngClass]="{'active':orderState.index === index}">
  <div class="create-section-header">
    <div class="create-section-title">{{title}}</div>
    <div class="create-section-controls">

      <div class="create-section-progress" *ngIf="orderState.index === index">
        In Progress
      </div>

      <div class="create-section-complete" *ngIf="orderState.index > index">
        Complete
      </div>

      <button
        mi-button
        *ngIf="changeable && (index < orderState.index)"
        class="create-section-cta"
        [ngClass]="{'disabled': activeStep}"
        (click)="onChangeClicked()">
        Change
      </button>

    </div>
  </div>
  <div class="create-section-content">
    <ng-content *ngIf="atLeastMyIndex"></ng-content>
  </div>
</div>
