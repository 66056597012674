import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from "rxjs/index";
import { CurrencyV2 } from '../types/CurrencyV2.interface';


@Injectable()
export class ParentServiceGroupService {

  private apiUrl = '_MICRO_SERVICE_/parent-service-group';
  
  constructor(private http: HttpClient) {

  }

  getParentServiceGroup(display_id: string, tenant_id: string): Observable<any> {
    const data = {
      params: {
        tenant_id: tenant_id
      }
    };
    return this.http
      .get(`${this.apiUrl}/parent-service-group?display_id=${display_id}`, data)
      .map((response: any) => response as any);
  }


  removeFromParent(parent_service_group_id:string, services:any[]): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/delete-service`, {
        parent_service_group_id,
        services
      })
      .map((response: any) => response as any);
  }

  addServiceToParent(parent_service_group_id:string, services:any[]): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/add-service`, {
        parent_service_group_id,
        services
      })
      .map((response: any) => response as any);
  }

  editParentName(parent_service_group_id: string, name: string): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/edit-name`, {
        parent_service_group_id,
        name
      })
      .map((response: any) => response as any);
  }

  setParentService(parent_service_group_id: string, product_name: string, display_id: string): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/set-parent-service`, {
        display_id,
        parent_service_group_id,
        product_name
      })
      .map((response: any) => response as any);
  }


}
