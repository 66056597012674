import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MiServiceDetails } from "../../../../../../shared/models/MiService";
import { TenantMiServiceService } from "../../../../../../libs/api/tenant/tenantMiService.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";

@Component(
  {
    selector: 'mi-subsection-order-details',
    templateUrl: './subsection-order-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionOrderDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<any> = new EventEmitter<any>();
  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  // Custom Vars:
  pageLoaded$: Observable<boolean>;
  accountId: string;
  formGroup: FormGroup;
  // miService: MiService;
  serviceDetails: MiServiceDetails;
  serviceIdChangeState: boolean = true;
  service_id: string;
  updating: boolean = false;

  constructor(private tenantMiService: TenantMiServiceService,
    // private formBuilder: FormBuilder,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit() {
    this.pageLoaded$ = this.store.select(SDReducer.DSPageLoaded);
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
          }

          // if (data.miService) {
          //   this.miService = data.miService;
          //   if(this.miService.service_identifier){
          //     this.service_id = this.miService.service_identifier;
          //     this.serviceIdChangeState = false;
          //   };
          //   this.formGroup = this.formBuilder.group({
          //     service_order_date: this.miService.current_financial.service_order_date,
          //     service_order_number: this.miService.current_financial.service_order_number,
          //     service_identifier: this.miService.service_identifier,
          //   });
          // }
        });
  }

  // serviceIdChange(serviceId) {
  //   this.formGroup.get('service_identifier').setValue(serviceId);
  //   this.serviceIdChangeState = false
  // }

  // onDateSelected(date: any) {
  //   this.formGroup.get('service_order_date').setValue(date);
  // }

  // onUpdateSubmit(data: any) {
  //   this.updating = true;
  //   // this.tenantMiService.updateMiService(this.miService._id, {
  //   //   service_identifier: data.service_identifier
  //   // })
  //   // .toPromise().then(res=>{
  //   //   return this.tenantMiService.updateMiServiceFinancials(this.miService._id, {
  //   //     service_order_date: data.service_order_date,
  //   //     service_order_number: data.service_order_number
  //   //   })
  //   //   .toPromise()
  //   // })
  //   // .then(res => {
  //   //   this.dataUpdate.emit(res);
  //   //   this.onToggleExpand();
  //   //   this.service_id = data.service_identifier;
  //   // })
  //   // .catch(e => this.error = e)
  //   // .finally(() => {
  //   //   this.updating = false;
  //   // });
  // }

  // onToggleExpand(): void {
  //   this.isOpen = !this.isOpen;
  // }

}
