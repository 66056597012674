import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractPageComponent } from '../../../../shared/pages/abstract-page/abstract-page.component';
import { Link } from '../../../../shared/ng-models/Link.interface';
import { NO_DATA } from '../../../../shared/ng-models/SectionDataTable.model';
import { MiServiceDetails, ServiceCalculationDetails} from '../../../../shared/models/MiService';
import { Observable } from 'rxjs';
import * as SDReducer from './state/reducers';
import * as SDActions from './state/actions';
import * as NoteListActions from '../../../../shared/state/note-list/actions';
import { Store } from '@ngrx/store';
import { SERVICE_CURRENT, SERVICE_ARCHIVED } from '../../../../../src/common/utils/sharedConstants';
import { print } from '../../../../shared/shared.functions';
import { ResetEmailListAction } from '../../../../shared/state/email-list/actions';
import { Alert } from '../../../../shared/models/Alert.interface';
import { TabsContainerComponent } from 'src/shared/components/containers/tabs/tabs-container.component';
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { TabContainerComponent } from 'src/shared/components/containers/tabs/tab-container.component';

@Component({
  templateUrl: './detail-miservice.component.html',
  styleUrls: ['./detail-miservice.component.scss']
})

export class MiServiceDetailComponent extends AbstractPageComponent implements OnInit {

  serviceAlert: Alert;
  pageLoaded$: Observable<boolean>;
  error: string = '';
  title: string;
  serviceId: string = '';
  breadcrumbs: Link[];
  modalId: string;
  NO_DATA: string = NO_DATA;
  customFields: any[];
  onPrintClicked: Function = print;
  equipmentsCount: number = 0;
  attachmentsCount: number = 0;
  groupsCount: number = 0;
  locationsCount: number = 0;
  tenant_id: string;
  serviceCalculationDetails: ServiceCalculationDetails
  serviceDetails: MiServiceDetails
  parentServiceDetails: MiServiceDetails
  pageLoading: boolean = true
  validationFlag: string= "not-applicable";
  daysSinceLastUpdate: number;
  urlParams: Params
  selectedServiceDetailsTab: TabContainerComponent
  selectedTab: number = 0

  @ViewChild('detailTabs', { static: false }) detailTabs: TabsContainerComponent;

  readonly SERVICE_STATUS: any = {
    SERVICE_CURRENT: SERVICE_CURRENT,
    SERVICE_ARCHIVED: SERVICE_ARCHIVED
  };

  constructor(private activatedRoute: ActivatedRoute,
    private serviceV2Service: ServiceV2Service,
    private store: Store<any>) {
    super();
  }

  ngOnInit() {
    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe((data) => { this.tenant_id = data.tenantData.domain },
      (e) => (this.error = e)
    );
    this.resetStores();
    this.loadDetails();
    this.pageLoaded$ = this.store.select(SDReducer.DSPageLoaded);
  }

  populateValidationFlagData() {
    switch (this.serviceDetails.service_data.validation_flag) {
      case null:
        this.validationFlag = "not-applicable";
        this.daysSinceLastUpdate = this.serviceDetails.service_data.days_since_last_update ? this.serviceDetails.service_data.days_since_last_update : null;
        break;
      case "up":
        this.validationFlag = this.serviceDetails.service_data.validation_flag;
        this.daysSinceLastUpdate = this.serviceDetails.service_data.days_since_last_update;
        break;
      case "down":
        this.validationFlag = this.serviceDetails.service_data.validation_flag;
        this.daysSinceLastUpdate = this.serviceDetails.service_data.days_since_last_update;
        break;
      case "not_monitored":
        this.validationFlag = "not-applicable";
        this.daysSinceLastUpdate = this.serviceDetails.service_data.days_since_last_update ? this.serviceDetails.service_data.days_since_last_update : null;
        break;
    }
  }

  loadDetails() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.resetStores();
        this.setBreadCrumbs(params.display_id);
        this.urlParams = params
        this.getServiceDetails()
      }
    );
  }

  getServiceDetails() {
    this.pageLoading = true;
    this.serviceV2Service.getServiceDetailsWithServiceDisplayIdAndTenantId(this.urlParams.display_id, this.tenant_id)
      .toPromise()
      .then(res => {
        this.store.dispatch(new SDActions.SDLoadSuccess({ serviceDetails: res.miso_service }));
        this.serviceDetails = res.miso_service
        this.populateValidationFlagData();
        if (this.serviceDetails.general.family_designation.toLowerCase() === "parent") { // If current service is parent it-self then not calling for parent service details API
          this.store.dispatch(new SDActions.SDLoadSuccess({ parentServiceDetails: res.miso_service }));
          this.getCurrentServiceCalculationDetails()
        } else { // If current service is not parent then  calling for parent service details API
          this.serviceV2Service.getServiceDetails(this.serviceDetails.general.parent_service_id)
            .toPromise()
            .then(parenServiceDetailsResponse => {
              this.store.dispatch(new SDActions.SDLoadSuccess({ parentServiceDetails: parenServiceDetailsResponse.miso_service }));
              this.getCurrentServiceCalculationDetails()
            })
            .catch(e => {
              this.error = e;
            });
        }
      })
      .catch(e => {
        this.pageLoading = false
        this.error = e.message;
      });
  }

  getCurrentServiceCalculationDetails() {
    this.serviceV2Service.getServiceCalculationDetails(this.serviceDetails.service_data.service_id)
      .toPromise()
      .then(res => {
        this.serviceCalculationDetails = res
        this.store.dispatch(new SDActions.SDLoadSuccess({ serviceCalculationDetails: res }));
        if (this.serviceDetails.general.family_designation.toLowerCase() === "parent") { // If current service is parent it-self then not calling for parent service details API
          this.store.dispatch(new SDActions.SDLoadSuccess({ parentServiceCalculationDetails: res }));
          this.pageLoading = false
        } else { // If current service is not parent then  calling for parent service details API
          this.getParentServiceCalculationDetails()
        }
      })
      .catch(e => {
        this.error = e;
      });
  }

  getParentServiceCalculationDetails() {
    this.serviceV2Service.getServiceCalculationDetails(this.serviceDetails.general.parent_service_id)
      .toPromise()
      .then(res => {
        this.pageLoading = false
        this.store.dispatch(new SDActions.SDLoadSuccess({ parentServiceCalculationDetails: res }));
      })
      .catch(e => {
        this.error = e;
      });
  }


  updateServiceDetailsStore() {
    this.resetStores();
    this.getCurrentServiceCalculationDetails();
    setTimeout(() => {
      this.loadDetails();
    }, 1000);
    //this.store.dispatch(new alertActions.LoadAlertsAction(null));
  }

  resetStores() {
    // everytime the parm changes we need to reset
    this.store.dispatch(new SDActions.SDReset({}));
    this.store.dispatch(new NoteListActions.ResetNoteListAction({}));
    this.store.dispatch(new ResetEmailListAction({}));
  }

  setBreadCrumbs(display_id: string): void {
    this.breadcrumbs = [{
      url: '',
      label: 'Dashboard',
      internal: true
    }, {
      url: '/services',
      label: 'Services',
      internal: true
    }, {
      url: '/services/' + display_id,
      label: display_id,
      internal: true
    }];
  }

  onTabChange(ctrl) {
    let tabList = ['vendor', 'service_id', 'locations', 'assignee', 'rate', 'end_date', 'end_of_term', 'contacts', 'custom_fields', 'associated_documents', 'equipment', 'service-group-list'];
    let selectedIndex = tabList.indexOf(ctrl);
    let tabs = this.detailTabs.tabs.filter((element, index) => index === selectedIndex);
    if (tabs && tabs.length) {
      this.detailTabs.selectTab(tabs[0]);
    }
  }

  tabChangeHandler(event){
    this.selectedTab = event
  }
}