import { Component, Input, OnInit, ViewChild, TemplateRef} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { Equipment } from "../../../shared/models/Equipment";

@Component({
  templateUrl: './select-equipment-modal.component.html',
  styleUrls: ['./select-equipment-modal.component.scss']
})

export class SelectEquipmentModalComponent implements OnInit {
  displayCancelButton : boolean = true;
  constructor(private modal: ModalRef) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modal.close({equipment: null});
  }

  onSelectEquipment(equipment: Equipment): void {
    this.modal.close({equipment});
  }
  showAddEquipmentStatus(status){
    this.displayCancelButton = status;
  }
}

