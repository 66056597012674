import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {integerValidator} from '../../../../../../shared/shared.validators';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TenantMiServiceService} from '../../../../../../libs/api/tenant/tenantMiService.service';
import {MiService} from '../../../../../../shared/models/MiService';
import {NO_DATA} from '../../../../../../shared/ng-models/SectionDataTable.model';
import {TermsAndConditions} from '../../../../../../shared/models/TermsAndConditions';
import {Financial} from '../../../../../../shared/models/Financial';
import {FINANCIAL_RETERM} from '../../../../../../common/utils/sharedConstants';
import * as SDActions from '../../state/actions';
import {Store} from '@ngrx/store';
import * as SDReducer from '../../state/reducers';
import {createLogger, LOG_LEVELS} from '../../../../../../shared/logger';
import FeDateUtil from '../../../../../../shared/utils/FeDate.util';
import {DateTime} from 'luxon';
import {MisoCollection} from '../../../../../../shared/models/MisoCollection.enum';
import * as NoteActions from '../../../../../../shared/state/note-list/actions';
import {NoteHtml} from '../../../../../../shared/models/NoteHtml.enum';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-reterm-form',
  styleUrls: ['./reterm-form.component.scss'],
  templateUrl: './reterm-form.component.html'
})

export class RetermFormComponent extends AbstractPageComponent implements OnInit {

  @Input() isEdit: boolean;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();
  @Output() retermUpdated: EventEmitter<null> = new EventEmitter<null>();

  readonly ORDER_ID: number = 0;
  readonly TERM_LENGTH: number = 1;
  readonly COSTS: number = 2;
  readonly TERMS_AND_CONDITIONS: number = 3;
  readonly SUMMARY: number = 4;

  readonly NO_DATA: string = NO_DATA;

  currentRetermFinancial: Financial;
  currentState: number = this.ORDER_ID;
  eotCurrent: any;
  eotReterm: any;
  error: string = '';
  loaded: boolean;
  progressDisabled: boolean = false;
  retermForm: FormGroup;
  service: MiService;
  serviceId: string;
  dateKnown: number = 0;
  startDate: any = {
    UNKNOWN: 0,
    KNOWN: 1
  };
  updating: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private router: Router,
              private tenantMiService: TenantMiServiceService,
              private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {

    this.loaded = false;
    this.serviceId = this.activatedRoute.snapshot.params.display_id;
    this.tenantMiService.getMiService(this.serviceId)
      .toPromise()
      .then((res: MiService) => {

        this.service = res;

        this.currentRetermFinancial = res.re_term_financial;

        let service_order_number: string = this.currentRetermFinancial ? this.currentRetermFinancial.service_order_number : null;
        let service_order_date: string = this.currentRetermFinancial ? this.currentRetermFinancial.service_order_date : null;
        let start_date: string = this.currentRetermFinancial ? this.currentRetermFinancial.start_date : null;
        let term_length: string = this.currentRetermFinancial ? this.currentRetermFinancial.term_length : null;
        let cost: number = this.currentRetermFinancial ? this.currentRetermFinancial.cost : null;
        let quantity: number = this.service.current_financial ? this.service.current_financial.quantity : 1;
        let non_recurring_cost: number = this.currentRetermFinancial ? this.currentRetermFinancial.non_recurring_cost : 0;
        let billing_frequency: number = this.currentRetermFinancial ? this.currentRetermFinancial.billing_frequency : null;
        let terms_and_conditions: any = this.currentRetermFinancial ? this.currentRetermFinancial.terms_and_conditions : null;

        this.retermForm = this.formBuilder.group({
          service_order_number: service_order_number,
          service_order_date: service_order_date,
          start_date: start_date,
          quantity: [quantity, Validators.compose([Validators.required, Validators.min(1)])],
          term_length: [term_length, Validators.compose([Validators.required, Validators.min(1), Validators.max(100), integerValidator])],
          cost: [cost, Validators.compose([Validators.required, Validators.min(0)])],
          non_recurring_cost: [non_recurring_cost, Validators.compose([Validators.min(0)])],
          billing_frequency: [billing_frequency, Validators.compose([Validators.required, Validators.min(1), Validators.max(120), integerValidator])],
          terms_and_conditions: [terms_and_conditions, Validators.required]
        });

        this.onToggleDateKnown(service_order_date ? this.startDate.KNOWN : this.startDate.UNKNOWN);

        this.retermForm.valueChanges.takeUntil(this.destroy$).subscribe(data => {
          this.updateDisabled();
        });

        this.loaded = true;

      })
      .catch(e => this.error = e);
  }

  onToggleDateKnown(dateKnown: number) {
    this.dateKnown = dateKnown;
  }

  calculateEndsOfTerm(): void {
    let startDate = new Date(this.retermForm.get('start_date').value);
    let termLength = this.retermForm.get('term_length').value;

    if (startDate && termLength) {
      const eotRetermDate = FeDateUtil.getEndDate(startDate, termLength);
      this.eotReterm = DateTime.fromJSDate(eotRetermDate).toLocaleString(DateTime.DATE_SHORT);
    }

    this.eotCurrent = this.service.current_notification.end_date;

  }

  onBackClicked(): void {

    this.currentState--;
    if (this.currentState < this.ORDER_ID) {
      this.currentState = this.ORDER_ID;
    }

    this.retermForm.updateValueAndValidity();

    this.updateDisabled();

  }

  onContinueClicked() {

    this.currentState++;

    if (this.currentState > this.SUMMARY) {
      this.currentState = this.SUMMARY;
    }

    this.retermForm.updateValueAndValidity();

    this.calculateEndsOfTerm();

    this.updateDisabled();

  }

  onServiceOrderDateChanged(evt): void {
    //log('service order date changed', evt);
    this.retermForm.get('service_order_date').setValue(evt);
  }

  onDateChange(evt): void {
    this.retermForm.get('start_date').setValue(evt);
  }

  onExitClicked() {
    this.canceled.emit();
  }

  onRetermClicked() {
    this.updating=true;
    const financial: Financial = {
      service_order_number: this.retermForm.get('service_order_number').value,
      service_order_date: this.dateKnown === this.startDate.KNOWN ? this.retermForm.get('service_order_date').value : null,
      billing_frequency: this.retermForm.get('billing_frequency').value,
      cost: this.retermForm.get('cost').value,
      quantity: this.retermForm.get('quantity').value,
      financial_type: FINANCIAL_RETERM,
      non_recurring_cost: this.retermForm.get('non_recurring_cost').value || 0,
      start_date: this.retermForm.get('start_date').value,
      term_length: this.retermForm.get('term_length').value,
      service_term: this.retermForm.get('terms_and_conditions').value.id,
    };

    if (this.currentRetermFinancial) {
      // Remove an existing Re-Term:
      this.tenantMiService.cancelReterm(this.service._id)
        .toPromise()
        .then(res => {
          this.saveReterm(financial);
        });
    } else {
      this.saveReterm(financial);
    }

  }
  saveReterm(financial: Financial): void {
    this.tenantMiService.reterm(this.service._id, financial, this.isEdit
      ?
      NoteHtml.RETERM_EDIT_NOTE_HTML
      :
      NoteHtml.RETERM_NOTE_HTML)
      .toPromise()
      .then(res => {
        this.store.dispatch(new SDActions.SDLoadSuccess({miService: res}));
        this.store.dispatch(new NoteActions.LoadNotesAction({
          document_id: res._id,
          document_collection: MisoCollection.SERVICES,
          events: res.events
        }));
        this.retermUpdated.emit();
        this.onExitClicked();
      })
      .catch(e => this.error = e)
      .finally(() => {
        this.updating = false;
      });
  }

  onTermsAndConditionsSelected(evt: TermsAndConditions): void {
    this.retermForm.get('terms_and_conditions').setValue(evt);
    this.currentState = this.SUMMARY;
  }

  onSelectDateOpen() {
    if (!this.retermForm.get('start_date').value) {
      this.retermForm.get('service_order_date').setValue(
        (()=>{
          const today: Date = new Date();
          return `${(today.getMonth() + 1).toString().padStart(2, "0")}/${(today.getDate()).toString().padStart(2, "0")}/${today.getFullYear()}`
        })()
      );
    }
    this.dateKnown = this.startDate.KNOWN;


  }

  updateDisabled() {
    switch (this.currentState) {
      case this.TERM_LENGTH:
        this.progressDisabled = !this.retermForm.get('start_date').valid || !this.retermForm.get('term_length').valid;
        break;
      case this.COSTS:
        this.progressDisabled = !this.retermForm.get('quantity').valid || !this.retermForm.get('cost').valid || !this.retermForm.get('billing_frequency').valid;
        break;
      default:
        this.progressDisabled = !this.retermForm.get('service_order_date').valid;
    }
  }

}
