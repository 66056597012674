<mi-page-header
  title="{{ 'DATA_IMPORT.UPLOAD_DOCUMENT_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<mi-page-alert></mi-page-alert>

<mi-service-file-upload></mi-service-file-upload>
