<ng-container>

  <mi-server-error [error]="error"></mi-server-error>

  <ng-container *ngIf="loaded; else loading">

    <div class="sections">

      <!-- <ng-container>
                                      <mi-tabs-container externalClasses="reverse-color">
                                        <mi-tab tabTitle="End of Term Action (Account)" route="vendor-resolution" [withPane]="false"></mi-tab>
                                        <mi-tab tabTitle="Sales Representative (Account)" route="address-resolution" [withPane]="false"></mi-tab>
                                      </mi-tabs-container>
                                      <div>
                                        <router-outlet></router-outlet>
                                      </div>
                                    </ng-container> -->
      <!-- <section class="data-section">

        <h2>Details</h2>

        <dl *ngIf="accountId" class="section-details">

          <div>
            <dt>Account ID</dt>
            <dd>{{accountId.account.label}}</dd>
          </div>

          <div>
            <dt>Vendor</dt>
            <dd>
              <mi-id-link
                [id]="accountId.account.vendor.display_id"
                title="{{accountId.account.vendor.display_id}} - {{accountId.account.vendor.vendor_name}}">

              </mi-id-link>
              {{accountId.account.vendor.vendor_name}}
            </dd>
          </div>

          <div *ngIf="accountId.contacts">
              <dt>Account Representative</dt>
              <dd>
                <mi-id-link
                  [id]="accountId.contacts.id"
                  [display_id]="accountId.contacts.display_id"
                  title="{{accountId.contacts.display_id}} - {{accountId.contacts.first_name}} {{accountId.contacts.last_name}}">
  
                </mi-id-link>
                {{accountId.contacts.first_name}} {{accountId.contacts.last_name}}
              </dd>
            </div>
        </dl>
      </section> -->

      <!-- <mi-find-or-create-contact (contactSelected)="onAddContact($event)" *ngIf="true">
                                                      </mi-find-or-create-contact> -->
      <section class="data-section">
        <mi-account-id-service-term [accountId]="accountId">
        </mi-account-id-service-term>
      </section>
      <section class="card-1">
          <mi-account-id-service-ownership [accountId]="accountId">
          </mi-account-id-service-ownership>
      </section>
      </div>
  </ng-container>
  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>