import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiParentService} from '../../../shared/models/MiParentService';
import {MiService} from '../../../shared/models/MiService';
import {CanDelete} from '../../../shared/models/CanDelete';
import {combineLatest, of} from 'rxjs';
import {MiServiceGroup} from '../../../shared/models/MiServiceGroup';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantMiParentServiceService {

  private apiUrl = '/{tenant}/parent-services';
  private apiUrlGetById = '/{tenant}/parent-services/id';
  private apiUrlUpdate = '/{tenant}/parent-services/update';

  constructor(private http: HttpClient) {
  }

  create(parentService: MiParentService): Observable<MiParentService> {
    return this.http
      .post(this.apiUrl, parentService)
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  getById(id: string): Observable<MiParentService> {
    return this.http.get(`${this.apiUrlGetById}/${id}`)
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  getByDisplayId(displayId: string): Observable<MiParentService> {
    return this.http.get(`${this.apiUrl}/${displayId}`)
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  addService(parent_service_id: string, service_id: string): Observable<MiParentService> {
    let url: string = `${this.apiUrlGetById}/${parent_service_id}/add-service`;
    return this.http.put(url, {service_id})
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  removeService(parent_service_id: string, service_id: string): Observable<MiParentService> {
    let url: string = `${this.apiUrlGetById}/${parent_service_id}/remove-service`;
    return this.http.put(url, {service_id: service_id})
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  updateParentService(parent_service_id: string, safeData: any): Observable<MiParentService> {
    return this.http.post(`${this.apiUrlGetById}/${parent_service_id}`, safeData)
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http.get(`${this.apiUrlGetById}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteParentService(id: String): Observable<MiParentService> {
    return this.http.delete(`${this.apiUrlGetById}/${id}`)
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  addCancellation(id: String, cancellation_date: string): Observable<MiParentService> {
    return this.http.put(`${this.apiUrlGetById}/${id}/add-cancellation`, {
      cancellation_date
    })
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  removeCancellation(id: String): Observable<MiParentService> {
    return this.http.put(`${this.apiUrlGetById}/${id}/remove-cancellation`, {})
      .map((response: any) => {
        return response.data.mi_parent_service as MiParentService;
      });
  }

  confirmActivation(id: string, activation_date: string): Observable<MiParentService> {
    return this.http
      .put(`${this.apiUrlGetById}/${id}/confirm-activation`, {
        activation_date
      })
      .map((response: any) => response.data.mi_parent_service as MiParentService);
  }

  getServiceGroups(id: String, serviceGroupIds: any[]): Observable<Array<MiServiceGroup>> {
    return this.http
      .post(`${this.apiUrl}/${id}/service-groups`, {serviceGroupIds})
      .map((response: any) => response.data.mi_service_groups as MiServiceGroup[]);
  }

}
