<div class="parent-group-table card-1">
  <mi-sortable-table
    externalClasses="fullWidth"
    *ngIf="parent_service_group.service_family; else noRow"
    [columns]="parentColumns"
    [rows]="parent_service_group.service_family">
  </mi-sortable-table>

  <ng-template #noRow>
    You do not have any service.
  </ng-template>
</div>

<!-- <ng-template #removeTmpl let-row="row" let-value="value">
  <a (click)="onRemove(row)">Remove</a>
</ng-template> -->

<ng-template #removeTmpl let-row="row" let-value="value">
  <span *ngIf="!row.is_parent">
    <a (click)="onRemove(row)">Remove</a>
  </span>
  <span *ngIf="row.is_parent">
    <span>Parent</span>
  </span>
</ng-template>

<ng-template #radioTmpl let-row="row" let-value="value">
  <input type="radio"
    id="{{is_parent + '_' + value}}"
    name="is_parent"
    [value]="value"
    [(ngModel)]="parent_service_group.parent_service_display_id"
    (change)="onSelectionChange(row)">
</ng-template>

<ng-template #serviceStatusTmpl let-row="row" let-value="value">
  <span *ngIf="value">
    {{'SERVICE_STATUSES_IS_ACTIVE.' + value | translate}}
  </span>
</ng-template>