<ng-container *ngIf="orderState; else loading">

  <div class="order-docs">
    <mi-section-data-attachments
      [docs]="orderDocs"
      noResultsText="No associated order attachments, files, or documents."
      title="Associated Order Documents">
    </mi-section-data-attachments>
  </div>

  <ng-container *ngIf="activeIndex; else static">
    <h2>Service Documents</h2>
    <mi-find-or-create-vendor-documents
      [selectDocuments]="getServiceDocs()"
      [vendor]="orderState.vendor"
      (selectedVendorDocuments)="onFormSubmit($event)">
    </mi-find-or-create-vendor-documents>

  </ng-container>

  <ng-template #static>
    <div class="service-docs">
      <mi-section-data-attachments
        [docs]="serviceDocs"
        noResultsText="If you have any attachments, files, or documents associated to this service please add them here."
        title="Service Documents">
      </mi-section-data-attachments>
    </div>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

