import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {createSecureUser, ISecureUser} from '../../../common/security/secureUser';
import {throwError} from 'rxjs';
import {clearAuthCookie, getAuthCookie} from '../../user-store/state/auth-cookie';
import {AuthServiceReturn} from "../../../shared/ng-models/AuthServiceReturn.interface";

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class AuthService {

  private apirUrlGetUser = '/account/user';
  private apiUrlLogout = '/logout';

  constructor(private http: HttpClient) {
  }

  getLoggedInUser(): Observable<AuthServiceReturn> {
    if (getAuthCookie() !== null) {
      return this.http
        .get(this.apirUrlGetUser)
        .map((response: any) => response.data.user)
        .map((data: any) => {
          return {
            secureUser: createSecureUser(data)
          };
        })
        .catch(e => {
            clearAuthCookie();
            return throwError(e);
          }
        );
    } else {
      // debugger;
      return throwError('there was not a cookie set');
    }
  }

  logout(): Promise<Object> {
    return this.http
      .get(this.apiUrlLogout)
      .toPromise()
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
