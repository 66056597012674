import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiConnector} from "../../../shared/models/ApiConnectors";
import {HttpClient} from '@angular/common/http';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class MiyagiApiConnectorService
 {

  private headers = new Headers({'Content-Type': 'application/json'});

  private apiUrlCrud = '/miyagi/api-connectors';
  private apiUrlConnectorTypesGet = '/miyagi/api-connectors-db';

  constructor(private http: HttpClient) {
  }

  createApiConnector(apiConnector: ApiConnector): Observable<ApiConnector> {
    return this.http.post(this.apiUrlCrud, apiConnector)
      .map((response:any) => {
        return response.data.apiConnector as ApiConnector
      });
  }

  editApiConnector(apiConnector: ApiConnector): Observable<ApiConnector> {
    return this.http.put(`${this.apiUrlCrud}/${apiConnector._id}`, apiConnector)
      .map((response: any) => {
        return response.data.apiConnector as ApiConnector
      });
  }

  getApiConnector(apiConnectorId: string): Observable<ApiConnector> {
    return this.http.get(`${this.apiUrlCrud}/${apiConnectorId}`)
      .map((response:any) => {
        return response.data.apiConnector as ApiConnector
      });
  }

  listApiConnectors(): Observable<ApiConnector[]> {
    return this.http.get(`${this.apiUrlCrud}`)
      .map((response: any) => {
        return response.data.apiConnectors as ApiConnector[]
      });
  }

  deleteApiConnector(apiConnectorId: string): Observable<ApiConnector> {
    return this.http.delete(`${this.apiUrlCrud}/${apiConnectorId}`)
      .map((response: any) => {
        return response.data.apiConnector as ApiConnector
      });
  }

  getApiConnectorTypeKeys(): Observable<string[]> {
    return this.http.get(`${this.apiUrlConnectorTypesGet}`)
      .map((response: any) => {
        return Object.keys(response.data.apiConnectorsDB)
      });
  }

  getApiConnectorTypes(): Observable<string[]> {
    return this.http.get(`${this.apiUrlConnectorTypesGet}`)
      .map((response: any) => {
        return response.data.apiConnectorsDB
      });
  }
}
