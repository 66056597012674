<mi-side-notice noticeType="WARNING">
  <h2>{{title}}</h2>
  <p>{{subtitle}}</p>
  <p>
    <ng-container *ngFor="let dependent of dependents; index as i">
      <div *ngIf="dependent.reference_type == 'Locations'">
        <div><a routerLink="/locations/{{ dependent.id }}/details">{{ dependent.name }}</a></div>
      </div>

      <div *ngIf="dependent.reference_type == 'BusinessUnits'">
        <div><a>{{ dependent.name }}</a></div>
      </div>

      <span *ngIf="i">, </span>
      <mi-id-link *ngIf="dependent.reference_type != 'BusinessUnits' && dependent.reference_type != 'Locations' && dependent.reference_type != 'Equipments'" id="{{dependent.display_id}}"></mi-id-link>
      <mi-id-link *ngIf="dependent.reference_type == 'Equipments'" display_id="{{dependent.display_id}}" id="{{dependent.id}}"></mi-id-link>
      <span *ngIf="dependent.description">{{dependent.description}}</span>
    </ng-container>
  </p>
  <ng-content></ng-content>
  <div class="button-container">
      <a mi-button
         buttonTheme="alert-light-theme"
         (click)=onCancel()>Cancel</a>
      <!-- <button mi-button
              buttonTheme="dark-theme"
              class="login-button"
              [disabled]="true">
        Replace
      </button> -->
  </div>
</mi-side-notice>

