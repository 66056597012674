import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Vendor} from '../../../shared/models/Vendor';
import {TenantVendors} from '../../../libs/api/tenant/tenantVendors';
import {Link} from '../../../shared/ng-models/Link.interface';
import {Product} from '../../../shared/models/Product';
import {PageAlertAddAction} from '../../../shared/state/page-alert/actions';
import * as pageAlertReducer from '../../../shared/state/page-alert/reducers';
import {Store} from '@ngrx/store';
import { ProductServicesModes, ProductServices } from '../../../shared/models/ProductServices.interface';
import { Title } from '@angular/platform-browser';
import { TenantProductsService } from 'src/libs/api/tenant/tenantProducts';
import FeDateUtil from "../../../shared/utils/FeDate.util";
import linkFormatter from 'src/common/utils/linkFormatter';

@Component({
  templateUrl: './vendor-product-create.component.html'
})

export class VendorProductCreateComponent extends AbstractPageComponent implements OnInit {

  backLink: string = '';
  breadcrumbs: Link[];
  error: string = '';
  supportLink: string = linkFormatter.getSupportLink();
  vendor: Vendor;
  dummyProduct: Product;

  tenantProductServicesMode: ProductServicesModes = ProductServicesModes.TENANT;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantVendors: TenantVendors,
              private router: Router,
              private store: Store<pageAlertReducer.State>,
              private titleService: Title,
              private TenantProductsService:TenantProductsService ) {
    super();
  }

  ngOnInit() {

    const vendorId: string = this.activatedRoute.snapshot.params.vendor_display_id;

    this.backLink = `/vendors/${vendorId}/products`;

    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Vendors',
        url: '/vendors',
        internal: true
      },
      {
        label: `Vendor Details ${vendorId}`,
        url: `/vendors/${vendorId}/products`,
        internal: true
      },
      {
        label: `Create Product`,
        url: `/vendors/${vendorId}/products/create`,
        internal: true
      }
    ];

    this.activatedRoute.params
      .takeUntil(this.destroy$)
      .switchMap(params => this.tenantVendors.getVendorByDisplayId(vendorId))
      .subscribe((vendor: Vendor) => {
        this.vendor = vendor;
        this.dummyProduct = {...new Product(), vendor_id: this.vendor._id, product_vendor: this.vendor};
        this.titleService.setTitle(`Create Product - ${vendor.vendor_name} - Vendor Details ${vendor.display_id} - MISO3`);
      });
  }

  onCanceled() {
    this.router.navigateByUrl(`/vendors/${this.vendor.display_id}`);
  }

  onProductSelected(product: Product): void {
    this.store.dispatch(new PageAlertAddAction({
      message: `Product ${product.display_id} added successfully`,
        alertType: 'SUCCESS'
      }));
    //const recentlyAdded = FeDateUtil.secondsDiff(new Date(), product.created_at ) < 10;
    //console.log(new Date())
    //console.log(product.created_at)
    //if(recentlyAdded) {
    //  this.store.dispatch(new PageAlertAddAction({
    //    message: `Product added successfully`,
    //    alertType: 'SUCCESS'
    //  }));
    //} else {
    //  this.store.dispatch(new PageAlertAddAction({
    //    message: `Product, "${product.display_id}", already exists on this vendor.`,
    //    alertType: 'SUCCESS'
    //  }));
    //}

    this.router.navigateByUrl(this.backLink);
  }

}
