<div class="address" (click)="onDeselected()">
  <input type="checkbox"
         [checked]="isChecked"
         [value]="location.id"/>
  <div>
    {{ location.address.formatted_address }} <br />
    {{ location.address.street_address }}
    {{location.nick_name ? '(' + location.nick_name +')': ''}}
  </div>
  <!--<div class="address-id">
    {{location.display_id}}
  </div>-->
</div>
