import {Component, EventEmitter, Input, Output} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {MiOrder} from "../../../../../shared/models/MiOrder";
import {TenantMiOrderService} from "../../../../../libs/api/tenant/tenantMiOrder.service";

@Component(
  {
    selector: 'mi-service-order-summary-documents',
    templateUrl: './service-order-summary-documents.component.html'
  }
)

export class ServiceOrderSummaryDocumentsComponent extends AbstractPageComponent {

  @Input() editable: boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiOrder> = new EventEmitter<MiOrder>();
  isOpen: boolean = false;

  _order: MiOrder;
  loaded:boolean = false;
  saveError: any = '';

  constructor(private tenantMiOrderService: TenantMiOrderService) {
    super();
  }


  @Input() set order(value: MiOrder) {
    this.loaded = true;
    this._order = value;

    this.updateService();
  }

  onDocumentSelected(data: any[]) {

    let newData: any = {
      _id: this._order._id,
      associated_documents: data
    };

    this.tenantMiOrderService.updateOrder(newData)
      .subscribe(
        (orderRes: MiOrder) => {
          this.isOpen = false;
          this.dataUpdate.emit(orderRes);
        },
        (e) => this.saveError = e
      );
  }

  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

  updateService() {
    this.loaded = true;
  }

  getSelectedDocuments(){
    return this._order.associated_documents?this._order.associated_documents.map(d=> d.id):[];
  }

}
