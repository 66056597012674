import {Component, Input, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import * as ASReducer from "../../../state/advanced-search/reducers";
import * as ASActions from "../../../state/advanced-search/actions";
import * as appDataReducer from "../../../state/app-data/reducers";
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {TenantSearchService} from "../../../../libs/api/tenantSearch/tenantSearch.service";
import {FilterBucket} from "../../../ng-models/search/FilterBucket.interface";
import {EVENT_TYPES} from "../../../../common/utils/sharedConstants";
import { TenantCustomFieldService } from "src/libs/api/tenant/tenantCustomFields.service";
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { ModalRef } from '@independer/ng-modal';
import { TenantMiServiceGroupService } from 'src/libs/api/tenant/tenantMiServiceGroup.service';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { TenantAccountIdService } from "src/libs/api2/account-id-v2/account-id-v2.service";
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { combineLatest } from 'rxjs';

@Component({
  styleUrls: ['./filter-group-overlay.component.scss'],
  templateUrl: './filter-group-overlay.component.html'
})

export class FilterGroupOverlayComponent extends AbstractPageComponent implements OnInit {

  modalId: string;
  error: string;
  filterName: string;
  filters: FilterBucket[];
  loaded: boolean = false;
  modalTitle: string;
  selectedFilters: string[];
  readonly  EVENT_TYPES: any = EVENT_TYPES;
  filterLabel: string;
  customFields: any = {};
  businessUnitsByIDs: any = {};
  accountIdsByIDs: any = {};
  searchType: string;
  filterType: string;
  serviceGroupByIDs: any = {};
  contactsByIDs: any = {};

  constructor(
    private modal: ModalRef,
    private store: Store<ASReducer.State>,
    private tenantService: TenantV2Service,
    private tenantSearchService: TenantSearchService,
    private tenantAccountIdService:TenantAccountIdService,
    private tenantCustomFieldService: TenantCustomFieldService,
    private tenantMiServiceGroupService: TenantMiServiceGroupService,
    private contactV2Service: ContactV2Service,
    private tenantVendorService: TenantVendors
  ) {
    super();
  }

  ngOnInit(): void {

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.filterGroup) {
            // Get the data and open the modal:
            let filterGroup = data.isCustomField ? ("custom_properties." + data.filterGroup + ".keyword") : data.filterGroup
            this.filterName = data.filterGroup;
            this.modalTitle = data.filterGroup.toUpperCase();
            this.searchType = data.searchType;
            this.filterLabel = data.filterLabel;
            this.selectedFilters = data.selectedFilters;
            if( ['mrc', 'price', 'unit_non_recurring_cost', 'unit_recurring_cost', 'days_until_event', 'days_since_last_update'].indexOf(this.filterName) >= 0){
              this.filterType = "NUMBER";
              this.store.dispatch(new ASActions.AsFilterClearName({}));
              this.loaded = true;
            } else 
            if( ['notification_date', 'order_date', 'end_date'].indexOf(this.filterName) >= 0){
              this.filterType = "DATE";
              this.store.dispatch(new ASActions.AsFilterClearName({}));
              this.loaded = true;
            }  else
            if (this.filterName==="location_id"){
              this.filterType = "LOCATIONS";
              this.store.dispatch(new ASActions.AsFilterClearName({}));
              this.loaded = true;
            } else
            if(this.modalId==='equipment_filterOverlay'){
              this.tenantSearchService.allOfEquipmentFilterByName(filterGroup)
                .toPromise()
                .then(data => {
                  this.filters = data.aggregations.all_of_filter.buckets.map((bucket: FilterBucket) => {
                    bucket.selected = !!this.selectedFilters.find(cb => {
                      return cb['key'] === bucket.key
                    });
                    return bucket;
                  }).sort((a, b) => {
                    return a.key > b.key ? 1 : -1;
                  });
                  this.store.dispatch(new ASActions.AsFilterClearName({}));
                  this.loaded = true;
              })
            } else
            if(this.modalId==='address_filterOverlay'){
              if(this.filterName==="locations"){
                this.filterType = "LOCATIONS";
                this.store.dispatch(new ASActions.AsFilterClearName({}));
                return this.loaded = true
              }
              this.tenantSearchService.allOfAddressFilterByName(filterGroup)
                .toPromise()
                .then(data => {
                  this.filters = data.aggregations.all_of_filter.buckets.map((bucket: FilterBucket) => {
                    bucket.selected = !!this.selectedFilters.find(cb => {
                      return cb['key'] === bucket.key
                    });
                    return bucket;
                  }).sort((a, b) => {
                    return a.key > b.key ? 1 : -1;
                  });
                  this.store.dispatch(new ASActions.AsFilterClearName({}));
                  this.loaded = true;
              })
            } else
            if(this.modalId==='contact_filterOverlay'){
              this.filters = data.results.aggregations[this.filterName].buckets.map((bucket: FilterBucket) => {
                bucket.selected = !!this.selectedFilters.find(cb => {
                  return cb['key'] === bucket.key
                });
                return bucket;
              }).sort((a, b) => {
                return a.key > b.key ? 1 : -1;
              });
              if(this.filterName == "service_associations"){
                this.tenantCustomFieldService.listCustomFields().takeUntil(this.destroy$).subscribe(
                  (allCustomFields) => {
                    allCustomFields.map(customField => this.customFields[customField._id] = customField);
                    this.store.dispatch(new ASActions.AsFilterClearName({}));
                    this.loaded = true;
                  },
                  (e) => this.error = e
                );
              }
              if(this.filterName === "business_unit_id" || 
                this.filterName === "cost_center_id" ||
                this.filterName === "division" ||
                this.filterName === "team"){
                this.getBus();
              }
              else {
                this.store.dispatch(new ASActions.AsFilterClearName({}));
                this.loaded = true;
              }
            } else {
              if(data.isCustomField){
                this.tenantCustomFieldService.listCustomFields().takeUntil(this.destroy$).subscribe(
                  (allCustomFields) => {
                    allCustomFields.map(customField => this.customFields[customField._id] = customField);
                    const currentCustomField = allCustomFields.find(cf => cf._id === this.filterName);
                    if(currentCustomField.type === 'NUMBER' || currentCustomField.type === 'DATE'){
                      this.filterType = currentCustomField.type;
                      this.store.dispatch(new ASActions.AsFilterClearName({}));
                      this.loaded = true;
                    }else {
                      this.filters = data.results.aggregations[this.filterName].buckets.map((bucket: FilterBucket) => {
                        bucket.selected = !!this.selectedFilters.find(cb => {
                          return cb['key'] === bucket.key
                        });
                        return bucket;
                      }).sort((a, b) => {
                        return a.key > b.key ? 1 : -1;
                      });
                      this.store.dispatch(new ASActions.AsFilterClearName({}));
                      this.loaded = true;      
                    }
                  },
                  (e) => this.error = e
                );
              }  else if (this.filterName === "locations"){
                this.filterType = "LOCATIONS";
                this.store.dispatch(new ASActions.AsFilterClearName({}));
                this.loaded = true;
              } else {
                // if(this.filterName==="all_product_categories"){
                //   this.filterType = "SERVICE_CATEGORY";
                // }
                this.filters = data.results.aggregations[this.filterName].buckets.map((bucket: FilterBucket) => {
                  bucket.selected = !!this.selectedFilters.find(cb => {
                    return cb['key'] === bucket.key
                  });
                  return bucket;
                }).sort((a, b) => {
                  return a.key > b.key ? 1 : -1;
                });
                if(this.filterName === "business_unit_id" || 
                  this.filterName === "cost_center_id" ||
                  this.filterName === "division" ||
                  this.filterName === "team"){
                  this.getBus();
                } else if (this.filterName === "account_id") {
                  this.getAccountId();
                } else if (this.filterName === "contact") {
                  this.getContacts();
                } else if(this.filterName === "service_group_id") {
                  this.getServiceGroup();
                } else {
                  this.store.dispatch(new ASActions.AsFilterClearName({}));
                  this.loaded = true;
                }
              }
            }
          }
        });
  }

  getContacts(){
    return this.contactV2Service.getContactsByVendor('')
    .takeUntil(this.destroy$)
    .toPromise()
    .then(contacts => {
      contacts.map(contact => {
        this.contactsByIDs[contact.id] = contact;
      });

      this.store.dispatch(new ASActions.AsFilterClearName({}));
      this.loaded = true;
      return;
    });
  }

  getServiceGroup(){
    return this.tenantMiServiceGroupService.list()
      .takeUntil(this.destroy$)
      .toPromise()
      .then(groups => {
        if(!groups){
          return;
        }
        groups.map(groups => {
          this.serviceGroupByIDs[groups._id] = groups;
        })
        this.store.dispatch(new ASActions.AsFilterClearName({}));
        this.loaded = true;

        return;
      },
      e => this.error = e);
  }

  getServiceStatusCount(status){
    if(status==="ARCHIVED"){
      let inactiveFilter = this.filters.find(filter => filter.key===status);
      return inactiveFilter.doc_count;
    } else {
      let inactiveFilters = this.filters.filter(filter => filter.key!=='ARCHIVED');
      return inactiveFilters.reduce(function (acc, obj) { return acc + obj.doc_count; }, 0);
    }
  }

  getBus(){
    const tenantStateData = this.store.select(appDataReducer.getAppData);

    tenantStateData
      .first()
      .subscribe((data)=>{
        this.tenantService.getBusinessUnits({owner: data.tenantData.domain})
        .takeUntil(this.destroy$)
        .subscribe(bus => {
          bus.map(bu => {
            this.businessUnitsByIDs[bu.id] = bu;
          })
          this.store.dispatch(new ASActions.AsFilterClearName({}));
          this.loaded = true;
        })
      },
      e => this.error = e);
  }

  getAccountId(){
    let allAccountidsPromis = this.tenantAccountIdService.getByTenantId();
    let allVendorsPromis = this.tenantVendorService.listVendors();
    combineLatest(allAccountidsPromis, allVendorsPromis)
      .takeUntil(this.destroy$)
      .subscribe(data => {
        //debugger;
        let account_ids = data[0];
        let allVendors = data[1];
        ;
        account_ids.map(account_id => {
          if(allVendors&&!account_id.vendor){
            account_id.vendor = allVendors.find(v => v.id == account_id.vendor_id);
          }
          this.accountIdsByIDs[account_id.id] = account_id;
        });
        this.store.dispatch(new ASActions.AsFilterClearName({}));
        this.loaded = true;
      },
      e => this.error = e);
  }

  close() {
    this.modal.close('User closed');
  }

  onModalClose(): void {
    this.close();
    this.filters = [];
    this.loaded = false;
  }

  onToggleFilter(evt): void {
    this.filters.forEach((filter: FilterBucket) => {
      if (filter.key === evt.target.value) {
        filter.selected = evt.target.checked;
        return true;
      }
      return false;
    });
  }

  onUpdateFilters(): void {

    let newQuery = {};
    newQuery[this.filterName] = this.filters
      .filter((filterBucket: FilterBucket) => {
        return filterBucket.selected;
      })
      .map((filterBucket: FilterBucket) => {
        return filterBucket.key;
      });

      console.log("newQuery", newQuery);

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

    this.close();

  }
}


