import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import { CanDelete } from "../../../../../shared/models/CanDelete";
import { PageAlertAddAction } from "../../../../../shared/state/page-alert/actions";
import { Store } from "@ngrx/store";
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import { TenantMiParentServiceService } from "../../../../../libs/api/tenant/tenantMiParentService.service";
import { MiParentService } from "../../../../../shared/models/MiParentService";

@Component({
  templateUrl: './parent-service-delete.component.html',
  styleUrls: ['./parent-service-delete.component.scss']
})

export class ParentServiceDeleteComponent extends AbstractPageComponent implements OnInit {

  parentServiceId: string;
  error: string;
  exitRoute: string;
  canDelete: any;
  canDeleteMode: boolean = true;

  constructor(private activatedRoute: ActivatedRoute,
              private tpsService: TenantMiParentServiceService,
              private router: Router,
              private store: Store<ASReducer.State>) {
    super();
  }

  ngOnInit() {
    this.parentServiceId = this.activatedRoute.parent.snapshot.params.display_id || null;
    this.exitRoute = `/parent-services/${this.parentServiceId}/edit`;
    this.tpsService.getByDisplayId(this.parentServiceId)
      .toPromise()
      .then((parentService: MiParentService) => this.tpsService.canDelete(parentService._id).toPromise())
      .then((res: CanDelete) => {
        this.canDelete = res;
        if(res.dependents.length){
          this.canDeleteMode = false;
        }
      })
      .catch(e => this.error = e)
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete(){
    this.tpsService.deleteParentService( this.canDelete.delete._id )
    .subscribe(() =>{
        this.store.dispatch(
          new PageAlertAddAction({
            message: `Parent Service was successfully deleted.`,
            alertType: "SUCCESS"
          })
        );
        this.router.navigate([`/services`]);
      },
      e=> this.error = e
    )
  }

}
