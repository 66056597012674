import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { EVENT_TYPES } from "src/common/utils/sharedConstants";
import { ServiceV2Service } from "src/libs/api2/service-v2/service-v2.service";
import { ServiceListingColumnsCustom, ServiceListingColumnsInitial, ServiceV2Filter, ServiceV2FilterRequest, ServiceV2FilterResponse, ServiceV2LocationFilterResponse, ServiceV2LocationFilterResponseDisplay, ServiceV2LocationFilterResponseItem, ServiceV2Request, ServiceV2Response, ServiceV2ResponseData } from "src/shared/models/ServiceV2";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../../shared/state/service-v2/actions';
import { ModalRef } from "@independer/ng-modal";

interface filterSelection {
  label: string;
  selected: boolean;
  type?: string;
}

@Component({
  styleUrls: ['./filter-group-overlay-v2.component.scss'],
  templateUrl: './filter-group-overlay-v2.component.html'
})

export class FilterGroupOverlayV2Component extends AbstractPageComponent implements OnInit {
  modalId: string;
  error: string;
  filterName: string;
  filters: filterSelection[] = [];
  loaded: boolean = false;
  modalTitle: string;
  selectedFilters: string[];
  readonly  EVENT_TYPES: any = EVENT_TYPES;
  filterLabel: string;
  customFields: any = {};
  businessUnitsByIDs: any = {};
  accountIdsByIDs: any = {};
  searchType: string;
  filterType: string;
  serviceGroupByIDs: any = {};
  contactsByIDs: any = {};
  isCustomField: boolean = false;
  existingFilters: ServiceV2Filter[];

  isTag: boolean = false;
  isTagTypeOption: boolean = false;
  tagById: any = {};

  rangeMinPrefill: number = null;
  rangeMaxPrefill: number = null;
  dateRangeMinPrefill: string = null;
  dateRangeMaxPrefill: string = null;
  optionFilterSearch: string = "";
  locationFilterSearch: any = {
    country: "",
    state: "",
    city: ""
  };

  filterRequest: ServiceV2FilterRequest = {
    current_filter: {
      field: "",
      is_custom_field: false,
      is_tag: false
    },
    existing_filters: [],
    search: "",
    tenant_id: ""
  }

  filterResponse: ServiceV2FilterResponse;
  displayFilterResponse: ServiceV2FilterResponse = {
    field: "",
    options: []
  };

  locationFilterResponse: ServiceV2LocationFilterResponse;
  locationCountryDisplayFilterResponse: ServiceV2LocationFilterResponseDisplay = {
    field: "Country",
    options: []
  };
  locationStateDisplayFilterResponse: ServiceV2LocationFilterResponseDisplay = {
    field: "State / Province",
    options: []
  };
  locationCityDisplayFilterResponse: ServiceV2LocationFilterResponseDisplay = {
    field: "City",
    options: []
  };
  locationFilterEmpty: boolean = false;

  request: ServiceV2Request = {
    pageSize: 0,
    page_number: 0,
    tenant_id: "",
    search: "",
    sort: {},
    filters: []
  };
  serviceData: ServiceV2Response;

  constructor(
    private serviceV2Service: ServiceV2Service,
    private store: Store<ServiceV2Reducer.State>,
    private modal: ModalRef
  ) {
    super();
  }

  ngOnInit(): void {
    console.log("filterName", this.filterName);
    this.store.select(ServiceV2Reducer.getServiceV2Data)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          console.log("store data", data);
          this.serviceData = data.results;
          this.existingFilters = data.results.filters;

          
        }
      );

    //this.calculateExistingFilters();
        
    if(['cmrc', 'price', 'unit_recurring_cost', 'days_to_event', 'days_since_last_update', 'liability', 'price_increase_percentage'].indexOf(this.filterName) >= 0) {
      this.filterType = "NUMBER";
    }
    else if(['notification_date', 'order_date', 'end_of_term_date', 'termination_date'].indexOf(this.filterName) >= 0) {
      this.filterType = "DATE";
    }
    else if(this.filterName === "location_a") {
      this.filterType = "LOCATION";
    }
    else if(this.isTag && this.isTagTypeOption) {
      this.filterType = "OPTION";
      this.filterLabel = this.tagById[this.filterName];
    }
    else {
      this.filterType = "OPTION";
      if(this.isCustomField) {

      }
      else {

      }
    }

    this.getFilterOptions();
  }

  calculateExistingFilters(): void {
    this.filters = [];
    console.log("in calculateExistingFilters");
    console.log("existingFilters", this.existingFilters);
    console.log("filterResponse", this.filterResponse);
    console.log("filterName", this.filterName);
    console.log("filterType", this.filterType);
    let foundExisting = false;
    this.existingFilters.forEach(existingFilter => {
      console.log("existingFilter", existingFilter);
      if(existingFilter.field_name == this.filterName && this.filterType == "OPTION") {
        foundExisting = true;
        console.log("true");
        this.filterResponse.options.forEach(item => {
          if(existingFilter.values.indexOf(item.label) != -1) {
            item.selected = true;
            this.filters.push({
              label: item.label,
              selected: true
            });
          }
          else {
            item.selected = false;
            this.filters.push({
              label: item.label,
              selected: false
            });
          }
          console.log("item", item);
        })
      }
      else if(existingFilter.field_name == this.filterName && this.filterType == "NUMBER") {
        this.rangeMaxPrefill = existingFilter.range_max;
        this.rangeMinPrefill = existingFilter.range_min;
      }
      else if(existingFilter.field_name == this.filterName && this.filterType == "DATE") {
        this.dateRangeMaxPrefill = existingFilter.date_range_max;
        this.dateRangeMinPrefill = existingFilter.date_range_min;
      }
    })
    if(foundExisting == false && this.filterType == "OPTION") {
      this.filterResponse.options.forEach(item => {
        this.filters.push({
          label: item.label,
          selected: false
        });
      });
    }
  }

  calculateExistingLocationFilters(): void {
    this.filters = [];
    console.log("in calculateExistingLocationFilters");
    console.log("existingFilters", this.existingFilters);
    console.log("locationFilterResponse", this.locationFilterResponse);
    console.log("filterName", this.filterName);
    console.log("filterType", this.filterType);
    let foundExisting = false;

    this.existingFilters.forEach(existingFilter => {
      console.log("existingFilter", existingFilter);
      if(existingFilter.field_name == this.filterName) {
        foundExisting = true;

        this.locationFilterResponse.country.forEach(item => {
          if(existingFilter.locations.country.indexOf(item.label) != -1) {
            item.selected = true;
            this.filters.push({
              label: item.label,
              selected: true,
              type: "country"
            });
          }
          else {
            item.selected = false;
            this.filters.push({
              label: item.label,
              selected: false,
              type: "country"
            });
          }
        });

        this.locationFilterResponse.state.forEach(item => {
          if(existingFilter.locations.state.indexOf(item.label) != -1) {
            item.selected = true;
            this.filters.push({
              label: item.label,
              selected: true,
              type: "state"
            });
          }
          else {
            item.selected = false;
            this.filters.push({
              label: item.label,
              selected: false,
              type: "state"
            });
          }
        });

        this.locationFilterResponse.city.forEach(item => {
          if(existingFilter.locations.city.indexOf(item.label) != -1) {
            item.selected = true;
            this.filters.push({
              label: item.label,
              selected: true,
              type: "city"
            });
          }
          else {
            item.selected = false;
            this.filters.push({
              label: item.label,
              selected: false,
              type: "city"
            });
          }
        });
      }
    });

    if(foundExisting == false && this.filterType == "LOCATION") {
      this.locationFilterResponse.country.forEach(item => {
        this.filters.push({
          label: item.label,
          selected: false,
          type: "country"
        });
      });

      this.locationFilterResponse.state.forEach(item => {
        this.filters.push({
          label: item.label,
          selected: false,
          type: "state"
        });
      });

      this.locationFilterResponse.city.forEach(item => {
        this.filters.push({
          label: item.label,
          selected: false,
          type: "city"
        });
      });
    }

    console.log("filters", this.filters);    
  }
  
  setDefaultFilterRequestParams(): void {
    this.filterRequest.tenant_id = FrontEndSettingsUtil.getDomain();
    this.filterRequest.current_filter.field = this.filterName;
    this.filterRequest.current_filter.is_custom_field = this.isCustomField;
    this.filterRequest.current_filter.is_tag = this.isTag;
    this.filterRequest.search = this.serviceData.search;
  }

  getFilterOptions(): void {
    this.filterRequest.existing_filters = this.existingFilters;

    if(this.filterType != "OPTION") {
      if(this.filterType == "LOCATION") {
        this.getLocationFilterOptions();
        return;
      }
      this.calculateExistingFilters();      
      this.loaded = true;
      return;
    }
    this.setDefaultFilterRequestParams();

    this.serviceV2Service.getFilters(this.filterRequest)
    .toPromise()
    .then(response => {
      console.log("response", response);
      this.filterResponse = response;
      this.displayFilterResponse.field = response.field;
      this.displayFilterResponse.options = [...response.options];
      this.calculateExistingFilters();
      this.loaded = true;
    })
    .catch(err => {
      console.log("error while getFilters", err);
      this.error = err;
    })
  }

  getLocationFilterOptions(): void {
    this.setDefaultFilterRequestParams();

    this.serviceV2Service.getLocationFilters(this.filterRequest)
    .toPromise()
    .then(response => {
      console.log("response", response);
      this.locationFilterResponse = response;
      this.locationCountryDisplayFilterResponse.options = [...response.country];
      this.locationStateDisplayFilterResponse.options = [...response.state];
      this.locationCityDisplayFilterResponse.options = [...response.city];
      //console.log("locationCountryDisplayFilterResponse", this.locationCountryDisplayFilterResponse);
      if(this.locationCountryDisplayFilterResponse.options.length == 0 
        && this.locationStateDisplayFilterResponse.options.length == 0
        && this.locationCityDisplayFilterResponse.options.length == 0) {
          this.locationFilterEmpty = true;
        }
      this.calculateExistingLocationFilters();
      this.loaded = true;
    })
    .catch(err => {
      console.log("error while getLocationFilters", err);
      this.error = err;
    })
  }

  onToggleFilter($event) {
    console.log("$event value", $event.target.value);
    console.log("$event checked", $event.target.checked);
    if($event.target.checked) {
      this.filters.forEach(filter => {
        if(filter.label == $event.target.value) {
          filter.selected = true;
        }
      });
      this.filterResponse.options.forEach(filter => {
        if(filter.label == $event.target.value) {
          filter.selected = true;
        }
      });
    }
    else {
      this.filters.forEach(filter => {
        if(filter.label == $event.target.value) {
          filter.selected = false;
        }
      });
      this.filterResponse.options.forEach(filter => {
        if(filter.label == $event.target.value) {
          filter.selected = false;
        }
      });
    }

    console.log("filters", this.filters);
  }

  onToggleLocationFilter($event, type): void {
    console.log("$event value", $event.target.value);
    console.log("$event checked", $event.target.checked);
    console.log("location type", type);
    if($event.target.checked) {
      this.filters.forEach(filter => {
        if(filter.type == type) {
          if(filter.label == $event.target.value) {
            filter.selected = true;
          }
        }
      });
      this.locationFilterResponse[type].forEach((filter: ServiceV2LocationFilterResponseItem) => {
        if(filter.label == $event.target.value) {
          filter.selected = true;
        }
      });
    }
    else {
      this.filters.forEach(filter => {
        if(filter.type == type) {
          if(filter.label == $event.target.value) {
            filter.selected = false;
          }
        }
      });
      this.locationFilterResponse[type].forEach((filter: ServiceV2LocationFilterResponseItem) => {
        if(filter.label == $event.target.value) {
          filter.selected = false;
        }
      });
    }

    console.log("filters", this.filters);
  }

  setDefaultRequestParams() {
    this.request.pageSize = 25;
    this.request.page_number = 0;
    this.request.tenant_id = FrontEndSettingsUtil.getDomain();
    this.request.sort = this.serviceData.sort;
    this.request.filters = this.existingFilters;
    this.request.search = this.serviceData.search;
  }

  onUpdateFilters() {
    console.log("onUpdateFilters");
    console.log("filterType", this.filterType);

    this.setDefaultRequestParams();

    let foundExisting: boolean = false;

    
    if(this.filterType == "LOCATION") {
      
      this.request.filters.forEach(filter => {
        if(filter.field_name == this.filterName) {
          foundExisting = true;
          filter.locations.country = [];
          filter.locations.state = [];
          filter.locations.city = [];
          this.filters.forEach(newFilterValue => {
            if(newFilterValue.selected) {
              console.log("selected filter", newFilterValue);
              switch(newFilterValue.type) {
                case "country":
                  filter.locations.country.push(newFilterValue.label);
                  break;
                case "state":
                  filter.locations.state.push(newFilterValue.label);
                  break;
                case "city":
                  filter.locations.city.push(newFilterValue.label);
                  break;
              }
            }
            
          });
        }
      });
      
      
      if(!foundExisting) {
        let countryValues: string[] = [];
        let stateValues: string[] = [];
        let cityValues: string[] = [];
        this.filters.forEach(filter => {
          if(filter.selected) {
            switch(filter.type) {
              case "country":
                countryValues.push(filter.label);
                break;
              case "state":
                stateValues.push(filter.label);
                break;
              case "city":
                cityValues.push(filter.label);
                break;
            }
            //newValues.push(filter.label);
          }
        });

        let newFilter: ServiceV2Filter = {
          field_name: this.filterName,
          type: "option",
          is_custom_field: this.isCustomField,
          is_tag: this.isTag,
          is_location: true,
          locations: {
            country: countryValues,
            state: stateValues,
            city: cityValues
          },
          removable: true
        };

        if(this.request.filters) {
          this.request.filters.push(newFilter);
        }
        else {
          this.request.filters = [];
          this.request.filters.push(newFilter);
        }

      };

    console.log("request.filters", this.request.filters);
     
    this.updateServices(this.request);

    this.close();
    return;
    }

    this.request.filters.forEach(filter => {
      if(filter.field_name == this.filterName) {
        foundExisting = true;
        filter.values = [];
        this.filters.forEach(newFilterValue => {
          if(newFilterValue.selected) {
            filter.values.push(newFilterValue.label);
          }
        });
        if(filter.values.length == 0) {
          this.request.filters.splice(this.request.filters.indexOf(filter), 1);
        }
      }
    });

    if(!foundExisting) {
      let newValues: string[] = [];
      this.filters.forEach(filter => {
        if(filter.selected) {
          newValues.push(filter.label);
        }
      });
      let newFilter: ServiceV2Filter = {
        field_name: this.filterName,
        type: "option",
        is_custom_field: this.isCustomField,
        is_tag: this.isTag,
        is_location: false,
        values: newValues,
        removable: true
      };
      
      if(this.request.filters) {
        this.request.filters.push(newFilter);
      }
      else {
        this.request.filters = [];
        this.request.filters.push(newFilter);
      }
    }

    this.updateServices(this.request);

    this.close();
  }

  setLoadMoreStatus(data: any[]): void {
    if(data.length > 0) {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(true));
    }
    else {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(false));
    }
  }

  combineData(data1: ServiceV2ResponseData[], data2: ServiceV2ResponseData[]): any {
    let combinedData: ServiceV2ResponseData[] = [];
   
   data1.forEach((data, index) => {
    data.miso_service.custom_fields = data2[index].miso_service.custom_fields;
    data.miso_service.tags = data2[index].miso_service.tags;
   });

   return data1;
  }

  formatData(data: ServiceV2ResponseData[]): any {
    //console.log("formatData", data);

    data.map(service => {
      if(service.miso_service.tags.length > 0) {
        let spliceList: number[] = [];
        
        for(let i=0; i<service.miso_service.tags.length; i++) {
          let tagId = service.miso_service.tags[i].tag_id;
          for(let j=0; j<service.miso_service.tags.length; j++) {
            let comparedTagId = service.miso_service.tags[j].tag_id;
            //console.log("formatData tagId", tagId);
            //console.log("formatData comparedTagId", comparedTagId);
            if(j == i) continue;
            if((tagId == comparedTagId) && (spliceList.indexOf(i) == -1)) {
              service.miso_service.tags[i].tag_value += ", " + service.miso_service.tags[j].tag_value;
              spliceList.push(j);
            }
          }
        }

        //console.log("formatData spliceList", spliceList);

        spliceList.map(spliceIndex => {
          service.miso_service.tags.splice(spliceIndex, 1);
        });
      }
    });

    return data;
  }

  updateServices(request) {
    console.log("UPDATING SERVICES", request);
    //request.cursor = 0;
    request.columns = ServiceListingColumnsInitial;
    this.store.dispatch(new ServiceV2Actions.SetSearchLoading(this.serviceData));
    this.serviceV2Service.getServices(request)
    .toPromise()
    .then(res => {
      console.log("res", res);
      this.setLoadMoreStatus([...res.data]);
      this.serviceData = res;
      this.serviceData.filters = this.request.filters;
      this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
      this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(this.serviceData));
      this.serviceV2Service.getTotalCmrc(request)
      .toPromise()
      .then((totalCmrc: any) => {
        this.serviceData.total_mrc = totalCmrc.total_cmrc;
        this.serviceData.total = totalCmrc.total_service_count;
        this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));

        this.request.columns = ServiceListingColumnsCustom;
        this.serviceV2Service.getServices(this.request)
        .toPromise()
        .then((response: ServiceV2Response) => {
          let combinedData = this.combineData([...this.serviceData.data], [...response.data]);
          //console.log("combinedData", combinedData);
          let formattedData = this.formatData([...combinedData]);
          console.log("formattedData", formattedData);
          this.serviceData.data = formattedData;
          this.store.dispatch(new ServiceV2Actions.SetServiceResults(this.serviceData));
        })
        .catch(error => {
          console.log("error while getServices_2", error);
          this.error = error;
        });

      })
      .catch(error => {
        console.log("error while getCmrc", error);
        this.error = error;
      });
    })
    .catch(err => {
      console.log("error while getServices", err);
      this.error = err;
    });
  }

  close() {
    this.modal.close('User closed');
  }

  applyNumberFilter($event) {
    console.log("$event", $event);
    this.setDefaultRequestParams();

    let foundExisting: boolean = false;

    this.request.filters.forEach(filter => {
      if(filter.field_name == this.filterName) {
        foundExisting = true;
        filter.range_max = $event.max;
        filter.range_min = $event.min;
      }
    });

    if(!foundExisting) {
      let newFilter: ServiceV2Filter = {
        field_name: this.filterName,
        type: "range",
        is_custom_field: this.isCustomField,
        is_tag: this.isTag,
        is_location: false,
        range_max: $event.max,
        range_min: $event.min
      };
      
      if(this.request.filters) {
        this.request.filters.push(newFilter);
      }
      else {
        this.request.filters = [];
        this.request.filters.push(newFilter);
      }
    }

    this.updateServices(this.request);

    this.close();
  }

  applyDateFilter($event) {
    console.log("$event", $event);
    this.setDefaultRequestParams();

    let foundExisting: boolean = false;

    this.request.filters.forEach(filter => {
      if(filter.field_name == this.filterName) {
        foundExisting = true;
        filter.date_range_max = $event.max;
        filter.date_range_min = $event.min;
      }
    });

    if(!foundExisting) {
      let newFilter: ServiceV2Filter = {
        field_name: this.filterName,
        type: "date_range",
        is_custom_field: this.isCustomField,
        is_tag: this.isTag,
        is_location: false,
        date_range_max: $event.max,
        date_range_min: $event.min
      };
      
      if(this.request.filters) {
        this.request.filters.push(newFilter);
      }
      else {
        this.request.filters = [];
        this.request.filters.push(newFilter);
      }
    }

    this.updateServices(this.request);

    this.close();
  }

  onModalClose() {
    this.close();
  }

  onOptionFilterChange($event) {
    if(this.optionFilterSearch.length == 0) {
      this.displayFilterResponse.options = [...this.filterResponse.options];
      return;
    }
    let tempOptions = [...this.filterResponse.options];
    this.displayFilterResponse.options = [];
    //console.log("filterResponse", this.filterResponse);
    //console.log("displayFilterResponse", this.displayFilterResponse);
    //console.log("optionFilterSearch", this.optionFilterSearch);
    let keepIndexes = [];
    tempOptions.forEach(option => {
      //console.log("option", option);
      //console.log("option part", option.label.substring(0, this.optionFilterSearch.length).toLowerCase());
      if(option.label.substring(0, this.optionFilterSearch.length).toLowerCase() ==  this.optionFilterSearch.toLowerCase()) {
        keepIndexes.push(tempOptions.indexOf(option));        
      }
    });
    tempOptions.forEach((option, index) => {
      if(keepIndexes.indexOf(index) != -1) {
        this.displayFilterResponse.options.push(option);
      }
    });
    //console.log("displayFilterResponse", this.displayFilterResponse);
  }

  onLocationFilterChange($event, type): void {
    //console.log("type", type);
    //console.log("locationFilterSearch", this.locationFilterSearch);

    if(this.locationFilterSearch[type].length == 0) {
      switch(type) {
        case "country":
          this.locationCountryDisplayFilterResponse.options = [...this.locationFilterResponse.country];
          break;
        case "state":
          this.locationStateDisplayFilterResponse.options = [...this.locationFilterResponse.state];
          break;
        case "city":
          this.locationCityDisplayFilterResponse.options = [...this.locationFilterResponse.city];
          break;
      }
      return;
    }

    let tempOptions = [];
    let keepIndexes = [];

    switch(type) {
      case "country":
        tempOptions = [...this.locationFilterResponse.country];
        this.locationCountryDisplayFilterResponse.options = [];
        keepIndexes = [];
        tempOptions.forEach(option => {
          if(option.label.substring(0, this.locationFilterSearch[type].length).toLowerCase() ==  this.locationFilterSearch[type].toLowerCase()) {
            keepIndexes.push(tempOptions.indexOf(option));        
          }
        });
        tempOptions.forEach((option, index) => {
          if(keepIndexes.indexOf(index) != -1) {
            this.locationCountryDisplayFilterResponse.options.push(option);
          }
        });
        break;
      case "state":
        tempOptions = [...this.locationFilterResponse.state];
        this.locationStateDisplayFilterResponse.options = [];
        keepIndexes = [];
        tempOptions.forEach(option => {
          if(option.label.substring(0, this.locationFilterSearch[type].length).toLowerCase() ==  this.locationFilterSearch[type].toLowerCase()) {
            keepIndexes.push(tempOptions.indexOf(option));        
          }
        });
        tempOptions.forEach((option, index) => {
          if(keepIndexes.indexOf(index) != -1) {
            this.locationStateDisplayFilterResponse.options.push(option);
          }
        });
        break;
      case "city":
        tempOptions = [...this.locationFilterResponse.city];
        this.locationCityDisplayFilterResponse.options = [];
        keepIndexes = [];
        tempOptions.forEach(option => {
          if(option.label.substring(0, this.locationFilterSearch[type].length).toLowerCase() ==  this.locationFilterSearch[type].toLowerCase()) {
            keepIndexes.push(tempOptions.indexOf(option));        
          }
        });
        tempOptions.forEach((option, index) => {
          if(keepIndexes.indexOf(index) != -1) {
            this.locationCityDisplayFilterResponse.options.push(option);
          }
        });
        break;
    }
  }
}