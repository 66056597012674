import {Component, Input, OnInit} from '@angular/core';
import {MiService} from '../../../../shared/models/MiService';
import {NO_DATA} from '../../../ng-models/SectionDataTable.model';
import {getNotificationSeverity, NOTIFICATION_SEVERITY} from '../../../utils/notificationSeverity';
import {SERVICE_ARCHIVED, DATA_INTEGRITY_MISSING, DATA_INTEGRITY_INCOMPLETE} from '../../../../common/utils/sharedConstants';

@Component({
  selector: 'mi-advanced-search-service-details',
  templateUrl: './advanced-search-service-details.component.html',
  styleUrls: ['./advanced-search-service-details.component.scss']
})

export class AdvancedSearchServiceDetailsComponent implements OnInit {

  @Input() miService: MiService;
  @Input() withViewDetails: boolean = false;
  NO_DATA: string = NO_DATA;
  error: string;
  severity: NOTIFICATION_SEVERITY;
  SERVICE_ARCHIVED: string = SERVICE_ARCHIVED;
  readonly DATA_INTEGRITY_MISSING: string = DATA_INTEGRITY_MISSING;
  readonly DATA_INTEGRITY_INCOMPLETE: string = DATA_INTEGRITY_INCOMPLETE;

  constructor() {
  }

  ngOnInit() {

    if(this.miService){
      this.severity = getNotificationSeverity(this.miService.current_notification);
    }

  }

}
