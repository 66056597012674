<ng-container>

  <mi-server-error [error]="error"></mi-server-error>

  <ng-container *ngIf="product; else loading">

    <div class="sections">

      <section class="data-section">

        <h2>Details</h2>

        <dl class="section-details">

          <div>
            <dt>
              Name
            </dt>
            <dd>
              {{product.product_name}}
            </dd>
          </div>

          <div *ngIf="product.product_vendor">
            <dt>
              Vendor
            </dt>
            <dd>
              <mi-id-link [id]="product.product_vendor.display_id"
                          [title]="product.product_vendor.display_id"></mi-id-link>
              {{product.product_vendor.vendor_name}}
            </dd>
          </div>

          <div>
            <dt>
              Product Category
            </dt>
            <dd>
              {{product.product_category.category_name}}
            </dd>
          </div>

          <div>
            <dt>
              Category Description
            </dt>
            <dd>
              {{product.product_category.description}}
            </dd>
          </div>
          <div *ngFor="let productField of product.product_properties" mi-product-field-static-item [productField]="productField"></div>
        </dl>

      </section>

    </div>

  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</ng-container>
