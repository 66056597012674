<div class="contact-details--avatar">
  <ng-container *ngIf="contact; else loading">
    <ng-template *ngIf="contact['image']; else avatarDefault">
      <!-- To be determined -->
    </ng-template>
    <ng-template #avatarDefault>
      <svg *ngIf="!contact || !contact['image']; else profileImage"
         id="default_avatar"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         width="160px" height="160px"
         viewBox="0 0 160 160"
         style="enable-background:new 0 0 160 160;" xml:space="preserve">
      <style type="text/css">
        .st0 {
          fill: #0090C9;
        }
      </style>
      <path id="Shape" class="st0" d="M108,98.7c15.3-9.3,25.3-26,25.3-45.3C133.3,24,109.3,0,80,0S26.7,24,26.7,53.3
	c0,19.3,10,36,25.3,45.3C25.3,108.7,5.3,132,0,160h13.3c6.7-30.7,34-53.3,66.7-53.3s60,22.7,66.7,53.3H160
	C154.7,131.3,134.7,108,108,98.7z M40,53.3c0-22,18-40,40-40s40,18,40,40s-18,40-40,40S40,75.3,40,53.3z"/>
    </svg>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</div>
