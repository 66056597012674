<mi-side-notice *ngIf="alert" noticeType="WARNING">

  <ng-container *ngIf="self_alert">
    {{'ALERTS.STATUS_PREFIX' | translate}}
    <ng-container ngSwitch="{{alert.cause_reference_type}}">
      <ng-container *ngSwitchCase="ALERT_CAUSES.ALERT_CAUSE_CONTACT">
        {{'ALERTS.CAUSE_CONTACT' | translate}}
      </ng-container>
      <ng-container *ngSwitchCase="ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT">
        {{'ALERTS.CAUSE_EQUIPMENT' | translate}}
      </ng-container>
      <ng-container *ngSwitchCase="ALERT_CAUSES.ALERT_CAUSE_LOCATION">
        {{'ALERTS.CAUSE_LOCATION' | translate}}
      </ng-container>
      <ng-container *ngSwitchCase="ALERT_CAUSES.ALERT_CAUSE_SERVICE">
        {{'ALERTS.CAUSE_SERVICE' | translate}}
      </ng-container>
    </ng-container>
    <mi-id-link [display_id]="alert.cause_display_id" [id]="alert.cause_id" *ngIf="alert.cause_reference_type == ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT||alert.cause_reference_type == ALERT_CAUSES.ALERT_CAUSE_CONTACT"></mi-id-link>
    <mi-id-link [id]="alert.cause_display_id" *ngIf="alert.cause_reference_type != ALERT_CAUSES.ALERT_CAUSE_LOCATION  && alert.cause_reference_type != ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT && alert.cause_reference_type != ALERT_CAUSES.ALERT_CAUSE_CONTACT"></mi-id-link>
    <mi-simple-location *ngIf="alert.cause_reference_type == ALERT_CAUSES.ALERT_CAUSE_LOCATION" [id]="alert.cause_display_id" [compact]="true"></mi-simple-location>

    {{'ALERTS.STATUS_SUFFIX' | translate}}.
    {{'ALERTS.STATUS_IMPACT_PREFIX' | translate}}

    <ng-container *ngIf="serviceCount">
      <a routerLink="services">
        {{
        'ALERTS.SERVICE'
          | translate: {count: serviceCount}
          | translateSelector:serviceCount
        }}
      </a>
    </ng-container>

    <ng-container *ngIf="serviceGroupCount && serviceCount">
      {{'ALERTS.AND' | translate}}
    </ng-container>

    <ng-container *ngIf="serviceGroupCount">
      {{ 'ALERTS.SERVICE_GROUP' | translate: {count: serviceGroupCount} | translateSelector: serviceGroupCount }} (<ng-container *ngFor="let serviceGroup of serviceGroups; let first = first; let last = last"><mi-id-link [id]="serviceGroup" [noIcon]="true"></mi-id-link>{{!last ? ', ' : ''}}</ng-container>)
    </ng-container>

    {{'ALERTS.STATUS_IMPACT_SUFFIX' | translate}}
    <strong>{{alert.service_mrc | currency:'USD':'symbol':'1.2-2'}}</strong>

    <ng-container *ngIf="(serviceCount || serviceGroupCount) && equipmentCount">
      {{'ALERTS.AND' | translate}}
    </ng-container>

    <ng-container *ngIf="equipmentCount">
      <a routerLink="equipment">
        {{
        'ALERTS.EQUIPMENT'
          | translate: {count: equipmentCount}
          | translateSelector:equipmentCount
        }}
      </a>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="!self_alert">
    <mi-id-link [display_id]="this.display_id" [id]="this.sql_id" *ngIf="sql_id"></mi-id-link>
    <mi-id-link [id]="this.display_id" *ngIf="!sql_id"></mi-id-link>
    {{'ALERTS.BROKEN_EXPLANATION' | translate: {count: causes.length} | translateSelector: causes.length }}:
    <ul>
      <li *ngFor="let cause of causes">
        <mi-id-link [display_id]="cause.cause_display_id" [id]="cause.cause_id" *ngIf="cause.cause_reference_type == ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT || cause.cause_reference_type == ALERT_CAUSES.ALERT_CAUSE_CONTACT"></mi-id-link>
        <mi-id-link [id]="cause.cause_display_id" *ngIf="cause.cause_reference_type != ALERT_CAUSES.ALERT_CAUSE_LOCATION && cause.cause_reference_type != ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT && cause.cause_reference_type != ALERT_CAUSES.ALERT_CAUSE_CONTACT"></mi-id-link>
        <mi-simple-location *ngIf="cause.cause_reference_type == ALERT_CAUSES.ALERT_CAUSE_LOCATION" [id]="cause.cause_display_id" [compact]="true"></mi-simple-location>
        {{'ALERTS.BAD_STATUS' | translate }}
      </li>
    </ul>
  </ng-container>

</mi-side-notice>
