<div class="note-list">
  <div class="section-data--header">
    <h3>{{title}}</h3>
  </div>
  <ng-container *ngIf="document_id">

    <div class="notes-form">
      <mi-note-form [document_id]="document_id" [document_collection]="document_collection"
                    (noteCreated)="onNoteCreated($event)"></mi-note-form>
    </div>

    <mi-loading-animation *ngIf="!(loaded$ | async)"></mi-loading-animation>

    <ng-container *ngIf="showEvents">
      <div class="notes">

        <mi-tabs-container externalClasses="reverse-color">

          <mi-tab tabTitle="Comments ({{(notes$ | async)?.length}})" [active]="true">

            <ng-container *ngFor="let note of (notes$ | async)">
              <mi-note-item [note]="note"
                            [showSuperUsers]="isSuperUser$ | async"
                            miNoteLink>
              </mi-note-item>
            </ng-container>

          </mi-tab>

          <mi-tab tabTitle="Events ({{(events$ | async)?.length}})">

            <ng-container *ngFor="let event of (events$ | async )">
              <mi-event-item [event]="event"></mi-event-item>
            </ng-container>

          </mi-tab>

          <mi-tab tabTitle="All Activity">

            <ng-container *ngFor="let activity of (allActivity$ | async)">
              <ng-container *ngIf="activity.type === EVENT">
                <mi-event-item [event]="activity"></mi-event-item>
              </ng-container>
              <ng-container *ngIf="activity.type === NOTE">
                <mi-note-item [note]="activity"
                              [showSuperUsers]="isSuperUser$ | async"
                              miNoteLink>
                </mi-note-item>
              </ng-container>
            </ng-container>

          </mi-tab>

        </mi-tabs-container>

      </div>
    </ng-container>

    <ng-container *ngIf="!showEvents">
      <div class="notes" *ngIf="(notes$ | async)?.length">
        <ng-container *ngFor="let note of (notes$ | async)">
          <mi-note-item [note]="note" [showSuperUsers]="isSuperUser$ | async" miNoteLink></mi-note-item>
        </ng-container>
      </div>
    </ng-container>

  </ng-container>

</div>
