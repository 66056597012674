import {Component} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';


@Component({
  templateUrl: './two-button-notification-modal.component.html',
  styleUrls: ['./two-button-notification-modal.component.scss']
})

export class TwoButtonNotificationModalComponent {

  title:string = "Notification"
  message:string = "Notification"
  yesButtonLabel:string = "Yes"
  noButtonLabel:string = "No"

  constructor(
    private modal: ModalRef,
  ) {
  }

  onCancel() {
    this.modal.close(false);
  }
  onYes() {
    this.modal.close(true);
  }
}
