import {CustomRouterStateSerializer} from './../shared/state/router/router-state-serializer';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AppRootComponent} from './app-root/app-root.component';
import {appRoutes} from './app.router';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {translationConfig} from './../shared/translation';
import {SharedModule} from '../shared/shared.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers} from '../shared/state/meta-reducer';
import {EmailDetailComponent} from './pages/emails/details/email-detail.component';
import {OrderDateEditComponent} from './pages/services/single/create/order-details/date-edit/order-date-edit.component';
import {ServiceSingleCreateModule} from './pages/services/single/create/service-single-create.module';
import {OrderDetailsComponent} from './pages/services/single/create/order-details/order-details.component';
import {OrderDetailsSummaryComponent} from './pages/services/single/create/order-details/summary/order-details-summary.component';
import {ContactsModule} from './pages/contacts/contacts.module';
import {TenantComponentsModule} from './components/tenant-components.module';
import {EventsListPageComponent} from './pages/events/events-list-page/events-list-page.component';
import {DashboardModule} from './pages/dashboard/dashboard.module';
import {LocationInstanceWrapModule} from './pages/locations/instance-wrap/location-instance-wrap.module';
import {VendorInstanceWrapModule} from './pages/vendor/instance-wrap/vendor-instance-wrap.module';
import {VendorAccountIdInstanceWrapModule} from './pages/vendor-account-ids/instance-wrap/vendor-account-id-instance-wrap.module';
import {TermAndConditionInstanceWrapModule} from './pages/service-term/instance-wrap/service-term-instance-wrap.module';
import {AssociatedDocumentsInstanceWrapModule} from './pages/associated-documents/instance-wrap/associated-documents-instance-wrap.module';
import {AccountLockedComponent} from './pages/account-locked/account-locked.component';
import {VendorDefinedFieldsComponent} from './pages/vendor-defined-fields/vendor-defined-fields.component';
import {NgxSmoothDnDModule} from 'ngx-smooth-dnd';
import {ProductsModule} from './pages/products/products.module';
import {TanentProductSearchTestComponent} from './pages/test/product-search-test.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ServiceModule} from './pages/services/service.module';
import {MiModalService} from '../shared/services/Modal.service';
import {ModalModule} from '@independer/ng-modal';
import {ParentServiceModule} from './pages/parent-services/parent-service.module';
import {MissingOpportunitiesModule} from './pages/missing-opportunities/missing-opportunities.module';
import {VendorEquipmentCreateComponent} from './pages/vendor-equipment-create/vendor-equipment-create.component';
import {ReQuoteEmailComponent} from './pages/emails/re-quote/re-quote.component';
import {EmailSentComponent} from './pages/emails/sent/email-sent.component';
import {ModalCancellationComponent} from './pages/services/detail/modal-cancellation-instruction/modal-cancellation-instruction.component';
import {ParentModalCancellationComponent} from './pages/parent-services/detail/modal-cancellation-instruction/modal-cancellation-instruction.component';
import {DataImportModule} from './pages/data-import/data-import.module';
import {AuthGuardsModule} from "../libs/auth-guards/auth-guards.module";
import {ApiInterceptorsModule} from "../libs/api-interceptors/api-interceptors.module";
import {environment} from "../environments/environment";
import {AgmCoreModule} from "@agm/core";
import { AcceptTermsAndConditionsComponent } from './pages/accept-terms-and-conditions/accept-terms-and-conditions.component';
import { ServiceSimpleCreateModule } from './pages/services/single/simple-create/service-simple-create.module';
import { DocumentsUploadComponent } from './pages/documents/documents-upload/documents-upload.component';
import { ToastrModule } from "ngx-toastr";
import { Miso3AccountModule } from './pages/miso3-account/miso3-account.module';
import { Miso3LandingPageComponent } from './pages/landing-page/landing-page.component';
import { TenantReportComponent } from './pages/tenant-reports/tenant-reports.component';
import { ReportSummaryComponent } from './pages/report-summary/report-summary.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { VarianceReportComponent } from './pages/variance-report/variance-report.component';
import { UnAlignServicesComponent } from './pages/un-align-services/un-align-services.component';
import { AlignMetabaseComponent } from './pages/align-metabase/align-metabase.component';
import { CostVarianceReportComponent } from './pages/cost-variance-report/cost-variance-report.component';
import { TermRenewalModule } from './pages/term-renewal/term-renewal.module';
import { UnpaidInvoiceReportComponent } from './pages/unpaid-invoice-report/unpaid-invoice-report.component';
import { AccountingAnalysisComponent } from './pages/accounting-analysis/accounting-analysis.component';
import { CostChangeAnalysisComponent } from './pages/cost-change-analysis/cost-change-analysis.component';
import { VarianceSnapshotsComponent } from './pages/variance-snapshots/variance-snapshots.component';

@NgModule({
  declarations: [
    AppRootComponent,
    EmailDetailComponent,
    OrderDetailsComponent,
    OrderDateEditComponent,
    OrderDetailsSummaryComponent,
    EventsListPageComponent,
    VendorDefinedFieldsComponent,
    AccountLockedComponent,
    TanentProductSearchTestComponent,
    VendorEquipmentCreateComponent,
    ReQuoteEmailComponent,
    EmailSentComponent,
    ModalCancellationComponent,
    ParentModalCancellationComponent,
    AcceptTermsAndConditionsComponent,
    DocumentsUploadComponent,
    Miso3LandingPageComponent,
    TenantReportComponent,
    ReportSummaryComponent,
    UnAlignServicesComponent,
    AlignMetabaseComponent,
    VarianceReportComponent,
    VarianceSnapshotsComponent,
    CostVarianceReportComponent,
    AccountingAnalysisComponent,
    CostChangeAnalysisComponent,
    UnpaidInvoiceReportComponent
  ],
  imports: [
    MissingOpportunitiesModule,
    NgSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    ContactsModule,
    DashboardModule,
    HttpClientModule,
    FormsModule,
    LocationInstanceWrapModule,
    VendorAccountIdInstanceWrapModule,
    TermAndConditionInstanceWrapModule,
    AssociatedDocumentsInstanceWrapModule,
    ServiceSingleCreateModule,
    ReactiveFormsModule,
    ServiceModule,
    AuthGuardsModule,
    TermRenewalModule,
    SharedModule,
    ApiInterceptorsModule,
    LocationInstanceWrapModule,
    ProductsModule,
    TenantComponentsModule,
    VendorInstanceWrapModule,
    ParentServiceModule,
    DataImportModule,
    Ng2SmartTableModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      closeButton: true
    }),
    RouterModule.forRoot(appRoutes,
      // enable tracing give more debugging for routers
      // {enableTracing: true}
    ),
    ModalModule,
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    // StoreModule.forRoot([], {metaReducers: metaReducers}),
    StoreModule.forRoot({metaReducers: metaReducers}),
    StoreDevtoolsModule.instrument({maxAge: 50}),
    EffectsModule.forRoot([]),
    // you can uncomment this line when this bug is deployed https://github.com/ngrx/platform/issues/201
    StoreRouterConnectingModule,
    TranslateModule.forRoot(translationConfig),
    NgxSmoothDnDModule,
    ServiceSimpleCreateModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAP_API_KEY,
      libraries: ['places']
    }),
    Miso3AccountModule
  ],
  providers: [
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    MiModalService
  ],
  exports: [AlignMetabaseComponent],
  bootstrap: [
    AppRootComponent
  ]
})

export class AppModule {
}
