import { Action } from '@ngrx/store';

export const ActionTypes = {
  PAGE_ALERT_ADD: '[Miso:Page Alert] Add',
  SHOW_PAGE_ALERT: '[Miso:Page Alert] Show'
};

export class PageAlertAddAction implements Action {
  type = ActionTypes.PAGE_ALERT_ADD;

  constructor(public payload: any) { }
}

export class ShowPageAlertAction implements Action {
  type = ActionTypes.SHOW_PAGE_ALERT;

  constructor(public payload: any) { }
}

export type Actions
  = PageAlertAddAction
  | ShowPageAlertAction