import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountId} from "../../../../../shared/models/AccountId";
import { CanDelete } from "../../../../../shared/models/CanDelete";
import { PageAlertAddAction } from "../../../../../shared/state/page-alert/actions";
import { Store } from "@ngrx/store";
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import { Title } from "@angular/platform-browser";
import { TenantAccountIdService } from "src/libs/api2/account-id-v2/account-id-v2.service";

@Component({
  templateUrl: './vendor-account-id-delete.component.html',
  styleUrls: ['./vendor-account-id-delete.component.scss']
})

export class VendorAccountIdDeleteComponent extends AbstractPageComponent implements OnInit {

  accountId: string;
  error: string;
  exitRoute: string;
  canDelete: any;
  canDeleteMode: boolean = true;
  loaded:boolean = true;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantAccountIdService: TenantAccountIdService,
              private router: Router,
              private store: Store<ASReducer.State>,
              private titleService: Title
      ) {
    super();
  }

  ngOnInit() {
    this.accountId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    this.exitRoute = `/vendor-account-ids/${this.accountId}/edit`;
    this.tenantAccountIdService.getByDisplayId(this.accountId)
      .toPromise()
      .then((acctId: AccountId) => this.tenantAccountIdService.canDelete(acctId._id).toPromise())
      .then((res: CanDelete) => {
        this.canDelete = res;
        this.titleService.setTitle(`Delete - ${this.canDelete.delete.label} - Account ${this.canDelete.delete.display_id} - VENDORNAME - MISO3`);
        if(res.dependents.length){
          this.canDeleteMode = false;
        }
      })
      .catch(e => this.error = e)
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete(){
    this.tenantAccountIdService.deleteAccountId( this.canDelete.delete._id )
    .subscribe(() =>{
        this.loaded = false;
        this.store.dispatch(
          new PageAlertAddAction({
            message: `Account ID was successfully deleted.`,
            alertType: "SUCCESS"
          })
        );
        setTimeout(()=>{
          this.router.navigate([`/vendors/${this.canDelete.delete.vendor.display_id}/account-id`]);
        }, 1000);
      },
      e=> this.error = e
    )
  }

  onUpdateAccountId(accountId:AccountId):void {
    // The change has been added.
    this.router.navigate([this.exitRoute], {
      queryParams:{update: new Date().getTime()},
      relativeTo: this.activatedRoute
    });
  }

}
