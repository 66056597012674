<div class="mi-grid-std">
  <div class="mi-grid-full-static">

    <mi-page-header
      title="{{title}}"
      [breadcrumbs]="breadcrumbs">

    </mi-page-header>

    <mi-server-error [error]="loadError"></mi-server-error>
    <mi-page-alert></mi-page-alert>

    <div class="half-page">

      <mi-contact-form
        [editId]="editId"
        (canceled)="onCanceled($event)"
        (contactComplete)="onContactCompleted($event)">
      </mi-contact-form>

    </div>

  </div>
</div>
