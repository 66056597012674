<mi-server-error [error]="error"></mi-server-error>
<mi-loading-animation *ngIf="loading"></mi-loading-animation>

<form *ngIf="!loading && serviceTermForm" class="card p-4"
      [formGroup]="serviceTermForm"
      (submit)="onFormSubmit(serviceTermForm.value)">

  <dd class="afterward">
    <mi-extended-input
      [labelText]="titles.select"
      labelRequired="true"
      [(inputControl)]="serviceTermForm.controls['event_type']"
      [(inputErrors)]="serviceTermForm.controls['event_type'].errors"
      [(inputTouched)]="serviceTermForm.controls['event_type'].touched"
      [errorDefs]='{required: "Please select an End of Term action."}'>
      <select (change)="onEOTSelect($event)" [attr.disabled]="isEdit ? '' : null"
              formControlName="event_type">
        <option [value]="EVENT_TYPE_AUTO_RENEW">Auto-Renew</option>
        <option [value]="EVENT_TYPE_AUTO_CANCELLATION">Cancel</option>
      </select>
    </mi-extended-input>
  </dd>

  <ng-container>

    <mi-extended-input [labelText]="titles.notification_period"
                       labelRequired="true"
                       suffix="Months"
                       [(inputControl)]="serviceTermForm.controls['auto_renew_length']"
                       [(inputErrors)]="serviceTermForm.controls['auto_renew_length'].errors"
                       [(inputTouched)]="serviceTermForm.controls['auto_renew_length'].touched"
                       [errorDefs]="{required: 'Please enter an auto-renew frequency.'}">
      <input type="number" [attr.disabled]="serviceTermForm.value.event_type===EVENT_TYPE_AUTO_CANCELLATION? '' : null"
             formControlName="auto_renew_length"
             placeholder="Ex. 3"
             min="0"
             (change)="onMonthChange($event)"
             mi-restrict-input miRestrictType="Integer"/>
    </mi-extended-input>
    <mi-extended-input labelRequired="true"
                       [labelText]="titles.requred_notification"
                       suffix="Days"
                       [(inputControl)]="serviceTermForm.controls['days_to_notify']"
                       [(inputErrors)]="serviceTermForm.controls['days_to_notify'].errors"
                       [(inputTouched)]="serviceTermForm.controls['days_to_notify'].touched"
                       [errorDefs]="{required: 'Please enter days required for cancellation notification.'}">
      <input type="number" [attr.disabled]="serviceTermForm.value.event_type===EVENT_TYPE_AUTO_CANCELLATION? '' : null"
             formControlName="days_to_notify"
             placeholder="Ex. 30"
             min="0"
             mi-restrict-input miRestrictType="Integer"/>
    </mi-extended-input>
  </ng-container>

  <mi-server-error [error]="submitError"></mi-server-error>

  <div class="d-flex justify-content-end align-items-center note-info">

    <a mi-button
       type="button"
       (click)="onBackClick()"
       buttonTheme="light-theme">
      Cancel
    </a>

    <button mi-button class="ml-2"
            type="submit"
            [disabled]="!serviceTermForm.valid"
            buttonTheme="dark-theme">
      Continue
    </button>

  </div>

</form>
