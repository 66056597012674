import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceTerm } from '../types/ServiceTerm.interface';

@Injectable()
export class ServiceOwnershipService {
  private apiUrl = '_MICRO_SERVICE_/accountid/ownership';

  constructor(private http: HttpClient) { }

  getAccountOwnershipByAccountId(accountId: string): Observable<any> {
    return this.http
    .get(`${this.apiUrl}/${accountId}`)
    .map((response: any) => response);
  }

  addAccountOwnership(accountId: string,userId: string): Observable<any> {
    const data = {
        user_id: userId
    };
    return this.http
      .post(`${this.apiUrl}/${accountId}`, data)
      .map((response: any) => response);
  }

  removeAccountOwnership(accountId: string): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/${accountId}`)
      .map((response: any) => response);
  }

}
