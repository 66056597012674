<div>
  <mi-page-header
    title="{{ 'TENANT.CONTACTS_LIST_TITLE' | translate }}"
    [breadcrumbs]="breadCrumbs"
    [links]="headerLinks">
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>

  <mi-page-alert></mi-page-alert>

  <div class="mi-grid-small-first">
    <div class="col-all">
      <!-- <mi-tabs-container externalClasses="reverse-color">
        <mi-tab tabTitle="Users & Employees" route="/employees" [withPane]="false"></mi-tab>
        <mi-tab tabTitle="Vendor Contacts" route="/contacts" [withPane]="false"></mi-tab>
        </mi-tabs-container> -->

    <!-- <mi-advanced-search-header
      searchTypeTitle="Search Users & Employees"
      searchType="TENANT_USER"
      [showDownloadButton]="false"
      [showTotalMrc]="false">
    </mi-advanced-search-header> -->

    <div class="sections">
      <mi-tabs-container [wrap]="true" [full]="true" externalClasses="detail-page-wide-tab reverse-color">
        <!-- <mi-tab tabTitle="All" route="/employees/all" [withPane]="false"></mi-tab> -->
        <!-- <mi-tab tabTitle="Subscribers" route="/employees/end-users" [withPane]="false"></mi-tab>
        <mi-tab tabTitle="Owners" route="/employees/service-owner" [withPane]="false"></mi-tab>
        <mi-tab tabTitle="MI3 Owners" route="/employees/miso-manager" [withPane]="false"></mi-tab>
      -->
      </mi-tabs-container>
    </div>

    <!-- <mi-opacity-loader [ready]="searchLoaded$ | async"> -->
    <!-- <mi-applied-search-filters></mi-applied-search-filters> -->
      <mi-advanced-search-tenant-user-results [canEditRoleOnBu]='true'></mi-advanced-search-tenant-user-results>
      <!-- <mi-advanced-search-load-more></mi-advanced-search-load-more> -->
      <!-- </mi-opacity-loader> -->

    </div>
  </div>

</div>

