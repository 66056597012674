import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {MiServiceGroup} from '../../../shared/models/MiServiceGroup';
import {fixAngularArrayQuery} from 'src/shared/utils/fixAngularArrayQuery';
import {SearchQueryParams} from 'src/shared/ng-models/search/SearchQueryParams.interface';
import {MiServiceGroupSearchResults} from 'src/shared/models/SearchResults';

@Injectable()
export class TenantMiServiceGroupService {

  private apiUrl = '/{tenant}/service-groups';
  private apiSearchUrl = '/{tenant}/search/tenant-mi-service-groups';

  constructor(private http: HttpClient) {

  }

  searchTenantServiceGroups(queryParams: SearchQueryParams = {}): Observable<MiServiceGroupSearchResults> {
    return this.http
      .get(this.apiSearchUrl, {params: fixAngularArrayQuery(queryParams)})
      .map((value: any) => value.data as MiServiceGroupSearchResults);
  }

  list(): Observable<Array<MiServiceGroup>> {
    return this.http
      .get(`${this.apiUrl}`)
      .map((response: any) => response.data.mi_service_groups as MiServiceGroup[]);
  }

  create(serviceGroup: MiServiceGroup): Observable<MiServiceGroup> {
    return this.http
      .post(`${this.apiUrl}`, serviceGroup)
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  getById(id: string): Observable<MiServiceGroup> {
    return this.http
      .get(`${this.apiUrl}/id/${id}`)
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  getByDisplayId(displayId: string): Observable<MiServiceGroup> {
    return this.http
      .get(`${this.apiUrl}/display-id/${displayId}`)
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  addParentService(service_group_id: string, parent_service_id: string): Observable<MiServiceGroup> {
    return this.http
      .put(`${this.apiUrl}/${service_group_id}/add-parent-service`, {parent_service_id})
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  addService(service_group_id: string, service_id: string): Observable<MiServiceGroup> {
    return this.http
      .put(`${this.apiUrl}/${service_group_id}/add-service`, {service_id})
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  removeParentService(service_group_id: string, parent_service_id: string): Observable<MiServiceGroup> {
    return this.http
      .put(`${this.apiUrl}/${service_group_id}/remove-parent-service`, {parent_service_id})
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  removeService(service_group_id: string, service_id: string): Observable<MiServiceGroup> {
    return this.http
      .put(`${this.apiUrl}/${service_group_id}/remove-service`, {service_id: service_id})
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  update(service_group_id: string, name: string, description: string = ''): Observable<MiServiceGroup> {
    return this.http
      .put(`${this.apiUrl}/${service_group_id}`, {
        service_group_id,
        name,
        description
      })
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

  deleteServiceGroup(id: String): Observable<MiServiceGroup> {
    return this.http
      .delete(`${this.apiUrl}/${id}`)
      .map((response: any) => response.data.mi_service_group as MiServiceGroup);
  }

}
