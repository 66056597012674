<!-- <div class="tab-title-header">
    <h2>{{title}}</h2>
    <ng-container *ngIf="editable">
        <a
            class="edit-link button dark-theme"
            (click)="onToggleExpand()"
            *ngIf="editable"
        >
            {{isOpen ? 'Close' : 'Edit'}}
        </a>
    </ng-container>
</div> -->
<!-- <mi-server-error [error]="error"></mi-server-error>
<ng-container>
    <ng-container *ngIf="isOpen; else static">
        <mi-opacity-loader [ready]="!updating">
            <div class=" open">
                <form
                    [formGroup]="formGroup_service_dates"
                    (submit)="updateData(formGroup_service_dates.value)"
                >
                    <ul class="radio-collection">
                        <li>
                            <input
                                id="opt_1"
                                type="radio"
                                [value]="DONT_KNOW"
                                [checked]="status === DONT_KNOW"
                                (change)="onRadioChanged($event)"
                            >
                            <label for="opt_1">Not sure</label>
                        </li>
                        <li>
                            <input
                                id="opt_2"
                                type="radio"
                                [value]="START_AND_TERM"
                                [checked]="status === START_AND_TERM"
                                (change)="onRadioChanged($event)"
                            >
                            <label for="opt_2">I know the start date and length</label>
                        </li>
                        <li *ngIf="status === START_AND_TERM">
                            <div class="form-field">
                                <mi-extended-input
                                    labelText="Term Length"
                                    class="service-details"
                                    [labelRequired]="true"
                                    [(inputControl)]="formGroup_service_dates.controls['term_length']"
                                    [(inputErrors)]="formGroup_service_dates.controls['term_length'].errors"
                                    [(inputTouched)]="formGroup_service_dates.controls['term_length'].touched"
                                    [errorDefs]="{ required: 'Term Length is required.', min: 'Minimum term length is 1', max: 'Maximum term length is 100' }"
                                >
                                    <div class="flex">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="term_length"
                                            mi-restrict-input
                                            miRestrictType="Integer"
                                        >
                                        <span class="suffix">Months</span>
                                    </div>
                                </mi-extended-input>
                                <mi-extended-input
                                    labelText="Start Date"
                                    class="service-details"
                                    [labelRequired]="true"
                                    [(inputControl)]="formGroup_service_dates.controls['start_date']"
                                    [(inputErrors)]="formGroup_service_dates.controls['start_date'].errors"
                                    [(inputTouched)]="formGroup_service_dates.controls['start_date'].touched"
                                    [errorDefs]="{required: 'A start date is required.'}"
                                >
                                    <mi-date-picker
                                        (dateChange)="onDateChange($event)"
                                        [currentDate]="formGroup_service_dates.value.start_date"
                                    >
                                    </mi-date-picker>
                                </mi-extended-input>
                            </div>
                        </li>
                        <li>
                            <input
                                id="opt_3"
                                type="radio"
                                [value]="END_ONLY"
                                [checked]="status === END_ONLY"
                                (change)="onRadioChanged($event)"
                            >
                            <label for="opt_3">I know the term end date</label>
                        </li>
                        <li *ngIf="status === END_ONLY">
                            <mi-extended-input
                                labelText="Initial End Date"
                                class="service-details"
                                [labelRequired]="true"
                                [(inputControl)]="formGroup_service_dates.controls['end_date']"
                                [(inputErrors)]="formGroup_service_dates.controls['end_date'].errors"
                                [(inputTouched)]="formGroup_service_dates.controls['end_date'].touched"
                                [errorDefs]="{required: 'An end date is required.'}"
                            >
                                <mi-date-picker
                                    (dateChange)="onDateChange($event)"
                                    [currentDate]="formGroup_service_dates.value.end_date"
                                >
                                </mi-date-picker>
                            </mi-extended-input>
                        </li>
                    </ul>
                    <div class="button-container">
                        <button
                            type="button"
                            mi-button
                            buttonTheme="light-theme"
                            (click)="onCancel()"
                        >
                            Cancel
                        </button>
                        <button
                            mi-button
                            buttonTheme="dark-theme"
                            [disabled]="!formGroup_service_dates.valid"
                            type="submit"
                        >
                            Apply
                        </button>
                    </div>
                </form>
            </div>
        </mi-opacity-loader>
    </ng-container>
    <ng-container #static>
        <dl class="section-details">
            <div>
                <dt>Entered Start Date</dt>
                <dd>
                    {{(formattedStartDate | date:'shortDate')|| NO_DATA}}
                </dd>
            </div>
            <div>
                <dt>Entered Term Length</dt>
                <dd>
                    <ng-container *ngIf="serviceDetails.dates_and_locations.initial_term_length; else noData">
                        {{('MONTHS_QTY'
            | translate:{count: serviceDetails.dates_and_locations.initial_term_length}
            | translateSelector: serviceDetails.dates_and_locations.initial_term_length) || NO_DATA }}
                    </ng-container>
                </dd>
            </div>
            <div>
                <dt>Initial End Date</dt>
                <dd>
                    {{(formattedEndDate| date:'shortDate')|| NO_DATA}}
                </dd>
            </div>
            <div>
                <dt>Termination Date</dt>
                <dd>
                    {{(serviceDetails.service_data.termination_date | date:'shortDate')|| NO_DATA}}
                </dd>
            </div>
        </dl>
        <div class="current-values">
            <h2>Calculated Values</h2>
            <dl class="section-details">
                <div>
                    <dt>Start of Current Term</dt>
                    <dd>
                        {{(serviceCalculationDetails.current_dates.current_start_date | date:'shortDate') || NO_DATA}}
                    </dd>
                </div>
                <div>
                    <dt>Term Length</dt>
                    <dd>
                        <ng-container *ngIf="serviceCalculationDetails.current_dates.current_term; else noData">
                            {{('MONTHS_QTY'
              | translate:{count: serviceCalculationDetails.current_dates.current_term}
              | translateSelector: serviceCalculationDetails.current_dates.current_term) || NO_DATA }}
                        </ng-container>
                    </dd>
                </div>
                <div>
                    <dt>End of Current Term</dt>
                    <dd>
                        {{(
            serviceCalculationDetails.current_dates.current_end_date | date:'shortDate') || NO_DATA}}
                    </dd>
                </div>
                <div>
                    <dt>In/Out of Term</dt>
                    <dd>
                        {{ serviceCalculationDetails.in_term ? ('IN_TERM.' + serviceCalculationDetails.in_term | translate) : NO_DATA}}
                    </dd>
                </div>
            </dl>
        </div>
    </ng-container>
</ng-container> -->


<!-- <ng-template #noData>{{NO_DATA}}</ng-template>


<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template> -->
<div class="tab-title-header">
    <h2>{{title}}</h2>
    <ng-container *ngIf="editable">
        <a
            class="edit-link button dark-theme"
            (click)="onToggleExpand()"
            *ngIf="editable"
        >
            {{isOpen ? 'Close' : 'Edit'}}
        </a>
    </ng-container>
</div>
<mi-server-error [error]="error"></mi-server-error>
<ng-container>
  <ng-container *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
      <div class=" open">
        <form [formGroup]="formGroup_service_dates" (submit)="updateData(formGroup_service_dates.value)" class="card-1">
          <ul class="radio-collection">
            <li>
              <input id="opt_1" type="radio" [value]="DONT_KNOW" [checked]="status === DONT_KNOW" (change)="onRadioChanged($event)" />
              <!-- <label for="opt_1">Not sure</label> -->
              <label for="opt_1">{{ getNotSureLabel() }}</label>
            </li>
            <li>
              <input id="opt_2" type="radio" [value]="START_AND_TERM" [checked]="status === START_AND_TERM"
                (change)="onRadioChanged($event)" />
              <label for="opt_2">I know the start date and length</label>
            </li>
            <li *ngIf="status === START_AND_TERM">
              <div class="form-field">
                <mi-extended-input labelText="Term Length" class="service-details" [labelRequired]="true"
                  [(inputControl)]="formGroup_service_dates.controls['term_length']"
                  [(inputErrors)]="formGroup_service_dates.controls['term_length'].errors"
                  [(inputTouched)]="formGroup_service_dates.controls['term_length'].touched"
                  [errorDefs]="{ required: 'Term Length is required.', min: 'Minimum term length is 1', max: 'Maximum term length is 100' }">
                  <div class="flex">
                    <input type="text" class="form-control" formControlName="term_length" mi-restrict-input miRestrictType="Integer">
                    <span class="suffix">
                      Months
                    </span>
                  </div>
                </mi-extended-input>
                <mi-extended-input labelText="Start Date" class="service-details" [labelRequired]="true"
                  [(inputControl)]="formGroup_service_dates.controls['start_date']"
                  [(inputErrors)]="formGroup_service_dates.controls['start_date'].errors"
                  [(inputTouched)]="formGroup_service_dates.controls['start_date'].touched"
                  [errorDefs]="{required: 'A start date is required.'}">
                  <mi-date-picker (dateChange)="onDateChange($event)" [currentDate]="formGroup_service_dates.value.start_date">
                  </mi-date-picker>
                </mi-extended-input>
              </div>
            </li>
            <li>
              <input id="opt_3" type="radio" [value]="END_ONLY" [checked]="status === END_ONLY" (change)="onRadioChanged($event)" />
              <label for="opt_3">I know the term end date</label>
            </li>
            <li *ngIf="status === END_ONLY">
              <mi-extended-input labelText="Initial End Date" class="service-details" [labelRequired]="true"
                [(inputControl)]="formGroup_service_dates.controls['end_date']"
                [(inputErrors)]="formGroup_service_dates.controls['end_date'].errors"
                [(inputTouched)]="formGroup_service_dates.controls['end_date'].touched"
                [errorDefs]="{required: 'An end date is required.'}">
                <mi-date-picker (dateChange)="onDateChange($event)" [currentDate]="formGroup_service_dates.value.end_date">
                </mi-date-picker>
              </mi-extended-input>
            </li>
          </ul>
          <div class="d-flex justify-content-end note-info-1">
            <button type="button" mi-button buttonTheme="light-theme" (click)="onCancel()">
              Cancel
            </button>
            <button class="ml-2" mi-button buttonTheme="dark-theme" [disabled]="!formGroup_service_dates.valid" type="submit">
              Apply
            </button>
          </div>
        </form>
      </div>
    </mi-opacity-loader>
  </ng-container>
<ng-container #static>
    <dl class="section-details card-1 ">
      <div>
        <dt>Entered Start Date</dt>
        <dd>{{(formattedStartDate | date:'shortDate')|| NO_DATA}}</dd>
      </div>
      <div>
        <dt>Entered Term Length</dt>
        <dd>
          <ng-container *ngIf="serviceDetails.dates_and_locations.initial_term_length; else noData">
            {{('MONTHS_QTY'
            | translate:{count: serviceDetails.dates_and_locations.initial_term_length}
            | translateSelector: serviceDetails.dates_and_locations.initial_term_length) || NO_DATA }}
          </ng-container>
        </dd>
      </div>
      <div>
        <dt>Initial End Date</dt>
        <dd>{{(formattedEndDate| date:'shortDate')|| NO_DATA}}</dd>
      </div>
      <div>
        <dt>Termination Date</dt>
        <dd>{{(serviceDetails.service_data.termination_date | date:'shortDate')|| NO_DATA}}</dd>
      </div>
    </dl>
    <div class="current-values card-1">
      <h2 class="note-info-1">Calculated Values</h2>
      <dl class="section-details">
        <div>
          <dt>Start of Current Term</dt>
          <dd>
            {{(serviceCalculationDetails.current_dates.current_start_date | date:'shortDate') || NO_DATA}}</dd>
          </div>
        <div>
          <dt>Term Length</dt>
          <dd>
            <ng-container *ngIf="serviceCalculationDetails.current_dates.current_term; else noData">
              {{('MONTHS_QTY'
              | translate:{count: serviceCalculationDetails.current_dates.current_term}
              | translateSelector: serviceCalculationDetails.current_dates.current_term) || NO_DATA }}
                        </ng-container>
                    </dd>
                </div>
                <div>
                    <dt>End of Current Term</dt>
                    <dd>
                        {{(
            serviceCalculationDetails.current_dates.current_end_date | date:'shortDate') || NO_DATA}}
                    </dd>
                </div>
                <div>
                    <dt>In/Out of Term</dt>
                    <dd>
                        {{ serviceCalculationDetails.in_term ? ('IN_TERM.' + serviceCalculationDetails.in_term | translate) : NO_DATA}}
                    </dd>
                </div>
            </dl>
        </div>
    </ng-container>
</ng-container>


<ng-template #noData>{{NO_DATA}}</ng-template>


<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>
