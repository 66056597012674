import { Action } from '@ngrx/store';

export const ActionTypes = {
  RESET: '[Miso:Note List] Reset',
  LOAD_NOTES: '[Miso:Note List] Load',
  LOAD_NOTES_SUCCESS: '[Miso:Note List] Load Success',
  LOAD_NOTES_FAIL: '[Miso:Note List] Load Fail'
};

export class ResetNoteListAction implements Action {
  type = ActionTypes.RESET;
  constructor(public payload: any) { }
}

export class LoadNotesAction implements Action {
  type = ActionTypes.LOAD_NOTES;
  constructor(public payload: any) { }
}

export class LoadNotesSuccessAction implements Action {
  type = ActionTypes.LOAD_NOTES_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadNotesFailAction implements Action {
  type = ActionTypes.LOAD_NOTES_FAIL;
  constructor(public payload: any) { }
}

export type Actions
  = ResetNoteListAction
  | LoadNotesAction
  | LoadNotesSuccessAction
  | LoadNotesFailAction
