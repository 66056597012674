<div>

  <mi-page-header
    title="{{ 'TENANT.MIPARENT_SERVICE_DETAILS_TITLE' | translate:{display_id: parentService ? parentService.display_id : ''} }}"
    [breadcrumbs]="breadcrumbs">
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>

  <mi-page-alert></mi-page-alert>

  <ng-container *ngIf="pageLoaded$|async; else loading" [ngSwitch]="error">

    <ng-container *ngSwitchCase="''">

      <mi-parent-service-details-header>

      </mi-parent-service-details-header>

    </ng-container>

    <ng-container *ngSwitchDefault>
      <p>Parent Service {{parentServiceId}} could not be found. Please check the ID and try again.</p>
      <p>If you think you're reaching this message in error, please contact support.</p>
    </ng-container>

    <!-- this is the route output for the modal -->
    <router-outlet></router-outlet>

  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</div>
