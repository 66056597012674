import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FileService} from '../../../libs/api/file/file.service';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {environment} from '../../../environments/environment';

const log = createLogger(LOG_LEVELS.FILE_UPLOAD);

@Component({
  selector: 'mi-upload',
  styles: [`
    img {
      max-width: 100px;
    }
  `],
  templateUrl: './mi-upload.directive.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MiUpload),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MiUpload),
      multi: true,
    },
    FileService
  ]
})

export class MiUpload implements ControlValueAccessor {
  @Input() accept: string;
  @Input() upload_type: string;
  fileUrl: any;
  error: any;
  isImage = false;
  loading = false;
  loaded = false;
  imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'ico', 'tif', 'tiff', 'webp'];

  private propagateChange = (_: any) => {};
  private propagateTouch = (_: any) => {};

  constructor(private http: HttpClient, private fileService: FileService) {

  }

  public validate(c: FormControl) {
    return null;
  }

  public writeValue(obj: any) {
    this.fileUrl = obj;
    this.isImage = obj && obj.file_path ? this.imageExtensions.includes(obj.file_path.split('.').pop()) : false;
    this.propagateChange(this.fileUrl);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  // Right now you can only do one, add "multiple" to input for multiple, change logic
  fileChanged(e: Event) {

    const target: HTMLInputElement = e.target as HTMLInputElement;
    this.error = '';

    if (target.accept) {
      if (target.accept !== '' && target.accept !== '*') {
        // There are acceptable file types passed through;
        // Make sure all the passed file(s) are of that type.
        const files = Array.from(target.files)
          .filter((file) => {
            const fileType = file.name.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
            return target.accept.indexOf(fileType) === -1 ? false : true;
          });

        if (files.length !== target.files.length) {
          // There's something amiss; one or more of the files passed
          // isn't of the type we're expecting.
          this.error = 'One or more of the files selected for upload is of the wrong type. Please try again.';
          return;
        }
      }
    }
    for (let i = 0; i < target.files.length; i++) {
      //this.upload(target.files[i]);
    }
  }

  /*
  upload(file): any {

    this.loading = true;
    this.loaded = false;

    // To accomodate non-empty types, do !file.type->'application/octet-stream'

    let fileType = 'application/octet-stream';

    if (file !== '' && file.type) {
      fileType = file.type;
    }

    if (file && file.name) {
      const fileParts = file.name.split('.');
      if (fileParts[fileParts.length - 1] === 'json') {
        fileType = 'application/json';
      }
    }

    this.http
      .post(`/{tenant}/files/get-upload-credentials`, {
        key: file.name,
        upload_type: this.upload_type,
        contentType: fileType,
        size: file.size
      })
      .toPromise()
      .then((response: any) => {
        return response.data;
      }).then(response => {

      const url = new URL(response.S3Credentials);
      const headers = new HttpHeaders();
      const cacheHeader = url.searchParams.get('Cache-Control');

      if (cacheHeader) {
        headers.set('cache-control', cacheHeader);
      }

      const file_with_correct_content_type = new Blob([file], {type: response.content_type});

      return this.http.put(response.S3Credentials, file_with_correct_content_type, {headers: headers})
        .toPromise()
        .then(res => {
          this.error = undefined;
          this.isImage = this.imageExtensions.includes(file.name.split('.').pop());
          // Handle private case on backend based on file type
          this.fileUrl = {
            // Full_path: url.origin+url.pathname,
            file_path: url.pathname,
            upload_type: this.upload_type,
            file_name: file.name,
            mime_type: response.content_type,
            file_size: file.size,
            // For immediate access only!!!!
            full_path: `${settings.API_URL}/${url.pathname.split('/')[1]}/files?file_path=` + url.pathname.substring(1, url.pathname.length)
          };
          this.propagateChange(this.fileUrl);
          this.loading = false;
          this.loaded = true;
        });

    }).catch(err => {
      this.error = err;
    });
  }
  */
}
