<mi-server-error [error]="loadError"></mi-server-error>
<mi-loading-animation *ngIf="loading; else loadedTpl"></mi-loading-animation>
<ng-template #loadedTpl>
  <h2>Filters</h2>
  <div class="col-2 my-filter-scrollable-container">
    <ng-container *ngIf="!eventFilter; else eventTpl">
      <div class="filter-group">
        <div *ngIf="customFields.length" class="col-2">
          <h2>Custom Field Filters</h2>
          <ul>
            <li *ngFor="let cfv of customFields" class="filter-item">
              <label>
                <div>
                  <span>{{cfv.label}}</span>
                  <input type="checkbox" [checked]="visibleFilters[cfv._id]" (change)="toggle($event, cfv._id)"
                  />
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-group">
        <h2>{{'FILTER_TITLES.STANDARD_FILTERS' | translate}}</h2>
        <ul>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.ASSIGNED_TO' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['assigned_email_id']" (change)="toggle($event, 'assigned_email_id')" />
              </div>
            </label>
          </li>
  
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.SERVICE_CATEGORY' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['product_category']" (change)="toggle($event, 'product_category')"
                />
              </div>
            </label>
          </li>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.VENDOR' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['vendor_name']" (change)="toggle($event, 'vendor_name')" />
              </div>
            </label>
          </li>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.REQUESTOR' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['requestor']" (change)="toggle($event, 'requestor')" />
              </div>
            </label>
          </li>
          <li class="filter-item">
              <label>
                <div>
                  <span>{{'FILTER_TITLES.DATA_INTEGRITY' | translate}}</span>
                  <input type="checkbox" [checked]="visibleFilters['data_integrity_overall_status']" (change)="toggle($event, 'data_integrity_overall_status')" />
                </div>
              </label>
            </li>
            <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.MONTHLY_COST' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['mrc']" (change)="toggle($event, 'mrc')" />
              </div>
            </label>
          </li>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.COUNTRY' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['country_long']" (change)="toggle($event, 'country_long')" />
              </div>
            </label>
          </li>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.STATE' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['state_long']" (change)="toggle($event, 'state_long')" />
              </div>
            </label>
          </li>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.CITY' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['city_long']" (change)="toggle($event, 'city_long')" />
              </div>
            </label>
          </li>
          <li class="filter-item">
            <label>
              <div>
                <span>{{'FILTER_TITLES.PARENT_SERVICE_STATUS' | translate}}</span>
                <input type="checkbox" [checked]="visibleFilters['parent_service_status']" (change)="toggle($event, 'parent_service_status')" />
              </div>
            </label>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>

  <div class="button-container">
    <button mi-button (click)="onApplyClicked($event)">
      Apply
    </button>
  </div>
</ng-template>

<ng-template #eventTpl>
  <div class="filter-group">
    <div *ngIf="customFields.length" class="col-2">
      <h2>Custom Field Filters</h2>
      <ul>
        <li *ngFor="let cfv of customFields" class="filter-item">
          <label>
            <div>
              <span>{{cfv.label}}</span>
              <input type="checkbox" [checked]="visibleFilters[cfv._id]" (change)="toggle($event, cfv._id)"
              />
            </div>
          </label>
        </li>
      </ul>
    </div>
  </div>
  <div class="filter-group">
    <h2>Event Filters</h2>
    <ul>
      <li class="filter-item">
        <label>
          <div>
            <span>{{'FILTER_TITLES.ASSIGNED_TO' | translate}}</span>
            <input type="checkbox" [checked]="visibleFilters['assigned_email_id']" (change)="toggle($event, 'assigned_email_id')" />
          </div>
        </label>
      </li>
      <li class="filter-item">
        <label>
          <div>
            <span>{{'FILTER_TITLES.VENDOR' | translate}}</span>
            <input type="checkbox" [checked]="visibleFilters['vendor_name']" (change)="toggle($event, 'vendor_name')" />
          </div>
        </label>
      </li>
      <li class="filter-item">
        <label>
          <div>
            <span>{{'FILTER_TITLES.PENDING_EVENT' | translate}}</span>
            <input type="checkbox" [checked]="true" disabled="true" />
          </div>
        </label>
      </li>
      <li class="filter-item">
        <label>
          <div>
            <span>{{'FILTER_TITLES.NEXT_DECISION' | translate}}</span>
            <input type="checkbox" [checked]="true" disabled="true" />
          </div>
        </label>
      </li>
      <li class="filter-item">
        <label>
          <div>
            <span>{{'FILTER_TITLES.MONTHLY_COST' | translate}}</span>
            <input type="checkbox" [checked]="visibleFilters['mrc']" (change)="toggle($event, 'mrc')" />
          </div>
        </label>
      </li>
      <li class="filter-item">
        <label>
          <div>
            <span>{{'FILTER_TITLES.SERVICE_CATEGORY' | translate}}</span>
            <input type="checkbox" [checked]="visibleFilters['product_category']" (change)="toggle($event, 'product_category')"
            />
          </div>
        </label>
      </li>
    </ul>
  </div>
</ng-template>