import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class AccountTwoFactorService {

  //need ability to create an account id on a vendor
  private apiStartTFA = '/account/tfa/start';
  private apiOptOutOfTFA = '/account/tfa/opt-out';
  private apiConfirmTFA = '/account/tfa/confirm';

  constructor(private http: HttpClient) {
  }

  startTFA(mobile_number: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiStartTFA}`, {mobile_number})
      .map((response: any) => response.data.message);
  }

  confirmTFA(pending_tfa_code: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiConfirmTFA}`, {pending_tfa_code})
      .map((response: any) => response.data.message);
  }

  optOutTFA(): Observable<any> {
    return this.http
      .post<any>(`${this.apiOptOutOfTFA}`, {})
      .map((response: any) => response.data.message);
  }

}
