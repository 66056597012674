import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ServiceTerm, ServiceTermAssociation } from '../types/ServiceTerm.interface';

@Injectable()
export class ServiceTermService {
  private apiUrl = '_MICRO_SERVICE_/serviceterm';
  private tenantRetermListAPI = `_MICRO_SERVICE_/serviceterm/service-terms`;
  private updateTenantServiceTermAPI = `_MICRO_SERVICE_/vendor/tenants`;

  constructor(private http: HttpClient) {}

  getQueryParams(queryParams: any): HttpParams {
    return new HttpParams({fromObject: queryParams as any});
  }

  saveServiceTerm(serviceterm: ServiceTerm): Observable<ServiceTerm> {
    return this.http
      .post(this.apiUrl + '/service-term', serviceterm)
      .map((response: any) => response as ServiceTerm);
  }

  getServiceTermById(id: string): Observable<ServiceTerm> {
    const data = {
      params: {
        service_term_id: id
      }
    };
    return this.http
      .get(this.apiUrl + '/service-term', data)
      .map((response: any) => response as ServiceTerm);
  }

  // this service added as a PRITI requested
  getServiceTermByIdForService(service_term_id: string, service_display_id : string): Observable<ServiceTerm> {
    const data = {
      params: {
        service_term_id: service_term_id,
        service_display_id  : service_display_id 
      }
    };
    return this.http
      .get(this.apiUrl + '/service-term', data)
      .map((response: any) => response as ServiceTerm);
  }

  getServiceTermByTenantIdAndServiceDisplayId(tenant_id: string, service_display_id: string): Observable<ServiceTerm> {
    const data = {
      params: {
        display_id: service_display_id,
        tenant_id: tenant_id
      }
    };
    return this.http
      .get(this.apiUrl + '/service/term', data)
      .map((response: any) => response as ServiceTerm);
  }


  saveServiceTermAssociation(data: ServiceTermAssociation) {
    return this.http
      .post(this.apiUrl + '/service-term-association', data)
      .map((response: any) => response as any);
  }

  updateTenantServiceTerms(payload) {
    return this.http
      .put(`${this.updateTenantServiceTermAPI}`, payload)
  }


  searchServiceTerms(tenantId: string,  queryParams:any = {}) {
    
    return this.http
      .get(this.apiUrl + `/service-terms?tenant_id=${tenantId}`, {params: this.getQueryParams(queryParams)})
        .map((response: any) => response);
  }

  getTenantRetermListService(tenant_id: string
  ) {
    return this.http.get<any>(`${this.tenantRetermListAPI}?tenant_id=${tenant_id}`)
  }

}
