<div class="date-picker">
  <input
    class="form-control date-picker-input"
    type="text"
    [placeholder]="placeholder"
    mwlFlatpickr
    [dateFormat]="dateFormat"
    (focus)="_pickerFocus()"
    [(ngModel)]="currentDate"
    (flatpickrChange)="_dateChange()"
    [allowInput]="true"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [enable]="[enableDate]"
    [disabled]="!enabled"
    mi-restrict-input miRestrictType="Date">
</div>
