/**
 * Takes a query and gets it to mimic arrays
 * @param {Object} query
 * @returns {Object}
 */
export const enforceQueryArrayProperties = (query: object): object => {

  let newQuery: any = {};

  Object.keys(query).forEach((key) => {
    if (key.includes('[]')) {
      // These items should be arrays. Sometimes they're not.
      newQuery[key] = Array.isArray(query[key]) ? query[key] : [query[key]];
    }else{
      newQuery[key] = query[key]
    }
  });

  return newQuery;

};
