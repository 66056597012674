<mi-tabs-container [full]="true" (indexChanged)="toggle($event)" externalClasses="reverse-color">

  <ng-container *ngFor="let bucket of filters">
    <mi-tab tabTitle="{{'TIME_RANGE_BUTTONS.'+bucket.key | translate:{
      doc_count: bucket.doc_count,
      financial_commitment: bucket.financial_commitment.value | tenantcurrency:'1.0'
    } }}" [withPane]="false" [active]="bucket.selected">
    </mi-tab>
  </ng-container>

</mi-tabs-container>


