import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TenantComponentsModule} from "../../../components/tenant-components.module";
import {SharedModule} from "../../../../shared/shared.module";
import {ContactInstanceWrapComponent} from "./contact-instance-wrap.component";
import {ContactDetailComponent} from "./vendor-contact/detail/contact-details.component";
import {ContactSearchEventsComponent} from "./vendor-contact/search-events/contact-search-events.component";
import {ContactSearchMiServicesComponent} from "./vendor-contact/search-mi-services/contact-search-mi-services.component";
import {ContactEditComponent} from "./vendor-contact/edit/contact-edit.component";
import {CreateEditContactsComponent} from "./vendor-contact/create-edit/create-edit-contacts.component";
import {ContactDetailsAvatarComponent} from "../../../components/contact-details-avatar/contact-details-avatar.component";
import {ContactSearchEquipmentComponent} from './vendor-contact/search-equipment/contact-search-equipment.component';
import { ContactDetailsHeaderItemsComponent } from './vendor-contact/header/contact-details-header-items.component';
import { EmployeeInstanceWrapComponent } from './employee-instance-wrap.component';
import { EmployeeSearchMiServicesComponent } from './employee/search-mi-services/employee-search-mi-services.component';
import { EmployeeSearchEventsComponent } from './employee/search-events/employee-search-events.component';
import { EmployeeSearchEquipmentComponent } from './employee/search-equipment/employee-search-equipment.component';
import { EmployeeDetailsHeaderItemsComponent } from './employee/header/employee-details-header-items.component';
import { MisoManagerSearchMiServicesComponent } from './employee/search-mi-services/miso-manager-search-mi-services.component';
import { ServiceOwnerSearchMiServicesComponent } from './employee/search-mi-services/seervice-owner-search-mi-services.component';
import { EndUserSearchMiServicesComponent } from './employee/search-mi-services/end-user-search-mi-services.component';
import { SubscriberSearchMiServicesV2Component } from './employee/search-mi-services-v2/subscriber-search-mi-services-v2.component';
import { ServiceOwnerSearchMiServicesV2Component } from './employee/search-mi-services-v2/service-owner-search-mi-services-v2.component';
import { M3OwnerSearchMiServicesV2Component } from './employee/search-mi-services-v2/m3-owner-search-mi-services-v2.component';

@NgModule({
  declarations: [
    ServiceOwnerSearchMiServicesComponent,
    MisoManagerSearchMiServicesComponent,
    EndUserSearchMiServicesComponent,
    EmployeeDetailsHeaderItemsComponent,
    EmployeeSearchEquipmentComponent,
    EmployeeSearchEventsComponent,
    EmployeeSearchMiServicesComponent,
    EmployeeInstanceWrapComponent,
    ContactInstanceWrapComponent,
    ContactDetailComponent,
    ContactSearchEventsComponent,
    ContactSearchMiServicesComponent,
    ContactEditComponent,
    ContactDetailsHeaderItemsComponent,
    CreateEditContactsComponent,
    ContactDetailsAvatarComponent,
    ContactSearchEquipmentComponent,
    SubscriberSearchMiServicesV2Component,
    ServiceOwnerSearchMiServicesV2Component,
    M3OwnerSearchMiServicesV2Component
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule
  ],
  providers: [],
  exports: [
    EmployeeDetailsHeaderItemsComponent,
    EmployeeSearchEquipmentComponent,
    EmployeeSearchEventsComponent,
    EmployeeSearchMiServicesComponent,
    EmployeeInstanceWrapComponent,
    ContactInstanceWrapComponent,
    ContactDetailComponent,
    ContactSearchEventsComponent,
    ContactSearchMiServicesComponent,
    ContactEditComponent,
    ContactDetailsHeaderItemsComponent,
    CreateEditContactsComponent,
    ContactDetailsAvatarComponent,
    ContactSearchEquipmentComponent
  ]
})
export class ContactInstanceWrapModule {
}
