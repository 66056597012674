import {
  createFeatureSelector,
} from '@ngrx/store';
import {ParentServiceActions} from './actions';
import {Product} from '../../../../shared/models/Product';
import {MiService} from '../../../../shared/models/MiService';
import {Vendor} from '../../../../shared/models/Vendor';
import {User} from '../../../../shared/models/User';

export enum ParentServiceSteps {
  AssignedTo = 'assigned-to',
  Locations = 'locations',
  Nickname = 'nickname',
  Product = 'product',
  AddParentService = 'AddParentService',
  Vendor = 'vendor'
}

export const ParentServiceProgression: string[] = [
  ParentServiceSteps.Product,
  ParentServiceSteps.Nickname,
  ParentServiceSteps.AssignedTo,
  ParentServiceSteps.AddParentService
];

export interface ParentServiceState {
  _id?: string;
  division?: string;
  team?: string;
  assigned_email?: User;
  assigned_user?: User;
  display_id?: string;
  index: number;
  maxIndex: number;
  nickname?: string;
  product?: Product,
  services: any[];
  vendor?: Vendor,
}

const initialState: ParentServiceState = {
  assigned_email: null,
  assigned_user: null,
  display_id: null,
  index: 0,
  maxIndex: 0,
  nickname: null,
  product: null,
  services: [],
  vendor: null,
};

export function reducer(state: ParentServiceState = initialState, action): ParentServiceState {
  switch (action.type) {
    case ParentServiceActions.ResetParentServiceAction:
      return JSON.parse(JSON.stringify(initialState));
    case ParentServiceActions.UpdateParentServiceAction:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export const getParentServiceStore = createFeatureSelector<ParentServiceState>('parent-service');
