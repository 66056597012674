import { Component, Input, OnInit } from "@angular/core";
import { HeaderLink } from "../../ng-models/HeaderLink.interface";
import { Link } from "../../ng-models/Link.interface";
import * as mainNavReducer from "../../state/main-nav/reducers";
import { Store } from "@ngrx/store";
import { AbstractPageComponent } from "../../pages/abstract-page/abstract-page.component";
import * as appDataReducer from "../../state/app-data/reducers";
import { Observable } from 'rxjs';

@Component({
  selector: 'mi-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})

export class PageHeaderComponent extends AbstractPageComponent implements OnInit {

  @Input() title: string = 'Placeholder';
  @Input() breadcrumbs: Link[];
  @Input() links: HeaderLink[];
  @Input() showShortcutMenu: boolean = true;
  @Input() type: string = "";
  @Input() validationFlag: string;
  @Input() daysSinceLastUpdate: number;

  @Input() videoLink: string ;
  @Input() videoModalTitle: string= '';
  videoLinkForModal: string = null


  isOpen: boolean = false;
  tenantDisplayName$: Observable<string>;
  showValidationFlag: boolean = false;  

  constructor(private store: Store<mainNavReducer.State>) {
    super();
    this.tenantDisplayName$ = this.store.select(appDataReducer.tenantDisplayName);
   
  }

  ngOnInit(){
    if(this.type == "service_details" && this.validationFlag) {
      this.showValidationFlag = true;
    }
    
    this.store.select(mainNavReducer.getMainNav).takeUntil(this.destroy$).subscribe(
      state => {
        this.isOpen = state.open;
      });

     
  }

  ngAfterViewInit() {
    if(this.videoLink){
      document.getElementById("video-iframe").setAttribute("src", this.videoLink);
    }
  }

  openVideoInfo(videoModal){
    videoModal.open()
    videoModal.closed.subscribe(({reason, result = []}) => {
      console.log("modal closed");
      document.getElementById("video-iframe").setAttribute("src", this.videoLink);
    });
  }
}
