import { Routes } from '@angular/router';
import { Miso3AccountComponent } from './miso3-account.component';
import { SuperUserAuthGuard } from 'src/libs/auth-guards/guards/SuperUserAuth.guard';
import { TenantUserAuthGuard } from 'src/libs/auth-guards/guards/TenantUserAuth.guard';
import { ManagePaymentMethodsComponent } from './manage-payment-methods/manage-payment-methods.component';
import { ManageCurrentPlanComponent } from './manage-current-plan/manage-current-plan.component';
import { PlanAndUsageDetailsComponent } from './plan-and-usage-details/plan-and-usage-details.component';
import { TenantSuperAdminAuthGuard } from 'src/libs/auth-guards/guards/TenantSuperAdminAuth.guard';

const Miso3AccountRoutes: Routes = [
  {
    path: 'billing',
    component: Miso3AccountComponent,
    data: {
      pageTitle: 'Billing',
      headerLabel: 'Billing'
    },
    canActivate: [TenantSuperAdminAuthGuard] // check if only tenant super admin is able to access
  },
  {
    path: 'payment-methods',
    component: ManagePaymentMethodsComponent,
    data: {
      pageTitle: 'Billing - Manage Payment Methods',
      headerLabel: 'Manage Payment Methods'
    },
    canActivate: [TenantSuperAdminAuthGuard]
  },
  {
    path: 'current-plan',
    component: ManageCurrentPlanComponent,
    data: {
      pageTitle: 'Billing - Manage Current Plan',
      headerLabel: 'Manage Current Plan'
    },
    canActivate: [TenantSuperAdminAuthGuard]
  },
  {
    path: 'plan-and-usage',
    component: PlanAndUsageDetailsComponent,
    data: {
      pageTitle: 'Billing - Plan and Usage Details',
      headerLabel: 'Plan and Usage Details'
    },
    canActivate: [TenantSuperAdminAuthGuard]
  }
];

export { Miso3AccountRoutes };
