
import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {createLogger, LOG_LEVELS} from "../../logger";
import {AbstractPageComponent} from "../../pages/abstract-page/abstract-page.component";
import {urlValidator} from "../../shared.validators";
import {Vendor} from "../../models/Vendor";
import { Store } from '@ngrx/store';
import { BusinessUnitBaseV2 } from 'src/libs/api2/types/BusinessUnitBaseV2.interface';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { Document } from 'src/libs/api2/types/Document.interface';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import {CUSTOMER_DOCUMENT_UPLOAD_TYPES, UPLOAD_TYPES} from '../../../common/utils/sharedConstants';
import {ModalService} from '@independer/ng-modal';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import { AccountId } from 'src/shared/models/AccountId';
import { FindOrCreateVendorModalComponent } from 'src/shared/components/find-or-create-vendor-modal/find-or-create-vendor-modal.component';
import { Subject } from 'rxjs';
import { CurrencyService } from 'src/libs/api2/currency-v2/currency.service';
import { TenantCurrencyV2 } from 'src/libs/api2/types/TenantCurrencyV2.interface';
import { environment } from 'src/environments/environment';
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';

const log = createLogger(LOG_LEVELS.COMPONENT);

export enum AssociatedDocumentFormType {
  LINK,
  UPLOAD
};

@Component({
  selector: 'mi-associated-document-form',
  templateUrl: './associated-document-form.component.html',
  styleUrls: ['./associated-document-form.component.scss']
})

export class AssociatedDocumentFormComponent extends AbstractPageComponent implements OnInit {

  @Input() vendor: Vendor;
  @Input() editId: string;
  @Input() hideDelete: boolean = false;
  @Output() associatedDocumentCreated: EventEmitter<Document> = new EventEmitter<Document>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  owner: string;
  document: Document;
  loading: boolean = false;
  successMessage: string = null;
  file: any = null;
  fileType: string = null;
  loaded: boolean = false;
  editMode: boolean = false;
  companyOwner: BusinessUnitBaseV2 = null;
  isTeamRequired: boolean = false;
  uploadUrl: boolean = false;
  showInvoiceDetails: boolean = false;
  showReceiptDetails: boolean = false;
  showOrderDetails: boolean = false;
  showOtherDetails: boolean = false;
  showAgreementDetails: boolean = false;
  showAmendmentDetails: boolean = false;
  showNdaDetails: boolean = false;

  readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;
  readonly UPLOAD_TYPES: any = UPLOAD_TYPES;

  commandGetAccountId:Subject<any> = new Subject();


  busy: boolean = false;
  docForm: FormGroup;
  error: string = null;
  errorLink: string = null;
  errorInvoiceNumber: string = null;
  objectKeys = Object.keys;
  selectedVendor: Vendor;

  businessUnits: BusinessUnitBaseV2[] = [];
  divisions: BusinessUnitBaseV2[] = [];
  teams: BusinessUnitBaseV2[] = [];
  currentAccountId: AccountId;
  selectedDivision: string = null;
  selectedTeam: string = null;
  vendorLocked: boolean;
  default_tenant_currency_id: string;
  tenantCurrencies: TenantCurrencyV2[];
  defaultCurrency: TenantCurrencyV2 = null;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private modalService: ModalService,
    private http: HttpClient,
    private tenantV2Service: TenantV2Service,
    private tenantDocumentManagementService: TenantDocumentManagementService,
    private tenantVendors: TenantVendors,
    private tenantAccountIdService: TenantAccountIdService,
    private currencyService: CurrencyService
  ) {
    super();
  }

  ngOnInit() {
    if(this.vendor){
      this.selectedVendor = this.vendor;
      this.vendorLocked=true;
    }
    this.store.select(appDataReducer.getAppData).first().subscribe(
      state => {
        this.owner = state.tenantData.domain;
        this.default_tenant_currency_id = state.tenantData.v2.tenant_currency_id;
        this.currencyService.getTenantCurrencies(this.owner, "")
        .toPromise()
        .then(tenantcurrency => {
          this.tenantCurrencies = tenantcurrency;
          this.getBusinessUnits();
        });
      });

  }

  getBusinessUnits() {
    this.tenantV2Service.getBusinessUnits({owner: this.owner})
      .subscribe(
        (businessUnits) => {
          this.businessUnits = businessUnits;
          // console.log('businessUnits', businessUnits);
          this.businessUnits = this.businessUnits === null || this.businessUnits === undefined ? [] : this.businessUnits;

          this.divisions = this.businessUnits.filter(bu => bu.parent_id && bu.parent_id === this.owner);
          this.companyOwner = this.businessUnits.find(x => x.id === this.owner);

          this.divisions = this.divisions === null || this.divisions === undefined ? [] : this.divisions;
          this.loading = false;

          this,this.setupForm()

          
        },
        (error) => {
          this.error = error;
        });
  }

  setupForm(){
    if (this.editId) {
      this.setupEditForm();
      
    } else {
      this.setupCreateForm();
      
    }
  }

  setupEditForm(){
    this.tenantDocumentManagementService.getDocumentById(this.editId)
    .toPromise()
    .then(doc => {
      //debugger;
      this.document = doc;
      if(this.document.type == "UNKNOWN") {
        this.document.type = null;
      }
      this.editMode = true;
      this.currentAccountId = doc.accountId;
      const {
        file_name,
        type,
        name,
        description,
        invoice_number,
        invoice_value,
        invoice_date,
        invoice_tenant_currency_id,
        invoice_from_date,
        invoice_to_date,
        receipt_number,
        receipt_value,
        receipt_tenant_currency_id,
        receipt_date,
        receipt_from_date,
        receipt_to_date,
        order_date,
        order_number,
        other_date,
        agreement_date,
        amendment_date,
        amendment_doc_id,
        nda_date,
        invoice_status
      } = this.document;
      this.changeUploadType(type);
      this.docForm = this.formBuilder.group({
        file_name: [file_name, Validators.required],
        type: [type, Validators.required],
        division_id: null,
        description: description,
        team_id: null,
        name: [name, Validators.required],
        vendor: [null, Validators.required],
        account_id: [null],
        invoice_number:[invoice_number],
        invoice_value:[invoice_value],
        invoice_date:[invoice_date],
        invoice_tenant_currency_id:[invoice_tenant_currency_id||this.default_tenant_currency_id],
        invoice_from_date: [invoice_from_date],
        invoice_to_date: [invoice_to_date],
        receipt_tenant_currency_id: [receipt_tenant_currency_id||this.default_tenant_currency_id],
        receipt_number:[receipt_number],
        receipt_value:[receipt_value],
        receipt_date:[receipt_date],
        receipt_from_date: [receipt_from_date],
        receipt_to_date: [receipt_to_date],
        order_date: [order_date],
        order_number: [order_number],
        other_date: [other_date],
        agreement_date: [agreement_date],
        amendment_date: [amendment_date],
        amendment_doc_id: [amendment_doc_id],
        nda_date: [nda_date],
        invoice_status: [invoice_status]
      });

      if (this.document.business_unit_id !== this.owner) {
        let division = null;
        let team = null;
        const businessUnit = this.businessUnits.find(x => x.id === this.document.business_unit_id);
        if (businessUnit != null) {
          const businessUnitParent = this.businessUnits.find(x => x.id === businessUnit.parent_id);
          if (businessUnitParent.parent_id === this.owner) {
            division = businessUnitParent;
            team = businessUnit;
          } else if (businessUnit.parent_id === this.owner) {
            division = businessUnit;
          }

          if (division != null) {
            this.changeDivision(division.id);
            this.docForm.get('division_id').setValue(division.id);
          }

          if (team != null) {
            this.changeTeam(team.id);
            this.docForm.get('team_id').setValue(team.id);
          }
        }
      }
      //debugger;
      if (this.document.tenant_vendor_id)
        this.getVendorById(this.document.tenant_vendor_id);
      if(this.document.account_id)
        this.getAccountIdById(this.document.account_id);
      this.uploadUrl=this.document.is_url;
      this.loaded = true;

    })
    .catch(e => {
      this.error = e;
      this.loaded = true;
    }); 
  
  }

  setupCreateForm(){
    this.uploadUrl = true;
    this.docForm = this.formBuilder.group({
      description: '',
      division_id: null,
      team_id: null,
      name: [null, Validators.required],
      file_name: [null, Validators.required],
      vendor: [this.selectedVendor, Validators.required],
      account_id: [this.currentAccountId],
      type: [null, Validators.required],
      account_number: null,
      invoice_number: null,
      invoice_date: null,
      invoice_value: null,
      invoice_tenant_currency_id: this.default_tenant_currency_id,
      receipt_tenant_currency_id: this.default_tenant_currency_id,
      invoice_currency_code: null,
      receipt_currency_code: null,
      invoice_from_date: null,
      invoice_to_date: null,
      receipt_number:null,
      receipt_value:null,
      receipt_date:null,
      receipt_from_date: null,
      receipt_to_date: null,
      order_date: null,
      order_number: null,
      other_date: null,
      agreement_date: null,
      amendment_date: null,
      amendment_doc_id: null,
      nda_date: null,
      invoice_status: null
    });

    if (this.companyOwner.role === 999 && this.divisions.length) {
      this.docForm.get('division_id').setValue(this.divisions[0].id);
      this.docForm.get('division_id').updateValueAndValidity();
      this.changeDivision(this.divisions[0].id);
      // this.changeDivision(this.divisions[0].id, true);
    }
    this.loaded = true;
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit($event);
  }

  getVendorById(id) {
    if (this.editMode) {
      this.loading = true;
    }
    this.tenantVendors.getVendorById(id).subscribe((response) => {
      this.onVendorSelect(response);
      //debugger;
      this.vendorLocked=true;
      this.loading = false;
      this.loaded = true;
    }, (error) => {
      this.error = error;
      this.loading = false;
      this.loaded = true;
    });
  }

  getAccountIdById(id) {
    this.tenantAccountIdService.getById(id).subscribe((response) => {
      this.currentAccountId = response;
    }, (error) => {
      this.error = error;
      this.loading = false;
      this.loaded = true;
    });
  }

  onFormSubmit() {
    this.errorLink = null;
    this.errorInvoiceNumber = null;
    this.commandGetAccountId.next('');
      
    }   
    
    onAccountIdSelected(account_id){
      this.currentAccountId= account_id;
    const formValue = this.docForm.value;
    if (this.editMode) {
      return this.editForm(formValue);
    }
    var invoice_currency_code = null;
    var receipt_currency_code = null;
    this.tenantCurrencies.forEach((currency) => {
      if (currency.id == formValue.invoice_tenant_currency_id) {
        invoice_currency_code = currency.code;
      }
      if (currency.id == formValue.receipt_tenant_currency_id) {
        receipt_currency_code = currency.code;
      }
    });
    const data = {
      business_unit_id: this.selectedTeam || this.selectedDivision || this.owner,
      file_name: formValue.file_name,
      name: formValue.name,
      type: formValue.type,
      //vendor_id: formValue.vendor._id,
      vendor_id: formValue.vendor.id,
      //tenant_vendor_id: formValue.vendor._id,
      tenant_vendor_id: formValue.vendor.tenant_vendor_id,
      vendor_name: formValue.vendor.vendor_name,
      description: formValue.description,
      is_url: this.uploadUrl,
      invoice_number: formValue.invoice_number,
      invoice_date: formValue.invoice_date,
      invoice_to_date: formValue.invoice_to_date,
      invoice_from_date: formValue.invoice_from_date,
      invoice_value: formValue.invoice_value,
      invoice_status: formValue.invoice_status,
      account_id: this.currentAccountId? this.currentAccountId.id: null,
      account_number: this.currentAccountId? this.currentAccountId.label: null,
      invoice_tenant_currency_id: formValue.invoice_tenant_currency_id,
      receipt_tenant_currency_id: formValue.receipt_tenant_currency_id,
      invoice_currency_code: invoice_currency_code,
      receipt_currency_code: receipt_currency_code,
      receipt_number:formValue.receipt_number,
      receipt_value:formValue.receipt_value,
      receipt_date:formValue.receipt_date,
      receipt_from_date: formValue.receipt_from_date,
      receipt_to_date: formValue.receipt_to_date,
      order_date: formValue.order_date,
      order_number: formValue.order_number,
      other_date: formValue.other_date,
      agreement_date: formValue.agreement_date,
      amendment_date: formValue.amendment_date,
      amendment_doc_id: formValue.amendment_doc_id,
      nda_date: formValue.nda_date
    };

    if (data.business_unit_id == 'null') {
      data.business_unit_id = this.owner;
    }

    this.busy = true;
    this.loading = true;
    this.tenantDocumentManagementService
      .save(data)
      .subscribe(
        (response) => {
            this.successMessage = 'Your link has been successfully submitted!';
            this.associatedDocumentCreated.emit(response);
        },
        (e) => {
          if (e.paid_invoice_document_id) {
            this.errorInvoiceNumber = `Invoice Number ${formValue.invoice_number} (${e.duplicate_display_id}) already exists.`;
            this.error = `Invoice Number ${formValue.invoice_number} (${e.duplicate_display_id}) already exists.`;
          } else
          if (e.duplicate_display_id) {
            this.errorLink =`Link ${formValue.file_name} (${e.duplicate_display_id}) already exists.`;
            this.error =`Link ${formValue.file_name} (${e.duplicate_display_id}) already exists.`;
          }
          else{
            this.error = e;
          }
          this.busy = false;
        }
      );
  }

  editForm(formValue) {
    //debugger;
    var invoice_currency_code = null;
    var receipt_currency_code = null;
    this.tenantCurrencies.forEach((currency) => {
      if (currency.id == formValue.invoice_tenant_currency_id) {
        invoice_currency_code = currency.code;
      }
      if (currency.id == formValue.receipt_tenant_currency_id) {
        receipt_currency_code = currency.code;
      }
    });
    const data = {
      file_name: formValue.file_name,
      id: this.document.id,
      business_unit_id: this.selectedTeam || this.selectedDivision || this.owner,
      name: formValue.name,
      description: formValue.description,
      type: formValue.type,
      //vendor_id: formValue.vendor._id,
      vendor_id: formValue.vendor.id,
      //tenant_vendor_id: formValue.vendor._id || formValue.vendor.id,
      tenant_vendor_id: formValue.vendor.tenant_vendor_id,
      //vendor_name: formValue.vendor.vendor.vendor_name,
      vendor_name: formValue.vendor.vendor_name,
      is_url: this.uploadUrl,
      invoice_number: formValue.invoice_number,
      invoice_date: formValue.invoice_date,
      invoice_to_date: formValue.invoice_to_date,
      invoice_from_date: formValue.invoice_from_date,
      invoice_value: formValue.invoice_value,
      invoice_status: formValue.invoice_status,
      account_id: this.currentAccountId? this.currentAccountId.id: null,
      account_number: this.currentAccountId? this.currentAccountId.label: null,
      invoice_tenant_currency_id: formValue.invoice_tenant_currency_id,
      receipt_tenant_currency_id: formValue.receipt_tenant_currency_id,
      invoice_currency_code: invoice_currency_code,
      receipt_currency_code: receipt_currency_code,
      receipt_number:formValue.receipt_number,
      receipt_value:formValue.receipt_value,
      receipt_date:formValue.receipt_date,
      receipt_from_date: formValue.receipt_from_date,
      receipt_to_date: formValue.receipt_to_date,
      order_date: formValue.order_date,
      order_number: formValue.order_number,
      other_date: formValue.other_date,
      agreement_date: formValue.agreement_date,
      amendment_date: formValue.amendment_date,
      amendment_doc_id: formValue.amendment_doc_id,
      nda_date: formValue.nda_date
    };

    if (data.business_unit_id == 'null') {
      data.business_unit_id = this.owner;
    }
    this.busy = true;
    this.loading = true;
    this.tenantDocumentManagementService
      .update(data)
      .subscribe(
        (response) => {
          this.successMessage = 'Your document has been successfully updated!';
          this.busy = false;
          this.loading = false;
          this.associatedDocumentCreated.emit(response);
        },
        (e) => {
          this.error = e;
          this.busy = false;
          this.loading = false;
        }
      );
  }

  onVendorSelect(vendor: Vendor): void {
    this.docForm.get('vendor').setValue(vendor ? vendor : null);
    this.docForm.updateValueAndValidity();
    this.selectedVendor = vendor;
  }

  openSelectVendor(evt) {
    evt.preventDefault();

    const modalRef = this.modalService.open(FindOrCreateVendorModalComponent, m => {
      m.title = 'Select Vendor or Organization';
      this.error = null;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      // Results come in array, as the modal supports multiple returned results
      const [vendor] = result;
      // console.log('vendor selected', result);
      this.onVendorSelect(vendor);
    });
  }


  changeDivision(id, triggerTeam: boolean = true) {
    this.selectedDivision = id;
    this.selectedTeam = null;
    this.teams = [];

    if (id != 'null') {
      // console.log('id of the division is valid', id);
      this.checkUnitRole(id);
      this.teams = this.businessUnits.filter(bu => bu.parent_id === id && bu.role !== 999);

      if (triggerTeam && this.teams.length) {
        // this.changeTeam(this.teams[0].id);
      }
    } else {
      this.selectedTeam = null;
      if (this.companyOwner.role === 999) {
        this.isTeamRequired = true;
        this.toggleValidation('team_id');
      }
    }
    // this.docForm.get('team_id').setValue(null);
    // this.docForm.updateValueAndValidity();
  }

  changeTeam(id) {
    this.selectedTeam = id;
    if (this.selectedTeam != 'null') {
      // console.log('id of the team', id);
      this.checkUnitRole(this.selectedTeam);
    }
    this.docForm.updateValueAndValidity();
  }

  toggleValidation(field: string = 'division_id', required: boolean = true) {
    if (this.companyOwner.role === 999) {
      this.docForm.get('division_id').setValidators([Validators.required]);
    }

    if (required) {
      this.docForm.get(field).setValidators([Validators.required, Validators.minLength(5)]);
      // console.log('team id is required to select', field);
      // const division = this.divisions.find(x => x.id === this.selectedDivision);
      // if (division != null && division.role === 999) {
        // this.docForm.get('team_id').setValidators([Validators.required, Validators.minLength(5)]);
      // }
      this.docForm.get('team_id').setValue(null);
      this.docForm.get('team_id').setErrors({'required': true, 'labelRequired': true});
    } else {
      // console.log('team id is not required to select', field);
      this.docForm.get(field).clearValidators();
    }
    this.docForm.updateValueAndValidity();
  }

  checkUnitRole(id: string) {
    const businessUnit = this.businessUnits.find(x => x.id === id);
    if (businessUnit != null) {
      if (businessUnit.role === 999) {
        // console.log('business role is 999', businessUnit);
        this.toggleValidation('team_id');
        this.isTeamRequired = true;
      }
    } else if (this.selectedDivision == 'null') {
      this.toggleValidation('team_id', false);
      this.isTeamRequired = false;
    }
  }

  toggleUpload() {
    this.uploadUrl = !this.uploadUrl;
    this.docForm.get('document').setValidators(null);
    this.docForm.get('document').clearValidators();
    if (!this.uploadUrl) {
      this.docForm.get('document').setValidators([Validators.required]);
    }
    this.docForm.get('document').updateValueAndValidity({emitEvent: false, onlySelf: false});
  }

  onDateChanged(e, field): void {
    this.docForm.get(field).setValue(e);
  }

  changeUploadType(value){
    this.showInvoiceDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.INVOICE);
    this.showReceiptDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.RECEIPT);
    this.showOrderDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.SERVICE_ORDER);
    this.showOtherDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.OTHER);
    this.showAgreementDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.MASTER_AGREEMENT);
    this.showAmendmentDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.AMENDMENT);
    this.showNdaDetails = (value == CUSTOMER_DOCUMENT_UPLOAD_TYPES.NDA);
  }

  downloadDocument(id) {
    return this.tenantDocumentManagementService.download(id).subscribe((response) => {
      window.open(response.download_url, "_blank");
    }, (e) => {

    });
  }


  getV1FileUrl(row) {
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }


}
