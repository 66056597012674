<mi-server-error [error]="loadError"></mi-server-error>
<mi-loading-animation *ngIf="loading; else loadedTpl"></mi-loading-animation>

<ng-template #loadedTpl>

  <form [formGroup]="formGroup" (submit)="onFormSubmit(formGroup.value)">
  <div class="half-col">
    <table *ngIf="allNonCustomFieldsProperties.length>0; else noCustomFieldDefined" class="custom-field-form-table">
      <tbody>
      <tr *ngFor="let customFieldProperty of allNonCustomFieldsProperties; let i = index;">
        <td class="custom-field-label">
            <label class="control-label">
            {{customFieldProperty.custom_field.label}}
            </label>
        </td>
          <td class="custom-field-value">
              <mi-extended-input
              class="service-details"
              [labelRequired]="false"
              [inputControl]="formGroup.controls[fieldName(i)]"
              [inputErrors]="formGroup.controls[fieldName(i)].errors"
              [inputTouched]="formGroup.controls[fieldName(i)].touched"
              [errorDefs]='{invalidFormat: "Invalid " + customFieldProperty.custom_field.label}'>
  
                <ng-container [ngSwitch]="customFieldProperty.custom_field.type">
                  <ng-container *ngSwitchCase="'NUMBER'">
                  <input type="number"
                          class="form-control"
                          formControlName="{{fieldName(i)}}"
                          placeholder="" mi-restrict-input miRestrictType="Number">
                  </ng-container>
                  <ng-container *ngSwitchCase="'PHONE'">
                      <mi-telephone-input
                      formControlName="{{fieldName(i)}}">
                    </mi-telephone-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'DATE'">
                  <mi-date-picker
                    (dateChange)="onDateChange($event, i)"
                    [currentDate]="customFieldProperty.value">
                  </mi-date-picker>
                  </ng-container>
                  <ng-container *ngSwitchCase="'DROPDOWN'">
                  <ng-select
                    class="mi-select"
                    [items]="customFieldProperty.custom_field.dropdownoptions"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    formControlName="{{fieldName(i)}}">
                  </ng-select>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <input type="text"
                    class="form-control"
                    formControlName="{{fieldName(i)}}"
                    placeholder="">
                  </ng-container>
                </ng-container>
  
              </mi-extended-input>
          </td>
        </tr>
        </tbody>
      </table>
  
  </div>

    <div class="button-container">
      <a mi-button
         *ngIf="cancelCTA"
         class="button--cancel"
         buttonTheme="light-theme"
         (click)="onCancel($event)">
        {{cancelCTA}}
      </a>
      <button
        mi-button
        *ngIf="submitCTA && (!hideSubmitIfNoCustomFields || allNonCustomFieldsProperties.length>0)"
        buttonTheme="dark-theme"
        type="submit"
        [disabled]="!formGroup.valid">
        {{submitCTA}}
      </button>
    </div>

  </form>

  <ng-template #noCustomFieldDefined>
    <span class="no-results">
      {{'TENANT.CUSTOM_FIELDS.CUSTOM_FIELDS_EMPTY_TITLE' | translate}}
    </span>
  </ng-template>
</ng-template>
