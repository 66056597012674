<mi-server-error [error]="loadError"></mi-server-error>
<mi-loading-animation *ngIf="loading; else loadedTpl"></mi-loading-animation>

<ng-template #loadedTpl>

  <ng-container *ngIf="currentFieldData.length; else noCustomFields">
    <dl class="section-details">

      <ng-container *ngFor="let customFieldProperty of currentFieldData">

        <div>
          <dt>
            {{customFieldProperty.customFieldLabel}}
          </dt>
          <dd>
              <ng-container  *ngIf="customFieldProperty.value||customFieldProperty.value===0; else noDATA" [ngSwitch]="customFieldProperty.custom_field.type">
                <ng-container *ngSwitchCase="'CONTACT'">
                  <ng-container *ngFor="let contact of customFieldProperty.value">
                    <mi-id-link id="{{contact.display_id}}"></mi-id-link>
                    {{contact.first_name}} {{contact.last_name}}
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'PHONE'">
                    <ng-container>
                        <a type="tel" href="tel:{{customFieldProperty.value.display}}">{{customFieldProperty.value.display}}</a>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'NUMBER'">
                  <ng-container>
                    {{customFieldProperty.value | number}}
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{customFieldProperty.value}}
                </ng-container>
              </ng-container>
              <ng-template #noDATA>
                  {{NO_DATA}}
              </ng-template>
          </dd>
        </div>
      </ng-container>
    </dl>
  </ng-container>

  <ng-template #noCustomFields>
    <div class="no-results">
      {{'TENANT.CUSTOM_FIELDS.CUSTOM_FIELDS_EMPTY_TITLE' | translate}}
    </div>
  </ng-template>

</ng-template>
