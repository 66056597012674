<div class="tab-title-header">
  <h2>{{title}}</h2>
  <ng-container *ngIf="editable">
    <a class="edit-link button dark-theme" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>
    </ng-container>
</div>

<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="pageLoaded; else loading">

  <div class="open" *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
        <mi-service-ownership-static
          [requestor]= "requestor"
          [miMisoUser]= "miMisoUser"
          [endUser]= "endUser"
          [tenant_id]= "serviceDetails.general.tenant_id"
          [division]= "serviceDetails.service_data.division_name"
          [team]= "serviceDetails.service_data.team"
          [editMode]= "true"
          (updateData)= "updateData($event)"
          (removeEmployee)= "onRemoveEmployee($event)"
        >
        </mi-service-ownership-static>
    </mi-opacity-loader>
  </div>

  <ng-template #static>
    <span  *ngIf="loadUser;else loading">
      <mi-service-ownership-static
        [requestor]= "requestor"
        [miMisoUser]= "miMisoUser"
        [endUser]= "endUser"
        [tenant_id]= "serviceDetails.general.tenant_id"
        [division]= "serviceDetails.service_data.division_name"
        [team]= "serviceDetails.service_data.tam"
        [editMode]= "false"
      >
      </mi-service-ownership-static>
    </span>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
