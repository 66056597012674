import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DropdownItemData} from '../../models/DropdownItem.interface';
import {Router} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../logger';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-dropdown',
  styleUrls: ['./dropdown.component.scss'],
  templateUrl: './dropdown.component.html'
})

export class DropdownComponent implements OnInit {

  @HostListener('document:click', ['$event']) clickOutside(event) {
    if (this.isOpen && !this.el.nativeElement.contains(event.target)) {
      this.toggleOpen();
    }
  }

  @Input() disabled: boolean = false;
  @Input() customList: boolean = false;
  @Input() dropdownTitle: string = 'DROPDOWN TITLE';
  @Input() icon: string;
  @Input() items: DropdownItemData[] = [];
  @Input() startOpen: boolean = false;
  @Input() theme: string;
  isOpen: boolean = false;

  constructor(
    private el: ElementRef,
    private router:Router
  ) {
  }


  ngOnInit(): void {

    this.el.nativeElement.className += (this.el.nativeElement.className.length === 0 ? '' : ' ') + (this.theme || 'default');

    this.isOpen = this.startOpen;

    if (!this.customList && (!this.items || this.items.length === 0)) {
      this.disabled = true;
      this.isOpen = false;
    }

    if (this.disabled) {
      this.el.nativeElement.className += (this.el.nativeElement.className.length === 0 ? '' : ' ') + 'disabled';
    }

    if (this.isOpen) {
      this.el.nativeElement.className += (this.el.nativeElement.className.length === 0 ? '' : ' ') + 'open';
    }

    if (this.icon) {
      switch (this.icon) {
        case 'fast-foward':
        case 'profile':
        case 'compose':
          /*
          * Intent here is we can easily extend icons by just
          * stacking other CASE X: values prior to the
          * declaration and return.
          */
          this.el.nativeElement.className += (this.el.nativeElement.className.length === 0 ? '' : ' ') + 'icon icon-' + this.icon;
          return;
        default:
          // No icon.
      }
    }

  }

  onItemSelected(data:DropdownItemData):void{

    if(!data.url){
      log.error(`No router link present for dropdown option ${data.title}`);
      return;
    }

    if(data.isDownload){
      window.open(data.url, 'Download');
    }else if(!!data.externalLink){
      // External link:
      window.open(data.url, data.target || '_self');
    }else{
      this.router.navigate([data.url], data.queryParams || {});
    }

  }

  toggleOpen() {
    if (this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
    this.isOpen ? (this.el.nativeElement.classList.add('open')) : (this.el.nativeElement.classList.remove('open'));
  }
}
