<div class="filter-group" *ngIf="filters.length && isVisible">
  <h2>{{title}}</h2>

  <ul>
    <li *ngFor="let filter of filters" class="filter-item">

      <label>

        <input type="checkbox"
               [checked]="filter.selected"
               (change)="toggle($event, filter.key)"/>

        <div *ngIf="filter.key != 'null'; else textBlock">
          <span>
            {{ contacts[filter.key].first_name + " " + contacts[filter.key].last_name}}</span>
            <span *ngIf="includeCount">({{filter.doc_count}})</span>
          <div class="small">
            <span>{{contacts[filter.key].vendor.vendor_name}}</span>
          </div>
        </div>
        <ng-template #textBlock>
          <div class="no-requestor">
            <span>No Service Owner</span>
            <span *ngIf="includeCount">({{filter.doc_count}})</span>
          </div>
        </ng-template>

      </label>

    </li>
    <li *ngIf="showAll"><a class="showAll" (click)="onShowAllClicked()">Show All</a></li>
  </ul>
</div>



