<select [ngModel]="selected" (change)="toggle($event)">
  <option disabled >{{'SEARCH_FILTERS.SORT_BY' | translate}}</option>

  <ng-container [ngSwitch]="searchType">
    <ng-container *ngSwitchCase="'SERVICE'">
      <option *ngFor="let val of serviceOptions;" value="{{val}}">
        {{'SORTS.' + val | translate}}
      </option>
    </ng-container>
    <ng-container *ngSwitchCase="'DOCUMENT'">
      <option *ngFor="let val of documentOptions;" value="{{val}}">
        {{'SORTS.' + val | translate}}
      </option>
    </ng-container>
  </ng-container>
  
</select>