import { Component, OnInit } from "@angular/core";
import { Link } from "src/shared/ng-models/Link.interface";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Params, Router} from '@angular/router';
import { Store, select } from "@ngrx/store";
import * as userReducer from '../../../libs/user-store/state/reducers';
import * as tenantBillingAccountReducers from '../../../shared/state/tenant-billing-account/reducers';
import { map, takeUntil } from "rxjs/operators";
import { TenantBillingAccount } from "src/shared/models/Billing";
import { BillingService } from "src/libs/api2/billing/billing.service";
import { CurrencyService } from "src/libs/api2/currency-v2/currency.service";
import { TenantCurrencyV2 } from "src/libs/api2/types/TenantCurrencyV2.interface";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";

interface AccountPlanDetails {
  planName?: string;
  billingAmount?: number;
  billingInterval?: string;
  inTrialPeriod?: boolean;
}

interface AccountOrgDetails {

}

interface SuperAdminDetails {

}

interface BillingDetails {

}

@Component({
  templateUrl: './miso3-account.component.html',
  styleUrls: ['./miso3-account.component.scss']
})

export class Miso3AccountComponent extends AbstractPageComponent implements OnInit {
  breadcrumbs: Link[];
  headerLabel: string = "";
  tenantBillingAccount: TenantBillingAccount;
  stripeCustomer: any;
  stripeSubscription: any;
  accountPlanDetails: AccountPlanDetails = {};
  tenantCurrency: TenantCurrencyV2;
  loaded: boolean = false;
  tenantBillingAccountNotFound: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<userReducer.State>,
    private billingService: BillingService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.data.subscribe((data: any) => {
      console.log("data", data);
      this.headerLabel = data.headerLabel;
    });

    this.activatedRoute
      .params
      .subscribe((queryParams: Params) => {

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: this.headerLabel,
            url: './',
            internal: true
          }
        ];
      });    

    this.store.pipe(
      select(tenantBillingAccountReducers.getTenantBillingAccountData),
      takeUntil(this.destroy$),
      map(async (billingAccountData) => {        
        this.tenantBillingAccount = billingAccountData.result;
        console.log("tenantBillingAccount", this.tenantBillingAccount);
        // this.getBusinessUnit(appData.tenantData.domain);
        //this.loaded = true;
        if(Object.keys(this.tenantBillingAccount).length == 0) {
          console.log("TBA not found");
          this.loaded = true;
          this.tenantBillingAccountNotFound = true;
          return;
        }

        await this.getTenantCurrency();
        await this.getStripeCustomer();
        await this.getStripeSubscription();

        this.loaded = true;
      })
      ).subscribe();
  }

  async getStripeCustomer(): Promise<void> {
    console.log("stripeCustomerId", this.tenantBillingAccount.stripeCustomerId);
    let res = await this.billingService.getCustomer(this.tenantBillingAccount.stripeCustomerId).toPromise();
    console.log("getCustomer", res);
    this.stripeCustomer = res;

    this.stripeCustomer.addressString = this.stripeCustomer.address.line1 + ", ";

    if(this.stripeCustomer.address.line2) {
      this.stripeCustomer.addressString += this.stripeCustomer.address.line2 + ", ";
    }

    this.stripeCustomer.addressString +=
                        this.stripeCustomer.address.city + ", " +
                        this.stripeCustomer.address.state + ", " +
                        this.stripeCustomer.address.country + " - " +
                        this.stripeCustomer.address.postal_code;
  }

  async getStripeSubscription(): Promise<void> {
    console.log("stripeSubscriptionId", this.tenantBillingAccount.stripeSubscriptionId);
    let res = await this.billingService.getSubscription(this.tenantBillingAccount.stripeSubscriptionId).toPromise();
    console.log("getSubscription", res);
    this.stripeSubscription = res;
    await this.calculateTotalBillingAmount();
    await this.getStripeProducts();
  }

  async getStripeProducts(): Promise<void> {
    await this.stripeSubscription.items.data.map(async subscriptionItem => {
      let res = await this.billingService.getProduct(subscriptionItem.plan.product).toPromise();
      subscriptionItem.product = res;
      if(subscriptionItem.product.metadata.plan_type == "base") {
        this.accountPlanDetails.planName = subscriptionItem.product.name;
        this.accountPlanDetails.billingInterval = subscriptionItem.plan.interval;
      }
    });
    console.log("stripeSubscription", this.stripeSubscription);
  }

  async calculateTotalBillingAmount(): Promise<void> {
    let total = 0;
    this.stripeSubscription.items.data.map(subscriptionItem => {
      total += (subscriptionItem.plan.amount * subscriptionItem.quantity);
    });
    this.accountPlanDetails.billingAmount = total/100;
  }

  async getTenantCurrency(): Promise<void> {
    this.tenantCurrency = FrontEndSettingsUtil.get().MISO3_TENANT_CURRENCY;
    console.log("tenantCurrency", this.tenantCurrency);
  }
}