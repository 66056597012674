import {Component, Input} from "@angular/core";

@Component({
  selector: 'mi-header-data',
  template: `<div class="vendor-details--data-point">
    <dl>
      <dt>
        {{label}}
      </dt>
      <dd>
        {{value}}
      </dd>
    </dl>
  </div>`,
  styleUrls: ['./header-data.component.scss']
})

export class HeaderDataComponent{

  @Input() label: string = '';
  @Input() value: any;
  @Input() type: string = '';

  constructor(){}

}
