<div>

  <mi-page-header
    title="Request a Quote"
    [breadcrumbs]="breadcrumbs">
  </mi-page-header> 

  <mi-server-error [error]="error"></mi-server-error>
  <mi-page-alert></mi-page-alert>

  <div *ngIf="loaded; else loading">
    <ng-container *ngIf= "!email_step">
      <h2>Select whom you would like to get quote from:</h2>
      <div class='checkbox-input'>
        <input type="checkbox" value="contactCurrent" (click)="recipientsSelect($event)" checked="true">
        <strong>&nbsp;{{service.purchasing_vendor.vendor_name}}</strong>&nbsp;| account: {{service.account_id_v2.label}} (Current)
      </div>
      <div class='checkbox-input'>
        <input type="checkbox" value="contactMarket" (click)="recipientsSelect($event)" checked="true">
        <strong>&nbsp;MISO Marketplace</strong>&nbsp;(Let MISO find you saving)
      </div>

      <div class="button-container">
        <a mi-button
          class="button--cancel"
          buttonTheme="light-theme"
          (click)="cancel()">
          Cancel
        </a>
        <button mi-button
          buttonTheme="dark-theme"
          (click)="toQuotePage()"
          [disabled]="buttonDisable">
          Continue
        </button>
      </div>
    </ng-container>

  </div> 





  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</div>