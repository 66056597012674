<div>

  <mi-page-header
    title="Cancellation Request"
    [breadcrumbs]="breadcrumbs"
    [links]="headerLinks">
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>
  <mi-page-alert></mi-page-alert>

  <ng-container *ngIf="!error">

    <mi-email-composer
      *ngIf="loaded; else loading"
      [cancelUrl]="cancelUrl"
      [defaultRecipients]="emailRecipients"
      [subject]="emailSubject"
      [body]="emailBody"
      [services]="[service]"
      [vendorName]="service.product.product_vendor.vendor_name"
      [type]="emailType"
      (emailCreated)="onEmailCreated($event)">
    </mi-email-composer>

  </ng-container>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
