import {Directive, forwardRef, ElementRef, Input, HostListener} from '@angular/core';

import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[mi-select-on-focus]'
  })

export class MiSelectOnFocus {

  
  constructor(private el: ElementRef) {
  }

  private hasFocus: boolean = false;

  @HostListener('click', ['$event']) onClick(e) {
    if(!this.hasFocus){
      this.hasFocus = true;
      try{
        this.el.nativeElement.focus();
        this.el.nativeElement.select();
      }
      catch(e){
        //console.log(e);
      }
    }
  }

  @HostListener('blur', ['$event']) onBlur(e) {
    this.hasFocus = false;
  }
}
