import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";
import { Store } from "@ngrx/store";
import { BulkUploadTemplateField } from "src/shared/models/BulkUploadTemplateSection.interface";
import { SortableTableColumn } from "src/shared/ng-models/SortableTableColumn.interface";
import * as appDataReducer from '../../state/app-data/reducers';
import * as ASReducer from '../../state/advanced-search/reducers';
import {AsSimpleStateUpdate} from "../../state/advanced-search/actions";

@Component({
  templateUrl: './sortable-table-column-select-modal-v2.component.html',
  styleUrls: ['./sortable-table-column-select-modal-v2.component.scss']
})

export class SortableTableColumnSelectModalV2Component implements OnInit {
  data: any;
  _columns: Array<SortableTableColumn> = [];
  customFieldById: any;
  tagById: any;
  searchType: string = '';
  fields: BulkUploadTemplateField[]= [];
  cfFields: BulkUploadTemplateField[]= [];
  tagFields: BulkUploadTemplateField[]= [];
  numberOfStickyColumn: number = 1;
  onSubmit: boolean = false;

  constructor(
    private modal: ModalRef,
    private store: Store<appDataReducer.State>,) {
  }
  
  ngOnInit(): void {
    //console.log("_columns", this._columns);
    if(this.searchType === "SERVICE"){
      this.fields = [];
      this._columns.map((item, i) => {
        if(!this.customHeaderName(item.name) && !this.tagHeaderName(item.name)) {
          this.fields.push({
            field_id: item.name,
            display: item.name,
            selected: item.hidden? false : true,
            index: i,
            required: i<this.numberOfStickyColumn? true: false
          });
        } else if(this.tagHeaderName(item.name)) {
          this.tagFields.push({
            field_id: item.name,
            display: item.name,
            selected: item.hidden? false : true,
            index: i,
            required: i===0? true: false
          });
        } else if(this.customHeaderName(item.name)) {
          this.cfFields.push({
            field_id: item.name,
            display: item.name,
            selected: item.hidden? false : true,
            index: i,
            required: i===0? true: false
          });
        }
      });
      //console.log("cfFields", this.cfFields);
    }
  }

  onFieldsSelected(select) {
    //console.log("_columns", this._columns);
    //console.log("onFieldsSelected", select);
    this._columns = select.map(item => {
  
      if(item.column_name === item.field_id){
        let currentColumns = this._columns.find(c => c.name === item.field_id);

        return {
          ...currentColumns,
          name: item.column_name,
          hidden: item.selected? false : true,
          is_custom_data: false,
          is_tag_data: false
        }
      } else if(item.field_id.substring(0, 3) != "cf_") {
        //console.log("onFieldsSelected TAG");
        let currentColumns = this._columns.find(c => c.name === item.field_id);
        return {
          ...currentColumns,
          name: item.field_id,
          hidden: item.selected? false : true,
          is_custom_data: false,
          is_tag_data: true
        }
      } else {
        //console.log("in else");
        //console.log("item.field_id.substring(3)", item.field_id.substring(3));
        let currentColumns = this._columns.find(c => c.name === item.field_id.substring(3));
        //console.log("currentColumns", currentColumns);
        return {
          ...currentColumns,
          name: item.field_id.substring(3),
          hidden: item.selected? false : true,
          is_custom_data: true,
          is_tag_data: false
        }
      }
    })
    this.updateColumnsState();
    this.close();
  }

  updateColumnsState() {
    //console.log("updateColumnsState _columns", this._columns);
    let currentColumns = this._columns.map(item => {
      let column : ASReducer.IColumn = {
        name:item.name,
        hidden: item.hidden? true : false,
        is_custom_data: false,
        is_tag_data: false
      }
      if(this.customHeaderName(item.name)){
        //console.log("is custom header", item.name);
        column.name = this.customFieldById[item.name];
        column.is_custom_data = true;
        column.is_tag_data = false;
      }
      else if(this.tagHeaderName(item.name)){
        //console.log("is tag header", item.name);
        column.name = this.tagById[item.name];
        column.is_custom_data = false;
        column.is_tag_data = true;
        //console.log("column", column);
      }
      return column;
    })
    //console.log("updateColumnsState currentColumns", currentColumns);
    this.store.dispatch(new AsSimpleStateUpdate({columns: currentColumns}));
  }

  customHeaderName(columnName){
    //console.log("columnName", columnName);
    const thisCustomField = this.customFieldById[columnName];
    //console.log("thisCustomField", thisCustomField);
    if(!thisCustomField){
      return '';
    }
    return true;
  }

  tagHeaderName(columnName){
    //console.log("columnName", columnName);
    const thisTag = this.tagById[columnName];
    //console.log("thisTag", thisTag);
    if(!thisTag){
      return '';
    }
    return true;
  }

  close() {
    this.modal.close({columns:this._columns});
  }

  onToggleSelect(i){
   this._columns[i].hidden = !this._columns[i].hidden;
   this.updateColumnsState();
  }

  selectAll(select:boolean){
    //console.log("numberOfStickyColumn", this.numberOfStickyColumn)
    this._columns = this._columns.map((column, i)=> {
      if(i>this.numberOfStickyColumn-1){
        column.hidden = select;
      }
      return column;
    })
    this.updateColumnsState();
  }

  onModalClose(): void {
    this.modal.close('User closed');
  }

  submit(): void {
    this.onSubmit = true;
  }
}