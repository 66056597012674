import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiServiceDetails } from '../../../../../../shared/models/MiService';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as SDReducer from '../../state/reducers';
import * as SDActions from '../../state/actions';
import { Store } from '@ngrx/store';
import { EVENT_TYPE_ACTIVATION } from '../../../../../../common/utils/sharedConstants';

import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mi-service-details-form-remove-cancellation',
  templateUrl: './remove-cancellation-form.component.html'
})

export class RemoveCancellationFormComponent implements OnInit {

  @Input() miService: MiServiceDetails;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  cancellationFormGroup: FormGroup;
  error: string = '';
  readonly EVENT_TYPES: any = {
    EVENT_TYPE_ACTIVATION: EVENT_TYPE_ACTIVATION
  };
  updating: boolean = false;

  constructor(
    private serviceV2Service: ServiceV2Service,
    private formBuilder: FormBuilder,
    // private store: Store<SDReducer.State>,
    private toastr: ToastrService,

  ) {
    // Nothing.
  }

  ngOnInit() {
    this.cancellationFormGroup = this.formBuilder.group({
      service_id: this.miService.general.service_display_id
    });
  }

  onCancelClicked(e): void {
    e.stopPropagation();
    e.preventDefault();
    this.canceled.emit();
  }

  onCancellationFormSubmit() {
    this.updating = true;
    this.error = '';

    this.serviceV2Service
      .servicePlanCancelation(
        this.miService.service_data.service_id, null)
      .toPromise()
      .then(res => {
        console.log("here is res", res)
        // this.store.dispatch(new SDActions.SDLoadSuccess({miService: res}));
        // this.store.dispatch(new NoteActions.LoadNotesAction({
        //   document_id: res._id,
        //   document_collection: MisoCollection.SERVICES,
        //   events: res.events
        // }));
        this.toastr.success(`Service cancellation removed successfully`);
        this.updateServiceDetailsStore.emit();
        this.canceled.emit();

      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while removing cancellation for service`);
      })
      .finally(() => {
        this.updating = false;
      });

    // this.tenantMiService
    //   .removeCancellation(this.miService._id, NoteHtml.CANCELLATION_REMOVAL_NOTE_HTML)
    //   .toPromise()
    //   .then(res => {
    //     this.store.dispatch(new SDActions.SDLoadSuccess({miService: res}));
    //     this.store.dispatch(new NoteActions.LoadNotesAction({
    //       document_id: res._id,
    //       document_collection: MisoCollection.SERVICES,
    //       events: res.events
    //     }));
    //     this.canceled.emit();
    //   })
    //   .catch(e => this.error = e)
    //   .finally(() => {
    //     this.updating = false;
    //   });

  }

}
