<div class="form-container">
  <form [(formGroup)]="docForm" (ngSubmit)="onFormSubmit()">
    <section>
      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.UPLOAD_TYPE_TITLE' | translate}}"
        [labelRequired]="true"
        hint="{{'DATA_IMPORT.UPLOAD_TYPE_HINT' | translate}}">
        <select
          formControlName="import_type">
          <option [value]="null" disabled>
            {{'DATA_IMPORT.UPLOAD_TYPE_OPTION_ONE' | translate}}
          </option>
          <option *ngFor="let type of objectKeys(DOC_TYPES)" [value]="type">
            {{"UPLOAD_DOC_TYPES." + DOC_TYPES[type] | translate }}
          </option>
        </select>
      </mi-extended-input>
    </section>

    <section>
      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.FILE_TITLE' | translate}}"
        hint="{{'DATA_IMPORT.FILE_HINT' | translate}}"
        [errorDefs]="{required: 'A file is required.'}"
        [labelRequired]="true">
        <mi-upload accept="{{UPLOAD_TYPES.CUSTOMER_DOCUMENT_IMPORT.CONTENT_TYPE}}"
                   upload_type="{{UPLOAD_TYPES.CUSTOMER_DOCUMENT_IMPORT.KEY_TO_STRING}}"
                   formControlName="document"></mi-upload>
      </mi-extended-input>
    </section>

    <section>
      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.FILE_NAME_TITLE' | translate}}"
        hint="{{'DATA_IMPORT.FILE_NAME_HINT' | translate}}"
        [labelRequired]="true"
        [inputControl]="docForm.controls['label']"
        [inputErrors]="docForm.controls['label'].errors"
        [inputTouched]="docForm.controls['label'].touched"
        [errorDefs]="{required: 'A file name is required.'}">
        <input
          formControlName="label"
          type="text"
          name="file_name"
          placeholder="{{'DATA_IMPORT.FILE_NAME_PLACEHOLDER'| translate}}"/>
      </mi-extended-input>
    </section>

    <section>

      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.UPLOAD_DOCUMENT_VENDOR_TITLE' | translate}}"
        [labelRequired]="true"
        hint="{{'DATA_IMPORT.UPLOAD_DOCUMENT_VENDOR_HINT' | translate}}">
      </mi-extended-input>

      <mi-simple-vendor
        *ngIf="selectedVendor"
        [vendor]="selectedVendor">
      </mi-simple-vendor>

      <button
        mi-button
        (click)="openSelectVendor($event)">
        {{(selectedVendor ? 'DATA_IMPORT.CHANGE_VENDOR' : 'DATA_IMPORT.CHOOSE_VENDOR') | translate}}
      </button>

    </section>

    <section>
      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.ADDITIONAL_INSTRUCTIONS_TITLE' | translate}}"
        hint="{{'DATA_IMPORT.ADDITIONAL_INSTRUCTIONS_HINT' | translate}}">
        <angular-tinymce
          formControlName="comment">
        </angular-tinymce>
      </mi-extended-input>
    </section>

    <div class="button-container">
      <a buttonTheme="light-theme"
         mi-button
         routerLink="/data-import">
        {{'DATA_IMPORT.CANCEL' | translate}}
      </a>
      <button buttonTheme="dark-theme"
              [disabled]="!docForm.valid"
              mi-button
              type="submit">
        {{'DATA_IMPORT.UPLOAD' | translate}}
      </button>
    </div>

    <mi-server-error [error]="error"></mi-server-error>

  </form>

  <div class="form-busy" *ngIf="busy">
    <mi-loading-animation></mi-loading-animation>
  </div>

</div>
