import {Injectable} from "@angular/core";
import {getAuthCookieV2} from "./state/auth-cookie";
import {Observable} from "rxjs/index";
import {AuthServiceReturn} from "../../shared/ng-models/AuthServiceReturn.interface";
import {switchMap} from "rxjs/operators";
import {AuthService} from "../api/auth/auth.service";
import {SettingsService} from "../api/settings/settings.service";
import {HttpClient} from "@angular/common/http";
import {AuthV2Service} from "../api2/auth-v2/auth-v2.service";
import {ISecureUser} from "../../common/security/secureUser";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import { environment } from "src/environments/environment";

@Injectable()
export class UserStoreService {


  constructor(private http: HttpClient,
              private authService: AuthService,
              private authV2Service: AuthV2Service,
              private settingsService: SettingsService) {

  }

  getLoggedInService():Observable<AuthServiceReturn> {
    // make sure the settings service is loaded
    /*
    return this.settingsService.get()
      .pipe(
        switchMap( (data) => {
           console.log('getAuthCookieV2',getAuthCookieV2())
          //debugger;
          if(getAuthCookieV2() !== null) {
            return this.getLoggedInSecureUser();
          }
          return this.authService.getLoggedInUser();
        })
      )
    */
    if(getAuthCookieV2() !== null) {
      return this.getLoggedInSecureUser();
    }
    return this.authService.getLoggedInUser();
  }

  getLoggedInSecureUser():Observable<AuthServiceReturn> {
    return this.authV2Service.getLoggedInUser();
  }
}
