import * as missingDataActions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {createLogger, LOG_LEVELS} from "../../../../../../shared/logger";
import {AccountId} from "../../../../../../shared/models/AccountId";
import {Contact} from "../../../../../../shared/models/Contact";
import {Vendor} from "../../../../../../shared/models/Vendor";
import {MissingDataTotalsBucket, VendorMissingData} from "../../../../../../shared/models/VendorMissingData";

const log = createLogger(LOG_LEVELS.REDUX_DEBUG);


export interface MissingAccountResults {
  account_id: AccountId,
  total_count: number,
  total_cost: number,
  account_id_not_provided: boolean
}

export interface MissingDataAccountGroups {
  filter: string,
  contact?: Contact,
  total_count: number,
  total_cost: number,
  selected: boolean
}

export interface State {
  error: any,
  loaded: boolean,
  filter: string,
  vendor: Vendor,
  accountIds: AccountId[],
  selectedAccountIds: AccountId[],
  selectedContact: Contact,
  rawVendorMissingData: VendorMissingData,
  results: MissingAccountResults[],
  allResults: MissingAccountResults[],
  accountGroups: MissingDataAccountGroups[],
  totalCount: number,
  totalCost: number
}

const initialState: State = {
  error: null,
  loaded: false,
  vendor: null,
  filter: '',
  accountIds:[],
  selectedAccountIds: [],
  selectedContact: null,
  rawVendorMissingData: null,
  results: null,
  allResults: null,
  accountGroups: [],
  totalCount: 0,
  totalCost: 0,
};

export function reducer(state = initialState, action: missingDataActions.Actions): any {
  let newState;
  switch (action.type) {

    case missingDataActions.ActionTypes.RESET_MISSING_VENDOR_DATA:{
      return {
        ...initialState,
        loaded: false
      }
    }

    case missingDataActions.ActionTypes.STARTUP_MISSING_VENDOR_DATA:{
      return {
        ...state,
        vendor: action.payload.vendor,
        loaded: false
      }
    }

    case missingDataActions.ActionTypes.MISSING_DATA_ACCOUNT_IDS_LOAD_COMPLETE:{
      return {
        ...state,
        ...action.payload,
        loaded: false
      }
    }

    case missingDataActions.ActionTypes.MISSING_DATA_VENDOR_DATA_LOAD_COMPLETE:{

      const rawVendorMissingData:VendorMissingData = action.payload.results
        .find( (venderMissingData: VendorMissingData) => {
          return venderMissingData.vendor._id === state.vendor._id;
      });

      newState = gatherAccountGroups({
        ...state,
        rawVendorMissingData,
        loaded: true
      });

      newState.results = gatherSelectedResults(newState);
      newState.selectedContact = getSelectedContact(newState);
      newState.selectedAccountIds = gatherSelectedAccountIds(newState);

      newState = {
        ...newState,
        ...gatherTotals(newState.results)
      };

      return newState
    }

    case missingDataActions.ActionTypes.MISSING_DATA_DEEPLINK_CHANGE:{

      newState = gatherAccountGroups({
        ...state,
        ...action.payload
      });

      newState.results = gatherSelectedResults(newState);
      newState.selectedContact = getSelectedContact(newState);
      newState.selectedAccountIds = gatherSelectedAccountIds(newState);
      newState = {
        ...newState,
        ...gatherTotals(newState.results)
      };

      return newState;
    }

    case missingDataActions.ActionTypes.MISSING_DATA_UPDATE_ACCOUNT_CONTACT_COMPLETE:{
      const newAccountId = action.payload.accountId;

      // debugger;

      newState = {
        ...state,
        accountIds: [...state.accountIds]
      };

      newState.accountIds = newState.accountIds.map(accountId => {
        if(accountId._id === newAccountId._id) {
          return newAccountId
        }
        return accountId;
      });

      newState = gatherAccountGroups(newState);
      newState.results = gatherSelectedResults(newState);
      newState.selectedContact = getSelectedContact(newState);
      newState.selectedAccountIds = gatherSelectedAccountIds(newState);

      newState = {
        ...newState,
        ...gatherTotals(newState.results)
      };

      return newState;
    }

    case missingDataActions.ActionTypes.REQUEST_FAILED_MISSING_VENDOR_DATA:{
      console.error(action.payload);
      return {
        ...state,
        error: 'There was an issue loading this page, please contact customer support'
      };
    }

    default: {
      return state;
    }
  }
}

function gatherAccountGroups(state: State) {
  let newState:State = {...state};

  if(newState.loaded) {

    let newResults:  MissingAccountResults[];
    const rawAccountsWithCounts: MissingDataTotalsBucket[] =  newState.rawVendorMissingData.by_account_id.buckets
      .filter( missingDataBucket => missingDataBucket.doc_count);

    newResults = rawAccountsWithCounts.map( (rawAccountId:MissingDataTotalsBucket) => {

      const accountBucketResult = gatherBucketResult(rawAccountId);
      const account_id = newState.accountIds.find( accountId => accountId._id === rawAccountId.key);

      return {
        ...accountBucketResult,
        account_id
      }
    });

    let unkownAccountGroup: MissingDataAccountGroups;
    let notProvidedAccountGroup: MissingDataAccountGroups;

    const newAccountGroups = [];

    newAccountGroups.push({
      filter: '',
      contact: null,
      total_count: newState.rawVendorMissingData.total_count,
      total_cost: newState.rawVendorMissingData.total_mrc
    });


    let uniqueContacts:Contact[] = newResults
      .filter( newResult => {
        return newResult.account_id && newResult.account_id.contact;
      })
      .map( newResult=> {
        return newResult.account_id.contact;
      });

    uniqueContacts = uniqueContacts.filter( (contact, index) => {
      const firstIndex = uniqueContacts.findIndex( contactInner => contactInner._id === contact._id );
      return firstIndex === index;
    });

    const contactAccountGroups: MissingDataAccountGroups[] = uniqueContacts.map( contact=> {

      let total_count = 0;
      let total_cost = 0;

      newResults.forEach( (currentResult: MissingAccountResults)=> {
        if(
          currentResult.account_id &&
          currentResult.account_id.contact &&
          currentResult.account_id.contact._id === contact._id
        ) {
          total_count += currentResult.total_count;
          total_cost += currentResult.total_cost;
        }
      });

      return {
        filter: contact._id,
        contact: contact,
        total_count: total_count,
        total_cost: total_cost,
        selected: false
      }
    });

    newAccountGroups.push(...contactAccountGroups);

    let allResultsWithNoContact:MissingAccountResults[] = newResults
      .filter( newResult => {
        return newResult.account_id && !newResult.account_id.contact;
      });


    if(allResultsWithNoContact.length) {

      let all_results_total_count = 0;
      let all_results_total_cost = 0;

      allResultsWithNoContact.forEach( (currentResult: MissingAccountResults)=> {
        all_results_total_count += currentResult.total_count;
        all_results_total_cost += currentResult.total_cost;
      });

      newAccountGroups.push({
        filter: "NO_REP",
        contact: null,
        total_count: all_results_total_count,
        total_cost: all_results_total_cost,
        selected: false,
      });
    }

    if(unkownAccountGroup) {
      newAccountGroups.push(unkownAccountGroup)
    }

    if(notProvidedAccountGroup) {
      newAccountGroups.push(notProvidedAccountGroup)
    }

    newAccountGroups.forEach( accountGroup => {
      accountGroup.selected = accountGroup.filter === newState.filter
    });

    //const missingVendorAccounts = state.results.find( )
    newState.accountGroups = newAccountGroups;
    newState.allResults = newResults;
    newState.results = [];
  }

  return newState;
}

function gatherSelectedResults(state: State): MissingAccountResults[] {

  let selected: MissingAccountResults[] = [];

  if(state.loaded) {
    switch(state.filter) {
      case "":
        selected = state.allResults;
        break;

      case "NO_REP":
        selected = state.allResults.filter( (result:MissingAccountResults) => {
          return (result.account_id && !result.account_id.contact);
        });
        break;

      default:
        //this must be an account rep filter.

        selected = state.allResults.filter( (result:MissingAccountResults) => {
          return (result.account_id && result.account_id.contact && result.account_id.contact._id === state.filter);
        });

        break;
    }
  }

  return selected;
}

function gatherTotals(results: MissingAccountResults[]): any {
  let totalCount: number = 0;
  let totalCost: number = 0;


  results.forEach( (currentResult: MissingAccountResults)=> {
    totalCount += currentResult.total_count;
    totalCost += currentResult.total_cost;
  });

  return {
    totalCount,
    totalCost
  }
}


function gatherSelectedAccountIds(state: State): AccountId[] {
  const accountIds:AccountId[] = [];
  if(state.filter === "NO_REP"){
    state.results.forEach(result => {
      if(
        result.account_id &&
        !result.account_id.contact
      ){
        accountIds.push(result.account_id)
      }
    });
  }
  if(state.selectedContact){
    //only if there is a selected contact can we gather the selected accountIds
    state.results.forEach(result => {
      if(
        result.account_id &&
        result.account_id.contact &&
        result.account_id.contact._id == state.selectedContact._id
      ){
        accountIds.push(result.account_id)
      }
    });
  };
  return accountIds;
}

function getSelectedContact(state: State): Contact {
  let contact: Contact = null;
  state.accountGroups.forEach( accountGroup => {
    if(accountGroup.selected && accountGroup.contact){
      contact= accountGroup.contact;
    }
  });
  return contact;
}

function gatherBucketResult(bucket:MissingDataTotalsBucket ) : MissingAccountResults {

  const account_id = null;
  const total_count = bucket.doc_count;
  const total_cost = bucket.total_mrc.value;
  const account_id_not_provided = false;

  return {
    account_id,
    total_count,
    total_cost,
    account_id_not_provided
  };
}

export const getMissingDataStore = createFeatureSelector<any>('missing-data-store');
export const getMissingDataRequestError = createSelector(getMissingDataStore, (data) => data.error);
export const getMissingDataLoading = createSelector(getMissingDataStore, (data) => data.loaded);
export const getMissingDataVendor = createSelector(getMissingDataStore, (data) => data.vendor);
export const getMissingDataAccountGroups = createSelector(getMissingDataStore, (data) => data.accountGroups);
export const getMissingDataResults = createSelector(getMissingDataStore, (data) => data.results);
