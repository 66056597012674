import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {throwError} from 'rxjs';
import { VendorLoginInfo } from 'src/shared/models/VendorLoginInfo';
import * as CryptoJS from 'crypto-js';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class CollectVendorLoginService {

    private INVOKE_URL = FrontEndSettingsUtil.getCvl().CVL_INVOKE_URL;
    private SERVER_URL_GET= FrontEndSettingsUtil.getCvl().CVL_SERVER_URL_GET;
    private SERVER_URL_POST= FrontEndSettingsUtil.getCvl().CVL_SERVER_URL_POST;
    private REGION= FrontEndSettingsUtil.getCvl().CVL_REGION;
    private SERVICE_NAME= FrontEndSettingsUtil.getCvl().CVL_SERVICE_NAME;
    private SECRET_KEY= FrontEndSettingsUtil.getCvl().CVL_SECRET_KEY;
    private ACCESS_KEY= FrontEndSettingsUtil.getCvl().CVL_ACCESS_KEY;
    
    

    constructor(private http: HttpClient) {
    }

    hmac(secret, value) {
        return CryptoJS.HmacSHA256(value, secret, {asBytes: true});
    }

    hexEncode(value) {
        return value.toString(CryptoJS.enc.Hex);
    }

    hash(value) {
        return CryptoJS.SHA256(value);
    }

    buildAuthorizationHeader(AWS_SHA_256, accessKey, credentialScope, headers, signature) {
        return AWS_SHA_256 + ' Credential=' + accessKey + '/' + credentialScope + ', SignedHeaders=' + this.buildCanonicalSignedHeaders(headers) + ', Signature=' + signature;
    }

    calculateSignature(key, stringToSign) {
        return this.hexEncode(this.hmac(key, stringToSign));
    }

    calculateSigningKey(AWS4, secretKey, datetime, region, service, AWS4_REQUEST) {
        return this.hmac(this.hmac(this.hmac(this.hmac(AWS4 + secretKey, datetime.substr(0, 8)), region), service), AWS4_REQUEST);
    }

    buildStringToSign(datetime, credentialScope, hashedCanonicalRequest, AWS_SHA_256) {
        return AWS_SHA_256 + '\n' +
            datetime + '\n' +
            credentialScope + '\n' +
            hashedCanonicalRequest;
    }

    buildCredentialScope(datetime, region, service, AWS4_REQUEST) {
        return datetime.substr(0, 8) + '/' + region + '/' + service + '/' + AWS4_REQUEST;
    }

    hashCanonicalRequest(request) {
        return this.hexEncode(this.hash(request));
    }

    buildCanonicalSignedHeaders(headers) {
        var sortedKeys = [];
        for (var property in headers) {
            if (headers.hasOwnProperty(property)) {
                sortedKeys.push(property.toLowerCase());
            }
        }
        sortedKeys.sort();

        return sortedKeys.join(';');
    }

    buildCanonicalHeaders(headers) {
        var canonicalHeaders = '';
        var sortedKeys = [];
        for (var property in headers) {
            if (headers.hasOwnProperty(property)) {
                sortedKeys.push(property);
            }
        }
        sortedKeys.sort();

        for (var i = 0; i < sortedKeys.length; i++) {
            canonicalHeaders += sortedKeys[i].toLowerCase() + ':' + headers[sortedKeys[i]] + '\n';
        }
        return canonicalHeaders;
    }

    fixedEncodeURIComponent (str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
          return '%' + c.charCodeAt(0).toString(16).toUpperCase();
        });
    }

    buildCanonicalQueryString(queryParams) {
        if (Object.keys(queryParams).length < 1) {
            return '';
        }

        var sortedQueryParams = [];
        for (var property in queryParams) {
            if (queryParams.hasOwnProperty(property)) {
                sortedQueryParams.push(property);
            }
        }
        sortedQueryParams.sort();

        var canonicalQueryString = '';
        for (var i = 0; i < sortedQueryParams.length; i++) {
            canonicalQueryString += sortedQueryParams[i] + '=' + this.fixedEncodeURIComponent(queryParams[sortedQueryParams[i]]) + '&';
        }
        return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
    }

    buildCanonicalUri(uri) {
        return encodeURI(uri);
    }

    buildCanonicalRequest(method, path, queryParams, headers, payload) {
        return method + '\n' +
            this.buildCanonicalUri(path) + '\n' +
            this.buildCanonicalQueryString(queryParams) + '\n' +
            this.buildCanonicalHeaders(headers) + '\n' +
            this.buildCanonicalSignedHeaders(headers) + '\n' +
            this.hexEncode(this.hash(payload));
    }

    parseParametersToObject(params, keys) {
        if (params === undefined) {
            return {};
        }
        var object = { };
        for (var i = 0; i < keys.length; i++) {
            object[keys[i]] = params[keys[i]];
        }
        return object;
    }

    buildHeaders(verb, body): any {
        let AWS_SHA_256 = 'AWS4-HMAC-SHA256';
        let AWS4_REQUEST = 'aws4_request';
        let AWS4 = 'AWS4';
        let X_AMZ_DATE = 'x-amz-date';
        let X_AMZ_SECURITY_TOKEN = 'x-amz-security-token';
        let HOST = 'host';
        let AUTHORIZATION = 'Authorization';
        let CONTENT_TYPE='Content-Type';
        let ACCEPT='Accept';

        let contentType="application/json";
        let accept="application/json";

        let path="/default/SecretsManagerBridge";

        let queryParams={};
        
        if(verb=='GET') {
            queryParams=this.parseParametersToObject({Operation: 'Get'},['Operation']);
        }
        else if(verb=='POST') {
            queryParams=this.parseParametersToObject({Operation: 'Post'},['Operation']);
        }

        let headers={};        

        headers[CONTENT_TYPE]=contentType;
        headers[ACCEPT]=accept;

        if(body === undefined || verb === 'GET') {
            body='';
        }
        else {
            body=JSON.stringify(body);
        }

        if(body === '' || body === undefined || body === null) {
            delete headers[CONTENT_TYPE];
        }

        let datetime = new Date().toISOString().replace(/\.\d{3}Z$/, 'Z').replace(/[:\-]|\.\d{3}/g, '');

        headers[X_AMZ_DATE]=datetime;
        
        let endpoint = /(^https?:\/\/[^\/]+)/g.exec(this.INVOKE_URL)[1];
        var parser = document.createElement('a');
        parser.href=endpoint;
        headers[HOST] = parser.hostname;

        let canonicalRequest = this.buildCanonicalRequest(verb, path, queryParams, headers, body);

        let hashedCanonicalRequest = this.hashCanonicalRequest(canonicalRequest);

        let credentialScope = this.buildCredentialScope(datetime, this.REGION, this.SERVICE_NAME, AWS4_REQUEST);

        let stringToSign = this.buildStringToSign(datetime, credentialScope, hashedCanonicalRequest, AWS_SHA_256);

        let signingKey = this.calculateSigningKey(AWS4, this.SECRET_KEY, datetime, this.REGION, this.SERVICE_NAME, AWS4_REQUEST);

        let signature = this.calculateSignature(signingKey, stringToSign);

        headers[AUTHORIZATION] = this.buildAuthorizationHeader(AWS_SHA_256, this.ACCESS_KEY, credentialScope, headers, signature);

        delete headers[HOST];
        
        return headers;
    }

    saveVendorLoginInfo(input:VendorLoginInfo): Observable<any> {

        let headers=this.buildHeaders("POST",input);

        return this.http.post<any>(this.SERVER_URL_POST,input,{'headers':headers});
    }

    getVendorLoginInfo(): Observable<any> {

        let headers=this.buildHeaders("GET",undefined);

        return this.http.get<any>(this.SERVER_URL_GET,{'headers':headers});
    }
}