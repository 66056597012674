import {Component, Input, OnInit} from '@angular/core';
import {createLogger, LOG_LEVELS} from '../../logger';

const log = createLogger(LOG_LEVELS.COMPONENT);

const enum ID_TYPE {
  ACCOUNT = 'VAC',
  ADDRESS = 'ADR',
  ADDRESS_OLD = 'ADD',
  CONTACT = 'CNT',
  LOCATION = 'LOC',
  PRODUCT = 'PRD',
  SERVICE = 'SVC',
  SERVICE_GROUP = 'SVG',
  PARENT_SERVICE = 'SVP',
  VENDOR = 'VND',
  SERVICETERM = 'TAC',
  ASSOCIATEDOCUMENT = 'DOC',
  EQUIPMENT = 'EQP'
}

@Component({
  selector: 'mi-id-link',
  styleUrls: ['./id-link.component.scss'],
  template: `
    <ng-container *ngIf="!idFound">
      {{display_id||id}}
    </ng-container>
    <a [routerLink]="href"
       *ngIf="idFound"
       [title]="title"
       [ngClass]="{'no-icon': this.noIcon}">
      <ng-container *ngIf="!iconOnly">
        {{display_id||id}}
      </ng-container>
    </a>`
})

export class IdLinkComponent implements OnInit {
  @Input() id: string = '';
  @Input() display_id: string = '';
  @Input() isEdit: boolean = false;
  @Input() iconOnly: boolean = false;
  @Input() noIcon: boolean = false;
  @Input() title: string;
  href: string = '';
  idFound: boolean;

  ngOnInit() {
    this.id ? this.id.toUpperCase() : '';
    let urlFromId: string = this.getUrlFromId(this.getRootId(this.display_id||this.id));
    this.idFound = !(!urlFromId);
    this.href = urlFromId + this.id;
    if(this.isEdit){
      this.href = urlFromId + this.id + '/edit';
    }
    this.title = this.title || this.id;
  }

  getRootId(id: string): string {

    return id && id.length >= 3 ? (id.substr(0, 3) || '') : '';
  }

  getUrlFromId(rootId: string): string {
    switch (rootId) {
      case ID_TYPE.ACCOUNT:
        return `/vendor-account-ids/`;
      case ID_TYPE.ADDRESS:
      case ID_TYPE.ADDRESS_OLD:
        return '/addresses/';
      case ID_TYPE.CONTACT:
        return '/contacts/';
      case ID_TYPE.LOCATION:
        return '/locations/';
      case ID_TYPE.PRODUCT:
        return '/products/';
      case ID_TYPE.SERVICE:
        return `/services/`;
      case ID_TYPE.SERVICE_GROUP:
        return `/groups/`;
      case ID_TYPE.VENDOR:
        return `/vendors/`;
      case ID_TYPE.PARENT_SERVICE:
        return `/parent-services/`;
      case ID_TYPE.SERVICETERM:
        return `/service-term/`;
      case ID_TYPE.ASSOCIATEDOCUMENT:
        return `/associated-documents/`;
      case ID_TYPE.EQUIPMENT:
        return `/equipment/`;
      default:
        log('Unidentified ID type: ', this.id);
        return '';
    }
  }
}
