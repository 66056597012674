import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {debounceTime} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {SortableTableColumn, SortableTemplatesEnum} from '../../../shared/ng-models/SortableTableColumn.interface';
import {SearchQueryParams} from '../../../shared/ng-models/search/SearchQueryParams.interface';
import {TenantMiServiceGroupService} from 'src/libs/api/tenant/tenantMiServiceGroup.service';
import {MiServiceGroup} from 'src/shared/models/MiServiceGroup';

@Component({
  selector: 'mi-find-or-create-service-groups',
  templateUrl: './find-or-create-service-groups.component.html',
  styleUrls: ['./find-or-create-service-groups.component.scss']
})

export class FindOrCreateServiceGroupsComponent extends AbstractPageComponent implements OnInit {

  @Output() selecteServiceGroup: EventEmitter<MiServiceGroup> = new EventEmitter<MiServiceGroup>();
  @Output() showAddServiceGroupStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() searchString: string;

  generalInformationTableColumns: Array<SortableTableColumn>;
  showAddServiceGroup: boolean = false;

  resultsLoadedFirstTime: boolean = false;
  resultsLoaded: boolean = false;
  resultsError: any = '';
  results: any;
  hits: Array<MiServiceGroup> = [];
  lastQuery: any = {};
  total: number = 0;
  page: number = 1;
  pageSize: number = 5;
  searchTimeout: any;

  searchInput = new Subject<string>();
  @ViewChild('checkboxTpl', {static: true}) checkboxTpl: TemplateRef<any>;
  @ViewChild('alertStatusTmpl', {static: true}) alertStatusTmpl: TemplateRef<any>;

  constructor(
    private tenantGroupService: TenantMiServiceGroupService
  ) {
    super();
  }

  ngOnInit(): void {

    this.generalInformationTableColumns = [
      {
        name: 'MISO ID',
        prop: 'display_id',
        sortable: false,
        cellTemplate: this.checkboxTpl,
        className: 'no-wrap'
      },
      {
        name: 'Group Name',
        prop: 'name',
        sortable: false
      },
      {
        name: 'Status',
        cellTemplate: this.alertStatusTmpl,
        sortable: false
      },
      {
        name: 'Services',
        prop: 'service_count',
        sortable: false
      },
      {
        name: 'Spend (cMRC)',
        prop: 'mrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: false
      }
    ] as Array<SortableTableColumn>;

    this.searchInput.pipe(debounceTime(150))
      .subscribe(q => {
        this.fetchResults({q});
      });

    this.fetchResults({q: this.searchString});
  }

  onSearchTermChanged(q = '') {
    if ((this.lastQuery.q || '') == (q || '')) {
      this.fetchResults({q: ''});
    }
    ;
    this.searchInput.next(q);
  }

  onClearSearch() {
    this.fetchResults({q: ''});
  }

  loadMoreClicked($event) {
    $event.preventDefault();
    this.fetchResults(this.lastQuery, this.page + 1);
  }

  fetchResults(query: any = {}, page: number = 1) {
    this.resultsLoaded = false;
    this.resultsError = false;
    this.page = page;
    this.searchString = query.q;
    let size = this.pageSize;
    let from = (page > 1 ? (this.pageSize * (page - 1)) : 0);

    const options = {from: 0, size: this.pageSize};

    if (page > 1) {
      options.from = this.pageSize + ((page - 2) * 10);
      options.size = 10;
    }


    let newQuery = {
      ...this.lastQuery,
      ...query,
      ...options
    };
    this.tenantGroupService.searchTenantServiceGroups(newQuery)
      .subscribe(results => {
          results.hits = results.hits.map((mi_service_group) => {
            return {
              ...mi_service_group,
              service_count: (mi_service_group.services || []).length
                + (mi_service_group.parent_services || []).length
            };
          });
          this.resultsLoadedFirstTime = true;
          this.resultsLoaded = true;
          this.results = results;
          if (page > 1) {
            this.hits.push(...results.hits);
          } else {
            this.hits = results.hits;
          }
          this.total = results.total.value;
          if (results.hits.length) {
            this.lastQuery = newQuery as SearchQueryParams;
          }
        },
        (e => {
          this.resultsLoaded = true;
          this.resultsError = e;
        })
      );

  }

  onClickCreateNew() {
    this.showAddServiceGroup = true;
    this.showAddServiceGroupStatus.emit(!this.showAddServiceGroup);
  }

  onAddServiceGroupCancel() {
    this.showAddServiceGroup = false;
    this.showAddServiceGroupStatus.emit(!this.showAddServiceGroup);
  }

  onAddComplete($event) {
    //this.fetchResults(this.lastQuery);

    this.resultsError = '';

    // Submit.
    const serviceGroup: MiServiceGroup = {
      ...$event
    };

    this.resultsLoaded = true;

    this.tenantGroupService.create(serviceGroup)
      .toPromise()
      .then((mi_service_group: MiServiceGroup) => {
        this.resultsLoaded = false;
        this.showAddServiceGroup = false;
        this.selecteServiceGroup.emit(mi_service_group);
      })
      .catch((e) => {
        this.resultsError = e;
        this.resultsLoaded = false;
      });
  }

  onSelect($event) {
    let selectedServiceGroup: MiServiceGroup;
    if ($event.target.checked) {
      selectedServiceGroup = this.hits.find(
        (item: MiServiceGroup): boolean => {
          return item._id === $event.target.value;
        });
    } else {
      selectedServiceGroup = null;
    }
    this.selecteServiceGroup.emit(selectedServiceGroup);
  }

}
