import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';

@Injectable()
export class TenantReportsAuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        const currentDomain = FrontEndSettingsUtil.get();
        const tenantCode = currentDomain?.MISO3_TENANT_CODE?.toLowerCase() || '';

        if (tenantCode === 'ipc02') {
          observer.next(true);  // Allow access
        } else {
          this.router.navigate(['/dashboard']);  // Redirect to dashboard
          observer.next(false);  // Deny access
        }

        observer.complete();
      } catch (error) {
        console.error("Error in TenantReportsAuthGuard:", error);
        this.router.navigate(['/dashboard']);
        observer.next(false);  // Deny access on error
        observer.complete();
      }
    });
  }
}
