<div class="tab-title-header">
  <h2>{{title}}</h2>
  <a class="edit-link button dark-theme" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' :
    locationsData.length ? 'Edit' : 'Add'}}</a>
  </div>
<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="pageLoaded; else loading">
  <div *ngIf="isOpen; else static" class="card-1">
    <mi-opacity-loader [ready]="!updating">
      <div *ngIf="locationsData.length === 0">
        {{'TENANT.SERVICE_DETAILS.NO_ADDRESSES' | translate}}
      </div>
      <div class="half-col address-list" *ngIf="locationsData.length">
        <ng-container *ngFor="let loc of locationsData; let i = index;">
          <h3 class="non-input-label">Address {{i+1}}</h3>
          <mi-address-display
            [location]="loc"
            (onDeselect)="onRemoveLocation($event)">
          </mi-address-display>
        </ng-container>
      </div>
      <div class="address-list">
        <ng-container *ngIf="mode === FORM && locationsData.length < 2">
          <a (click)="onToggleEditMode()" mi-button class="button dark-theme">{{locationsData.length === 0 ? 'Add an Address' :
            'Add a Second Address'}}</a>
        </ng-container>
        <ng-container *ngIf="mode === FORM_EDIT">
          <div class="non-input-label" [ngStyle]="{'padding': '20px 0 0 0'}">Add An Address</div>
          <mi-find-or-create-location
            (updateButtonToggle)="onAddressSelected($event)"
            (locationSelected)="onLocationSelected($event)"
            [resetOnSelect]="true">
          </mi-find-or-create-location>
        </ng-container>
        <div class="mt-5 note-info" *ngIf="!locationState">
          <button mi-button buttonTheme="dark-theme" *ngIf="showDoneButton" (click)="onDoneHandler()">
            Done
          </button>
          <button
            mi-button
            buttonTheme="light-theme"
            *ngIf="!showDoneButton"
            (click)="onCancel()">
            Cancel
          </button>
          <button
            class="ml-2"
            mi-button
            buttonTheme="dark-theme"
            *ngIf="!showDoneButton"
            (click)="onUpdateLocations()"
            [disabled]="!formEnabled">
            Apply
          </button>
        </div>
      </div>
    </mi-opacity-loader>
  </div>

  <ng-template #static>
    <div class="card-1">
      <div *ngIf="locationsData.length" class="location-container">
        <ng-container *ngFor="let loc of locationsData; let i = index;">
          <div class="location-item half-col">
            <h3 class="address-index note-info mb-3">Address {{i+1}}</h3>
            <div>
              <a routerLink="/locations/{{ loc.id }}">{{ loc.address.street_address }}</a>
              <div>Location: {{loc.address.formatted_address}}</div>
              </div>
              <div class="map-holder" *ngIf="loc.address.latitude && loc.address.longitude">
              <mi-static-map [address]="loc.address" [height]="400" [width]="700">
              </mi-static-map>
              </div>
              </div>
              </ng-container>
        </div>
      <ng-template #noLocations>
          {{'TENANT.SERVICE_DETAILS.NO_ADDRESSES' | translate}}
      </ng-template>
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
