import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tenantBridgeCustomFieldsResponse, tenantBridgesResponse } from "src/shared/models/ServiceV2";

@Injectable()
export class CustomFieldsV2Service {
  private apiUrl = '_MICRO_SERVICE_/vendor';

  constructor(private http: HttpClient) {}

  getTenantBridges(tenantId: string): Observable<tenantBridgesResponse[]> {
    return this.http
      .get(`${this.apiUrl}/tenant/${tenantId}/bridges`)
      .map((response: any) => response as tenantBridgesResponse[]);
  }

  getTenantBridgeCustomFields(tenantId: string, bridgeId: string, vendorId: string): Observable<tenantBridgeCustomFieldsResponse[]> {
    return this.http
    .get(`${this.apiUrl}/tenant/${tenantId}/bridges/${bridgeId}/custom-fields?vendorId=${vendorId}`)
    .map((response: any) => response as tenantBridgeCustomFieldsResponse[]);
  }
}