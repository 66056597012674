import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TenantLocationService} from "../../../../../../libs/api/tenant/tenantLocation.service";
import {Contact} from "../../../../../../shared/models/Contact";

@Component({
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})

export class ContactEditComponent extends AbstractPageComponent implements OnInit {

  contactId: string;
  error: string;
  exitRoute: string;
  contact: Contact;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private tenantLocationService: TenantLocationService) {
    super();

    this.contactId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');

    if (!this.contactId) {
      this.error = 'No location ID.';
      return;
    }

    this.exitRoute = '../'
  }

  ngOnInit() {

  }

  onCanceled() {
    this.router.navigate([this.exitRoute], {relativeTo: this.activatedRoute});
  }
  onUpdateContact(contact:any):void{
    this.router.navigate([this.exitRoute], {
      queryParams:{update: new Date().getTime()},
      relativeTo: this.activatedRoute
    });
  }
}
