import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NO_DATA} from '../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Product} from '../../../../../../shared/models/Product';
import {ParentServiceState} from '../../../state/reducer';
import * as ParentServiceActions from '../../../state/actions';
import * as ParentServiceReducer from '../../../state/reducer';

@Component({
  selector: 'mi-parent-service-nickname',
  styleUrls: ['./parent-service-nickname.component.scss'],
  templateUrl: './parent-service-nickname.component.html'
})

export class ParentServiceNicknameComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  cancelLink: string;
  componentForm: FormGroup;
  error: string;
  formProgression: any[] = ParentServiceReducer.ParentServiceProgression;
  formStep: string = ParentServiceReducer.ParentServiceSteps.Nickname;
  index: number = this.formProgression.indexOf(this.formStep);
  parentServiceState: ParentServiceState;
  product: Product;
  step: string = ParentServiceReducer.ParentServiceSteps.Product;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<ParentServiceState>
  ) {
    super();
  }

  ngOnInit() {

    this.cancelLink = `/services`;

    this.componentForm = this.formBuilder.group({
      nickname: [null, Validators.required]
    });

    this.store.select(ParentServiceReducer.getParentServiceStore)
      .subscribe(
        (state: ParentServiceState) => {


          this.parentServiceState = state;
          this.activeIndex = this.index === this.parentServiceState.index;

          if (state.nickname) {
            // Nickname:
            this.componentForm.get('nickname').setValue(this.parentServiceState.nickname);
          } else {
            // Nothing yet ...
            let autoNickName = null;
            if(state.product){
              autoNickName = `${state.services[0].purchasing_vendor.vendor_name} - ${state.product.product_name} (${state.services[0].display_id})`
            }
            this.componentForm.get('nickname').setValue(autoNickName);
          }
          this.componentForm.updateValueAndValidity();
        },
        (e) => {
          this.error = e;
        });

  }

  onFormSubmit(data: any) {

    const maxIndex = Math.max(this.parentServiceState.maxIndex, this.index + 1);

    this.store.dispatch(new ParentServiceActions.UpdateParentServiceAction({
      index: maxIndex,
      maxIndex: maxIndex,
      nickname: data.nickname
    }));

  }

}
