import {Component, OnInit} from '@angular/core';
import {TenantMiOrderService} from '../../../../../libs/api/tenant/tenantMiOrder.service';
import {ActivatedRoute} from '@angular/router';
import {MiOrder} from '../../../../../shared/models/MiOrder';
import {Vendor} from '../../../../../shared/models/Vendor';
import {
  DetailHeaderNavData, DetailHeaderNavSpec,
  HeaderNavSpecTypes,
  HeaderNavSubNavTypes
} from '../../../../../shared/ng-models/DetailHeaderNavData.interface';
import {DateTime} from "luxon";
import {Link} from "../../../../../shared/ng-models/Link.interface";
import {NO_DATA} from '../../../../../shared/ng-models/SectionDataTable.model';

@Component({
  templateUrl: './service-order-summary.component.html'
})
export class ServiceOrderSummaryComponent implements OnInit {

  error: any;
  loaded: boolean = false;
  order: MiOrder;
  vendor: Vendor;
  detailHeaderNavData: DetailHeaderNavData;
  breadcrumbs: Link[]= [];
  display_id: string= '';

  navType: HeaderNavSubNavTypes.NONE;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: TenantMiOrderService
  ) {

  }

  ngOnInit() {
    this.activatedRoute
      .params
      .switchMap( params => this.orderService.getByDisplayId(params.display_id))
      .subscribe(
        (order: MiOrder) => this.updateOrder(order),
        e => this.error = e);
  }

  contactUpdated(order:MiOrder) {
    this.updateOrder(order);
  }

  onDocumentsUpdated(order:MiOrder) {
    this.updateOrder(order);
  }

  updateOrder(order:MiOrder) {

    this.order = order;
    this.display_id = order.display_id;

    this.detailHeaderNavData = <DetailHeaderNavData> {
      headline: `Order ID: ${order.order_id || NO_DATA}`,
      byline: {
        label: 'TENANT.MULTI_ORDER_SUMMARY.VENDOR_BYLINE',
        obj: {vendor:  order.vendor.vendor_name}
      },
      specs: [
        {
          label: 'TENANT.MULTI_ORDER_SUMMARY.ORDER_DATE',
          value:  order.order_date,
          value_type: HeaderNavSpecTypes.DATE
        },
        {
          label: 'TENANT.MULTI_ORDER_SUMMARY.VENDOR_ACCOUNT_ID',
          value: order.account_id ? order.account_id.label : NO_DATA

        }]
    };

    this.breadcrumbs = [
      {
        url: '',
        label: 'Dashboard',
        internal: true
      },
      {
        url: '/services',
        label: 'Services',
        internal: true

      },
      {
        url: `/services/orders/summary/${order.display_id}`,
        label: 'Order Summary',
        internal: true
      }
    ];

    this.loaded = true;
  }
}
