import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceTerm } from '../types/ServiceTerm.interface';

@Injectable()
export class VendorOwnershipService {
  private apiUrl = '_MICRO_SERVICE_/vendor/tenants/ownership';

  constructor(private http: HttpClient) { }

  getVendorOwnership(tenant_vendor_id: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/${tenant_vendor_id}`)
      .map((response: any) => response);
  }

  addVendorOwnership(tenant_vendor_id: string,userId: string): Observable<any> {
    const data = {
      user_id: userId
    };
    return this.http
      .post(`${this.apiUrl}/${tenant_vendor_id}`, data)
      .map((response: any) => response );
  }

  removeVendorOwnership(tenant_vendor_id: string): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/${tenant_vendor_id}`)
      .map((response: any) => response );
  }

  // removeVendorOwnership(id: string): Observable<any> {
  //   const data = {
  //     params: {
  //       tenant_vendor_id: id
  //     }
  //   };
  //   return this.http
  //     .delete(this.apiUrl, data)
  //     .map((response: any) => response);
  // }

}
