<div class="middle-well">

  <div class="form-container">
    <mi-page-alert class="login-alert"></mi-page-alert>
    <div class="login-well">

      <h1><span class="offscreen">MISO</span>
        <img src="../../../shared/assets/images/miso3-logo.svg" class="miso-logo"/>
      </h1>

      <mi-server-error [error]="error"></mi-server-error>

      <ng-container *ngIf="loaded; else loading">Terms and Conditions

        <p *ngIf='tenantData.v2.role===5' innerHTML="{{'ACCEPT_TENANT_TERMS_AND_CONDITIONS' | translate}}"></p>
        <p *ngIf='tenantData.v2.role!==5' innerHTML="{{'NOT_ACCEPTED_TENANT_TERMS_AND_CONDITIONS' | translate}}"></p>

        <a *ngIf='tenantData.v2.role===5' href="{{termsLink}}" target="_blank">View the MISO Terms and Conditions</a>

        <form *ngIf='tenantData.v2.role===5' (submit)="onSubmitClick()">
          <div class="checkbox-container">
            <label class="checkbox">
              <input type="checkbox"
                     [checked]="accepted"
                     (change)="toggleAccept()"
              />
              I agree to the MISO Terms and Conditions
            </label>
          </div>
          <mi-server-error [error]="acceptError"></mi-server-error>
          <button mi-button
                  buttonTheme="dark-theme"
                  class="login-button"
                  type="submit"
                  [disabled]="!accepted">
            Continue
          </button>
        </form>
        <!--
        <p><a href="{{link}}">Go to your account section.</a></p>
        <div class="support-links">
            <p>If you need any help, please contact us at <a href="{{supportLink}}">MISO
              Support</a>.</p>
          </div>
        -->
      </ng-container>

      <ng-template #loading>
        <mi-loading-animation></mi-loading-animation>
      </ng-template>

    </div>
  </div>
</div>
