import {Component, OnInit} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as ASReducer from '../../../../state/advanced-search/reducers';
import * as appDataReducer from '../../../../state/app-data/reducers';
import { Store, select } from '@ngrx/store';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { removeNonUrlSearchParams } from 'src/shared/utils/unsafeUrlProperties';
import { Observable, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import { minLengthWithTrimmedWhitespace } from 'src/shared/shared.validators';

export enum CreateEditUmMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

export enum ActionTypeEnum {
  REPORT = 'REPORT',
  VIEW = 'VIEW'
}

@Component({
  templateUrl: './create-report-or-view-modal.component.html',
  styleUrls: ['./create-report-or-view-modal.component.scss']
})

export class CreateReportOrViewModalComponent extends AbstractPageComponent implements OnInit {

  // action_type:ActionTypeEnum = ActionTypeEnum.REPORT;
  action_type:string = 'SERVICE_REPORT';

  message:string = "Notification"
  closeButtonLabel:string = "Confirm"
  form: FormGroup = null;
  mode: CreateEditUmMode = CreateEditUmMode.CREATE;
  loaded: boolean = true;
  filters: any;
  columns: any;
  tenant_id: string;
  readable_filters: string;
  error: any;

  constructor(
    private store: Store<ASReducer.State>,
    private formBuilder: FormBuilder,
    private modal: ModalRef,
    private reportV2Service: ReportV2Service
   ) {
    super()
  }

  ngOnInit(){
    combineLatest(
      this.store.pipe(select(appDataReducer.tenantDomain), first()),
      this.store.select(ASReducer.getASData)
    )
    .takeUntil(this.destroy$)
    .subscribe(([domain, data]) => {
      this.tenant_id = domain;
        if (data.results && data.results) {
          this.filters = removeNonUrlSearchParams(data.results.meta.params);
          this.readable_filters = data.readableFilter;
          this.columns = data.columns;
        } else {
          this.filters = [];
        }
        this.createForm();

        console.log("filters: ", this.filters)
      },
      e => this.error = e);
  }

  createForm(data: any = {}): void { 
    let {
      label
    } = data;

    this.form = this.formBuilder.group({
      label: [label, Validators.compose([
        Validators.required,
        Validators.maxLength(200), 
        Validators.minLength(2),
        minLengthWithTrimmedWhitespace(2)
      ])],
      action_type: [this.action_type, Validators.required]
    });

    this.loaded = true;
  }


  onCancel(report = false) {
    this.modal.close({report});

    const microServiceBody = {
      label: this.form.value.label,
      filters: this.filters,
      columns: this.columns
    }
  }

  onFormSubmit(formValue): void {
    this.loaded = false;
    const microBodyFilter = Object.keys(this.filters).map(key => {
      let value = this.filters[key];
      if(typeof this.filters[key] === "string"){
        value = [this.filters[key]];
      }

      return {
        filter_name: key,
        value: value
      }
    })
    const microServiceBody = {
      tenant_id: this.tenant_id,
      label: formValue.label,
      filters: microBodyFilter,
      readable_filters: this.readable_filters,        
      columns: this.columns,
      action_type: this.action_type,
      // recipient_emails: [],
      // schedule_frequency: 0,
      // schedule_time: null,
    }
    console.log("microServiceBody", microServiceBody)
    this.error = null;

    this.reportV2Service.createReportOrView(microServiceBody)
      .subscribe(
        (res) => {
          this.onCancel(true);
        },
            (e) => this.error= e
      );
  }
}
