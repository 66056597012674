import { Component, Input, OnInit, ViewChild, TemplateRef} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { Contact } from "src/shared/models/Contact";

@Component({
  templateUrl: './select-contact-modal.component.html'
})

export class SelectContactModalComponent implements OnInit {
  data: any;
  vendorName: string;
  showVendorTab: boolean = false;
  showAddContact: boolean = true;
  contactError: string = '';
  contactRows: any[];
  contactColumns: any[];
  defaultSearchText: string = '';
  contacts: Contact[] = [];
  title: string = "Select Account Rep";
  @ViewChild('emailTmpl', {static: true}) emailTmpl: TemplateRef<any>;

  constructor(private modal: ModalRef) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modal.close({contact: null});
  }

  onAddContact(contact: Contact): void {
    this.modal.close({contact});
  }
}

