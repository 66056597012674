import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";
import { BillingItem } from "src/shared/models/Billing";

@Component({
  templateUrl: './billing-breakdown-modal.component.html',
  styleUrls: ['./billing-breakdown-modal.component.scss']
})

export class BillingBreakdownModalComponent implements OnInit {
  title: string = "Itemized Billing Details";
  billingItems: BillingItem[];
  totalAmount: number;

  constructor(
    private modal: ModalRef
  ) {
  }
  
  ngOnInit(): void {
    
  }

  onModalClose() {
    this.modal.close({reason: 'User closed'});
  }
}