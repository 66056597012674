import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Report} from '../../../shared/models/Report';

import {Observable} from 'rxjs';
import {MissingDataReportQuery} from 'src/shared/models/VendorMissingData';
import { MiServiceSearchQueryParams } from 'src/shared/ng-models/search/SearchQueryParams.interface';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantReportsService {

  private apiUrl = '/{tenant}/reports/get-by-id/';
  private apiUrlContacts = '/{tenant}/reports/contacts/';
  private apiUrlEquipments = '/{tenant}/reports/equipments/';
  private apiUrlList = '/{tenant}/reports/list/';
  private apiUrlLocations = '/{tenant}/reports/locations/';
  private apiUrlLocationsDetail = '/{tenant}/reports/locations-detail/';
  private apiUrlMissingData = '/{tenant}/reports/missing-data/';
  private apiUrlSecureLink = '/{tenant}/reports/secure-link/';
  private apiUrlServicesStandardLink = '/{tenant}/reports/services/standard/';
  private apiUrlServicesEventLink = '/{tenant}/reports/services/events/';
  private apiUrlServicesWithParentsLink = '/{tenant}/reports/services/with-parents/';
  private apiUrlServicesBulkLink = '/{tenant}/reports/services/bulk/';
  private apiUrlParentServicesLink = '/{tenant}/reports/parent-services/';
  private apiUrlServiceGroupsLink = '/{tenant}/reports/service-groups/';
  private apiUrlAccountIdLink = '/{tenant}/reports/account-ids/';
  private apiUrlDetail = '/{tenant}/reports/download-miservice-detail/';
  private apiUrlUpcomingEvent = '/{tenant}/reports/services/upcoming-events';
  private apiUrlGetServiceList = '/{tenant}/search/tenant-mi-services/reports';


  constructor(private http: HttpClient) {
  }

  getServiceList(tenant_id: string): Observable<any> {
    return this.http
    .get(this.apiUrlGetServiceList, {params: {"tenant_id": tenant_id}})
    .map((response: any) => response.data);
  }

  list(): Observable<any> {
    return this.http
      .get(this.apiUrlList)
      .map((response: any) => response.data);
  }

  createContactsReport(field_properties=[]): Observable<Report> {
    return this.http
      .post(this.apiUrlContacts, {field_properties})
      .map((response: any) => {
        return response.data;
      });
  }

  createEquipmentsReport(): Observable<Report> {
    return this.http
      .post(this.apiUrlEquipments, {})
      .map((response: any) => response.data);
  }

  createLocationsReport(): Observable<Report> {
    return this.http
      .post(this.apiUrlLocations, {})
      .map((response: any) => response.data);
  }

  createLocationsDetailReport(): Observable<Report> {
    log('createLocationsDetailReport');
    return this.http
      .post(this.apiUrlLocationsDetail, {})
      .map((response: any) => response.data);
  }

  createMissingDataReport(): Observable<Report> {
    return this.http
      .post(this.apiUrlMissingData, {})
      .map((response: any) => response.data);
  }

  createServicesForImportReport(vendor_id: string = ''): Observable<Report> {

    const body = {};
    if(vendor_id) {
      body['purchasing_vendor'] = vendor_id;
    }
    return this.http
      .post(this.apiUrlServicesBulkLink, body)
      .map((response: any) => response.data);
  }

  createServicesStandardReport(vendor_name: string = ''): Observable<Report> {
    const params = {};
    if(vendor_name) {
      params['vendor_name[]'] = vendor_name;
    }
    return this.http
      .post(this.apiUrlServicesStandardLink, {}, {params: params })
      .map((response: any) => response.data);
  }

  createServicesAndParentReport(vendor_name: string = ''): Observable<Report> {
    const params = {parents_and_services:'true'};
    if(vendor_name) {
      params['vendor_name[]'] = vendor_name;
    }
    return this.http
      .post(this.apiUrlServicesWithParentsLink, {}, {params: params })
      .map((response: any) => response.data);
  }
  createServicesEventReport(vendor_name: string = ''): Observable<Report> {
    const params = {};
    if(vendor_name) {
      params['vendor_name[]'] = vendor_name;
    }
    return this.http
      .post(this.apiUrlServicesEventLink, {}, {params: params })
      .map((response: any) => response.data);
  }

  createParentServicesReport(vendor_name: string = ''): Observable<Report> {
    const params = {parents_and_services:'true', not_has_parent_service:'true'};
    if(vendor_name) {
      params['vendor_name[]'] = vendor_name;
    }
    return this.http
      .post(`${this.apiUrlParentServicesLink}standard`, {}, {params: params })
      .map((response: any) => response.data);
  }

  createParentServicesForImportReport(vendor_id: string = ''): Observable<Report> {
    const body = {};
    if(vendor_id) {
      body['purchasing_vendor'] = vendor_id;
    }
    return this.http
      .post(this.apiUrlParentServicesLink, {})
      .map((response: any) => response.data);
  }

  createServiceGroupsReport(): Observable<Report> {
    return this.http
      .post(this.apiUrlServiceGroupsLink, {})
      .map((response: any) => response.data);
  }

  createAccountIdsReport(): Observable<Report> {
    return this.http
      .post(this.apiUrlAccountIdLink, {})
      .map((response: any) => response.data);
  }

  createtMissingDataReportByVendorId(query: MissingDataReportQuery): Observable<Report> {
    return this.http
      .post(this.apiUrlMissingData, {query})
      .map((response: any) => response.data);
  }

  createtMissingDataReportSingle(_id: string): Observable<Report> {
    return this.http
      .post(`${this.apiUrlMissingData}single`, {_id})
      .map((response: any) => response.data);
  }

  createtMissingDataReportParent(_id: string): Observable<Report> {
    return this.http
      .post(`${this.apiUrlMissingData}parent`, {_id})
      .map((response: any) => response.data);
  }

  getReportById(report_id: string): Observable<Report> {
    return this.http
      .get(this.apiUrl + report_id)
      .map((response: any) => response.data);
  }

  getSecureLink(report_id: string): Observable<any> {
    return this.http
      .get(this.apiUrlSecureLink + report_id)
      .map((response: any) => response.data);
  }

  createDownloadDetailParentReport(_id: string): Observable<Report> {
    return this.http
      .post(`${this.apiUrlDetail}parent`, {_id})
      .map((response: any) => response.data);
  }

  createDownloadDetailSingleReport(_id: string): Observable<Report> {
    return this.http
      .post(`${this.apiUrlDetail}single`, {_id})
      .map((response: any) => response.data);
  }
  
  getQueryParams(queryParams: MiServiceSearchQueryParams): HttpParams {
    return new HttpParams({fromObject: queryParams as any});
  }

  createServicesUpcomingEventReport(queryParams: any = {}): Observable<Report> {
    return this.http
      .post(this.apiUrlUpcomingEvent, {}, {params: this.getQueryParams(queryParams)})
      .map((response: any) => response.data);
  }

}