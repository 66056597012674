import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Router} from '@angular/router';
import {TenantMiParentServiceService} from '../../../../../../libs/api/tenant/tenantMiParentService.service';
import * as ParentServiceReducer from '../../../state/reducer';
import {ParentServiceState, ParentServiceSteps} from '../../../state/reducer';
import {MiParentService} from '../../../../../../shared/models/MiParentService';


@Component({
  selector: 'mi-parent-service-add',
  styles: ['.button-container { margin: 0 0 40px; }'],
  templateUrl: './parent-service-add.component.html'
})
export class ParentServiceAddComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  error: string;
  index: number;
  state: ParentServiceState;
  step: ParentServiceSteps = ParentServiceSteps.AddParentService;
  parentServiceState: ParentServiceState;
  cancelLink: string;

  constructor(
    private store: Store<ParentServiceState>,
    private tenantParentServiceService: TenantMiParentServiceService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.cancelLink = `/services`;
    this.store.select(ParentServiceReducer.getParentServiceStore)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: ParentServiceState) => {
          this.parentServiceState = state;
          // Find the index belonging to the state's current step:
          this.index = ParentServiceReducer.ParentServiceProgression.findIndex((a) => {
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.parentServiceState.index;
        },
        (e) => {
          this.error = e;
        });
  }

  onCreateParentService(): void {

    let locations = new Set();

    this.parentServiceState.services.map((service) => {
      service.locations.forEach((location) => {
        locations.add(location._id);
      });
    });
    this.tenantParentServiceService.create({
      assigned_email: this.parentServiceState.assigned_email,
      locations: Array.from(locations),
      nickname: this.parentServiceState.nickname,
      division: this.parentServiceState.division,
      team: this.parentServiceState.team,
      product: this.parentServiceState.product._id,
      services: this.parentServiceState.services.map((service) => {
        return service._id;
      })
    })
      .toPromise()
      .then((parentService: MiParentService) => {
        this.router.navigateByUrl(`parent-services/${parentService.display_id}`)
      })
      .catch(e => this.error = e);

  }

}
