<mi-page-header title="Document Manager" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
</mi-page-header>

<div class="card mt-2">
    <div class="card-body py-1">
        <div class="d-flex w-50">
            <div class="item px-0 mr-4">
                <div class="label-combo-input">
                    <label class="label-combo-input-label">SVC-</label>
                    <input #searchInputSVC (input)="onSearchChange($event.target.value)" placeholder="Search Documents by Service Number"
                        type="number" autofocus>
                    <button class="cross-button" *ngIf="!isSearchEmptySVC" (click)="onClearSearchSVC()"></button>
                </div>
                </div>
            <div class="item px-0 mr-2">
                <div class="label-combo-input">
                    <input #searchInputServiceIdentifier (input)="searchByServiceIdentifierHandler($event.target.value)"
                        placeholder="Search Documents by Service Identifier" type="text">
                    <button class="cross-button" *ngIf="!isSearchEmptyServiceIdentifier"
                        (click)="onClearSearchServiceIdentifier()"></button>
                </div>
                </div>
                </div>
    </div>
</div>


<div class="card mt-2">
    <mi-opacity-loader [ready]="isDataLoaded">
        <div class="sticky-header top-pagination">
            <div class="note note-info" *ngIf="getMessage()">
                {{ getMessage() }}
            </div>
            <ng2-smart-table (userRowSelect)="onRowSelect($event)" (mouseenter)="onChange()" (mouseleave)="onChange()"
                (keypress)="onChange()" (click)="onChange()" [settings]="settings" [source]="source"
                class="table-hover"></ng2-smart-table>
        </div>
    </mi-opacity-loader>
</div>
