import {Component, Input, OnInit} from '@angular/core';
import {OrderState} from '../state/reducer';
import {Store} from '@ngrx/store';
import {UpdateOrderAction} from '../state/actions';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import * as OrderReducers from '../state/reducer';

@Component({
  selector: 'mi-order-create-section',
  styleUrls: ['./order-create-section.component.scss'],
  templateUrl: './order-create-section.component.html'
})

export class OrderCreateSectionComponent extends AbstractPageComponent implements OnInit {

  @Input() changeable: boolean = true;
  @Input() index: number;
  @Input() segment: string;
  @Input() title: string = '';

  complete: boolean = false;
  activeStep: boolean = false;
  orderState: OrderState;
  atLeastMyIndex: boolean = false;

  constructor(
    private store: Store<OrderState>,
  ) {
    super();
  }

  ngOnInit() {

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state;
          this.atLeastMyIndex = this.index <= this.orderState.maxIndex;
        },
        (e) => {
          console.error('Error:', e);
        });

  }

  onChangeClicked(): void {
    this.store.dispatch(new UpdateOrderAction({
      index: this.index
    }));
  }
}
