<mi-page-header
  title="{{ 'TENANT.MULTI_ORDER_ADD_SERVICE_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<ng-container *ngIf="loaded; else loading">

  <div class="sections">

    <h2>Product Info</h2>

    <mi-order-create-section
      id="section_7"
      [index]="7"
      title="Product">
      <mi-service-order-product></mi-service-order-product>
    </mi-order-create-section>

    <mi-order-create-section
      id="section_8"
      [index]="8"
      title="Service Dates">
      <mi-service-order-dates></mi-service-order-dates>
    </mi-order-create-section>

    <mi-order-create-section
      id="section_9"
      [index]="9"
      title="Costs">
      <mi-service-costs></mi-service-costs>
    </mi-order-create-section>

    <h2>Service Tracking</h2>

    <mi-order-create-section
      id="section_10"
      [index]="10"
      title="Service Identifier">
      <mi-service-identifier></mi-service-identifier>
    </mi-order-create-section>

    <mi-order-create-section
      id="section_11"
      [index]="11"
      title="Location">
      <mi-service-locations></mi-service-locations>
    </mi-order-create-section>

    <mi-order-create-section
      id="section_12"
      [index]="12"
      title="Custom Data">
      <mi-custom-fields></mi-custom-fields>
    </mi-order-create-section>

    <h2>Service Tracking</h2>

    <mi-order-create-section
      id="section_13"
      [index]="13"
      title="Service Documents &amp; Links">
      <mi-service-documents></mi-service-documents>
    </mi-order-create-section>

    <mi-order-create-section
      id="section_14"
      [index]="14"
      title="Service Term">
      <mi-service-terms-and-conditions></mi-service-terms-and-conditions>
    </mi-order-create-section>

    <h2>Contacts</h2>

    <mi-order-create-section
      id="section_15"
      [index]="15"
      title="Assigned to User">
        <mi-assigned-to-user></mi-assigned-to-user>
    </mi-order-create-section>

    <mi-order-create-section
      id="section_16"
      [index]="16"
      title="Additional Contacts">
        <mi-service-contacts></mi-service-contacts>
    </mi-order-create-section>

    <mi-add-service-button></mi-add-service-button>
  </div>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
