

<mi-server-error [error]="loadError"></mi-server-error>
<mi-loading-animation *ngIf="loading"></mi-loading-animation>

<form *ngIf="!loading && form !== null" [(formGroup)]="form" (ngSubmit)="onFormSubmit(form.value)">

  <mi-extended-input labelText="Address"
                     [labelRequired]="true"
                     [(inputControl)]="form.controls['street_address']"
                     [(inputErrors)]="form.controls['street_address'].errors"
                     [(inputTouched)]="form.controls['street_address'].touched"
                     [errorDefs]='{required: "Please enter an address / description"}'>

    <textarea
      type="text"
      class="form-control"
      formControlName="street_address"
      placeholder="ex: Address and Street Name"></textarea>

  </mi-extended-input>

  <div class="row">
    <mi-extended-input class="half-column"
                       labelText="Country"
                       [labelRequired]="true"
                       [(inputControl)]="form.controls['country_id']"
                       [(inputErrors)]="form.controls['country_id'].errors"
                       [(inputTouched)]="form.controls['country_id'].touched"
                       [errorDefs]='{required: "Please select a country"}'>

      <select formControlName="country_id" (change)="onCountryChange($event.target.value, null)">
        <option value="null" disabled="disabled">Select Country</option>
        <option *ngFor="let country of countries" value="{{ country.id }}">
          {{ country.country_name }}
        </option>
      </select>

    </mi-extended-input>

    <mi-extended-input class="half-column"
                       labelText="State / Province"
                       [(inputControl)]="form.controls['state_id']"
                       [(inputErrors)]="form.controls['state_id'].errors"
                       [(inputTouched)]="form.controls['state_id'].touched"
                       [errorDefs]='{required: "Please select a state"}'>

      <input
        *ngIf="states === null"
        type="text"
        disabled
        placeholder="Select Country First">

      <ng-container *ngIf="states">
        <select
          *ngIf="states.length > 0"
          formControlName="state_id">
          <option value=null disabled="disabled">Select State</option>
          <option *ngFor="let state of states" value="{{ state.id }}">
            {{ state.state_name }}
          </option>
        </select>

        <input
          *ngIf="states.length === 0"
          type="text"
          formControlName="state_other"
          placeholder="State Name">
      </ng-container>

    </mi-extended-input>
  </div>

  <div class="row">

    <mi-extended-input class="half-column"
                       labelText="City or County / District"
                       [labelRequired]="false"
                       [(inputControl)]="form.controls['city']"
                       [(inputErrors)]="form.controls['city'].errors"
                       [(inputTouched)]="form.controls['city'].touched"
                       [errorDefs]='{required: "Please enter a city or county"}'>

      <input type="text"
             class="form-control"
             formControlName="city"
             placeholder="City Name">
    </mi-extended-input>

    <mi-extended-input class="half-column"
                       labelText="ZipCode / CC"
                       [labelRequired]="false"
                       [(inputControl)]="form.controls['zip']"
                       [(inputErrors)]="form.controls['zip'].errors"
                       [(inputTouched)]="form.controls['zip'].touched"
                       [errorDefs]='{required: "Please enter a zipcode"}'>

      <input type="text"
             class="form-control"
             formControlName="zip"
             placeholder="ex: 12345">

    </mi-extended-input>
  </div>

  <div class="row">
    <mi-server-error [error]="submitError"></mi-server-error>
    <div class="button-container">
      <ng-container *ngIf="mode==='EDIT'">
        <a mi-button
           class="button-alternate"
           buttonTheme="alert-light-theme"
           routerLink="../delete">
          Delete
        </a>
      </ng-container>

      <a mi-button
         class="button--cancel"
         buttonTheme="light-theme"
         (click)="onCancel($event)">
        Cancel
      </a>

      <button mi-button
              type="submit"
              [disabled]="!form.valid">
          {{ctaLabel}}
      </button>
    </div>
  </div>
</form>

