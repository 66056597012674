<div class="form-container">
  <form [(formGroup)]="docForm" (ngSubmit)="onFormSubmit()">

    <!--
    "API_INTEGRATION_TITLE": "API Integration Request",
    "": "Select Organization or Vendor",
    "": "Which vendor API would you like to integrate with?",
    "": "Additional Instructions",
    "": "If you have any additional information regarding this request, please let us know.",
    -->

    <section>

      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.API_INTEGRATION_VENDOR_TITLE' | translate}}"
        [labelRequired]="true"
        hint="{{'DATA_IMPORT.API_INTEGRATION_VENDOR_HINT' | translate }}">
      </mi-extended-input>

      <mi-simple-vendor
        *ngIf="selectedVendor"
        [vendor]="selectedVendor">
      </mi-simple-vendor>

      <button
        mi-button
        (click)="openSelectVendor($event)">
        {{(selectedVendor ? 'DATA_IMPORT.CHANGE_VENDOR' : 'DATA_IMPORT.CHOOSE_VENDOR') | translate}}
      </button>

    </section>

    <section>
      <mi-extended-input
        class="label--large"
        labelText="{{'DATA_IMPORT.API_INTEGRATION_INSTRUCTION_TITLE' | translate}}"
        hint="{{'DATA_IMPORT.API_INTEGRATION_INSTRUCTION_HINT' | translate}}">
        <angular-tinymce
          formControlName="comment">
        </angular-tinymce>
      </mi-extended-input>
    </section>

    <div class="button-container">
      <a buttonTheme="light-theme"
         mi-button
         routerLink="/data-import">
        {{'DATA_IMPORT.CANCEL' | translate}}
      </a>
      <button buttonTheme="dark-theme"
              [disabled]="!docForm.valid"
              mi-button
              type="submit">
        {{'DATA_IMPORT.SUBMIT' | translate}}
      </button>
    </div>

    <mi-server-error [error]="error"></mi-server-error>

  </form>

  <div class="form-busy" *ngIf="busy">
    <mi-loading-animation></mi-loading-animation>
  </div>

</div>
