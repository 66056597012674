import { Component, OnInit } from '@angular/core';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { Link } from 'src/shared/ng-models/Link.interface';
import { Store } from '@ngrx/store';
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { ChartData } from 'src/shared/ng-models/ChartData.interface';
import { getCurrencySymbol } from '@angular/common';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mi-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class Miso3LandingPageComponent implements OnInit {
  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/dashboard",
      internal: true
    }
  ];
  headerLinks: Link[];
  error: any = null;

  largestInternalOwner: {
    miMisoUserName: string,
    totalCmrcPercentage: number
  }

  tenantReportDetails: {
    reportDate: string,
    tenantId: string,
    tenantVendorCount: number,
    accountidCount: number,
    productCount: number,
    documentCount: number,
    serviceCount: number,
    addressCount: number,
    totalCmrc: number,
    totalDataIntegrityCmrcPercentage: number,
    netCostChange: number,
    comingOutOfTerm90DaysServiceCount: number,
    currentlyOutOfTermServiceCount: number,
    unknownTermServiceCount: number,
    miMisoUserCount: number,
    subscriberCount: number,
    annualSpendPoorDataIntegrity: number,
    noActivityServiceCount: number,
    tenantVendors: [
      {
        tenantVendorId: string,
        vendorName: string,
        accountidCount: number,
        serviceCount: number,
        cmrc: number,
        dataIntegrityCmrcPercentage: number,
        documentCount: number,
        addressCount: number
      },
    ],
    products: [
      {
        productId: string,
        productName: string
      },
    ],
    miMisoUserCmrcPercentages: [
      {
        miMisoUserName: string,
        totalCmrcPercentage: number
      },
    ],
    productCategoryCmrcPercentages: [
      {
        productCategoryName: string,
        totalCmrcPercentage: number
      },
    ],
    netCostChangeValues: {
      costIncreasedServiceCount: number,
      costDecreasedServiceCount: number,
      costDecreasedPercentage: number,
      costIncreasedPercentage: number,
      totalCostChangePercentage: number,
      costDecreasedValue: number,
      costIncreasedValue: number
    },
    subscribers: [],
    subscriberValues: {
      subscriberNames: [],
      subscriberVendorNames: [],
      subscriberProductNames: []
    },
    addresses: {
      countries: [
        string,
        string
      ],
      states: [string],
      cities: [string]
    },
    comingOutOfTerm90DaysValues: {
      comingOutOfTerm90DaysCmrc: number,
      comingOutOfTerm90DaysCmrcPercentage: number,
      comingOutOfTerm90DaysVendorCount: number,
      comingOutOfTerm90DaysMonthlyBreakup: [
        {
          month: string,
          totalCmrc: number
        }
      ]
    },
    unknownTermValues: {
      unknownTermCmrc: number,
      unknownTermCmrcPercentage: number,
      unknownTermAnnualSpend: number,
      unknownTermVendorCount: number
    },
    currentlyOutOfTermValues: {
      currentlyOutOfTermCmrc: number,
      currentlyOutOfTermCmrcPercentage: number,
      cmrcWeightedTotalAutoRenewMonths: number,
      currentlyOutOfTermVendorCount: number
    },
    inactiveUsersValues: {
      inactiveSubscriberNames: [],
      inactiveSubscribersAnnualSpend: number,
      inactiveSubscribersCmrcPercentage: number,
      inactiveSubscribersServiceCount: number
    }
  }

  topCategory: {
    productCategoryName: string
    totalCmrcPercentage: number
  }
  loaded: boolean = false


  PieData: ChartData
  termReviewData: ChartData
  topFiveCategories: any[]

  monthlySpendChartData: any

  tenantData: any

  barLineChartYAxesPrefix: string = getCurrencySymbol(FrontEndSettingsUtil.getCurrencyCode(), 'wide');
  barLineChartTooltipPrefix: string = getCurrencySymbol(FrontEndSettingsUtil.getCurrencyCode(), 'wide');

  downloadCostVarianceStatusIncrease: boolean = false;
  downloadCostVarianceStatusDecrease: boolean = false;

  constructor(
    private tenantService: TenantV2Service,
    private store: Store<any>,
    private reportV2Service: ReportV2Service,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loaded = false

    const tenantData = this.store.select(appDataReducer.getAppData);


    tenantData.first().subscribe((data) => {
      this.tenantData = data.tenantData

      '6f4f2171-5bce-4651-9981-a40aeddd179c' /// PIC2

      this.tenantService.getTenantReport(this.tenantData.domain)
        .subscribe((response) => {
          console.log("here response", response)
          this.tenantReportDetails = response
          console.log("here comingOutOfTerm90DaysMonthlyBreakup", this.tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysMonthlyBreakup)

          let modifiedData = this.tenantReportDetails.productCategoryCmrcPercentages.map(item => {
            return {
              productCategoryName: item.productCategoryName,
              totalCmrcPercentage: parseFloat(item.totalCmrcPercentage.toFixed(1))

            };
          });

          let sortedCategories = modifiedData.sort((a, b) => b.totalCmrcPercentage - a.totalCmrcPercentage);

          let sortedMiMisoUserCmrcPercentages = this.tenantReportDetails.miMisoUserCmrcPercentages.sort((a, b) => b.totalCmrcPercentage - a.totalCmrcPercentage);
          this.topFiveCategories = sortedCategories.slice(0, 5)
          this.largestInternalOwner = sortedMiMisoUserCmrcPercentages[0]

          this.topCategory = {
            productCategoryName: sortedCategories.slice(0, 1)[0].productCategoryName,
            totalCmrcPercentage: sortedCategories.slice(0, 1)[0].totalCmrcPercentage
          }

          this.PieData = {
            labels: this.topFiveCategories.map(item => item.productCategoryName),
            values:
              [
                {
                  type: "Revenue",
                  values: this.topFiveCategories.map(item => item.totalCmrcPercentage)
                },
              ]
          };

          this.monthlySpendChartData = {
            barChartHeader:'Out of Term',
            lineChartHeader:'Coming Out of Term',
            currentlyOutOfTermCmrc : this.tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrc,
            months : this.tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysMonthlyBreakup.map(item => item.month),
            lineChartValue : this.tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysMonthlyBreakup.map(item => this.getRoundNUmber(item.totalCmrc)),
          };

          this.termReviewData = {
            labels: ['Coming Out of Term', 'Out of Term', 'Unknown Term'],
            values:
              [
                {
                  type: "Revenue",
                  values: [this.getRoundNUmber(this.tenantReportDetails.comingOutOfTerm90DaysValues.comingOutOfTerm90DaysCmrcPercentage),
                  this.getRoundNUmber(this.tenantReportDetails.currentlyOutOfTermValues.currentlyOutOfTermCmrcPercentage),
                  this.getRoundNUmber(100 - this.tenantReportDetails.totalDataIntegrityCmrcPercentage)]
                },
              ]
          };
          

          this.loaded = true

        },
          (e) => {
            this.error = e;
          }
        )
    })

  }


  getDataIntegrityGrade(percent) {
    let grade, colorClass;
    switch (true) {
      case percent >= 0 && percent < 60:
        grade = "F";
        colorClass = "red";
        break;
      case percent >= 60 && percent < 70:
        grade = "D";
        colorClass = "orange";
        break;
      case percent >= 70 && percent < 80:
        grade = "C";
        colorClass = "yellow";
        break;
      case percent >= 80 && percent < 90:
        grade = "B";
        colorClass = "blue";
        break;
      case percent >= 90 && percent <= 100:
        grade = "A";
        colorClass = "green";
        break;
      default:
        grade = "Invalid input";
        colorClass = "black"; // or any other default color
    }
    return { grade, colorClass };
  }

  getPercentage(number: number) {
    const modifiedPercentage = number.toFixed(1);
    return modifiedPercentage + '%'
  }


  getRoundNUmber(number: any) {
    const modifiedPercentage = number.toFixed(1);
    return modifiedPercentage
  }


  pluralizeWord(word, count) {
    if (count === 1) {
      return word;
    } else {
      return word + 's';
    }
  }

  fixDataIntegrityHandler(): void {
    this.router.navigate(['data-integrity']);
  }

  downloadCostVarianceExport(type: string) : void {
    this.router.navigate(['/cost-variance-report']);

    /*
    if(this.downloadCostVarianceStatusIncrease == true || this.downloadCostVarianceStatusDecrease == true) {
      return;
    }
    switch(type) {
      case "increase":
        this.downloadCostVarianceStatusIncrease = true;
        break;
      case "decrease":
        this.downloadCostVarianceStatusDecrease = true;
        break;
    }
    
    this.reportV2Service.getCostVarianceExport(this.tenantData.domain)
    .subscribe(res => {      
      let contentDisposition = res.headers.get('content-disposition');
      let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      let blob = new Blob([res.body], {type: "application/octet-stream"});
      let downloadUrl = window.URL.createObjectURL(blob);
      let anchor = document.createElement("a");
      anchor.download = filename;
      anchor.href = downloadUrl;
      anchor.click();

      setTimeout(() => {
        this.downloadCostVarianceStatusIncrease = false;
        this.downloadCostVarianceStatusDecrease = false;
      }, 500);      
    });
    */
  }

}
