<div class="tab-title-header">
    <h2>{{title}}</h2>

<ng-container *ngIf="editable">
  <a class="edit-link button dark-theme" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>
</ng-container>
</div>

<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="pageLoaded; else loading">

  <div class="open" *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
      <mi-find-or-create-service-term [serviceTermId]="serviceTerm?.id" [service]="serviceDetails"
        (selectedServiceTerm)="selectedServiceTerm($event)"
        (serviceTermRemoved)="serviceTermRemovedHandler($event)"
        (modeChange)="modeChange($event)" (canceled)="onToggleExpand()"></mi-find-or-create-service-term>

      <div class="button-container" *ngIf="!edit">
        <button mi-button buttonTheme="light-theme" (click)="onToggleExpand()">
          {{'GLOBAL.BUTTON_CANCEL' | translate}}
        </button>
        <button mi-button (click)="updateServiceTerm()" buttonTheme="dark-theme">
          Apply
        </button>
        </div>
    </mi-opacity-loader>
  </div>

  <ng-template #static>
    <dl class="section-details card-1" id="Notification_Date">
      <ng-container *ngIf="serviceTerm; else noTerms">
        <div>
          <dt>
            MISO ID
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm && serviceTerm.display_id; else noTermsAndConditionsDoc">
              <mi-id-link [id]="serviceTerm.id" [display_id]="serviceTerm.display_id"></mi-id-link>
            </ng-container>
            <ng-template #noTermsAndConditionsDoc>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>
            End of Term Action
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm; else noEot">
              {{'END_OF_TERM.' + serviceTerm.event_type | translate}}
            </ng-container>
            <ng-template #noEot>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>
            Auto-Renew Period
          </dt>
          <dd>
            <ng-container *ngIf="serviceTerm && serviceTerm.auto_renew_length; else noArp">
              {{('MONTHS_QTY'
              | translate:{count: serviceTerm.auto_renew_length}
              | translateSelector: serviceTerm.auto_renew_length ) || NO_DATA}}
            </ng-container>
            <ng-template #noArp>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>{{ 'DAYS_TO_NOTIFY' | translate}}</dt>
          <dd>
            <ng-container *ngIf="serviceTerm; else noDtn">
              {{serviceTerm.days_to_notify|| 0}}
            </ng-container>
            <ng-template #noDtn>
              {{NO_DATA}}
            </ng-template>
          </dd>
        </div>
        <div>
          <dt>Origin of Term</dt>
          <dd>
            {{serviceTerm.service_term_origin}}
          </dd>
        </div>
      </ng-container>

      <ng-template #noTerms>
        <div>
          There is no agreement, end of term action, auto-renew period or days to notifiy for this service.
        </div>
      </ng-template>

    </dl>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>