<div class="note" *ngIf="note.user; else versionTwoNote">

  <div class="note-avatar" [ngClass]="{system: note.user.isSuperUser}"
       [ngStyle]="{ 'background-color': this.note.user.color }">
    {{initials}}
  </div>

  <div class="note-details">
    <div class="note-body-name" *ngIf="note.user.isSuperUser">Miyagi Message</div>
    <div class="note-body-name" *ngIf="!note.user.isSuperUser || showSuperUsers"
         [ngClass]="{super: note.user.isSuperUser, archived: archived}">
      <span *ngIf="archived">Archived User:</span>
      {{this.note.user.first_name}}
      {{this.note.user.last_name}}
    </div>
    <div class="note-timestamp">
      {{ this.relativeTime }}
      <span class="smaller light-gray">{{this.note.created_at | date:'shortDate'}}</span>
    </div>
    <div class="note-body" [innerHTML]="this.note.html"></div>
  </div>

</div>

<ng-template #versionTwoNote>
  <div class="note">

    <div class="note-avatar system">
    </div>

    <div class="note-details">
      <div class="note-body-name">
        <a href="mailto:{{note.user_email}}">{{note.user_email}}</a>
      </div>
      <div class="note-timestamp">
        {{ this.relativeTime }}
        <span class="smaller light-gray">{{this.note.created_at | date:'shortDate'}}</span>
      </div>
      <div class="note-body" [innerHTML]="this.note.html"></div>
    </div>
  </div>
</ng-template>
