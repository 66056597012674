import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../../shared/state/service-v2/actions';
import { lessThenDateValidator } from "src/shared/shared.validators";

@Component({
  selector: 'mi-advanced-search-filter-date-v2',
  templateUrl: './advanced-search-filter-date-v2.component.html',
  styleUrls: ['./advanced-search-filter-date-v2.component.scss']
})

export class AdvancedSearchFilterDateV2Component extends AbstractPageComponent {
  @Input() title: string = 'Date Filter';
  @Input() filter_name: string='Date';
  @Input() min_label: string='Min';
  @Input() max_label: string='Max';
  @Input() min_placeholder: string='Start';
  @Input() max_placeholder: string='End';
  @Input() min_prefill: string = null;
  @Input() max_prefill: string = null;
  @Input() filter_button_label: string='Apply';
  @Output() filterApplied: EventEmitter<any> = new EventEmitter<any>();

  min: Date;
  max: Date;
  maxname: string = "max";
  minname: string = "min";
  form: FormGroup = null;
  isVisible: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<ServiceV2Reducer.State>
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.maxname = "max";
    this.minname = "min";

    let formgroup = {};
    formgroup[this.maxname]=[null];
    formgroup[this.minname]=[null];
    this.form = this.formBuilder.group(formgroup, {
      validator: lessThenDateValidator(this.minname, this.maxname)
    });

    this.prefillFormData();
  }

  prefillFormData() {
    let formgroupdata = {};
    formgroupdata[this.maxname] = this.max_prefill || null;
    formgroupdata[this.minname] = this.min_prefill || null;
    this.form.setValue({...formgroupdata});
  }

  onFormSubmit(data) {
    let formgroupdata = {};
    formgroupdata[this.maxname] = data[this.maxname];
    formgroupdata[this.minname] = data[this.minname];
  
    this.filterApplied.emit(formgroupdata);
  }

  onDateChangeMin(newDate) {
    this.form.get(this.minname).setValue(newDate);
    this.form.updateValueAndValidity();
  }

  onDateChangeMax(newDate) {
    this.form.get(this.maxname).setValue(newDate);
    this.form.updateValueAndValidity();
  }
}