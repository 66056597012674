<div class="card p-3">
<mi-server-error [error]="loadError"></mi-server-error>
<mi-loading-animation *ngIf="loading"></mi-loading-animation>

<div class="location">
  {{ this.selectedAddress && this.selectedAddress.formatted_address }}
</div>

<ng-container *ngIf="!loading">
  <h3>Choose a location</h3>

  <div class="row">
    <radio-array-list
      placeholder="New Location"
      [(ngModel)]="selectedLocation"
      allowNew="true"
      [newValue]="newLocationLabel"
      [buttonOptions]="allLocations"
    >
    </radio-array-list>
  </div>

  <div class="row">
    <mi-server-error [error]="submitError"></mi-server-error>
    <div class="button-container">
      <a mi-button
         class="button--cancel"
         buttonTheme="light-theme"
         (click)="onCancel($event)">
        Cancel
      </a>

      <button mi-button
              type="submit"
              [disabled]="!selectedLocation || isSubmitLocationProgress"
              (click)="submitLocation($event)">
          {{ctaLabel}} <mi-loading-button *ngIf="isSubmitLocationProgress"></mi-loading-button>
      </button>
    </div>
  </div>
</ng-container>
</div>
