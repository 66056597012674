<mi-loading-animation *ngIf="loading"></mi-loading-animation>
<mi-server-error [error]="loadingError"></mi-server-error>

<form *ngIf="!loading && termsAndConditionsForm"
      [formGroup]="termsAndConditionsForm"
      (submit)="onFormSubmit(termsAndConditionsForm.value)">

  <dd class="afterward">
    <mi-extended-input
      [labelText]="titles.select"
      labelRequired="true"
      [(inputControl)]="termsAndConditionsForm.controls['event_type']"
      [(inputErrors)]="termsAndConditionsForm.controls['event_type'].errors"
      [(inputTouched)]="termsAndConditionsForm.controls['event_type'].touched"
      [errorDefs]='{required: "Please select an End of Term action."}'>
      <select (change)="onEOTSelect($event)" [attr.disabled]="isEdit ? '' : null"
              formControlName="event_type">
        <option [value]="EVENT_TYPE_AUTO_RENEW">Auto-Renew</option>
        <option [value]="EVENT_TYPE_AUTO_CANCELLATION">Cancel</option>
      </select>
    </mi-extended-input>
  </dd>

  <ng-container *ngIf="autoRenewSelected">
    <mi-extended-input [labelText]="titles.notification_period"
                       labelRequired="true"
                       suffix="Months"
                       [(inputControl)]="termsAndConditionsForm.controls['auto_renew_length']"
                       [(inputErrors)]="termsAndConditionsForm.controls['auto_renew_length'].errors"
                       [(inputTouched)]="termsAndConditionsForm.controls['auto_renew_length'].touched"
                       [errorDefs]="{required: 'Please enter an auto-renew frequency.'}">
      <input type="number" [attr.disabled]="isEdit ? '' : null"
             formControlName="auto_renew_length"
             placeholder="Ex. 3"
             min="0"
             (change)="onMonthChange($event)"
             mi-restrict-input miRestrictType="Integer"/>
    </mi-extended-input>
    <mi-extended-input labelRequired="true"
                       [labelText]="titles.requred_notification"
                       suffix="Days"
                       [(inputControl)]="termsAndConditionsForm.controls['days_to_notify']"
                       [(inputErrors)]="termsAndConditionsForm.controls['days_to_notify'].errors"
                       [(inputTouched)]="termsAndConditionsForm.controls['days_to_notify'].touched"
                       [errorDefs]="{required: 'Please enter days required for cancellation notification.'}">
      <input type="number"
             formControlName="days_to_notify"
             placeholder="Ex. 30"
             min="0"
             mi-restrict-input miRestrictType="Integer"/>
    </mi-extended-input>
  </ng-container>

  <mi-extended-input labelRequired="true"
                     labelText="Agreement Label"
                     hint="The display name for this agreement. A recommended agreement label has been generated."
                     [(inputControl)]="termsAndConditionsForm.controls['label']"
                     [(inputErrors)]="termsAndConditionsForm.controls['label'].errors"
                     [(inputTouched)]="termsAndConditionsForm.controls['label'].touched"
                     [errorDefs]="{required: 'Please enter an agreement label.'}">
    <input type="text"
           formControlName="label"
           placeholder="Ex. 'ABC Renewal'"/>
  </mi-extended-input>

  <mi-extended-input
    labelText="Cancellation Instructions"
    hint="Put any details you wish to save about the cancellation process."
    [(inputControl)]="termsAndConditionsForm.controls['cancellation_instructions']"
    [(inputErrors)]="termsAndConditionsForm.controls['cancellation_instructions'].errors"
    [(inputTouched)]="termsAndConditionsForm.controls['cancellation_instructions'].touched">
          <textarea
            formControlName="cancellation_instructions"
            placeholder="Ex. 'ABC Renewal'"></textarea>
  </mi-extended-input>

  <mi-extended-input labelText="Agreement Link"
                     [(inputControl)]="termsAndConditionsForm.controls['agreement_link']"
                     [(inputErrors)]="termsAndConditionsForm.controls['agreement_link'].errors"
                     [(inputTouched)]="termsAndConditionsForm.controls['agreement_link'].touched"
                     [errorDefs]="{url: 'Please enter a valid URL.'}">
    <input type="text"
           formControlName="agreement_link"
           placeholder="http://example.com/terms.html"/>
  </mi-extended-input>

  <mi-server-error [error]="submitError"></mi-server-error>

  <div class="button-container">
    <ng-container *ngIf='isEdit'>
      <a mi-button
        class="button-alternate"
        buttonTheme="alert-light-theme"
        routerLink="../delete">
        Delete
      </a>
    </ng-container>

    <a mi-button
       type="button"
       (click)="onBackClick()"
       buttonTheme="light-theme">
      Cancel
    </a>

    <button mi-button
            type="submit"
            [disabled]="!termsAndConditionsForm.valid"
            buttonTheme="dark-theme">
      Continue
    </button>

  </div>

</form>

