import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../../create/state/reducer';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {UpdateOrderAction} from '../../../create/state/actions';
import {integerValidator, numberValidator} from '../../../../../../../shared/shared.validators';
import { Subject } from 'rxjs';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {TermsAndConditions} from '../../../../../../../shared/models/TermsAndConditions';
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';

@Component({
  selector: 'mi-service-terms-and-conditions',
  styleUrls: ['./service-terms-and-conditions.component.scss'],
  templateUrl: './service-terms-and-conditions.component.html'
})

export class ServiceTermsAndConditionsComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  commandGetAccountId:Subject<any> = new Subject();
  currentDate: string;
  error: string;
  formDisabled: boolean = true;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  serviceId:string;
  serviceTermForm:FormGroup;
  serviceTermDefined: boolean = false;
  serviceTerm: ServiceTerm;
  step: string = OrderSteps.TermsAndConditions;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
  ) {
    super();
  }

  ngOnInit() {

    this.serviceTermForm = this.formBuilder.group({
      service_term: [null]
    });

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state:OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
          this.serviceTermDefined = 'service_term' in this.orderState.service;
          this.formDisabled = !this.serviceTermDefined;
        },
        (e) => {
          this.error = e;
        });

  }

  onChangeClicked():void{
    this.serviceTerm = null;
    this.serviceTermDefined = false;
    this.serviceTermForm.get('service_term').setValue(null);
    this.formDisabled = true;
  }

  onSelectServiceTerm(evt):void{
    this.serviceTerm = evt;
    this.serviceTermDefined = true;
    this.serviceTermForm.get('service_term').setValue(this.serviceTerm);
    this.formDisabled = false;
  }

  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          service_term: data.service_term? data.service_term.id : ''
        }
      }
    ));

  }

}
