
import {Vendor} from "./Vendor";
import {Product} from "./Product";
import {Contact} from "./Contact";
import {Address} from "./Address";
import {MiService} from "./MiService";
import { Equipment } from "./Equipment";
import { MiServiceGroup } from "./MiServiceGroup";

export class SearchResultsMeta {
  params: any;
}

export class totalResults {
  relation: string;
  value: number;
}

export class SearchResults {
  meta: SearchResultsMeta;
  aggregations:any;
  total: totalResults;
  hits: Array<any>;
  additionalHits?: Array<any>;
}

export class MiServiceSearchResults extends SearchResults {
  hits: Array<MiService>
}

export class VendorSearchResults extends SearchResults {
  hits: Array<Vendor>
}

export class ProductSearchResults extends SearchResults {
  hits: Array<Product>
}

export class ContactSearchResults extends SearchResults {
  hits: Array<Contact>
}

export class AddressSearchResults extends SearchResults {
  hits: Array<Address>;
}

export class EquipmentSearchResults extends SearchResults {
  hits: Array<Equipment>
}

export class MiServiceGroupSearchResults extends SearchResults {
  hits: Array<MiServiceGroup>
}
