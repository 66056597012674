<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <div class="half-col">

      <h2>What is the length of the Service term?</h2>

      <form [formGroup]="datesForm" (submit)="onFormSubmit(datesForm.value)">

      <ul class="options">
        <li>
          <input id="NotSure"
                 type="radio"
                 [value]="NOT_SURE"
                 [name]="radioSelect"
                 [checked]="radioSelect === NOT_SURE"
                 (change)="onRadioSelect($event)"/>
          <label for="NotSure">
            Not Sure
            <p class="hint">Not setting a term length will make this service incomplete</p>
          </label>
        </li>
        <li>
          <input id="StartAndLength"
                 type="radio"
                 [value]="START_AND_LENGTH"
                 [name]="radioSelect"
                 [checked]="radioSelect === START_AND_LENGTH"
                 (change)="onRadioSelect($event)"/>
          <label for="StartAndLength">
            I know the term start date and length
          </label>
        </li>
        <li *ngIf="radioSelect==START_AND_LENGTH">
          <div class="form-field">
            <mi-extended-input labelText="Start Date"
                               [labelRequired]="true"
                               [(inputControl)]="datesForm.controls['start_date']"
                               [(inputErrors)]="datesForm.controls['start_date'].errors"
                               [(inputTouched)]="datesForm.controls['start_date'].touched"
                               [errorDefs]="{required: 'A start date is required.'}">

              <mi-date-picker (dateChange)="onStartDateChange($event)"
                              [currentDate]="startDate">

              </mi-date-picker>

            </mi-extended-input>
            <mi-extended-input labelText="Term Length"
                               [labelRequired]="true"
                               [(inputControl)]="datesForm.controls['term_length']"
                               [(inputErrors)]="datesForm.controls['term_length'].errors"
                               [(inputTouched)]="datesForm.controls['term_length'].touched"
                               [errorDefs]="{ required: 'A term length is required.', min: 'Please enter a number greater than zero', integer: 'Whole numbers only, please.', notNumber: 'Numbers only, please.'}">
              <input type="number" min="1"
                     formControlName="term_length"
                     placeholder="ex: 1"
                     mi-restrict-input miRestrictType="Integer"/>
            </mi-extended-input>
          </div>

        </li>
        <li>
          <input id="EndDate"
                 type="radio"
                 [value]="END_DATE"
                 [name]="radioSelect"
                 [checked]="radioSelect === END_DATE"
                 (change)="onRadioSelect($event)"/>
          <label for="EndDate">
            I know the term end date.
          </label>
        </li>
        <li *ngIf="radioSelect==END_DATE">
          <div class="form-field">
            <mi-extended-input labelText="End Date"
                               [labelRequired]="true"
                               [(inputControl)]="datesForm.controls['end_date']"
                               [(inputErrors)]="datesForm.controls['end_date'].errors"
                               [(inputTouched)]="datesForm.controls['end_date'].touched"
                               [errorDefs]="{required: 'An ending date is required.'}">
              <mi-date-picker (dateChange)="onEndDateChange($event)"
                              [currentDate]="endDate">

              </mi-date-picker>
            </mi-extended-input>
          </div>
        </li>
      </ul>

      <div class="button-container">
        <a mi-button
            buttonTheme="light-theme"
            (click)="onCancelCLicked()">
          Cancel
        </a>
        <button type="submit"
                mi-button
                buttonTheme="dark-theme"
                [disabled]="!datesForm.valid">
          Next
        </button>
      </div>
      </form>
    </div>

  </ng-container>

  <ng-template #static>

    <ng-container [ngSwitch]="radioSelect">
      <ng-container *ngSwitchCase="START_AND_LENGTH">
        <ul class="service-dates">
          <li>
            <dl>
              <dt>Start Date</dt>
              <dd>{{datesForm.get('start_date').value || NO_DATA}}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Term Length</dt>
              <dd>{{datesForm.get('term_length').value || NO_DATA}}</dd>
            </dl>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngSwitchCase="END_DATE">
        <dl>
          <dt>End Date</dt>
          <dd>{{datesForm.get('end_date').value || NO_DATA}}</dd>
        </dl>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ul>
          <li>
            <dl>
              <dt>Start Date</dt>
              <dd>{{datesForm.get('start_date').value || NO_DATA}}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Term Length</dt>
              <dd>{{datesForm.get('term_length').value || NO_DATA}}</dd>
            </dl>
          </li>
        </ul>
      </ng-container>
    </ng-container>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


