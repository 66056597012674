import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';


@Injectable()
export class DashboardService {

  private apiUrl = '_MICRO_SERVICE_/dashboard';


  constructor(private http: HttpClient) {

  }

  getMrcChartData(body): Observable<any> {

    return this.http
      .post(`${this.apiUrl}/cMrc`, body)
      .map((response: any) => response);

  }

  untrackedSpendingChartData(body): Observable<any> {


    return this.http
      .post(`${this.apiUrl}/untrackedSpending`, body)
      .map((response: any) => response);

  }

  getRenewalData(body): Observable<any> {

    return this.http
      .post(`${this.apiUrl}/renewal`, body)
      .map((response: any) => response);

  }
}
