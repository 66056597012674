function getfieldsProperties (type){
  let fieldsDownload = []
  switch (type) {
    case 'Contacts':
      fieldsDownload = [
        {
          group: "Required",
          label: "MISO ID",
          index:0,
          object_paths: [
            {
                column_name: "MISO ID",
                path: "CNT.display_id"
            }
          ]
        },
        {
          group: "Required",
          label: "First Name",
          index:1,
          object_paths: [
            {
              column_name: "First Name",
              path: "CNT.first_name"
            }
          ]
        },
        {
          group: "Required",
          label: "Last Name",
          index:2,
          object_paths: [
            {
                column_name: "Last Name",
                path: "CNT.last_name"
            }
          ]
        },
        {
          group: "Required",
          label: "Email",
          index:3,
          object_paths: [
            {
              column_name: "Email",
              path: "CNT.email"
            }
          ]
        },
        {
          group: "Required",
          label: "Vendor",
          index:4,
          object_paths: [
            {
              column_name: "Vendor Display ID",
              path: "CNT.vendor.display_id",
              value: "CNT.vendor.vendor_name"
            }
          ]
        },
        {
          group: "Optional",
          label: "Role",
          index:5,
          object_paths: [
            {
              column_name: "Role",
              path: "CNT.role"
            }
          ]
        },
        {
          group: "Optional",
          label: "Status",
          index:6,
          object_paths: [
            {
              column_name: "The user status, such as ACTIVE",
              path: "CNT.status"
            }
          ]
        },
        {
          group: "Optional",
          label: "Mobile Number",
          index:7,
          object_paths: [
            {
              column_name: "Mobile Locality: Choose OTHER or US",
              path: "CNT.mobile_phone.locality"
            },
            {
              column_name: "Mobile Phone",
              path: "CNT.mobile_phone.display"
            }
          ]
        },
        {
          group: "Optional",
          label: "Office Number",
          index:8,
          object_paths: [
            {
              column_name: "Office Locality: Choose OTHER or US",
              path: "CNT.office_phone.locality"
            },
            {
              column_name: "Office Phone",
              path: "CNT.office_phone.display"
            }
          ]
        },
        {
          group: "Optional",
          label: "Fax Number",
          index:9,
          object_paths: [
            {
              column_name: "Fax Locality: Choose OTHER or US",
              path: "CNT.fax.locality"
            },
            {
              column_name: "Fax Number",
              path: "CNT.fax.display"
            }
          ]
        }
      ]
      break;
    default:
      break;
  }
  return fieldsDownload;
}

function getTemplateFieldsProperties (type){
  let fieldsDownload = []
  switch (type) {
      case 'MiServices':
          fieldsDownload = 
          [
            {
              title: "Vendors, Products, Services & Locations",
              sub_sections: [
                {
                  columns: [
                    {
                      fields: [
                        "vendor_purchasing",
                        "account_identifier",
                        "product_name",
                        "product_category"]
                    },
                    {
                      fields: [
                        "service_quantity",
                        "unit_recurring_cost",
                        "billing_frequency",
                        "unit_non_recurring_cost",
                        "currency"]
                    },
                    {
                      fields: [
                        "service_start_date",
                        "service_term",
                        "service_end_date",
                        "service_identifier"]
                    }
                  ]
                },

                {
                  separator: true,
                  columns: [
                    {
                      fields: [
                        "location_a",
                        "location_b"
                      ]
                    }       
                  ]
                }

              ]
            },
            {
              title: "Service Ownership",
              sub_sections: [
                {
                  columns: [
                    {
                      fields: [
                        "assigned_email",
                        "requestor_email",
                        "end_user_email"
                      ]
                    },
                    {
                      fields: [
                        "assigned_division",
                        "requestor_division",
                        "end_user_division"
                      ]
                    },
                    {
                      fields: [
                        "assigned_team",
                        "requestor_team",
                        "end_user_team"
                      ]
                    }
                  ]
                }
              ]
            },
            {
              title: "Invoices, Service Orders & Documents",
              sub_sections: [
                {
                  columns: [
                    {
                      fields: [
                        "order_number",
                        "order_date"]
                    },
                    {
                      fields: [
                        "invoice_number",
                        "invoice_date",
                        "billing_period_start",
                        "billing_period_end",
                        "invoice_total_new_charges"]
                    },
                    {
                      fields: [
                        "doc_id",
                        "service_group"
                      ]
                    }
                  ]
                }
              ]
            }
          ];
          break;
      case 'MiServicesColumnSelect':
        fieldsDownload = 
        [
          {
            title: "Vendors Data",
            sub_sections: [
              {
                columns: [
                  {
                    fields: [
                      "Vendor (Purchasing)",
                      "Account ID",
                      //"Vendor (Manufacturer)",
                    ]
                  },
                  {
                    fields: [
                      //"Order Date",
                      //"Order Number",
                    ]
                  },
                  {
                    fields: [
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: "Service Data",
            sub_sections: [
              {
                columns: [
                  {
                    fields: [
                      "Product",
                      "Service Category",
                      "Service ID",
                      //"Division",
                      //"Team",
                      //"Service Group"
                    ]
                  },
                  {
                    fields: [
                      "Quantity",
                      "Unit Recurring Cost",
                      "Unit Non-Recurring Cost",
                      "Billing Frequency",
                      "Currency",
                      //"cMRC"
                      "CMRC",
                      "Activity Alert",
                      "Price Increase %"
                    ]
                  },
                  {
                    fields: [
                      //"Service Owner",
                      "Accountable Executive",
                      "Subscriber",
                      //"M3 Owner",
                      "Mi-MiSO Owner",
                      "Validation Flag",
                      "Days Since Last Update",
                      "In-Term",
                      "Liability"
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: "Events and Service Status",
            sub_sections: [
              {
                columns: [
                  {
                    fields: [
                      //"Next Event",
                      //"Event Date",
                      "Days to Event",
                      //"Next Event Value",
                      "Number of Auto Renew"
                    ]
                  },
                  {
                    fields: [
                      "Data Integrity",
                      //"Service Status",
                    ]
                  },
                  {
                    fields: [
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: "Dates and Locations",
            sub_sections: [
              {
                columns: [
                  {
                    fields: [
                      "Start Date",
                      "Current Term",
                      "End of Term",
                      "Termination Date"
                    ]
                  },
                  {
                    fields: [
                      "Location A",
                      "Location B",
                    ]
                  },
                  {
                    fields: [
                    ]
                  }
                ]
              }
            ]
          }
        ];
        break;
    default:
      break;
  }
  return fieldsDownload;
}

export {
  getfieldsProperties,
  getTemplateFieldsProperties
}