import {Component, Input} from '@angular/core';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';
import { ModalService } from '@independer/ng-modal';
import { CreateReportOrViewModalComponent } from './create-report-or-view-modal/create-report-or-view-modal.component';
import { RunReportOrViewModalComponent } from './run-report-or-view-modal/run-report-or-view-modal.component';
import { ExcelEmailNotificationModalComponent } from '../../excel-email-notification-modal/excel-email-notification-modal.component';
import { AdvancedSearchFilterModalComponent } from '../../advanced-search-filter-modal/advanced-search-filter-modal.component';

@Component({
  selector: 'mi-header-action-dropdown',
  templateUrl: './action-dropdown-buttons.component.html',
  styleUrls: ['./action-dropdown-buttons.component.scss']
})

export class HeaderActionDropdownComponent extends AbstractPageComponent {

  @Input() searchType: string = 'SERVICE';
  @Input() addNewURL: string = '';


  total = 0;
  total_mrc = 0;
  loading = true;
  q: string = '';

  constructor(
    private modalService: ModalService,
    private store: Store<ASReducer.State>) {
    super();
  }

  onSearchSubmit(searchValue) {

    const newQuery = {
      q: searchValue
    };

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }

  clearSearch() {
    const newQuery = {
      q: ''
    };
    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }

  ngOnInit() {
  }

  openRunModal(action_type: string = "SERVICE_REPORT"){

    const modalRef = this.modalService.open(RunReportOrViewModalComponent, m => {
      m.action_type = action_type;
      m.searchType = this.searchType;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;
      console.log("report", report)
      if(report && report.print && action_type === "SERVICE_REPORT"){
        this.createNotification("SERVICE_REPORT");
      }

    });
  }

  emailNotification(){
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Email Notification";
      m.message = "Your request to download Services Inventory is in progress. Please check your email in a few minutes to receive the requested information (it may also be in your Spam folder).";
      m.closeButtonLabel = "Confirm";
    });
  }

  createNotification(action_type){
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Create Notification";
      m.message = `Your request to create ${action_type} was successful. Please check Actions to use it in the future.`;
      m.closeButtonLabel = "Confirm";
    });
  }

  openCreateModal(action_type: string = "SERVICE_REPORT"){
    const modalRef = this.modalService.open(CreateReportOrViewModalComponent, m => {
      m.action_type = action_type;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;

      if(report && report.report){
        this.createNotification(action_type);
      }

    });
  }

  openShowAllFilter(){
    const modalRef = this.modalService.open(AdvancedSearchFilterModalComponent, m => {
      m.searchType = ASReducer.ADVANCED_SEARCH_TYPES.TENANT_USER;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results

      const [data] = result;
      if(data){
        console.log(data);
      }

    });
  }

  // createNewDocument(){
  //   console.log("createNewDocument")
  // }

}
