import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { Subscription} from 'rxjs/Subscription';
import * as appDataReducer from '../../../state/app-data/reducers';
import {createLogger, LOG_LEVELS} from '../../../../shared/logger';
import {Store} from "@ngrx/store";
import {settings} from '../../../../common/utils/SettingsUtil';
import {Tenant} from "../../../models/Tenant";

const log = createLogger(LOG_LEVELS.BASE_PAGE);

export const APP_TYPES: any = {
  MIYAGI: new URL(settings.MIYAGI_URL).host,
  ACCOUNTS: new URL(settings.ACCOUNTS_URL).host,
  TENANT: 'TENANT'
};

@Component({
  selector: 'mi-main-nav-standard-flyout',
  templateUrl: './main-nav-standard-flyout.component.html',
  styleUrls: ['./main-nav-standard-flyout.component.scss']
})
export class MainNavStandardFlyoutComponent implements OnInit {

  @Input() links: Array<Object>;

  appData: any;
  isBranded: boolean = false;
  isMiyagi: boolean = /^https?:\/\/miyagi/.test(window.location.href);
  logoUrl: string;
  tenant: Tenant;

  private appSubscription: Subscription;

  constructor(
    private activatedRoute:ActivatedRoute,
    private baseAppDataStore: Store<appDataReducer.State>) { }

  toggleNest(e){
    // Flip the switch:
    e.target.classList.toggle('open');
    // Find the corresponding UL:
    let nestedLinks = e.target.parentElement.parentElement.querySelector('.nested');
    nestedLinks.classList.toggle('open');
  }

  ngOnInit( ) {
    this.baseAppDataStore.select(appDataReducer.getAppData).subscribe(
      state => {
        switch(state.appType){
          case APP_TYPES.TENANT:
            this.tenant = state.tenantData;
            this.isBranded = !!this.tenant && !!this.tenant.logo && !!this.tenant.logo.full_path;
          case APP_TYPES.MIYAGI:
            this.logoUrl = '/';
            break;
          case APP_TYPES.ACCOUNTS:
            this.logoUrl =  '/accounts';
            break;
        }
      }
    );
  }
}
