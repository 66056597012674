import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DivisionV2} from "../types/DivisionV2.interface";
import {SiteV2} from "../types/SiteV2";
import {BusinessUnitV2Service} from "../tenant-v2/bussiness-unit-v2.service.interface";


@Injectable()
export class SiteV2Service implements BusinessUnitV2Service{

  private divisionHost = '_MICRO_SERVICE_/tenant';

  constructor(private http: HttpClient) {
  }

  newBusinessUnit(params: DivisionV2): Observable<SiteV2> {
    return this.http
      .post(`${this.divisionHost}/site`, params)
      .map((response: any) => response as SiteV2);
  }

  getBusinessUnits(parent_id: string = ''): Observable<SiteV2[]> {
    const params = {
      id: parent_id
    };
    return this.http
      .get(`${this.divisionHost}/sites`, {params})
      .map((response: any) => response as SiteV2[]);
  }

  getBusinessUnit(id: string = ''): Observable<SiteV2> {
    const params = {
      id: id
    };
    return this.http
      .get(`${this.divisionHost}/site`, {params})
      .map((response: any) => response as SiteV2);
  }

}
