import * as DocumentV2Actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import { DocumentsV2ListResponse } from 'src/shared/models/DocumentV2';

export interface State {
  results: DocumentsV2ListResponse;
  searchLoaded: boolean;
}

const initialState: State = {
  results: {
    data: [],
    moreAvailable: false,
    pageNumber: 0,
    pageSize: 0,
    total: 0
  },
  searchLoaded: true
};

export function reducer(state = initialState, action: DocumentV2Actions.Actions): State {
  // actions have action.type:string and action.payload:object.
  // log('inside of user reducer', state, action);
  switch (action.type) {
    case DocumentV2Actions.ActionTypes.SET_RESULTS: {
      return {
        ...state,
        results: {
          ...action.payload
        }
      };
    }
    case DocumentV2Actions.ActionTypes.GET_DATA: {
      return {
        ...state
      };
    }
    case DocumentV2Actions.ActionTypes.SEARCH_LOADING: {
      return {
        ...state,
        searchLoaded: false
      };
    }
    case DocumentV2Actions.ActionTypes.SEARCH_LOADED: {
      return {
        ...state,
        searchLoaded: true
      };
    }
    default: {
      return state;
    }
  }
}

export const getDocumentV2Data = createFeatureSelector<State>('documentV2');
export const getLoaded = createSelector(
  getDocumentV2Data,
  (state) => state.searchLoaded
);