    <div [ngClass]="{horizontal: horizontal}">
      <dt>
        {{productField.label}}
      </dt>
      <dd>
        <ng-container>
          {{productField.value}}
          {{productField.unit}}
        </ng-container>
      </dd>
    </div>
