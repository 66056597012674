import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {LocationModel} from '../../../shared/models/Location';
import {CanDelete} from '../../../shared/models/CanDelete';

@Injectable()
export class TenantLocationService {

  private apiUrlCreate = '/{tenant}/locations/';
  private apiUrlGet = '/{tenant}/locations';
  private apiUrlGetByDisplayId = '/{tenant}/locations-by-display-id';

  constructor(private http: HttpClient) {
  }

  create(label, address, status): Observable<LocationModel> {
    return this.http
      .post(this.apiUrlCreate, {label, address, status})
      .map((response: any) => response.data.location as LocationModel);
  }

  getLocation(id: string): Observable<LocationModel> {
    return this.http
      .get(`${this.apiUrlGet}/${id}`)
      .map((response: any) => response.data.location as LocationModel);
  }


  getByDisplayIdLocation(display_id: string): Observable<LocationModel> {
    return this.http
      .get(`${this.apiUrlGetByDisplayId}/${display_id}`)
      .map((response: any) => response.data.location as LocationModel);
  }

  updateLocation(location: LocationModel): Observable<LocationModel> {
    return this.http
      .put(`${this.apiUrlGet}/${location._id}`, location)
      .map((response: any) => response.data.location as LocationModel);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlGet}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteLocation(id: String): Observable<LocationModel> {
    return this.http
      .delete(`${this.apiUrlGet}/${id}`)
      .map((response: any) => response.data.location as LocationModel);
  }

}
