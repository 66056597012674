<input [ngModel]="_value"
       type="tel"
       (blur)="inputTouched()"
       (ngModelChange)="onDisplayChange($event)"
       placeholder="(555) 555-5555"
       [miOnlyNumbers]="true"
       [maxlength]="maxLength"
       [attr.disabled]="disable ? disable : null"
       [attr.tabindex]="tabindex"
>
