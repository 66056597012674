import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {TypeReferenceEnum} from '../../../shared/ng-models/TypeReference.enum';
import { BulkUploadTemplateField } from 'src/shared/models/BulkUploadTemplateSection.interface';
import {Settings as luxonSettings} from 'luxon';
import FeDateUtil from 'src/shared/utils/FeDate.util';

export interface IResolvedData {
  property_id: string;
  resolution_value: string;
  system_id?: string;
  is_autoresolved: boolean;
  ignore: boolean;
}
export interface IAssignServiceKey {
  service_id: string;
  service_key: string;
}

export interface IMatchingService {
  bulkUploadServiceId: string;
  serviceKey?: string;
  display_id?: string;
}

@Injectable()
export class SubsscriptionService {

  private uploadSubscriptionHost = '_MICRO_SERVICE_/subscription';


  constructor(private http: HttpClient) {

  }

  getSubscriptionList(tenantId, search: string = '', pageNumber: number = 0, pageSize: number = 20): Observable<any> {

    return this.http
      .get(`${this.uploadSubscriptionHost}/list?tenantId=${tenantId}&pagination=true&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`)
      .map((response: any) => response);

  }

}
