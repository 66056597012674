<div class="tab-title-header">
  <h2>{{title}}</h2>

  <ng-container *ngIf="editable">
    <a class="edit-link button dark-theme" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>
  </ng-container>
</div>

<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="pageLoaded; else loading">
  <div class="open" *ngIf="isOpen; else static">
    <div class="tag-container">
      <div *ngFor="let serviceTag of serviceTags" class="tag-wrapper">
        <div class="tag-name">
          {{serviceTag.tag_name}}
        </div>
        <div class="tag-value">
          {{serviceTag.tag_value}}
        </div>
        <div class="edit" (click)="editTag(serviceTag)"></div>
        <div class="delete" (click)="deleteTag(serviceTag)"></div>
      </div>
      <div class="add-tag-container">
        <a mi-button (click)="addNewTag()">Add New Tag</a>
      </div>
    </div>
  </div>
  <ng-template #static>
    <div class="tag-container">
      <div *ngFor="let serviceTag of viewServiceTags" class="tag-wrapper">
        <div class="tag-name">
          {{serviceTag.tag_name}}
        </div>
        <div class="tag-value">
          {{serviceTag.tag_value}}
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>