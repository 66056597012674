import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {EmailFormRecipient} from "../../../../shared/ng-models/EmailFormRecipient.interface";
import {Link} from "../../../../shared/ng-models/Link.interface";
import {MiService} from "../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../libs/api/tenant/tenantMiService.service";
import {Contact} from "../../../../shared/models/Contact";
import * as userReducer from "../../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import {Store} from "@ngrx/store";
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';

@Component({
  styleUrls: ['./account-representatitve-email.component.scss'],
  templateUrl: './account-representatitve-email.component.html'
})

export class AccountRepEmailComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true
    }
  ];
  cancelUrl: string;

  error: string;
  headerLinks: Link[];
  loaded: boolean = false;
  service: MiService;
  tenantContacts: EmailFormRecipient[];
  userData: any;
  attachment: any[];
  tenantData: any;
  title: string = "Account Representitive";
  type: string;
  contact: Contact;
  newAccountId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceService: TenantMiServiceService,
    private store: Store<userReducer.State>,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    // Find the current service ID:
    let serviceId = this.activatedRoute.snapshot.params['display_id'];
    this.type = this.activatedRoute.snapshot.params['type'];
    // Update breadcrumbs:
    let newBreadcrumbs = [
      {
        url: `/services/${serviceId}`,
        label: serviceId,
        internal: true
      },
      {
        url: `/services/${serviceId}/account-rep`,
        label: 'Account Representitive',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];

    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState);
    const serviceData = this.serviceService.getMiService(serviceId).toPromise()
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

    combineLatest(userStateData, serviceData, tenantStateData)
      .subscribe((data)=>{
        this.userData = data[0].secureUser.getSafeData();
        this.service = data[1];
        this.tenantData = data[2].tenantData;
        this.loaded= true;
      },
        e => this.error = e);
  }

  next(){
    if(this.type==="request-a-quote-miso"){
      this.router.navigate([`../../request-a-quote/miso-quote`], {relativeTo: this.activatedRoute});
    }else{
      this.router.navigate([`../../${this.type}`], {relativeTo: this.activatedRoute});
    }
  }
}
