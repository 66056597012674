import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { BillingService } from "src/libs/api2/billing/billing.service";
import { Link } from "src/shared/ng-models/Link.interface";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as userReducer from '../../../../libs/user-store/state/reducers';
import * as tenantBillingAccountReducers from '../../../../shared/state/tenant-billing-account/reducers';
import { map, takeUntil } from "rxjs/operators";
import { BillingItem, TenantBillingAccount, TenantCurrentBillingCycle } from "src/shared/models/Billing";
import { ModalService } from "@independer/ng-modal";
import { BillingBreakdownModalComponent } from "../components/modals/billing-breakdown-modal/billing-breakdown-modal.component";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import FeDateUtil from "src/shared/utils/FeDate.util";

interface AccountPlanDetails {
  planName?: string;
  billingAmount?: number;
  billingInterval?: string;
  inTrialPeriod?: boolean;
  basePlanCost?: number;
  additionalTemplatesCost?: number;
  additionalTemplatesQuantity?: number;
  additionalServicesCost?: number;
  additionalServicesQuantity?: number;
  templateSetupFee?: number;
  templatesIncluded?: number;
  servicesIncluded?: number;
}

@Component({
  templateUrl: './plan-and-usage-details.component.html',
  styleUrls: ['./plan-and-usage-details.component.scss']
})

export class PlanAndUsageDetailsComponent extends AbstractPageComponent implements OnInit {
  breadcrumbs: Link[];
  headerLabel: string = "";
  loaded: boolean = false;
  tenantBillingAccount: TenantBillingAccount;
  stripeSubscription: any;
  accountPlanDetails: AccountPlanDetails = {};
  tenantCurrentBillingCycle: TenantCurrentBillingCycle;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<userReducer.State>,
    private billingService: BillingService,
    private modalService: ModalService
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.setLoading();

    this.activatedRoute.data.subscribe((data: any) => {
      console.log("data", data);
      this.headerLabel = data.headerLabel;
    });

    this.activatedRoute
      .params
      .subscribe((queryParams: Params) => {

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Billing',
            url: '/billing',
            internal: true
          },
          {
            label: this.headerLabel,
            url: './',
            internal: true
          }
        ];
    });

    this.store.pipe(
      select(tenantBillingAccountReducers.getTenantBillingAccountData),
      takeUntil(this.destroy$),
      map((billingAccountData) => {        
        this.tenantBillingAccount = billingAccountData.result;
        console.log("tenantBillingAccount", this.tenantBillingAccount);
        // this.getBusinessUnit(appData.tenantData.domain);
        //this.loaded = true;
        this.loadData();
        //this.getTenantCurrency();
        //this.getStripeCustomer();
        //this.getStripeSubscription();
      })
      ).subscribe();
  }

  setLoading(): void {
    this.loaded = false;
  }

  async loadData(): Promise<void> {
    await this.getStripeSubscription();
    await this.getTenantCurrentBillingCycle();
    console.log("accountPlanDetails", this.accountPlanDetails);
    this.loaded = true;
  }

  async getStripeSubscription(): Promise<void> {
    console.log("stripeSubscriptionId", this.tenantBillingAccount.stripeSubscriptionId);
    let res = await this.billingService.getSubscription(this.tenantBillingAccount.stripeSubscriptionId).toPromise();
    console.log("getSubscription", res);
    this.stripeSubscription = res;
    await this.calculateTotalBillingAmount();
    await this.getStripeProducts();
  }

  async getStripeProducts(): Promise<void> {
    let planCode;
    await this.stripeSubscription.items.data.map(async subscriptionItem => {
      let res = await this.billingService.getProduct(subscriptionItem.plan.product).toPromise();
      subscriptionItem.product = res;
      if(subscriptionItem.product.metadata.plan_type == "base") {
        planCode = subscriptionItem.product.metadata.plan_code;

        let templateSetupProduct = await this.getTemplateSetupProduct(planCode);
        console.log("templateSetupProduct", templateSetupProduct);

        let allPrices = await this.getPrices();
        console.log("allPrices", allPrices);

        allPrices.data.map(price => {
          if(price.id == templateSetupProduct.defaultPrice) {
            this.accountPlanDetails.templateSetupFee = price.unit_amount / 100;
          }
        });

        this.accountPlanDetails.planName = subscriptionItem.product.name;
        this.accountPlanDetails.billingInterval = subscriptionItem.plan.interval;
        this.accountPlanDetails.templatesIncluded = subscriptionItem.product.metadata.templates;
        this.accountPlanDetails.servicesIncluded = subscriptionItem.product.metadata.services;
        this.accountPlanDetails.basePlanCost = subscriptionItem.price.unit_amount / 100;
      }
      else if(subscriptionItem.product.metadata.plan_type == "additional_template") {
        this.accountPlanDetails.additionalTemplatesQuantity = subscriptionItem.product.metadata.additional_quantity;
        this.accountPlanDetails.additionalTemplatesCost = subscriptionItem.price.unit_amount / 100;
      }
      else if(subscriptionItem.product.metadata.plan_type == "additional_service") {
        this.accountPlanDetails.additionalServicesQuantity = subscriptionItem.product.metadata.additional_quantity;
        this.accountPlanDetails.additionalServicesCost = subscriptionItem.price.unit_amount / 100;
      }
    });

    

    console.log("stripeSubscription", this.stripeSubscription);
  }

  async calculateTotalBillingAmount(): Promise<void> {
    let total = 0;
    this.stripeSubscription.items.data.map(subscriptionItem => {
      total += (subscriptionItem.plan.amount * subscriptionItem.quantity);
    });
    this.accountPlanDetails.billingAmount = total/100;
  }

  async getTemplateSetupProduct(planCode: string): Promise<any> {
    let res = await this.billingService.getTemplateSetupProduct(planCode).toPromise();
    return res;
  }

  async getPrices(): Promise<any> {
    let res = await this.billingService.getPrices().toPromise();
    return res;
  }

  showBillingBreakdown(): void {
    let billingItems: BillingItem[] = [];

    this.stripeSubscription.items.data.map(subscriptionItem => {
      if(subscriptionItem.product.metadata.plan_type == "base") {
        billingItems.push({
          itemName: "Base Plan",
          itemPrice: subscriptionItem.price.unit_amount / 100,
          itemQuantity: subscriptionItem.quantity
        });
      }
      else if(subscriptionItem.product.metadata.plan_type == "additional_template") {
        billingItems.push({
          itemName: "Additional Templates",
          itemPrice: subscriptionItem.price.unit_amount / 100,
          itemQuantity: subscriptionItem.quantity
        });
      }
      else if(subscriptionItem.product.metadata.plan_type == "additional_service") {
        billingItems.push({
          itemName: "Additional Services",
          itemPrice: subscriptionItem.price.unit_amount / 100,
          itemQuantity: subscriptionItem.quantity
        });
      }
    });

    const modalRef = this.modalService.open(BillingBreakdownModalComponent, m => {
      m.billingItems = billingItems;
      m.totalAmount = this.accountPlanDetails.billingAmount;
    });

    modalRef.closed.subscribe(({ reason, result }) => {
      console.log("result", result);
    });
  }

  async getTenantCurrentBillingCycle(): Promise<void> {
    let res = await this.billingService.getTenantCurrentBillingCycle(FrontEndSettingsUtil.getTenantCode()).toPromise();
    console.log("getTenantCurrentBillingCycle", res);
    this.tenantCurrentBillingCycle = res;
  }

  getHumanReadableTimestamp(timeStamp: string): string {
    if(timeStamp == null) {
      return "";
    }
    let date = new Date(timeStamp);
    //return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    //return FeDateUtil.toFormat(date, "LLL dd yyyy HH:mm:ss 'GMT' Z");
    return FeDateUtil.toFormat(date, "LLL dd, yyyy h:mm a");
  }
}