<div class="tab-title-header">
  <h2>{{title}}</h2>

<ng-container *ngIf="editable">
  <a class="edit-link button dark-theme" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>
</ng-container>
</div>
<mi-server-error [error]="error"></mi-server-error>

<ng-container>
  <div class="open" *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
      <div *ngIf="formReady">
        <mi-service-financial-form [detaileditmode]="true" [financial]="financialDetails" [tenantCurrencyId]="tenantCurrencyId"
          (updateCompleted)="onUpdateService($event)"
          (canceled)="onToggleExpand()">
        </mi-service-financial-form>
      </div>
    </mi-opacity-loader>
  </div>

  <ng-template #static>
    <dl class="section-details card-1">
      <div>
        <dt>{{'TENANT.SERVICE_DETAILS.QUANTITY' | translate}}</dt>
        <dd>
          {{serviceDetails.service_data.quantity | number}}
        </dd>
      </div>
      <div>
        <dt>{{ 'TENANT.SERVICE_DETAILS.RECURRING_COSTS_LABEL' | translate | translateSelector:
          serviceDetails.service_data.billing_frequency }}</dt>
        <dd>{{ serviceDetails.service_data.unit_recurring_cost |
          currency:(serviceDetails.service_data.currency||'USD'):'symbol':'1.2-2'}}
        </dd>
      </div>
      <div>
        <dt>{{ 'TENANT.SERVICE_DETAILS.TOTAL_NON_RECURRING_COSTS_LABEL' | translate }}</dt>
        <dd>{{ serviceDetails.service_data.total_non_recurring_cost |
          currency:(serviceDetails.service_data.currency||'USD'):'symbol':'1.2-2'}}
        </dd>
        </div>
        <div>
        <dt>{{'TENANT.SERVICE_DETAILS.BILLING_FREQUENCY_LABEL' | translate}}</dt>
        <dd>
          {{('MONTHS_QTY'
          | translate:{count: serviceDetails.service_data.billing_frequency}
          | translateSelector: serviceDetails.service_data.billing_frequency) || NO_DATA }}
        </dd>
      </div>
      <div>
        <dt>{{'TENANT.SERVICE_DETAILS.CALCULATED_MRC' | translate}}</dt>
        <dd>
          {{serviceDetails.service_data.cmrc | tenantcurrency:'1.2-2'}}
        </dd>
      </div>
      <div>
        <dt>{{'TENANT.SERVICE_DETAILS.ACTIVE_USAGE' | translate}}</dt>
        <dd>
          {{serviceDetails.service_data.active_usage}}
        </dd>
      </div>
      <div>
        <dt>{{'TENANT.SERVICE_DETAILS.PRICE_INCREASE_PERCENTAGE' | translate}}</dt>
        <dd>
          {{serviceDetails.service_data.price_increase_percentage}}
        </dd>
      </div>
      
    </dl>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>