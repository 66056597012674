import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { BillingService } from "src/libs/api2/billing/billing.service";
import { Link } from "src/shared/ng-models/Link.interface";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as userReducer from '../../../../libs/user-store/state/reducers';
import * as tenantBillingAccountReducers from '../../../../shared/state/tenant-billing-account/reducers';
import { map, takeUntil } from "rxjs/operators";
import { TenantBillingAccount, UpdateCustomerRequest } from "src/shared/models/Billing";
import { ModalService } from "@independer/ng-modal";
import { ChangePaymentMethodModalComponent } from "../components/modals/change-payment-method-modal/change-payment-method-modal.component";
import { ToastrService } from "ngx-toastr";
import { AddPaymentMethodModalComponent } from "../components/modals/add-payment-method-modal/add-payment-method-modal.component";
import { ChangeInvoiceEmailModalComponent } from "../components/modals/change-invoice-email-modal/change-invoice-email-modal.component";

@Component({
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss']
})

export class ManagePaymentMethodsComponent extends AbstractPageComponent implements OnInit {
  breadcrumbs: Link[];
  headerLabel: string = "";
  tenantBillingAccount: TenantBillingAccount;
  stripeCustomer: any;
  stripeCustomerPaymentMethods: any;
  paymentMethodChanged: boolean = false;
  defaultPaymentMethodId: string;
  selectedPaymentMethodId: string;
  loaded: boolean = false;
  changeEmail: boolean = false;
  newInvoiceEmail: string = "";
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  emailErrorText = "";
  emailValid: boolean = false;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<userReducer.State>,
    private billingService: BillingService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setLoading();
    this.activatedRoute.data.subscribe((data: any) => {
      console.log("data", data);
      this.headerLabel = data.headerLabel;
    });

    this.activatedRoute
      .params
      .subscribe((queryParams: Params) => {

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Billing',
            url: '/billing',
            internal: true
          },
          {
            label: this.headerLabel,
            url: './',
            internal: true
          }
        ];
    });

    this.store.pipe(
      select(tenantBillingAccountReducers.getTenantBillingAccountData),
      takeUntil(this.destroy$),
      map((billingAccountData) => {        
        this.tenantBillingAccount = billingAccountData.result;
        console.log("tenantBillingAccount", this.tenantBillingAccount);
        // this.getBusinessUnit(appData.tenantData.domain);
        //this.loaded = true;
        this.loadData();
        //this.getTenantCurrency();
        //this.getStripeCustomer();
        //this.getStripeSubscription();
      })
      ).subscribe();
  }

  async loadData(): Promise<void> {
    this.stripeCustomer = await this.getStripeCustomer();
    console.log("stripeCustomer", this.stripeCustomer);

    this.selectedPaymentMethodId = this.stripeCustomer.invoice_settings.default_payment_method;
    this.defaultPaymentMethodId = this.selectedPaymentMethodId;

    this.stripeCustomerPaymentMethods = await this.getstripeCustomerPaymentMethods();
    console.log("stripeCustomerPaymentMethods", this.stripeCustomerPaymentMethods);

    this.setLoaded();
  }

  async getStripeCustomer(): Promise<any> {
    console.log("stripeCustomerId", this.tenantBillingAccount.stripeCustomerId);
    let res = await this.billingService.getCustomer(this.tenantBillingAccount.stripeCustomerId).toPromise();
    return res;
  }

  async getstripeCustomerPaymentMethods(): Promise<any> {
    console.log("stripeCustomerId", this.tenantBillingAccount.stripeCustomerId);
    let res = await this.billingService.getCustomerPaymentMethods(this.tenantBillingAccount.stripeCustomerId).toPromise();
    return res;
  }

  selectCard($event): void {
    console.log("selectCard", $event.target.value);
    this.selectedPaymentMethodId = $event.target.value;
    if(this.selectedPaymentMethodId != this.defaultPaymentMethodId) {
      this.paymentMethodChanged = true;
    }
    else {
      this.paymentMethodChanged = false;
    }    
  }

  addNewCardPrompt(): void {
    let defaultLocked = (this.stripeCustomer.invoice_settings.default_payment_method == null) ? true : false;
    const modalRef = this.modalService.open(AddPaymentMethodModalComponent, m => {
      m.addText = "Add Card";
      m.title = "Add a New Card";
      m.defaultLocked = defaultLocked;
    });

    modalRef.closed.subscribe(({ reason, result }) => {
      console.log("result", result);

      if(result && result[0].result == true) {
        this.setLoading();
        this.addPaymentMethod(result[0].paymentMethodId, result[0].setNewPaymentAsDefault, defaultLocked);
      }
    });
  }

  async addPaymentMethod(paymentMethodId: string, setNewPaymentAsDefault: boolean, defaultLocked: boolean): Promise<void> {
    let updateCustomerRequest: UpdateCustomerRequest = {
      customerId: this.stripeCustomer.id,
      paymentMethodId: paymentMethodId
    };
    let res = await this.billingService.attachCustomerNewPaymentMethod(updateCustomerRequest).toPromise();

    this.toastr.success("Card added successfully");

    if(setNewPaymentAsDefault || defaultLocked) {
      this.changePaymentMethod(paymentMethodId);
    }
    else {
      await this.loadData();
      this.setLoaded();
    }    
  }

  showInvoiceEmailChange(): void {
    this.changeEmail = true;
  }

  cancelInvoiceEmailChange(): void {
    this.changeEmail = false;
  }

  changeInvoiceEmailPrompt(): void {
    const modalRef = this.modalService.open(ChangeInvoiceEmailModalComponent, m => {
      m.newInvoiceEmail = this.newInvoiceEmail;
    });

    modalRef.closed.subscribe(({ reason, result }) => {
      console.log("result", result);

      if(result && result[0].result == true) {
        this.setLoading();
        this.changeInvoiceEmail();
      }
    });
  }

  async changeInvoiceEmail(): Promise<void> {
    let updateCustomerRequest: UpdateCustomerRequest = {
      customerId: this.stripeCustomer.id,
      invoiceEmail: this.newInvoiceEmail
    };

    try {
      let res = await this.billingService.changeCustomerInvoiceEmail(updateCustomerRequest).toPromise();
    
      this.toastr.success("Invoice email changed successfully");
      this.loadData();
    }
    catch(e) {
      this.toastr.error("Failed");
    }    
  }

  changePaymentMethodPrompt(): void {
    const modalRef = this.modalService.open(ChangePaymentMethodModalComponent, m => {
    });

    modalRef.closed.subscribe(({ reason, result }) => {
      console.log("result", result);

      if(result && result[0].result == true) {
        this.setLoading();
        this.changePaymentMethod();
      }
    });
  }

  async changePaymentMethod(paymentMethodId: string = null): Promise<void> {
    let updateCustomerRequest: UpdateCustomerRequest = {
      customerId: this.stripeCustomer.id,
      paymentMethodId: paymentMethodId? paymentMethodId : this.selectedPaymentMethodId
    };

    try {
      let res = await this.billingService.changeCustomerDefaultPaymentMethod(updateCustomerRequest).toPromise();
    
      this.toastr.success("Payment method changed successfully");
      this.loadData();
    }
    catch(e) {
      this.toastr.error("Failed");
    }
  }

  setLoading(): void {
    this.loaded = false;
  }

  setLoaded(): void {
    this.changeEmail = false;
    this.loaded = true;
    this.newInvoiceEmail = "";
  }

  validateEmail($event): void {
    console.log("email", this.newInvoiceEmail);
    console.log("$event", $event);
    //let labelId = "emailLabel";
    //let errorLabelId = "emailErrorLabel";
    if(this.newInvoiceEmail.length == 0) {
      this.emailValid = false;
      this.emailErrorText = "This field is required"
      //document.getElementById(labelId).classList.add("error-label-top");
      //document.getElementById(errorLabelId).classList.add("error-label-bottom");
      //document.getElementById(errorLabelId).style.display = "block";
    }
    else if(!this.emailPattern.test(this.newInvoiceEmail)) {
      this.emailValid = false;
      this.emailErrorText = "Please enter a valid email"
      //document.getElementById(labelId).classList.add("error-label-top");
      //document.getElementById(errorLabelId).classList.add("error-label-bottom");
      //document.getElementById(errorLabelId).style.display = "block";
    }
    else {
      this.emailValid = true;
      this.emailErrorText = "";
      //document.getElementById(labelId).classList.remove("error-label-top");
      //document.getElementById(errorLabelId).classList.remove("error-label-bottom");
      //document.getElementById(errorLabelId).style.display = "none";
    }
  }
}