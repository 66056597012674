<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">
    <div>
      <mi-manage-service-contacts
        [hideCancel]="true"
        [account_id]="orderState.account_id"
        [vendorName]="orderState.vendor.vendor_name"
        [custom_data]="orderState.service.custom_data"
        (serviceContactsComplete)="onContactsComplete($event)">
      </mi-manage-service-contacts>
    </div>
  </ng-container>

  <ng-template #static>

    <div class="service-contacts">
        <mi-service-contacts-static
        [account_id]="orderState.account_id"
        [custom_data]="orderState.service.custom_data">
      </mi-service-contacts-static>
    </div>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

