import { Component, OnInit } from '@angular/core';
import { Link } from 'src/shared/ng-models/Link.interface';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { ITenantReport } from 'src/libs/api/settings/settings.service';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import FeDateUtil from 'src/shared/utils/FeDate.util';

@Component({
  selector: 'mi-cost-change-analysis',
  styles: [
    `
      .iframe-container {
        width: 100%;
        border: 0.1px solid lightgray;
        height: 100vh;
        padding: 0px;
        border-radius: 5px;
      }
    `
  ],
  template: `
    <div *ngIf="metaBaseDashboardId; else loading">
      <mi-page-header 
        title="Cost Change Analysis" 
        [breadcrumbs]="breadCrumbs" 
        [links]="headerLinks">
      </mi-page-header>
      <div class="iframe-container mt-3">
      <mi-opacity-loader [ready]="!showLoader">
          <iframe class="iframe-container" id="metabase-iframe-{{metaBaseDashboardId}}" src="" title="metabase"></iframe>
      </mi-opacity-loader>
    </div>
    </div>
    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
  `
})
export class CostChangeAnalysisComponent implements OnInit {
  metaBaseDashboardId: number;
  tenantId: string = null;
  showLoader: boolean = true;

  breadCrumbs: Link[] = [
    { label: "Dashboard", url: "/", internal: true },
    { label: "Cost Change Analysis", url: "/cost-change-analysis", internal: true }
  ];

  frontEndSettings: ITenantReport[] = FrontEndSettingsUtil.get().TENANT_REPORTS || [];
  headerLinks: Link[] = [];

  constructor(
    private reportV2Service: ReportV2Service
  ) { }

  ngOnInit(): void {

    const report = this.frontEndSettings.find(report => report.reportName === "Cost Change Analysis");

    if (report) {
      this.metaBaseDashboardId = report.metabaseDashboardId;
      this.tenantId = report.tenantId;
    } else {
      console.warn("Report with name 'Cost Change Analysis' not found.");
    }

    this.loadData();
  }

  loadData(): void {
    this.showLoader = true;

    this.reportV2Service.getCostChangeAnalysisMetaBaseResponse(this.tenantId, this.metaBaseDashboardId).then((res: string) => {
      const cleanUrl = res.split('#')[0];
      const bill_from = FeDateUtil.getCostChangeAnalysisDateFormate(2);
      const bill_to = FeDateUtil.getCostChangeAnalysisDateFormate(1);
      const modifiedUrl = `${cleanUrl}?match_status=1.%20Charges%20to%20Compare&bill_from=${encodeURIComponent(bill_from)}&bill_to=${encodeURIComponent(bill_to)}#bordered&titled=false`;

      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).setAttribute("src", modifiedUrl);
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).addEventListener('load', () => {
        this.showLoader = false;
      });
    })
  }

}
