import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccountPlan } from "src/shared/models/TenantCreationV2";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";

@Component({
  selector: 'mi-account-plan-card-v2',
  styleUrls: ['./account-plan-card-v2.component.scss'],
  templateUrl: './account-plan-card-v2.component.html'
})

export class AccountPlanCardV2Component extends AbstractPageComponent implements OnInit {
  
  @Input() accountPlan: AccountPlan;
  @Input() set planBillingTime(value: string) {
    console.log("planBillingTime", value);
    this.billingPlanModeSelection = value;
    setTimeout(() => {
      //this.setBillingDisplays();
    }, 100);    
  }
  @Input() viewMode: string = "select";
  @Output() selectedPlanCode = new EventEmitter<any>();
  billingPlanModeSelection: string;
  billingDisplayNumber: number;
  //billingDisplayText: string;

  detailView: boolean = false;
  
  ngOnInit(): void {
    console.log("accountPlan", this.accountPlan);
  }

  /*
  setBillingDisplays(): void {
    console.log("setBillingDisplays");
    if(this.billingPlanModeSelection == 1) {
      //this.billingDisplayNumber = this.accountPlan.planPricePerMonth;
      this.billingDisplayText = "month";
    }
    else if(this.billingPlanModeSelection == 12) {
      //this.billingDisplayNumber = this.accountPlan.planPricePerYear;
      this.billingDisplayText = "year";
    }
  }
  */

  selectPlan(): void {
    this.selectedPlanCode.emit(this.accountPlan.planCode);
  }
}