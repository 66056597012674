<div class="card-1">
<mi-section-title title="This will supersede any “vendor-assigned” end of term action (EOTA) for associated services">
</mi-section-title>

<div *ngIf="isOpen; else static">
  <mi-find-or-create-service-term
    [serviceTermId]="accountId.service_term_id"
    [serviceTerm]="serviceTerm"
    (selectedServiceTerm)="selectedServiceTerm($event)"
    (serviceTermRemoved)="serviceTermRemoved($event)"
    (modeChange)="modeChange($event)"
    (canceled)="close()"
  ></mi-find-or-create-service-term>

  <!-- <div class="button-container">
      <button
        mi-button
        buttonTheme="light-theme"
        (click)="isOpen=false">
        {{'GLOBAL.BUTTON_CANCEL' | translate}}
      </button>
      <button 
        mi-button
        (click)="updateAccountTerm()"
        disabled="canUpdate"
        buttonTheme="dark-theme">
        Save
      </button>
  </div> -->
</div>
</div>


<ng-template #static>
  <div class="section-details">
    <ng-container>
      <div>
        <dt>
          MISO ID
        </dt>
        <dd>
          <ng-container *ngIf="serviceTerm && serviceTerm.display_id; else noTermsAndConditionsDoc">
            <mi-id-link [id]="serviceTerm.id" [display_id]="serviceTerm.display_id"></mi-id-link>
          </ng-container>
          <ng-template #noTermsAndConditionsDoc>
            {{NO_DATA}}
          </ng-template>
        </dd>
      </div>
      <div>
        <dt>
          End of Term Action
        </dt>
        <dd>
          <ng-container *ngIf="serviceTerm; else noEot">
            {{'END_OF_TERM.' + serviceTerm.event_type | translate}}
          </ng-container>
          <ng-template #noEot>
            {{NO_DATA}}
          </ng-template>
        </dd>
      </div>
      <div>
        <dt>
          Auto-Renew Period
        </dt>
        <dd>
          <ng-container *ngIf="serviceTerm && serviceTerm.auto_renew_length; else noArp">
            {{('MONTHS_QTY'
            | translate:{count: serviceTerm.auto_renew_length}
            | translateSelector: serviceTerm.auto_renew_length ) || NO_DATA}}
          </ng-container>
          <ng-template #noArp>
            {{NO_DATA}}
          </ng-template>
        </dd>
      </div>
      <div>
        <dt>{{ 'DAYS_TO_NOTIFY' | translate}}</dt>
        <dd>
          <ng-container *ngIf="serviceTerm; else noDtn">
            {{serviceTerm.days_to_notify|| 0}}
          </ng-container>
          <ng-template #noDtn>
            {{NO_DATA}}
          </ng-template>
        </dd>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- <ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
  <span class="loadingMsg">
    Updating all associated services (Process may take some time).
  </span>
</ng-template> -->