import {HostListener} from '@angular/core';
import {Directive} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[miNoteLink]'
})

export class NoteListDirective {
  constructor(
    private router: Router
  ) {
  }

  @HostListener('click', ['$event']) onClick(e) {

    if(e.target && !e.target.href){
      // There's no link to intercept.
      // Carry on.
      return;
    }

    const origin: string = window.location.origin;
    const offset = e.target.href.indexOf(origin);

    // if this is not the same URL, then offset === -1
    if (offset === 0) {
      e.preventDefault();
      // Likely an internal link; strip off the clutter and try a router nav:
      this.router.navigateByUrl(e.target.href.substr(origin.length));
    }

  }
}
