
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {CustomField} from "../../../shared/models/CustomField";
import {TenantCustomFieldService} from "../../../libs/api/tenant/tenantCustomFields.service";
import {CustomFieldProperty} from "../../../shared/models/CustomFieldProperty";
import {dateStringValidator} from '../../../shared/shared.validators';

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-custom-field-service-form',
  templateUrl: './custom-field-service-form.component.html',
  styleUrls: ['./custom-field-service-form.component.scss']
})

export class CustomFieldServiceFormComponent extends AbstractPageComponent implements OnInit {

  @Output() customFieldsComplete: EventEmitter<CustomFieldProperty[]> = new EventEmitter<CustomFieldProperty[]>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  @Input() submitCTA:string = 'Apply';
  @Input() cancelCTA:string = 'Cancel';
  @Input() hideSubmitIfNoCustomFields: boolean = false;

  @Input('currentFieldPropertiesSet')
  set currentFieldPropertiesSet(val) {
    if (val) {
      this.currentFieldProperties = JSON.parse(JSON.stringify(val));
    }
  }
  currentFieldProperties: CustomFieldProperty[] = [];

  formGroup: FormGroup = null;

  loading: boolean = true;
  loadError: string = '';
  allCustomFieldsProperties: CustomFieldProperty[];
  allNonCustomFieldsProperties: CustomFieldProperty[];

  contactError: string = '';

  constructor(private formBuilder: FormBuilder,
              private tenantCustomFieldsService: TenantCustomFieldService) {
    super();
  }

  ngOnInit() {
    this.tenantCustomFieldsService.listCustomFields()
      .subscribe(
        (allCustomFields) => this.startForm(allCustomFields),
        (e) => this.loadError = e
      );
  }


  startForm(allCustomFields: CustomField[]): void {
    //log('yeah we all the CFs', allCustomFields);
//debugger;

    let formData: any = {};

    this.allCustomFieldsProperties = allCustomFields.map(item => {
      let fieldProperty: CustomFieldProperty = this.currentFieldProperties.find(
        (miServiceDataItem: CustomFieldProperty): boolean => {
          return miServiceDataItem.custom_field._id === item._id;
        }
      );

      if (!fieldProperty) {
        fieldProperty = {
          value: '',
          custom_field: item
        }
      }
      ;

      return fieldProperty;
    });

    this.allNonCustomFieldsProperties = this.allCustomFieldsProperties.filter(item => item.custom_field.type != "CONTACT");

    this.allNonCustomFieldsProperties.forEach((customField, index) => {
      if(customField.custom_field.type === "DATE"){
        formData[this.fieldName(index)] = [customField.value, Validators.compose([dateStringValidator])];
      }
      else{
        formData[this.fieldName(index)] = customField.value;
      }
    });

    this.formGroup = this.formBuilder.group(formData);

    this.loading = false;
  }


  onFormSubmit(formValue): void {
    this.onCustomFieldsComplete(formValue);
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  onDateChange(newDate, index) {
    this.formGroup.get(this.fieldName(index)).setValue(newDate);
    this.formGroup.updateValueAndValidity();
  }

  fieldName(index: number = 0): string {
    return 'custom_field_' + index;
  }

  onCustomFieldsComplete(data: any): void {
    const newCustomFieldProps: CustomFieldProperty[] = [...this.allCustomFieldsProperties];

    Object.keys(data).forEach(key => {
      const index = key.substr(key.lastIndexOf('_') + 1);
      let cf: CustomFieldProperty = this.allNonCustomFieldsProperties[index];
      if(cf){
        let cfp = newCustomFieldProps.find(c => c.custom_field._id == cf.custom_field._id);
        if(cfp){
          cfp['value'] = this.formGroup.get(key).value as CustomFieldProperty;
        }
      }
    });
    this.customFieldsComplete.emit(newCustomFieldProps);
  }
}

