import { Component, Input, OnInit } from "@angular/core";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";

@Component({
  selector: 'mi-info-tooltip',
  styleUrls: ['./info-tooltip.component.scss'],
  templateUrl: './info-tooltip.component.html'
})

export class InfoTooltipComponent extends AbstractPageComponent implements OnInit {

  @Input() toolTipText: string = '';
  @Input() toolTipSize: string = 'normal';
  @Input() toolTipId: string;

  ngOnInit() {
    
  }

  showTooltip() {
    document.getElementById(this.toolTipId).style.visibility = "visible";
  }

  hideTooltip() {
    document.getElementById(this.toolTipId).style.visibility = "hidden";
  }
}