
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {emailValidator} from "../../../shared/shared.validators";
import {Store} from "@ngrx/store";
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import {Vendor} from "../../../shared/models/Vendor";
import {FindOrCreateVendorComponentSteps} from "../../../shared/components/find-or-create-vendor/find-or-create-vendor.component";
import { RecentContactsStorageService } from '../../../shared/services/recent-contacts-storage.service';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { takeUntil } from 'rxjs/operators';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';
import { CONTACT_STATUS_ACTIVE, CONTACT_STATUS_INACTIVE } from 'src/common/utils/sharedConstants';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

const log = createLogger(LOG_LEVELS.CORE);

export enum CreateEditContactsMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
};

@Component({
  selector: 'mi-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent extends AbstractPageComponent implements OnInit {

  @Input() _editId: string;
  @Input() activeLocked: boolean = false;
  @Output() contactComplete: EventEmitter<VendorContact> = new EventEmitter<VendorContact>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  mode: CreateEditContactsMode;
  form: FormGroup = null;
  loading: boolean = false;
  loadError: string = '';
  submitError: string = '';
  ctaLabel: string = '';
  vendorState: number = -1;
  CONTACT_STATUSES: string[];
  CONTACT_ROLES: string[];
  showButtonContainer: boolean = true;
  userEmails: string[] = [];
  owner: string;

  constructor(private formBuilder: FormBuilder,
    private contactV2Service:ContactV2Service,
    private tenantVendors: TenantVendors,
    private store: Store<appDataReducer.State>,
    private toastr: ToastrService,
    private router: Router,
    private recentContactsStorageService: RecentContactsStorageService) {
    super();
  }

  ngOnInit() {
    this.store.select(appDataReducer.getAppData).takeUntil(this.destroy$)
      .subscribe(state => {
        this.CONTACT_STATUSES = state.CONSTANTS.CONTACT_STATUSES;
        this.CONTACT_ROLES = state.CONSTANTS.CONTACT_ROLES;
        this.getTenantUserEmails(state.tenantData.domain);
        this.owner = state.tenantData.domain;
      });
    if (!this._editId) {
      this.startAsCreateMode();
    }
  }

  @Input('editId')
  set editId(val) {
    if (val) {
      this.startAsEditMode(<string>val);
    }
  }
  getTenantUserEmails(tenant_id){
    this.contactV2Service.getAllTenantUsers(tenant_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(users => {
        this.userEmails = users.map(user => user.email);
      })
  }

  noTenantUserEmailValidator(input: FormControl) {
    const isValid = this.userEmails.indexOf(input.value)<0;
    if(isValid){
      this.loadError = null;
    }
    return isValid ? null : {noTenantUserEmailValidator: true};
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  startAsCreateMode(): void {
    this.mode = CreateEditContactsMode.CREATE;
    this.ctaLabel = 'Create Contact';
    this.createForm();
  }

  startAsEditMode(contactId: string): void {
    this.mode = CreateEditContactsMode.EDIT;
    this.ctaLabel = 'Update Contact';
    this._editId = contactId;
    this.loading = true;
    this.loadError = '';
    this.contactV2Service.getContactById(contactId)
      .toPromise()
      .then((contact: VendorContact) => {
        if(this.userEmails.indexOf(contact.email)>=0){
          this.loadError = "The same e-mail could not be used as Vendor/User contacts. This contact is not allow to edit until the email is changed."
        }
        //this.tenantVendors.getVendorById(contact.tenant_vendor_id)
        this.tenantVendors.getVendorById(contact.vendor_id)
        .toPromise()
        .then((vendor: Vendor) => {
          contact.vendor = vendor;
          contact.role=contact.function;
          contact.status= contact.is_active ? CONTACT_STATUS_ACTIVE : CONTACT_STATUS_INACTIVE;
          this.createForm(contact);
        });
      })
      .catch(error => {
        this.loadError = error
      });
  }

  onVendorCreateStepChanged(step: FindOrCreateVendorComponentSteps) {
    this.vendorState = step;
    if (step) {
      this.showButtonContainer = true;
    } else {
      this.showButtonContainer = false;
    }
  }

  onVendorSelected(vendor: Vendor) {
    // log('contact has a vendor', vendor);
    this.form.setValue({
      ...this.form.value,
      vendor: vendor
    });
    this.showButtonContainer = true;
  }

  onVendorUnselected(unSelected: boolean) {
    if (unSelected) {
      this.onVendorSelected(null);
    }
  }


  createForm(contact: any = {}): void {

    let {
      first_name,
      last_name,
      status,
      email,
      role,
      vendor,
      mobile_phone,
      office_phone,
      fax
    } = contact;

    status = status || this.CONTACT_STATUSES[0];
    role = role || '';

    this.form = this.formBuilder.group({
      first_name: [first_name, Validators.required],
      last_name: [last_name, Validators.required],
      status: [status, Validators.required],
      vendor: [vendor, Validators.required],
      role: [role, Validators.required],
      email: [email, Validators.compose([Validators.required, emailValidator, this.noTenantUserEmailValidator.bind(this)])], 
      mobile_phone: [mobile_phone],
      office_phone: [office_phone]
    });

    this.loading = false;
  }

  onFormSubmit(formValue, $event): void {
    this.loading = true;
    $event.preventDefault();
    
    formValue={
      ...formValue,
      tenant_id: this.owner,
      //tenant_vendor_id: formValue.vendor._id || formValue.vendor.id,
      tenant_vendor_id: formValue.vendor.tenant_vendor_id,
      vendor_id: formValue.vendor.id,
      is_active: formValue.status == CONTACT_STATUS_ACTIVE,
      function: formValue.role
    };
    /*
    if (formValue.vendor_id == formValue.tenant_vendor_id) {
      formValue.vendor_id = formValue.vendor.vendor.id
    }
    */
    if (this.mode === CreateEditContactsMode.CREATE) {
      this.createContact(formValue, formValue.vendor);
    } else if (this.mode = CreateEditContactsMode.EDIT) {
      this.updateContact(formValue);
    }
    $event.stopPropagation();
  }

  createContact(data: any = {}, vendor): void {
    this.contactV2Service.saveContact(data)
      .toPromise()
      .then(contact => {
        contact._id = contact.id;
        contact.vendor = vendor;
        contact.role = contact.function;
        this.toastr.success(`${data.first_name} ${data.last_name} created successfully`);
        // this.updateRecentContacts(contact);

        this.loading = false;
        this.router.navigate([`/vendors/${data.vendor.display_id}/contacts`]);
      })
      .catch(error => this.submitError = error)
      .finally(() => {
        this.loading = false;
      });
  }

  updateContact(data: any = {}): void {

    this.contactV2Service.saveContact({
      ...data,
      id: this._editId
    })
      .toPromise()
      .then(contact => {
        console.log('Post update, pre updateRecentContacts');
        // log('contact updated', contact);
        this.updateRecentContacts(contact);
      })
      .catch((error) => {
        this.submitError = error;
        this.loading = false;
      });
  }

  updateRecentContacts(contact: VendorContact) {
    this.recentContactsStorageService.saveRecentContact(contact)
    .subscribe((contacts) => {
      this.contactComplete.emit(contact);
      this.loading = false;
    });
  }


}
