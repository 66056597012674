import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReverseTableColumnTemplate, ReverseTableRowTemplate } from 'src/shared/models/ReverseTable';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';

@Component({
  selector: 'mi-reverse-table',
  styleUrls: ['reverse-table.component.scss'],
  templateUrl: 'reverse-table.component.html'
})

export class ReverseTableComponent extends AbstractPageComponent implements OnInit {
  
  @Input() columns: ReverseTableColumnTemplate[] = [];
  @Input() rows: ReverseTableRowTemplate[] = [];

  @Input() toggleDataLayerTrigger: EventEmitter<string> = new EventEmitter<string>();
  @Input() changeDataTrigger: EventEmitter<ReverseTableRowTemplate[]> = new EventEmitter<ReverseTableRowTemplate[]>();

  @Input() dataLayersSelected: any[] = [];

  columnRows: any[] = [];

  ngOnInit(): void {
    this.calculateRowData();
    this.selectDataLayers();

    this.toggleDataLayerTrigger
    .pipe(takeUntil(this.destroy$))
    .subscribe(event => {
      this.toggleDataLayer(event);
    });
    
    this.changeDataTrigger
    .pipe(takeUntil(this.destroy$))
    .subscribe(event => {
      this.changeData(event);
    });
  }

  calculateRowData() {
    this.columns.forEach(col => {
      col.data.forEach(colData => {
        this.columnRows[colData.id] = [];

        this.rows.forEach(row => {
          this.columnRows[colData.id][row.id] = [];

          row.data.forEach(rowData => {
            if(colData.id == rowData.column_id) {
              this.columnRows[colData.id][row.id].value = rowData.value;

              if(colData.is_date) {
                this.columnRows[colData.id][row.id].value = new Date(this.columnRows[colData.id][row.id].value)
                                                                  .toLocaleString('default', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    second: '2-digit',
                                                                    timeZone: 'UTC'
                                                                  });
              }

              if(rowData.color_red){
                this.columnRows[colData.id][row.id].color_red = true;
              }
              if(rowData.color_apple_green){
                this.columnRows[colData.id][row.id].color_apple_green = true;
              }
              if(rowData.color_mid_blue){
                this.columnRows[colData.id][row.id].color_mid_blue = true;
              }
              if(rowData.color_orange){
                this.columnRows[colData.id][row.id].color_orange = true;
              }
              if(rowData.color_purple){
                this.columnRows[colData.id][row.id].color_purple = true;
              }
            }
          })
        })
      })
    });
  }

  selectDataLayers(): void {
    this.dataLayersSelected.forEach(layer => {
      if(layer.value) {
        this.toggleDataLayer(layer.data_layer);
      }
    });

  }

  toggleDataLayer(dataLayer: string): void {
    let tableElement = document.getElementById("reverse-table-main");
    tableElement.classList.toggle(dataLayer);
  }

  changeData(data: any): void {
    this.rows = data;
    this.calculateRowData();
  }

}

