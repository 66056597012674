import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NO_DATA} from '../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Product} from '../../../../../../shared/models/Product';
import {ParentServiceState} from '../../../state/reducer';
import * as ParentServiceActions from '../../../state/actions';
import * as ParentServiceReducer from '../../../state/reducer';
import {TenantProductsService} from '../../../../../../libs/api/tenant/tenantProducts';
import {TenantUsersService} from '../../../../../../libs/api/tenant/tenantUsers.service';
import {User} from '../../../../../../shared/models/User';
import {combineLatest} from "rxjs";
import {Assignee} from '../../parent-services-create.component';

@Component({
  selector: 'mi-parent-service-assignee',
  styleUrls: ['./parent-service-assignee.component.scss'],
  templateUrl: './parent-service-assignee.component.html'
})

export class ParentServiceAssigneeComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  allUsers: User[];
  assigneeEmail: string;
  assigneeName: string;
  cancelLink: string;
  componentForm: FormGroup;
  error: string;
  formProgression: any[] = ParentServiceReducer.ParentServiceProgression;
  formStep: string = ParentServiceReducer.ParentServiceSteps.AssignedTo;
  index: number = this.formProgression.indexOf(this.formStep);
  loaded: boolean = false;
  parentServiceState: ParentServiceState;
  product: Product;
  step: string = ParentServiceReducer.ParentServiceSteps.Product;
  users: Assignee[];
  division: string;
  team: string;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private userService: TenantUsersService,
    private formBuilder: FormBuilder,
    private store: Store<ParentServiceState>,
    private tenantProductService: TenantProductsService
  ) {
    super();
  }

  ngOnInit() {

    this.cancelLink = `/services`;

    const usersData = this.userService.getUsers(['NO_ACCESS_USER']);

    //this is not used now
    combineLatest(usersData)
      .takeUntil(this.destroy$)
      .subscribe((allValues) => {
        this.allUsers = allValues[0].map(user => user._rawData);
        this.users = this.allUsers
          .map((user) => {
            return {
              label: `${user.first_name} ${user.last_name}`,
              value: user.email,
              selected: false
            };
          })
          .sort((a, b) => {
            return a.label > b.label
              ?
              1
              :
              -1;
          });

        this.componentForm = this.formBuilder.group({
          assigned_email: [null, Validators.required],
          division: [null],
          team: [null]
        });

        this.store.select(ParentServiceReducer.getParentServiceStore)
          .subscribe(
            (state: ParentServiceState) => {
              this.parentServiceState = state;
              this.activeIndex = this.index === this.parentServiceState.index;
              this.componentForm.updateValueAndValidity();
            },
            (e) => {
              this.error = e;
            });

        this.loaded = true;

      });

  }

  onAssignedEmail(assignee){
    this.assigneeEmail = assignee;
    this.componentForm.get('assigned_email').setValue(assignee);
  }

  onBusinessUnit(bu){
    this.division = bu.division? bu.division.name : '';
    this.team = bu.team? bu.team.name : '';
    this.componentForm.get('division').setValue(bu.division? bu.division.id : null);
    this.componentForm.get('team').setValue(bu.team? bu.team.id : null);
  }

  onFormSubmit(data: any) {
    const maxIndex = Math.max(this.parentServiceState.maxIndex, this.index + 1);

    this.store.dispatch(new ParentServiceActions.UpdateParentServiceAction({
      index: maxIndex,
      maxIndex: maxIndex,
      division: data.division,
      team: data.team,
      assigned_email: data.assigned_email || null
    }));

  }

}
