import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as ParentServicesReducer from '../../state/reducers';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import {MiParentService} from '../../../../../../shared/models/MiParentService';
import {MiService} from '../../../../../../shared/models/MiService';
import {LocationModel} from '../../../../../../shared/models/Location';
import {getNotificationSeverity, NOTIFICATION_SEVERITY} from '../../../../../../shared/utils/notificationSeverity';
import {
  DATA_INTEGRITY_COMPLETE,
  DATA_INTEGRITY_INCOMPLETE,
  DATA_INTEGRITY_MISSING
} from '../../../../../../common/utils/sharedConstants';
import {Router} from '@angular/router';
import {ParentServiceStatus} from '../../../../../../shared/models/ParentServiceStatus.interface';
import {parentServiceStatusHelper} from '../../../../../../shared/shared.functions';

@Component({
  selector: 'mi-parent-service-details-header-info',
  templateUrl: './parent-service-details-header-info.component.html',
  styleUrls: ['../parent-service-details-header-items.scss', './parent-service-details-header-info.component.scss'],
  preserveWhitespaces: true
})

export class ParentServiceDetailsHeaderInfoComponent extends AbstractPageComponent implements OnInit {

  aggregateSaving: number = 0;
  aggregateMissed: number = 0;

  categories: string;
  daysRemaining: number = 0;
  eventStyles: any;
  hasPassed: boolean = false;
  locations: LocationModel[];
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  productTitle: string;
  parentServiceStatus:ParentServiceStatus;
  daysRemainingStyle: any;

  constructor(
    private store: Store<ParentServicesReducer.State>,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.pageLoaded$ = this.store.select(ParentServicesReducer.ParentServicePageLoaded);
    this.store
      .select(ParentServicesReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentService) {

            this.parentService = data.parentService;

            if(this.parentService.current_notification){
              this.hasPassed = this.parentService.current_notification.days_until_event < 0;

              this.daysRemaining = Math.abs(this.parentService.current_notification.notification_days_until_event || 0);
            }

            this.parentServiceStatus = parentServiceStatusHelper(this.parentService);

            this.categories = this.getCategoriesFromServices(this.parentService);

            this.productTitle = this.getProductTitle(this.parentService);

            this.locations = this.getLocations(this.parentService);

            this.eventStyles = this.getEventStyles(this.parentService);

            this.aggregateSaving = this.getAggregateSavings(this.parentService.services);

            this.aggregateMissed = this.getAggregateMissed(this.parentService.services);

            this.daysRemainingStyle = {
              'alert': this.hasPassed || Math.abs(this.daysRemaining) <= 30,
              'warning': Math.abs(this.daysRemaining) <= 60
            };
          }
        }
      );
  }

  getAggregateSavings(services: MiService[]): number {

    return services
      .filter((service: MiService) => {
        return service.data_integrity.overall_status !== DATA_INTEGRITY_INCOMPLETE;
      })
      .map((service: MiService) => {
        return service.savings.potential.to_savings;
      })
      .reduce((potential, agg) => {
        return agg += potential;
      }, 0) * 12;

  }

  getAggregateMissed(services: MiService[]): number {

    return services
      .filter((service: MiService) => {
        return service.data_integrity.overall_status === DATA_INTEGRITY_INCOMPLETE;
      })
      .map((service: MiService) => {
        return service.savings.missed.to_savings;
      })
      .reduce((missed, agg) => {
        return agg += missed;
      }, 0) * 12;
  }

  showMissed() {

    if(this.aggregateMissed === 0){
      return; // take no action as there's no missed savings.
    }

    this.router.navigate(['services'], {
      queryParams: {
        'q': this.parentService.display_id,
        'data_integrity_overall_status[]': DATA_INTEGRITY_INCOMPLETE,
        'vendor_name[]': this.parentService.product.product_vendor.vendor_name
      }
    });
  }

  showSavings() {

    /***
     * TBD: Request is to open an email with all services included.
     * Currently that isn't supported, so link goes to a winnowed
     * Services page.
     ***/

    if(this.aggregateSaving === 0){
      return; // take no action as there's no missed savings.
    }

    this.router.navigate(['services'], {
      queryParams: {
        'q': this.parentService.display_id,
        'data_integrity_overall_status[]': [DATA_INTEGRITY_MISSING, DATA_INTEGRITY_COMPLETE],
        'vendor_name[]': this.parentService.product.product_vendor.vendor_name
      }
    });
  }

  getCategoriesFromServices(parentService: MiParentService): string {
    let categorySet = new Set();

    parentService.services.forEach((service) => {
      categorySet.add(service.product.product_category.category_name);
    });

    return Array.from(categorySet)
      .sort((a, b) => {
        return a > b ? 1 : -1;
      }).join(', ');
  }

  getEventStyles(parentService: MiParentService): any {

    let severity: string = NOTIFICATION_SEVERITY.WARNING;

    if (parentService.current_notification) {
      severity = getNotificationSeverity(parentService.current_notification);
    }

    return {
      alert: severity === NOTIFICATION_SEVERITY.ALERT,
      archived: severity === NOTIFICATION_SEVERITY.ARCHIVED,
      copacetic: severity === NOTIFICATION_SEVERITY.LOW,
      warning: severity === NOTIFICATION_SEVERITY.WARNING
    };

  }

  getLocations(parentService: MiParentService): any[] {
    let locations = [];
    let locationsSet = new Set();
    parentService.services.forEach((service: MiService) => {
      service.locations.forEach((location: LocationModel) => {
        if (!locationsSet.has(location.display_id)) {
          locationsSet.add(location.display_id);
          locations.push(location);
        }
      });
    });
    return locations.sort((a: LocationModel, b: LocationModel) => {
      return a.address.city_long > b.address.city_long
        ?
        1
        :
        -1;
    });
  }

  getProductTitle(parentService: MiParentService): string {
    return parentService.nickname
      ?
      `${parentService.nickname} / ${parentService.product.product_name}`
      :
      parentService.product.product_name;

  }
}
