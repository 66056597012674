import { AbstractPageComponent } from "../../pages/abstract-page/abstract-page.component";
import { Component, Input, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import * as appDataReducer from "../../../shared/state/app-data/reducers";
import { SearchFilter } from "../../ng-models/SearchFilter.interface";
import * as ASActions from "../../state/advanced-search/actions";
import { TenantCustomFieldService } from "../../../libs/api/tenant/tenantCustomFields.service";
import {
  CUSTOM_FIELD_TYPE_DATE,
  CUSTOM_FIELD_TYPE_NUMBER
} from "../../../common/utils/sharedConstants";
import { CustomField } from "../../models/CustomField";
import {AsSimpleStateUpdate} from "../../state/advanced-search/actions";
import { TranslateService } from "@ngx-translate/core";
import {removeNonUrlSearchParams, removeNonDisplayFriendlySearchParams} from "../../utils/unsafeUrlProperties";
import {createLogger, LOG_LEVELS} from "../../logger";
import { TenantContactsService } from "../../../libs/api/tenant/tenantContacts.service";
import {ISecureUser} from "../../../common/security/secureUser";
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { combineLatest } from 'rxjs';
import { TenantMiServiceGroupService } from 'src/libs/api/tenant/tenantMiServiceGroup.service';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { TenantAccountIdService } from "src/libs/api2/account-id-v2/account-id-v2.service";
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';

export const ASSIGNED_TO: string = "assigned_email_id";

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-applied-search-filters',
  styleUrls: ['./applied-search-filters.component.scss'],
  templateUrl: './applied-search-filters.component.html'
})

export class AppliedSearchFiltersComponent extends AbstractPageComponent implements OnInit {

  error: string = null;
  filters: SearchFilter[] = [];
  loaded = false;
  resultsLoaded$: Observable<boolean>;
  users: ISecureUser[] = [];
  customFields: CustomField[] = [];
  pageLockFilters: string[] = [];
  contacts: any = {};
  customFieldsByIDs: any = {};
  businessUnitsByIDs: any = {};
  accountIdsByIDs: any = {};
  contactsByIDs: any = {};
  serviceGroupByIDs: any = {};
  searchType: string = '';

  constructor(
    private store: Store<any>,
    private tenantService: TenantV2Service,
    private tenantCustomFieldService: TenantCustomFieldService,
    private translate: TranslateService,
    private tenantAccountIdService:TenantAccountIdService,
    private tenantMiServiceGroupService: TenantMiServiceGroupService,
    private contactV2Service: ContactV2Service,
    private tenantVendorService: TenantVendors
  ) {
    super();
  }

  ngOnInit() {
    const getASData = this.store.select(ASReducer.getASData)
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

    getASData.takeUntil(this.destroy$)
    .subscribe((data) => {
      this.pageLockFilters = data.pageLockFilters;
      this.searchType = data.searchType;
    });
    this.resultsLoaded$ = this.store.select(ASReducer.ASResultsLoaded);

    let promises = [this.getBUID()];

    if(this.searchType === "SERVICE" || this.searchType === "EVENT"){
      promises.push(...[this.getCustomFields(), this.getAccountId(), this.getServiceGroup()]);
    }
    if(this.searchType === "ACCOUNT_ID"){
      promises.push(this.getContacts());
    }
    
    Promise.all(promises)
      .then(() => {
        return this.store
          .select(ASReducer.ASResults)
          .takeUntil(this.destroy$)
          .subscribe(results => {

            const data = {results};

            this.loaded = false;
            if (data.results && data.results.aggregations) {
              this.filters = [];

              const queryParams = removeNonDisplayFriendlySearchParams(removeNonUrlSearchParams(data.results.meta.params));

              for (let filterName in queryParams) {
                if (
                  queryParams[filterName] && filterName !== 'sort'
                ) {
                  if(!this.pageLockFilters.includes(filterName) && filterName !== "bu_access_lock"){
                    this.filters.push({
                      filterName: filterName,
                      value: queryParams[filterName]
                    });
                  }
                }
              }

              this.customFields.forEach(cf => {
                let selectedCusotmField = null;
                switch (cf.type) {
                  case CUSTOM_FIELD_TYPE_DATE:
                  case CUSTOM_FIELD_TYPE_NUMBER:
                    //debugger;
                    selectedCusotmField = this.filters.find(f => {
                      return (f.filterName.indexOf(cf._id + "_max") > -1);
                    });

                    if (selectedCusotmField)
                      selectedCusotmField.filterLabel = "Max " + cf.label;

                    selectedCusotmField = this.filters.find(f => {
                      return (f.filterName.indexOf(cf._id + "_min") > -1);
                    });

                    if (selectedCusotmField)
                      selectedCusotmField.filterLabel = "Min " + cf.label;
                    break;

                  default:
                    selectedCusotmField = this.filters.find(f => {
                      return f.filterName == cf._id;
                    });
                    if (selectedCusotmField)
                      selectedCusotmField.filterLabel = cf.label;
                }
              });
              this.updateFriendlyFilters();
              this.loaded = true;
            } else {
              this.loaded = true;
              this.filters = [];
            }
          });
      });
  }

  getBUID(){
    return this.tenantService.getBusinessUnits()
    .takeUntil(this.destroy$)
    .toPromise()
    .then(bus => {
      return bus.map(bu => {
        this.businessUnitsByIDs[bu.id] = bu;
      });
    });
  }

  getContacts(){
    return this.contactV2Service.getContactsByVendor('')
    .takeUntil(this.destroy$)
    .toPromise()
    .then(contacts => {
      return contacts.map(contact => {
        this.contactsByIDs[contact.id] = contact;
      });
    });
  }

  getCustomFields(){
    return this.tenantCustomFieldService
    .listCustomFields()
    .toPromise()
    .then(customFields => {
      customFields.map(customField => this.customFieldsByIDs[customField._id] = customField);
      return this.customFields = [...customFields];
    });
  }

  getAccountId(){
    let allAccountidsPromis = this.tenantAccountIdService.getByTenantId();
    let allVendorsPromis = this.tenantVendorService.listVendors();
    let account_ids = null;
    return this.tenantAccountIdService.getByTenantId()
      .toPromise()
      .then(data => {
        account_ids = data;
        return this.tenantVendorService.listVendors()
      })
      .then(data => {
        let allVendors = data;
        ;
        account_ids.map(account_id => {
          if(allVendors&&!account_id.vendor){
            account_id.vendor = allVendors.find(v => v.id == account_id.vendor_id);
          }
          this.accountIdsByIDs[account_id.id] = account_id;
        });
        this.store.dispatch(new ASActions.AsFilterClearName({}));
        return;
      },
      e => this.error = e);
  }

  getServiceGroup(){
    return this.tenantMiServiceGroupService.list()
      .takeUntil(this.destroy$)
      .toPromise()
      .then(groups => {
        if(!groups){
          return;
        }
        groups.map(groups => {
          this.serviceGroupByIDs[groups._id] = groups;
        })
        this.store.dispatch(new ASActions.AsFilterClearName({}));
        return;
      },
      e => this.error = e);
  }

  updateFriendlyFilters() {
    const filterList = [];

    this.filters.forEach(element => {

      if(element.filterLabel){
        filterList.push(element.filterLabel + ":" + element.value);
      } else {
        this.translate.get('GLOBAL.SEARCH_FILTERS.'+ element.filterName.toUpperCase())
          .subscribe(res => {
            let readableValue = '';
            switch (element.filterName) {

              case "account_id":
                readableValue = element.value.map(value => {
                  if(!this.accountIdsByIDs[value]){
                    return value;
                  }
                  let label = this.accountIdsByIDs[value].label;
                  if(label ==='Not Provided'){
                    label = res + ":" + label + "(" + this.accountIdsByIDs[value].vendor.vendor_name + ")";
                  }
                  return label;
                });
                filterList.push(res + ":" + readableValue);
                break;

              case "division":
                readableValue = element.value.map(value => this.businessUnitsByIDs[value].name);
                filterList.push(res + ":" + readableValue);
                break;

              case "team":
                readableValue = element.value.map(value => this.businessUnitsByIDs[value].name);
                filterList.push(res + ":" + readableValue);
                break;

              default:
                filterList.push(res + ":" + element.value);
                break;
            }
          });
      }
    });
    this.store.dispatch( new AsSimpleStateUpdate({readableFilter: filterList.join('  '), readableFilterArray: filterList }))
  }

  onRemoveClick(filter): void {
    let newFilter = this.filters.find((f: SearchFilter) => {
      return f.filterName === filter.filterName;
    });

    if (newFilter.value instanceof Array) {
      newFilter.value = newFilter.value.filter(item => {
        return item !== filter.value;
      });
    } else {
      newFilter.value = [];
    }

    let newQuery = {};
    newQuery[filter.filterName] = newFilter.value;

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }

  onRemoveAllClick(): void {
    let newQuery = {};

    this.filters.forEach(filter => {
      newQuery[filter.filterName] = [];
    });

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }
}
