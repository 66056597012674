<mi-page-header title="Vendor Defined Fields" [breadcrumbs]="breadcrumbs">
</mi-page-header>
<ng-container>
    <mi-search-header searchPlaceholder="Search" (searchParamChanged)="onSearchTermChanged($event)"
      [total]="filteredBridges.length" [showButton]="false" searchType="VENDORS_DEFINED_FIELD">
    </mi-search-header>
</ng-container>
<ng-container *ngIf="!isLoading; else loading">

  <mi-sortable-table *ngIf="filteredBridges && filteredBridges.length" [rows]="filteredBridges"
    [columns]="bridgeTableColumn">
  </mi-sortable-table>
  <ng-container *ngIf="!filteredBridges || !filteredBridges.length">
    <p>{{ filterString.length > 0 ? "No Records for " + filterString : "No Records" }}</p>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #bridgeDetailsTemplate let-row="row" let-value="value">
  <span [class]="'details-link'" (click)="bridgeDetailsModalHandler(row,bridgeDetailsModal)"> Open </span>
</ng-template>

<modal #bridgeDetailsModal>
  <modal-header>
    <b>{{ selectedRow?.vendorName }} Vendor Fields </b>
  </modal-header>
  <modal-content>
    <mi-sortable-table *ngIf="customFields && customFields.length" [rows]="customFields" [columns]="fieldsTableColumn">
    </mi-sortable-table>
  </modal-content>
  <modal-footer>
    <a mi-button buttonTheme="dark-theme" (click)="bridgeDetailsModal.close()">Close</a>
  </modal-footer>
</modal>