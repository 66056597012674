<div>

  <ng-select 
    [items]="account_ids"
    class="mi-select"
    bindLabel="label"
    [(ngModel)]="account_id"
    placeholder="New Account ID"
    [addTag]="addNewAccountId"
    autocomplete="none">
  </ng-select>
  <!-- [editableSearchTerm]="true" -->

  <mi-server-error [error]="error"></mi-server-error>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>


  <div class="button-container" *ngIf="allowSubmit">
    <button
      mi-button
      (click)="selectAccountIdSubmit($event)">
      Save
    </button>
  </div>

</div>

