<select [ngModel]="selected" (change)="toggle($event)">
  <option disabled >{{'SEARCH_FILTERS.SORT_BY' | translate}}</option>

  <option *ngFor="let val of options;" value="{{val}}">
    {{'SORTS.'+val | translate}}
  </option>
</select>



