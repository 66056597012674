<div class="middle-well">
  <img class="main-miso-logo"
  [src]="logoSrc" />
  <h1>Download File</h1>
  <mi-server-error [error]="error"></mi-server-error>
  <ng-container *ngIf="loaded; else loading">
    <ng-container *ngIf='buttonLabel && link'>
      <p>Please Click on the File Name to start Download.</p>
      <a [ngClass]="{theme:theme?true:false,download:true}" (class)="theme" download="download"  href="{{link}}">
        {{buttonLabel}}
      </a>
    </ng-container>
    <ng-container *ngIf='!buttonLabel || !link'>
      <p>Please contact your representative for new download link.</p>
      <p [ngClass]="{theme:theme?true:false,download:false}" class ="download-disabled" (class)="theme">
        File not available
      </p>
    </ng-container>
    <p>*File downloads expire in 30 days.</p>
    <br>
    <p>Do you need help ? <a href="https://support.miso3.com">Go to Miso Support.</a></p>
  </ng-container>
</div>
<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>