import {Component, OnInit} from '@angular/core';
import {Link} from "../../../../shared/ng-models/Link.interface";
import {Router} from "@angular/router";
import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import {TenantVendors} from "../../../../libs/api/tenant/tenantVendors";
import {Vendor} from "../../../../shared/models/Vendor";
import {getSupportLink} from "../../../../common/utils/linkFormatter";
import { TenantReportsService } from 'src/libs/api/tenant/tenantReports.service';
import { VendorMissingData, MissingDataReportQuery } from 'src/shared/models/VendorMissingData';

@Component({
  templateUrl: './missing-opportunities-vendor.component.html',
  styleUrls: ['./missing-opportunities-vendor.component.scss']
})

export class MissingOpportunitiesVendorComponent extends AbstractPageComponent implements OnInit {
  breadcrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Incomplete Data By Vendor",
      url: "/",
      internal: true
    }
  ];
  error: any;
  headerLinks: Link[];
  supportLink: string = getSupportLink();
  vendorMissingData: VendorMissingData[]=[];

  constructor(
    private tenantReportService: TenantReportsService,
    private tenantVendor: TenantVendors,
    private router: Router) {
  super();
  }
  ngOnInit(){
    this.tenantVendor.getVendorIncompleteData()
      .subscribe(
          vendors => {
            this.vendorMissingData = vendors.filter(vendor => vendor.total_count?vendor:'');
            // sort by total overall
            this.vendorMissingData.sort( (a:VendorMissingData, b:VendorMissingData) => {
              return b.total_overall - a.total_overall;
            });
        },
        e => {this.error = e}
      );
  }

  onCreateReport(vendorId){
    let query: MissingDataReportQuery = {
      vendor_id : vendorId
    }
    this.tenantReportService.createtMissingDataReportByVendorId(query)
          .toPromise()
          .then((res: any) => {
              let report = res || null;
              if (report) {
                  const reportUrl = `./reports/${report._id}/details`;
                  this.router.navigate([reportUrl]);
              }
          })
          .catch(e => this.error = e);
  }
}
