import { Component, Input, forwardRef, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { FileService } from '../../../libs/api/file/file.service';
import { Subscription } from 'rxjs';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import { Document } from 'src/libs/api2/types/Document.interface';
import { SectionDataTableColumn } from 'src/shared/ng-models/SectionDataColumn.interface';
import { SortableTemplatesEnum } from 'src/shared/ng-models/SortableTableColumn.interface';
import { ModalService } from '@independer/ng-modal';
import { DocumentUploadModalComponent } from 'src/shared/components/document-upload-modal/document-upload-modal.component';


@Component({
  selector: 'mi-document-upload-drag-drop',
  styleUrls: ['./mi-document-upload-drag-drop.directive.scss'],
  templateUrl: './mi-document-upload-drag-drop.directive.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MiDocumentUploadDragDrop),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MiDocumentUploadDragDrop),
      multi: true,
    },
    FileService
  ]
})

export class MiDocumentUploadDragDrop extends AbstractPageComponent implements ControlValueAccessor {
  @Input() accept: string;
  @Input() tenantData: any;

  fileUrl: any; //[{loading: false, name: "Aditya 1.xlsx", fileUrl: {full_path: "Aditya 1.xlsx", file_name: "Aditya 1.xlsx"}}, {loading: true, name: "Aditya 2.xlsx", progress: 50}];
  loading = false;
  loaded = false;
  fileName: string = "";
  error: any;
  isImage = false;
  imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'ico', 'tif', 'tiff', 'webp'];
  isDrag: boolean = false;
  isInvalidDrag: boolean = false;
  progress = 0;
  currentUpload = 0;
  totalUpload = 0;
  progressSubscription: Subscription;
  count = 0;
  total = 0;
  processing = false;
  uploadedDocs: Document[] = [];
  uploadedDocsColumns: SectionDataTableColumn[] = [];
  duplicateError:string[] = [];
  @ViewChild('editLink', {static: true}) editLink: TemplateRef<any>;
  @ViewChild('deleteLink', {static: true}) deleteLink: TemplateRef<any>;
  deleting: boolean;
  private propagateChange = (_: any) => { };

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    if (!this.containsOneFiles(evt) || this.loading) {
      evt.dataTransfer.dropEffect = 'none';
      this.isInvalidDrag = true;
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.isDrag = true;
  }


  containsOneFiles(event) {
    let fileCount = 0;
    let isFile = false;
    if (event.dataTransfer.types) {
      for (var i = 0; i < event.dataTransfer.types.length; i++) {
        if (event.dataTransfer.types[i] == "Files") {
          isFile = true;
        }
      }
      if (isFile && event.dataTransfer.items) {
        fileCount = event.dataTransfer.items.length
      }
    }

    return fileCount >= 1;
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isInvalidDrag = false;
    this.isDrag = false;
  }

  //Drop listener
  @HostListener('drop', ['$event']) public async ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDrag = false;
    this.isInvalidDrag = false;
    let targetfiles = evt.dataTransfer.files;

    this.loaded = false;

    if (targetfiles.length > 0) {
      const files = Array.from(targetfiles)
        .filter((file: any) => {
          const fileType = file.name.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
          return this.accept.indexOf(fileType) === -1 ? false : true;
        });

      if (files.length !== targetfiles.length) {
        // There's something amiss; one or more of the files passed
        // isn't of the type we're expecting.
        this.error = 'One or more of the files selected for upload is of the wrong type. Please try again.';
        return;
      }

      this.error = null;
      this.loading = true;
      this.currentUpload = 0;
      this.totalUpload = files.length;

      for (let i = 0; i < files.length; i++) {
         this.currentUpload++;
        await this.upload(files[i]).then(() => null);
      }
      //debugger;
      this.loading = false;
    }
  }

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private tenantDocumentManagementService: TenantDocumentManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.uploadedDocsColumns = [
      {
        name: "MISO ID",
        prop: 'display_id',
        cellTemplate: this.editLink,
        sortable: false
      },
      {
        name: "File Name",
        prop: 'file_name',
        cellTemplate: SortableTemplatesEnum.fileLink,
        sortable: true
      },
      {
        name: "File Size",
        prop: 'file_size',
        cellTemplate: SortableTemplatesEnum.fileSize,
        sortable: false
      },
      {
        name: "File Uploaded",
        prop: 'created_on_utc',
        cellTemplate: SortableTemplatesEnum.shortISODateTime,
        sortable: false
      },
      {
        name: "Uploaded By",
        prop: 'created_by_user_name',
        sortable: false
      },
      {
        name: "Delete",
        prop: 'id',
        cellTemplate: this.deleteLink,
        sortable: false
      }
    ];

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public validate() {
    return null;
  }

  public writeValue(obj: any) {
    //this.isImage = obj && obj.file_path ? this.imageExtensions.includes(obj.file_path.split('.').pop()) : false;
    this.propagateChange(this.fileUrl);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
  }

  // Right now you can only do one, add "multiple" to input for multiple, change logic
  async fileChanged(e: Event) {

    const target: HTMLInputElement = e.target as HTMLInputElement;
    this.error = '';

    if (target.accept) {
      if (target.accept !== '' && target.accept !== '*') {
        // There are acceptable file types passed through;
        // Make sure all the passed file(s) are of that type.
        const files = Array.from(target.files)
          .filter((file) => {
            const fileType = file.name.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
            return target.accept.indexOf(fileType) === -1 ? false : true;
          });

        if (files.length !== target.files.length) {
          // There's something amiss; one or more of the files passed
          // isn't of the type we're expecting.
          this.error = 'One or more of the files selected for upload is of the wrong type. Please try again.';
          return;
        }
      }
    }
    this.error = null;
    this.loading = true;
    this.currentUpload = 0;
    this.totalUpload = target.files.length;

    for (let i = 0; i < target.files.length; i++) {
      this.currentUpload++;
      await this.upload(target.files[i]).then(() => null);
      //debugger;
    }
    //debugger;
    this.loading = false;
  }

  reset() {
    this.loading = false;
    this.loaded = false;
    this.propagateChange(this.fileUrl);
  }


  async upload(file) {

    //debugger;
    const data = {
      file_name: file.name,
      name: file.name,
      is_url: false,
      business_unit_id: this.tenantData.domain
    };
    this.fileName = file.name;
    this.progress = 0;

    await this.tenantDocumentManagementService
      .save(data)
      .toPromise()
      .then(
        (response) => {
          this.progress = 30;
          return this.uploadFile(response.upload_url, response.id, file);
        },
        (e) => {
          if(e.duplicate_display_id){
            this.duplicateError.push(`File ${this.fileName} (${e.duplicate_display_id}) already exists.`);
          }
          else{
            this.error = e;
          }
          return null;
        }
      );
  }

  uploadFile(upload_url, id, file) {
    const url = new URL(upload_url);
    const headers = new HttpHeaders();
    const cacheHeader = url.searchParams.get('Cache-Control');
    let fileType = file.type || 'application/octet-stream';

    if (cacheHeader) {
      headers.set('cache-control', cacheHeader);
    }

    const file_with_correct_content_type = new Blob([file], { type: fileType });

    return this.http.put(upload_url, file_with_correct_content_type, {
      headers: headers
    })
      .toPromise()
      .then((response: HttpEvent<any>) => {
        this.progress = 60;
        return this.confirmUpload(id);
      },
        (e) => {
          this.error = e;
          return null;
        });
  }

  confirmUpload(id) {
    return this.tenantDocumentManagementService
      .confirmUpload(id)
      .toPromise()
      .then(
        (doc) => {
          //debugger;
          this.progress = 100;
          this.uploadedDocs.push(doc);
          return doc;
        },
        (e) => {
          this.error = e;
          return null;
        });
  }

  createDocumentLink() {
    const modalRef = this.modalService.open(DocumentUploadModalComponent, m => {
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      
      if (result.length) {
        //TBD ShowMessage
      }
    });
  }

  openDocumentEditModal(id) {
    const modalRef = this.modalService.open(DocumentUploadModalComponent, m => {
      m.editId = id;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      
      if (result.length&&result[0].doc) {
        this.uploadedDocs = this.uploadedDocs.filter(d => d.id != result[0].doc.id);
      }
    });
  }

  permanentlyDelete(id){
    this.deleting = true;
    this.tenantDocumentManagementService.delete( id, [id] )
    .subscribe(() =>{
      this.uploadedDocs = this.uploadedDocs.filter(d => d.id !=id);
      this.deleting = false;
      },
      e=> {
        this.error = e;
        this.deleting = false;
      }
    )
  }


}
