import {NgModule} from '@angular/core';
import {AccountPlansService} from "./account/accountPlans.service";
import {AccountTwoFactorService} from "./account/twoFactor.service";
import {AuthService} from "./auth/auth.service";
import {CheckoutService} from "./account/checkout.service";
import {FreshdeskService} from "./auth/freshdesk.service";
import {MiyagiApiConnectorService} from "./miyagi/miyagiApiConnectors";
import {MiyagiCategoryService} from "./miyagi/miyagiCategories";
import {MiyagiConfigService} from "./miyagi/miyagiConfig";
import {MiyagiProductService} from "./miyagi/miyagiProducts";
import {MiyagiProductTypeService} from "./miyagi/miyagiProductType";
import {MiyagiSearchService} from "./miyagiSearch/miyagiSearch.service";
import {ProductFieldPropertyService} from "./miyagi/miyagiProductFieldProperty";
import {ProductFieldService} from "./miyagi/miyagiProductField";
import {SaveSearchService} from "./tenantSearch/saveSearch.service";
import {SettingsService} from "./settings/settings.service";
import {SharedTenantService} from "./tenant/shared-tenant.service";
// import {TenantAccountIdService} from "./tenant/tenantAccountId.service.ts.example";
import {TenantAddressService} from "./tenant/tenantAddress.service";
import {TenantAlertService} from "./tenant/tenantAlert.service";
import {TenantAssociatedDocumentsService} from "./tenant/tenantAssociatedDocument.service";
import {TenantBulkUploadService} from "./tenant/tenantBulkUpload.service";
import {TenantUserUploadService} from "./tenant/tenantUserUpload.service";
import {TenantUserManagementService} from './tenant/tenantUserManagement.service';
import {TenantCategoryService} from "./tenant/tenantCategories.service";
import {TenantContactsService} from "./tenant/tenantContacts.service";
import {TenantCustomFieldService} from "./tenant/tenantCustomFields.service";
import {TenantCustomerImportService} from "./tenant/tenantCustomerImport.service";
import {TenantDataConnectorsService} from "./tenant/tenantDataConnectors.service";
import {TenantEmailService} from "./tenant/tenantEmail.service";
//import {TenantEquipmentService} from "./tenant/tenantEquipment.service";
import {TenantLocationService} from "./tenant/tenantLocation.service";
import {TenantMiOrderService} from "./tenant/tenantMiOrder.service";
import {TenantMiServiceGroupService} from "./tenant/tenantMiServiceGroup.service";
import {TenantMiServiceService} from "./tenant/tenantMiService.service";
import {TenantNoteService} from "./tenant/tenantNote.service";
import {TenantOrderDraftsService} from "./tenant/tenantOrderDrafts.service";
import {TenantProductsService} from "./tenant/tenantProducts";
import {TenantReportsService} from "./tenant/tenantReports.service";
import {TenantSearchService} from "./tenantSearch/tenantSearch.service";
import {TenantService} from "./account/tenant.service";
import {TenantSynonymsService} from "./tenant/tenantSynonyms.service";
import {TenantTermsAndConditionsService} from "./tenant/tenantTermsAndConditions.service";
import {TenantUsersService} from "./tenant/tenantUsers.service";
import {TenantVendors} from "./tenant/tenantVendors";
import {UserEmailService} from "./account/userEmails.service";
import {UserService} from "./account/accountUser.service";
import {VendorService} from "./miyagi/miyagiVendors";
import {HttpClientModule} from "@angular/common/http";
import {ApiInterceptorsModule} from "../api-interceptors/api-interceptors.module";
import {TenantDocumentManagementService} from './tenant/tenantDocumentManagement.service';
import { CollectVendorLoginService } from './collectvendorlogin/CollectVendorLogin.service';


@NgModule({
  imports: [
    HttpClientModule,
    ApiInterceptorsModule
  ],
  declarations: [],
  providers: [
    AccountPlansService,
    AccountTwoFactorService,
    AuthService,
    CheckoutService,
    FreshdeskService,
    MiyagiApiConnectorService,
    MiyagiCategoryService,
    MiyagiConfigService,
    MiyagiProductService,
    MiyagiProductTypeService,
    MiyagiSearchService,
    ProductFieldPropertyService,
    ProductFieldService,
    SaveSearchService,
    SettingsService,
    SharedTenantService,
    // TenantAccountIdService,
    TenantAddressService,
    TenantAlertService,
    TenantAssociatedDocumentsService,
    TenantBulkUploadService,
    TenantUserUploadService,
    TenantUserManagementService,
    TenantCategoryService,
    TenantContactsService,
    TenantCustomFieldService,
    TenantCustomerImportService,
    TenantDataConnectorsService,
    TenantEmailService,
    //TenantEquipmentService,
    TenantLocationService,
    TenantMiOrderService,
    TenantMiServiceGroupService,
    TenantMiServiceService,
    TenantNoteService,
    TenantOrderDraftsService,
    TenantProductsService,
    TenantReportsService,
    TenantSearchService,
    TenantService,
    TenantSynonymsService,
    TenantTermsAndConditionsService,
    TenantUsersService,
    TenantVendors,
    UserEmailService,
    UserService,
    VendorService,
    TenantDocumentManagementService,
    CollectVendorLoginService
  ],
  exports: []
})

export class ApiModule {
}
