import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import {settings} from '../../../../common/utils/SettingsUtil';
import {TenantSearchService} from '../../../../libs/api/tenantSearch/tenantSearch.service';
import {DetailHeaderNavData, HeaderNavSpecTypes} from '../../../../shared/ng-models/DetailHeaderNavData.interface';
import {TenantLocationService} from '../../../../libs/api/tenant/tenantLocation.service';
import {combineLatest} from "rxjs";
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {MiModalService} from '../../../../shared/services/Modal.service';
import {FILTER_GROUP_MODAL_ID} from '../../../../shared/ng-models/FilterGroupModalId';
import {AddressV2} from '../../../../libs/api2/types/AddressV2.interface';
import {LocationV2} from '../../../../libs/api2/types/LocationV2.interface';
import {AddressV2Service} from '../../../../libs/api2/address-v2/address-v2.service';
import { ModalService } from '@independer/ng-modal';
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';

@Component({
  templateUrl: './location-instance-wrap.component.html'
})

export class LocationInstanceWrapComponent extends AbstractPageComponent implements OnInit {

  address: AddressV2;
  breadcrumbs: Link[];
  detailHeaderNavData: DetailHeaderNavData;
  display_id: string;
  error: string = '';
  filterOverlayId: string = FILTER_GROUP_MODAL_ID;
  location: LocationV2;
  supportLink: string = settings.MISO_CONTACT_URL;
  title: string;
  equipmentCount: number = 0;

  NO_DATA: string = NO_DATA;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantLocationService: TenantLocationService,
              private tenantSearchService: TenantSearchService,
              private tenantAddressV2Service: AddressV2Service,
              private store: Store<any>,
              private modalService: ModalService) {
    super();
  }

  ngOnInit() {

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.filterGroup) {
            // Get the data and open the modal:
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.filterOverlayId;
            })
          }
        });

    this.updateFromRoute();

  }

  updateFromRoute() {
    // console.log('locations instance wrap cmp');
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .distinctUntilChanged((prev, curr) => prev[1].update === curr[1].update)
      .switchMap(([params, queryParams]) => {
        this.display_id = params.display_id;
        // get the data
        return this.tenantAddressV2Service.getLocationById(this.display_id);
      })
      .catch(e => this.error = e)
      //.switchMap((res: LocationV2) => {
      .subscribe((res: LocationV2) => {
        console.log('res from the tenantLocationService', res);
        this.location = res;
        this.address = res.address;

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Addresses',
            url: '/addresses',
            internal: true
          },
          {
            label: `Address`,
            url: '/addresses/' + this.address.id,
            internal: true
          },
          {
            label: `Location`,
            url: '/locations/' + this.display_id,
            internal: true
          }
        ];

        const serviceCount = res.service_count || 0;
        const totalCmrc = res.c_mrc || 0;
        const nickName = this.location.nick_name ? ' (' + this.location.nick_name + ')' : '';

        this.detailHeaderNavData = <DetailHeaderNavData> {
          headline: this.location.label + nickName,
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: serviceCount
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: totalCmrc,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        /*
        return combineLatest(
          this.tenantSearchService.typeSummary({'location_id[]': this.location.id})
        );
        */
        
      })
      /*
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe(([addressResults, equipmentResults]: any[]) => {
        this.equipmentCount = equipmentResults.total;

        // // we can now find the correct bucket for this address
        const correctBucket = addressResults.aggregations.location_id.buckets.find(bucket => bucket.key === this.location.id);
        const serviceCount = correctBucket ? correctBucket.doc_count : 0;
        const nickName = this.location.nick_name ? ' (' + this.location.nick_name + ')' : '';

        this.detailHeaderNavData = <DetailHeaderNavData>{
          headline: this.location.label + nickName,
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: serviceCount
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: addressResults.aggregations.total_mrc.value || 0,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };
      });
      */
  }
}
