<ng-container>

  <mi-page-header
    title="{{ 'TENANT.SERVICE_TERM.DETAIL_TITLE' | translate:{display_id: display_id} }}"
    [breadcrumbs]="breadcrumbs"
    *ngIf="serviceTerm">
    <ng-container>
      <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
    </ng-container>
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>
  <mi-page-alert></mi-page-alert>

  <div class="details-header-nav">

    <div class="details-header-nav__info" *ngIf="detailHeaderNavData">

      <mi-details-header-nav
        [showEdit]="false"
        [data]="detailHeaderNavData">
      </mi-details-header-nav>

    </div>

  </div>

  <router-outlet></router-outlet>