import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { BillingService } from "src/libs/api2/billing/billing.service";
import { Link } from "src/shared/ng-models/Link.interface";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as userReducer from '../../../../libs/user-store/state/reducers';
import { AccountPlan } from "src/shared/models/TenantCreationV2";
import { TenantBillingAccount, CreateSubscriptionRequest, SubscriptionItem } from "src/shared/models/Billing";
import { map, takeUntil } from "rxjs/operators";
import * as tenantBillingAccountActions from '../../../../shared/state/tenant-billing-account/actions';
import * as tenantBillingAccountReducers from '../../../../shared/state/tenant-billing-account/reducers';
import { ModalService } from "@independer/ng-modal";
import { ToastrService } from "ngx-toastr";
import { ChangePlanModalComponent } from "../components/modals/change-plan-modal/change-plan-modal.component";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";

@Component({
  templateUrl: './manage-current-plan.component.html',
  styleUrls: ['./manage-current-plan.component.scss']
})

export class ManageCurrentPlanComponent extends AbstractPageComponent implements OnInit {
  breadcrumbs: Link[];
  headerLabel: string = "";
  loaded: boolean = false;

  tenantBillingAccount: TenantBillingAccount;
  stripeCustomer: any;
  stripeSubscription: any;

  accountPlans: AccountPlan[] = [];
  trimmedAccountPlans: AccountPlan[] = [];
  currentAccountPlan: AccountPlan;
  products: any[];
  prices: any[];

  currentPlanBillingInterval: string;
  newPlanBillingInterval: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<userReducer.State>,
    private billingService: BillingService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.setLoading();
    this.activatedRoute.data.subscribe((data: any) => {
      console.log("data", data);
      this.headerLabel = data.headerLabel;
    });
    
    this.activatedRoute
      .params
      .subscribe((queryParams: Params) => {

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Billing',
            url: '/billing',
            internal: true
          },
          {
            label: this.headerLabel,
            url: './',
            internal: true
          }
        ];
    });

    this.store.pipe(
      select(tenantBillingAccountReducers.getTenantBillingAccountData),
      takeUntil(this.destroy$),
      map(async (billingAccountData) => {        
        this.tenantBillingAccount = billingAccountData.result;
        console.log("tenantBillingAccount", this.tenantBillingAccount);
        // this.getBusinessUnit(appData.tenantData.domain);
        //this.loaded = true;
        this.loadData();

        
      })
      ).subscribe();
  }

  ngAfterViewInit(): void {
    //this.startScrollListener();
  }

  startScrollListener(): void {
    const scrollContainer = document.querySelector('#billingPlansContainer');
    console.log("scrollContainer", scrollContainer);

    scrollContainer.addEventListener('wheel', (evt) => {
      //console.log("evt", evt);
      evt.preventDefault();
      scrollContainer.scrollLeft += evt["deltaY"];
    });
  }

  async loadData(): Promise<void> {
    await this.getStripeCustomer();
    await this.getStripeSubscription();
    await this.getProducts();

    this.setLoaded();
  }

  setLoading(): void {
    this.loaded = false;
  }

  setLoaded(): void {
    this.loaded = true;
    this.startScrollListener();
  }

  async getStripeCustomer(): Promise<void> {
    console.log("stripeCustomerId", this.tenantBillingAccount.stripeCustomerId);
    let res = await this.billingService.getCustomer(this.tenantBillingAccount.stripeCustomerId).toPromise();
    console.log("getCustomer", res);
    this.stripeCustomer = res;

    this.stripeCustomer.addressString = this.stripeCustomer.address.line1 + ", ";

    if(this.stripeCustomer.address.line2) {
      this.stripeCustomer.addressString += this.stripeCustomer.address.line2 + ", ";
    }

    this.stripeCustomer.addressString +=
                        this.stripeCustomer.address.city + ", " +
                        this.stripeCustomer.address.state + ", " +
                        this.stripeCustomer.address.country + " - " +
                        this.stripeCustomer.address.postal_code;
  }

  async getStripeSubscription(): Promise<void> {
    console.log("stripeSubscriptionId", this.tenantBillingAccount.stripeSubscriptionId);
    let res = await this.billingService.getSubscription(this.tenantBillingAccount.stripeSubscriptionId).toPromise();
    console.log("getSubscription", res);
    this.stripeSubscription = res;
    this.currentPlanBillingInterval = this.stripeSubscription.items.data[0].plan.interval;
    this.newPlanBillingInterval = this.currentPlanBillingInterval;

    await this.getStripeProducts();
  }

  async getStripeProducts(): Promise<void> {
    await this.stripeSubscription.items.data.map(async subscriptionItem => {
      let res = await this.billingService.getProduct(subscriptionItem.plan.product).toPromise();
      subscriptionItem.product = res;
    });
    console.log("stripeSubscription", this.stripeSubscription);
  }

  async getProducts(): Promise<void> {
    let res = await this.billingService.getProductsByTenantBillingAccountId(this.tenantBillingAccount.tenantBillingAccountId).toPromise();
    console.log("getProducts res", res);
    this.products = res.data;
    await this.filterProducts();
  }

  async filterProducts(): Promise<void> {
    this.products.map((product, index) => {
      if(product.active == false) {
        this.products.splice(index, 1);
      }
    });
    console.log("filtered products", this.products);
    await this.getPrices();
  }

  async getPrices(): Promise<void> {
    let res = await this.billingService.getPrices().toPromise();
    console.log("getPrices res", res);
    this.prices = res.data;
    await this.createPlans();
  }

  async createPlans(): Promise<void> {
    this.accountPlans = [];
    this.sortProducts();
    let newPlan: AccountPlan = {};
    let currentPlanCode: number = null;
    this.products.map(product => {  
      //
      //let currentPrice = this.prices.find(price => price.id == product.default_price);
      if(currentPlanCode == null) {
        currentPlanCode = parseInt(product.metadata.plan_code);
      }
      else if(currentPlanCode != parseInt(product.metadata.plan_code)) {
        this.accountPlans.push(newPlan);
        newPlan = {};
        currentPlanCode = parseInt(product.metadata.plan_code);
      }
      switch(product.metadata.plan_type) {
        case "base":
          newPlan.planCode = parseInt(product.metadata.plan_code);
          newPlan.planName = product.name;
          newPlan.planDescription = product.description;
          newPlan.servicesIncluded = parseInt(product.metadata.services);
          newPlan.templatesIncluded = parseInt(product.metadata.templates);
          newPlan.stripePlanId = product.id;
          //newPlan.planBaseCost = (currentPrice.unit_amount / 100);
          newPlan.planBaseCostMonthly = (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").unit_amount / 100);
          newPlan.planBaseCostYearly = (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").unit_amount / 100);
          newPlan.allowTrial = product.metadata.allow_trial == "true" ? true : false;
          if(newPlan.allowTrial) {
            newPlan.trialDays = parseInt(product.metadata.trial_days);
          }
          if(!newPlan.relatedStripePlans) {
            newPlan.relatedStripePlans = [];
          }
          newPlan.relatedStripePlans.push({
            stripePlanId: product.id,
            //stripePriceId: currentPrice.id,
            stripePriceIdMonthly: this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").id,
            stripePriceIdYearly: this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").id,
            planType: product.metadata.plan_type,
            //cost: (currentPrice.unit_amount / 100),
            costMonthly: (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").unit_amount / 100),
            costYearly: (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").unit_amount / 100),
            additionalQuantity: null,
            //isRecurring: (currentPrice.type == "recurring" ? true : false)
            isRecurring: true
          });
          break;
        case "setup":
          let currentPrice = this.prices.find(price => price.id == product.default_price);
          newPlan.templateSetupFee = (currentPrice.unit_amount / 100);
          if(!newPlan.relatedStripePlans) {
            newPlan.relatedStripePlans = [];
          }
          newPlan.relatedStripePlans.push({
            stripePlanId: product.id,
            //stripePriceId: currentPrice.id,
            stripePriceIdFixed: currentPrice.id,
            planType: product.metadata.plan_type,
            //cost: (currentPrice.unit_amount / 100),
            costFixed: (currentPrice.unit_amount / 100),
            additionalQuantity: null,
            //isRecurring: (currentPrice.type == "recurring" ? true : false)
            isRecurring: false
          });
          break;
        case "additional_template":
          //newPlan.additionalTemplateCost = (currentPrice.unit_amount / 100);
          newPlan.additionalTemplateCostMonthly = (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").unit_amount / 100);
          newPlan.additionalTemplateCostYearly = (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").unit_amount / 100);
          newPlan.additionalTemplateQuantity = parseInt(product.metadata.additional_quantity);
          if(!newPlan.relatedStripePlans) {
            newPlan.relatedStripePlans = [];
          }
          newPlan.relatedStripePlans.push({
            stripePlanId: product.id,
            //stripePriceId: currentPrice.id,
            stripePriceIdMonthly: this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").id,
            stripePriceIdYearly: this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").id,
            planType: product.metadata.plan_type,
            //cost: (currentPrice.unit_amount / 100),
            costMonthly: (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").unit_amount / 100),
            costYearly: (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").unit_amount / 100),
            additionalQuantity: parseInt(product.metadata.additional_quantity),
            //isRecurring: (currentPrice.type == "recurring" ? true : false)
            isRecurring: true
          });
          break;
        case "additional_service":
          //newPlan.additionalServiceCost = (currentPrice.unit_amount / 100);
          newPlan.additionalServiceCostMonthly = (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").unit_amount / 100);
          newPlan.additionalServiceCostYearly = (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").unit_amount / 100);
          newPlan.additionalServiceQuantity = parseInt(product.metadata.additional_quantity);
          if(!newPlan.relatedStripePlans) {
            newPlan.relatedStripePlans = [];
          }
          newPlan.relatedStripePlans.push({
            stripePlanId: product.id,
            //stripePriceId: currentPrice.id,
            stripePriceIdMonthly: this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").id,
            stripePriceIdYearly: this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").id,
            planType: product.metadata.plan_type,
            //cost: (currentPrice.unit_amount / 100),
            costMonthly: (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "month").unit_amount / 100),
            costYearly: (this.prices.find(price => (price.product == product.id) && price.recurring && price.recurring.interval == "year").unit_amount / 100),
            additionalQuantity: parseInt(product.metadata.additional_quantity),
            //isRecurring: (currentPrice.type == "recurring" ? true : false)
            isRecurring: true
          });
          break;
      }
    });

    if(Object.keys(newPlan).length != 0) {
      this.accountPlans.push(newPlan);
    }

    await this.getCurrentAccountPlan();
    await this.getTrimmedAccountPlans();
    
    console.log("accountPlans", this.accountPlans);
  }

  async getCurrentAccountPlan(): Promise<void> {
    await this.accountPlans.map(accountPlan => {
      this.stripeSubscription.items.data.map(subscriptionItem => {
        if(subscriptionItem.product.metadata.plan_type == "base" && (subscriptionItem.product.id == accountPlan.stripePlanId)) {
          this.currentAccountPlan = accountPlan;
        }
      });
    });

    console.log("currentAccountPlan", this.currentAccountPlan);
  }

  async getTrimmedAccountPlans(): Promise<void> {
    this.trimmedAccountPlans = [];

    await this.accountPlans.map(accountPlan => {
      if((!accountPlan.allowTrial) && (accountPlan.planBaseCostMonthly > this.currentAccountPlan.planBaseCostMonthly) && (accountPlan.planBaseCostYearly > this.currentAccountPlan.planBaseCostYearly)) {
        this.trimmedAccountPlans.push(accountPlan);
      }
    });

    console.log("trimmedAccountPlans", this.trimmedAccountPlans);
  }

  sortProducts(): void {
    
    this.products.sort((a, b) => {
      return parseInt(a.metadata.plan_code) > parseInt(b.metadata.plan_code) ? 1 : -1;
    });
    console.log("sorted products", this.products);
    
  }

  changePlanBillingInterval($event): void {
    console.log("changePlanBillingTime", $event);
    this.newPlanBillingInterval = $event.target.value;
  }

  selectPlan($event): void {
    console.log("selectPlan", $event);

    const modalRef = this.modalService.open(ChangePlanModalComponent, m => {
      m.newAccountPlan = this.trimmedAccountPlans.find(accountPlan => accountPlan.planCode == $event);
      m.billingInterval = this.newPlanBillingInterval;
    });

    modalRef.closed.subscribe(({ reason, result }) => {
      console.log("result", result);

      if(result && result[0].result == true) {
        this.setLoading();
        this.changeCurrentPlan(result[0].selectedPlanCode);
      }
    });
  }

  async changeCurrentPlan(selectedPlanCode: number): Promise<void> {
    let subscription_items: SubscriptionItem[] = [];
    let selectedPlan = this.trimmedAccountPlans.find(accountPlan => accountPlan.planCode == selectedPlanCode);
    selectedPlan.relatedStripePlans.map(plan => {
      if(plan.isRecurring) {
        /*
        subscription_items.push({
          price_id: plan.stripePriceId,
          quantity: (plan.planType == "base" ? 1 : 0)
        });
        */
        if(this.newPlanBillingInterval == "month") {
          subscription_items.push({
            price_id: plan.stripePriceIdMonthly,
            quantity: (plan.planType == "base" ? 1 : 0)
          });
        }    
        else if(this.newPlanBillingInterval == "year") {
          subscription_items.push({
            price_id: plan.stripePriceIdYearly,
            quantity: (plan.planType == "base" ? 1 : 0)
          });
        }
      }      
    });


    let createSubscriptionRequest: CreateSubscriptionRequest = {
      tenantCode: FrontEndSettingsUtil.getTenantCode(),
      customerId: this.tenantBillingAccount.stripeCustomerId,
      subscriptionItems: subscription_items,
      billingInterval: this.newPlanBillingInterval,
      collectionMethod: this.stripeCustomer.invoice_settings.default_payment_method == null ? "invoice" : "auto",
      allowTrial: false
    };

    try {
      let res = await this.billingService.changeSubscription(createSubscriptionRequest).toPromise();
      console.log("changeSubscription", res);
  
      this.toastr.success("Plan changed successfully");
      await this.getTenantBillingAccount();
      this.loadData();
    }
    catch(e) {
      this.toastr.error("Failed");    
    }
  }

  async getTenantBillingAccount(): Promise<void> {
    let res = await this.billingService.getTenantBillingAccountByTenantCode(FrontEndSettingsUtil.getTenantCode()).toPromise();
    console.log("getTenantBillingAccountByTenantCode", res);

    await this.store.dispatch(new tenantBillingAccountActions.SetTenantBillingAccountData(res));
  }
}