import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiService} from '../../../shared/models/MiService';
import {Financial} from '../../../shared/models/Financial';
import {TenantNoteService} from './tenantNote.service';
import {Note} from '../../../shared/models/Note.interface';
import {MisoCollection} from '../../../shared/models/MisoCollection.enum';
import {combineLatest, Observable} from "rxjs";
import {NotificationPreferences} from '../../../shared/models/NotificationPreferences.interface';
import {CanDelete} from 'src/shared/models/CanDelete';
import {MiServiceGroup} from '../../../shared/models/MiServiceGroup';
import {of} from 'rxjs';
import { ServiceAudit } from 'src/shared/models/ServiceAudit';
import { VendorSearchResults } from 'src/shared/models/SearchResults';
import { Vendor } from '../../../shared/models/Vendor';
import { VendorNormalizationPayload } from 'src/shared/ng-models/vendor.interface';
import { IBulkCreateProductsPayload, IProductToResolvePayload } from 'src/shared/models/ProductCategory';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantMiServiceService {

  private apiUrlCreateServiceFromOrderDraft = '/{tenant}/service/fromOrderDraft';
  private apiUrlServiceCRUD = '/{tenant}/service';
  // private  = '/{tenant}/upload/property/product/resolution';

  private apiUrlFinancialUpdate = '/{tenant}/service/';
  private apiUrlServiceAuditData = '_MICRO_SERVICE_/upload/service/audit/service';


  private apiGetVendors = '_MICRO_SERVICE_/vendor/search/vendors';
  private apiGetApprovedVendorsMappingService = '_MICRO_SERVICE_/vendor/get-approved-vendor-mappings';
  private apiAlignTenantServices = '_MICRO_SERVICE_/connector/align-tenant-services/search';
  private apiAlignTenantConfig = '_MICRO_SERVICE_/connector/align-config';
  private apiAlignStats = '_MICRO_SERVICE_/connector/get-stats';

  private apiAlignServices = '_MICRO_SERVICE_/connector/align-services';

  // https://api2-staging.miso3app.com/connector/get-stats?config_id=29e61b47-f423-11ee-86b8-0affd57ae787

  // https://api2-staging.miso3app.com/connector/get-stats?config_id=c6c9eaa3-f1ad-11ee-86b8-0affd57ae787'
  
  private apiUrlResolveProduct = '_MICRO_SERVICE_/upload/property/product/resolution';
  private apiUrlUpdateResolveProduct = '_MICRO_SERVICE_/upload/updateService';
  private apiUrlBulkCreateProducts = '_MICRO_SERVICE_/product/Bulk_Create';


  private apiGetUnresolvedProducts = '_MICRO_SERVICE_/upload/property/product/unresolved';
  private apiGetResolvedProducts = '_MICRO_SERVICE_/product/getProduct';
  // private apiGetUnresolvedProducts = 'https://api2-dev.miso3app.com/upload/property/product/unresolved?search=lumen&pageNumber=0&pageSize=10&is_resolved=false';
  
  // private apiResolvedProduct = '_MICRO_SERVICE_/upload/property/product/unresolved';
  private apiMiyagiProductService = '_MICRO_SERVICE_/product/create';
  private apiUpdateMiyagiProductService = '_MICRO_SERVICE_/product/updateProduct';


  private apiGetApprovedVendors = '_MICRO_SERVICE_/vendor/approved';
  private apiVendorService = '_MICRO_SERVICE_/vendor/tenants';
  private apiVendorUpdateService = '_MICRO_SERVICE_/vendor';
  private apiRemoveVendorLogoService = '_MICRO_SERVICE_/vendor';
  private apiVendorNormalizationService = '_MICRO_SERVICE_/vendor/normalize';
  private apiGetVendorsService = '_MICRO_SERVICE_/vendor/vendors';
  private apiUploadVendorLogoService = '_MICRO_SERVICE_/vendor/vendors/logo';
  private apirUrlVendorByDisplayId = '/{tenant}/vendors';
  private apiGetVendorsResolutionList = '_MICRO_SERVICE_/buquery/resolution/get-vendor-resolutions';
  private apiVendorResolution = '_MICRO_SERVICE_/buquery/resolution';


  private anyToVendor(any: any): Vendor {
    var vendor = any.vendor as Vendor;
    if (any.vendor.logo) {
      vendor.logo = {
        file_path: any.vendor.logo,
        upload_type: "MIYAGI_VENDOR_LOGO",
        file_name: any.vendor.logo.split('/')[2],
        full_path: any.vendor.logo
      };
    }
    vendor.display_id = any.display_id as string;
    vendor._id = any.id;
    vendor.tenant_id = any.tenant_id;
    if (any.dependents) {
      vendor.account_ids = [];
      vendor.contacts = [];
      any.dependents.forEach((dependent) => {
        if (dependent.reference_type == "Accounts") {
          vendor.account_ids.push({
            id: dependent.id,
            display_id: dependent.display_id,
            reference_type: dependent.reference_type,
            label: dependent.label
          })
        }
        if (dependent.reference_type == "Vendor contact") {
          vendor.contacts.push({
            id: dependent.id,
            display_id: dependent.display_id,
            reference_type: dependent.reference_type
          })
        }
      });
    }
    if (vendor.is_resolved)
      vendor.actual_vendor_id = vendor.resolution_id;
    return vendor;
  }

  constructor(
    private http: HttpClient,
    private tenantNoteService: TenantNoteService) {
  }

  createMiServiceFromOrderDraft(draft_id: String): Observable<MiService> {
    return this.http
      .post<MiService>(`${this.apiUrlCreateServiceFromOrderDraft}/${draft_id}`, {})
      .map((response: any) => response.data.miservice);
  }

  createMiServiceFromObject(service: any): Observable<MiService> {
    return this.http
      .post<MiService>(`${this.apiUrlServiceCRUD}`, service)
      .map((response: any) => response.data.miservice);
  }

  getMiService(service_id: String): Observable<MiService> {
    return this.http
      .get<MiService>(`${this.apiUrlServiceCRUD}/${service_id}`)
      .map((response: any) => response.data.miservice);
  }

  updateMiService(service_id: String, safeData: any): Observable<MiService> {
    return this.http
      .put<MiService>(`${this.apiUrlServiceCRUD}/${service_id}`, safeData)
      .map((response: any) => response.data.miservice);
  }

  updateCMRC(tenant_currency_id: String): Observable<any> {
    return this.http
      .post<MiService>(`${this.apiUrlServiceCRUD}-update-cmrc`, {tenant_currency_id})
      .map((response: any) => response.data);
  }

  updateMiServiceFinancials(service_id: String, financial: Financial): Observable<MiService> {
    let requestUrl = `${this.apiUrlFinancialUpdate}${service_id}/financial`;
    return this.http
      .put<MiService>(requestUrl, financial)
      .map((response: any) => response.data.miservice);
  }

  updateServiceNotifications(service_id: string, notification_preferences: NotificationPreferences): Observable<MiService> {
    let requestUrl = `${this.apiUrlServiceCRUD}/${service_id}/notification-preferences`;
    return this.http
      .post<MiService>(requestUrl, {notification_preferences})
      .map((response: any) => response.data.miservice);
  }

  addCancellation(service_id: string, cancellation_date: string, noteHtml: string = ''): Observable<MiService> {

    let noteObservable: Observable<any> = of(false);

    if (noteHtml) {
      noteObservable = this.createNote(service_id, noteHtml);
    }

    let requestUrl = `${this.apiUrlFinancialUpdate}${service_id}/cancellation`;

    const serviceObservable = this.http
      .post<MiService>(requestUrl, {
        start_date: cancellation_date
      });

    return combineLatest(serviceObservable, noteObservable)
      .first()
      .map(([response, note]) => response['data']['miservice']);
  }

  removeCancellation(service_id: String, noteHtml: string = ''): Observable<MiService> {

    let noteObservable: Observable<any> = of(false);
    if (noteHtml) {
      noteObservable = this.createNote(service_id, noteHtml);
    }

    let requestUrl = `${this.apiUrlFinancialUpdate}${service_id}/cancellation`;

    const serviceObservable = this.http
      .delete<MiService>(requestUrl);

    return combineLatest(serviceObservable, noteObservable)
      .first()
      .map(([response, note]) => response['data']['miservice']);
  }

  reterm(service_id: String, financial: Financial, noteHtml: string = ''): Observable<MiService> {

    let noteObservable: Observable<any> = of(false);
    if (noteHtml) {
      noteObservable = this.createNote(service_id, noteHtml);
    }

    let requestUrl = `${this.apiUrlServiceCRUD}/${service_id}/reterm`;

    const serviceObservable = this.http
      .post<MiService>(requestUrl, financial);

    return combineLatest(serviceObservable, noteObservable)
      .first()
      .map(([response, note]) => response['data']['miservice']);

  }

  cancelReterm(service_id: String, noteHtml: string = ''): Observable<MiService> {

    let noteObservable: Observable<any> = of(false);
    if (noteHtml) {
      noteObservable = this.createNote(service_id, noteHtml);
    }

    let requestUrl = `${this.apiUrlServiceCRUD}/${service_id}/reterm`;

    const serviceObservable = this.http.delete<MiService>(requestUrl);

    return combineLatest([serviceObservable, noteObservable])
      .first()
      .map(([response, note]) => response['data']['miservice']);

  }

  private createNote(service_id, html: string): Observable<Note> {
    const note = {
      connections: [{
        document_id: service_id,
        document_collection: MisoCollection.SERVICES
      }],
      html
    };
    return this.tenantNoteService.createNote(note);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get<CanDelete>(`${this.apiUrlServiceCRUD}/${id}/can-delete`)
      .map((response: any) => response.data);
  }

  deleteService(id: String): Observable<MiService> {
    return this.http
      .delete<MiService>(`${this.apiUrlServiceCRUD}/${id}/delete`)
      .map((response: any) => response.data.accountId as MiService);
  }

  getServiceGroups(id: String, serviceGroupIds: any[]): Observable<Array<MiServiceGroup>> {
    return this.http
      .post<Array<MiServiceGroup>>(`${this.apiUrlServiceCRUD}/${id}/service-groups`, {serviceGroupIds})
      .map((response: any) => response.data.mi_service_groups);
  }

  getServiceAuditInfo(tenant_id: String, display_id: String): Observable<ServiceAudit> {
    return this.http
      .post<any>(`${this.apiUrlServiceAuditData}`,{"tenant_id":tenant_id, "display_id":display_id})
      .map((response: ServiceAudit) => response);
  }

  getVendorService(search: string, pageNumber: number, pageSize: number, is_resolved: boolean = true, is_ignored: boolean = false): Observable<any> {
    return this.http.get(`${this.apiGetVendors}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&is_ignored=${is_ignored}&is_resolved=${is_resolved}`);
  }

  getBulkUploadVendorFiltersService(): Observable<any> {
    return this.http.get(`${this.apiGetApprovedVendorsMappingService}`);
  }

  getVendorResolutionService(pageNumber: number, pageSize: number, tenantId: string, type: string): Observable<any> {
    let params = `?type=${type}`;

    if (pageNumber) {
        params += `&pageNumber=${pageNumber}`;
    }

    if (pageSize) {
        params += `&pageSize=${pageSize}`;
    }

    if (tenantId) {
        params += `&tenantId=${tenantId}`;
    }

    return this.http.get(`${this.apiGetVendorsResolutionList}${params}`);
}

vendorResolution(payload: any): Observable<any> {
  const url = `${this.apiVendorResolution}/update-resolution`;
  return this.http.post(url, payload);
}


  getUnresolvedProductsService(search: string, pageNumber: number, pageSize: number, is_resolved: boolean = false): Observable<any> {
    return this.http.get(`${this.apiGetUnresolvedProducts}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&is_resolved=${is_resolved}`);
  }


  getResolvedProductsService(search: string, pageNumber: number, pageSize: number, vendorId: string): Observable<any> {
    return this.http.get(`${this.apiGetResolvedProducts}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&vendorId=${vendorId}`);
  }

  createMiyagiProductService(productData: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiMiyagiProductService}`, productData)
      .map((response: any) => response);
  }


  updateMiyagiProductService(productData: any): Observable<any> {
    return this.http
      .put<any>(`${this.apiUpdateMiyagiProductService}`, productData)
      .map((response: any) => response);
  }


  resolvedProductsService(payload: IProductToResolvePayload): Observable<any> {
    return this.http
    .put<any>(`${this.apiUrlResolveProduct}`, payload)
    .map((response: any) => response);
  }

  UpdateResolvedProductsService(payload: IProductToResolvePayload): Observable<any> {
    return this.http
    .post<any>(`${this.apiUrlUpdateResolveProduct}`, payload)
    .map((response: any) => response);
  }


  bulkCreateProductsService(payload: IBulkCreateProductsPayload[]): Observable<any> {
    return this.http
    .post<any>(`${this.apiUrlBulkCreateProducts}`, payload)
    .map((response: any) => response);
  }

  searchAllVendors(q: any = '', from: number = 0, size: number = 10): Promise<VendorSearchResults> {
    const params = new HttpParams({ fromObject: { q, from: from.toString(), size: size.toString() } });
    return this.http
      .get('/miyagi/search/allVendors', { params })
      .toPromise()
      .then((response: any) => response.data as VendorSearchResults);
  }

  getApprovedVendorService(): Observable<any> {
    return this.http
      .get<any>(`${this.apiGetApprovedVendors}`)
      .map((response: any) => response);
  }

  createVendorService(vendorData: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiVendorService}`, vendorData)
      .map((response: any) => response);
  }

  iqnoreVendorService(vendorData: any): Observable<any> {
    return this.http
      .put<any>(`${this.apiVendorService}`, vendorData)
      .map((response: any) => response);
  }
  
  updateVendorService(vendorData: any, vendor_id: string): Observable<any> {
    return this.http
      .put<any>(`${this.apiVendorUpdateService}/${vendor_id}`, vendorData)
      .map((response: any) => response);
  }

  removeVendorLogo(vendor_id: String): Observable<MiService> {
    return this.http
      .delete<MiService>(`${this.apiRemoveVendorLogoService}/${vendor_id}/logo`)
      .map((response: any) => response);
  }

  updateVendorLogoService(vendor_id: string, logoUrl: string): Observable<any> {
    return this.http
      .put<any>(`${this.apiRemoveVendorLogoService}/${vendor_id}/logo`, {logo : logoUrl})
      .map((response: any) => response);
  }


  normalizeVendorService(vendorNormalizationPayload: VendorNormalizationPayload): Observable<any> {
    return this.http
      .post<any>(`${this.apiVendorNormalizationService}`, vendorNormalizationPayload)
      .map((response: any) => response);
  }

  uploadVendorLogoService(fileToUpload: File): Observable<string> {
    const formData = new FormData();
    formData.append('logoImage', fileToUpload, fileToUpload.name);
    return this.http
      .post(this.apiUploadVendorLogoService, formData)
      .map((response: string) => response);
  }


  servicesAlignService(services: string[], ignore: boolean , match: boolean): Observable<any> {
    return this.http
    .post<any>(`${this.apiAlignServices}?ignore=${ignore}&match=${match}`, services)
    .map((response: any) => response);
  } 

  getAlignTenantServices(search: string, pageNumber: number, pageSize: number, dataset1_id: string, dataset2_id: string,vendor: any, country: string, state: any, city: any, currency: any, vendorAccount: string, probability: any, isIgnored: any, cost_min: string , cost_max: string, street: string, matched: boolean): Observable<any> {
    let url = `${this.apiAlignTenantServices}?dataset1_id=${dataset1_id}&dataset2_id=${dataset2_id}&page_number=${pageNumber}&size=${pageSize}&matched=${matched}`;

    // Check if country is provided, then add it to the URL
    if (search) {
      url += `&search=${search}`;
    }

    if(cost_max){
      url += `&cmrc_max=${cost_max}`;
    }

    if(cost_min){
      url += `&cmrc_min=${cost_min}`;
    }

    if (vendor) {
      url += `&vendor=${vendor.vendor.vendor_name}`;
    }

    if (probability) {
      url += `&probability=${probability.value}`;
    }

    
    // if (isIgnored) {
    //   url += `&isIgnored=${isIgnored}`;
    // }

    if (street) {
      url += `&street=${street}`;
    }
    // ?isIgnored=true&matched=true&probability=high&street

    if (vendorAccount) {
      url += `&account_label=${vendorAccount}`;
    }
    
    if (country) {
      url += `&country=${country}`;
    }

    if (state && country) {
      url += `&state=${state.stateName}`;
    }

    if (city && country && state) {
      url += `&city=${city.cityName}`;
    }

    if (currency) {
      url += `&currency=${currency}`;
    }
  
    return this.http.get(url);


  }

  getAlignTenantConfig(tenantId: string, ): Observable<any> {
    return this.http.get(`${this.apiAlignTenantConfig}?tenant_id=${tenantId}`);
  }

  getStatsService(configId: string, ): Observable<any> {
    return this.http.get(`${this.apiAlignStats}?config_id=${configId}`);
  }

  getVendorById(vendorId: string): Observable<any> {
    const url = `${this.apiGetVendorsService}/${vendorId}`;
    return this.http.get<any>(url);
  }
}
