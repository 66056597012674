import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import { TenantVendors } from "../../../../../libs/api/tenant/tenantVendors";
import { Vendor } from "../../../../../shared/models/Vendor";
import { AssociatedDocumentFormType } from "../../../../../shared/components/associated-document-form/associated-document-form.component";
import { AssociatedDocument } from "../../../../../shared/models/AssociatedDocument";

@Component({
  templateUrl: './associated-documents-edit.component.html',
  styleUrls: ['./associated-documents-edit.component.scss']
})

export class AssociatedDocumentsEditComponent extends AbstractPageComponent implements OnInit {

  associatedDocumentsId: string;
  error: string;
  exitRoute: string;
  vendor: Vendor;
  associatedDocumentFormType: any = AssociatedDocumentFormType;

  constructor(private activatedRoute: ActivatedRoute,
    private tenantVendors: TenantVendors,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.associatedDocumentsId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    this.exitRoute = `/associated-documents/${this.associatedDocumentsId}/details`;
  }

  onAssociatedDocumentCancelled() {
    this.router.navigate([this.exitRoute]);
  }

  onUpdateAssociatedDocuments(associatedDocument:AssociatedDocument):void {
    // The change has been added.
    this.router.navigate([this.exitRoute], {
      queryParams:{update: new Date().getTime()},
      relativeTo: this.activatedRoute
    });
  }

}
