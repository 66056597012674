import {Component, OnInit, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Store} from '@ngrx/store';
import * as userReducer from '../../../libs/user-store/state/reducers';
import * as userActions from '../../../libs/user-store/state/actions';
import * as appDataReducer from '../../state/app-data/reducers';
import * as appDataActions from '../../state/app-data/actions';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import * as linkFormatter from '../../../common/utils/linkFormatter';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {getAuthCookie, getAuthCookieV2} from '../../../libs/user-store/state/auth-cookie';
import {AbstractPageComponent} from '../abstract-page/abstract-page.component';
import {Title} from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import {CvlSettings, FrontEndSettings, SettingsService} from "../../../libs/api/settings/settings.service";
import {settings} from '../../../common/utils/SettingsUtil';

const log = createLogger(LOG_LEVELS.BASE_PAGE);

@Component({
  template: '<div></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,

})

export class BasePageComponent extends AbstractPageComponent implements OnInit, OnDestroy {
  private userSubscription: Subscription;
  private isFocusListening: Boolean = false;
  private appSubscription: Subscription;

  isNavPath: Boolean = false;
  protected invalidNavPaths: Array<string> = [
    '/login',
    '/signup',
    '/confirm-reset-password',
    '/reset-password-code',
    '/404',
    '/403',
    '/external-download',
    '/logout',
    '/set-password',
    '/user-policy',
    '/setup-two-factor',
    '/auth/forgot',
    '/auth/challenge-forgot',
    '/auth/set-password',
    '/v2/password',
    '/checkout/complete',
    '/checkout/start',
    '/account-locked',
    '/auth/freshdesk',
    '/accept-terms-and-conditions',
    '/collect-vendor-login',
    '/create-tenant',
    '/get-started'
  ];

  appData;
  baseTemplateClass: string = 'main--content';
  bootReady = false;
  hasRemovedLoader = false;
  template: string = '';
  userData;
  displayId: string;
  pageTitle: string;
  autoPilotComplete: boolean = false;
  frontEndSettings: FrontEndSettings;
  checkingBootReady: boolean = false;

  private showGrid: Boolean = false;

  constructor(
    private _store: Store<any>,
    private _translateService: TranslateService,
    private _router: Router,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private _settingsService: SettingsService
  ) {
    super();
  }

  // we may wish to show the grid in the future
  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key === ';') {
  //     let className = window.document.body.className;
  //     if (this.showGrid) {
  //       className = className.replace(/show\-grid/g, '');
  //     } else {
  //       className = className + ' show-grid';
  //     }
  //     window.document.body.className = className;
  //     this.showGrid = !this.showGrid;
  //   }
  // }

  handleVisibilityChange(): void {
    if (document.hidden) {
      log('window went to background');
    } else {
      log('window moved to the foreground, verifying login status');
      //firstly if no coookie, we drop right into logout.
      if (getAuthCookie() || getAuthCookieV2()) {
        //at least the user still has a cookie.
        this.verifyUser();
      } else if (!this.shouldNotRedirect()) {
        log('this user has no cookie, and should redirect');
        //this user has no cookie even.
        //we will log out.
        //alert("getLogoutLink");
        window.location.href = linkFormatter.getLogoutLink();
      }

    }
  }

  ngOnInit(): void {
    /*
    this._settingsService.get().subscribe({
      next: (data: FrontEndSettings) => {
        this.frontEndSettings = data;
        //log('front end settings are loaded', this.frontEndSettings);
        this.loadDomainAndContinueInit();
      }
    });

    this._settingsService.getCvl().subscribe({
      next: (data: CvlSettings) => {
        //log("cvl data are loaded", data);
      }
    });
    */
    this.frontEndSettings = environment;
    this.loadDomainAndContinueInit();
  }

  loadDomainAndContinueInit(): void{
    this.continueInit();
  }

  continueInit(): void {
    // set default language
    this._translateService.setDefaultLang('en');
    this._translateService.use('en');

    this.isNavPath = !this.invalidNavPaths.includes(window.location.pathname);
    this.browserSniff();


    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        //need to notify the page-alert component.
        this.isNavPath = !this.invalidNavPaths.includes(window.location.pathname);
        this.template = this.getRouteData('template') || '';
      }
    });

    this.userSubscription = this._store.select(userReducer.getUserState).subscribe(
      state => {
        log('user state changed', state);
        if (state.verified) {
          this.removeLoader();
          this.userData = state;
          this.fetchAppData();
          this.autopilotId();
          this.checkBootReady();
        } else {
          if (!state.verifying) {
            this.verifyUser();
          }
        }
      }
    );

    this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this._activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
    )
      .subscribe((event) => {
        this.displayId = event.snapshot.paramMap.get('display_id') || '';
        this.pageTitle = event.snapshot.data.pageTitle;
        if (this.pageTitle && this.pageTitle.indexOf('DISPLAYID') != -1 && !this.displayId) {
          this.displayId = event.parent.snapshot.paramMap.get('display_id') || 'not found';
        }
        this.setPageTitle();
      });
  }

  setPageTitle() {

    if (this.appData && this.appData.appType === 'TENANT') {
      if (this.pageTitle && this.pageTitle.indexOf('DELAYED') != -1) {
        return;
      } else if (this.pageTitle) {
        return this._titleService.setTitle(this.pageTitle
          .replace('TENANTNAME', this.appData.tenantData.display_name)
          .replace('DISPLAYID', this.displayId));
      } else {
        return this._titleService.setTitle('MISO');
      }
    } else {
      if (this.pageTitle === 'DELAYED') {
        return;
      } else if (this.pageTitle) {
        return this._titleService.setTitle(this.pageTitle);
      } else {
        return this._titleService.setTitle('MISO');
      }
    }
  }

  browserSniff(): void {
    const browsers = [];
    if (/msie\s|trident\//i.test(window.navigator.userAgent)) {
      browsers.push('ie');
    }
    if (/edge\//i.test(window.navigator.userAgent)) {
      browsers.push('edge');
    }
    browsers.forEach(item => {
      document.body.classList.add(item);
    });
  }

  removeLoader(): void {
    if (!this.hasRemovedLoader) {
      this.hasRemovedLoader = true;
      let elem = document.getElementById('full-page-loader');
      if (elem) {
        elem.classList.add('loaded');
        window.setTimeout((e) => {
          elem.parentNode.removeChild(elem);
        }, 1002);
      }
    }
  }

  fetchAppData() {
    if (this.appSubscription) {
      return;
    }

    // go get the data:
    this._store.dispatch(new appDataActions.AppDataGetAction({}));

    // listen for the data
    this.appSubscription = this._store.select(appDataReducer.getAppData).subscribe(
      state => {
        log('Received appData');
        this.appData = state;
        if (this.appData.invalidDomain) {
          log.error('This is not a valid domain.');
          // if this is an invalid domain, we have a bigger issue.
          // this case picked-up in the auth guard
        } else {
          if(!this.checkingBootReady) {
            this.checkBootReady();
          }          
        }
      }
    );
  }

  getRouteData(key: string): any {
    let root = this._router.routerState.snapshot.root;
    while (root) {
      if (root.children && root.children.length) {
        root = root.children[0];
      } else if (root.data && root.data[key]) {
        return root.data[key];
      } else {
        return;
      }
    }
  }

  checkBootReady() {
    this.checkingBootReady = true;
    const bootReady = (
      this.userData && this.userData.verified &&
      this.appData && this.appData.appType && !this.appData.invalidDomain
    );

    log('Checking if we are bootready: ', bootReady);
    if (bootReady) {
      this.setPageTitle();
    }

    let willRedirect: Boolean = false;

    if (bootReady && !this.userData.secureUser) {
      willRedirect = this.decideIfLoginRedirect();
    }
    if (!willRedirect) {

      // make sure we only add one listener
      if (!this.isFocusListening) {
        if (this.userData.secureUser) {
          //we only want to add a listenr if there is a secure state.
          document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);
        }
        this.isFocusListening = true;
      }

      this.bootReady = bootReady;
    }
    this.checkingBootReady = false;
  }

  shouldNotRedirect(): Boolean {

    const nonRedirectList = <any>[
      {
        pageUrl: linkFormatter.getLoginLink()
      },
      {
        pageUrl: settings.ACCOUNTS_URL
      },
      {
        pathname: '/logout'
      },
      {
        pathname: '/signup'
      },
      {
        pathname: '/404'
      },
      {
        pathname: '/403'
      },
      {
        pathname: '/confirm-reset-password'
      },
      {
        pathname: '/reset-password-code'
      },
      {
        pathname: '/auth/forgot'
      },
      {
        pathname: '/auth/challenge-forgot'
      },
      {
        pathname: '/auth/set-password'
      },
      {
        pathname: '/v2/password'
      },
      {
        pathname: '/checkout/start'
      },
      {
        pathname: '/checkout/complete'
      },
      {
        pathname: '/style-guide'
      },
      {
        pathname: '/test'
      },
      {
        pathname: '/auth/freshdesk'
      },
      {
        pathname: '/external-download'
      },
      {
        pathname: '/collect-vendor-login'
      },
      {
        pathname: '/create-tenant'
      },
      {
        pathname: '/get-started'
      }
    ];

    const pageUrl = window.location.href.replace(window.location.search, '');
    const pathname = window.location.pathname;

    const noRedirect = nonRedirectList.some((nonRedObject) => {
      if (nonRedObject.pageUrl) {
        return nonRedObject.pageUrl === pageUrl;
      } else {
        return nonRedObject.pathname === pathname;
      }
    });
    return noRedirect;
  }

  decideIfLoginRedirect(): boolean {
    // debugger;
    const noRedirect = this.shouldNotRedirect();

    if (noRedirect) {
      //something we do not allow redirect for
      return false;
    }
    //alert("decideIfLoginRedirect redirecting");

    window.location.href = linkFormatter.getLoginLink(window.location.href);
    return true;
  }

  verifyUser() {
    log('verifying user');
    this._store.dispatch(new userActions.UserVerifyAction({}));
  }

  ngOnDestroy(): void {
    log('destroying outer component');
    this.userSubscription.unsubscribe();
    this.appSubscription.unsubscribe();
  }

  autopilotId(): void {
    if (!this.autoPilotComplete && this.userData.secureUser) {
      const user = this.userData.secureUser.getSafeData();
      const today = new Date();
      const lastActive = `${String(today.getUTCMonth() + 1).padStart(2, '0')}/${String(today.getUTCDate()).padStart(2, '0')}/${today.getUTCFullYear()} ${today.getUTCHours()}:${today.getUTCMinutes()}:${today.getUTCSeconds()}`;
      const msg = {
        Email: user.email,
        FirstName: user.first_name,
        LastName: user.last_name,
        Status: lastActive,
        Company: settings.COOKIE_CROSS_DOMAIN,
        custom: {
          'boolean--Miso--Customer': true,
          'date--Last--Active': lastActive,
          'boolean--Superuser': this.userData.secureUser._rawData.isSuperUser
        }
      };
      //window['Autopilot'].run('associate', msg);
      this.autoPilotComplete = true;
    }
  }

}

