import { Component } from '@angular/core';


@Component({
  selector: 'mi-not-found-page',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})

export class NotFoundComponent {
  constructor() {}
}
