
import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';

import * as pageAlertActions from './actions';

import { ROUTER_NAVIGATION, ROUTER_CANCEL } from '@ngrx/router-store';

import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.REDUX);

export interface State {
  nextPageAlerts: Array<any>;
  pageAlerts: Array<any>;
}

const initialState: State = {
  nextPageAlerts: [],
  pageAlerts: [],
};

export function reducer(state = initialState, action: pageAlertActions.Actions): State {
  // log('inside of user reducer', state, action);
  switch (action.type) {
    case pageAlertActions.ActionTypes.PAGE_ALERT_ADD: {
      log('adding an alert');
      return {
        ...state,
        nextPageAlerts: state.nextPageAlerts.concat(action.payload),
      };
    }
    case pageAlertActions.ActionTypes.SHOW_PAGE_ALERT:
    case ROUTER_NAVIGATION : {
    if (state.nextPageAlerts.length === 0 && state.pageAlerts.length === 0) {
      // there is no change.
      return state;
    } else {
      // there was a page change, and there is
      return {
        ...state,
        nextPageAlerts: [],
        pageAlerts: state.nextPageAlerts
      };
    }
  }
    case ROUTER_CANCEL : {
      return {
        ...state,
        nextPageAlerts: state.pageAlerts,
        pageAlerts: []
      };
    }
    default: {
    //  log('returning default user store');
      return state;
    }
  }
}

export const getPageAlert = createFeatureSelector<State>('pageAlert');

export const getCurrentPageAlerts = createSelector(
  getPageAlert,
  (state) => state.pageAlerts
);
