import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {UploadV2Service} from "./upload-v2/upload-v2.service";
import {TenantV2Service} from "./tenant-v2/tenant-v2.service";
import {Miso3baseService} from "./miso3base-v2/miso3base.service";
import {AuthV2Service} from "./auth-v2/auth-v2.service";
import {ApiInterceptorsModule} from "../api-interceptors/api-interceptors.module";
import {DivisionV2Service} from "./division-v2/division-v2.service";
import {SiteV2Service} from "./site-v2/site-v2.service";
import { ProfileV2Service } from './profile-v2/profile-v2.service';
import { AddressV2Service } from './address-v2/address-v2.service';
import { ServiceTermService } from './service-term/service-term.service';
//import { EquipmentV2Service } from './equipment-v2/equipment-v2.service';
import { ContactV2Service } from './contact-v2/contact-v2.service';
import { UsersV2Service } from './users-v2/users-v2.service';
import { ReportV2Service } from './report-v2/report-v2.service';
import { CurrencyService } from './currency-v2/currency.service';
import { DashboardService } from './dashboard/dashboard.service';
import { ParentServiceGroupService } from './parent-service-group-v2/parent-service-group.service';
import { TenantAccountIdService } from './account-id-v2/account-id-v2.service';
import { SubsscriptionService } from './subscription/subscription';
import { TenantCreationService } from './account-v2/tenantCreation.service';
import { NewReportsV2Service } from './report-v2/new-reports-v2.service';
import { VendorResolutionService } from './resolution/vendor-resolution.service';
import { AddressResolutionService } from './resolution/address-resolution.service';
import { ProductResolutionService } from './resolution/products-resolution.service ';
import { TenantVendorService } from './vendor-v2/vendor-v2.service';
import { VendorDefinedFieldsV2Service } from './vendor-defined-fields/vendor-defined-fields-v2.service';
import { ServiceV2Service } from './service-v2/service-v2.service';
import { CustomFieldsV2Service } from './service-v2/custom-fields-v2.service';
import { DocumentV2Service } from './document-v2/document-v2.service';
import { ServiceReportsV2Service } from './service-v2/service-reports-v2.service';
import { MetabaseReportsV2Service } from './report-v2/metabase-reports-v2.service';
import { TenantCreationV2Service } from './account-v2/tenantCreationV2.service';
import { BillingService } from './billing/billing.service';
import { ServiceOwnershipService } from './service-ownership/service-ownership.service';
import { VendorOwnershipService } from './service-ownership/vendor-ownership.service';
import { TagsService } from './tags/tags.service';

@NgModule({
  imports: [
    HttpClientModule,
    ApiInterceptorsModule
  ],
  declarations: [],
  providers: [
    DashboardService,
    TenantAccountIdService,
    UploadV2Service,
    SubsscriptionService,
    ParentServiceGroupService,
    TenantV2Service,
    Miso3baseService,
    AuthV2Service,
    DivisionV2Service,
    SiteV2Service,
    ProfileV2Service,
    AddressV2Service,
    ServiceTermService,
    ServiceOwnershipService,
    VendorOwnershipService,
    //EquipmentV2Service,
    ContactV2Service,
    UsersV2Service,
    ReportV2Service,
    CurrencyService,
    NewReportsV2Service,
    VendorDefinedFieldsV2Service,
    ServiceV2Service,
    TenantCreationService,
    TenantVendorService,
    ServiceV2Service,
    CustomFieldsV2Service,
    DocumentV2Service,
    ServiceReportsV2Service,
    VendorResolutionService,
    ProductResolutionService,
    AddressResolutionService,
    MetabaseReportsV2Service,
    TenantCreationV2Service,
    BillingService,
    TagsService
  ],
  exports: []
})

export class Api2Module {
}
