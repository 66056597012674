import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {CustomerImport} from '../../../shared/models/CustomerImport';
import {CustomerAPIRequest} from '../../../shared/models/CustomerAPIRequest';

@Injectable()
export class TenantCustomerImportService {

  private apiUrl: string = '/{tenant}/customer-import';
  private apiRequestUrl: string = '/{tenant}/api-integration-request';

  constructor(private http: HttpClient) {
  }

  create(customerImport: CustomerImport): Observable<CustomerImport> {
    return this.http
      .post<CustomerImport>(this.apiUrl, customerImport)
      .map((response: any) => response.data.customer_import);
  }

  createAPIRequest(apiRequest: CustomerAPIRequest): Observable<CustomerImport> {
    return this.http
      .post(this.apiRequestUrl, apiRequest)
      .map((response: any) => response.data.api_integration_request);
  }

  list(): Observable<CustomerImport[]> {
    return this
      .http.get<CustomerImport[]>(this.apiUrl)
      .map((response: any) => response.data.customer_imports);
  }

  getById(id: string): Observable<CustomerImport> {
    return this
      .http.get<CustomerImport>(`${this.apiUrl}/${id}`)
      .map((response: any) => response.data.customer_import);
  }

}
