import {Component, Input, OnInit} from '@angular/core';
import * as ASActions from '../../../state/advanced-search/actions';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../state/advanced-search/reducers';
import {FilterBucket} from '../../../ng-models/search/FilterBucket.interface';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';
import {TenantCustomFieldService} from 'src/libs/api/tenant/tenantCustomFields.service';
import * as appDataReducer from '../../../state/app-data/reducers';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';

@Component({
  selector: 'mi-advanced-search-filter-table-header',
  templateUrl: './advanced-search-filter-table-header.component.html',
  styleUrls: ['./advanced-search-filter-table-header.component.scss'],
  preserveWhitespaces: true
})

export class AdvancedSearchFilterTableHeaderComponent extends AbstractPageComponent implements OnInit {

  @Input() filter_name: string;
  @Input() title: string;
  @Input() isCustomField: boolean = false
  filters: FilterBucket[] = [];
  selectedFilters: FilterBucket[] = [];
  loadError: string = '';

  constructor(
    private store: Store<ASReducer.State>,
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.results && data.results.aggregations && data.results.aggregations[this.filter_name]) {
            this.filters = data.results.aggregations[this.filter_name].buckets as FilterBucket[];
            this.selectedFilters = this.filters.filter(bucket => bucket.selected);
            if (this.selectedFilters.length) {
              this.filters = this.selectedFilters;
            }
          } else {
            this.filters = [];
          }
        },
        e => this.loadError = e);
  }

  onShowAllClicked() {
    this.store.dispatch(new ASActions.AsFilterGroupShowAll({
      filter: this.filter_name,
      selectedFilters: this.selectedFilters,
      isCustomField: this.isCustomField,
      filterLabel: this.title
    }));
  }
}
