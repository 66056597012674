import { Component, Input, TemplateRef, ViewChild, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import * as ASActions from "../../../state/advanced-search/actions";
import { NO_DATA } from "../../../ng-models/SectionDataTable.model";
import { NOTIFICATION_SEVERITY } from "../../../utils/notificationSeverity";
import { SectionDataTableColumn } from "../../../ng-models/SectionDataColumn.interface";
import { AbstractPageComponent } from "../../../pages/abstract-page/abstract-page.component";
import * as appDataReducer from "../../../state/app-data/reducers";
import { ModalService } from '@independer/ng-modal';
import { UsersManagementFormModalComponent } from '../../users-management-form-modal/users-management-form-modal.component';
import { UsersAccessFormModalComponent } from '../../users-management-form/users-access-form-modal/users-access-form-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaginationPayload } from 'src/shared/models/MiPagination';
import { UsersV2Service } from 'src/libs/api2/users-v2/users-v2.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from "rxjs";

@Component({
  selector: 'mi-advanced-search-tenant-user-results',
  templateUrl: './advanced-search-tenant-user-results.component.html',
  styleUrls: ['./advanced-search-tenant-user-results.component.scss']
})

export class AdvancedSearchTenantUserResultsComponent extends AbstractPageComponent implements OnInit {

  @Input() canEditRoleOnBu: boolean = false;
  @Input() employeeList: boolean = true;
  hits: any[];
  resultsLoaded: boolean = false;
  queryParams: Params;
  totalRow: number;
  pageNumber: number = 0;
  pageSizeData: number[] = [10, 20, 30, 40, 50];
  selectedPageSize: number = this.pageSizeData[0];
  totalPages: number = 1
  searchParam: Subject<string> = new Subject<string>();
  userSearchParam: string = ""
  error: string
  tenantId: string
  userType: string
  sortByColumn: string
  selectedColumnOrder: string

  readonly NO_DATA: string = NO_DATA;
  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;
  @ViewChild('mrcTmpl', { static: true }) mrcTmpl: TemplateRef<any>;
  @ViewChild('contactTmpl', { static: true }) contactTmpl: TemplateRef<any>;
  @ViewChild('mobileTmpl', { static: true }) mobileTmpl: TemplateRef<any>;
  @ViewChild('costCenterTmpl', { static: true }) costCenterTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', { static: true }) statusTmpl: TemplateRef<any>;
  @ViewChild('miMisoAccessTmpl', { static: true }) miMisoAccessTmpl: TemplateRef<any>;
  // @ViewChild('misoAccessTmpl', { static: true }) misoAccessTmpl: TemplateRef<any>;
  @ViewChild('vendorTmpl', { static: true }) vendorTmpl: TemplateRef<any>;
  @ViewChild('userAccessTmpl', { static: true }) userAccessTmpl: TemplateRef<any>;

  contactsColumns: SectionDataTableColumn[] = [];

  constructor(
    private store: Store<any>,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private usersV2Service: UsersV2Service,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.getTenantDetails();
    this.initializeTable();
    this.searchParam.pipe(debounceTime(1000))
      .subscribe(searchParam => {
        this.userSearchParam = searchParam
        this.getTenantUsers();
      });

    // this.activatedRoute
    //   .queryParams
    //   .takeUntil(this.destroy$)
    //   .subscribe((queryParams: Params) => {
    //     this.queryParams = queryParams;
    //     switch (this.router.url.substring(this.router.url.lastIndexOf('/') + 1)) {
    //       case "all":
    //         this.userType = ""
    //         break;
    //       case "end-users":
    //         this.userType = "end_user_email"
    //         break;
    //       case "service-owner":
    //         this.userType = "requestor_email"
    //         break;
    //       case "miso-manager":
    //         this.userType = "assigned_email"
    //         break;
    //       default:
    //         this.userType = ""
    //     }
    //   });
  }

  initializeTable() {
    this.contactsColumns = [
      {
        name: "Employee Name",
        prop: 'full_name',
        cellTemplate: this.contactTmpl,
        sortable: true,
        serverSideSortName: 'first_name'
      },
      {
        name: "Email",
        prop: 'user.email',
        sortable: true,
        serverSideSortName: 'email'
      },
      {
        name: "Mobile Phone",
        prop: 'user.mobile_phone',
        cellTemplate: this.mobileTmpl
      },
      {
        name: "Cost Center",
        prop: 'user.cost_center',
        cellTemplate: this.costCenterTmpl,
        sortable: true,
        serverSideSortName: 'cost_center'
      },
      {
        name: 'Status',
        prop: 'user.is_active',
        cellTemplate: this.statusTmpl,
        sortable: true,
        serverSideSortName: 'is_active'
      },
      {
        name: "MISO ACCESS",
        prop: 'user.miso_access',
        sortable: false,
      },
      {
        name: 'MI-MISO ACCESS',
        prop: 'user.mi_access_level',
        cellTemplate: this.miMisoAccessTmpl,
        sortable: false,
      }
    ];

    if (!this.employeeList) {
      this.contactsColumns.push(
        {
          name: 'Access',
          prop: 'id',
          cellTemplate: this.userAccessTmpl,
          sortable: false
        }
      );
    }
  }

  getTenantDetails() {
    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe(
      (data) => {
        this.tenantId = data.tenantData.domain
        this.getTenantUsers();
      },
      (e) => (this.error = e)
    );
  }

  sortChangeHandler(event) {
    this.sortByColumn = event.column.serverSideSortName
    this.selectedColumnOrder = event.column.sort.toUpperCase()
    this.getTenantUsers()
  }

  searchParamChangeHandler(searchParam = '') {
    const trimmedSearchParam = searchParam.replace(/\s{2,}/g, ' ').trim()
    this.searchParam.next(trimmedSearchParam);
    this.userSearchParam = trimmedSearchParam
    this.pageNumber = 0
  }

  onClearSearch() {
    this.searchParam.next('');
    this.userSearchParam = ""
  }

  getTenantUsers() {
    this.resultsLoaded = false;
    this.usersV2Service
      .getUsersByVendor(this.pageNumber, this.selectedPageSize, this.userSearchParam, this.userType, this.sortByColumn, this.selectedColumnOrder, "cmrc")
      .toPromise()
      .then(data => {
        this.resultsLoaded = true;
        this.hits = data.data
        this.totalRow = data.total
        this.totalPages = this.totalRow > 0 ? Math.ceil(this.totalRow / this.selectedPageSize) : 1
      })
      .catch((e) => (this.error = e));
  }

  paginationChangeHandler(paginationPayload: PaginationPayload) {
    this.pageNumber = paginationPayload.selectedPageNumber - 1
    this.selectedPageSize = paginationPayload.selectedPageSize
    this.getTenantUsers()
  }

  openUserEditModal(userId) {
    const modalRef = this.modalService.open(UsersManagementFormModalComponent, m => {
      m.userId = userId;
      m.canEditRoleOnBu = this.canEditRoleOnBu;
    });
    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results
      const [user] = result;
      if (user) {
        this.getTenantUsers()
        setTimeout(() => {
          this.store.dispatch(new ASActions.AsDeeplinkChanged(this.queryParams));
          //this.store.dispatch(new ShowPageAlertAction({}));
        }, 1000);
      }
    });
  }

  openUserAccessModal(userId, row) {
    const modalRef = this.modalService.open(UsersAccessFormModalComponent, m => {
      m.editId = userId;
      m.userData = row;
      m.canEditRoleOnBu = this.canEditRoleOnBu;
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      //results come in array, as the modal supports multiple returned results
      const [message] = result;
      if (message) {
        setTimeout(() => {
          this.store.dispatch(new ASActions.AsDeeplinkChanged(this.queryParams));
          //this.store.dispatch(new ShowPageAlertAction({}));
        }, 1000);
      }
      if (result[0].updated) {
        this.getTenantUsers()
      }
    });
  }

}