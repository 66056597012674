import { Action } from '@ngrx/store';

export const ActionTypes = {
  RESET: '[Miso:Email List] Reset',
  LOAD_SERVICE_EMAILS: '[Miso:Service Email List] Load',
  LOAD_PARENT_SERVICE_EMAILS: '[Miso:Parent Service Email List] Load',
  LOAD_EMAIL_SUCCESS: '[Miso:Email List] Load Success',
  LOAD_EMAIL_FAIL: '[Miso:Email List] Load Fail'
};

export class ResetEmailListAction implements Action {
  type = ActionTypes.RESET;
  constructor(public payload: any) { }
}

export class LoadServiceEmailsAction implements Action {
  type = ActionTypes.LOAD_SERVICE_EMAILS;
  constructor(public payload: any) { }
}

export class LoadParentServiceEmailsAction implements Action {
  type = ActionTypes.LOAD_PARENT_SERVICE_EMAILS;
  constructor(public payload: any) { }
}

export class LoadEmailsSuccessAction implements Action {
  type = ActionTypes.LOAD_EMAIL_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadEmailsFailAction implements Action {
  type = ActionTypes.LOAD_EMAIL_FAIL;
  constructor(public payload: any) { }
}

export type Actions
  = ResetEmailListAction
  | LoadServiceEmailsAction
  | LoadParentServiceEmailsAction
  | LoadEmailsSuccessAction
  | LoadEmailsFailAction
