import { Component } from '@angular/core';

import * as linkFormatter from '../../../common/utils/linkFormatter';

@Component({
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})

export class ForbiddenComponent {
  constructor() {}

  link = linkFormatter.getAccountsLink();
}
