import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as ParentServicesReducer from '../state/reducers';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import {MiParentService} from '../../../../../shared/models/MiParentService';

@Component({
  selector: 'mi-parent-service-get-quote',
  templateUrl: './parent-service-get-quote.component.html',
  styleUrls: ['./parent-service-get-quote.component.scss']
})

export class ParentServiceGetQuoteComponent extends AbstractPageComponent implements OnInit {

  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;

  constructor(
    private store: Store<ParentServicesReducer.State>
  ) {
    super();
  }

  ngOnInit() {

    this.pageLoaded$ = this.store.select(ParentServicesReducer.ParentServicePageLoaded);

    this.store
      .select(ParentServicesReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentService) {

            this.parentService = data.parentService;

          }
        }
      );
  }

}
