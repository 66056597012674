import {NgModule} from '@angular/core';
import {ServiceSingleCreateComponent} from './service-single-create.component';
import {SharedModule} from '../../../../../shared/shared.module';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {StoreModule} from "@ngrx/store";
import {reducer as createOrderReducers} from './state/reducer';
import {EffectsModule} from "@ngrx/effects";
import {ContainedStoreEffects} from "./state/effects";
import {HelloWorldService} from '../../../../../libs/api/hello-world.service';
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ConfirmPurchasingVendorComponent} from "./vendor-product/confirm-purchasing-vendor/confirm-purchasing-vendor.component";
import {FindOrCreateVendorStepComponent} from "./vendor-product/find-or-create-vendor-step/find-or-create-vendor-step.component";
import {AccountIdEditComponent} from "./vendor-product/account-id-edit/account-id-edit.component";
import {AccountIdSummaryComponent} from "./vendor-product/account-id-summary/account-id-summary.component";
import {ProductSummaryComponent} from "./vendor-product/product-summary/product-summary.component";
import {OrderContactComponent} from "./order-details/contact/order-contact.component";
import {TenantComponentsModule} from '../../../../components/tenant-components.module';
import {ServiceTrackingDetailsComponent} from "./service-details/service-tracking-details/service-tracking-details.component";
import {ServicesDetailsComponent} from "./service-details/services-details.component";
import {ServiceFinancialsComponent} from "./service-details/service-finanicals/service-financials.component";
import {ServiceUsageComponent} from "./service-details/service-usage/service-usage.component";
import {ServiceAddressComponent} from "./service-details/service-address/service-address.component";
import {ServiceCustomFieldsComponent} from "./service-details/service-custom-fields/service-custom-fields.component";
import {TermsAndConditionsComponent} from "./service_term/terms-and-conditions.component";
import {TermsAndConditionsStepOneComponent} from "./service_term/step-one/terms-and-conditions-step-one.component";
import {TermsAndConditionsStepTwoComponent} from "./service_term/step-two/terms-and-conditions-step-two.component";

@NgModule({
  declarations: [
    ServiceSingleCreateComponent,
    ConfirmPurchasingVendorComponent,
    FindOrCreateVendorStepComponent,
    AccountIdEditComponent,
    AccountIdSummaryComponent,
    ProductSummaryComponent,
    OrderContactComponent,
    ServicesDetailsComponent,
    ServiceTrackingDetailsComponent,
    ServiceFinancialsComponent,
    ServiceUsageComponent,
    ServiceAddressComponent,
    ServiceCustomFieldsComponent,
    TermsAndConditionsComponent,
    TermsAndConditionsStepOneComponent,
    TermsAndConditionsStepTwoComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    StoreModule.forFeature('order-create-store', createOrderReducers),
    EffectsModule.forFeature([ContainedStoreEffects])
  ],
  providers: [HelloWorldService],
  exports: [
    ServiceSingleCreateComponent,
    ConfirmPurchasingVendorComponent,
    FindOrCreateVendorStepComponent,
    AccountIdEditComponent,
    AccountIdSummaryComponent,
    ProductSummaryComponent,
    OrderContactComponent,
    ServicesDetailsComponent,
    ServiceTrackingDetailsComponent,
    ServiceFinancialsComponent,
    ServiceUsageComponent,
    ServiceAddressComponent,
    ServiceCustomFieldsComponent,
    TermsAndConditionsComponent,
    TermsAndConditionsStepOneComponent,
    TermsAndConditionsStepTwoComponent,
  ]
})
export class ServiceSingleCreateModule {
}
