import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TelephoneSimpleInputComponent} from "./telephone-simple-input/telephone-simple-input.component";
import {NumbersOnlyInputComponent} from "./numbers-only-input/numbers-only-input.component";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [TelephoneSimpleInputComponent,  NumbersOnlyInputComponent],
  providers: [],
  exports: [TelephoneSimpleInputComponent, NumbersOnlyInputComponent]
})
export class FormComponentsModule {
}
