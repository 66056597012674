<div class="email-recipients"
     *ngIf="loaded; else loading">

  <div class="header row">
    <div class="col-1">To:</div>
    <div class="col-2">CC:</div>
    <div class="col-3"></div>
  </div>

  <mi-email-recipient
    *ngFor="let recipient of addedRecipients; let i = index"
    [recipient]="recipient"
    (typeSelected)="onRecipientSelected($event, false)"
    (remove)="onRecipientSelected($event, true)"
    [toChecked]="recipient===defaultRecipients[i]? true: false">
  </mi-email-recipient>

  <mi-email-recipient-search
    [vendorName] = "vendorName"
    [otherRecipients] = "otherRecipients"
    [vendorRecipients] = "vendorRecipients"
    [internalRecipients]= "internalRecipients"
    (recipientSelected)="onAddRecipient($event)">

  </mi-email-recipient-search>

  <div [ngClass]="{'addContact': true, 'formOpen':addFormOpen}">

    <div *ngIf="addFormOpen" style="margin-top:20px;">
      <h2>Create Contact</h2>
      <mi-contact-form *ngIf="addFormOpen"
                       (canceled)="onCloseAddContactForm()"
                       (contactComplete)="onNewContactCreated($event)">
      </mi-contact-form>
    </div>

    <button *ngIf="!addFormOpen"
            mi-button
            buttonTheme="dark-theme"
            (click)="onAddContactClicked()">
      Create Contact
    </button>

  </div>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
