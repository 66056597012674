<modal class="extra-wide" [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{title}} - {{userData.user.first_name}} {{userData.user.last_name}} ({{userData.user.email}})</h4>
  </modal-header>
  <modal-content>
    <div *ngIf="tenantUserBUTree && !restrictAccess">
      <mi-opacity-loader [ready]="!loading">
        <mi-server-error [error]="loadError"></mi-server-error>
    
        <div *ngIf="successMessage != null">
          <mi-alert-item [alertType]="'SUCCESS'">{{ successMessage }}</mi-alert-item>
        </div>
    
        <div>
          <table class="accessManageTable standard" width="100%">
            <thead>
              <tr>
                <th class="businessUnitLabel">
                  Organization
                </th>
                <th class="businessUnitLabel">
                  Division
                </th>
                <th class="businessUnitLabel">
                  Team
                </th>
                <th>
                  Super Admin
                </th>
                <th>
                  Admin
                </th>
                <th>
                  User
                </th>
                <th>
                  No Access
                </th>
                <th>
                  Cost Center
                </th>
                <th class="access-level-header">
                  Mi Access
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="tenant-row">
                <td>
                  {{tenantUserBUTree.name}}
                </td>
                <td></td>
                <td></td>
                <td>
                  <input type="radio"
                    [name]="tenantUserBUTree.id"
                    [value]="roleByName['Client Super Admin']"
                    (change)="checkboxChange($event)"
                    [(ngModel)]="tenantUserBUTree.role_id"
                    [disabled]="superAdminDisabled">
                </td>
                <td>
                  <input type="radio"
                    [name]="tenantUserBUTree.id"
                    [value]="roleByName['Client Admin']"
                    (change)="checkboxChange($event)"
                    [(ngModel)]="tenantUserBUTree.role_id">
                </td>
                <td>
                  <input type="radio"
                    [name]="tenantUserBUTree.id"
                    [value]="roleByName['Client User']"
                    (change)="checkboxChange($event)"
                    [(ngModel)]="tenantUserBUTree.role_id">
                </td>
                <td>
                  <input type="radio"
                    [name]="tenantUserBUTree.id"
                    [value]="roleByName['None']"
                    (change)="checkboxChange($event)"
                    [(ngModel)]="tenantUserBUTree.role_id">
                </td>
                <td class="cost-center-td">
                  <input type="radio"
                    name="costCenter"
                    [value]="tenantUserBUTree.id"
                    (change)="checkboxChange($event)"
                    [(ngModel)]="cost_center_id">
                </td>
                <td class="access-level">
                  <select [(ngModel)]="mi_access_level" (change)="accessChangeHandler($event.target.value)">
                    <option *ngFor="let option of accessLevelOptions" [value]="option.value" [disabled]="option.disable">{{ option.label
                      }}</option>
                  </select>
                </td>
              </tr>
              <ng-container *ngFor="let division of tenantUserBUTree.divisions; let i=index">
                <tr class="division-row">
                  <td></td>
                  <td>{{division.name}}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <input type="radio"
                      [name]="division.id"
                      [disabled]="tenantUserBUTree.role_id === roleByName['Client Super Admin'] ||
                        tenantUserBUTree.role_id === roleByName['Client Admin']"
                      [value]="roleByName['Client Admin']"
                      (change)="checkboxChange($event)"
                      [(ngModel)]="tenantUserBUTree.divisions[i].role_id">
                  </td>
                  <td>
                    <input type="radio"
                      [name]="division.id"
                      [disabled]="tenantUserBUTree.role_id === roleByName['Client Super Admin'] ||
                        tenantUserBUTree.role_id === roleByName['Client Admin']"
                      [value]="roleByName['Client User']"
                      (change)="checkboxChange($event)"
                      [(ngModel)]="tenantUserBUTree.divisions[i].role_id">
                  </td>
                  <td>
                    <input type="radio"
                      [disabled]="tenantUserBUTree.role_id !== roleByName['None']"
                      [name]="division.id"
                      [value]="roleByName['None']"
                      (change)="checkboxChange($event)"
                      [(ngModel)]="tenantUserBUTree.divisions[i].role_id">
                  </td>
                  <td class="cost-center-td">
                    <input type="radio"
                      name="costCenter"
                      [value]="division.id"
                      (change)="checkboxChange($event)"
                      [(ngModel)]="cost_center_id">
                  </td>
                </tr>
                <ng-container *ngFor="let site of division.sites; let z=index">
                  <tr class="site-row">
                    <td></td>
                    <td></td>
                    <td>{{site.name}}</td>
                    <td></td>
                    <td>
                      <input type="radio"
                        [name]="site.id"
                        [disabled]="division.role_id === roleByName['Client Admin']"
                        [value]="roleByName['Client Admin']"
                        (change)="checkboxChange($event)"
                        [(ngModel)]="tenantUserBUTree.divisions[i].sites[z].role_id">
                    </td>
                    <td>
                      <input type="radio"
                        [name]="site.id"
                        [disabled]="division.role_id === roleByName['Client Admin']"
                        [value]="roleByName['Client User']"
                        (change)="checkboxChange($event)"
                        [(ngModel)]="tenantUserBUTree.divisions[i].sites[z].role_id">
                    </td>
                    <td>
                      <input type="radio"
                        [name]="site.id"
                        [disabled]="division.role_id !== roleByName['None']"
                        [value]="roleByName['None']"
                        (change)="checkboxChange($event)"
                        [(ngModel)]="tenantUserBUTree.divisions[i].sites[z].role_id">
                    </td>
                    <td class="cost-center-td">
                      <input type="radio"
                        name="costCenter"
                        [value]="site.id"
                        (change)="checkboxChange($event)"
                        [(ngModel)]="cost_center_id">
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
      <div class="button-container">
        <!-- <a *ngIf="canEditRoleOnBu && mode != 'CREATE'" (click)="showResetPassword($event)"
            buttonTheme="light-theme"
            class="button--cancel"
            mi-button> -->
          <!--Reset Password-->
          <!-- Set Password
        </a> -->
  
        <a (click)="close($event)"
            buttonTheme="light-theme"
            class="button--cancel"
            mi-button>
          Cancel
        </a>
  
        <button *ngIf="canEditRoleOnBu"
                mi-button
                (click)="onFormSubmit()">
          Update
        </button>
      </div>
    </div>
    
      <form *ngIf="showResetPass && mode != 'CREATE'" [(formGroup)]="resetForm" (ngSubmit)="onPasswordSubmit(resetForm.value)">
        <mi-extended-input [labelText]="'Password'"
                            [labelRequired]="true"
                            [inputControl]="resetForm.controls['password']"
                            [inputErrors]="resetForm.controls['password'].errors"
                            [inputTouched]="resetForm.controls['password'].touched"
                            [errorDefs]="{required: 'Password is required', passwordValidator: 'Please check your password.'}">
          <input id="password"
                  name="password"
                  formControlName="password"
                  autofocus="autofocus"
                  autocomplete="password"
                  type="password">
        </mi-extended-input>
        <mi-extended-input [labelText]="'Confirm Password'"
                            [labelRequired]="true"
                            [inputControl]="resetForm.controls['password_confirm']"
                            [inputErrors]="resetForm.controls['password_confirm'].errors"
                            [inputTouched]="resetForm.controls['password_confirm'].touched"
                            [errorDefs]="{required: 'Password is required', passwordValidator: 'Please check your password.'}">
          <input id="password_confirm"
                  name="password_confirm"
                  formControlName="password_confirm"
                  autocomplete="password"
                  type="password">
        </mi-extended-input>
    
        <button mi-button
                buttonTheme="dark-theme"
                class="full-width"
                type="submit"
                [disabled]="!resetForm.valid">
          {{'ACCOUNTS.PASSWORD_RULES.CTA' | translate }}
        </button>
      </form>
      </mi-opacity-loader>
    </div>
    <div *ngIf="restrictAccess">
      <label>You cannot Edit Access for this user.</label>
    </div>
  </modal-content>
</modal>
