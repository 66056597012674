import {Component, Output, EventEmitter, Input} from "@angular/core";
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../state/advanced-search/reducers';
import { TenantCustomFieldService } from "../../../../libs/api/tenant/tenantCustomFields.service";
import { AbstractPageComponent } from "../../../pages/abstract-page/abstract-page.component";
import { CustomField } from "../../../models/CustomField";
import { FilterBucket } from "../../../ng-models/search/FilterBucket.interface";
import { CUSTOM_FIELD_TYPE_PHONE } from "../../../../common/utils/sharedConstants";

@Component({
  selector: 'advanced-search-fiilter-configuration',
  templateUrl: './advanced-search-fiilter-configuration.component.html',
  styleUrls: ['./advanced-search-fiilter-configuration.component.scss']
})

export class AdvancedSearchFilterConfigureComponent extends AbstractPageComponent {
  @Output() configurationComplete: EventEmitter<any> = new EventEmitter<any>();
  @Input() eventFilter: boolean = false;

  loadError:string;
  loading: boolean = true;
  customFields:CustomField[]=[];
  tenantCustomFieldServiceIns: TenantCustomFieldService;
  filters: FilterBucket[] = [];

  //assign future filter default state
  visibleFilters: any = {'parent_service_status' : true};

  constructor(
    private store: Store<ASReducer.State>,
    private tenantCustomFieldService: TenantCustomFieldService
  ) {

    super();

    this.store.select(ASReducer.getASData)
    .takeUntil(this.destroy$)
    .subscribe(
      data => {
          this.visibleFilters = {...this.visibleFilters, ...data.visibleFilters};
      });

    if(this.eventFilter){
      this.loading=false;
    } else {
      this.tenantCustomFieldService.listCustomFields().subscribe(
        (allCustomFields) => {
         this.customFields=allCustomFields.filter(cf => {
           return cf.type != CUSTOM_FIELD_TYPE_PHONE;
         });
         this.loading=false;
            },
        (e) => this.loadError = e
      );
    }
  }

  toggle(evt, key) {
    let fkey: string=key;
      this.visibleFilters[fkey]=evt.target.checked;
  }

  onApplyClicked($event) {
    $event.preventDefault();
    this.configurationComplete.emit(this.visibleFilters);
  }
}
