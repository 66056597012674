<div class="section-title note-info">
  <ng-container [ngSwitch]="headlineLevel">
    <h1 *ngSwitchCase="1">
      {{title}}
    </h1>
    <h2 *ngSwitchCase="2">
      {{title}}
    </h2>
    <h3 *ngSwitchCase="3">
      {{title}}
    </h3>
  </ng-container>
  <ng-content></ng-content>
</div>

