import {Component, EventEmitter, Input, Output} from "@angular/core";
import {EmailFormRecipient} from "../../../../shared/ng-models/EmailFormRecipient.interface";
import {EMAIL_CC, EMAIL_TO} from "../../../../common/utils/sharedConstants";


@Component({
  selector: 'mi-email-recipient',
  styleUrls: ['./email-recipient.component.scss', '../email-recipient-select/email-recipient-select.component.scss'],
  templateUrl: './email-recipient.component.html'
})
export class EmailRecipientComponent {


  readonly TO_RECIPIENT: string = EMAIL_TO;
  readonly CC_RECIPIENT: string = EMAIL_CC;

  @Input() recipient: EmailFormRecipient;
  @Output() typeSelected: EventEmitter<EmailFormRecipient> = new EventEmitter<EmailFormRecipient>();
  @Output() remove: EventEmitter<EmailFormRecipient> = new EventEmitter<EmailFormRecipient>();

  @Input() toChecked: boolean = false;
  ccChecked: boolean = false;

  constructor() {

  }

  onRemoveClick(): void {
    this.toChecked = false;
    this.ccChecked = false;
    this.remove.emit({...this.recipient});
  }

  onToggleSelect(e): void {

    let checked = e.target.checked;
    let newRecipient: EmailFormRecipient = {...this.recipient};
    let value = e.target.value;



    if (value === this.TO_RECIPIENT) {
      this.toChecked = checked;
      if (checked) {
        newRecipient.addressField = this.TO_RECIPIENT;
        this.ccChecked = false;
      }
    } else if (value === this.CC_RECIPIENT) {
      this.ccChecked = checked;
      if (checked) {
        newRecipient.addressField = this.CC_RECIPIENT;
        this.toChecked = false;
      }
    }

    this.typeSelected.emit(newRecipient);
  }
}
