<div class="service-details-header-item service-details-vendor"
  routerLink="/vendors/{{serviceDetails.vendor.purchasing_vendor_display_id}}">
  <ng-container *ngIf="vendorLogoUrl; else fallback">
    <img [src]="vendorLogoUrl" class="vendor-logo" />
  </ng-container>
  
  <ng-template #fallback>
    <img class="vendor-logo" src="shared/assets/images/vendor-logo-placeholder.svg" />
  </ng-template>

  <div *ngIf="!vendorLogoUrl" class="vendor-title cursor-pointer">
    {{serviceDetails.vendor.purchasing_vendor_name}}
  </div>

  <div class="copy">
    <div *ngIf="!this.showSubline" class="title" [ngClass]="titleClasses">
      <div class="product-name" [title]="serviceDetails.vendor.purchasing_vendor_name">
        {{serviceDetails.service_data.product_name}}
      </div>
    </div>
    <div *ngIf="this.showSubline" class="title" [ngClass]="titleClasses">
      <div class="product-name" [title]="serviceDetails.vendor.purchasing_vendor_name">
        {{serviceDetails.vendor.purchasing_vendor_name}}
      </div>
    </div>
    <ng-container *ngIf="serviceDetails.service_data.total_recurring_cost;else noData">
      {{(serviceDetails.service_data.total_recurring_cost) | currency:( serviceDetails.service_data.currency
      ||'USD'):'symbol':'1.0-0'}}
      {{'MONTHS_QTY_ADV' |
      translate:{count:
      serviceDetails.service_data.billing_frequency}|translateSelector:serviceDetails.service_data.billing_frequency
      }}
    </ng-container>
    <div>
      {{serviceDetails.events_and_service_status.service_status | titlecase }}
      ({{serviceDetails.general.family_designation}})
    </div>
  </div>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #noData>
  {{NO_DATA}}
</ng-template>