<ng-container>

  <mi-section-title [title]="title">
    <ng-content></ng-content>
  </mi-section-title>

  <div *ngIf="tableData && tableData.rows.length; else noResults">
    <mi-sortable-table externalClasses="serviceDetails" [rows]="tableData.rows"
                       [columns]="tableData.columns"></mi-sortable-table>
  </div>

  <ng-template #noResults>
      <span class="no-results">
        <ng-container *ngIf="noResultsText; else defaultNoResultsText">
          {{noResultsText}}
        </ng-container>
        <ng-template #defaultNoResultsText>
          {{'VENDORS.DEFAULT'|translate: {'title': title} }}
        </ng-template>
      </span>
  </ng-template>

  <ng-template #currencyTmpl let-row="row" let-value="value">
    {{ value | round | currency:'USD':'symbol':'1.00' }}
  </ng-template>

  <ng-template #addressLink let-row="row" let-value="value">
    <a routerLink="../../../addresses/{{ row.display_id }}">{{ row.formatted_address }}</a>
  </ng-template>

</ng-container>
