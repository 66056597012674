import {Component, Input, OnInit} from '@angular/core';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {FilterBucket} from '../../../ng-models/search/FilterBucket.interface';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';
import {TenantCustomFieldService} from 'src/libs/api/tenant/tenantCustomFields.service';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';

@Component({
  selector: 'mi-advanced-search-filter-group',
  templateUrl: './advanced-search-filter-group.component.html',
  styleUrls: ['./advanced-search-filter-group.component.scss'],
  preserveWhitespaces: true
})

export class AdvancedSearchFilterGroupComponent extends AbstractPageComponent implements OnInit {

  @Input() filter_name: string;
  @Input() title: string;
  @Input() includeCount: boolean = true;
  @Input() translatePrefix: boolean = false;
  @Input() isCustomField: boolean = false;
  @Input() ignoreVisibleConfig: boolean = false;
  
  businessUnitsByIDs: any = {};
  filters: FilterBucket[] = [];
  selectedFilters: FilterBucket[] = [];
  isVisible: boolean = true;
  loadError: string = '';
  customFields: any[];
  showAll: boolean = false;

  constructor(
    private store: Store<ASReducer.State>,
    private tenantService: TenantV2Service,
    private tenantCustomFieldService: TenantCustomFieldService
  ) {
    super();
  }

  ngOnInit() {
    if(this.filter_name === "business_unit_id"){
      this.getBus();
    }
    else{
      this.init();
    }
  }

  init()
  {
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.results && data.results.aggregations && data.results.aggregations[this.filter_name]) {
            this.filters = data.results.aggregations[this.filter_name].buckets as FilterBucket[];
            this.selectedFilters = this.filters.filter(bucket => bucket.selected);
            this.showAll = this.filters.length > 5;

            if (this.selectedFilters.length) {
              this.showAll = true;
              this.filters = this.selectedFilters;
            }
            
            if (this.filters.length > 5) {
              this.filters = this.filters.slice(0, 5);
            }

            if (this.filter_name === 'service_associations') {
              this.tenantCustomFieldService.listCustomFields().takeUntil(this.destroy$).subscribe(
                (allCustomFields) => {
                  this.customFields = allCustomFields.filter(cf => {
                    return cf.type === 'CONTACT';
                  });
                  if (this.filters) {
                    this.filters.forEach((element, i) => {
                      const filterCF = this.customFields.filter(cf => {
                        return cf._id.toString() === element.key.toString();
                      });
                      if (filterCF.length) {
                        this.filters[i].label = filterCF[0].label;
                      }
                    });
                  }
                },
                (e) => this.loadError = e
              );
            }

          } else {
            this.filters = [];
            this.showAll = false;
          }

          // needs to be reactive to the asData state, similar to:
          this.isVisible = this.ignoreVisibleConfig || data.visibleFilters[this.filter_name];
        }
      );
  }

  getBus(){
    const tenantStateData = this.store.select(appDataReducer.getAppData);

    tenantStateData
      .first()
      .subscribe((data)=>{
        this.tenantService.getBusinessUnits({owner: data.tenantData.domain})
        .takeUntil(this.destroy$)
        .subscribe(bus => {
          bus.map(bu => {
            this.businessUnitsByIDs[bu.id] = bu;
          });
          this.init();
        })
      },
      e => this.loadError = e);
  }

  toggle(evt, key) {

    let newFilters: string[] = this.selectedFilters.map(bucket => bucket.key);

    if (evt.target.checked) {
      // we want to remove the filter
      newFilters.push(key);
    } else {
      newFilters = newFilters.filter(item => item !== key);
    }

    const newQuery = {};

    newQuery[this.filter_name] = newFilters;

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }

  onShowAllClicked() {
    this.store.dispatch(new ASActions.AsFilterGroupShowAll({
      filter: this.filter_name,
      selectedFilters: this.selectedFilters,
      isCustomField: this.isCustomField,
      filterLabel: this.title
    }));
  }
}
