import {Component, Input} from "@angular/core";
import {Contact} from "../../../shared/models/Contact";
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";

@Component({
  selector: 'mi-contact-details-avatar',
  templateUrl: './contact-details-avatar.component.html',
  styleUrls: ['./contact-details-avatar.component.scss']
})

export class ContactDetailsAvatarComponent{

  @Input() avatarWidth: number = 200;
  @Input() avatarHeight: number = 200;
  @Input() contact: Contact;

  NO_DATA: string = NO_DATA;

  constructor(){}

}
