import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {ISecureUser, createSecureUser} from '../../../common/security/secureUser';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantUsersService {

  private apiUrlGetUsers = '/{tenant}/users';

  constructor(private http: HttpClient) {
  }

  getUsers(excludeRoles: Array<string> = []): Observable<ISecureUser[]> {
    let params = new HttpParams({fromString: `excludeRoles[]=${excludeRoles}`});
    return this.http
      .get(`${this.apiUrlGetUsers}`, {params})
      .map((response: any) => response.data.users.map(createSecureUser) as ISecureUser[]);
  }

}
