<div class="tab-title-header">
  <h2>{{'TENANT.VENDOR_AND_PRODUCT_TITLE' | translate}}</h2>
</div>
<div class="card-1">
  <ng-container>
    <dl class="section-details ">
      <div>
        <dt>Purchasing Vendor</dt>
        <dd>
          <mi-id-link [id]="serviceDetails.vendor.purchasing_vendor_display_id">
          </mi-id-link>
          {{serviceDetails.vendor.purchasing_vendor_name}}
        </dd>
      </div>
      <div>
        <dt>Manufacturing Vendor</dt>
        <dd>
          <mi-id-link [id]="serviceDetails.vendor.purchasing_vendor_display_id">
          </mi-id-link>
          {{serviceDetails.vendor.purchasing_vendor_name}}
        </dd>
      </div>
      <div>
        <dt>Account Identifier </dt>
        <dd>
          <mi-id-link [id]="serviceDetails.vendor.accountid_display_id">
          </mi-id-link>
          {{serviceDetails.vendor.account_id_label}}
        </dd>
      </div>
      <div>
        <dt>Product</dt>
        <dd>
          <!-- <mi-id-link [id]="serviceDetails.service_data.product_display_id">
          </mi-id-link> -->
          {{serviceDetails.service_data.product_display_id +' '+ serviceDetails.service_data.product_name}}<br />
        </dd>
      </div>
      <div>
        <dt>Category</dt>
        <dd>{{serviceDetails.service_data.product_category_name}}<br />
        </dd>
      </div>
      <!-- <div>
        <dt>Manufacturing Vendor</dt>
        <dd>{{serviceDetails.vendor.manufacturing_vendor_name}}<br />
        </dd>
      </div> -->
      <!-- <div *ngFor="let productField of miService.product_v2.product_properties" mi-product-field-static-item [productField]="productField"></div> -->
    </dl>
  </ng-container>
  <!-- <a class="edit-link" (click)="onToggleExpand()" *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a> -->

  <!-- <mi-server-error [error]="error"></mi-server-error> -->

  <!-- <div class="vendor-open" *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
      <form>
        <dl class="section-details">
          <div>
            <dt>Service Provider 123</dt>
            <dd>
              {{this.serviceDetails.vendor.purchasing_vendor_name}}
            </dd>
          </div>

          <div class="one-third-edit">
            <dt>Customer Account ID</dt>

            <dd>
              <mi-find-or-create-account-id [vendor]="miService.purchasing_vendor_v2" [currentAccountId]="miService.account_id_v2"
                (accountSelected)="onAccountIdSelected($event)" [commandGetAccountId]="commandGetAccountId">
              </mi-find-or-create-account-id>
            </dd>  
          </div>

          <div class="one-third-edit">
            <dt>Product</dt>
            <dd class="flex">
              <div class="no-wrap">
                {{serviceDetails.service_data.product_name}}<br />
                <span *ngIf="miService.product_v2.product_name!==miService.product.actual_product.product_name"
                  class="smaller">{{miService.product.actual_product.product_name}}</span>
              </div>
              <div class="suffix">
                <a mi-button
                  class="no-wrap"
                  buttonTheme="dark-theme"
                  (click)="openSelectProductModal($event)">
                  Change Product
                </a>
              </div>
            </dd>
          </div>
        </dl>
        <div class="button-container">
          <button type="button" mi-button buttonTheme="light-theme" (click)="onToggleExpand()">Cancel</button>
          <button mi-button type="submit" (click)="getAccountId()">Apply</button>
        </div>
      </form>
    </mi-opacity-loader>
  </div> -->


  <!-- <ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template> -->
</div>