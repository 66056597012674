import {Component} from '@angular/core';
import {SEARCH_TYPE_EMPLOYEE, EMAIL_KEY} from "../../../../../components/detail-search/detail-search.component";

@Component({
  templateUrl: './employee-search-events.component.html'
})

export class EmployeeSearchEventsComponent {
  readonly SEARCH_TYPE_EMPLOYEE: string = SEARCH_TYPE_EMPLOYEE;
  queryParams: any = {
    "employee[]": EMAIL_KEY
  };
}
