import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
// import { createLogger, LOG_LEVELS } from "../../../../../shared/logger";
import { AbstractPageComponent } from "../../../../../shared/pages/abstract-page/abstract-page.component";
// import {TenantAddressService} from "../../../../../libs/api/tenant/tenantAddress.service";
import { NO_DATA } from "../../../../../shared/ng-models/SectionDataTable.model";
// import {TenantSearchService} from "../../../../../libs/api/tenantSearch/tenantSearch.service";
// import {MiServiceSearchResults} from "../../../../../shared/models/SearchResults";
// import {TypeSummaryBucket} from "../../../../../shared/ng-models/search/FilterBucket.interface";
import { TenantProductsService } from '../../../../../libs/api/tenant/tenantProducts';
// import { TenantVendors } from '../../../../../libs/api/tenant/tenantVendors';
import { Product } from '../../../../../shared/models/Product';

// const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  styleUrls: ['./product-detail.component.scss'],
  templateUrl: './product-detail.component.html'
})

export class ProductDetailComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  product: Product;
  display_id: string;
  error: string = '';
  locations: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private tenantProductService: TenantProductsService,
    // private tenantVendorsService: TenantVendors,
    // private tenantSearchService: TenantSearchService
  ) {
    super();

  }

  ngOnInit() {
    this.activatedRoute
      .parent
      .params
      .takeUntil(this.destroy$)
      .subscribe((queryParams: Params) => {
        this.display_id = queryParams.display_id;
        this.getProductDetails()
      });
    // this.activatedRoute
    //   .parent
    //   .params
    //   .switchMap((queryParams: Params) => {
    //     this.display_id = queryParams.display_id;
    //     return this.tenantProductService.getProductByDisplayId(this.display_id);
    //   })
    //   .switchMap(res => {
    //     // We have the product data:
    //     this.product = res as Product;
    //     return this.tenantVendorsService.getVendorBytenantVendorId(this.product.tenant_vendor_id).
    //       then((tenantVendor) => {
    //         this.product.product_vendor = {
    //           ...tenantVendor
    //         }
    //     });
    //     // get the locationsSummary
    //     //return this.tenantSearchService.typeSummary({'product_id[]': this.product._id});
    //     // return this.tenantSearchService.typeSummary({'product_id[]': this.product.id});
    //   })
    //   .catch(e => this.error = e)
    //   .takeUntil(this.destroy$)
    //   .subscribe(  (productResults: MiServiceSearchResults) => {
    //     // we now have both portions of data.
    //     if('product_id' in productResults.aggregations){
    //       const locationBuckets  = productResults.aggregations['product_id'].buckets as TypeSummaryBucket[];
    //     }

    //   });

    // console.groupEnd();
  }

  getProductDetails() {
    this.tenantProductService
      .getProductByProductDisplayId(this.display_id)
      .toPromise()
      .then(data => {
        this.product = data as Product; // <-- Product Data
      }
      )
      .catch((e) => (this.error = e));
  }
}
