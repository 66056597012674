import { createFeatureSelector } from '@ngrx/store';
import * as TenantBillingAccountActions from './actions';
import { TenantBillingAccount } from "src/shared/models/Billing";

export interface State {
  result: TenantBillingAccount;
}

const initialState: State = {
  result: {
  },
};

export function reducer(state = initialState, action: TenantBillingAccountActions.Actions): State {
  switch (action.type) {
    case TenantBillingAccountActions.ActionTypes.GET_DATA:
      return {
        ...state
      };
    case TenantBillingAccountActions.ActionTypes.SET_DATA:
      return {
        ...state,
        result: {
          ...action.payload
        }
      };
    case TenantBillingAccountActions.ActionTypes.RESET:
      return {
        ...initialState
      }
    default: {
      return state;
    }
  }
}

export const getTenantBillingAccountData = createFeatureSelector<State>('tenantBillingAccount');