import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiService} from '../../../shared/models/MiService';
import {Tenant} from '../../../shared/models/Tenant';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class CheckoutService {

  private apiTimezoneCrud = '/account/checkout-select-timezone';

  constructor(private http: HttpClient) {
  }

  getCheckoutStart(access_feature, domain): Observable<Tenant> {
    return this.http
      .get(`/account/payment-plan-options?access_feature=${access_feature}`)
      .map((response: any) => response.data.tenant);
  }

  setTimezone(accessFeature: string, timezone: string): Observable<MiService> {
    return this.http
      .post(this.apiTimezoneCrud, {
        accessFeature,
        timezone
      })
      .map((response: any) => response.data.timezone);
  }

}

