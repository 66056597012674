import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import {Telephone} from '../../models/Telephone';
import {formatNoneUsPhone, formatUsPhone} from '../../../common/utils/stringFormatter';
import {TELEPHONE_LOCALITY_US} from '../../../common/utils/sharedConstants';

export function validateTelephone(c: FormControl) {

  if (c.value) {
    const value: Telephone = c.value;
    if (value.number !== Number.MIN_SAFE_INTEGER && value.number !== 0) {
      // This input has been changed.
      const error = {'badTelephone': {value: value.display}};
      if (value.locality === 'US') {
        return (value.number + '').length > 9 ? null : error;
      }
    }
  }
  return true;
}


@Component({
  selector: 'mi-telephone-input',
  templateUrl: './telephone-input.component.html',
  styleUrls: ['./telephone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TelephoneInputComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useValue: validateTelephone,
      multi: true
    }]
})


export class TelephoneInputComponent implements ControlValueAccessor, OnInit {
  @Input() forceUS: boolean = false;
  @Input() maxLength: number = 20;
  @Input() assignedValue: string = null;
  @Input() formType: string = "";

  TELEPHONE_LOCALITIES: Array<string>;
  TELEPHONE_LOCALITY_US: string = TELEPHONE_LOCALITY_US;
  _value: Telephone = null;
  placeholder: string;

  private propagateChange = (_: any) => {
    // Placeholder for implement.
  }

  private propagateTouch = (_: any) => {
    // Placeholder for implement.
  }

  constructor(private store: Store<appDataReducer.State>) {

  }

  onLocalityChange(newLocality) {
    this.assignNewValue(<Telephone>{
      ...this._value,
      locality: newLocality
    });
  }

  onDisplayChange(value) {
    this.assignNewValue(<Telephone>{
      ...this._value,
      display: value
    });

  }

  isUSA(value: Telephone = null): boolean {
    const newValue: Telephone = value || this._value;
    return (newValue.locality === this.TELEPHONE_LOCALITY_US);
  }

  assignNewValue(newValue: Telephone) {
    let number: number = Number.MIN_SAFE_INTEGER;

    if (newValue.display !== '') {
      const possibleNumber = Number((newValue.display + '').replace(/[^\d]+/g, ''));
      if (possibleNumber) {
        number = possibleNumber;
      }
    }

    let newDisplay = newValue.display;

    if (this.isUSA(newValue)) {
      newDisplay = formatUsPhone(newValue.display);
      if (newDisplay === '') {
        number = Number.MIN_SAFE_INTEGER;
      }
    } else {
      newDisplay = formatNoneUsPhone(newValue.display);
      if (newDisplay === '') {
        number = Number.MIN_SAFE_INTEGER;
      }
    }

    this._value = {
      ...newValue,
      display: newDisplay,
      number
    };
    this.propagateChange((this._value.number !== Number.MIN_SAFE_INTEGER) ? this._value : null);
    this.setPlaceholder();
  }

  ngOnInit() {
    if(this.formType == "sign-up") {
      document.getElementById("select").classList.add("sign-up");
      document.getElementById("input").classList.add("sign-up");
    }

    this.store.select(appDataReducer.getAppData).first()
      .subscribe(state => {
        this.TELEPHONE_LOCALITIES = state.CONSTANTS.TELEPHONE_LOCALITIES;
        this.TELEPHONE_LOCALITY_US = state.CONSTANTS.TELEPHONE_LOCALITY_US;
        this.setDefault();
      });
  }

  setDefault() {
    if (!this._value) {
      this._value = <Telephone>{
        locality: this.TELEPHONE_LOCALITIES[0],
        display: '',
        number: Number.MIN_SAFE_INTEGER,
      };
    }
    this.setPlaceholder();
  }

  setPlaceholder() {
    this.placeholder = '+33 (05555) 555555';
    if (this.isUSA()) {
      this.placeholder = '(555) 555-5555';
    }
  }

  inputTouched() {
    // this assigned should write the correct value on blur :
    // this.assignNewValue(this._value);
    this.propagateTouch(this._value);
  }

  public writeValue(obj: any) {
    if (obj) {
      this.assignNewValue(<Telephone>obj);
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

}
