import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";
import { SortableTemplatesEnum } from '../../../shared/ng-models/SortableTableColumn.interface';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';
import { ParentServiceGroupService } from 'src/libs/api2/parent-service-group-v2/parent-service-group.service';
import { ParentServiceGroup, ServiceFamily } from 'src/shared/models/MiService';
import { ModalService } from '@independer/ng-modal';
import { TwoButtonNotificationModalComponent } from 'src/shared/components/two-button-notification-modal/two-button-notification-modal.component';
import { ExcelEmailNotificationModalComponent } from 'src/shared/components/excel-email-notification-modal/excel-email-notification-modal.component';
import { ToastrService } from 'ngx-toastr';

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-service-parent-static',
  templateUrl: './service-parent-static.component.html',
  styleUrls: ['./service-parent-static.component.scss']
})

export class ServiceParentStaticComponent extends AbstractPageComponent implements OnInit {

  @Input() parent_service_group: ParentServiceGroup;
  @Output() dataUpdate: EventEmitter<void> = new EventEmitter<void>();

  parentColumns: any[];

  @ViewChild('removeTmpl', {static: true}) removeTmpl: TemplateRef<any>;
  @ViewChild('radioTmpl', {static: true}) radioTmpl: TemplateRef<any>;
  @ViewChild('serviceStatusTmpl', {static: true}) serviceStatusTmpl: TemplateRef<any>;

  readonly NO_DATA: string = NO_DATA;

  accountrep: VendorContact;

  constructor(
    private parentServiceGroupService:ParentServiceGroupService,
    private modalService: ModalService,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    this.buildTable();
  }

  buildTable() {
    this.parentColumns = [
      {
        name: 'Action',
        prop: 'display_id',
        cellTemplate: this.removeTmpl,
      },
      {
        name: 'Parent',
        prop: 'display_id',
        cellTemplate: this.radioTmpl,
      },
      {
        name: 'MISO-ID',
        prop: 'display_id',
        cellTemplate: SortableTemplatesEnum.idLink,
        sortable: true
      },
      {
        name: 'Vendor',
        prop: 'vendor_name',
      },
      {
        name: 'Product',
        prop: 'product_name',
        sortable: true
      },
      {
        name: 'Account ID',
        prop: 'accountid_label',
        sortable: true
      },
      {
        name: 'Service ID',
        prop: 'service_identifier',
        sortable: true
      },
      {
        name: "CMRC",
        prop:'c_mrc',
        cellTemplate: SortableTemplatesEnum.tenantCurrency,
        sortable: true
      },
      {
        name: "Currency",
        prop:'currency_code',
        sortable: true
      },
      {
        name: "Status",
        prop:'service_status',
        cellTemplate: this.serviceStatusTmpl,
        sortable: true
      }
    ];
  }

  parentChange(row: ServiceFamily) {
    this.parentServiceGroupService.setParentService(this.parent_service_group.id, row.product_name, row.display_id)
      .subscribe(() => {
        this.toastr.success(`${row.display_id} set as parent service successfully`);
        this.dataUpdate.emit()
      }, e => {
        this.toastr.error(`Facing issue while changing parent service`);
      });
  }

  removeFromParent(row: ServiceFamily) {
    let services = [{
      display_id: row.display_id,
      product_name: row.product_name
    }]
    this.parentServiceGroupService.removeFromParent(this.parent_service_group.id, services)
      .subscribe(() => {
        this.toastr.success(`${row.display_id} Service removed successfully`);
        this.dataUpdate.emit()
      }, e => {
        this.toastr.error(`Facing issue while removing service from group`);
      });
  }

  onRemove(row){
    if(row.display_id === this.parent_service_group.parent_service_display_id){
      const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m => {
        m.title = `Remove ${row.display_id} from the Group`
        m.message = `A Parent Service cannot be removed. Please assign a different Parent for the Group and try again.`;
      });
      modalRef.closed.subscribe(({reason, result = []}) => {
      });
      
    } else {
      const modalRef = this.modalService.open(TwoButtonNotificationModalComponent, m => {
        m.title = `Remove ${row.display_id} from the Group`
        m.message = `You are about to remove ${row.display_id} from the group.  Are you sure?`;
      });
      modalRef.closed.subscribe(({reason, result = []}) => {
        if(result[0]){
          this.removeFromParent(row);
        }
      });
    }
  }

  onSelectionChange(row:ServiceFamily){
    const modalRef = this.modalService.open(TwoButtonNotificationModalComponent, m => {
      m.title = "Changing the Parent"
      m.message = `Do you want to make ${row.display_id} the Parent?`;
    });
    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0]){
        this.parentChange(row);
      }
    });
  }
}

