import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit, Input, Output, EventEmitter
} from '@angular/core';
import {
  TabContainerComponent
} from "./tab-container.component";

@Component({
  selector: 'mi-tabs-container',
  templateUrl: './tabs-container.component.html',
  styleUrls: ['./tabs-container.component.scss']
})
export class TabsContainerComponent implements AfterContentInit {

  @Input() externalClasses:string='';
  @Input() full:boolean = false;
  @Input() wrap:boolean = false;
  @Input() subnav:boolean = false;
  @Output() indexChanged:EventEmitter<number> = new EventEmitter();
  @ContentChildren(TabContainerComponent) tabs: QueryList<TabContainerComponent>;

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter( tab => tab.active );
    // if there is no active tab set, activate the first
    if(activeTabs.length === 0) {
      setTimeout(() => {
        this.selectTab(this.tabs.first);
      });
    }
  }

  selectTab(tab: TabContainerComponent): void{
    // loop through all the tabs
    this.tabs.forEach( (loopTab, index) => {

      if (tab === loopTab) {
        // this is the passed tab
        if (tab.active === false) {
          // only emit if this isn't the previous index.
          this.indexChanged.emit(index);
        }
        // set this active tab
        tab.active = true;
      } else {
        loopTab.active = false;
      }

    });
  }

}
