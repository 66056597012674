import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Synonym} from '../../../shared/models/Synonym';
import {AssociatedDocument} from '../../../shared/models/AssociatedDocument';
import {Observable} from 'rxjs';
import {TypeReferenceEnum} from '../../../shared/ng-models/TypeReference.enum';

@Injectable()
export class TenantSynonymsService {

  private apiUrlCreate = '/{tenant}/synonyms/create';
  private apiUrlDelete = '/{tenant}/synonyms/delete';
  private apiUrlGetById = '/{tenant}/synonyms';
  private apiUrlUpload = '/{tenant}/synonyms/upload';
  private apiUrlGetByType = '/{tenant}/synonyms/get-by-type';
  private apiUrlGetByTypeAndText = '/{tenant}/synonyms/get-by-type-and-text';

  constructor(private http: HttpClient) {

  }

  uploadSynonyms(csvUpload: AssociatedDocument, type_reference: TypeReferenceEnum): Observable<Synonym[]> {
    return this.http
      .post(`${this.apiUrlUpload}/${type_reference}`, {
        document: csvUpload
      })
      .map((response: any) => response.data.synonyms as Synonym[]);
  }

  create(synonym: Synonym): Observable<Synonym> {
    return this.http
      .post(`${this.apiUrlCreate}`, synonym)
      .map((response: any) => response.data.synonym as Synonym);
  }

  deleteById(id: string): Observable<boolean> {
    return this.http
      .delete(`${this.apiUrlDelete}/${id}`)
      .map((response: any) => true);
  }

  getById(id: string): Observable<Synonym> {
    return this.http
      .get(`${this.apiUrlGetById}/${id}`)
      .map((response: any) => response.data.synonyms as Synonym);
  }

  getByType(type_reference: TypeReferenceEnum): Observable<Synonym[]> {
    return this.http
      .get(`${this.apiUrlGetByType}/${type_reference}`)
      .map((response: any) => response.data.synonyms as Synonym[]);
  }

  getByTypeAndText(type_reference: TypeReferenceEnum, text: string): Observable<Synonym> {
    return this.http
      .get(`${this.apiUrlGetByTypeAndText}/${type_reference}/${text}`)
      .map((response: any) => response.data.synonym as Synonym);
  }

}
