import { SearchProductsService } from "../../libs/api/interfaces/SearchProductsService.interface";
import { VendorsService } from "../../libs/api/interfaces/VendorsService.interface";
import { ProductsService } from "../../libs/api/interfaces/ProductsService.interface";

export enum ProductServicesModes {
    MIYAGI,
    TENANT
};

export interface ProductServices {
    searchProductsService?: SearchProductsService;
    productsService?: ProductsService;
    vendorsService?: VendorsService;
    mode?: ProductServicesModes;
}
