import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Note} from '../../../shared/models/Note.interface';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantNoteService {

  private apiUrlCrud = '/{tenant}/notes/';
  private apiUrlListById = '/{tenant}/notes/documents/';

  constructor(private http: HttpClient) {

  }

  createNote(note: Note): Observable<Note> {
    return this.http
      .post(`${this.apiUrlCrud}`, note)
      .map((response: any) => response.data.note as Note);
  }

  listNotesById(document_id: string): Observable<Note[]> {
    return this.http
      .get(`${this.apiUrlListById}${document_id}`)
      .map((response: any) => response.data.notes as Array<Note>);
  }

}
