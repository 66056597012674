<mi-server-error [error]="loadError"></mi-server-error>
<div class="half-column">
  <ng-container *ngIf="inEditMode; else static">
    <form *ngIf="locationForm" [formGroup]="locationForm" (submit)="onUpdateLocation(locationForm.value)">
      <div class="half-col">
        <mi-extended-input
          [labelText]="'Location Name'"
          [labelRequired]="true"
          [inputControl]="locationForm.controls['label']"
          [inputErrors]="locationForm.controls['label'].errors"
          [inputTouched]="locationForm.controls['label'].touched"
          [errorDefs]='{required: "A name for the location is required.", minLength: "A location name must be at least two characters long", minLengthWithTrimmedWhiteSpace: "Please enter a valid location name."}'>
          <input type="text"
                 formControlName="label" />
        </mi-extended-input>
      </div>

      <div class="half-col">
        <mi-extended-input
          [labelText]="'Location Nickname'"
          [inputControl]="locationForm.controls['nick_name']"
          [inputErrors]="locationForm.controls['nick_name'].errors"
          [inputTouched]="locationForm.controls['nick_name'].touched"
          [errorDefs]='{required: "A nickname for the location is required.", minLength: "A location nickname must be at least two characters long", minLengthWithTrimmedWhiteSpace: "Please enter a valid location nickname."}'>
          <input type="text"
                 formControlName="nick_name" />
        </mi-extended-input>
      </div>

      <div class="half-col">
        <mi-extended-input
          labelText="Status"
          [labelRequired]="true"
          [inputControl]="locationForm.controls['status']"
          [inputErrors]="locationForm.controls['status'].errors"
          [inputTouched]="locationForm.controls['status'].touched"
          [errorDefs]='{required: "A status for the location is required."}'>
          <select formControlName="status">
            <option *ngFor="let status of LOCATION_STATUSES;"
                    value="{{status}}">
              {{ 'LOCATION_STATUSES.' + status | translate}}
            </option>
          </select>
        </mi-extended-input>
      </div>

      <div class="button-container">

        <ng-container *ngIf=inEditMode>
          <a mi-button
            class="button-alternate"
            buttonTheme="alert-light-theme"
            routerLink="../delete">
            Delete
          </a>
        </ng-container>

        <button mi-button
                buttonTheme="light-theme"
                (click)="onCancel($event)">
          Cancel
        </button>
        <button mi-button
                type="submit"
                buttonTheme="dark-theme"
                [disabled]="!locationForm.valid">
          Continue
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template #static>
    <code>{{location | json}}</code>
  </ng-template>
</div>

