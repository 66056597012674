import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import { AbstractPageComponent } from "../../pages/abstract-page/abstract-page.component";
import { SearchFilter } from "../../ng-models/SearchFilter.interface";
import { Observable } from 'rxjs';
import { TenantReportsService } from "../../../libs/api/tenant/tenantReports.service";
import {environment} from '../../../environments/environment';
import { filter } from "rxjs/operators/filter";
import {removeNonUrlSearchParams} from "../../utils/unsafeUrlProperties";
import { ExcelEmailNotificationModalComponent } from '../excel-email-notification-modal/excel-email-notification-modal.component';
import { ModalService } from '@independer/ng-modal';
import { UploadV2Service } from 'src/libs/api2/upload-v2/upload-v2.service';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import * as appDataReducer from "src/shared/state/app-data/reducers";
// import { TenantSearchService } from 'src/libs/api/tenantSearch/tenantSearch.service';


@Component({
  selector: 'mi-excel-button',
  styleUrls: ['./excel-button.component.scss'],
  templateUrl: './excel-button.component.html'
})

export class DownloadExcelButtonComponent extends AbstractPageComponent implements OnInit {
  @Input() theme: string = 'dark-theme';
  @Input() buttonLabel: string = "Download";
  @Input() printButton: boolean = false;
  @Input() dropdownItem: boolean = false;

  loaded = false;
  resultsLoaded$: Observable<boolean>;
  hasResults: boolean = false;
  searchType: string;
  error: string;
  queryString: string;
  url: string;
  owner: string;
  readableFilter: string;
  columns: ASReducer.IColumn[];
  result: any
  tenant_id: string
  queryParams: any

  constructor(
    private store: Store<ASReducer.State>,
    private reportV2Service: ReportV2Service,
    private modalService: ModalService,
    private uploadV2Service: UploadV2Service,
    // private tenantSearchService: TenantSearchService
  ) {
    super();
  }

  ngOnInit(): void {

    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe(
      (data) => {
        this.tenant_id = data.tenantData.domain;
      },
      (e) => (this.error = e)
    );


    this.resultsLoaded$ = this.store.select(ASReducer.ASResultsLoaded);
    this.store
      .select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(data => {
        this.url = "";
        this.hasResults = false;
        this.searchType = data.searchType;
        this.readableFilter = data.readableFilter;
        this.columns = data.columns;
        this.result = data.results;

        if (data.results) {
          this.owner = data.results.meta.params['owner'];
          this.buildQueryString(data.results.meta.params);
          this.buildUrl();
          // this.getFiltersPayload(data.results.meta.params);
          this.hasResults = data.results.total.value > 0;
        }
      });
  }

  // getFiltersPayload(rawParams) {
  //   const params = removeNonUrlSearchParams(rawParams);
  //   const queryParams = {}

  //   for (let filter in params) {
  //     if (params[filter] ) {

  //       if (filter !== "q") {

  //         if (Array.isArray(params[filter])) {
  //           params[filter].map(data => {
  //             queryParams[`${filter}[]`]=  data 
  //             // query += `&${filter}[]=${encodeURIComponent(data)}`;
  //           });
  //         }
  //          else {
  //           queryParams[`${filter}`]= encodeURIComponent(params[filter]);
  //           // query += `&${filter}:${encodeURIComponent(params[filter])}`;
  //         }
  //       }
  //     }
  //   }
  //   queryParams['q'] = encodeURIComponent(params["q"] || "");
  //   queryParams['size'] = 1000;
  //   this.queryParams = queryParams
  // }



  buildQueryString(rawParams) {
 
    const params = removeNonUrlSearchParams(rawParams);
    let query = "q=" + encodeURIComponent(params["q"] || "");
    for (let filter in params) {
      if (params[filter] ) {
        if (filter !== "q") {
          if (Array.isArray(params[filter])) {
            params[filter].map(data => {
              query += `&${filter}[]=${encodeURIComponent(data)}`;
            });
          } else {
            //query += `&${filter}=${encodeURIComponent(params[filter])}`;
            query += `&${filter}[]=${encodeURIComponent(params[filter])}`;
          }
        }
      }
    }

    query = `${query}&readableFilters=${encodeURIComponent(this.readableFilter)}`;
    this.queryString = query;
  }

  buildUrl() {
    switch (this.searchType) {
      case "SERVICE":
        //this.url = this.getSearchMiServiceDownloadUrl(this.owner, this.queryString);
        break;

      case "EVENT":
        //this.url = this.getSearchEventDownloadUrl(this.owner, this.queryString);
        break;

      case "EQUIPMENT":
        //this.url = this.getSearchEquipmentDownloadUrl(this.owner, this.queryString);
        break;

      case "VENDOR":
        //this.url = this.getSearchVendorDownloadUrl(this.owner, this.queryString);
        break;

      case "CONTACT":
        //this.url = this.getSearchContactDownloadUrl(this.owner, this.queryString);
        break;

      case "ADDRESS":
        //this.url = this.getSearchAddressDownloadUrl(this.owner, this.queryString);
        break;

      case "MI_SERVICE_GROUP":
        //this.url = this.getSearchServiceGroupDownloadUrl(this.owner, this.queryString);
        break;
      }
  }


  getSearchMiServiceDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-miservice-search?${queryString}`;
  }

  getSearchEventDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-event-search?${queryString}`;
  }

  getSearchEquipmentDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-equipment-search?${queryString}`;
  }

  getSearchVendorDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-vendor-search?${queryString}`;
  }

  getSearchContactDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-contact-search?${queryString}`;
  }

  getSearchAddressDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-address-search?${queryString}`;
  }

  getSearchServiceGroupDownloadUrl(domain, queryString) {
    //return `${settings.API_URL}/${domain}/reports/download-service-groups-search?${queryString}`;
  }

  print(){
        // this.tenantSearchService.searchTenantMiServices(this.queryParams)
        // .subscribe(allServices => {
        //     this.result = allServices.hits;
        //   },
        //   (e) => (this.error = e)
        //   );

    if(this.searchType == "SERVICE" && this.result?.total.value > 40000) {
      const alertModal = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
        m.title = "Email Notification";
        m.message = "Print Request for more than 40,000 services is currently not supported. Please try again with a smaller number.";
        m.closeButtonLabel = "Confirm";
      });
      return;
    }


    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Email Notification";
      m.message = "Your request to download Services Inventory is in progress. Please check your email in a few minutes to receive the requested information (it may also be in your Spam folder).";
      m.closeButtonLabel = "Confirm";
    });
    modalRef.closed.subscribe(({reason, result = []}) => {
      const [filters] = result;
      this.reportV2Service.printServiceSearch(this.columns, this.queryString, this.searchType)
      .takeUntil(this.destroy$)
      .subscribe(res => {
        console.log(res)
      }
      ,e => this.error = e)
    });
  }
}
