import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Vendor} from '../../../shared/models/Vendor';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {VendorsService} from '../interfaces/VendorsService.interface';
import {SearchResults, totalResults, VendorSearchResults} from '../../../shared/models/SearchResults';
import {CanDelete} from '../../../shared/models/CanDelete';
import {VendorMissingData} from '../../../shared/models/VendorMissingData';
import { Product } from '../../../shared/models/Product';
import FrontEndSettingsUtil from "../../../shared/utils/frontEndSettings.util";

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantVendors implements VendorsService {

  private apirUrlSearchAllVendors = '/{tenant}/search/allVendors';
  private apirUrlVendorByDisplayId = '/{tenant}/vendors';
  private apirUrlVendorById = '/{tenant}/vendors/id';
  private apirUrlVendorUnNormalized = '/{tenant}/vendors-list-unnormalized';
  private apiUrlCreateNormalizedVendor = '/{tenant}/vendors/normalized/create';
  private apiUrlCreateUnNormalizedVendor = '/{tenant}/vendors/un-normalized/create';
  private apiUrlVendorMissingData = '/{tenant}/missing-data-by-vendor';
  private apiUrlS3Path = '/{tenant}/S3Path';

  private accountHost = '_MICRO_SERVICE_/vendor';
  private apirUrlSearchTenantVendors = '_MICRO_SERVICE_/vendor/tenants';
  private apirUrlTenantVendorProducts = '_MICRO_SERVICE_/product/products/tenants/vendor';
  private apirUrlTenantVendorAccountIds = '_MICRO_SERVICE_/accountid/tenant-vendor-accountid';
  private apiUrlVendorCalculations = '_MICRO_SERVICE_/calculation/tenant/vendor';

  constructor(private http: HttpClient) {
  }

  private anyToVendors(any: any): Array<Vendor> {
    var vendors = <Array<Vendor>>[];
    for (var i = 0; i < any.length; i++) {
      vendors.push(this.anyToVendor(any[i]));
    }
    return vendors;
  }

  private anyToVendor(any: any): Vendor {
    var vendor = any.vendor as Vendor;
    vendor.tenant_vendor_id = any.id;
    if (any.vendor.logo) {
      vendor.logo = {
        file_path: any.vendor.logo,
        upload_type: "MIYAGI_VENDOR_LOGO",
        file_name: any.vendor.logo.split('/')[2],
        //full_path: `https://wwwlocal-miso3-uploads-public.s3.amazonaws.com${any.vendor.logo}`
        //full_path: 'https://wwwlocal-miso3-uploads-public.s3.amazonaws.com' + any.vendor.logo
        full_path: 'https://miso3-uploads-public.s3.amazonaws.com' + any.vendor.logo
      };
    }
    vendor.display_id = any.display_id as string;
    //vendor._id = any.id;
    vendor._id = any.vendor?.id;
    vendor.tenant_id = any.tenant_id;
    if (any.dependents) {
      vendor.account_ids = [];
      vendor.contacts = [];
      any.dependents.forEach((dependent) => {
        if (dependent.reference_type == "Accounts") {
          vendor.account_ids.push({
            id: dependent.id,
            display_id: dependent.display_id,
            reference_type: dependent.reference_type,
            label: dependent.label
          })
        }
        if (dependent.reference_type == "Vendor contact") {
          vendor.contacts.push({
            id: dependent.id,
            display_id: dependent.display_id,
            reference_type: dependent.reference_type
          })
        }
      });
    }
    if (vendor.is_resolved)
      vendor.actual_vendor_id = vendor.resolution_id;
    vendor.service_term_id = any.service_term_id;
    return vendor;
  }

  
  listVendors(): Promise<Array<Vendor>> {
    return this.http
      .get(`${this.accountHost}/tenants/_TENANT_ID_`)
      .toPromise()
      .then((response: any) => {
        return this.anyToVendors(response);
      });
  }

  searchAllVendors(q: string = '', size: number = 6): Promise<VendorSearchResults> {
    const params = new HttpParams({ fromObject: { q, size: size.toString() } });
    return this.http
      .get(this.apirUrlSearchAllVendors, { params })
      .toPromise()
      .then((response: any) => response.data as VendorSearchResults);
  }

  searchTenantVendorsWithActiveServices(q: string = '', page_number : number = 0, size: number = 10, tenant_id: string, order: string = "ASC", sort: string = "displayId", has_active_services: boolean= true, exclude_props: string = ''): Observable<any> {
    const hasActiveServices = has_active_services ? `search?has_active_services=true&poor_data_integrity=false` : `search`
    const params = new HttpParams({ fromObject: { q, page_number : page_number.toString(), size: size.toString(), order: order.toUpperCase(), sort , exclude_props,} });
    return this.http.get(`${this.apirUrlSearchTenantVendors}/${tenant_id}/${hasActiveServices}`, { params });
  }

  getVendorWithEOTAIssue(q: string = '', page_number : number = 0, size: number = 10, tenant_id: string, order: string = "ASC", sort: string = "displayId", has_active_services: boolean= true, exclude_props: string = ''): Observable<any> {
    const hasActiveServices = has_active_services ? `search?has_active_services=true&poor_data_integrity=false&post_sort_value=service_term_event_type&post_sort_order=DESC` : `search`
    const params = new HttpParams({ fromObject: { q, page_number : page_number.toString(), size: size.toString(), sort , exclude_props,} });
    return this.http.get(`${this.apirUrlSearchTenantVendors}/${tenant_id}/${hasActiveServices}`, { params });
  }

  getTenantVendorsDataIntegrityIssue(q: string = '', page_number : number = 0, size: number = 10, tenant_id: string, order: string = "ASC", sort: string = "displayId", has_active_services: boolean= true, exclude_props: string = ''): Observable<any> {
    const hasActiveServices = has_active_services ? `search?has_active_services=true&poor_data_integrity=true&post_sort_value=service_count_dates&post_sort_order=DESC` : `search`
    const params = new HttpParams({ fromObject: { q, page_number : page_number.toString(), size: size.toString(),sort , exclude_props,} });
    return this.http.get(`${this.apirUrlSearchTenantVendors}/${tenant_id}/${hasActiveServices}`, { params });
  }

  tenantVendorProducts(q: string = '', page_number : number = 0, size: number = 10, tenant_vendor_id: string, order: string = "ASC", sort: string = "displayId"): Observable<any> {
    const params = new HttpParams({ fromObject: { q, page_number : page_number.toString(), size: size.toString(), order: order.toUpperCase(), sort } });
    return this.http.get(`${this.apirUrlTenantVendorProducts}/${tenant_vendor_id}`, { params });
  }

  tenantVendorAccountIds(q: string = '', page_number: number = 0, size: number = 10, tenant_vendor_id: string, order: string = "ASC", sort: string = "displayId", exclude_props: string = ''): Observable<any> {
    const params = new HttpParams({ fromObject: { tenant_vendor_id, q, page_number: page_number.toString(), size: size.toString(), order: order.toUpperCase(), sort, exclude_props  } });
    return this.http.get(`${this.apirUrlTenantVendorAccountIds}`, { params });
  }

  searchActiveTenantVendors(q: string = '', from: number = 0, size: number = 6, active: string = '1'): Promise<VendorSearchResults> {
    const params = new HttpParams({ fromObject: { q, active: active, from: (++from).toString(), size: size.toString() } });
    return this.http
      .get(`${this.accountHost}/tenants/_TENANT_ID_/search`, { params })
      .toPromise()
      .then((response: any) => {
        var hits = <Array<Vendor>>[];
        for (var i = 0; i < response.hits.length; i++) {
          var vendor = this.anyToVendor(response[i]);
          hits.push(vendor);
        }
        var searchResults = new VendorSearchResults;
        searchResults.hits = hits.sort((a, b) => a.display_id.localeCompare(b.display_id));
        searchResults.total.value = response.total as number;
        return searchResults;
      });
  }

  getVendorByDisplayId(id: String): Promise<Vendor> {
    return this.http
      .get(`${this.accountHost}/tenants/_TENANT_ID_/display/${id}`)
      .toPromise()
      .then((response: any) => {
        return this.anyToVendor(response);
      });
  }

  updateVendorSQL(vendor: Vendor): Promise<Vendor> {
    return this.http
      .put(`${this.accountHost}/tenants`, { vendor_name: vendor.vendor_name, vendor_url: vendor.vendor_url, tenant_vendor_id : vendor.tenant_vendor_id, service_term_id: vendor.service_term })
      .toPromise()
      .then((response: any) => {
        return this.anyToVendor(response);
      });
  }


  updateVendor(vendor: Vendor): Promise<Vendor> {
    return this.updateVendorSQL(vendor)
      .then(vendor => {
        return this.http
          .put(`${this.apirUrlVendorByDisplayId}/${vendor.id}`, vendor)
          .toPromise()
          .then((response: any) => {
            return response.data.vendor as Vendor;
          });
      }).catch(e => null);
  }

  createUnNormalizedVendorSQL(vendor: Vendor): Promise<Vendor> {
    vendor.tenant_id = FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN;
    return this.http
      .post(`${this.accountHost}/tenants`, vendor)
      .toPromise()
      .then((response: any) => {
        return this.anyToVendor(response);
      });
  }

  createUnNormalizedVendor(vendor: Vendor): Promise<Vendor> {
    return this.createUnNormalizedVendorSQL(vendor)
      .then(vendor => {
        /*
        return this.http
          .post(this.apiUrlCreateUnNormalizedVendor, vendor)
          .toPromise()
          .then((response: any) => {
            return response.data.vendor as Vendor;
          });
        */
        return vendor;
        }).catch(e => null);
  }

  createNormalizedVendorSQL(actual_vendor_id: string): Promise<Vendor> {
    let tenantId = FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN;
    return this.http
      .post(`${this.accountHost}/tenants`, { vendor_id: actual_vendor_id, tenant_id: tenantId })
      .toPromise()
      .then((response: any) => {
        return this.anyToVendor(response);
      });
  }

  createNormalizedVendor(actual_vendor_id: string): Promise<Vendor> {

    return this.createNormalizedVendorSQL(actual_vendor_id)
      .then(vendor => {
        /*
        return this.http
          .post(this.apiUrlCreateNormalizedVendor, vendor)
          .toPromise()
          .then((response: any) => {
            response.data.vendor.tenant_vendor_id = vendor.tenant_vendor_id;
            return response.data.vendor as Vendor;
          });
        */
        return vendor;
      }).catch(e => null);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.accountHost}/tenants/vendors/${id}`)
      .map((response: any) => {
        var results = {
          dependents: response.dependents,
          delete: { _id: response.id as String, display_id: response.display_id as String, vendor_name: response.vendor.vendor_name } as any
        };
        return results;
      });
  }

  deleteVendorSQL(id: String): Promise<String> {
    return this.http
      .delete(`${this.accountHost}/tenants/${id}`)
      .toPromise()
      .then((response) => {
        return id;
      }).catch(e => null);;
  }


  deleteVendor(id: String): Observable<Vendor> {
    this.deleteVendorSQL(id);
      return this.http
        .delete(`${this.apirUrlVendorById}/${id}`)
        .map((response: any) => {
          return response.data.vendor as Vendor;
        });
  }

  getVendorBytenantVendorId(id: String): Promise<Vendor> {
    return this.http
      .get(`${this.accountHost}/tenants/vendors/${id}`)
      .toPromise()
      .then((response: any) => {
        return this.anyToVendor(response);
      });
  }

  ////////////////////

  getVendorIncompleteData(vendor: Vendor = null, withAccountIds: boolean = false): Observable<Array<VendorMissingData>> {
    const params = {};

    if (vendor) {
      params['purchasing_vendor_name[]'] = vendor.vendor_name;
    }
    if (withAccountIds) {
      params['include_account_id'] = 1;
    }
    params['data_integrity_overall_status[]'] = 'INCOMPLETE';

    return this.http
      .get(this.apiUrlVendorMissingData, {params})
      .map((response: any) => {
        return response.data.vendors as Array<VendorMissingData>;
      });
  }

  getVendorProducts(id: String): Promise<Product[]> {
    return this.http
      .get(`${this.apirUrlVendorByDisplayId}/${id}/products`)
      .toPromise()
      .then((response: any) => {
        return response.data.products as Product[];
      });
  }

  getVendorByDisplayIdAsObservable(id: String): Observable<Vendor> {
    return this.http
      .get(`${this.apirUrlVendorByDisplayId}/${id}`)
      .map((response: any) => response.data.vendor as Vendor);
  }

  getVendorById(id: String): Observable<Vendor> {
    return this.http
      .get(`${this.apirUrlVendorById}/${id}`)
      .map((response: any) => response.data.vendor as Vendor);
  }

  getVendorCalculations(tenantVendorId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlVendorCalculations}/${tenantVendorId}`)
      .map((response: any) => response);
  }
}
