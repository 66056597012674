import {Component, Input} from "@angular/core";

@Component({
  selector: 'mi-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})

export class LoadingAnimationComponent {
  @Input() type: string = "circle";
}
