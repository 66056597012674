import {NgModule} from '@angular/core';
import {SharedModule} from "../../../shared/shared.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ContactDetailComponent} from "./instance-wrap/vendor-contact/detail/contact-details.component";
import {TenantComponentsModule} from "../../components/tenant-components.module";
import {ContactDetailsAvatarComponent} from "../../components/contact-details-avatar/contact-details-avatar.component";
import {ContactInstanceWrapComponent} from "./instance-wrap/contact-instance-wrap.component";
import {ContactInstanceWrapModule} from "./instance-wrap/contact-instance-wrap.module";
import { ContactDeleteComponent } from './delete/contact-delete.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { UserAndEmployeeListComponent } from './user-and-employee-list/user-and-employee-list.component';
import { CreateEditEmployeeComponent } from './employees/create-edit/create-edit-employee.component';
import { UserAndEmployeeListEndUsersComponent } from './user-and-employee-list/user-and-employee-list-end-user.component';
import { UserAndEmployeeListMisoManagerComponent } from './user-and-employee-list/user-and-employee-list-miso-manager.component';
import { UserAndEmployeeServiceOwnerListComponent } from './user-and-employee-list/user-and-employee-list-service-owner.component';

@NgModule({
  declarations: [
    ContactDeleteComponent,
    ContactListComponent,
    UserAndEmployeeListComponent,
    CreateEditEmployeeComponent,
    UserAndEmployeeListEndUsersComponent,
    UserAndEmployeeListMisoManagerComponent,
    UserAndEmployeeServiceOwnerListComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    ContactInstanceWrapModule,
  ],
  providers: [],
  exports: [
    ContactDeleteComponent,
    ContactListComponent,
    UserAndEmployeeListComponent,
    CreateEditEmployeeComponent,
    UserAndEmployeeListEndUsersComponent,
    UserAndEmployeeListMisoManagerComponent,
    UserAndEmployeeServiceOwnerListComponent
  ]
})
export class ContactsModule {
}
