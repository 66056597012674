import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {TinyMceModule} from 'angular-tinymce';
import {miTinymceDefaultSettings} from 'src/shared/utils/miTinymceDefaultSettings';
import {DataImportComponent} from './data-import.component';
import {ApiIntegrationComponent} from './api-integration/api-integration.component';
import {UploadDocumentComponent} from './upload-document/upload-document.component';
import {DataImportUploadComponent} from './components/data-import-upload/data-import-upload.component';
import {TenantComponentsModule} from '../../components/tenant-components.module';
import {DataImportApiIntegrationComponent} from './components/data-import-api-integration/data-import-api-integration.component';

@NgModule({
  declarations: [
    DataImportComponent,
    ApiIntegrationComponent,
    UploadDocumentComponent,
    DataImportUploadComponent,
    DataImportApiIntegrationComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    TinyMceModule.forRoot(miTinymceDefaultSettings()),
  ],
  providers: [],
  exports: []
})

export class DataImportModule {

}
