<div class="dropdown">
  <div class="dropdown-header"
       (click)="toggleOpen()">
    {{dropdownTitle}}
  </div>
</div>
<ul class="dropdown-items">
  <ng-container *ngIf="!customList">
    <li class="dropdown-item"
        (click)="onItemSelected(item)"
        *ngFor="let item of this.items">
      <div class="dropdown-item-title">
        {{item.title}}
      </div>
      <div class="dropdown-item-subtitle">
        {{item.subtitle}}
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="customList">
    <ng-content>
    </ng-content>
  </ng-container>
</ul>