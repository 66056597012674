<div class="details-nav-info">
  <div class="data" *ngIf="data">

    <div class="main">
      <h2 *ngIf="data.headline" title="{{data.headline}}"><mi-trans-copy [copy]="data.headline"></mi-trans-copy></h2>
      <h3 *ngIf="data.byline"><mi-trans-copy [copy]="data.byline"></mi-trans-copy></h3>
    </div>
    <div class="stats">
      <ng-container *ngFor="let dataItem of data.specs">

        <ng-container [ngSwitch]="dataItem.value_type">
          <mi-header-data *ngSwitchCase="DATE" label="{{dataItem.label | translate }}" value="{{dataItem.value | date:'shortDate'}}"></mi-header-data>
          <mi-header-data *ngSwitchCase="CURRENCY" label="{{dataItem.label | translate}}" value="{{dataItem.value | tenantcurrency:'1.2-2'}}"></mi-header-data>
          <mi-header-data *ngSwitchDefault label="{{dataItem.label | translate}}" value="{{dataItem.value}}"></mi-header-data>
        </ng-container>

      </ng-container>

    </div>

  </div>

  <div class="nav">

    <ul class="sub-page-links">

      <ng-container  *ngIf="navType === NAV_TYPE_STANDARD">
        <li>
          <a class="details"
             routerLink="./details"
             routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
          Details
        </a>
        </li>        
        <li>
          <a
            class="services"
            routerLink="./services"
            routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
            Services
          </a>
        </li>
        <!--
        <li>
          <a
            class="events"
            routerLink="./events"
            routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
            Events
          </a>
        </li>
        <li *ngIf="missingDataDocCount > 0">
          <a
            class="missing-data"
            routerLink="./missing-data"
            routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
            Incomplete Status  {{missingDataTotal| currency:'USD':'symbol':'1.2-2'}}
          </a>
        </li>
        <li *ngIf="equipmentCount > -1">
            <a
              class="equipment"
              routerLink="./equipment"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
              Equipment  ({{equipmentCount}})
            </a>
          </li>
        -->
        </ng-container>

    </ul>

    <ul *ngIf="showEdit" class="additional-links">
      <ng-container *ngIf="showAddAndRemoveService">
        <li>
          <a  class="add-service"
              routerLink="./add-service"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">Add Service
          </a>
        </li>
        <li>
          <a  class="remove-service"
              routerLink="./remove-service"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">Remove Service
          </a>
        </li>
      </ng-container>
      <li>
        <a class="edit"
             routerLink="./edit"
             routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">Edit
        </a>
      </li>
    </ul>

  </div>
</div>
