const RECURLY_BILLING = 'RECURLY_BILLING';
const MANUAL_BILLING = 'MANUAL_BILLING';
const PAYMENT_PLAN_BILLING_TYPES = [
  RECURLY_BILLING,
  MANUAL_BILLING
];
const CUSTOM_FIELD_TYPE_CONTACT = 'CONTACT';
const CUSTOM_FIELD_TYPE_DROPDOWN = 'DROPDOWN';
const CUSTOM_FIELD_TYPE_DATE = 'DATE';
const CUSTOM_FIELD_TYPE_NUMBER = 'NUMBER';
const PRODUCT_FIELD_TYPES = ['DROPDOWN', 'NUMBER', 'BANDWIDTH_SPEED', 'TEXT'];
const CUSTOM_FIELD_TYPE_TEXT = 'TEXT';
const CUSTOM_FIELD_TYPE_PHONE = 'PHONE';

const CUSTOM_FIELD_TYPES = [
  CUSTOM_FIELD_TYPE_CONTACT,
  CUSTOM_FIELD_TYPE_DROPDOWN,
  CUSTOM_FIELD_TYPE_DATE,
  CUSTOM_FIELD_TYPE_NUMBER,
  CUSTOM_FIELD_TYPE_PHONE,
  CUSTOM_FIELD_TYPE_TEXT
];

const EMAIL_TYPE_OTHER = 'OTHER';
const EMAIL_TYPE_MISSING_DATA = 'MISSING_DATA';
const EMAIL_TYPE_REQUEST_QUOTE = 'REQUEST_QUOTE';
const EMAIL_TYPE_REQUEST_CANCELLATION = 'REQUEST_CANCELLATION';
const EMAIL_TYPE_RE_ASSESSMENT_REQUEST = 'RE_ASSESSMENT_REQUEST';

const EMAIL_TYPES = [
  EMAIL_TYPE_OTHER,
  EMAIL_TYPE_MISSING_DATA,
  EMAIL_TYPE_REQUEST_CANCELLATION,
  EMAIL_TYPE_REQUEST_QUOTE,
  EMAIL_TYPE_RE_ASSESSMENT_REQUEST
];

const SENT_FROM_MIYAGI = 'MIYAGI';
const SENT_FROM_QUEUE = 'QUEUE';

const EMAIL_SOURCE = [
  SENT_FROM_MIYAGI,
  SENT_FROM_QUEUE
];

const HAS_PARENT = 'HAS_PARENT';
const HAS_NO_PARENT = 'HAS_NO_PARENT';
const IS_PARENT = 'IS_PARENT';

const PARENT_SERVICE_STATUS = [
  HAS_PARENT,
  HAS_NO_PARENT,
  IS_PARENT
];

const ALERT_STATUS_NONE = 'ALERT_STATUS_NONE';
const ALERT_STATUS_BROKEN = 'ALERT_STATUS_BROKEN';
const ALERT_STATUS_INACTIVE = 'ALERT_STATUS_INACTIVE';

const ALERT_STATUSES = {
  ALERT_STATUS_NONE,
  ALERT_STATUS_BROKEN,
  ALERT_STATUS_INACTIVE
};

const ALERT_CAUSE_CONTACT = 'ALERT_CAUSE_CONTACT';
const ALERT_CAUSE_EQUIPMENT = 'ALERT_CAUSE_EQUIPMENT';
const ALERT_CAUSE_LOCATION = 'ALERT_CAUSE_LOCATION';
const ALERT_CAUSE_SERVICE = 'ALERT_CAUSE_SERVICE';
const ALERT_CAUSE_TENANTUSER = 'ALERT_CAUSE_TENANTUSER';

const ALERT_CAUSES = {
  ALERT_CAUSE_CONTACT,
  ALERT_CAUSE_EQUIPMENT,
  ALERT_CAUSE_LOCATION,
  ALERT_CAUSE_SERVICE,
  ALERT_CAUSE_TENANTUSER
};


const CONTACT_ROLE_SALES = 'SALES';
const CONTACT_ROLE_SUPPORT = 'SUPPORT';
const CONTACT_ROLE_PROVISIONING = 'PROVISIONING';
const CONTACT_ROLE_FINANCE = 'FINANCE';
const CONTACT_ROLE_INTERNAL = 'INTERNAL';
const CONTACT_ROLE_OTHER = 'OTHER';

const CONTACT_ROLES = [
  CONTACT_ROLE_SALES,
  CONTACT_ROLE_SUPPORT,
  CONTACT_ROLE_PROVISIONING,
  CONTACT_ROLE_FINANCE,
  CONTACT_ROLE_INTERNAL,
  CONTACT_ROLE_OTHER
];

const COLLECTION_TYPE_REFERENCES = {
  ACCOUNT_IDS: 'AccountIds',
  ADDRESSES: 'Addresses',
  CONTACTS: 'Contacts',
  LOCATIONS: 'Locations',
  PRODUCTS: 'Products',
  PARENT_SERVICES: 'MiParentServices',
  SERVICES: 'MiServices',
  TERMS_AND_CONDITIONS: 'TermsAndConditions',
  VENDORS: 'Vendors',
  EQUIPMENT:'Equipment',
  DOCUMENTS:'Documents',
  EQUIPMENT_CATALOG:'EquipmentCatalogs',
  SERVICE_GROUPS:'MiServiceGroups'
};

const BLACK_LISTED_EXTENSIONS = [
  'exe'
];
const BLACK_LISTED_FILE_TYPES = [
  'application/exe',
  'application/x-exe',
  'application/dos-exe',
  'vms/exe',
  'application/x-winexe',
  'application/msdos-windows',
  'application/x-msdos-program',
  'application/x-msdownload'
];

const CONTACT_STATUS_ACTIVE = 'ACTIVE';
const CONTACT_STATUS_INACTIVE ='INACTIVE';

const CONTACT_STATUSES = [
  CONTACT_STATUS_ACTIVE,
  CONTACT_STATUS_INACTIVE
];

const FILE_TYPES = {
  'image/svg+xml': 'IMAGE',
  'image/webp': 'IMAGE',
  'image/tiff': 'IMAGE',
  'image/png': 'IMAGE',
  'image/jpeg': 'IMAGE',
  'image/x-icon': 'IMAGE',
  'image/gif': 'IMAGE',
  'application/msword': 'DOC',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC',
  'application/vnd.ms-excel': 'XLS',
  'application/pdf': 'PDF'
};

const EMAIL_CC = 'cc';
const EMAIL_BCC = 'bcc';
const EMAIL_TO = 'to';

const EMAIL_RECIPIENT_TYPES = [
  EMAIL_CC,
  EMAIL_BCC,
  EMAIL_TO
];

const TELEPHONE_LOCALITY_US = 'US';
const TELEPHONE_LOCALITY_OTHER = 'OTHER';

const TELEPHONE_LOCALITIES = [
  TELEPHONE_LOCALITY_US,
  TELEPHONE_LOCALITY_OTHER
];

const BANDWIDTH_UNITS = [
  'Gbps',
  'Mbps',
  'Kbps'
];

const TOKEN_ERROR_EXPIRED = 'This download link has expired.';
const TOKEN_ERROR_INVALID = 'This download link is not valid.';
const THIRTY_DAY_ACCESS = 'THIRTY_DAY_ACCESS';
const CHECKOUT_ACCESS_FUNCTION ='CHECKOUT_ACCESS_FUNCTION';
const ACCESS_FUNCTIONS =[CHECKOUT_ACCESS_FUNCTION];

const FINANCIAL_CANCELLATION = 'FINANCIAL_CANCELLATION';
const FINANCIAL_RETERM = 'FINANCIAL_RETERM';
const FINANCIAL_STANDARD = 'FINANCIAL_STANDARD';
const FINANCIAL_TYPES = [FINANCIAL_STANDARD, FINANCIAL_RETERM, FINANCIAL_CANCELLATION];

const EVENT_TYPE_ACTIVATION = 'EVENT_TYPE_ACTIVATION';
const EVENT_TYPE_AUTO_RENEW = 'EVENT_TYPE_AUTO_RENEW';
const EVENT_TYPE_AUTO_CANCELLATION = 'EVENT_TYPE_AUTO_CANCELLATION';
const EVENT_TYPE_CANCELLATION = 'EVENT_TYPE_CANCELLATION';
const EVENT_TYPE_RE_TERM = 'EVENT_TYPE_RE_TERM';
const EVENT_TYPE_REPLACE = 'EVENT_TYPE_REPLACE';

const EVENT_TYPES = [
  EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_RE_TERM,
  EVENT_TYPE_REPLACE
];

const NEW_PASSWORD = 'new';
const RESET_PASSWORD = 'reset';

const VENDOR_STATUS_CLOSED = 'Closed';
const VENDOR_STATUS_IGNORED = 'Ignored';
const VENDOR_STATUS_OPEN = 'Not Ignored';
const VENDOR_STATUSES = [VENDOR_STATUS_CLOSED, VENDOR_STATUS_IGNORED, VENDOR_STATUS_OPEN];

const PRODUCT_STATUS_CLOSED = 'Closed';
const PRODUCT_STATUS_IGNORED = 'Ignored';
const PRODUCT_STATUS_OPEN = 'Not Ignored';
const PRODUCT_STATUSES = [PRODUCT_STATUS_CLOSED, PRODUCT_STATUS_IGNORED, PRODUCT_STATUS_OPEN];

const SERVICE_CURRENT = 'CURRENT';
const SERVICE_ARCHIVED = 'ARCHIVED';
const SERVICE_UNKNOWN = 'UNKNOWN';

const SERVICE_STATUSES = [
  SERVICE_CURRENT,
  SERVICE_ARCHIVED,
  SERVICE_UNKNOWN
];

const REPORT_TYPE_MISSING_DATA = 'REPORT_TYPE_MISSING_DATA';
const REPORT_TYPE_CONTACT_FOR_IMPORT = 'REPORT_TYPE_CONTACT_FOR_IMPORT';
const REPORT_TYPE_SERVICE_FOR_IMPORT = 'REPORT_TYPE_SERVICE_FOR_IMPORT';
const REPORT_TYPE_SERVICE_STANDARD = 'REPORT_TYPE_SERVICE_STANDARD';
const REPORT_TYPE_SERVICE_COMBINE_PARENTS = 'REPORT_TYPE_SERVICE_COMBINE_PARENTS';
const REPORT_TYPE_LOCATION_FOR_IMPORT = 'REPORT_TYPE_LOCATION_FOR_IMPORT';
const REPORT_TYPE_EQUIPMENT_FOR_IMPORT = 'REPORT_TYPE_EQUIPMENT_FOR_IMPORT';
const REPORT_TYPE_PARENT_SERVICE_FOR_IMPORT = 'REPORT_TYPE_PARENT_SERVICE_FOR_IMPORT';
const REPORT_TYPE_SERVICE_GROUP_FOR_IMPORT = 'REPORT_TYPE_SERVICE_GROUP_FOR_IMPORT';
const REPORT_TYPE_ACCOUNT_ID_FOR_IMPORT = 'REPORT_TYPE_ACCOUNT_ID_FOR_IMPORT';

const REPORT_TYPES = [
  REPORT_TYPE_MISSING_DATA,
  REPORT_TYPE_CONTACT_FOR_IMPORT,
  REPORT_TYPE_SERVICE_FOR_IMPORT,
  REPORT_TYPE_SERVICE_STANDARD,
  REPORT_TYPE_SERVICE_COMBINE_PARENTS,
  REPORT_TYPE_LOCATION_FOR_IMPORT,
  REPORT_TYPE_EQUIPMENT_FOR_IMPORT,
  REPORT_TYPE_PARENT_SERVICE_FOR_IMPORT,
  REPORT_TYPE_ACCOUNT_ID_FOR_IMPORT,
  REPORT_TYPE_SERVICE_GROUP_FOR_IMPORT
];

const REPORT_SHORT_IDS = {
  PARENT_SERVICE_EXPORT: 'Sy4FKKhzB',
  SEARCH_DOWNLOAD_EXPORT: 'HkHR19pDQ',
  GENERAL_CSV_EXPORT: 'ryKmFFkN7',
  MISSING_DATA: 'ByN9yQ35z',
  EQUIPMENT_EXPORT: 'ByqzpvBg4',
  VENDOR_EXPORT: 'rkZp4v5GV',
  CONTACT_EXPORT: 'B1uW_jqdN',
  ADDRESS_EXPORT: 'BJ0GcsmFV',
  SERVICE_GROUPS_EXPORT: 'SyXq22k6N',
  LOCATION_EXPORT: 'H1iXZKUyS',
  SERVICE_EVENT_REPORT: 'HkFKO6CBH',
  UPCOMING_EVENT_REPORT: 'Bkvw6b36S'
};

const POST_LOGIN_USER_POLICY = 'POST_LOGIN_USER_POLICY';

export interface GroupedCountries {
  label: string;
  states: string[];
}

const GROUPED_COUNTRIES: GroupedCountries[] = [
  {
    label: 'United States',
    states: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'Armed Forces Africa',
      'Armed Forces Canada',
      'Armed Forces Europe',
      'Armed Forces Middle East',
      'Armed Forces Pacific',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States Of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Islands',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming'
    ]
  },
  {label: 'Afghanistan', states: []},
  {label: 'Albania', states: []},
  {label: 'Algeria', states: []},
  {label: 'Andorra', states: []},
  {label: 'Angola', states: []},
  {label: 'Anguilla', states: []},
  {label: 'Antigua & Barbuda', states: []},
  {label: 'Argentina', states: []},
  {label: 'Armenia', states: []},
  {label: 'Australia', states: []},
  {label: 'Austria', states: []},
  {label: 'Azerbaijan', states: []},
  {label: 'Bahamas', states: []},
  {label: 'Bahrain', states: []},
  {label: 'Bangladesh', states: []},
  {label: 'Barbados', states: []},
  {label: 'Belarus', states: []},
  {label: 'Belgium', states: []},
  {label: 'Belize', states: []},
  {label: 'Benin', states: []},
  {label: 'Bermuda', states: []},
  {label: 'Bhutan', states: []},
  {label: 'Bolivia', states: []},
  {label: 'Bosnia & Herzegovina', states: []},
  {label: 'Botswana', states: []},
  {label: 'Brazil', states: []},
  {label: 'Brunei Darussalam', states: []},
  {label: 'Bulgaria', states: []},
  {label: 'Burkina Faso', states: []},
  {label: 'Burundi', states: []},
  {label: 'Cambodia', states: []},
  {label: 'Cameroon', states: []},
  {label: 'Canada', states: []},
  {label: 'Cape Verde', states: []},
  {label: 'Cayman Islands', states: []},
  {label: 'Central African Republic', states: []},
  {label: 'Chad', states: []},
  {label: 'Chile', states: []},
  {label: 'China', states: []},
  {label: 'Colombia', states: []},
  {label: 'Comoros', states: []},
  {label: 'Congo', states: []},
  {label: 'Costa Rica', states: []},
  {label: 'Croatia', states: []},
  {label: 'Cuba', states: []},
  {label: 'Cyprus', states: []},
  {label: 'Czech Republic', states: []},
  {label: 'Democratic Republic of the Congo', states: []},
  {label: 'Denmark', states: []},
  {label: 'Djibouti', states: []},
  {label: 'Dominica', states: []},
  {label: 'Dominican Republic', states: []},
  {label: 'Ecuador', states: []},
  {label: 'Egypt', states: []},
  {label: 'El Salvador', states: []},
  {label: 'Equatorial Guinea', states: []},
  {label: 'Eritrea', states: []},
  {label: 'Estonia', states: []},
  {label: 'Ethiopia', states: []},
  {label: 'Fiji', states: []},
  {label: 'Finland', states: []},
  {label: 'France', states: []},
  {label: 'French Guiana', states: []},
  {label: 'Gabon', states: []},
  {label: 'Gambia', states: []},
  {label: 'Georgia', states: []},
  {label: 'Germany', states: []},
  {label: 'Ghana', states: []},
  {label: 'Great Britain', states: []},
  {label: 'Greece', states: []},
  {label: 'Grenada', states: []},
  {label: 'Guadeloupe', states: []},
  {label: 'Guatemala', states: []},
  {label: 'Guinea', states: []},
  {label: 'Guinea-Bissau', states: []},
  {label: 'Guyana', states: []},
  {label: 'Haiti', states: []},
  {label: 'Honduras', states: []},
  {label: 'Hungary', states: []},
  {label: 'Iceland', states: []},
  {label: 'India', states: []},
  {label: 'Indonesia', states: []},
  {label: 'Iran', states: []},
  {label: 'Iraq', states: []},
  {label: 'Israel', states: []},
  {label: 'Italy', states: []},
  {label: 'Ivory Coast (Cote d\'Ivoire)', states: []},
  {label: 'Jamaica', states: []},
  {label: 'Japan', states: []},
  {label: 'Jordan', states: []},
  {label: 'Kazakhstan', states: []},
  {label: 'Kenya', states: []},
  {label: 'Republic of Korea', states: []},
  {label: 'Kosovo', states: []},
  {label: 'Kuwait', states: []},
  {label: 'Kyrgyz Republic (Kyrgyzstan)', states: []},
  {label: 'Laos', states: []},
  {label: 'Latvia', states: []},
  {label: 'Lebanon', states: []},
  {label: 'Lesotho', states: []},
  {label: 'Liberia', states: []},
  {label: 'Libya', states: []},
  {label: 'Liechtenstein', states: []},
  {label: 'Lithuania', states: []},
  {label: 'Luxembourg', states: []},
  {label: 'Madagascar', states: []},
  {label: 'Malawi', states: []},
  {label: 'Malaysia', states: []},
  {label: 'Maldives', states: []},
  {label: 'Mali', states: []},
  {label: 'Malta', states: []},
  {label: 'Martinique', states: []},
  {label: 'Mauritania', states: []},
  {label: 'Mauritius', states: []},
  {label: 'Mayotte', states: []},
  {label: 'Mexico', states: []},
  {label: 'Moldova, Republic of', states: []},
  {label: 'Monaco', states: []},
  {label: 'Mongolia', states: []},
  {label: 'Montenegro', states: []},
  {label: 'Montserrat', states: []},
  {label: 'Morocco', states: []},
  {label: 'Mozambique', states: []},
  {label: 'Myanmar/Burma', states: []},
  {label: 'Namibia', states: []},
  {label: 'Nepal', states: []},
  {label: 'Netherlands', states: []},
  {label: 'New Zealand', states: []},
  {label: 'Nicaragua', states: []},
  {label: 'Niger', states: []},
  {label: 'Nigeria', states: []},
  {label: 'Norway', states: []},
  {label: 'Oman', states: []},
  {label: 'Pacific Islands', states: []},
  {label: 'Pakistan', states: []},
  {label: 'Panama', states: []},
  {label: 'Papua New Guinea', states: []},
  {label: 'Paraguay', states: []},
  {label: 'Peru', states: []},
  {label: 'Philippines', states: []},
  {label: 'Poland', states: []},
  {label: 'Portugal', states: []},
  {label: 'Puerto Rico', states: []},
  {label: 'Qatar', states: []},
  {label: 'Republic of Macedonia', states: []},
  {label: 'Reunion', states: []},
  {label: 'Romania', states: []},
  {label: 'Russian Federation', states: []},
  {label: 'Rwanda', states: []},
  {label: 'Saint Kitts and Nevis', states: []},
  {label: 'Saint Lucia', states: []},
  {label: 'Saint Vincent\'s & Grenadines', states: []},
  {label: 'Samoa', states: []},
  {label: 'Sao Tome and Principe', states: []},
  {label: 'Saudi Arabia', states: []},
  {label: 'Senegal', states: []},
  {label: 'Serbia', states: []},
  {label: 'Seychelles', states: []},
  {label: 'Sierra Leone', states: []},
  {label: 'Singapore', states: []},
  {label: 'Slovak Republic (Slovakia)', states: []},
  {label: 'Slovenia', states: []},
  {label: 'Solomon Islands', states: []},
  {label: 'Somalia', states: []},
  {label: 'South Africa', states: []},
  {label: 'South Sudan', states: []},
  {label: 'Spain', states: []},
  {label: 'Sri Lanka', states: []},
  {label: 'Sudan', states: []},
  {label: 'Suriname', states: []},
  {label: 'Swaziland', states: []},
  {label: 'Sweden', states: []},
  {label: 'Switzerland', states: []},
  {label: 'Syria', states: []},
  {label: 'Tajikistan', states: []},
  {label: 'Tanzania', states: []},
  {label: 'Thailand', states: []},
  {label: 'Timor Leste', states: []},
  {label: 'Togo', states: []},
  {label: 'Trinidad & Tobago', states: []},
  {label: 'Tunisia', states: []},
  {label: 'Turkey', states: []},
  {label: 'Turkmenistan', states: []},
  {label: 'Turks & Caicos Islands', states: []},
  {label: 'Uganda', states: []},
  {label: 'Ukraine', states: []},
  {label: 'United Arab Emirates', states: []},
  {label: 'United Kingdom', states: []},
  {label: 'Uruguay', states: []},
  {label: 'Uzbekistan', states: []},
  {label: 'Venezuela', states: []},
  {label: 'Vietnam', states: []},
  {label: 'Virgin Islands (UK)', states: []},
  {label: 'Virgin Islands (US)', states: []},
  {label: 'Yemen', states: []},
  {label: 'Zambia', states: []},
  {label: 'Zimbabwe', states: []}
];

const DATA_INTEGRITY_MISSING = 'MISSING';
const DATA_INTEGRITY_COMPLETE = 'COMPLETE';
const DATA_INTEGRITY_INCOMPLETE = 'INCOMPLETE';

const DATA_INTEGRITY_TYPES = [
  DATA_INTEGRITY_MISSING,
  DATA_INTEGRITY_COMPLETE,
  DATA_INTEGRITY_INCOMPLETE
];

const SERVICE_SORTING = ['mrc_desc', 'mrc_asc'];
const SERVICE_GROUP_SORTING = ['mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc', 'name_desc', 'name_asc', 'miso_id_asc', 'miso_id_desc'];
const ACCOUNT_ID_SORTING = ['label_asc', 'label_desc', 'mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc'];
const ADDRESS_SORTING = ['mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc'];
const CONTACT_SORTING = ['last_name_asc', 'last_name_desc', 'full_name_desc', 'full_name_asc', 'mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc', 'vendor_name_asc', 'vendor_name_desc'];
const EMPLOYEE_SORTING = ['last_name_asc', 'last_name_desc', 'full_name_desc', 'full_name_asc', 'mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc'];
const EQUIPMENT_SORTING = ['price_desc', 'price_asc'];
const PRODUCT_SORTING = ['category_asc', 'category_desc', 'mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc',];
const DOCUMENT_SORTING = ['date_desc', 'date_asc', 'name_desc', 'name_asc', 'mrc_desc', 'mrc_asc'];
const VENDOR_SORTING = ['mrc_desc', 'mrc_asc', 'service_count_desc', 'service_count_asc', 'vendor_name_asc', 'vendor_name_desc', 'miso_id_asc', 'miso_id_desc'];

const BULK_UPLOAD_FIELD_STATUS = {
  NONE: 'NONE',
  IGNORE: 'IGNORE',
  FOUND: 'FOUND',
  NOT_FOUND: 'NOT_FOUND',
  INVALID_DATA: 'INVALID_DATA'
};

const BULK_UPLOAD_DOCUMENT_STATUS = {
  CREATED: 'CREATED',
  PROCESSED: 'PROCESSED',
  ERROR: 'ERROR'
};

const BULK_UPLOAD_STATUS = {
  AUTO_UPDATE: 'AUTO_UPDATE',
  ERROR: 'ERROR',
  IMPORT_AS_NEW: 'IMPORT_AS_NEW',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  USER_REPROCESS: 'USER_REPROCESS',
  USER_REPROCESS_UPDATE_SUCCESS: 'USER_REPROCESS_UPDATE_SUCCESS',
  USER_REPROCESS_IMPORT_AS_NEW_SUCCESS: 'USER_REPROCESS_IMPORT_AS_NEW_SUCCESS',
  USER_IGNORED: 'USER_IGNORED'
};

const FRESHDESK_TICKET_TYPES = {
  BUG: 'Bug',
  DEMO_REQUEST: 'DEMO Request',
  FEATURE_REQEST: 'Feature Request',
  FILE_UPLOAD: 'File Upload',
  OTHER: 'Other',
  REPORT_A_PROBLEM: 'Report a Problem',
  API_INTEGRATION_REQUEST: 'API Integration Request'
};

const CUSTOMER_DOCUMENT_UPLOAD_TYPES = {
  INVOICE: 'INVOICE',
  SERVICE_ORDER: 'SERVICE_ORDER',
  MASTER_AGREEMENT: 'MASTER_AGREEMENT',
  OTHER: 'OTHER',
  AMENDMENT: 'AMENDMENT',
  RECEIPT: 'RECEIPT',
  NDA: 'NDA'
};

const CUSTOMER_IMPORT_DOCUMENT_TYPES = {
  INVOICE: 'INVOICE',
  SERVICE_ORDER: 'SERVICE_ORDER',
  MASTER_AGREEMENT: 'MASTER_AGREEMENT',
  EQUIPMENT_EXPORT: 'EQUIPMENT_EXPORT',
  LOCATION_EXPORT: 'LOCATION_EXPORT',
  VENDOR_EXPORT: 'VENDOR_EXPORT',
  EMPLOYEE_EXPORT: 'EMPLOYEE_EXPORT',
  CONTACT_EXPORT: 'CONTACT_EXPORT',
  OTHER: 'OTHER',
  AMENDMENT: 'AMENDMENT',
  RECEIPT: 'RECEIPT',
  NDA: 'NDA'
};

const READABLE_CUSTOMER_IMPORT_DOCUMENT_TYPES = {
  INVOICE: 'Invoice',
  SERVICE_ORDER: 'Service Order',
  MASTER_AGREEMENT: 'Master Agreement',
  EQUIPMENT_EXPORT: 'Equipment Export',
  LOCATION_EXPORT: 'Location Export',
  VENDOR_EXPORT: 'Vendor Export',
  EMPLOYEE_EXPORT: 'Employee Export',
  CONTACT_EXPORT: 'Contact Export',
  OTHER: 'Other',
  AMENDMENT: 'AMENDMENT',
  RECEIPT: 'RECEIPT',
  NDA: 'NDA'
};

const ACCOUNT_ID_NOTPROVIDEDBYVENDOR_ID = 'NotProvidedbyVendor';
const ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT = 'Not Provided';

const RECENT_CONTACTS = 'recent_contacts';

const MAX_RECENT_CONTACTS = 10;

const WORD_DOC_TYPES = '.docx,.docm,.dotx,.dotm,.docb,.rtf';
const EXCEL_DOC_TYPES = '.xlsx,.xlsm,.xltx,.xltm';
const POWER_POINT_DOC_TYPES = '.ppt,.pptx';
const IMAGE_DOC_TYPES = '.svg,.png,.jpeg,.jpg';
const CLIENT_DOC_TYPES = ['.csv', '.pdf', WORD_DOC_TYPES, EXCEL_DOC_TYPES, POWER_POINT_DOC_TYPES, IMAGE_DOC_TYPES].join(',');

const UPLOAD_TYPES = {
  DEFAULT: {
    KEY_PREFIX: 'MISO Default',
    MAX_CONTENT_LENGTH: '500000'
  },
  MIYAGI_VENDOR_LOGO: {
    CACHECONTROL: 'public,max-age=2052000',
    CONTENT_TYPE: IMAGE_DOC_TYPES,
    KEY_PREFIX: 'vendor-logo',
    MAX_CONTENT_LENGTH: '512000',
    PUBLIC: true
  },
  PROMOTION_GRAPHIC: {
    CACHECONTROL: 'public,max-age=2052000',
    CONTENT_TYPE: IMAGE_DOC_TYPES,
    KEY_PREFIX: 'promotion-graphic',
    MAX_CONTENT_LENGTH: '512000',
    PUBLIC: true
  },
  TENANT_LOGO: {
    CACHECONTROL: 'public,max-age=2052000',
    CONTENT_TYPE: IMAGE_DOC_TYPES,
    KEY_PREFIX: 'tenant-logo',
    MAX_CONTENT_LENGTH: '512000',
    PUBLIC: true
  },
  TENANT_DOCUMENT_FILE: {
    CONTENT_TYPE: CLIENT_DOC_TYPES,
    KEY_PREFIX: 'files',
    MAX_CONTENT_LENGTH: '20000000',
    PUBLIC: false
  },
  TENANT_SYNONYM_FILE: {
    CONTENT_TYPE: '.csv',
    KEY_PREFIX: 'temp',
    MAX_CONTENT_LENGTH: '50000000',
    PUBLIC: false
  },
  TENANT_BULK_UPLOAD_FILE: {
    CONTENT_TYPE: ['.csv','.json','application/json', '.xlsx'],
    KEY_PREFIX: 'bud',
    MAX_CONTENT_LENGTH: '50000000',
    PUBLIC: false
  },
  TENANT_USER_UPLOAD_FILE: {
    CONTENT_TYPE: ['.csv','.json','application/json', '.xlsx'],
    KEY_PREFIX: 'bud',
    MAX_CONTENT_LENGTH: '50000000',
    PUBLIC: false
  },
  STYLE_GUIDE: {
    CACHECONTROL: 'public,max-age=2052000',
    CONTENT_TYPE: IMAGE_DOC_TYPES,
    KEY_PREFIX: 'testing/style-guide',
    MAX_CONTENT_LENGTH: '50000000',
    PUBLIC: true
  },
  LINK: {
    CONTENT_TYPE: '*',
    KEY_PREFIX: '',
    MAX_CONTENT_LENGTH: '',
    PUBLIC: true
  },
  REPORT: {
    CONTENT_TYPE: CLIENT_DOC_TYPES,
    KEY_PREFIX: 'REPORT',
    MAX_CONTENT_LENGTH: '50000000',
    PUBLIC: false
  },
  CUSTOMER_DOCUMENT_IMPORT: {
    CONTENT_TYPE: CLIENT_DOC_TYPES,
    KEY_PREFIX: 'customer-document-import',
    MAX_CONTENT_LENGTH: '20000000',
    PUBLIC: false
  },
};

Object.keys(UPLOAD_TYPES).forEach(function(key) {
  UPLOAD_TYPES[key].KEY_TO_STRING = key;
});

const CONTACT_SEARCH_TYPE = {
  ONLY_RECENT_CONTACTS: 'ONLY_RECENT_CONTACTS',
  ONLY_VENDOR_CONTACTS: 'ONLY_VENDOR_CONTACTS'
};

const DATA_CONNECTOR_STATUS_TYPE = {
  PROCESSING: 'PROCESSING',
  IMPORTING: 'IMPORTING',
  SUCCESS: 'SUCCESS',
  GLUE_ERROR: 'GLUE_ERROR',
  IMPORT_ERROR: 'IMPORT_ERROR',
  NEEDS_REVIEW: 'NEEDS_REVIEW'
};

const SERVICE_GROUP_STATUS_ACTIVE = 'SERVICE_GROUP_STATUS_ACTIVE';
const SERVICE_GROUP_STATUS_BROKEN = 'SERVICE_GROUP_STATUS_BROKEN';
const SERVICE_GROUP_STATUS_INACTIVE = 'SERVICE_GROUP_STATUS_INACTIVE';

const SERVICE_GROUP_STATUS = [
  SERVICE_GROUP_STATUS_ACTIVE,
  SERVICE_GROUP_STATUS_BROKEN,
  SERVICE_GROUP_STATUS_INACTIVE
];

const EQUIPMENT_STATUSES = [
  'ACTIVE',
  'INACTIVE'
];

const LOCATION_STATUS_ACTIVE = 'ACTIVE';
const LOCATION_STATUS_INACTIVE = 'INACTIVE';

const LOCATION_STATUSES = [
  LOCATION_STATUS_ACTIVE,
  LOCATION_STATUS_INACTIVE
];

const ENCRYPTED_VALUE_PLACEHOLDER = '********';


const WARRANTY_STATUS_ACTIVE = 'WARRANTY_STATUS_ACTIVE';
const WARRANTY_STATUS_INACTIVE = 'WARRANTY_STATUS_INACTIVE';

const LEASE_STATUS_ACTIVE = 'LEASE_STATUS_ACTIVE';
const LEASE_STATUS_INACTIVE = 'LEASE_STATUS_INACTIVE';

const SERVICE_LISTING_FIELDS = {
  "vendor_purchasing": "Vendor (Purchasing)",
  "account_identifier": "Account Identifier",
  "vendor_sales_representative": "Vendor Sales Representative",
  "vendor_manufacturer": "Vendor (Manufacturer)",
  "product_name": "Product Name",
  "product_category": "Product Category",
  "service_quantity": "Service Quantity",
  "unit_recurring_cost": "Unit Recurring Cost",
  "billing_frequency": "Billing Frequency",
  "unit_non_recurring_cost": "Unit Non-Recurring Cost",
  "currency": "Currency",
  "service_start_date": "Service Start Date",
  "service_term": "Service Term",
  "service_end_date": "Service End Date",
  "service_identifier": "Service Identifier",
  "location_a": "Location A",
  "location_b": "Location B",
  "parent_name": "Parent Name",
  "parent_product": "Parent Product",
  "service_group": "Service Group",
  "order_number": "Order Number",
  "order_date": "Order Date",
  "invoice_number": "Invoice Number",
  "invoice_date": "Invoice Date",
  "billing_period_start": "Billing Period: Start",
  "billing_period_end": "Billing Period: End",
  "invoice_total_new_charges": "Invoice Total (New Charges)",
  "doc_id": "DOC ID",
  "auto_renew_length": "Auto Renew Length",
  "advance_days_to_notify_of_change": "Advance Days to Notify of Change",
  "cancellation": "Cancellation",
  "fair_market_value_fmv_buyout": "Fair Market Value (FMV) Buyout",
  "de_minimus_buyout": "De Minimus Buyout",
  "new_rate": "New Rate",
  "percent_change": "Percent Change (+/-)",
  "new_term_length": "New Term Length",
  "service_owner_requestor": "Service Owner",
  "service_user": "Service User",
  "service_user_email": "Service User Email",
  "other_contact": "Other Contact",
  "equipment_manufacturer": "Equipment Manufacturer",
  "equipment_model_name": "Equipment Model Name",
  "equipment_serial_number": "Equipment Serial Number",
  "equipment_tag_number": "Equipment Tag Number",
  "assigned_email": "Email of M3 Owner",
  "assigned_division": "Service M3 Owner Division",
  "employee": "Service Owner",
  "assigned_team": "Service M3 Owner Team",
  "requestor_email":	"Service Owner",
  "requestor_division":	"Service Owner Business Unit",
  "requestor_team":	"Service Owner Team",
  "end_user_email":	"Subscriber",
  "end_user_division":	"Subscriber Business Unit",
  "end_user_team":	"Subscriber Team",
  "parent_group": "Parent Group"
};


export {
  ENCRYPTED_VALUE_PLACEHOLDER,
  CONTACT_SEARCH_TYPE,
  DATA_CONNECTOR_STATUS_TYPE,
  ACCOUNT_ID_NOTPROVIDEDBYVENDOR_ID,
  ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT,
  THIRTY_DAY_ACCESS,
  BLACK_LISTED_EXTENSIONS,
  BLACK_LISTED_FILE_TYPES,
  BULK_UPLOAD_DOCUMENT_STATUS,
  BULK_UPLOAD_STATUS,
  BULK_UPLOAD_FIELD_STATUS,
  EMAIL_RECIPIENT_TYPES,
  EMAIL_CC,
  EMAIL_BCC,
  EMAIL_TO,
  VENDOR_STATUS_CLOSED,
  VENDOR_STATUS_IGNORED,
  VENDOR_STATUS_OPEN,
  VENDOR_STATUSES,
  PRODUCT_STATUS_CLOSED,
  PRODUCT_STATUS_IGNORED,
  PRODUCT_STATUS_OPEN,
  PRODUCT_STATUSES,
  EVENT_TYPES,
  CONTACT_ROLE_SALES,
  CONTACT_ROLE_SUPPORT,
  CONTACT_ROLE_PROVISIONING,
  CONTACT_ROLE_FINANCE,
  CONTACT_ROLE_INTERNAL,
  CONTACT_ROLE_OTHER,
  CONTACT_ROLES,
  TELEPHONE_LOCALITY_OTHER,
  TELEPHONE_LOCALITY_US,
  TELEPHONE_LOCALITIES,
  CONTACT_STATUS_ACTIVE,
  CONTACT_STATUS_INACTIVE,
  CONTACT_STATUSES,
  GROUPED_COUNTRIES,
  FILE_TYPES,
  FINANCIAL_TYPES,
  FINANCIAL_STANDARD,
  FINANCIAL_RETERM,
  FINANCIAL_CANCELLATION,
  EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_RE_TERM,
  EVENT_TYPE_REPLACE,
  DATA_INTEGRITY_MISSING,
  DATA_INTEGRITY_COMPLETE,
  DATA_INTEGRITY_INCOMPLETE,
  DATA_INTEGRITY_TYPES,
  SERVICE_CURRENT,
  SERVICE_ARCHIVED,
  SERVICE_UNKNOWN,
  SERVICE_STATUSES,
  EQUIPMENT_SORTING,
  DOCUMENT_SORTING,
  PRODUCT_SORTING,
  CONTACT_SORTING,
  EMPLOYEE_SORTING,
  VENDOR_SORTING,
  SERVICE_SORTING,
  SERVICE_GROUP_SORTING,
  ACCOUNT_ID_SORTING,
  ADDRESS_SORTING,
  POST_LOGIN_USER_POLICY,
  CHECKOUT_ACCESS_FUNCTION,
  ACCESS_FUNCTIONS,
  NEW_PASSWORD,
  RESET_PASSWORD,
  RECURLY_BILLING,
  MANUAL_BILLING,
  PAYMENT_PLAN_BILLING_TYPES,
  REPORT_TYPES,
  REPORT_TYPE_MISSING_DATA,
  REPORT_TYPE_CONTACT_FOR_IMPORT,
  REPORT_TYPE_EQUIPMENT_FOR_IMPORT,
  REPORT_TYPE_SERVICE_FOR_IMPORT,
  REPORT_TYPE_SERVICE_STANDARD,
  REPORT_TYPE_SERVICE_COMBINE_PARENTS,
  REPORT_TYPE_LOCATION_FOR_IMPORT,
  REPORT_TYPE_PARENT_SERVICE_FOR_IMPORT,
  REPORT_TYPE_ACCOUNT_ID_FOR_IMPORT ,
  REPORT_TYPE_SERVICE_GROUP_FOR_IMPORT,
  REPORT_SHORT_IDS,
  CUSTOM_FIELD_TYPES,
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_PHONE,
  CUSTOM_FIELD_TYPE_CONTACT,
  CUSTOM_FIELD_TYPE_DROPDOWN,
  CUSTOM_FIELD_TYPE_DATE,
  CUSTOM_FIELD_TYPE_NUMBER,
  PARENT_SERVICE_STATUS,
  HAS_PARENT,
  HAS_NO_PARENT,
  IS_PARENT,
  ALERT_STATUS_NONE,
  ALERT_STATUS_BROKEN,
  ALERT_STATUS_INACTIVE,
  ALERT_STATUSES,
  COLLECTION_TYPE_REFERENCES,
  PRODUCT_FIELD_TYPES,
  BANDWIDTH_UNITS,
  CUSTOMER_DOCUMENT_UPLOAD_TYPES,
  CUSTOMER_IMPORT_DOCUMENT_TYPES,
  READABLE_CUSTOMER_IMPORT_DOCUMENT_TYPES,
  RECENT_CONTACTS,
  MAX_RECENT_CONTACTS,
  UPLOAD_TYPES,
  FRESHDESK_TICKET_TYPES,
  TOKEN_ERROR_EXPIRED,
  TOKEN_ERROR_INVALID,
  EMAIL_TYPE_OTHER,
  EMAIL_TYPE_MISSING_DATA,
  EMAIL_TYPE_REQUEST_CANCELLATION,
  EMAIL_TYPE_REQUEST_QUOTE,
  EMAIL_TYPE_RE_ASSESSMENT_REQUEST,
  EMAIL_TYPES,
  SENT_FROM_MIYAGI,
  SENT_FROM_QUEUE,
  EMAIL_SOURCE,
  SERVICE_GROUP_STATUS,
  EQUIPMENT_STATUSES,
  LOCATION_STATUSES,
  LOCATION_STATUS_ACTIVE,
  LOCATION_STATUS_INACTIVE,
  ALERT_CAUSE_CONTACT,
  ALERT_CAUSE_EQUIPMENT,
  ALERT_CAUSE_LOCATION,
  ALERT_CAUSES,
  WARRANTY_STATUS_ACTIVE,
  WARRANTY_STATUS_INACTIVE,
  LEASE_STATUS_ACTIVE,
  LEASE_STATUS_INACTIVE,
  SERVICE_LISTING_FIELDS
};
