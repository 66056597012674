<ng-container *ngIf="step===SEARCH_STATE">

  <mi-server-error [error]="error"></mi-server-error>
  <div class="card px-3 pt-3 pb-2">
  <mi-search placeholder="Enter Address" value="{{searchString}}"
    (searchTermChanged)="onUpdateSearchStringHandler($event)"></mi-search>
  </div>
  <div class="card mt-2">
  <ng-container>
    <ng-container *ngIf="results && results.length > 0; else noLocations">
        <div class="address-results">
      <table class="results">
        <tr>
          <th></th>
          <th>
            Address
          </th>
          <th>
            Locations
          </th>
        </tr>

        <ng-container *ngFor="let item of results">
          <tr (click)="onAddressSelected(item)" [ngClass]="{'google-hit': !item.id}">
            <td width="40">
              <div class="location">
                <input type="checkbox" value="{{item}}" />
              </div>
            </td>
            <td>
              <div class="location">
                <div>
                  <span class="contact--name">
                    {{item.formatted_address}}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div class="contact--company">
                <!--{{item.locations && item.locations.length}}-->
                {{item.location_count}}
              </div>
            </td>
          </tr>
        </ng-container>
        </table>
        </div>

    </ng-container>
  <ng-container *ngIf="loadingMore">
    <mi-loading-animation></mi-loading-animation>
  </ng-container>
  
  <div class="load-more" *ngIf="!loadingMore && (results.length < total) && !selectedAddress && results.length != 0">
    <a mi-button href="#" (click)="onLoadMoreClicked($event)">Load More</a>
  </div>

    <ng-template #noLocations>
      <div *ngIf="!searchString || searchString.length <= 2" class="px-3 pt-2">
        You have no addresses yet.<br> Please start searching for an address above.
      </div>

      <div *ngIf="searchString && searchString.length > 2" class="px-3 pt-2">
        No addresses found containing <strong>"{{searchString}}"</strong>
      </div>
    </ng-template>

    <div class="cta-message p-1" *ngIf="searchString && searchString.length > 2">
      <h3>{{'TENANT.ADDRESSES.CANNOT_FIND_ADDRESS_TITLE' | translate }}</h3>
      <p>{{'TENANT.ADDRESSES.CANNOT_FIND_ADDRESS_COPY' | translate }}</p>
      <a (click)="swapTo(CREATE_STATE)" class="link--after">{{'TENANT.ADDRESSES.CANNOT_FIND_CTA' | translate }}</a>
    </div>

    <ng-container *ngIf="isAdditionalAddressDetailsVisible">
      <hr>
      <tr (click)="ignoreAddressToggleHandler()" class="px-3">
        <td width="40">
          <div class="location">
            <input [checked]="isAddressIgnoreChecked" type="checkbox" />
          </div>
        </td>
        <td>
          <div class="location">
            <h3>{{'TENANT.ADDRESSES.IGNORE_THIS_ADDRESS' | translate }}</h3>
          </div>
        </td>
      </tr>
      <div>
        <div class="button-container">
          <button (click)="updateAddressHandler()" mi-button buttonTheme="dark-theme" [disabled]="ignoreSubmitButtonDisable">
            {{!updatingAddress ? 'Confirm': 'Updating...' }}
          </button>
        </div>
      </div>
    </ng-container>

  </ng-container>
  </div>
  

</ng-container>

<ng-container *ngIf="step===CREATE_STATE">
  <mi-address-form (canceled)="swapTo(SEARCH_STATE)" (addressSelected)="onAddressSelected($event)"
    (newCreated)="onNewCreated()" [tenantId]="tenantId">
  </mi-address-form>
</ng-container>


<ng-container *ngIf="step===CREATE_LOCATION_STATE">
  <mi-location-form (newCreated)="onNewCreated()" (canceled)="onLocationCanceled()"
    (chosenLocation)="onLocationSelected($event)" [editId]="this.selectedAddress.id" [owner]="this.owner">
  </mi-location-form>
</ng-container>