<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content >

    <div class="chosen-contacts">
      <mi-find-or-create-tenant-user
        [searchString]="defaultSearchText"
        (contactSelected)="onAddContact($event)">
      </mi-find-or-create-tenant-user>
    </div>
    <div class="button-container">
      <button mi-button buttonTheme="light-theme" (click)="close()">Cancel</button>
    </div>
  </modal-content>
</modal>
