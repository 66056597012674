import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";

@Component({
  templateUrl: './confirm-delete-vendor-term-modal.component.html',
  styleUrls: ['./confirm-delete-vendor-term-modal.component.scss']
})

export class ConfirmDeleteVendorTermsModalComponent implements OnInit {
  title: string = 'Confirm delete term';
  
  constructor(
    private modal: ModalRef) {
  }

  ngOnInit(): void { }

  close() {
    this.modal.close(false);
  }

  confirmHandler(): void {
    this.modal.close(true);
  }
}
