import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {select, Store} from '@ngrx/store';
import * as appDataReducer from '../../../shared/state/app-data/reducers';
import {CountryV2} from '../../../libs/api2/types/CountryV2.interface';
import {StateV2} from '../../../libs/api2/types/StateV2.interface';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {takeUntil} from 'rxjs/operators';
import {Miso3baseService} from '../../../libs/api2/miso3base-v2/miso3base.service';
import {AddressV2Service} from '../../../libs/api2/address-v2/address-v2.service';
import { AddressV2 } from 'src/libs/api2/types/AddressV2.interface';
import { ToastrService } from "ngx-toastr";

const log = createLogger(LOG_LEVELS.CORE);

export enum CreateEditAddressesMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

@Component({
  selector: 'mi-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})

export class AddressFormComponent extends AbstractPageComponent implements OnInit {
  @Input() address: AddressV2;
  @Input() tenantId: string;
  @Output() addressSelected: EventEmitter<AddressV2> = new EventEmitter<AddressV2>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() addressUpdated: EventEmitter<AddressV2> = new EventEmitter<AddressV2>();
  @Output() newCreated: EventEmitter<void> = new EventEmitter<void>();
  mode: CreateEditAddressesMode;
  form: FormGroup = null;
  loading: boolean = false;
  loadError: string = '';
  submitError: string = '';
  ctaLabel: string = '';
  countries: CountryV2[] = [];
  states: StateV2[] = [];
  owner: string = null;

  constructor(private formBuilder: FormBuilder,
              private miso3baseService: Miso3baseService,
              private tenantAddressV2Service: AddressV2Service,
              private store: Store<appDataReducer.State>,
              private toastrService: ToastrService) {
    super();
  }

  ngOnInit() {
    const fetchCountries = this.miso3baseService.getCountries();
    const fetchTenant = this.store.select(appDataReducer.getAppData);

    combineLatest(fetchTenant, fetchCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([tenant, countries]) => {
          // this.owner = tenant.tenantData.domain;
          if (!this.owner) {
            this.owner = this.tenantId;
          }
          if (tenant.tenantData && tenant.tenantData.domain) {
            this.owner = tenant.tenantData.domain;
          }
          this.countries = countries;

          console.log('address on create / edit', this.address);

          if (this.address) {
            this.loading = true;
            this.startAsEditMode(this.address);
          } else {
            this.startAsCreateMode();
          }
        },
        (e) => {
          this.loadError = e;
        });
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  startAsCreateMode(): void {
    this.mode = CreateEditAddressesMode.CREATE;
    this.ctaLabel = 'Create Address';
    this.createForm();
  }

  startAsEditMode(address: AddressV2): void {
    this.mode = CreateEditAddressesMode.EDIT;
    this.ctaLabel = 'Update Address';
    this.loadError = '';
    this.createForm(address);
  }

  onCountryChange(countryLabel, state_id) {
    const country = this.countries.find(x => x.id === countryLabel);
    if (country) {
      this.miso3baseService.getStates(country.id)
        .subscribe(states => {
          this.states = states;
          this.form.setValue({
            ...this.form.value,
            state_id: state_id
          });
        });
    } else {
      this.states = null;
      this.form.setValue({
        ...this.form.value,
        state_id: state_id
      });
    }

    if (this.states === undefined || this.states === null) {
      this.states = [];
    }
  }

  createForm(address: any = {}): void {

    let {
      city,
      country_id,
      state_id,
      state_other,
      zip,
      street_address,
    } = address;

    if (!state_id || state_id === 'null') {
      state_id = '';
    }

    country_id = country_id || null;

    this.form = this.formBuilder.group({
      city: [city],
      country_id: [country_id, Validators.required],
      state_id: [state_id],
      state_other: [state_other],
      zip: [zip],
      street_address: [street_address, Validators.required]
    });

    this.onCountryChange(country_id, state_id);

    this.loading = false;
  }

  AddressRequiredValidator(pageCtrl: any) {
    return (input: FormControl) => {
        const isValid = input.value || !(pageCtrl.states && pageCtrl.states.length > 0);
    	return isValid ? null : {state: false};
    };
  }

  onFormSubmit(formValue): void {
    if (this.mode === CreateEditAddressesMode.CREATE) {
      this.createAddress(formValue);
    } else if (this.mode === CreateEditAddressesMode.EDIT) {
      this.updateAddress(formValue);
    }
  }

  createAddress(data: any = {}): void {
    let {
      state_id
    } =  data;

    if (!state_id || state_id === 'null') {
      state_id = '';
    }

    data.owner = this.owner;

    console.log('data on create address', data);

    this.tenantAddressV2Service.saveAddress(data).subscribe((address) => {
      //this.indexAddress(address.id);
      this.toastrService.success("Address created successfully");
      this.addressSelected.emit(address);
      this.newCreated.emit();
    }, (error) => {
      this.submitError = error;
    });
  }

  updateAddress(data: any = {}): void {
    let {
      state_id
    } =  data;

    if (!state_id || state_id === 'null') {
      state_id = '';
    }

    data.owner = this.owner;

    this.tenantAddressV2Service.saveAddress({
      ...data,
      id: this.address.id
    }).subscribe((address) => {
      this.toastrService.success("Address updated successfully");
      this.addressUpdated.emit(address);
      //this.indexAddress(this.address.id);
    }, (error) => {
      this.submitError = error;
    });
  }

  indexAddress(id) {
    this.tenantAddressV2Service.indexAddress(id).subscribe((response) => {
      console.log('response in indexing address', response);
    }, (error) => {
      console.log('error in indexing address', error);
    });
  }
}
