import { Routes } from '@angular/router';
import { VendorRenewalTermComponent } from './vendor-renewal-term/vendor-renewal-term.component';
import { AccountEndOfTermsComponent } from './account-end-of-term/account-end-of-term.component';

const TermRenewalRoutes: Routes = [
  {
    path: 'renewal-term',
    component: VendorRenewalTermComponent,
  },
  {
    path: 'account-end-of-term',
    component: AccountEndOfTermsComponent,
  },
];

export { TermRenewalRoutes }
