import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DivisionV2} from "../types/DivisionV2.interface";
import {BusinessUnitV2Service} from "../tenant-v2/bussiness-unit-v2.service.interface";


@Injectable()
export class DivisionV2Service implements BusinessUnitV2Service{

  private divisionHost = '_MICRO_SERVICE_/tenant';

  constructor(private http: HttpClient) {

  }

  newBusinessUnit(params: DivisionV2): Observable<DivisionV2> {
    return this.http
      .post(`${this.divisionHost}/division`, params)
      .map((response: any) => response as DivisionV2);
  }

  getBusinessUnits(parent_id: string = ''): Observable<DivisionV2[]> {
    const params = {
      id: parent_id
    };
    return this.http
      .get(`${this.divisionHost}/divisions`, {params})
      .map((response: any) => response as DivisionV2[]);
  }

  getBusinessUnit(id: string = ''): Observable<DivisionV2> {
    const params = {
      id: id
    };
    return this.http
      .get(`${this.divisionHost}/division`, {params})
      .map((response: any) => response as DivisionV2);
  }

  deleteDivisions(ids: string[], isSite: boolean) {
    const url = isSite ? `${this.divisionHost}/delete-sites` : `${this.divisionHost}/delete-divisions`;
    return this.http
      .post(url, ids)
      .map((response: any) => response);
  }
}
