<div class="chosen-contacts">
  <mi-sortable-table
    *ngIf="contactRows.length; else noContacts"
    [columns]="contactColumns"
    [rows]="contactRows"
    externalClasses="serviceDetails">
  </mi-sortable-table>

  <ng-template #noContacts>
    {{'TENANT.SERVICE_DETAILS.NO_CONTACTS' | translate }}
  </ng-template>
</div>

<ng-template #emailTmpl let-row="row" let-value="value">
  <a *ngIf="value; else staticServiceAssociationNoEmail" href="mailto:{{value}}">{{value}}</a>
  <ng-template #staticServiceAssociationNoEmail>
    {{NO_DATA}}
  </ng-template>
</ng-template>

<ng-template #contactTmpl let-row="row" let-value="value">
  <a routerLink="/contacts/{{row.id}}">
    <ng-container>
      {{value}}
    </ng-container>
  </a>
  <a class="edit-contact" routerLink="/contacts/{{row.id}}/edit"></a>
</ng-template>

<ng-template #serviceAssociationTmpl let-row="row" let-value="value">
  <ng-container *ngIf="row.service_association_type=='Acc Rep'; else staticServiceAssociation">
    {{'Acc Rep for '}} <mi-id-link [id]="row.service_association_display_id"></mi-id-link> {{row.service_association}}
  </ng-container>
  <ng-template #staticServiceAssociation>
      {{row.service_association}}
  </ng-template>
<ng-template>