import {Component} from '@angular/core';
import {Link} from '../../../../../shared/ng-models/Link.interface';
import {OrderState} from './state/reducer';
import {Store} from '@ngrx/store';
import {ResetOrderAction} from './state/actions';
import {Router} from '@angular/router';

@Component({
  selector: 'mi-order-create',
  templateUrl: './service-order-create.component.html'
})

export class ServiceOrderCreateComponent {
  breadcrumbs: Link[] = [
    {
      url: '',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true

    },
    {
      url: '/services/create',
      label: 'Service Create',
      internal: true

    },
    {
      url: '/services/orders/create',
      label: 'Add Order',
      internal: true
    }
  ];
  error: string;
  order: OrderState;

  constructor(
    private store: Store<OrderState>,
    private router: Router
  ) {
  }

  ngOnInit() {
    // always reset when this page loads.
    this.store.dispatch(new ResetOrderAction({}));
  }

  onCancelOrder() {
    function myConfirm(message) {
      const start = Number(new Date());
      const result = confirm(message);
      const end = Number(new Date());
      return (end<(start+10)||result==true);
    }
  if (myConfirm('This order is not saved, you will lose any in-progress work, Do you wish to exit?')) {
      this.store.dispatch(new ResetOrderAction({}));
      this.router.navigateByUrl('/services/create');
    }
  }
}
