
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {MiServiceGroup} from '../../../shared/models/MiServiceGroup';

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-service-group-service-form',
  templateUrl: './service-group-service-form.component.html',
  styleUrls: ['./service-group-service-form.component.scss']
})

export class ServiceGroupServiceFormComponent extends AbstractPageComponent implements OnInit {

  @Output() serviceGroupComplete: EventEmitter<MiServiceGroup[]> = new EventEmitter<MiServiceGroup[]>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  @Input() submitCTA:string = 'Apply';
  @Input() cancelCTA:string = 'Cancel';
  @Input() isDetailPage: boolean = false;

  @Input('currentServiceGroupsSet')
  set currentServiceGroupsSet(val) {
    if (val) {
      this.currentServiceGroups = JSON.parse(JSON.stringify(val));
    }
  }
  currentServiceGroups: MiServiceGroup[] = [];


  constructor() {
    super();
  }

  ngOnInit() {
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  onServiceGroupComplete($event): void {
    $event.preventDefault();
    this.serviceGroupComplete.emit(this.currentServiceGroups);
  }

  onServiceGroupSelected(serviceGroup){
    this.currentServiceGroups = this.currentServiceGroups.filter(eq => eq.display_id != serviceGroup.display_id);
    this.currentServiceGroups.push(serviceGroup);
    this.currentServiceGroups
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
  }

  onServiceGroupRemoved(serviceGroup){
    this.currentServiceGroups = this.currentServiceGroups.filter(eq => eq.display_id != serviceGroup.display_id);
  }

}

