<div class="parent-service-details-header-item parent-service-details-vendor"
     *ngIf="(pageLoaded$ | async); else loading">

  <div *ngIf="vendorLogoUrl" class="logo-container">
    <img [src]="vendorLogoUrl" class="vendor-logo"/>
  </div>

  <div *ngIf="!vendorLogoUrl" class="vendor-title">
    {{parentService.product.product_vendor.vendor_name}}
  </div>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
