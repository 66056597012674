import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";
import {Contact} from "../../../shared/models/Contact";
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';

@Component({
  selector: 'mi-chosen-contact',
  styleUrls: ['./chosen-contact.component.scss'],
  templateUrl: './chosen-contact.component.html'
})

export class ChosenContactComponent  extends AbstractPageComponent implements OnInit{
  @Input()    contact:any;
  @Input()    locked:Boolean = false;
  @Output()   deselect:EventEmitter<VendorContact> = new EventEmitter();

  constructor(private contactV2Service: ContactV2Service){
    super();
  }

  ngOnInit() {
    if(this.contact && !this.contact.id){
      this.contactV2Service.getContactById(this.contact)
      .toPromise()
      .then(contact => {
        this.contact = contact;
      });
    }

  }


  onDeleteContact(){
    this.deselect.emit(this.contact);
  }
}
