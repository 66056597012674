import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);
import {ProductSearchResults} from '../../../shared/models/SearchResults';
import {HttpParams} from '@angular/common/http';
import {Product} from '../../../shared/models/Product';
import {OrderDraft} from '../../../shared/models/OrderDraft';

@Injectable()
export class TenantOrderDraftsService {

  private apiUrlCreateOrderDraft = '/{tenant}/orders/drafts';
  private apiUrlGetOrderDraft = '/{tenant}/orders/drafts';

  constructor(private http: HttpClient) {
  }

  getOrderDraft(id: String): Observable<OrderDraft> {
    return this.http
      .get(`${this.apiUrlGetOrderDraft}/${id}`)
      .map((response: any) => response.data.order_draft as OrderDraft);
  }

  createOrderDraft(draft: OrderDraft): Observable<OrderDraft> {
    return this.http
      .post(this.apiUrlCreateOrderDraft, draft)
      .map((response: any) => response.data.order_draft as OrderDraft);
  }

  updateOrderDraft(draft: OrderDraft): Observable<OrderDraft> {
    let id = draft._id;

    if (draft.service_details && draft.service_details.locations) {
      draft.service_details.locations = draft.service_details.locations.map(loc => loc && loc._id ? loc._id : (loc && loc.id ? loc.id : loc));
    }

    return this.http
      .put(`${this.apiUrlGetOrderDraft}/${id}`, draft)
      .map((response: any) => response.data.order_draft as OrderDraft);
  }
}
