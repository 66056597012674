import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../../../shared/models/User';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class UserService {

  private apiUrlCrud = '/account/user';
  private apiUrlLogin = '/login';
  private apiUrlLogout = '/logout';
  private apirUrlFetchAll = '/account/users';
  private apirUrlGetUser = '/account/user';
  private apiUrlGetMyTenants = '/account/user/me/tenants';
  private apiUrlAcceptPrivacyPolicy = '/account/accept-privacy-policy';
  private apiUrlTutorialViewed = '/{tenant}/shortcut-tutorial-viewed';

  constructor(private http: HttpClient) {
  }

  getLoggedInUser(): Promise<User> {
    return this.http
      .get<User>(this.apirUrlGetUser)
      .toPromise()
      .then((response: any) => response.data.user);
  }

  getUserById(_id: string): any {
    return this.http
      .get<any>(this.apirUrlGetUser + `/${_id}`)
      .toPromise()
      .then((response: any) => response.data.user);
  }

  getUsers(): Promise<any> {
    return this.http
      .get<any>(this.apirUrlFetchAll)
      .toPromise()
      .then((response: any) => response.data);
  }

  getMyTenants(): Promise<any> {
    return this.http
      .get<any>(this.apiUrlGetMyTenants)
      .toPromise()
      .then((response: any) => response.data);
  }

  isSuperAdmin(user: User): boolean {
    return user.isSuperUser;
  }

  logout(): Promise<any> {
    return this.http
      .get<any>(this.apiUrlLogout)
      .toPromise()
      .then((response: any) => response.data);
  }

  initChangePassword(): Promise<any> {
    return this.http
      .post<any>('/account/init-password-change', {})
      .toPromise()
      .then((response: any) => response.data);
  }

  initResetPassword(email: String): Promise<any> {
    return this.http
      .post<any>('/account/init-forgot-password', {email: email})
      .toPromise();
  }

  getResetPasswordLink(email: String, code: any): Observable<any> {
    return this.http
      .post<any>('/account/get-forgot-password-link-from-code-and-email',
        {email, code})
      .map((response: any) => response.data);
  }

  resetPassword(password: String, token: String): Observable<any> {
    return this.http
      .post<any>('/account/password-reset', {password: password, token: token})
      .map((response: any) => response.data);
  }

  activateAccount(password: String, token: String): Promise<any> {
    return this.http
      .post<any>('/account-activate', {password: password, activation_token: token})
      .toPromise()
      .then((response: any) => response.data);
  }

  login(email: string, password: string, successRedirect: string = ''): Promise<any> {
    return this.http
      .post<any>(this.apiUrlLogin, {
        email: email.toLowerCase().trim(),
        password: password.trim(),
        successRedirect: successRedirect
      })
      .toPromise()
      .then((response: any) => response.data);
  }

  create(user: User): Promise<any> {
    return this.http
      .put<any>(this.apiUrlCrud, JSON.stringify(user))
      .toPromise()
      .then((response: any) => response.data);
  }

  acceptUserPrivacyPolicy(privacy_url: string): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrlAcceptPrivacyPolicy}`, {privacy_url})
      .map((response: any) => response.data);
  }

  setTutorialViewed(tutorial_name: string): Observable<any> {
    /*
    * Stubbed to maybe be a bit more flexible with only a bit more work.
    */
    return this.http
      .post<any>(`${this.apiUrlTutorialViewed}`, {tutorial_name})
      .map((response: any) => response.data);
  }

}

