<mi-page-header title="{{title}}" [breadcrumbs]="breadcrumbs" *ngIf="title">
</mi-page-header>

<div class="card mt-2 ">
    <mi-opacity-loader [ready]="isDataLoaded">
        <div class="sticky-header top-pagination custom-hover-effect">
            <div class="note note-info">
            We have found <b>{{vendorWithoutEOT}}</b> vendors which don't have EOTA out of total <b>{{ formattedVendorData.length
                }}</b>
            vendors.
            </div>
            <ng2-smart-table [settings]="settings" [source]="vendorDataSource">
            </ng2-smart-table>
        </div>
    </mi-opacity-loader>
</div>
