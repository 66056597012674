import {Component, Input} from "@angular/core";
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import * as userReducer from '../../../../libs/user-store/state/reducers';
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {createLogger, LOG_LEVELS} from "../../../logger";
import { FilterBucket } from 'src/shared/ng-models/search/FilterBucket.interface';
import { combineLatest } from 'rxjs';



const log = createLogger(LOG_LEVELS.COMPONENT);


@Component({
  selector: 'mi-advanced-search-filter-assigned-to',
  templateUrl: './advanced-search-filter-assigned-to.component.html',
  styleUrls: ['./advanced-search-filter-assigned-to.component.scss']
})

export class AdvancedSearchFilterAssignedToComponent extends AbstractPageComponent {

  @Input() title: string = 'Assigned To';
  filter_name: string = 'assigned_email_id';
  filters: FilterBucket[] = [];
  selected: string = '';
  isVisible:boolean = true;
  myEmail: string = '';

  constructor(
    private store: Store<ASReducer.State>) {
    super()
  }

  ngOnInit() {
    const asDataStore = this.store.select(ASReducer.getASData).takeUntil(this.destroy$);
    const userStore = this.store.select(userReducer.getUserState).first();
    
    combineLatest(userStore, asDataStore)
    .subscribe(
      data => {
        if (data[0].verified) {
          this.myEmail = data[0].secureUser.getUserEmail();
        }
        if (data[1].results && data[1].results.aggregations && data[1].results.aggregations[this.filter_name]) {
          this.filters = data[1].results.aggregations[this.filter_name].buckets.filter(filter => filter.key !== this.myEmail) as FilterBucket[];

        } else {
          this.filters = [];
        }
        if (data[1].resultsLoaded) {
          //only if results loaded should we swap this value.
          this.selected = '';

          if (data[1].resultsLoaded && data[1].results && data[1].results.meta.params.assigned_email_id) {
            this.selected = data[1].results.meta.params.assigned_email_id[0];
          }
        }
        this.isVisible = data[1].visibleFilters['assigned_email_id'];
      }
    );

  }

  toggle(evt) {
    //log('heard assigned to dropdown', evt);

    let newFilters: string[] = [];
    if (evt.srcElement.value) {
      newFilters= [evt.srcElement.value]
    }

    let newQuery = {};
    newQuery[this.filter_name] = newFilters;

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }
}
