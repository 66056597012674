import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../../../shared/ng-models/SectionDataTable.model";
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import {ADVANCED_SEARCH_TYPES} from '../../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../../shared/state/advanced-search/actions';
import {combineLatest, Observable} from "rxjs";
import {TenantLocationService} from "../../../../../libs/api/tenant/tenantLocation.service";
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';

@Component({
  templateUrl: './vendor-account-id-search-mi-services.component.html'
})

export class VendorAccountIdSearchMiServicesComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  error: string = '';
  display_id: string;
  pageLoaded$: Observable<boolean>;
  searchLoaded$: Observable<boolean>;

  constructor(private accountIdService: TenantAccountIdService,
              private activatedRoute: ActivatedRoute,
              private tenantLocationService: TenantLocationService,
              private store: Store<ASReducer.State>) {
    super();

  }

  ngOnInit() {
    //debugger;
    this.store.dispatch(new ASActions.AsReset({searchType: ADVANCED_SEARCH_TYPES.MI_SERVICE}));

    // listen for page loaded
    this.pageLoaded$ = this.store.select(ASReducer.ASPageLoaded);

    // Listen for search loaded
    this.searchLoaded$ = this.store.select(ASReducer.ASSearchLoaded);

    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.display_id = queryParams.display_id;
        return combineLatest(
          this.accountIdService.getByDisplayId(this.display_id),
          this.activatedRoute.queryParams
        );
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe( ([accountIdData, queryParams]:any[]) => {

        const newParams = {
          ...queryParams,
          "account_id[]": accountIdData._id,
          "active_status[]": 'ACTIVE'
        };

        this.store.dispatch(new ASActions.AsReset(
          {pageLockFilters: ['account_id', 'active_status']
        }));

        this.store.dispatch(new ASActions.AsDeeplinkChanged(newParams));

      });
  }
}
