<mi-server-error [error]="loadError"></mi-server-error>
<div class="filter-group" *ngIf="filters.length && isVisible">
  <h2>{{title}}</h2>

  <ul>
    <li *ngFor="let filter of filters" class="filter-item">

      <label>

        <input type="checkbox"
               [checked]="filter.selected"
               (change)="toggle($event, filter.key)"/>

        <div>
          <ng-container [ngSwitch]="filter_name">
            <ng-container *ngSwitchCase="'business_unit_id'">
              <label
                for="{{'option_'+i}}"
                title="{{filter.key}}">
                {{businessUnitsByIDs[filter.key]? businessUnitsByIDs[filter.key].name: filter.key}}
              </label>
            </ng-container>
            <ng-container *ngSwitchCase="'cost_center_id'">
              <label
                for="{{'option_'+i}}"
                title="{{filter.key}}">
                {{businessUnitsByIDs[filter.key]? businessUnitsByIDs[filter.key].name: filter.key}}
              </label>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span *ngIf="translatePrefix; else textBlock">{{ translatePrefix + filter.key | translate}}</span>
              <ng-template #textBlock>{{filter.label? filter.label : filter.key}}</ng-template>
              <span *ngIf="includeCount">({{filter.doc_count}})</span>
            </ng-container>
          </ng-container>  
        </div>

      </label>

    </li>
    <li *ngIf="showAll"><a class="showAll" (click)="onShowAllClicked()">Show All</a></li>
  </ul>
</div>



