import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { ServiceV2Service } from "src/libs/api2/service-v2/service-v2.service";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as ServiceV2Reducer from '../../../../shared/state/service-v2/reducers';
import * as ServiceV2Actions from '../../../../shared/state/service-v2/actions';
import { ServiceListingColumnsCustom, ServiceListingColumnsInitial, ServiceV2Request, ServiceV2Response, ServiceV2ResponseData } from "src/shared/models/ServiceV2";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import { DocumentV2Service } from "src/libs/api2/document-v2/document-v2.service";
import * as DocumentV2Reducer from '../../../../shared/state/document-v2/reducers';
import * as DocumentV2Actions from '../../../../shared/state/document-v2/actions';
import { DocumentsV2ListRequest, DocumentsV2ListResponse, DocumentsV2ListResponseObject } from "src/shared/models/DocumentV2";
import { Vendor } from "src/shared/models/Vendor";

@Component({
  selector: 'mi-advanced-search-load-more-v2',
  templateUrl: './advanced-search-load-more-v2.component.html',
  styleUrls: ['./advanced-search-load-more-v2.component.scss']
})

export class AdvancedSearchLoadMoreV2Component extends AbstractPageComponent {
  @Input() searchType: string = "SERVICE";
  @Input() vendor: Vendor;
  showAll: boolean = false;
  loadMoreLoading: boolean = false;
  hasLoadMore: boolean = false;

  serviceData: ServiceV2Response;
  serviceRequest: ServiceV2Request = {
    pageSize: 0,
    page_number: 0,
    tenant_id: "",
    search: "",
    sort: {},
    filters: []
  };
  serviceDataStore: ServiceV2ResponseData[] = [];

  documentData: DocumentsV2ListResponse;
  documentRequest: DocumentsV2ListRequest = {
    business_unit_id: ""
  };
  documentDataStore: DocumentsV2ListResponseObject[] = [];

  constructor(
    private serviceV2Service: ServiceV2Service,
    private documentV2Service: DocumentV2Service,
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit(): void {
    switch(this.searchType) {
      case "SERVICE":
        this.getServiceData();
        break;
      case "DOCUMENT":
        this.getDocumentData();
        break;
    }
    
  }

  getServiceData(): void {
    this.store.select(ServiceV2Reducer.getServiceV2Data)
    .subscribe(
      data => {
        console.log("store data", data);
        this.serviceData = data.results;

        /*
        if(this.serviceData.moreAvailable) {
          this.hasLoadMore = true;
        }
        else {
          this.hasLoadMore = false;
        }
        */
        //this.setLoadMoreStatus(data.results.data);
        if(data.hasLoadMore) {
          this.hasLoadMore = true;
        }
        else {
          this.hasLoadMore = false;
        }
      }
    );
  }

  getDocumentData(): void {
    this.store.select(DocumentV2Reducer.getDocumentV2Data)
    .subscribe(
      data => {
        console.log("store data", data);
        this.documentData = data.results;

        if(this.documentData.moreAvailable) {
          this.hasLoadMore = true;
        }
        else {
          this.hasLoadMore = false;
        }
      }
    );
  }

  setLoadMoreStatus(data: any[]): void {
    if(data.length > 0) {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(true));
    }
    else {
      this.store.dispatch(new ServiceV2Actions.SetLoadMoreStatus(false));
    }
  }

  setDefaultServiceRequestParams() {
    this.serviceRequest.pageSize = this.serviceData.pageSize;
    this.serviceRequest.page_number = this.serviceData.pageNumber;
    this.serviceRequest.tenant_id = FrontEndSettingsUtil.getDomain();
    this.serviceRequest.sort = this.serviceData.sort;
    this.serviceRequest.filters = this.serviceData.filters;
    this.serviceRequest.search = this.serviceData.search;
    this.serviceRequest.columns = ServiceListingColumnsInitial;
  }

  setDefaultVendorDocumentRequestParams() {
    this.documentRequest.size = this.documentData.pageSize;
    this.documentRequest.page_number = this.documentData.pageNumber;
    this.documentRequest.business_unit_id = FrontEndSettingsUtil.getDomain();
    this.documentRequest.search = this.documentData.search;
    this.documentRequest.types = this.documentData.types;
    this.documentRequest.tenant_vendor_id = this.vendor.tenant_vendor_id;
  }

  setDefaultDocumentRequestParams() {
    this.documentRequest.size = this.documentData.pageSize;
    this.documentRequest.page_number = this.documentData.pageNumber;
    this.documentRequest.business_unit_id = FrontEndSettingsUtil.getDomain();
    this.documentRequest.search = this.documentData.search;
    this.documentRequest.types = this.documentData.types;
  }

  combineData(data1: ServiceV2ResponseData[], data2: ServiceV2ResponseData[]): any {
    //console.log("combineData data1", data1);
    //console.log("combineData data2", data2);
    let combinedData: ServiceV2ResponseData[] = [];
   
   data1.forEach((data, index) => {
    //console.log("index", index);
    data.miso_service.custom_fields = data2[index].miso_service.custom_fields;
    data.miso_service.tags = data2[index].miso_service.tags;
   });

   return data1;
  }

  formatData(data: ServiceV2ResponseData[]): any {
    //console.log("formatData", data);

    data.map(service => {
      if(service.miso_service.tags.length > 0) {
        let spliceList: number[] = [];
        
        for(let i=0; i<service.miso_service.tags.length; i++) {
          let tagId = service.miso_service.tags[i].tag_id;
          for(let j=0; j<service.miso_service.tags.length; j++) {
            let comparedTagId = service.miso_service.tags[j].tag_id;
            //console.log("formatData tagId", tagId);
            //console.log("formatData comparedTagId", comparedTagId);
            if(j == i) continue;
            if((tagId == comparedTagId) && (spliceList.indexOf(i) == -1)) {
              service.miso_service.tags[i].tag_value += ", " + service.miso_service.tags[j].tag_value;
              spliceList.push(j);
            }
          }
        }

        //console.log("formatData spliceList", spliceList);

        spliceList.map(spliceIndex => {
          service.miso_service.tags.splice(spliceIndex, 1);
        });
      }
    });

    return data;
  }

  updateServices(request: ServiceV2Request) {
    this.store.dispatch(new ServiceV2Actions.SetSearchLoading(this.serviceData));
    this.serviceDataStore = [];
    this.serviceDataStore.push(...this.serviceData.data);
    let saveMrc = this.serviceData.total_mrc;
    let saveTotal = this.serviceData.total;
    this.serviceV2Service.getServices(request)
    .toPromise()
    .then(res => {
      //console.log("updateServices res", res);

      request.columns = ServiceListingColumnsCustom;
      this.serviceV2Service.getServices(request)
      .toPromise()
      .then((response: ServiceV2Response) => {
        let combinedData = this.combineData([...res.data], [...response.data]);
        //console.log("combinedData", combinedData);
        let formattedData = this.formatData([...combinedData]);
        console.log("formattedData", formattedData);
        res.data = formattedData;

        this.setLoadMoreStatus([...res.data]);
        this.serviceDataStore.push(...res.data);
        res.data = this.serviceDataStore;
        res.total_mrc = saveMrc;
        res.total = saveTotal;
        this.store.dispatch(new ServiceV2Actions.SetSearchLoaded(res));
        this.store.dispatch(new ServiceV2Actions.SetServiceResults(res));
        this.loadMoreLoading = false;
      })
      .catch(error => {
        console.log("error while getServices_2", error);
        
      });
    })
    .catch(err => {
      console.log("error while getServices", err);
    });
  }

  updateVendorDocuments(request: DocumentsV2ListRequest) {
    this.documentDataStore = [];
    this.documentDataStore.push(...this.documentData.data);
    this.documentV2Service.getVendorDocuments(request)
    .toPromise()
    .then(res => {
      console.log("res", res);
      this.documentDataStore.push(...res.data);
      res.data = this.documentDataStore;
      this.store.dispatch(new DocumentV2Actions.SetDocumentResults(res));
      this.loadMoreLoading = false;
    })
    .catch(err => {

    });
  }

  updateDocuments(request: DocumentsV2ListRequest) {
    this.documentDataStore = [];
    this.documentDataStore.push(...this.documentData.data);
    this.documentV2Service.getDocuments(request)
    .toPromise()
    .then(res => {
      console.log("res", res);
      this.documentDataStore.push(...res.data);
      res.data = this.documentDataStore;
      this.store.dispatch(new DocumentV2Actions.SetDocumentResults(res));
      this.loadMoreLoading = false;
    })
    .catch(err => {

    });
  }

  loadMore(): void {
    switch(this.searchType) {
      case "SERVICE":
        this.loadMoreLoading = true;
        this.setDefaultServiceRequestParams();
        this.serviceRequest.page_number += 1;
        this.serviceRequest.cursor = this.serviceData.cursor;
        this.updateServices(this.serviceRequest);
        break;
      case "DOCUMENT":
        this.loadMoreLoading = true;
        if(this.vendor == null) {
          this.setDefaultDocumentRequestParams();
          this.documentRequest.page_number += 1;
          this.updateDocuments(this.documentRequest);
        }
        else {
          this.setDefaultVendorDocumentRequestParams();
          this.documentRequest.page_number += 1;
          this.updateVendorDocuments(this.documentRequest);
        }
        break;
    }    
  }
}