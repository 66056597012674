import {createSelector, createFeatureSelector} from '@ngrx/store';
import * as actions from './actions';

export interface Architecture {
  shortCuts: any,
  defaultLink: any
}

export interface State {
  structure: any,
  open: boolean
}

const initialState: State = {
  structure: <Architecture>{shortCuts: {}, defaultLink: []},
  open: true
};

export function reducer(state = initialState, action: actions.Actions): State {
  // log('inside of user reducer', state, action);
  switch (action.type) {
    case actions.ActionTypes.MAIN_NAV_INITIALIZE: {
      return {
        ...state,
        structure: action.payload
      };
    }
    case actions.ActionTypes.MAIN_NAV_OPEN: {
      return {
        ...state,
        open: true
      };
    }
    case actions.ActionTypes.MAIN_NAV_CLOSE: {
      return {
        ...state,
        open: false
      };
    }
    default: {
      return state;
    }
  }
}

export const getMainNav = createFeatureSelector<State>('mainNav');

export const navOpen = createSelector(
  getMainNav,
  (state) => state.open
);
