import {createFeatureSelector, createSelector,} from '@ngrx/store';
import * as asActions from './actions';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {SearchResults} from '../../models/SearchResults';

import {SERVICE_SORTING} from '../../../common/utils/sharedConstants';

const log = createLogger(LOG_LEVELS.REDUX_ADVANCED_SEARCH);

export enum ADVANCED_SEARCH_TYPES {
  MI_SERVICE = 'SERVICE',
  MI_SERVICE_GROUP = 'MI_SERVICE_GROUP',
  MI_PARENT_SERVICE = 'PARENT_SERVICE',
  MIYAGI_ANALYSIS = 'MIYAGI_ANALYSIS',
  EVENT = 'EVENT',
  EQUIPMENT = 'EQUIPMENT',
  VENDOR = 'VENDOR',
  CONTACT = 'CONTACT',
  TENANT_USER = 'TENANT_USER',
  ADDRESS = 'ADDRESS',
  DOCUMENT = 'DOCUMENT',
  PRODUCT = 'PRODUCT',
  ACCOUNT_ID = 'ACCOUNT_ID'
}

export interface State {
  results: SearchResults;
  pageLoaded: boolean;
  pageLockFilters: string[];
  resultsLoaded: boolean;
  loadMoreLoading: boolean;
  hasLoadMore: boolean;
  hasNoResults: boolean;
  isPageOne: boolean;
  sortOptions: string[];
  searchType: ADVANCED_SEARCH_TYPES;
  filterGroup: string;
  selectedFilters: string[];
  visibleFilters: any;
  isCustomField: boolean;
  isTag: boolean;
  filterLabel: string;
  readableFilter: string;
  readableFilterArray: string[];
  columns: IColumn[];
}

export interface IColumn {
  name: string,
  prop?: string,
  hidden?: boolean,
  is_custom_data?: boolean
  is_tag_data?: boolean
}

const initialState: State = {
  results: null,
  pageLoaded: false,
  pageLockFilters: ['bu_access_lock', 'table_view'],
  resultsLoaded: false,
  loadMoreLoading: false,
  hasLoadMore: true,
  hasNoResults: true,
  isPageOne: true,
  sortOptions: SERVICE_SORTING,
  searchType: ADVANCED_SEARCH_TYPES.MI_SERVICE,
  filterGroup: null,
  selectedFilters: [],
  visibleFilters: {
    'assigned_email_id': true, 'product_category': true, 'vendor_name': true,
    'data_integrity_overall_status': true, 'mrc': true, 'country_long': true,
    'state_long': true, 'city_long': true, 'price': true,
    'warranty_status': true, 'lease_status': true,
    'parent_service_status': true, 'advance_search_version2': true,
    'role': true, 'service_associations': true, 'equipment_associations': true,
    'business_unit_id': true, 'cost_center_id': true
  },
  isCustomField: false,
  isTag: false,
  filterLabel: null,
  readableFilter: '',
  readableFilterArray: null,
  columns: []
};

function saveViewableFilters(searchType: string, userFilters: any) {
  const valuetosave = JSON.stringify(userFilters);
  /// save to session with  searchType identifier
  window.localStorage.setItem(searchType, valuetosave);
}

function retrieveViewableFilters(searchType: string) {
  /// retrieve from session with searchType identifier
  const data = window.localStorage.getItem(searchType);
  if (data) {
    const viewableFilters = JSON.parse(data);
    // Use saved session when user has saved new filter structure in session
    if (viewableFilters && viewableFilters.advance_search_version2) {
      return {...initialState.visibleFilters, ...viewableFilters};
    }
  }
  return initialState.visibleFilters;
}

export function reducer(state = initialState, action: asActions.Actions): State {
  switch (action.type) {
    case asActions.ActionTypes.AS_RESET: {

      const visibleFilters = retrieveViewableFilters(action.payload.searchType);

      return {
        ...initialState,
        ...action.payload,
        visibleFilters
      };

    }
    case asActions.ActionTypes.AS_APPLY_VISIBLE_FILTERS: {

      saveViewableFilters(state.searchType, action.payload);

      return {
        ...state,
        visibleFilters: action.payload
      };

    }
    case asActions.ActionTypes.AS_DEEP_LINK_CHANGED: {

      return {
        ...state,
        resultsLoaded: false,
        hasLoadMore: false
      };

    }
    case asActions.ActionTypes.AS_DATA_LOAD_SUCCESS: {

      const newHits = action.payload.hits;
      const total = action.payload.total.value;

      const hasLoadMore = newHits.length <= total - 1;
      const hasNoResults = total === 0 &&
        action.payload.meta.params.from === 0 &&
        action.payload.meta.params.q === '';

      return {
        ...state,
        pageLoaded: true,
        resultsLoaded: true,
        results: {
          ...action.payload
        },
        loadMoreLoading: false,
        hasLoadMore,
        hasNoResults,
        isPageOne: true
      };
    }
    case asActions.ActionTypes.AS_LOAD_MORE: {

      return {
        ...state,
        isPageOne: false,
        loadMoreLoading: true
      };

    }
    case asActions.ActionTypes.AS_LOAD_MORE_SUCCESS: {

      //const newHits = [...state.results.hits, ...action.payload.hits];
      const newHits = [...action.payload.hits];
      const total = action.payload.total.value;
      const hasLoadMore = newHits.length <= total - 1;
      const hasNoResults = false;

      return {
        ...state,
        results: {
          ...action.payload,
          hits: newHits,
        },
        loadMoreLoading: false,
        hasLoadMore,
        hasNoResults,
        isPageOne: false
      };
    }
    case asActions.ActionTypes.AS_FILTER_GROUP_SHOW_ALL: {

      return {
        ...state,
        filterGroup: action.payload.filter,
        selectedFilters: action.payload.selectedFilters,
        isCustomField: action.payload.isCustomField,
        isTag: action.payload.isTag,
        filterLabel: action.payload.filterLabel
      };

    }
    case asActions.ActionTypes.AS_FILTER_CLEAR_FILTER_NAME: {

      return {
        ...state,
        filterGroup: null,
        selectedFilters: []
      };

    }
    case asActions.ActionTypes.AS_SIMPLE_STATE_UPDATE: {

      return {
        ...state,
        ...action.payload
      };

    }
    default: {
      return state;
    }
  }
}

export const getASData = createFeatureSelector<State>('advancedSearch');
export const ASResults = createSelector(getASData, (data) => data.results);
export const ASColumns = createSelector(getASData, (data) => data.columns);
export const ASHits = createSelector(getASData, (data) => data.results ? data.results.hits : []);
export const ASPageLoaded = createSelector(getASData, (data) => data.pageLoaded);
export const ASResultsLoaded = createSelector(getASData, (data) => data.resultsLoaded);
export const ASLoadMoreLoading = createSelector(getASData, (data) => data.loadMoreLoading);
export const ASHasLoadMore = createSelector(getASData, (data) => data.hasLoadMore);
export const ASActiveTab = createSelector(getASData, (data) => data.resultsLoaded ? data.results.meta.params.activeTab : 0);

export const ASSearchLoaded = createSelector(getASData, (data) => data.pageLoaded && !data.loadMoreLoading && data.resultsLoaded);
