import {createFeatureSelector, createSelector,} from '@ngrx/store';
import * as alertListActions from './actions';
import {Alert} from '../../models/Alert.interface';
import * as router from '@ngrx/router-store';

export interface State {
  alerts: Alert[];
  effected: {};
  error: string;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  alerts: [],
  effected: {},
  error: null,
  loading: false,
  loaded: false
};

export function reducer(state = initialState, action: alertListActions.Actions): State {
  switch (action.type) {
    case router.ROUTER_NAVIGATION: {
      return {
        ...initialState
      };
    }
    case alertListActions.ActionTypes.RESET: {
      return {
        ...initialState
      };
    }
    case alertListActions.ActionTypes.LOAD_ALERTS: {
      return {
        ...state,
        effected: {},
        error: null,
        loaded: false,
        loading: true
      };
    }
    case alertListActions.ActionTypes.LOAD_ALERTS_FAIL: {
      return {
        ...state,
        effected: {},
        loaded: true,
        loading: false,
        error: action.payload
      };
    }
    case alertListActions.ActionTypes.LOAD_ALERTS_SUCCESS: {

      const effected = {};
      action.payload.forEach((alert) => {
        alert.effects.forEach((effect) => {
          if (!(effect.display_id in effected)) {
            effected[effect.display_id] = [];
          }
          effected[effect.display_id].push(alert.cause_display_id);
        });
      });

      return {
        ...state,
        alerts: action.payload || [],
        effected: effected,
        loaded: true,
        loading: false,
        error: null
      };
    }
    default: {
      return state;
    }
  }
}

export const getAlertsList = createFeatureSelector<State>('alertList');

export const alerts = createSelector(
  getAlertsList,
  (state) => state.alerts
);
export const loaded = createSelector(
  getAlertsList,
  (state) => state.loaded
);
export const error = createSelector(
  getAlertsList,
  (state) => state.error
);
