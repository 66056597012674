import {Component, OnInit} from '@angular/core';
import {OrderState, OrderProgression, OrderSteps} from '../../../create/state/reducer';
import {Store} from '@ngrx/store';
import * as OrderReducers from '../../../create/state/reducer';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {TenantMiOrderService} from '../../../../../../../libs/api/tenant/tenantMiOrder.service';
import {Router} from '@angular/router';
import {PageAlertAddAction} from "../../../../../../../shared/state/page-alert/actions";

@Component({
  selector: 'mi-add-service-button',
  styles: ['.button-container { margin: 0 0 40px; }'],
  templateUrl: './add-service-button.component.html'
})
export class AddServiceButtonComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  error: string;
  index: number;
  orderState: OrderState;
  step: OrderSteps = OrderSteps.AddService;
  orderProgression: any[] = OrderReducers.OrderProgression;

  constructor(
    private store: Store<any>,
    private orderService: TenantMiOrderService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {

          this.orderState = state as OrderState;
          // Find the index belonging to the state's current step:
          this.index = this.orderProgression.indexOf(this.step);
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });
  }

  onAddOrder(): void {

    const newOrder:any = {
      ...this.orderState,
      service: {
        ...this.orderState.service
      }
    };

    newOrder.service.financial = {
      billing_frequency: this.orderState.service.billing_frequency,
      cost: this.orderState.service.cost,
      quantity: this.orderState.service.quantity,
      non_recurring_cost: this.orderState.service.non_recurring_cost,
      start_date: this.orderState.service.start_date,
      term_length: this.orderState.service.term_length,
      end_date: this.orderState.service.end_date
    };

    newOrder.service.product = newOrder.service.product._id;

    if(this.orderState.service.service_term) {
      newOrder.service.service_term = this.orderState.service.service_term;
    }

    newOrder.vendor = newOrder.vendor._id;

    newOrder.service.locations = newOrder.service.locations.map(location => location.id);

    this.orderService.createFromOrder(newOrder)
      .subscribe(
        (value) => {
          this.store.dispatch(
            new PageAlertAddAction({
              message: `Service ${value.miservice.display_id} has been created`,
              alertType: 'SUCCESS'
            })
          );
          this.router.navigateByUrl(`/services/orders/summary/${this.orderState.display_id}`);
        },
        (e) => this.error = e
      )
  }

}
