<mi-server-error [error]="error"></mi-server-error>
<div [ngClass]="{'compact': compact}" *ngIf="location; else loading">
  <div class="location-details" *ngIf="!compact">
    <svg width="14" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 7.219a2 2 0 1 1-3.999.001A2 2 0 0 1 9 7.22M7 17S2 10 2 7c0-2.757 2.243-5 5-5s5 2.243 5 5c0 3-5 10-5 10M7 0a7 7 0 0 0-7 7c0 3.866 7 13 7 13s7-9.134 7-13a7 7 0 0 0-7-7"
        fill="#0090C9" fill-rule="evenodd" />
    </svg>
    <div>
      <a routerLink="/locations/{{ location.id }}">{{ location.formatted_address }}</a>
      <div>Location: {{location.label}} {{ location.nick_name ? '(' + location.nick_name + ')' : '' }}</div>
    </div>
  </div>
  <a *ngIf="compact && loaded" routerLink="/locations/{{ location.id }}">{{ locationLabel }}{{
    location.address.formatted_address }}</a>
  <mi-static-map *ngIf="showMap && !compact" [address]="location.address" [height]="MAP_H" [width]="MAP_W">
  </mi-static-map>
</div>
<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>