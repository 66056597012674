<div class="middle-well">
    <h1>Your MISO Account</h1>
  <ng-container *ngIf="isAccountHolder">
    <p>Just a reminder, your MISO Account is ready to go. Activating only takes a minute. Simply click the below link, select your plan and start saving. Please email support@miso3.com if you have any questions.</p>
    <a mi-button href="{{accountLink}}">Manage Account</a>

  </ng-container>

  <ng-container *ngIf="!isAccountHolder">
    <p>You are almost there, though your subscription still needs to be activated. Please contact your organization’s Account Holder to activate your MISO account. If you are unsure who this is, please email support@miso3.com.</p>
    <a mi-button href="{{accountLink}}">Your Accounts</a>
  </ng-container>

</div>
