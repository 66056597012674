<div class="email-composer">

  <form *ngIf="loaded; else loading"
        [formGroup]="emailForm"
        (submit)="onSendEmail(emailForm.value)">

    <mi-email-recipient-select
      [defaultRecipients]="defaultRecipients"
      [vendorName]="vendorName"
      (updateRecipients)="onRecipientsUpdated($event)">
    </mi-email-recipient-select>

    <div class="subject-line">
      <label for="subject">
        Subject:
      </label>
      <input id="subject"
             formControlName="subject"/>
    </div>

    <div *ngIf="attachments && attachments.length" class="subject-line">
      <label>
        {{'ATTACHMENTS' | translate | translateSelector:attachments.length}}:
      </label>
      <ng-container *ngFor="let attachment of attachments">
        <a
          href="{{attachment.full_path}}"
          download="download"
          class="report-download">
          {{attachment.file_name}}
        </a>
      </ng-container>
    </div>

    <angular-tinymce formControlName="body"></angular-tinymce>

    <div class="email-notification"
         *ngIf="!emailForm.valid">
      Emails require at least one TO: recipient, a subject, and body.
    </div>
    <mi-server-error [error]="error"></mi-server-error>

    <div class="button-container">
      <a [routerLink]="cancelUrl"
         mi-button
         class="button light-theme"
         *ngIf="cancelUrl">
        Cancel
      </a>
      <a (click)="onCancel()"
         mi-button
         class="button light-theme"
         *ngIf="!cancelUrl">
        Cancel
      </a>
      <button type="submit"
              mi-button
              buttonTheme="dark-theme"
              [disabled]="!emailForm.valid">
        Send Email
      </button>
    </div>

  </form>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>
</div>
