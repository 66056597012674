import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { UserStoreService } from "src/libs/user-store/user-store.service";
import { createLogger, LOG_LEVELS } from "src/shared/logger";
import * as userReducer from '../../user-store/state/reducers';
import * as userActions from '../../user-store/state/actions';
import { catchError, map } from "rxjs/operators";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import linkFormatter from "src/common/utils/linkFormatter";
import { AUTH } from "src/common/security/secureUser";

const log = createLogger(LOG_LEVELS.AUTH_GUARD);

@Injectable()
export class TenantSuperAdminAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userStoreService: UserStoreService,
    private store: Store<userReducer.State>
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.userStoreService.getLoggedInService()
      .pipe(
        map((res) => {
          //console.log("TenantSuperAdminAuthGuard res", res);
          const domain = FrontEndSettingsUtil.get() && FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
          ? FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
          : linkFormatter.getSubdomainFromHost(window.location.host);
          // lets keep the user store updated:
          this.store.dispatch(new userActions.UserVerifySuccessAction(res));

          let mayAccess = res.secureUser && res.secureUser.hasTenantAccess(domain, AUTH.ACCOUNT_HOLDER);
          //console.log("TenantSuperAdminAuthGuard mayAccess", mayAccess);

          //debugger;
          if (!mayAccess) {
            // if this person is not able to navigation we must send him to 404
            log('this user has no access to this tenant');
            console.log('this user has no access to this tenant', res.secureUser);
            this.router.navigate(['/403']);
          } else if (mayAccess && !res.secureUser.isSuperUser()) {
            //this person at least has access to this domain...
            //but does this domain have a plan?
            if (!res.secureUser.hasTermsAndConditionsAccepted(domain)) {
              this.router.navigate(['/accept-terms-and-conditions']);
            }
          }

          return mayAccess;
        }),
        catchError((error: any) => {
          log('this person was not logged in');
          window.location.href = linkFormatter.getLoginLink(window.location.href);
          return of(false);
        })
      );
  }
}