import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";
import { Contact, ServiceAssociationType } from '../../../shared/models/Contact';
import { SortableTemplatesEnum } from '../../../shared/ng-models/SortableTableColumn.interface';
import { AccountId } from '../../../shared/models/AccountId';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-service-contacts-static',
  templateUrl: './service-contacts-static.component.html',
  styleUrls: ['./service-contacts-static.component.scss']
})

export class ServiceContactsStaticComponent extends AbstractPageComponent implements OnInit {

  @Input() account_id: AccountId;
  @Input() custom_data: any[];
  
  contactRows: any[] = [];
  contactColumns: any[];

  @ViewChild('emailTmpl', {static: true}) emailTmpl: TemplateRef<any>;
  @ViewChild('contactTmpl', {static: true}) contactTmpl: TemplateRef<any>;
  @ViewChild('serviceAssociationTmpl', {static: true}) serviceAssociationTmpl: TemplateRef<any>;

  readonly NO_DATA: string = NO_DATA;

  accountrep: VendorContact;


  constructor(private contactV2Service: ContactV2Service) {
    super();
  }

  ngOnInit() {
    this.loadAccountIdRep();
  }

  getServiceAssociationRow(contact: VendorContact, service_association_type: ServiceAssociationType,
    service_association: string, service_association_display_id?: string){
      if(contact){
        return {
          name: contact.first_name + ' ' + contact.last_name,
          company: contact.vendor.vendor_name,
          role: contact.role,
          email: contact.email,
          display_id: contact.display_id,
          service_association,
          service_association_type,
          service_association_display_id,
          id: contact.id
        }
      }
      else{
        return {
          name: NO_DATA,
          company: NO_DATA,
          role: NO_DATA,
          email: '',
          display_id: '',
          service_association,
          service_association_type,
          service_association_display_id
        }
      }
  }

  loadAccountIdRep(){
    if(this.account_id && this.account_id.contact){
      this.contactV2Service.getContactById(this.account_id.contact_id)
      .toPromise()
      .then(contact => {
        this.accountrep=contact;
        this.buildContactsTable();
      });

    }
    else{
      this.buildContactsTable();
    }
  }

  buildContactsTable() {
    this.contactRows = [];
    if(this.account_id){
      this.contactRows.push(
        this.getServiceAssociationRow(this.accountrep,
        ServiceAssociationType.ACCOUNT_REP,
        this.account_id.label,
        this.account_id.display_id));
    }
    

    if(this.custom_data){
      this.custom_data.map(custom_data => {
        if(custom_data.custom_field.type == "CONTACT"){
          if(custom_data.value && Array.isArray(custom_data.value)){
            custom_data.value.map(contact => {
              this.contactRows.push(
                this.getServiceAssociationRow(contact,
                ServiceAssociationType.ADDITIONAL_CONTACT,
                custom_data.custom_field.label));
            });
          }
        }
      });
    }


    this.contactColumns = [
      {
        name: 'Name',
        prop: 'name',
        sortable: this.contactRows.length > 1,
        cellTemplate: this.contactTmpl,
      },
      {
        name: 'Company',
        prop: 'company',
        sortable: this.contactRows.length > 1
      },
      {
        name: 'Roles',
        prop: 'role',
        sortable: this.contactRows.length > 1
      },
      {
        name: 'Email',
        prop: 'email',
        cellTemplate: this.emailTmpl,
        sortable: this.contactRows.length > 1
      },
      {
        name: 'Service Association',
        prop: 'service_association',
        cellTemplate: this.serviceAssociationTmpl,
        sortable: this.contactRows.length > 1
      }
    ];
  }

}

