import {Component, OnInit} from '@angular/core';
import {TenantMiOrderService} from '../../../../../libs/api/tenant/tenantMiOrder.service';
import {ActivatedRoute} from '@angular/router';
import {Vendor} from '../../../../../shared/models/Vendor';
import {DetailHeaderNavData} from '../../../../../shared/ng-models/DetailHeaderNavData.interface';
import {Product} from '../../../../../shared/models/Product';
import {Link} from '../../../../../shared/ng-models/Link.interface';
import {MiOrder} from '../../../../../shared/models/MiOrder';
import {Store} from '@ngrx/store';
import * as OrderReducer from '../create/state/reducer';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {ResetOrderAction, UpdateOrderAction} from '../create/state/actions';
import {scrollTo} from '../../../../../shared/shared.functions';
import {OrderState} from '../create/state/reducer';

@Component({
  templateUrl: './service-order-add-service.component.html'
})
export class ServiceOrderAddServiceComponent extends AbstractPageComponent implements OnInit {

  error: any;
  loaded: boolean = false;
  order: OrderState;
  product: Product;
  orderSteps:any = OrderReducer.OrderSteps;
  vendor: Vendor;
  detailHeaderNavData: DetailHeaderNavData;
  breadcrumbs: Link[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: TenantMiOrderService,
    private store: Store<OrderReducer.OrderState>
  ) {
    super();
  }

  ngOnInit() {

    // always reset when this page loads.
    this.store.dispatch(new ResetOrderAction({}));

    const display_id: string = this.activatedRoute.snapshot.params.display_id;

    this.breadcrumbs = [
      {
        url: '',
        label: 'Dashboard',
        internal: true
      },
      {
        url: '/services',
        label: 'Services',
        internal: true
      },
      {
        url: `/services/orders/summary/${display_id}`,
        label: `Order ${display_id} Summary`,
        internal: true
      },
      {
        url: `/services/orders/${display_id}/add-service`,
        label: 'Add Service',
        internal: true
      }
    ];


    this.store.select(OrderReducer.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe((order: any) => {
        this.order = order as OrderState;
        scrollTo(`section_${this.order.index}`);
      });

    this.orderService.getByDisplayId(display_id).takeUntil(this.destroy$)
      .toPromise()
      .then((order: MiOrder) => {
        const index = OrderReducer.OrderProgression.indexOf(this.orderSteps.Product);
        this.store.dispatch(new UpdateOrderAction({
          ...order,
          service: {
            requestor: order.requestor,
            custom_data: order.custom_data,
            locations: [],
            associated_documents: []
          },
          index: index,
          maxIndex: index
        }));
        this.loaded = true;
      }).catch((e) => {
      this.error = e;
      this.loaded = true;
    });

  }
}
