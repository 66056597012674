import {
  Component, EventEmitter,
  Input,
  Output
} from "@angular/core";
import {Vendor} from "../../../shared/models/Vendor";

@Component({
  selector: 'mi-product-vendor',
  styleUrls:['./vendor-header.component.scss'],
  template: `<ng-container>
    <div class="order-vendor" [ngClass]="{'locked': locked}" (click)="unSelectVendor()">
      <div class="vendor">
        <input type="checkbox"
               value="{{vendor._id}}"
               checked="true"
        />
        <mi-vendor-logo [url]="vendor.logo"></mi-vendor-logo>
        <span class="vendor-name">{{vendor.vendor_name}}</span>
      </div>
      <span *ngIf="vendor.display_id">{{vendor.display_id}}</span>
    </div>
  </ng-container>`
})

export class VendorHeaderComponent {
  @Input() vendor: Vendor;
  @Input() locked: boolean = false;
  @Output() onUnSelectVendor: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(){

  }

  unSelectVendor(){
    this.onUnSelectVendor.emit(true);
  }

}
