import {ProductCategory} from "./ProductCategory";
import {TermsAndConditions} from "./TermsAndConditions";
import {Vendor} from "./Vendor";
import { ProductCategoryFieldProperty } from "./ProductCategoryFieldProperty";

export class Product {
  _id?:string;
  created_at?: string;
  updated_at?: string;
  category_id?: string;
  vendor_id?: string;
  owner?: string;
  updated_user_name?: string;
  actual_product?: Product;
  actual_product_id?: string;
  product_category?: ProductCategory;
  miyagi_terms?: TermsAndConditions;
  product_vendor?: Vendor;
  product_fields?: ProductCategoryFieldProperty[];
  status?: string;
  service_count?:string;
  mrc?:string;
  id?: string;
  tenant_vendor_id?: string;
  product_category_id?: string;
  product_name?: string;
  display_id?: string;
  product_properties?: ProductCategoryFieldProperty[];
  dependents?: any;
  tenant_vendor_display_id?: any;
  tenant_vendor_name?: any;

}
