<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide no-padding-top">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <div class="text-container">
      <label>Do you want to change the Invoice Email to <b>{{newInvoiceEmail}}</b>?</label>
    </div>
  </modal-content>
  <modal-footer>
    <div class="cta">
      <button (click)="changePaymentMethod()"
              mi-button buttonTheme="alert-theme">
        Yes
      </button>
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        No
      </button>
    </div>
  </modal-footer>
</modal>