import {Component, OnInit} from "@angular/core";
import {Vendor} from "../../../shared/models/Vendor";
import {ModalRef} from "@independer/ng-modal";
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';

@Component({
  templateUrl: './users-management-form-modal.component.html',
  styleUrls: ['./users-management-form-modal.component.scss']
})

export class UsersManagementFormModalComponent implements OnInit {
  title: string = 'User Management';
  userId: string = '';
  canEditRoleOnBu: boolean = false;

  constructor(
    private modal: ModalRef) {
  }

  ngOnInit(): void {
    // if(this.userId){
    //   this.close();
    // }
    console.log("UsersManagementFormModalComponent userId", this.userId)
  }

  close() {
    this.modal.close(null);
  }

  onUserUpdated(user: TenantUser): void {
    this.modal.close(user);
  }
}
