import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import {MiService, MiServiceDetails} from "../../../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../../../libs/api/tenant/tenantMiService.service";
import {NO_DATA} from "../../../../../../shared/ng-models/SectionDataTable.model";
import * as SDReducer from "../../state/reducers";
import {AbstractPageComponent} from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import { Document } from 'src/libs/api2/types/Document.interface';
import {CUSTOMER_DOCUMENT_UPLOAD_TYPES} from '../../../../../../common/utils/sharedConstants';
import { SortableTemplatesEnum } from 'src/shared/ng-models/SortableTableColumn.interface';
import { environment } from 'src/environments/environment';
import { DocumentsV2ListRequest, DocumentsV2ListResponse, DocumentsV2ListResponseObject } from "src/shared/models/DocumentV2";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import * as DocumentV2Reducer from '../../../../../../shared/state/document-v2/reducers';
import * as DocumentV2Actions from '../../../../../../shared/state/document-v2/actions';
import { DocumentV2Service } from "src/libs/api2/document-v2/document-v2.service";
import { PaginationPayload } from 'src/shared/models/MiPagination';

@Component(
  {
    selector: 'mi-subsection-associated-documents',
    templateUrl: './subsection-associated-documents-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionAssociatedDocumentsDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiService> = new EventEmitter<MiService>();
  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  // miService: MiService;
  serviceDetails: MiServiceDetails;
  //pageLoaded$: Observable<boolean>;
  // Custom vars:
  associatedDocumentRows: any[] = [];
  associatedDocumentColumns: any[] = [];
  updating: boolean = false;

  tenantId: string = "";
  serviceId: string = "";
  documentListRequest: DocumentsV2ListRequest = {
    business_unit_id: ""
  };
  documentListResponse: DocumentsV2ListResponse;
  searchLoaded: boolean = false;
  pageNumber: number;
  pageSizeData: number[] = [10, 20, 30, 40, 50];
  selectedPageSize: number = this.pageSizeData[0];
  totalRow: number
  totalPages: number = 1

  readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;

  @ViewChild('fileType', {static: true}) fileType: TemplateRef<any>;
  @ViewChild('fileSize', {static: true}) fileSize: TemplateRef<any>;
  @ViewChild('fileLink', {static: true}) fileLink: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) dateTmpl: TemplateRef<any>;
  @ViewChild('documentDetailTpl', {static: true}) documentDetailTpl: TemplateRef<any>;
  

  constructor(private tenantMiService: TenantMiServiceService,
              private documentV2Service: DocumentV2Service,
              private tenantAssociatedDocumentsService: TenantDocumentManagementService,
              private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.searchLoaded = false;
    //this.pageLoaded$ = this.store.select(SDReducer.DSPageLoaded);
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
            console.log("serviceDetails", this.serviceDetails);
            this.serviceId = this.serviceDetails.service_data.service_id;
            this.tenantId = FrontEndSettingsUtil.getDomain();
            this.getServiceDocuments();
          }
        });
  }

  onDocumentSelected(data: any[]) {
    //debugger;
    this.updating = true;
    let newData: any = {
      associated_documents: data.map(doc => {
        return doc;
      })
    };

    this.tenantMiService
      .updateMiService(this.serviceDetails.service_data.service_id, newData)
      .toPromise()
      .then(res => {
        this.getServiceDocuments();
        this.onToggleExpand();
      })
      .catch(e => this.error = e)
      .finally(() => {
        this.updating = false;
      });
  }

  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

  startListen() {
    this.store.select(DocumentV2Reducer.getLoaded)
      .subscribe(
        data => {
          console.log("data loaded", data);
          if(data == true) {
            this.searchLoaded = true;
          }
          else {
            this.searchLoaded = false;
          }
        }
      );
  }

  setDefaultRequestParams(): void {
    this.documentListRequest.business_unit_id = this.tenantId;
    this.documentListRequest.page_number =  this.pageNumber;
    this.documentListRequest.size = this.selectedPageSize ;
    this.documentListRequest.service_id = this.serviceId;
  }

  getServiceDocuments(): void {
    this.setDefaultRequestParams();
    //this.store.dispatch(new DocumentV2Actions.SetSearchLoading(this.documentListResponse));
    this.documentV2Service.getDocuments(this.documentListRequest)
    .toPromise()
    .then((results: DocumentsV2ListResponse) => {
      this.documentListResponse = results;
      this.totalRow = results.total
      this.totalPages = this.totalRow > 0 ? Math.ceil(this.totalRow / this.selectedPageSize) : 1
      
      this.updateServiceDoc(this.documentListResponse.data);
      //this.associatedDocumentRows = results.data;
      console.log("documentListResponse", this.documentListResponse);
      //this.store.dispatch(new DocumentV2Actions.SetDocumentResults(this.documentListResponse));
      //this.store.dispatch(new DocumentV2Actions.SetSearchLoaded(this.documentListResponse));
      this.searchLoaded = true;
    })
    .catch(error => {
      console.log("error while getDocuments", error);
      this.error = error;
    });
    /*
    this.tenantAssociatedDocumentsService.getDocuments(this.serviceDetails.general.tenant_id, '')
      .toPromise()
      .then((documents) => {
        // let ads = documents.filter(ad => (this.miService.associated_documents||[]).find(d => d===ad.id));

        // this.updateServiceDoc(ads);
      })
      .catch(e => this.error = e);
    */
  }

  //updateServiceDoc(associated_documents: Document[]) {
    updateServiceDoc(associated_documents: DocumentsV2ListResponseObject[]) {
    this.associatedDocumentRows = associated_documents.map(doc => {
      return {
        id: doc.id,
        display_id: doc.display_id,
        is_url: doc.is_url,
        name: doc.name,
        file_name: doc.file_name,
        file_type: doc.type,
        file_size: doc.file_size || this.NO_DATA,
        updated_at: doc.created_on_utc,
        extraction_date: doc.extraction_date,
        file_url: doc.file_name,
        business_unit_name: doc.business_unit_name,
        tenant_id: doc.tenant_id,
        s3_key: doc.s3_key,
        is_v1: doc.is_v1
      }
    });
    this.associatedDocumentColumns = [
      {
        name: 'MISO ID',
        prop: 'display_id',
        cellTemplate: SortableTemplatesEnum.idLink2,
        sortable: this.associatedDocumentRows.length > 1
      },
      {
        name: 'Document Name',
        prop: 'name',
        sortable: this.associatedDocumentRows.length > 1
      },
      {
        name: 'File Name',
        prop: 'file_name',
        cellTemplate: this.fileLink,
        sortable: this.associatedDocumentRows.length > 1
      },
      {
        name: 'File Type',
        prop: 'file_type',
        cellTemplate: this.fileType,
        sortable: this.associatedDocumentRows.length > 1
      },
      {
        name: 'File Size',
        prop: 'file_size',
        sortable: this.associatedDocumentRows.length > 1,
        cellTemplate: this.fileSize
      },
      {
        name: 'Date Modified',
        prop: 'updated_at',
        cellTemplate: SortableTemplatesEnum.shortISODateTime,
        sortable: this.associatedDocumentRows.length > 1
      },
      {
        name: 'Extraction Date',
        prop: 'extraction_date',
        // cellTemplate: SortableTemplatesEnum.shortISODateTime,
        sortable: true
      },
      // {
      //   name: 'Division/Team',
      //   prop: 'business_unit_name',
      //   sortable: this.associatedDocumentRows.length > 1
      // }
    ];
  }

  shortenFileName(name) {
    if (name && name.length > 40) {
      return name.slice(0, 40) + '...';
    }
    return name;
  }

  downloadDocument(id) {
    return this.tenantAssociatedDocumentsService.download(id).subscribe((response) => {
      window.open(response.download_url, "_blank");;
    }, (e) => {

    });
  }

  getV1FileUrl(row){
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }

  paginationChangeHandler(paginationPayload: PaginationPayload) {
    this.pageNumber = paginationPayload.selectedPageNumber - 1
    this.selectedPageSize = paginationPayload.selectedPageSize
    this.getServiceDocuments()
  }

}
