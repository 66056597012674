import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {ModalRef} from '@independer/ng-modal';
import {Vendor} from '../../../shared/models/Vendor';
import {Document} from '../../../libs/api2/types/Document.interface';

@Component({
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss']
})

export class DocumentUploadModalComponent extends AbstractPageComponent implements OnInit {
  @Input() editId: string;
  @Input() vendor: Vendor;
  @Input() document: Document = null;
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() linkcreated: EventEmitter<Document> = new EventEmitter<Document>();
  
  loaded: boolean = false;
  
  constructor(
    private modal: ModalRef
  ) {
    super();
  }

  ngOnInit() {
    
  }

  onAssociatedDocumentCancelled($event) {
    $event.preventDefault();
    this.canceled.emit();
    this.modal.close({message: null, error: null});
  }

  onAssociatedDocumentCreated($event) {
    this.linkcreated.emit($event);
    this.modal.close({message: null, error: null, doc: $event});
  }

}
