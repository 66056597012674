import {Action} from '@ngrx/store';

export const ActionTypes = {
  LOAD_AS_NEW: '[Miso: Order Create Load As New]',
  UP_DATE_MARKETING_MESSAGE: '[Miso: order, update marketing message.]',

  SAVE_DRAFT:  '[Miso: Order Create Save Draft]',
  SAVE_DRAFT_SUCCESS:  '[Miso: Order Create SAVE_DRAFT_SUCCESS]',
  SAVE_DRAFT_ERROR:  '[Miso: Order Create SAVE_DRAFT_ERROR]',

  LOAD_DRAFT:  '[Miso: Order LOAD_DRAFT]',
  LOAD_DRAFT_SUCCESS:  '[Miso: LOAD_DRAFT_SUCCESS]',
  LOAD_DRAFT_ERROR:  '[Miso: LOAD_DRAFT_ERROR]',

  SELECT_PURCHASHING_VENDOR: '[Miso: Select Purchasing Vendor]',
  CONFIRM_PURCHASING_VENDOR: '[Miso: Confirm Purchasing Vendor]',
  RESET_PURCHASING_VENDOR: '[Miso: Order Reset Purchasing Vendor]',
  CREATE_PURCHASING_PRODUCT: '[Miso: Create Purchasing Product]',
  CREATE_PURCHASING_VENDOR: '[Miso: Create Purchasing Vendor]',
  SET_PURCHASING_VENDOR: '[Miso: Set Purchasing Vendor]',
  SET_MANUFACTURING_VENDOR: '[Miso: Set Manufacturing Vendor]',
  UPDATE_VENDOR_STEP: '[Miso: Update Vendor Step]',
  VIEW_PRODUCT_SUMMARY: '[Miso: View Step]',

  EDIT_SERVICE_DETAILS: '[Miso: Edit Service Details]',
  CONFIRM_SERVICE_DETAILS: '[Miso: Confirm Service Details]',
  SAVE_SERVICE_DETAILS: '[Miso: Save Service Details]',

  //SEARCH_CONTACTS_SERVICE_DETAILS: '[Miso: Search Contacts Service Details]',
  CONTACT_SELECTED_SERVICE_DETAILS: '[Miso: Contact Selected Service Details]',
  //DELETE_CONTACT_SERVICE_DETAILS: '[Miso: Contact Deleted From Serivce Details]',
  //CONTACTS_COMPLETE_SERVICE_DETAILS: '[Miso: Contact Selection Complete In Serivce Details]',

  SERVICE_DETAILS_START: '[Miso: Service Details :: Start]',
  SERVICE_DETAILS_STEP_ONE: '[Miso: Service Details :: Step One]',
  SERVICE_DETAILS_STEP_FINANCIALS: '[Miso: Service Details :: Step Two]',
  SERVICE_DETAILS_STEP_USAGE: '[Miso: Service Details :: Step Usage]',
  SERVICE_DETAILS_STEP_ADDRESS: '[Miso: Service Details :: Step Address]',
  SERVICE_DETAILS_STEP_CUSTOM: '[Miso: Service Details :: Step Custom]',
  SERVICE_DETAILS_STEP_SET: '[Miso: Service Details :: Step Set]',

  SERVICE_TERM_STEP_START: '[Miso: Service Term :: Start]',
  SERVICE_TERM_STEP_FORM_SUMMARY: '[Miso: Service Term :: Form Summary]',
  SERVICE_TERM_STEP_SET: '[Miso: Service Term :: Step Set]',

  SERVICE_CREATE: '[Miso: Service Create ]',
  SERVICE_CREATE_ERROR: '[Miso: Service Create:: SERVICE_CREATE_ERROR]',
  SERVICE_CREATE_SUCCESS: '[Miso: Service Create:: SERVICE_CREATE_SUCCESS]'

  /*TEST_GET_HELLO: '[Miso: Contained Store Test] TEST_GET_HELLO',
  TEST_GET_HELLO_SUCCESS: '[Miso: Contained Store Test] TEST_GET_HELLO_SUCCESS',
  TEST_GET_HELLO_FAILURE: '[Miso: Contained Store Test] TEST_GET_HELLO_FAILURE'*/
};

export class LoadAsNewAction implements Action {
  type = ActionTypes.LOAD_AS_NEW;
  constructor(public payload) {}
}

export class SaveDraftAction implements Action {
  type = ActionTypes.SAVE_DRAFT;
  constructor(public payload) {}
}

export class SaveDraftSuccessAction implements Action {
  type = ActionTypes.SAVE_DRAFT_SUCCESS;
  constructor(public payload) {}
}

export class SaveDraftErrorAction implements Action {
  type = ActionTypes.SAVE_DRAFT_ERROR;
  constructor(public payload) {}
}

export class LoadDraftAction implements Action {
  type = ActionTypes.LOAD_DRAFT;
  constructor(public payload) {}
}

export class LoadDraftSuccessAction implements Action {
  type = ActionTypes.LOAD_DRAFT_SUCCESS;
  constructor(public payload) {}
}

export class LoadDraftErrorAction implements Action {
  type = ActionTypes.LOAD_DRAFT_ERROR;
  constructor(public payload) {}
}

export class SelectPurchasingVendor implements Action {
  type = ActionTypes.SELECT_PURCHASHING_VENDOR;

  constructor(public payload) {
  }
}

export class ConfirmPurchasingVendor implements Action {
  type = ActionTypes.CONFIRM_PURCHASING_VENDOR;

  constructor(public payload) {
  }
}

export class ResetVendorAndService implements Action {
  type = ActionTypes.RESET_PURCHASING_VENDOR;

  constructor(public payload) {
  }
}

export class CreatePurchasingProduct implements Action {
  type = ActionTypes.CREATE_PURCHASING_PRODUCT;
  constructor(public payload) {
  }
}

export class UpdateVendorStep implements Action {
  type = ActionTypes.UPDATE_VENDOR_STEP;

  constructor(public payload) {
  }
}

export class UpdateVendorAndService implements Action {
  type = ActionTypes.SET_PURCHASING_VENDOR;

  constructor(public payload) {
  }
}

export class SetManufacturingVendor implements Action {
  type = ActionTypes.SET_MANUFACTURING_VENDOR;

  constructor(public payload) {
  }
}

export class ViewProductSummary implements Action {
  type = ActionTypes.VIEW_PRODUCT_SUMMARY;

  constructor(public payload) {
  }
}

export class EditServiceDetails implements Action{
  type = ActionTypes.EDIT_SERVICE_DETAILS;
  constructor(public payload) {
  }
}

export class ConfirmServiceDetails implements Action{
  type = ActionTypes.CONFIRM_SERVICE_DETAILS;
  constructor(public payload) {
  }
}

export class SaveServiceDetails implements Action{
  type = ActionTypes.SAVE_SERVICE_DETAILS;
  constructor(public payload) {
  }
}

export class ContactSelectedServiceDetails implements Action{
  type = ActionTypes.CONTACT_SELECTED_SERVICE_DETAILS;
  constructor(public payload){

  }
}

export class ServiceDetailsStart implements Action{
  type = ActionTypes.SERVICE_DETAILS_START;
  constructor(public payload){

  }
}

export class ServiceDetailsStepOneComplete implements Action{
  type = ActionTypes.SERVICE_DETAILS_STEP_ONE;
  constructor(public payload){

  }
}

export class ServiceDetailsStepTwoComplete implements Action{
  type = ActionTypes.SERVICE_DETAILS_STEP_FINANCIALS;
  constructor(public payload){

  }
}

export class ServiceDetailsStepThreeComplete implements Action{
  type = ActionTypes.SERVICE_DETAILS_STEP_USAGE;
  constructor(public payload){

  }
}

export class ServiceDetailsStepFourComplete implements Action{
  type = ActionTypes.SERVICE_DETAILS_STEP_ADDRESS;
  constructor(public payload){

  }
}

export class ServiceDetailsStepFiveComplete implements Action{
  type = ActionTypes.SERVICE_DETAILS_STEP_CUSTOM;
  constructor(public payload){

  }
}

export class ServiceDetailsStepSet implements Action{
  type = ActionTypes.SERVICE_DETAILS_STEP_SET;
  constructor(public payload){

  }
}

export class ServiceTermStepSet implements Action{
  type = ActionTypes.SERVICE_TERM_STEP_SET;
  constructor(public payload){

  }
}

export class ServiceTermStepFormComplete implements Action{
  type = ActionTypes.SERVICE_TERM_STEP_FORM_SUMMARY;
  constructor(public payload){

  }
}

export class ServiceCreate implements Action{
  type = ActionTypes.SERVICE_CREATE;
  constructor(public payload){
  }
}

export class ServiceCreateError implements Action{
  type = ActionTypes.SERVICE_CREATE_ERROR;
  constructor(public payload){
  }
}

export class ServiceCreateSuccess implements Action{
  type = ActionTypes.SERVICE_CREATE_SUCCESS;
  constructor(public payload){
  }
}
export class UpdateMarketingMessage implements Action{
  type = ActionTypes.UP_DATE_MARKETING_MESSAGE;
  constructor(public payload){
  }
}



export type Actions
  = LoadAsNewAction |
  SelectPurchasingVendor |
  ConfirmPurchasingVendor |
  CreatePurchasingProduct |
  SetManufacturingVendor |
  ResetVendorAndService |
  UpdateVendorAndService |
  ViewProductSummary |
  SaveDraftAction |
  SaveDraftSuccessAction |
  SaveDraftErrorAction |
  LoadDraftAction |
  LoadDraftErrorAction |
  LoadDraftSuccessAction |
  EditServiceDetails |
  ConfirmServiceDetails |
  SaveServiceDetails |
  //SearchContactsServiceDetails |
  ContactSelectedServiceDetails |
  //DeleteContactServiceDetails |
  //ContactSelectionComplete |
  ServiceDetailsStart |
  ServiceDetailsStepOneComplete |
  ServiceDetailsStepTwoComplete |
  ServiceDetailsStepThreeComplete |
  ServiceDetailsStepFourComplete |
  ServiceDetailsStepFiveComplete |
  ServiceDetailsStepSet |
  ServiceTermStepSet |
  ServiceTermStepFormComplete |
  ServiceCreate |
  ServiceCreateError |
  ServiceCreateSuccess |
  UpdateMarketingMessage;
