<div class="form-group"
     [ngClass]="{'has-error':errorMessage}">
  <label class="control-label"
         [ngClass]="{'required': labelRequired, 'offscreen': labelOffscreen}">
    {{labelText}}
    <mi-info-tooltip class="tooltip" *ngIf="hasToolTip" [toolTipText]="toolTipText" [toolTipSize]="toolTipSize" [toolTipId]="toolTipId">
    </mi-info-tooltip>
  </label>
  
  <div class="hint" *ngIf="hint">
    {{hint}}
  </div>

  <div class="alert-and-input-wrap">

    <div [ngClass]="{'input-group': suffix, 'input-wrap': true}">
      <ng-content></ng-content>  <span *ngIf="suffix">{{suffix}}</span>
    </div>

    <mi-alert-item [alertType]="'ERROR'"
                   *ngIf="errorMessage">
      {{errorMessage}}
    </mi-alert-item>

  </div>
</div>
