<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <label>{{confirmationText}}</label>
  </modal-content>
  <modal-footer>
    <div class="cta">
      <button *ngIf="!isDanger" (click)="confirm()"
              mi-button buttonTheme="dark-theme">
        {{buttonLabel}}
      </button>
      <button *ngIf="isDanger" (click)="confirm()"
              mi-button buttonTheme="alert-theme">
        {{buttonLabel}}
      </button>
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        {{'GLOBAL.BUTTON_CANCEL' | translate}}
      </button>
    </div>
  </modal-footer>
</modal>