import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductField} from '../../../shared/models/ProductField';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {HttpClient} from '@angular/common/http';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class ProductFieldService {

  private apiUrlCrud = '/miyagi/product-fields';

  private accountHost = '_MICRO_SERVICE_/product/properties/types';

  constructor(private http: HttpClient) {
  }

  createProductField(productField: ProductField): Promise<ProductField> {
    return this.http
      .post(`${this.accountHost}`, productField)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  listProductFields(): Promise<ProductField[]> {
    return this.http
      .get(`${this.accountHost}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  editProductField(productField: ProductField): Promise<ProductField> {
    return this.http
      .put(`${this.accountHost}`, productField)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  deleteProductField(productFieldId: string): Promise<ProductField> {
    return this.http
      .delete(`${this.accountHost}/${productFieldId}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  deleteProductFieldOLD(productFieldId: string): Observable<ProductField> {
    return this.http
      .delete(`${this.apiUrlCrud}/${productFieldId}`)
      .map((response: any) => response.data.product_field as ProductField);
  }

  getProductField(productFieldId: string): Promise<ProductField> {
    return this.http
      .get(`${this.accountHost}/${productFieldId}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }
}
