<ng-container *ngIf="orderState; else loading">

  <div class="half-col">

    <ng-container *ngIf="activeIndex; else static">
        <mi-service-id-form (complete)='onFormSubmit($event)'
                            [actual_vendor_id]='orderState.vendor.actual_vendor_id'
                            completeButton='Next'>
                        <a mi-button
                          buttonTheme="light-theme"
                          (click)="onCancelCLicked()">
                          Cancel
                        </a>
        </mi-service-id-form>

    </ng-container>

    <ng-template #static>

      <dl>
        <dt>Service Identifier</dt>
        <dd>{{serviceIdentifierForm.get('service_identifier').value || NO_DATA}}</dd>
      </dl>

    </ng-template>

  </div>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


