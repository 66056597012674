<mi-server-error [error]="error"></mi-server-error>

<h2>{{'CONTACTS.EDIT_CONTACTS_TITLE' | translate}}</h2>

<div style="width:50%" *ngIf="contactId; else loading">
  <mi-contact-form
    [editId]="contactId"
    (canceled)="onCanceled()"
    (contactComplete)="onUpdateContact($event)">
  </mi-contact-form>
</div>


<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

