import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Miso3AccountRoutes } from "./miso3-account.router";
import { SharedModule } from "src/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { Miso3AccountComponent } from "./miso3-account.component";
import { ManagePaymentMethodsComponent } from "./manage-payment-methods/manage-payment-methods.component";
import { ManageCurrentPlanComponent } from "./manage-current-plan/manage-current-plan.component";
import { ChangePaymentMethodModalComponent } from "./components/modals/change-payment-method-modal/change-payment-method-modal.component";
import { ModalModule } from "@independer/ng-modal";
import { ToastrModule } from "ngx-toastr";
import { AddPaymentMethodModalComponent } from "./components/modals/add-payment-method-modal/add-payment-method-modal.component";
import { ChangeInvoiceEmailModalComponent } from "./components/modals/change-invoice-email-modal/change-invoice-email-modal.component";
import { ChangePlanModalComponent } from "./components/modals/change-plan-modal/change-plan-modal.component";
import { PlanAndUsageDetailsComponent } from "./plan-and-usage-details/plan-and-usage-details.component";
import { BillingBreakdownModalComponent } from "./components/modals/billing-breakdown-modal/billing-breakdown-modal.component";

@NgModule({
  declarations: [
    Miso3AccountComponent,
    ManagePaymentMethodsComponent,
    ManageCurrentPlanComponent,
    ChangePaymentMethodModalComponent,
    AddPaymentMethodModalComponent,
    ChangeInvoiceEmailModalComponent,
    ChangePlanModalComponent,
    PlanAndUsageDetailsComponent,
    BillingBreakdownModalComponent
  ],
  imports: [
    RouterModule.forChild(Miso3AccountRoutes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ModalModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      closeButton: true
    })
  ],
  providers: [],
  exports: [
    Miso3AccountComponent,
    ManagePaymentMethodsComponent,
    ManageCurrentPlanComponent,
    ChangePaymentMethodModalComponent,
    AddPaymentMethodModalComponent,
    ChangeInvoiceEmailModalComponent,
    ChangePlanModalComponent,
    PlanAndUsageDetailsComponent,
    BillingBreakdownModalComponent
  ]
})

export class Miso3AccountModule {
}