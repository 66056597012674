import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {EmailFormRecipient} from "../../../../../shared/ng-models/EmailFormRecipient.interface";
import {Link} from "../../../../../shared/ng-models/Link.interface";
import {MiService} from "../../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../../libs/api/tenant/tenantMiService.service";
import {Contact} from "../../../../../shared/models/Contact";
import * as userReducer from "../../../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../../../shared/state/app-data/reducers';
import {Store} from "@ngrx/store";
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {MisoCollection} from "../../../../../shared/models/MisoCollection.enum";
import {Email} from "../../../../../shared/models/Email.interface";
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {Note} from "../../../../../shared/models/Note.interface";
import {EmailRecipient} from "../../../../../shared/models/EmailRecipient.interface";
import {TenantNoteService} from "../../../../../libs/api/tenant/tenantNote.service";
import {PageAlertAddAction} from "../../../../../shared/state/page-alert/actions";
import { TenantReportsService } from "../../../../../libs/api/tenant/tenantReports.service";
import { Report } from "../../../../../shared/models/Report";
import {EMAIL_TYPE_MISSING_DATA} from '../../../../../common/utils/sharedConstants';
import { missingDataBody } from "src/shared/utils/emailContent";
import { MiParentService } from "src/shared/models/MiParentService";
import { TenantMiParentServiceService } from "src/libs/api/tenant/tenantMiParentService.service";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  styleUrls: ['./request-missing-data.component.scss'],
  templateUrl: './request-missing-data.component.html'
})

export class ParentRequestMissingDataComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true
    }
  ];
  cancelUrl: string;
  emailBody: string;
  emailRecipients: EmailFormRecipient[];
  emailSubject: string;
  emailType: string = EMAIL_TYPE_MISSING_DATA;
  error: string;
  headerLinks: Link[];
  loaded: boolean = false;
  tenantContacts: EmailFormRecipient[];
  userData: any;
  report: Report;
  attachment: any[];
  tenantData: any;
  parentService: MiParentService;

  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceService: TenantMiServiceService,
    private tpsService: TenantMiParentServiceService,
    private store: Store<userReducer.State>,
    private tenantNoteService: TenantNoteService,
    private router: Router,
    private tenantReportService: TenantReportsService,
    private appDataStore: Store<appDataReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    // Find the current service ID:
    let parentServiceId = this.activatedRoute.snapshot.params['display_id'];

    // Update breadcrumbs:
    let newBreadcrumbs = [
      {
        url: `/parent-services/${parentServiceId}`,
        label: parentServiceId,
        internal: true
      },
      {
        url: `/parent-services/${parentServiceId}/request-missing-data`,
        label: 'Request Missing Data',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];

    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState);
    const parentService = this.tpsService.getByDisplayId(parentServiceId).toPromise()
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

    combineLatest(userStateData, parentService, tenantStateData)
      .subscribe((data)=>{
        this.tenantData = data[2].tenantData;
        this.userData = data[0].secureUser.getSafeData();
        this.parentService = data[1];
        let emailBodyComponent = {
          user_email:this.userData.email,
          user_name: `${this.userData.first_name} ${this.userData.last_name}`,
          vendor_name: this.parentService.current_notification_service.product.product_vendor.vendor_name,
          recipient_first_name: this.parentService.current_notification_service.account_id && this.parentService.current_notification_service.account_id_v2.contact? this.parentService.current_notification_service.account_id_v2.contact.first_name: '',
          tenant_display_name: this.tenantData.display_name
        }
        if(!this.report){
          this.tenantReportService.createtMissingDataReportParent(this.parentService._id)
          .toPromise()
          .then((res: any) => {
              this.report = res || null;
              this.attachment= [];
              if(this.report){
                this.attachment.push(this.report.excel_file);
              }
              this.emailBody = missingDataBody(emailBodyComponent)
              this.cancelUrl = `/services/${this.parentService.current_notification_service.display_id}`;
              let recipientArray = [];
              if(this.parentService.current_notification_service.account_id && this.parentService.current_notification_service.account_id_v2.contact){
                let contactAR = this.parentService.current_notification_service.account_id_v2.contact;
                  recipientArray.push({
                    _id: contactAR._id,
                    first_name: contactAR.first_name,
                    last_name: contactAR.last_name,
                    email: contactAR.email,
                    role: contactAR.role,
                    type: MisoCollection.CONTACTS,
                    display_id: contactAR.display_id||""
                  });
              }
              this.emailRecipients = recipientArray;
              this.emailSubject = `${emailBodyComponent.user_name} at ${this.tenantData.display_name} is looking for data regarding ${emailBodyComponent.vendor_name} services`;
              this.loaded = true;
          })
          .catch(e => this.error = e);
        }
      },
        e => this.error = e);


  }

  onEmailCreated(email: Email) {

    const note:Note = {
      connections: [],
      html: `<p><strong>MISO Action</strong> — <em>Request Missing Data</em><br><a href="/emails/${email._id}">View Email</a></p>`
    };

    note.connections.push({
      document_id: this.parentService._id,
      document_collection: MisoCollection.PARENT_SERVICES
    });

    email.recipients.forEach( (recipient:EmailRecipient) => {
      if (recipient.collection_ref === MisoCollection.CONTACTS) {
        note.connections.push({
          document_id: recipient.collection_id,
          document_collection: MisoCollection.CONTACTS
        });
      }
    });

    this.tenantNoteService.createNote(note)
      .subscribe(
        note => {
          //log('note saved', note);
          this.store.dispatch(
            new PageAlertAddAction({
              message: `Request Missing Data Email has been sent.`,
              alertType: "SUCCESS"
            })
          );
          return this.router.navigate([`email-sent`], {relativeTo: this.activatedRoute});
        },
          e => this.error = `The email has been sent, but a note error occurred: ${e}`
      )

  }




}
