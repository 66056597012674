import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DraftAccountResponse, TenantDraftAccountRequest, ValidationResponse } from "src/shared/models/TenantCreationV2";

@Injectable()
export class TenantCreationV2Service {
  private apiUrl = "_MICRO_SERVICE_/tenant/";
  private apiUrlCompanyIdValidation = "_MICRO_SERVICE_/tenant/identifier/verify";
  private apiUrlCreateTenantDraftAccount = "_MICRO_SERVICE_/tenant/createDraftTenantAccount";
  private apiUrlSendTenantInviteEmail = "_MICRO_SERVICE_/tenant/email/admin/invitation";

  constructor(private http: HttpClient) {
  }

  validateCompanyCode(code: string): Observable<ValidationResponse> {
    return this.http
      .post<ValidationResponse>(this.apiUrlCompanyIdValidation + `?identifier=${code}`, {});
  }

  createTenantDraftAccount(data: TenantDraftAccountRequest): Observable<DraftAccountResponse> {
    return this.http
      .post<DraftAccountResponse>(this.apiUrlCreateTenantDraftAccount, {...data});
  }

  sendTenantInviteEmail(email: string): Observable<any> {
    return this.http
      .post(this.apiUrlSendTenantInviteEmail, {email: email});
  }
}