<ng-container *ngIf="!showAddServiceGroup">

  <div class="group-search-wrapper">
    <div class="group-search">
      <mi-search placeholder="Search for Service Group"
                 value="{{searchString}}"
                 qtyMessage="{{ 'SEARCH_QUANTITIES.SERVICE_GROUPS' | translate:{count:total} | translateSelector:total }}"
                 (searchTermChanged)="onSearchTermChanged($event)" (clearSearch)="onClearSearch()"></mi-search>
      <mi-server-error [error]="resultsError"></mi-server-error>
    </div>
  </div>

  <div class="mi-grid-std search-results">
    <div class="scrollableSubTable" *ngIf="(hits && hits.length); else noContent">
      <mi-sortable-table [rows]="hits" [columns]="generalInformationTableColumns" variant="SMALL"
                         externalClasses="fullWidth">
      </mi-sortable-table>
    </div>

    <mi-loading-animation class="first-load" *ngIf="!resultsLoadedFirstTime">
    </mi-loading-animation>

    <div class="load-more" *ngIf="(hits.length < total)">
      <a href="#" *ngIf="(page*pageSize) <= total" (click)="loadMoreClicked($event)">Load More</a>
    </div>

    <ng-template #noContent>
      <div *ngIf="resultsLoaded">No Service Group found</div>
    </ng-template>
  </div>

</ng-container>

<div class="add-group">

  <div *ngIf="!showAddServiceGroup"
       class="create-container">
    <a (click)="onClickCreateNew()"
       class="link--after">
      Create a new Service Group
    </a>
  </div>

  <ng-container *ngIf="showAddServiceGroup">
    <h3>Add Service Group</h3>
    <br/>
    <div>
      <mi-service-groups-edit-form
        [preFill]="searchString"
        (onFormSubmitComplete)="onAddComplete($event)"
        (onAddServiceGroupCancel)="onAddServiceGroupCancel()">
      </mi-service-groups-edit-form>
    </div>
  </ng-container>

</div>

<ng-template #checkboxTpl let-row="row" let-value="value">

  <label>
    <input type="checkbox" value="{{row._id}}" (click)="onSelect($event)">
    {{row.display_id}}
  </label>

</ng-template>

<ng-template #alertStatusTmpl let-row="row" let-value="value">
  {{ 'ALERT_STATUS.' + row.alert_status | translate}}
</ng-template>
