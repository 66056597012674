import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl} from '@angular/forms';
import {FileService} from '../../../libs/api/file/file.service';
import {guid} from '../../shared.functions';

@Component({
  selector: 'radio-list',
  templateUrl: './radio-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioList),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioList),
      multi: true,
    },
    FileService
  ]
})

export class RadioList implements ControlValueAccessor {
  @Input() textFirst: boolean = false;
  @Input() allowNew: boolean;
  @Input() placeholder: string = 'New';
  @Input() buttonOptions: Array<string>;
  @Input() inputId: string = 'groupName_' + (guid(true));

  selectedValue: string;
  @Input() newValue: string = '';
  @Input() selectedIndex: number = 0;
  isOpen: boolean = false;
  selectNewValue = false;
  allowNewId: string = 'Input_999' + ((Math.random() * 10) | 0);

  private propagateChange = (_: any) => {
  };
  private propagateTouch = (_: any) => {
  };

  constructor() {

  }

  onSelectionChange(value: any) {
    this.selectedValue = value;
    this.propagateChange(value);
  }

  public validate(c: FormControl) {
    return null;
  }

  public writeValue(obj: any) {
    if (!obj && this.buttonOptions.length > 0) {
      obj = this.buttonOptions[0];
    }
    this.selectedValue = obj;
    let indexOfObj = this.buttonOptions.indexOf(obj);
    if (this.selectedIndex === 0) {
      this.selectedIndex = indexOfObj > -1 ? indexOfObj : 0;
    }
    this.propagateChange(obj);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

}
