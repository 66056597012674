import {Component, OnInit} from '@angular/core';
import {MiParentService} from '../../../../../../shared/models/MiParentService';
import * as psdReducer from '../../state/reducers';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {MiService} from '../../../../../../shared/models/MiService';
import {ParentServiceStatus} from '../../../../../../shared/models/ParentServiceStatus.interface';
import {parentServiceStatusHelper, scrollTo} from '../../../../../../shared/shared.functions';
import {ParentServiceShowForm, FormTypes} from '../../state/actions';
import { LocationV2 } from 'src/libs/api2/types/LocationV2.interface';


@Component({
  selector: 'mi-parent-service-summary',
  templateUrl: './parent-service-summary.component.html',
  styleUrls: ['./parent-service-summary.component.scss']
})

export class ParentServiceSummaryComponent extends AbstractPageComponent implements OnInit {

  accountIDs: any[];
  error: string;
  formOpen: boolean = false;
  locations: any[];
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  parentServiceStatus: ParentServiceStatus;
  serviceGroups: any[];
  serviceModifications: any[];
  selectedForm: string;
  title: string = 'Product Details';
  totalMRC: number;
  totalMissed: number;
  totalPotential: number;
  totalRealized: number;

  // Show All Toggles:
  maxRows: number = Number.MAX_SAFE_INTEGER;
  maxLocations: number = 2;
  showAllLocations: boolean = false;
  maxAccountIds: number = 2;
  showAllAccountIds: boolean = false;

  readonly NO_FORM: string = "NO_FORM";

  // For Types:
  readonly formTypes:any = FormTypes;

  readonly CANCELLATION_FORM: string = FormTypes.CANCELLATION_FORM;
  readonly CONFIRM_ACTIVATION_FORM: string = FormTypes.ACTIVATION_FORM
  readonly CONFIRM_CANCELLATION_FORM: string = FormTypes.CONFIRM_CANCELLATION_FORM;
  readonly REMOVE_CANCELLATION_FORM: string = FormTypes.REMOVE_CANCELLATION_FORM;

  constructor(
    private store: Store<psdReducer.State>
  ) {
    super();
  }

  ngOnInit() {
    this.pageLoaded$ = this.store.select(psdReducer.ParentServicePageLoaded);
    this.store.select(psdReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe((data) => {
        if (data.parentService) {
          this.parentService = data.parentService;
          this.parentServiceStatus = this.updateParentServiceStatus(this.parentService);
          this.locations = this.getUniqueLocations(this.parentService);
          this.accountIDs = this.getUniqueAccountIDs(this.parentService);
          this.totalMRC = this.getTotalMRC(this.parentService);
          this.totalMissed = this.getTotalMissed(this.parentService);
          this.totalPotential = this.getTotalPotential(this.parentService);
          this.totalRealized = this.getTotalRealized(this.parentService);
          this.serviceModifications = this.getServiceModifications(this.parentService);
        }
        this.showForm(data.currentForm);
      });
  }

  getServiceModifications(parentService: MiParentService): any[] {
    return [];
  }

  getTotalMissed(parentService: MiParentService): number {
    return parentService.services.reduce((total, service) => {
      return total + service.savings.missed.to_savings;
    }, 0) * 12;
  }

  getTotalMRC(parentService: MiParentService): number {
    return parentService.mrc;
  }

  getTotalPotential(parentService: MiParentService): number {
    return parentService.services.reduce((total, service) => {
      return total + service.savings.potential.to_savings;
    }, 0);
  }

  getTotalRealized(parentService: MiParentService): number {
    return parentService.services.reduce((total, service) => {
      return total + service.savings.realized;
    }, 0);
  }

  getUniqueLocations(parentService: MiParentService): any[] {

    const unique = [];
    const map = new Map();

    parentService.services.forEach((service: MiService) => {
      service.locations_v2.forEach((location: LocationV2) => {
        if (!map.has(location.id)) {
          map.set(location.id, true);
          unique.push(location);
        }
      });
    });

    return unique.sort((a, b) => {
      return a.address.city_long > b.address.city_long
        ?
        1
        :
        -1;
    });

  }

  getUniqueAccountIDs(parentService: MiParentService): any[] {

    const unique = [];
    const map = new Map();

    parentService.services.forEach((service: MiService) => {
      if (service.account_id && !map.has(service.account_id_v2._id)) {
        map.set(service.account_id_v2._id, true);
        unique.push(service.account_id);
      }
    });

    return unique.sort((a, b) => {
      return a.label > b.label
        ?
        1
        :
        -1;
    });

  }

  scrollToSection(which: string): void {
    setTimeout(() => {
      scrollTo(which);
    }, 100);
  }

  showForm(formName: string): void {
    this.selectedForm = formName || '';
    this.formOpen = this.selectedForm && this.selectedForm !== '';
    this.formOpen ? this.scrollToSection(this.selectedForm) : window.scrollTo(0, 0);
  }

  updateParentServiceStatus(parentService: MiParentService): ParentServiceStatus {
    return parentServiceStatusHelper(parentService);
  }

  /*
  * Actions
  * */

  onCancelRetermClicked(): void {
    console.log('onConfirmActivationClicked');
  }

  onConfirmActivationClicked(): void {
    this.store.dispatch(new ParentServiceShowForm({
      currentForm: FormTypes.ACTIVATION_FORM
    }));
  }

  onConfirmCancellationClicked(): void {
    this.store.dispatch(new ParentServiceShowForm({
      currentForm: FormTypes.CONFIRM_CANCELLATION_FORM
    }));
  }

  onCancelClicked(): void {
    this.store.dispatch(new ParentServiceShowForm(
      {
        currentForm: FormTypes.CANCELLATION_FORM
      }
    ));
  }

  onRemoveCancellationClicked(): void {
    this.store.dispatch(new ParentServiceShowForm({
      currentForm: FormTypes.REMOVE_CANCELLATION_FORM
    }));
  }

  /*
  * FORM STUFF
  */

  // Generic cancel handler:
  onFormCancelClicked() {
    this.store.dispatch(new ParentServiceShowForm({
      currentForm: ''
    }));
    window.scrollTo(0, 0);
  }

}
