<mi-server-error [error]="error"></mi-server-error>
<ng-container *ngIf="mode===FORM_EDIT && confirmCancellationFormGroup">
  <form [formGroup]="confirmCancellationFormGroup"
    (submit)="onEditCancellationRequestFormSubmit(confirmCancellationFormGroup.value)" *ngIf="serviceDetails">
    <div class="cancellation-date">
      <mi-extended-input labelText="Cancellation Date" class="service-details" [labelRequired]="true"
        [(inputControl)]="confirmCancellationFormGroup.controls['cancellation_date']"
        [(inputErrors)]="confirmCancellationFormGroup.controls['cancellation_date'].errors"
        [(inputTouched)]="confirmCancellationFormGroup.controls['cancellation_date'].touched">
        <mi-date-picker [currentDate]="confirmCancellationFormGroup.value.cancellation_date"
          (dateChange)="onDateChange($event)" [ngStyle]="{'width':'200px'}">
        </mi-date-picker>
      </mi-extended-input>
    </div>
    <div class="button-container">
      <button mi-button buttonTheme="light-theme" (click)="onCancelClicked($event)">
        {{ 'TENANT.SERVICE_DETAILS.CTA.CANCEL_WITHOUT_MODIFICATION' | translate }}
      </button>
      <button mi-button type="button" buttonTheme="dark-theme" [disabled]="confirmCancellationFormGroup.invalid"
        (click)="onContinueClick()">
        {{ 'TENANT.SERVICE_DETAILS.CTA.CONTINUE' | translate }}
      </button>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="mode===FORM_CONFIRM">
  <ng-container *ngIf="cancelDateIsBeforeToday">
    {{'TENANT.SERVICE_DETAILS.CONFIRM_CANCELLATION_MSG' | translate: {
    'date': confirmCancellationFormGroup.get('cancellation_date').value | date:"shortDate"
    }
    }}
  </ng-container>
  <ng-container *ngIf="!cancelDateIsBeforeToday">
    {{
    'TENANT.SERVICE_DETAILS.EDIT_PENDING_CANCELLATION_MSG' | translate: {
    'date': confirmCancellationFormGroup.get('cancellation_date').value | date:"shortDate"
    }
    }}
  </ng-container>
  <div class="button-container">
    <button mi-button buttonTheme="dark-theme" [disabled]="confirmCancellationFormGroup.invalid"
      (click)="onEditCancellationRequestFormSubmit(confirmCancellationFormGroup.value)">
      {{ 'TENANT.SERVICE_DETAILS.CTA.SAVE' | translate }}
    </button>
    <button mi-button type="button" buttonTheme="light-theme" (click)="onBackClick()">
      {{ 'TENANT.SERVICE_DETAILS.CTA.BACK' | translate }}
    </button>
  </div>
</ng-container>