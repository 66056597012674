import {Component, OnInit} from "@angular/core";
import {Vendor} from "../../../shared/models/Vendor";
import {ModalRef} from "@independer/ng-modal";

@Component({
  templateUrl: './find-or-create-vendor-modal.component.html',
  styleUrls: ['./find-or-create-vendor-modal.component.scss']
})

export class FindOrCreateVendorModalComponent implements OnInit {
  title: string = 'Find Vendor or Organization';
  defaultSearchText: string = '';
  showCreateVendor: boolean = true

  constructor(
    private modal: ModalRef) {
  }

  ngOnInit(): void {

  }

  close() {
    this.modal.close(null);
  }

  onVendorSelect(vendor: Vendor): void {
    this.modal.close(vendor);
  }
}
