import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as pageStoreActions from './actions';
import * as router from '@ngrx/router-store';

export interface State {
  pageHistory: any[];
  showFooter: boolean;
  showNav: boolean;
  template: string;
  pageTitle: string;
}

const initialState: State = {
  pageHistory: [],
  showFooter: true,
  showNav: true,
  template: null,
  pageTitle: "MISO"
};

export function reducer(state = initialState, action: pageStoreActions.Actions): State {

  switch (action.type) {
    case router.ROUTER_NAVIGATION: {
      /* Check whether the Footer needs to be displayed? Against the data object in the route? */
      return {
        ... state,
        showFooter: action.payload.routerState.showFooter,
        showNav: action.payload.routerState.showNav,
        template: action.payload.routerState.template,
        pageTitle: action.payload.routerState.pageTitle? action.payload.routerState.pageTitle: '',
      };
    }
    default: {
      return state;
    }
  }
}

export const getPageData = createFeatureSelector<State>('page');

export const showFooter = createSelector(
  getPageData,
  (state) => state.showFooter
);

