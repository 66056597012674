import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mi-list-limit',
  templateUrl: './list-limit.component.html'
})

export class ListLimitComponent {

  isOpen: boolean = false;

  @Input() list:any[];
  @Input() max:number = 5;
  @Input() get open(){
    return this.isOpen;
  }
  set open(value){
    this.isOpen = value;
    this.openChange.emit(this.isOpen);
  }
  @Output() openChange = new EventEmitter();

  toggle():void{
    this.open = !this.open;
  }
}
