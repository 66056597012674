import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from "rxjs/index";
import {RoleV2} from "../types/RolesV2.interface";
import {CountryV2} from "../types/CountryV2.interface";
import {StateV2} from "../types/StateV2.interface";
import {map, switchMap} from "rxjs/operators";
import {Cacheable} from "ngx-cacheable";

export interface CountryAndStateV2{
  country: CountryV2,
  state: StateV2
}

@Injectable()
export class Miso3baseService {

  private host = '_MICRO_SERVICE_/miso3base';
  private cachedGetStates = {};

  constructor(private http: HttpClient) {

  }

  getRoles(): Observable<RoleV2[]> {
    return this.http
      .get(`${this.host}/roles`)
      .map((response: any) => response as RoleV2[]);
  }

  @Cacheable()
  getCountries(): Observable<CountryV2[]> {
    return this.http
      .get(`${this.host}/countries`)
      .map((response: any) => response as CountryV2[]);
  }

  @Cacheable()
  getStates(country_id: string): Observable<StateV2[]> {
    if(!this.cachedGetStates[country_id]) {
      const params = {country_id};
      this.cachedGetStates[country_id] = this.http
        .get(`${this.host}/states`, {params})
        .map((response: any) => response as StateV2[]);
    }
    return this.cachedGetStates[country_id];
  }

  @Cacheable()
  getCountryAndStateFromName(countryName, stateName): Observable<CountryAndStateV2> {
      let country: CountryV2;
      let state: StateV2;
      return this.getCountries()
        .pipe(
          switchMap(countries => {
              countries.forEach(cntry => {
                if (cntry.country_name === countryName) {
                  country = cntry;
                }
              });
              if(country) {
                return this.getStates(country.id)
              }
              return of([])
            }),
            map((states)=> {
              state = states.find(st => st.state_name === stateName);
              return {country, state}
            })
        )
  }
}
