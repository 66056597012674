import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as OrderReducers from '../../../create/state/reducer';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {UpdateOrderAction} from '../../../create/state/actions';
import {AssociatedDocument} from "../../../../../../../shared/models/AssociatedDocument";
import { Document } from 'src/libs/api2/types/Document.interface';


@Component({
  selector: 'mi-service-documents',
  templateUrl: './service-documents.component.html',
  styleUrls: ['./service-documents.component.scss']
})

export class ServiceDocumentsComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  step: string = OrderSteps.ServiceDocs;

  orderDocs: Document[] = [];
  serviceDocs: Document[] = [];

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private store: Store<OrderState>
  ) {
    super();
  }

  ngOnInit() {

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
          this.orderDocs = this.orderState.associated_documents;
          this.serviceDocs = this.orderState.service.associated_documents;
        },
        (e) => this.error = e
      );

  }

  onFormSubmit(data: any) {

    this.store.dispatch(new UpdateOrderAction(
      {
        index: this.index + 1,
        maxIndex: Math.max(this.orderState.maxIndex, this.index + 1),
        service: {
          ...this.orderState.service,
          associated_documents: data
        }
      }
    ));

  }

  getServiceDocs(){
    return this.serviceDocs.map(d=>d.id);
  }

}
