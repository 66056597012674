<section class="service-summary">

  <ng-container *ngIf="parentService; else loading">

    <div class="service-summary-container">

      <div class="service-summary-column">

        <div class="service-summary-item">

          <h2>
            Details
          </h2>

          <table>
            <tr>
              <td>
                Vendor
              </td>
              <td>
                <mi-id-link [id]="parentService.product.product_vendor.display_id">
                </mi-id-link>
                {{parentService.product.product_vendor.vendor_name}}
              </td> 
            </tr>
            <tr>
              <td>
                Product
              </td>
              <td>
                {{parentService.product.product_name}}
              </td>
            </tr>
            <tr *ngIf="parentService.division">
              <td>
                Division
              </td>
              <td>
                <mi-simple-bu [id]="parentService.division"></mi-simple-bu>
              </td>
            </tr>
            <tr *ngIf="parentService.team">
              <td>
                Team
              </td>
              <td>
                <mi-simple-bu [id]="parentService.team"></mi-simple-bu>
              </td>
            </tr> 
            <tr>
              <td>
                M3 Owner
              </td>
              <td>
                <a href="mailto:{{parentService.assigned_email}}">{{parentService.assigned_email}}</a>
              </td>
            </tr>
          </table>

        </div>

        <div class="service-summary-item" [ngClass]="{showAll:showAllLocations}">
          <h2>
            Locations
            <mi-list-limit [max]="maxLocations"
                           [list]="locations"
                           [(open)]="showAllLocations"></mi-list-limit>
          </h2>
          <p class="note"
             *ngIf="locations.length === 0">
            {{'PARENT_SERVICE_SUMMARY.NO_LOCATIONS'|translate}}
          </p>
          <ul *ngIf="locations.length !== 0"
              class="list locations">
            <li class="location"
                [title]="location.address.formatted_address"
                *ngFor="let location of locations | slice:0:(showAllLocations ? maxRows : maxLocations)">
                <mi-simple-location [location]="location" [compact]="true"></mi-simple-location>
            </li>
          </ul>
        </div>

        <div class="service-summary-item">
          <h2>
            Account IDs
            <mi-list-limit [max]="maxAccountIds"
                           [list]="accountIDs"
                           [(open)]="showAllAccountIds"></mi-list-limit>
          </h2>
          <p class="note"
             *ngIf="!accountIDs.length">
            {{'PARENT_SERVICE_SUMMARY.NO_ACCOUNT_IDS'| translate}}
          </p>
          <ul class="list accountIds"
              *ngIf="accountIDs.length">
            <li class="accountId"
                *ngFor="let accountId of accountIDs | slice:0:(showAllAccountIds ? maxRows : maxAccountIds)">
              <mi-id-link [id]="accountId.display_id"></mi-id-link>
              {{accountId.label}}
            </li>
          </ul>
        </div>

      </div>

      <div class="service-summary-column">

        <div class="service-summary-item">
          <h2>Service Economics</h2>
          <table>
            <tr>
              <td>Child Service Count</td>
              <td>{{parentService.services.length}}</td>
            </tr>
            <tr>
              <td>Calculated MRC</td>
              <td>{{totalMRC | round |currency:'USD':'symbol':'1.0'}}</td>
            </tr>
            <tr>
              <td>Missed Opportunity</td>
              <td>{{totalMissed | round | currency:'USD':'symbol':'1.0'}}/annual</td>
            </tr>
            <tr>
              <td>Potential Savings</td>
              <td>{{totalPotential | round | currency:'USD':'symbol':'1.0'}}/month</td>
            </tr>
            <tr>
              <td>Realized Savings</td>
              <td>{{totalRealized | round | currency:'USD':'symbol':'1.0'}}</td>
            </tr>
          </table>
        </div>

        <div class="service-summary-item">
          <h2>Modify Service</h2>
          <ng-container *ngIf="!parentServiceStatus.allArchived;else noModifications">
            <ul class="link-list"
                *ngIf="parentService.services.length">
              <li *ngIf="parentServiceStatus.allPendingActivation">
                <a (click)="onConfirmActivationClicked()"
                   class="link link--confirm-activation"
                   [ngClass]="{'disabled':formOpen}">
                  Confirm Activation
                </a>
              </li>
              <li *ngIf="parentServiceStatus.allPendingCancellation">
                <a (click)="onConfirmCancellationClicked()"
                   class="link link--request-cancellation"
                   [ngClass]="{'disabled':formOpen}">
                  Confirm/Edit Cancellation
                </a>
              </li>
              <li *ngIf="parentServiceStatus.allPendingCancellation">
                <a (click)="onRemoveCancellationClicked()"
                   class="link link--request-cancellation"
                   [ngClass]="{'disabled':formOpen}">
                  Remove Cancellation
                </a>
              </li>
              <li *ngIf="!parentServiceStatus.allPendingCancellation">
                <a (click)="onCancelClicked()"
                   class="link link--request-cancellation"
                   [ngClass]="{'disabled':formOpen}">
                  Plan Cancellation
                </a>
              </li>
            </ul>
          </ng-container>
          <ng-template #noModifications>
            <p class="note">
              {{'PARENT_SERVICE_SUMMARY.MODIFICATIONS_WHEN_ARCHIVED'|translate}}
            </p>
          </ng-template>
        </div>

        <div class="service-summary-item">
          <h2>Quick Links</h2>
          <ul class="link-list">
            <li>
              <a (click)="scrollToSection('service-group-list')"
                 class="link link--service-groups">
                Service Groups ({{this.parentService.service_groups.length}})
              </a>
            </li>
          </ul>

        </div>

      </div>

    </div>

    <div class="service-summary-actions">
      <mi-parent-service-actions></mi-parent-service-actions>
    </div>

  </ng-container>

</section>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<div class="form-container" *ngIf="formOpen">

  <div [id]="formTypes.ACTIVATION_FORM"
       class="inline-form"
       *ngIf="selectedForm === formTypes.ACTIVATION_FORM">

    <mi-parent-service-activation-form
      [parentService]="parentService"
      (canceled)="onFormCancelClicked($event)">
    </mi-parent-service-activation-form>

  </div>

  <div [id]="formTypes.CANCELLATION_FORM"
       class="inline-form"
       *ngIf="selectedForm === CANCELLATION_FORM">

    <mi-parent-service-summary-cancellation-request-form
      [parentService]="parentService"
      (canceled)="onFormCancelClicked($event)">
    </mi-parent-service-summary-cancellation-request-form>

  </div>

  <div [id]="formTypes.CONFIRM_CANCELLATION_FORM"
       class="inline-form"
       *ngIf="selectedForm === CONFIRM_CANCELLATION_FORM">
    <mi-parent-service-confirm-cancellation-form
      (canceled)="onFormCancelClicked($event)"
      [parentService]="parentService">

    </mi-parent-service-confirm-cancellation-form>
  </div>
  <div [id]="formTypes.REMOVE_CANCELLATION_FORM"
       class="inline-form"
       *ngIf="selectedForm === REMOVE_CANCELLATION_FORM">
    <mi-parent-service-details-form-remove-cancellation
      [parentService]="parentService"
      (canceled)="onFormCancelClicked($event)">
    </mi-parent-service-details-form-remove-cancellation>
  </div>

</div>
