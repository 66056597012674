<div class="service-details-header">

  <div class="header-item">
    <mi-service-details-header-vendor>
    </mi-service-details-header-vendor>
  </div>

  <div class="header-item">
    <mi-service-details-group-economics>
    </mi-service-details-group-economics>
  </div>

  <div class="header-item">
    <mi-service-details-header-group-events>
    </mi-service-details-header-group-events>
  </div>

</div>
