import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../pages/abstract-page/abstract-page.component';
import {SectionDataTable} from '../../ng-models/SectionDataTable.interface';
import {attachmentsTableData, NO_DATA} from '../../ng-models/SectionDataTable.model';
import {AssociatedDocumentFormType} from '../associated-document-form/associated-document-form.component';
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import {CUSTOMER_DOCUMENT_UPLOAD_TYPES} from '../../../common/utils/sharedConstants';
import { Document } from 'src/libs/api2/types/Document.interface';
import { environment } from 'src/environments/environment';
import { Vendor } from 'src/shared/models/Vendor';
import { SortableTemplatesEnum } from 'src/shared/ng-models/SortableTableColumn.interface';

@Component({
  selector: 'mi-section-data-attachments',
  styleUrls: ['../section-data/section-data.component.scss'],
  templateUrl: './section-data-attachments.component.html'
})

export class SectionDataAttachmentsComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;

  data: SectionDataTable;
  @Input() noResultsText: string;
  @Input() title: string;
  @Input() vendors: Vendor[] = [];
  @Input() searchType: string = '';

  @ViewChild('fileType', {static: true}) fileType: TemplateRef<any>;
  @ViewChild('fileSize', {static: true}) fileSize: TemplateRef<any>;
  @ViewChild('fileLink', {static: true}) fileLink: TemplateRef<any>;
  @ViewChild('vendorTmpl', {static: true}) vendorTmpl: TemplateRef<any>;

 readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;

  private attachmentsTableData = {...attachmentsTableData};

  @Input('docs')
  set docs(val:Document[]) {
    this.data = this.updateAttachments(this.attachmentsTableData, val);
  }

  constructor(private tenantDocumentManagementService: TenantDocumentManagementService) {
    super();
  }


  updateAttachments(data: SectionDataTable, docs: Document[]): SectionDataTable {
    const retData = {...data};
    let rows = [];

    docs.forEach(doc => {
      rows.push({
        id: doc.id,
		    display_id: doc.display_id,
        document_name: doc.name,
        file_name: doc.file_name || this.NO_DATA,
        file_type: doc.type,
        file_size: doc.file_size || this.NO_DATA,
        updated_at: doc.created_on_utc,
        created_at:doc.created_on_utc,
        created_by: doc.created_by_user_name,
        is_url: doc.is_url,
        business_unit_id: doc.business_unit_id,
		    business_unit_name: doc.business_unit_name,
        tenant_id: doc.tenant_id,
        s3_key: doc.s3_key,
        is_v1: doc.is_v1,
        vendor_id: doc.vendor_id,
        invoice_date: doc.invoice_date,
        invoice_value: doc.invoice_value,
        invoice_number: doc.invoice_number,
        account_number: doc.account_number
      });
    });

    retData.rows = rows;

    retData.columns.forEach(col => {
      col.sortable = retData.rows.length !== 1;
    });

    retData.loaded = true;

    return retData;
  }

  ngOnInit() {
    const hasMultiple = this.data.rows.length > 1;
    this.data.columns.forEach(col => {
      col.sortable = hasMultiple;
      switch (col.prop) {
        case 'file_name':
          col.cellTemplate = this.fileLink;
          break;
          case 'file_size':
            col.cellTemplate = this.fileSize;
            break;
        case 'created_at':
        case 'updated_at':
          col.cellTemplate = SortableTemplatesEnum.shortISODateTime;
          break;
        case 'file_type':
            col.cellTemplate = this.fileType;
            break;
        case 'vendor_id':
          if(this.vendors && this.vendors.length){
            col.cellTemplate=this.vendorTmpl;
            col.hidden=false;
          }
          else{
            col.hidden=true;
            col.cellTemplate=null;
          }
      }
    });

    this.data.loaded = true;
  }

  shortenFileName(name) {
    if (name && name.length > 40) {
      return name.slice(0, 40) + '...';
    }
    return name;
  }

  getVendorDisplayName(row){
    let vendor = this.vendors.find(v=> v.id===row.vendor_id);
    if(vendor){
      return vendor.vendor_name;
    }
    return "";
  }

  getVendorDisplayId(row){
    let vendor = this.vendors.find(v=> v.id===row.vendor_id);
    if(vendor){
      return vendor.display_id;
    }
    return "";
  }

  downloadDocument(id) {
    return this.tenantDocumentManagementService.download(id).subscribe((response) => {
      window.open(response.download_url, "_blank");
    }, (e) => {

    });
  }

  getV1FileUrl(row){
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }
  
}
