<nav class="top-nav">
  <div class="top-short-cuts">
    <ul class="left">
      <li>
        <a [routerLink]="logoUrl">
          <ng-container *ngIf="!isMiyagi;else miyagiLogo">
            <ng-container *ngIf="isBranded;else unbranded">
              <!-- <img class="nav-flyout-logo" src="{{tenant.logo.full_path}}"> -->
              <img class="nav-flyout-logo" src="/shared/assets/images/miso3-logo.svg">
            </ng-container>
            <ng-template #unbranded>
              <img class="nav-flyout-logo" src="/shared/assets/images/miso3-logo.svg">
            </ng-template>
          </ng-container>
          <ng-template #miyagiLogo>
            <!--<img class="nav-flyout-logo miyagi" src="/shared/assets/images/miyagi-logo.svg">-->
          </ng-template>
        </a>
      </li>
      <li>
        <!-- <mi-shortcut-menu></mi-shortcut-menu> -->
      </li>
      <!-- <li *ngIf="showDashboardButton" class="short-cut-icon">
        <div class="dashboard-icon">
          <a
            [routerLink]="['/']">
          <span class="dashboard-anchor">Dashboard</span></a>
        </div>
      </li> -->
      <!-- <li *ngFor="let item of structure.shortCuts.leftLinks" class="short-cut-icon">
        <a [ngClass]="'micon-'+item.icon"
           [routerLink]="['/'+item.path]"
           [queryParams]="item.queryParams || {}">
          <em class="alert-count"
              [ngClass]="{ 'alert-count-zero' : item.alertCount == 0 }">
            {{item.alertCount}}
          </em>
          <span>{{item.label}}</span></a>
      </li> -->
    </ul>
    <ul class="right">
      <!-- <li *ngIf="tenantBillingAccount.trial" class="trial-container">
        <label>TRIAL</label>
        <label>{{trialDaysLeft}} Days Left</label>
      </li> -->
      <!-- <li *ngFor="let item of structure.shortCuts.rightLinks" class="short-cut-icon">
        <a *ngIf="item.url"
           [ngClass]="'micon-'+item.icon"
           [href]="item.url"
           [target]="item.target || '_self'"><span>{{item.label}}</span></a>

        <a *ngIf="!item.url"
           [ngClass]="'micon-'+item.icon"
           [routerLink]="['/'+item.path]"
           [queryParams]="item.queryParams || {}"><span>{{item.label}}</span></a>

      </li> -->
      

      <li>
        <mi-user-profile-dropdown [items]="dropdownItems" [dropdownTitle]="user.first_name+' '+user.last_name" [user]="user" [isSuperUser]="isSuperUser" icon="profile">
        </mi-user-profile-dropdown>
      </li>
    </ul>
  </div>
</nav>
