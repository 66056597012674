<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">
    <div class="half-col">

      <mi-custom-field-service-form
        submitCTA="Next"
        cancelCTA=""
        [currentFieldPropertiesSet]="orderState.service.custom_data"
        (customFieldsComplete)="onCustomFieldsComplete($event)">
      </mi-custom-field-service-form>

    </div>

  </ng-container>

  <ng-template #static>
    <mi-custom-field-service-static
      [currentFieldPropertiesSet]="orderState.service.custom_data">
    </mi-custom-field-service-static>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

