<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide">
  <modal-header>
    <h4>
      {{filterLabel||('TENANT.FILTER_GROUP_TITLE.' + modalTitle | translate)}}
    </h4>
  </modal-header>
  <modal-content >
    
    <div class="overlay-sections"  *ngIf="loaded; else loading">

    <mi-server-error [error]="error"></mi-server-error>

    <ng-container [ngSwitch]="filterType">

      <ng-container *ngSwitchCase="'NUMBER'">
        <mi-advanced-search-filter-number-v2
          [filter_name]="filterName"
          (filterApplied)="applyNumberFilter($event)"
          title=""
          [min_prefill]="rangeMinPrefill"
          [max_prefill]="rangeMaxPrefill">
        </mi-advanced-search-filter-number-v2>
      </ng-container>

      <ng-container *ngSwitchCase="'DATE'">
        <mi-advanced-search-filter-date-v2
          [filter_name]="filterName" 
          (filterApplied)="applyDateFilter($event)"
          title=""
          [min_prefill]="dateRangeMinPrefill"
          [max_prefill]="dateRangeMaxPrefill">
        </mi-advanced-search-filter-date-v2>
      </ng-container>

      <ng-container *ngSwitchCase="'SERVICE_CATEGORY'">
        <mi-advanced-search-filter-category>
        </mi-advanced-search-filter-category>
        <div class="cta">
          <button (click)="onModalClose()"
                  mi-button buttonTheme="dark-theme">
            Confirm
          </button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'LOCATION'">

        <p *ngIf="locationFilterEmpty">
          Search results do not contain items from this filter.
        </p>
        <!--COUNTRY-->
        <div class="location-header-items" *ngIf="locationFilterResponse.country.length">
          <div class="location-filter-label">
            <label>{{locationCountryDisplayFilterResponse.field}}</label>
          </div>
          <div class="option-filter-locations">
            <label>Filter&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <input type="text" (input)="onLocationFilterChange($event, 'country')" [(ngModel)]="locationFilterSearch.country">
          </div>
        </div>

        <div class="spacer-1"></div>

        <div class="options one-third">
          <div *ngFor="let filter of locationCountryDisplayFilterResponse.options; let i = index">
            <input
                type="checkbox"
                [value]="filter.label"
                id="{{'option_country_'+i}}"
                [checked]="filter.selected"
                (change)="onToggleLocationFilter($event, 'country')"/>
            <label
              for="{{'option_country_'+i}}"
              title="{{filter.label}}">
              {{filter.label}}
              <span *ngIf="filter.count">&nbsp;({{filter.count}})</span>
            </label>
          </div>
        </div>
        <div class="border-light"></div>
        <!--STATE-->
        <div class="location-header-items" *ngIf="locationFilterResponse.state.length">
          <div class="location-filter-label">
            <label>{{locationStateDisplayFilterResponse.field}}</label>
          </div>
          <div class="option-filter-locations">
            <label>Filter&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <input type="text" (input)="onLocationFilterChange($event, 'state')" [(ngModel)]="locationFilterSearch.state">
          </div>
        </div>

        <div class="spacer-1"></div>

        <div class="options one-third">
          <div *ngFor="let filter of locationStateDisplayFilterResponse.options; let i = index">
            <input
                type="checkbox"
                [value]="filter.label"
                id="{{'option_state_'+i}}"
                [checked]="filter.selected"
                (change)="onToggleLocationFilter($event, 'state')"/>
            <label
              for="{{'option_state_'+i}}"
              title="{{filter.label}}">
              {{filter.label}}
              <span *ngIf="filter.count">&nbsp;({{filter.count}})</span>
            </label>
          </div>
        </div>
        <div class="border-light"></div>
        <!--CITY-->
        <div class="location-header-items" *ngIf="locationFilterResponse.city.length">
          <div class="location-filter-label">
            <label>{{locationCityDisplayFilterResponse.field}}</label>
          </div>
          <div class="option-filter-locations">
            <label>Filter&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <input type="text" (input)="onLocationFilterChange($event, 'city')" [(ngModel)]="locationFilterSearch.city">
          </div>
        </div>

        <div class="spacer-1"></div>

        <div class="options one-third">
          <div *ngFor="let filter of locationCityDisplayFilterResponse.options; let i = index">
            <input
                type="checkbox"
                [value]="filter.label"
                id="{{'option_city_'+i}}"
                [checked]="filter.selected"
                (change)="onToggleLocationFilter($event, 'city')"/>
            <label
              for="{{'option_city_'+i}}"
              title="{{filter.label}}">
              {{filter.label}}
              <span *ngIf="filter.count">&nbsp;({{filter.count}})</span>
            </label>
          </div>
        </div>
        
      </ng-container>

      <ng-container *ngSwitchDefault>

        <div class="options">
          <p *ngIf="!filterResponse.options.length">
            Search results do not contain items from this filter.
          </p>

          <div class="option-filter" *ngIf="filterResponse.options.length">
            <label>Filter&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <input type="text" (input)="onOptionFilterChange($event)" [(ngModel)]="optionFilterSearch">
          </div>

          <div *ngFor="let filter of displayFilterResponse.options; let i = index">

            <input
              type="checkbox"
              [value]="filter.label"
              id="{{'option_'+i}}"
              [checked]="filter.selected"
              (change)="onToggleFilter($event)"/>

            <ng-container [ngSwitch]="filterName">
              <ng-container *ngSwitchCase="'parent_service_status'">
                <label
                  for="{{'option_'+i}}"
                  title="{{'TENANT.SERVICE_DETAILS.PARENT_SERVICE_STATUS.' + filter.key | translate }}">
                  {{'TENANT.SERVICE_DETAILS.PARENT_SERVICE_STATUS.' + filter.key | translate }}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'service_associations'">
                <label
                  for="{{'option_'+i}}"
                  title="{{customFields[filter.key]? customFields[filter.key].label : filter.key}}">
                  <span>
                    {{ customFields[filter.key]? customFields[filter.key].label : filter.key}}
                  </span>
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'contact'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  <span>
                    {{ contactsByIDs[filter.key]? contactsByIDs[filter.key].first_name + ' ' + contactsByIDs[filter.key].last_name : filter.key}}
                  </span>
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'role'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  {{ 'CONTACT_ROLES.' + filter.key | translate}}
                  <span *ngIf="filter.doc_count">({{filter.doc_count}})</span>
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'service_group_id'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  {{serviceGroupByIDs[filter.key]? serviceGroupByIDs[filter.key].name: filter.key}}
                  <span *ngIf="filter.doc_count">({{filter.doc_count}})</span>
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'business_unit_id'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  {{businessUnitsByIDs[filter.key]? businessUnitsByIDs[filter.key].name: filter.key}}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'account_id'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  <span *ngIf="accountIdsByIDs[filter.key]">
                    {{accountIdsByIDs[filter.key].label}} 
                    <span *ngIf="accountIdsByIDs[filter.key].label ==='Not Provided'">
                      ({{accountIdsByIDs[filter.key].vendor.vendor_name}})
                    </span>
                    <span *ngIf="filter.doc_count">({{filter.doc_count}})</span>
                  </span>
                  <span *ngIf="!accountIdsByIDs[filter.key]">
                    {{filter.key}}
                  </span>
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'cost_center_id'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  {{businessUnitsByIDs[filter.key]? businessUnitsByIDs[filter.key].name: filter.key}}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'division'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  {{businessUnitsByIDs[filter.key]? businessUnitsByIDs[filter.key].name: filter.key}}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'team'">
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  {{businessUnitsByIDs[filter.key]? businessUnitsByIDs[filter.key].name: filter.key}}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'next_event'">
                <label
                  for="{{'option_'+i}}"
                  title="{{'EVENTS.' + filter.label | translate }}">
                  {{'EVENTS.' + filter.label | translate }}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'next_decision'">
                <label
                  for="{{'option_'+i}}"
                  title="{{'TENANT.SERVICE_DETAILS.NEXT_DECISION.' + filter.key | translate }}">
                  {{'TENANT.SERVICE_DETAILS.NEXT_DECISION.' + filter.key | translate }}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'alert_status'">
                <label
                  for="{{'option_'+i}}"
                  title="{{'FILTER_TITLES.' + filter.key | translate }}">
                  {{'ALERT_STATUS.' + filter.key | translate }}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'in_term'">
                <label
                  for="{{'option_'+i}}"
                  title="{{'IN_TERM.' + filter.label | translate }}">
                  {{'IN_TERM.' + filter.label | translate }}
                </label>
              </ng-container>
              <ng-container *ngSwitchCase="'data_integrity_status'">
                <label
                  for="{{'option_'+i}}"
                  title="{{'DATA_INTEGRITY_STATUS.' + filter.label | translate }}">
                  {{'DATA_INTEGRITY_STATUS.' + filter.label | translate }}
                </label>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <label
                  for="{{'option_'+i}}"
                  title="{{filter.key}}">
                  <span *ngIf="translatePrefix; else textBlock">{{ translatePrefix + filter.key | translate}}</span>
                  <ng-template #textBlock>{{filter.label? filter.label : filter.key}}</ng-template>
                  <span *ngIf="filter.count">&nbsp;({{filter.count}})</span>
                </label>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    </div>
  </modal-content>
  <modal-footer *ngIf="filterType == 'OPTION' || filterType == 'LOCATION'">
    <div class="cta">
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        {{'GLOBAL.BUTTON_CANCEL' | translate}}
      </button>
      <button (click)="onUpdateFilters()" *ngIf="loaded"
              mi-button
              buttonTheme="dark-theme">
        {{'GLOBAL.BUTTON_APPLY' | translate }}
      </button>
    </div>
  </modal-footer>
</modal>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
