import {Component} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {SEARCH_TYPE_ASSOCIATEDDOCUMENTS, ID_KEY} from "../../../../components/detail-search/detail-search.component";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  templateUrl: './associated-documents-search-events.component.html'
})
export class AssociatedDocumentsSearchEventsComponent {
  readonly SEARCH_TYPE_ASSOCIATEDDOCUMENTS: string = SEARCH_TYPE_ASSOCIATEDDOCUMENTS;
  queryParams: any = {
    "associated_documents[]": ID_KEY
  }
}
