import {Component, OnInit, Output, Input, EventEmitter} from "@angular/core";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import { ModalService} from "@independer/ng-modal";
import { SelectEmployeeModalComponent } from '../select-employee-modal/select-employee-modal.component';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';

@Component({
  selector: 'mi-select-requestor',
  templateUrl: './select-requester.component.html',
  styleUrls: ['./select-requester.component.scss']
})

export class SelectRequestorCommponent extends AbstractPageComponent implements OnInit {

  @Output() selectRequestor: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() tenantUser: TenantUser;

  loaded: boolean = false;

  constructor(
    private modalService: ModalService,
    ) {
  super();
  }

  ngOnInit() {
    this.loaded = true;
  }

  onCancel() {
    this.canceled.emit();
  }

  openEmployeeModal() {
    
    const modalRef = this.modalService.open(SelectEmployeeModalComponent, m => {
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0] && result[0].tenantUser){
        this.tenantUser= result[0].tenantUser;
        this.selectRequestor.emit(this.tenantUser);
      }
    });
  }

  next(){
    this.selectRequestor.emit();
  }

  onRemoveEmployee(){
    this.tenantUser = null;
    this.selectRequestor.emit(this.tenantUser);
  }
}
