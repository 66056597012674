import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../../create/state/reducer';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {UpdateOrderAction} from '../../../create/state/actions';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {LocationModel} from '../../../../../../../shared/models/Location';

@Component({
  selector: 'mi-service-locations',
  styleUrls: ['./service-locations.component.scss'],
  templateUrl: './service-locations.component.html'
})

export class ServiceLocationsComponent extends AbstractPageComponent implements OnInit {

  readonly NO_ADDRESSES: string = '[Service Locations] No Addresses';
  readonly ONE_ADDRESS: string = '[Service Locations] One Address';
  readonly TWO_ADDRESSES: string = '[Service Locations] Two Addresses';

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  radioSelect: string = this.NO_ADDRESSES;
  locationsForm: FormGroup;
  locationOne: LocationModel;
  locationTwo: LocationModel;
  step: string = OrderSteps.Locations;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
  ) {
    super();
  }

  ngOnInit() {

    this.locationsForm = this.formBuilder.group({
      location_one: null,
      location_two: null
    });

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });

  }

  onLocationOneSelected(evt): void {
    console.log('location one for service selected', evt);
    const control: AbstractControl = this.locationsForm.get('location_one');
    this.error = null;
    this.locationOne = evt;
    control.setValue(this.locationOne);
    if (this.locationOne && this.locationTwo) {
      if (this.locationOne.id === this.locationTwo.id) {
        this.locationOne = null;
        this.error = 'Service addresses must be different';
        control.setValue(null);
      }
    }
    control.updateValueAndValidity();
  }

  onLocationTwoSelected(evt): void {
    let control:AbstractControl = this.locationsForm.get('location_two');
    this.error = null;
    this.locationTwo = evt;
    control.setValue(this.locationTwo);
    if(this.locationOne && this.locationTwo){
      if(this.locationOne.id === this.locationTwo.id){
        this.locationTwo = null;
        this.error = "Service addresses must be different";
        control.setValue(null);
      }
    }
    control.updateValueAndValidity();
  }

  onCancelCLicked(): void {
    this.store.dispatch(new UpdateOrderAction(
      {
        index: this.orderState.maxIndex > this.index ? this.orderState.maxIndex : this.index - 1
      }
    ));
  }

  onFormSubmit(data: any) {
    console.log('on service location update', data);

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    let serviceLocations: LocationModel[] = [];
    if (this.radioSelect === this.ONE_ADDRESS) {
      serviceLocations.push(data.location_one);
    } else if (this.radioSelect === this.TWO_ADDRESSES) {
      serviceLocations.push(data.location_one);
      serviceLocations.push(data.location_two);
    }

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          locations: serviceLocations
        }
      }
    ));

  }

  onRadioSelect(evt): void {

    this.radioSelect = evt.target.value;

    const locationOneControl: AbstractControl = this.locationsForm.get('location_one');
    const locationTwoControl: AbstractControl = this.locationsForm.get('location_two');

    this.locationOne = this.locationTwo = null;

    locationOneControl.setValidators([]);
    locationOneControl.setValue(null);
    locationOneControl.markAsUntouched();

    locationTwoControl.setValidators([]);
    locationTwoControl.setValue(null);
    locationTwoControl.markAsUntouched();

    switch (this.radioSelect) {
      case this.NO_ADDRESSES:
        // Nothing.
        break;
      case this.ONE_ADDRESS:
        locationOneControl.setValidators([Validators.required]);
        break;
      case this.TWO_ADDRESSES:
        locationOneControl.setValidators([Validators.required]);
        locationTwoControl.setValidators([Validators.required]);
        break;
    }

    locationOneControl.updateValueAndValidity();
    locationTwoControl.updateValueAndValidity();

  }

  resetLocationOne(): void {
    this.locationOne = null;
    this.locationsForm.get('location_one').setValue(null);
    this.locationsForm.get('location_one').updateValueAndValidity();
  }

  resetLocationTwo(): void {
    this.locationTwo = null;
    this.locationsForm.get('location_two').setValue(null);
    this.locationsForm.get('location_two').updateValueAndValidity();
  }

}
