import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {NO_DATA} from '../../../../../shared/ng-models/SectionDataTable.model';
import {TenantSearchService} from '../../../../../libs/api/tenantSearch/tenantSearch.service';
import {MiServiceSearchResults} from '../../../../../shared/models/SearchResults';
import {TypeSummaryBucket} from '../../../../../shared/ng-models/search/FilterBucket.interface';
import {TenantLocationService} from '../../../../../libs/api/tenant/tenantLocation.service';
import {
  ALERT_CAUSES,
  LOCATION_STATUS_ACTIVE,
  LOCATION_STATUS_INACTIVE,
  LOCATION_STATUSES
} from '../../../../../common/utils/sharedConstants';
import * as AlertReducer from '../../../../../shared/state/alerts/reducers';
import {Store} from '@ngrx/store';
import {AddressV2Service} from '../../../../../libs/api2/address-v2/address-v2.service';
import {AddressV2} from '../../../../../libs/api2/types/AddressV2.interface';
import {LocationV2} from '../../../../../libs/api2/types/LocationV2.interface';

@Component({
  templateUrl: './location-details.component.html'
})

export class LocationDetailComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  readonly ALERT_CAUSES = ALERT_CAUSES;
  address: AddressV2;
  display_id: string;
  error: string = '';
  loaded: boolean = false;
  location: LocationV2;
  addressTableData: any[];

  @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;

  readonly LOCATION_STATUSES = LOCATION_STATUSES;
  readonly LOCATION_STATUS_ACTIVE = LOCATION_STATUS_ACTIVE;
  readonly LOCATION_STATUS_INACTIVE = LOCATION_STATUS_INACTIVE;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantLocationService: TenantLocationService,
              private tenantSearchService: TenantSearchService,
              private tenantAddressV2Service: AddressV2Service,
              private store: Store<AlertReducer.State>
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.loaded = false;
        this.display_id = queryParams.display_id;
        // get the address data
        return this.tenantAddressV2Service.getLocationById(this.display_id);
      })
      .catch(e => this.error = e)
      .subscribe((res: LocationV2) => {
        console.log("location res", res);
        this.location = res;

        const combinedAddress = {
          display_id: this.location.address.id,
          formatted_address: this.location.address.formatted_address,
          location_count: this.location.address.location_count,
          vendor_count: "N/A",
          service_count: this.location.service_count || 0,
          total_mrc: this.location.c_mrc || 0
        };

        this.addressTableData = [combinedAddress];
        this.loaded = true;
      });
      /*
      .switchMap(res => {
        this.location = res;
        // get the locationsSummary
        return this.tenantSearchService.typeSummary({'address_id[]': this.location.address.id});
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((results: MiServiceSearchResults) => {
        // we now have both portions of data.
        const addressBuckets = results.aggregations.address_id.buckets as TypeSummaryBucket[];

        const bucket = addressBuckets.find(_bucket => _bucket.key === this.location.address.id);

        const combinedAddress = {
          display_id: this.location.address.id,
          formatted_address: this.location.address.formatted_address,
          location_count: this.location.address.location_count,
          vendor_count: bucket ? bucket.vendor_count.value : '0',
          service_count: bucket ? bucket.doc_count : '0',
          total_mrc: bucket ? bucket.total_mrc.value : 0
        };

        this.addressTableData = [combinedAddress];
        this.loaded = true;
      });
      */
  }
}
