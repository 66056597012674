<ng-container *ngIf="orderState;else loading">

  <ng-container *ngIf="activeIndex; else static">

    <form [formGroup]="orderDateForm" (submit)="onFormSubmit(orderDateForm.value)">
        <dl class="active">
          <dt>
            When did you order the service?
          </dt>
          <dd>
            <ul class="radio-collection">
                <li>
                  <input type="radio"
                        id="unsure"
                        [value]="dateKnown"
                        [checked]="dateKnown === startDate.UNKNOWN"
                        (change)="onToggleDateKnown(startDate.UNKNOWN)">
                  <label for="unsure">{{'GLOBAL.UNKNOWN' | translate }}</label>
                </li>
                <li>
                  <input type="radio"
                        id="date"
                        [value]="dateKnown"
                        [checked]="dateKnown === startDate.KNOWN"
                        (change)="onToggleDateKnown(startDate.KNOWN)">

                  <label for="date">
                    On This Date
                  </label>

                  <mi-date-picker class="narrow-picker"
                                  (dateChange)="onDateChange($event)"
                                  (click)="onToggleDateKnown(startDate.KNOWN)"
                                  [currentDate]="currentDate">
                  </mi-date-picker>

                </li>
              </ul>
            </dd>
          </dl>
      <div class="button-container">
        <button mi-button
                buttonTheme="dark-theme"
                type="submit"
                [disabled]="!orderDateForm.valid">
          Next
        </button>
      </div>

    </form>

  </ng-container>

  <ng-template #static>
    <dl>
      <dt>Order Date</dt>
      <dd>{{orderState.order_date || NO_DATA}}</dd>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


