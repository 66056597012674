import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { AddressV2, SaveServiceAddress } from '../types/AddressV2.interface';
import {LocationV2} from '../types/LocationV2.interface';
import {CanDelete} from '../../../shared/models/CanDelete';

@Injectable()
export class AddressV2Service {
  private apiUrl = '_MICRO_SERVICE_/address';
  private saveAddressURL = '_MICRO_SERVICE_/upload/service/';
  private tenantUrl = '/{tenant}';

  constructor(private http: HttpClient) {}

  saveAddress(address: AddressV2): Observable<AddressV2> {
    return this.http
      .post(this.apiUrl + '/address', address)
      .map((response: any) => response as AddressV2);
  }

  saveAddressIdsByLocationIds(address: string[]): Observable<any> {
    return this.http
      .post(this.apiUrl + '/locations-address-ids', address)
      .map((response: any) => response);
  }

  saveServiceAddressService(address: SaveServiceAddress[], serviceId: string): Observable<any> {
    return this.http
      .put(this.saveAddressURL + `${serviceId}/properties`, address)
      .map((response: any) => response);
  }

  removeServiceAddressService(serviceId: string, servicePropertyId: string): Observable<any> {
    return this.http.delete(this.saveAddressURL + `${serviceId}/properties?service_property_ids=${servicePropertyId}`)
      .map((response: any) => response);
  }

  getAddressById(id: string): Observable<AddressV2> {
    const data = {
      params: {
        address_id: id
      }
    };
    return this.http
      .get(this.apiUrl + '/address', data)
      .map((response: any) => response as AddressV2);
  }

  getAllAddressByOwner(owner: string, q: string = '', page_number: number = 0, size: number = 10, exclude_props: string = ''): Observable<any> {
    const params = new HttpParams({ fromObject: { owner, q, page_number: page_number.toString(), size: size.toString(), exclude_props } });
    return this.http
      .get(this.apiUrl + '/address-by-owner-service', { params })
      .map((response: any) => response);
  }

  indexAddress(id: string) {
    return this.http
      .put(this.tenantUrl + '/addresses/' + id, {})
      .map((response) => response);
  }

  indexDeleteAddress(id: string) {
    return this.http
      .delete(this.tenantUrl + '/addresses/' + id, {})
      .map((response) => response);
  }

  indexLocation(id: string) {
    return this.http
      .put(this.tenantUrl + '/locations/' + id, {})
      .map((response) => response);
  }

  /*canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlGet}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }*/

  saveGoogleAddress(owner: string, google_place_id: string) {
    return this.http
      .post(this.apiUrl + '/google-address', {
        owner: owner,
        google_place_id: google_place_id
      })
      .map((response: any) => response);
  }

  saveLocation(data) {
    return this.http
      .post(this.apiUrl + '/location', data)
      .map((response: any) => response as LocationV2);
  }

  deleteAddress(id: string, ids: string[]) {
    return this.http
      .post(this.apiUrl + '/delete-address?id=' + id, ids)
        .map((response: any) => response);
  }

  deleteLocations(id: string, ids: string[]) {
    return this.http
      .post(this.apiUrl + '/delete-location?id=' + id, ids)
      .map((response: any) => response);
  }

  getLocationById(id: string) {
    return this.http
      .get(this.apiUrl + '/location?address_location_id=' + id)
      .map((response: any) => response as LocationV2);
  }

  getLocations(id: string) {
    return this.http
      .get(this.apiUrl + '/locations?address_id=' + id)
      .map((response: any) => response);
  }

  canDeleteAddress(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.tenantUrl}/addresses/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }
}
