<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="extra-wide"
  id="parent-group-services">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <mi-server-error [error]="error"></mi-server-error>
    <div class="card px-3 pt-3 pb-1 mb-2 ">
      <div class="w-45">
      <mi-search placeholder="Search for Service"
        qtyMessage="{{ 'SEARCH_QUANTITIES.SERVICES' | translate:{count:total} | translateSelector:total }}"
        (searchTermChanged)="onSearchTermChanged($event)" (clearSearch)="onClearSearch()"></mi-search>
      </div>
        <span *ngIf="selectedServiceDisplayIds.length">
          Selected Services:
          <ng-container *ngFor="let selectedDisplayId of selectedServiceDisplayIds">
            <div class="added-service" (click)="onSelect({target:{checked: false}}, selectedDisplayId)">
              <span class="display-id">
                {{selectedDisplayId}}
              </span>
            </div>
          </ng-container>
        </span>
      </div>
    <div class="search-results card pb-2">
      <div class="scrollableSubTable" *ngIf="(serviceList && serviceList.length); else noContent">
        <mi-sortable-table 
          [rows]="serviceList" 
          [columns]="serviceColumns" 
          variant="SMALL"
          externalClasses="fullWidth">
        </mi-sortable-table>
      </div>
      <mi-loading-animation class="first-load" *ngIf="loadingMore">
      </mi-loading-animation>
      <!--
      <div class="load-more" *ngIf="!loadingMore && (serviceList.length < total)">
        <a href="#" class="edit-link button dark-theme" *ngIf="(page*pageSize) <= total" (click)="loadMoreClicked($event)">Load
          More</a>
      </div>
      -->
      <div class="load-more" *ngIf="!loadingMore">
        <a href="#" class="edit-link button dark-theme" *ngIf="hasLoadMore" (click)="loadMoreClicked($event)">Load
          More</a>
      </div>
      <ng-template #noContent>
        <div *ngIf="resultsLoaded && !loadingMore">No service found</div>
      </ng-template>
    </div>
    </modal-content>
    <modal-footer>
    <div class="button-container">
      <button mi-button buttonTheme="light-theme" (click)="close()">Cancel</button>
      <button mi-button buttonTheme="dark-theme" (click)="onAddServices()"
        [disabled]="selectedServiceDisplayIds.length < 1 || addingServicesInProgress">Add Services <mi-loading-button *ngIf="addingServicesInProgress"></mi-loading-button></button>
    </div>
  </modal-footer>
</modal>


<ng-template #checkboxTpl let-row="row" let-value="value">
  <label>
    <input 
      type="checkbox" 
      value="{{value}}" 
      [disabled]="value===serviceDetails.general.parent_service_display_id"
      (change)="onSelect($event, value)" 
      [checked]="selectedServiceDisplayIds.indexOf(value)+1>0">
    {{row.display_id}}
  </label>

</ng-template>