import {Component, Input} from '@angular/core';

@Component({
  selector: 'mi-section-title',
  styleUrls: ['./section-title.component.scss'],
  templateUrl: './section-title.component.html'
})

export class SectionTitleComponent{

  @Input() title: string;
  @Input() headlineLevel: number = 2;

}
