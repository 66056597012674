<div class="service-details-wrap" *ngIf="miService && miService.product">
  <div class="service-details" *ngIf="(miService.equipments_v2 && !miService.equipments_v2.length) || !miService.equipments_v2">
    <div class="service-details--column">
      <mi-server-error [error]="error"></mi-server-error>
      <table>
        <tr>
          <td class="key">Product</td>
          <td class="product">{{miService.product.product_name}}
            <ng-container *ngIf="miService.product.product_name !== miService.product.actual_product.product_name">
              <br>
              <span class="small">{{miService.product.actual_product.product_name}}</span>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td class="key">Service ID</td>
          <td>{{miService.service_identifier || NO_DATA}}</td>
        </tr>
        <tr>
          <td class="key">Assignee</td>
          <td>
            <a href="mailto:{{miService.assigned_email}}">{{miService.assigned_email}}</a>
          </td>
        </tr>
        <tr>
          <td class="key">Service Category</td>
          <td>
            {{miService.product.product_category.category_name || NO_DATA}}
          </td>
        </tr>
        <ng-container [ngSwitch]="miService.data_integrity.overall_status">
          <tr *ngSwitchCase="DATA_INTEGRITY_INCOMPLETE">
            <td class="key">
              {{'SERVICE_DETAILS.MISSED_SAVINGS_TITLE' | translate}}
            </td>
            <td>
              <ng-container
                *ngIf="miService.savings.missed.to_savings !== 0; else zeroSavings">
                {{miService.savings.missed.to_savings | round | currency:'USD':'symbol':'1.00'}}
              </ng-container>
              <ng-template #zeroSavings>
                {{miService.savings.missed.from_savings | currency:'USD':'symbol':'1.00'}}
              </ng-template>
            </td>
          </tr>
          <tr *ngSwitchDefault>
            <td class="key">
              {{'SERVICE_DETAILS.POTENTIAL_SAVINGS_TITLE' | translate}}
            </td>
            <td>
              <ng-container
                *ngIf="miService.savings.potential.to_savings !== 0; else zeroSavings">
                {{miService.savings.potential.to_savings | round | currency:'USD':'symbol':'1.00'}}
              </ng-container>
              <ng-template #zeroSavings>
                {{miService.savings.potential.from_savings | currency:'USD':'symbol':'1.00'}}
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="service-details--column">
      <table>
        <tr>
          <td class="key">
            {{'LOCATIONS_QTY'
            | translate:{count: miService.locations.length}
            | translateSelector: miService.locations.length}}
          </td>
        </tr>

        <tr>
          <td>
            <p *ngIf="(miService.locations.length === 0)">{{NO_DATA}}</p>
            <div class="address" *ngFor="let location of miService.locations_v2">
              <mi-simple-location
                [location]="location"
                [compact]="true">
              </mi-simple-location>
              <!--{{ location.address != null ? location.address.formatted_address : '' }}
              <ng-container *ngIf="location.label !== 'Default'">
                , {{location.label}}
              </ng-container>-->
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="service-details--column">
      <table>

        <tr>
          <td class="key">Data Integrity</td>
          <td>
            {{ 'TENANT.SERVICE_DETAILS.STATUS.' + miService.data_integrity.overall_status | translate}}
          </td>
        </tr>

        <ng-container *ngIf="withViewDetails === false && miService.current_notification.status !== SERVICE_ARCHIVED">
          <tr>
            <td class="key">Next Event</td>
            <td [class]="severity">

              <ng-container
                *ngIf="miService.current_notification && miService.current_notification.notification_event_type; else unknown">
                {{'EVENTS.' + miService.current_notification.notification_event_type | translate}} <br>
                {{ 'NATURAL_DAYS' | translate:{count: miService.current_notification.notification_days_until_event | abs} |
                translateSelector:miService.current_notification.notification_days_until_event}}
              </ng-container>

              <ng-template #unknown>
                {{'GLOBAL.UNKNOWN' | translate }}
              </ng-template>

            </td>
          </tr>
        </ng-container>

        <tr>
          <td class="key">{{ 'GLOBAL.CALCULATED_MRC' | translate }}</td>
          <td>
            {{ miService.mrc | round | currency:'USD':'symbol':'1.00'}}
          </td>
        </tr>

        <tr>
          <td class="key">{{ 'GLOBAL.QUANTITY' | translate }}</td>
          <td>
            {{ miService.current_financial.quantity || NO_DATA}}
          </td>
        </tr>

        <tr class="parent-service"  *ngIf="miService.parent_service || miService.current_notification.status !== SERVICE_ARCHIVED">
          <td class="key">{{ 'GLOBAL.PARENT_SERVICE' | translate }}</td>
          <td>
            <ng-container *ngIf="miService.parent_service;else noParentService">
              <mi-id-link id="{{miService.parent_service.display_id}}"
                          [title]="miService.parent_service.display_id"></mi-id-link>
            </ng-container>
            <ng-template #noParentService>
              <a routerLink="/parent-services/create/"
                 [queryParams]="{s: miService.display_id}">{{ 'GLOBAL.CREATE_PARENT_SERVICE_CTA' | translate }}</a>
            </ng-template>
          </td>
        </tr>

      </table>
    </div>

  </div>

  <div class="service-details" *ngIf="miService.equipments_v2 && miService.equipments_v2.length">
    <div class="service-details--column">
      <table>
        <tr>
          <td class="key">
            Equipments Model
          </td>
          <td>{{miService.equipments_v2[0].equipment_catalog.category_name}}</td>
        </tr>
        <tr>
          <td class="key">
            Serial No.
          </td>
          <td >{{miService.equipments_v2[0].serial}}</td>
        </tr>
        <tr>
          <td class="key">
            Service
          </td>
          <td class="product">{{miService.product.product_name}}
            <ng-container *ngIf="miService.product.product_name !== miService.product.actual_product.product_name">
              <br>
              <span class="small">{{miService.product.actual_product.product_name}}</span>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td class="key">
            Service Category
          </td>
          <td>{{miService.product.product_category.category_name || NO_DATA}}</td>
        </tr>
        <tr>
          <td class="key">
            Subscriber
          </td>
          <td>
            {{miService.equipments_v2[0].contact?.full_name || NO_DATA}}
          </td>
        </tr>
      </table>
    </div>
    <div class="service-details--column">
      <table>
        <tr>
          <td class="key">
            Location
          </td>
        </tr>

        <tr>
          <td>
            <p *ngIf="!miService.equipments_v2[0].location">{{NO_DATA}}</p>
            <div *ngIf="miService.equipments_v2[0].location && miService.equipments_v2[0].location.address">
                <mi-simple-location
                  [location]="miService.equipments_v2[0].location"
                  [compact]="true">
                </mi-simple-location>

              <!-- {{miService.equipments_v2[0].location.address.formatted_address}}
              <ng-container *ngIf="miService.equipments_v2[0].location && miService.equipments_v2[0].location.label !== 'Default'">
                , {{miService.equipments_v2[0].location?.label}}
              </ng-container> -->
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="service-details--column">
      <table>

        <tr>
          <td class="key">Data Integrity</td>
          <td>
            {{ 'TENANT.SERVICE_DETAILS.STATUS.' + miService.data_integrity.overall_status | translate}}
          </td>
        </tr>

        <ng-container *ngIf="withViewDetails === false && miService.current_notification.status !== SERVICE_ARCHIVED">
          <tr>
            <td class="key">Next Event</td>
            <td [class]="severity">

              <ng-container
                *ngIf="miService.current_notification && miService.current_notification.notification_event_type; else unknown">
                {{'EVENTS.' + miService.current_notification.notification_event_type | translate}} <br>
                {{ 'NATURAL_DAYS' | translate:{count: miService.current_notification.notification_days_until_event | abs} |
                translateSelector:miService.current_notification.notification_days_until_event}}
              </ng-container>

              <ng-template #unknown>
                {{'GLOBAL.UNKNOWN' | translate }}
              </ng-template>

            </td>
          </tr>
        </ng-container>

        <tr>
          <td class="key">Event Value</td>
          <td>
            {{ miService.financial_commitment | round | currency:'USD':'symbol':'1.00'}}
          </td>
        </tr>

        <tr>
          <td class="key">Reseller</td>
          <td>
            {{miService.purchasing_vendor.vendor_name}}
          </td>
        </tr>

        <tr class="parent-service"  *ngIf="miService.parent_service || miService.current_notification.status !== SERVICE_ARCHIVED">
          <td class="key">{{ 'GLOBAL.PARENT_SERVICE' | translate }}</td>
          <td>
            <ng-container *ngIf="miService.parent_service;else noParentService">
              <mi-id-link id="{{miService.parent_service.display_id}}"
                          [title]="miService.parent_service.display_id"></mi-id-link>
            </ng-container>
            <ng-template #noParentService>
              <a routerLink="/parent-services/create/"
                  [queryParams]="{s: miService.display_id}">{{ 'GLOBAL.CREATE_PARENT_SERVICE_CTA' | translate }}</a>
            </ng-template>
          </td>
        </tr>

      </table>
    </div>

  </div>

  <a class="view-details" *ngIf="withViewDetails" routerLink="/services/{{miService.display_id}}">View Details</a>
</div>

