import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServiceV2FilterRequest, ServiceV2FilterResponse, ServiceV2LocationFilterResponse, ServiceV2Request, ServiceV2Response } from "src/shared/models/ServiceV2";

@Injectable()
export class ServiceV2Service {
  private apiUrl = '_MICRO_SERVICE_/upload';
  private apiUrlGetServiceDetails = '_MICRO_SERVICE_/upload/service/details';
  private apiUrlGetServiceCalculationDetails = '_MICRO_SERVICE_/calculation/service';
  private apiUrlGetCountries = '_MICRO_SERVICE_/upload/service/filter/locations';
  private apiGetServiceDetailsByTenantIdAndDisplayId = '_MICRO_SERVICE_/upload/service/info';
  private apiGetServiceVendorCustomFieldsByIds = '_MICRO_SERVICE_/vendor/tenant/bridges/custom-fields';
  private apiServicePlanCancelation = '_MICRO_SERVICE_/upload/service/cancel';
  private apiServiceDelete = '_MICRO_SERVICE_/upload/service/remove';
  private MiServiceOwnership = '_MICRO_SERVICE_/upload/service/ownership/';



  private apiServiceDates = '_MICRO_SERVICE_/upload/service/dates/update';
  private apiServiceEconomics = '_MICRO_SERVICE_/upload/service/economics/update';
  private saveServiceProperties = '_MICRO_SERVICE_/upload/service/';


  constructor(private http: HttpClient) {}

  getServices(request: ServiceV2Request): Observable<ServiceV2Response> {
    return this.http
      .post(`${this.apiUrl}/service/list/cursor`, request)
      .map((response: any) => response as ServiceV2Response);
  }

  getTotalCmrc(request: ServiceV2Request): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/service/total/cmrc`, request)
      .map((response: any) => response as any);
  }

  getFilters(request: ServiceV2FilterRequest): Observable<ServiceV2FilterResponse> {
    return this.http
      .post(`${this.apiUrl}/service/filter`, request)
      .map((response: any) => response as ServiceV2FilterResponse);
  }
  
  getLocationFilters(request: ServiceV2FilterRequest): Observable<ServiceV2LocationFilterResponse> {
    return this.http
      .post(`${this.apiUrl}/service/filter/location`, request)
      .map((response: any) => response as ServiceV2LocationFilterResponse);
  }

  getCountriesFilters(tenantId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlGetCountries}/?tenant_id=${tenantId}`)
      .map((response: any) => {
        return response;
      });
  }

  getServiceDetails(serviceId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlGetServiceDetails}/${serviceId}`)
      .map((response: any) => {
        return response;
      });
  }

  getServiceDetailsWithServiceDisplayIdAndTenantId(serviceDisplayId: string, tenant_id: string): Observable<any> {
    const data = {
      params: {
        tenant_id: tenant_id
      }
    };
    return this.http
      .get(`${this.apiUrlGetServiceDetails}/${serviceDisplayId}`, data)
      .map((response: any) => {
        return response;
      });
  }

  getServiceCalculationDetails(serviceId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlGetServiceCalculationDetails}/${serviceId}`)
      .map((response: any) => {
        return response;
      });
  }

  getServiceDetailsByTenantIdAndDisplayId(display_id: string, tenantId: string): Observable<any> {
    return this.http
      .get(`${this.apiGetServiceDetailsByTenantIdAndDisplayId}?display_id=${display_id}&tenant_id=${tenantId}`)
      .map((response: any) => {
        return response;
      });
  }

  getServiceVendorCustomFieldsByIds(customFields: any): Observable<any> {
    return this.http
      .get(`${this.apiGetServiceVendorCustomFieldsByIds}?ids=${customFields}`)
      .map((response: any) => {
        return response
      });
    }
    
  servicePlanCancelation(service_id: string, termination_date: string): Observable<any> {
    return this.http
      .put(`${this.apiServicePlanCancelation}/${service_id}`, { termination_date: termination_date })
      .map((response: any) => {
        return response;
      });
  }

  saveServiceOwnershipService(data: any, serviceId: string): Observable<any> {
    return this.http
      .put(this.saveServiceProperties + `${serviceId}/properties`, data)
      .map((response: any) => response);
  }

  miUserUpdateServiceOwnership(serviceId: string, user_id: string): Observable<any> {
    const data = {
      "user_id": user_id
    }
    return this.http
      .post(this.MiServiceOwnership + `${serviceId}`, { ...data })
      .map((response: any) => {
        return response;
      });
  }

  miUserRemoveServiceOwnership(serviceId: string): Observable<any> {
    return this.http
      .delete(this.MiServiceOwnership + `${serviceId}`)
      .map((response: any) => {
        return response;
      });
  }

  serviceDelete(tenant_id: string, service_id: string): Observable<any> {
    const data = {
      "display_id": service_id,
      "tenant_id": tenant_id
    }
    return this.http
      .post(`${this.apiServiceDelete}`, { ...data })
      .map((response: any) => {
        return response;
      });
  }

  updateServiceDates(serviceDates: any, serviceId: string): Observable<any> {
    const paramData = {
      "service_end_date": serviceDates.end_date,
      "service_start_date": serviceDates.start_date,
      "service_term_length": serviceDates.term_length
    }
    return this.http
      .put(`${this.apiServiceDates}/${serviceId}`, { ...paramData })
      .map((response: any) => {
        return response;
      });
  }

  updateServiceEconomics(serviceEconomics: any, serviceId: string): Observable<any> {
    const paramData = {
      billing_frequency: serviceEconomics.billing_frequency,
      service_quantity: serviceEconomics.quantity,
      unit_non_recurring_cost: serviceEconomics.non_recurring_cost,
      unit_recurring_cost: serviceEconomics.cost,
      currency_code: serviceEconomics.currency_code,
    }
    return this.http
      .put(`${this.apiServiceEconomics}/${serviceId}`, { ...paramData })
      .map((response: any) => {
        return response;
      });
  }
}
