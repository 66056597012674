import {Component} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import { ID_KEY, SEARCH_TYPE_ACCOUNT_IDS } from '../../../../components/detail-search/detail-search.component';

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  templateUrl: './vendor-account-id-search-events.component.html'
})
export class VendorAccountIdSearchEventsComponent {
  readonly SEARCH_TYPE_ACCOUNT_IDS: string = SEARCH_TYPE_ACCOUNT_IDS;
  queryParams: any = {
    "account_id[]": ID_KEY
  }
}
