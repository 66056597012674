import { AbstractPageComponent } from '../../../pages/abstract-page/abstract-page.component';

import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { Product } from '../../../models/Product';
import { Vendor } from '../../../models/Vendor';
import { ProductCategory } from '../../../models/ProductCategory';
import { ProductSearchResults } from "../../../models/SearchResults";
import { ProductSearchQueryParams } from "../../../ng-models/search/SearchQueryParams.interface";
import { ProductCategoryFieldProperty } from '../../../models/ProductCategoryFieldProperty';


import { Subject } from "rxjs";
import { ProductServicesModes } from '../../../models/ProductServices.interface';
import { SortableTableColumn } from '../../../ng-models/SortableTableColumn.interface';
import { TenantProductsService } from '../../../../libs/api/tenant/tenantProducts';
import { SearchProductsService } from '../../../../libs/api/interfaces/SearchProductsService.interface';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';

@Component({
  selector: 'mi-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})

export class ProductSearchComponent extends AbstractPageComponent implements OnInit {

  @Input() vendor: Vendor;
  @Input() dummyProduct: Product;
  @Input() productServicesMode: ProductServicesModes = ProductServicesModes.TENANT;
  @Input() locked: boolean = false;
  @Input() theme: string = '';
  @Input() defaultSearchText: string = "";

  @Output() onUnSelectVendor: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selecteProduct: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() addedProduct: EventEmitter<Product> = new EventEmitter<Product>();

  selectedProduct: Product;
  generalInformationTableColumns: Array<SortableTableColumn>;

  @ViewChild('categoryDetailTpl', {static: true}) categoryDetailTpl: TemplateRef<any>;
  @ViewChild('propertiesTpl', {static: true}) propertiesTpl: TemplateRef<any>;
  @ViewChild('productNameTpl', {static: true}) productNameTpl: TemplateRef<any>;
  @ViewChild('misoNormalizedProductNameTpl', {static: true}) misoNormalizedProductNameTpl: TemplateRef<any>;

  normalizingProductError: any = '';
  normalizingProductLoad: boolean = false;

  productFindError: string = "";
  showAddProduct: boolean = false;

  allCategories: Array<ProductCategory> = [];
  categories: Array<any> = [];

  resultsLoadedFirstTime: boolean = false;
  resultsLoaded: boolean = false;
  resultsError: any = '';
  results: ProductSearchResults;
  hits: Array<Product> = [];
  lastQuery: ProductSearchQueryParams = {};
  total: number = 0;
  clicked: string;
  page: number = 1;
  pageSize: number = 5;
  searchTimeout: any;

  searchInput = new Subject<string>();
  searchProductsService: SearchProductsService;

  productSearch: string = "";
  pageNumber: number = 0;

  constructor(
    private tenantSearchProductsService: TenantProductsService,
    private tenantVendors: TenantVendors) {
    super();
  }

  ngOnInit(): void {

  if(this.vendor) {
    this.vendor._id = this.vendor.id;
    this.vendor.tenant_vendor_id = this.vendor.tenant_vendor_id ? this.vendor.tenant_vendor_id : this.vendor._id; // this expecting tenant vendor tenant_vendor_id
  }

    if(this.dummyProduct == null){
      this.dummyProduct = {...new Product(), vendor_id: this.vendor._id, product_vendor: this.vendor};
    }
    this.dummyProduct.product_name = this.defaultSearchText;

    this.generalInformationTableColumns = [
      {
        name: 'MISO ID',
        prop: 'display_id',
        sortable: false,
        hidden: this.productServicesMode === ProductServicesModes.MIYAGI
      },
      {
        name: 'Product',
        cellTemplate: this.productNameTpl,
        sortable: false,
      },
      {
        name: 'Category',
        prop: 'product_category.category_name',
        cellTemplate: this.productServicesMode === ProductServicesModes.MIYAGI ? this.categoryDetailTpl : null,
        sortable: false,
      },
      /*
      {
        name: 'Normalized',
        cellTemplate: this.misoNormalizedProductNameTpl,
        sortable: false,
        hidden: this.productServicesMode === ProductServicesModes.MIYAGI
      },
      */
      {
        name: 'Properties',
        //prop: 'product_fields',
        cellTemplate: this.propertiesTpl,
        sortable: false
      }
    ] as Array<SortableTableColumn>;

    /*
    const defaultQuery: ProductSearchQueryParams = {};
    //console.log("product-search component this.vendor", this.vendor);

    if (this.vendor) {
      //defaultQuery.product_vendor_actual_vendor_id = [this.vendor.actual_vendor_id];
      defaultQuery.product_vendor_actual_vendor_id = [this.vendor._id];
    }

    if(this.defaultSearchText){
      defaultQuery.q = this.defaultSearchText;
    }

    this.searchInput.pipe(debounceTime(150))
      .subscribe(q => {
        this.fetchResults({ q });
      });

    this.fetchResults(defaultQuery);
    */

    this.productSearch = this.defaultSearchText;
    
    this.getProducts();
  }

  getProducts(pageNumber: number = 0): void {
    this.pageNumber = pageNumber;
    this.tenantVendors.tenantVendorProducts(this.productSearch, this.pageNumber, this.pageSize, this.vendor.tenant_vendor_id)
    .subscribe(products => {
      if (this.pageNumber > 0) {
        this.hits.push(...products.data);
      } else {
        this.hits = [...products.data];
      }

      // if(this.hits.length === 0 && !this.productSearch){
      //   this.showAddProduct = false;
      // }

      this.total = products.total;
      this.clicked = "";

      this.resultsLoadedFirstTime = true;
      this.resultsLoaded = true;
    });
  }

  onSearchTermChanged(q) {
    /*
    if((this.lastQuery.q || '') === (q || '')) return;
    this.searchInput.next(q);
    */
    console.log("here q", q)

    this.productSearch = q;
    this.dummyProduct.product_name = q;
    this.getProducts();
  }

  onClearSearch() {
    this.productSearch = "";
    this.getProducts();
  }

  filterProductByCategory(category) {
    console.log("filterProductByCategory");
    //this.fetchResults({ product_category_name: [(category || {}).value] });
  }

  loadMoreClicked($event) {
    $event.preventDefault();
    //this.fetchResults(this.lastQuery, this.page + 1);
    this.getProducts((this.pageNumber + 1));
  }

  /*
  fetchResults(query: ProductSearchQueryParams = {}, page: number = 1) {
    //debugger;
    this.resultsLoaded = false;
    this.resultsError = false;
    this.page = page;
    let size = this.pageSize;
    let from = (page > 1 ? (this.pageSize * (page - 1)) : 0);

    const options = { from: 0, size: this.pageSize };

    if (page > 1) {
      options.from = this.pageSize + ((page - 2) * 10);
      options.size = 10;
    }


    let newQuery = {
      ...this.lastQuery,
      ...query,
      ...options
    };
    //debugger;
    this.searchProductsService.searchProducts(newQuery)
      .takeUntil(this.destroy$)
      .subscribe(results => {
        this.resultsLoadedFirstTime = true;
        this.resultsLoaded = true;
        this.results = results;
        //debugger;
        if (page > 1) {
          this.hits.push(...results.hits);
        } else {
          this.hits = results.hits;
        }
        if(this.hits.length === 0 && !newQuery.q){
          this.showAddProduct=true;
        }

        this.total = results.total.value;
        this.clicked = "";

        this.lastQuery = newQuery as ProductSearchQueryParams;

        if ((!this.categories || this.categories.length == 0) && results.aggregations["product_category_name"]) {
          let data = this.buildFlatArray(results.aggregations["product_category_name"].buckets);
          this.categories = this.buildHierarchy(data);
        }
      },
        (e => {
          this.resultsLoaded = true;
          this.resultsError = e
        })
      );

  }
  */

  buildFlatArray(arry) {
    let data = arry.map(item => {
      let d = item.key.split("~~##~~");
      return { Id: d[0], label: d[0], value: item.key, Parent: d.length > 0 ? d[1] || "" : "" };
    });
    return data;
  }

  buildHierarchy(arry) {

    var roots = [], children = {};

    // find the top level nodes and hash the children based on parent
    for (var i = 0, len = arry.length; i < len; ++i) {
      var item = arry[i],
        p = item.Parent,
        target = !p ? roots : (children[p] || (children[p] = []));

      target.push({ ...item });
      target.sort();
    }

    // function to recursively build the tree
    var findChildren = (parent) => {
      if (children[parent.Id]) {
        parent.children = children[parent.Id];
        for (var i = 0, len = parent.children.length; i < len; ++i) {
          findChildren(parent.children[i]);
        }
      }
    };

    // enumerate through to handle the case where there are multiple roots
    for (var i = 0, len2 = roots.length; i < len2; ++i) {
      findChildren(roots[i]);
    }

    return this.recursiveFlatten(roots);
  }

  recursiveFlatten(categories: any[], depth: number = 0): any[] {
    //{Id, label, value, Parent}
    const cats: any[] = [];
    if (categories.length) {
      //there are children here.
      categories.forEach(cat => {
        cat.level = depth;
        cats.push(cat);
        if (cat.children) {
          cats.push(...this.recursiveFlatten(cat.children, depth + 10))
        }
      })
    }
    return cats;
  }

  onAddProductCancel() {
    if(this.hits.length === 0){
      if(!this.defaultSearchText){
        this.onUnSelectSearchVendor(false);
      }
      this.onClearSearch();
    }else{
      this.defaultSearchText = this.lastQuery.q;
    }
    this.showAddProduct = false;
  }

  onAddComplete($event) {
    console.log("onAddComplete", $event);
    this.showAddProduct = false;
    this.selectedProduct = $event;
    //this.fetchResults(this.lastQuery);

    this.selecteProduct.emit($event);
  }

  onProductSelect($event) {
    //console.log("onProductSelect", $event);
    //console.log("hits", this.hits);
    this.normalizingProductError = '';
    this.normalizingProductLoad = false;

    if ($event.target.checked) {
      this.selectedProduct = this.hits.find(
        (item: Product): boolean => {
          //return item._id === $event.target.value;
          return item.id === $event.target.value;
        });
    }
    else {
      this.selectedProduct = null;
    }

    if(
      this.productServicesMode ===  ProductServicesModes.TENANT &&
      this.selectedProduct &&
      !this.selectedProduct.display_id
      ) {
       // we need to normalize.
      this.normalizingProductLoad = true;
      this.tenantSearchProductsService.createNormalizedProduct(this.selectedProduct.actual_product_id, this.vendor._id)
        .then(product=> {
          this.selectedProduct = product;
          this.selecteProduct.emit(this.selectedProduct);
          this.normalizingProductLoad = false;
        })
        .catch(e => {
          this.normalizingProductError = e;
          this.normalizingProductLoad = false;
        })
    } else {
      this.selecteProduct.emit(this.selectedProduct);
    }
  }

  getProductProperties(fields: ProductCategoryFieldProperty[]) {
    let props = [];
    if (fields && fields.length > 0) {
      props = fields.map(item => {
        //return item.product_field_property.product_field.label + ": " + this.getFieldValue(item);
        return item.label + ": " + (item.value?item.value:"") + " " + (item.unit?item.unit:"");
      });
    }
    return props.join(", ");
  }

  getFieldValue(prop: ProductCategoryFieldProperty): any {
    if (prop.product_field_property.product_field.property_type == "BANDWIDTH_SPEED" && prop.value)
      return prop.value.bandwidth || ""
    else
      return prop.value || ""
  }

  onUnSelectSearchVendor($event) {
    this.onUnSelectVendor.emit(null);
  }

  createNewButton(){
    this.showAddProduct=true;
    this.defaultSearchText = "";
  }

}
