<ng-container>
  <div *ngIf="contact && contact.id" class="contact" [ngClass]="{'locked':!locked}">
    <div class="contact-info">
      <div class="contact-details">
        <div class="contact-name">
          {{contact.first_name}} {{contact.last_name}}
        </div>
        <div class="contact-email">
          {{contact.email}}
        </div>
      </div>
      <div class="contact-company"  *ngIf="!locked">
        {{contact.vendor.vendor_name}} / {{ 'CONTACT_ROLES.'+ contact.role | translate}}
      </div>
    </div>

    <div class="contact-delete" (click)="onDeleteContact()" *ngIf="locked">

    </div>

  </div>
</ng-container>
