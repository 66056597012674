<section>

  <ng-container *ngIf="serviceDetails; else loading">
    <div class="service-details">
      <ul class="service-terms">
        <ng-container *ngFor="let item of buttonTypes">
          <ng-container [ngSwitch]="item">
            <!-- <ng-container *ngSwitchCase="1">
              <li [ngClass]="{'disabled':formOpen}" (click)="onConfirmActivationClick()">
                <div class="title">
                  <a class="link">
                    <div class="link link--confirm-activation"></div>
                    Confirm As Active
                  </a>
                </div>
              </li>
            </ng-container> -->
            <!-- <ng-container *ngSwitchCase="2">
              <li [ngClass]="{'disabled':formOpen}" (click)="onRetermClicked()">
                <div class="title">
                  <a class="link">
                    <div class="link link--request-re-term"></div>
                    Plan Re-Term
                  </a>
                </div>
              </li>
            </ng-container> -->
            <!-- <ng-container *ngSwitchCase="3">
              <li [ngClass]="{'disabled':formOpen}" (click)="onRetermClicked()">
                <div class="title">
                  <a class="link">
                    <div class="link link--request-re-term"></div>
                    Plan Re-Term
                  </a>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="4">
              <li [ngClass]="{'disabled':formOpen}" (click)="onRetermClicked()">
                <div class="title">
                  <a class="link">
                    <div class="link link--edit-re-term"></div>
                    Edit Re-Term
                  </a>
                </div>
              </li>
            </ng-container> -->
            <!-- <ng-container *ngSwitchCase="5">
              <li [ngClass]="{'disabled':formOpen}" (click)="onCancelReterm()">
                <div class="title">
                  <a class="link">
                    <div class="link link--cancel-re-term"></div>
                    {{ 'TENANT.SERVICE_DETAILS.CTA.CANCEL_RE_TERM' | translate}}
                  </a>
                </div>
              </li>
            </ng-container> -->
            <ng-container *ngSwitchCase="1">
              <li [ngClass]="{'disabled':formOpen}" (click)="onConfirmCancellationClicked(confirmCancellationModal)">
                <div class="title">
                  <a class="link">
                    <div class="link link--confirm-edit-cancellation"></div>
                    Edit Cancellation
                  </a>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <li [ngClass]="{'disabled':formOpen}" (click)="onRemoveCancellationClick(removeCancellationModal)">
                <div class="title">
                  <a class="link">
                    <div class="link link--request-cancellation"></div>
                    Remove Cancellation
                  </a>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="3">
              <li [ngClass]="{'disabled':formOpen}" (click)="onCancelClicked(planCancellationModal)">
                <div class="title">
                  <a class="link">
                    <div class="link link--request-cancellation"></div>
                    Plan Cancellation
                  </a>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="4">
              <li [ngClass]="{'disabled':formOpen}" (click)="onSeeDetails(fixServiceModal)">
                <div class="title">
                  <a class="link">
                    <div class="link link--data-integrity"></div>
                    Fix Your Service
                  </a>
                </div>
              </li>
            </ng-container>
            <!-- <ng-container *ngSwitchCase="10">
              <li [ngClass]="{'disabled':formOpen}" (click)="onGroupClicked()">
                <div class="title">
                  <a class="link">
                    <div class="link link--edit-group"></div>
                    Service Group
                  </a>
                </div>
              </li>
            </ng-container> -->
            <ng-container *ngSwitchCase="5">
              <li routerLink="/services/{{serviceDetails.general.parent_service_display_id}}">
                <div class="title">
                  <a class="link">
                    <div class="link link--request-re-term"></div>
                    Go to Parent Service {{serviceDetails.general.parent_service_display_id}}
                  </a>
                </div>
              </li>
            </ng-container>
            <ng-container *ngSwitchCase="6">
              <li (click)="deleteServiceHandler(deleteServiceModal)">
                <div class="title">
                  <a class="link">
                    <div class="link link--trash"></div>
                    Delete Service
                  </a>
                </div>
              </li>
            </ng-container>
            <!-- <ng-container *ngSwitchCase="6">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          <li routerLink="/services/{{serviceDetails.general.service_display_id }}/delete">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <div class="title">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <a class="link">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <div class="link link--trash"></div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                Delete Service
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </a>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </ng-container> -->
            <!-- <ng-container *ngSwitchDefault>
              <li class="link future-action">
                <div class="title">
                  <a class="link">
                    <div class="link link--future-action"></div>
                    Future Action
                  </a>
                </div>
              </li>
            </ng-container> -->
          </ng-container>
        </ng-container>
      </ul>

    </div>
    <mi-detail-alert [display_id]="serviceDetails.general.service_display_id" [self_alert]="true"></mi-detail-alert>
    <mi-detail-alert [display_id]="serviceDetails.general.service_display_id"></mi-detail-alert>

  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</section>



<div class="form-container" *ngIf="formOpen">

  <!-- <div id="ActivationForm" class="inline-form" *ngIf="selectedForm === FORM_TYPES.ACTIVATION_FORM">
    <mi-opacity-loader [ready]="!updating">
      <h2>{{ 'TENANT.SERVICE_DETAILS.CONFIRM_ACTIVATION_HEADLINE' | translate }}</h2>
      <ng-container *ngIf="confirmationFormGroup">
        <form [formGroup]="confirmationFormGroup" (submit)="onActivationFormSubmit(confirmationFormGroup.value)">
          <div>
            <mi-extended-input labelText="Activation Date" class="service-details" [labelRequired]="true"
              [inputControl]="confirmationFormGroup.controls['activation_date']"
              [inputErrors]="confirmationFormGroup.controls['activation_date'].errors"
              [inputTouched]="confirmationFormGroup.controls['activation_date'].touched">
              <mi-date-picker [currentDate]="confirmationFormGroup.get('activation_date').value"
                [maxDate]="service.current_financial.start_date" (dateChange)="onActivationDateChanged($event)">
              </mi-date-picker>
            </mi-extended-input>
          </div>
          <div class="button-container">
            <button mi-button buttonTheme="light-theme" (click)="onConfirmActivationCancelClicked()">
              {{'TENANT.SERVICE_DETAILS.CTA.CANCEL_WITHOUT_MODIFICATION' | translate }}
            </button>
            <button mi-button buttonTheme="dark-theme" [disabled]="confirmationFormGroup.invalid" type="submit">
              {{ 'TENANT.SERVICE_DETAILS.CTA.SAVE' | translate }}
            </button>
          </div>
        </form>
      </ng-container>
      </mi-opacity-loader>
  </div> -->



  <!-- <div id="ConfirmCancellationForm" class="inline-form" *ngIf="selectedForm === FORM_TYPES.CONFIRM_CANCELLATION_FORM">
    <mi-service-details-form-confirm-cancellation-request (canceled)="onFormCancelClicked()" [miService]="service">
    </mi-service-details-form-confirm-cancellation-request>
  </div> -->

  <div id="RetermForm" class="inline-form reterm" *ngIf="selectedForm === FORM_TYPES.RETERM_FORM">
    <mi-reterm-form (retermUpdated)="onFormCancelClicked()" (canceled)="onFormCancelClicked()">
    </mi-reterm-form>
  </div>

  <!-- <div id="GroupForm" class="inline-form-group" *ngIf="selectedForm === FORM_TYPES.GROUP_FORM">
    <mi-group-form title="Service Groups" (dataUpdate)="onFormCancelClicked()" (canceled)="onFormCancelClicked()"
      [editable]="!serviceStatus.isArchived">
    </mi-group-form>
  </div> -->

</div>
<modal #planCancellationModal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{ 'TENANT.SERVICE_DETAILS.REQUEST_CANCEL_SERVICE' | translate }}</h4>
  </modal-header>
  <modal-content>
    <mi-server-error [error]="error"></mi-server-error>
    <mi-service-details-form-cancellation-request [serviceDetails]="serviceDetails"
      (canceled)="onFormCancelClicked(planCancellationModal)" (updateServiceDetailsStore)="updateServiceDetailsStoreHandler()">
    </mi-service-details-form-cancellation-request>
  </modal-content>
</modal>

<modal #removeCancellationModal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{'TENANT.SERVICE_DETAILS.REMOVE_SERVICE_CANCELLATION_HEADLINE' | translate}}</h4>
  </modal-header>
  <modal-content>
    <mi-service-details-form-remove-cancellation [miService]="serviceDetails"
      (updateServiceDetailsStore)="updateServiceDetailsStoreHandler()"
      (canceled)="removeCancellationModalCloseHandler(removeCancellationModal)">
    </mi-service-details-form-remove-cancellation>
  </modal-content>
</modal>

<modal #confirmCancellationModal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{'TENANT.SERVICE_DETAILS.CONFIRM_EDIT_CANCEL_SERVICE_HEADLINE' | translate}}</h4>
  </modal-header>
  <modal-content>
    <mi-service-details-form-confirm-cancellation-request (updateServiceDetailsStore)="updateServiceDetailsStoreHandler()"
      (canceled)="onFormCancelClicked(confirmCancellationModal)" [serviceDetails]="serviceDetails">
    </mi-service-details-form-confirm-cancellation-request>
  </modal-content>
</modal>

<modal #deleteServiceModal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>Delete Service</h4>
  </modal-header>
  <modal-content>
    <mi-page-alert></mi-page-alert>

    <mi-server-error [error]="error"></mi-server-error>
    <div>
      <strong>Are you sure you wish to delete "{{ serviceDetails.general.service_display_id }}" service?</strong>
      <p>Once deleted, this action cannot be reversed!</p>
      <p>Press delete to remove this Service permanently.</p>
      <!-- <strong>This service is a Parent Service of:</strong>
      <p>
        <ng-container *ngFor="let dependent of canDelete.dependents; index as i">
          <span *ngIf="i">, </span>
          <mi-id-link id="{{dependent.display_id}}"></mi-id-link>
        </ng-container>
      </p>
      <p>A Parent Service cannot be deleted. Please assign a different Parent for the Group and try again.</p> -->

      <div class="button-container">
        <a (click)="closeDeleteServiceModal(deleteServiceModal)" buttonTheme="alert-light-theme" class="button--cancel"
          mi-button>
          Cancel
        </a>
        <a (click)="confirmDeleteHandler(deleteServiceModal)" buttonTheme="alert-theme" mi-button>
          Delete
        </a>
      </div>
    </div>

    <!-- <div>
      <strong>This service is a Parent Service of:</strong>
      <p>
        <ng-container *ngFor="let dependent of canDelete.dependents; index as i">
          <span *ngIf="i">, </span>
          <mi-id-link id="{{dependent.display_id}}"></mi-id-link>
        </ng-container>
      </p>
      <p>A Parent Service cannot be deleted. Please assign a different Parent for the Group and try again.</p>
      <div class="button-container">
        <a (click)="closeDeleteServiceModal(deleteServiceModal)" buttonTheme="alert-light-theme" class="button--cancel"
          mi-button>
          Cancel
        </a>
      </div>
      <mi-cascade-need-replace title="Cannot Delete Service"
        subtitle="The following items are associated with this Service." [dependents]="canDelete.dependents"
        (canceled)="onCancel($event)">
        <mi-alert-item alertType="ERROR">
          Before deleting the Service, please remove the Service from the associated items above.
        </mi-alert-item>
      </mi-cascade-need-replace>
    </div> -->

    <!-- <ng-container>
      <mi-side-notice noticeType="WARNING">
        <strong>Only Super Admins Are Allowed To Delete Services!</strong>
        <div class="button-container">
          <a (click)="closeDeleteServiceModal(deleteServiceModal)" buttonTheme="alert-light-theme"
            class="button--cancel" mi-button>
            Cancel
          </a>
        </div>
      </mi-side-notice>
    </ng-container> -->

    <!-- <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template> -->
  </modal-content>
</modal>
<modal #fixServiceModal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4><b>Data Integrity ({{ serviceCalculationDetails.current_dates.current_end_date &&
        serviceCalculationDetails.service_term.id ? 'Complete' : 'Incomplete' }})</b></h4>
  </modal-header>
  <modal-content>
    <mi-alert-item alertType="{{ serviceCalculationDetails.current_dates.current_end_date ? 'SUCCESS' : 'ERROR' }}">
    <a (click)="goTo(DESTINATIONS.END_OF_TERM, 'fixServiceModal')"> Current end of term date</a>
    </mi-alert-item>
    <mi-alert-item alertType="{{ serviceCalculationDetails.service_term.id ? 'SUCCESS' : 'ERROR' }}">
      <a (click)="goTo(DESTINATIONS.END_OF_TERM_ACTION, 'fixServiceModal')">End of term action</a>
    </mi-alert-item>
  </modal-content>
</modal>