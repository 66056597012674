<div *ngIf="parentService && emailsLoaded; else loading">

  <div class="action-segment">

    <h2>Recommended Actions</h2>

    <ng-container *ngIf="parentServiceStatus.allArchived">
      <p class="note">
        {{'PARENT_SERVICE_SUMMARY.RECOMMENDED_ACTIONS_ARCHIVED'|translate}}
      </p>
    </ng-container>

    <ng-container *ngIf="!parentServiceStatus.allArchived">

      <ng-container *ngIf="parentServiceStatus.nextEventTooDistant">

        <ul class="link-list">
          <li *ngIf="!parentServiceStatus.hasFullDataIntegrity">
            <mi-dropdown
              dropdownTitle="Fix Missing Data"
              icon="compose"
              [items]="serviceDataItems">
            </mi-dropdown>
          </li>
          <li *ngIf="parentServiceStatus.allPendingActivation">
            <a class="button link link--confirm-activation"
               mi-button
               buttonTheme="dark-theme"
               (click)="openForm(FORM_TYPES.ACTIVATION_FORM)">
              Confirm Activation
            </a>
          </li>
        </ul>

        <p *ngIf="!parentServiceStatus.allPendingActivation && parentServiceStatus.hasFullDataIntegrity"
           class="note">
          {{'PARENT_SERVICE_SUMMARY.NEXT_EVENT_TOO_DISTANT'|translate}}
        </p>

      </ng-container>

      <ng-container *ngIf="!parentServiceStatus.nextEventTooDistant">

        <ul class="link-list">
          <li *ngIf="!parentServiceStatus.hasFullDataIntegrity">
            <mi-dropdown
              dropdownTitle="Fix Missing Data"
              icon="compose"
              [items]="serviceDataItems">
            </mi-dropdown>
          </li>

          <ng-container [ngSwitch]="true">

            <ng-container *ngSwitchCase="parentServiceStatus.allPendingActivation">
              <li >
                <a class="button link link--confirm-activation"
                   mi-button
                   buttonTheme="dark-theme"
                   (click)="openForm(FORM_TYPES.ACTIVATION_FORM)">
                  Confirm Activation
                </a>
              </li>
            </ng-container>

            <ng-container *ngSwitchCase="parentServiceStatus.allPendingCancellation">
              <li>
                <a class="button link link--confirm-edit-cancellation"
                   mi-button
                   buttonTheme="dark-theme"
                   (click)="openForm(FORM_TYPES.CONFIRM_CANCELLATION_FORM)">
                  Confirm/Edit Cancellation
                </a>
              </li>
              <li>
                <a class="button link link--remove-cancellation"
                   mi-button
                   buttonTheme="dark-theme"
                   (click)="openForm(FORM_TYPES.REMOVE_CANCELLATION_FORM)">
                  Remove Cancellation
                </a>
              </li>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="parentServiceStatus.hasFullDataIntegrity">
                <li>
                  <p class="note">
                    {{'PARENT_SERVICE_SUMMARY.NO_RECOMMENDATIONS' | translate}}
                  </p>
                </li>
              </ng-container>
            </ng-container>

          </ng-container>

        </ul>

      </ng-container>

    </ng-container>

  </div>

  <div class="action-segment">

    <h2>Recent Messages</h2>

    <ng-container *ngIf="emailsLoaded; else loading">

      <ul *ngIf="emails && emails.length !== 0"
          class="emails">
        <li *ngFor="let email of emails"
            class="email">

          <div>
            <a [routerLink]="'/emails/'+email._id">
              <div class="email-subject">
                {{'EMAIL_TYPES.' + email.type | translate}}
              </div>
            </a>
            <div class="email-days-ago">
              {{this.daysPast(email.created_at)}}
            </div>
          </div>
          <div>
            <div class="email-date">{{email.created_at | date:"shortDate" }}</div>
          </div>
        </li>
      </ul>

      <p class="note"
         *ngIf="!emails || emails.length === 0">
        {{'PARENT_SERVICE_SUMMARY.NO_EMAILS'|translate|translateSelector:parentService.services.length}}
      </p>

    </ng-container>

  </div>

  <div *ngIf="!parentServiceStatus.allArchived"
       class="action-segment">
    <mi-dropdown
      dropdownTitle="Compose Message"
      icon="compose"
      [items]="composeMessageItems">
    </mi-dropdown>
  </div>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
