<mi-page-header
  title="{{ 'DATA_IMPORT.API_INTEGRATION_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<mi-page-alert></mi-page-alert>

<mi-data-import-api-integration></mi-data-import-api-integration>
