import { Component, Input, OnInit, ViewChild, TemplateRef} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { Contact } from "src/shared/models/Contact";

@Component({
  templateUrl: './select-employee-modal.component.html'
})

export class SelectEmployeeModalComponent implements OnInit {
  data: any;
  defaultSearchText: string = '';
  title: string = "Select User or Employee";

  constructor(private modal: ModalRef) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modal.close({tenantUser: null});
  }

  onAddContact(tenantUser: any): void {
    this.modal.close({tenantUser});
  }
}

