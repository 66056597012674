import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as ASReducer from '../../../../state/advanced-search/reducers';
import * as ASActions from "../../../../state/advanced-search/actions";
import * as appDataReducer from '../../../../state/app-data/reducers';
import { Store, select } from '@ngrx/store';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { removeNonUrlSearchParams } from 'src/shared/utils/unsafeUrlProperties';
import { Observable, combineLatest } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ReportOrView } from '../../../../models/Report';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import { TenantCustomFieldService } from 'src/libs/api/tenant/tenantCustomFields.service';
import { CustomField } from 'src/shared/models/CustomField';
import { minLengthWithTrimmedWhitespace } from 'src/shared/shared.validators';
import { SortableTemplatesEnum } from 'src/shared/ng-models/SortableTableColumn.interface';
import { SectionDataTableColumn } from 'src/shared/ng-models/SectionDataColumn.interface';

export enum CreateEditUmMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

export enum ActionTypeEnum {
  REPORT = 'REPORT',
  SERVICE_REPORT = 'SERVICE_REPORT'
}

@Component({
  templateUrl: './run-report-or-view-modal.component.html',
  styleUrls: ['./run-report-or-view-modal.component.scss']
})

export class RunReportOrViewModalComponent extends AbstractPageComponent implements OnInit {

  // action_type:ActionTypeEnum = ActionTypeEnum.REPORT;
  action_type:string = 'SERVICE_REPORT';
  searchType:string = '';

  message:string = "Notification"
  closeButtonLabel:string = "Confirm"
  mode: CreateEditUmMode = CreateEditUmMode.CREATE;
  loaded: boolean = true;
  filters: any;
  loadError: any;
  columns: any;
  tenant_id: string;
  readable_filters: string;
  items: ReportOrView[];
  report: ReportOrView = null;
  error: any;
  form: FormGroup = null;

  @ViewChild('changeViewTpl', {static: true}) changeViewTpl: TemplateRef<any>;
  @ViewChild('updateReportTpl', {static: true}) updateReportTpl: TemplateRef<any>;


  stringFilterList: string[] = [
    'q', 
    'notification_date_min',
    'notification_date_max',
    'order_date_min',
    'order_date_max',
    'end_date_min',
    'end_date_max',
    'days_until_event_max',
    'days_until_event_min',
    'unit_recurring_cost_min',
    'unit_recurring_cost_max',
    'unit_non_recurring_cost_min',
    'unit_non_recurring_cost_max',
    'mrc_min',
    'mrc_max',
  ];

  reportTableColumns:SectionDataTableColumn[];

  constructor(
    private store: Store<ASReducer.State>,
    private modal: ModalRef,
    private reportV2Service: ReportV2Service,
    private tenantCustomFieldService: TenantCustomFieldService,
    private formBuilder: FormBuilder,
   ) {
    super()
  }

  ngOnInit(){
    this.reportTableColumns = [
      {
        name: 'View Report (Click to View)',
        prop: 'label',
        sortable: true,
        cellTemplate: this.changeViewTpl,
      },
      {
        name: 'Create Date',
        prop: 'created_at',
        sortable: true,
        cellTemplate: SortableTemplatesEnum.shortDate,
      },
      {
        name: 'Change Report',
        prop: 'id',
        sortable: false,
        cellTemplate: this.updateReportTpl,
      }
    ];

    combineLatest(
      this.store.pipe(select(appDataReducer.tenantDomain), first()),
      this.store.select(ASReducer.getASData)
    )
    .takeUntil(this.destroy$)
    .subscribe(([domain, data]) => {
      this.tenant_id = domain;
        if (data.results && data.results) {
          this.filters = removeNonUrlSearchParams(data.results.meta.params);
          this.readable_filters = data.readableFilter;
          this.columns = data.columns;
        } else {
          this.filters = [];
        }
        this.getReportsOrView();
        this.getCustomFields();
        this.createForm();
      },
      e => this.loadError = e);
  }

  createForm(data: any = {}): void { 
    let {
      label
    } = data;

    this.form = this.formBuilder.group({
      label: [label, Validators.compose([
        Validators.required,
        Validators.maxLength(200), 
        Validators.minLength(2),
        minLengthWithTrimmedWhitespace(2)
      ])],
      action_type: [this.action_type, Validators.required]
    });

    this.loaded = true;
  }

  onFormSubmit(formValue): void {
    this.loaded = false;
    const microBodyFilter = Object.keys(this.filters).map(key => {
      let value = this.filters[key];
      if(typeof this.filters[key] === "string"){
        value = [this.filters[key]];
      }

      return {
        filter_name: key,
        value: value
      }
    })
    const microServiceBody = {
      tenant_id: this.tenant_id,
      report_group: 'SERVICE',
      label: formValue.label,
      filters: microBodyFilter,
      readable_filters: this.readable_filters,        
      columns: this.columns,
      action_type: this.action_type,
      // recipient_emails: [],
      // schedule_frequency: 0,
      // schedule_time: null,
    }
    console.log("microServiceBody", microServiceBody)
    this.error = null;

    this.reportV2Service.createReportOrView(microServiceBody)
      .subscribe(
        (res) => {
          this.onCancel(true);
        },
            (e) => this.error= e
      );
  }

  getCustomFields(){
    this.tenantCustomFieldService.listCustomFields()
    .toPromise()
    .then((customFields: CustomField[]) => {
      customFields.map(item => {
        if(item.type === "NUMBER" || item.type === "DATE"){
          this.stringFilterList.push(item._id+'_min');
          this.stringFilterList.push(item._id+'_max');
        }
      })
    })
    .catch(e => this.error = e);
  }

  getReportsOrView(){
    this.reportV2Service.getReportOrViewList(this.tenant_id, this.action_type)
      .subscribe(
        (res) => {
          this.items = res;
          this.loaded = true;
        },
            (e) => this.error= e
      );
  }

  onCancel(print:boolean = false) {
    this.modal.close({print});
  }

  updateColumnsState(columns: any){
    this.store.dispatch( new ASActions.AsSimpleStateUpdate({columns: columns}))
  }

  onUpdateFilters(report): void {

    let newQuery = {};
    
    report.filters
      .map((filterBucket) => {
        //if(this.stringFilterList.indexOf(filterBucket.filter_name)<0){
        if(this.stringFilterList.indexOf(filterBucket.name)<0){
          //newQuery[filterBucket.filter_name] = filterBucket.value;
          newQuery[filterBucket.name] = filterBucket.values;
        } else {
          //newQuery[filterBucket.filter_name] = filterBucket.value[0];
          newQuery[filterBucket.name] = filterBucket.values[0];
        }
      });
    this.store.dispatch(new ASActions.AsFilterReplace(newQuery));
    this.onCancel();
  }

  applySelection(report): void {
    this.report = report;
    if(this.report.action_type === "SERVICE_REPORT"){
      if(this.report.columns[0].name !=="Parent Group"|| this.report.columns[1].name !=="P-cMRC"){
        this.report.columns.unshift(
          {name: "Parent Group", hidden: false, is_custom_data: false},
          {name: "P-cMRC", hidden: false, is_custom_data: false}
        );
        this.updateReport(this.report);
      }
      this.updateColumnsState(this.report.columns);
      this.onUpdateFilters(this.report);
      if(this.searchType){
        const valuetosave = JSON.stringify({[this.report.tenant_id]: this.report.columns});
        window.localStorage.setItem(this.searchType, valuetosave);  
      }
      this.onCancel();
    }

    this.report = null;
  }

  onDeleteView(report){
    this.reportV2Service.removeReport(report.id)
      .takeUntil(this.destroy$)
      .subscribe((res) => {
      this.onCancel(false);
    }, (error) => {
      this.loadError = error;
    });
  }


  updateReport(report: any): void {
    const microBodyFilter = Object.keys(this.filters).map(key => {
      let value = this.filters[key];
      if(typeof this.filters[key] === "string"){
        value = [this.filters[key]];
      }

      return {
        filter_name: key,
        value: value
      }
    })
    const newReport = {
      ...report,
      filters: microBodyFilter,
      readable_filters: this.readable_filters,        
      columns: this.columns,
    };
    this.reportV2Service.updateReport(newReport)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
      this.onCancel();
    }, (error) => {
      this.loadError = error;
    });
  }
}
