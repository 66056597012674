import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiOrder} from '../../../shared/models/MiOrder';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantMiOrderService {

  private apiUrlCreateOrder = '/{tenant}/orders';
  private apiUrlGetByDisplayId = '/{tenant}/orders-by-display-id';
  private apiUrlGetByOrderId = '/{tenant}/service/from-order';
  private apiUrlGetEditOrder = '/{tenant}/orders';

  constructor(private http: HttpClient) {
  }

  createOrder(order: MiOrder): Observable<MiOrder> {
    return this.http
      .post(this.apiUrlCreateOrder, order)
      .map((response: any) => response.data.miorder as MiOrder);
  }

  createFromOrder(order: any): Observable<any> {
    return this.http
      .post(`${this.apiUrlGetByOrderId}/${order._id}`, order.service)
      .map((response: any) => response.data);
  }

  getByDisplayId(displayId: string): Observable<MiOrder> {
    return this.http
      .get(`${this.apiUrlGetByDisplayId}/${displayId}`)
      .map((response: any) => response.data.miorder as MiOrder);
  }

  getOrder(id: string): Observable<MiOrder> {
    return this.http
      .get(`${this.apiUrlGetEditOrder}/${id}`)
      .map((response: any) => response.data.miorder as MiOrder);
  }

  updateOrder(order: MiOrder): Observable<MiOrder> {
    return this.http
      .put(`${this.apiUrlGetEditOrder}/${order._id}`, order)
      .map((response: any) => response.data.miorder as MiOrder);
  }

}
