<mi-server-error [error]="loadError"></mi-server-error>

<mi-opacity-loader [ready]="!loading">
  <form *ngIf="form !== null" [formGroup]="form" (ngSubmit)="onFormSubmit(form.value, $event)"
        autocomplete="off">

    <div class="row">
      <mi-extended-input class="half-column"
                         [labelText]="'First Name'"
                         [labelRequired]="true"
                         [inputControl]="form.controls['first_name']"
                         [inputErrors]="form.controls['first_name'].errors"
                         [inputTouched]="form.controls['first_name'].touched"
                         [errorDefs]='{required: "Please enter a first name"}'>
        <input type="text"
               class="form-control"
               formControlName="first_name"
               placeholder="First Name">
      </mi-extended-input>

      <mi-extended-input class="half-column"
                         [labelText]="'Last Name'"
                         [labelRequired]="true"
                         [inputControl]="form.controls['last_name']"
                         [inputErrors]="form.controls['last_name'].errors"
                         [inputTouched]="form.controls['last_name'].touched"
                         [errorDefs]='{required: "Please enter a last name"}'>
        <input type="text"
               class="form-control"
               formControlName="last_name"
               placeholder="Last Name">
      </mi-extended-input>
    </div>

    <div class="row">
      <mi-extended-input class="half-column"
                         [labelText]="'Email'"
                         [labelRequired]="true"
                         [inputControl]="form.controls['email']"
                         [inputErrors]="form.controls['email'].errors"
                         [inputTouched]="form.controls['email'].touched"
                         [errorDefs]='{required: "Please enter an email.", 
                            emailValidator:"Please enter a valid email.",
                            noTenantUserEmailValidator: "The same e-mail could not be used as Vendor/User contacts."}'>

        <input type="email"
               class="form-control"
               formControlName="email"
               [attr.disabled]="this.mode === 'EDIT'? true: null"
               placeholder="email@domain.com">
      </mi-extended-input>

      <mi-extended-input class="half-column"
            [labelText]="'Status'"
            [labelRequired]="true"
            [inputControl]="form.controls['status']"
            [inputErrors]="form.controls['status'].errors"
            [inputTouched]="form.controls['status'].touched"
            [errorDefs]='{required: "Please select a status"}'>
        <select formControlName="status"
        [attr.disabled]="activeLocked?'':null">
        <option *ngFor="let status of CONTACT_STATUSES" value="{{status}}">
        {{ 'CONTACT_STATUSES.' + status | translate}}
        </option>
        </select>
      </mi-extended-input>

    </div>

    <div class="row">
      <mi-extended-input [labelText]="'Mobile Phone'"
                            class="half-column"
                            [labelRequired]="false"
                            [(inputControl)]="form.controls['mobile_phone']">
          <mi-telephone-simple-input id="mobile_phone"
                                      class="form-control"
                                      name="mobile_phone"
                                      placeholder=""
                                      formControlName="mobile_phone"
                                      type="text">
          </mi-telephone-simple-input>
        </mi-extended-input>

        <mi-extended-input [labelText]="'Office Phone'"
                class="half-column"
                [labelRequired]="false"
                [(inputControl)]="form.controls['office_phone']">
        <mi-telephone-simple-input id="office_phone"
                          class="form-control"
                          name="office_phone"
                          placeholder=""
                          formControlName="office_phone"
                          type="text">
        </mi-telephone-simple-input>
        </mi-extended-input>

    </div>

    <div class="row">

      <mi-extended-input class="half-column"
                         labelText="Function"
                         [labelRequired]="true"
                         [inputControl]="form.controls['role']"
                         [inputErrors]="form.controls['role'].errors"
                         [inputTouched]="form.controls['role'].touched"
                         [errorDefs]='{required: "Please select a role"}'>
        <select formControlName="role">
          <option value="" disabled="disabled">Select Role</option>
          <option *ngFor="let role of CONTACT_ROLES" value="{{role}}">
            {{ 'CONTACT_ROLES.' + role | translate}}
          </option>
        </select>
      </mi-extended-input>

    </div>

    <div class="row" [ngClass]="{'form-open': vendorState == 0}">
      <mi-extended-input
        labelText="{{  vendorState == 0 ? '' : 'Company'}}"
        [labelRequired]="vendorState !== 0"
        [inputControl]="form.controls['vendor']"
        [inputErrors]="form.controls['vendor'].errors"
        [inputTouched]="form.controls['vendor'].touched"
        [errorDefs]='{required: "Please select a company."}'>
        <input type="hidden"
               class="form-control"
               formControlName="vendor"
               placeholder="">

        <mi-find-or-create-vendor
          *ngIf="!form.controls['vendor'].value; else vendorSelectedView"
          (vendorSelected)="onVendorSelected($event)"
          (stepChanged)="onVendorCreateStepChanged($event)">

        </mi-find-or-create-vendor>

        <ng-template #vendorSelectedView>
          <mi-product-vendor
            [vendor]="form.controls['vendor'].value"
            (onUnSelectVendor)="onVendorUnselected($event)">

          </mi-product-vendor>
        </ng-template>


      </mi-extended-input>
    </div>

    <mi-server-error [error]="submitError"></mi-server-error>

    <div class="button-container" *ngIf="showButtonContainer">
      <ng-container *ngIf="mode==='EDIT'">
        <a mi-button
           class="button-alternate"
           buttonTheme="alert-light-theme"
           routerLink="../delete">
          Delete
        </a>
      </ng-container>

      <a mi-button
         class="button--cancel"
         buttonTheme="light-theme"
         (click)="onCancel($event)">
        Cancel
      </a>

      <button mi-button
              type="submit"
              [disabled]="!form.valid">
        {{ctaLabel}}
      </button>
    </div>
  </form>
</mi-opacity-loader>
