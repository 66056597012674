import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProductType} from '../../../shared/models/ProductType';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class MiyagiProductTypeService {

  private apiUrlCrud = '/miyagi/product-type';

  constructor(private http: HttpClient) {
  }

  createType(type: ProductType): Promise<ProductType> {
    return this.http
      .post(this.apiUrlCrud, type)
      .toPromise()
      .then((response: any) => response.json().data.productType as ProductType);
  }

  editType(type: ProductType): Promise<ProductType> {
    return this.http
      .put(`${this.apiUrlCrud}/${type._id}`, type)
      .toPromise()
      .then((response: any) => response.json().data.productType as ProductType);
  }

  getType(typeId: string): Promise<ProductType> {
    return this.http
      .get(`${this.apiUrlCrud}/${typeId}`)
      .toPromise()
      .then((response: any) => response.json().data.productType as ProductType);
  }

  listTypes(): Promise<Array<ProductType>> {
    return this.http
      .get(this.apiUrlCrud)
      .toPromise()
      .then((response: any) => response.json().data.productTypes as Array<ProductType>);
  }


}
