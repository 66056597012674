import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {createLogger, LOG_LEVELS} from "../../../../shared/logger";
import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {urlValidator} from "../../../../shared/shared.validators";
import { EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_RE_TERM,
  EVENT_TYPE_REPLACE} from '../../../../common/utils/sharedConstants';
import {TermsAndConditions} from "../../../../shared/models/TermsAndConditions";
import {Vendor} from "../../../../shared/models/Vendor";
import {Product} from "../../../../shared/models/Product";
import {TenantTermsAndConditionsService} from "../../../../libs/api/tenant/tenantTermsAndConditions.service";

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-tenant-terms-and-conditions-form',
  templateUrl: './tenant-terms-and-conditions-form.component.html',
  styleUrls: ['./tenant-terms-and-conditions-form.component.scss']
})

export class TenantTermsAndConditionsFormComponent extends AbstractPageComponent implements OnInit {

  @Output() termsAndConditionsCreated: EventEmitter<TermsAndConditions> = new EventEmitter<TermsAndConditions>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() product:Product;
  @Input() editId:string;
  @Input() vendor:Vendor;
  @Input() duplicateTerms: any = {};


  EVENT_TYPE_AUTO_RENEW: string = EVENT_TYPE_AUTO_RENEW;
  EVENT_TYPE_AUTO_CANCELLATION: string = EVENT_TYPE_AUTO_CANCELLATION;

  terms_and_conditions: TermsAndConditions;
  termsAndConditionsForm: any;
  loading: boolean = true;
  loadingError: any = '';
  submitError: any = '';
  autoRenewSelected: boolean;
  titles: any = {
    select: "End of Term Action",
    notification_period: "Auto-Renew Length in Months",
    requred_notification: "Days Required to Notify"
  };
  hasAgreementLink: boolean = false;
  isEdit: boolean = false;

  constructor(
              private formBuilder: FormBuilder,
              private tenantTermsAndConditionsService: TenantTermsAndConditionsService) {
    super();
  }

  ngOnInit() {
    const {
      event_type = this.EVENT_TYPE_AUTO_RENEW,
      auto_renew_length,
      days_to_notify,
      agreement_link,
      cancellation_instructions
    } = (this.duplicateTerms);

    this.termsAndConditionsForm = this.formBuilder.group({
      event_type: [event_type, [Validators.required]],
      auto_renew_length: [auto_renew_length, [Validators.required]],
      days_to_notify: [days_to_notify, [Validators.required]],
      label: [null, [Validators.required]],
      agreement_link: [agreement_link],
      cancellation_instructions: cancellation_instructions,
      tenant_vendor: null
    });

    this.termsAndConditionsForm.get('agreement_link').valueChanges.subscribe((linkText: string) => {

      const linkControl: FormControl = this.termsAndConditionsForm.get('agreement_link');
      const updateLinkValidation: boolean = linkText && !this.hasAgreementLink || !linkText && this.hasAgreementLink;

      if(linkText){
        if(!this.hasAgreementLink){
          this.hasAgreementLink = true;
          linkControl.setValidators([urlValidator]);
        }
      }else{
        if(this.hasAgreementLink){
          this.hasAgreementLink = false;
          linkControl.setValidators([]);
        }
      }

      if(updateLinkValidation){
        linkControl.updateValueAndValidity({onlySelf: true});
      }

    });

    if(this.editId){
      this.setupEdit();
    }
    else if (!this.vendor) {
      this.loading = true;
      this.loadingError = 'Error with create Terms and Agreement form. Please contact MISO Support.'
      log.error('No product or vendor was passed to the agreement form');
    } else {
      this.loading= false;
      this.onEOTSelect(null);
    }
  }

  setupEdit() {
    this.tenantTermsAndConditionsService.getByDisplayId(this.editId)
    .toPromise()
    .then(tac => {
        this.terms_and_conditions = tac;
        this.vendor = tac.vendor;

        this.termsAndConditionsForm
          .controls.event_type
          .setValue(this.terms_and_conditions.event_type);

        this.autoRenewSelected = this.termsAndConditionsForm.controls.event_type.value === this.EVENT_TYPE_AUTO_RENEW;

        this.termsAndConditionsForm
          .controls.auto_renew_length
          .setValue(this.terms_and_conditions.auto_renew_length || null);

        this.termsAndConditionsForm
          .controls.days_to_notify
          .setValue(this.terms_and_conditions.days_to_notify || 0);

        this.termsAndConditionsForm
          .controls.cancellation_instructions
          .setValue(this.terms_and_conditions.cancellation_instructions || null);

        this.termsAndConditionsForm
          .controls.label
          .setValue(this.terms_and_conditions.label);

        this.termsAndConditionsForm
          .controls.agreement_link
          .setValue(this.terms_and_conditions.agreement_link || null);

        this.termsAndConditionsForm
          .controls.tenant_vendor
          .setValue(this.terms_and_conditions.tenant_vendor);

        if (!this.autoRenewSelected) {
          // if this is not auto-renew make sure to get ride of days.
          this.termsAndConditionsForm.get('days_to_notify').setValue(null);
          this.termsAndConditionsForm.get('auto_renew_length').setValue(null);
        }

        this.updateValidation();
        this.isEdit = true;
        this.loading = false;
      });

  }

  defaultLabel(): string {
    const vendorName: string = this.vendor.vendor_name || 'ABC';
    const productName: string = this.product ? this.product.product_name : 'General';
    const actionType: string = this.autoRenewSelected ? "Auto-Renew" : "Cancellation";

    let months: string = '';

    if (this.autoRenewSelected && this.termsAndConditionsForm.get('auto_renew_length').value) {
      // the label changes slightly when there is months.
      months = this.termsAndConditionsForm.get('auto_renew_length').value;
      return `${vendorName} ${productName}, ${months} Month ${actionType}`;
    }

    return `${vendorName} ${productName} ${actionType}`;
  }

  onEOTSelect(e) {
    this.autoRenewSelected = e ? e.target.value === this.EVENT_TYPE_AUTO_RENEW : true;
    this.termsAndConditionsForm.controls.label.setValue(this.defaultLabel());

    if (!this.autoRenewSelected) {
      // if this is not auto-renew make sure to get ride of days.
      this.termsAndConditionsForm.get('days_to_notify').setValue(null);
      this.termsAndConditionsForm.get('auto_renew_length').setValue(null);
    }

    this.updateValidation();
  }

  onMonthChange(e) {
    log('month changed');
    this.onEOTSelect(null);
  }

  updateValidation() {
    if (this.autoRenewSelected) {
      this.termsAndConditionsForm.get('auto_renew_length').setValidators([Validators.required]);
      this.termsAndConditionsForm.get('days_to_notify').setValidators([Validators.required]);
    } else {
      this.termsAndConditionsForm.get('auto_renew_length').setValidators([]);
      this.termsAndConditionsForm.get('days_to_notify').setValidators([]);
    }
    this.updateValidity(this.termsAndConditionsForm);
  }

  updateValidity(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.controls[key].updateValueAndValidity();
    });
  }

  onBackClick() {
    this.canceled.emit();
  }

  onFormSubmit(data: any) {
    this.submitError = false;
    if(this.isEdit){
      let newTac = {
        ...this.terms_and_conditions,
        days_to_notify: data.days_to_notify,
        label: data.label.trim(),
        cancellation_instructions: data.cancellation_instructions,
        agreement_link: data.agreement_link
      };
      this.tenantTermsAndConditionsService.updateTermsAndConditions(newTac as TermsAndConditions)
      .toPromise()
      .then( (terms: TermsAndConditions) => this.termsAndConditionsCreated.emit(terms))
      .catch( e => this.submitError = e)
    }
    else{
    this.tenantTermsAndConditionsService.createTermsAndConditions(this.vendor._id, data as TermsAndConditions)
      .toPromise()
      .then( (terms: TermsAndConditions) => this.termsAndConditionsCreated.emit(terms))
      .catch( e => this.submitError = e)
    }
  }

}
