import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl} from '@angular/forms';
import {FileService} from '../../../libs/api/file/file.service';
import {guid} from '../../shared.functions';
import {LocationV2} from '../../../libs/api2/types/LocationV2.interface';

@Component({
  selector: 'radio-array-list',
  templateUrl: './radio-array-list.component.html',
  styleUrls: ['./radio-array-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioArrayList),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioArrayList),
      multi: true,
    },
    FileService
  ]
})

export class RadioArrayList implements ControlValueAccessor {
  @Input() textFirst: boolean = false;
  @Input() allowNew: boolean;
  @Input() placeholder: string = 'New';
  @Input() buttonOptions: LocationV2[] = [];
  @Input() inputId: string = 'groupName_' + (guid(true));
  @Input() newValue: string = '';
  @Input() newNickname: string = '';
  @Input() selectedIndex: number = 0;
  isOpen: boolean = false;
  allowNewId: string = 'Input_999' + ((Math.random() * 10) | 0);
  selectedObject: {label: string; nick_name: string} = {label: null, nick_name: null};

  private propagateChange = (_: any) => {
  }

  private propagateTouch = (_: any) => {
  }

  constructor() {

  }

  onSelectionChange(value: any, nickName = false) {
    const obj = nickName ?
        this.buttonOptions.find(x => x.nick_name === value || x.label == this.newValue) :
        this.buttonOptions.find(x => x.label === value);
    this.selectedObject = obj;

    if (nickName && this.newValue == '') {
      this.newNickname = '';
    }
    if (!obj && this.newValue != "") {
      this.selectedObject = {
        label: this.newValue,
        nick_name: this.newNickname
      };
    }
    this.propagateChange(this.selectedObject);
  }

  public validate(c: FormControl) {
    return null;
  }

  public writeValue(obj: any) {
    if (!obj && this.buttonOptions.length > 0) {
      obj = this.buttonOptions[0];
    }
    this.selectedObject = obj;
    const indexOfObj = this.buttonOptions.indexOf(obj);
    if (this.selectedIndex === 0) {
      this.selectedIndex = indexOfObj > -1 ? indexOfObj : 0;
    }
    this.propagateChange(obj);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

}
