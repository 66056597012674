<div class="advanced-search-header">
  <div class="clear-fix">
    <div class="search-col">
      <!-- <h2>{{searchTypeTitle}}</h2> -->
      <mi-search [placeholder]="searchTypeTitle" type="" [value]="q" (clearSearch)="clearSearch()"
        (searchSubmit)="onSearchSubmit($event)"></mi-search>
    </div>
    <div class="cost-col" *ngIf="showTotalMrc">
      <h2>Total Monthly Recurring Cost</h2>
      <p class="cost">{{total_mrc | tenantcurrency:'1.2-2'}}</p>
    </div>
    <div class="cost-col" *ngIf="showButton && buttonLabel && buttonUrl">
        <a class="breadcrumb button dark-theme" routerLink="{{buttonUrl}}">
          {{buttonLabel}}
        </a>
    </div>
  </div>

  <div class="clear-fix">
    <div class="search-col">
      <div class="reporting count">
        {{ ('SEARCH_QUANTITIES.' + searchType) | translate:{count:total} | translateSelector:total }}
      </div>
      <div class="reporting sort">
        <mi-advanced-search-sort-component></mi-advanced-search-sort-component>
      </div>
      <div class="active-checkbox" *ngIf="searchType==='SERVICE'">
        <label class="checkbox">
          <input type="checkbox"
                (change)="activeOnlyCheckbox($event)"
                [disabled]="!activeButton"
                [checked]="activeButtonSelected"
          />
          Active Only
        </label>
      </div>
    </div>
    <div class="cost-col">
      <mi-header-action-dropdown [searchType]="searchType" [addNewURL]="buttonUrl">
        <ng-content>
        </ng-content>
      </mi-header-action-dropdown>
    </div>
  </div>

</div>
