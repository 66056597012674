import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as userActions from './actions';
import {ISecureUser} from '../../../common/security/secureUser';
import {objCompare} from '../../../common/utils/ObjectUtils';
import {clearAllCookies, clearAuthCookie} from './auth-cookie';

export interface State {
  verified: boolean;
  verifying: boolean;
  secureUser: ISecureUser;
}

const initialState: State = {
  verified: false,
  verifying: false,
  secureUser: null
};

export function reducer(state = initialState, action: userActions.Actions): State {
  switch (action.type) {
    case userActions.ActionTypes.USER_VERIFY_SUCCESS: {
      let secureUser = state.secureUser || null;
      if (secureUser && objCompare(action.payload.secureUser._rawData, secureUser._rawData)) {
        if (state.verifying === false && state.verified === true) {
          // this was just the auth guard renewing the user object.
          return state;
        }
      } else {
        // this really is a new user object.
        secureUser = action.payload.secureUser;
      }
      // this is a new user or the app is starting up.
      return {
        ...state,
        verifying: false,
        verified: true,
        secureUser: secureUser
      };
    }
    case userActions.ActionTypes.USER_VERIFY_ERROR: {
      clearAuthCookie();
      return {
        ...state,
        verifying: false,
        verified: true,
        secureUser: null
        // think about adding a failed token here.
        // failedToken: true
      };
    }
    case userActions.ActionTypes.USER_VERIFY: {
      return {
        ...state,
        verifying: true
      };
    }
    case userActions.ActionTypes.USER_LOGOUT_SUCCESS: {
      //clearAuthCookie();
      clearAllCookies();
      return {
        ...state,
        verifying: false,
        verified: true,
        secureUser: null
        // think about adding a failed token here.
        // failedToken: true
      };
    }
    default: {
      //  log('returning default user store');
      return state;
    }
  }
}

export const getUserState = createFeatureSelector<State>('user');
export const isSuperUser = createSelector(
  getUserState,
  (state) => (state.secureUser && state.secureUser.isSuperUser()) as boolean
);
