<mi-page-header
  title="{{ 'DATA_IMPORT.TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>

<mi-page-alert></mi-page-alert>

<div class="service-columns">
  <div class="service-column">
    <h2>{{'DATA_IMPORT.UPLOAD_TEMPLATE_TITLE' | translate}}</h2>
    <div class="service-image">
      <a routerLink="upload">
        <img src="/shared/assets/images/data-import/download_template.jpg" alt=""/>
      </a>
    </div>
    <p>{{'DATA_IMPORT.UPLOAD_TEMPLATE_COPY_TOP' | translate}}</p>
    <ul>
      <li>
        <a href="{{VENDOR_TEMPLATE_URL}}"
           title="{{'DATA_IMPORT.UPLOAD_TEMPLATE_VENDOR_TEMPLATE'|translate}}">
          {{'DATA_IMPORT.UPLOAD_TEMPLATE_VENDOR_TEMPLATE'|translate}}
        </a>
      </li>
      <li>
        <a href="{{CONTACT_TEMPLATE_URL}}"
           title="{{'DATA_IMPORT.UPLOAD_TEMPLATE_CONTACT_TEMPLATE'|translate}}">
          {{'DATA_IMPORT.UPLOAD_TEMPLATE_CONTACT_TEMPLATE'|translate}}
        </a>
      </li>
      <li>
        <a href="{{EQUIPMENT_TEMPLATE_URL}}"
           title="{{'DATA_IMPORT.UPLOAD_TEMPLATE_EQUIPMENT_TEMPLATE'|translate}}">
          {{'DATA_IMPORT.UPLOAD_TEMPLATE_EQUIPMENT_TEMPLATE'|translate}}
        </a>
      </li>
      <li>
        <a href="{{LOCATION_TEMPLATE_URL}}"
           title="{{'DATA_IMPORT.UPLOAD_TEMPLATE_LOCATION_TEMPLATE'|translate}}">
          {{'DATA_IMPORT.UPLOAD_TEMPLATE_LOCATION_TEMPLATE'|translate}}
        </a>
      </li>
      <li>
        <a href="{{SERVICE_TEMPLATE_URL}}"
           title="{{'DATA_IMPORT.UPLOAD_TEMPLATE_SERVICE_TEMPLATE'|translate}}">
          {{'DATA_IMPORT.UPLOAD_TEMPLATE_SERVICE_TEMPLATE'|translate}}
        </a>
      </li>
    </ul>
    <p>{{'DATA_IMPORT.UPLOAD_TEMPLATE_COPY_BOTTOM' | translate}}</p>
  </div>
  <div class="service-column">
    <h2>{{'DATA_IMPORT.UPLOAD_DOCUMENT_TITLE' | translate}}</h2>
    <div class="service-image">
      <a routerLink="upload">
        <img src="/shared/assets/images/data-import/upload_document.jpg" alt=""/>
      </a>
    </div>
    <p>{{'DATA_IMPORT.UPLOAD_DOCUMENT_COPY_TOP' | translate}}</p>
    <ul>
      <li>
        {{'DATA_IMPORT.UPLOAD_DOCUMENT_VENDOR_INVOICE' | translate}}
      </li>
      <li>
        {{'DATA_IMPORT.UPLOAD_DOCUMENT_VENDOR_AGREEMENTS' | translate}}
      </li>
      <li>
        {{'DATA_IMPORT.UPLOAD_DOCUMENT_EXCEL_WORD' | translate}}
      </li>
      <li>
        {{'DATA_IMPORT.UPLOAD_DOCUMENT_DATA_DUMPS' | translate}}
      </li>
      <li>
        {{'DATA_IMPORT.UPLOAD_DOCUMENT_OTHER_DOCS' | translate}}
      </li>
    </ul>
    <p>{{'DATA_IMPORT.UPLOAD_DOCUMENT_COPY_BOTTOM' | translate}}</p>
  </div>
  <div class="service-column">
    <h2>{{'DATA_IMPORT.API_INTEGRATION_TITLE' | translate}}</h2>
    <div class="service-image">
      <a routerLink="api-integration">
        <img src="/shared/assets/images/data-import/api_connect.jpg" alt=""/>
      </a>
    </div>
    <p>{{'DATA_IMPORT.API_INTEGRATION_COPY' | translate }}</p>
  </div>
</div>
<div class="service-columns centered">
  <div class="service-column">
    <a
      class="service-link"
      mi-button
      buttonTheme="dark-theme"
      routerLink="upload">
      {{'DATA_IMPORT.UPLOAD_TEMPLATE_CTA' | translate}}
    </a>
  </div>
  <div class="service-column">
    <a
      class="service-link"
      mi-button
      buttonTheme="dark-theme"
      routerLink="upload">
      {{'DATA_IMPORT.UPLOAD_DOCUMENT_CTA' | translate}}
    </a>
  </div>
  <div class="service-column">
    <a
      class="service-link"
      mi-button
      buttonTheme="dark-theme"
      routerLink="api-integration">
      {{'DATA_IMPORT.API_INTEGRATION_CTA' | translate}}
    </a>
  </div>
</div>
