import {Component, Input, OnInit} from '@angular/core';
import {MiService} from '../../../../../../shared/models/MiService';
import {TenantMiServiceService} from '../../../../../../libs/api/tenant/tenantMiService.service';
import * as psdActions from '../../state/actions';
import * as psdReducer from '../../state/reducers';
import {Store} from '@ngrx/store';
import {SERVICE_ARCHIVED} from '../../../../../../../src/common/utils/sharedConstants';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {MiParentService} from '../../../../../../shared/models/MiParentService';
import {TenantMiParentServiceService} from '../../../../../../libs/api/tenant/tenantMiParentService.service';
import {Observable} from 'rxjs';


export enum ParentServiceTileMode {
  NORMAL = 'NORMAL',
  SEARCH_RESULT = 'SEARCH_RESULT'
};

@Component({
  selector: 'mi-parent-service-tile',
  templateUrl: './parent-service-tile.component.html',
  styleUrls: ['./parent-service-tile.component.scss'],
  preserveWhitespaces: true
})

export class ParentServiceTileComponent extends AbstractPageComponent implements OnInit {

  readonly ARCHIVED: string = SERVICE_ARCHIVED;
  readonly parentServiceTileMode = ParentServiceTileMode;

  error: string;
  loaded: boolean = false;
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  statusStyles: any = {'event-tile': true, 'has-parent': false, 'same-parent': false};
  @Input() mode: string = ParentServiceTileMode.NORMAL;
  @Input() service: MiService;

  constructor(
    private tpsService: TenantMiParentServiceService,
    private tenantService: TenantMiServiceService,
    private store: Store<psdReducer.State>
  ) {
    super();
  }

  ngOnInit() {

    this.pageLoaded$ = this.store.select(psdReducer.ParentServicePageLoaded);

    this.store.select(psdReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe((data) => {

        if (data.parentService) {

          this.parentService = data.parentService;

          this.update();
        }
      });
  }

  onAddService(): void {
    // this.store.dispatch(new psdActions.ParentServiceAddService(this.service));
    this.loaded = false;
    this.tpsService.addService(this.parentService._id, this.service._id)
      .toPromise()
      .then((parentService) => {
        this.parentService = parentService;
        this.store.dispatch(new psdActions.ParentServiceLoadSuccess({
          parentService: parentService
        }));
      })
      .catch(e => this.error = e);
  }

  onRemoveService(): void {

    if(this.parentService.services.length <= 1){

      if (!confirm('Deleting the only service on a Parent Service will destroy the Parent. Are you sure you wish to do this?')) {
        return;
      }

    }

    // this.store.dispatch(new psdActions.ParentServiceAddService(this.service));
    this.loaded = false;

    this.tpsService.removeService(this.parentService._id, this.service._id)
      .toPromise()
      .then((parentService) => {
        this.parentService = parentService;
        this.store.dispatch(new psdActions.ParentServiceLoadSuccess({
          parentService: parentService
        }));
      })
      .catch(e => this.error = e);
  }

  onToggleNotification(e): void {

    this.tenantService
      .updateServiceNotifications(this.service._id, {ignore: !e})
      .toPromise()
      .then((service) => {
        this.service = service;
        this.tpsService.getById(this.parentService._id)
          .toPromise()
          .then((parentService) => {
            this.parentService = parentService;
            this.store.dispatch(new psdActions
              .ParentServiceLoadSuccess({parentService: this.parentService}));
          })
          .catch(e => this.error = e);

      })
      .catch(e => {
        this.error = e;
      });

  }

  update(): void {

    // Check to see whether THIS service
    // is on the newly-returned
    // Parent Service.

    if (!this.service) {
      return;
    }

    let altService: MiService = this.parentService.services.find((service) => {
      return service.display_id === this.service.display_id;
    });

    if (altService) {

      // Assume that if the service is listed on the parent,
      // the parent should be on the service.
      this.service.parent_service = altService.parent_service;

    } else {

      // This component's service isn't supposed to
      // be on the parent service ... but is it?

      if (
        this.service.parent_service
        &&
        this.service.parent_service.display_id === this.parentService.display_id
      ) {
        // The service IS attached to the parent, but it shouldn't be.
        // Un-assign it locally.
        this.service.parent_service = null;
      }
    }

    if (this.service.parent_service) {
      this.statusStyles = {
        ...this.statusStyles,
        'has-parent': true,
        'same-parent': this.service.parent_service._id === this.parentService._id
      };
    } else {
      this.statusStyles = {
        ...this.statusStyles,
        'has-parent': false,
        'same-parent': false
      };
    }

    this.loaded = true;

  }
}
