import {Component, Input} from "@angular/core";
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';
import { DetailHeaderNavData, HeaderNavSpecTypes, HeaderNavSubNavTypes } from 'src/shared/ng-models/DetailHeaderNavData.interface';

@Component({
  selector: 'mi-employee-details-header-items',
  templateUrl: './employee-details-header-items.component.html',
  styleUrls: ['./employee-details-header-items.component.scss']
})

export class EmployeeDetailsHeaderItemsComponent{
  @Input() employee: TenantUser;
  @Input() data: DetailHeaderNavData;
  
  DATE = HeaderNavSpecTypes.DATE;
  CURRENCY = HeaderNavSpecTypes.CURRENCY;
  NAV_TYPE_STANDARD = HeaderNavSubNavTypes.STANDARD;
}
