<div>
  <mi-server-error [error]="loadError"></mi-server-error>
  <ng-container *ngIf="financialsForm; else loading">
    <form *ngIf="financialsForm" [formGroup]="financialsForm" (ngSubmit)="onFinancialSubmit(financialsForm.value)"
      autocomplete="off">
      <div class="card-1">
      <h3>Billing Frequency</h3>
      <div class="clear-fix">
        <div class="billing-frequency">
          <mi-extended-input labelText="How frequently does this service bill you?" [labelRequired]="true"
            [(inputControl)]="financialsForm.controls['billing_frequency']"
            [(inputErrors)]="financialsForm.controls['billing_frequency'].errors"
            [(inputTouched)]="financialsForm.controls['billing_frequency'].touched" [errorDefs]='{
                               required: "Frequency is required.",
                               min: "Billing frequency must be at least 1.",
                               integer: "Billing frequency must be a whole number. Ex: 1, 2, 6, etc."
                               }'>
            <select [ngModel]="frequencyDropdownValue" [ngModelOptions]="{standalone: true}"
              (change)="onBillFrequencySelect($event)">
              <option disabled="disabled" [value]="100">Select Frequency</option>
              <option [value]="1">Monthly</option>
              <option [value]="3">Quarterly</option>
              <option [value]="12">Annually</option>
              <option [value]="24">Every 2 Years</option>
              <option [value]="36">Every 3 Years</option>
              <option [value]="0">Other</option>
            </select>
            <div class="frequency-custom" [ngClass]="{'hidden': frequencyDropdownValue !== 0}">
              <div class="non-input-label">Enter Months</div>
              <input type="number" class="form-control" formControlName="billing_frequency" min="1" placeholder="Ex. 1"
                mi-restrict-input miRestrictType="Integer">
              <span>Month(s)</span>
            </div>
          </mi-extended-input>
        </div>
      </div>
      </div>
      <!-- <div class="usage-based">
              <div class="non-input-label">Usage Based or {{(defaultCurrency?defaultCurrency.code:'USD') | currencySymbol}}0
                Fee</div>
        <input type="checkbox" formControlName="allow_zero_mrc" id="allow_zero"><label for="allow_zero">Service has a
          usage charge and/or {{(defaultCurrency?defaultCurrency.code:'USD') | currencySymbol}}0 recurring fee.</label>
      </div> -->
      <div class="card-1">
      <table class="calculator">
        <thead>
          <td class="quantity-cell">Quantity</td>
          <td>Cost Type</td>
          <td>Unit Cost {{(defaultCurrency?defaultCurrency.code:'USD')}}</td>
          <td>Totals</td>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2" class="quantity-cell">
              <input type="number" class="form-control" formControlName="quantity" placeholder="Quantity" mi-restrict-input
                miRestrictType="Number" mi-select-on-focus>
              <mi-extended-input labelText="Currency" [(inputControl)]="financialsForm.controls['tenant_currency_id']"
                [(inputErrors)]="financialsForm.controls['tenant_currency_id'].errors"
                [(inputTouched)]="financialsForm.controls['tenant_currency_id'].touched">
                <select id="tenant_currency_id" (change)="onCurrencyChanged($event.target.value)" class="form-control"
                  name="tenant_currency_id" placeholder="" formControlName="tenant_currency_id" type="text" autocomplete="none">
                  <option *ngFor="let currency of tenantCurrencies" [value]="currency.id">
                    {{ currency.name + ' (' + currency.code +')'}}
                  </option>
                </select>
                </mi-extended-input>
                </td>
                <td>
                  <span class="required">Recurring Unit Cost</span>
                </td>
                <td>
              <mi-extended-input [inputControl]="financialsForm.controls['cost']"
                [inputErrors]="financialsForm.controls['cost'].errors"
                [inputTouched]="financialsForm.controls['cost'].touched" [errorDefs]='{
                required: "Cost is required",
                notANumber: "Recurring costs can only be numbers.",
                min: "Recurring costs must be 0 or greater."
              }'>
                <input type="number" min="0" class="form-control" formControlName="cost" placeholder="Unit Cost" mi-restrict-input
                  miRestrictType="Number" mi-select-on-focus>
                </mi-extended-input>
                </td>
                <td class="totals">
              <span>
                {{quantity}}
                x
                {{cost | currency:(defaultCurrency?defaultCurrency.code:'USD'):'symbol':'1.2-2'}}
                =
                {{total_cost | currency:(defaultCurrency?defaultCurrency.code:'USD'):'symbol':'1.2-2'}}
              </span>
            </td>
            </tr>
            <tr>
              <td>
                <span class="required">Non-Recurring Unit Cost</span>
              </td>
              <td>
              <mi-extended-input [inputControl]="financialsForm.controls['non_recurring_cost']"
                [inputErrors]="financialsForm.controls['non_recurring_cost'].errors"
                [inputTouched]="financialsForm.controls['non_recurring_cost'].touched" [errorDefs]='{
                required: "Non-Recurring costs must be 0 or greater.",
                min: "Non-Recurring costs must be 0 or greater.",
                notANumber: "Non-recurring costs can only be numbers."
              }'>
                <input type="number" min="0" class="form-control" formControlName="non_recurring_cost" placeholder="Non-Recurring Cost"
                  mi-restrict-input miRestrictType="Number" mi-select-on-focus>
                </mi-extended-input>
                </td>
                <td class="totals">
              <span>
                {{quantity}}
                x
                {{non_recurring_cost | currency:(defaultCurrency?defaultCurrency.code:'USD'):'symbol':'1.2-2'}}
                =
                {{total_non_recurring_cost | currency:(defaultCurrency?defaultCurrency.code:'USD'):'symbol':'1.2-2'}}
              </span>
            </td>
            </tr>
        </tbody>
      </table>


      <div class="note-info-1 button-container">
        <ng-container *ngIf="detaileditmode===false">
          <button mi-button type="submit" [disabled]="!financialsForm.valid" buttonTheme="dark-theme"
            (click)="onFinancialSubmit(financialsForm.value)">
            Apply
          </button>
        </ng-container>
        <ng-container *ngIf="detaileditmode===true">
          <button type="button" mi-button buttonTheme="light-theme" (click)="onCancel()">
            Cancel
          </button>
          <button class="ml-2" mi-button type="submit" [disabled]="!financialsForm.valid" buttonTheme="dark-theme">
            Apply
          </button>
        </ng-container>
      </div>
      </div>
    </form>
  </ng-container>
  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>
</div>