<ng-container *ngIf="orderState; else loading">

  <div class="half-col">

    <ng-container *ngIf="activeIndex; else static">

      <h2>Service Address(es)</h2>

      <ul class="options">
        <li>
          <input id="NotSure"
                 type="radio"
                 [value]="NO_ADDRESSES"
                 [name]="radioSelect"
                 [checked]="radioSelect === NO_ADDRESSES"
                 (change)="onRadioSelect($event)"/>
          <label for="NotSure">
            This service does not have an address
          </label>
        </li>
        <li>
          <input id="OneAddress"
                 type="radio"
                 [value]="ONE_ADDRESS"
                 [name]="radioSelect"
                 [checked]="radioSelect === ONE_ADDRESS"
                 (change)="onRadioSelect($event)"/>
          <label for="OneAddress">
            This service has one address
          </label>
        </li>
        <li>
          <input id="TwoAddresses"
                 type="radio"
                 [value]="TWO_ADDRESSES"
                 [name]="radioSelect"
                 [checked]="radioSelect === TWO_ADDRESSES"
                 (change)="onRadioSelect($event)"/>
          <label for="TwoAddresses">
            This service has two addresses
          </label>
        </li>
      </ul>

      <form [formGroup]="locationsForm" (submit)="onFormSubmit(locationsForm.value)">

        <div class="form-field" *ngIf="radioSelect === ONE_ADDRESS || radioSelect === TWO_ADDRESSES">

          <ng-container *ngIf="locationOne; else locationOneInput">
            <mi-address-display [location]="locationOne"
                                (onDeselect)="resetLocationOne()"></mi-address-display>
          </ng-container>

          <mi-server-error [error]="error"></mi-server-error>

          <ng-template #locationOneInput>
            <mi-extended-input labelText="Address One"
                               [labelRequired]="true"
                               [(inputControl)]="locationsForm.controls['location_one']"
                               [(inputErrors)]="locationsForm.controls['location_one'].errors"
                               [(inputTouched)]="locationsForm.controls['location_one'].touched">
              <mi-find-or-create-location
                (locationSelected)="onLocationOneSelected($event)">

              </mi-find-or-create-location>

            </mi-extended-input>
          </ng-template>

        </div>
        <div class="form-field" *ngIf="radioSelect === TWO_ADDRESSES">
          <ng-container *ngIf="locationTwo; else locationTwoInput">
            <mi-address-display [location]="locationTwo"
                                (onDeselect)="resetLocationTwo()"></mi-address-display>
          </ng-container>

          <mi-server-error [error]="error"></mi-server-error>

          <ng-template #locationTwoInput>
            <mi-extended-input labelText="Address Two"
                               [labelRequired]="true"
                               [(inputControl)]="locationsForm.controls['location_two']"
                               [(inputErrors)]="locationsForm.controls['location_two'].errors"
                               [(inputTouched)]="locationsForm.controls['location_two'].touched">
              <mi-find-or-create-location
                (locationSelected)="onLocationTwoSelected($event)">

              </mi-find-or-create-location>
            </mi-extended-input>
          </ng-template>
        </div>

        <div class="button-container">
          <!--
          <a mi-button
             buttonTheme="light-theme"
             (click)="onCancelCLicked()">
            Cancel
          </a>
          -->
          <button type="submit"
                  mi-button
                  buttonTheme="dark-theme"
                  [disabled]="!locationsForm.valid">
            Next
          </button>
        </div>
      </form>

    </ng-container>

    <ng-template #static>

      <dl>
        <dt *ngIf="radioSelect === ONE_ADDRESS || radioSelect === TWO_ADDRESSES">
          Address One
        </dt>
        <dd *ngIf="radioSelect === ONE_ADDRESS || radioSelect === TWO_ADDRESSES">

          {{locationOne.address.formatted_address}} <br/>
          {{locationOne.label}}

        </dd>
        <dt *ngIf="radioSelect === TWO_ADDRESSES">
          Address TWO
        </dt>
        <dd *ngIf="radioSelect === TWO_ADDRESSES">

          {{locationTwo.address.formatted_address}} <br/>
          {{locationTwo.label}}

        </dd>
        <dt *ngIf="radioSelect === NO_ADDRESSES">
          Addresses
        </dt>
        <dd *ngIf="radioSelect === NO_ADDRESSES">
          This service has no addresses
        </dd>
      </dl>

    </ng-template>

  </div>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


