import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as SDReducer from '../../state/reducers';
import { MiServiceDetails } from '../../../../../../shared/models/MiService';
import { AbstractPageComponent } from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import FeDateUtil from 'src/shared/utils/FeDate.util';

@Component({
  selector: 'mi-service-details-header-group-events',
  templateUrl: './service-details-header-events.component.html',
  styleUrls: ['../service-details-header-items.scss', './service-details-header-events.component.scss']
})

export class ServiceDetailsHeaderGroupEventsComponent extends AbstractPageComponent implements OnInit {

  //parentServiceDetails: MiServiceDetails;
  parentServiceCalculationDetails: any;
  title: string;
  termStatus: string;
  inActionValue: number;
  inActionText: string;
  inActionCount: number | string;
  endOfTermAction: string;
  dataIntegrity: string;
  tenant_id: string
  bottomMessage: string
  serviceStatus: string
  constructor(
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit() {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentServiceDetails) {
            //this.parentServiceDetails = data.parentServiceDetails;
            this.parentServiceCalculationDetails = data.parentServiceCalculationDetails;
            this.getEventDetails()
          }
        }
      );
  }

  getTermStatus(inTermValue) {
    switch (inTermValue) {
      case null:
        return "Unknown"
      case ("IN_TERM"):
        return "In Term"
      case ("OUT_OF_TERM"):
        return "Out of Term"
      default:
        return "Unknown"
    }
  }
  
  getEventDetails() {
    /*
    this.endOfTermAction = this.parentServiceDetails.events_and_service_status.next_event
    this.dataIntegrity = this.parentServiceDetails.events_and_service_status.data_integrity_status != null ? this.parentServiceDetails.events_and_service_status.data_integrity_status.toLocaleLowerCase() : null
    const inActionCount = this.parentServiceDetails.events_and_service_status.number_of_auto_renew;
    const inActionValue = this.parentServiceDetails.events_and_service_status.next_event_value
    const termStatus = this.getTermStatus(this.parentServiceDetails.service_data.in_term)
    const terminationDate =  this.parentServiceDetails.service_data.termination_date != null ? FeDateUtil.toFormat(this.parentServiceDetails.service_data.termination_date, 'MM/dd/yyyy') : null
    const daysToNextEvent = this.parentServiceDetails.events_and_service_status.days_to_event
    */
    this.endOfTermAction = this.parentServiceCalculationDetails.next_event.end_of_term_action;
    this.dataIntegrity = this.parentServiceCalculationDetails.data_integrity != null ? this.parentServiceCalculationDetails.data_integrity.toLocaleLowerCase() : null;
    const inActionCount = this.parentServiceCalculationDetails.current_dates.auto_renew_count;
    const inActionValue = this.parentServiceCalculationDetails.next_event.next_event_value;
    const termStatus = this.getTermStatus(this.parentServiceCalculationDetails.in_term);
    const terminationDate =  this.parentServiceCalculationDetails.current_dates.termination_date != null ? FeDateUtil.toFormat(this.parentServiceCalculationDetails.current_dates.termination_date, 'MM/dd/yyyy') : null;
    const daysToNextEvent = this.parentServiceCalculationDetails.next_event.days_to_next_event;
    switch (this.endOfTermAction) {
      case 'AUTO_RENEWAL':
        if (this.dataIntegrity === "complete") {
          this.title = `Auto Renewing in ${daysToNextEvent} Days`
          this.termStatus = termStatus
          this.inActionValue = inActionValue
          this.inActionCount = inActionCount
          this.serviceStatus = 'AUTO_RENEWAL_COMPLETE'
        } else if (this.dataIntegrity === "incomplete") {
          this.serviceStatus = 'AUTO_RENEWAL_INCOMPLETE'
          this.title = `Unmanaged Renewal Date`
          this.bottomMessage = `MISO does not know the date this service is set to auto renew.`
        }
        break;
      case 'EXPIRING':
        if (this.dataIntegrity === "complete") {
          this.serviceStatus = 'EXPIRING_COMPLETE'
          this.title = `Expiring in ${daysToNextEvent} Days`
          this.termStatus = termStatus
          this.inActionValue = inActionValue
          this.inActionCount = inActionCount
        } else if (this.dataIntegrity === "incomplete") {
          this.serviceStatus = 'EXPIRING_INCOMPLETE'
          this.title = `Unmanaged Renewal Date`
          this.bottomMessage = `MISO does not know the date this service is set to expire.`
        }
        break;
      case 'EXPIRED':
        this.serviceStatus = 'EXPIRED'
        this.title = `Expired (${daysToNextEvent}) Days Ago`
        this.termStatus = termStatus
        this.inActionValue = inActionValue
        this.inActionCount = inActionCount
        break;
      case 'PENDING_TERMINATION':
        this.serviceStatus = 'PENDING_TERMINATION'
        this.title = `Planned Cancellation in ${daysToNextEvent} Days`
        this.termStatus = termStatus
        this.inActionValue = inActionValue
        this.inActionCount = inActionCount
        break;
      case 'TERMINATED':
        this.serviceStatus = 'TERMINATED'
        this.title = `Terminated on ${terminationDate}`
        this.bottomMessage = `This service is inactive in MISO.`
        break;
        case 'UNKNOWN':
        this.serviceStatus = 'UNKNOWN'
        this.title = `Unmanaged Renewal Date and EOTA`
        this.bottomMessage = `MISO does not know the date this service is to expire  and the end-of-term action to apply.`
        break;
      case null:
        this.serviceStatus = 'NULL'
        this.title = `Unmanaged End of Term Action`
        this.bottomMessage = `MISO does not know the end-of-term action to apply to this service.`
        break;
    }
  }
}