import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {EmailFormRecipient} from '../../../shared/ng-models/EmailFormRecipient.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Email} from '../../../shared/models/Email.interface';
import {EmailRecipient} from '../../../shared/models/EmailRecipient.interface';
import {TenantEmailService} from '../../../libs/api/tenant/tenantEmail.service';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {FileUpload} from '../../../shared/models/FileUpload';
import {EMAIL_TYPE_OTHER, EMAIL_TO, EMAIL_CC} from '../../../common/utils/sharedConstants';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-email-composer',
  styleUrls: ['./email-composer.component.scss'],
  templateUrl: './email-composer.component.html'
})

export class EmailComposerComponent extends AbstractPageComponent implements OnInit {

  emailForm: FormGroup;

  @Input() body: string;
  @Input() cancelUrl: string; // Where to re-direct when the User clicks "Cancel".
  @Input() defaultRecipients: EmailFormRecipient[]; // List of potential recipients directly related to this message.
  @Input() subject: string;
  @Input() attachments: FileUpload[] = [];
  @Input() vendorName: string;
  @Input() services: any[] = [];
  @Input() type: string = EMAIL_TYPE_OTHER;

  @Output() emailCreated: EventEmitter<Email> = new EventEmitter<Email>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  error: any;
  loaded: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private tenantEmailService: TenantEmailService
  ) {
    super();
  }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      toRecipients: [null, Validators.required],
      ccRecipients: [null],
      subject: [this.subject, Validators.required],
      body: [this.body, Validators.required],
      type: this.type
    });
    this.loaded = true;
  }

  onRecipientsUpdated(e: any): void {
    if (e.to_recipients) {
      this.emailForm.get('toRecipients')
        .setValue((e.to_recipients).map((contact) => {
          return {
            ...contact,
            addressField: EMAIL_TO
          };
        }));
    }
    if (e.cc_recipients) {
      this.emailForm.get('ccRecipients')
        .setValue((e.cc_recipients).map((contact) => {
          return {
            ...contact,
            addressField: EMAIL_CC
          };
        }));
    }
    this.emailForm.updateValueAndValidity();
  }

  onSendEmail(data: any): void {

    let service_ids = [], service_display_ids = [];

    this.error = '';

    if (this.services && this.services.length) {
      service_ids = this.services.map(function (item) {
        return item['_id'];
      });
      service_display_ids = this.services.map(function (item) {
        return item['display_id'];
      });
    }

    const recipients: EmailRecipient[] = [];

    const allTo = [...data.ccRecipients, ...data.toRecipients];
    allTo.forEach((recipient: EmailFormRecipient) => {
      recipients.push({
        name: `${recipient.first_name} ${recipient.last_name}`,
        email: recipient.email,
        type: recipient.addressField,
        collection_ref: recipient.type,
        collection_id: recipient._id,
        display_id: recipient.display_id
      });
    });

    const email: Email = {
      subject: data.subject,
      body: data.body,
      attachments: this.attachments,
      services: service_ids,
      service_display_ids: service_display_ids,
      recipients,
      type: this.type
    };

    this.tenantEmailService.createEmail(email)
      .subscribe(
        (emailResponse: Email) => this.emailCreated.emit(emailResponse),
        (e) => {
          this.loaded = true;
          this.error = e;
        }
      );

    this.loaded = false;

  }

  onCancel() {
    this.canceled.emit();
  }
}
