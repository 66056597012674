import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {formatUsPhone} from '../../../common/utils/stringFormatter';

export function validateTelephone(c: FormControl) {

  if (c.value) {
    const value: string = c.value;
    const value_number = value.replace(/^[0-9]+/g, '');
    const error = {'badTelephone': {value: value}};

    return (value_number + '').length > 9 ? null : error;
  }
  return true;
}


@Component({
  selector: 'mi-telephone-simple-input',
  templateUrl: './telephone-simple-input.component.html',
  styleUrls: ['./telephone-simple-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TelephoneSimpleInputComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useValue: validateTelephone,
      multi: true
    }]
})


export class TelephoneSimpleInputComponent implements ControlValueAccessor, OnInit {
  @Input() forceUS: boolean = false;
  @Input() maxLength: number = 20;
  @Input() disable: boolean = false;
  @Input() tabindex: number = null;

  _value: string = '';
  placeholder: string;

  private propagateChange = (_: any) => {
    // Placeholder for implement.
  }

  private propagateTouch = (_: any) => {
    // Placeholder for implement.
  }

  constructor() {

  }

  onDisplayChange(value: string) {
    this.assignNewValue(value);
  }

  assignNewValue(newValue: string) {

    let number: number = Number.MIN_SAFE_INTEGER;
    let newDisplay = '';

    if (newValue !== '') {

      let possibleNumber = Number((newValue + '').replace(/[^0-9]/g, ''));
      if (possibleNumber) {
        number = possibleNumber;
      }

      newDisplay = formatUsPhone(newValue);
    }

    this._value = newDisplay;
    this.propagateChange((number !== Number.MIN_SAFE_INTEGER) ? this._value : '');
  }

  ngOnInit() {
    this.setDefault();
    // console.log('this disables in simple telephone', this.disable);
  }

  setDefault() {
    if (!this._value) {
      this._value = ''
    }
  }


  inputTouched() {
    // this assigned should write the correct value on blur :
    // this.assignNewValue(this._value);
    this.propagateTouch(this._value);
  }

  public writeValue(obj: string) {
    if (obj) {
      this.assignNewValue(obj);
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

}
