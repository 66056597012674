import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import {ISecureUser} from "../../../../common/security/secureUser";
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import * as userReducer from "../../../../libs/user-store/state/reducers";
import {TenantUsersService} from "../../../../libs/api/tenant/tenantUsers.service";
import {combineLatest} from 'rxjs';
import {EmailFormRecipient} from "../../../../shared/ng-models/EmailFormRecipient.interface";
import {Contact} from "../../../../shared/models/Contact";
import {TenantContactsService} from "../../../../libs/api/tenant/tenantContacts.service";
import {FindOrCreateContactComponentSteps} from "../../find-or-create-contact/find-or-create-contact.component";
import {MisoCollection} from "../../../../shared/models/MisoCollection.enum";
import {EMAIL_CC, EMAIL_TO} from "../../../../common/utils/sharedConstants";

@Component({
  selector: 'mi-email-recipient-select',
  styleUrls: ['./email-recipient-select.component.scss'],
  templateUrl: './email-recipient-select.component.html'
})

export class EmailRecipientSelectComponent extends AbstractPageComponent implements OnInit {

  @Input() vendorName : string;
  addFormOpen:boolean = false;
  // List of potential recipients directly related to this message.
  @Input() defaultRecipients: EmailFormRecipient[];
  // Potential recipients selected and added to the options:
  addedRecipients: EmailFormRecipient[] = [];
  ccRecipients: EmailFormRecipient[] = [];
  loaded: boolean = false;
  // List of additional potential recipients:
  otherRecipients: EmailFormRecipient[] = [];
  internalRecipients: EmailFormRecipient[] = [];
  vendorRecipients: EmailFormRecipient[] = [];

  toRecipients: EmailFormRecipient[] = [];
  @Output() updateRecipients: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private store: Store<userReducer.State>,
    private tenantContactService:TenantContactsService,
    private userService: TenantUsersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addedRecipients=this.addedRecipients.concat(this.defaultRecipients);
    this.toRecipients = this.defaultRecipients;
    this.updateRecipients.emit({
      to_recipients:this.toRecipients,
      cc_recipients:this.ccRecipients
    });
    this.loaded = false;
    this.init();
  }
  init(){
    // Gather info about THIS user:
    const userStateData: Observable<any> = this.store.select(userReducer.getUserState);
    // Gather info about the Tenant:
    const allUsersData: Observable<ISecureUser[]> = this.userService.getUsers();
    // Gather ALL Contacts:
    const allContacts: Observable<Contact[]> = this.tenantContactService.getAllContacts();

    combineLatest(userStateData, allUsersData, allContacts)
      .takeUntil(this.destroy$)
      .subscribe((allValues) => {

        const user: ISecureUser = allValues[0].secureUser;
        const userId: string = user._rawData._id;
        const tenantUsers: ISecureUser[] = allValues[1];
        const allContacts: Contact[] = allValues[2];

        // Extra Tenant contacts not directly associated with this Service:
        let otherContact:EmailFormRecipient[] = [];
        let vendorContact:EmailFormRecipient[] = [];
        allContacts.map((contact:Contact)=>{
          if(this.vendorName && contact.vendor.vendor_name== this.vendorName){
            this.pushToRecipient(contact, vendorContact)
          }else{
            this.pushToRecipient(contact, otherContact)
          }
        })

         otherContact = otherContact.filter((emailRecipient:EmailFormRecipient)=>{
          if(this.addedRecipients){
            return !this.addedRecipients.some((er)=>{
              return er._id == emailRecipient._id;
            })
          }
        });

        if(vendorContact && vendorContact.length){
          vendorContact = vendorContact.filter((emailRecipient:EmailFormRecipient)=>{
            if(this.addedRecipients){
              return !this.addedRecipients.some((er)=>{
                return er._id == emailRecipient._id;
              })
            }
          });
          this.vendorRecipients = vendorContact
          .sort((a,b)=>{
            return a.first_name > b.first_name ? 1 : -1;
          })
          .sort((a,b)=>{
            return a.last_name > b.last_name ? 1 : -1;
          });
        }

        // Internal Users attached to the Tenant:
        let internalEmailRecipients = tenantUsers.filter(user => {
          const safeData = user.getSafeData();
          return safeData._id !== userId;
        }).map(user => {
          const safeData = user.getSafeData();
          return {
            _id: safeData._id,
            first_name: safeData.first_name,
            last_name: safeData.last_name,
            email: safeData.email,
            type: MisoCollection.USERS
          }
        });

        internalEmailRecipients = internalEmailRecipients.filter((emailRecipient:EmailFormRecipient)=>{
          if(this.addedRecipients){
            return !this.addedRecipients.some((er)=>{
              return er._id == emailRecipient._id;
            })
          }
        });

        // sort:
        this.otherRecipients = otherContact
          .sort((a,b)=>{
            return a.first_name > b.first_name ? 1 : -1;
          })
          .sort((a,b)=>{
            return a.last_name > b.last_name ? 1 : -1;
          });
        this.internalRecipients = internalEmailRecipients
          .sort((a,b)=>{
            return a.first_name > b.first_name ? 1 : -1;
          })
          .sort((a,b)=>{
            return a.last_name > b.last_name ? 1 : -1;
          });

        this.loaded = true;
      });

  }

  pushToRecipient(contact, contactArray) {
    let aContact = {
      _id: contact._id,
      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email,
      role: contact.role,
      type: MisoCollection.CONTACTS,
      display_id: contact.display_id
    }
    contactArray.push(aContact);
  }

  onAddContactClicked():void{
    this.addFormOpen = true;
  }

  onCloseAddContactForm(evt):void{
    if(!evt){
      this.addFormOpen = false;
    }else if(evt === FindOrCreateContactComponentSteps.SEARCH_STATE){
      this.addFormOpen = false;
    }
  }

  onAddRecipient(emailRecipient:EmailFormRecipient):void{
    this.addedRecipients.push(emailRecipient);
    this.init();
  }

  onNewContactCreated(contact:Contact):void{
    this.addedRecipients.push(
      {
        _id: contact._id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        email: contact.email,
        role: contact.role,
        type: MisoCollection.CONTACTS,
        display_id: contact.display_id
      }
    );
    this.addFormOpen = false;
  }

  onRecipientSelected(emailRecipient: EmailFormRecipient, remove): void {
    if(emailRecipient.email){
      this.removeFromRecipients(emailRecipient.email, remove);
    }

    if (emailRecipient.addressField) {
      // User is being added to either the TO: or CC: list.
      if (emailRecipient.addressField === EMAIL_TO) {
        this.toRecipients.push(emailRecipient)
      } else if (emailRecipient.addressField === EMAIL_CC) {
        this.ccRecipients.push(emailRecipient);
      }
    }

    this.updateRecipients.emit({
      to_recipients:this.toRecipients,
      cc_recipients:this.ccRecipients
    });

  }

  removeFromRecipients(email: string, remove): void {

    this.toRecipients = this.toRecipients.filter((recipient: EmailFormRecipient) => {
      return recipient.email !== email;
    });
    this.ccRecipients = this.ccRecipients.filter((recipient: EmailFormRecipient) => {
      return recipient.email !== email;
    });
    if(remove){
      this.addedRecipients = this.addedRecipients.filter((recipient: EmailFormRecipient) => {
        return recipient.email !== email;
      });
      this.init();
    }
  }

}
