import {AuthService} from '../../api/auth/auth.service';
import {CanActivate, Router} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import * as linkFormatter from '../../../common/utils/linkFormatter';
import * as userActions from '../../user-store/state/actions';
import * as userReducer from '../../user-store/state/reducers';
import {catchError, map} from "rxjs/operators";
import {UserStoreService} from "../../user-store/user-store.service";

const log = createLogger(LOG_LEVELS.AUTH_GUARD);

@Injectable()
export class IsUserAuthGuard implements CanActivate {

  constructor(private router: Router,
              private userStoreService: UserStoreService,
              private store: Store<userReducer.State>) {
  }

  canActivate(): Observable<boolean> {
    return this.userStoreService.getLoggedInService()
      .pipe(
        map((res) => {
            // keep the user state updated :
            this.store.dispatch(new userActions.UserVerifySuccessAction(res));
            return res.secureUser && res.secureUser.isActivated();
        }),
        catchError( (error: any) => {
          log('this person was not logged in', error);
          window.location.href = linkFormatter.getLoginLink(window.location.href);
          return of(false);
        })
      )
  }
}
