<modal class="confirmation-modal" [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <div class="address">
      <h4>Are you sure, you want to delete this term?</h4>
    </div>
  </modal-content>
  <modal-footer>
    <div class="button-container">
      <button mi-button buttonTheme="light-theme" (click)="close()">
        Cancel
      </button>
      <button mi-button buttonTheme="alert-theme" (click)="confirmHandler()">
        Confirm
      </button>
    </div>
  </modal-footer>
</modal>