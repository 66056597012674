import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {reducer as serviceDetailsReducer} from './detail/state/reducers';
import {TenantComponentsModule} from '../../components/tenant-components.module';

import {ServiceDetailsModule} from './detail/service-details.module';
import {ServiceSingleCreateModule} from './single/create/service-single-create.module';
import {ServiceOrderCreateModule} from './orders/service-order-create.module';

import {ServiceDetailsEffects} from './detail/state/effects';

import {MiServiceDetailComponent} from './detail/detail-miservice.component';
import {MiServiceListComponent} from './list/mi-service-list.component';
import {ServicesCreateComponent} from './create/services-create.component';
import { RequestMissingDataComponent } from './request-missing-data/request-missing-data.component';
import { RequestAQuoteComponent } from './request-a-quote/request-a-quote.component';
import { MiServiceDeleteComponent } from './delete/service-delete.component';
import { AccountRepEmailComponent } from './account-representatitve-email/account-representatitve-email.component';
import { ServiceRequestorEmailComponent } from './service-requestor-email/service-requestor-email.component';
import { ReAssessmentEmailComponent } from './re-assessment-email/re-assessment-email.component';
import { CancellationReqeustEmailComponent } from './cancellation-request-email/cancellation-request-email.component';
import { QuoteMisoMarketplaceComponent } from './request-a-quote-miso-marketplace/quote-miso-marketplace.component';
import { TinyMceModule } from 'angular-tinymce';
import { miTinymceDefaultSettings } from 'src/shared/utils/miTinymceDefaultSettings';
import { MiServiceListV2Component } from './list-v2/mi-service-list-v2.component';

@NgModule({
  declarations: [
    MiServiceDetailComponent,
    MiServiceListComponent,
    ServicesCreateComponent,
    RequestMissingDataComponent,
    RequestAQuoteComponent,
    MiServiceDeleteComponent,
    AccountRepEmailComponent,
    ServiceRequestorEmailComponent,
    ReAssessmentEmailComponent,
    CancellationReqeustEmailComponent,
    QuoteMisoMarketplaceComponent,
    MiServiceListV2Component
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    StoreModule.forFeature('serviceDetails', serviceDetailsReducer),
    EffectsModule.forFeature([ServiceDetailsEffects]),
    ServiceDetailsModule,
    ServiceSingleCreateModule,
    ServiceOrderCreateModule,
    TenantComponentsModule,
    TinyMceModule.forRoot(miTinymceDefaultSettings()),
  ],
  providers: [],
  exports: []
})

export class ServiceModule {

}
