import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {OrderDraft} from '../../../shared/models/OrderDraft';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VendorService} from 'src/libs/api/miyagi/miyagiVendors';

const NO_SERVICE_ID: string = 'Service ID not provided';

@Component({
  selector: 'mi-service-id-form',
  templateUrl: './service-id-form.component.html',
  styleUrls: ['./service-id-form.component.scss']
})

export class ServiceIdFormComponent extends AbstractPageComponent implements OnInit {

  usageForm: FormGroup;
  fullState: OrderDraft;
  loading: boolean = true;
  error: any = '';
  service_id_hint: any;
  @Input() actual_vendor_id: string;
  @Output() complete: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() completeButton: string = 'Continue';
  @Input() title: string = 'Enter Service Identifier';
  @Input() service_id: string;
  selectedIndex: number = 3;
  @Input() completeServiceId: boolean = false;
  noServiceID: string = NO_SERVICE_ID;

  constructor(
    private formBuilder: FormBuilder,
    private vendorService: VendorService) {
    super();
  }

  ngOnInit() {
    this.usageForm = this.formBuilder.group(
      {
        service_identifier: [null, Validators.compose([Validators.required])]
      }
    );
    if (this.service_id === 'Not Provided') {
      this.usageForm.get('service_identifier').setValue(NO_SERVICE_ID);
      this.service_id = '';
      this.selectedIndex = 1;
    } else if (this.service_id) {
      this.selectedIndex = 3;
      this.usageForm.get('service_identifier').setValue(this.service_id);
    } else if (!this.service_id) {
      this.usageForm.get('service_identifier').setValue('I do not have my service ID or will supply at a later date');
      this.selectedIndex = 0;
    }
    if (this.actual_vendor_id) {
      this.vendorService.getVendor(this.actual_vendor_id)
        .then(vendor => {
          if (vendor.service_id_hint) {
            this.service_id_hint = {
              html: vendor.service_id_hint,
              logo: vendor.logo || null,
              vendorName: vendor.vendor_name
            };
          }
        });
    }
  }

  onFormSubmit(data: any) {
    this.onSaveCompleted(data.service_identifier);
  }


  onSaveCompleted(service_identifier: string = '') {
    if (service_identifier === 'I do not have my service ID or will supply at a later date') {
      this.complete.emit('');
    } else if (service_identifier === NO_SERVICE_ID) {
      this.complete.emit('Not Provided');
    } else {
      this.complete.emit(service_identifier);
    }
  }

}
