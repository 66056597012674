import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../../shared/logger';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import { Observable } from 'rxjs';
import {combineLatest} from 'rxjs';
import * as userReducer from "../../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import {MiService} from "../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../libs/api/tenant/tenantMiService.service";
import { Store } from '@ngrx/store';
import { EmailFormRecipient } from 'src/shared/ng-models/EmailFormRecipient.interface';
import { TenantMiParentServiceService } from 'src/libs/api/tenant/tenantMiParentService.service';


const log = createLogger(LOG_LEVELS.PAGE);


@Component({
  templateUrl: './re-quote.component.html',
  styleUrls: ['./re-quote.component.scss']
})

export class ReQuoteEmailComponent extends AbstractPageComponent implements OnInit {

  protected readonly NO_DATA: string = NO_DATA;
  blockerStyle: any = {};
  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    }
  ];
  error: string;
  display_id:string;
  loaded: boolean = false;
  alertMassage: string;
  cancelUrl: string;
  headerLinks: Link[];
  service: MiService;
  tenantContacts: EmailFormRecipient[];
  userData: any;
  tenantData: any;
  parentRoute:string = 'services';

  contactCurrent: boolean = true;
  contactMarket: boolean = true;

  buttonDisable: boolean = false;
  nextUrl: string;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private serviceService: TenantMiServiceService,
              private store: Store<userReducer.State>,
              private tpsService: TenantMiParentServiceService) {
    super();
  }

  ngOnInit(): void {
    // Find original
    this.display_id = this.activatedRoute.snapshot.params['display_id'];
    if(this.display_id.indexOf("SVP")>=0){
      this.parentRoute = 'parent-services';
    }

    // Update breadcrumbs:

    let newBreadcrumbs = [
      {
        url: `/${this.parentRoute}/${this.display_id}`,
        label: this.display_id,
        internal: true
      },
      {
        url: `/${this.parentRoute}/${this.display_id}/request-a-quote`,
        label: 'Request A Quote',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];

    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState).takeUntil(this.destroy$);
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

    if(this.parentRoute === 'parent-services'){
      const parentService = this.tpsService.getByDisplayId(this.display_id).toPromise()
      combineLatest(userStateData, parentService, tenantStateData)
      .subscribe((data)=>{
        this.userData = data[0].secureUser.getSafeData();
        this.service = data[1].current_notification_service;
        this.tenantData = data[2].tenantData;
        this.cancelUrl = `/${this.parentRoute}/${this.display_id}`;
        this.nextUrl = `${this.cancelUrl}/account-rep/request-a-quote-miso`
        this.loaded = true;
      },
        e => this.error = e);
    } else {
      const serviceData: Observable<MiService> = this.serviceService.getMiService(this.display_id).takeUntil(this.destroy$);
      combineLatest(userStateData, serviceData, tenantStateData)
      .subscribe((data)=>{
        this.userData = data[0].secureUser.getSafeData();
        this.service = data[1];
        this.tenantData = data[2].tenantData;
        this.cancelUrl = `/${this.parentRoute}/${this.service.display_id}`;
        this.nextUrl = `${this.cancelUrl}/account-rep/request-a-quote-miso`
        this.loaded = true;
      },
        e => this.error = e);}
  }

  recipientsSelect(selection){
    if(selection.target.value === "contactCurrent"){
      this.contactCurrent = selection.target.checked
    }
    if(selection.target.value === "contactMarket"){
      this.contactMarket = selection.target.checked
    }
    if(this.contactCurrent || this.contactMarket){
      this.buttonDisable = false
    } else {
      this.buttonDisable = true
    }
    if(!this.contactCurrent){
      this.nextUrl = `${this.cancelUrl}/miso-quote`
    } else if (this.contactCurrent && this.contactMarket) {
      this.nextUrl = `${this.cancelUrl}/account-rep/request-a-quote-miso`
    } else if (this.contactCurrent && !this.contactMarket) {
      this.nextUrl = `${this.cancelUrl}/account-rep/request-a-quote`
    }
  }

  cancel(){
    this.router.navigateByUrl(this.cancelUrl);
  }


  toQuotePage(){
    this.router.navigateByUrl(this.nextUrl);
  }
}
