import {Duration, DateTime} from 'luxon';

/**
 * Detects if a date is the last of the month.
 * @param {Date} day
 * @returns {boolean}
 */
function isLastDayOfMonth(day) {
  return DateTime.fromJSDate(day).month !== DateTime.fromJSDate(day).plus({days: 1}).month;
}

/**
 * Subtract Days
 * @param {Date} startDate
 * @param {Number} days
 * @returns {Date}
 */
function subtractDays(startDate, days = 0) {

  let startJsDate = startDate;
  if (typeof startDate === 'string') {
    // console.log('it was a string', startDate);
    startJsDate =  DateTime.fromISO(startDate).toJSDate();
  }

  return DateTime.fromJSDate(startJsDate)
    .minus({days})
    .toJSDate();
}


/**
 * Calculates a service end date
 * @param {Date} startDate The start date of the service
 * @param {number} termLength The term length in months
 * @returns {Date} the service end date
 */
function getEndDate(startDate, termLength) {

  let startJsDate = startDate;
  if (typeof startDate === 'string') {
    startJsDate = new Date(startDate);
  }

  const termDuration = Duration.fromObject({months: Number.parseInt(termLength + '')});
  const dayDuration = Duration.fromObject({days: 1});

  let endDate = DateTime.fromJSDate(startJsDate)
    .plus(termDuration)
    .minus(dayDuration);

  // we need to solve for the last day of the month.
  if (isLastDayOfMonth(startJsDate)) {
    endDate = [1, 1, 1, 1].reduce((accumulator, currentValue) => {
      if (isLastDayOfMonth(accumulator.toJSDate())) {
        return accumulator;
      } else {
        return accumulator.plus(dayDuration);
      }
    }, endDate);

    endDate = endDate.minus(dayDuration);

  }

  return endDate.toJSDate();
}

/**
 * Add months to date
 * @param {Date} date1
 * @return {Date} the modified date.
 */

function addMonths(date1, months) {
  let jsDate1 = date1;

  if (typeof date1 === 'string') {
    jsDate1 = new Date(date1);
  }

  const monthDuration = Duration.fromObject({months});

  return DateTime.fromJSDate(jsDate1).plus(monthDuration).toJSDate();
}

/**
 * Add days to date
 * @param {Date} date1
 * @return {Date} the modified date.
 */

function addDays(date1, days) {
  let jsDate1 = date1;

  if (typeof date1 === 'string') {
    jsDate1 = new Date(date1);
  }

  return DateTime.fromJSDate(jsDate1).plus({days: days}).toJSDate();
}

/**
 * Find the difference in months between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @return {number} the qty of months, can be negative.
 */

function monthsDiff(date1, date2) {
  let jsDate1 = date1;
  let jsDate2 = date2;

  if (typeof date1 === 'string') {
    jsDate1 = new Date(date1);
  }

  if (typeof date2 === 'string') {
    jsDate2 = new Date(date2);
  }

  return DateTime.fromJSDate(jsDate1).diff(DateTime.fromJSDate(jsDate2), ['months']).toObject().months;
}

/**
 * Find the difference in years between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @return {number} the qty of years, can be negative.
 */

function yearsDiff(date1, date2) {
  let jsDate1 = date1;
  let jsDate2 = date2;

  if (typeof date1 === 'string') {
    jsDate1 = new Date(date1);
  }

  if (typeof date2 === 'string') {
    jsDate2 = new Date(date2);
  }

  return DateTime.fromJSDate(jsDate1).diff(DateTime.fromJSDate(jsDate2), ['years']).toObject().years;
}

/**
 * Find the difference in Days between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @return {number} the qty of days, can be negative.
 */

function daysDiff(date1, date2) {
  let jsDate1 = date1;
  let jsDate2 = date2;

  if (typeof date1 === 'string') {
    jsDate1 = new Date(date1);
  }

  if (typeof date2 === 'string') {
    jsDate2 = new Date(date2);
  }

  return DateTime.fromJSDate(jsDate1).diff(DateTime.fromJSDate(jsDate2), ['days']).toObject().days;
}



/**
 * Find the difference in seconds between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @return {number} the qty of seconds, can be negative.
 */

function secondsDiff(date1, date2) {
  let jsDate1 = date1;
  let jsDate2 = date2;

  if (typeof date1 === 'string') {
    jsDate1 = new Date(date1);
  }

  if (typeof date2 === 'string') {
    jsDate2 = new Date(date2);
  }

  return DateTime.fromJSDate(jsDate1).diff(DateTime.fromJSDate(jsDate2), ['seconds']).toObject().seconds;
}

/**
 * Returns the start of the week in that timezone
 * @param {Date} start date
 * @param {string} timezone
 * @returns {boolean}
 */
function startOfWeek(test, timeZone = 'America/New_York') {

  let testJS = test;
  if (typeof test === 'string') {
    testJS = new Date(test);
  }

  let luxonLocal = DateTime.fromJSDate(testJS).setZone(timeZone).startOf('day');

  // // 1 is a monday in luxon formating
  while (Number(luxonLocal.toFormat('c')) !== 1) {
    luxonLocal = luxonLocal.minus({day: 1});
  }

  return luxonLocal.toJSDate();
}

/**
 * Returns the next instance of a specific day of the week in a given timezone
 * @param {Date} start date
 * @param {string} timezone
 * @param {number} day of the week, 1-7, Mon-Sun.
 * @returns {date}
 */
function nextDayOfWeek(date, timeZone = 'America/New_York', dayOfWeek = 1) {

  let dateJS = date;
  if (typeof date === 'string') {
    dateJS = new Date(date);
  }

  let luxonLocal = DateTime
    .fromJSDate(dateJS)
    .setZone(timeZone)
    .startOf('day')
    .plus({day:1});

  // // 1 is a monday in luxon formating
  while (luxonLocal.weekday !== dayOfWeek) {
    luxonLocal = luxonLocal.plus({day: 1});
  }

  return luxonLocal.toJSDate();

}

/**
 * Returns the next instance of a Monday in a given timezone
 * @param {Date} start date
 * @param {string} timezone
 * @returns {date}
 */
function nextMonday(date, timeZone = 'America/New_York') {
  return nextDayOfWeek(date, timeZone, 1);
}

/**
 * Checks if a date is past 10am.
 * @param {Date} start date
 * @param {string} timezone
 * @returns {boolean}
 */
function after10am(test, timeZone = 'America/New_York') {

  let testJS = test;
  if (typeof test === 'string') {
    testJS = new Date(test);
  }

  const luxonLocal = DateTime.fromJSDate(testJS).setZone(timeZone);
  const isAfter10 = (Number(luxonLocal.toFormat('H')) >= 10);

  return isAfter10;
}


/**
 * Checks if a date is past 12am.
 * @param {Date} start date
 * @param {string} timezone
 * @returns {boolean}
 */
function is12am(test, timeZone = 'America/New_York') {

  let testJS = test;
  if (typeof test === 'string') {
    testJS = new Date(test);
  }

  const luxonLocal = DateTime.fromJSDate(testJS).setZone(timeZone);
  const is12am = (Number(luxonLocal.toFormat('H')) === 0);

  return is12am;
}


/**
 * Returns if both dates are in the same week
 * @param {Date} first date
 * @param {Date} second date
 * @param {string} timezone
 * @returns {boolean}
 */
function isSameWeek(d1, d2, timeZone = 'America/New_York') {
  return startOfWeek(d1, timeZone).toString() === startOfWeek(d2, timeZone).toString();
}

/**
 * Returns the whole days between the 2 dates in a given timezone
 * @param {Date} start date
 * @param {Date} end date
 * @param {string} timezone
 * @returns {number}
 */
function wholeDaysBetween(start, end, timeZone = 'America/New_York') {

  let startJs = start;
  let endJS = end;
  if (typeof start === 'string') {
    startJs = new Date(start);
  }
  if (typeof end === 'string') {
    endJS = new Date(end);
  }

  const startOfDayStart = DateTime.fromJSDate(startJs).setZone(timeZone).startOf('day');
  const startOfDayEnd = DateTime.fromJSDate(endJS).setZone(timeZone).startOf('day');

  return Math.abs(startOfDayEnd.diff(startOfDayStart, 'days').toObject().days);
}

/**
 * Get only the month, day year of a date in a string format.
 * @param {Date} the date
 * @param {string} timezone
 * @returns {string} a string in format yyyy-MM-dd
 */
function datetimeToOnlyDate(date, timeZone = 'America/New_York') {

  let dateJS = date;
  if (typeof date === 'string') {
    dateJS = new Date(date);
  }

  const luxonLocal = DateTime.fromJSDate(dateJS);
  // const luxonLocal = DateTime.fromJSDate(dateJS).setZone(timeZone);

  return luxonLocal.toFormat('yyyy-MM-dd');
}


/**
 * Days from today
 * @param {Date} start start date
 * @returns {number}
 */
function daysFromToday(start) {
  let startJs = start;
  if (typeof start === 'string') {
    startJs = new Date(start);
  }

  const today = new Date();
  const diff = DateTime.fromJSDate(startJs).diff(DateTime.fromJSDate(today), 'days').toObject().days;

  return diff >= 0 ? -~diff : ~~diff;
}

/**
 * Is the day given before today
 * @param date {Date}
 * @returns {boolean}
 */
function isBeforeToday(date) {
  return daysDiff(new Date(), date) > 0;
}

/**
 * Format Date
 * @param {Date} date
 * @param {string} format
 * @returns {string}
 */
function toFormat(date, format) {
  let dateJs = date;
  if (typeof date === 'string') {
    dateJs = new Date(date);
  }

  return DateTime.fromJSDate(dateJs).toFormat(format);
}

function applyTimeZoneFromISO(date, timezone) {
  const dateJs = date;
  const utc = DateTime.fromISO(dateJs, { zone: 'UTC' });
  const newtime = utc.setZone(timezone);
  return newtime;
}


function getLocalDateTIme() {
  const dateTime = DateTime.local();
  return dateTime.toMillis();
}

function timeElapsed(milliSeconds: number): string {
  let timeString: string = "";
  let seconds = Math.round(milliSeconds/1000);
  timeString += seconds + " s";
  if(seconds >= 60) {
    let minutes = Math.floor(seconds/60);    
    seconds %= 60;
    timeString = minutes + " m " + seconds + " s";
    if(minutes>=60) {
      let hours = Math.floor(minutes/60);
      minutes %= 60;
      timeString = hours + " h " + minutes + " m " + seconds + " s";
    }
  }
  
  return timeString;
}


const DateUtil = {
  daysDiff,
  secondsDiff,
  isBeforeToday,
  wholeDaysBetween,
  after10am,
  isLastDayOfMonth,
  getEndDate,
  daysFromToday,
  startOfWeek,
  isSameWeek,
  is12am,
  monthsDiff,
  yearsDiff,
  addMonths,
  addDays,
  toFormat,
  nextMonday,
  subtractDays,
  datetimeToOnlyDate,
  applyTimeZoneFromISO,
  getLocalDateTIme,
  timeElapsed
};

export default DateUtil;
