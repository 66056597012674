import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
// import { createLogger, LOG_LEVELS } from "../../../../../../shared/logger";
// import { ProductCategory } from '../../../../../../shared/models/ProductCategory';
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
// import { AccountId } from 'src/shared/models/AccountId';
import { ToastrService } from 'ngx-toastr';

// const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-account-id-service-term',
  templateUrl: './account-id-service-term.component.html',
  styleUrls: ['./account-id-service-term.component.scss']
})

export class AccountIdServiceTermComponent extends AbstractPageComponent implements OnInit {
  title = '';
  @Input() accountId: any;
  serviceTerm: ServiceTerm;
  newTermId: string;
  isOpen: boolean = true;
  error: string = '';
  NO_DATA = NO_DATA;
  isServiceTermRemoved: boolean = false
 

  constructor(
    private toastr: ToastrService,
    private serviceTermService: ServiceTermService) {
    super();
  }

  ngOnInit() {
    if (this.accountId.account.service_term_id && this.accountId) {
      this.serviceTerm = {
        id: this.accountId.account.service_term_id,
        auto_renew_length: this.accountId.auto_renew_length,
        days_to_notify: this.accountId.days_to_notify,
        display_id: this.accountId.service_term_id,
        event_type: this.accountId.service_term_event_type,
        label: this.accountId.account.label,
        tenant_id: this.accountId.account.owner
      }
    } else {
      this.serviceTerm = null
    }
    // this.getAccountServiceOwner()

   

    
    
  }

  getAccountServiceOwner(){
  }

  serviceTermRemoved(isServiceTermRemoved) {
    this.isServiceTermRemoved = isServiceTermRemoved
    this.updateAccountTerm()
  }

  selectedServiceTerm(term) {
    this.newTermId = term.id;
  }

  updateAccountTerm() {
    const paramData = {
      accountid_id: this.accountId.account.id,
      is_deleted: this.isServiceTermRemoved,
      service_id: null,
      service_display_id: null,
      service_term_id: this.newTermId != null ? this.newTermId  : this.accountId.account.service_term_id,
      tenant_vendor_id: this.accountId.account.tenant_vendor_id
    }

    this.serviceTermService.saveServiceTermAssociation(paramData)
      .toPromise()
      .then(res => {
        if (this.isServiceTermRemoved) {
          this.toastr.info('Account terms removed successfully');
        }
      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while updating account term, Please try again`);
      })
  }
}