import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {TenantContactsService} from '../../../libs/api/tenant/tenantContacts.service';
import {RecentContactsStorageService} from '../../../shared/services/recent-contacts-storage.service';
import {CONTACT_SEARCH_TYPE, CONTACT_STATUSES} from '../../../common/utils/sharedConstants';
import { ContactSearchQueryParams } from '../../../shared/ng-models/search/SearchQueryParams.interface';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';

const log = createLogger(LOG_LEVELS.COMPONENT);

export enum FindOrCreateContactComponentSteps {
  CREATE_STATE,
  SEARCH_STATE
};

@Component({
  selector: 'mi-find-or-create-contact',
  templateUrl: './find-or-create-contact.component.html',
  styleUrls: ['./find-or-create-contact.component.scss']
})

export class FindOrCreateContactComponent implements OnInit {

  @Output() contactSelected: EventEmitter<VendorContact> = new EventEmitter<VendorContact>();
  @Output() stepChanged: EventEmitter<FindOrCreateContactComponentSteps> = new EventEmitter<FindOrCreateContactComponentSteps>();

  @Input() startState: FindOrCreateContactComponentSteps;
  @Input() createMode: boolean = true;
  @Input() showVendorTab: boolean = false;
  @Input() vendorName: string = '';

  readonly CREATE_STATE = FindOrCreateContactComponentSteps.CREATE_STATE;
  readonly SEARCH_STATE = FindOrCreateContactComponentSteps.SEARCH_STATE;

  readonly INACTIVE: string = "INACTIVE";

  searchTypes: Array<string> = ['', CONTACT_SEARCH_TYPE.ONLY_RECENT_CONTACTS];

  step: FindOrCreateContactComponentSteps = this.SEARCH_STATE;
  @Input() searchString: string = '';
  contacts: any;
  error: string;
  loaded: boolean = false;
  loadingMore: boolean = true;
  searching: boolean = false;

  page: number = 1;

  totalContacts: number;
  totalAllContacts: number;
  totalVendorContacts: number;
  totalRecentContacts: number;
  recentContactIds: Array<string> = [];
  selectedTab: string = '';

  constructor(
    private tenantContactsService: TenantContactsService,
    private recentContactsStorageService: RecentContactsStorageService
  ) {

  }

  ngOnInit() {
    if(this.showVendorTab && this.vendorName){
      this.searchTypes.unshift(CONTACT_SEARCH_TYPE.ONLY_VENDOR_CONTACTS);
      this.selectedTab = CONTACT_SEARCH_TYPE.ONLY_VENDOR_CONTACTS;
    }
    // Load the saved contacts:
    this.recentContactsStorageService.getRecentContacts()
      .subscribe((contacts) => {
        this.recentContactIds = (contacts || []).map(c => c._id);
        this.searchContacts();
      });
  }

  onLoadMoreClicked($event) {
    $event.preventDefault();
    this.loadingMore = true;
    this.searchContacts(this.page + 1);
  }

  onContactCreated(contact: VendorContact) {
    this.onSelectContact(contact);
  }

  onSelectContact(contact: VendorContact) {
    if(contact.status === this.INACTIVE){
      return;
    }
    this.recentContactsStorageService.saveRecentContact(contact)
      .subscribe((contacts) => {
        this.recentContactIds = (contacts || []).map(c => c._id);
        this.contactSelected.emit(contact);
      });

  }

  onSearchSubmit() {
    this.searchContacts();
  }

  onUpdateSearchString(str = ''){
    this.searchString = str;
    this.searchContacts();
  }

  searchContacts(page: number = 1) {
    const options = {from: 0, size: 6};

    if (page > 1) {
      options.from = 6 + ((page - 2) * 10);
      options.size = 10;
    }

    this.page = page;

    this.error = '';

    let queryParams: ContactSearchQueryParams = {
      q: this.searchString,
      from: options.from,
      size: options.size,
      recent_contacts: this.recentContactIds,
      vendor_name: [this.vendorName],
      contact_search_type: this.selectedTab,
      status:["ACTIVE"]
    };

    this.tenantContactsService.searchTenantContacts(queryParams)
      .toPromise()
      .then(contacts => {
        if (page > 1) {
          this.contacts.push(...contacts.hits.map(this.extendContact));
        } else {
          this.contacts = contacts.hits.map(this.extendContact);
        }

        this.totalContacts = contacts.total.value;
        this.totalAllContacts = contacts.aggregations.all_count.value;
        if(contacts.aggregations.vendor_name_count)
          this.totalVendorContacts = contacts.aggregations.vendor_name_count.doc_count;
        if(contacts.aggregations.recent_contacts_count)
          this.totalRecentContacts = contacts.aggregations.recent_contacts_count.doc_count;

        this.loadingMore = false;
        this.searching=false;
        this.loaded=true;

      })
      .catch(e => this['error'] = e);
  }

  swapTo(newState: FindOrCreateContactComponentSteps) {
    this.stepChanged.emit(newState);
    this.step = newState;
  }

  extendContact(contact: any): any {

    let emailLabel = contact.email.match(/([A-Z0-9\w.+]+)/)[0];
    let emailDomain = contact.email.match(/@.*/)[0];

    console.log(`extendContact ${contact.full_name}: ${emailLabel} at ${emailDomain}`);

    return {
      ...contact,
      emailLabel: emailLabel,
      emailDomain: emailDomain
    };
  }

  toggle(index) {
    this.selectedTab = this.searchTypes[index];
    this.page = 1;
    this.searching=true;
    this.searchContacts();
  }

}
