import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {AssociatedDocument} from '../../../shared/models/AssociatedDocument';
import {CanDelete} from 'src/shared/models/CanDelete';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantAssociatedDocumentsService {

  private apiUrlAssociatedDocumentsCRUD = '/{tenant}/associated-documents';
  private apiUrlAssociatedDocumentsByVendor = '/{tenant}/associated-documents/vendor';
  private apiUrlAssociatedDocumentByDisplayIdCRUD = '/{tenant}/associated-documents-by-display-id/';
  private apiUrlAssociatedDocumentEditCRUD = '/{tenant}/associated-documents-edit';

  constructor(private http: HttpClient) {
  }

  createAssociatedDocument(associatedDocument: AssociatedDocument): Observable<AssociatedDocument> {
    let postData: object = {
      document: associatedDocument.document,
      label: associatedDocument.label,
      vendor: typeof associatedDocument.vendor === 'string' ? associatedDocument.vendor : associatedDocument.vendor['_id'],
      uploaded_by: associatedDocument.uploaded_by
    };
    return this.http
      .post(`${this.apiUrlAssociatedDocumentsCRUD}`, postData)
      .map((response: any) => response.data.associatedDocument as AssociatedDocument);
  }

  getAssociatedDocumentsByVendor(vendor_id: string): Observable<Array<AssociatedDocument>> {
    return this.http
      .get(`${this.apiUrlAssociatedDocumentsByVendor}/${vendor_id}`)
      .map((response: any) => response.data.associatedDocuments as Array<AssociatedDocument>);
  }

  getByDisplayId(display_id: string): Observable<AssociatedDocument> {
    return this.http
      .get(`${this.apiUrlAssociatedDocumentByDisplayIdCRUD}${display_id}`)
      .map((response: any) => response.data.associated_documents as AssociatedDocument);
  }

  updateAssociatedDocument(associated_documents: AssociatedDocument): Observable<AssociatedDocument> {
    return this.http
      .put(`${this.apiUrlAssociatedDocumentEditCRUD}/${associated_documents._id}`, {associated_documents})
      .map((response: any) => response.data.associated_documents as AssociatedDocument);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlAssociatedDocumentsCRUD}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteAssociatedDocument(id: String): Observable<AssociatedDocument> {
    return this.http
      .delete(`${this.apiUrlAssociatedDocumentsCRUD}/${id}`)
      .map((response: any) => response.data.product as AssociatedDocument);
  }

}
