import { Action } from '@ngrx/store';

export const ActionTypes = {
  SET_DATA: '[Miso: TenantCreationV2] SET_DATA',
  GET_DATA: '[Miso: TenantCreationV2] GET_DATA',
  GET_STEP: '[Miso: TenantCreationV2] GET_STEP',
  RESET: '[Miso: TenantCreationV2] RESET',
};

export class SetTenantCreationV2Data implements Action {
  type = ActionTypes.SET_DATA;

  constructor(public payload) { }
}

export class GetTenantCreationV2Data implements Action {
  type = ActionTypes.GET_DATA;

  constructor(public payload) { }
}

export class GetTenantCreationV2Step implements Action {
  type = ActionTypes.GET_STEP;

  constructor(public payload) { }
}

export class TenantCreationV2Reset implements Action {
  type = ActionTypes.RESET;

  constructor(public payload) { }
}

export type Actions
  = SetTenantCreationV2Data
  | GetTenantCreationV2Data
  | GetTenantCreationV2Step
  | TenantCreationV2Reset