import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ICategoryPagination, ICreateCategory, IUpdateCategory, ProductCategory} from '../../../shared/models/ProductCategory';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {HttpClient} from '@angular/common/http';
import {PricePoints} from '../../../shared/models/PricePrediction';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class MiyagiCategoryService {

  private apiUrlCrud = '/miyagi/categories';
  private accountHost = '_MICRO_SERVICE_/product/categories';
  private getCategoriesService = '_MICRO_SERVICE_/product/getCategory';
  private createCategoryService = '_MICRO_SERVICE_/product/category';
  private updateCategoryService = '_MICRO_SERVICE_/product/updateCategory';

  

  constructor(private http: HttpClient) {
  }

  createCategory(category: ProductCategory): Promise<ProductCategory> {
    return this.http
      .post(`${this.accountHost}`, category)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  createNewCategory(category: ICreateCategory): Promise<ProductCategory> {
    return this.http
      .post(`${this.createCategoryService}`, category)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  updateCategory(category: IUpdateCategory): Promise<ProductCategory> {
    return this.http
      .put(`${this.updateCategoryService}`, category)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  editCategory(category: ProductCategory): Promise<ProductCategory> {
    return this.http
      .post(`${this.accountHost}`, category)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }


  getCategory(categoryId: string): Promise<ProductCategory> {
    return this.http
      .get(`${this.accountHost}/${categoryId}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  listCategories(): Promise<ProductCategory[]> {
    return this.http
      .get(`${this.accountHost}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  getCategoryTree(): Promise<ProductCategory[]> {
    return this.http
      .get(`${this.accountHost}/tree`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  getCategories(searchParam: string= '', pageNumber: number= 0, pageSize: number= 500, is_Deleted: boolean = false): Promise<ICategoryPagination> {
    return this.http
      .get(`${this.getCategoriesService}?search=${searchParam}&is_Deleted=${is_Deleted}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  deleteProductCategory(categoryId: string): Promise<ProductCategory> {
    return this.http
      .delete(`${this.accountHost}/${categoryId}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  addPricePoint(categoryId: string, pricePoint: PricePoints): Observable<ProductCategory> {
    return this.http
      .post(`${this.apiUrlCrud}/${categoryId}/prediction-price-point/`, {price_point: pricePoint})
      .map((response: any) => response.data.category as ProductCategory);
  }
  
  deletePricePrediction(categoryId: string, pricePointId: string): Observable<ProductCategory> {
    return this.http
      .delete(`${this.apiUrlCrud}/${categoryId}/prediction-price-point/${pricePointId}`)
      .map((response: any) => response.data.category as ProductCategory);
  }

}
