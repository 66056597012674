import {Component, EventEmitter, Input, Output, OnInit} from "@angular/core";
import { AddressV2 } from 'src/libs/api2/types/AddressV2.interface';
import {Address} from "../../../shared/models/Address";

@Component({
  selector: 'mi-address-display',
  templateUrl: './address-display.component.html',
  styleUrls: ['./address-display.component.scss']
})

export class AddressDisplayComponent implements OnInit{
  @Input() location: any
  @Output() onDeselect: EventEmitter<AddressV2> = new EventEmitter<AddressV2>()
  isChecked: Boolean = true;

  constructor(){
  }

  ngOnInit(){
  }

  onDeselected(){
    this.isChecked = false;
    this.onDeselect.emit(this.location);
    console.log('this.location in address display component', this.location);
  }
}
