import {TemplateRef} from '@angular/core';

export enum SortableTemplatesEnum {
  idLink = 1,
  shortDate,
  shortDateTime,
  shortISODateTime,
  contact,
  trimmedText,
  currency,
  roundedCurrency,
  tenantCurrency,
  serviceCurrency,
  truncate,
  number,
  idLink2,
  idLink2Edit,
  location,
  customField,
  customFieldHeader,
  tag,
  status,
  count,
  fileLink,
  fileSize,
  fileType
}

export interface SortableTableColumn {
  name: string;
  prop: string;
  cellTemplate?: SortableTemplatesEnum | TemplateRef<any>;
  sortable?: boolean;
  className?: string;
  hidden?: boolean;
  sort?: string;
  headerTemplate?: TemplateRef<any>;
}
