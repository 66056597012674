import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MiService} from '../../../shared/models/MiService';

@Component({
  selector: 'mi-parent-service-added-service',
  templateUrl: './parent-service-added-service.component.html',
  styleUrls: ['./parent-service-added-service.component.scss']
})

export class ParentServiceAddedServiceComponent {

  @Input() service: any;
  @Output() deselected: EventEmitter<any> = new EventEmitter<any>();

  constructor(

  ) {
  }

  onDeselect():void{
    this.deselected.emit(this.service);
  }

}
