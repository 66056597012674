import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IVendorTermFormatted } from 'src/shared/models/Vendor';
@Component({
  selector: 'view-vendor-accounts-end-of-term',
  template: `<div  
  (click)="viewVendorOwnershipClickHandler(rowData)" class="d-flex justify-content-between px-2">
  <div>
    {{ rowData.account_qty}}
  </div>
  <button class="btn btn-sm btn-primary">View</button>
</div>
`,
})

export class ViewVendorAccountEndOfTermComponent {
  @Input() rowData: IVendorTermFormatted;
  @Output() customEvent: EventEmitter<void> = new EventEmitter<void>();
  selectedVendor: any = null

  constructor(
    public router: Router
  ) { }

  viewVendorOwnershipClickHandler(selectedVendor) {
    console.log("here selectedVendor", selectedVendor)

    this.selectedVendor = this.rowData
    this.customEvent.emit();

    // const navigationExtras: NavigationExtras = {
    //   state: {
    //     vendorRowData: selectedVendor,
    //   },
    // };
    // this.router.navigate(["/account-end-of-term"], navigationExtras);
  }

}