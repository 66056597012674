import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as appActions from './actions';
import {
  CONTACT_ROLES,
  CONTACT_STATUSES,
  TELEPHONE_LOCALITIES,
  BANDWIDTH_UNITS,
  TELEPHONE_LOCALITY_US,
  GROUPED_COUNTRIES,
} from '../../../common/utils/sharedConstants';

import {settings} from '../../../common/utils/SettingsUtil';

export const APP_TYPES: any = {
  MIYAGI: new URL(settings.MIYAGI_URL).host,
  ACCOUNTS: new URL(settings.ACCOUNTS_URL).host,
  TENANT: 'TENANT'
};

import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {Tenant} from '../../models/Tenant';
import {Country} from '../../models/Country';

const log = createLogger(LOG_LEVELS.REDUX_DEBUG);

export interface AppConstants {
  CONTACT_ROLES: string[];
  TELEPHONE_LOCALITIES: string[];
  BANDWIDTH_UNITS: string[];
  CONTACT_STATUSES: string[];
  TELEPHONE_LOCALITY_US: string;
  COUNTRIES: Country[];
}

export interface State {
  appType?: String;
  tenantData?: Tenant;
  invalidDomain: Boolean;
  CONSTANTS: AppConstants;
}

const initialState: State = {
  appType: null,
  tenantData: null,
  invalidDomain: false,
  CONSTANTS: <AppConstants> {
    CONTACT_ROLES,
    TELEPHONE_LOCALITIES,
    BANDWIDTH_UNITS,
    CONTACT_STATUSES,
    TELEPHONE_LOCALITY_US,
    COUNTRIES: GROUPED_COUNTRIES
  }
};

export function reducer(state = initialState, action: appActions.Actions): State {
  switch (action.type) {
    case appActions.ActionTypes.APP_DATA_GET_SUCCESS: {
      return {
        ...state,
        appType: action.payload.appType,
        tenantData: action.payload.tenantData || null,
        invalidDomain: validAppData(action.payload)
      };
    }
    case appActions.ActionTypes.APP_DATA_GET_ERROR: {
      return {
        ...state,
        appType: action.payload.appType,
        tenantData: null,
        invalidDomain: false
      };
    }
    case appActions.ActionTypes.APP_DATA_GET_ERROR_NOT_FOUND: {
      return {
        ...state,
        appType: null,
        tenantData: null,
        invalidDomain: true
      };
    }
    default: {
      return state;
    }
  }
}

function validAppData(payload): Boolean {
  let invalid: Boolean = false;
  if (!payload.appType && !APP_TYPES.includes(payload.appType)) {
    invalid = true;
  } else if (payload.appType === APP_TYPES.TENANT && !payload.tenantData) {
    // this app could not load the tenant data.
    invalid = true;
  }
  return invalid;
}

export const getAppData = createFeatureSelector<State>('appData');
export const tenantDisplayName = createSelector(getAppData, (appData) => appData.tenantData ? appData.tenantData.display_name : '');
export const tenantDomain = createSelector(getAppData, (appData) => appData.tenantData ? appData.tenantData.domain : '');
export const isAccountApp = createSelector(getAppData, (appData) => appData.appType === APP_TYPES.ACCOUNTS);
