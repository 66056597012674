<modal class="extra-wide"
       [showCloseButton]="true"
       [closeOnEscape]="true"
       [closeOnOutsideClick]="true">

  <modal-header>
    <h4>Select Equipment</h4>
  </modal-header>

  <modal-content >

    <div>
      <mi-find-or-create-equipment
        (showAddEquipmentStatus) = "showAddEquipmentStatus($event)"
        (selecteEquipment)="onSelectEquipment($event)">
      </mi-find-or-create-equipment> 
    </div>

    <button
      *ngIf="displayCancelButton"
      class="modal-cancel"
      mi-button
      buttonTheme="light-theme"
      (click)="close()">
      Cancel
    </button>

  </modal-content>

</modal>
