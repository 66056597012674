import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {urlValidator} from '../../../shared/shared.validators';
import {TenantVendors} from '../../../libs/api/tenant/tenantVendors';
import {Vendor} from '../../../shared/models/Vendor';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';

const log = createLogger(LOG_LEVELS.COMPONENT);

export enum FindOrCreateVendorComponentSteps {
  CREATE_STATE,
  SEARCH_STATE
}

@Component({
  selector: 'mi-find-or-create-vendor',
  templateUrl: './find-or-create-vendor.component.html',
  styleUrls: ['./find-or-create-vendor.component.scss']
})

export class FindOrCreateVendorComponent extends AbstractPageComponent implements OnInit, OnChanges {
  @Input() searchString: string = '';
  @Input() showCreateVendor: boolean = true;
  @Input() activeVendorOnly: boolean = false;
  @Input() createVendor: boolean = false;
  @Output() vendorSelected: EventEmitter<Vendor> = new EventEmitter<Vendor>();
  @Output() stepChanged: EventEmitter<FindOrCreateVendorComponentSteps> = new EventEmitter<FindOrCreateVendorComponentSteps>();
  @Output() newCreated: EventEmitter<void> = new EventEmitter<void>();

  CREATE_STATE = FindOrCreateVendorComponentSteps.CREATE_STATE;
  SEARCH_STATE = FindOrCreateVendorComponentSteps.SEARCH_STATE;
  step: number = this.SEARCH_STATE;

  createPurchasingVendorForm: FormGroup;
  error = '';
  searchCreateError: string = '';
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private tenantVendorService: TenantVendors) {
            super();
          }

  ngOnInit() {
    this.activatedRoute.queryParams.takeUntil(this.destroy$).subscribe((query: Params) => {
      if (query.create) {
        this.searchString = query.create;
        this.swapTo(this.CREATE_STATE);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.createVendor) {
      this.swapTo(this.CREATE_STATE);
    }
  }

  updateSearchString(str) {
    this.searchString = str;
  }

  onVendorSelected(vendor: Vendor) {
    // log('setVendor', vendor);

    if (vendor && !vendor.display_id) {
      // Create an un-normalized vendor:
      this.tenantVendorService
        //.createNormalizedVendor(vendor.actual_vendor_id)
        .createNormalizedVendor(vendor.id)
        .then(unnormalizedVendor => {

          this.vendorSelected.emit(unnormalizedVendor);
        }).catch((e) => {
        if (e.data.matchedVendor) {
          // this.store.dispatch(new SelectPurchasingVendor({vendor: e.data.matchedVendor}));
          this.vendorSelected.emit(e.data.matchedVendor as Vendor);
        } else {
          this.searchCreateError = e;
          this.loading = false;
        }
      });
    } else {
      // Vendor is already registered with this Tenant; broadcast it.
      this.vendorSelected.emit(vendor);
    }
  }

  onVendorCreate(data: any) {
    this.error = '';
    this.loading = true;
    this.tenantVendorService
      .createUnNormalizedVendor({
        vendor_name: data.vendor_name,
        vendor_url: this.formatUrl(data.vendor_url)
      } as Vendor)
      .then(res => {
        this.vendorSelected.emit(res);
        this.newCreated.emit();
        // this.store.dispatch(new SelectPurchasingVendor({vendor: res}));
      }).catch(e => {

      if (e.data.matchedVendor) {
        // this.store.dispatch(new SelectPurchasingVendor({vendor: e.data.matchedVendor}));
        this.vendorSelected.emit(e.data.matchedVendor as Vendor);
      } else {
        this.error = e;
        this.loading = false;
      }

    });
  }

  formatUrl(str): string {
    const isUrl: RegExp = /\b(https?):/;
    return isUrl.test(str) ? str : 'http://' + str;
  }

  swapTo(newState: FindOrCreateVendorComponentSteps) {
    if(newState === FindOrCreateVendorComponentSteps.CREATE_STATE) {
      this.createPurchasingVendorForm = this.formBuilder.group({
        vendor_name: [this.searchString, Validators.required],
        vendor_url: [null, Validators.compose([Validators.required, urlValidator])]
      });
    }
    this.stepChanged.emit(newState);
    this.step = newState;
    if (this.step === FindOrCreateVendorComponentSteps.SEARCH_STATE) {
      // Re-set the form, clearing previously entered data.
      this.searchString = '';
      this.createPurchasingVendorForm.reset();
      this.createVendor = false;
    }
  }

}
