import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiServiceSearchResults, ProductSearchResults} from '../../../shared/models/SearchResults';
import {MiServiceSearchQueryParams, ProductSearchQueryParams,} from '../../../shared/ng-models/search/SearchQueryParams.interface';
import {fixAngularArrayQuery} from '../../../shared/utils/fixAngularArrayQuery';
import {SearchProductsService} from '../interfaces/SearchProductsService.interface';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class MiyagiSearchService implements SearchProductsService {

  private apiUrlSearchMiService = '/miyagi/search/mi-services';
  private apiUrlSearchMiyagiProducts = '/miyagi/search/all-products';

  constructor(private http: HttpClient) {
  }

  searchMiService(queryParams: MiServiceSearchQueryParams = {}): Observable<MiServiceSearchResults> {
    return this.http
      .get(this.apiUrlSearchMiService, {params: fixAngularArrayQuery(queryParams)})
      .map((response: any) => response.data as MiServiceSearchResults);
  }

  searchMiyagiProducts(queryParams: ProductSearchQueryParams): Observable<ProductSearchResults> {
    return this.http
      .get(this.apiUrlSearchMiyagiProducts, {params: fixAngularArrayQuery(queryParams)})
      .map((response: any) => response.data as ProductSearchResults);
  }

  searchProducts(queryParams: ProductSearchQueryParams): Observable<ProductSearchResults> {
    return this.searchMiyagiProducts(queryParams);
  }

}
