<mi-server-error [error]="error"></mi-server-error>

<h2>{{ 'TENANT.PRODUCT_EDIT_TITLE' | translate }}</h2>

<div style="width:50%;" *ngIf="product; else loading">
  <mi-product-form *ngIf="vendor" [productServicesMode]="tenantProductServicesMode" [lockVendor]="true" [productInsert]="product" (canceled)="onCanceled()"
    (productCompleted)="onProductAdded($event)">
  </mi-product-form>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>