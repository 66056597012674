import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SERVICE_LISTING_FIELDS } from "src/common/utils/sharedConstants";
import { CustomFieldsV2Service } from "src/libs/api2/service-v2/custom-fields-v2.service";
import { BulkUploadTemplateField, BulkUploadTemplateSection } from "src/shared/models/BulkUploadTemplateSection.interface";
import { tenantBridgeCustomFieldsResponse, tenantBridgesResponse } from "src/shared/models/ServiceV2";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import FrontEndSettingsUtil from "src/shared/utils/frontEndSettings.util";
import { getTemplateFieldsProperties } from "../fields-to-download-options";
import { TagsService } from "src/libs/api2/tags/tags.service";
import { TagDefinition } from "src/shared/models/Tags";

@Component({
  selector: 'mi-fields-to-download-template-v2',
  templateUrl: './fields-to-download-template-v2.component.html',
  styleUrls: ['./fields-to-download-template-v2.component.scss']
})

export class FieldsToDownloadTemplateV2Component extends AbstractPageComponent implements OnInit {
  @Input() reference = 'MiServices'
  @Input() fields: BulkUploadTemplateField[]=[];
  @Input() cfFields: BulkUploadTemplateField[]=[];
  @Input() tagFields: BulkUploadTemplateField[]= [];
  vcfFields: BulkUploadTemplateField[]=[];
  @Input() showCancel: boolean = false;
  @Input() downloadButtonName: String = 'Download';

  @Input('onSubmit')
  set onSubmit(val) {
    if(val) {
      this.onDownload(null);
    }
  }

  @Output() fieldsSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  selected: BulkUploadTemplateField[] = [];
  sections: BulkUploadTemplateSection[];
  error;
  tenantId: string = "";
  allCustomFields: tenantBridgeCustomFieldsResponse[] = [];
  allTags: TagDefinition[];

  constructor(
    private customFieldsV2Service: CustomFieldsV2Service,
    private tagsService: TagsService
  ) {
    super();
  }
  
  async ngOnInit() {
    //console.log("cfFields", this.cfFields);
    //console.log("fields", this.fields);
    this.sections = getTemplateFieldsProperties(this.reference);
    console.log("sections", this.sections);
    this.selected = this.fields;
    this.tenantId = FrontEndSettingsUtil.getDomain();
    if(this.reference === "MiServices" || this.reference === "MiServicesColumnSelect"){
      await this.getTags();
      await this.getCustomFields();      
    }
  }

  filterTenantBridges(tenantBridges: tenantBridgesResponse[]): tenantBridgesResponse[] {
    let filteredTenantBridges: tenantBridgesResponse[] = [];
    let uniqueTenantBridgeIds: string[] = [];
    
    tenantBridges.map(tb => {
      if(uniqueTenantBridgeIds.indexOf(tb.bridgeId) == -1) {
        uniqueTenantBridgeIds.push(tb.bridgeId);
        filteredTenantBridges.push(tb);
      }
      else {
        console.log("duplicate bridge", tb);        
      }
    });

    return filteredTenantBridges;
  }

  getTags(): void {
    this.tagsService.getTags(this.tenantId)
    .subscribe(res => {
      console.log("getTags", res);
      this.allTags = res;

      this.populateTags();
    });
  }

  populateTags(): void {
    this.allTags.sort(function(a, b){
      if(a.tagName < b.tagName) { return -1; }
      if(a.tagName > b.tagName) { return 1; }
      return 0;
    });

    console.log("allTags", this.allTags);

    let tagsSection = {
      title: "Tags (A-Z)",
      sub_sections:[
        {
          columns:[{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          }]
        }
      ]
    };

    let tagFields = [];

    this.allTags.map((tag, i) => {
      let currentFields = this.tagFields.find(item => item.field_id === tag.tagId);

      tagFields.push({
        field_id: tag.tagId,
        displayLabel: tag.tagName,
        display: tag.tagName,
        selected: currentFields? currentFields.selected : false
      });

      let index = (i+3) % 3;
      tagsSection.sub_sections[0].columns[index].fields.push(tag.tagId);
    });

    this.sections.push(tagsSection);

    tagFields.forEach(tagField => {
      if(this.selected.indexOf(this.selected.find(select => {return select.field_id == tagField.field_id})) != -1) {
        this.selected.splice(this.selected.indexOf(this.selected.find(select => {return select.field_id == tagField.field_id})) , 1);
        this.selected.push(tagField);
      }
      else {
        this.selected.push(tagField);
      }
    });
  }

  getCustomFields() {
    this.customFieldsV2Service.getTenantBridges(this.tenantId)
    .toPromise()
    .then((tenantBridges: tenantBridgesResponse[]) => {
      console.log("tenant bridges", tenantBridges);
      tenantBridges = this.filterTenantBridges([...tenantBridges]);
      let bridgeCount = 0;
      tenantBridges.forEach((tenantBridge: tenantBridgesResponse) => {
        this.customFieldsV2Service.getTenantBridgeCustomFields(this.tenantId, tenantBridge.bridgeId, tenantBridge.vendorId)
        .toPromise()
        .then((customFields: tenantBridgeCustomFieldsResponse[]) => {
          bridgeCount++;
          customFields.forEach((customField: tenantBridgeCustomFieldsResponse) => {
            customField.vendorName = tenantBridge.vendorName;
          });
          this.allCustomFields.push(...customFields);
          if(bridgeCount == tenantBridges.length) {
            this.populateCustomFields();
          }
        });
      });
    });
  }

  getVendorCustomFields(customFields: tenantBridgeCustomFieldsResponse[], cfFields:any[]) {
    //console.log("getVendorCustomFields cfFields", cfFields);
    let customFieldsWithVendor: tenantBridgeCustomFieldsResponse[]=[];
    
    //customFieldsWithVendor = [...customFields];
    customFields.forEach((cf, index) => {
      customFieldsWithVendor.push(cf);
    });

    customFieldsWithVendor.sort( (a,b) =>
      (a.vendorName > b.vendorName) ? 1 : -1
    );

    //console.log("sections", this.sections);

    let VendorCustomFieldsSection = {
      title: "Connector Defined Fields (A-Z)",
      sub_sections:[
        {
          columns:[{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          }]
        }
      ]
    };

    let vcfFields = [];
    let vendorHeaders=[];

    customFieldsWithVendor.forEach((cf,index) =>
    {
      vendorHeaders.push(
        {
          vendor_name : cf.vendorName
        }
      );
    });

    vendorHeaders=Array.from(new Set(vendorHeaders.map(s => s.vendor_name)))
                        .map(vendor_name => {
                          return {
                            vendor_name: vendor_name
                          };
                        }
                        );

    vendorHeaders.forEach((vh, index) => {
      //console.log("vh", vh);
      let vhSelected = true;

      let dependentFields = [];
      customFieldsWithVendor.forEach((cf, cfindex) =>
      {
        if(cf.vendorName == vh.vendor_name) {
          cfFields.forEach((cff, cffindex)=>
          {
            if(`cf_${cf.id}` == cff.field_id)
            {
              vhSelected = vhSelected && cff.selected;
            }
          });
          dependentFields.push(`cf_${cf.id}`);
        }
      });

      vcfFields.push({
        field_id: vh.vendor_name,
        displayLabel: vh.vendor_name,
        display: vh.vendor_name,
        selected: vhSelected,
        dependent_field_to_select: dependentFields
      });

      let idx = (index+5) % 5;

      VendorCustomFieldsSection.sub_sections[0].columns[idx].fields.push(vh.vendor_name);
    });

    //console.log("vcfFields", vcfFields);
    //console.log("VendorCustomFieldsSection", VendorCustomFieldsSection);

    this.sections.push(VendorCustomFieldsSection);
    //this.selected.push(...vcfFields);
    vcfFields.forEach(vcfField => {
      //console.log("find item", this.selected.find(select => {return select.field_id == vcfField.field_id}));
      //console.log("item index", this.selected.indexOf(this.selected.find(select => {return select.field_id == vcfField.field_id})));
      if(this.selected.indexOf(this.selected.find(select => {return select.field_id == vcfField.field_id})) != -1) {
        this.selected.splice(this.selected.indexOf(this.selected.find(select => {return select.field_id == vcfField.field_id})) , 1);
        this.selected.push(vcfField);
      }
      else {
        this.selected.push(vcfField);
      }
    });
    //console.log("selected", this.selected);
  }

  filterCustomFields(allCustomFields: tenantBridgeCustomFieldsResponse[]): tenantBridgeCustomFieldsResponse[] {
    let uniqueCustomFieldIds: string[] = [];
    let filteredCustomFields: tenantBridgeCustomFieldsResponse[] = [];
    allCustomFields.map(cf => {
      if(uniqueCustomFieldIds.indexOf(cf.id) == -1) {
        uniqueCustomFieldIds.push(cf.id);
        filteredCustomFields.push(cf);
      }
      else {
        console.log("duplicate custom field", cf);        
      }
    });
    //console.log("filteredCustomFields", filteredCustomFields);

    return filteredCustomFields;
  }

  populateCustomFields() {
    //console.log("allCustomFields", this.allCustomFields);
    this.allCustomFields = this.filterCustomFields([...this.allCustomFields]);
    console.log("allCustomFields", this.allCustomFields);
    this.allCustomFields.sort(function(a, b){
      if(a.vendorName < b.vendorName) { return -1; }
      if(a.vendorName > b.vendorName) { return 1; }
      return 0;
    });

    //console.log("allCustomFields", this.allCustomFields);

    let customFieldsSection = {
      title: "Custom Fields (A-Z)",
      sub_sections:[
        {
          columns:[{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          }]
        }
      ]
    };

    let cfFields = [];

    this.allCustomFields.map((cf, i) => {
      let currentFields = this.cfFields.find(item => item.field_id === cf.id);

      cfFields.push({
        field_id: `cf_${cf.id}`,
        displayLabel: `${cf.customFieldLabel} (${cf.customFieldType})`,
        display: cf.customFieldLabel,
        selected: currentFields? currentFields.selected : false
      });
      let index = (i+3) % 3;
      customFieldsSection.sub_sections[0].columns[index].fields.push(`cf_${cf.id}`);
    });

    this.getVendorCustomFields(this.allCustomFields, cfFields);

    this.sections.push(customFieldsSection);
    //this.selected.push(...cfFields);
    cfFields.forEach(cfField => {
      if(this.selected.indexOf(this.selected.find(select => {return select.field_id == cfField.field_id})) != -1) {
        this.selected.splice(this.selected.indexOf(this.selected.find(select => {return select.field_id == cfField.field_id})) , 1);
        this.selected.push(cfField);
      }
      else {
        this.selected.push(cfField);
      }
    });
    /*
    this.allCustomFields.map((cf, i) => {

      let currentFields = this.cfFields.find(item => item.field_id === cf._id);

      cfFields.push({
        field_id: `cf_${cf._id}`,
        displayLabel: `${cf.label} (${cf.type})`,
        display: cf.label,
        selected: currentFields? currentFields.selected : false
      })
      let index = (i+3)%3;
      customFieldsSection.sub_sections[0].columns[index].fields.push(`cf_${cf._id}`)
    })
    */
  }

  getFields(columnFields) {
    //console.log("getFields", columnFields);
    //console.log("selected", this.selected);
    let x = this.selected.filter(item => columnFields.indexOf(item.field_id) > -1)
    .sort((a, b) => {
      return columnFields.indexOf(a.field_id) > columnFields.indexOf(b.field_id) ? 1 : -1;
    });
    //console.log("x", x);
    return x;
  }

  selectAll(mode) {
    this.selected = this.selected.map(field => {
      if(!field.required){
        field.selected = mode;
      }
      return field;
    })
  }

  selectDependent(selectedField: BulkUploadTemplateField) {
    if(selectedField.dependent_field_to_select){
      selectedField.dependent_field_to_select.map(item => {
        if(this.selected.every(itemToDeselect => 
          (!itemToDeselect.dependent_field_to_deselect
          || itemToDeselect.dependent_field_to_deselect.indexOf(item) < 0)
          || (!itemToDeselect.selected && !itemToDeselect.required))){

          let field = this.selected.find(f => f.field_id == item);

          if(field){
            field.selected = true;
          }
        }
    });
    }
  }

  unSelectDependent(selectedField: BulkUploadTemplateField) {
    if(selectedField.dependent_field_to_select){
      selectedField.dependent_field_to_select.map(item => {
        let field = this.selected.find(f => f.field_id == item);
        if(field){
          field.selected = false;
        }
      });
    }
  }

  unSelectOnSelect(selectedField: BulkUploadTemplateField) {
    if(selectedField.dependent_field_to_deselect){
      selectedField.dependent_field_to_deselect.map(item => {
        let field = this.selected.find(f => f.field_id == item);
        if(field){
          field.selected = false;
        }
      });
    }
  }

  isSelected(field) {
    return field.required||field.selected;
  }

  toggle($event, field) {
    let selectedField = this.selected.find(item => field.field_id == item.field_id);
    if ($event.target.checked) {
      // we want to remove the filter
      if(selectedField && !selectedField.selected) {
        selectedField.selected = true;
        this.selectDependent(selectedField);
        this.unSelectOnSelect(selectedField);
      }
    } 
    else {
      if(selectedField && selectedField.selected) {
        selectedField.selected = false;
        this.unSelectDependent(selectedField);
      }
    }
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  onDownload($event) {
    if($event) {
      $event.preventDefault();
    }
    
    if(this.downloadButtonName == "Email Download") {
      console.log("selected fields", this.selected);
      let allFields = [];
      this.selected.map(item => {
        if(!item.dependent_field_to_select || !item.display) {
          let field = {
            name: item.display? item.display : SERVICE_LISTING_FIELDS[item.field_id],
            is_custom_data: item.display ? true : false,
            hidden: !item.selected
          }
          allFields.push(field);
        }
      });
      this.fieldsSelected.emit(allFields);
    }
    else {
      this.fieldsSelected.emit(this.selected.map(item=> {
        let field = {
          field_id: item.field_id,
          selected: item.required||item.selected
        }
        if(item.display){
          field["column_name"] = item.display;
        }
        //console.log("field", field);
        return field;
      }));
    }    
  }
}