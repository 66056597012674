<mi-server-error [error]="error"></mi-server-error>

<div class="button-container">
  <a
    mi-button
    buttonTheme="light-theme"
    [routerLink]="cancelLink">
    Cancel
  </a>  
  <button mi-button
          buttonTheme="dark-theme"
          [disabled]="!activeIndex"
          (click)="onCreateParentService()">
    Create Parent Service
  </button>
</div>
