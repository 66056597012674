import {
  Component,
  OnInit,
  Input,
  ViewChild, TemplateRef
} from '@angular/core';
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
// import { createLogger, LOG_LEVELS } from "../../../../../../shared/logger";
// import { ProductCategory } from '../../../../../../shared/models/ProductCategory';
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
// import { AccountId } from 'src/shared/models/AccountId';
import { ToastrService } from 'ngx-toastr';
import { ServiceAssociationType } from 'src/shared/models/Contact';
import { ModalService } from '@independer/ng-modal';
import { SelectEmployeeModalComponent } from 'src/tenant/components/select-employee-modal/select-employee-modal.component';
import { ServiceOwnershipService } from 'src/libs/api2/service-ownership/service-ownership.service';

// const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-account-id-service-ownership',
  templateUrl: './account-id-service-ownership.component.html',
  styleUrls: ['./account-id-service-ownership.component.scss']
})

export class AccountIdServiceOwnershipComponent extends AbstractPageComponent implements OnInit {
  title = '';
  @Input() accountId: any;


  @ViewChild('emailTmpl', { static: true }) emailTmpl: TemplateRef<any>;
  @ViewChild('actionTmpl', { static: true }) actionTmpl: TemplateRef<any>;

  contactRows: any[] = [];
  contactColumns: any[] = [];
  loaded: boolean = false

  constructor(
    private toastr: ToastrService,
    private modalService: ModalService,
    private serviceOwnershipService: ServiceOwnershipService) {
    super();
  }

  ngOnInit() {
    this.getAccountServiceOwner()
  }

  initializeTable() {
    this.contactColumns = [
      {
        name: 'Name',
        prop: 'name',
        sortable: false
      },
      {
        name: 'Email',
        prop: 'email',
        cellTemplate: this.emailTmpl,
        sortable: false
      },
      {
        name: 'Phone',
        prop: 'phone',
        // cellTemplate: this.buTmpl,
        sortable: false
      },
      {
        name: 'Service Association',
        prop: 'service_association',
        sortable: false
      },
      {
        name: 'Action',
        prop: 'service_association',
        sortable: false,
        cellTemplate: this.actionTmpl,
      }
    ];
  }


  getAccountServiceOwner() {
    this.loaded = false
    this.contactRows = []
    this.serviceOwnershipService.getAccountOwnershipByAccountId(this.accountId.account.id)
      .toPromise()
      .then(res => {
        this.contactRows.push(
          {
            id: res.id,
            name: res.id ?  res.first_name + ' ' + res.last_name : '-',
            phone: res.mobile_phone ? res.mobile_phone : '-',
            email: res.email ? res.email : '-',
            service_association: ServiceAssociationType.MI_OWNER
          }
        );
        this.initializeTable()
        this.loaded = true
      })
      .catch(e => {
        console.warn(`Facing issue while getting account ownership users, Please try again`);
      })

    
  }

  openSelectUserModalHandler() {
    const modalRef2 = this.modalService.open(SelectEmployeeModalComponent, m => {
    });
    modalRef2.closed.subscribe(({ reason, result = [] }) => {
      console.log("SelectEmployeeModalComponent Result: ", result)
      if (result[0] && result[0].tenantUser) {
        console.log("here in result", result)
        this.addOwnershipHandler(result[0].tenantUser)
      }
    });
  }

  addOwnershipHandler(user) {
    this.serviceOwnershipService.addAccountOwnership(this.accountId.account.id, user.id)
      .toPromise()
      .then(res => {
        console.log("here add user response", res)
        this.getAccountServiceOwner()
      })
      .catch(e => {
        console.warn(`Facing issue while adding vendor ownership users, Please try again`);
      })
  }

  updateOwnershipHandler(user) {
    this.serviceOwnershipService.addAccountOwnership(this.accountId.account.id, user.id)
      .toPromise()
      .then(res => {
        console.log("here add user response", res)
        this.getAccountServiceOwner()
      })
      .catch(e => {
        console.warn(`Facing issue while adding vendor ownership users, Please try again`);
      })
  }

  removeUserHandler() {
    this.serviceOwnershipService.removeAccountOwnership(this.accountId.account.id)
      .toPromise()
      .then(res => {
        console.log("here account ownership", res)
        this.toastr.success(`Account ownership removed successfully`);
        this.getAccountServiceOwner()
      })
      .catch(e => {
        console.warn(e)
      })
  }
}