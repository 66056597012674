<div class="service-details-header" *ngIf="data; else loading">

  <div class="header-item">
    <div class="service-details-header-item">
      <mi-contact-details-avatar [contact]="contact">
      </mi-contact-details-avatar>
    </div>
  </div>

  <div class="header-item">
    <div class="address-header-item">
      <div class="stats">
        <div class="vendor-details--data-point">
          <dl>
            <dt class="formatted-address">
              {{contact.first_name}} {{contact.last_name}}
            </dt>
            <dd class="contact-detail">
              {{contact.email}}
              <br>
              <div *ngIf="contact.mobile_phone">
                M:{{contact.mobile_phone}}
              </div>
              <div *ngIf="contact.office_phone">
                O:{{contact.office_phone}}
              </div>
              <!-- M:{{contact.mobile_phone? contact.mobile_phone : "--"}}
              <br>
              O:{{contact.office_phone? contact.office_phone : "--"}} -->
            </dd>
            <dd class="edit-bottom">
              <a class="edit formatted-address edit-bottom"
                *ngIf="contact"
                routerLink="/contacts/{{contact.id}}/edit"
                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">Edit
              </a>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div class="header-item">
    <div class="service-details-header-item">
      <div class="stats">
        <div class="vendor-details--data-point">
          <dl>
            <dt>
              {{data.specs[0].label | translate }}
            </dt>
            <dd>
              {{data.specs[0].value}}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div class="header-item">
    <div class="service-details-header-item">
      <div class="stats">
        <div class="vendor-details--data-point">
          <dl>
            <dt>
              {{data.specs[1].label | translate }}
            </dt>
            <dd>
              {{data.specs[1].value | tenantcurrency:'1.2-2'}}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>