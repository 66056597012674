import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountId} from "../../../../../shared/models/AccountId";

@Component({
  templateUrl: './vendor-account-id-edit.component.html',
  styleUrls: ['./vendor-account-id-edit.component.scss']
})

export class VendorAccountIdEditComponent extends AbstractPageComponent implements OnInit {

  accountId: string;
  error: string;
  exitRoute: string;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router
              ) {
    super();
  }

  ngOnInit() {
    this.accountId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    this.exitRoute = `/vendor-account-ids/${this.accountId}/details`;
  }

  onCanceled() {
    this.router.navigate([this.exitRoute]);
  }

  onUpdateAccountId(accountId:AccountId):void {
    // The change has been added.
    setTimeout(()=>{
      this.router.navigate([this.exitRoute], {
        queryParams:{update: new Date().getTime()},
        relativeTo: this.activatedRoute
      });
    }, 1500);
  }

}
