import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../../create/state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {Vendor} from '../../../../../../../shared/models/Vendor';
import * as serviceOrderReducers from '../../../../single/create/state/reducer';
import {OrderDraft} from '../../../../../../../shared/models/OrderDraft';
import {VendorAndServiceSteps} from '../../../../single/create/state/reducer';
import {Product} from '../../../../../../../shared/models/Product';
import {Router} from '@angular/router';
import {LoadAsNewAction, SetManufacturingVendor} from '../../../../single/create/state/actions';
import {UpdateOrderAction} from '../../../create/state/actions';
import * as orderCreateActions from '../../../../single/create/state/actions';
import { TenantProductsService } from '../../../../../../../libs/api/tenant/tenantProducts';
import { ProductServicesModes } from '../../../../../../../shared/models/ProductServices.interface';
import { Equipment } from '../../../../../../../shared/models/Equipment';


@Component({
  selector: 'mi-service-order-product',
  styleUrls: ['./service-product.component.scss'],
  templateUrl: './service-product.component.html'
})

export class ServiceProductComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  currentDate: string;
  error: string;
  cancelLink: string;
  formStep: number = 0;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  product:Product;
  productForm: FormGroup;
  step: string = OrderSteps.Product;
  dummyProduct: Product;
  selectedEquipments: Array<Equipment> = [];
  readonly NO_DATA: string = NO_DATA;
  tenantProductServicesMode: ProductServicesModes = ProductServicesModes.TENANT;

  constructor(
    private formBuilder: FormBuilder,
    private orderStore: Store<OrderDraft>,
    private router: Router,
    private store: Store<OrderReducers.OrderState>,
    private productsService: TenantProductsService
  ) {
    super();
  }

  ngOnInit() {

    this.productForm = this.formBuilder.group({
      manufacturing_vendor: [null, Validators.required],
      product: [null, Validators.required]
    });

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state:OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;
          this.cancelLink = `/services/orders/summary/${this.orderState.display_id}`;
          // Update the form:
          this.productForm.controls['manufacturing_vendor'].setValue(this.orderState.vendor);
          this.dummyProduct = {...new Product(), vendor_id: this.orderState.vendor._id, product_vendor: this.orderState.vendor};
          this.orderStore.dispatch(new SetManufacturingVendor({
            vendor: this.orderState.vendor
          }));
          this.formStep = 1;
        },
        (e) => {
          this.error = e;
        });

    // Listen to the OrderDraft store:
    this.orderStore
      .select(serviceOrderReducers.getOrderCreateStore)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: OrderDraft) => {
          switch (state.vendor_and_service.currentStep) {
            // Create Product:
            case VendorAndServiceSteps.CREATE_PURCHASING_PRODUCT:
              this.formStep = 100;
              break;
            // Cancel Create Product:
            case VendorAndServiceSteps.SELECT_VENDOR_PRODUCTS:
              if(this.formStep === 100){
                this.formStep = 1;
              }
              break;
            case VendorAndServiceSteps.SELECT_MANUFACTURING_VENDOR:
              // The User has de-selected the manufacturing vendor;
              // show the Vendor Select component:
              this.productForm.controls['manufacturing_vendor'].setValue(null);
              this.formStep = 0;
              break;
            case VendorAndServiceSteps.PRODUCT_SUMMARY:
              // The User has selected a product;
              // cut to the summary:
              this.product = state.vendor_and_service.product;
              this.productForm.controls['product'].setValue(this.product);
              this.productForm.updateValueAndValidity();
              this.formStep = 2;
              break;
            default:
              console.warn('Unknown step:',state.vendor_and_service.currentStep);
          }
        }
      );
  }

  onCancelCLicked():void{
    this.productForm.controls['product'].setValue(null);
    this.productForm.controls['manufacturing_vendor'].setValue(this.orderState.vendor);
    this.formStep = 1;
    this.orderStore.dispatch(new LoadAsNewAction(null));
    this.router.navigateByUrl(this.cancelLink);
  }

  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          manufacturing_vendor: data.manufacturing_vendor,
          purchasing_vendor: this.orderState.vendor,
          product: data.product,
          equipments: this.selectedEquipments
        }
      }
    ));

  }

  onVendorDeselect(): void {
    console.group('onVendorDeselect');
    this.productForm.get('manufacturing_vendor').setValue(null);
    this.productForm.updateValueAndValidity();
    this.formStep = 0;
  }

  onVendorSelected(vendor: Vendor): void {
    this.productForm.get('manufacturing_vendor').setValue(vendor);
    this.dummyProduct = {...new Product(), vendor_id: vendor._id, product_vendor: vendor};
    this.orderStore.dispatch(new SetManufacturingVendor({
      vendor: vendor
    }));
    this.formStep = vendor ? 1 : 0;
  }


  onSelectProduct(product: Product) {
    this.error = '';
    if(product.display_id){
      this.store.dispatch(new orderCreateActions.ViewProductSummary({
        product: product
      }))
    }else{
      this.productsService.createNormalizedProduct(product._id, this.productForm.get('manufacturing_vendor').value._id).then(normalizedProduct => {
        this.store.dispatch(new orderCreateActions.ViewProductSummary({
          product: normalizedProduct
        }))
      }).catch(e => {
        this.error = e;
      });
    }
  }

  onUnselectManufacturingVendor() {
    this.store.dispatch(new orderCreateActions.SetManufacturingVendor({
      marketingMessage: 'ORDER_INSTRUCTIONS.DIFFERENT_PURCHASING_VENDOR',
      vendor: null
    }));
  }

  onVendorReset($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.store.dispatch(new orderCreateActions.SetManufacturingVendor({vendor: null}));
  }

  onEquipmentSelected(equipment){
    this.selectedEquipments = this.selectedEquipments.filter(eq => eq.display_id != equipment.display_id);
    this.selectedEquipments.push(equipment);
    this.selectedEquipments
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
  }

  onEquipmentRemoved(equipment){
    this.selectedEquipments = this.selectedEquipments.filter(eq => eq.display_id != equipment.display_id);
  }

}
