import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TenantMiServiceService } from "src/libs/api/tenant/tenantMiService.service";
import { TabsContainerComponent } from "src/shared/components/containers/tabs/tabs-container.component";
import { MiService, MiServiceDetails } from "src/shared/models/MiService";
import { ServiceAudit } from "src/shared/models/ServiceAudit";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import * as SDReducer from "../../../../state/reducers";

@Component({
  selector: 'mi-subsection-service-audit-container',
  styleUrls: ['subsection-service-audit-container.component.scss'],
  templateUrl: 'subsection-service-audit-container.component.html'
})

export class SubsectionServiceAuditContainerComponent extends AbstractPageComponent implements OnInit {

  titleRoot: string = "Service Tracking: ";
  title: string = "";

  pageLoaded$: Observable<boolean>;
  // miService: MiService;
  error: string = "";
  serviceDetails: MiServiceDetails;
  serviceAuditData: ServiceAudit;
  currency: string = "Currency not set";

  loadViews: boolean = false;

  @ViewChild('viewTabs', { static: false }) viewTabs: TabsContainerComponent;

  constructor(private tenantMiService: TenantMiServiceService,
              private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {
    this.pageLoaded$ = this.store.pipe(select(SDReducer.DSPageLoaded));
    this.store.pipe(select(SDReducer.getDSData))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.loadViews = false;
          // if (data.miService) {
          //   this.miService = data.miService;
          //   this.title = this.titleRoot + this.miService.display_id;
          //   this.currency = this.miService.currency_code;
          //   this.getServiceAuditInfo(this.miService.owner, this.miService.display_id);
          // }

          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
            this.title = this.titleRoot + this.serviceDetails.general.service_display_id;
            this.currency = this.serviceDetails.service_data.currency;
            this.getServiceAuditInfo(this.serviceDetails.general.tenant_id, this.serviceDetails.general.service_display_id);
          }
        });

  }

  getServiceAuditInfo(tenantId: string, displayId: string): void {

    this.tenantMiService.getServiceAuditInfo(tenantId, displayId)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      response => {
        this.serviceAuditData = response;
        if(this.serviceAuditData.data.length>0) {
          this.convertIdToName();
          this.loadViews = true;
        }
      },
      error => {
        this.error = error;
      }
    );
  }

  convertIdToName(): void {
    this.serviceAuditData.data.forEach(row => {
      row.update_date = new Date(row.update_date).toLocaleString('default');
      row.data.forEach((item, index) => {
        if(item.id == "vendor_purchasing") {
          if(item.value == this.serviceDetails.vendor.purchasing_vendor_id) {
            item.value = this.serviceDetails.vendor.purchasing_vendor_name;
          }
        }
        else if(item.id == "account_identifier") {
          if(item.value == this.serviceDetails.vendor.accountid_id) {
            item.value = this.serviceDetails.vendor.accountid_display_id;
          }
        }
        else if(item.id == "product_name") {
          if(item.value == this.serviceDetails.service_data.product_id) {
            item.value = this.serviceDetails.service_data.product_name;
          }
        }
        else if(item.id == "last_service_updated") {
          item.value = new Date(item.value).toLocaleString('default');
        }
        else if(item.id == "last_job_update") {
          item.value = new Date(item.value).toLocaleString('default');
        }
        else if(item.id == "parent_group") {
          item.value = this.serviceDetails.general.service_display_id + " " + item.value;
        }
        else if(item.id.substring(0,3) == "cf_") {
          let cfFound: boolean = false;
          let cfId = item.id.substring(3);
          // Rohit THis need to un-comment
          // this.miService.custom_data.forEach(cf => {
          //   if(cfId == cf.custom_field._id) {
          //     item.id = "cf_" + cf.custom_field.label;
          //     cfFound = true;
          //   }
          // });
          if(!cfFound) {
            row.data.splice(index, 1);
          }
        }
      });
    });
  }

  onTabChange(event): void {
  }

}