import { Component, Input, OnInit } from '@angular/core';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@independer/ng-modal';
import { Store } from '@ngrx/store';
import * as SDReducer from '../state/reducers';
import * as psdReducer from '../state/reducers';
import * as psdActions from '../state/actions';
import { MiService } from 'src/shared/models/MiService';
import { TermsAndConditions } from 'src/shared/models/TermsAndConditions';
import { TenantMiParentServiceService } from 'src/libs/api/tenant/tenantMiParentService.service';
import { Observable } from 'rxjs';
import { MiParentService } from 'src/shared/models/MiParentService';

@Component({
  templateUrl: './modal-cancellation-instruction.component.html'
})
export class ParentModalCancellationComponent extends AbstractPageComponent implements OnInit {
  parentService : MiParentService;
  terms_and_conditions: TermsAndConditions;
  loaded: boolean = false;
  pageLoaded$: Observable<boolean>;
  parentServiceId:string;


  constructor(private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private store: Store<SDReducer.State>,
    private tpsService: TenantMiParentServiceService,
    ) {
super();

}

ngOnInit() {
  // Retrieve the display ID from the route:
  this.parentServiceId = this.activatedRoute.snapshot.params.display_id || null;

  this.pageLoaded$ = this.store.select(psdReducer.ParentServicePageLoaded)
  .takeUntil(this.destroy$);

  this.store.select(psdReducer.getParentServiceData)
    .takeUntil(this.destroy$)
    .subscribe((data) => {

      if (data.parentService) {
        this.parentService = data.parentService;
        if(this.parentService.current_notification_service.current_financial && this.parentService.current_notification_service.current_financial.terms_and_conditions){
          this.terms_and_conditions = this.parentService.current_notification_service.current_financial.terms_and_conditions;
        }
      }
    })

  }


}
