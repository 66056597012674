import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import {MiServiceSearchResults} from '../../../../shared/models/SearchResults';
import {DetailHeaderNavData} from '../../../../shared/ng-models/DetailHeaderNavData.interface';
import {combineLatest} from "rxjs";
import {AccountId} from '../../../../shared/models/AccountId';
import {Store} from "@ngrx/store";
import * as ASReducer from "../../../../shared/state/advanced-search/reducers";
import {FILTER_GROUP_MODAL_ID} from "../../../../shared/ng-models/FilterGroupModalId";
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';
import { ModalService } from '@independer/ng-modal';
import linkFormatter from 'src/common/utils/linkFormatter';
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';

@Component({
  styleUrls: ['./vendor-account-id-instance-wrap.component.scss'],
  templateUrl: './vendor-account-id-instance-wrap.component.html'
})

export class VendorAccountIdInstanceWrapComponent extends AbstractPageComponent implements OnInit {

  accountId: AccountId;
  breadcrumbs: Link[];
  detailHeaderNavData: DetailHeaderNavData;
  display_id: string;
  error: string = '';
  filterOverlayId: string = FILTER_GROUP_MODAL_ID;
  supportLink: string = linkFormatter.getSupportLink();
  title: string;
  tenantVendorId: string;
  NO_DATA: string = NO_DATA;
  loaded: boolean = false;
  navLoaded: boolean = false;
  accountDetails: any

  constructor(private activatedRoute: ActivatedRoute,
              private tenantAccountIdService: TenantAccountIdService,
              private tenantVendors: TenantVendors,
              private store: Store<ASReducer.State>,
              private modalService: ModalService,
              ) {
    super();
  }

  ngOnInit() {    
    this.loaded = false;
    this.navLoaded = false;
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .switchMap(([params, queryParams]) => {
        this.display_id = params.display_id;
        return this.tenantAccountIdService.getById(this.display_id);
      })
      .switchMap((res: AccountId) => {
        this.accountId = res;
        return this.tenantVendors.getVendorBytenantVendorId(this.accountId.account.tenant_vendor_id);
      })
      .switchMap((res) => {
        console.log("here vendor res", res)
        this.accountId.account.vendor = res;
        console.log("this.accountId after adding vendor", this.accountId);
        this.tenantVendorId = this.accountId.account.tenant_vendor_id;
        this.navLoaded = true;
        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Vendors',
            url: '/vendors',
            internal: true
          },
          {
            label: `Vendor Details ${this.accountId.account.vendor.display_id}`,
            url: `/vendors/${this.accountId.account.vendor.display_id}/account-id`,
            internal: true
          },
          {
            label: `Account ${this.display_id}`,
            url: `/vendor-account-ids/${this.display_id}/details`,
            internal: true
          }
        ];

        this.loaded = true;
        // build a location typeSummary query
        //return this.tenantSearchService.typeSummary({'account_id[]': this.accountId._id});
        return combineLatest();
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((accountIdResults: MiServiceSearchResults) => {
        
        // // we can now find the correct bucket for this address
        // let correctBucket = accountIdResults.aggregations.account_id.buckets.find(bucket=> bucket.key === this.accountId._id);
        // let serviceCount = correctBucket ? correctBucket.doc_count : 0;

        
        // this.detailHeaderNavData = <DetailHeaderNavData> {
        //   headline: this.accountId.label,
        //   byline: {
        //     label: 'MISO_ID_BYLINE',
        //     obj: {display_id: this.accountId.display_id}
        //   },
        //   specs: [
        //     {
        //       label: 'TENANT.SERVICE_COUNT_TITLE',
        //       value: this.serviceCount
        //     },
        //     {
        //       label: 'TENANT.TOTAL_MRC_TITLE',
        //       value: accountIdResults.aggregations.total_mrc.value || 0,
        //       value_type: HeaderNavSpecTypes.CURRENCY
        //     }]
        // };

      });

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if(data.filterGroup){
            // Get the data and open the modal:
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.filterOverlayId;
            })
          }
        });
  }

  // getAccountIdDetails() {
  //   this.tenantAccountIdService.getAccountIdDetails(this.display_id, this.tenantVendorId)
  //     .toPromise()
  //     .then(response => {
  //       console.log("here is response", response)
  //       this.accountDetails = response

  //       // this.detailHeaderNavData = <DetailHeaderNavData> {
  //       //   headline: this.accountId.account.label,
  //       //   byline: {
  //       //     label: 'MISO_ID_BYLINE',
  //       //     obj: {display_id: this.accountId.account.display_id}
  //       //   },
  //       //   specs: [
  //       //     {
  //       //       label: 'TENANT.SERVICE_COUNT_TITLE',
  //       //       value: response.data[0].service_count || 0
  //       //     },
  //       //     {
  //       //       label: 'TENANT.TOTAL_MRC_TITLE',
  //       //       value: response.data[0].mrc || 0,
  //       //       value_type: HeaderNavSpecTypes.CURRENCY
  //       //     }]
  //       // };
  //       this.navLoaded = true;
  //     })
  //     .catch(error => {
  //       this.error = error;
  //     });
  // }





}
