import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NO_DATA} from '../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Product} from '../../../../../../shared/models/Product';
import {ParentServiceState} from '../../../state/reducer';
import * as ParentServiceActions from '../../../state/actions';
import * as ParentServiceReducer from '../../../state/reducer';
import {TenantProductsService} from '../../../../../../libs/api/tenant/tenantProducts';

@Component({
  selector: 'mi-parent-service-product',
  styleUrls: ['./parent-service-product.component.scss'],
  templateUrl: './parent-service-product.component.html'
})

export class ParentServiceProductComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  cancelLink: string;
  componentForm: FormGroup;
  error: string;
  formProgression: any[] = ParentServiceReducer.ParentServiceProgression;
  formStep: string = ParentServiceReducer.ParentServiceSteps.Product;
  index: number = this.formProgression.indexOf(this.formStep);
  parentServiceState: ParentServiceState;
  product: Product;
  step: string = ParentServiceReducer.ParentServiceSteps.Product;
  defaultProductName: string;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<ParentServiceState>,
    private tenantProductService: TenantProductsService
  ) {
    super();
  }

  ngOnInit() {

    this.cancelLink = `/services`;

    this.componentForm = this.formBuilder.group({
      product: [null, Validators.required]
    });

    this.store.select(ParentServiceReducer.getParentServiceStore)
      .subscribe(
        (state: ParentServiceState) => {
          this.parentServiceState = state;
          if(this.parentServiceState.product){
            this.defaultProductName = this.parentServiceState.product.product_name;
          } else if(this.parentServiceState.services && this.parentServiceState.services.length > 0){
            this.defaultProductName = this.parentServiceState.services[0].product.product_name;
          }
          this.activeIndex = this.index === this.parentServiceState.index;
          if (state.product) {
            // Prodcut has already been defined; show it's id:
            this.componentForm.get('product').setValue(this.parentServiceState.product.display_id);
          } else {
            // Nothing yet ...
            this.componentForm.get('product').setValue(null);
          }
          this.componentForm.updateValueAndValidity();
        },
        (e) => {
          this.error = e;
        });

  }

  onAddedSelected(vendorProduct: Product): void {
    this.componentForm
    .get('product')
    .setValue(null);
    this.error = '';
    if(vendorProduct && vendorProduct.display_id){
      this.componentForm
      .get('product')
      .setValue(vendorProduct.display_id);

      this.onFormSubmit(this.componentForm.value);
    } else{
      this.error = "The product does not have display id: Error on product Create.";
    }
  }

  onFormSubmit(data: any) {

    // Test that the passed ID is valid
    this.tenantProductService.getProductByDisplayId(data.product)
      .then((product: Product) => {

        const maxIndex = Math.max(this.parentServiceState.maxIndex, this.index + 1);

        this.store.dispatch(new ParentServiceActions.UpdateParentServiceAction({
          index: maxIndex,
          maxIndex: maxIndex,
          product: product
        }));

      })
      .catch((e) => {

        this.error = `No product with display id ${data.product} on this client. Please try again.`;

      });

    /*const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          manufacturing_vendor: data.manufacturing_vendor,
          purchasing_vendor: this.orderState.vendor,
          product: data.product
        }
      }
    ));*/

  }

}
