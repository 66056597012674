import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'mi-cascade-need-replace',
  templateUrl: './cascade-need-replace.component.html',
  styleUrls: ['./cascade-need-replace.component.scss']
})

export class CascadeNeedReplaceComponent {

  @Input() dependents : any[];
  @Input() title: string;
  @Input() subtitle: string;
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();


  onCancel() {
    this.canceled.emit();
  }

}

