<mi-sortable-table *ngIf="selectedEquipments && selectedEquipments.length>0; else noSelectedEquipments" [rows]="selectedEquipments"
  [columns]="selectedEquipmentsColumns">
</mi-sortable-table>

<ng-template #noSelectedEquipments>
  <div class="no-results">
    {{'TENANT.SERVICE_DETAILS.EQUIPMENT.EQUIPMENT_EMPTY_TITLE' | translate}}
  </div>
</ng-template>
<div class="button-container {{isDetailPage? 'detail-page': ''}}">
  <button type="button" mi-button buttonTheme="light-theme" (click)="openEquipmentModal()">
    Add Equipment
  </button>
</div>

<ng-template #removeTmpl let-row="row" let-value="value">
  <a (click)="onEquipmentRemoved(row)" class="deleteEquipment"></a>
</ng-template>
