import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Tenant } from 'src/shared/models/Tenant';
import { TenantEmailService } from 'src/libs/api/tenant/tenantEmail.service';

@Component({
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})

export class DownloadsComponent implements OnInit {
  tenant: Tenant;
  link : string = '';
  logoSrc = '/shared/assets/images/miso3-logo.svg';
  error : any;
  buttonLabel: string= '';
  loaded: boolean= false;

  constructor (
    private activatedRoute: ActivatedRoute,
    private emailService: TenantEmailService

  ) {
  }
  ngOnInit(){
    this.emailService.getTenantLogo()
      .subscribe(logo=> this.logoSrc = logo);

    this.activatedRoute
    .queryParams
    .subscribe((queryParams: Params) => {
      this.emailService.getAccessFeature(queryParams.access_feature)
      .subscribe(data=>{
        this.buttonLabel= data.data.file_name;
        this.link= data.downloadLink;
        this.loaded= true;
      },e => {this.error = e;this.loaded= true;})
    },
    e => {this.error = e;this.loaded= true;})
    }

}
