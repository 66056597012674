import {Component, Input,} from "@angular/core";
import {TransCopyData} from "../../ng-models/TransCopyData.interface";

@Component({
  selector: 'mi-trans-copy',
  template: `<span innerHTML="{{_isTranslatable ? (_label | translate:_obj) : _label }}"></span>`
})

export class TransCopyComponent {

  _label: string;
  _obj: any = {};
  _isTranslatable: boolean = false;

  @Input() set copy(value: TransCopyData | string) {
    if (typeof value === 'string') {
      this._isTranslatable = false;
      this._label = value;
    } else {
      this._isTranslatable = true;
      this._obj = value.obj || {};
      this._label = value.label;
    }
  }
}
