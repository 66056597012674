import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {MiService} from '../../../shared/models/MiService';
import {PaymentPlan} from '../../../shared/models/PaymentPlan.interface';
import {map} from "rxjs/operators";

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class AccountPlansService {

  private apiPlansCrud = '/miyagi/account/plans';
  private assignPlanUrl = '/miyagi/account';

  constructor(private http: HttpClient) {
  }

  createPlan(planData: PaymentPlan): Observable<MiService> {
    return this.http
      .post<MiService>(this.apiPlansCrud, planData)
      .pipe(
        map((response: any) => response.data as MiService)
      );
  }

  getPlan(plan_id: string): Observable<MiService> {
    return this.http
      .get<MiService>(`${this.apiPlansCrud}/${plan_id}`)
      .map((response: any) => response.data.paymentPlan);
  }

  editPlan(planData: PaymentPlan): Observable<MiService> {
    return this.http
      .put<MiService>(`${this.apiPlansCrud}/${planData._id}`, planData)
      .map((response: any) => response.data.paymentPlan);
  }

  getPlans(): Observable<PaymentPlan[]> {
    return this.http
      .get<PaymentPlan[]>(this.apiPlansCrud)
      .map((response: any) => response.data.paymentPlans);
  }

  addPlanOptionsToTenant(domain, plans): Observable<PaymentPlan[]> {
    return this.http
      .put<PaymentPlan[]>(`${this.assignPlanUrl}/${domain}/plan-options`, {plan_options: plans})
      .map((response: any) => response.data.paymentPlans);
  }

  addPlanToTenant(domain, payment_plan): Observable<PaymentPlan> {
    return this.http
      .put<PaymentPlan>(`${this.assignPlanUrl}/${domain}/payment-plan`, {payment_plan: payment_plan})
      .map((response: any) => response.data.paymentPlans);
  }

}

