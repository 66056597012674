<mi-section-title [title]="title">
  <a
    (click)="onToggleExpand()"
    *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>
</mi-section-title>

<ng-container *ngIf="_order; else loading">

  <div class="open" *ngIf="isOpen; else static">

    <mi-find-or-create-vendor-documents
      [selectDocuments]="getSelectedDocuments()"
      [vendor]="_order.vendor"
      (selectedVendorDocuments)="onDocumentSelected($event)">

    </mi-find-or-create-vendor-documents>

    <mi-server-error [error]="saveError"></mi-server-error>
    <div class="half-col">

      <div class="button-container">
        <button
          mi-button
          buttonTheme="light-theme"
          (click)="onToggleExpand()">
          Cancel
        </button>
      </div>

    </div>

  </div>

  <ng-template #static>

    <div class="section-details">

      <div class="associated-documents">
          <mi-section-data-attachments
          [docs]="this._order.associated_documents"
          noResultsText="{{'ORDER_INSTRUCTIONS.NO_ATTACHMENT' | translate }}">
        </mi-section-data-attachments>
      </div>
    </div>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
