<form [formGroup]="usageForm" (submit)="onFormSubmit(usageForm.value)">

  <mi-extended-input [labelText]="title"
                      hint="This is often a service ID, circuit ID, or serial #."
                      [(inputControl)]="usageForm.controls['service_identifier']"
                      >
  <div class="tile" *ngIf='service_id_hint'>

    <div class="header">
        <span *ngIf='service_id_hint.logo'><mi-vendor-logo url="{{service_id_hint.logo.full_path}}" name="{{service_id_hint.vendorName}}"></mi-vendor-logo></span> {{service_id_hint.vendorName}}
    </div>

    <div class="note-body"  [innerHTML]="this.service_id_hint.html"></div>

  </div>

    <radio-list [allowNew]="true"
            placeholder="Service ID"
            formControlName="service_identifier"
            textFirst="true"
            [newValue]="service_id"
            [selectedIndex]= "selectedIndex"
            (change) = "completeServiceId? onFormSubmit(usageForm.value): ''"
          [buttonOptions]="['I do not have my service ID or will supply at a later date', noServiceID]">
    </radio-list>
  </mi-extended-input>

  <div class="button-container"
        *ngIf="!completeServiceId">
    <ng-content>
    </ng-content>
    <button mi-button
      type="submit"
      [disabled]="!usageForm.valid"
      buttonTheme="dark-theme">
      {{completeButton}}
    </button>

  </div>

</form>
