<div class="tab-title-header">
  <h2>{{title}}</h2>
</div>
<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="(pageLoaded); else loading">
  <!-- <mi-custom-field-service-static [currentFieldData]="customFieldData">
    </mi-custom-field-service-static> -->
  <ng-container *ngIf="customFieldData.length; else noCustomFields">
    <dl class="section-details card-1">
      <ng-container *ngFor="let customFieldProperty of customFieldData">
        <div>
          <dt>
            {{customFieldProperty.label}}
          </dt>
          <dd>
            {{customFieldProperty.value}}
          </dd>
        </div>
        </ng-container>
        </dl>
        </ng-container>
        <ng-template #noCustomFields>
          <div class="no-results">
            {{'TENANT.CUSTOM_FIELDS.CUSTOM_FIELDS_EMPTY_TITLE' | translate}}
          </div>
    </ng-template>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>