import {Component, OnInit, Output, Input, EventEmitter} from "@angular/core";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {ModalCloseReason, ModalService} from "@independer/ng-modal";
import { SelectContactModalComponent } from "src/tenant/components/select-contact-modal/select-contact-modal.component";
import { takeUntil } from "rxjs/operators";
import { MiService } from "src/shared/models/MiService";
import { TenantMiServiceService } from "src/libs/api/tenant/tenantMiService.service";
import { ActivatedRoute } from "@angular/router";
import { SelectEmployeeModalComponent } from '../select-employee-modal/select-employee-modal.component';

@Component({
  selector: 'mi-service-requestor',
  templateUrl: './service-requester.component.html',
  styleUrls: ['./service-requester.component.scss']
})

export class ServiceRequestorCommponent extends AbstractPageComponent implements OnInit {

  @Output() complete: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() skipRoute: string = "../../";
  @Input() cancelRoute: string = "../../";
  @Input() service: MiService;
  serviceWithRequestor: MiService;
  snapshot:string;

  loaded: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private serviceService: TenantMiServiceService) {
  super();
  }

  ngOnInit() {
    this.snapshot = this.activatedRoute.snapshot.params['display_id'];
    if(this.service.requestor_email){
      this.serviceWithRequestor = this.service;
    }
    this.loaded = true;
  }

  onCancel() {
    this.canceled.emit();
  }

  openEmployeeModal() {
    const modalRef = this.modalService.open(SelectEmployeeModalComponent, m => {
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      console.log("SelectEmployeeModalComponent Result: ", result)
      if(result[0] && result[0].tenantUser){
        let selectedEmployee= result[0].tenantUser;

        if(this.service && this.service._id){
          this.serviceService.updateMiService(this.service._id, {requestor_email: selectedEmployee.email})
          .subscribe(service=> {
            this.serviceWithRequestor = service;
          })
        } else {
          this.complete.emit(selectedEmployee);
        }
      }
    });
  }

  next(){
    this.complete.emit();
  }
}
