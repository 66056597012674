import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router, Params} from "@angular/router";
import { Vendor } from "../../../../../shared/models/Vendor";
import { TenantVendors } from "../../../../../libs/api/tenant/tenantVendors";
import {Link} from '../../../../../shared/ng-models/Link.interface';
import {environment} from '../../../../../environments/environment';
import { TermsAndConditions } from "../../../../../shared/models/TermsAndConditions";
import { AssociatedDocumentFormType } from "../../../../../shared/components/associated-document-form/associated-document-form.component";
import linkFormatter from "src/common/utils/linkFormatter";


@Component({
  templateUrl: './associated-documents-create.component.html',
  styleUrls: ['./associated-documents-create.component.scss']
})

export class AssociatedDocumentsCreateComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  error: string;
  supportLink: string = linkFormatter.getSupportLink();;
  vendor: Vendor;
  associatedDocumentFormType: AssociatedDocumentFormType = AssociatedDocumentFormType.UPLOAD;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantVendors: TenantVendors,
              private router: Router) {
    super();
  }

  ngOnInit() {

    const vendorId: string = this.activatedRoute.snapshot.params.vendor_display_id;
    const type: string = this.activatedRoute.snapshot.params.type;

    if((type||"").toUpperCase() === "LINK")
      this.associatedDocumentFormType = AssociatedDocumentFormType.LINK;

    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Vendors',
        url: '/vendors',
        internal: true
      },
      {
        label: `Vendor Details ${vendorId}`,
        url: `/vendors/${vendorId}/details`,
        internal: true
      },
      {
        label: `Create Attachments`,
        url: `/vendors/${vendorId}/associated-documents/create/${type}`,
        internal: true
      }
    ];

    this.activatedRoute.params
    .takeUntil(this.destroy$)
    .switchMap(params => this.tenantVendors.getVendorByDisplayId(vendorId))
    .subscribe(vendor => this.vendor=vendor);
  }

  onAssociatedDocumentCancelled() {
    this.router.navigateByUrl(`/vendors/${this.vendor.display_id}`);
  }

  onAssociatedDocumentCreated(termandcondition:TermsAndConditions):void {
    // The change has been added.
    this.router.navigateByUrl(`/associated-documents/${termandcondition.display_id}/details`);
  }

}
