<ng-container *ngIf="resultsLoaded$ | async; else loading">

  <mi-parent-service-tile *ngFor="let service of (hits$ | async)"
                          [mode]="parentServiceTileMode.SEARCH_RESULT"
                          [service]="service">

  </mi-parent-service-tile>

  <ng-container *ngIf="!(hits$ | async).length"><p>No matching results found.</p></ng-container>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

