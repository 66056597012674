import {Component, Input} from "@angular/core";
import {print} from "../../shared.functions";

@Component({
  selector: 'mi-payment-card-display',
  styleUrls: ['./payment-card-display.component.scss'],
  templateUrl: './payment-card-display.component.html'
})

export class PaymentCardDisplayComponent
{
  @Input() provider: string;
  @Input() last4: string;

  getCardLogo(provider: string) {
    return "/shared/assets/images/card-logos/" + provider.toLowerCase() + ".svg";
  }
}
