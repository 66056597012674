import {Component, Input, OnInit} from '@angular/core';
import {Note} from '../../models/Note.interface';
import {AUTH} from '../../../common/security/secureUser';
import * as moment from 'moment';

@Component({
  selector: 'mi-note-item',
  styleUrls: ['./note-item.component.scss'],
  templateUrl: './note-item.component.html'
})

export class NoteItemComponent implements OnInit {

  @Input() note: Note;
  @Input() showSuperUsers: boolean = false;

  initials: string = '';
  archived: boolean = false;
  relativeTime: string;

  constructor() {

  }

  ngOnInit() {

    if (this.note && this.note.user) {
      if (!this.note.user.isSuperUser) {
        this.initials = this.note.user.first_name.charAt(0) + this.note.user.last_name.charAt(0);
      }
      this.archived = (this.note.user_tenant_profile && this.note.user_tenant_profile.role === AUTH.NO_ACCESS_USER);
      this.relativeTime = moment(this.note.created_at).fromNow();
    } else if(this.note && this.note.user_email) {
      this.relativeTime = moment(this.note.created_at).fromNow();
    }

  }
}

