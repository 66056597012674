import {Component, OnInit} from '@angular/core';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {ActivatedRoute} from '@angular/router';
import {MiService} from '../../../../shared/models/MiService';
import {TenantMiServiceService} from '../../../../libs/api/tenant/tenantMiService.service';
import {combineLatest} from "rxjs";
import {TenantUsersService} from '../../../../libs/api/tenant/tenantUsers.service';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Vendor} from '../../../../shared/models/Vendor';
import {scrollTo} from '../../../../shared/shared.functions';
import {Store} from '@ngrx/store';
import * as ParentServiceActions from '../state/actions';
import * as ParentServiceReducer from '../state/reducer';
import {ParentServiceState} from '../state/reducer';
import {ParentServiceSteps} from '../state/reducer';
import {OrderState} from '../../services/orders/create/state/reducer';
import * as OrderActions from '../../services/orders/create/state/actions';
import * as OrderReducers from '../../services/orders/create/state/reducer';
import {User} from '../../../../shared/models/User';

export interface Assignee {
  label: string;
  selected: boolean;
  value: string,
};

@Component({
  styleUrls: ['./parent-services-create.component.scss'],
  templateUrl: './parent-services-create.component.html'
})

export class ParentServicesCreateComponent extends AbstractPageComponent implements OnInit {

  assignee: User;
  breadcrumbs: Link[] = [
    {
      url: '',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Parent Services',
      internal: true

    },
    {
      url: '/parent-services/create',
      label: 'Parent Service Create',
      internal: true
    }
  ];
  error: string;
  loaded: boolean = false;
  parentServiceState:ParentServiceState;
  parentServiceSteps: any = ParentServiceSteps;
  service: MiService;
  services: MiService[] = [];
  state: ParentServiceState;
  orderState: OrderState;
  vendor: Vendor;

  constructor(private tenantService: TenantMiServiceService,
              private tenantUserService: TenantUsersService,
              private activatedRoute: ActivatedRoute,
              private store: Store<ParentServiceState>,
              private orderStore: Store<OrderState>){
    super();
  }

  ngOnInit() {

    /*
    If the clicked through from an existing service, pre-populate some information.
    */

    let serviceDisplayId: string = this.activatedRoute.snapshot.queryParams.s;

    if (serviceDisplayId) {

      this.connectToStore();

      const serviceData = this.tenantService.getMiService(serviceDisplayId);
      const usersData = this.tenantUserService.getUsers(['NO_ACCESS_USER']);

      combineLatest(serviceData, usersData)
        .takeUntil(this.destroy$)
        .subscribe((allValues) => {

          this.service = allValues[0];
          this.services.push(this.service);
          this.vendor = this.service.purchasing_vendor;

          this.assignee = this.parseAssignees(allValues[1].map((user)=>user._rawData));

          this.store.dispatch(new ParentServiceActions.UpdateParentServiceAction({
            ...this.parentServiceState,
            assigned_email: this.assignee,
            services: this.services,
            vendor: this.vendor
          }));

          this.loaded = true;
        });
    } else {
      // No Service passed in; just get the Users.
      this.error = 'Parent Services can only be created from a pre-existing Child Service.';
      this.loaded = true;

    }
  }

  connectToStore(){

    this.store.dispatch(new ParentServiceActions.ResetParentServiceAction({}));

    this.store.select(ParentServiceReducer.getParentServiceStore)
      .subscribe(
        (state:ParentServiceState) => {

          this.state = state;
          this.orderStore.dispatch(new OrderActions.UpdateOrderAction({
            index: this.state.index
          }));

          scrollTo(`section_${state.index}`);

        },
        (e) => {
          this.error = e;
        });

    this.orderStore.dispatch(new OrderActions.ResetOrderAction({}));
    this.orderStore.dispatch(new OrderActions.UpdateOrderAction({
      index: 0,
      maxIndex: ParentServiceReducer.ParentServiceProgression.length
    }));

    this.orderStore.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe((state:OrderState)=>{
        this.orderState = state;
        if(state.index !== this.state.index){
          this.store.dispatch(new ParentServiceActions.UpdateParentServiceAction({
            ...this.state,
            index: state.index
          }));
        }
      });

  }

  parseAssignees(users: any[]): User{

    return users.sort((usr_one, usr_two) => {
      return `${usr_one.first_name} ${usr_one.last_name}` > `${usr_two.first_name} ${usr_two.last_name}`
        ?
        1
        :
        -1;
    }).find((user)=>{
      return user.email === this.service.assigned_user.email
    }) || null;

  }

}
