import * as orderCreateActions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
// import {
//   OrderDraft,
// } from "../../../../../../shared/models/OrderDraft";
import {createLogger, LOG_LEVELS} from "../../../../../../shared/logger";
import { SimpleServiceObject } from 'src/shared/models/SimpleServiceObject';

const log = createLogger(LOG_LEVELS.REDUX_DEBUG);

export const OrderSteps: any = {
  VENDOR_SELECT: 0,
  PRODUCT_SELECT: 1,
  FINANCIALS: 2,
  OWNERSHIP: 3,
};

const initialState: SimpleServiceObject = {
  activeStep: OrderSteps.VENDOR_SELECT,
  lastStep: OrderSteps.VENDOR_SELECT,
  draftService: <any>{
    purchasing_vendor: null,
    product: null,
    financial: null,
    ownership: null,
    account_id: null,
    associated_documents: null
  },
  loaded: false,
  error: null
};

export function reducer(state = initialState, action: orderCreateActions.Actions): any {
  switch (action.type) {

    case orderCreateActions.ActionTypes.LOAD_AS_NEW: {
      return {
        ...initialState,
        loaded: true
      };
    }

    case orderCreateActions.ActionTypes.UPDATE_DRAFT_SERVICE: {
      let activeStep = action.payload.activeStep === 0 ? 0 : action.payload.activeStep || state.activeStep;
      let lastStep = state.lastStep < activeStep? activeStep : state.lastStep;
      return {
        ...state,
        draftService: {
          ...state.draftService,
          ...action.payload.draftService
        },
        loaded: action.payload.loaded === false ? action.payload.loaded : true,
        error: null,
        activeStep: activeStep,
        lastStep: lastStep || initialState.lastStep
      };
    }

    case orderCreateActions.ActionTypes.SERVICE_CREATE_ERROR: {

      let error:any = 'Error Saving Service';
      if (action.payload.error.message) {
        error = {
          message: action.payload.error.message,
          stack: action.payload.error.stack
        }
      };

      return {
        ...state,
        loaded: true,
        error
      };
    }

    default: {
      return state;
    }
  }
}

export const getSimpleCreateStore = createFeatureSelector<any>('simple-create-store');
export const getStepNumber = createSelector(getSimpleCreateStore, (state: any) => state.activeStep);
