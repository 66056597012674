import { ToastrService } from 'ngx-toastr';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@independer/ng-modal';
import { TenantService } from 'src/libs/api/account/tenant.service';
import { ConfirmEOTDeleteModalComponent } from '../confirm-eot-delete-modal/confirm-eot-delete-modal.component';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { EditVendorEndOfTermModal } from '../edit-vendor-end-of-term-modal/edit-vendor-end-of-term-modal.component';

@Component({
  selector: 'action-accounts-end-of-term',
  template: `<div *ngIf="rowData.account_end_of_term.service_term_event_type !== null" placement="left" ngbTooltip="Delete Renewal Term">
  <button class="btn btn-sm btn-danger text-white" (click)="confirmDeleteHandler(rowData)">Remove</button>
</div>
<div  *ngIf="rowData.account_end_of_term.service_term_event_type === null" placement="left" ngbTooltip="Add Renewal Term">
<button class="btn btn-sm btn-primary" (click)="editAccountEOTClickHandler(rowData)">Update</button>
</div>
`,
})

export class ActionAccountEndOfTermComponent {
  @Input() rowData: any;

  @Output() customEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public tenantService: TenantService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private serviceTermService: ServiceTermService
  ) { }

  confirmDeleteHandler(account: any) {
    console.log("here confirmDeleteHandler", account)

    const modalRef = this.modalService.open(ConfirmEOTDeleteModalComponent, m => {
      m.modalTitle = this.rowData.vendor_name + ' ' + '(' + account.account_identifier + ')'
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      console.log("here result", result)
      if (result[0]) {
        const payload = {
          accountid_id: account.id,
          is_deleted: true,
          service_id: null,
          service_display_id: null,
          service_term_id: this.rowData.account_end_of_term.account.service_term_id,
          tenant_vendor_id: this.rowData.account_end_of_term.account.tenant_vendor_id,
        }

        this.serviceTermService.saveServiceTermAssociation(payload)
          .toPromise()
          .then((data: any) => {
            this.customEvent.emit();
            setTimeout(() => {
              this.toastr.success(`Accounts terms removed successfully`);
            }, 3000)
          })
          .catch((e) => console.warn(e));
      }
    });
  }

  editAccountEOTClickHandler(vendorAccount: any) {
    const modalRef = this.modalService.open(EditVendorEndOfTermModal, m => {
      m.selectedVendor = vendorAccount.account_identifier + (vendorAccount.account_dsp_id)
      m.tenantServiceTerms = vendorAccount.tenantServiceTerms
      m.showEOTList = true
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {

      if (result) {
        const payload = {
          accountid_id: vendorAccount.id,
          is_deleted: false,
          service_id: null,
          service_display_id: null,
          service_term_id: result[0].data.id,
          tenant_vendor_id: this.rowData.vendor_id,
        }

        this.serviceTermService.saveServiceTermAssociation(payload)
          .toPromise()
          .then((data: any) => {
            this.customEvent.emit();
            this.toastr.success(`Accounts terms updated successfully`);
          })
          .catch((e) => console.warn(e));


      }
    });


  }

}