import {Injectable} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {RECENT_CONTACTS, MAX_RECENT_CONTACTS} from '../../common/utils/sharedConstants';
import { Contact } from '../models/Contact';
import {Observable} from 'rxjs';
import { ContactV2 } from 'src/libs/api2/types/ContactV2.interface';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';

@Injectable()
export class RecentContactsStorageService {

  constructor(private localStorage: LocalStorage) {
  }

  saveRecentContact(contact:VendorContact): Observable<VendorContact[]> {
    return this.getRecentContacts()
    .switchMap((contacts) => {
        contacts = contacts || [];

        // Find if the contact is alredy in the list:
        let index = contacts.findIndex((c) => {
          return c._id === contact._id;
        });

        if (index === -1) {
          // Push the item onto the list:
          contacts.push(contact);
        } else {
          // The person's already on the list.
          // Pop them off and add them back on
          // so that they're in the front
          // of the list.
          contacts.splice(index, 1);
          contacts.push(contact);
        }

        if (contacts.length > MAX_RECENT_CONTACTS) {
          // The list is longer than the max.
          // Truncate the earliest item in the list.
          contacts.splice(0, 1);
        }

        return this.localStorage.setItem(RECENT_CONTACTS, contacts)
        .toPromise()
        .then(() => {
          return contacts;
        });
      });
  }

  getRecentContacts(): Observable<VendorContact[]> {
    return this.localStorage.getItem<Array<VendorContact>>(RECENT_CONTACTS);
  }

}
