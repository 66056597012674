import {
  Component, OnInit, ViewChild, TemplateRef
} from '@angular/core';
import { AbstractPageComponent } from "../../../shared/pages/abstract-page/abstract-page.component";
import { Link } from "../../../shared/ng-models/Link.interface";

import * as linkFormatter from '../../../common/utils/linkFormatter';
import { TenantService } from '../../../libs/api/account/tenant.service';
import { TenantBridge, TenantBridgeFields } from '../../../shared/models/CustomField';
import { VendorDefinedFieldsV2Service } from 'src/libs/api2/vendor-defined-fields/vendor-defined-fields-v2.service'
import { SectionDataTableColumn } from 'src/shared/ng-models/SectionDataColumn.interface';

@Component({
  templateUrl: './vendor-defined-fields.component.html',
  styleUrls: ['./vendor-defined-fields.component.scss']
})

export class VendorDefinedFieldsComponent extends AbstractPageComponent implements OnInit {

  title: string;
  breadcrumbs: Link[] = [{
    url: '',
    label: 'Dashboard',
    internal: true
  }, {
    url: '/vendor-defined-fields',
    label: 'Vendor Defined Fields',
    internal: true
  }];;
  tenantBridge: TenantBridge[] = [];
  tenantId: string
  bridgeTableColumn: SectionDataTableColumn[] = [];
  fieldsTableColumn: SectionDataTableColumn[] = [];
  customFields: TenantBridgeFields[]
  selectedRow: TenantBridge;
  error: string
  filteredBridges: TenantBridge[] = []
  isLoading: boolean = true
  @ViewChild("bridgeDetailsTemplate", { static: true })
  bridgeDetailsTemplate: TemplateRef<any>;
  filterString: string = ""
  constructor(
    private tenantService: TenantService,
    private vendorDefinedFieldsV2Service: VendorDefinedFieldsV2Service,
  ) {
    super();
  }

  ngOnInit() {
    this.tenantService.getCurrentTenant().then(tenant => {

      // tenant.full_domain = linkFormatter.getTenantLink(
      //   tenant.domain
      // );
      this.tenantId = tenant.domain;
      this.getTenantBridges()
    });
  }

  getTenantBridges() {
    this.vendorDefinedFieldsV2Service.list(this.tenantId)
      .toPromise()
      .then((tenantBridge: TenantBridge[]) => {
        this.tenantBridge = tenantBridge
        this.filteredBridges = this.tenantBridge
        this.initializeBridgesTable()
        this.isLoading = false
      })
      .catch(e => this.error = e);
  }

  getTenantBridgeDetails(bridgeId: string, bridgeDetailsModal?: any) {
    this.vendorDefinedFieldsV2Service.getBridgeDetails(this.tenantId, bridgeId)
      .toPromise()
      .then((customFields: TenantBridgeFields[]) => {
        this.customFields = customFields
        this.initializeCustomFieldsTable()
        bridgeDetailsModal.open();
      })
      .catch(e => this.error = e);
  }


  initializeBridgesTable() {
    this.bridgeTableColumn = [
      {
        name: "Details",
        prop: "id",
        sortable: false,
        cellTemplate: this.bridgeDetailsTemplate,
      },
      {
        name: "Vendor Name",
        prop: "vendorName",
        sortable: true,
      },
      {
        name: "Vendor URL",
        prop: "vendorUrl",
        sortable: true,
      }
    ]
  }

  initializeCustomFieldsTable() {
    this.fieldsTableColumn = [
      {
        name: "Label",
        prop: "customFieldLabel",
        sortable: true,
      },
      {
        name: "Type",
        prop: "customFieldType",
        sortable: true,
      }
    ]
  }

  bridgeDetailsModalHandler(row: TenantBridge, bridgeDetailsModal: any) {
    this.selectedRow = row;
    this.getTenantBridgeDetails(row.bridgeId, bridgeDetailsModal)
  }

  onSearchTermChanged(event) {
    this.filterString = event
    this.filteredBridges = this.tenantBridge.filter((bridge: TenantBridge) => bridge.vendorName.toLowerCase().includes(event.toLowerCase()))
  }
}