<mi-page-header
  title="{{ 'TENANT.MISERVICE_ORDER_EDIT_TITLE' | translate:{display_id: display_id} }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<ng-container *ngIf="loaded; else loading">

  <a class="link--back" routerLink="../">Back</a>
  <h2>Edit Order</h2>

  <form [formGroup]="orderForm"
        (submit)="onFormSubmit(orderForm.value)">

    <mi-extended-input labelText="Vendor"
                       [labelRequired]="true"
                       [(inputControl)]="orderForm.controls['vendor']"
                       [(inputErrors)]="orderForm.controls['vendor'].errors"
                       [(inputTouched)]="orderForm.controls['vendor'].touched"
                       [errorDefs]="{required: 'A vendor is required.'}">

      <input type="text"
             disabled="disabled"
             class="form-control"
             formControlName="vendor" />
    </mi-extended-input>


    <mi-extended-input labelText="Order Date"
                       [labelRequired]="false"
                       [(inputControl)]="orderForm.controls['order_date']"
                       [(inputErrors)]="orderForm.controls['order_date'].errors"
                       [(inputTouched)]="orderForm.controls['order_date'].touched">
      <mi-date-picker class="narrow-picker"
                      (dateChange)="onDateChange($event)"
                      [currentDate]="order.order_date">
      </mi-date-picker>
    </mi-extended-input>


    <mi-extended-input
      labelText="Order Id"
      [labelRequired]="false"
      [(inputControl)]="orderForm.controls['order_id']"
      [(inputErrors)]="orderForm.controls['order_id'].errors"
      [(inputTouched)]="orderForm.controls['order_id'].touched">
      <input type="text"
             class="form-control"
             formControlName="order_id"
             placeholder="ex. 10001"
             mi-restrict-input miRestrictType="OrderId"/>
    </mi-extended-input>

    <mi-extended-input
      labelText="Vendor Account ID"
      [labelRequired]="true"
      [(inputControl)]="orderForm.controls['account_id']"
      [(inputErrors)]="orderForm.controls['account_id'].errors"
      [(inputTouched)]="orderForm.controls['account_id'].touched"
      [errorDefs]='{required: "Please select or enter your Vendor Account ID."}'>

      <mi-find-or-create-account-id
        [vendor]="order.vendor"
        [currentAccountId]="order.account_id"
        (accountSelected)="onAccountIdSelected($event)"
        [commandGetAccountId]="commandGetAccountId">
      </mi-find-or-create-account-id>

    </mi-extended-input>

    <mi-server-error [error]="submitError"></mi-server-error>

    <div class="button-container">
      <a mi-button
         buttonTheme="dark-theme"
         routerLink="../"
        >
        Cancel
      </a>
      <button mi-button
              buttonTheme="dark-theme"
              type="submit">
        Save
      </button>
    </div>

  </form>


</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
