<ng-container *ngIf="loaded; else loading">
  <div *ngIf="!tenantUser">
    <a mi-button buttonTheme="dark-theme" 
            (click)="openEmployeeModal()">
      Select Employee
    </a>
  </div>


  <div *ngIf="tenantUser">

    <table class="mi-sort-table fullWidth">

        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              Email
            </th>
            <th>
              Cost Center
            </th>
            <th>
              Action
            </th>
          </tr>
        </thead>
  
          <!-- now do the rows -->
        <tbody> 
          <tr>
            <td>
              {{tenantUser.first_name+' '+tenantUser.last_name}}
            </td>
            <td>
              <div class="text-align">
                {{tenantUser.email}}
              </div>
            </td>
            <td>
              {{tenantUser.cost_center}}
            </td>
            <td>
              <a
                class="deleteContact"
                (click)="onRemoveEmployee()">
              </a>
            </td>
          </tr>
        </tbody>
  
      </table>
  </div>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
