<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide no-padding-top">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <div id="summary-container" class="summary-container">
      <div class="summary-header-label">
        <label>Selected Plan</label>
      </div>
      <div class="summary-display">
        <table class="summary-display-table">
          <tr>
            <td>Plan Name</td>
            <td>{{newAccountPlan.planName}}</td>
          </tr>
          <tr>
            <td>Billed</td>
            <td *ngIf="billingInterval == 'month'">{{newAccountPlan.planBaseCostMonthly | tenantcurrency:'1.0'}} / <sub>Month</sub></td>
            <td *ngIf="billingInterval == 'year'">{{newAccountPlan.planBaseCostYearly | tenantcurrency:'1.0'}} / <sub>Year</sub></td>
          </tr>
          <tr *ngIf="newAccountPlan.allowTrial">
            <td>Trial Period</td>
            <td>{{newAccountPlan.trialDays}} Days</td>
          </tr>
          <tr>
            <td>Templates Included</td>
            <td>{{newAccountPlan.templatesIncluded}}</td>
          </tr>
          <tr>
            <td>Services Included</td>
            <td>{{newAccountPlan.servicesIncluded}}</td>
          </tr>
          <tr>
            <td>Setup Fee per Template</td>
            <td>{{(newAccountPlan.templateSetupFee ? newAccountPlan.templateSetupFee : 0) | tenantcurrency:'1.0'}}</td>   
          </tr>
        </table>
      </div>
      <div class="summary-header-label secondary">
        <label>Add-Ons</label>
      </div>
      <div class="summary-display">
        <table class="summary-display-table">
          <!--
          <tr *ngIf="newAccountPlan.additionalTemplateCost">
            <td>Additional Templates</td>
            <td *ngIf="newAccountPlan.additionalTemplateQuantity == 1">{{newAccountPlan.additionalTemplateCost | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalTemplateQuantity}} Template</sub></td>
            <td *ngIf="newAccountPlan.additionalTemplateQuantity > 1">{{newAccountPlan.additionalTemplateCost | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalTemplateQuantity}} Templates</sub></td>
          </tr>
          <tr *ngIf="newAccountPlan.additionalServiceCost">
            <td>Additional Services</td>
            <td *ngIf="newAccountPlan.additionalServiceQuantity == 1">{{newAccountPlan.additionalServiceCost | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalServiceQuantity}} Service</sub></td>
            <td *ngIf="newAccountPlan.additionalServiceQuantity > 1">{{newAccountPlan.additionalServiceCost | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalServiceQuantity}} Services</sub></td>
          </tr>
          -->
          <tr *ngIf="billingInterval == 'month'">
            <td>Additional Templates</td>
            <td *ngIf="newAccountPlan.additionalTemplateQuantity == 1">{{newAccountPlan.additionalTemplateCostMonthly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalTemplateQuantity}} Template</sub></td>
            <td *ngIf="newAccountPlan.additionalTemplateQuantity > 1">{{newAccountPlan.additionalTemplateCostMonthly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalTemplateQuantity}} Templates</sub></td>
          </tr>
          <tr *ngIf="billingInterval == 'year'">
            <td>Additional Templates</td>
            <td *ngIf="newAccountPlan.additionalTemplateQuantity == 1">{{newAccountPlan.additionalTemplateCostYearly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalTemplateQuantity}} Template</sub></td>
            <td *ngIf="newAccountPlan.additionalTemplateQuantity > 1">{{newAccountPlan.additionalTemplateCostYearly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalTemplateQuantity}} Templates</sub></td>
          </tr>
          <tr *ngIf="billingInterval == 'month'">
            <td>Additional Services</td>
            <td *ngIf="newAccountPlan.additionalServiceQuantity == 1">{{newAccountPlan.additionalServiceCostMonthly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalServiceQuantity}} Service</sub></td>
            <td *ngIf="newAccountPlan.additionalServiceQuantity > 1">{{newAccountPlan.additionalServiceCostMonthly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalServiceQuantity}} Services</sub></td>
          </tr>
          <tr *ngIf="billingInterval == 'year'">
            <td>Additional Services</td>
            <td *ngIf="newAccountPlan.additionalServiceQuantity == 1">{{newAccountPlan.additionalServiceCostYearly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalServiceQuantity}} Service</sub></td>
            <td *ngIf="newAccountPlan.additionalServiceQuantity > 1">{{newAccountPlan.additionalServiceCostYearly | tenantcurrency:'1.0'}} / <sub>{{newAccountPlan.additionalServiceQuantity}} Services</sub></td>
          </tr>
        </table>
      </div>
    </div>
  </modal-content>
  <modal-footer>
    <div class="cta">
      <button (click)="changePlan()"
              mi-button buttonTheme="dark-theme">
        Change Plan
      </button>
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        Cancel
      </button>
    </div>
  </modal-footer>
</modal>