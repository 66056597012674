import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MiService, MiServiceDetails } from "../../../../../../shared/models/MiService";
// import { TenantMiServiceService } from "../../../../../../libs/api/tenant/tenantMiService.service";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import { FormGroup } from "@angular/forms";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { of, combineLatest } from 'rxjs';
import { Store } from "@ngrx/store";
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { catchError } from "rxjs/operators";
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { ToastrService } from 'ngx-toastr';
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';

@Component(
  {
    selector: 'mi-subsection-ownership-details',
    templateUrl: './subsection-ownership-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionOwnershipDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: Boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiService> = new EventEmitter<MiService>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  pageLoaded: boolean = false;
  loadUser: boolean = false;
  users: any[] = [];
  formGroup: FormGroup;
  requestor: any;
  endUser: any;
  miMisoUser: any;
  // assignee: any;
  updating: boolean = false;
  serviceDetails: MiServiceDetails
  tenant_id: string

  constructor(
    private contactV2Service: ContactV2Service,
    private serviceV2Service: ServiceV2Service,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {
    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe((data) => { this.tenant_id = data.tenantData.domain },
      (e) => (this.error = e)
    );

    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails
            this.pageLoaded = true
            this.getUsers();
          }
        })
  }

  getUsers() {
    this.loadUser = false;
    // this.assignee = null;
    this.miMisoUser = null
    this.requestor = null;
    this.endUser = null;
    let promise = [];

    // promise.push(
    //   this.contactV2Service.getTenantUser(this.serviceDetails.service_data.m3_owner_email, this.tenant_id)
    //     .pipe(
    //       catchError(() => of(null))
    //     )
    // )

    if (this.serviceDetails.service_data.service_owner_email) {
      promise.push(
        this.contactV2Service.getTenantUser(this.serviceDetails.service_data.service_owner_email, this.tenant_id)
          .pipe(
            catchError(() => of(null))
          )
      )
    } else {
      promise.push(of(null));
    }

    if (this.serviceDetails.service_data.subscriber_email) {
      promise.push(
        this.contactV2Service.getTenantUser(this.serviceDetails.service_data.subscriber_email, this.tenant_id)
          .pipe(
            catchError(() => of(null))
          )
      )
    } else {
      promise.push(of(null));
    }


    if (this.serviceDetails.service_data.mi_miso_user_email) {
        console.log("here this.serviceDetails.service_data.mi_miso_user_email", this.serviceDetails.service_data.mi_miso_user_email)
      promise.push(
        this.contactV2Service.getTenantUser(this.serviceDetails.service_data.mi_miso_user_email, this.tenant_id)
          .pipe(
            catchError(() => of(null))
          )
      )
    } else {
      promise.push(of(null));
    }

    combineLatest(
      promise
    )
      .takeUntil(this.destroy$)
      .subscribe(([requestor, endUser, miMisoUser]) => {
        // this.assignee = assignee;
        this.requestor = requestor;
        this.endUser = endUser;
        this.miMisoUser = miMisoUser;
        this.miMisoUser.origin = this.serviceDetails.service_data.mi_miso_user_origin;

        console.log("here this.miMisoUser takeUntil", this.miMisoUser)

        if (this.serviceDetails.service_data.mi_miso_user_email && !this.miMisoUser) {
          this.miMisoUser = {
            first_name: 'NO USER EXIST ON THIS EMAIL',
            last_name: '',
            email: this.serviceDetails.service_data.mi_miso_user_email === 'miso3tester+firstuser@gmail.com' ? "MISO3" : this.serviceDetails.service_data.mi_miso_user_email
          }
        }

        if (this.serviceDetails.service_data.service_owner_email && !this.requestor) {
          this.requestor = {
            first_name: 'NO EMPLOYEE EXIST ON THIS EMAIL',
            last_name: '',
            email: this.serviceDetails.service_data.service_owner_email === 'miso3tester+firstuser@gmail.com' ? "MISO3" : this.serviceDetails.service_data.service_owner_email
          }
        }

        if (this.serviceDetails.service_data.subscriber_email && !this.endUser) {
          this.endUser = {
            first_name: 'NO EMPLOYEE EXIST ON THIS EMAIL',
            last_name: '',
            email: this.serviceDetails.service_data.subscriber_email === 'miso3tester+firstuser@gmail.com' ? "MISO3" : this.serviceDetails.service_data.subscriber_email
          }
        }

        this.loadUser = true;
        this.pageLoaded = true
      }, e => {
        this.pageLoaded = true
        console.log("getTenantUser error:", e);
      })
  }

  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

  onAssignedEmail(assigned_email) {
    this.formGroup.get('assigned_email').setValue(assigned_email);
  }

  onBusinessUnit(bu) {
    this.formGroup.get('division').setValue(bu.division ? bu.division.id : null);
    this.formGroup.get('team').setValue(bu.team ? bu.team.id : null);
  }


  updateData(event) {
    this.loadUser = false;
    this.updating = true;
    let payloadData = []

    console.log("here event", event)
    console.log("here Object.keys(event)[0]", Object.keys(event)[0])
    console.log("here Object.value(event)[1]", Object.values(event)[1])
    if( Object.keys(event)[0] == "mi_user_email"){
      const userId = Object.values(event)[1] as string
      this.serviceV2Service.miUserUpdateServiceOwnership(this.serviceDetails.service_data.service_id, userId)
      .toPromise()
      .then(res => {
        //this.toastr.success(`Mi Service Owner update successfully`);
        this.toastr.success(`Mi-MiSO Owner updated successfully`);
        this.onToggleExpand();
        this.updateServiceDetailsStore.emit();
      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while remove ownership, Please try again`);
      })
      .finally(() => {
        this.updating = false;
      });
    }
    else{
      payloadData = [
        {
          system_id: event[Object.keys(event)[0]],
          service_property_id: Object.keys(event)[0],
          resolution_value: event[Object.keys(event)[0]]
        }
      ]
      this.serviceV2Service.saveServiceOwnershipService(payloadData, this.serviceDetails.service_data.service_id)
        .toPromise()
        .then(res => {
          this.toastr.success(`Ownership updated successfully`);
          this.onToggleExpand();
          this.updateServiceDetailsStore.emit();
        })
        .catch(e => {
          this.error = e
          this.toastr.error(`Facing issue while updating ownership, Please try again`);
        })
        .finally(() => {
          this.updating = false;
        });
    }
    // switch (event.service_association) {
    //   case 'Service Owner':
        
    //     break;

    //   case 'Mi-Service Owner':
       
    //     break;


    //   case 'Subscriber':
    //     payloadData = [
    //       {
    //         system_id: event[Object.keys(event)[0]],
    //         service_property_id: Object.keys(event)[0],
    //         resolution_value: event[Object.keys(event)[0]]
    //       }
    //     ]
    //     this.serviceV2Service.saveServiceOwnershipService(payloadData, this.serviceDetails.service_data.service_id)
    //       .toPromise()
    //       .then(res => {
    //         this.toastr.success(`Ownership updated successfully`);
    //         this.onToggleExpand();
    //         this.updateServiceDetailsStore.emit();
    //       })
    //       .catch(e => {
    //         this.error = e
    //         this.toastr.error(`Facing issue while updating ownership, Please try again`);
    //       })
    //       .finally(() => {
    //         this.updating = false;
    //       });
    //     break;
    //   default:
    //     break;
    // }






  }
  onRemoveEmployee(row) {
    this.updating = true;
    let payloadData = []
    switch (row.service_association) {
      case 'Accountable Executive':  //Changed case name from Service Owner to Accountable Executive
        payloadData = [
          {
            system_id: null,
            service_property_id: 'requestor_email',
            resolution_value: null
          }
        ]
        this.serviceV2Service.saveServiceOwnershipService(payloadData, this.serviceDetails.service_data.service_id)
          .toPromise()
          .then(res => {
            //this.toastr.success(`Service Owner removed successfully`);
            this.toastr.success(`Accountable Executive removed successfully`);
            this.onToggleExpand();
            this.updateServiceDetailsStore.emit();
          })
          .catch(e => {
            this.error = e
            this.toastr.error(`Facing issue while remove ownership, Please try again`);
          })
          .finally(() => {
            this.updating = false;
          });
        break;

      case 'Mi-MiSO Owner':
        this.serviceV2Service.miUserRemoveServiceOwnership(this.serviceDetails.service_data.service_id)
          .toPromise()
          .then(res => {
            //this.toastr.success(`Mi Service Owner removed successfully`);
            this.toastr.success(`Mi-MiSO Owner removed successfully`);
            this.onToggleExpand();
            this.updateServiceDetailsStore.emit();
          })
          .catch(e => {
            this.error = e
            this.toastr.error(`Facing issue while remove ownership, Please try again`);
          })
          .finally(() => {
            this.updating = false;
          });
        break;


      case 'Subscriber':
        payloadData = [
          {
            system_id: null,
            service_property_id: 'end_user_email',
            resolution_value: null
          }
        ]
        this.serviceV2Service.saveServiceOwnershipService(payloadData, this.serviceDetails.service_data.service_id)
          .toPromise()
          .then(res => {
            this.toastr.success(`Subscriber removed successfully`);
            this.onToggleExpand();
            this.updateServiceDetailsStore.emit();
          })
          .catch(e => {
            this.error = e
            this.toastr.error(`Facing issue while remove ownership, Please try again`);
          })
          .finally(() => {
            this.updating = false;
          });
        break;
      default:
        break;
    }
  }

}