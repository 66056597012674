<ng-container>

  <mi-server-error [error]="error"></mi-server-error>

  <ng-container *ngIf="loaded; else loading">

    <div class="sections">

      <section class="data-section">

        <h2>Details</h2>

        <dl *ngIf="serviceTerm" class="section-details">
          <div>
            <dt>End of Term Action</dt>
            <dd>
              <ng-container [ngSwitch]="serviceTerm.event_type">
                <ng-template ngSwitchCase="EVENT_TYPE_AUTO_RENEW">{{"END_OF_TERM.EVENT_TYPE_AUTO_RENEW" | translate}}</ng-template>
                <ng-template ngSwitchCase="EVENT_TYPE_AUTO_CANCELLATION">{{"END_OF_TERM.EVENT_TYPE_AUTO_CANCELLATION" | translate}}</ng-template>
                <ng-template ngSwitchDefault>{{NO_DATA}}</ng-template>
              </ng-container>
            </dd>
          </div>
          <div *ngIf="serviceTerm.event_type=='EVENT_TYPE_AUTO_RENEW'">
              <dt>Auto Renew Length</dt>
            <dd>
              {{serviceTerm.auto_renew_length}}
            </dd>
          </div>
          <div *ngIf="serviceTerm.event_type=='EVENT_TYPE_AUTO_RENEW'">
              <dt>Days to Notify</dt>
              <dd>
                {{serviceTerm.days_to_notify}}
              </dd>
            </div>        
        </dl>
      </section>
    </div>

  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</ng-container>
