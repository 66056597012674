<ng-container *ngIf="loaded; else loading">
  <mi-page-header
    [breadcrumbs]="breadcrumbs"
    [links]="headerLinks"
    title="{{ 'TENANT.DELETE_SERVICE_TITLE' | translate:{display_id: displayId} }}">
  </mi-page-header>

  <mi-page-alert></mi-page-alert>

  <mi-server-error [error]="error"></mi-server-error>
  <div *ngIf="canDeleteMode && canDelete && isSuperAdmin" class="half-page">
    <strong>Are you sure you wish to delete "{{ displayId }}" service?</strong>
    <p>Once deleted, this action cannot be reversed!</p>
    <p>Press delete to remove this Service permanently.</p>
    <div class="button-container">
      <a (click)="onCancel($event)"
          buttonTheme="alert-light-theme"
          class="button--cancel"
          mi-button>
        Cancel
      </a>
      <a (click)="permanentlyDelete()"
          buttonTheme="alert-theme"
          mi-button>
        Delete
      </a>
    </div>
  </div>

  <div *ngIf="!canDeleteMode && canDelete.dependents.length" class="half-page">
    <strong>This service is a Parent Service of:</strong>
    <p>
      <ng-container *ngFor="let dependent of canDelete.dependents; index as i">
        <span *ngIf="i">, </span>
        <mi-id-link id="{{dependent.display_id}}"></mi-id-link>
      </ng-container>
    </p>
    <p>A Parent Service cannot be deleted. Please assign a different Parent for the Group and try again.</p>
    <div class="button-container">
      <a (click)="onCancel($event)"
         buttonTheme="alert-light-theme"
         class="button--cancel"
         mi-button>
        Cancel
      </a>
    </div>
    <!-- <mi-cascade-need-replace
      title= "Cannot Delete Service"
      subtitle= "The following items are associated with this Service."
      [dependents]= "canDelete.dependents"
      (canceled)="onCancel($event)">
      <mi-alert-item alertType="ERROR">
        Before deleting the Service, please remove the Service from the associated items above.
      </mi-alert-item>
    </mi-cascade-need-replace> -->
  </div>

  <ng-container *ngIf="loaded && !isSuperAdmin">
    <mi-side-notice noticeType="WARNING">
      <strong>Only Super Admins Are Allowed To Delete Services!</strong>
      <div class="button-container">
        <a (click)="onCancel($event)"
           buttonTheme="alert-light-theme"
           class="button--cancel"
           mi-button>
          Cancel
        </a>
      </div>
    </mi-side-notice>
  </ng-container>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
