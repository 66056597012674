import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../../../shared/ng-models/SectionDataTable.model";
import * as appDataReducer from '../../../../../shared/state/app-data/reducers'
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import { ServiceTermService } from '../../../../../libs/api2/service-term/service-term.service';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';


@Component({
  styleUrls: ['./service-term-details.component.scss'],
  templateUrl: './service-term-details.component.html'
})

export class ServiceTermDetailsComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  serviceTerm: ServiceTerm;
  serviceTermId: string;
  error: string = '';
  loaded: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private serviceTermService: ServiceTermService) {
    super();

  }

  ngOnInit() {
    const tenantStateData = this.store.select(appDataReducer.getAppData);

    combineLatest(tenantStateData, this.activatedRoute.parent.params)
      .switchMap(([tenantData ,params]) => {
        this.loaded = false;
        this.serviceTermId = params.display_id;
        return this.serviceTermService.getServiceTermById(this.serviceTermId);
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe(  (serviceTerm: ServiceTerm) => {
        this.serviceTerm = serviceTerm;
        this.loaded = true;
      });
  }
}
