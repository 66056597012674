export interface ServiceAuditRowData {
  id: string;
  value: string;
  changed: boolean;
  is_custom_field?: boolean
}

export interface ServiceAuditData {
  update_date: string;
  extraction_date: string;
  data: ServiceAuditRowData[];
}

export class ServiceAudit {
  miso_service_id: string;
  display_id: string;
  job_extraction_date: string;
  is_validation_required: string;
  last_job_doc_id: string;
  data: ServiceAuditData[];
}

export interface ServiceAuditTableColumnOrder {
  id: string;
  value: string;
  order: number;
  section_id: string;
  section_order: number;
  display: boolean;
  is_date?: boolean;
}

export interface ServiceAuditTableColumnData {
  id: string;
  value: string;
  is_date?: boolean;
}

export interface ServiceAuditTableColumns {
  section_id: string;
  data: ServiceAuditTableColumnData[];
}