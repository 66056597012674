<mi-page-header
  title="{{ 'TENANT.ASSOCIATED_DOCUMENTS.DETAIL_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs"
  *ngIf="associatedDocument">
  <ng-container>
    <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
  </ng-container>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<div class="details-header-nav" *ngIf="associatedDocument&&vendor">
  <div class="details-header-nav__graphic">

    <div class="details-header-nav__graphic-container" *ngIf="associatedDocument&&vendor">

      <ng-container
        *ngIf="vendor.vendor.logo && vendor.vendor.logo_full_path; else fallback">
        <img class="vendor-logo" [src]="vendor.vendor.logo_full_path"/>
      </ng-container>
      <ng-template #fallback>
        <img class="vendor-logo" src="shared/assets/images/vendor-logo-placeholder.svg"/>
      </ng-template>

    </div>

  </div>

  <div class="details-header-nav__info">

    <mi-details-header-nav
      [data]="detailHeaderNavData">
    </mi-details-header-nav>

  </div>

</div>

<router-outlet></router-outlet>
