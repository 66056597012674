import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderSteps, OrderState, OrderProgression} from '../../state/reducer';
import {Store} from '@ngrx/store';
import {UpdateOrderAction} from '../../state/actions';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as OrderReducers from '../../state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {AssociatedDocument} from '../../../../../../../shared/models/AssociatedDocument';
import { Document } from 'src/libs/api2/types/Document.interface';
@Component({
  selector: 'mi-service-order-documents',
  styleUrls: ['./service-order-documents.component.scss', '../service-segments.scss'],
  templateUrl: './service-order-documents.component.html'
})
export class ServiceOrderDocumentsComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  addingDocuments: boolean = false;
  index: number;
  documents: Document[] = [];
  error: string;
  NO_DATA: string = NO_DATA;
  readonly OrderSteps: any = OrderReducers.OrderSteps;
  orderState: OrderState;
  documentsForm: FormGroup;
  step: string = OrderSteps.Uploads;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store<OrderState>
  ) {
    super();
  }

  ngOnInit() {

    const path = this.activatedRoute.snapshot.routeConfig.path;

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state as OrderState;
          this.documents = this.orderState.associated_documents;
          this.documentsForm = this.formBuilder.group({
            associated_documents: this.documents
          });
          // Find the index belonging to the state's current step:
          this.index = OrderProgression.findIndex((a) => {
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });
  }

  onAddDocumentsClicked() {
    this.addingDocuments = true;
  }

  onDocumentSelect(doc: Document[]): void {
    this.addingDocuments = false;
    this.documents = doc;
  }

  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction({
      index: maxIndex,
      maxIndex: maxIndex,
      associated_documents: this.documents
    }));

  }

  onRemoveDocument(doc: Document): void {
    this.documents = this.documents.filter((a: Document) => {
      return doc.id !== a.id;
    });
  }

}
