import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Vendor} from '../../../shared/models/Vendor';
import {TenantVendors} from '../../../libs/api/tenant/tenantVendors';
import {Link} from '../../../shared/ng-models/Link.interface';
import {Product} from '../../../shared/models/Product';
import {PageAlertAddAction} from '../../../shared/state/page-alert/actions';
import * as pageAlertReducer from '../../../shared/state/page-alert/reducers';
import {Store} from '@ngrx/store';
import { ProductServicesModes, ProductServices } from '../../../shared/models/ProductServices.interface';
import { Title } from '@angular/platform-browser';
import linkFormatter from 'src/common/utils/linkFormatter';

@Component({
  templateUrl: './vendor-equipment-create.component.html'
})

export class VendorEquipmentCreateComponent extends AbstractPageComponent implements OnInit {

  backLink: string = '';
  breadcrumbs: Link[];
  error: string = '';
  supportLink: string = linkFormatter.getSupportLink();
  vendor: Vendor;

  tenantProductServicesMode: ProductServicesModes = ProductServicesModes.TENANT;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantVendors: TenantVendors,
              private router: Router,
              private store: Store<pageAlertReducer.State>,
              private titleService: Title
      ) {
    super();
  }

  ngOnInit() {

    const vendorId: string = this.activatedRoute.snapshot.params.vendor_display_id;

    this.backLink = `/vendors/${vendorId}/equipment`;

    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Vendors',
        url: '/vendors',
        internal: true
      },
      {
        label: `Vendor Details ${vendorId}`,
        url: `/vendors/${vendorId}/equipment`,
        internal: true
      },
      {
        label: `Create Equipment`,
        url: `/vendors/${vendorId}/equipment/create`,
        internal: true
      }
    ];

    this.activatedRoute.params
      .takeUntil(this.destroy$)
      .switchMap(params => this.tenantVendors.getVendorByDisplayId(vendorId))
      .subscribe((vendor: Vendor) => {
        this.vendor = vendor;
        this.titleService.setTitle(`Create Equipment - ${vendor.vendor_name} - Vendor Details ${vendor.display_id} - MISO3`);
      });
  }

  onCanceled() {
    this.router.navigateByUrl(this.backLink);
  }

  onEquipmentAdded(product: Product): void {
    this.store.dispatch(new PageAlertAddAction({
      message: `Equipment added successfully`,
      alertType: 'SUCCESS'
    }));

    setTimeout(() => {
      this.router.navigate([this.backLink], {
        relativeTo: this.activatedRoute
      });
    }, 2000);
  }

}
