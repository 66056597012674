import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {LocationModel} from "../../../shared/models/Location";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {minLengthWithTrimmedWhitespace} from "../../../shared/shared.validators";
import {AccountId} from "../../../shared/models/AccountId";
import {Vendor} from "../../../shared/models/Vendor";
import { Contact } from "../../../shared/models/Contact";
import { TenantAccountIdService } from "../../../libs/api2/account-id-v2/account-id-v2.service";
import { TenantVendors } from "src/libs/api/tenant/tenantVendors";

@Component({
  selector: 'mi-accountId-edit-form',
  templateUrl: './accountId-edit-form.component.html',
  styleUrls: ['./accountId-edit-form.component.scss']
})

export class AccountIdEditFormComponent extends AbstractPageComponent implements OnInit {

  @Output() updateCompleted: EventEmitter<AccountId> = new EventEmitter<AccountId>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  /*
  *
  * Accepts EITHER a Display ID for editing purposes
  *
  * *OR*
  *
  * a Vendor for creating new. Vendor is required
  *
  * If the ID is provided the component makes a call to gather other AccountID information
  * and self-populates.
  *
  * If in Create Mode (w/vendor), no lookup is necessary, but you do need the vendor to create.
  *
  * NOTE:
  * There may very well be a better way of handling this. Trying to be expeditious.
  *
  * */

  @Input('accountIdDisplayId')
  set accountIdDisplayId(val) {
    if (val) {
      this.startAsEditMode(<string>val);
    }
  }

  @Input('vendor')
  set vendor(val) {
    if (val) {
      this.startAsCreateMode(val);
    }
  }

  _accountIdDisplayId: string;
  _accountId: AccountId;
  accountIdForm: FormGroup;
  ctaLabel: string;
  inEditMode: boolean = false;
  loadError: string = '';
  saveError: any;
  loading: boolean = false;
  showAddContact: boolean = false;
  contact: Contact;

  constructor(private tenantAccountIdService: TenantAccountIdService,
              private tenantVendors: TenantVendors,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {

  }

  createForm(acctId: AccountId) {
    console.log("accountid createForm", acctId);
    this._accountId = acctId;
    this.contact = this._accountId.contacts;
    this.accountIdForm = this.formBuilder.group({
      label: [this._accountId.account.label || '', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        minLengthWithTrimmedWhitespace(1)
      ])],
      vendor: new FormControl({value: this._accountId.vendor.vendor_name, disabled: true}),
      contact: [this._accountId.account.contact_id],
      service_term: [this._accountId.service_term_id]
    });
  }

  onAddContact(contactToAdd: Contact): void {
    this.contact = contactToAdd;
    this.showAddContact = false;
    this.accountIdForm.get('contact').setValue(this.contact);
  }

  onContactDeselect(contactToRemove: Contact) {

    this.contact = null;
    this.showAddContact = false;
    this.accountIdForm.get('contact').setValue(this.contact);
  }

  onShowAddContact(): void {
    this.showAddContact = true;
  }


  onUpdateAccountId(data: any): void {
    console.log("onUpdateAccountId", data);
    let newAccountId: AccountId = {
      label: data.label.trim(),
      contact_id: this.contact? this.contact.id: null,
      //tenant_vendor_id: this._accountId.vendor._id || this._accountId.vendor.id,
      tenant_vendor_id: this._accountId.tenant_vendor_id,
      service_term_id: data.service_term,
      //owner: this._accountId.vendor.tenant_id,
      owner: this._accountId.account.owner,
      //vendor_id: this._accountId.vendor.vendor.id
      vendor_id: this._accountId.vendor_id
    };

    if (this.inEditMode) {
      this.tenantAccountIdService.update(newAccountId, this._accountId.account.id)
      .subscribe(
        (res: AccountId) => this.updateCompleted.emit(res),
        (error)=>this.loadError=error
      );
    } else {
      this.tenantAccountIdService.createAccountId(newAccountId)
        .subscribe(
          (res: AccountId) => this.updateCompleted.emit(res),
          (error)=>this.saveError=error
        );
    }

  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  startAsEditMode(accountId: string): void {
    this.inEditMode = true;
    this.ctaLabel = 'Continue';
    this._accountIdDisplayId = accountId;
    this.loading = true;
    this.loadError = '';

    //this.tenantAccountIdService.getByDisplayId(accountId)
    this.tenantAccountIdService.getById(accountId)
      .toPromise()
      .then((acctId: AccountId) => {
        //acctId.vendor_id = acctId.vendor.vendor.id;
        //acctId.tenant_vendor_id = acctId.vendor.id;
        acctId.vendor_id = acctId.account.vendor_id;
        acctId.tenant_vendor_id = acctId.account.tenant_vendor_id;
        this.tenantVendors.getVendorBytenantVendorId(acctId.tenant_vendor_id)
        .then(vendor => {
          acctId.vendor = vendor;
          this.createForm(acctId);
        })
        //this.createForm(acctId);
      })
      .catch(error => this.loadError = error);
  }

  startAsCreateMode(accountVendor: Vendor): void {
    this.inEditMode = false;
    this.ctaLabel = 'Continue';
    this._accountId = {
      label: '',
      vendor: accountVendor,
      //vendor_id: accountVendor._id,
      vendor_id: accountVendor.id,
      //tenant_vendor_id: accountVendor._id,
      tenant_vendor_id: accountVendor.tenant_vendor_id,
      contact: null
    };
    this.createForm(this._accountId);

    this.loading = false;
  }

  onSelectServiceTerm(serviceTerm){
    if(serviceTerm){
      this.accountIdForm.get('service_term').setValue(serviceTerm.id);
    } else {
      this.accountIdForm.get('service_term').setValue(null);
    }
  }

}
