
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { Store } from '@ngrx/store';
import * as appDataReducer from "../../../shared/state/app-data/reducers";
import { Observable } from 'rxjs';



const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-select-service-ownership',
  templateUrl: './select-service-ownership.component.html',
  styleUrls: ['./select-service-ownership.component.scss']
})

export class SelectServiceOwnershipComponent extends AbstractPageComponent implements OnInit {

  @Input() selectedDivision: string = "";
  @Input() selectedTeam: string = "";
  @Input() selectedUser: string = "";
  @Output() outPutBusinessUnit: EventEmitter<any> = new EventEmitter<any>();
  @Output() outPutUser: EventEmitter<any> = new EventEmitter<any>();

  divisions: any[]= [];
  teams: any = {};
  users: any[] = [];
  error: any;
  domain: string;
  loaded: boolean = false;
  AllTenantAccess: boolean = true;
  allBusinessUnits: any = {}

  constructor(
    private tenantService: TenantV2Service,
    private store: Store<appDataReducer.State>
  ) {
    super();
  }


  ngOnInit() {
    this.selectedDivision= this.selectedDivision? this.selectedDivision : "";
    this.selectedTeam= this.selectedTeam? this.selectedTeam : "";
    this.selectedUser= this.selectedUser? this.selectedUser : "";

    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    
    tenantStateData
      .first()
      .subscribe((data)=>{
        this.domain = data.tenantData.domain;
        this.getAllBusinessUnits(this.domain);
      },
      e => this.error = e);

  }

  getAllBusinessUnits(domain){
    this.tenantService.getBusinessUnits({owner:domain})
      .takeUntil(this.destroy$)
      .subscribe(bus => {
        bus.map(bu => {
          this.allBusinessUnits[bu.id] = bu;
        })
        this.AllTenantAccess = this.allBusinessUnits[this.domain].role<999;
        this.divisions = bus.filter(bu=> bu.parent_id && bu.parent_id === domain);
        const teamsLevel = bus.filter(bu=> bu.parent_id && bu.parent_id !== domain);
        this.divisions.map(div => this.teams[div.id] = []);
        teamsLevel.map(team => this.teams[team.parent_id].push(team));

        this.getUsers();
        this.loaded = true;
      })
  }

  outPutBus(){
    const serviceOwnershipData = {
      division: this.selectedDivision? this.allBusinessUnits[this.selectedDivision] : null,
      team: this.selectedTeam? this.allBusinessUnits[this.selectedTeam] : null
    }
    this.outPutBusinessUnit.emit(serviceOwnershipData);
  }

  getUsers(){
    if(!this.selectedUser){
      if(!this.selectedDivision && !this.AllTenantAccess){
          this.selectedDivision = this.divisions[0].id;
      }
  
      if(!this.selectedTeam && this.selectedDivision && this.allBusinessUnits[this.selectedDivision] && 
        this.allBusinessUnits[this.selectedDivision].role===999 && this.teams[this.selectedDivision].length===1){
        this.selectedTeam = this.teams[this.selectedDivision][0].id;
      }
    }

    const getUsersArg = this.selectedTeam? this.selectedTeam : 
      this.selectedDivision? this.selectedDivision :
      this.domain;
    this.tenantService.getBusssinessUsers(getUsersArg)
      .takeUntil(this.destroy$)
      .subscribe(users => {
        this.outPutBus();
        this.users = users.filter(user => user.role_number<999);
      })
  }

  onDivision(evt){
    this.selectedUser = "";
    this.outPutUser.emit(this.selectedUser);
    this.selectedTeam = "";
    this.getUsers();
  }

  onTeam(evt){
    this.selectedUser = "";
    this.outPutUser.emit(this.selectedUser);
    this.getUsers();
  }

  onUser(evt){
    this.selectedUser = evt.target.value;
    this.outPutUser.emit(this.selectedUser? this.selectedUser: null);
  }


}
