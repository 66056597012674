//TAG DEF

export class TagDefinition {
  tagId: string;
  tenantId: string;
  tagName: string;
  tagType: string;
  isMultiValue: boolean;
  isDisabled: boolean;
  isDeleted: boolean;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

export class CreateTagDefinitionRequest {
  tenant_id: string;
  tag_name: string;
  tag_type: string;
  is_multi_value: boolean;
}

export class ModifyTagDefinitionRequest {
  tag_id: string;
  tag_name: string;
  is_disabled: boolean;
  is_deleted: boolean;
}

//TAG OPTS

export class TagOption {
  tagOptionValueId: string;
  tagId: string;
  tagValue: string;
  isDisabled: boolean;
  isDeleted: boolean;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

export class CreateTagOptionRequest {
  tag_id: string;
  tag_value: string;
}

export class ModifyTagOptionRequest {
  tag_option_value_id: string;
  tag_value: string;
  is_disabled: boolean;
  is_deleted: boolean;
}

//SERVICE TAGS

export class ServiceTag {
  miso_service_tag_id: string;
  tag_id: string;
  tag_name: string;
  tag_type: string;
  is_multi_value: boolean;
  tag_value: string;
}

export class CreateServiceTagRequest {
  miso_service_id: string;
  tag_id: string;
  tag_value: string;
}

export class ModifyServiceTagRequest {
  miso_service_tag_id: string;
  tag_value: string;
  is_deleted: boolean;
}