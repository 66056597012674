import {combineLatest, Observable} from "rxjs";
import linkFormatter from 'src/common/utils/linkFormatter';
import { TenantVendorService } from 'src/libs/api2/vendor-v2/vendor-v2.service';
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import { ModalService } from '@independer/ng-modal';
import {Store} from "@ngrx/store";

import {environment} from '../../../../environments/environment';
import { TenantAssociatedDocumentsService } from '../../../../libs/api/tenant/tenantAssociatedDocument.service';
import { TenantDocumentManagementService } from '../../../../libs/api/tenant/tenantDocumentManagement.service';
import { TenantVendors } from '../../../../libs/api/tenant/tenantVendors';
import {TenantSearchService} from '../../../../libs/api/tenantSearch/tenantSearch.service';
import { Document } from '../../../../libs/api2/types/Document.interface';
import {createLogger, LOG_LEVELS} from '../../../../shared/logger';
import { AssociatedDocument } from '../../../../shared/models/AssociatedDocument';
import {MiServiceSearchResults} from '../../../../shared/models/SearchResults';
import { Vendor } from '../../../../shared/models/Vendor';
import {DetailHeaderNavData, HeaderNavSpecTypes} from '../../../../shared/ng-models/DetailHeaderNavData.interface';
import {FILTER_GROUP_MODAL_ID} from "../../../../shared/ng-models/FilterGroupModalId";
import {Link} from '../../../../shared/ng-models/Link.interface';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import * as ASReducer from "../../../../shared/state/advanced-search/reducers";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  templateUrl: './associated-documents-instance-wrap.component.html'
})

export class AssociatedDocumentsInstanceWrapComponent extends AbstractPageComponent implements OnInit {

  associatedDocument: Document;
  breadcrumbs: Link[];
  detailHeaderNavData: DetailHeaderNavData;
  display_id: string;
  error: string = '';
  filterOverlayId: string = FILTER_GROUP_MODAL_ID;
  supportLink: string = linkFormatter.getSupportLink();;
  title: string;
  vendor: Vendor;

  NO_DATA: string = NO_DATA;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantAssociatedDocumentsService: TenantDocumentManagementService,
              private tenantSearchService: TenantSearchService,
              //private tenantVendorService: TenantVendors,
              private tenantVendorService: TenantVendorService,
              private store: Store<ASReducer.State>,
              private modalService: ModalService) {
    super();
  }

  ngOnInit() {
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .switchMap(([params, queryParams]) => {
        this.display_id = params.display_id;
        return this.tenantAssociatedDocumentsService.getDocumentById(this.display_id)
        .switchMap((res: Document) => {
          this.associatedDocument = res;
          //if (this.associatedDocument.vendor_id) {
          if (this.associatedDocument.tenant_vendor_id) {
          //return this.tenantVendorService.getVendorById(this.associatedDocument.vendor_id);
          return this.tenantVendorService.getVendorOrTenantVendorById(this.associatedDocument.tenant_vendor_id);
          }else{
            return Observable.of(null);
          }
        });
      })
      //.switchMap((res: Vendor) => {
      .takeUntil(this.destroy$)
      .subscribe((res: Vendor) => {
        this.vendor = res;

        // if(this.vendor.vendor.logo && !this.vendor.vendor.logo_full_path) {
        //   this.vendor.vendor.logo_full_path = environment.AWS_PATH + this.vendor.vendor.logo;
        // }

        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: 'Document Management',
            url: '/associated-documents',
            internal: true
          },
          {
            label: `Attachment ${this.associatedDocument.name}`,
            url: `/vendor-account-ids/${this.display_id}/details`,
            internal: true
          }
        ];

        this.detailHeaderNavData = <DetailHeaderNavData> {
          headline: this.associatedDocument.name,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: {display_id: this.associatedDocument.display_id}
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: this.associatedDocument.service_count
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: this.associatedDocument.mrc,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        // build a location typeSummary query
        //return this.tenantSearchService.typeSummary({'associated_documents[]': this.associatedDocument.id});
      });
      //.catch(e => this.error = e)
      //.takeUntil(this.destroy$)
      /*
      .subscribe((associatedDocumentResults: MiServiceSearchResults) => {
        // // we can now find the correct bucket for this address
        let correctBucket = associatedDocumentResults.aggregations.associated_documents.buckets.find(bucket=> bucket.key === this.associatedDocument.id);
        let serviceCount = correctBucket ? correctBucket.doc_count : 0;

        this.detailHeaderNavData = <DetailHeaderNavData> {
          headline: this.associatedDocument.name,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: {display_id: this.associatedDocument.display_id}
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: serviceCount
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: associatedDocumentResults.aggregations.total_mrc.value || 0,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

      });*/

    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if(data.filterGroup){
            // Get the data and open the modal:
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.filterOverlayId;
            })
          }
        });
  }

}
