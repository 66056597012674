<mi-page-header title="{{ 'TENANT.ACCOUNT_ID.DETAIL_TITLE' | translate:{display_id: display_id} }}"
  [breadcrumbs]="breadcrumbs" *ngIf="accountId">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<ng-container *ngIf="navLoaded; else loading">
  <div class="service-details-header">
    <div class="header-item">
      <div class="service-details-header-item">
        <div class="stats">
          <div class="vendor-details--data-point">
            <dl>
              <dt>
                {{this.accountId.account.vendor.vendor_name}}
              </dt>
              <dd>
                <mi-id-link [id]="accountId.account.vendor.display_id"
                  title="{{accountId.account.vendor.display_id}} - {{accountId.account.vendor.vendor_name}}">
                </mi-id-link>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="header-item">
      <div class="service-details-header-item">
        <div class="stats">
          <div class="vendor-details--data-point">
            <dl>
              <dt>
                {{this.accountId.account.label}}
              </dt>
              <dd>
                {{this.accountId.account.display_id}}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="header-item">
      <div class="service-details-header-item">
        <div class="stats">
          <div class="vendor-details--data-point">
            <dl>
              <dt>
                Service Count
              </dt>
              <dd>
                {{this.accountId.service_count != null ? this.accountId.service_count : 0}}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="header-item">
      <div class="service-details-header-item">
        <div class="stats">
          <div class="vendor-details--data-point">
            <dl>
              <dt>
                CMRC
              </dt>
              <dd>
                {{this.accountId.mrc != null ? this.accountId.mrc : 0}}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

  </div>
  </ng-container>

<router-outlet></router-outlet>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>