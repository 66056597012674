import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class VendorDefinedFieldsV2Service {
  private apiUrl = "_MICRO_SERVICE_/vendor/tenant";

  constructor(private http: HttpClient) { }

  list(tenantId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${tenantId}/bridges`);
  }

  getBridgeDetails(tenantId: string, bridgeId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${tenantId}/bridges/${bridgeId}/custom-fields`);
  }

}
