import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../../../shared/ng-models/SectionDataTable.model";
import { TenantDocumentManagementService } from '../../../../../libs/api/tenant/tenantDocumentManagement.service';
import { Document } from '../../../../../libs/api2/types/Document.interface';
import { TenantVendors } from '../../../../../libs/api/tenant/tenantVendors';
import { Vendor } from 'src/shared/models/Vendor';
import { environment } from 'src/environments/environment';
import * as appDataReducer from '../../../../../shared/state/app-data/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import FeDateUtil from 'src/shared/utils/FeDate.util';
import {CUSTOMER_DOCUMENT_UPLOAD_TYPES} from '../../../../../common/utils/sharedConstants';
import { TenantVendorService } from 'src/libs/api2/vendor-v2/vendor-v2.service';

@Component({
  styleUrls: ['./associated-documents-details.component.scss'],
  templateUrl: './associated-documents-details.component.html'
})

export class AssociatedDocumentsDetailsComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  associatedDocument: Document;
  vendor: Vendor;
  display_id: string;
  error: string = '';
  loaded: boolean = false;
  timeZone: string = "America/New_York"
  readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantAssociatedDocumentsService: TenantDocumentManagementService,
              //private tenantVendorService: TenantVendors,
              private tenantVendorService: TenantVendorService,
              private store: Store<appDataReducer.State>) {
    super();

  }

  ngOnInit() {
    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.loaded = false;
        this.display_id = queryParams.display_id;
        // get the address data
        return this.tenantAssociatedDocumentsService.getDocumentById(this.display_id)
        .switchMap((res: Document) => {
          this.associatedDocument = res;
          //return this.tenantVendorService.getVendorById(this.associatedDocument.vendor_id)
          return this.tenantVendorService.getVendorOrTenantVendorById(this.associatedDocument.tenant_vendor_id);
        })
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe(  (vendor: Vendor) => {
        this.vendor = vendor;
        this.loaded = true;
      });
      const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    tenantStateData.first()
      .subscribe(data => {
        if(data && data.tenantData && data.tenantData.timezone) {
          this.timeZone =  data.tenantData.timezone;
        }
      })
  }

  convertTimeZone(time){
    return FeDateUtil.applyTimeZoneFromISO(time, this.timeZone)
  }

  downloadDocument(id) {
    return this.tenantAssociatedDocumentsService.download(id).subscribe((response) => {
      window.open(response.download_url, "_blank");
    }, (e) => {

    });
  }

  shortenFileName(name) {
    if (name && name.length > 40) {
      return name.slice(0, 40) + '...';
    }
    return name;
  }

  getV1FileUrl(row){
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }

}
