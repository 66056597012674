

//([a-zA-Z-0-9]*?)\.


import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { of, combineLatest } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { SharedTenantService } from '../../../libs/api/tenant/shared-tenant.service';
import * as AppDataActions from './actions';
import {APP_TYPES} from "./reducers";
import * as linkFormatter from '../../../common/utils/linkFormatter';
import { TenantV2Service } from '../../../../src/libs/api2/tenant-v2/tenant-v2.service';
import FrontEndSettingsUtil from '../../../shared/utils/frontEndSettings.util';

@Injectable() export class AppDataEffects {

  @Effect()
  getAppData$: Observable<Action> = this.actions$.ofType(AppDataActions.ActionTypes.APP_DATA_GET)
    .mergeMap( (action) => {
      let subdomain = FrontEndSettingsUtil.get() && FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
      ? FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
      : linkFormatter.getSubdomainFromHost(window.location.host);
      let host=window.location.host;
      //return of(subdomain);
      if (host) {
        switch (host) {
          case APP_TYPES.MIYAGI:
            return of(new AppDataActions.AppDataGetSuccessAction({ appType: APP_TYPES.MIYAGI}));
          case APP_TYPES.ACCOUNTS:
            return of(new AppDataActions.AppDataGetSuccessAction({ appType: APP_TYPES.ACCOUNTS}));
          default:
            //return this.tenantService.getTenantByDomain(subdomain)


            return combineLatest(
              //this.tenantService.getTenantByDomain(subdomain),
              this.tenantV2Service.getBusinessUnit(subdomain)
            )
            // can this user access this domain?
            //.map(([tenantData, tenantV2Data]) => 
            .map(([tenantV2Data]) =>
            ( 
              new AppDataActions.AppDataGetSuccessAction(
              { 
                appType: APP_TYPES.TENANT,
                tenantData: {
                  //...tenantData,
                  //display_name: tenantV2Data ? tenantV2Data.name : tenantData.display_name,
                  display_name: tenantV2Data ? tenantV2Data.name : null,
                  v2: tenantV2Data,
                  /*
                  logo: tenantV2Data.logo? {
                    file_name: tenantV2Data.logo,
                    file_path: tenantV2Data.logo,
                    full_path: tenantV2Data.logo
                  } : tenantData.logo
                  */
                  logo: tenantV2Data.logo? {
                    file_name: tenantV2Data.logo,
                    file_path: tenantV2Data.logo,
                    full_path: tenantV2Data.logo
                  } : null,
                  domain: tenantV2Data.id
                }
              })
            ))
            // If the user is NOT logged in send an error action.
            .catch((error) => {
              console.warn('Effects error:', error);
              if (error.status && (error.status.code === 404 || error.status === 'NOT_FOUND')) {
                return of(new AppDataActions.AppDataGetErrorNotFoundAction({appType: APP_TYPES.TENANT}))
              } else {
                return of(new AppDataActions.AppDataGetErrorAction({appType: APP_TYPES.TENANT}))
              }
            })
        }
      } else {
        return of(new AppDataActions.AppDataGetErrorAction({}))
      }
    });


  constructor(
    private tenantService: SharedTenantService,
    private tenantV2Service: TenantV2Service,
    private http: HttpClient,
    private actions$: Actions,
  ) {}

}
