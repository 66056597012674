import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import * as OrderReducers from '../../state/reducer';
import {OrderState, OrderSteps} from '../../state/reducer';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {UpdateOrderAction} from '../../state/actions';
import {OrderProgression} from '../../state/reducer';
import { Subject } from 'rxjs';
import {AccountId} from '../../../../../../../shared/models/AccountId';

@Component({
  selector: 'mi-service-order-account-id',
  templateUrl: './service-order-account-id.component.html'
})
export class ServiceOrderAccountIdComponent extends AbstractPageComponent implements OnInit {

  accountForm: FormGroup;
  activeIndex: boolean = false;
  activeStep: boolean = false;
  commandGetAccountId: Subject<any> = new Subject();
  currentAccountId: AccountId = null;
  currentAccountIdNotProvided: boolean;
  error: string;
  index: number;
  orderState: OrderState;
  readonly orderSteps: any = OrderSteps;
  step: string = OrderReducers.OrderSteps.AccountID;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state as OrderState;
          this.currentAccountId = this.orderState.account_id;
          this.accountForm = this.formBuilder.group({
            account_id: [this.currentAccountId, Validators.required]
          });
          // Find the index belonging to the state's current step:
          this.index = OrderProgression.findIndex((a) => {
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });
  }

  onAccountIdSelected(account_id: AccountId): void {

    this.currentAccountId = account_id;

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction({
      account_id: account_id,
      index: maxIndex,
      maxIndex: maxIndex,
    }));

  }

  onFormSubmit(data: any) {
    // R/T to the component, checking if the selected ID is valid or not:
    this.commandGetAccountId.next('');
  }


}
