import {Component, OnInit, Output, Input, EventEmitter} from "@angular/core";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {ModalCloseReason, ModalService} from "@independer/ng-modal";
import { AccountId } from "src/shared/models/AccountId";
import { SelectContactModalComponent } from "src/tenant/components/select-contact-modal/select-contact-modal.component";
import { takeUntil } from "rxjs/operators";
import { MiService } from "src/shared/models/MiService";
import { MissingDataUpdateAccountContact } from "src/tenant/pages/vendor/instance-wrap/missing-data/state/actions";
import { Store } from "@ngrx/store";

@Component({
  selector: 'mi-account-id-rep',
  templateUrl: './account-id-rep.component.html',
  styleUrls: ['./account-id-rep.component.scss']
})

export class AccountIdRepCommponent extends AbstractPageComponent implements OnInit {

  @Output() complete: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() cancelRoute: string = "../../";
  @Input() service: MiService;
  newAccountId: any;
  loaded: boolean = false;

  constructor(
    private modalService: ModalService,
    private store: Store<any>) {
  super();
  }

  ngOnInit() {
    if(this.service.account_id_v2.contact){
      this.newAccountId = this.service.account_id_v2;
    }
    this.loaded = true;
  }

  onCancel() {
    this.canceled.emit();
  }

  openContactModal(accountId: AccountId) {
    const modalRef = this.modalService.open(SelectContactModalComponent, m => {
      m.data = {
      };
      m.vendorName = this.service.purchasing_vendor.vendor_name;
      m.showVendorTab = true;
    });
    modalRef.closed
      .pipe(takeUntil(this.destroy$))
      .subscribe(args => {
        console.log(`Modal closed. Reason: ${ModalCloseReason[args.reason]}. Result:`, args.result);

        if(args.result && args.result.length && args.result[0].contact) {
          this.newAccountId = {
            ...accountId,
            contact: args.result[0].contact
          };
          this.store.dispatch(new MissingDataUpdateAccountContact({accountId: this.newAccountId}));
        }
    });
  }

  next(){
    this.complete.emit();
  }
}
