import {Component, Input, OnInit} from '@angular/core';
import {ALERT_CAUSES} from '../../../common/utils/sharedConstants';
import {Store} from '@ngrx/store';
import * as AlertReducer from '../../../shared/state/alerts/reducers';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {Alert} from '../../../shared/models/Alert.interface';

@Component({
  selector: 'mi-detail-alert',
  templateUrl: './detail-alert.component.html',
  styleUrls: ['./detail-alert.component.scss']
})

export class DetailAlertComponent extends AbstractPageComponent implements OnInit {

  @Input() display_id: string;
  @Input() sql_id: string = '';
  @Input() self_alert: boolean = false;

  readonly ALERT_CAUSES = ALERT_CAUSES;
  alert: Alert;
  causes: Alert[];
  equipmentCount: number = 0;
  serviceCount: number = 0;
  serviceGroupCount: number = 0;
  serviceGroups: any[];
  equipmentLink: string;
  serviceLink: string;

  constructor(
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(AlertReducer.getAlertsList)
      .takeUntil(this.destroy$)
      .subscribe((response) => {

        if (response.loaded) {
          if (this.self_alert) { // Self Alert.

            this.alert = response.alerts.find((alert) => {
              return alert.cause_display_id === this.display_id;
            });

            if (this.alert) {
              this.equipmentCount = this.alert.effects.filter((effect) => {
                return /^EQP-/.test(effect.display_id);
              }).length;
              this.serviceCount = this.alert.effects.filter((effect) => {
                return /^SVC-/.test(effect.display_id);
              }).length;
              this.serviceGroupCount = this.alert.effects.filter((effect) => {
                return /^SVG-/.test(effect.display_id);
              }).length;
              this.serviceGroups = this.alert.effects
                .filter((effect) => {
                  return /^SVG-/.test(effect.display_id);
                }).map((serviceGroup) => {
                  return serviceGroup.display_id;
                });
              this.equipmentLink = this.getEquipmentLink();
              this.serviceLink = this.getServiceLink();
            }

          } else { // General alert.

            if (this.display_id in response.effected) {
              this.causes = response.alerts.filter((alert) => {
                return alert.effects.find((effect) => {
                  return effect.display_id === this.display_id;
                });
              })
                .sort((a, b) => {
                  return a.cause_display_id > b.cause_display_id ? 1 : -1;
                });

              this.alert = response.alerts.find((alert) => {
                return alert.effects.find((effect) => {
                  return effect.display_id === this.display_id;
                });
              });
            } else {
              this.alert = undefined;
            }
          }
        }
      });
  }

  getEquipmentLink(): string {
    switch (this.alert.cause_reference_type) {
      case ALERT_CAUSES.ALERT_CAUSE_CONTACT:
        return `/contacts/${this.display_id}/equipment`;
        break;
      case ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT:
        return `/equipment/${this.display_id}`;
        break;
      case ALERT_CAUSES.ALERT_CAUSE_LOCATION:
        return `/locations/${this.display_id}/equipment`;
        break;
    }
  }

  getServiceLink(): string {
    switch (this.alert.cause_reference_type) {
      case ALERT_CAUSES.ALERT_CAUSE_CONTACT:
        return `/contacts/${this.display_id}/services`;
        break;
      case ALERT_CAUSES.ALERT_CAUSE_EQUIPMENT:
        return `/equipment/${this.display_id}/services`;
        break;
      case ALERT_CAUSES.ALERT_CAUSE_LOCATION:
        return `/locations/${this.display_id}/services`;
        break;
    }
  }
}
