import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class UserEmailService {

  private apiUrlWeeklyReport = '/account/user/email-preference-weekly-report';

  constructor(private http: HttpClient) {
  }

  editWeeklyReportPreferences(tenantProfiles: any): Observable<any> {
    return this.http
      .post(`${this.apiUrlWeeklyReport}`, {tenantProfiles})
      .map((response: any) => response.data);
  }

}
