<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide no-padding-top">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <div class="main-container">
      <table>
        <tr>
          <td>
            Item Name
          </td>
          <td>
            Price
          </td>
          <td>
            Quantity
          </td>
        </tr>
        <tr *ngFor="let billingItem of billingItems">
          <td>
            {{billingItem.itemName}}
          </td>
          <td>
            {{billingItem.itemPrice | tenantcurrencyusd:'1.0'}}
          </td>
          <td>
            {{billingItem.itemQuantity}}
          </td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td>
            Total
          </td>
          <td>
            {{totalAmount | tenantcurrencyusd:'1.0'}}
          </td>
        </tr>
      </table>
    </div>
  </modal-content>
  <modal-footer>
    <div class="cta">
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        Close
      </button>
    </div>
  </modal-footer>
</modal>