import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {AccountId} from "../../../shared/models/AccountId";
import {Vendor} from "../../../shared/models/Vendor";
import { Subject } from 'rxjs';
import {ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT, ACCOUNT_ID_NOTPROVIDEDBYVENDOR_ID} from '../../../common/utils/sharedConstants';
import { TenantAccountIdService } from '../../../libs/api2/account-id-v2/account-id-v2.service';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-find-or-create-account-id',
  templateUrl: './find-or-create-account-id.component.html',
  styleUrls: ['./find-or-create-account-id.component.scss']
})

export class FindOrCreateAccountIdComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  _vendor: Vendor;
  @Input() currentAccountId: AccountId;
  @Input() valueForCreateNew: string;
  @Input() commandGetAccountId: Subject<any> = new Subject();
  @Input() allowSubmit: boolean = false;

  @Output() accountSelected: EventEmitter<AccountId> = new EventEmitter<AccountId>();

  @Input('vendor')
  set inputCusvendortomField(val) {
    if (val) {
      console.log("vendor input", val);
      this._vendor = val;
      this._vendor.tenant_vendor_id = this._vendor.id;
      //this.tenantAccountIdService.getByVendorId(this._vendor.tenant_vendor_id || this._vendor.id)
      //this.tenantAccountIdService.getByVendorId(this._vendor.tenant_vendor_id || this._vendor._id)
        this.tenantAccountIdService.getAccountIdDetails("", this._vendor.tenant_vendor_id)
          .toPromise()
          .then(account_ids => {
            console.log("account_ids", account_ids);
            //this.account_ids = account_ids || [];
            this.account_ids = account_ids.data || [];
            this.account_ids.forEach(accountId => {
              accountId.label = accountId.account.label;
              accountId.id = accountId.account.id;
            });
    
            if(!this.account_ids.some(a => a.label === ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT)){
              this.account_ids.push({label: ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT, _id: ACCOUNT_ID_NOTPROVIDEDBYVENDOR_ID});
            }
    
            this.account_ids = this.account_ids.sort( (a,b) =>{
              return a.label === ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT? 1 : -1;
            });
    
            if (this.currentAccountId) {
              this.account_id = this.account_ids.find(account => account.id === this.currentAccountId.id);
            } else {
              this.account_id = this.account_ids.find(account => account.label === ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT);
              if(!this.account_id){
                this.account_id = this.account_ids[0];
              }
            }
    
            if(this.valueForCreateNew) {
              this.account_id = this.account_ids.find(account => account.label.replace(/[^a-zA-Z0-9]/g, '') === this.valueForCreateNew.replace(/[^a-zA-Z0-9]/g, ''));
              if(this.account_id){
                this.valueForCreateNew = '';
              } else {
                //this.account_id = this.valueForCreateNew;
                this.account_id = {
                  id: null,
                  label: this.valueForCreateNew
                };
              }
            }
            this.loaded = true;
          })
          .catch(e => this.error = e);
       
    }
  }

  account_ids: AccountId[] = [];
  account_id: any = null;
  loaded: boolean = false;

  error: string;

  constructor(private tenantAccountIdService: TenantAccountIdService) {
    super();
  }

  orderSelectAccountId() {
    this.selectAccountIdSubmit(this.account_id);
  }

  selectAccountIdSubmit($evt: any) {
    //debugger;
    if (this.account_id.id !== ACCOUNT_ID_NOTPROVIDEDBYVENDOR_ID && this.account_id.id) {

      this.selectAccountId(this.account_id);
    } else {
      if (this.account_id.id === ACCOUNT_ID_NOTPROVIDEDBYVENDOR_ID) {
        this.account_id = ACCOUNT_ID_NOTPROVIDEDBYVENDOR_TEXT;
      }
      //console.log("selectAccountIdSubmit _vendor", this._vendor);
      //if this is a new account id, update
      let newAccountId = {
        label: this.account_id.label + '',
        vendor_id: this._vendor._id ||this._vendor.id,
        //owner: this._vendor.owner,
        owner: this._vendor.tenant_id,
        tenant_vendor_id: this._vendor.tenant_vendor_id
      };

      this.tenantAccountIdService.createAccountId(newAccountId)
        .toPromise()
        .then(account_id => {
          this.selectAccountId(account_id);
        }).catch(e => {
        this.error = e;
      });
    }

  }

  addNewAccountId(search){
    return search;
  }

  selectAccountId(account_id: AccountId) {
    this.accountSelected.emit(account_id);
  }

  ngOnInit() {

    this.commandGetAccountId
      .takeUntil(this.destroy$)
      .subscribe(event => {
        this.orderSelectAccountId();
      });
      

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
