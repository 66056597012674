
<p>{{'TENANT.SERVICE_DETAILS.REMOVE_SERVICE_CANCELLATION' | translate}}</p>

<div class="button-container">

  <mi-server-error [error]="error"></mi-server-error>

  <form
    [formGroup]="cancellationFormGroup"
    (submit)="onCancellationFormSubmit()">
    <div class="button-container">
      <button
        mi-button
        buttonTheme="light-theme"
        (click)="onCancelClicked($event)">
        {{'TENANT.SERVICE_DETAILS.CTA.CANCEL_WITHOUT_MODIFICATION' | translate }}
      </button>
      <button
        mi-button
        buttonTheme="dark-theme"
        [disabled]="cancellationFormGroup.invalid"
        type="submit">
        {{'TENANT.SERVICE_DETAILS.REMOVE_SERVICE_CANCELLATION_CONFIRM' | translate }}
      </button>
    </div>
  </form>
</div>