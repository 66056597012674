import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import * as userReducer from '../../../../libs/user-store/state/reducers';
import {select, Store} from '@ngrx/store';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import {map, takeUntil} from 'rxjs/operators';
import {Link} from '../../../../shared/ng-models/Link.interface';
import { UPLOAD_TYPES } from 'src/common/utils/sharedConstants';

@Component({
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss']
})

export class DocumentsUploadComponent extends AbstractPageComponent implements OnInit {
  readonly UPLOAD_TYPES: any = UPLOAD_TYPES;
  loaded: boolean = false;
  error: any;
  tenantData: any = null;
  headerLinks: Link[];
  breadcrumbs: Link[];
  constructor(
    private store: Store<userReducer.State>
  ) {
    super();
  }

  ngOnInit() {
    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Document',
        url: '/',
        internal: true
      },
    ];
    this.store.pipe(
      select(appDataReducer.getAppData),
      takeUntil(this.destroy$),
      map((appData) => {
        this.tenantData = appData.tenantData;
        this.loaded = true;
      })
    ).subscribe();
  }
}
