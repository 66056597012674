import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as psReducer from '../state/reducers';
import * as psActions from '../state/actions';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import {MiParentService} from '../../../../../shared/models/MiParentService';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TenantUsersService} from '../../../../../libs/api/tenant/tenantUsers.service';
import {TenantMiParentServiceService} from '../../../../../libs/api/tenant/tenantMiParentService.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mi-parent-service-edit',
  templateUrl: './parent-service-edit.component.html',
  styleUrls: ['./parent-service-edit.component.scss']
})

export class ParentServiceEditComponent extends AbstractPageComponent implements OnInit {

  editForm: FormGroup;
  editing: boolean = false;
  error: string;
  initialized: Boolean = false;
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  saveComplete: boolean = false;
  state: psReducer.State;
  users: any[];
  valuesChanged: boolean = false;
  exitRoute:string = '../details';

  constructor(
    private store: Store<psReducer.State>,
    private formBuilder: FormBuilder,
    private parentServiceService: TenantMiParentServiceService,
    private tenantUserService: TenantUsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {

    // Gather the list of Users/possible users.
    this.store
      .select(psReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentService) {
            this.pageLoaded$ = this.store.select(psReducer.ParentServicePageLoaded);

            this.state = data;
            this.parentService = data.parentService;
            this.editForm = this.formBuilder.group({
              nickname: [data.parentService.nickname, Validators.required],
              division: data.parentService.division,
              team: data.parentService.team,
              assigned_email: [data.parentService.assigned_user.email, Validators.required],
            });
            this.editForm.valueChanges
              .takeUntil(this.destroy$)
              .subscribe((data: any) => {
                this.saveComplete = false;
                this.valuesChanged = (data.assigned_email !== this.parentService.assigned_email.toString())
                  ||
                  (data.nickname !== this.parentService.nickname)
                  ||
                  ((data.division?data.division:'') !== (this.parentService.division?this.parentService.division:''))
                  ||
                  ((data.team?data.team:'') !== (this.parentService.team?this.parentService.team:''));
              });
          }
      },
      e => this.error = e)
  }

  onAssignedEmail(assignee){
    this.editForm.get('assigned_email').setValue(assignee? assignee: null);
  }

  onBusinessUnit(bu){
    this.editForm.get('division').setValue(bu.division? bu.division.id : null);
    this.editForm.get('team').setValue(bu.team? bu.team.id : null);
  }

  onParentServiceUpdate(data: any): void {
    this.editing = true;
    this.parentServiceService.updateParentService(this.parentService._id, {
      assigned_email: data.assigned_email,
      division: data.division,
      team: data.team,
      nickname: data.nickname
    })
      .toPromise()
      .then((parentService) => {
        this.store.dispatch(new psActions.ParentServiceLoadSuccess({
          ...this.state,
          parentService: parentService
        }));
        this.saveComplete = true;
        this.router.navigate([this.exitRoute], {
          queryParams: {update: new Date().getTime()},
          relativeTo: this.activatedRoute
        });
      });
  }

  parseUsers(users: any[]): any[] {

    return users.map(user => user._rawData)
      .map((user) => {
        return {
          label: `${user.first_name} ${user.last_name}`,
          value: user.email,
          selected: false
        };
      })
      .sort((a, b) => {
        return a.label > b.label
          ?
          1
          :
          -1;
      });

  }

}
