<div class="tab-title-header">
  <h2>{{title}}</h2>
</div>
<mi-server-error [error]="error"></mi-server-error>
<div>
  <mi-tabs-container #viewTabs [wrap]="true" [full]="false" externalClasses="service-audit-view-tab reverse-color">
    <mi-subsection-service-audit-table *ngIf="loadViews"
      [serviceAuditData]="serviceAuditData"></mi-subsection-service-audit-table>
    <!-- <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Table'">
      <mi-subsection-service-audit-table *ngIf="loadViews" [serviceAuditData]="serviceAuditData"></mi-subsection-service-audit-table>
    </mi-tab> -->
    <!-- <mi-tab [withPane]="false" [noBorder]="true" [tabTitle]="'Graph'">
      <mi-subsection-service-audit-graph *ngIf="loadViews" [serviceAuditData]="serviceAuditData" [currency]="currency"></mi-subsection-service-audit-graph>
    </mi-tab> -->
  </mi-tabs-container>
</div>