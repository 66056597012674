import { Link } from 'src/shared/ng-models/Link.interface';
import { Component} from '@angular/core';
import { ITenantReport } from 'src/libs/api/settings/settings.service';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import FeDateUtil from 'src/shared/utils/FeDate.util';

@Component({
  selector: 'mi-accounting-analysis',
  styles: [
    `
      .iframe-container {
        width: 100%;
        border: 0.1px solid lightgray;
        height: 100vh;
        padding: 0px;
        border-radius: 5px;
      }
    `
  ],
  template: `
  <div *ngIf="metaBaseDashboardId; else loading">
  <mi-page-header 
      title="Accounting Analysis" 
      [breadcrumbs]="breadCrumbs" 
      [links]="headerLinks">
    </mi-page-header>
    <div class="iframe-container mt-3">
      <mi-opacity-loader [ready]="!showLoader">
          <iframe class="iframe-container" id="metabase-iframe-{{metaBaseDashboardId}}" src="" title="metabase"></iframe>
      </mi-opacity-loader>
    </div>
    </div>
    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>
`,
})

export class AccountingAnalysisComponent  {
  metaBaseDashboardId: number;
  showLoader: boolean = true;
  tenantId: string = null;
  frontEndSettings: ITenantReport[] = FrontEndSettingsUtil.get().TENANT_REPORTS || [];
  headerLinks: Link[] = [];

  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Accounting Analysis",
      url: "/accounting-analysis",
      internal: true
    }
  ];

  constructor(
    private reportV2Service: ReportV2Service
  ) { }

  ngOnInit(): void {
    const report = this.frontEndSettings.find(report => report.reportName === "Accounting Analysis");

    if (report) {
      this.metaBaseDashboardId = report.metabaseDashboardId;
      this.tenantId = report.tenantId;
    } else {
      console.warn("Report with name 'Accounting Analysis' not found.");
    }

    this.loadData();

  }


  loadData(): void {
    this.showLoader = true;

    this.reportV2Service.getCostChangeAnalysisMetaBaseResponse(this.tenantId, this.metaBaseDashboardId).then((res: string) => {
      const cleanUrl = res.split('#')[0];
      const bill_date =  FeDateUtil.getAccountingAnalysisDateFormate(1);

      const modifiedUrl = `${cleanUrl}?bill_date=${encodeURIComponent(bill_date)}#bordered&titled=false`;

      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).setAttribute("src", modifiedUrl);
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).addEventListener('load', () => {
        this.showLoader = false;
      });
    })
  }

}
