export function isEditRoleOnBU( role_number: number): boolean {
  return [1,2,5,6].indexOf(role_number) > -1;
  // return false;
}

export function isMisoAdmin( role_number: number): boolean {
  return [1,2].indexOf(role_number) > -1;
  // return false;
}

export function filterRole( roles: any[], isMiso: boolean): any[] {
  return roles.filter(role => {
    if (role.role_number <= 4 && isMiso) {
      return true;
    } else if (role.role_number > 4 && !isMiso) {
      return true;
    } else if (role.role_number === 999) {
      return true;
    }
    return false;
  });
}
