import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TenantComponentsModule} from '../../../components/tenant-components.module';
import {SharedModule} from '../../../../shared/shared.module';
import {VendorAccountIdInstanceWrapComponent} from './vendor-account-id-instance-wrap.component';
import {VendorAccountIdDetailsComponent} from './detail/vendor-account-id-details.component';
import {VendorAccountIdSearchEventsComponent} from './search-events/vendor-account-id-search-events.component';
import {VendorAccountIdSearchMiServicesComponent} from './search-mi-services/vendor-account-id-search-mi-services.component';
import {VendorAccountIdEditComponent} from './edit/vendor-account-id-edit.component';
import {VendorAccountIdCreateComponent} from './create/vendor-account-id-create.component';
import {VendorProductCreateComponent} from '../../vendor-products/vendor-product-create.component';
import {VendorAccountIdDeleteComponent} from './delete/vendor-account-id-delete.component';
import {AccountIdServiceTermComponent} from './detail/account-id-service-term/account-id-service-term.component';
import { AccountIdServiceOwnershipComponent } from './detail/account-id-service-ownership/account-id-service-ownership.component';

@NgModule({
  declarations: [
    VendorAccountIdInstanceWrapComponent,
    VendorAccountIdDetailsComponent,
    VendorAccountIdSearchEventsComponent,
    VendorAccountIdSearchMiServicesComponent,
    VendorAccountIdEditComponent,
    VendorAccountIdCreateComponent,
    VendorProductCreateComponent,
    VendorAccountIdDeleteComponent,
    AccountIdServiceOwnershipComponent,
    AccountIdServiceTermComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule
  ],
  providers: [],
  exports: [
    VendorAccountIdInstanceWrapComponent,
    VendorAccountIdDetailsComponent,
    VendorAccountIdSearchEventsComponent,
    VendorAccountIdSearchMiServicesComponent,
    VendorAccountIdEditComponent,
    VendorAccountIdCreateComponent,
    VendorAccountIdDeleteComponent
  ]
})
export class VendorAccountIdInstanceWrapModule {
}
