function buildUrls () {

  const fullLocation = window.location.href;
  const regexHttp = /^(http|https):\/\//;
  const regexUpToPeriod = /^([A-Za-z0-9-]{1,256}).\.+/;
  const regexAfterSlash = /\/.*$/;
  const regexAfterColon = /:.*$/;

  const DOMAIN_OPTIONAL_PORT = fullLocation
    .replace(regexHttp, '')
    .replace(regexAfterSlash, '')
    .replace(regexUpToPeriod, '');

  const DOMAIN = DOMAIN_OPTIONAL_PORT
    .replace(regexAfterColon, '');

  const PROTOCOL = window.location.protocol;

  const API_VERSION = 'v1';

  return {
    ACCOUNTS_URL: `${PROTOCOL}//accounts.${DOMAIN_OPTIONAL_PORT}`,
    FULL_ACCOUNTS_URL: `${PROTOCOL}//accounts.${DOMAIN_OPTIONAL_PORT}`,
    MIYAGI_URL: `${PROTOCOL}//miyagi.${DOMAIN_OPTIONAL_PORT}`,
    CLIENT_DOMAIN_URL: `${PROTOCOL}//{DOMAIN}.${DOMAIN_OPTIONAL_PORT}/`,
    COOKIE_CROSS_DOMAIN: DOMAIN
  };
}

export const settings = {
  ...buildUrls(),
  AUTH_COOKIE: 'mi-auth_2',
  MI_MISO_AUTH_COOKIE: 'mi-miso-auth-token',
  MI_MISO_LOGGED_IN_USER_COOKIE: 'mi-miso-logged-in-user',
  MI_MISO_USER_SELECTED_TENANT_COOKIE: 'mi-miso-user-selected-tenant',
  TENANT_DUAL_PLATFORM_ACCESS_COOKIE: 'mi-dual-access',
  COOKIE_HTTPS_ONLY: false,
  MISO_CONTACT_URL: 'http://www.miso3.com/contact'
}