<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content >


    <mi-server-error [error]="contactError"></mi-server-error>

    <div class="chosen-contacts">
      <mi-find-or-create-contact
        [searchString]="defaultSearchText"
        [vendorName]="vendorName"
        [showVendorTab]="showVendorTab"
        (contactSelected)="onAddContact($event)">
      </mi-find-or-create-contact>
    </div>
    <div class="button-container">
      <button mi-button buttonTheme="light-theme" (click)="close()">Cancel</button>
    </div>
  </modal-content>
</modal>
