import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MiServiceGroup} from '../../../shared/models/MiServiceGroup';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: "mi-service-groups-edit-form",
  styleUrls: ['./service-groups-edit-form.component.scss'],
  templateUrl: './service-groups-edit-form.component.html'
})

export class ServiceGroupsEditFormComponent implements OnInit {

  inEditMode: boolean = false;
  createForm: FormGroup;
  error: string;
  loaded: boolean = false;
  @Input() preFill: string = null
  @Input() mi_service_group: MiServiceGroup;
  @Output() onFormSubmitComplete: EventEmitter<MiServiceGroup> = new EventEmitter<MiServiceGroup>();
  @Output() onAddServiceGroupCancel: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    if(this.mi_service_group){
      this.inEditMode = true;
      this.createForm = this.formBuilder.group({
        name: [this.mi_service_group.name || '', Validators.required],
        description: [this.mi_service_group.description || '']
      })
    }else{
      this.createForm = this.formBuilder.group({
        name: [this.preFill, Validators.required],
        description: [''],
      });
    }
  }

  onFormSubmit(data: any): void {

    // Submit.
    const serviceGroup: MiServiceGroup = {
      ...data
    };

    this.onFormSubmitComplete.emit(serviceGroup);

  }

  onCancel(){
    this.onAddServiceGroupCancel.emit();
  }

}
