<div class='page-header'>
  <div class="page-header--left">
    <ul class="page-header--breadcrumbs">
      <li *ngIf="!breadcrumbs">
        &nbsp;
      </li>

      <li *ngFor="let breadcrumb of breadcrumbs">
        <ng-container *ngIf="breadcrumb.internal; else external">
          <a class="breadcrumb" routerLink="{{breadcrumb.url}}">
            {{breadcrumb.label === "Dashboard" ? (tenantDisplayName$ | async) + " " + breadcrumb.label :
            breadcrumb.label}}
          </a>
        </ng-container>
        <ng-template #external>
          <a class="breadcrumb" href="{{breadcrumb.url}}">
            {{breadcrumb.label}}
          </a>
        </ng-template>
      </li>

    </ul>
    <!-- <div class="page-header--title">
      <mi-primary-h1>{{title}}</mi-primary-h1>
      <ng-content select="[showSubTitle]"></ng-content>
    </div> -->
  </div>

  <div class="page-header--right">

    <div class="page-header--right-content">

      <ul class="page-links" *ngIf="links">

        <li *ngFor="let link of links">

          <ng-container *ngIf="link.internal; else external">
            <a class="breadcrumb button dark-theme" routerLink="{{link.url}}">
              {{link.label}}
            </a>
          </ng-container>

          <ng-template #external>
            <a class="breadcrumb button dark-theme" href="{{link.url}}" target="{{link.target || '_self'}}">
              {{link.label}}
            </a>
          </ng-template>

        </li>

      </ul>
      <ng-content></ng-content>
      
      <a class="link" (click)="openVideoInfo(videoModal)" *ngIf="videoLink">
        <div class="link link--data-integrity"></div>
      </a>

      <mi-validation-flag *ngIf="showValidationFlag" [validationFlag]="validationFlag"
        [daysSinceLastUpdate]="daysSinceLastUpdate"></mi-validation-flag>
      <!-- <mi-shortcut-menu *ngIf="showShortcutMenu"></mi-shortcut-menu> -->

    </div>
  </div>
</div>
<modal #videoModal class="extra-wide no-padding-top no-footer no-padding no-footer-modal">
  <modal-header>
  {{videoModalTitle}}
  </modal-header>
  <modal-content>
    <iframe class="mt-3" id="video-iframe" [src]="videoLink" width="100%" height="390" frameborder="0" allowfullscreen
      allow="autoplay; encrypted-media"></iframe>
  </modal-content>
</modal>