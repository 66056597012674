import {Component, Input} from '@angular/core';

@Component({
  selector: 'mi-opacity-loader',
  styleUrls: ['./opacity-loader.component.scss'],
  templateUrl: './opacity-loader.component.html'
})

export class OpacityLoaderComponent{

  @Input() ready: boolean = false;
  @Input() type: string = "circle";

}
