import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Tenant} from '../../../shared/models/Tenant';

@Injectable()
export class SharedTenantService {

  private fetchOneDomain = '/account/tenants/';

  constructor(private http: HttpClient) {
  }

  getTenantByDomain(domain: string): Observable<Tenant> {
    return this.http
      .get<Tenant>(this.fetchOneDomain + domain)
      .map((response: any) => response.data.tenant);
  }
}

