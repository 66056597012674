import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MiServiceDetails, ServiceCalculationDetails } from "../../../../../../shared/models/MiService";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import { TermsAndConditions } from "../../../../../../shared/models/TermsAndConditions";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Store } from "@ngrx/store";
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { ToastrService } from 'ngx-toastr';

interface CurrentFinancial {
  cost: number,
  billing_frequency: number;
  end_date?: string;
  non_recurring_cost?: number;
  start_date?: string;
  auto_renew_length?: number;
  terms_and_conditions?: TermsAndConditions;
  _id?: string;
}

@Component(
  {
    selector: 'mi-subsection-service-term-details',
    templateUrl: './subsection-service-term-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionServiceTermsDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: Boolean = true;
  @Input() title: string;
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  pageLoaded: boolean = false;
  users: any[] = [];
  terms_and_conditions: TermsAndConditions;
  current_financial: CurrentFinancial;
  serviceTerm: ServiceTerm;
  newTermId: string;
  edit: boolean = false;
  updating: boolean = false;
  serviceCalculationDetails: ServiceCalculationDetails;
  serviceDetails: MiServiceDetails
  selectedServiceTermDetails: any
  isServiceTermRemoved: boolean
  deletedServiceTerm: ServiceTerm

  constructor(
    private serviceTermService: ServiceTermService,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails && data.serviceCalculationDetails) {
            this.serviceDetails = data.serviceDetails
            this.serviceCalculationDetails = data.serviceCalculationDetails;
            if (this.serviceCalculationDetails.service_term.id) {
              this.getServiceTerm();
            }else{
              this.serviceTerm = null;
              this.pageLoaded = true
            }
          }
        });
  }

  getServiceTerm() {
    this.serviceTermService.getServiceTermByTenantIdAndServiceDisplayId(this.serviceDetails.general.tenant_id, this.serviceDetails.general.service_display_id)
      .takeUntil(this.destroy$)
      .subscribe(serviceTerm => {
        this.pageLoaded = true
        this.serviceTerm = serviceTerm;
        this.isServiceTermRemoved = serviceTerm.id ? true : false
      },
        e => this.error = e);
  }

  onToggleExpand(): void {
    this.isOpen = !this.isOpen;
  }

  selectedServiceTerm(term) {
    this.deletedServiceTerm = this.serviceTerm
    this.isServiceTermRemoved = false
    this.selectedServiceTermDetails = term
    if (term) {
      this.newTermId = term.id;
    } else {
      this.newTermId = null;
    }
  }

  serviceTermRemovedHandler(isServiceTermRemovedValue: boolean) {
    this.isServiceTermRemoved = isServiceTermRemovedValue
  }

  modeChange(edit) {
    this.edit = edit;
  }

  updateServiceTerm() {
    this.updating = true;
    this.error = "";
    const paramData = {
      accountid_id: null,
      is_deleted: this.isServiceTermRemoved ? true : false,
      service_id: this.serviceDetails.service_data.service_id,
      service_display_id: this.serviceDetails.general.service_display_id,
      service_term_id: this.isServiceTermRemoved ? this.deletedServiceTerm.id : this.selectedServiceTermDetails.id,
      tenant_vendor_id: this.serviceDetails.vendor.tenant_vendor_id, // this changes being done with respect to new changes done by priti in backend
      vendor_id: this.serviceDetails.vendor.purchasing_vendor_id // this changes being done with respect to new changes done by priti in back end
    }

    this.serviceTermService.saveServiceTermAssociation(paramData)
      .toPromise()
      .then(res => {
        this.updating = false;
        this.toastr.success(`Service terms updated successfully`);
        this.updateServiceDetailsStore.emit();
        this.onToggleExpand();
      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while updating service term, Please try again`);
      })
      .finally(() => {
        this.updating = false;
      });
  }

}