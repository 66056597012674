<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'circle'">
    <div class="loading-animation">

      <svg class="loader"
           xmlns="http://www.w3.org/2000/svg"
           width="65px"
           height="65px"
           viewBox="0 0 40 40">
        <path
          opacity=".2"
          fill="#2484C6"
          d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z"/>
        <path
          fill="#2484C6"
          d="M26.013 10.047l1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="0.5s"
            repeatCount="indefinite"/>
        </path>
      </svg>
    
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'circle-signup'">
    <div class="loading-animation">

      <svg class="loader"
           xmlns="http://www.w3.org/2000/svg"
           width="65px"
           height="65px"
           viewBox="0 0 40 40">
        <path
          opacity=".2"
          fill="#1c3144"
          d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z"/>
        <path
          fill="#1c3144"
          d="M26.013 10.047l1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="0.5s"
            repeatCount="indefinite"/>
        </path>
      </svg>
    
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'dots'">
    <div class="dots-animation">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'dot-loader'">
    <div class="dot-loader-animation">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'miso'">
    <svg class="miso-svg" viewBox="0 0 200 300">
      <symbol id="s-text">
        <text text-anchor="middle" x="50%" y="50%">MiSO</text>
      </symbol>
    
      <g class = "g-ants">
        <use xlink:href="#s-text" class="text-copy"></use>
        <use xlink:href="#s-text" class="text-copy"></use>
        <use xlink:href="#s-text" class="text-copy"></use>
        <use xlink:href="#s-text" class="text-copy"></use>
        <use xlink:href="#s-text" class="text-copy"></use>
      </g>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'circle-new'">
    <svg class="miso-svg-circle" viewBox="0 0 200 300">
      <symbol id="s-text">
        <text text-anchor="middle" x="50%" y="50%">.</text>
      </symbol>
    
      <g class = "g-ants">
        <use xlink:href="#s-text" class="text-copy-circle"></use>
        <use xlink:href="#s-text" class="text-copy-circle"></use>
        <use xlink:href="#s-text" class="text-copy-circle"></use>
        <use xlink:href="#s-text" class="text-copy-circle"></use>
        <use xlink:href="#s-text" class="text-copy-circle"></use>
      </g>
    </svg>
  </ng-container>
</ng-container>


