import * as ServiceV2Actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import { ServiceV2Response } from 'src/shared/models/ServiceV2';

export interface State {
  results: ServiceV2Response;
  searchLoaded: boolean;
  hasLoadMore: boolean;
}

const initialState: State = {
  results: {
    moreAvailable: false,
    pageNumber: 0,
    pageSize: 25,
    total: 0,
    total_mrc: 0,
    tenantId: "",
    search: "",
    sort: {},
    filters: [],
    data: []
  },
  searchLoaded: true,
  hasLoadMore: false
};

export function reducer(state = initialState, action: ServiceV2Actions.Actions): State {
  // actions have action.type:string and action.payload:object.
  // log('inside of user reducer', state, action);
  switch (action.type) {
    case ServiceV2Actions.ActionTypes.SET_RESULTS: {
      return {
        ...state,
        results: {
          ...action.payload
        }
      };
    }
    case ServiceV2Actions.ActionTypes.GET_DATA: {
      return {
        ...state
      };
    }
    case ServiceV2Actions.ActionTypes.SEARCH_LOADING: {
      return {
        ...state,
        searchLoaded: false
      };
    }
    case ServiceV2Actions.ActionTypes.SEARCH_LOADED: {
      return {
        ...state,
        searchLoaded: true
      };
    }
    case ServiceV2Actions.ActionTypes.SET_LOAD_MORE: {
      return {
        ...state,
        hasLoadMore: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const getServiceV2Data = createFeatureSelector<State>('serviceV2');
export const getLoaded = createSelector(
  getServiceV2Data,
  (state) => state.searchLoaded
);
export const getLoadMoreStatus = createSelector(
  getServiceV2Data,
  (state) => state.hasLoadMore
);
