<div class="px-2 py-3">

  <div class="note note-info d-flex justify-content-between align-items-center">
    <div>
      Select the properties you wish to have in your list and download template.
    </div>
    <div class="select-all">
      <button class="btn-sm btn btn-primary mx-1" (click)="selectAll(true)">Select All</button>
      <button class="btn-sm btn btn-primary mx-1" (click)="selectAll(false)">Deselect All</button>
    </div>
  </div>
  <div class="section-group card p-3 mt-3" *ngFor="let section of sections">
    <h3><div class="note-info p-2">{{section.title}}</div></h3>
    <div *ngFor="let sub_section of section.sub_sections">
      <div class="sub-section separator" *ngIf="sub_section.separator">
        &nbsp;
      </div>
      <div class="sub-section p-2">
        <ul class="column" *ngFor="let column of sub_section.columns">
          <li *ngFor="let field of getFields(column.fields)">
            <label>

              <input type="checkbox" [checked]="isSelected(field)" (change)="toggle($event, field)"
                [disabled]="field.required" />

              <div *ngIf="!field.display">
                <span>{{('BULK_IMPORT.TEMPLATE_FIELDS.' + reference + '.' + field.field_id)|translate}}</span>
              </div>
              <div *ngIf="field.display">
                <span>{{field.displayLabel? field.displayLabel:field.display}}</span>
              </div>

            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--
  <div class="button-container">
    <a *ngIf="showCancel" mi-button class="button--cancel" buttonTheme="light-theme" (click)="onCancel($event)">
      Cancel
    </a>
    <a mi-button buttonTheme="dark-theme" (click)="onDownload($event)">
      {{downloadButtonName}}
    </a>
  </div>
  -->
</div>