import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import { CanDelete } from '../../../../shared/models/CanDelete';
import { PageAlertAddAction } from '../../../../shared/state/page-alert/actions';
import { select, Store } from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import { TenantMiServiceService } from 'src/libs/api/tenant/tenantMiService.service';
import { Link } from 'src/shared/ng-models/Link.interface';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { isEditRoleOnBU } from '../../../../common/utils/UserAccess';
import {TenantV2Service} from '../../../../libs/api2/tenant-v2/tenant-v2.service';

@Component({
  selector: 'mi-service-delete-component',
  templateUrl: './service-delete.component.html',
  styleUrls: ['./service-delete.component.scss']
})

export class MiServiceDeleteComponent extends AbstractPageComponent implements OnInit {

  displayId: string;
  error: string;
  exitRoute: string;
  canDelete: CanDelete;
  canDeleteMode: boolean = true;
  loaded: boolean = false;
  breadcrumbs: Link[] = [];
  headerLinks: Link[] = [];
  owner: string = null;
  isSuperAdmin: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantMiService: TenantMiServiceService,
              private router: Router,
              private store: Store<ASReducer.State>) {
    super();
  }

  ngOnInit() {
    this.store.pipe(
      select(appDataReducer.getAppData)
    ).subscribe(
      (tenant) => {
          this.isSuperAdmin = this.allowedToDelete(tenant.tenantData.v2.role);
          // this.isSuperAdmin = true;

          this.displayId = this.activatedRoute.snapshot.paramMap.get('display_id');
          this.exitRoute = `/services/${this.displayId}`;
          this.tenantMiService.getMiService(this.displayId)
            .toPromise()
            // .then((res) => {
            .then((res) => this.tenantMiService.canDelete(res._id).toPromise())
            .then((res: CanDelete) => {
              this.canDelete = res;
              this.canDeleteMode = true;
              if (res.dependents.length) {
                this.canDeleteMode = false;
              }

              this.breadcrumbs = [
                {
                  url: '/',
                  label: 'Dashboard',
                  internal: true
                },
                {
                  url: '/services',
                  label: 'Services',
                  internal: true
                },
                {
                  url: `/services/${this.displayId}`,
                  label: this.displayId,
                  internal: true
                },
                {
                  url: '/services',
                  label: 'Delete',
                  internal: true
                }
              ];
            })
            .catch(e => this.error = e);

            this.loaded = true;
      },
      (e) => {
        this.error = e;
      });
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete() {
    if (!this.isSuperAdmin) {
      return;
    }

    this.tenantMiService.deleteService( this.canDelete.delete._id )
    // this.tenantMiService.deleteService( this.displayId )
    .subscribe(() => {
        this.loaded = false;
        this.store.dispatch(
          new PageAlertAddAction({
            message: `Service, ${this.displayId}, was successfully deleted.`,
            alertType: 'SUCCESS'
          })
        );
        setTimeout(() => {
          this.router.navigate([`/services`]);
        }, 1000);
      }, (e) => {
        this.error = e;
      });
  }

  allowedToDelete(role: number) {
    return isEditRoleOnBU(role);
  }
}
