<div *ngIf="(pageLoaded$ | async); else loading">

  <mi-parent-service-summary></mi-parent-service-summary>

  <div class="sections">

    <section class="data-section service-list">

      <div class="service-list-filter-left">
        <mi-toggle class="title-small title-bold small"
                   toggleTitle="Hide 0$ CMRC"
                   [titleOnRight]="true"
                   (onChange)="toggleFilterZeroCheck($event)"></mi-toggle>
      </div>

      <h2>Attached Services</h2>

      <mi-parent-service-tile
        *ngFor="let service of parentService.services.filter(filterFunction)"
        [service]="service"
        [mode]="MODE">
      </mi-parent-service-tile>

      <ng-container *ngIf="!parentService.services.filter(filterFunction).length">
        <em>{{"NO_SERVICES_ATTACHED"|translate}}</em>
      </ng-container>

    </section>

    <section id="service-group-list" class='data-section service-group-list'>

      <mi-parent-subsection-service-group
        title="Service Groups"
        (dataUpdate)="updateStore($event)"
        [editable]="true">
      </mi-parent-subsection-service-group>

    </section>


    <section class="data-section">
      <mi-note-list></mi-note-list>
    </section>

  </div>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #noServiceGroups>
  <em>{{"PARENT_SERVICE_NO_SERVICES_GROUPS"|translate}}</em>
</ng-template>
