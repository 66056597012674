<div class="row">
  <div class="col-1">
    <input type="checkbox"
           [ngModel]="toChecked"
           (click)="onToggleSelect($event)"
           [value]="TO_RECIPIENT" />
  </div>
  <div class="col-2">
    <input type="checkbox"
           [ngModel]="ccChecked"
           (click)="onToggleSelect($event)"
           [value]="CC_RECIPIENT" />
  </div>
  <div class="col-3">
    <div class="limit">
      {{recipient.first_name}} {{recipient.last_name}} | {{recipient.email}} <ng-container *ngIf="recipient.role">({{('CONTACT_ROLES.' + recipient.role) | translate }})</ng-container>
    </div>
    <a class="remove-recipient"
      (click)="onRemoveClick()">
      Remove
    </a>
  </div>
</div>
