import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import { AddressV2 } from 'src/libs/api2/types/AddressV2.interface';
import { LocationV2 } from '../../../../libs/api2/types/LocationV2.interface';
import { AddressV2Service } from '../../../../libs/api2/address-v2/address-v2.service';

@Component({
  selector: 'mi-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss']
})

export class LocationFormComponent extends AbstractPageComponent implements OnInit {

  @Output() chosenLocation: EventEmitter<LocationV2> = new EventEmitter<LocationV2>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() newCreated: EventEmitter<void> = new EventEmitter<void>();

  @Input() owner: string = null;

  _editId: string;
  loading: boolean = false;
  loadError: string = '';
  submitError: string = '';
  ctaLabel: string = '';
  newLocationLabel: string = '';

  selectedAddress: AddressV2 = null;
  selectedLocation: {label: string, nick_name: string} = null;
  allLocations: LocationV2[] = [];
  locationObjects: LocationV2[] = [];
  isSubmitLocationProgress: boolean= false;

  constructor(
      private tenantAddressV2Service: AddressV2Service
    ) {
    super();
  }

  @Input('editId')
  set editId(val) {
    if (val) {
      // console.log('val for edit id', val);
      this.startAsEditMode(<string>val);
    }
  }

  ngOnInit() {
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  startAsEditMode(addressId: string): void {
    this.ctaLabel = 'Continue';
    this._editId = addressId;
    this.loading = true;
    this.loadError = '';
    this.tenantAddressV2Service.getAddressById(addressId).subscribe((address) => {
      this.createForm(address);
    }, (error) => {
      this.loadError = error;
    });
  }

  createForm(address: any = {}): void {
    this.selectedAddress = address;
    this.locationObjects = address.locations;
    this.allLocations = this.locationObjects.filter(location => location.is_active === true);
    this.selectedLocation = this.allLocations.length ? this.allLocations[0] : null;
    // console.log('this.selectedLocation', this.selectedLocation);
    console.log('this.allLocations', this.allLocations);
    let defaultLocation = this.locationObjects.find(location => location.label === "Default");
    console.log('this.defaultLocation', defaultLocation);

    if(!defaultLocation|| !this.allLocations.length){
      this.newLocationLabel = "Default";
      this.selectedLocation = {label: "Default", nick_name: null};
    }
    this.loading = false;
  }

  submitLocation(event): void {
    this.isSubmitLocationProgress = true
    event.preventDefault();
    const locationItem: LocationV2 = this.locationObjects.find(location => location.label === this.selectedLocation.label);
    if (!locationItem) {
      const data = {
        address_id: this._editId,
        label: this.selectedLocation.label,
        is_active: true,
        nick_name: this.selectedLocation.nick_name,
        owner: this.owner
      };

      this.tenantAddressV2Service.saveLocation(data).subscribe((location) => {
        // this.indexLocation(location);
        console.log("here is tenantAddressV2Service", location )
        this.dispatchLocationSelect(location);
      }, (error) => {
        this.isSubmitLocationProgress = false
        this.submitError = error;
      });
    } else {
      this.dispatchLocationSelect(locationItem);
      this.isSubmitLocationProgress = false
    }
  }

  indexLocation(data) {
    this.tenantAddressV2Service.indexLocation(data.id).subscribe((response) => {
      this.newCreated.emit();
      return this.dispatchLocationSelect(data);
    }, (error) => {
      this.loadError = error;
    });
  }

  dispatchLocationSelect(chosenLocation: LocationV2) {
    console.log("here chosenLocation", chosenLocation)
    console.log("here this.selectedAddress", this.selectedAddress)
    chosenLocation.address = this.selectedAddress;
    if (chosenLocation.address != null) {
      this.chosenLocation.emit(chosenLocation);
    }
  }
}
