import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {AbstractPageComponent} from '../../pages/abstract-page/abstract-page.component';
import * as userReducer from '../../../libs/user-store/state/reducers';
import * as notesReducer from '../../state/note-list/reducers';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {MisoCollection} from '../../models/MisoCollection.enum';
import * as NoteListActions from '../../state/note-list/actions';
import {COLLECTION_TYPE_REFERENCES} from '../../../common/utils/sharedConstants';
import {MisoActivityTypes} from '../../models/MisoActivity.interface';
import {MiServiceEvent} from '../../models/MiServiceEvent';

@Component({
  selector: 'mi-note-list',
  styleUrls: ['./note-list.component.scss'],
  templateUrl: './note-list.component.html',
})

export class NoteListComponent extends AbstractPageComponent implements OnInit {

  title: string = 'Comments';
  content: string = '';

  allActivity$: Observable<any[]>;
  events$: Observable<any[]>;
  notes$: Observable<any[]>;

  EVENT: string = MisoActivityTypes.EVENT;
  NOTE: string = MisoActivityTypes.NOTE;

  loaded$: Observable<boolean>;
  isSuperUser$: Observable<boolean>;

  document_id: string;
  document_collection: MisoCollection;
  events: MiServiceEvent[];

  showEvents: boolean = false;

  constructor(
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit() {

    this.loaded$ = this.store.select(notesReducer.loaded).takeUntil(this.destroy$);

    this.allActivity$ = this.store.select(notesReducer.allActivity).takeUntil(this.destroy$);
    this.events$ = this.store.select(notesReducer.events).takeUntil(this.destroy$);
    this.notes$ = this.store.select(notesReducer.notes).takeUntil(this.destroy$);

    this.isSuperUser$ = this.store.select(userReducer.isSuperUser).takeUntil(this.destroy$);

    this.events$.subscribe((e) => {
      // Save a rendition of the Events.
      // They need to be passed in with
      // each new note creation.
      this.events = e as MiServiceEvent[];
    });

    this.store.select(notesReducer.getNoteList)
      .takeUntil(this.destroy$)
      .subscribe(noteList => {
        this.document_id = noteList.document_id;
        this.document_collection = noteList.document_collection;
        this.showEvents = noteList.document_collection === COLLECTION_TYPE_REFERENCES.SERVICES;
      });
  }

  onNoteCreated(note) {
    this.store.dispatch(
      new NoteListActions.LoadNotesAction({
        document_id: this.document_id,
        document_collection: this.document_collection,
        events: this.events // Pass the events here.
      })
    );
  }

}


