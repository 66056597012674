import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {LOG_LEVELS, createLogger} from '../../logger';
import { Store } from '@ngrx/store';
import * as userReducer from '../../../libs/user-store/state/reducers';
import {AbstractPageComponent} from "../../pages/abstract-page/abstract-page.component";
import * as mainNavReducer from '../../state/main-nav/reducers';
import {
  MainNavOpenAction,
  MainNavCloseAction
} from "../../state/main-nav/actions";
import {Tenant} from "../../models/Tenant";
import { Router,NavigationEnd  } from '@angular/router';

const log = createLogger(LOG_LEVELS.MAIN_NAV);

@Component({
  selector: 'mi-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  @Input() structure: any = {
    shortCuts: {
      upperLinks: [],
      lowerLinks: []
    },
    defaultLinks: []
  };currentURL:string

  @Input() isOpen: boolean = true;

  user: any;
  tenant: Tenant;
  itsAccountsAndMiyagi: boolean = true

  constructor(
    private store: Store<userReducer.State>,
    private router: Router
  ) {
    super();
      const currentUrl = window.location.href;       
        const accountsURL = currentUrl.includes("accounts");
        const miyagiURL = currentUrl.includes("miyagi");
        this.itsAccountsAndMiyagi = accountsURL || miyagiURL ? true : false
  }

  toggleNav(){
    if(!this.itsAccountsAndMiyagi){
      this.isOpen
      ?
      this.store.dispatch(new MainNavCloseAction({}))
      :
      this.store.dispatch(new MainNavOpenAction({}));
    }
  }
  
  ngOnInit() {
    this.router.events.filter(event => event instanceof NavigationEnd)
          .subscribe((event : any) => {
            this.currentURL = event.url.substring(1);
          });

    this.store.select(userReducer.getUserState).subscribe(
      state => {
        if (state.secureUser) {
          this.user = state.secureUser.getSafeData();
        }
      });

    this.store.select(mainNavReducer.getMainNav).subscribe(
      state => {
        this.router.events.filter(event => event instanceof NavigationEnd)
          .subscribe((event : any) => {
            this.currentURL = event.url.substring(1);
          }); 

          this.structure = state.structure;
          const updatedStructureShortcuts = this.structure.shortCuts.upperLinks.map(obj => ({ ...obj, isActive: this.currentURL === obj.path }))
          this.structure.shortCuts.upperLinks = updatedStructureShortcuts
        this.isOpen = state.open;
      });

  }

  ngOnDestroy() {
    log('destroying');
    super.ngOnDestroy();
  }

}
