import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

import {debounceTime} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {Equipment} from '../../../shared/models/Equipment';
import {SortableTableColumn, SortableTemplatesEnum} from '../../../shared/ng-models/SortableTableColumn.interface';
import {EquipmentSearchQueryParams} from '../../../shared/ng-models/search/SearchQueryParams.interface';

@Component({
  selector: 'mi-find-or-create-equipment',
  templateUrl: './find-or-create-equipment.component.html',
  styleUrls: ['./find-or-create-equipment.component.scss']
})

export class FindOrCreateEquipmentComponent extends AbstractPageComponent implements OnInit {

  @Output() selecteEquipment: EventEmitter<Equipment> = new EventEmitter<Equipment>();
  @Output() showAddEquipmentStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  generalInformationTableColumns: Array<SortableTableColumn>;

  equipmentFindError: string = "";
  showAddEquipment: boolean = false;

  resultsLoadedFirstTime: boolean = false;
  resultsLoaded: boolean = false;
  resultsError: any = '';
  results: any;
  hits: Array<Equipment> = [];
  lastQuery: any = {};
  total: number = 0;
  page: number = 1;
  pageSize: number = 5;
  searchTimeout: any;
  vendors: Array<string> = [];

  searchInput = new Subject<string>();
  @ViewChild('equipmentTpl', {static: true}) equipmentTpl: TemplateRef<any>;

  constructor() {
    super();
  }

  ngOnInit(): void {

    this.generalInformationTableColumns = [
      {
        name: "MISO ID",
        prop:'display_id',
        sortable: false,
        cellTemplate: this.equipmentTpl,
        className: 'no-wrap'
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: false
      },
      {
        name: "Manufacturer",
        prop: 'equipment_catalog.vendor.vendor_name',
        sortable: false
      },
      {
        name: "Model #",
        prop: 'equipment_catalog.category_name',
        sortable: false
      },
      {
        name: 'Serial Number or Tag',
        prop: 'serial',
        sortable: false,
        cellTemplate: SortableTemplatesEnum.truncate,
      },
      {
        name: 'Price $',
        prop: 'price',
        sortable: false,
        cellTemplate: SortableTemplatesEnum.currency
      }
    ] as Array<SortableTableColumn>;

    this.searchInput.pipe(debounceTime(150))
      .subscribe(q => {
        this.fetchResults({ q });
      });

    this.fetchResults();
  }

  onSearchTermChanged(q = '') {
    if ((this.lastQuery.q || "") == (q || "")) {
      this.fetchResults({ q: '' })
    };
    this.searchInput.next(q);
  }

  onClearSearch() {
    this.fetchResults({ q: '' });
  }

  filterEquipmentByVendor(vendor) {
    this.fetchResults({ vendor_name: [(vendor || {}).key] });
  }

  loadMoreClicked($event) {
    $event.preventDefault();
    this.fetchResults(this.lastQuery, this.page + 1);
  }

  fetchResults(query: any = {}, page: number = 1) {
    this.resultsLoaded = false;
    this.resultsError = false;
    this.page = page;
    let size = this.pageSize;
    let from = (page > 1 ? (this.pageSize * (page - 1)) : 0);

    const options = { from: 0, size: this.pageSize, status:["ACTIVE"]};

    if (page > 1) {
      options.from = this.pageSize + ((page - 2) * 10);
      options.size = 10;
    }


    let newQuery = {
      ...this.lastQuery,
      ...query,
      ...options
    };
   

  }
  onClickCreateNew() {
    this.showAddEquipment=true;
    this.showAddEquipmentStatus.emit(!this.showAddEquipment);
  }

  onAddEquipmentCancel() {
    this.showAddEquipment = false;
    this.showAddEquipmentStatus.emit(!this.showAddEquipment);
  }

  onAddComplete($event) {
    this.showAddEquipment = false;
    //this.fetchResults(this.lastQuery);
    this.selecteEquipment.emit($event);
  }

  onEquipmentSelect($event) {
    let selectedEquipment: Equipment;
    if ($event.target.checked) {
      selectedEquipment = this.hits.find(
        (item: Equipment): boolean => {
          return item._id === $event.target.value;
        });
    }
    else {
      selectedEquipment = null;
    }
    this.selecteEquipment.emit(selectedEquipment);
  }

}
