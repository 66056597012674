<ng-container *ngIf="orderState; else loading">
  <ng-container *ngIf="activeIndex; else static">
    <ng-container [ngSwitch]="vendor">
      <ng-container *ngSwitchCase="null">
        <mi-find-or-create-vendor
          (vendorSelected)="onVendorSelected($event)">

        </mi-find-or-create-vendor>
      </ng-container>
      <ng-container *ngSwitchDefault>

        <mi-product-vendor
          [vendor]="vendor"
          (onUnSelectVendor)="onDeselectVendor()">
        </mi-product-vendor>

        <div class="button-container">
          <a mi-button
             buttonTheme="light-theme"
             (click)="onDeselectVendor()">
            Cancel
          </a>
          <a mi-button
             buttonTheme="dark-theme"
             (click)="onContinue()">
            Next
          </a>
        </div>

      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #static>
    <dl>
      <dt>Vendor</dt>
      <dd *ngIf="vendor && vendor.vendor_name;else noData">{{vendor.vendor_name || ''}}</dd>
      <ng-template #noData>
        <dd>{{NO_DATA}}</dd>
      </ng-template>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
