import {Component, Input} from '@angular/core';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';

@Component({
  selector: 'mi-advanced-search-header',
  templateUrl: './advanced-search-header.component.html',
  styleUrls: ['./advanced-search-header.component.scss']
})

export class AdvancedSearchHeaderComponent extends AbstractPageComponent {

  @Input() saveSearch: boolean = false;
  @Input() searchTypeTitle: string = 'Search Services';
  @Input() showTotalMrc: boolean = true;
  @Input() searchType: string = 'SERVICE';
  @Input() showButton: boolean = false;
  @Input() buttonLabel: string;
  @Input() buttonUrl: string;
  @Input() showDownloadButton: boolean = true;
  activeButton: boolean = false;
  activeButtonSelected: boolean = false;;
  total = 0;
  total_mrc = 0;
  loading = true;
  q: string = '';

  constructor(private store: Store<ASReducer.State>) {
    super();
  }

  onSearchSubmit(searchValue) {

    const newQuery = {
      q: searchValue
    };

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }

  clearSearch() {
    const newQuery = {
      q: ''
    };
    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }

  activeOnlyCheckbox(evt){
    if (evt.target.checked) {
      this.store.dispatch(new ASActions.AsFilterUpdate({active_status: ['ACTIVE']}));
    } else {
      this.store.dispatch(new ASActions.AsFilterUpdate({active_status: []}));
    }
  }

  ngOnInit() {
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {

          if (data.results && data.results.aggregations) {
            if(this.searchType === 'SERVICE'){
              this.activeButton = data.pageLockFilters.indexOf("active_status")<0;
              this.activeButtonSelected = data.results.meta.params.active_status 
                && data.results.meta.params.active_status[0] === "ACTIVE" 
                && data.results.meta.params.active_status.length === 1;  
            }
            this.loading = false;
            this.total = data.results.total.value;
            this.q = data.results.meta.params.q || '';

            if (data.results.aggregations.total_mrc && this.showTotalMrc) {
              this.total_mrc = (data.results.aggregations.total_mrc.value || 0);
            }

          } else {
            this.loading = true;
            this.total = 0;
            this.total_mrc = 0;
          }
        }
      );
  }
}
