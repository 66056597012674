<mi-page-header
  title="{{ 'TENANT.MISERVICE_ORDER_CREATE_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
  <a mi-button
    buttonTheme="light-theme"
    (click)="onCancelOrder()">
    Cancel Order
  </a>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<router-outlet></router-outlet>
