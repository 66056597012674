<modal #modal [routeBehavior]="true">
  <modal-header>
    <h4>Cancellation Instruction</h4>
    <br/> <mi-id-link [id]="terms_and_conditions.display_id" *ngIf = "terms_and_conditions"></mi-id-link>
  </modal-header>
  <modal-content *ngIf="pageLoaded$|async; else loading">
    <p *ngIf = "terms_and_conditions && terms_and_conditions.cancellation_instructions; else noTerm" class="preserve-line-break no-max-width">
      {{terms_and_conditions.cancellation_instructions}}
    </p>
    
    <div class="button-container">
      <a
          class="button light-theme"
          (click)="modal.close()">
        Cancel
      </a>

      <button mi-button
              class="button dark-theme"
              routerLink="../account-rep/cancellation-request">
        Send Email
      </button>
    </div>
  </modal-content>
</modal>

<ng-template #noTerm>
  <p>
    There are no Cancellation Instructions attached to this service.
    Would you like to send email to account representative for cancellation?
  </p>
</ng-template>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
  
