import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild, OnInit} from '@angular/core';
import {SortableTableColumn, SortableTemplatesEnum} from '../../ng-models/SortableTableColumn.interface';
import {NO_DATA} from '../../ng-models/SectionDataTable.model';
import * as appDataReducer from '../../state/app-data/reducers';
import * as ASActions from '../../state/advanced-search/actions';
import * as ASReducer from '../../state/advanced-search/reducers';
import {AsSimpleStateUpdate} from "../../state/advanced-search/actions";
import { environment } from 'src/environments/environment';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import FeDateUtil from 'src/shared/utils/FeDate.util';
import { ModalService } from '@independer/ng-modal';
import { SortableTableColumnSelectModalComponent } from '../sortable-table-column-select-modal/sortable-table-column-select-modal.component';
import { TenantCustomFieldService } from 'src/libs/api/tenant/tenantCustomFields.service';
import { CustomField } from 'src/shared/models/CustomField';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { DecimalPipe } from '@angular/common';
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import { CUSTOMER_DOCUMENT_UPLOAD_TYPES } from 'src/common/utils/sharedConstants';
import { ActivatedRoute } from '@angular/router';
import { getTableView } from './table-view-options';

type SortableTableVariant =
  | 'NORMAL'
  | 'SMALL';


@Component({
  selector: 'mi-sortable-draggable-table',
  templateUrl: './sortable-draggable-table.component.html',
  styleUrls: ['./sortable-draggable-table.component.scss']
})

export class SortableDraggableTable  extends AbstractPageComponent implements OnInit {

  protected readonly NO_DATA: string = NO_DATA;
  private originalSortChanged: EventEmitter<any> = new EventEmitter();
  readonly DOC_TYPES: any = CUSTOMER_DOCUMENT_UPLOAD_TYPES;
  _rows: Array<any> = [];
  _columns: Array<SortableTableColumn> = [];
  _classes: any = {};
  _rowsSelectable: boolean = false;
  _selectedRow: any = null;
  timeZone: string = "America/New_York"
  dragStart:number;
  customFieldById: any = {};
  error: any;
  loaded: boolean = false;
  tenantId: string = '';
  table_view: string = '';
  numberOfStickyColumn: number = 1;
  
  @Input() public externalClasses: string = '';
  @Output() public sortChanged: EventEmitter<any> = this.originalSortChanged;
  @Output() public rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() public rowIdProp: string = 'display_id';
  @Input() searchType: string = '';
  @Input() hasCustomFields: boolean = false;
  @Input() editableColumns: boolean = true;

  _columnClicked: string = '';

  @ViewChild('defaultCellTpl', {static: true}) defaultCellTpl: TemplateRef<any>;
  @ViewChild('defaultHeaderTpl', {static: true}) defaultHeaderTpl: TemplateRef<any>;
  @ViewChild('idLinkTpl', {static: true}) idLinkTpl: TemplateRef<any>;
  @ViewChild('idLinkTpl2', {static: true}) idLinkTpl2: TemplateRef<any>;
  @ViewChild('contactTpl', {static: true}) contactTpl: TemplateRef<any>;
  @ViewChild('shortDateTpl', {static: true}) shortDateTpl: TemplateRef<any>;
  @ViewChild('shortDateTimeTpl', {static: true}) shortDateTimeTpl: TemplateRef<any>;
  @ViewChild('shortISODateTimeTpl', {static: true}) shortISODateTimeTpl: TemplateRef<any>;
  @ViewChild('trimmedTextTpl', {static: true}) trimmedTextTpl: TemplateRef<any>;
  @ViewChild('currencyTpl', {static: true}) currencyTpl: TemplateRef<any>;
  @ViewChild('roundedCurrencyTpl', {static: true}) roundedCurrencyTpl: TemplateRef<any>;
  @ViewChild('truncateTpl', {static: true}) truncateTpl: TemplateRef<any>;
  @ViewChild('numberTpl', {static: true}) numberTpl: TemplateRef<any>;
  @ViewChild('locationsTpl', {static: true}) locationsTpl: TemplateRef<any>;
  @ViewChild('customFieldTpl', {static: true}) customFieldTpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) statusTmpl: TemplateRef<any>;
  @ViewChild('countTmpl', {static: true}) countTmpl: TemplateRef<any>;
  @ViewChild('tenantCurrencyTpl', {static: true}) tenantCurrencyTpl: TemplateRef<any>;
  @ViewChild('serviceCurrencyTpl', {static: true}) serviceCurrencyTpl: TemplateRef<any>;
  @ViewChild('fileType', {static: true}) fileType: TemplateRef<any>;
  @ViewChild('fileSize', {static: true}) fileSize: TemplateRef<any>;
  @ViewChild('fileLink', {static: true}) fileLink: TemplateRef<any>;
  @ViewChild('idLinkTpl2Edit', {static: true}) idLinkTpl2Edit: TemplateRef<any>;
  

  // @ViewChild('customFieldHeaderTpl', {static: true}) customFieldHeaderTpl: TemplateRef<any>;

  constructor(private store: Store<appDataReducer.State>,
    private modalService: ModalService,
    private tenantCustomFieldService: TenantCustomFieldService,
    private _decimalPipe: DecimalPipe,
    private tenantDocumentManagementService: TenantDocumentManagementService,
    private activatedRoute: ActivatedRoute
    ) {
    super();
  }

  ngOnInit(){
    if(this.searchType === "SERVICE" ){
      this.numberOfStickyColumn = 3;
    }

    this.activatedRoute.queryParams
    .subscribe( queryParmas => {
      if(queryParmas['table_view']){
        this.table_view = queryParmas['table_view'];
      } else {
        this.table_view = '';
      }
    });

    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    tenantStateData.first()
      .subscribe(data => {
        this.tenantId = data.tenantData.domain;
        if(data && data.tenantData && data.tenantData.timezone) {
          this.timeZone =  data.tenantData.timezone;
        }
        if(this.hasCustomFields){
          this.getCustomFields();
        }
        else{
          if(this.searchType && this.searchType !== "NO_REDUX"){
            this._columns = this.retrieveViewableColumn(this.searchType);
            this.updateColumnsState();
          }
          this.loaded = true;
        }
      });

    if(this.searchType && this.searchType !== "NO_REDUX"){
      this.store.select(ASReducer.ASColumns)
      .takeUntil(this.destroy$)
      .subscribe(
        columns => {
          this._columns = this.changeColumnsLayout(columns);
        }
      ); 
    }
  }

  saveViewableColumn(searchType: string, tenantId:string, columns: any) {
    let currentColumns = this._columns.map(item => {
      let column : ASReducer.IColumn = {
        name:item.name,
        hidden: item.hidden? true : false,
        is_custom_data: false 
      }
      if(this.customHeaderName(item.name)){
        column.name = this.customFieldById[item.name];
        column.is_custom_data = true;
      }
      return column;
    })
    const valuetosave = JSON.stringify({[tenantId]: currentColumns});
    /// save to session with  searchType identifier
    window.localStorage.setItem(searchType, valuetosave);
  }
  
  retrieveViewableColumn(searchType: string) {
    if(!searchType){
      return this._columns;
    } else if(this.table_view) {
      return this.changeColumnsLayout(getTableView(this.searchType, this.table_view));
    }
    /// retrieve from session with searchType identifier
    const data = window.localStorage.getItem(searchType);
    if (data) {
      const viewableColumn = JSON.parse(data);
      // Use saved session when user has saved new filter structure in session persons.some(person => person.name === "Peter")
      if (viewableColumn && viewableColumn[this.tenantId]) {
        let storedColumn = viewableColumn[this.tenantId]
        if(searchType === "SERVICE" && this.numberOfStickyColumn===3 && (storedColumn[0].name !== "Parent Group" || storedColumn[2].name !== "MISO ID")){
          storedColumn = this._columns;
          this.saveViewableColumn(searchType, this.tenantId, this._columns);
        }
        return this.changeColumnsLayout(storedColumn);
      }
    }
    return this._columns;
  }

  changeColumnsLayout(newColumns){
    let result = [];
    newColumns.forEach((key) => {
      var found = false;
      this._columns = this._columns.filter((item) => {
          if(!found && (item.name == key.name || this.customFieldById[item.name] == key.name)) {
              item.hidden = key.hidden;
              result.push(item);
              found = true;
              return false;
          } else {
            return true;
          }
      })
    })
    return [...result, ...this._columns];
  }
  
  

  allowDrop(e) {
    e.stopPropagation();
    e.srcElement.classList.add('drag-over');
    e.preventDefault();
  }
  onDragout(e) {
    e.stopPropagation();
    e.srcElement.classList.remove('drag-over');
    e.preventDefault();
  }

  drag(e) {
    if(e.target && e.target.attributes &&  e.target.attributes["data-index"]){
      this.dragStart = e.target.attributes["data-index"].value;
    }
  }

  move(input, from, to) {
    let numberOfDeletedElm = 1;
    const elm = input.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;

    if(to < this.numberOfStickyColumn){
      to = this.numberOfStickyColumn;
    }
  
    input.splice(to, numberOfDeletedElm, elm);
  }

  drop(e) {
    e.preventDefault();
    e.srcElement.classList.remove('drag-over');
    if(e.target && e.target.attributes &&  e.target.attributes["data-index"]){
      this.move(this._columns, this.dragStart, e.target.attributes["data-index"].value);
      if(this.searchType){
        this.saveViewableColumn(this.searchType, this.tenantId, this._columns);
        this.updateColumnsState();
      }    
    }
  }

  hide(e){
    const columnIndex = e.target.parentElement.attributes["data-index"].value;
    this._columns[columnIndex].hidden = true;
  }

  updateColumnsState(){
    // let currentColumns: any = this._columns.filter(column => !column.hidden);
    let currentColumns = this._columns.map(item => {
      let column : ASReducer.IColumn = {
        name:item.name,
        hidden: item.hidden? true : false,
        is_custom_data: false
      }
      if(this.customHeaderName(item.name)){
        column.name = this.customFieldById[item.name];
        column.is_custom_data = true;
        if(this.table_view){
          column.hidden = true;
        }
      }
      return column;
    })
    this.store.dispatch( new AsSimpleStateUpdate({columns: currentColumns}))
  }

  addColumn(){

    const modalRef = this.modalService.open(SortableTableColumnSelectModalComponent, m => {
      m._columns = this._columns;
      m.customFieldById = this.customFieldById;
      m.searchType = this.searchType;
      m.numberOfStickyColumn = this.numberOfStickyColumn;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0] && result[0].columns){
        this._columns= result[0].columns;
        if(this.searchType){
          this.saveViewableColumn(this.searchType, this.tenantId, this._columns);
        }
      }

    });
  }

  convertTimeZone(time){
    return FeDateUtil.applyTimeZoneFromISO(time, this.timeZone)
  }

  @Input()
  public set variant(value: SortableTableVariant) {
    if (value === 'SMALL') {
      this._classes['mi-sort-table__small'] = true;
    }
  }

  @Input()
  public set rowSelectable(value: boolean) {
    this._classes['mi-sort-table__row-selectable'] = true;
    this._rowsSelectable = value;
  }

  @Input()
  public set columns(values: Array<SortableTableColumn>) {
    // loop through the passed data and resolve the templates

    const templatedColumns = values.map(item => {

      switch (item.cellTemplate) {
        case SortableTemplatesEnum.shortDate:
          item.cellTemplate = this.shortDateTpl;
          break;
        case SortableTemplatesEnum.shortDateTime:
          item.cellTemplate = this.shortDateTimeTpl;
          break;
        case SortableTemplatesEnum.shortISODateTime:
          item.cellTemplate = this.shortISODateTimeTpl;
          break;
        case SortableTemplatesEnum.idLink:
          item.cellTemplate = this.idLinkTpl;
          break;
        case SortableTemplatesEnum.location:
          item.cellTemplate = this.locationsTpl; 
          break;
        case SortableTemplatesEnum.idLink2:
          item.cellTemplate = this.idLinkTpl2;
          break;
        case SortableTemplatesEnum.idLink2Edit:
          item.cellTemplate = this.idLinkTpl2Edit;
          break;          
        case SortableTemplatesEnum.contact:
          item.cellTemplate = this.contactTpl;
          break;
        case SortableTemplatesEnum.trimmedText:
          item.cellTemplate = this.trimmedTextTpl;
          break;
        case SortableTemplatesEnum.currency:
          item.cellTemplate = this.currencyTpl;
          break;
        case SortableTemplatesEnum.roundedCurrency:
          item.cellTemplate = this.roundedCurrencyTpl;
          break;
        case SortableTemplatesEnum.tenantCurrency:
          item.cellTemplate = this.tenantCurrencyTpl;
          break;
        case SortableTemplatesEnum.serviceCurrency:
          item.cellTemplate = this.serviceCurrencyTpl;
          break;
        case SortableTemplatesEnum.truncate:
          item.cellTemplate = this.truncateTpl;
          break;
        case SortableTemplatesEnum.number:
          item.cellTemplate = this.numberTpl;
          break;
        case SortableTemplatesEnum.customField:
          item.cellTemplate = this.customFieldTpl;
          break;
        case SortableTemplatesEnum.status:
          item.cellTemplate = this.statusTmpl;
          break;
        case SortableTemplatesEnum.count:
          item.cellTemplate = this.countTmpl;
          break;
        case SortableTemplatesEnum.fileType:
          item.cellTemplate = this.fileType;
          break;
        case SortableTemplatesEnum.fileSize:
          item.cellTemplate = this.fileSize;
          break;
        case SortableTemplatesEnum.fileLink:
          item.cellTemplate = this.fileLink;
          break;
        default:
          if (!item.cellTemplate) {
            item.cellTemplate = this.defaultCellTpl;
          }
          break;
      }
      if (!item.headerTemplate) {
        item.headerTemplate = this.defaultHeaderTpl;
      }

      return item;

    });

    this._columns = templatedColumns;
  }

  @Input()
  public set rows(values: Array<any>) {
    this._rows = values;

    if (!this.hasExternalSortListener()) {
      this.internalDataSort();
    }
  }

  /**
   * Take a row and dig deep into its properties recursively by key name
   * @param row
   * @param {string} propertyName
   * @returns {string}
   * @example
   *  getData({test:{foo:5}}, 'test.foo');
   */

  public getData(row: any, propertyName: string): string {
    if (!propertyName) {
      return '';
    }
    if (propertyName === '...') {
      return row;
    }
    return propertyName.split('.').reduce(
      (prev: any, curr: string) => {
        let ret: any = prev[curr];

        if (!prev[curr] && !(prev[curr] === 0)) {
          // anything falsy other than the number zero,
          // is set to empty string
          ret = '';
        }
        return ret;
      },
      row);
  }

  public onChangeTable(data: any): void {
    this._columns.forEach((col: any) => {
      // Remove sorting from the other tables
      if (col.name !== data.column.name && col.sortable) {
        col.sort = '';
      }
    });
    if (!!this.hasExternalSortListener()) {
      this.sortChanged.emit(data);
    } else {
      // We want to sort internally
      this.internalDataSort();
    }
  }

  hasExternalSortListener(): boolean {
    return this.sortChanged !== this.originalSortChanged;
  }

  internalDataSort() {
    this._columns.forEach((col: any) => {
      if (col.sortable && col.sort) {
        this.sort(col);
      }
    });
  }

  sort(column) {
    this._rows.sort((rowA, rowB) => {
      let prop = column.prop;
      if(prop === 'locations_v2.0' || prop === 'locations_v2.1'){
        prop = column.prop + ".address.formatted_address"
      }
      const aValue = this.getData(rowA, prop);
      const bValue = this.getData(rowB, prop);

      if (aValue > bValue) {
        return column.sort === 'asc' ? 1 : -1;
      } else if (aValue < bValue) {
        return column.sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  onRowClick(value: any) {
    if(this._rowsSelectable) {
      this._selectedRow= value;
      this.rowSelected.emit(value);
    }
  }

  stringToNumber(value: any) {
    if (value) {
      value = ('' + value).replace(/[^0-9.-]/g, '');
    }
    return Number(value);
  }

  getCustomFields(){
    this.tenantCustomFieldService.listCustomFields()
    .toPromise()
    .then((customFields: CustomField[]) => {
      if(!customFields.length){
        this.updateColumnsState();
        this.loaded = true;
        return;
      }
      customFields.map(cf => this.customFieldById[cf._id] = cf.label);
      if(this.searchType){
        this._columns = this.retrieveViewableColumn(this.searchType);
        this.updateColumnsState();
      }
      this.loaded = true;
    })
    .catch(e => this.error = e);
  }

  getCfCellValue(columnName, customDatas){
    const customData = this.getCFData(columnName, customDatas);
    let cellValue = '';
    if(!customData || !customData.custom_field_type){
      return cellValue;
    }

    switch (customData.custom_field_type) {
      case 'CONTACT':
        if(customData.value && customData.value.length){
          cellValue = customData.value.map(contact => contact.full_name).join(', ')
        }
        break;
      case 'PHONE':
        if(customData.value && customData.value.display){
          cellValue = customData.value.display;
        }
        break;

      case 'NUMBER':
        if(customData.value && customData.value){
          cellValue = this._decimalPipe.transform(customData.value, '1.2-2');
        }
        break;
    
      default:
        cellValue = customData.value;
        break;
    }

    return cellValue;
  }

  getCFData(columnName, customDatas){
    return customDatas.find(cd => cd.custom_field.id===columnName);
  }

  customHeaderName(columnName){
    const thisCustomField = this.customFieldById[columnName];
    if(!thisCustomField){
      return '';
    }
    return true;
  }

  downloadDocument(id) {
    return this.tenantDocumentManagementService.download(id).subscribe((response) => {
      window.open(response.download_url, "_blank");
    }, (e) => {

    });
  }

  getV1FileUrl(row){
    //return `${settings.API_URL}/${row.tenant_id}/files?file_path=${row.s3_key.substr(1)}`;
  }

  shortenFileName(name) {
    if (name && name.length > 40) {
      return name.slice(0, 40) + '...';
    }
    return name;
  }
}
