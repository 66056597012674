<div class="mi-grid-small-first search-results">

  <div class="col-1">
    <mi-standard-tenant-search-filters-v1></mi-standard-tenant-search-filters-v1>
  </div>

  <div class="col-2">

    <mi-advanced-search-header>
    </mi-advanced-search-header>

    <div class="added-services">

      <div class="added-services--header">

        <h2>
          {{'PARENT_SERVICE_DETAILS.CURRENT_SERVICES' | translate:{count: parentService.services.length} | translateSelector: parentService.services.length}}
        </h2>

        <a routerLink="../details" class="done">
          {{'PARENT_SERVICE_DETAILS.DONE_EDITING' | translate}}
        </a>

      </div>

      <div class="service-list">

        <mi-parent-service-added-service
          *ngFor="let miService of parentService.services"
          [service]="miService"
          (deselected)="onDeselect($event)">
        </mi-parent-service-added-service>

        <div *ngIf="!loaded" class="loading">
          <mi-loading-animation></mi-loading-animation>
        </div>

      </div>

    </div>

    <mi-parent-service-search-results></mi-parent-service-search-results>

    <mi-advanced-search-load-more></mi-advanced-search-load-more>

  </div>
</div>
