import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {throwError} from 'rxjs';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class FileService {

  constructor(private http: HttpClient) {
  }

  getFile(uploadFile: any): any {
    return this.http
      .get(`${uploadFile.full_path}`)
      .toPromise()
      .catch(e => throwError(e));
  }

}
