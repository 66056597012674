export class MiModalService {

  private modals: any[] = [];

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    let modalToRemove = this.getModal(id);
    this.modals = this.modals.filter(modal =>{
      return modal !== modalToRemove;
    });
  }

  open(id: string) {
    let modal = this.getModal(id);
    if (modal) {
      modal.open();
    } else {
      console.error('Could not open modal', id);
    }
  }

  close(id: string) {
    let modal = this.getModal(id);
    if (modal) {
      modal.close();
    }else{
      console.error('Could not close modal', id);
    }
  }

  getModal(id:string){
    return this.modals.filter(modal => {
      return modal.id === id;
    })[0] || null;
  }
}
