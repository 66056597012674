import { PageAlertAddAction } from './../../state/page-alert/actions';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {createLogger, LOG_LEVELS} from '../../logger';

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  selector: 'mi-side-notice',
  templateUrl: './side-notice.component.html',
  styleUrls: ['./side-notice.component.scss']
})

export class SideNoticeComponent {
  @Input() noticeType: string = "";
}
