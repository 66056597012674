import { LocalDataSource } from 'ng2-smart-table';
import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { IEventTypeEnum, ITenantServiceTerm, ITenantVendorDetails, IVendorTermFormatted } from 'src/shared/models/Vendor';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { Store, select } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { TenantVendors } from 'src/libs/api/tenant/tenantVendors';
import { ViewVendorAccountEndOfTermComponent } from '../view-vendor-accounts-end-of-term/view-vendor-accounts-end-of-term.component';
import { ActionVendorAccountEndOfTermComponent } from '../delete-vendor-accounts-end-of-term/delete-vendor-accounts-end-of-term.component';

@Component({
    selector: 'vendor-renewal-term',
    templateUrl: './vendor-renewal-term.component.html',
})

export class VendorRenewalTermComponent extends AbstractPageComponent {

    title = `Vendors renewal term`;
    breadcrumbs = [
        {
            label: 'Dashboard',
            url: '/',
            internal: true
        },
        {
            label: this.title,
            url: './',
            internal: true
        }
    ];

    vendorDataSource: LocalDataSource = new LocalDataSource();
    vendorWithoutEOT: number

    selectedPageNumber: number = 1
    selectedVendor: any = null;
    isDataLoaded: boolean = false
    vendors: ITenantVendorDetails[];
    vendorsDropdown: any;
    tenantServiceTerms: ITenantServiceTerm[] = [];
    formattedVendorData: IVendorTermFormatted[] = [];
    vendorUpdateSuccessful: boolean = false
    settings = {
        pager: {
            display: true,
            perPage: 10
        },
        actions: {
            add: false,
            delete: false,
            edit: false,
            position: 'right',
        },
        rowClassFunction: (row) => 'cursor-pointer',
        noDataMessage: 'Loading vendors...',
        columns: {}
    };
    tenantData: any = null;

    constructor(
        public router: Router,
        private serviceTermService: ServiceTermService,
        private store: Store<any>,
        private tenantVendorService: TenantVendors
    ) {
        super();
        this.store.pipe(
            select(appDataReducer.getAppData),
            takeUntil(this.destroy$),
            map((appData) => {
                this.tenantData = appData.tenantData;
                this.getTenantVendors()
            })
        ).subscribe();
        this.vendorDataSource = new LocalDataSource([]);
    }

    getTenantServiceTerm() {
        this.serviceTermService
            .getTenantRetermListService(this.tenantData.domain)
            .toPromise()
            .then((data: any) => {
                this.tenantServiceTerms = data
                this.getFormattedVendors()
            })
            .catch((e) => console.warn(e));
    }

    getTenantVendors() {
        this.isDataLoaded = false
        this.tenantVendorService
            .searchTenantVendorsWithActiveServices("", 0, 1000, this.tenantData.domain, "ASC", "displayId", true, "cmrc")
            .toPromise()
            .then((data) => {
                this.vendors = data.data
                if (this.tenantServiceTerms.length > 0) {
                    this.getFormattedVendors()
                } else {
                    this.getTenantServiceTerm()
                }
            })
            .catch((e) => console.warn(e));
    }

    getFormattedVendors() {
        this.formattedVendorData = this.vendors.map((vendor: ITenantVendorDetails) => {
            return {
                vendor_id: vendor.id,
                vendor_name: vendor.vendor.vendor_name,
                vendor_dsp_id: vendor.display_id,
                vendorTerm: vendor,
                tenantServiceTerms: this.tenantServiceTerms,
                account_qty: vendor.accountid_display_ids.length,
                highlightRow: this.selectedVendor !== null && this.selectedVendor.vendor_id === vendor.id ? true : false
            }
        });

        // Filter elements with null service_term_event_type
        const vendorWithoutEOT = this.formattedVendorData.filter(element => element.vendorTerm && element.vendorTerm.service_term_event_type === null);
        this.vendorWithoutEOT = vendorWithoutEOT.length
        this.initializeTable()
        this.vendorDataSource = new LocalDataSource(this.formattedVendorData);
        setTimeout(() => this.vendorDataSource.setPage(this.selectedPageNumber), 50);
    }


    initializeTable() {

        const vendors = this.formattedVendorData.map((vendor: any) => {
            return {
                value: vendor.vendor_name,
                title: vendor.vendor_name
            }
        })

        const allVendors: any[] = Object.values(vendors.reduce((acc, obj) => {
            if (obj.hasOwnProperty('value') && obj.value !== undefined && obj.value !== '' && /[A-Z]/.test(obj.value)) {
                acc[obj.value] = obj;
            }
            return acc;
        }, {})
        );

        this.vendorsDropdown = allVendors.slice().sort((a, b) => {
            if (a.value.toUpperCase() < b.value.toUpperCase()) {
                return -1;
            }
            if (a.value.toUpperCase() > b.value.toUpperCase()) {
                return 1;
            }
            return 0;
        });


        this.isDataLoaded = true
        this.settings = {
            pager: {
                display: true,
                perPage: 10
            },
            actions: {
                add: false,
                delete: false,
                edit: false,
                position: 'right',
            },
            rowClassFunction: (row) => 'cursor-pointer',
            noDataMessage: 'No Renewal Terms (Vendor Tier) found with respect to search filters',
            columns: {
                vendor_name: {
                    title: 'Vendor',
                    sortDirection: 'asc',
                    editable: false,
                    width: '25%',
                    filter: {
                        type: 'list',
                        config: {
                            selectText: this.vendorsDropdown.length > 0 ? 'All Vendors' : 'No Vendors',
                            list: this.vendorsDropdown,
                        },
                    },
                },
                vendor_dsp_id: {
                    title: 'Vendor ID',
                    editable: false,
                    filter: true,
                    width: '10%'
                },
                vendorTerm: {
                    title: 'End of Term Action',
                    editable: false,
                    filter: false,
                    type: 'html',
                    valuePrepareFunction: (cell: any, row: any) => this.getFormattedEOT(row.vendorTerm.service_term_event_type, row.vendorTerm.auto_renew_length, row.vendorTerm.days_to_notify),
                    width: '40%',
                    sortable: false,
                    class: 'disable-sorting',
                },
                actionVendorAccountEndOfTermComponent: {
                    title: 'Action',
                    editable: false,
                    filter: false,
                    type: 'custom',
                    renderComponent: ActionVendorAccountEndOfTermComponent,
                    onComponentInitFunction: (instance) => {
                        instance.customEvent.subscribe(() => {
                            this.getTenantVendors()
                            console.log("here rowData", instance.rowData)
                            this.selectedVendor = instance.rowData
                        });
                    },
                    width: '6%',
                    sortable: false,
                    class: 'disable-sorting',
                },
                vendorAccountComponent: {
                    title: 'Accounts',
                    editable: false,
                    filter: false,
                    type: 'custom',
                    renderComponent: ViewVendorAccountEndOfTermComponent,
                    width: '10%',
                    sortable: false,
                    class: 'disable-sorting',
                },
            }
        };
    }

    getFormattedEOT(EOTType: IEventTypeEnum, autoRenewalLength: number, daysToNotify: string) {
        switch (EOTType) {
            case IEventTypeEnum.EVENT_TYPE_AUTO_RENEW:
                return "Auto-Renew (" + autoRenewalLength + " Month" + (autoRenewalLength > 1 ? "'s" : "") + " " + daysToNotify + " Days Advance Notification)";
            case IEventTypeEnum.EVENT_TYPE_AUTO_CANCELLATION:
                return "Cancel " + "(" + daysToNotify + " Days Advance Notification" + ")";
            default:
                return "-";
        }
    }

}
