<mi-page-header
  title="{{ 'TENANT.PRODUCT_DETAIL_TITLE' | translate:{display_id: display_id} }}"
  [breadcrumbs]="breadcrumbs"
  *ngIf="product">
  <ng-container>
    <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
  </ng-container>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<div class="details-header-nav">
  <div class="details-header-nav__graphic">
    <div class="details-header-nav__graphic-container" *ngIf="vendor">
      <ng-container *ngIf="vendor.logo && vendor.logo.full_path; else fallback">
        <img class="vendor-logo" [src]="vendor.logo.full_path"/>
      </ng-container>
      <ng-template #fallback>
        <img class="vendor-logo fallback" src="shared/assets/images/vendor-logo-placeholder.svg"/>
      </ng-template>
    </div>
  </div>

  <div class="details-header-nav__info">

    <mi-details-header-nav
      [data]="detailHeaderNavData"
      [showEdit]="true">
    </mi-details-header-nav>

  </div>

</div>

<router-outlet></router-outlet>
