import {Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges, OnChanges} from "@angular/core";
import {EmailFormRecipient} from "../../../../shared/ng-models/EmailFormRecipient.interface";

export interface SearchableRecipient{
  email_recipient:EmailFormRecipient;
  label?:string;
  disabled?:boolean;
};

@Component({
  selector: 'mi-email-recipient-search',
  styleUrls: ['./email-recipient-search.component.scss',
    '../email-recipient/email-recipient.component.scss',
    '../email-recipient-select/email-recipient-select.component.scss'],
  templateUrl: './email-recipient-search.component.html'
})

export class EmailRecipientSearchComponent implements OnInit, OnChanges {

  @Input() vendorName : string;
  @Input() vendorRecipients: EmailFormRecipient[] = [];
  @Input() otherRecipients: EmailFormRecipient[] = [];
  @Input() internalRecipients: EmailFormRecipient[] = [];
  recipients:SearchableRecipient[] = [];
  @ViewChild ('recipientSelect', {static: true}) recipientSelect;
  @Output() recipientSelected: EventEmitter<EmailFormRecipient> = new EventEmitter<EmailFormRecipient>();

  constructor() {

  }
 
  ngOnInit():void{
    this.sortRecipients();
  }
  
  sortRecipients(){
    if(this.vendorRecipients && this.vendorRecipients.length){
      this.recipients.push({
        email_recipient: null,
        label: `---${this.vendorName}---`,
        disabled: true
      })
      this.vendorRecipients.map((emailRecipient:EmailFormRecipient)=>{
        this.recipients.push({
          email_recipient: emailRecipient,
          label: `${emailRecipient.first_name} ${emailRecipient.last_name} | ${emailRecipient.email}`
        })
      })
    }
    if(this.internalRecipients && this.internalRecipients.length){
      this.recipients.push({
        email_recipient: null,
        label: `---Internal---`,
        disabled: true
      })
      this.internalRecipients.map((emailRecipient:EmailFormRecipient)=>{
        this.recipients.push({
          email_recipient: emailRecipient,
          label: `${emailRecipient.first_name} ${emailRecipient.last_name} | ${emailRecipient.email}`
        })
      })
    }
    if(this.otherRecipients && this.otherRecipients.length){
      this.recipients.push({
        email_recipient: null,
        label: `---Others---`,
        disabled: true
      })
      this.otherRecipients.map((emailRecipient:EmailFormRecipient)=>{
        this.recipients.push({
          email_recipient: emailRecipient,
          label: `${emailRecipient.first_name} ${emailRecipient.last_name} | ${emailRecipient.email}`
        })
      }) 
    }
  }
  ngOnChanges(changes: SimpleChanges){
    // Listens for changes to the inputs; looking for changes to the index.
    // Only fired once the page is loaded.
    if (changes) {
      this.recipients = [];
      this.sortRecipients();
    }
  }

  onRecipientSelected(e:SearchableRecipient):void{
    if(e && e.email_recipient!=null){
      this.recipientSelected.emit(e.email_recipient);
      this.recipients = this.recipients.filter((recipient:SearchableRecipient)=>{
        return recipient !== e;
      });
      this.recipientSelect.clearItem(e);
    }
  }

  recipientSearch(term: string, item: SearchableRecipient):boolean {
    term = term.toLocaleLowerCase();
    return item.label.toLocaleLowerCase().indexOf(term) > -1;
  }

}
