<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <div class="half-col">
      <mi-select-service-ownership 
        (outPutUser)="onAssignedEmail($event)"
        (outPutBusinessUnit)="onBusinessUnit($event)">
      </mi-select-service-ownership>

      <div class="button-container">
        <button (click)="onFormSubmit(assignedForm.value)"
                mi-button
                buttonTheme="dark-theme"
                [disabled]="!assignedForm.valid">
          Next
        </button>
      </div>

    </div>

  </ng-container>

  <ng-template #static>
    <dl>
      <div class="service-ownership-summery">
        <div *ngIf="division">
          <dt>Division</dt>
          <dd>{{division}}</dd>
        </div>
        <div *ngIf="team">
          <dt>Team</dt>
          <dd>{{team}}</dd>
        </div>
        <dt>Assigned To</dt>
        <dd>{{assignee}}</dd>
      </div> 
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


