<div class="reverse-table-container">
  <table id="reverse-table-main" class="reverse-table-main">
    <div class="reverse-table-section" *ngFor="let section of columns">
      <div class="reverse-table-row" *ngFor="let column of section.data">
        <tr>
          <td class="reverse-table-td column-td">
            <div>
              {{column.value}}
            </div>
          </td>
          <td class="reverse-table-td" *ngFor="let row of rows" [ngClass]="{'color_red': columnRows[column.id][row.id].color_red, 'color_apple_green': columnRows[column.id][row.id].color_apple_green, 'color_mid_blue': columnRows[column.id][row.id].color_mid_blue, 'color_orange': columnRows[column.id][row.id].color_orange, 'color_purple': columnRows[column.id][row.id].color_purple}">
            <div class="data-cell">
              {{columnRows[column.id][row.id].value}}
            </div>
          </td>
        </tr>
      </div>
    </div>
  </table>
</div>