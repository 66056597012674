import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CanDelete} from '../../../shared/models/CanDelete';
import { AccountId } from 'src/shared/models/AccountId';

@Injectable()
export class TenantAccountIdService {
  private apiUrl = '_MICRO_SERVICE_/accountid';
  private apiUrlGetByDisplayId = '/{tenant}/account-ids/by-display-id/';
  private apiUrlUpdateAccountId = '/{tenant}/account-ids/';
  private appURLGetByVendor = '/{tenant}/account-ids/by-vendor-id/';
  private apiUrlCrud = '/{tenant}/account-ids/';
  private apiUrlVendorAccountDetails = '_MICRO_SERVICE_/accountid/tenant-vendor-accountid';

  constructor(private http: HttpClient) {}

  createAccountId(accountId: AccountId): Observable<AccountId> {
    return this.http
      .post(`${this.apiUrl}/accountid`, accountId)
      .map((response: any) => response);
      /*
      .switchMap((response: any) => {
        return this.indexAccountId(response);
      });
      */
  }

  getAccountIdDetails(display_id: string,tenant_vendor_id: string): Observable<any> {
    return this.http
    .get(`${this.apiUrlVendorAccountDetails}?tenant_vendor_id=${tenant_vendor_id}&q=${display_id}&sort=displayId&order=ASC`)
      .map((response: any) => response);
  }

  getVendorAccountsListByTenantVendorIdService(tenant_vendor_id: string, size: number = 10, pageNumber: number = 0, excludeProps?: string) {
    return this.http
    .get<any>(`${this.apiUrlVendorAccountDetails}?tenant_vendor_id=${tenant_vendor_id}&q=&page_number=${pageNumber}&size=${size}&order=ASC&sort=displayId&exclude_props=${excludeProps}&expand_users=true`)
    .map((response: any) => response);
  }

  getByDisplayId(display_id: string): Observable<AccountId> {
    return this.http
      .get(`${this.apiUrlGetByDisplayId}${display_id}`)
      .map((response: any) => response.data.account_id as AccountId);
  }

  getById(id: string): Observable<AccountId> {
    return this.http
      .get(`${this.apiUrl}/accountid?id=${id}&tenant_id=_TENANT_ID_`)
      .map((response: any) => response as AccountId);
  }

  getByVendorId(vendor_id: string): Observable<Array<AccountId>> {
    return this.http
      .get(`${this.appURLGetByVendor}${vendor_id}`)
      .map((response: any) => response.data.account_ids);
  }

  getByTenantId(): Observable<Array<AccountId>> {
    return this.http
      .get(`${this.apiUrl}/accountids?owner=_TENANT_ID_`)
      .map((response: any) => response);
  }

  update(accountId: AccountId, id: String): Observable<AccountId> {
    console.log("here in API accountId", accountId)
    console.log("here in API id", id)
    const updateAccountId = {
      ...accountId,
      id
    }
    return this.http
      .post(`${this.apiUrl}/accountid`, updateAccountId)
      .map((response: any) => response);
      /*
      .switchMap((response: any) => {
        return this.indexAccountId(response);
      });
      */
      // .map((response: any) => response.data.account_id as AccountId);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlUpdateAccountId}${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteAccountId(id: String): Observable<AccountId> {
    return this.http
      .post(`${this.apiUrl}/delete`, [id])
      .switchMap((response: any) => {
        if(response.status == "Success") {
          return this.deleteIndexAccountId(id);
        }
      });

      // .map((response: any) => response.data.accountId as AccountId);
  }
/////
  indexAccountId(accountId: AccountId) {
    return this.http
      //.put(this.apiUrlCrud + accountId.id + '/index', {tenant_vendor_id: accountId.tenant_vendor_id})
      .put(this.apiUrlCrud + accountId.id + '/index', {vendor_id: accountId.vendor_id})
      .map((response) => accountId);
  }

  deleteIndexAccountId(accountId: String) {
    return this.http
      .delete(this.apiUrlCrud + accountId + '/index', {})
      .map((response) => response);
  }
}