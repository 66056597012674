<mi-page-header
  title="{{ 'TENANT.MISERVICE_CREATE_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<div class="service-columns">
  <div class="service-column">
    <h2>Single Service</h2>
    <div class="service-image">
      <a routerLink="/services/single/create">
        <img src="/shared/assets/images/service-create/single-service.png" alt="" />
      </a>
    </div>
    <ul>
      <li>Good for adding one service at a time.</li>
      <li>Easy step-by-step instructions.</li>
      <li>Good for when you are missing data.</li>
    </ul>
  </div>
  <div class="service-column">
    <h2>Multi-Service Order</h2>
    <div class="service-image">
      <a routerLink="/services/orders/create">
        <img src="/shared/assets/images/service-create/multi-service.png" alt=""  />
      </a>
    </div>
    <ul>
      <li>Good for adding multiple services at a time.</li>
      <li>Good for when you have an Order ID in front of you.</li>
    </ul>
  </div>
  <div class="service-column">
    <h2>Import File or Integrate API</h2>
    <div class="service-image">
      <a routerLink="/services/file-upload/create">
        <img src="/shared/assets/images/service-create/upload-service.png" alt=""  />
      </a>
    </div>
    <ul>
      <li>Upload an invoice or order for automatic import.</li>
      <li>Or integrate directly to an API endpoint.</li>
      <li>Receive direct communication and help from a MISO Account Specialist.</li>
    </ul>
  </div>
</div>
<div class="service-columns centered">
  <div class="service-column">
    <a
      class="servie-link"
      mi-button
      buttonTheme="dark-theme"
      routerLink="/services/single/create">
      Add Service
    </a>
  </div>
  <div class="service-column">
    <a
      class="servie-link"
      mi-button
      buttonTheme="dark-theme"
      routerLink="/services/orders/create">
      Add Order
    </a>
  </div>
  <div class="service-column">
    <a
      class="servie-link"
      mi-button
      buttonTheme="dark-theme"
      routerLink="/data-import">
      Upload File or Connect
    </a>
  </div>
</div>
