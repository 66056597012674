<div class="filter-group" *ngIf="isVisible"> 
  <h2>{{title}}</h2>


  <form *ngIf="form !== null" [(formGroup)]="form" (ngSubmit)="onFormSubmit(form.value)">

    <div class="clear-fix">
      <div>
        <label for="{{minname}}">{{min_label}}</label>
        <mi-date-picker class="min-date" [currentDate]="min_prefill"
        (dateChange)="onDateChangeMin($event)"></mi-date-picker>
      </div>

      <div>
        <label for="{{maxname}}">{{max_label}}</label>
        <mi-date-picker class="max-date" [currentDate]="max_prefill"
        (dateChange)="onDateChangeMax($event)"></mi-date-picker>
      </div>
    </div>

    <div *ngIf="!form.valid"
         class="mi-alert mi-alert--error"
         style="margin: 10px 0 0;">
         {{min_label}} should be less than {{max_label}}.
    </div>

    <div class="button-container">

      <button mi-button
              type="submit"
              [disabled]="!form.valid">
        {{filter_button_label}}
      </button>
    </div>

  </form>

</div>