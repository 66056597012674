import {SectionDataTable} from './SectionDataTable.interface';
import {SectionDataTableColumn} from './SectionDataColumn.interface';
import {SortableTemplatesEnum} from './SortableTableColumn.interface';

const contactsTableColumns: SectionDataTableColumn[] = [
  {
    name: 'Status',
    prop: 'status',
    sortable: true
  },
  {
    name: 'Name',
    prop: 'full_name',
    sortable: true
  },
  {
    name: 'Company',
    prop: 'company',
    sortable: true
  },
  {
    name: 'Office Phone',
    prop: 'office_phone',
    sortable: true
  },
  {
    name: 'Mobile Phone',
    prop: 'mobile_phone',
    sortable: true
  },
  {
    name: 'Fax',
    prop: 'fax',
    sortable: true
  },
  {
    name: 'Email',
    prop: 'email',
    sortable: true
  },
  {
    name: 'Role',
    prop: 'role',
    sortable: true
  }
];
const contactsListingTableColumns: SectionDataTableColumn[] = [
  {
    name: "MISO ID",
    prop:'display_id',
    cellTemplate: SortableTemplatesEnum.idLink2,
    sortable: false
  },
  {
    name: "Name",
    prop: 'name',
    sortable: true
  },
  {
    name: 'Company',
    prop: 'company',
    sortable: true
  },
  {
    name: 'Office Phone',
    prop: 'office_phone',
    sortable: true
  },
  {
    name: 'Mobile Phone',
    prop: 'mobile_phone',
    sortable: true
  },
  {
    name: 'Email',
    prop: 'email',
    sortable: true
  },
  {
    name: 'Role',
    prop: 'role',
    sortable: true
  },
  {
    name: 'Status',
    prop: 'status',
    sortable: true
  }

];
const productsTableColumns: SectionDataTableColumn[] = [
  {
    name: "MISO ID",
    prop:'display_id',
    cellTemplate: SortableTemplatesEnum.idLink,
    sortable: true
  },
  {
    name: 'Product',
    prop: 'product_description',
    sortable: true
  }
];
const termsAndConditionsColumns: SectionDataTableColumn[] = [
  {
    name: "MISO ID",
    prop:'display_id',
    cellTemplate: SortableTemplatesEnum.idLink,
    sortable: true
  },
  {
    name: 'Name of Saved Agreement',
    prop: 'name',
    sortable: true
  },
  {
    name: 'Date Updated',
    prop: 'date_updated',
    sortable: true
  },
  {
    name: 'End of Action',
    prop: 'end_of_action',
    sortable: true
  },
  {
    name: 'Auto-Renew Period',
    prop: 'auto_renew_period',
    sortable: true
  },
  {
    name: 'Days to Notify',
    prop: 'days_to_nofify',
    sortable: true
  }
];
export const assignedCustomerIdentifiersColumns: SectionDataTableColumn[] = [
  {
    name: "MISO ID",
    prop:'display_id',
    cellTemplate: SortableTemplatesEnum.idLink,
    sortable: false
  },
  {
    name: 'Account ID',
    prop: 'label',
    sortable: true
  },
  {
    name: 'Account Representative',
    prop: 'contact',
    cellTemplate: SortableTemplatesEnum.contact,
    sortable: false
  },
  {
    name: 'End of Term Actions',
    prop: 'service_term',
    sortable: false
  },
];
const attachmentColumns: SectionDataTableColumn[] = [
  {
    name: "MISO ID",
    prop:'display_id',
    cellTemplate: SortableTemplatesEnum.idLink2,
    sortable: false
  },
  {
    name: 'Document Name',
    prop: 'document_name',
    sortable: true
  },
  {
    name: 'File Name',
    prop: 'file_name',
    sortable: true
  },
  {
    name: 'File Type',
    prop: 'file_type',
    sortable: true
  },
  {
    name: 'Vendor',
    prop: 'vendor_id',
    sortable: true
  },
  {
    name: 'File Size',
    prop: 'file_size',
    sortable: true
  },
  {
    name: 'File Uploaded',
    prop: 'created_at',
    sortable: true
  },
  {
    name: 'Uploaded By',
    prop: 'created_by',
    sortable: true
  },
  {
    name: 'Division/Team',
    prop: 'business_unit_name',
    sortable: true
  },
  {
    name: 'Invoice Date',
    prop: 'invoice_date',
    sortable: true
  },
  {
    name: 'Invoice Number',
    prop: 'invoice_number',
    sortable: true
  },
  {
    name: 'Invoice Value',
    prop: 'invoice_value',
    cellTemplate: SortableTemplatesEnum.currency,
    sortable: true
  },
  {
    name: 'Account Number',
    prop: 'account_number',
    sortable: true
  }
];
const currencyColumns: SectionDataTableColumn[] = [
  {
    name: "Currency",
    prop:'code',
    sortable: false
  },
  {
    name: 'Conversion Rate',
    prop: 'conversion_rate',
    sortable: true
  }
];
const locationsTableColumns: SectionDataTableColumn[] = [
  /*{
    name: 'MISO ID',
    prop: 'display_id',
    cellTemplate: 'locationLink',F
    sortable: false
  },*/
  {
    name: 'Location',
    prop: 'name',
    cellTemplate: 'locationLink',
    sortable: true
  },
  {
    name: 'Nickname',
    prop: 'nick_name',
    sortable: true
  },
  {
    name: 'Vendor Count',
    prop: 'vendor_count',
    sortable: true
  },
  {
    name: 'Service Count',
    prop: 'service_count',
    sortable: true
  },
  {
    name: 'Status',
    prop: 'status',
    sortable: true,
    cellTemplate: 'statusTmpl'
  },
  {
    name: 'CMRC',
    prop: 'total_mrc',
    sortable: true,
    className: 'right',
    cellTemplate: SortableTemplatesEnum.tenantCurrency
  }
];

const addressesTableColumns: SectionDataTableColumn[] = [
  /*{
    name: "MISO ID",
    prop:'display_id',
    cellTemplate: SortableTemplatesEnum.idLink,
    sortable: false
  },*/
  {
    name: 'Address',
    prop: 'formatted_address',
    cellTemplate: 'addressLink',
    sortable: true
  },
  {
    name: 'Locations',
    prop: 'location_count',
    sortable: true
  },
  {
    name: 'Vendors',
    prop: 'vendor_count',
    sortable: true
  },
  {
    name: 'Services',
    prop: 'service_count',
    sortable: true
  },
  {
    name: 'Total cMRC',
    prop: 'total_mrc',
    sortable: true,
    className: 'right',
    cellTemplate: SortableTemplatesEnum.tenantCurrency
  }
];


// Contacts
export var contactsTableData: SectionDataTable = {
  columns: contactsTableColumns,
  rows: [],
  loaded: false
};
export var contactsListingTableData: SectionDataTable = {
  columns: contactsListingTableColumns,
  rows: [],
  loaded: false
};

// Products
export var productsTableData: SectionDataTable = {
  columns: productsTableColumns,
  rows: [],
  loaded: false
};

// Terms & Conditions
export var termsAndConditionsTableData: SectionDataTable = {
  columns: termsAndConditionsColumns,
  rows: [],
  loaded: false
};

// Assigned WhatNot
export var assignedCustomerIdentifiersTableData: SectionDataTable = {
  columns: assignedCustomerIdentifiersColumns,
  rows: [],
  loaded: false
};

// Attachments:
export var attachmentsTableData: SectionDataTable = {
  columns: attachmentColumns,
  rows: [],
  loaded: false
};

// currency:
export var currencyTableData: SectionDataTable = {
  columns: currencyColumns,
  rows: [],
  loaded: false
};

// Locations
export var locationsTableData: SectionDataTable = {
  columns: locationsTableColumns,
  rows: [],
  loaded: false
};

// Addresses
export var addressesTableData: SectionDataTable = {
  columns: addressesTableColumns,
  rows: [],
  loaded: false
};

export const NO_DATA: string = '---';
