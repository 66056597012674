import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Vendor} from '../../../../../shared/models/Vendor';
import {Link} from '../../../../../shared/ng-models/Link.interface';
import {Product} from '../../../../../shared/models/Product';
import * as pageAlertReducer from '../../../../../shared/state/page-alert/reducers';
import {Store} from '@ngrx/store';
import { ProductServicesModes } from '../../../../../shared/models/ProductServices.interface';
import { TenantVendors } from '../../../../../libs/api/tenant/tenantVendors';
import { TenantProductsService } from '../../../../../libs/api/tenant/tenantProducts';
import linkFormatter from 'src/common/utils/linkFormatter';

@Component({
  templateUrl: './product-edit.component.html'
})

export class ProductEditComponent extends AbstractPageComponent implements OnInit {

  backLink: string = '';
  breadcrumbs: Link[];
  error: string = '';
  supportLink: string = linkFormatter.getSupportLink();
  vendor: Vendor;
  product: Product;
  display_id: string;
  loaded: boolean = false;

  tenantProductServicesMode: ProductServicesModes = ProductServicesModes.TENANT;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantProductService: TenantProductsService,
              private tenantVendorsService: TenantVendors,
              private router: Router,
              private store: Store<pageAlertReducer.State>) {
    super();
  }

  ngOnInit() {

   this.display_id = this.activatedRoute.parent.snapshot.paramMap.get('display_id');

    this.backLink = '../details';
    this.activatedRoute.params
      .takeUntil(this.destroy$)
      .switchMap(params => this.tenantProductService.getProductByDisplayId(this.display_id))
      .subscribe((product: Product) => {

        this.tenantVendorsService.getVendorBytenantVendorId(product.tenant_vendor_id).
          then((tenantVendor) => {
            this.vendor = {
              ...tenantVendor,
            };
           // this.product.product_vendor = {
           //   ...tenantVendor
           // }

            this.product = {
              ...product,
              product_vendor: {
                ...tenantVendor
              }
            };
            this.loaded = true;
          });
      });
  }

  onCanceled() {
    this.router.navigate([this.backLink], {relativeTo: this.activatedRoute});
  }

  onProductAdded(product: Product): void {
    console.log("here in onProductAdded product", product)
    console.log("here in onProductAdded backLink", this.backLink)
    this.router.navigate([`/vendors/${product.tenant_vendor_display_id}/products`]);

    // this.router.navigate([this.backLink], {
    //   queryParams:{update: new Date().getTime()},
    //   relativeTo: this.activatedRoute
    // });
  }

}
