import {Component, Input, OnInit} from '@angular/core';


import {combineLatest} from "rxjs";
import * as ASActions from '../../../../shared/state/advanced-search/actions';

import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {TenantUsersService} from "../../../../libs/api/tenant/tenantUsers.service";
import {createLogger, LOG_LEVELS} from "../../../logger";

const log = createLogger(LOG_LEVELS.COMPONENT);


@Component({
  selector: 'mi-advanced-search-sort-component',
  templateUrl: './advanced-search-sort.component.html',
  styleUrls: ['./advanced-search-sort.component.scss']
})

export class AdvancedSearchSortComponent extends AbstractPageComponent implements OnInit{

  title: string = 'Sort';
  options: string[] = [];
  selected: string = '';

  constructor(
    private store: Store<ASReducer.State>,
    private tenantUsersService: TenantUsersService) {
    super();
  }

  ngOnInit() {

    const asDataData = this.store.select(ASReducer.getASData).takeUntil(this.destroy$);

    combineLatest(asDataData)
      .subscribe(([asData]) => {

        // sort options are stored on the store
        this.options = asData.sortOptions;
        // selected sort defaults to the first sortOption
        let newSelected = asData.sortOptions[0];

        if (asData.resultsLoaded && asData.results.meta.params.sort) {
          // else if its passed a parameter use that one.
          newSelected = asData.results.meta.params.sort;
        }
        this.selected = newSelected;
      });

  }

  toggle(evt) {
    if (evt.srcElement.value) {
      const sort = evt.srcElement.value;
      this.store.dispatch(new ASActions.AsFilterUpdate({sort}))
    }
  }
}
