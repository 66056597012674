import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Tenant} from '../../../shared/models/Tenant';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantService {

  private apiUrlCrud = '/tenant/';
  private apiUrlFetchAll = '/tenants/';
  private apiUrlGetPaymentPlan = '/get-checkout-link';
  private apiUrlCurrentTenant = '/account/tenants/{tenant}/';

  constructor(private http: HttpClient) {
  }

  getTenants(): Promise<Tenant[]> {
    return this.http
      .get<Tenant[]>(this.apiUrlFetchAll)
      .toPromise();
  }

  getCurrentTenant(): Promise<Tenant> {
    return this.http
      .get<Tenant>(this.apiUrlCurrentTenant)
      .toPromise()
      .then((response: any) => response.data.tenant);
  }

  create(domain: Tenant): Promise<Tenant> {
    return this.http
      .put<Tenant>(this.apiUrlCrud + domain.domain, domain)
      .toPromise()
      .then((response: any) => {
        const json: any = response.data;
        if (json.error) {
          throw new Error(json.error);
        } else {
          return json.tenant;
        }
      });
  }

  getTenantCheckoutUrl(domain: Tenant): Promise<string> {
    return this.http
      .get<string>(`/${domain.domain}` + this.apiUrlGetPaymentPlan)
      .toPromise()
      .then((response: any) => response.data.checkout_link);
  }

  edit(domain: Tenant): Promise<Tenant> {
    return this.http
      .post<Tenant>(this.apiUrlCrud + domain.domain, domain)
      .toPromise()
      .then((response: any) => response.data.tenant);
  }

}

