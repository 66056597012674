import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import * as appDataReducer from '../../../shared/state/app-data/reducers';

import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import { Observable, combineLatest } from "rxjs";
import { TenantReportsService } from "../../../libs/api/tenant/tenantReports.service";
import {environment} from '../../../environments/environment';
import {removeNonUrlSearchParams} from "../../../shared/utils/unsafeUrlProperties";
import { ModalService } from '@independer/ng-modal';
import { UploadV2Service } from 'src/libs/api2/upload-v2/upload-v2.service';
import { ExcelEmailNotificationModalComponent } from '../../../shared/components/excel-email-notification-modal/excel-email-notification-modal.component';
import { takeUntil } from 'rxjs/operators';
import { ServiceDownloadModalComponent } from '../service-download-modal/service-download-modal.component';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';

@Component({
  selector: 'mi-excel-with-filter-button',
  styleUrls: ['./excel-button.component.scss'],
  templateUrl: './excel-button.component.html'
})

export class DownloadExcelButtonWithFilterComponent extends AbstractPageComponent implements OnInit {
  @Input() theme: string = 'dark-theme';
  @Input() buttonLabel: string = "Download";
  @Input() externalTrigger: boolean = false;

  loaded = false;
  resultsLoaded$: Observable<boolean>;
  hasResults: boolean = false;
  searchType: string;
  error: string;
  queryString: string;
  url: string;
  owner: string;
  readableFilter: string;
  parmas: any;
  tenantId: string;

  constructor(
    private store: Store<ASReducer.State>,
    private modalService: ModalService,
    private reportV2Service: ReportV2Service,
    private uploadV2Service:UploadV2Service
  ) {
    super();
  }


  ngOnInit(): void { 
    this.resultsLoaded$ = this.store.select(ASReducer.ASResultsLoaded);

    combineLatest(
        this.store.select(appDataReducer.tenantDomain),
        this.store.select(ASReducer.getASData)
      )
      .takeUntil(this.destroy$)
      .subscribe(([domain, data]) => {
        this.tenantId = domain;
        this.url = "";
        this.hasResults = false;
        this.searchType = data.searchType;
        this.readableFilter = data.readableFilter;

        if (data.results) {
          this.owner = data.results.meta.params['owner'];
          this.parmas = data.results.meta.params;
          this.buildQueryString(data.results.meta.params);
          this.hasResults = data.results.total.value > 0;
        }
      });
  }

  buildQueryString(rawParams) {

    const params = removeNonUrlSearchParams(rawParams);
    let query = "q=" + encodeURIComponent(params["q"] || "");
    for (let filter in params) {
      if (params[filter] ) {
        if (filter !== "q") {
          if (Array.isArray(params[filter])) {
            params[filter].map(data => {
              query += `&${filter}[]=${encodeURIComponent(data)}`;
            });
          } else {
            query += `&${filter}=${encodeURIComponent(params[filter])}`;
          }
        }
      }
    }

    query = `${query}&readableFilters=${encodeURIComponent(this.readableFilter)}`;
    this.queryString = query;
  }

  getDownloadStatus(){
    this.reportV2Service.getDownloadStatus(this.tenantId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if(res.download_status === 'pending'){
          const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
            m.title = "Download In Progress";
            m.message = "Your earlier request for Services Inventory is still in progress. Please check your email in a few minutes for the requested information (email may be in the Spam folder as well).";
            m.closeButtonLabel = "Confirm";
          });
          modalRef.closed.subscribe(({reason, result = []}) => {
            const [filters] = result
          });
        } else {
          this.openDownloadModel();
        }
      }
      ,e => this.error = e
    )
  }

  

  openDownloadModel(){
    const modalRef = this.modalService.open(ServiceDownloadModalComponent, m => {
      m.type_reference = 'service';
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [filters] = result;

      if(filters.filters){
        this.openEmailNotificationModal();
        this.uploadV2Service.createServiceDownload(filters.filters, this.queryString)
          .takeUntil(this.destroy$)
          .subscribe(res => {
            console.log(res)
          }
          ,e => this.error = e)
      }

    });
  }

  openEmailNotificationModal(){
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Email Notification";
      m.message = "Your request to download Services Inventory is in progress. Please check your email in a few minutes to receive the requested information (it may also be in your Spam folder).";
      m.closeButtonLabel = "Confirm";
    });
    modalRef.closed.subscribe(({reason, result = []}) => {
      const [filters] = result
    });
  }

  downloadFile(blob) {
    let file = new Blob([blob], {
      type: 'application/octet-stream'
    });
    const url = window.URL.createObjectURL(file);
    //console.log(url);
    //window.open(url);
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = 'MiServiceTemplate.xlsx';
    document.body.appendChild(a); //create the link "a"
    a.click(); //click the link "a"
    document.body.removeChild(a); //remove the link "a"
  }
}
