<div class="wrapper">  <!--Here I have put the wrapper to put overflow-->
  <table class="mi-sort-table {{externalClasses}}"
       [ngClass]="_classes">

  <thead>
  <tr>
    <th *ngFor="let column of _columns"
        [miSortableColumn]="column.sortable"
        [column]="column"
        (sortChanged)="onChangeTable($event)"
        [ngClass]="{'sortable': column.sortable,
                      'sort-asc': column.sort === 'asc',
                      'sort-desc': column.sort === 'desc'
                      }"
    >
    <ng-template
    [ngTemplateOutlet]="column.headerTemplate"
    [ngTemplateOutletContext]="{column: column}">
  </ng-template>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of _rows" class="{{ (_selectedRow == row)? 'selected' : '' }}" (click)="onRowClick(row)">
    <td *ngFor="let column of _columns" ngClass="{{column.className}}">
      <ng-template
        [ngTemplateOutlet]="column.cellTemplate"
        [ngTemplateOutletContext]="{row: row, value: getData(row, column.prop), prop: column.prop, columnName: column.name}">
      </ng-template>
    </td>
  </tr>
  </tbody>
</table>

<ng-template #defaultCellTpl let-row="row" let-value="value">
  {{value}}
</ng-template>

<ng-template #defaultHeaderTpl let-column="column">
  {{column.name}}
</ng-template>

<ng-template #idLinkTpl let-row="row" let-value="value">
  <mi-id-link *ngIf="value" id="{{value}}"></mi-id-link>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #idLinkTpl2 let-row="row" let-value="value">
  <mi-id-link *ngIf="value" id="{{row.id}}" display_id="{{value}}"></mi-id-link>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #contactTpl let-row="row" let-value="value">
  <mi-id-link *ngIf="value" display_id="{{value.display_id}}" id="{{value.id}}"></mi-id-link>
  <ng-container *ngIf="value">{{value.first_name}} {{value.last_name}}</ng-container>
  <ng-container *ngIf="!value">{{this.NO_DATA}}</ng-container>
</ng-template>

<ng-template #shortDateTpl let-row="row" let-value="value">
  {{value | date:'shortDate' }}
</ng-template>

<ng-template #shortDateTimeTpl let-row="row" let-value="value">
  {{value | date:'short' }}
</ng-template>

<ng-template #shortISODateTimeTpl let-row="row" let-value="value">
  {{convertTimeZone(value) | date:'short' }}
</ng-template>

<ng-template #trimmedTextTpl let-row="row" let-value="value">
  {{value | limitText}}
</ng-template>

<ng-template #tenantCurrencyTpl let-row="row" let-value="value">
  <span class="overflow-controlled number-template">
    {{ value | tenantcurrency:'1.2-2' }}
  </span>
</ng-template>

<ng-template #serviceCurrencyTpl let-row="row" let-value="value">
  {{ value | currency:(row.currency_code? row.currency_code : 'USD'):'symbol':'1.2-2' }}
</ng-template>

<ng-template #currencyTpl let-row="row" let-value="value">
  {{ value | currency:'USD':'symbol':'1.2-2' }}
</ng-template>

<ng-template #roundedCurrencyTpl let-row="row" let-value="value">
  {{ value | round | currency:'USD':'symbol':'1.0' }}
</ng-template>

<ng-template #truncateTpl let-row="row" let-value="value" let-prop="prop">
  <span
    (click)="_columnClicked = (_columnClicked !== (row[rowIdProp] + '#@#' + prop) ? (row[rowIdProp] + '#@#' + prop) : null)"
    [ngClass]="{'overflow-controlled': _columnClicked !== (row[rowIdProp] + '#@#' + prop), 'wrap': _columnClicked == (row[rowIdProp] + '#@#' + prop)}"
    title="{{value}}">
    {{value}}
  </span>
</ng-template>

<ng-template #numberTpl let-row="row" let-value="value">
  <span class="overflow-controlled" title="{{stringToNumber(value)}}">
    {{stringToNumber(value)|number}}
  </span>
</ng-template>

</div>