<ng-container *ngIf="parentServiceState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <form *ngIf="componentForm"
          [formGroup]="componentForm"
          (submit)="onFormSubmit(componentForm.value)">

      <mi-extended-input labelText="Nickname"
                         hint="You can add a friendly or familiar nickname to your parent service so it is easier to find."
                         [(inputControl)]="componentForm.controls['nickname']"
                         [(inputErrors)]="componentForm.controls['nickname'].errors"
                         [(inputTouched)]="componentForm.controls['nickname'].touched"
                         [errorDefs]='{}'>
        <input type="text"
               class="form-control"
               formControlName="nickname"
               placeholder="Ex: My Ethernet Connection"
               autofocus>

      </mi-extended-input>

      <div class="button-container">
        <a
          mi-button
          buttonTheme="light-theme"
          [routerLink]="cancelLink">
          Cancel
        </a>
        <button
          mi-button
          buttonTheme="dark-theme" [disabled]="!componentForm.valid">
          Next
        </button>
      </div>

    </form>

  </ng-container>

  <ng-template #static>
    <dl>
      <dt>Nickname</dt>
      <dd>
        <ng-container *ngIf="this.parentServiceState.nickname;else noData">
          {{this.parentServiceState.nickname}}
        </ng-container>
        <ng-template #noData>
          {{NO_DATA}}
        </ng-template>
      </dd>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


