<mi-opacity-loader [ready]="!updating">
<div class="retermForm">
  <h2>Re-Term</h2>
  <mi-server-error [error]="error"></mi-server-error>
  <ng-container *ngIf="loaded">
    <form *ngIf="retermForm" [formGroup]="retermForm">

      <ng-container [ngSwitch]="currentState">

        <section *ngSwitchCase="ORDER_ID">

          <dl class="active">
            <dt>
              When did you order the service?
            </dt>
            <dd>
              <ul class="radio-collection">
                <li>
                  <input type="radio"
                         id="unsure"
                         [value]="dateKnown"
                         [checked]="dateKnown === startDate.UNKNOWN"
                         (change)="onToggleDateKnown(startDate.UNKNOWN)">
                  <label for="unsure">{{'GLOBAL.UNKNOWN' | translate }}</label>
                </li>
                <li>
                  <input type="radio"
                         id="date"
                         [value]="dateKnown"
                         [checked]="dateKnown === startDate.KNOWN"
                         (change)="onToggleDateKnown(startDate.KNOWN)">

                  <label for="date" (click)="onSelectDateOpen()">
                    On This Date
                  </label>

                  <mi-date-picker
                    [currentDate]="retermForm.get('service_order_date').value"
                    (dateChange)="onServiceOrderDateChanged($event)"
                    (click)="onToggleDateKnown(startDate.KNOWN)"
                    class="service-reterm-date"
                  >
                  </mi-date-picker>

                </li>
              </ul>
            </dd>
          </dl>

          <mi-extended-input
            labelText="What is your order ID?"
            class="service-details"
            [(inputControl)]="retermForm.controls['service_order_number']"
            [(inputErrors)]="retermForm.controls['service_order_number'].errors"
            [(inputTouched)]="retermForm.controls['service_order_number'].touched"
            [errorDefs]="{}">


            <input type="text"
                   class="form-control"
                   formControlName="service_order_number"
                   placeholder="order number or id"
                   mi-restrict-input miRestrictType="OrderId">

          </mi-extended-input>
        </section>

        <section *ngSwitchCase="TERM_LENGTH"
                 class="section--term-length">

          <mi-extended-input labelText="What is the start date?"
                             class="service-details narrow"
                             [labelRequired]="true"
                             [(inputControl)]="retermForm.controls['start_date']"
                             [(inputErrors)]="retermForm.controls['start_date'].errors"
                             [(inputTouched)]="retermForm.controls['start_date'].touched"
                             [errorDefs]="{required: 'Please select a valid date.'}">
            <mi-date-picker
              [currentDate]="retermForm.get('start_date').value"
              (dateChange)="onDateChange($event)"
              [ngStyle]="{'width':'308px', 'display':'block'}">
            </mi-date-picker>

          </mi-extended-input>

          <mi-extended-input labelText="Term Length"
                             class="service-details narrow"
                             [labelRequired]="true"
                             [(inputControl)]="retermForm.controls['term_length']"
                             [(inputErrors)]="retermForm.controls['term_length'].errors"
                             [(inputTouched)]="retermForm.controls['term_length'].touched"
                             [errorDefs]="{ required: 'Term Length is required.', min: 'Minimum term length is 1', max: 'Maximum term length is 100', integer: 'Term length must be an integer (1, 2, 6, etc.)'}">
            <div class="flex">
              <input type="text"
                     class="form-control"
                     formControlName="term_length"
                     placeholder="ex: 1, 2, 6, 12, etc."
                     mi-restrict-input miRestrictType="Integer">
              <span class="suffix">
                  {{'TENANT.RETERM.MONTHS_QTY' | translate }}
                </span>
            </div>
          </mi-extended-input>

        </section>

        <section *ngSwitchCase="COSTS"
                 class="section--costs">

          <mi-extended-input labelText="Recurring Unit Costs"
                             hint="Cost per unit of the service for each billing period"
                             class="service-details narrow"
                             [labelRequired]="true"
                             [(inputControl)]="retermForm.controls['cost']"
                             [(inputErrors)]="retermForm.controls['cost'].errors"
                             [(inputTouched)]="retermForm.controls['cost'].touched"
                             [errorDefs]="{ required: 'Recurring cost is required.', min: 'Minimum cost is 0'}">
            <div class="flex">
              <input type="text"
                     class="form-control"
                     formControlName="cost"
                     placeholder="ex: 100"
                     mi-restrict-input miRestrictType="PositiveNumber">
              <span class="suffix">
                  {{service.currency_code||'USD'}}
                </span>
            </div>
          </mi-extended-input>

          <mi-extended-input labelText="Quantity"
                             hint="Number of services ordered"
                             class="service-details narrow"
                             [labelRequired]="true"
                             [(inputControl)]="retermForm.controls['quantity']"
                             [(inputErrors)]="retermForm.controls['quantity'].errors"
                             [(inputTouched)]="retermForm.controls['quantity'].touched"
                             [errorDefs]="{ required: 'Quantity is required.', min: 'Minimum quantity is 1'}">
            <div class="flex">
              <input type="text"
                     class="form-control"
                     formControlName="quantity"
                     placeholder="ex: 1"
                     mi-restrict-input miRestrictType="PositiveNumber">
              <span class="suffix">
              Units
              </span>
            </div>
          </mi-extended-input>

          <mi-extended-input labelText="Billing Frequency"
                             hint="How frequently does this service bill you? Once every ..."
                             class="service-details narrow"
                             [labelRequired]="true"
                             [(inputControl)]="retermForm.controls['billing_frequency']"
                             [(inputErrors)]="retermForm.controls['billing_frequency'].errors"
                             [(inputTouched)]="retermForm.controls['billing_frequency'].touched"
                             [errorDefs]="{ required: 'Billing frequency is required.', min: 'Minimum frequency is 1', max: 'Maximum frequency is 120', integer: 'Billing frequency must be an integer (ex: 1, 2, 6, etc.)'}">
            <div class="flex">
              <input type="text"
                     class="form-control"
                     formControlName="billing_frequency"
                     placeholder="ex: 1"
                     mi-restrict-input miRestrictType="Integer">
              <span class="suffix">
                  {{'TENANT.RETERM.MONTHS_QTY' | translate }}
                </span>
            </div>
          </mi-extended-input>

          <mi-extended-input labelText="Non-Recurring Costs"
                             hint="One-time costs for the service"
                             class="service-details narrow"
                             [(inputControl)]="retermForm.controls['non_recurring_cost']"
                             [(inputErrors)]="retermForm.controls['non_recurring_cost'].errors"
                             [(inputTouched)]="retermForm.controls['non_recurring_cost'].touched"
                             [errorDefs]="{ min: 'Minimum non-recurring cost is 0'}">
            <div class="flex">
              <input type="text"
                     class="form-control"
                     formControlName="non_recurring_cost"
                     placeholder="ex: 100"
                     mi-restrict-input miRestrictType="PositiveNumber">
              <span class="suffix">
                {{service.currency_code||'USD'}}
                </span>
            </div>
          </mi-extended-input>

        </section>

        <section *ngSwitchCase="TERMS_AND_CONDITIONS"
                 class="section--tac">

          <mi-find-or-create-terms-and-conditions
            [selectedItem]="retermForm.get('terms_and_conditions').value"
            (termsAndConditionsSelected)="onTermsAndConditionsSelected($event)"
            [allowNull]="false">
          </mi-find-or-create-terms-and-conditions>

        </section>

        <section *ngSwitchCase="SUMMARY" class="section--tac">
          <dl class="summary re-term">
            <div class="summary-group">
              <div class="headline">Re-Term Details</div>
              <div class="headline">Re-Term</div>
            </div>
            <div class="summary-group">
              <dt>
                Order Date
              </dt>
              <dd>
                {{ retermForm.get('service_order_date').value ? (retermForm.get('service_order_date').value | date:'MM/dd/yyyy') : NO_DATA}}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Order ID
              </dt>
              <dd>
                {{retermForm.get('service_order_number').value || NO_DATA}}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Term Length
              </dt>
              <dd>
                {{'MONTHS_QTY'
                | translate:{count: retermForm.get('term_length').value}
                | translateSelector: retermForm.get('term_length').value }}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Start Date
              </dt>
              <dd>
                {{retermForm.get('start_date').value}}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Calculated MRC
              </dt>
              <dd>
                {{ (retermForm.get('cost').value) * (retermForm.get('quantity').value) / (retermForm.get('billing_frequency').value) | tenantcurrency:'1.2-2'}}
                / Month
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Quantity
              </dt>
              <dd>
                {{ retermForm.get('quantity').value }} Units
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Billing Frequency
              </dt>
              <dd>
                {{'MONTHS_QTY'
                | translate:{count: retermForm.get('billing_frequency').value}
                | translateSelector: retermForm.get('billing_frequency').value }}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Non-Recurring Cost
              </dt>
              <dd>
                {{retermForm.get('non_recurring_cost').value || 0 | currency:(service.currency_code||'USD'):'symbol':'1.2-2'}}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                Selected Service Term
              </dt>
              <dd>
                {{'END_OF_TERM.' + retermForm.get('terms_and_conditions').value.event_type | translate}}

                {{('MONTHS_QTY'
                | translate:{count: retermForm.get('terms_and_conditions').value.auto_renew_length}
                | translateSelector: retermForm.get('terms_and_conditions').value.auto_renew_length ) || NO_DATA}}
              </dd>
            </div>
            <div class="summary-group">
              <dt>
                End of Term (EOT)
              </dt>
              <dd>
                {{eotReterm | date:'MM/dd/yyyy'}}
              </dd>
            </div>
          </dl>

        </section>

      </ng-container>

      <div class="button-container">
        <button *ngIf="currentState === ORDER_ID || currentState === SUMMARY"
                mi-button
                buttonTheme="light-theme"
                (click)="onExitClicked()">
          {{'TENANT.SERVICE_DETAILS.CTA.CANCEL_WITHOUT_MODIFICATION'|translate}}
        </button>
        <button *ngIf="currentState !== ORDER_ID"
                mi-button
                buttonTheme="light-theme"
                (click)="onBackClicked()">
          {{'TENANT.SERVICE_DETAILS.CTA.BACK' | translate}}
        </button>
        <button *ngIf="currentState === SUMMARY"
                mi-button
                buttonTheme="dark-theme"
                (click)="onRetermClicked()"
                [disabled]="progressDisabled">
          {{'TENANT.SERVICE_DETAILS.CTA.RE_TERM' | translate}}
        </button>
        <button *ngIf="currentState !== TERMS_AND_CONDITIONS && currentState !== SUMMARY"
                mi-button
                buttonTheme="dark-theme"
                (click)="onContinueClicked()"
                [disabled]="progressDisabled">
          {{'TENANT.SERVICE_DETAILS.CTA.CONTINUE' | translate}}
        </button>
      </div>

    </form>
  </ng-container>
  <ng-container *ngIf="!loaded">
    <mi-loading-animation></mi-loading-animation>
  </ng-container>
</div>
</mi-opacity-loader>