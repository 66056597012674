import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import {AbstractPageComponent} from '../../pages/abstract-page/abstract-page.component';
import {Vendor} from '../../models/Vendor';
import {TenantVendors} from '../../../libs/api/tenant/tenantVendors';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'mi-vendor-tenant-search',
  templateUrl: './vendor-tenant-search.component.html',
  styleUrls: ['./vendor-tenant-search.component.scss']
})

export class VendorTenantSearchComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  @Input() ctaLabel: string;
  @Input() placeholder: string = 'Search';
  @Input() qtyMessage: String = 'SEARCH_QUANTITIES.VENDORS';
  @Input() vendors: Array<any> = [];
  @Input() searchString: string = '';

  @Output() itemSelected: EventEmitter<Vendor> = new EventEmitter<Vendor>();
  @Output() ctaClicked: EventEmitter<Vendor> = new EventEmitter<Vendor>();
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

  selectedVendor: any;
  error: String = '';
  total: number = 0;
  hasLoaded: Boolean = false;
  page: number = 1;

  firstVendors: Array<Vendor> = [];
  firstTotal: number;

  loadingMore: Boolean = false;

  constructor(
    private tenantVendor: TenantVendors
  ) {
    super();
  }

  filterVendors(vendorName: string, page: number = 1) {

    this.searchString = vendorName;
    const options = {from: 0, size: 6};

    if (page > 1) {
      options.from = 6 + ((page - 2) * 10);
      options.size = 10;
    }

    this.page = page;

    if (this.searchString.length > 1 || page > 1) {
      this.error = '';
      this.tenantVendor.searchActiveTenantVendors(this.searchString, options.from, options.size)
        .then(data => {

          if (page > 1) {
            //this is a load more
            this.vendors.push(...data.hits);
          } else {
            this.vendors = data.hits;
          }
          this.total = data.total.value;
          this.loadingMore = false;
        })
        .catch(e => {
          this.error = e;
        });
    } else {
      this.vendors = this.firstVendors;
      this.total = this.firstTotal;
    }

    this.searchChange.emit(this.searchString);
  }

  onSearchTermChanged(searchString:string = '') {
    this.filterVendors(searchString.trim());
  }

  onCtaClicked() {
    this.ctaClicked.emit(this.selectedVendor);
  }

  selectVendor(vendor) {
    this.selectedVendor = vendor;
    this.filterVendors(this.searchString);
    this.itemSelected.emit(this.selectedVendor);
  }

  unSelectVendor() {
    this.searchString = '';
    this.selectedVendor = null;
    this.filterVendors(this.searchString, 1);
    this.itemSelected.emit(this.selectedVendor);
  }

  loadMoreClicked($event) {
    $event.preventDefault();
    this.loadingMore = true;
    this.filterVendors(this.searchString, this.page + 1);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    combineLatest(
      this.tenantVendor.searchActiveTenantVendors(this.searchString, 0, 6),
      this.tenantVendor.searchActiveTenantVendors("", 0, 6)
    )
    .subscribe(([data, emptySearchData])=> {
      this.hasLoaded = true;
      this.vendors = data.hits;
      this.firstVendors = emptySearchData.hits;
      this.firstTotal = emptySearchData.total.value;
      this.total = data.total.value;
    },
    (e) => {
      this.error = e;
    });

  }

}
