import { Action } from '@ngrx/store';

export const ActionTypes = {
  APP_DATA_GET: '[Miso:AppData] get',
  APP_DATA_GET_SUCCESS: '[Miso:AppData] get success',
  APP_DATA_GET_ERROR: '[Miso:AppData] get error',
  APP_DATA_GET_ERROR_NOT_FOUND: '[Miso:AppData] get error not found',
};

export class AppDataGetAction implements Action {
  type = ActionTypes.APP_DATA_GET;

  constructor(public payload: any) { }
}

export class AppDataGetSuccessAction implements Action {
  type = ActionTypes.APP_DATA_GET_SUCCESS;

  constructor(public payload: any) { }
}

export class AppDataGetErrorAction implements Action {
  type = ActionTypes.APP_DATA_GET_ERROR;

  constructor(public payload: any) { }
}

export class AppDataGetErrorNotFoundAction implements Action {
  type = ActionTypes.APP_DATA_GET_ERROR_NOT_FOUND;

  constructor(public payload: any) { }
}

export type Actions
  = AppDataGetAction
  | AppDataGetSuccessAction
  | AppDataGetErrorAction
  | AppDataGetErrorNotFoundAction
