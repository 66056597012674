import { Component } from '@angular/core';
import { ModalRef } from '@independer/ng-modal';

@Component({
  selector: 'confirm-eot-delete-modal',
  template: `
  <modal #categoryModal>
    <modal-header>
        <h4> {{modalTitle}} Renewal Term</h4>
    </modal-header>
    <modal-content>
    Are you sure you want to remove the end of term action for <b>{{modalTitle}}</b> ?
    </modal-content>
    <modal-footer>
    <span>
      <button type="button" class="btn btn-outline-primary" (click)="close()">Cancel</button>
    </span>
    <span>
      <button class="btn btn-danger ml-2" (click)="confirmHandler()">
        Confirm
      </button>
    </span>
    </modal-footer>
</modal>
`
})

export class ConfirmEOTDeleteModalComponent {
  modalTitle: any
  constructor(
    private modal: ModalRef
  ) { }

  close() {
    this.modal.close(false);
  }

  confirmHandler(): void {
    this.modal.close(true);
  }
}
