import {createFeatureSelector, createSelector,} from '@ngrx/store';
import * as sdActions from './actions';
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {MiService, MiServiceDetails, ServiceCalculationDetails} from "../../../../../shared/models/MiService";

const log = createLogger(LOG_LEVELS.REDUX);

export interface State {
  miService: MiService,
  serviceDetails: MiServiceDetails,
  parentServiceDetails: MiServiceDetails,
  serviceCalculationDetails: ServiceCalculationDetails,
  parentServiceCalculationDetails: ServiceCalculationDetails,
  pageLoaded: boolean,
  overlayName: string,
  currentForm: string,
}

const initialState: State = {
  miService: null,
  serviceDetails: null,
  parentServiceDetails: null,
  serviceCalculationDetails: null,
  parentServiceCalculationDetails: null,
  pageLoaded: false,
  overlayName: 'modalId',
  currentForm: ''
};

export function reducer(state = initialState, action: sdActions.Actions): State {
  switch (action.type) {
    case sdActions.ActionTypes.SERVICE_DETAILS_RESET: {
      return {
        ...initialState
      }
    }
    case sdActions.ActionTypes.LOAD_SERVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        pageLoaded: true
      }
    }
    case sdActions.ActionTypes.SERVICE_DETAILS_SHOW_FORM: {
      return {
        ...state,
        ...action.payload,
        pageLoaded: true
      }
    }
    default: {
      return state;
    }
  }
}

export const getDSData = createFeatureSelector<State>('serviceDetails');
export const DSPageLoaded = createSelector(getDSData, (data) => data.pageLoaded);

