<mi-server-error [error]="error"></mi-server-error>

<div class="button-container">
  <button mi-button
          buttonTheme="dark-theme"
          [disabled]="!activeIndex"
          (click)="onAddOrder()">
    Add Order
  </button>
</div>
