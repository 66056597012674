
<mi-page-header
  title="{{ 'TENANT.MISERVICE_ORDER_SUMMARY_TITLE' | translate:{display_id: display_id} }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<ng-container *ngIf="loaded; else loading">
  <div class="details-header-nav">
    <div class="details-header-nav__graphic">
      <div class="details-header-nav__graphic-container">

        <ng-container *ngIf="order.vendor.logo && order.vendor.logo.full_path; else fallback">
          <img class="vendor-logo" [src]="order.vendor.logo.full_path" />
        </ng-container>
        <ng-template #fallback>
          <img class="vendor-logo fallback" src="shared/assets/images/vendor-logo-placeholder.svg" />
        </ng-template>
      </div>
    </div>

    <div class="details-header-nav__info">
      <mi-details-header-nav
        [data]="detailHeaderNavData"
        [showEdit]="true"
        [navType]="navType">
      </mi-details-header-nav>
    </div>
  </div>

  <div class="sections">

    <section class='data-section'>
      <h2>Services</h2>
      <mi-service-order-summary-services [order]="order"></mi-service-order-summary-services>
    </section>

    <section class='data-section'>
        <h2>Order Contacts</h2>
        <mi-service-contacts-static
        [account_id]="order.account_id"
        [custom_data]="order.custom_data">
      </mi-service-contacts-static>
    </section>

    <section class='data-section'>
      <mi-service-order-summary-documents
        (dataUpdate)="onDocumentsUpdated($event)"
        [order]="order"
        title="Associated Documents" >
      </mi-service-order-summary-documents>
    </section>

  </div>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
