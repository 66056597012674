<div class="appliedSearchFilters" [ngClass]="{'hidden': filters && filters.length == 0}">

  <div class="appliedSearchFilters--header">
    <h2>{{'GLOBAL.SEARCH_FILTERS.FILTERS_TITLE'|translate}}</h2>
    <a class="removeAll"
       (click)="onRemoveAllClick()">
      {{'GLOBAL.SEARCH_FILTERS.REMOVE_ALL_FILTERS' | translate}}
    </a>
  </div>

  <div class="appliedSearchFilters--filters">
    <ng-container *ngIf="loaded && filters.length">
      <mi-applied-search-filter-item
        *ngFor="let filter of filters"
        [filter]="filter"
        [customField]="customFieldsByIDs"
        [businessUnits]="businessUnitsByIDs"
        [accountIdsByIDs]="accountIdsByIDs"
        [serviceGroupByIDs]="serviceGroupByIDs"
        [contactsByIDs]="contactsByIDs"
        (remove)="onRemoveClick($event)">
      </mi-applied-search-filter-item>
    </ng-container>
  </div>

</div>
