<mi-server-error [error]="error"></mi-server-error>
<mi-loading-animation *ngIf="loading"></mi-loading-animation>

<form [(formGroup)]="editProductForm" (ngSubmit)="submit(editProductForm.value)" *ngIf="editProductForm"
      autocomplete="off">
  <ng-container *ngIf="lockVendor">
    <product-vendor-header [locked]="true" [vendor]="productInsert.product_vendor" [theme]="lockVendorTheme">
    </product-vendor-header>
    <input type="hidden" formControlName="vendor_id">
  </ng-container>

  <mi-extended-input *ngIf="!lockVendor" [labelText]="'Vendor'" [labelRequired]="true"
                     [(inputControl)]="editProductForm.controls['vendor_id']"
                     [(inputErrors)]="editProductForm.controls['vendor_id'].errors"
                     [(inputTouched)]="editProductForm.controls['vendor_id'].touched"
                     [errorDefs]="{required: 'Please enter a Product Vendor.'}">

    <ng-select class="mi-select"
               [items]="vendors"
               bindLabel="vendor_name"
               bindValue="_id"
               [multiple]="false"
               [closeOnSelect]="true"
               formControlName="vendor_id">
    </ng-select>

  </mi-extended-input>

  <mi-extended-input [labelText]="'Product Category'" [labelRequired]="true"
                     [(inputControl)]="editProductForm.controls['category_id']"
                     [(inputErrors)]="editProductForm.controls['category_id'].errors"
                     [(inputTouched)]="editProductForm.controls['category_id'].touched"
                     [errorDefs]="{required: 'Please enter a Product Category.'}">

    <ng-select class="mi-select" [items]="categories" bindLabel="category_name" bindValue="id" [multiple]="false"
               [closeOnSelect]="true" formControlName="category_id" (change)="showProductCategoryFields($event)" appendTo="body">

      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div [style.margin-left]="item.level + 'px'">{{item.category_name}}</div>
      </ng-template>

    </ng-select>
  </mi-extended-input>

  <mi-extended-input [labelText]="'Product Name'" [labelRequired]="true"
                     [(inputControl)]="editProductForm.controls['product_name']"
                     [(inputErrors)]="editProductForm.controls['product_name'].errors"
                     [(inputTouched)]="editProductForm.controls['product_name'].touched"
                     [errorDefs]="{
                       required: 'Please enter a Product Name.', 
                       maxlength: 'Product Name cannot be longer than 250 characters long.'
                      }">
    <input id="product_name" name="product_name" formControlName="product_name" placeholder="Product Name Goes Here"
           type="text">
  </mi-extended-input>
  <form [(formGroup)]="editProductFieldsForm" *ngIf="allProductCategoryFieldsProperties.length > 0" autocomplete="off">
    <h3>Property Types</h3>
    <div class="form-group">
      <table>
        <tbody>
        <tr *ngFor="let productCategoryField of allProductCategoryFieldsProperties; let i = index;">
          <td class="custom-field-label">
            <label class="control-label">
              {{productCategoryField.product_field_property.product_field.label}}
            </label>
          </td>
          <td class="custom-field-value">
            <mi-extended-input class="service-details" [labelRequired]="false"
                               [inputControl]="editProductFieldsForm.controls[fieldName(productCategoryField.product_field_property)]"
                               [inputErrors]="editProductFieldsForm.controls[fieldName(productCategoryField.product_field_property)].errors"
                               [inputTouched]="editProductFieldsForm.controls[fieldName(productCategoryField.product_field_property)].touched"
                               [errorDefs]='{invalidFormat: "Invalid " + productCategoryField.product_field_property.product_field.label}'>

              <ng-container [ngSwitch]="productCategoryField.product_field_property.product_field.product_field_type">
                <ng-container *ngSwitchCase="'NUMBER'">
                  <div class="property">

                    <input type="text" class="form-control" style="margin-right: 10px"
                           formControlName="{{fieldName(productCategoryField.product_field_property)}}"
                           placeholder="">

                    <ng-select *ngIf="productCategoryField.product_field_property.options.length > 0" class="mi-select" [items]="productCategoryField.product_field_property.options"
                               [multiple]="false" [closeOnSelect]="true"
                               formControlName="{{fieldUnitName(productCategoryField.product_field_property)}}">
                    </ng-select>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'BANDWIDTH_SPEED'">

                  <div class="property">
                    
                  <input type="text" class="form-control" style="margin-right: 10px"
                         formControlName="{{fieldName(productCategoryField.product_field_property)}}"
                         placeholder="">

                  <ng-select *ngIf="productCategoryField.product_field_property.options.length > 0" class="mi-select" [items]="productCategoryField.product_field_property.options"
                             [multiple]="false" [closeOnSelect]="true"
                             formControlName="{{fieldUnitName(productCategoryField.product_field_property)}}">
                  </ng-select>
                  </div>

                </ng-container>
                <ng-container *ngSwitchCase="'DROPDOWN'">
                  <ng-select class="mi-select" [items]="productCategoryField.product_field_property.options"
                             [multiple]="false" [closeOnSelect]="true"
                             formControlName="{{fieldName(productCategoryField.product_field_property)}}">
                  </ng-select>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="property">

                    <input type="text" class="form-control" style="margin-right: 10px"
                           formControlName="{{fieldName(productCategoryField.product_field_property)}}"
                           placeholder="">

                    <ng-select *ngIf="productCategoryField.product_field_property.options.length > 0" class="mi-select" [items]="productCategoryField.product_field_property.options"
                               [multiple]="false" [closeOnSelect]="true"
                               formControlName="{{fieldUnitName(productCategoryField.product_field_property)}}">
                    </ng-select>
                  </div>
                </ng-container>
              </ng-container>

            </mi-extended-input>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </form>

  <!-- <a mi-button buttonTheme="dark-theme"
     class="login-button toggleAgreement"
     (click)="addAgreement = !addAgreement"
     // *ngIf="productServicesMode === miyagiProductServicesMode"
     >
    Toggle Agreement
  </a> -->

  <!-- <form [(formGroup)]="editAgreementForm" *ngIf="addAgreement" autocomplete="off">

    <mi-extended-input [labelText]="'End of Term Action'" [labelRequired]="true"
                       [(inputControl)]="editAgreementForm.controls['event_type']"
                       [(inputErrors)]="editAgreementForm.controls['event_type'].errors"
                       [(inputTouched)]="editAgreementForm.controls['event_type'].touched"
                       [errorDefs]="{required: 'Please enter an End of Term Action.'}">
      <select id="event_type"
              name="event_type"
              formControlName="event_type">
        <option *ngFor="let action of event_types"
                value="{{action.value}}">
          {{action.key}}
        </option>
      </select>
    </mi-extended-input>

    <mi-extended-input *ngIf="editAgreementForm.controls['event_type'].value==='EVENT_TYPE_AUTO_RENEW'"
                       [labelText]="'Auto renew term length in months'"
                       [labelRequired]="true" [(inputControl)]="editAgreementForm.controls['auto_renew_length']"
                       [(inputErrors)]="editAgreementForm.controls['auto_renew_length'].errors"
                       [(inputTouched)]="editAgreementForm.controls['auto_renew_length'].touched"
                       [errorDefs]="{required: 'Please enter an Auto renew term length.', min: 'Auto renew length cannot be below zero.'}">
      <input id="auto_renew_length"
             name="auto_renew_length"
             formControlName="auto_renew_length"
             placeholder="Ex. 3"
             type="number"
             min="0"
             mi-restrict-input miRestrictType="Integer">
    </mi-extended-input>

    <mi-extended-input *ngIf="editAgreementForm.controls['event_type'].value==='EVENT_TYPE_AUTO_RENEW'"
                       [labelText]="'Days required to notify'"
                       [labelRequired]="true" [(inputControl)]="editAgreementForm.controls['days_to_notify']"
                       [(inputErrors)]="editAgreementForm.controls['days_to_notify'].errors"
                       [(inputTouched)]="editAgreementForm.controls['days_to_notify'].touched"
                       [errorDefs]="{required: 'Please enter days required to notify.', min: 'Days to notify cannot be below zero.'}">
      <input id="days_to_notify"
             name="days_to_notify"
             formControlName="days_to_notify"
             placeholder="Ex. 30"
             type="number"
             min="0"
             mi-restrict-input miRestrictType="Integer">
    </mi-extended-input>

    <mi-extended-input
      labelText="Agreement Label, preferred default: ( MISO Standard <product name> Default Agreement )"
      [labelRequired]="true"
      [(inputControl)]="editAgreementForm.controls['label']"
      [(inputErrors)]="editAgreementForm.controls['label'].errors"
      [(inputTouched)]="editAgreementForm.controls['label'].touched"
      [errorDefs]="{required: 'Please enter a label.'}">
      <input id="label"
             name="label"
             formControlName="label"
             placeholder='Agreement Label'
             type="text">
    </mi-extended-input>

    <mi-extended-input [labelText]="'Cancellation Instructions'"
                       [(inputControl)]="editAgreementForm.controls['cancellation_instructions']"
                       [(inputErrors)]="editAgreementForm.controls['cancellation_instructions'].errors"
                       [(inputTouched)]="editAgreementForm.controls['cancellation_instructions'].touched"
                       [errorDefs]="{required: 'Please enter cancellation instructions.'}">
      <textarea id="cancellation_instructions"
                name="cancellation_instructions"
                formControlName="cancellation_instructions"
                placeholder=''
                type="text"></textarea>
    </mi-extended-input>

    <mi-extended-input [labelText]="'Agreement Link'"
                       [(inputControl)]="editAgreementForm.controls['agreement_link']"
                       [(inputErrors)]="editAgreementForm.controls['agreement_link'].errors"
                       [(inputTouched)]="editAgreementForm.controls['agreement_link'].touched"
                       [errorDefs]="{url: 'Please enter a valid URL.'}">
      <input type="text"
             id="agreement_link"
             name="agreement_link"
             formControlName="agreement_link"
             placeholder="http://example.com/terms.html"/>
    </mi-extended-input>

  </form> -->

  <div class="button-container">

    <ng-container *ngIf='mode==="EDIT"'>
      <a mi-button
         class="button-alternate"
         buttonTheme="alert-light-theme"
         routerLink="../delete">
        Delete
      </a>
    </ng-container>

    <a mi-button
       [buttonTheme]="'light-theme'"
       (click)="onCancel($event)">
      Cancel
    </a>

    <button mi-button
            buttonTheme="dark-theme"
            class="login-button"
            type="submit"
            [disabled]="!editProductForm.valid">
      Save
    </button>

  </div>
</form>
