import {Component} from '@angular/core';
import {SEARCH_TYPE_CONTACTS, ID_KEY} from "../../../../../components/detail-search/detail-search.component";

@Component({
  templateUrl: './contact-search-equipment.component.html'
})

export class ContactSearchEquipmentComponent  {
  readonly SEARCH_TYPE_CONTACTS: string = SEARCH_TYPE_CONTACTS;
  queryParams: any = {
    "contact_id[]": ID_KEY
  };
}
