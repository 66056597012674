import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as PSReducer from '../../../state/reducers';
import {Store} from '@ngrx/store';
import {EVENT_TYPE_ACTIVATION} from '../../../../../../../../src/common/utils/sharedConstants';
import FeDateUtil from '../../../../../../../shared/utils/FeDate.util';
import {MisoCollection} from '../../../../../../../shared/models/MisoCollection.enum';
import {NoteHtml} from '../../../../../../../shared/models/NoteHtml.enum';
import {MiParentService} from '../../../../../../../shared/models/MiParentService';
import {TenantMiParentServiceService} from '../../../../../../../libs/api/tenant/tenantMiParentService.service';
import {ParentServiceLoadSuccess} from '../../../state/actions';
import {TenantNoteService} from '../../../../../../../libs/api/tenant/tenantNote.service';
import {Note} from '../../../../../../../shared/models/Note.interface';
import {MiService} from '../../../../../../../shared/models/MiService';

@Component({
  selector: 'mi-parent-service-summary-cancellation-request-form',
  templateUrl: './parent-service-request-cancellation-form.component.html',
})

export class ParentServiceRequestCancellationFormComponent implements OnInit {

  readonly FORM_CONFIRM: string = '[form] confirm';
  readonly FORM_EDIT: string = '[form] edit';
  readonly EVENT_TYPES: any = {
    EVENT_TYPE_ACTIVATION: EVENT_TYPE_ACTIVATION
  };

  @Input() parentService: MiParentService;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();

  cancelDateIsBeforeToday: boolean = false;
  cancellationRequestFormGroup: FormGroup;
  defaultCancellationDate: string;
  error: string = '';
  minDate: string;
  mode: string = this.FORM_EDIT;

  constructor(private tenantMiParentService: TenantMiParentServiceService,
              private formBuilder: FormBuilder,
              private store: Store<PSReducer.State>,
              private noteService: TenantNoteService) {
  }

  ngOnInit() {

    const tomorrow: Date = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.defaultCancellationDate = `${tomorrow.getMonth() + 1}/${tomorrow.getDate()}/${tomorrow.getFullYear()}`;

    this.cancellationRequestFormGroup = this.formBuilder.group({
      cancellation_date: [this.defaultCancellationDate, Validators.required]
    });

  }

  onDateChange(e): void {
    this.cancellationRequestFormGroup.get('cancellation_date').setValue(e);
    this.cancelDateIsBeforeToday = FeDateUtil.isBeforeToday((new Date(e)));
  }

  onCancelClicked(e): void {
    e.stopPropagation();
    e.preventDefault();
    this.cancellationRequestFormGroup
      .get('cancellation_date')
      .setValue(this.defaultCancellationDate);
    this.canceled.emit();
  }

  onCancellationRequestFormSubmit(data: any) {
    let updatedParentService: MiParentService;
    this.error = '';
    this.tenantMiParentService
      .addCancellation(this.parentService.display_id, data.cancellation_date)
      .toPromise()
      .then((res) => {
        updatedParentService = res;
        /*
        * Add notes to all the services that are now cancelled.
        */
        let promises = res.services.map((service:MiService)=>{
          const note:Note = {
            connections: [{
              document_id: service._id,
              document_collection: MisoCollection.SERVICES
            }],
            html: NoteHtml.CANCELLATION_SCHEDULED_NOTE_HTML
          };
          return this.noteService.createNote(note)
            .toPromise();
        });
        return Promise.all(promises);
      })
      .then(() => {
        // Update the Parent Service:
        this.store.dispatch(new ParentServiceLoadSuccess({parentService: updatedParentService}));
        this.canceled.emit();
      })
      .catch(e => this.error = e);

  }

  onContinueClick() {
    this.mode = this.FORM_CONFIRM;
  }

  onBackClick() {
    this.mode = this.FORM_EDIT;
  }
}
