import { Component, OnInit } from "@angular/core";
import { MiServiceDetails } from "../../../../../../shared/models/MiService";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Store } from "@ngrx/store";
@Component(
  {
    selector: 'mi-subsection-vendor-and-product',
    templateUrl: './subsection-vendor-and-product.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionVendorAndProductComponent extends AbstractPageComponent implements OnInit {
  serviceDetails: MiServiceDetails;
  constructor(
    private store: Store<SDReducer.State>
  ) {
    super();
  }

  ngOnInit() {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
          }
        });
  }

}
