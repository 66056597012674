import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractPageComponent } from '../../../shared/pages/abstract-page/abstract-page.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { integerValidator, numberValidator } from '../../../shared/shared.validators';
import { CalculationFinancial, Financial } from '../../../shared/models/Financial';
import { CurrencyService } from 'src/libs/api2/currency-v2/currency.service';
import { select, Store } from "@ngrx/store";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { combineLatest } from "rxjs";
import * as appDataReducer from "../../../shared/state/app-data/reducers";
import { TenantCurrencyV2 } from 'src/libs/api2/types/TenantCurrencyV2.interface';


@Component({
  selector: 'mi-service-financial-form',
  templateUrl: './service-financial-form.component.html',
  styleUrls: ['./service-financial-form.component.scss']
})

export class ServiceFinancialFormComponent extends AbstractPageComponent implements OnInit {

  @Output() updateCompleted: EventEmitter<Financial> = new EventEmitter<Financial>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() ctaLabel: string = 'Save';
  @Input() tenantCurrencyId: string;

  @Input() set financial(val) {
    this._financial = val;
    this.load();
  }

  @Input() detaileditmode: boolean = false;

  _financial: CalculationFinancial;
  loading: boolean = false;
  loadError: string = '';
  inEditMode: boolean = false;
  financialsForm: FormGroup;
  frequencyDropdownValue = 100;
  default_tenant_currency_id: string;
  tenantCurrencies: TenantCurrencyV2[];
  defaultCurrency: TenantCurrencyV2 = null;

  constructor(private formBuilder: FormBuilder,
    private store: Store<any>,
    private currencyService: CurrencyService) {
    super();
  }

  ngOnInit() {
    if (!this._financial) {
      this.load();
    }
  }

  load() {
    const tenantCurrenciesSub = this.store.pipe(
      select(appDataReducer.getAppData),
      map(appData => {
        this.default_tenant_currency_id = appData.tenantData.v2.tenant_currency_id;
        //debugger;
        return appData.tenantData.domain
      }),
      switchMap(id => this.currencyService.getTenantCurrencies(id, "")),
    );

    combineLatest(
      tenantCurrenciesSub
    )
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([tenantCurrenciesData]) => {
          this.tenantCurrencies = tenantCurrenciesData;

          this.createForm(this._financial);
        },
        (e) => {
        }
      );
  }

  get cost(): number {
    return <number>(this.financialsForm.get('cost').value || 0);
  }

  get non_recurring_cost(): number {
    return <number>(this.financialsForm.get('non_recurring_cost').value || 0);
  }

  get quantity(): number {
    return <number>(this.financialsForm.get('quantity').value || 1);
  }

  get total_cost(): number {
    return this.cost * this.quantity;
  }

  get total_non_recurring_cost(): number {
    return this.non_recurring_cost * this.quantity;
  }

  createForm(financial: CalculationFinancial) {
    let tenant_currency_id = this.tenantCurrencyId;
    //debugger;
    if (!tenant_currency_id) {
      if (!this.detaileditmode && this.default_tenant_currency_id) {
        this.defaultCurrency = this.tenantCurrencies.find(c => c.id === this.default_tenant_currency_id);
      }
      if (!this.defaultCurrency) {
        this.defaultCurrency = this.tenantCurrencies.find(c => c.code === 'USD');
      }
      tenant_currency_id = this.defaultCurrency.id;
    }
    else {
      this.defaultCurrency = this.tenantCurrencies.find(c => c.id === tenant_currency_id);
    }

    const {
      cost = financial.cost &&  0,
      billing_frequency = financial.billing_frequency  && null,
      non_recurring_cost = financial.non_recurring_cost &&  0,
      quantity = financial.quantity && 1
    } = financial || {};
    this.financialsForm = this.formBuilder.group(
      {
        cost: [cost, Validators.compose([
          Validators.required,
          numberValidator,
          Validators.min(0)
        ])],
        quantity: [quantity, Validators.compose([
          Validators.required,
          numberValidator,
          Validators.min(0.01)
        ])],
        billing_frequency: [billing_frequency, Validators.compose([
          Validators.required,
          Validators.min(1),
          integerValidator
        ])],
        non_recurring_cost: [non_recurring_cost, Validators.compose([
          Validators.required,
          Validators.min(0),
          numberValidator
        ])],
        tenant_currency_id: [tenant_currency_id, Validators.compose([
          Validators.required
        ])]
      }
    );

    if (billing_frequency) {
      if ([1, 3, 12, 24, 36].indexOf(billing_frequency) > -1) {
        this.frequencyDropdownValue = billing_frequency;
      } else {
        this.frequencyDropdownValue = 0;
      }
    }

  }

  onBillFrequencySelect(event: any) {
    this.frequencyDropdownValue = Number(event.target.value);

    if (this.frequencyDropdownValue > 0) {
      this.financialsForm.patchValue({ billing_frequency: this.frequencyDropdownValue });
    } else {
      this.financialsForm.patchValue({ billing_frequency: null });
    }
  }

  onCancel() {
    this.canceled.emit();
  }

  onFinancialSubmit(data: any): boolean {
    data = { ...data, currency_code: (this.defaultCurrency ? this.defaultCurrency.code : 'USD') };
    this.updateCompleted.emit(<Financial>data);
    return false;
  }

  onCurrencyChanged(data) {
    //debugger;
    this.defaultCurrency = this.tenantCurrencies.find(c => c.id == data);
  }

}
