<modal class="extra-wide no-footer-modal custom-modal-body">
  <modal-header>
    <b>Renewal Term for ({{selectedVendor}})</b>
    </modal-header>
  <modal-content class="p-0">
    <div class="d-flex justify-content-between py-2 px-1 notes note-info align-items-center mb-2">
      <div class="ml-2"> {{showAddEOTForm ? 'Create new Renewal Term ' : 'Select term from list'}}</div>
      <div class="mr-2">
        <button class="btn btn-primary btn-block" (click)="togglerAddTermFormHandler()">
          {{showAddEOTForm ? 'Back to term list ' : 'Create a new renewal Term'}}
          </button>
          </div>

    </div>
    <form [(formGroup)]="addEOTForm" *ngIf="showAddEOTForm" (ngSubmit)="onSubmitHandler(addEOTForm.value)">
      <div class="card">
        <div class="row card-body">
          <div class="col-4 ">
            <div class="mb-2">
              <mi-extended-input [inputControl]="addEOTForm.controls['type']" [labelRequired]="true"
                [inputErrors]="addEOTForm.controls['type'].errors" [inputTouched]="addEOTForm.controls['type'].touched"
                [inputType]="'select'" [labelText]="'End of Term Action'"
                [errorDefs]=" {required: 'Please select End of Term Action' }">
                <select formControlName="type" class="form-select form-control" (change)="onSelectChange()">
                  <option value>Please Select end of term action</option>
                  <option value="EVENT_TYPE_AUTO_RENEW">Auto Renew</option>
                  <option value="EVENT_TYPE_AUTO_CANCELLATION">Cancel</option>
                  </select>
                  </mi-extended-input>
                  </div>
                  </div>
                  <div class="col-4" *ngIf="addEOTForm.get('type').value !== 'EVENT_TYPE_AUTO_CANCELLATION'">
                    <div class="mb-2">
                      <mi-extended-input [inputControl]="addEOTForm.controls['auto_renew_day']"
                        [inputErrors]="addEOTForm.controls['auto_renew_day'].errors" [labelRequired]="true"
                        [inputTouched]="addEOTForm.controls['auto_renew_day'].touched" [inputType]="'number'"
                        [labelText]="'Auto-Renew Length (Months)'" [errorDefs]=" {required: 'Please enter auto renew length' }">
                        <input id="auto_renew_day" class="form-control" name="auto_renew_day" placeholder="Enter auto renew length "
                          formControlName="auto_renew_day" type="number">
                        </mi-extended-input>
                        </div>
                        </div>
                        <div class="col-4 ">
                          <div class="mb-2">
                            <mi-extended-input [inputControl]="addEOTForm.controls['notification_day']"
                              [inputErrors]="addEOTForm.controls['notification_day'].errors" [labelRequired]="true"
                              [inputTouched]="addEOTForm.controls['notification_day'].touched" [inputType]="'number'"
                              [labelText]="'Advanced Notification (Days)'"
                              [errorDefs]=" {required: 'Please enter advanced notification days' }">
                              <input id="notification_day" class="form-control" name="notification_day"
                                placeholder="Enter advanced notification days" formControlName="notification_day" type="number">
                            </mi-extended-input>
                          </div>
                        </div>
                        </div>
<div class="d-flex justify-content-end py-2 px-1 notes note-info ">
  <div>
    <button type="button" class="btn btn-outline-dark btn-md  mr-2" (click)="togglerAddTermFormHandler()">
      Cancel
    </button>
  </div>
  <div>
    <button [disabled]="!addEOTForm.valid" class="btn btn-primary btn-md mr-2" type="submit">
      Create Term
    </button>
  </div>
</div>
</div>
</form>
<div class="card" *ngIf="!showAddEOTForm">
  <mi-opacity-loader [ready]="isDataLoaded">
  <div class="sticky-header top-pagination custom-hover-effect">
        <div class="note note-info">
          <b>{{tenantServiceTerms.length }}</b>
          renewal terms records.
        </div>
        <ng2-smart-table [settings]="termsColumnsSettings" [source]="source" class="table-hover"
          (custom)="onSelect($event)"></ng2-smart-table>
        </div>
      </mi-opacity-loader>
        </div>
        </modal-content>
</modal>