import { Component, Input, OnInit, ViewChild, TemplateRef} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { MiServiceGroup } from '../../../shared/models/MiServiceGroup';
import { ADVANCED_SEARCH_TYPES } from 'src/shared/state/advanced-search/reducers';


@Component({
  templateUrl: './advanced-search-filter-modal.component.html',
  styleUrls: ['./advanced-search-filter-modal.component.scss']
})

export class AdvancedSearchFilterModalComponent implements OnInit {
  
  searchType: ADVANCED_SEARCH_TYPES = ADVANCED_SEARCH_TYPES.CONTACT;

  constructor(private modal: ModalRef) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modal.close({assignee: null});
  }

  selecteServiceGroup(serviceGroup: MiServiceGroup): void {
    this.modal.close(serviceGroup);
  }

  showAddServiceGroupStatus(status){
    // this.displayCancelButton = status;
  }
}

