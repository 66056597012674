import { Link } from 'src/shared/ng-models/Link.interface';
import { SectionDataTableColumn } from 'src/shared/ng-models/SectionDataColumn.interface';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import { Store } from '@ngrx/store';
import * as appDataReducer from "src/shared/state/app-data/reducers";
import html2pdf from 'html2pdf.js';
import { TenantReport } from 'src/shared/models/Tenant';

@Component({
  selector: 'mi-Tenant-reports',
  templateUrl: './tenant-reports.component.html',
  styleUrls: ['./tenant-reports.component.scss']
})

export class TenantReportComponent implements OnInit {

  @ViewChild('dataIntegrityPercentageTempl', { static: true }) dataIntegrityPercentageTempl: TemplateRef<any>;
  @ViewChild('mrcTmpl', { static: true }) mrcTmpl: TemplateRef<any>;
  @ViewChild('content', { static: false }) content: ElementRef;

  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Report Summary",
      url: "/report-summary",
      internal: true
    },
    {
      label: "Tenant reports",
      url: "/tenant-reports",
      internal: true
    }
  ];
  headerLinks: Link[];
  vendorsColumns: SectionDataTableColumn[] = [];
  tenantVendorReports = []
  tenantReportDetails: TenantReport
  currentUrl: string
  baseUrl: string
  vendorUrl: string
  addressUrl: string
  error: any = null;
  loaded: boolean = false
  topFiveCategories: any[]
  topFiveVendors: any[]
  tenantData: any

  constructor(
    private tenantService: TenantV2Service,
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.getTenantReportDetails()
  }

  getTenantReportDetails() {
    const tenantData = this.store.select(appDataReducer.getAppData);
    tenantData.first().subscribe((data) => {
      this.tenantData = data.tenantData

      this.tenantService.getTenantReport(this.tenantData.domain)
        .subscribe((response) => {
          this.tenantReportDetails = response
          const sortedVendors = this.tenantReportDetails.tenantVendors.sort((a, b) => b.cmrc - a.cmrc);
          this.topFiveVendors = sortedVendors.slice(0, 5)

          let AdditionalVendorsColumns = {
            vendorName: 'Future Vendor',
            accountidCount: 0,
            serviceCount: 0,
            cmrc: 0,
            dataIntegrityCmrcPercentage: 0,
            documentCount: 0,
            addressCount: 0,
          }


          if (this.topFiveVendors.length < 5) {
            const remainingSlots = 5 - this.topFiveVendors.length;
            this.topFiveVendors = [
              ...this.topFiveVendors,
              ...Array(remainingSlots).fill(AdditionalVendorsColumns)
            ];
          }

          let modifiedData = this.tenantReportDetails.productCategoryCmrcPercentages.map(item => {
            return {
              productCategoryName: item.productCategoryName,
              totalCmrcPercentage: parseFloat(item.totalCmrcPercentage.toFixed(1))
            };
          });

          let sortedCategories = modifiedData.sort((a, b) => b.totalCmrcPercentage - a.totalCmrcPercentage);
          this.topFiveCategories = sortedCategories.slice(0, 5)
          this.initializeTable()
        },
          (e) => {
            this.error = e;
          }
        )
    })
  }


  getSpacingArrayData(str) {
    return str.join(', ');
  }

  onVendorChanges(tenantVendorId: string) {
    console.log("here onVendorChanges", tenantVendorId)
  }

  downloadPDF() {
    const date = new Date(this.tenantReportDetails.reportDate);

    // Extracting date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    // Formatted date and time without space
    const formattedDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${milliseconds}`;

    let options = {
      margin: 0.4,
      filename: `${this.tenantData.display_name}-${formattedDateTime}`,
      image: {
        type: 'jpeg',
        quality: '1',
      },
      html2canvas: {
        scale: 3,
        useCORS: true
      },
      // jsPDF: {
      //   unit: 'in',
      //   format: 'a4',
      //   orientation: 'portrait'
      // },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      beforePageContent: (doc) => {
        // Add footer to each page
        const footer = 'Report generate by MISO3';
        doc.pEl[0].push({
          text: footer,
          alignment: 'center',
        });
      },
      header: {
        height: '50px',
        contents: function (page, pagesCount) {
          return "<div>" + (page) + " / " + pagesCount + "</div>";
        }
      },
      footer: {
        height: "50px", // Customize the height of the footer
        contents: `
          <div>
            <span>This is the footer</span>
          </div>`
      },
      // hook: {
      //   // Called before adding a new page
      //   beforePageBreak: function (pageData) {
      //     // Add text at the bottom of the page
      //     const xPosition = 1; // Adjust as needed
      //     const yPosition = 1; // Adjust as needed
      //     pageData.settings.doc.text("Your text here Your text hereYour text here", xPosition, yPosition);
      //   },
      // },
    }
    const pEl = document.getElementById('pEl');
    const page2 = document.getElementById('page-2');
    page2.style.marginTop = '70px';


    const customPdfSpacing = document.querySelectorAll('.pdf-spacing');


    // Iterate through each list item and apply box shadow
    customPdfSpacing.forEach(item => {
      const listItem = item as HTMLElement; // Assert type as HTMLElement
      listItem.style.margin = '50px 10px 0px';
    });




    const categoryItems = document.querySelectorAll('.category-spend li');


    // Iterate through each list item and apply box shadow
    categoryItems.forEach(item => {
      const listItem = item as HTMLElement; // Assert type as HTMLElement
      listItem.style.background = '#258fce0f';
    });


    console.log("here options", options)

    // const content = this.content.nativeElement;

    // // Append custom footer to content
    // const footer = document.createElement('div');
    // footer.innerHTML = '<div><b>This is a custom footer</b></div>';
    // footer.style.background = '#258fce0f';
    // footer.style.marginBottom = '0px';

    // pEl.appendChild(footer);


    html2pdf().from(pEl).set(options).save().then(() => {
      pEl.style.margin = '0px';
      page2.style.margin = '0px';

      const categoryItems = document.querySelectorAll('.category-spend li');

      // Iterate through each list item and apply box shadow
      categoryItems.forEach(item => {
        const listItem = item as HTMLElement; // Assert type as HTMLElement
        listItem.style.background = '#fff';
      });

    }).catch((error) => console.error('Error saving PDF:', error));
  }

  getPercentage(number) {
    const modifiedPercentage = number.toFixed(1);
    return modifiedPercentage + '%'
  }


  getDataIntegrityGrade(percent) {
    let grade, colorClass;
    switch (true) {
      case percent >= 0 && percent <= 59:
        grade = "F";
        colorClass = "red";
        break;
      case percent >= 60 && percent <= 69:
        grade = "D";
        colorClass = "orange";
        break;
      case percent >= 70 && percent <= 79:
        grade = "C";
        colorClass = "yellow";
        break;
      case percent >= 80 && percent <= 89:
        grade = "B";
        colorClass = "blue";
        break;
      case percent >= 90 && percent <= 100:
        grade = "A";
        colorClass = "green";
        break;
      default:
        grade = "Invalid input";
        colorClass = "black"; // or any other default color
    }
    return { grade, colorClass };
  }


  pluralizeWord(word, count) {
    if (count === 1) {
      return word;
    } else {
      return word + 's';
    }
  }


  getFormattedFloatNumber(number) {
    const modifiedFloatValue = number.toFixed(1);
    return modifiedFloatValue
  }

  initializeTable() {
    const browserUrl = window.location.href
    const parsedUrl = new URL(browserUrl);
    const baseUrl = parsedUrl.origin;
    this.baseUrl = baseUrl

    this.vendorUrl = this.baseUrl + '/vendors';
    this.addressUrl = this.baseUrl + '/addresses';

    this.loaded = true
    this.vendorsColumns = [
      {
        name: 'Vendor',
        prop: 'vendorName',
      },
      {
        name: 'Account',
        prop: 'accountidCount',
      },
      {
        name: 'Services',
        prop: 'serviceCount',
      },
      {
        name: 'Monthly Spend',
        prop: 'cmrc',
        cellTemplate: this.mrcTmpl,
      },
      {
        name: 'Data Integrity',
        prop: 'dataIntegrityCmrcPercentage',
        cellTemplate: this.dataIntegrityPercentageTempl,
      },
      {
        name: 'Documents',
        prop: 'documentCount',
      },
      {
        name: 'Locations',
        prop: 'addressCount',
      },
    ];
  }

}
