import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../state/advanced-search/reducers';
import {NO_DATA} from '../../../ng-models/SectionDataTable.model';
import {NOTIFICATION_SEVERITY} from '../../../utils/notificationSeverity';
import {Equipment} from '../../../models/Equipment';
import {SortableTemplatesEnum} from '../../../ng-models/SortableTableColumn.interface';
import {SectionDataTableColumn} from '../../../ng-models/SectionDataColumn.interface';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';
import { scrollTo } from 'src/shared/shared.functions';

@Component({
  selector: 'mi-advanced-search-equipment-results',
  templateUrl: './advanced-search-equipment-results.component.html',
  styleUrls: ['./advanced-search-equipment-results.component.scss']
})

export class AdvancedSearchEquipmentResultsComponent extends AbstractPageComponent implements OnInit{

  hits$: Equipment[];
  resultsLoaded$: boolean;
  readonly NO_DATA: string = NO_DATA;
  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;

  @ViewChild('vendorTmpl', {static: true}) vendorTmpl: TemplateRef<any>;
  @ViewChild('costTmpl', {static: true}) costTmpl: TemplateRef<any>;
  @ViewChild('modelTmpl', {static: true}) modelTmpl: TemplateRef<any>;
  @ViewChild('nameTmpl', {static: true}) nameTmpl: TemplateRef<any>;

  equipmentsColumns: SectionDataTableColumn[] = [];

  constructor(
    private store: Store<ASReducer.State>,
  ){
    super();
  }

  ngOnInit() {
    this.equipmentsColumns = [
      {
        name: "MISO ID",
        prop:'display_id',
        cellTemplate: SortableTemplatesEnum.idLink2,
        sortable: false
      },
      {
        name: "Manufacturer",
        prop:'',
        cellTemplate: this.vendorTmpl,
        sortable: true
      },
      {
        name: "Model #",
        prop: 'equipment_catalog.category_name',
        sortable: true,
        cellTemplate: SortableTemplatesEnum.truncate,
      },
      {
        name: 'Serial Number or Tag',
        prop: 'serial',
        cellTemplate: SortableTemplatesEnum.truncate,
        sortable: true
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: false,
        cellTemplate: this.nameTmpl,
      },
      {
        name: 'Location',
        prop: 'location',
        cellTemplate: SortableTemplatesEnum.location,
        sortable: true
      },
      {
        name: 'Price $',
        prop: 'price',
        cellTemplate: SortableTemplatesEnum.currency,
        sortable: true
      },
      {
        name: 'Status',
        prop: 'status',
        cellTemplate: SortableTemplatesEnum.status,
        sortable: true
      },
    ];

    this.store.select(ASReducer.getASData)
    .takeUntil(this.destroy$)
    .subscribe(
      data => {
        this.resultsLoaded$ = true;
        if (data.results && data.resultsLoaded) {
          this.hits$ = data.results.hits as Equipment[];
          scrollTo('AdvancedSearchEquipmentResultsComponent');
        }
      }
    );


  }

}
