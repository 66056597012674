import {Component} from '@angular/core';
import {Link} from '../../../../shared/ng-models/Link.interface';

@Component({
  styleUrls: ['./services-create.component.scss'],
  templateUrl: './services-create.component.html'
})

export class ServicesCreateComponent {
  breadcrumbs: Link[] = [
    {
      url: '',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true

    },
    {
      url: '/services/create',
      label: 'Services Create',
      internal: true
    }
  ];
  error: string;
}
