import {Component, OnInit} from '@angular/core';
import {OrderState, OrderSteps} from '../state/reducer';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import * as OrderReducers from '../state/reducer';
import {ResetOrderAction} from '../state/actions';
import {scrollTo} from "../../../../../../shared/shared.functions";

@Component({
  templateUrl: './service-order-create-form.component.html'
})
export class ServiceOrderCreateFormComponent extends AbstractPageComponent implements OnInit {

  error: string;
  loaded: boolean = false;
  readonly orderSteps: any = OrderSteps;
  order: OrderState;

  constructor(
    private activatedRoute:ActivatedRoute,
    private store: Store<OrderState>,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {

    this.store.dispatch(new ResetOrderAction({}));

    this.store.select(OrderReducers.getOrderData)
      .subscribe(
        (orderState) => {
          this.order = orderState;
          this.loaded = true;
          scrollTo(`section_${orderState.index}`);
        },
        (e) => {
          this.error = e;
        });
  }
}
