<!-- <div class="container-wrapper">
  <div id="border-div" class="border-container" [ngSwitch]="validationFlag">
    <label *ngIf="validationFlag === 'not-applicable'">Not Monitored by MISO</label>
    <label *ngIf="validationFlag !== 'not-applicable'">Last MISO Update - {{daysSinceLastUpdate}} Days</label>
  </div>
  </div> -->
  <div class="container-wrapper">
    <div *ngIf="validationFlag === 'not-applicable'" id="border-div" class="border-container not-applicable">
      <label>Not Monitored by MISO</label>
    </div>
    <div *ngIf="validationFlag === 'up'" id="border-div" class="border-container up">
      <label>Last MISO Update - {{daysSinceLastUpdate}} Days</label>
    </div>
    <div *ngIf="validationFlag === 'down'" id="border-div" class="border-container down">
      <label>Last MISO Update - {{daysSinceLastUpdate}} Days</label>
  </div>
</div>