import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {EmailFormRecipient} from "../../../../../shared/ng-models/EmailFormRecipient.interface";
import {Link} from "../../../../../shared/ng-models/Link.interface";
import {MiService} from "../../../../../shared/models/MiService";
import {TenantMiServiceService} from "../../../../../libs/api/tenant/tenantMiService.service";
import * as userReducer from "../../../../../libs/user-store/state/reducers";
import * as appDataReducer from '../../../../../shared/state/app-data/reducers';
import {Store} from "@ngrx/store";
import {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {NO_DATA} from "../../../../../shared/ng-models/SectionDataTable.model";
import {MisoCollection} from "../../../../../shared/models/MisoCollection.enum";
import {Email} from "../../../../../shared/models/Email.interface";
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {Note} from "../../../../../shared/models/Note.interface";
import {EmailRecipient} from "../../../../../shared/models/EmailRecipient.interface";
import {TenantNoteService} from "../../../../../libs/api/tenant/tenantNote.service";
import {PageAlertAddAction} from "../../../../../shared/state/page-alert/actions";
import {EMAIL_TYPE_REQUEST_QUOTE} from '../../../../../common/utils/sharedConstants';
import { misoReQuoteBody } from "../../../../../shared/utils/emailContent";
import { TenantEmailService } from "src/libs/api/tenant/tenantEmail.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TenantMiParentServiceService } from "src/libs/api/tenant/tenantMiParentService.service";
import { MiParentService } from "src/shared/models/MiParentService";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  styleUrls: ['./quote-miso-marketplace.component.scss'],
  templateUrl: './quote-miso-marketplace.component.html'
})

export class ParentQuoteMisoMarketplaceComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/services',
      label: 'Services',
      internal: true
    }
  ];
  cancelUrl: string;
  emailBody: string;
  emailRecipients: EmailFormRecipient[];
  emailSubject: string;
  emailType: string = EMAIL_TYPE_REQUEST_QUOTE;
  error: string;
  headerLinks: Link[];
  loaded: boolean = false;
  parentService: MiParentService;
  tenantContacts: EmailFormRecipient[];
  userData: any;
  tenantData: any;
  emailForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceService: TenantMiServiceService,
    private store: Store<userReducer.State>,
    private tenantNoteService: TenantNoteService,
    private router: Router,
    private tenantEmailService: TenantEmailService,
    private formBuilder: FormBuilder,
    private tpsService: TenantMiParentServiceService,
  ) {
    super();
  }

  ngOnInit(): void {

    // Find the current service ID:
    let parentServiceId = this.activatedRoute.snapshot.params['display_id'];

    // Update breadcrumbs:
    let newBreadcrumbs = [
      {
        url: `/parent-services/${parentServiceId}`,
        label: parentServiceId,
        internal: true
      },
      {
        url: `/parent-services/${parentServiceId}/miso-quote`,
        label: 'Request MISO Quote',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];

    const userStateData: Observable<userReducer.State> = this.store.select(userReducer.getUserState).takeUntil(this.destroy$);
    const parentService = this.tpsService.getByDisplayId(parentServiceId).toPromise()
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);

    combineLatest(userStateData, parentService, tenantStateData)
      .subscribe((data)=>{

        this.userData = data[0].secureUser.getSafeData();
        this.parentService = data[1];
        this.tenantData = data[2].tenantData;

        let emailBodyComponent = {
          user_email:this.userData.email,
          user_name: `${this.userData.first_name} ${this.userData.last_name}`,
          vendor_name: this.parentService.current_notification_service.product.product_vendor.vendor_name,
          recipient_first_name: this.parentService.current_notification_service.account_id && this.parentService.current_notification_service.account_id_v2.contact? this.parentService.current_notification_service.account_id_v2.contact.first_name: '',
          tenant_display_name: this.tenantData.display_name,
          services: this.parentService.services,
          service_end_date: this.parentService.current_notification_service.current_notification && this.parentService.current_notification_service.current_notification.notification_earliest_cancellation_date
        }
        this.cancelUrl = `/parent-services/${parentServiceId}`;
        this.emailBody = misoReQuoteBody(emailBodyComponent)
        let recipientArray = [];

        recipientArray.push({
          first_name: "Support",
          last_name: "MISO",
          email: "support@miso3.com"
        });


        this.emailRecipients = recipientArray;
        this.emailSubject = `Quote request from ${emailBodyComponent.user_name} @${this.tenantData.display_name}`;
        this.emailForm = this.formBuilder.group({
          toRecipients: [recipientArray, Validators.required],
          ccRecipients: [null],
          subject: [this.emailSubject, Validators.required],
          body: [this.emailBody, Validators.required],
          type: this.emailType
        });
        this.loaded = true;

      },
        e => this.error = e);


  }
  onSendEmail(data: any): void {
    let services = this.parentService.services
    let service_ids = [], service_display_ids = [];

    this.error = '';

    if (services && services.length) {
      service_ids = services.map(function (item) {
        return item['_id'];
      });
      service_display_ids = services.map(function (item) {
        return item['display_id'];
      });
    }

    const recipients: EmailRecipient[] = [];

    const allTo = [...data.toRecipients];
    allTo.forEach((recipient) => {
      recipients.push({
        name: `${recipient.first_name} ${recipient.last_name}`,
        email: recipient.email,
        type: 'to'
      });
    });

    const email: Email = {
      subject: data.subject,
      body: data.body,
      services: service_ids,
      service_display_ids: service_display_ids,
      recipients,
      type: this.emailType
    };

    this.tenantEmailService.createEmail(email)
      .subscribe(
        (emailResponse: Email) => this.onEmailCreated(emailResponse),
        (e) => {
          this.loaded = true;
          this.error = e;
        }
      );

    this.loaded = false;

  }


  onEmailCreated(email: Email) {

    const note:Note = {
      connections: [],
      html: `<p><strong>MISO Action</strong> — <em>Request Quote (MISO Marketplace)</em><br><a href="/emails/${email._id}">View Email</a></p>`
    };

    note.connections.push({
      document_id: this.parentService._id,
      document_collection: MisoCollection.PARENT_SERVICES
    });

    email.recipients.forEach( (recipient:EmailRecipient) => {
      if (recipient.collection_ref === MisoCollection.CONTACTS) {
        note.connections.push({
          document_id: recipient.collection_id,
          document_collection: MisoCollection.CONTACTS
        });
      }
    });

    this.tenantNoteService.createNote(note)
      .subscribe(
        note => {
          //log('note saved', note);
          this.store.dispatch(
            new PageAlertAddAction({
              message: `Request Quote Email has been sent to MISO Marketplace.`,
              alertType: "INFO"
            })
          );
          return this.router.navigate([`email-sent`], {relativeTo: this.activatedRoute});
        },
          e => this.error = `The email has been sent, but a note error occurred: ${e}`
      )

  }

}
