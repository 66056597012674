<div class="telephone-inputs">
  <div class="locality">

    <select [(ngModel)]="_value.locality"
            ngDefaultControl
            (ngModelChange)="onLocalityChange($event)"
            [disabled]="forceUS"
            id="select">
      <option value="" disabled="disabled">Select Locality</option>
      <option *ngFor="let locality of TELEPHONE_LOCALITIES" value="{{locality}}">
        {{ 'TELEPHONE_LOCALITIES.'+ locality | translate}}
      </option>
    </select>

  </div>
  <div class="number">
    <input [ngModel]="_value.display"
           type="tel"
           (blur)="inputTouched()"
           (ngModelChange)="onDisplayChange($event)"
           [placeholder]="placeholder"
           [miOnlyNumbers]="true"
           [maxlength]="maxLength"
           [allowArithmetic]="!isUSA()"
           id="input"></div>
</div>
