import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {AbstractPageComponent} from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../../../../shared/ng-models/SectionDataTable.model";
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../../../shared/state/advanced-search/reducers';
import {ADVANCED_SEARCH_TYPES} from '../../../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../../../shared/state/advanced-search/actions';
import {combineLatest, Observable} from "rxjs";
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';

@Component({
  templateUrl: './contact-search-mi-services.component.html'
})

export class ContactSearchMiServicesComponent extends AbstractPageComponent implements OnInit {

  readonly NO_DATA: string = NO_DATA;
  error: string = '';
  display_id: string;
  searchLoaded$: Observable<boolean>;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantContactV2Service: ContactV2Service,
              private store: Store<ASReducer.State>) {
    super();

  }

  ngOnInit() {

    //debugger;
    this.store.dispatch(new ASActions.AsReset({searchType: ADVANCED_SEARCH_TYPES.MI_SERVICE, pageLockFilters: ['contact_id', 'active_status', 'update']}));

    // Listen for search loaded
    this.searchLoaded$ = this.store.select(ASReducer.ASSearchLoaded);

    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.display_id = queryParams.display_id;
        // get the address data
        return combineLatest(
          this.tenantContactV2Service.getContactById(this.display_id),
          this.activatedRoute.queryParams
        );
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe( ([contactData, queryParams]:any[]) => {
        const newParams = {
          ...queryParams,
          "contact_id[]": contactData.id,
          "active_status[]": 'ACTIVE'
        };
        this.store.dispatch(new ASActions.AsDeeplinkChanged(newParams));
      });

  }
}
