<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="loaded && order; else loading">

  <h2>Order Info</h2>

  <mi-order-create-section
    id="section_0"
    [index]="0"
    [segment]="orderSteps.Vendor"
    title="What company did you order from?"
    [changeable]="false"
    >

    <mi-service-vendor-select></mi-service-vendor-select>

  </mi-order-create-section>

  <mi-order-create-section
    id="section_1"
    [index]="1"
    [segment]="orderSteps.OrderID"
    title="Order ID">

    <mi-service-order-id></mi-service-order-id>

  </mi-order-create-section>

  <mi-order-create-section
    id="section_2"
    [index]="2"
    [segment]="orderSteps.OrderDate"
    title="Order Date">

    <mi-service-order-date></mi-service-order-date>

  </mi-order-create-section>

  <mi-order-create-section
    id="section_3"
    [index]="3"
    [segment]="orderSteps.AccountID"
    title="Vendor Account ID">

    <mi-service-order-account-id></mi-service-order-account-id>

  </mi-order-create-section>

  <h2>Vendor Contacts</h2>

  <mi-order-create-section
    id="section_4"
    [index]="4"
    [segment]="orderSteps.Contacts"
    title="Associated Service Contacts">

    <mi-service-order-contacts>
    </mi-service-order-contacts>

  </mi-order-create-section>

  <h2>Uploads</h2>

  <mi-order-create-section
    id="section_5"
    [index]="5"
    [segment]="orderSteps.Uploads"
    title="Associated Documents">

    <mi-service-order-documents></mi-service-order-documents>

  </mi-order-create-section>

  <mi-service-order-add-order></mi-service-order-add-order>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
