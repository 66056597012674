import {Action} from '@ngrx/store';

export enum OrderActions {
  ResetOrderAction = '[Orders] Cancel Order',
  UpdateOrderAction = '[Orders] Update Order',
}


export class ResetOrderAction implements Action{
  readonly type = OrderActions.ResetOrderAction;
  constructor(public payload: {}){

  }
}

export class UpdateOrderAction implements Action {
  readonly type = OrderActions.UpdateOrderAction;
  constructor(public payload: {}) {

  }
}

export type Actions
  = OrderActions |
  ResetOrderAction |
  UpdateOrderAction;
