import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {SearchFilter} from "../../ng-models/SearchFilter.interface";
import { Contact } from "../../models/Contact";
import { CustomField } from "src/shared/models/CustomField";
import { SearchFilterV2 } from "src/shared/models/ServiceV2";
import { TenantDocumentManagementService } from "src/libs/api/tenant/tenantDocumentManagement.service";

@Component({
  selector: 'mi-applied-search-filter-item-v2',
  styleUrls: ['./applied-search-filter-item-v2.component.scss'],
  templateUrl: './applied-search-filter-item-v2.component.html'
})

export class AppliedSearchFilterItemV2Component implements OnInit {
  @Input() displayNone: boolean = false;
  @Input() filter: SearchFilterV2;
  @Input() customField: any = {};
  @Input() tagById: any = {};
  @Input() businessUnits: any = {};
  @Input() serviceGroupByIDs: any = {};
  @Input() accountIdsByIDs: any = {};
  @Input() contactsByIDs: any = {};
  @Output() remove:EventEmitter<SearchFilterV2> = new EventEmitter<SearchFilterV2>();
  isArray: boolean = false;
  documentDisplayId: string = "";

  constructor(
    private tenantDocumentManagementService: TenantDocumentManagementService
  ) {

  }

  ngOnInit() {
    //console.log("Filter item", this.filter);
    if(this.filter){
      this.isArray = this.filter.value instanceof Array;
      if(this.filter.filterName == "document_id") {
        this.getDocumentDisplayId(this.filter.value[0]);
      }
    }
  }

  onRemoveClick(filterValue: any): void {
    this.remove.emit({
      filterName: this.filter.filterName,
      value: filterValue,
      field_name: this.filter.field_name,
      field_type: this.filter.field_type,
      location_type: this.filter.location_type || null,
      removable: this.filter.removable
    });
  }

  getDocumentDisplayId(documentId): void {
    this.tenantDocumentManagementService.getDocumentById(documentId)
    .subscribe(document => {
      //console.log("document", document);
      this.documentDisplayId = document.display_id;
    }, e => {
      console.log("ERROR", e);
    });
  }

  tagHeaderName(filterName){
    const thisTag = this.tagById[filterName];
    if(!thisTag){
      return false;
    }
    return true;
  }

  getTagName(filterName) {
    return this.tagById[filterName];
  }
}
