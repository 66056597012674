import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MiService, MiServiceDetails } from "../../../../../../shared/models/MiService";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import { LocationV2 } from '../../../../../../libs/api2/types/LocationV2.interface';
import { SaveServiceAddress } from "../../../../../../libs/api2/types/AddressV2.interface";
import { AddressV2Service } from 'src/libs/api2/address-v2/address-v2.service';
import { ToastrService } from 'ngx-toastr';

@Component(
  {
    selector: 'mi-subsection-address-details',
    templateUrl: './subsection-address-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionAddressDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: boolean = false;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiService> = new EventEmitter<MiService>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  error: string = '';
  isOpen: boolean = false;
  readonly NO_DATA: string = NO_DATA;
  serviceDetails: MiServiceDetails;
  readonly FORM: string = 'Standard Form Display';
  readonly FORM_EDIT: string = 'Edit Mode for Adding Addresses';
  formEnabled: boolean = false;
  mode: string = this.FORM;
  locationState: boolean = false;
  updating: boolean = false;
  locationA: LocationV2;
  locationB: LocationV2
  locationsData: LocationV2[] = []
  pageLoaded: boolean = false
  showDoneButton: boolean = false;
  constructor(
    private addressV2Service: AddressV2Service,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails;
            this.locationsData = []
            if (this.serviceDetails.dates_and_locations.location_a_name) {
              this.locationA = {
                address: {
                  city: this.serviceDetails.dates_and_locations.city,
                  latitude: this.serviceDetails.dates_and_locations.location_a_coordinate?.latitude,
                  longitude: this.serviceDetails.dates_and_locations.location_a_coordinate?.longitude,
                  street_address: this.serviceDetails.dates_and_locations.location_a_street,
                  formatted_address: this.serviceDetails.dates_and_locations.location_a_name,
                  state_name: this.serviceDetails.dates_and_locations.state_name,
                  country_name: this.serviceDetails.dates_and_locations.country_name,
                },
                address_id: this.serviceDetails.dates_and_locations.location_a_id,
                id: this.serviceDetails.dates_and_locations.location_a_id,
                nick_name: "",
                owner: this.serviceDetails.general.tenant_id,
                label: this.serviceDetails.dates_and_locations.location_a_name,
                service_property_id :"location_a"
              }
              this.locationsData.push(this.locationA)
            }

            if (this.serviceDetails.dates_and_locations.location_b_name) {
              this.locationB = {
                address: {
                  city: this.serviceDetails.dates_and_locations.city,
                  latitude: this.serviceDetails.dates_and_locations.location_b_coordinate?.latitude,
                  longitude: this.serviceDetails.dates_and_locations.location_b_coordinate?.longitude,
                  street_address: this.serviceDetails.dates_and_locations.location_b_street,
                  formatted_address: this.serviceDetails.dates_and_locations.location_b_name,
                  state_name: this.serviceDetails.dates_and_locations.state_name,
                  country_name: this.serviceDetails.dates_and_locations.country_name,
                },
                address_id: this.serviceDetails.dates_and_locations.location_b_id,
                id: this.serviceDetails.dates_and_locations.location_b_id,
                nick_name: "",
                owner: this.serviceDetails.general.tenant_id,
                label: this.serviceDetails.dates_and_locations.location_b_name,
                service_property_id :"location_b"
              }
              this.locationsData.push(this.locationB)
            }
          }
          this.pageLoaded = true
        });
  }

  initLocations() {
    return this.locationsData.map(loc => loc);
  }

  onRemoveLocation(locationToRemove: LocationV2): void {
    console.log("here locationToRemove", locationToRemove.service_property_id)
    // this.locationsData = this.locationsData.filter(loc => loc.id !== locationToRemove.id);
    this.locationsData = this.locationsData.filter(loc => loc.id !== locationToRemove.id || loc.service_property_id !== locationToRemove.service_property_id);
    this.formEnabled = true;
    this.showDoneButton = true;
    this.addressV2Service.removeServiceAddressService(this.serviceDetails.service_data.service_id,locationToRemove.service_property_id)
      .toPromise()
      .then(res => {
        this.toastr.success(`Address removed successfully`);
      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while removing address, Please try again`);
      })
      .finally(() => {
        this.updating = false;
      });
  }

  onLocationSelected(location: LocationV2) {
    this.locationState = false;
    if (this.locationsData.indexOf(location) === -1) {
      this.locationsData.push(location);
      this.mode = this.FORM;
      this.formEnabled = true;
    }
  }

  onToggleEditMode(): void {
    this.mode = this.mode === this.FORM ? this.FORM_EDIT : this.FORM;
  }

  onToggleExpand(): void {
    if (this.isOpen) {
      this.formEnabled = false;
    }
    this.isOpen = !this.isOpen;
  }

  onCancel(): void {
    this.locationsData = this.initLocations();
    this.onToggleExpand();
  }

  onAddressSelected(status: boolean) {
    this.locationState = true;
  }

  onUpdateLocations(): void {
    this.updating = true;
    const locationsPayload = [] as SaveServiceAddress[]

    if (this.locationsData.length <= 1) {
      const locationAData = {
        system_id: this.locationsData[0].id,
        service_property_id: "location_a",
        resolution_value: this.locationsData[0].id
      }
      locationsPayload.push(locationAData)
    }
    if (this.locationsData.length == 2) {
      const locationBData = {
        system_id: this.locationsData[1].id,
        service_property_id: "location_b",
        resolution_value: this.locationsData[1].id
      }
      locationsPayload.push(locationBData)
    }

    this.addressV2Service.saveServiceAddressService(locationsPayload, this.serviceDetails.service_data.service_id)
      .toPromise()
      .then(res => {
        this.toastr.success(`Address updated successfully`);
        this.onToggleExpand();
        this.updateServiceDetailsStore.emit();
      })
      .catch(e => {
        this.error = e
        this.toastr.error(`Facing issue while updating address, Please try again`);
      })
      .finally(() => {
        this.updating = false;
      });
  }
  onDoneHandler(): void {
    this.showDoneButton = false;
    this.onToggleExpand();
  }
}