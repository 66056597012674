import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {SaveSearch} from '../../../shared/models/SaveSearch';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class SaveSearchService {

  private apiUrlCrud = '/{tenant}/save-search';
  private headers = new Headers({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) {
  }

  listSaveSearches(saveSearch: any): Observable<Array<SaveSearch>> {
    return this.http
      .get(`/${this.apiUrlCrud}/user/`)
      .map((response: any) => response.data.save_searches);
  }

  createSaveSearch(saveSearch: SaveSearch): Observable<SaveSearch> {
    return this.http
      .post(`/${this.apiUrlCrud}/user/`, saveSearch)
      .map((response: any) => response.data.save_search);
  }

  deleteSaveSearch(saveSearch: SaveSearch): Observable<SaveSearch> {
    return this.http
      .delete(`/${this.apiUrlCrud}/delete/${saveSearch._id}`)
      .map((response: any) => response.data.save_search);
  }

}
