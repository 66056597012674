<div>

  <mi-page-header
    title="MISO Marketplace Request Quote"
    [breadcrumbs]="breadcrumbs"
    [links]="headerLinks">
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>
  <mi-page-alert></mi-page-alert>
  <div class="to-container">
    <strong>To: </strong> MISO Marketplace
  </div>

  <p class="instruction"><strong>Please add any additional instruction.</strong></p>

  <ng-container *ngIf="!error">
    <form *ngIf="loaded; else loading"
    [formGroup]="emailForm"
    (submit)="onSendEmail(emailForm.value)">

      <angular-tinymce formControlName="body"></angular-tinymce>

      <div class="button-container">
        <a 
          mi-button
          class="button light-theme"
          routerLink="../">
          Cancel
        </a>
        <button type="submit"
                mi-button
                buttonTheme="dark-theme"
                [disabled]="!emailForm.valid">
          Send Email
        </button>
      </div>

    </form>
  </ng-container>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
