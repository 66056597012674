<div>
  <mi-page-header
    title="{{ 'TENANT.SERVICE_LISTING_TITLE' | translate }}"
    [breadcrumbs]="breadcrumbs"
    [links]="headerLinks"
    [videoLink]="videoLink"
    [videoModalTitle]="videoModalTitle"
  >
  </mi-page-header>
    <mi-server-error [error]="error"></mi-server-error>
  
    <mi-page-alert></mi-page-alert>
  
    <mi-opacity-loader [ready]="searchLoaded">
      <div class="mi-grid-aside-basic">
        <div class="col-all">
          <div class="card mb-2">
          <mi-advanced-search-header-v2
            searchType="SERVICE"
            saveSearch="true">
          </mi-advanced-search-header-v2>
          </div>
          
          <mi-applied-search-filters-v2 [nonRemovableFilters]="['service_status']"></mi-applied-search-filters-v2>
          <mi-advanced-search-service-results-table-v2></mi-advanced-search-service-results-table-v2>
          <mi-advanced-search-load-more-v2></mi-advanced-search-load-more-v2>
        </div>
      </div>
    </mi-opacity-loader>

</div>