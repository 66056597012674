import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';


import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {CustomField} from '../../../shared/models/CustomField';

const log = createLogger(LOG_LEVELS.HTTP_API);


@Injectable()
export class TenantCustomFieldService {

  private apiUrlCustomFieldCRUD = '/{tenant}/custom-fields';
  private apiUrlListCustomFieldsWithVendor = '/{tenant}/custom-fields/list';
  private apiUrlCustomFieldUpdatePriorities = '/{tenant}/custom-fields/update-priority';
  private apiUrlCustomFieldByDisplayIdCRUD = '/{tenant}/custom-fields/by-display-id';

  constructor(private http: HttpClient) {
  }

  listCustomFields(): Observable<Array<CustomField>> {
    return this.http
      .get(`${this.apiUrlCustomFieldCRUD}`)
      .map((response: any) => {
        return response.data.customFields as Array<CustomField>;
      });
  }

  listCustomFieldsWithVendor(): Observable<Array<CustomField>> {
    return this.http
      .get(`${this.apiUrlListCustomFieldsWithVendor}`)
      .map((response: any) => {
        return response.data.customFields as Array<CustomField>;
      });
  }

  updateCustomFieldsPriorities(customFields: CustomField[]): Observable<Array<CustomField>> {
    return this.http
      .put(`${this.apiUrlCustomFieldUpdatePriorities}`, {customFields})
      .map((response: any) => {
        return response.data.customFields as Array<CustomField>;
      });
  }

  updateCustomField(customField: CustomField): Observable<CustomField> {
    return this.http
      .put(`${this.apiUrlCustomFieldCRUD}/${customField._id}`, {customField})
      .map((response: any) => {
        return response.data.customField as CustomField;
      });
  }

  updateCustomFieldByDisplayId(customField: CustomField): Observable<CustomField> {
    return this.http
      .put(`${this.apiUrlCustomFieldByDisplayIdCRUD}/${customField.display_id}`, {customField})
      .map((response: any) => {
        return response.data.customField as CustomField;
      });
  }

  createCustomField(customField: CustomField): Observable<CustomField> {
    return this.http
      .post(this.apiUrlCustomFieldCRUD, customField)
      .map((response: any) => {
        return response.data.customField as CustomField;
      });
  }

  getCustomField(displayId: String): Observable<CustomField> {
    return this.http
      .get(`${this.apiUrlCustomFieldByDisplayIdCRUD}/${displayId}`)
      .map((response: any) => {
        return response.data.customField as CustomField;
      });
  }

  deleteCustomField(customFieldId: String): Observable<CustomField> {
    return this.http
      .delete(`${this.apiUrlCustomFieldCRUD}/${customFieldId}`)
      .map((response: any) => {
        return response.data.customField as CustomField;
      });
  }

}
