import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {TenantComponentsModule} from '../../components/tenant-components.module';
import {TranslateModule} from '@ngx-translate/core';
import {ProductDetailComponent} from './instance-wrap/detail/product-detail.component';
import {ProductInstanceWrapComponent} from './instance-wrap/product-instance-wrap.component';
import {ProductSearchEventsComponent} from './instance-wrap/search-events/product-search-events.component';
import {ProductSearchMiServicesComponent} from './instance-wrap/search-mi-services/product-search-mi-services.component';
import {ProductEditComponent} from './instance-wrap/edit/product-edit.component';
import { ProductDeleteComponent } from './instance-wrap/delete/product-delete.component';

@NgModule({
  declarations: [
    ProductInstanceWrapComponent,
    ProductDetailComponent,
    ProductSearchEventsComponent,
    ProductSearchMiServicesComponent,
    ProductEditComponent,
    ProductDeleteComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
  ],
  providers: [],
  exports: [
  ]
})
export class ProductsModule {
}
