import {Component} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {SEARCH_TYPE_SERVICETERM, ID_KEY} from "../../../../components/detail-search/detail-search.component";

const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  templateUrl: './service-term-search-events.component.html'
})
export class ServiceTermSearchEventsComponent {
  readonly SEARCH_TYPE_SERVICETERM: string = SEARCH_TYPE_SERVICETERM;
  queryParams: any = {
    "service_term[]": ID_KEY
  }
}
