import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import {NO_DATA} from "../../../../../shared/ng-models/SectionDataTable.model";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {MiOrder} from "../../../../../shared/models/MiOrder";
import {TenantMiOrderService} from "../../../../../libs/api/tenant/tenantMiOrder.service";

@Component(
  {
    selector: 'mi-service-order-summary-services',
    templateUrl: './service-order-summary-services.component.html',
    styleUrls: ['./service-order-summary-services.component.scss'],
  }
)

export class ServiceOrderSummaryMiServicesComponent extends AbstractPageComponent {

  @Input() order: MiOrder;

  constructor() {
    super();
  }

}
