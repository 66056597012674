import {Action} from '@ngrx/store';

export enum ParentServiceActions {
  ResetParentServiceAction = '[ParentService] Cancel',
  UpdateParentServiceAction = '[ParentService] Update Parent Service',
}

export class ResetParentServiceAction implements Action{
  readonly type = ParentServiceActions.ResetParentServiceAction;
  constructor(public payload: {}){

  }
}

export class UpdateParentServiceAction implements Action {
  readonly type = ParentServiceActions.UpdateParentServiceAction;
  constructor(public payload: {}) {

  }
}

export type Actions
  = ParentServiceActions |
  ResetParentServiceAction |
  UpdateParentServiceAction;
