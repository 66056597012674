import { Component, Input, OnInit, ViewChild, TemplateRef} from "@angular/core";
import { ModalRef } from '@independer/ng-modal';
import { MiServiceGroup } from '../../../shared/models/MiServiceGroup';


@Component({
  templateUrl: './select-service-group-modal.component.html',
  styleUrls: ['./select-service-group-modal.component.scss']
})

export class SelectServiceGroupModalComponent implements OnInit {
  displayCancelButton : boolean = true;
  defaultSearchText: string = '';
  title: string = 'Select Service Group';

  constructor(private modal: ModalRef) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modal.close(null);
  }

  selecteServiceGroup(serviceGroup: MiServiceGroup): void {
    this.modal.close(serviceGroup);
  }
  showAddServiceGroupStatus(status){
    this.displayCancelButton = status;
  }
}

