<ng-container *ngIf="orderState.vendor; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <div>

      <ng-container [ngSwitch]="formStep">
        <ng-container *ngSwitchCase="0">
          <h2>Who manufactures the product?</h2>
          <div class="half-col">
            <mi-find-or-create-vendor (vendorSelected)="onVendorSelected($event)">
            </mi-find-or-create-vendor>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <h2>What product or service did you purchase?</h2>

          <mi-product-search (onUnSelectVendor)="onUnselectManufacturingVendor()" [productServicesMode]="tenantProductServicesMode"
            (selecteProduct)="onSelectProduct($event)" [vendor]="productForm.get('manufacturing_vendor').value"
            [dummyProduct]="dummyProduct">
          </mi-product-search>
          <br />
          <h3>Search other vendors</h3>
  <!-- <button 
        mi-button
        type="button"
        buttonTheme="light-theme"
        (click)="onVendorReset($event)">Search for a product form Another Vendor</button> -->
          <a href="#" class="link--before" (click)="onVendorReset($event)">Search other vendors for this product or
            service</a>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <dl>
            <dt>Product</dt>
            <dd>{{this.product.product_name}}</dd>
          </dl>

          <ng-container *ngIf="this.product && this.product.product_category && this.product.product_category.include_equipment">
            <div class="step-form__sub_step">
              <h2>Check what equipment associated to this service?</h2>
              <div class="sections">
                <section class="data-section">
                  <h2>Equipment</h2>
                  <mi-select-service-equipments [selectedEquipments]="selectedEquipments" (equipmentSelected)="onEquipmentSelected($event)"
                    (equipmentRemoved)="onEquipmentRemoved($event)">
                  </mi-select-service-equipments>
                </section>
              </div>
            </div>
          </ng-container>

        </ng-container>
      </ng-container>
      <div class="half-col">
        <form [formGroup]="productForm" (submit)="onFormSubmit(productForm.value)">
          <div class="button-container">
            <a mi-button buttonTheme="light-theme" (click)="onCancelCLicked()">
              Cancel
            </a>
            <button type="submit" mi-button buttonTheme="dark-theme" [disabled]="!productForm.valid">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>

  </ng-container>

  <ng-template #static>
    <dl>
      <dt>Product</dt>
      <dd>
        <ng-container *ngIf="this.product;else noData">
          {{this.product.product_name}}
        </ng-container>
        <ng-template #noData>
          {{NO_DATA}}
        </ng-template>
      </dd>
      <dt *ngIf="this.product && this.product.product_category && this.product.product_category.include_equipment">
        Associated Equipment
      </dt>
      <dd *ngIf="this.product && this.product.product_category && this.product.product_category.include_equipment">
        <mi-equipments-service-static
          [showLinks]="false"
          [currentEquipments]="selectedEquipments">
        </mi-equipments-service-static>
      </dd>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
