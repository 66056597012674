<div class="profile-container" (click)="toggleOpen()">
  <div class="profile-details">
    <div class="user-name"><b>{{dropdownTitle}}</b></div>
    <div class="user-access" [ngClass]="isSuperUser ? 'color-red' : ''">
      {{user.email}}
    </div>
  </div>
  <div class="profile-img">
    <img class="profile-pic" src="/shared/assets/images/miso-user-profile.jpeg" alt="profile-img">
  </div>
</div>

<ul class="dropdown-items">
  <ng-container *ngIf="!customList">
    <li class="dropdown-item" (click)="onItemSelected(item)" *ngFor="let item of this.items">
      <div class="dropdown-item-title">
        {{item.title}}
      </div>
      <div class="dropdown-item-subtitle">
        {{item.subtitle}}
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="customList">
    <ng-content>
    </ng-content>
  </ng-container>
</ul>