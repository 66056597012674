<modal [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true" class="wide">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <div class="form-container" *ngIf="pageLoaded">
      <form [formGroup]="form" autocomplete="off">
        <div class="row">
          <mi-extended-input [errorDefs]="{required: 'Please select a Tag'}"
                             [labelRequired]="true"
                             [inputControl]="form.controls['tag_id']"
                             [inputErrors]="form.controls['tag_id'].errors"
                             [inputTouched]="form.controls['tag_id'].touched"
                             class="half-column"
                             labelText="Tag">
            <ng-select *ngIf="mode == 'CREATE'" class="mi-select" [items]="allTags" [multiple]="false"
                        bindLabel="tagName" bindValue="tagId"
                        [closeOnSelect]="true" formControlName="tag_id"
                        placeholder='Choose a Tag' 
                        appendTo="body" (change)="selectTag()">
            </ng-select>
            <ng-select *ngIf="mode == 'EDIT'"  class="mi-select" [items]="currentTagArray" [multiple]="false"
                        bindLabel="tag_name" bindValue="tag_id"
                        [closeOnSelect]="true" formControlName="tag_id"
                        placeholder='Choose a Tag' 
                        appendTo="body">
            </ng-select>
          </mi-extended-input>
        </div>
        <div class="row" *ngIf="tagSelected">
          <mi-extended-input [errorDefs]="{required: 'Please enter a Value'}"
                             [labelRequired]="true"
                             [inputControl]="form.controls['tag_value']"
                             [inputErrors]="form.controls['tag_value'].errors"
                             [inputTouched]="form.controls['tag_value'].touched"
                             class="half-column"
                             labelText="Tag">
            <ng-select *ngIf="isMultiValue" class="mi-select" [items]="allTagOptions" [multiple]="false"
                        bindLabel="tagValue" bindValue="tagValue"
                        [closeOnSelect]="true" formControlName="tag_value"
                        placeholder='Select a Value'
                        appendTo="body" (change)="validateData()">
            </ng-select>
            <input *ngIf="!isMultiValue" class="form-control"
                    formControlName="tag_value"
                    type="text"
                    (keyup)="validateData()">
          </mi-extended-input>
        </div>
      </form>
    </div>
  </modal-content>
  <modal-footer>
    <div class="cta">
      <button (click)="submit()"
              [disabled]="!editTagDataValid"
              mi-button buttonTheme="dark-theme">
        {{buttonLabel}}
      </button>
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        {{'GLOBAL.BUTTON_CANCEL' | translate}}
      </button>
    </div>
  </modal-footer>
</modal>

