<ng-container>
  <mi-server-error [error]="error"></mi-server-error>
  <mi-loading-animation class="first-load" *ngIf="!hasLoaded"></mi-loading-animation>
  <ng-container *ngIf="hasLoaded">
    <ng-container *ngIf="!selectedVendor">
      <mi-search
        value="{{searchString}}"
        [placeholder]="placeholder"
        qtyMessage="{{ qtyMessage | translate:{count:total} | translateSelector:total }}"
        (searchTermChanged)="onSearchTermChanged($event)"
        type="primary">
      </mi-search>
    </ng-container>

    <ng-container *ngIf="selectedVendor">
      <table class="vendors selected-vendor">
        <tr class="selected"
            (click)="unSelectVendor()">
          <td>
            <div class="vendor">
              <input type="checkbox"
                     value="{{selectedVendor._id}}"
                     checked="true"
              />
              <mi-vendor-logo [url]="selectedVendor.logo"></mi-vendor-logo>
              {{selectedVendor.vendor_name}}
            </div>
          </td>
          <td class="vendor-id">
            <span *ngIf="selectedVendor.display_id">{{selectedVendor.display_id}}</span>
          </td>
        </tr>
      </table>
      <a mi-button
         (click)="onCtaClicked()"
         *ngIf="ctaLabel">
        {{ctaLabel}}
      </a>
    </ng-container>

    <ng-content *ngIf="vendors.length === 0 && !selectedVendor"></ng-content>

    <table class="vendors" *ngIf="vendors.length > 0 && !selectedVendor">
      <tr>
        <th></th>
        <th>Vendor</th>
        <th>Existing Portfolio Vendor</th>
      </tr>

      <ng-container *ngFor="let vendor of vendors">
        <tr (click)="selectVendor(vendor)">
          <td width="40">
            <div class="vendor">
              <input type="checkbox"
                     value="{{vendor._id}}" />
            </div>
          </td>
          <td>
            <div class="vendor">
              <mi-vendor-logo [url]="vendor.logo"></mi-vendor-logo>
              <span class='vendor-name'>{{vendor.vendor_name}}</span>
            </div>
          </td>
          <td class="vendor-id">
            {{vendor.display_id ? vendor.display_id : ''}}
          </td>
        </tr>
      </ng-container>

    </table>


    <mi-loading-animation class="first-load" *ngIf="loadingMore">
    </mi-loading-animation>

    <div class="load-more"
         *ngIf="!loadingMore && (vendors.length < total) && !selectedVendor">
      <a href="#" (click)="loadMoreClicked($event)">Load More</a>
    </div>

  </ng-container>

</ng-container>
