import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

import {Observable} from 'rxjs';
import {of} from 'rxjs';

import {Store} from '@ngrx/store';
import * as userReducer from '../../user-store/state/reducers';
import * as userActions from '../../user-store/state/actions';

import * as linkFormatter from '../../../common/utils/linkFormatter';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {UserStoreService} from "../../user-store/user-store.service";

const log = createLogger(LOG_LEVELS.AUTH_GUARD);

@Injectable()
export class SuperUserAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userStoreService: UserStoreService,
    private store: Store<userReducer.State>
  ) {
  }

  canActivate(): Observable<boolean> {

    return this.userStoreService.getLoggedInService()
      .first()
      .map((res) => {
        //log("this.authService.getLoggedInUser()", res)
        // lets keep the user store updated:
        this.store.dispatch(new userActions.UserVerifySuccessAction(res));

        const isSuperUser = res.secureUser && res.secureUser.isSuperUser();
        // if this person is not able to navigation we must send him to 404
        if (!isSuperUser) {
          this.router.navigate(['/403']);
        }
        return isSuperUser;
      })
      .catch((error: any) => {
        log('this person was not logged in');
        window.location.href = linkFormatter.getLoginLink(window.location.href);
        return of(false);
      });
  }
}
