<ul class="radio-collection account-ids">
  <ng-container *ngIf='!textFirst'>
    <li *ngFor="let option of buttonOptions; let idx = index;" class="row">
<!--      OPTION: {{ option }}-->
      <input type="radio"
              id="{{ inputId + '_' + idx }}"
              name="{{ inputId }}"
              [checked]="idx === selectedIndex"
              [value]="option.label"
              (change)="onSelectionChange(option.label)">
      <label for="{{ inputId + '_' + idx }}"
             class="half-column">
        {{ option.label }}
      </label>

      <label for="{{ inputId + '_' + idx }}"
             class="half-column">
        {{ option.nick_name }}
      </label>
    </li>
  </ng-container>
  <li *ngIf="allowNew" class="row">
    <input type="radio"
           name="{{ inputId }}"
           id="{{ allowNewId }}"
           class="radio-button"
           placeholder="{{ placeholder }}"
           [checked]="buttonOptions.length == 0 || newValue"
           [(value)]="newValue"
           (change)="onSelectionChange(newValue)">

    <label for="{{ allowNewId }}"
           (focus)="onSelectionChange(newValue)"
           class="half-column">
      <input class="radio-button-label"
             placeholder="{{ placeholder }}"
             (focus)="onSelectionChange(newValue)"
             [(ngModel)]="newValue"
             (ngModelChange)="onSelectionChange($event)"
             [checked]="textFirst">
    </label>

    <input class="radio-button-label half-column"
             placeholder="New Nickname"
             (focus)="onSelectionChange(newNickname, true)"
             [(ngModel)]="newNickname"
             [disabled]="newValue == ''"
             (ngModelChange)="onSelectionChange($event, true)"
             [checked]="textFirst" />
  </li>
</ul>
