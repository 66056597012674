<div class="tab-title-header">
  <h2>Parent Child Details</h2>
  <a class="edit-link button dark-theme" (click)="addServiceModal()">Add Service</a>
</div>
<div class="d-flex align-items-center" *ngIf="parentNameForm!== null">
  <form [(formGroup)]="parentNameForm" (ngSubmit)="parentNameFormSubmit(parentNameForm.value)" autocomplete="off">
    <mi-extended-input [inputControl]="parentNameForm.controls['name']"
      [inputErrors]="parentNameForm.controls['name'].errors" [inputTouched]="parentNameForm.controls['name'].touched"
      [errorDefs]="{required: 'Name is required'}">
      <h2 class="parent-name-display-id">{{serviceDetails.general.parent_service_display_id}}</h2>
      <input id="name" class="parent-name-input" name="name" placeholder="Parent Name" formControlName="name"
        type="text">
    </mi-extended-input>
    <button mi-button class="button mt-1 darkTheme" type="submit">
      Save
    </button>
  </form>
</div>
<mi-server-error [error]="error"></mi-server-error>
<mi-alert-item *ngFor="let alert of alerts" [alertType]="alert.alertType">
  <span [innerHTML]="alert.message"></span>
</mi-alert-item>
<ng-container *ngIf="pageLoaded; else loading">
  <ng-container *ngIf="isOpen; else static">
    <mi-opacity-loader [ready]="!updating">
    </mi-opacity-loader>
  </ng-container>
  <ng-template #static>
    <mi-service-parent-static (dataUpdate)="onUpdate()" [parent_service_group]="parentServiceGroup">
    </mi-service-parent-static>
  </ng-template>
</ng-container>
<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>