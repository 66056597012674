import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpXhrBackend,
  XhrFactory,
  HttpParameterCodec,
  HttpParams
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import * as linkFormatter from '../../../../common/utils/linkFormatter';
import {settings} from '../../../../common/utils/SettingsUtil';
import {getAuthCookie, getAuthCookieV2} from '../../../user-store/state/auth-cookie';
import {tap} from 'rxjs/operators';
import FrontEndSettingsUtil from "../../../../shared/utils/frontEndSettings.util";


let isIE = false;

if (/msie\s|trident\//i.test(window.navigator.userAgent)) {
  isIE = true;
}
if (/edge\//i.test(window.navigator.userAgent)) {
  isIE = true;
}


@Injectable()
export class MisoHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /*
    Query manipulation moved to the Backend Interceptor
    Matches previous methods.
    */

    const handler = next.handle(req)
      .pipe(
        tap(InterceptorHelpers.logResponseData)
      )
      .catch( (e:any) => {

        if(isIE){
          let message = 'Unknown Error. If your difficulties persist, please contact customer support.';
          if(e.error && e.error.status && e.error.status.error &&  e.error.status.error.description) {
            message =  e.error.status.error.description;
          }
          throw new Error(message);
        }

        return throwError(e.error);
      });

    return handler;
  }
}

@Injectable()
export class MisoHttpBackEndInterceptor extends HttpXhrBackend {

  constructor(xhrFactory: XhrFactory) {
    super(xhrFactory);
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {

    const cookie = getAuthCookie();
    const cookieV2 = getAuthCookieV2();

    let newReq = req.clone();

    let isLocalRequest = InterceptorHelpers.isLocalRequest(newReq.url);
    let isMicroServiceRequest = InterceptorHelpers.isMicroServiceRequest(newReq.url);

    if (isLocalRequest || isMicroServiceRequest) {

      if (cookie) {
        newReq = newReq.clone({
          headers: newReq.headers.append('Authorization', cookie),
          withCredentials: isLocalRequest
        });
      } else {
        newReq = newReq.clone({
          headers: newReq.headers.delete('Authorization'),
          withCredentials: isLocalRequest
        });
      }
      if(cookieV2) {
        newReq = newReq.clone({
          headers: newReq.headers.append('Authorization', `Bearer ${cookieV2}`) //,
          // withCredentials: isMicroServiceRequest
        });
      } else {
        newReq = newReq.clone({
          headers: newReq.headers.delete('Authorization')//,
          //withCredentials: isMicroServiceRequest
        });
      }
      if (!req.headers.has('Content-Type') && !req.headers.has('ignore-content-type-inject') && req.url != "_MICRO_SERVICE_/vendor/vendors/logo" ) {
        newReq = newReq.clone({
          headers: newReq.headers.set('Content-Type', 'application/json')
        });
      }
    }

    /*
    if (isLocalRequest){
      newReq = newReq.clone({
        url: InterceptorHelpers.updateUrl(newReq.url)
      });
    }
    */
    //SRIJAN#
    if (isMicroServiceRequest){
      newReq = newReq.clone({
        url: InterceptorHelpers.updateMicroServiceUrl(newReq.url)
      });
    }

    const params = new HttpParams({encoder: new MisoCustomEncoder(), fromString: req.params.toString()});

    return super.handle(newReq.clone({params}));

  }

}

class MisoCustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}


class InterceptorHelpers {

  static isLocalRequest(url: string): boolean {
    return url.indexOf('~') === 0 || url.indexOf('/') === 0;
  }

  static isMicroServiceRequest(url: string): boolean {
    return url.indexOf('_MICRO_SERVICE_') === 0;
  }

  /*
  static updateUrl(req: string) {
    const replacements = {
      tenant: FrontEndSettingsUtil.get() && FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
      ? FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN
      : linkFormatter.getSubdomainFromHost(window.location.host)
    };
    return settings.API_URL + linkFormatter.supplant(req, replacements);
  }
  */

  static updateMicroServiceUrl(req: string) {
    const MICRO_SERVICE_BASE_URL = FrontEndSettingsUtil.get().MICRO_SERVICE_BASE_URL;
    const MICRO_SERVICE_UPLOAD_URL = FrontEndSettingsUtil.get().MICRO_SERVICE_UPLOAD_URL;
    return req
      .replace('_TENANT_ID_', FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN)
      .replace('_MICRO_SERVICE_UPLOAD_', MICRO_SERVICE_UPLOAD_URL)
      .replace('_MICRO_SERVICE_', MICRO_SERVICE_BASE_URL);
  }


  static logResponseData(response: HttpResponse<any>): any {
    if (response && response.body && response.body.status) {
      const status = response.body.status || null;
      if (status) {
        // logResponse(`${status.code} (${status.label}) ${response.url}`);
        // logResponse(response.body.data);
      }
    }
    return response;
  }

}
