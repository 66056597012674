import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractPageComponent } from '../../../../shared/pages/abstract-page/abstract-page.component';
import { Link } from '../../../../shared/ng-models/Link.interface';
import { NO_DATA } from '../../../../shared/ng-models/SectionDataTable.model';
// import { TenantSearchService } from '../../../../libs/api/tenantSearch/tenantSearch.service';
import { DetailHeaderNavData, DetailHeaderNavSpec, HeaderNavSpecTypes } from '../../../../shared/ng-models/DetailHeaderNavData.interface';
// import { Store } from '@ngrx/store';
// import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import { FILTER_GROUP_MODAL_ID } from '../../../../shared/ng-models/FilterGroupModalId';
import { Product } from '../../../../shared/models/Product';
import { TenantProductsService } from '../../../../libs/api/tenant/tenantProducts';
import { Vendor } from '../../../../shared/models/Vendor';
// import { combineLatest } from "rxjs";
// import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';
// import { ModalService } from '@independer/ng-modal';
import linkFormatter from 'src/common/utils/linkFormatter';
// import { ProductV2Service } from 'src/libs/api2/product-v2/product-v2.service';

@Component({
  templateUrl: './product-instance-wrap.component.html'
})

export class ProductInstanceWrapComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  detailHeaderNavData: DetailHeaderNavData;
  display_id: string;
  error: string = '';
  filterModalId: string = FILTER_GROUP_MODAL_ID;
  product: Product;
  supportLink: string = linkFormatter.getSupportLink();
  title: string;
  vendor: Vendor;

  NO_DATA: string = NO_DATA;

  @ViewChild('editTmpl', { static: true }) editTmpl: TemplateRef<any>;

  constructor(private activatedRoute: ActivatedRoute,
    // private modalService: ModalService,
    // private store: Store<ASReducer.State>,
    // private productV2Service: ProductV2Service,
    private tenantProductService: TenantProductsService,
    // private tenantSearchService: TenantSearchService
  ) {
    super();

  }

  ngOnInit() {
    this.activatedRoute
      .params
      .takeUntil(this.destroy$)
      .subscribe((queryParams: Params) => {
        this.display_id = queryParams.display_id;
        this.breadcrumbs = [
          {
            label: 'Dashboard',
            url: '/',
            internal: true
          },
          {
            label: `Product ${this.display_id}`,
            url: '/products/' + this.display_id,
            internal: true
          }
        ];
        this.getProductDetails()
      });


    // combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
    // .switchMap(([params, queryParams]) => {

    //     // get the product data
    //     return this.tenantProductService.getProductByDisplayId(this.display_id);
    // })
    // .catch(e => this.error = e)
    // .takeUntil(this.destroy$)
    //   .switchMap(res => {
    //     this.product = res as Product; // <-- Product Data
    //     this.vendor = this.product.product_vendor;
    //     this.detailHeaderNavData = {
    //       headline: this.product.product_name,
    //       byline: this.product.display_id
    //     };

    //     this.detailHeaderNavData = <DetailHeaderNavData> {
    //       headline: this.product.product_name,
    //       byline: {
    //         label: 'MISO_ID_BYLINE',
    //         obj: {display_id: this.product.display_id}
    //       },
    //       specs: [
    //         {
    //           label: 'TENANT.SERVICE_COUNT_TITLE',
    //           value: 0
    //         },
    //         {
    //           label: 'TENANT.TOTAL_MRC_TITLE',
    //           value: 0,
    //           value_type: HeaderNavSpecTypes.CURRENCY
    //         }]
    //     };

    //       this.detailHeaderNavData.specs[0].value = this.product.service_count ? this.product.service_count : 0;
    //       this.detailHeaderNavData.specs[1].value = this.product.mrc || 0;
    //     //return this.tenantSearchService.typeSummary({'product_id[]': this.product._id});
    //     return this.tenantSearchService.typeSummary({'product_id[]': this.product.id});
    //   })
    //   .catch(e => this.error = e)
    //   .takeUntil(this.destroy$)
    //   .subscribe((productResults: any) => {


    //     // if ('product_id' in productResults.aggregations) {

    //     //   // we can now find the correct bucket for this address
    //     //   let correctBucket = productResults.aggregations.product_id.buckets.find(bucket => bucket.key === this.product._id);
    //     //   let serviceCount = this.product.service_count ? this.product.service_count : 0;
    //     //   let totalMRC = this.product.mrc || 0;

    //     //   this.detailHeaderNavData.specs[0].value = serviceCount;
    //     //   this.detailHeaderNavData.specs[1].value = totalMRC;

    //     // }

    //   });

    // this.store.select(ASReducer.getASData)
    //   .takeUntil(this.destroy$)
    //   .subscribe(
    //     data => {
    //       if (data.filterGroup) {
    //         // Get the data and open the modal:
    //         const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
    //           m.modalId = this.filterModalId;
    //         })
    //       }
    //     });
  }

  getProductDetails() {
    this.tenantProductService
      .getProductByProductDisplayId(this.display_id)
      .toPromise()
      .then(data => {
        this.product = data as Product; // <-- Product Data
        this.vendor = this.product.product_vendor;
        this.detailHeaderNavData = {
          headline: this.product.product_name,
          byline: this.product.display_id
        };

        this.detailHeaderNavData = <DetailHeaderNavData>{
          headline: this.product.product_name,
          byline: {
            label: 'MISO_ID_BYLINE',
            obj: { display_id: this.product.display_id }
          },
          specs: [
            {
              label: 'TENANT.SERVICE_COUNT_TITLE',
              value: 0
            },
            {
              label: 'TENANT.TOTAL_MRC_TITLE',
              value: 0,
              value_type: HeaderNavSpecTypes.CURRENCY
            }]
        };

        this.detailHeaderNavData.specs[0].value = this.product.service_count ? this.product.service_count : 0;
        this.detailHeaderNavData.specs[1].value = this.product.mrc || 0;
      }
      )
      .catch((e) => (this.error = e));
  }

}
