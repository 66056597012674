import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as OrderReducers from '../../../create/state/reducer';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {UpdateOrderAction} from '../../../create/state/actions';
import * as userReducer from "../../../../../../../libs/user-store/state/reducers";
import {TenantUsersService} from "../../../../../../../libs/api/tenant/tenantUsers.service";
import {combineLatest} from 'rxjs';
import {ISecureUser} from "../../../../../../../common/security/secureUser";
import {SimpleUser} from "../../../../../../../shared/ng-models/SimpleUser.interface";

@Component({
  selector: 'mi-assigned-to-user',
  styleUrls: ['./service-assigned-to-user.component.scss'],
  templateUrl: './service-assigned-to-user.component.html'
})

export class ServiceAssignedToUserComponent extends AbstractPageComponent implements OnInit {

  readonly END_DATE: string = "[Service Date] End Date";
  readonly NOT_SURE: string = "[Service Date] Not Sure";
  readonly START_AND_LENGTH: string = "[Service Date] Start and Length";

  readonly NO_DATA: string = NO_DATA;

  activeIndex: boolean = false;
  activeStep: boolean = false;
  assignedForm: FormGroup;
  error: string;
  index: number;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  step: string = OrderSteps.AssignedTo;


  users: SimpleUser[] = [];
  user: ISecureUser;
  summary_user: SimpleUser;
  assignee: string;
  division: string;
  team: string;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<OrderState>,
    private tenantUsersService: TenantUsersService
  ) {
    super();
  }

  ngOnInit() {
    this.assignedForm = this.formBuilder.group(
      {
        assignee: [null, Validators.required],
        division: [null],
        team: [null]
      }
    );

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;

          if (this.orderState.service.assigned_email) {
            const assignedToId: string = (this.orderState.service.assigned_email as string);
            this.assignedForm.get('assignee').setValue(assignedToId);
            this.summary_user = this.users.find(user => (user.email === assignedToId));
          }

        },
        (e) => {
          this.error = e;
        });

    const userStateData = this.store.select(userReducer.getUserState);
    const allUsersData = this.tenantUsersService.getUsers(['NO_ACCESS_USER']);

    combineLatest(userStateData, allUsersData)
      .takeUntil(this.destroy$)
      .subscribe((allValues) => {

        const thisUserData: ISecureUser = allValues[0].secureUser;
        const allUsers: ISecureUser[] = allValues[1];


        this.users = allUsers.map(user => {
          const safeData = user.getSafeData();
          return {
            name: `${safeData.first_name} ${safeData.last_name}`,
            _id: safeData._id,
            isYou: safeData.email === thisUserData._rawData.email,
            email: safeData.email
          }
        });

        if (this.users.some(userItem => userItem.email === thisUserData._rawData.email)) {
          //the current user does belong to this tenant.
          this.assignedForm.get('assignee').setValue(thisUserData._rawData.email);
        }
      });
  }

  onAssignedEmail(assignee){
    this.assignee = assignee;
    this.assignedForm.get('assignee').setValue(assignee);
  }

  onBusinessUnit(bu){
    this.division = bu.division? bu.division.name : '';
    this.team = bu.team? bu.team.name : '';
    this.assignedForm.get('division').setValue(bu.division? bu.division.id : null);
    this.assignedForm.get('team').setValue(bu.team? bu.team.id : null);
  }

  onFormSubmit(data: any) {

    const maxIndex: number = Math.max(this.orderState.maxIndex, this.index + 1);

    this.store.dispatch(new UpdateOrderAction(
      {
        index: maxIndex,
        maxIndex: maxIndex,
        service: {
          ...this.orderState.service,
          assigned_email: data.assignee,
          division:this.assignedForm.get('division').value,
          team:this.assignedForm.get('team').value,
        }
      }
    ));

  }

}
