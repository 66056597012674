import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { AbstractPageComponent } from "../../../../../shared/pages/abstract-page/abstract-page.component";
import { AccountId } from '../../../../../shared/models/AccountId';
import { NO_DATA } from "../../../../../shared/ng-models/SectionDataTable.model";
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';

@Component({
  styleUrls: ['./vendor-account-id-details.component.scss'],
  templateUrl: './vendor-account-id-details.component.html'
})

export class VendorAccountIdDetailsComponent extends AbstractPageComponent implements OnInit {



  readonly NO_DATA: string = NO_DATA;
  accountId: AccountId;
  display_id: string;
  error: string = '';
  loaded: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private tenantAccountIdService: TenantAccountIdService
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.loaded = false;
        this.display_id = queryParams.display_id;
        return this.tenantAccountIdService.getById(this.display_id);
      })
      .catch(e => this.error = e)
      .takeUntil(this.destroy$)
      .subscribe((accountId: AccountId) => {
        this.accountId = accountId;
        this.loaded = true;
      });
  }

  // populateVendor() {
  //   this.tenantVendors.getVendorBytenantVendorId(this.accountId.account.tenant_vendor_id)
  //   .then(vendor => {
  //     console.log("tenant vendor", vendor);
  //     this.accountId.account.vendor = vendor;
  //     this.loaded = true;
  //   });
  // }

  // populateContact() {
  //   if(this.accountId.account.contact_id) {
  //     this.tenantContactService.getContactById(this.accountId.account.contact_id)
  //     .takeUntil(this.destroy$)
  //     .subscribe(contact => {
  //       this.accountId.account.contact = contact;
  //       this.loaded = true;
  //     },
  //       e=> this.error = e);
  //   }
  //   else {
  //     this.loaded = true;
  //   }
  // }
}
