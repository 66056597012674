import {Component, OnInit} from '@angular/core';
import {Link} from "../../../../shared/ng-models/Link.interface";
import {ActivatedRoute, Params} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import { AbstractPageComponent } from '../../../../shared/pages/abstract-page/abstract-page.component';
import { Observable } from 'rxjs';
import { CONTACT_SORTING} from '../../../../common/utils/sharedConstants';
import { ModalService } from '@independer/ng-modal';
import { FilterGroupOverlayComponent } from 'src/shared/components/advanced-search/filter-group-overlay/filter-group-overlay.component';

@Component({
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})

export class ContactListComponent extends AbstractPageComponent implements OnInit {


  breadCrumbs: Array<Link> = [{url: '..', label: 'Dashboard', internal: true}, {url: '../contacts', label: 'Contacts'}];

  headerLinks: Link[];
  loaded: boolean = true;
  pageLoaded$: Observable<boolean>;
  searchLoaded$: Observable<boolean>;
  error: string = '';
  modalId: string = "contact_filterOverlay";

  constructor(private store: Store<ASReducer.State>,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService) {
      super();
  }

  ngOnInit() {

    // send a reset.
    this.store.dispatch(new ASActions.AsReset({searchType: ASReducer.ADVANCED_SEARCH_TYPES.CONTACT, sortOptions: CONTACT_SORTING}));

    // listen for page loaded
    this.pageLoaded$ = this.store.select(ASReducer.ASPageLoaded);
    this.searchLoaded$ = this.store.select(ASReducer.ASSearchLoaded);
    // listen for route changes
    this.activatedRoute
      .queryParams
      .takeUntil(this.destroy$)
      .subscribe((queryParams: Params) => {
        this.store.dispatch(new ASActions.AsDeeplinkChanged(queryParams));
      });
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if(data.filterGroup){
            // Get the data and open the modal:     
            const modalRef = this.modalService.open(FilterGroupOverlayComponent, m => {
              m.modalId = this.modalId;
            })
          }
        });
  }

}
