/**
 * Search Properties which should not publish to the url
 * @param {Object} query
 * @returns {Object}
 */
export const removeNonUrlSearchParams = (query: any): any => {
  const newQuery = {...query};

  // remove params which should not be stored in the URL
  ['owner', 'from', 'size', 'custom_properties_agg', 'custom_properties_agg[]',
    'custom_properties_filters', 'custom_properties_filters[]',
    'custom_properties_filters_number', 'custom_properties_filters_number[]',
    'custom_properties_filters_date', 'custom_properties_filters_date[]']
    .forEach(unsafeParam => {
      delete newQuery[unsafeParam];
    });

  return newQuery;
};

export const removeNonDisplayFriendlySearchParams = (query: any): any => {
  const newQuery = {...query};

  // remove params which should not be stored in the URL
  ['product_id', 'address_id', 'terms_and_conditions', 'contact_id', 'associated_documents'].forEach(unsafeParam => {
    delete newQuery[unsafeParam];
  });

  return newQuery;
};
