import {Component,  OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../../../shared/pages/abstract-page/abstract-page.component';
import {Store} from '@ngrx/store';
import {DropdownItemData} from '../../../../../../shared/models/DropdownItem.interface';
import {ActivatedRoute} from '@angular/router';
import * as ParentServiceReducer from '../../state/reducers';
import { TenantReportsService } from 'src/libs/api/tenant/tenantReports.service';
import * as emailReducer from '../../../../../../shared/state/email-list/reducers';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import {Email} from '../../../../../../shared/models/Email.interface';
import {EMAIL_TYPE_RE_ASSESSMENT_REQUEST, EMAIL_TYPE_REQUEST_QUOTE} from '../../../../../../common/utils/sharedConstants';
import {TenantMiServiceService} from '../../../../../../libs/api/tenant/tenantMiService.service';
import {FormTypes} from '../../../../services/detail/state/actions';
import {MiParentService} from '../../../../../../shared/models/MiParentService';
import {ParentServiceShowForm} from '../../state/actions';
import {ParentServiceStatus} from '../../../../../../shared/models/ParentServiceStatus.interface';
import {parentServiceStatusHelper} from '../../../../../../shared/shared.functions';
import {TenantMiParentServiceService} from '../../../../../../libs/api/tenant/tenantMiParentService.service';

@Component({
  selector: 'mi-parent-service-actions',
  styleUrls: ['./parent-service-actions.component.scss'],
  templateUrl: './parent-service-actions.component.html',
})

export class ParentServiceActionsComponent extends AbstractPageComponent implements OnInit {

  error: string;
  emails: Email[] = [];
  emailsLoaded: boolean = false;
  serviceDataItems: DropdownItemData[];
  composeMessageItems: DropdownItemData[];
  // Service States
  parentService: MiParentService;
  parentServiceStatus: ParentServiceStatus;
  moment: any = moment;
  downloadMissingDataUrl: string;

  readonly FORM_TYPES: any = FormTypes;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private tenantReportService: TenantReportsService,
    private tenantMiService:TenantMiServiceService,
    private tenantMiParentService:TenantMiParentServiceService
  ) {
    super();
  }

  ngOnInit(): void {

    /*
    this.store.select(ParentServiceReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentService) {
            this.parentService = data.parentService;
            this.downloadMissingDataUrl = `${settings.API_URL}/${this.parentService.owner}/reports/missing-data/download-parent/${this.parentService._id}`;
            this.updateServiceStatus();
          }
        }
      );


    this.store.select(emailReducer.serviceEmails)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          this.emails = data;
          this.emailsLoaded = true;
        });
        */
  }

  daysPast(when:string):String{
    return moment(when).fromNow();
  }

  hasQuoted():boolean{
    if(!this.emails || this.emails.length === 0){
      return false;
    }
    return this.emails[0].type === EMAIL_TYPE_REQUEST_QUOTE;
  }

  hasReassessed():boolean{
    if(!this.emails || this.emails.length === 0){
      return false;
    }
    return this.emails[0].type === EMAIL_TYPE_RE_ASSESSMENT_REQUEST;
  }

  getMessageItems(): DropdownItemData[] {

    let messageItems = [];

    if (!this.parentServiceStatus.hasFullDataIntegrity) {
      messageItems.push({
        title: 'Request Missing Data',
        subtitle: 'Ask your rep for help',
        url: '../start-missing-data-compose',
        queryParams: {relativeTo: this.activatedRoute}
      });
    }

    messageItems.push({
      title: 'Re-Assessment Request',
      subtitle: 'From an internal contact',
      url: '../start-re-assessment-compose',
      queryParams: {relativeTo: this.activatedRoute}
    });

    messageItems.push({
      title: 'Request Quote',
      subtitle: 'Get a quote for this service',
      url: '../start-quote-compose',
      queryParams: {relativeTo: this.activatedRoute}
    });

    messageItems.push({
      title: 'Request Cancellation',
      subtitle: 'Send cancellation to vendor',
      url: '../cancellation-instructions',
      queryParams: {relativeTo: this.activatedRoute}
    });

    return messageItems;

  }

  getServiceItems(): DropdownItemData[] {

    let serviceItems = [];

    if (!this.parentServiceStatus.hasFullDataIntegrity) {
      serviceItems = [...serviceItems, {
        title: 'Download Missing Data',
        subtitle: 'Fix what\'s missing',
        externalLink: true,
        url: this.downloadMissingDataUrl,
        queryParams: {relativeTo: this.activatedRoute}
      },
        {
          title: 'Request Missing Data',
          subtitle: 'Ask your rep for help',
          url: '../start-missing-data-compose',
          queryParams: {relativeTo: this.activatedRoute}
        }
      ];
    }

    return serviceItems;
  }

  onCancelReterm(): void{
    console.error('No Reterm Cancellation endpoint.');
  }

  updateServiceStatus(): void {
    // Set service status:
    this.parentServiceStatus = parentServiceStatusHelper(this.parentService);
    // Build the New-Fangled Dropdown if the Service Integrity is in a bad way:
    this.serviceDataItems = this.getServiceItems();
    // Build the Compose Message items:
    this.composeMessageItems = this.getMessageItems();
  }

  openForm(formName: string): void {
    this.store.dispatch(new ParentServiceShowForm(
      {
        currentForm: formName
      }
    ));
  }
}
