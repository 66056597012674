import {Component} from '@angular/core';
import {Link} from '../../../../shared/ng-models/Link.interface';

@Component({
  styleUrls: ['./api-integration.component.scss'],
  templateUrl: './api-integration.component.html'
})

export class ApiIntegrationComponent {

  breadcrumbs: Link[] = [
    {
      url: '',
      label: 'Dashboard',
      internal: true
    },
    {
      url: '/data-import',
      label: 'Data Import',
      internal: true
    },
    {
      url: '/data-import/api-integration',
      label: 'API Integration',
      internal: true
    }
  ];

  error: string;

}
