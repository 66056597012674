<div>
  <mi-page-header
    title="{{ 'TENANT.CONTACTS_LIST_TITLE' | translate }}"
    [breadcrumbs]="breadCrumbs"
    [links]="headerLinks">
  </mi-page-header>

  <mi-server-error [error]="error"></mi-server-error>

  <mi-page-alert></mi-page-alert>

  <div class="mi-grid-small-first">
    <div class="col-all">
      <mi-tabs-container externalClasses="reverse-color">
        <mi-tab tabTitle="Users & Employees" route="/employees" [withPane]="false"></mi-tab>
        <mi-tab tabTitle="Vendor Contacts" route="../contacts" [withPane]="false"></mi-tab>
      </mi-tabs-container>

      <mi-advanced-search-header
        searchTypeTitle="Search Contacts"
        searchType="CONTACT"
        [showTotalMrc]="false">
      </mi-advanced-search-header>

      <mi-opacity-loader [ready]="searchLoaded$ | async">
        <mi-applied-search-filters></mi-applied-search-filters>
        <mi-advanced-search-contact-results></mi-advanced-search-contact-results>
        <mi-advanced-search-load-more></mi-advanced-search-load-more>
      </mi-opacity-loader>

    </div>
  </div>

</div>

