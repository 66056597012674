import { Component, Input, OnInit } from "@angular/core";
import { ModalService } from "@independer/ng-modal";
import { ServiceV2Filter } from "src/shared/models/ServiceV2";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import { FilterGroupOverlayV2Component } from "../filter-group-overlay-v2/filter-group-overlay-v2.component";

@Component({
  selector: 'mi-advanced-search-filter-table-header-v2',
  templateUrl: './advanced-search-filter-table-header-v2.component.html',
  styleUrls: ['./advanced-search-filter-table-header-v2.component.scss'],
  preserveWhitespaces: true
})

export class AdvancedSearchFilterTableHeaderV2Component extends AbstractPageComponent implements OnInit {
  @Input() filter_name: string;
  @Input() title: string;
  @Input() isCustomField: boolean = false;
  @Input() isTag: boolean = false;
  @Input() isTagTypeOption: boolean = false;
  @Input() tagById: any = {};
  loadError: string = '';
  modalId: string = "filterOverlay";

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    
  }

  onShowAllClicked() {
    const modalRef = this.modalService.open(FilterGroupOverlayV2Component, m => {
      m.modalId = this.modalId;
      m.filterName = this.filter_name;
      m.filterLabel = this.title;
      m.isCustomField = this.isCustomField;
      m.isTag = this.isTag;
      m.isTagTypeOption = this.isTagTypeOption;
      m.tagById = this.tagById;
    })
  }
}