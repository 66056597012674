import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {createLogger, LOG_LEVELS} from "../../../../../shared/logger";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {Store} from "@ngrx/store";
import * as appDataReducer from '../../../../../shared/state/app-data/reducers';
import {PageAlertAddAction} from "../../../../../shared/state/page-alert/actions";
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';


const log = createLogger(LOG_LEVELS.PAGE);

export enum CreateEditContactsMode {
  NONE = 'NONE',
  EDIT = 'EDIT',
  CREATE = 'CREATE'
};

@Component({
  templateUrl: './create-edit-employee.component.html',
  styleUrls: ['./create-edit-employee.component.scss']
})


export class CreateEditEmployeeComponent extends AbstractPageComponent implements OnInit {

  MODE_NONE = CreateEditContactsMode.NONE;
  MODE_EDIT = CreateEditContactsMode.EDIT;
  MODE_CREATE = CreateEditContactsMode.CREATE;

  mode: CreateEditContactsMode = this.MODE_NONE;
  form: FormGroup;
  loadError: string = '';
  submitError: string = '';
  title: string;
  successMessage = '';
  editId: string = '';
  CONTACT_STATUSES: string[];
  CONTACT_ROLES: string[];
  breadcrumbs :any[] = [];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store<appDataReducer.State>) {
    super();
  }

  ngOnInit() {
    this.store.select(appDataReducer.getAppData).takeUntil(this.destroy$)
      .subscribe( state => {
        this.CONTACT_STATUSES = state.CONSTANTS.CONTACT_STATUSES;
        this.CONTACT_ROLES = state.CONSTANTS.CONTACT_ROLES;
      });

    this.activatedRoute.parent.params.takeUntil(this.destroy$).subscribe((params: Params) => {
      if (params.display_id) {
        this.startAsEditMode(params.display_id)
      } else {
        this.startAsCreateMode();
      }
    });

  }

  startAsCreateMode(): void {
    this.title = 'Create Employee';
    this.successMessage = 'Employee Created';
    this.mode = this.MODE_CREATE;
    this.breadcrumbs = [{
      label: 'Dashboard',
      url: '/',
      internal: true},
      {
        label: 'Employee List',
        url: '/employees',
        internal: true
      },
      {
        label: this.title,
        url: '/employees/create',
        internal: true
      }];
  }

  startAsEditMode(contactId: string): void {
    this.mode = this.MODE_EDIT;
    this.title = 'Edit Employee';
    this.successMessage = 'Employee Updated';
    this.editId = contactId;
    this.breadcrumbs = [{
      label: 'Dashboard',
      url: '/',
      internal: true},
      {
        label: 'Employee List',
        url: '/employees',
        internal: true
      },
      {
        label: this.title + ' Edit',
        url: '#',
        internal: true
      }];
}

  onCanceled($event){
    this.router.navigate([`/employees/`]);
  }

  onUserUpdated(tenantUser: TenantUser){
    /*
    this.store.dispatch(
      new PageAlertAddAction({
        message: this.successMessage,
        alertType: "SUCCESS"
      })
    );
    */
    setTimeout(()=>{
      this.router.navigate([`/employees/${tenantUser.id}`]);
    }, 1500);
  }


}
