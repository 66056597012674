import {Action} from '@ngrx/store';

export const ActionTypes = {
  MAIN_NAV_INITIALIZE: '[Miso:Main Nav] Initialize',
  MAIN_NAV_OPEN: '[Miso: Main Nav] Open',
  MAIN_NAV_CLOSE: '[Miso: Main Nav] Close',
};

export class MainNavInitializeAction implements Action {
  type = ActionTypes.MAIN_NAV_INITIALIZE;

  constructor(public payload: any) {
  }
}

export class MainNavOpenAction implements Action {
  type = ActionTypes.MAIN_NAV_OPEN;

  constructor(public payload: any) {
  }
}

export class MainNavCloseAction implements Action {
  type = ActionTypes.MAIN_NAV_CLOSE;

  constructor(public payload: any) {
  }
}

export type Actions
  = MainNavInitializeAction
  | MainNavOpenAction
  | MainNavCloseAction;
