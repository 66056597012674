let config;

function configure(data) {
  config = data;
}

function getEmailConfirmationUrl(email, id, formType, token) {
  return `${config.FULL_ACCOUNTS_URL}/auth/set-password?formType=${formType}&token=${token}&email=${email}`;
}

function getRelativePasswordlink(email, formType, token) {
  return `/auth/set-password?formType=${formType}&token=${token}&email=${email}`;
}

function getLoginLink(redirect = '') {
  //console.log("getLoginLink", redirect);
  let link = `${config.ACCOUNTS_URL}/login`;
  if (redirect) {
    link = `${link}?redirect=${redirect}`;
  }
  //console.log("link", link);
  return link;
}
function getRecurlyCheckoutLink(plan_code, first_name, last_name, email, account_code, user_id) {
  return `${config.RECURLY_BASE_URL}${plan_code}/${account_code}/${user_id}?email=${encodeURIComponent(email)}&first_name=${encodeURIComponent(first_name)}&last_name=${encodeURIComponent(last_name)}`;
}

function getTimezoneLink(email, activation_token) {
  return `${config.FULL_ACCOUNTS_URL}/checkout/complete?email=${encodeURIComponent(email)}&token=${activation_token}`;
}

/*
function getCheckoutLinkManualBilling(domain, plan_code) {
  return `${config.FULL_API_URL}/account/checkout-success?account_code=${domain}&plan_code=${plan_code}`;
}
*/

function getLogoutLink() {
  return `${config.ACCOUNTS_URL}/logout`;
}

function getAccountsLink() {
  return `${config.ACCOUNTS_URL}/accounts`;
}

function getAccountsSecurityLink() {
  return `${config.ACCOUNTS_URL}/security`;
}

function getAccountsUsersLink() {
  return `${config.ACCOUNTS_URL}/users`;
}

function getAccountsUserCreateLink() {
  return config.ACCOUNTS_URL + '/user/create';
}

function getManageAccountLink(domain) {
  return getTenantManageLink(domain);
}

function freshdeskLoginLink() {
  return `${config.FULL_ACCOUNTS_URL}/auth/freshdesk`;
}

function getProfileLink() {
  return `${config.FULL_ACCOUNTS_URL}/profile`;
}

function getCheckoutStartLink(checkout_token, domain) {
  return `${config.FULL_ACCOUNTS_URL}/checkout/start?access_feature=${checkout_token}&domain=${domain}`;
}

function getSupportLink() {
  return 'https://support.miso3.com';
}

function getSupportTicketLink(ticketId = null) {
  if(!ticketId) {
    return `${getSupportLink()}/support/tickets`;
  }
  return `${getSupportLink()}/support/tickets/${ticketId}`;
}

function getTermsAndConditionsLink() {
  return 'https://miso3.com/terms20180101/';
}

function getTenantLink(domain) {
  return config.CLIENT_DOMAIN_URL.replace('{DOMAIN}', domain);
}

function getTenantManageLink(domain) {
  return `${getTenantLink(domain)}/manage`;
}

/*
function testApiCall() {
  return config.API_URL;
}
*/

function getSubdomainFromHost(host) {
  const regex = /([a-zA-Z-0-9]*?)\./i;
  const subdomains = regex.exec(host);
  return subdomains.length > 1 ? subdomains[1] : '';
}

/*
function getSynonymDownloadLink(domain, typeReference) {
  return `${config.API_URL}/${domain}/synonyms/get-csv-by-type/${typeReference}`;
}
*/

function supplant(mystring, o) {
  return mystring.replace(/{([^{}]*)}/g,
    function (a, b) {
      const r = o[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    }
  );
}

function getServiceLink(domain, display_id) {
  return `${getTenantLink(domain)}/services/${display_id}`;
}

function getParentServiceLink(domain, display_id) {
  return `${getTenantLink(domain)}/parent-services/${display_id}`;
}

function getEmailDetailLink(domain, id) {
  return `${getTenantLink(domain)}/emails/${id}`;
}

function getAccountsEventsLink(domain) {
  return `${getTenantLink(domain)}/events`;
}

function getAccountsServiceLink(domain) {
  return `${getTenantLink(domain)}/services`;
}

function getPersonalEventsLink(domain, user_email) {
  return `${getTenantLink(domain)}/events?time_range=30_DAY&assigned_email_id[]=${user_email}`;
}

function getDocumentLink(domain, doc_display_id){
  return `${getTenantLink(domain)}/associated-documents/${doc_display_id}`;
}

function getUploadTemplateLink(uploadType){
  let uploadFileURL = '';
  switch(uploadType){
    case 'CONTACTS':
    case 'EQUIPMENT':
    case 'LOCATIONS':
    case 'SERVICES':
    case 'VENDORS':
      uploadFileURL = `./shared/assets/imports/v.1/bulk_upload_${uploadType.toLowerCase()}.xlsx`;
      break;
    default:
      throw new Error(`Unknown File Type: ${uploadType}`);
  }

  return uploadFileURL;
}

const linkFormatter = {
  supplant,
  configure,
  getEmailConfirmationUrl,
  getLoginLink,
  getLogoutLink,
  getSupportLink,
  getTenantLink,
  getTenantManageLink,
  getServiceLink,
  getParentServiceLink,
  getEmailDetailLink,
  //testApiCall,
  getAccountsLink,
  getProfileLink,
  getSubdomainFromHost,
  getRecurlyCheckoutLink,
  getTimezoneLink,
  getAccountsEventsLink,
  getPersonalEventsLink,
  getAccountsServiceLink,
  getCheckoutStartLink,
  getRelativePasswordlink,
  getTermsAndConditionsLink,
  getManageAccountLink,
  //getCheckoutLinkManualBilling,
  getAccountsSecurityLink,
  getAccountsUsersLink,
  getAccountsUserCreateLink,
  //getSynonymDownloadLink,
  freshdeskLoginLink,
  getDocumentLink,
  getSupportTicketLink,
  getUploadTemplateLink
};

export default linkFormatter;

export {
  supplant,
  configure,
  getEmailConfirmationUrl,
  getLoginLink,
  getLogoutLink,
  getSupportLink,
  getTenantLink,
  getTenantManageLink,
  getServiceLink,
  getParentServiceLink,
  getEmailDetailLink,
  //testApiCall,
  getAccountsLink,
  getProfileLink,
  getSubdomainFromHost,
  getRecurlyCheckoutLink,
  getTimezoneLink,
  getAccountsEventsLink,
  getPersonalEventsLink,
  getAccountsServiceLink,
  getCheckoutStartLink,
  getRelativePasswordlink,
  getTermsAndConditionsLink,
  getManageAccountLink,
  //getCheckoutLinkManualBilling,
  getAccountsSecurityLink,
  getAccountsUsersLink,
  getAccountsUserCreateLink,
  //getSynonymDownloadLink,
  freshdeskLoginLink,
  getDocumentLink,
  getSupportTicketLink,
  getUploadTemplateLink
};
