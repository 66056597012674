import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';

@Injectable()
export class UsersV2Service {
  private apiUrl = '_MICRO_SERVICE_/tenant';

  constructor(private http: HttpClient) { }

  getUsersByVendor(pageNumber: number = 0, pageSize: number = 10, search: string = "", association?: string, selectedColumnOrder: string= "email", sortByColumn: string = "ASC", exclude_props: string = ''): Observable<any> {
    let params: any
    if (association) {
      params = new HttpParams({
        fromObject: {
          id: FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN,
          page_number: pageNumber.toString(),
          size: pageSize.toString(),
          q: search,
          association: association,
          selectedColumnOrder: selectedColumnOrder,
          sortByColumn:sortByColumn,
          exclude_props: exclude_props
        }
      });
    } else {
      params = new HttpParams({
        fromObject: {
          id: FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN,
          page_number: pageNumber.toString(),
          size: pageSize.toString(),
          q: search,
          selectedColumnOrder: selectedColumnOrder,
          sortByColumn:sortByColumn,
          exclude_props: exclude_props
        }
      });
    }

    return this.http
      .get(`${this.apiUrl}/tenant-users?`, { params })
      .map((response: any) => response);
  }
}
