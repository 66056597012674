import { Action } from '@ngrx/store';

export const ActionTypes = {
  LOAD_PARENT_SERVICE_DETAILS_SUCCESS: '[Miso:ParentServiceDetails] Load Service Details',
  SAVE_PARENT_SERVICE_DETAILS_SUCCESS: '[Miso:ParentServiceDetails] Save Service Details',
  PARENT_SERVICE_DETAILS_RESET: '[Miso:ParentServiceDetails] Reset',
  UPDATE_PARENT_SERVICE: '[Miso:ParentServiceDetails] Update',
  PARENT_SERVICE_ADD_SERVICE: '[Miso:ParentServiceDetails] Add Service',
  PARENT_SERVICE_REMOVE_SERVICE: '[Miso:ParentServiceDetails] Remove Service',
  PARENT_SERVICE_SHOW_FORM: '[MISO:ParentServiceDetails] Show Form'
};

export const FormTypes = {
  ACTIVATION_FORM: 'ActivationForm',
  CANCELLATION_FORM: 'CancellationForm',
  CONFIRM_CANCELLATION_FORM: 'ConfirmCancellationForm',
  REMOVE_CANCELLATION_FORM: 'RemoveCancellationForm',
  RETERM_FORM: 'RetermForm',
  GROUP_FORM: 'GroupForm'
};

export class ParentServiceAddService implements Action {
  type = ActionTypes.PARENT_SERVICE_ADD_SERVICE;
  constructor(public payload: any) { }
}

export class ParentServiceRemoveService implements Action {
  type = ActionTypes.PARENT_SERVICE_REMOVE_SERVICE;
  constructor(public payload: any) { }
}

export class ParentServiceLoadSuccess implements Action {
  type = ActionTypes.LOAD_PARENT_SERVICE_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class ParentServiceUpdate implements Action {
  type = ActionTypes.UPDATE_PARENT_SERVICE;
  constructor(public payload: any) { }
}

export class ParentServiceSaveSuccess implements Action {
  type = ActionTypes.SAVE_PARENT_SERVICE_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class ParentServiceReset implements Action {
  type = ActionTypes.PARENT_SERVICE_DETAILS_RESET;
  constructor(public payload: any) { }
}

export class ParentServiceShowForm implements Action {
  type = ActionTypes.PARENT_SERVICE_SHOW_FORM;
  constructor(public payload: any) {
  }
}

export type Actions
  = ParentServiceAddService
  | ParentServiceRemoveService
  | ParentServiceLoadSuccess
  | ParentServiceSaveSuccess
  | ParentServiceReset
  | ParentServiceUpdate
  | ParentServiceShowForm
