<modal>
  <modal-header><h2>Create New {{"TENANT.REPORT_ACTION_TYPE." + action_type | translate}}</h2></modal-header>
  <modal-content>
    <mi-server-error [error]="error"></mi-server-error>
    <div *ngIf="loaded; else loading">
      <ng-container *ngIf="action_type==='SERVICE_REPORT'; else serviceViewText">
        <p>
          You are about the create new report utilizing current search criteria.
        </p>
        <p>
          Please enter your report name below.
        </p>
      </ng-container>

      <ng-template #serviceViewText>
        <p>
          You are about the create new service view.
        </p>
        <p>
          Please enter your service view name below.
        </p>
      </ng-template>
  
      <form (ngSubmit)="onFormSubmit(form.value, $event)" *ngIf="form !== null" [formGroup]="form" autocomplete="off">
        <mi-extended-input
                            [inputControl]="form.controls['label']"
                            [inputErrors]="form.controls['label'].errors"
                            [inputTouched]="form.controls['label'].touched"
                            class="half-column"
                            [errorDefs]="{
                              required: 'Please enter a label.',
                              maxlength: 'Label cannot be longer than 200 characters long.',
                              minlength: 'Label must be at least 2 characters long'}">
          <input class="form-control"
                  formControlName="label"
                  placeholder="Enter {{'TENANT.REPORT_ACTION_TYPE.' + action_type | translate}} Name"
                  type="text"
                  tabindex="1">
        </mi-extended-input>
        
        <div class="button-container">
          <a (click)="onCancel(false)"
              buttonTheme="light-theme"
              class="button--cancel"
              mi-button>
            Cancel
          </a>
      
          <button
                  [disabled]="!form.valid"
                  mi-button
                  type="submit">
            {{action_type==="SERVICE_REPORT"? "Create Report": "Save View"}}
          </button>
        </div>
      </form>
    </div>
  </modal-content>
</modal>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>