import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../../../shared/shared.module';
import {MissingDataMainComponent} from './missing-account-main/missing-data-main.component';
import {MissingAccountDataSidebarComponent} from './missing-account-data-sidebar/missing-account-data-sidebar.component';
import {MissingAccountDataHeaderComponent} from './missing-account-data-header/missing-account-data-header.component';
import {MissingAccountDataListComponent} from './missing-account-data-list/missing-account-data-list.component';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {reducer as missingDataReducer} from './state/reducer';
import {MissingDataEffects} from "./state/effects";

// import {TenantComponentsModule} from "../../../components/tenant-components.module";

@NgModule({
  declarations: [
    MissingAccountDataHeaderComponent,
    MissingAccountDataSidebarComponent,
    MissingDataMainComponent,
    MissingAccountDataListComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    StoreModule.forFeature('missing-data-store', missingDataReducer),
    EffectsModule.forFeature([MissingDataEffects])
  ],
  providers: [],
  exports: [
    MissingAccountDataHeaderComponent,
    MissingAccountDataSidebarComponent,
    MissingDataMainComponent,
    MissingAccountDataListComponent,
  ]
})

export class VendorMissingDataByAccountModule {

}
