import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import * as ASActions from "../../state/advanced-search/actions";

import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import * as userReducer from "../../../libs/user-store/state/reducers";
import {combineLatest} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SaveSearchService} from '../../../libs/api/tenantSearch/saveSearch.service';
import { SaveSearch } from '../../models/SaveSearch';
import {removeNonUrlSearchParams} from "../../utils/unsafeUrlProperties";
import { PageAlertAddAction } from '../../state/page-alert/actions';


@Component({
  selector: 'mi-save-search-tray',
  templateUrl: './save-search-tray.component.html',
  styleUrls: ['./save-search-tray.component.scss']
})

export class SaveSearchTrayComponent extends AbstractPageComponent implements OnInit {

  filters: any[] = [];
  currentMode: string = "";
  currentQuery: any;
  form: FormGroup = null;
  error: string = '';
  domain: string = "";
  saveSearches: any[] = [];
  selectedSearch: SaveSearch;
  canSave:boolean = false;
  message: string= "";

  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private saveSearchService: SaveSearchService
  ) {
    super()
  }

  ngOnInit() {
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {

          if (data.results && data.results.aggregations && data.readableFilterArray) {
            this.init();
          }
        });
  }

  init(){
    this.error = '';
    combineLatest(
      this.activatedRoute.queryParams,
      this.store.select(userReducer.getUserState),
      this.store.select(ASReducer.getASData)
    )
      .first()
      .subscribe((data) => this.generateForm(data))
  }

  generateForm([query, user, data]) {

    this.error = '';
    this.currentQuery = {};
    this.canSave=false;
    this.currentQuery = {...query};
    let queryKey= Object.keys(this.currentQuery);
    if(queryKey.length){
      queryKey.forEach(key => {
        if(key.indexOf("[]")>0){
          let newKey=key.substr(0, key.length-2);
          let newKeyValue = this.currentQuery[key];
          this.currentQuery[newKey]=[newKeyValue];
          delete this.currentQuery[key]
        }
      });
      let queryKeyNew= Object.keys(this.currentQuery);
      for(let i = 0; i <queryKeyNew.length; i++){
        if(queryKeyNew.length && this.currentQuery[queryKeyNew[i]].length){
          this.canSave=true;
        }
      }
    }
    this.filters = Object.keys(removeNonUrlSearchParams(data.results.meta.params));
    this.domain = data.results.meta.params['owner'];

    let arg = {
      owner: this.domain
    };
    queryKey= Object.keys(this.currentQuery);
    queryKey.forEach(key => {
      if(!this.currentQuery[key].length || !this.currentQuery[key][0].length){
        delete this.currentQuery[key]
      }
    });

    this.saveSearchService.listSaveSearches(arg)
    .subscribe(save_searches => {
      this.saveSearches= save_searches;
      this.saveSearches.forEach((saveSearch)=>{
                if(this.objectEqualityCheck(saveSearch.query, this.currentQuery)){
                  this.canSave=false;
                  }
        });
      },
      e => this.error = e
    );

    this.form = this.formBuilder.group({
      label: ["", Validators.compose([Validators.required, Validators.maxLength(100), Validators.minLength(2)])],
      friendlyFilters: [data.readableFilterArray]
    });
  }

  objectEqualityCheck(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if(typeof(a[propName]) != typeof(b[propName])){
          return false;
        }else if (typeof(a[propName])=="string") {
          if(a[propName].toLowerCase() != b[propName].toLowerCase()){
            return false;
          }
        }else if (a[propName] && b[propName]&& a[propName].toString() != b[propName].toString()) {
          return false;
        }
    }
    return true;
  }

  // delete
  permanentlyDelete(): void {
    this.error = '';
    this.saveSearchService.deleteSaveSearch(this.selectedSearch)
      .subscribe(save_search => {
        this.showMessage (`"${this.selectedSearch.label}" was successfully deleted.`);
        this.currentMode = "";
        this.selectedSearch={};
        this.init();
        },
        error => this.error = error
      )
  }

  // create
  submit(form: any): void {
    let data = form;
    this.error = '';
    let queryKey= Object.keys(this.currentQuery);
    queryKey.forEach(key => {
      if(!this.currentQuery[key].length || !this.currentQuery[key][0].length){
        delete this.currentQuery[key]
      }
    });
    this.saveSearchService.createSaveSearch({
      ...data,
      query: this.currentQuery,
      owner: this.domain
    })
      .toPromise()
      .then((data) => {
        this.showMessage (`"${data.label}" was successfully created.`);
        this.currentMode = "";
        this.init();
        this.store.dispatch(new ASActions.AsFilterReplace(data.query))
      })
      .catch(error => this.error = error);
  }
  showMessage(message){
    this.message= message
    setTimeout(()=>this.message="", 5000);
  }
  onSaveSearch() {
    this.currentMode = "CREATE";
  }
  onRemoveClick(saveSearch){
    this.currentMode = "DELETE";
    this.selectedSearch = saveSearch;
  }
  onCancel() {
    this.error = '';
    this.currentMode = "";
    this.selectedSearch={};
  }
  currentModeNull(){
    this.currentMode = "";
  }
  moveToSaveSearch(query) {
    this.error = '';
    this.store.dispatch(new ASActions.AsFilterReplace(query));

  }

}

