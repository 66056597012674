<mi-page-header
      [breadcrumbs]="breadcrumbs"
      [showShortcutMenu]="false">
</mi-page-header>
<!--
<div class="header-label-container">
  <label>{{headerLabel}}</label>
</div>
-->
<div class="card">

  <div class="main-container" *ngIf="loaded && tenantBillingAccountNotFound">
    <div class="left">
      <label class="error-label">Billing Account not found. For questions, please contact <a href="mailto:finance@miso3.com?subject=Billing Account not found">finance@miso3.com</a>.</label>
    </div>
  </div>
  <div class="main-container" *ngIf="loaded && !tenantBillingAccountNotFound">
    <div class="left">
      <div class="details-container">
        <div class="details-header">
          <label>Plan Details</label>
        </div>
        <div class="table-container">
          <table class="details-table">
            <tr>
              <td>
                Plan Name
              </td>
              <td>
                {{accountPlanDetails.planName}}
              </td>
            </tr>
            <tr>
              <td>
                Billed
              </td>
              <td>
                {{accountPlanDetails.billingAmount | tenantcurrencyusd:'1.0'}} / <sub>{{accountPlanDetails.billingInterval}}</sub>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="details-container">
        <div class="details-header">
          <label>Organization Details</label>
        </div>
        <div class="table-container">
          <table class="details-table">
            <tr>
              <td>
                Organization Name
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountOrgName}}
              </td>
            </tr>
            <tr>
              <td>
                Organization URL
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountOrgUrl}}
              </td>
            </tr>
            <tr>
              <td>
                Default Currency
              </td>
              <td>
                {{tenantCurrency.code}}
              </td>
            </tr>
            <tr>
              <td>
                MISO3 Tenant Code
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountTenantCode}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="details-container">
        <div class="details-header">
          <label>Super Admin Details</label>
        </div>
        <div class="table-container">
          <table class="details-table">
            <tr>
              <td>
                Name
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountSuperAdminFname}} {{tenantBillingAccount.tenantBillingAccountSuperAdminLname}}
              </td>
            </tr>        
            <tr>
              <td>
                Email
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountSuperAdminEmail}}
              </td>
            </tr>
            <tr>
              <td>
                Title
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountSuperAdminTitle}}
              </td>
            </tr>
            <tr>
              <td>
                Phone
              </td>
              <td>
                {{tenantBillingAccount.tenantBillingAccountSuperAdminPhone}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="details-container">
        <div class="details-header">
          <label>Billing Details</label>
        </div>
        <div class="table-container">
          <table class="details-table">
            <tr>
              <td>
                Name
              </td>
              <td>
                {{stripeCustomer.name}}
              </td>
            </tr>
            <tr>
              <td>
                Email
              </td>
              <td>
                {{stripeCustomer.email}}
              </td>
            </tr>
            <tr>
              <td>
                Billing Address
              </td>
              <td>
                {{stripeCustomer.addressString}}
              </td>
            </tr>
            <tr>
              <td>
                Phone
              </td>
              <td>
                {{stripeCustomer.phone}}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="link-list-item">
        <a routerLink="plan-and-usage">Plan and Usage Details</a>
      </div>
      <div class="link-list-item">
        <a routerLink="current-plan">Manage Current Plan</a>
      </div>
      <div class="link-list-item">
        <a routerLink="payment-methods">Manage Payment Methods</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loaded; else loading">

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>