import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductCategory} from '../../../shared/models/ProductCategory';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {HttpClient} from '@angular/common/http';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantCategoryService {

  private apiUrlCrud = '/{tenant}/categories';
  private accountHost = '_MICRO_SERVICE_/product/categories';
  private apiGetCategories = '_MICRO_SERVICE_/categories/search';

  constructor(private http: HttpClient) {
  } 

  createCategory(category: ProductCategory): Observable<ProductCategory> {
    return this.http
      .post(this.apiUrlCrud, category)
      .map((response: any) => response.data.category as ProductCategory);
  }

  getCategory(categoryId: string): Observable<ProductCategory> {
    return this.http
      .get(`${this.apiUrlCrud}/${categoryId}`)
      .map((response: any) => response.data.category as ProductCategory);
  }

  listCategories(): Observable<Array<ProductCategory>> {
    return this.http
      .get(`${this.apiUrlCrud}`)
      .map((response: any) => response.data.categories as Array<ProductCategory>);
  }

  getCategoryTree(): Promise<ProductCategory[]> {
    return this.http
      .get(`${this.accountHost}/tree`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  getCategoriesService(search: string, pageNumber: number, pageSize: number, is_resolved: boolean = true, is_ignored: boolean = false): Observable<any> {
    return this.http.get(`${this.apiGetCategories}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&is_ignored=${is_ignored}&is_resolved=${is_resolved}`);
  }

}
