import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'mi-extended-input',
  styleUrls: ['./extended-input.component.scss'],
  templateUrl: './extended-input.component.html'
})

export class ExtendedInputComponent implements OnChanges {

  @Input() labelText: string = '';          // A label, if desired.
  @Input() labelRequired: boolean = false;  // Whether to add a required flag to the label or not.
  @Input() labelOffscreen: boolean = false; // Flag whether to hide the input's label offscreen or not.
  @Input() hint: string;                    // Text for a hint, if provided.
  @Input() suffix: string = '';             // Text to append to the right side of the input (ie: "Month(s)" or "Day(s)"
  @Input() errorDefs: any;                  // Definition object of errors to listen for and how to respond.
  @Input() inputControl: any;               // Form Control
  @Input() inputErrors: any;                // Errors object. Req'd as changes the inputControl itself haven't bubbled through.
  @Input() inputTouched: boolean;           // Whether the User has touched the control or not.
  @Input() hasToolTip: boolean = false;     // Whether to add an info-tooltip
  @Input() toolTipText: string = '';        // Text for the info-tooltip
  @Input() toolTipSize: string = '';        // Tooltip size
  @Input() toolTipId: string;               // Tooltip ID

  errorMessage: string = '';

  ngOnChanges(changes: any): void {
    this.errorMessage = '';
    if (this.inputTouched && this.inputControl.errors) {
      Object.keys(this.errorDefs||{}).some(key => {
        if (this.inputControl.errors[key]) {
          this.errorMessage = this.errorDefs[key];
          return true;
        }
      });
    }
  }
}
