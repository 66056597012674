import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MiService, MiServiceDetails, ServiceCalculationDetails } from '../../../../../shared/models/MiService';
import { NO_DATA } from '../../../../../shared/ng-models/SectionDataTable.model';
import { scrollTo } from '../../../../../shared/shared.functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as SDActions from '../state/actions';
import { Store } from '@ngrx/store';
import * as SDReducer from '../state/reducers';
import { ServiceStatus } from '../../../../../shared/models/ServiceStatus.interface';
import { DateTime } from 'luxon';
import { AbstractPageComponent } from '../../../../../shared/pages/abstract-page/abstract-page.component';
import { isEditRoleOnBU } from '../../../../../common/utils/UserAccess';
import { AuthV2Service } from 'src/libs/api2/auth-v2/auth-v2.service';
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mi-service-summary',
  styleUrls: ['./service-summary.component.scss'],
  templateUrl: './service-summary.component.html',
})

export class ServiceSummaryComponent extends AbstractPageComponent implements OnInit {

  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  confirmationFormGroup: FormGroup;
  error: string;
  formOpen: boolean = false;
  selectedForm: string = '';
  today: string = DateTime.local().toLocaleString(DateTime.SHORT_DATE);
  serviceStatus: ServiceStatus;
  buttonTypes: number[] = [];
  readonly FORM_TYPES: any = SDActions.FormTypes;
  readonly NO_DATA: string = NO_DATA;
  overlayName: string;
  loggedUserDetails: any
  serviceDetails: MiServiceDetails;
  serviceCalculationDetails: ServiceCalculationDetails;


  readonly DESTINATIONS = {
    PRODUCT: 'vendor',
    SERVICE_ID: 'service_id',
    RATE: 'rate',
    ASSIGNEE: 'assignee',
    END_OF_TERM: 'end_date',
    END_OF_TERM_ACTION: 'end_of_term',
    NOTIFICATION_DATE: 'end_of_term',
    VENDOR_NAME: 'vendor',
  };


  constructor(
    private formBuilder: FormBuilder,
    private store: Store<SDReducer.State>,
    private authV2Service: AuthV2Service,
    private tenantMiService: ServiceV2Service,
    private router: Router,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.authV2Service.getIdentity()
      .toPromise()
      .then(res => {
        this.loggedUserDetails = res
        this.buttonStatusHandler()
      })
      .catch(e => {
        this.error = e;
      });

    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        (data) => {
          if (data.serviceDetails && data.serviceCalculationDetails) {
            this.serviceDetails = data.serviceDetails;
            this.serviceCalculationDetails = data.serviceCalculationDetails
          }
        });

    this.confirmationFormGroup = this.formBuilder.group({
      service_id: this.serviceDetails.general.service_display_id,
      activation_date: [null, Validators.required]
    });
  }

  updateServiceDetailsStoreHandler(){
    this.updateServiceDetailsStore.emit()
  }
  confirmDeleteHandler(deleteServiceModal: any) {
    this.tenantMiService.serviceDelete(this.serviceDetails.general.tenant_id,this.serviceDetails.general.service_display_id)
      .toPromise()
      .then(res => {
        deleteServiceModal.close()
        this.toastr.success(`${this.serviceDetails.general.service_display_id} Service deleted successfully`);
        this.router.navigate(['/services']);
      })
      .catch(e => {
        this.error = e;
        this.toastr.error('Facing issue while deleting Service, Please try again');
      });
  }

  buttonStatusHandler() {
    // Tab numbering in UI
    // 1  Confirm/Edit Cancellation
    // 2  Remove Cancellation
    // 3  Plan Cancellation
    // 4  Fix Your Service
    // 5  Delete Service
    // 6  Go to Parent Service

    this.buttonTypes = [];
    const isParentService = this.serviceDetails.general.family_designation
    const doesServiceHaveTerminationDate = this.serviceDetails.service_data.termination_date
    if (isParentService.toLowerCase() == "Parent".toLowerCase()) { // Parent service
      if (doesServiceHaveTerminationDate !== null) {
        this.buttonTypes.push(1);
        this.buttonTypes.push(2);
      }
      else if (doesServiceHaveTerminationDate === null) {
        this.buttonTypes.push(3);
      }
      this.buttonTypes.push(4);
    } else if (isParentService.toLowerCase() == "Child".toLowerCase()) { // child service
      this.buttonTypes.push(5);
    }

    if (this.loggedUserDetails.role) { // logic for can user able to delete service
      const userWhoHasAccessToDeleteService = [1, 2, 5, 6] // please refer document for database table to check the access permission
      if (userWhoHasAccessToDeleteService.includes(this.loggedUserDetails.role) && isParentService.toLowerCase() != "Child".toLowerCase()) {
        this.buttonTypes.push(6);
      }
    }
    

  }

  onCancelReterm(): void {
    // this.tenantMiService.cancelReterm(this.service._id, NoteHtml.RETERM_CANCELLATION_NOTE_HTML)
    //   .toPromise()
    //   .then(res => {
    //     this.store.dispatch(new SDActions.SDLoadSuccess({miService: res}));
    //     this.store.dispatch(new NoteActions.LoadNotesAction({
    //       document_id: res._id,
    //       document_collection: MisoCollection.SERVICES
    //     }));
    //   });
  }

  onUpdateStore(updatedService: MiService): void {
    // this.selectedForm = '';
    this.formOpen = false;

    this.store.dispatch(new SDActions.SDLoadSuccess({
      miService: updatedService,
      currentForm: ''
    }));

    // this.updateServiceStatus(updatedService);
  }

  scrollToSection(which: string): void {
    setTimeout(() => {
      scrollTo(which);
    }, 100);
  }

  showForm(formName: string): void {
    // this.selectedForm = formName || '';
    // this.formOpen = this.selectedForm && this.selectedForm !== '';
    this.formOpen ? this.scrollToSection(this.selectedForm) : window.scrollTo(0, 0);
  }

  onFormCancelClicked(planCancellationModal?: any) {
    planCancellationModal.close();
    // this.store.dispatch(new SDActions.SDShowForm({ currentForm: '' }));
  }

  removeCancellationModalCloseHandler(removeCancellationModal: any) {
    removeCancellationModal.close();
    // this.store.dispatch(new SDActions.SDShowForm({ currentForm: '' }));
  }

  // Activation

  // onConfirmActivationClick() {
  //   // this.confirmationFormGroup.get('activation_date').setValue(this.service.current_financial.start_date);
  //   // this.store.dispatch(new SDActions.SDShowForm({ currentForm: SDActions.FormTypes.ACTIVATION_FORM }));
  // }


  onConfirmActivationCancelClicked(): void {
    this.confirmationFormGroup.get('activation_date').setValue(null);
    this.confirmationFormGroup.updateValueAndValidity();
    this.onFormCancelClicked();
  }

  // Cancellation

  onCancelClicked(planCancellationModal: any) {
    planCancellationModal.open();
    // this.store.dispatch(new SDActions.SDShowForm({ currentForm: SDActions.FormTypes.CANCELLATION_FORM }));
  }

  onConfirmCancellationClicked(confirmCancellationModal: any) {
    confirmCancellationModal.open()
    // this.store.dispatch(new SDActions.SDShowForm({ currentForm: SDActions.FormTypes.CONFIRM_CANCELLATION_FORM }));
  }

  deleteServiceHandler(deleteServiceModal: any) {
    deleteServiceModal.open()
  }

  closeDeleteServiceModal(deleteServiceModal: any) {
    deleteServiceModal.close()
  }
  onRemoveCancellationClick(removeCancellationModal: any): void {
    removeCancellationModal.open()
    // this.store.dispatch(new SDActions.SDShowForm({ currentForm: SDActions.FormTypes.REMOVE_CANCELLATION_FORM }));
  }

  // Reterm

  // onRetermClicked() {

  //   // this.store.dispatch(new SDActions.SDShowForm({currentForm: SDActions.FormTypes.RETERM_FORM}));
  // }

  // onGroupClicked() {
  //   scrollTo('service-group-list');
  //   this.store.dispatch(new SDActions.SDShowForm({ currentForm: SDActions.FormTypes.GROUP_FORM }));
  // }

  allowedToDelete(role: number) {
    return isEditRoleOnBU(role);
  }

  onSeeDetails(fixServiceModal: any) {
    fixServiceModal.open();

      // this.miModalService.open(fixServiceModal);
  }

  goTo(which: string, fixServiceModal: any){
    fixServiceModal.close();
    scrollTo(which);
  }

}