import { Component, Input } from "@angular/core";
import { ModalService } from "@independer/ng-modal";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import { ExcelEmailNotificationModalComponent } from "../../excel-email-notification-modal/excel-email-notification-modal.component";
import { RunReportOrViewModalV2Component } from "../action-dropdown-buttons-v2/run-report-or-view-modal-v2/run-report-or-view-modal-v2.component";

@Component({
  selector: 'mi-header-action-dropdown-v2',
  templateUrl: './action-dropdown-buttons-v2.component.html',
  styleUrls: ['./action-dropdown-buttons-v2.component.scss']
})

export class HeaderActionDropdownV2Component extends AbstractPageComponent {
  @Input() searchType: string = 'SERVICE';
  @Input() addNewURL: string = '';
  total = 0;
  total_mrc = 0;
  loading = true;
  q: string = '';

  constructor(
    private modalService: ModalService) {
    super();
  }

  ngOnInit() {
  }

  openRunModal(action_type: string = "SERVICE_REPORT") {
    const modalRef = this.modalService.open(RunReportOrViewModalV2Component, m => {
      m.action_type = action_type;
      m.searchType = this.searchType;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;
      console.log("report", report)
      if(report && report.print && action_type === "SERVICE_REPORT"){
        this.createNotification("SERVICE_REPORT");
      }

    });
  }

  createNotification(action_type) {
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Create Notification";
      m.message = `Your request to create ${action_type} was successful. Please check Actions to use it in the future.`;
      m.closeButtonLabel = "Confirm";
    });
  }
}