import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  DownloadReport,
  ReportsType,
  ReportService,
} from "src/shared/models/Report";

@Injectable()
export class NewReportsV2Service {
  private apiUrl = "_MICRO_SERVICE_/upload/report";
  private uploadHost = '_MICRO_SERVICE_UPLOAD_';
  private apiUrlServiceList = "_MICRO_SERVICE_/upload/report/service/list";
  private apiUrlDownloadReport = "_MICRO_SERVICE_/upload/report/download";

  constructor(private http: HttpClient) {}

  list(tenant_id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/list/?tenant_id=${tenant_id}`);
  }

  reportTypesList(tenant_id: string): Observable<ReportsType[]> {
    return this.http
      .get(`${this.apiUrl}/type?tenant_id=${tenant_id}`)
      .map((response: any) => response as ReportsType[]);
  }

  serviceList(tenantId: string): Observable<ReportService[]> {
    return this.http
      .get(`${this.apiUrlServiceList}?tenant_id=${tenantId}`)
      .map((response: any) => response as ReportService[]);
  }

  createReport(report: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/create`, report)
      .map((response: any) => response.data);
  }

  editReport(report: any): Observable<any> {
    return this.http
      .put(`${this.apiUrl}/update`, report)
      .map((response: any) => response.data);
  }

  deleteReport(id: string) {
    return this.http
      .delete(`${this.apiUrl}/delete?report_id=${id}`)
      .map((response) => response);
  }

  downloadReport(reportDetails: DownloadReport): Observable<any> {
    return this.http
      .post(this.apiUrlDownloadReport, reportDetails, {
        responseType: "arraybuffer",
      })
      .map((response: any) => {
        return response;
      });
  }

}
