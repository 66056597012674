<div *ngIf="loaded">
  <table>
    <tr *ngIf="divisions.length || selectedDivision">
      <td class="key">Division</td>
      <td class="select-dropdown">
        <select (change)="onDivision($event)" [disabled]="divisions.length===1 && !AllTenantAccess" [(ngModel)]="selectedDivision">
          <option value="" *ngIf="AllTenantAccess">Company Wide</option>
          <option *ngFor="let division of divisions;" value="{{division.id}}">
            {{division.name}}
          </option>
        </select>
      </td>
    </tr>
    <tr *ngIf="teams[selectedDivision] && teams[selectedDivision].length">
      <td class="key">Team</td>
      <td class="select-dropdown">
        <select [disabled]="selectedDivision? teams[selectedDivision].length===1 
          && allBusinessUnits[selectedDivision].role === 999 ? true : false: true" 
          [(ngModel)]="selectedTeam"
          (change)="onTeam($event)">
          <option value="" *ngIf="allBusinessUnits[this.selectedDivision].role<999">Division Wide</option>
          <option *ngFor="let team of teams[selectedDivision];" value="{{team.id}}">
            {{team.name}}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td class="key"><span class=required>M3 Owner</span></td>
      <td class="input-group">
        <select [disabled]="users && users.length? false: true"
          [ngClass]="{'select-dropdown ng-pristine ng-touched': true, 'ng-invalid':!users.length}" 
          [(ngModel)]="selectedUser"
          (change)="onUser($event)">
          <option value="" disabled>{{users.length? 'Select User' : 'No Available User'}}</option>
          <option *ngFor="let user of users" value="{{user.email}}">
            {{user.first_name}} {{user.last_name}}
          </option>
        </select>
      </td>
    </tr>
  </table>
</div>