<modal class="extra-wide" [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>Column Select</h4>
  </modal-header>
  <modal-content>
    <ng-container [ngSwitch]="searchType">

      <ng-container *ngSwitchCase="'SERVICE'">
        <mi-fields-to-download-template-v2
          reference="MiServicesColumnSelect"
          [fields]="fields"
          [cfFields]="cfFields"
          [tagFields]="tagFields"
          [showCancel]="true"
          (canceled)="close()"
          downloadButtonName='Continue'
          (fieldsSelected)="onFieldsSelected($event)"
          [onSubmit]="onSubmit">
        </mi-fields-to-download-template-v2>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="select-all">
          <a (click)="selectAll(false)">Select All</a>
          <a (click)="selectAll(true)">Deselect All</a>
        </div>
        <br/>
        <div>
          <ng-container *ngFor="let column of _columns; let i = index">
            <label
              *ngIf="i>0"
              class="checkbox">
              <input type="checkbox"
                [ngModel]="!_columns[i].hidden"
                [value]="column.hidden"
                (click)="onToggleSelect(i)"
              />
              {{customFieldById[column.name]? customFieldById[column.name] : column.name}}
            </label>
          </ng-container>
        </div>
        <div class="button-container">
          <a mi-button
            buttonTheme="dark-theme"
            (click)="close()">
            Continue
          </a>
        </div>
      </ng-container>

    </ng-container>

  </modal-content>
  <modal-footer>
    <div class="cta">
      <button (click)="onModalClose()"
              mi-button buttonTheme="light-theme">
        {{'GLOBAL.BUTTON_CANCEL' | translate}}
      </button>
      <button (click)="submit()"
              mi-button buttonTheme="dark-theme">
        Continue
      </button>      
    </div>
  </modal-footer>
</modal>