import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {TermsAndConditions} from '../../../shared/models/TermsAndConditions';
import {CanDelete} from 'src/shared/models/CanDelete';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class TenantTermsAndConditionsService {

  private apiUrlTenantTermsAndConditionsCRUD = '/{tenant}/terms-and-conditions/vendors/';
  private apiUrlTenantTermsAndConditionByDisplayIdCRUD = '/{tenant}/terms-and-conditions-by-display-id/';
  private apiUrlTenantTermsAndConditionEditCRUD = '/{tenant}/terms-and-conditions-edit';
  private apiUrlTenantTermsAndConditionDELETE = '/{tenant}/terms-and-conditions';


  constructor(private http: HttpClient) {
  }

  listTermsAndConditions(vendorId: string): Observable<Array<TermsAndConditions>> {
    return this.http
      .get(`${this.apiUrlTenantTermsAndConditionsCRUD}${vendorId}`)
      .map((response: any) => response.data.terms_and_conditions as Array<TermsAndConditions>);
  }

  createTermsAndConditions(vendorId: string, tAndC: TermsAndConditions): Observable<TermsAndConditions> {
    return this.http
      .post(`${this.apiUrlTenantTermsAndConditionsCRUD}${vendorId}`, tAndC)
      .map((response: any) => response.data.terms_and_conditions as TermsAndConditions);
  }

  getByDisplayId(display_id: string): Observable<TermsAndConditions> {
    return this.http
      .get(`${this.apiUrlTenantTermsAndConditionByDisplayIdCRUD}${display_id}`)
      .map((response: any) => response.data.terms_and_conditions as TermsAndConditions);
  }

  updateTermsAndConditions(terms_and_conditions: TermsAndConditions): Observable<TermsAndConditions> {
    return this.http
      .put(`${this.apiUrlTenantTermsAndConditionEditCRUD}/${terms_and_conditions._id}`, {terms_and_conditions})
      .map((response: any) => response.data.terms_and_conditions as TermsAndConditions);
  }

  canDelete(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.apiUrlTenantTermsAndConditionDELETE}/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  deleteTac(id: String): Observable<TermsAndConditions> {
    return this.http
      .delete(`${this.apiUrlTenantTermsAndConditionDELETE}/${id}`)
      .map((response: any) => response.data.product as TermsAndConditions);
  }

}
