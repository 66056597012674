<mi-search-header searchPlaceholder="Search Contacts" (searchParamChanged)="searchParamChangeHandler($event)"
  [total]="totalRow" searchType="CONTACT">
</mi-search-header>
<ng-container *ngIf="resultsLoaded; else loading">
    <mi-sortable-table [isServerSideSortEnable]="true" (serverSideSortHandler)="sortChangeHandler($event)"
      *ngIf="hits && hits.length" [rows]="hits" [columns]="contactsColumns">
    </mi-sortable-table>
  <ng-container *ngIf="!hits || !hits.length">
    <p> "No matching results found"</p>
  </ng-container>
</ng-container>
<ng-container *ngIf="(totalRow && totalPages) && (hits || hits.length)">
  <mi-pagination totalRow="{{ totalRow }}" totalPages="{{totalPages}}"
    (paginationChangeHandler)="this.paginationChangeHandler($event)">
  </mi-pagination>
</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #contactTmpl let-row="row" let-value="value">
  <!--<a routerLink="/contacts/{{row.id}}">-->
    <ng-container>
      {{row.first_name + row.last_name}}
    </ng-container>
  <!--</a>-->
  <!--<a class="edit-contact" routerLink="/contacts/{{row.id}}/edit"></a>-->
</ng-template>
<ng-template #contactStatusTmpl let-row="row" let-value="value">
  <ng-container>{{row.is_active ? "Active" : "Inactive" }}</ng-container><br>
</ng-template>
<ng-template #officePhoneTmpl let-row="row" let-value="value">
  <ng-container>{{row.office_phone ? row.office_phone : "-"}}</ng-container><br>
</ng-template>
<ng-template #mobilePhoneTmpl let-row="row" let-value="value">
  <ng-container>{{row.mobile_phone ? row.mobile_phone : "-"}}</ng-container><br>
</ng-template>