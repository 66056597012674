import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild, OnInit} from '@angular/core';
import {SortableTableColumn, SortableTemplatesEnum} from '../../ng-models/SortableTableColumn.interface';
import {NO_DATA} from '../../ng-models/SectionDataTable.model';
import * as appDataReducer from '../../state/app-data/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import FeDateUtil from 'src/shared/utils/FeDate.util';


type SortableTableVariant =
  | 'NORMAL'
  | 'SMALL';


@Component({
  selector: 'mi-sortable-table',
  templateUrl: './sortable-table.component.html',
  styleUrls: ['./sortable-table.component.scss']
})

export class SortableTable implements OnInit {

  protected readonly NO_DATA: string = NO_DATA;
  private originalSortChanged: EventEmitter<any> = new EventEmitter();

  _rows: Array<any> = [];
  _columns: Array<SortableTableColumn> = [];
  _classes: any = {};
  _rowsSelectable: boolean = false;
  _selectedRow: any = null;
  timeZone: string = "America/New_York"

  @Input() public externalClasses: string = '';
  @Output() public sortChanged: EventEmitter<any> = this.originalSortChanged;
  @Output() public serverSideSortHandler: EventEmitter<any> = this.originalSortChanged;
  @Output() public rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() public rowIdProp: string = 'display_id';
  @Input() public externalSort: boolean = false;
  @Input() public isServerSideSortEnable: boolean = false;
  _columnClicked: string = '';

  @ViewChild('defaultCellTpl', {static: true}) defaultCellTpl: TemplateRef<any>;
  @ViewChild('defaultHeaderTpl', {static: true}) defaultHeaderTpl: TemplateRef<any>;
  @ViewChild('idLinkTpl', {static: true}) idLinkTpl: TemplateRef<any>;
  @ViewChild('idLinkTpl2', {static: true}) idLinkTpl2: TemplateRef<any>;
  @ViewChild('contactTpl', {static: true}) contactTpl: TemplateRef<any>;
  @ViewChild('shortDateTpl', {static: true}) shortDateTpl: TemplateRef<any>;
  @ViewChild('shortDateTimeTpl', {static: true}) shortDateTimeTpl: TemplateRef<any>;
  @ViewChild('shortISODateTimeTpl', {static: true}) shortISODateTimeTpl: TemplateRef<any>;
  @ViewChild('trimmedTextTpl', {static: true}) trimmedTextTpl: TemplateRef<any>;
  @ViewChild('currencyTpl', {static: true}) currencyTpl: TemplateRef<any>;
  @ViewChild('roundedCurrencyTpl', {static: true}) roundedCurrencyTpl: TemplateRef<any>;
  @ViewChild('truncateTpl', {static: true}) truncateTpl: TemplateRef<any>;
  @ViewChild('numberTpl', {static: true}) numberTpl: TemplateRef<any>;
  @ViewChild('tenantCurrencyTpl', {static: true}) tenantCurrencyTpl: TemplateRef<any>;
  @ViewChild('serviceCurrencyTpl', {static: true}) serviceCurrencyTpl: TemplateRef<any>;
 
  constructor(private store: Store<appDataReducer.State>,) {
  }

  ngOnInit(){
    const tenantStateData: Observable<appDataReducer.State> = this.store.select(appDataReducer.getAppData);
    tenantStateData.first()
      .subscribe(data => {
        if(data && data.tenantData && data.tenantData.timezone) {
          this.timeZone =  data.tenantData.timezone;
        }
      })
  }

  convertTimeZone(time){
    return FeDateUtil.applyTimeZoneFromISO(time, this.timeZone)
  }

  @Input()
  public set variant(value: SortableTableVariant) {
    if (value === 'SMALL') {
      this._classes['mi-sort-table__small'] = true;
    }
  }

  @Input()
  public set rowSelectable(value: boolean) {
    this._classes['mi-sort-table__row-selectable'] = true;
    this._rowsSelectable = value;
  }

  @Input()
  public set columns(values: Array<SortableTableColumn>) {
    // loop through the passed data and resolve the templates

    const templatedColumns = values.map(item => {

      switch (item.cellTemplate) {
        case SortableTemplatesEnum.shortDate:
          item.cellTemplate = this.shortDateTpl;
          break;
        case SortableTemplatesEnum.shortDateTime:
          item.cellTemplate = this.shortDateTimeTpl;
          break;
        case SortableTemplatesEnum.shortISODateTime:
          item.cellTemplate = this.shortISODateTimeTpl;
          break;
        case SortableTemplatesEnum.idLink:
            item.cellTemplate = this.idLinkTpl;
            break;
        case SortableTemplatesEnum.idLink2:
          item.cellTemplate = this.idLinkTpl2;
          break;
        case SortableTemplatesEnum.contact:
          item.cellTemplate = this.contactTpl;
          break;
        case SortableTemplatesEnum.trimmedText:
          item.cellTemplate = this.trimmedTextTpl;
          break;
        case SortableTemplatesEnum.currency:
          item.cellTemplate = this.currencyTpl;
          break;
        case SortableTemplatesEnum.roundedCurrency:
          item.cellTemplate = this.roundedCurrencyTpl;
          break;
        case SortableTemplatesEnum.tenantCurrency:
          item.cellTemplate = this.tenantCurrencyTpl;
          break;
        case SortableTemplatesEnum.serviceCurrency:
          item.cellTemplate = this.serviceCurrencyTpl;
          break;
        case SortableTemplatesEnum.truncate:
          item.cellTemplate = this.truncateTpl;
          break;
        case SortableTemplatesEnum.number:
          item.cellTemplate = this.numberTpl;
          break;
        default:
          if (!item.cellTemplate) {
            item.cellTemplate = this.defaultCellTpl;
          }
          break;
      }
      if (!item.headerTemplate) {
        item.headerTemplate = this.defaultHeaderTpl;
      }

      return item;

    });

    this._columns = templatedColumns.filter((item) => {
      return !item.hidden;
    });
  }

  @Input()
  public set rows(values: Array<any>) {
    this._rows = values;

    if (!this.hasExternalSortListener()) {
      this.internalDataSort();
    }
  }

  /**
   * Take a row and dig deep into its properties recursively by key name
   * @param row
   * @param {string} propertyName
   * @returns {string}
   * @example
   *  getData({test:{foo:5}}, 'test.foo');
   */

  public getData(row: any, propertyName: string): string {
    if (!propertyName) {
      return '';
    }
    if (propertyName === '...') {
      return row;
    }
    return propertyName.split('.').reduce(
      (prev: any, curr: string) => {
        let ret: any = prev[curr];

        if (!prev[curr] && !(prev[curr] === 0)) {
          // anything falsy other than the number zero,
          // is set to empty string
          ret = '';
        }
        return ret;
      },
      row);
  }

  public onChangeTable(data: any): void {
    if (this.isServerSideSortEnable) {
      this.serverSideSortHandler.emit(data)
    }
    this._columns.forEach((col: any) => {
      // Remove sorting from the other tables
      if (col.name !== data.column.name && col.sortable) {
        col.sort = '';
      }
    });
    if (!!this.hasExternalSortListener()) {
      this.sortChanged.emit(data);
    } else {
      // We want to sort internally
      this.internalDataSort();
    }
  }

  hasExternalSortListener(): boolean {
    /*
    console.log("this.sortChanged", this.sortChanged);
    console.log("this.originalSortChanged", this.originalSortChanged);
    return this.sortChanged !== this.originalSortChanged;
    */
   if(this.externalSort) {
     return true;
   }
   return false;
  }

  internalDataSort() {
    this._columns.forEach((col: any) => {
      if (col.sortable && col.sort) {
        this.sort(col);
      }
    });
  }

  sort(column) {
    this._rows.sort((rowA, rowB) => {
      const aValue = this.getData(rowA, column.prop);
      const bValue = this.getData(rowB, column.prop);

      if (aValue > bValue) {
        return column.sort === 'asc' ? 1 : -1;
      } else if (aValue < bValue) {
        return column.sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  onRowClick(value: any) {
    if(this._rowsSelectable) {
      this._selectedRow= value;
      this.rowSelected.emit(value);
    }
  }

  stringToNumber(value: any) {
    if (value) {
      value = ('' + value).replace(/[^0-9]+/g, '');
    }
    return Number(value);
  }
}
