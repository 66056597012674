import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import {of} from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import * as UserActions from './actions';
import {UserStoreService} from "../user-store.service";

@Injectable() export class UserEffects {

  @Effect()
  userVerify$: Observable<Action> = this.actions$.ofType(UserActions.ActionTypes.USER_VERIFY)
    .mergeMap( action => {
      return this.userStoreService.getLoggedInService()
        .map( secureUser => ( new UserActions.UserVerifySuccessAction(secureUser) ))
        .catch((error) => of( new UserActions.UserVerifyErrorAction({error})));
      }
    );

  constructor(
    private userStoreService: UserStoreService,
    private actions$: Actions
  ) {}
}

