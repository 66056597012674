import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../libs/api/account/accountUser.service';
import {ActivatedRoute, NavigationExtras, Params, Router} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';

import * as appDataReducer from '../../../shared/state/app-data/reducers';
import {map, takeUntil} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import {User} from '../../../shared/models/User';
import * as userReducer from '../../../libs/user-store/state/reducers';
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {ISecureUser} from "../../../common/security/secureUser";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {AuthV2Service} from "../../../libs/api2/auth-v2/auth-v2.service";
import { TenantV2 } from 'src/libs/api2/types/TenantV2.interface';
import { Tenant } from 'src/shared/models/Tenant';
import { TenantV2Service } from 'src/libs/api2/tenant-v2/tenant-v2.service';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import linkFormatter, { getSupportLink } from 'src/common/utils/linkFormatter';
const log = createLogger(LOG_LEVELS.PAGE);

@Component({
  templateUrl: './accept-terms-and-conditions.component.html',
  styleUrls: ['./accept-terms-and-conditions.component.scss']
})

export class AcceptTermsAndConditionsComponent extends AbstractPageComponent implements OnInit {

  successRoute: string = '/';
  successRedirect: string = '/';
  privacyPolicy: string;
  loaded: boolean = false;
  accepted: boolean = false;
  error: any = false;
  acceptError: any = false;
  user: User;
  secureUser: ISecureUser;
  tenantData: Tenant;
  termsLink: string;
  supportLink: string;
  link = linkFormatter.getAccountsLink();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store<userReducer.State>,
              private tenantV2Service: TenantV2Service) {
    super();
  }

  ngOnInit(): void {

    this.loaded = false;
    this.termsLink = FrontEndSettingsUtil.get().CURRENT_TERMS_AND_CONDITIONS_URL;
    this.supportLink = getSupportLink();

      const fetchTenant = this.store.pipe(
        select(appDataReducer.getAppData)
      );
   

      combineLatest(fetchTenant, this.activatedRoute.queryParams)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(
          ([tenant, params]) => {
            this.tenantData = tenant.tenantData;
            if (params.redirect) {
              this.successRedirect = params.redirect as string;
            }
            this.loaded = true;
          },
          (e) => {
            this.error = e;
          }
        );

      

  }

  toggleAccept() {
    this.accepted = !this.accepted;
  }

  onSubmitClick() {
    this.acceptError = '';
    if (this.accepted) {

      this.tenantV2Service.acceptTermsAndConditions(this.tenantData.domain)
          .subscribe(
            () => this.doRedirect(),
            (e)=> this.error=e
          );
    } else {
      this.acceptError = 'You must accept the terms and conditions';
    }
  }

  doRedirect(): void {
    if (this.successRedirect) {
      window.location.href = this.successRedirect as string;
    } else {
      this.router.navigate([this.successRoute]);
    }
  }

}
