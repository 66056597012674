import {createFeatureSelector, createSelector,} from '@ngrx/store';
import * as emailListActions from './actions';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {EVENT_TYPE_AUTO_CANCELLATION} from '../../../common/utils/sharedConstants';
import {Email} from '../../models/Email.interface';

const log = createLogger(LOG_LEVELS.REDUX);

export interface State {
  display_id: string;
  emails: Email[];
  error: any;
  loaded: boolean;
}

const initialState: State = {
  display_id: null,
  emails: null,
  error: null,
  loaded: false,
};

export function reducer(state = initialState, action: emailListActions.Actions): State {
  switch (action.type) {
    case emailListActions.ActionTypes.RESET: {
      return {
        ...initialState
      };
    }
    case emailListActions.ActionTypes.LOAD_SERVICE_EMAILS: {
      return {
        ...state,
        display_id: action.payload.display_id,
        emails: action.payload.emails || [],
        error: false,
        loaded: false
      };
    }
    case emailListActions.ActionTypes.LOAD_PARENT_SERVICE_EMAILS: {
      return {
        ...state,
        display_id: action.payload.display_id,
        emails: action.payload.emails || [],
        error: false,
        loaded: false
      };
    }
    case emailListActions.ActionTypes.LOAD_EMAIL_FAIL: {
      return {
        ...state,
        error: action.payload,
        loaded: true
      };
    }
    case emailListActions.ActionTypes.LOAD_EMAIL_SUCCESS: {
      return {
        ...state,
        emails: action.payload,
        error: null,
        loaded: true
      };
    }
    default: {
      return state;
    }
  }
}

export const getServiceEmailList = createFeatureSelector<State>('serviceEmailList');

export const emailsLoaded = createSelector(
  getServiceEmailList,
  (state) => state.loaded
);

export const serviceEmails = createSelector(
  getServiceEmailList,
  (state) => state.emails
);
