
<ng-container *ngIf="!showAddProduct">

  <mi-server-error [error]="normalizingProductError"></mi-server-error>
  <ng-container *ngIf="!normalizingProductLoad; else normalizingProductLoadTpl">


    <div class="product-search card pb-2 px-3 pt-3 mb-2">
      <div class="search-category-container">
        <div class="search-container">
          <mi-search placeholder="Search for Product" qtyMessage="{{ 'SEARCH_QUANTITIES.PRODUCTS' | translate:{count:total} | translateSelector:total }}"
          (searchTermChanged)="onSearchTermChanged($event)" (clearSearch)="onClearSearch()" [value]="defaultSearchText"></mi-search>
        </div>
        <div class="category-container">
          <ng-select class="mi-select" [items]="categories" bindLabel="label" bindValue="value" [multiple]="false"
            [closeOnSelect]="true" (change)="filterProductByCategory($event)" placeholder="Filter Category">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div [style.margin-left]="item.level + 'px'">{{item.label}}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="pvh-container">
        <product-vendor-header (onUnSelectVendor)="onUnSelectSearchVendor($event)" [locked]="locked" [vendor]="vendor"
          [theme]="theme">
        </product-vendor-header>
      </div>
      <mi-server-error [error]="productFindError"></mi-server-error>
    </div>
    <div class="mi-grid-std product-search-results card p-2">
      <div *ngIf="(hits && hits.length); else noContent">
        <mi-sortable-table [rows]="hits" [columns]="generalInformationTableColumns" variant="SMALL">
        </mi-sortable-table>
      </div>

      <mi-loading-animation class="first-load" *ngIf="!resultsLoadedFirstTime">
      </mi-loading-animation>

      <div class="load-more note-info mt-2" *ngIf="(hits.length < total)">
        <a mi-button buttonTheme="light-theme" href="#" *ngIf="(page*pageSize) <= total" (click)="loadMoreClicked($event)">Load More</a>
      </div>

      <ng-template #noContent>
        <div *ngIf="resultsLoaded">No products found</div>
      </ng-template>
    </div>

  </ng-container>

</ng-container>

<div class="add-product card p-2">
  <div *ngIf="!showAddProduct">
    <ng-container *ngIf='total<2;else moreThanOne'>
      <h3>{{total}} Product was found for {{vendor.vendor_name}}</h3>
    </ng-container>
    <ng-template #moreThanOne>
      <h3 class="note-info">{{total}} Products were found for {{vendor.vendor_name}}</h3>
    </ng-template>
    <p>Please search the list above. If you do not find your product <br/> above you may create one.</p>

    <button 
      (click)="createNewButton()"
      class="btn btn-sm btn-primary">
      Create New {{vendor.vendor_name}} Product
    </button>

  </div>
  <ng-container *ngIf="showAddProduct">
    <h3>Add Product</h3>
    <br />
    <div>
      <mi-product-form [productServicesMode]="productServicesMode" [lockVendor]="true" [lockVendorTheme]="theme"
        [productInsert]="dummyProduct" (canceled)="onAddProductCancel()" (productCompleted)="onAddComplete($event)">
      </mi-product-form>
    </div>
  </ng-container>
</div>

<ng-template #categoryDetailTpl let-row="row" let-value="value">
  <a routerLink="/categories/{{row.product_category._id}}">{{row.product_category.category_name}}</a>
</ng-template>

<ng-template #propertiesTpl let-row="row" let-value="value">
  <div [class.truncate]="clicked !== row._id" (click)="clicked = (clicked !== row._id ? row._id : null)">{{getProductProperties(row.product_properties)}}</div>
</ng-template>

<ng-template #productNameTpl let-row="row" let-value="value">
  <!--
  <div style="display:flex;align-items:center;">
    <input type="checkbox"
           value="{{row._id}}"
           id="item-{{row._id}}"
           (click)="onProductSelect($event)"
           [checked]="selectedProduct && row._id==selectedProduct._id">
    <label for="item-{{row._id}}">
      {{row.product_name}}
    </label>
  </div>
  -->
  <div style="display:flex;align-items:center;">
    <input type="checkbox"
           value="{{row.id}}"
           id="item-{{row.id}}"
           (click)="onProductSelect($event)"
           [checked]="selectedProduct && row.id==selectedProduct.id">
    <label for="item-{{row.id}}">
      {{row.product_name}}
    </label>
  </div>
</ng-template>

<ng-template #misoNormalizedProductNameTpl let-row="row" let-value="value">
  <ng-container *ngIf="row.actual_product && row.product_name !== row.actual_product.product_name">
    {{ row.actual_product.product_name}}
  </ng-container>
</ng-template>


<ng-template #normalizingProductLoadTpl >
  <mi-loading-animation>
  </mi-loading-animation>
</ng-template>
