import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {TypeReferenceEnum} from '../../../shared/ng-models/TypeReference.enum';
import { BulkUploadTemplateField } from 'src/shared/models/BulkUploadTemplateSection.interface';
import {Settings as luxonSettings} from 'luxon';
import FeDateUtil from 'src/shared/utils/FeDate.util';

export interface IResolvedData {
  property_id: string;
  resolution_value: string;
  system_id?: string;
  is_autoresolved: boolean;
  ignore: boolean;
}
export interface IAssignServiceKey {
  service_id: string;
  service_key: string;
}

export interface IMatchingService {
  bulkUploadServiceId: string;
  serviceKey?: string;
  display_id?: string;
}

@Injectable()
export class UploadV2Service {

  private uploadHost = '_MICRO_SERVICE_UPLOAD_';
  private uploadSubscriptionHost = '_MICRO_SERVICE_/subscription';


  constructor(private http: HttpClient) {

  }

  getListByType(typeReference: TypeReferenceEnum, tenantId, search, pageNumber: number = 0, pageSize: number = 20, sortField: string = "", sortType: string = ""): Observable<any> {
    switch (typeReference) {
      case "Subscriptions":
        return this.http
          .get(`${this.uploadSubscriptionHost}/filesStatus?tenantId=${tenantId}&pagination=true&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&sortField=${sortField}&sortType=${sortType}`)
          .map((response: any) => response);
    
      default:
        return this.http
          .get(`${this.uploadHost}/filesStatus?tenantId=${tenantId}&pagination=true&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&typeReference=${typeReference}&sortField=${sortField}&sortType=${sortType}`) // &typeReference=${typeReference}
          .map((response: any) => response);
    }

  }

  getTemplateByType(tenantId, typeReference: TypeReferenceEnum): Observable<BulkUploadTemplateField[]> {

    return this.http
      .get(`${this.uploadHost}/properties?tenantId=${tenantId}&typeReference=${typeReference}&typeReference=${typeReference}`) // &typeReference=${typeReference}
      .map((response: any) => response);

  }

  getSubscriptionTemplate(tenantId): Observable<BulkUploadTemplateField[]> {
    const user_date= new Date();
    const user_timezone = luxonSettings.defaultZoneName;

    return this.http
      .post(`${this.uploadSubscriptionHost}/template`, {tenant_id:tenantId, user_date, user_timezone}, {responseType: 'arraybuffer'})
      .map((response: any) => response);
  }

  setTemplateByType(tenant_id, user_id, typeReference: TypeReferenceEnum, fields: any): any {
    const user_date= new Date();
    const user_timezone = luxonSettings.defaultZoneName;

    let param = {
      tenant_id, user_id, fields, user_date, user_timezone
    };  //, typeReference

    return this.http
      .post(`${this.uploadHost}/properties`, param, {responseType: 'arraybuffer'})
      .map((response: any) => response);

  }

  getRowsByType(typeReference: TypeReferenceEnum, bulk_upload_id, search, pageNumber: number = 0, pageSize: number = 20, upload_status:string = ''): Observable<any> {
    switch (typeReference) {
      case "Subscriptions":
        return this.http
          .get(`${this.uploadSubscriptionHost}/draft/list?fileId=${bulk_upload_id}&pagination=true&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&upload_status=${upload_status}&typeReference=${typeReference}`) // &typeReference=${typeReference}
          .map((response: any) => {
            response.data = response.data.map(data => {
              return {
                ...data,
                service_start_date: data.service_start_date? FeDateUtil.toFormat(data.service_start_date, "MM/dd/yyyy"): '',
                service_end_date: data.service_end_date? FeDateUtil.toFormat(data.service_end_date, "MM/dd/yyyy"): '',
                order_date: data.order_date? FeDateUtil.toFormat(data.order_date, "MM/dd/yyyy"): '',
                invoice_date: data.invoice_date? FeDateUtil.toFormat(data.invoice_date, "MM/dd/yyyy"): ''          }
            })
            return response;
          });
    
      default:
        return this.http
          .get(`${this.uploadHost}/userServices?fileId=${bulk_upload_id}&pagination=true&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&upload_status=${upload_status}&typeReference=${typeReference}`) // &typeReference=${typeReference}
          .map((response: any) => {
            response.data = response.data.map(data => {
              return {
                ...data,
                service_start_date: data.service_start_date? FeDateUtil.toFormat(data.service_start_date, "MM/dd/yyyy"): '',
                service_end_date: data.service_end_date? FeDateUtil.toFormat(data.service_end_date, "MM/dd/yyyy"): '',
                order_date: data.order_date? FeDateUtil.toFormat(data.order_date, "MM/dd/yyyy"): '',
                invoice_date: data.invoice_date? FeDateUtil.toFormat(data.invoice_date, "MM/dd/yyyy"): ''          }
            })
            return response;
          });
      }

  }

  getRowData(bulk_upload_row_id, typeReference): Observable<any> {
    switch (typeReference) {
      case "Subscriptions":
        return this.http
          .get(`${this.uploadSubscriptionHost}/draft?id=${bulk_upload_row_id}`)
          .map((response: any) => {
            response.type_reference_enum = typeReference;
            return response;
          });

      default:
        return this.http
          .get(`${this.uploadHost}/serviceProperty?serviceId=${bulk_upload_row_id}`)
          .map((response: any) => {
            response.type_reference_enum = typeReference;
            return response;
          });
    }

  }

  resolveSubscription(resolvedData:any): Observable<any> {
    return this.http
      .put(`${this.uploadSubscriptionHost}/draft`, resolvedData)
      .map((response: any) => response);

  }

  resolveByType(resolvedData:IResolvedData): Observable<any> {
    return this.http
      .post(`${this.uploadHost}/updateField`, resolvedData)
      .map((response: any) => response);

  }

  createServiceFromBulkuploadRow(serviceCreateBody:any): Observable<any> {
    /*
    return this.http.post(`/{tenant}/bulk-upload-v2/create-service`, serviceCreateBody)
    .map((response: any) => {
      return response;
    });
    */
    return this.http.post(`${this.uploadHost}/service/createFromDraft`, {serviceId: serviceCreateBody.bulkUploadServiceId, tenantId: serviceCreateBody.tenantId})
    .map((response: any) => {
      return response;
    });
  }

  createSubscriptionFromBulkuploadRow(createBody:any): Observable<any> {
    return this.http.post(`${this.uploadSubscriptionHost}/create`, createBody)
    .map((response: any) => {
      return response;
    });
  }

  getQueryParams(queryParams: any): HttpParams {
    return new HttpParams({fromObject: queryParams as any});
  }

  getServices(tenantId, queryParams:any): Observable<any> {
    return this.http
      .get(`${this.uploadHost}/service/list?tenant_id=${tenantId}`, {params: this.getQueryParams(queryParams)})
      .map((response: any) => response);
  }

  assignServiceKey(assignServiceKey:IAssignServiceKey): Observable<any> {
    return this.http
      .post(`${this.uploadHost}/service/assignServiceKey`, assignServiceKey)
      .map((response: any) => response);
  }

  matchingServices(matchingService:IMatchingService): Observable<any> {
    return this.http.post(`/{tenant}/bulk-upload-v2/matching-service`, matchingService)
    .map((response: any) => {
      return response;
    });
  }

  createServiceDownload(filters:any, queryParams: any = {}): Observable<any> {
    const user_date= new Date();
    const user_timezone = luxonSettings.defaultZoneName;
    return this.http.post(`/{tenant}/bulk-download-v2/service?${queryParams}`, {filters, user_date, user_timezone},  {responseType: 'arraybuffer'})
    .map((response: any) => {
      return response;
    });
  }

  auditTrailRequest(audit: {start_date:string, end_date:string, tenant_id:string, users:string[], audit_type: string}): Observable<any> {
    const user_date = new Date();
    const user_timezone = luxonSettings.defaultZoneName;
    let auditBody = {
      ...audit,
      user_date: user_date,
      user_timezone: user_timezone
    }

    return this.http
      .post(`${this.uploadHost}/service/audit`, auditBody)
      .map((response: any) => response.toString());
  }
}
