<p *ngIf="account_id" class="hint">Modifying the Account representative should be done on the Account ID page.</p>
<form *ngIf="loaded; else loading" [formGroup]="contactForm" (ngSubmit)="onSaveContacts(contactForm.value)">
    <div formArrayName="contacts">
      <table class="mi-sort-table">
        <tr>
          <th>Name</th>
          <th>Company</th>
          <th>Roles</th>
          <th>Email</th>
          <th>Service Association</th>
          <th>Actions</th>
        </tr>
        <ng-container *ngFor="let serviceContact of contactForm.controls.contacts.controls; let i=index">
            <tr [formGroupName]="i">
              <td>
                  <ng-container *ngIf="serviceContact.value.contact; else staticNoData">
                      {{serviceContact.value.contact.first_name + ' ' + serviceContact.value.contact.last_name}}
                  </ng-container>
              </td>
              <td>
                  <ng-container *ngIf="serviceContact.value.contact; else staticNoData">
                      {{serviceContact.value.contact.vendor.vendor_name}}
                  </ng-container>
              </td>
              <td>
                  <ng-container *ngIf="serviceContact.value.contact; else staticNoData">
                      {{serviceContact.value.contact.role}}
                  </ng-container>
              </td>
              <td>
                  <ng-container *ngIf="serviceContact.value.contact && serviceContact.value.contact.email; else staticNoData">
                      <a href="mailto:{{serviceContact.value.contact.email}}">{{serviceContact.value.contact.email}}</a>
                  </ng-container>
              </td>
              <td>
                  <ng-container [ngSwitch]="serviceContact.value.association_type">
                      <ng-container *ngSwitchCase="'Acc Rep'">
                          <input type="hidden"  formControlName="custom_field">
                          {{'Acc Rep for '}} <mi-id-link [id]="account_id.display_id"></mi-id-link> {{account_id.label}}
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                          <ng-select 
                            [clearable]="false"
                            class="mi-select"
                            [items]="contacts_custom_fields"
                            [multiple]="false"
                            bindLabel="label"
                            [closeOnSelect]="true"
                            formControlName="custom_field">
                          </ng-select>
                      </ng-container>
                  </ng-container>
              </td>
              <td class="action-cell">
                  <ng-container [ngSwitch]="serviceContact.value.association_type">
                      <ng-container *ngSwitchCase="'Acc Rep'">
                          <a *ngIf="!serviceContact.value.contact" 
                            mi-button
                            class="button-alternate"
                            buttonTheme="light-theme"
                            (click)="openAddContactModal('Acc Rep', i)">
                            Select Rep
                          </a>
                          <a *ngIf="serviceContact.value.contact"
                            class="deleteContact"
                            (click)="removeAccountRep('Acc Rep', i)">
                          </a>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                          <a *ngIf="serviceContact.value.contact"
                            class="deleteContact"
                            (click)="deleteContact('Additional Contact', i)">
                          </a>
                      </ng-container>
                  </ng-container>

              </td>
            </tr>
        </ng-container>
      </table>
    </div>
    <div class="button-container">
        <ng-container>
          <a mi-button
            *ngIf="contacts_custom_fields.length"
            class="button-alternate"
            buttonTheme="light-theme"
            (click)="openAddContactModal()">
            Add Contact
          </a>
        </ng-container>
        
        <button mi-button *ngIf="!hideCancel"
                buttonTheme="light-theme"
                (click)="onCancel($event)">
          Cancel
        </button>
        <button mi-button
                type="submit"
                buttonTheme="dark-theme"
                [disabled]="!contactForm.valid">
          Apply
        </button>
      </div>
</form>

<ng-template #staticNoData>
    {{NO_DATA}}
</ng-template>
 
<ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
</ng-template>