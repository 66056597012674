<ng-container *ngIf="parentServiceState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <h2>Assigned To</h2>

    <form *ngIf="componentForm"
          [formGroup]="componentForm"
          (submit)="onFormSubmit(componentForm.value)">

      <mi-extended-input labelText="Who is responsible for this service"
                         [labelRequired]="true"
                         hint="This person will receive notification of service events."
                         [(inputControl)]="componentForm.controls['assigned_email']"
                         [(inputErrors)]="componentForm.controls['assigned_email'].errors"
                         [(inputTouched)]="componentForm.controls['assigned_email'].touched"
                         [errorDefs]='{required: "Please choose an M3 Owner."}'>
          <mi-select-service-ownership 
            (outPutUser)="onAssignedEmail($event)"
            (outPutBusinessUnit)="onBusinessUnit($event)">
          </mi-select-service-ownership>

      </mi-extended-input>

      <div class="button-container">
        <a
          mi-button
          buttonTheme="light-theme"
          [routerLink]="cancelLink">
          Cancel
        </a>
        <button
          mi-button
          buttonTheme="dark-theme" [disabled]="!componentForm.valid">
          Next
        </button>
      </div>

    </form>

  </ng-container>

  <ng-template #static>
    <dl>
      <div *ngIf="division">
        <dt>Division</dt>
        <dd>{{division}}</dd>
      </div>
      <div *ngIf="team">
        <dt>Team</dt>
        <dd>{{team}}</dd>
      </div>
      <dt>Assigned To</dt>
      <dd>
        <ng-container *ngIf="assigneeEmail;else noData">
          {{assigneeName}} (<a href="mailto:{{assigneeEmail}}">{{assigneeEmail}}</a>)
        </ng-container>
        <ng-template #noData>
          {{NO_DATA}}
        </ng-template>
      </dd>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


