<ul class="radio-collection account-ids">
  <ng-container *ngIf='!textFirst'>
    <li *ngFor="let option of buttonOptions; let idx = index;">
      <input type="radio"
              id="{{inputId + '_' + idx}}"
              name="{{inputId}}"
              [checked]="idx === selectedIndex"
              [value]="option"
              (change)="selectNewValue=false;onSelectionChange(option)">
      <label for="{{inputId + '_' + idx}}">
        {{option}}
      </label>
    </li>
  </ng-container>
  <li *ngIf="allowNew">
    <input type="radio"
           name="{{inputId}}"
           id="{{allowNewId}}"
           class="radio-button"
           placeholder="{{placeholder}}"
           [checked]="selectNewValue || buttonOptions.length==0 || newValue"
           [(value)]="newValue"
           (change)="onSelectionChange(newValue)">

    <label for="{{allowNewId}}"
           (focus)="selectNewValue=true;onSelectionChange(newValue)">
      <input class="radio-button-label"
             placeholder="{{placeholder}}"
             (focus)="selectNewValue=true;onSelectionChange(newValue)"
             [(ngModel)]="newValue"
             (ngModelChange)="onSelectionChange($event)"
             [checked]="textFirst">
    </label>

  </li>
  <ng-container *ngIf='textFirst'>
      <li *ngFor="let option of buttonOptions; let idx = index;">
        <input type="radio"
                id="{{inputId + '_' + idx}}"
                name="{{inputId}}"
                [value]="option"
                [checked]="idx === selectedIndex"
                (change)="selectNewValue=false;onSelectionChange(option)">
        <label for="{{inputId + '_' + idx}}">
          {{option}}
        </label>
      </li>
    </ng-container>
</ul>
