<mi-server-error [error]="loadError"></mi-server-error>
<mi-loading-animation *ngIf="loading; else loadedTpl"></mi-loading-animation>

<ng-template #loadedTpl>

  <ng-container *ngIf="currentEquipments.length; else noEquipments">
    <div class="section-details">
        <mi-sortable-table
            [rows]="currentEquipments"
            [columns]="currentEquipmentsColumns">
          </mi-sortable-table>
    </div>
  </ng-container>

  <ng-template #noEquipments>
    <div class="no-results">
        {{'TENANT.SERVICE_DETAILS.EQUIPMENT.EQUIPMENT_EMPTY_TITLE' | translate}}
    </div>
  </ng-template>

</ng-template>
<ng-template #vendorTmpl let-row="row" let-value="value">
    <mi-id-link
    [id]="row.equipment_catalog.vendor.display_id"
    title="{{row.equipment_catalog.vendor.display_id}} - {{row.equipment_catalog.vendor.vendor_name}}">

  </mi-id-link>
  {{row.equipment_catalog.vendor.vendor_name}}
</ng-template>  
