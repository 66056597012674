
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {Note} from "../../models/Note.interface";
import {htmlEmptyValidator} from "../../shared.validators";
import {TenantNoteService} from "../../../libs/api/tenant/tenantNote.service";
import {MisoCollection} from "../../models/MisoCollection.enum";

const log = createLogger(LOG_LEVELS.CORE);

@Component({
  selector: 'mi-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss']
})

export class NoteFormComponent extends AbstractPageComponent implements OnInit {

  @Input() document_id: string;
  @Input() document_collection: MisoCollection;

  @Output() noteCreated: EventEmitter<Note> = new EventEmitter<Note>();

  form: FormGroup = null;
  error: any = '';
  loading: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private tenantNoteService: TenantNoteService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = false;
    this.startAsCreateMode();
  }

  startAsCreateMode(): void {
    this.createForm();
  }

  createForm(html: string = ''): void {
    this.form = this.formBuilder.group({
      html: [html, Validators.compose([
        Validators.required,
        htmlEmptyValidator
      ])],
    });
  }

  onFormSubmit(formValue): void {
    const note: any = {
      html: formValue.html,
      connections: [{
        document_id: this.document_id,
        document_collection: this.document_collection
      }]
    };

    this.error = null;

    this.tenantNoteService.createNote(note as Note)
      .subscribe(
        (newNote:Note) => {
          this.form.setValue({html: ''});
          this.noteCreated.emit(newNote);
        },
            (e) => this.error= e
      );
  }

}
