<mi-server-error [error]="error"></mi-server-error>
<ng-container *ngIf="canDeleteMode && canDelete">
  <h2>Delete Parent Service</h2>
  <mi-side-notice noticeType="WARNING">
      <strong>Do you want to delete Parent Service "{{canDelete.delete.nickname}}" ?</strong>
      <p>Remember, this action cannot be reversed.</p>
      <p>Press delete to remove this Parent Service permanently</p>
      <div class="button-container">
        <a mi-button
          class="button--cancel"
          buttonTheme="alert-light-theme"
          (click)="onCancel($event)">
          Cancel
        </a>
        <a mi-button
          buttonTheme="alert-theme"
          (click)="permanentlyDelete()">
          Delete
        </a>
      </div>
    </mi-side-notice>
</ng-container>

<ng-container *ngIf="!canDeleteMode && canDelete.dependents.length">
  <mi-cascade-need-replace
    title= "Cannot Delete Parent Service"
    subtitle= "The following items are associated with this Parent Service."
    [dependents]= "canDelete.dependents"
    (canceled)="onCancel($event)">
    <mi-alert-item alertType="ERROR">
      Before deleting the Parent Service, please remove the Parent Service from the associated items above.
    </mi-alert-item>
  </mi-cascade-need-replace>
</ng-container>
