import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {of} from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import * as OrderServiceActions from './actions';
import {Router} from "@angular/router";
import {createLogger, LOG_LEVELS} from "../../../../../../shared/logger";
import {TenantMiServiceService} from "../../../../../../libs/api/tenant/tenantMiService.service";

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable() export class ServiceCreateEffects {
  @Effect()
  saveService: Observable<Action> = this.actions$.ofType(OrderServiceActions.ActionTypes.SIMPLE_SERVICE_CREATE)
    .mergeMap( (action: OrderServiceActions.SimpleServiceCreate) => {
      let serviceObject = {
        assigned_email: action.payload.ownership.assignee.email,
        requestor_email: action.payload.ownership.requestor_email,
        end_user_email: action.payload.ownership.end_user_email,
        vendor_purchasing: action.payload.purchasing_vendor.id.toString(),
        product_name: action.payload.product.id.toString(),
        unit_recurring_cost: action.payload.financial.recurring_costs,
        unit_non_recurring_cost: action.payload.financial.non_recurring_cost,
        service_quantity: action.payload.financial.quantity,
        billing_frequency: action.payload.financial.billing_frequency,
        account_identifier: action.payload.account_id|| null,
        doc_id: action.payload.doc_id|| null,
        assigned_division: action.payload.ownership.division,
        assigned_team: action.payload.ownership.team,
        currency_code: action.payload.financial.currency_code,
        tenant_currency_id: action.payload.financial.tenant_currency_id,
        tenant_vendor_id: action.payload.purchasing_vendor._id.toString()
      }
      return this.tenantMiServiceService.createMiServiceFromObject(serviceObject)
        .map( miServiceData => {
          //log("created service", miServiceData)
          this.router.navigate([`/services/${miServiceData.display_id}`]);
          return( new OrderServiceActions.ServiceCreateSuccess({mi_service: miServiceData}) )
        })
        .catch((error) => this.saveOrUpdateErrorHandler(error));
    });

  saveOrUpdateErrorHandler(error):Observable<OrderServiceActions.Actions> {
    return of( new OrderServiceActions.ServiceCreateError({error}));
  }

  constructor(
    private tenantMiServiceService: TenantMiServiceService,
    private router: Router,
    private actions$: Actions
  ) {}
}

