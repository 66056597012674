<ng-container [ngSwitch]="searchType">
  <ng-container *ngSwitchCase="'SERVICE' || 'EVENT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="&nbsp;&nbsp;&nbsp;&nbsp;Actions"
        icon="fast-foward">
        <!--
        <a routerLink="/services/single/create">
          <li class="dropdown-item">
            <span class="dropdown-item-title">
              Add Service
            </span>
          </li>
        </a>
        -->
        <!--
        <li class="dropdown-item"
          (click)="bulkChangeComponent.getDownloadStatus()">
          <div class="dropdown-item-title">
            <mi-excel-button-with-filter-v2 #bulkChangeComponent
              buttonLabel="Bulk Change" 
              [externalTrigger]="true">
            </mi-excel-button-with-filter-v2>
          </div>
        </li>
        <li class="dropdown-item"
            (click)="openRunModal('SERVICE_REPORT')">
          <div class="dropdown-item-title">
            Reports / Views
          </div>
        </li>
        -->
        <mi-excel-button-v2 [dropdownItem]="true" [printButton]="true"></mi-excel-button-v2>
      </mi-dropdown>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'DOCUMENT'">
    <span class="download-btn" style="display: inline-flex;">
      <mi-dropdown 
        [customList]="true"
        [startOpen]="false"
        dropdownTitle="Add Document / Actions"
        icon="fast-foward">
        <ng-content>
        </ng-content>
      </mi-dropdown>
    </span>
  </ng-container>
</ng-container>