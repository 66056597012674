import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import { TagsService } from "src/libs/api2/tags/tags.service";
import { CreateServiceTagRequest, ModifyServiceTagRequest, ServiceTag } from "src/shared/models/Tags";
import { FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "@independer/ng-modal";
import { AddOrEditTagComponent } from "src/shared/components/add-new-tag/add-or-edit-tag.component";
import { ConfirmationModalComponent } from "src/shared/components/confirmation-modal/confirmation-modal.component";

export enum TagOptionEditMode {  
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

@Component(
  {
    selector: 'mi-subsection-tags',
    templateUrl: './subsection-tags.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionTagsComponent extends AbstractPageComponent implements OnInit {
  misoServiceId: string = null;
  tenantId: string = null;
  @Input() editable: Boolean = true;
  @Input() title: string;

  error: string = '';
  isOpen: boolean = false;

  pageLoaded: boolean = false;

  serviceTags: ServiceTag[];
  viewServiceTags: ServiceTag[];
  addNewTagFormData: FormGroup;
  editTagFormData: FormGroup;

  constructor(
    private store: Store<SDReducer.State>,
    private tagsService: TagsService,
    private toastr: ToastrService,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          console.log("getDSData", data);
          this.misoServiceId = data.serviceDetails.service_data.service_id;
          this.tenantId = data.serviceDetails.general.tenant_id;
        });
    
    this.getServiceTags();
  }

  getServiceTags(): void {
    this.tagsService.getMisoServiceTags(this.misoServiceId)
    .subscribe(res => {
      console.log("getMisoServiceTags", res);
      this.serviceTags = res;

      this.error = "";

      this.formatTags();
    });
  }

  formatTags(): void {
    this.serviceTags.sort((a, b) => {
      return a.tag_name > b.tag_name ? 1 : -1;
    });

    this.viewServiceTags = [];
    this.serviceTags.forEach(tag => {
      this.viewServiceTags.push({...tag});
    });

    let spliceList: number[] = [];
    
    for(let i=0; i<this.viewServiceTags.length; i++) {
      let tagId = this.viewServiceTags[i].tag_id;
      for(let j=0; j<this.viewServiceTags.length; j++) {
        let comparedTagId = this.viewServiceTags[j].tag_id;
        //console.log("formatData tagId", tagId);
        //console.log("formatData comparedTagId", comparedTagId);
        if(j == i) continue;
        if((tagId == comparedTagId) && (spliceList.indexOf(i) == -1)) {
          this.viewServiceTags[i].tag_value += ", " + this.viewServiceTags[j].tag_value;
          spliceList.push(j);
        }
      }
    }

    //console.log("formatData spliceList", spliceList);

    spliceList.map((spliceIndex, index) => {
      this.viewServiceTags.splice((spliceIndex - index), 1);
    });

    //console.log("formatData serviceTags", this.serviceTags);
    //console.log("formatData viewServiceTags", this.viewServiceTags);

    this.pageLoaded = true;
  }

  onToggleExpand(): void {
    this.error = "";
    this.isOpen = !this.isOpen;
  }

  addNewTag(): void {
    const modalRef = this.modalService.open(AddOrEditTagComponent, m => {
      m.title = "Add New Tag";
      m.buttonLabel = "Create";
      m.mode = TagOptionEditMode.CREATE;
      m.tenantId = this.tenantId;
      m.existingTags = this.serviceTags;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0] == "Submit" && result[1] != undefined) {
        let skipRequest = false;
        this.addNewTagFormData = result[1];
        let createServiceTagRequest: CreateServiceTagRequest = new CreateServiceTagRequest();

        createServiceTagRequest.miso_service_id = this.misoServiceId;
        createServiceTagRequest.tag_id = this.addNewTagFormData.controls["tag_id"].value;      
        createServiceTagRequest.tag_value = this.addNewTagFormData.controls["tag_value"].value;
  
        this.serviceTags.map(misoServiceTag => {
          if((misoServiceTag.tag_id == createServiceTagRequest.tag_id) && (misoServiceTag.tag_value == createServiceTagRequest.tag_value)) {
            this.error = "Duplicate Tag";
            skipRequest = true;
          }
  
          if((misoServiceTag.tag_id == createServiceTagRequest.tag_id) && (!misoServiceTag.is_multi_value)) {
            this.error = "Tag does not support multiple values";
            skipRequest = true;
          }
        });
        
        if(!skipRequest) {
          this.tagsService.createMisoServiceTag(createServiceTagRequest)
          .subscribe(res => {
            console.log("createMisoServiceTag", res);
            this.toastr.success("Tag added successfully");
    
            this.pageLoaded = false;
            this.getServiceTags();
          });
        }
      }
    });
  }

  editTag(serviceTag: ServiceTag): void {
    console.log("editTag", serviceTag);

    const modalRef = this.modalService.open(AddOrEditTagComponent, m => {
      m.title = "Edit Tag - " + serviceTag.tag_name;
      m.buttonLabel = "Apply";
      m.mode = TagOptionEditMode.EDIT;
      m.tenantId = this.tenantId;
      m.existingTags = this.serviceTags;
      m.currentTag = serviceTag;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0] == "Submit" && result[1] != undefined) {
        this.editTagFormData = result[1];

        if(this.editTagFormData.controls.tag_value.value == serviceTag.tag_value) {
          return;
        }

        let skipRequest = false;
        let modifyServiceTagRequest: ModifyServiceTagRequest = new ModifyServiceTagRequest();

        modifyServiceTagRequest.miso_service_tag_id = serviceTag.miso_service_tag_id;   
        modifyServiceTagRequest.tag_value = this.editTagFormData.controls.tag_value.value;
  
        this.serviceTags.map(misoServiceTag => {
          if((misoServiceTag.tag_id == serviceTag.tag_id) && (misoServiceTag.tag_value == modifyServiceTagRequest.tag_value)) {
            this.error = "Duplicate Tag";
            skipRequest = true;
          }
        });
        
        if(!skipRequest) {
          this.tagsService.renameMisoServiceTag(modifyServiceTagRequest)
          .subscribe(res => {
            console.log("renameMisoServiceTag", res);
            this.toastr.success("Tag edited successfully");
    
            this.pageLoaded = false;
            this.getServiceTags();
          });
        }
      }
    });
  }

  deleteTag(serviceTag: ServiceTag): void {
    console.log("deleteTag", serviceTag);

    const modalRef = this.modalService.open(ConfirmationModalComponent, m => {
      m.title = "Delete Tag - " + serviceTag.tag_name + " | " + serviceTag.tag_value;
      m.isDanger = true;
      m.confirmationText = "Are you sure you want to delete this tag?";
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      if(result[0] == "confirm") {
        let request: ModifyServiceTagRequest = new ModifyServiceTagRequest();
        request.miso_service_tag_id = serviceTag.miso_service_tag_id;

        this.tagsService.deleteMisoServiceTag(request)
        .subscribe(res => {
          console.log("deleteMisoServiceTag", res);
          this.toastr.success("Tag deleted successfully");
          
          this.pageLoaded = false;
          this.getServiceTags();
        });
      }
    });
  }
}