<h2>{{title}}</h2>

<a class="edit-link button dark-theme"
   (click)="onToggleExpand()"
   *ngIf="editable">{{isOpen ? 'Close' : 'Edit'}}</a>

<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="(pageLoaded$ | async); else loading">

  <div class="open" *ngIf="isOpen; else static">

    <div>

      <mi-service-group-service-form
        [currentServiceGroupsSet]="serviceGroups"
        (canceled)="onServiceGroupCanceled($event)"
        (serviceGroupComplete)="onServiceGroupComplete($event)">
      </mi-service-group-service-form>

      <mi-server-error [error]="saveError"></mi-server-error>

    </div>

  </div>

  <ng-template #static>

    <ng-container *ngIf="!!serviceGroups">

      <mi-sortable-table *ngIf="serviceGroups.length > 0; else noServiceGroups"
                         [rows]="serviceGroups"
                         [columns]="serviceGroupColumns">
      </mi-sortable-table>

    </ng-container>

  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>

<ng-template #noServiceGroups>
  <em>{{"PARENT_SERVICE_NO_SERVICES_GROUPS"|translate}}</em>
</ng-template>

<ng-template #alertStatusTmpl let-row="row" let-value="value">
  {{ 'ALERT_STATUS.' + row.alert_status | translate}}
</ng-template>


