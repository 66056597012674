import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResolveVendorPayload, VendorStatus } from 'src/shared/ng-models/vendor-normalization';

@Injectable()
export class VendorResolutionService {
  private getVendorUrl = "_MICRO_SERVICE_/upload/property/vendor/resolution/search";
  private updateAddressUrl = "_MICRO_SERVICE_/upload/property/vendor/resolution";

  constructor(private http: HttpClient) { }

  list(tenant_id: string, search: string, pageNumber: number, pageSize: number, statuses: VendorStatus): Observable<any> {
    return this.http.get(`${this.getVendorUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&tenantId=${tenant_id}&statuses=${statuses}`);
  }

  resolveVendor(payload: ResolveVendorPayload): Observable<any> {
    return this.http
      .put(`${this.updateAddressUrl}`, payload)
      .map((response: any) => response);
  }

}
