import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import {Link} from '../../../../shared/ng-models/Link.interface';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {createLogger, LOG_LEVELS} from '../../../../shared/logger';
import {NO_DATA} from '../../../../shared/ng-models/SectionDataTable.model';
import * as userReducer from "../../../../libs/user-store/state/reducers";
import { Store } from '@ngrx/store';

const log = createLogger(LOG_LEVELS.PAGE);


@Component({
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss']
})

export class EmailSentComponent extends AbstractPageComponent implements OnInit {

  protected readonly NO_DATA: string = NO_DATA;
  blockerStyle: any = {};
  breadcrumbs: Link[] = [
    {
      url: '/',
      label: 'Dashboard',
      internal: true
    }
  ];
  error: string;
  display_id:string;
  loaded: boolean = false;
  alertMassage: string;
  backToOriginalURL:string;
  eventURL: string = "/events";

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private store: Store<userReducer.State>,) {
    super();
  }

  ngOnInit(): void {
    this.store.select(userReducer.getUserState).takeUntil(this.destroy$)
      .subscribe(user => {
        let userData = user.secureUser.getSafeData();
        if(userData && !userData.isSuperUser){
          this.eventURL = `/events?time_range=30_DAY&assigned_email_id%5B%5D=${userData._id}`
        }
      })


    // Find original
    this.display_id = this.activatedRoute.snapshot.params['display_id'];

    if(this.display_id.indexOf("SVP")>=0){
      this.backToOriginalURL = `/parent-services/${this.display_id}`;
    } else {
      this.backToOriginalURL = `/services/${this.display_id}`
    }

    let email_type = this.activatedRoute.snapshot.params['email-type'];
    
    if(email_type === 'request-missing-data'){
      this.alertMassage= `Your Request Missing Data Email for ${this.display_id} has been sent.`
    }else if(email_type === 'request-a-quote'){
      this.alertMassage= `Your Re-Quote Email for ${this.display_id} has been sent.`
    }else if(email_type === 're-assessment'){
      this.alertMassage= `Your Re-Assessment Email for ${this.display_id} has been sent.`
    }else if(email_type === 'miso-quote'){
      this.alertMassage= `Your Miso Marketplace Email for ${this.display_id} has been sent.`
    }else {
      this.alertMassage= `Cannot Find Email Type`
    }
    
    // Update breadcrumbs:
    // this.alertMassage= `${email_type} Email for ${this.display_id} has been sent.`

    let newBreadcrumbs = [
      {
        url: '/services',
        label: 'Services',
        internal: true
      },
      {
        url: this.backToOriginalURL,
        label: this.display_id,
        internal: true
      },
      {
        url: `/emails/${this.display_id}/email-sent`,
        label: 'Email Sent',
        internal: true
      }
    ];
    this.breadcrumbs = [...this.breadcrumbs, ...newBreadcrumbs];
    this.loaded = true;
  }

  backToOriginal(){
    this.router.navigateByUrl(this.backToOriginalURL);
  }


  viewActionableEvent(){
    this.router.navigateByUrl(this.eventURL);
  }
}
