import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../../shared/logger";
import {AbstractPageComponent} from "../../../../shared/pages/abstract-page/abstract-page.component";
import { BulkUploadTemplateSection, BulkUploadTemplateField } from 'src/shared/models/BulkUploadTemplateSection.interface';
import {getTemplateFieldsProperties} from '../fields-to-download-options';
import { TenantCustomFieldService } from 'src/libs/api/tenant/tenantCustomFields.service';
import { CustomField } from 'src/shared/models/CustomField';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-fields-to-download-template',
  templateUrl: './fields-to-download-template.component.html',
  styleUrls: ['./fields-to-download-template.component.scss']
})

export class FieldsToDownloadTemplateComponent extends AbstractPageComponent implements OnInit {

  @Input() reference = 'MiServices'
  @Input() fields: BulkUploadTemplateField[]=[];
  @Input() cfFields: BulkUploadTemplateField[]=[];
  vcfFields: BulkUploadTemplateField[]=[];
  @Input() showCancel: boolean = false;
  @Input() downloadButtonName: String = 'Download';

  @Output() fieldsSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  selected: BulkUploadTemplateField[] = [];
  sections: BulkUploadTemplateSection[];
  error;

  constructor(
    private tenantCustomFieldService: TenantCustomFieldService,
  ) {
    super();
  }

  ngOnInit() {
    this.sections = getTemplateFieldsProperties(this.reference);
    this.selected =this.fields;
    if(this.reference === "MiServices" || this.reference === "MiServicesColumnSelect"){
      this.getCustomFields();
    }
  }

  getVendorCustomFields(customFields: CustomField[], cfFields:any[])
  {
    let customFieldsWithVendor:CustomField[]=[];

    customFields.forEach((cf, index) => {
      if(cf.vendor && cf.vendor_v2){
        customFieldsWithVendor.push(cf);
      }
      else if(cf.vendor && !cf.vendor_v2) {
        console.log("ERROR: vendor not populated for vendor custom field", cf);
      }
    });

    customFieldsWithVendor.sort( (a,b) =>
    //(a.vendor.vendor_name > b.vendor.vendor_name) ? 1 :
    (a.vendor_v2.vendor_name > b.vendor_v2.vendor_name) ? 1 :
    //(a.vendor.vendor_name === b.vendor.vendor_name) ? 
    (a.vendor_v2.vendor_name === b.vendor_v2.vendor_name) ? 
    (
      (a.priority > b.priority) ? 1 : -1
    )
    : -1
    );

    let VendorCustomFieldsSection = {
      title: "Connector Defined Fields (A-Z)",
      sub_sections:[
        {
          columns:[{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          },{
            fields:[]
          }]
        }
      ]
    }

    let vcfFields = [];

    let vendorHeaders=[];
    customFieldsWithVendor.forEach((cf,index)=>
    {
      vendorHeaders.push(
        {
          //vendor_name : cf.vendor.vendor_name,
          vendor_name : cf.vendor_v2.vendor_name,
          //vendor_id : cf.vendor.id
          vendor_id : cf.vendor_v2.id
        }
      );
    });

    vendorHeaders=Array.from(new Set(vendorHeaders.map(s=>s.vendor_name)))
                        .map( vendor_name => {
                          return {
                            vendor_name:vendor_name,
                            vendor_id:vendorHeaders.find(s=>s.vendor_name===vendor_name).vendor_id
                          };
                        }
                        );

    vendorHeaders.forEach((vh,index)=>{
      let currentFields = this.cfFields.find(item => item.field_id === vh.vendor_id);
      let vhSelected=true;

      let dependentFields=[];
      customFieldsWithVendor.forEach((cf,cfindex)=>{
        //if(cf.vendor.id==vh.vendor_id){
        if(cf.vendor_v2.id==vh.vendor_id){
          cfFields.forEach((cff,cffindex)=>
          {
            if(`cf_${cf._id}`==cff.field_id)
            {
              vhSelected=vhSelected && cff.selected;
            }
          });
          dependentFields.push(`cf_${cf._id}`);
        }
      });

      vcfFields.push({
        field_id: vh.vendor_id,
        displayLabel: vh.vendor_name,
        display: vh.vendor_name,
        selected: vhSelected,
        dependent_field_to_select: dependentFields
      });

      let idx = (index+3)%3;

      VendorCustomFieldsSection.sub_sections[0].columns[idx].fields.push(vh.vendor_id);
    });

    this.sections.push(VendorCustomFieldsSection);
    this.selected.push(...vcfFields);
  }

  getCustomFields(){
    this.tenantCustomFieldService.listCustomFieldsWithVendor()
    .toPromise()
    .then((customFields: CustomField[]) => {
      if(!customFields.length){
        return;
      }
      customFields.sort(function(a, b){
        if(a.label < b.label) { return -1; }
        if(a.label > b.label) { return 1; }
        return 0;
      })

      let customFieldsSection = {
        title: "Custom Fields (A-Z)",
        sub_sections:[
          {
            columns:[{
              fields:[]
            },{
              fields:[]
            },{
              fields:[]
            }]
          }
        ]
      }
      let cfFields = [];
      let noContactCf = customFields.filter(cf => cf.type !== "CONTACT")

      noContactCf.map((cf, i) => {

        let currentFields = this.cfFields.find(item => item.field_id === cf._id);

        cfFields.push({
          field_id: `cf_${cf._id}`,
          displayLabel: `${cf.label} (${cf.type})`,
          display: cf.label,
          selected: currentFields? currentFields.selected : false
        })
        let index = (i+3)%3;
        customFieldsSection.sub_sections[0].columns[index].fields.push(`cf_${cf._id}`)
      })

      this.getVendorCustomFields(customFields,cfFields);

      this.sections.push(customFieldsSection);
      this.selected.push(...cfFields);
    })
    .catch(e => this.error = e);    
  }

  getFields(columnFields){
    return this.selected.filter(item => columnFields.indexOf(item.field_id) > -1)
    .sort((a, b) => {
      return columnFields.indexOf(a.field_id) > columnFields.indexOf(b.field_id) ? 1 : -1;
    });;
  }

  toggle(evt, field) {
    let selectedField = this.selected.find(item => field.field_id == item.field_id);
    if (evt.target.checked) {
      // we want to remove the filter
      if(selectedField && !selectedField.selected){
        selectedField.selected = true;
        this.selectDependent(selectedField);
        this.unSelectOnSelect(selectedField);
      }
    } else {
      if(selectedField && selectedField.selected){
        selectedField.selected = false;
        this.unSelectDependent(selectedField);
      }

    }
  }

  selectDependent(selectedField: BulkUploadTemplateField){
    if(selectedField.dependent_field_to_select){
      selectedField.dependent_field_to_select.map(item => {
        if(this.selected.every(itemToDeselect => 
          (!itemToDeselect.dependent_field_to_deselect
          || itemToDeselect.dependent_field_to_deselect.indexOf(item) < 0)
          || (!itemToDeselect.selected && !itemToDeselect.required))){

          let field = this.selected.find(f => f.field_id == item);

          if(field){
            field.selected = true;
          }
        }
    });
    }
  }

  unSelectDependent(selectedField: BulkUploadTemplateField){
    if(selectedField.dependent_field_to_select){
      selectedField.dependent_field_to_select.map(item => {
        let field = this.selected.find(f => f.field_id == item);
        if(field){
          field.selected = false;
        }
      });
    }
  }

  unSelectOnSelect(selectedField: BulkUploadTemplateField){
    if(selectedField.dependent_field_to_deselect){
      selectedField.dependent_field_to_deselect.map(item => {
        let field = this.selected.find(f => f.field_id == item);
        if(field){
          field.selected = false;
        }
      });
    }
  }

  isSelected(field){
    return field.required||field.selected;
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }
  
  onDownload($event) {
    $event.preventDefault();
    this.fieldsSelected.emit(this.selected.map(item=> {
      let field = {
        field_id: item.field_id, 
        selected: item.required||item.selected
      }
      if(item.display){
        field["column_name"] = item.display;
      }
      return field;
    }));
  }

  selectAll(select:boolean){
    this.selected = this.selected.map(field => {
      if(!field.required){
        field.selected = select;
      }
      return field;
    })
  }

}
