import {Injectable} from '@angular/core';
import {Effect, ofType, Actions} from '@ngrx/effects';
import {catchError, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {Router} from "@angular/router";
import {createLogger, LOG_LEVELS} from "../../../../../../shared/logger";
import {Observable} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';

import {TenantVendors} from "../../../../../../libs/api/tenant/tenantVendors";
import {
  Actions as MissingDataActions,
  ActionTypes as MissingDataActionTypes,
  MissingDataAccountIdLoadComplete, MissingDataUpdateAccountContactComplete,
  MissingDataVendorLoadComplete,
  RequestFailedMissingVendorData
} from "./actions";
import {of} from "rxjs/internal/observable/of";
import {VendorMissingData} from "../../../../../../shared/models/VendorMissingData";
import {getMissingDataStore, getMissingDataVendor, State as MissingDataStoreState} from "./reducer";
import {AccountId} from "../../../../../../shared/models/AccountId";
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';

const log = createLogger(LOG_LEVELS.HTTP_API);


@Injectable()
export class MissingDataEffects {

  @Effect()
  startupMissingVendorData: Observable<Action> = this.actions$.pipe(
    ofType(MissingDataActionTypes.STARTUP_MISSING_VENDOR_DATA),
    mergeMap((action: MissingDataActions) => this.tenantAccountIdService.getByVendorId(action.payload.vendor._id)),
    map(accountIds => new MissingDataAccountIdLoadComplete( {accountIds: accountIds})),
    catchError((e) => of(new RequestFailedMissingVendorData({error: 'Could not load account IDs', realError: e})))
  );


  @Effect()
  fetchMissingVendorData: Observable<Action> = this.actions$.pipe(
    ofType(MissingDataActionTypes.MISSING_DATA_ACCOUNT_IDS_LOAD_COMPLETE),
    withLatestFrom(this.store$.pipe(select(getMissingDataVendor))),
    mergeMap(([action, vendor]) => this.tenantVendors.getVendorIncompleteData(vendor, true)),
    map((results:VendorMissingData[]) => new MissingDataVendorLoadComplete( {results})),
    catchError((e) => of(new RequestFailedMissingVendorData({error: 'Could not load missing data', realError: e})))
  );

  @Effect()
  updateAccountIdWithContact: Observable<Action> = this.actions$.pipe(
    ofType(MissingDataActionTypes.MISSING_DATA_UPDATE_ACCOUNT_CONTACT),
    mergeMap((action: MissingDataActions) => this.tenantAccountIdService.update(action.payload.accountId, action.payload.accountId._id)),
    map((accountId:AccountId) => new MissingDataUpdateAccountContactComplete( {accountId})),
    catchError((e) => of(new RequestFailedMissingVendorData({error: 'Could not update accountId', realError: e})))
  );

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private tenantVendors: TenantVendors,
    private tenantAccountIdService: TenantAccountIdService
  ) {
  }
}

