import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductFieldProperty} from '../../../shared/models/ProductFieldProperty';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {HttpClient} from '@angular/common/http';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class ProductFieldPropertyService {

  private apiUrlCrud = '/miyagi/categories';
  private accountHost = '_MICRO_SERVICE_/product/categories';

  constructor(private http: HttpClient) {
  }

  createProductFieldProperty(productFieldProperty: ProductFieldProperty): Promise<ProductFieldProperty> {
    return this.http
      .post(`${this.accountHost}/properties`, productFieldProperty)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  editProductFieldProperty(productFieldProperty: ProductFieldProperty): Promise<ProductFieldProperty>  {
    return this.http
      .post(`${this.accountHost}/properties`, productFieldProperty)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  deleteProductFieldProperty(productFieldPropertyId: string): Promise<ProductFieldProperty> {
    return this.http
      .delete(`${this.accountHost}/properties/${productFieldPropertyId}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

  getProductFieldProperty(productFieldPropertyId: string): Promise<ProductFieldProperty> {
    return this.http
      .get(`${this.accountHost}/${productFieldPropertyId}`)
      .toPromise()
      .then((response: any) => {
        return response;
      });
  }

}
