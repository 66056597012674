import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Alert} from '../../../shared/models/Alert.interface';

@Injectable()
export class TenantAlertService {

  private apiUrl = '/{tenant}/alerts';

  constructor(private http: HttpClient) {

  }

  list(): Observable<Array<Alert>> {
    return this.http
      .get(`${this.apiUrl}`)
      .map((response: any) => response.data.alerts as Alert[]);
  }

  create(alert: Alert): Observable<Alert> {
    return this.http
      .post(`${this.apiUrl}`, alert)
      .map((response: any) => response.data.alert as Alert);
  }

}
