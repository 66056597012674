import { Component, OnInit, Input } from '@angular/core';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import * as appDataReducer from "src/shared/state/app-data/reducers";
import { TenantMiServiceService } from 'src/libs/api/tenant/tenantMiService.service';
import { Store } from '@ngrx/store';


@Component({
  selector: 'mi-align-metabase',
  templateUrl: './align-metabase.component.html',
  styleUrls: ['./align-metabase.component.scss']
})

export class AlignMetabaseComponent implements OnInit {
  @Input() metaBaseDashboardId: string;

  tenantId: string
  showLoader: boolean = true
  error: any = null;
  selectedOptionConfigId: string

  tenantConfig: {
    config_id: string,
    tenant_id1: string,
    tenant_id2: string,
    config_name: string
  }[]

  constructor(
    private reportV2Service: ReportV2Service,
    private store: Store<any>,
    private tenantMiServiceService: TenantMiServiceService
  ) { }

  ngOnInit(): void {
    const tenantData = this.store.select(appDataReducer.getAppData);
    tenantData.first().subscribe((data) => {
      this.tenantId = data.tenantData.domain
    }, (e) => (this.error = e));
    this.getMatchingTenantsDetails()
  }

  getMatchingTenantsDetails() {
    this.tenantMiServiceService
      .getAlignTenantConfig(this.tenantId)
      .subscribe((response) => {
        this.tenantConfig  = response.configurations
        this.selectedOptionConfigId = this.tenantConfig[0].config_id
        this.getMetaBaseURL()
      },
        (e) => {
          this.error = e;
        }
      )
  }

  onChangesHandler(selectedOptionConfigId) {
    this.selectedOptionConfigId = selectedOptionConfigId
    this.getMetaBaseURL()
  }

  getMetaBaseURL() {
    this.showLoader = true;
    this.reportV2Service.getAlignMetaBaseResponse(this.selectedOptionConfigId, this.metaBaseDashboardId).then((res: string) => {
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).setAttribute("src", res.replace("#bordered=true", "#bordered=false").replace("&titled=true", "&titled=false"))
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).addEventListener('load', () => {
        this.showLoader = false;
      });
    })
  }

}
