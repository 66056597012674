<modal class="extra-wide" [showCloseButton]="true" [closeOnEscape]="true" [closeOnOutsideClick]="true">
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content >
    <div>
      <mi-find-or-create-service-groups
        [searchString] = "defaultSearchText"
        (showAddServiceGroupStatus) = "showAddServiceGroupStatus($event)"
        (selecteServiceGroup)="selecteServiceGroup($event)">
      </mi-find-or-create-service-groups> 
    </div>

    <button
      *ngIf="displayCancelButton"
      class="modal-cancel"
      mi-button
      buttonTheme="light-theme"
      (click)="close()">
      Cancel
    </button>

  </modal-content>
</modal>
