import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TenantComponentsModule} from "../../../components/tenant-components.module";
import {SharedModule} from "../../../../shared/shared.module";
import {AssociatedDocumentsInstanceWrapComponent} from "./associated-documents-instance-wrap.component";
import {AssociatedDocumentsDetailsComponent} from "./detail/associated-documents-details.component";
import {AssociatedDocumentsSearchEventsComponent} from "./search-events/associated-documents-search-events.component";
import {AssociatedDocumentsSearchMiServicesComponent} from "./search-mi-services/associated-documents-search-mi-services.component";
import {AssociatedDocumentsEditComponent} from "./edit/associated-documents-edit.component";
import {AssociatedDocumentsCreateComponent} from "./create/associated-documents-create.component";
import { AssociatedDocDeleteComponent } from './delete/associated-documents-delete.component';
import { AssociatedDocumentsSearchMiServicesV2Component } from './search-mi-services-v2/associated-documents-search-mi-services-v2.component';


@NgModule({
  declarations: [
    AssociatedDocumentsInstanceWrapComponent,
    AssociatedDocumentsDetailsComponent,
    AssociatedDocumentsSearchEventsComponent,
    AssociatedDocumentsSearchMiServicesComponent,
    AssociatedDocumentsEditComponent,
    AssociatedDocumentsCreateComponent,
    AssociatedDocDeleteComponent,
    AssociatedDocumentsSearchMiServicesV2Component
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule
  ],
  providers: [],
  exports: [
    AssociatedDocumentsInstanceWrapComponent,
    AssociatedDocumentsDetailsComponent,
    AssociatedDocumentsSearchEventsComponent,
    AssociatedDocumentsSearchMiServicesComponent,
    AssociatedDocumentsEditComponent,
    AssociatedDocumentsCreateComponent,
    AssociatedDocDeleteComponent,
    AssociatedDocumentsSearchMiServicesV2Component
  ]
})
export class AssociatedDocumentsInstanceWrapModule {
}
