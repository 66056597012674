<ng-container *ngIf="linkData">

  <a *ngIf="!linkData.links && !linkData.absoluteUrl"
     [routerLink]="['/'+linkData.path]"
     [queryParams]="linkData.queryParams"
     routerLinkActive="active-link"
     class="{{linkData.additionalClasses}}"
     >
    {{linkData.label}}
  </a>

  <a *ngIf="!linkData.links && linkData.absoluteUrl"
     [href]="linkData.path"
     routerLinkActive="active-link"
     class="{{linkData.additionalClasses}}"
  >
    {{linkData.label}}
  </a>

  <ng-container *ngIf="linkData.links">
    <a href="#"
       class="nester"
       (click)="toggleLinks($event)">
      {{linkData.label}}
    </a>
    <ul class="nest" id="nest" [@openClose]="stateExpression">
      <li *ngFor="let subItem of linkData.links">
        <mi-main-nav-link [linkData]="subItem"></mi-main-nav-link>
      </li>
    </ul>
  </ng-container>

</ng-container>
