import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/first';
import 'rxjs/add/observable/if';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/observable/forkJoin';
import 'rxjs/observable/zip';
import 'rxjs/observable/combineLatest';
import 'rxjs/Subject';
import 'rxjs/ReplaySubject';
import 'rxjs/add/operator/distinctUntilChanged';
import {MiServerErrorComponent} from './components/server-error-alert/server-error-alert.component';
import {MiPrimaryH1Component} from './components/primary-h1/primary-h1.component';
import {MiPageAlertComponent} from './components/mi-page-alert/mi-page-alert.component';
import {MiAlertItemComponent} from './components/mi-alert-item/mi-alert-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateSelectorPipe} from './translation';
import {MiAlertCloseableComponent} from './components/mi-alert-closeable/mi-alert-closeable.component';
import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {MainNavComponent} from './components/main-nav/main-nav.component';
import {MainNavStandardFlyoutComponent} from './components/main-nav/main-nav-standard-flyout/main-nav-standard-flyout.component';
import {RouterModule} from '@angular/router';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducer as emailListReducers} from './state/email-list/reducers';
import {reducer as noteListReducers} from './state/note-list/reducers';
import {reducer as pageAlertReducers} from './state/page-alert/reducers';
import {reducer as appDataReducers} from './state/app-data/reducers';
import {reducer as advancedSearchReducers} from './state/advanced-search/reducers';
import {reducer as mainNavReducers} from './state/main-nav/reducers';
import {reducer as routerReducer} from './state/router/reducers';
import {reducer as pageReducer} from './state/page/reducers';
import {reducer as alertReducer} from './state/alerts/reducers';
import {AppDataEffects} from './state/app-data/effects';
import {AdvancedSearchEffects} from './state/advanced-search/effects';
import {EmailListEffects} from './state/email-list/effects';
import {NoteListEffects} from './state/note-list/effects';
import {AlertListEffects} from './state/alerts/effects';
import {MiRestrictInput} from './components/mi-restrict-input/mi-restrict-input.directive';
import {MiSelectOnFocus} from './components/mi-select-on-focus/mi-select-on-focus.directive';
import {MiButton} from './components/mi-button/mi-button.directive';
import {MiUpload} from './components/mi-upload/mi-upload.directive';
import {BasePageComponent} from './pages/base-page/base-page.component';
import {AbstractPageComponent} from './pages/abstract-page/abstract-page.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {ForbiddenComponent} from './pages/forbidden/forbidden.component';
import {BorderedContainerComponent} from './components/containers/bordered-container.component';
import {SelectableContainerHeaderComponent} from './components/containers/selectable-container-header.component';
import {SelectableContainerContentComponent} from './components/containers/selectable-container-content.component';
import {UserAvatar} from './components/avatar/user-avatar.component';
import {ExtendedInputComponent} from './components/extended-input/extended-input.component';
import {MainNavLinkComponent} from './components/main-nav/main-nav-link/main-nav-link.component';
import {TestComponent} from './pages/test/test.component';
import {TabsContainerComponent} from './components/containers/tabs/tabs-container.component';
import {TabContainerComponent} from './components/containers/tabs/tab-container.component';
import {SearchComponent} from './components/search/search.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SortableTable} from './components/sortable-table/sortable-table.component';
import {SortableColumn} from './components/sortable-table/sortable-column.directive';
import {VendorLogoComponent} from './components/vendor-logo/vendor-logo.component';
import {ProgressDisplayComponent} from './components/progress-display/progress-display.component';
import {LoadingAnimationComponent} from './components/loader/loading-animation.component';
import {RadioList} from './components/radio-list/radio-list.component';
import {RadioArrayList} from './components/radio-array-list/radio-array-list.component';
import {RadioObjectList} from './components/radio-object-list/radio-object-list.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {TelephoneInputComponent} from './components/telephone-input/telephone-input.component';
import {BandwidthInputComponent} from './components/bandwidth-input/bandwidth-input.component';
import {SimpleFooterComponent} from './components/simple-footer/simple-footer.component';
import {AbsolutePipe, RoundPipe, SafeHtmlPipe, SafeSourceUrlPipe, ReversePipe, LimitTextPipe, FileSizePipe, CurrencySymbolPipe, TenantCurrencyPipe, TenantCurrencyUsdPipe, NumberToTextPipe, TrackingPageCodeValuePipe} from './shared.functions';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import {AccountTileComponent} from './components/account-tile/account-tile.component';
import {StaticMapComponent} from './components/static-map/static-map.component';
import {SectionDataComponent} from './components/section-data/section-data.component';
import {HeaderDataComponent} from './components/header-data/header-data.component';
import {SectionDataContactDetailsComponent} from './components/section-data-contacts-details/section-data-contact-details.component';
import {SectionDataLocationsComponent} from './components/section-data-locations/section-data-locations.component';
import {SectionDataContactsListingComponent} from './components/section-data-contacts-listing/section-data-contacts-listing.component';
import {NoteListComponent} from './components/note-list/note-list.component';
import {NoteItemComponent} from './components/note-item/note-item.component';
import {EventItemComponent} from './components/event-item/event-item.component';
import {SectionDataTermsAndConditionsComponent} from './components/section-data-terms-and-conditions/section-data-terms-and-conditions.component';
import {SectionDataAttachmentsComponent} from './components/section-data-attachments/section-data-attachments.component';
import {ModalComponent} from './components/modal/modal.component';
import {MiModalService} from './services/Modal.service';
import {RecentContactsStorageService} from './services/recent-contacts-storage.service';
import {SimpleNameComponent} from './components/simple-name/simple-name.component';
import {AdvancedSearchServiceResultsComponent} from './components/advanced-search/advanced-search-service-results/advanced-search-service-results.component';
import {AdvancedSearchFilterGroupComponent} from './components/advanced-search/advanced-search-filter-group/advanced-search-filter-group.component';
import {AdvancedSearchFilterConfigureComponent} from './components/advanced-search/advanced-search-fiilter-configuration/advanced-search-fiilter-configuration.component';
import {AdvancedSearchHeaderComponent} from './components/advanced-search/advanced-search-header/advanced-search-header.component';
import {AdvancedSearchFilterAssignedToComponent} from './components/advanced-search/advanced-search-filter-assigned-to/advanced-search-filter-assigned-to.component';
import {AdvancedSearchFilterCategoryComponent} from './components/advanced-search/advanced-search-filter-category/advanced-search-filter-category.component';
import {AdvancedSearchFilterMrcComponent} from './components/advanced-search/advanced-search-filter-mrc/advanced-search-filter-mrc.component';
import {AdvancedSearchSortComponent} from './components/advanced-search/advanced-search-sort/advanced-search-sort.component';
import {AdvancedSearchLoadMoreComponent} from './components/advanced-search/advanced-search-load-more/advanced-search-load-more.component';
import {AdvancedSearchEventResultsComponent} from './components/advanced-search/advanced-search-event-results/advanced-search-event-results.component';
import {AdvancedSearchTimeRangeComponent} from './components/advanced-search/advanced-search-time-range/advanced-search-time-range.component';
import {MiyagiAdvancedSearchTimeRangeComponent} from './components/advanced-search/miyagi-advanced-search-time-range/miyagi-advanced-search-time-range.component';
import {MiyagiAdvancedSearchServiceResultsComponent} from './components/advanced-search/miyagi-advanced-search-service-results/miyagi-advanced-search-service-results.component';
import {MiyagiAdvancedSearchServiceDetailsComponent} from './components/advanced-search/miyagi-advanced-search-service-details/miyagi-advanced-search-service-details.component';
import {TransCopyComponent} from './components/trans-copy/trans-copy.component';
import {SectionDataAddressesComponent} from './components/section-data-addresses/section-data-addresses.component';
import {IdLinkComponent} from './components/id-link/id-link.component';
import {AppliedSearchFiltersComponent} from './components/applied-search-filters/applied-search-filters.component';
import {AppliedSearchFilterItemComponent} from './components/applied-search-filter-list/applied-search-filter-item.component';
import {PrintButtonComponent} from './components/print-button/print-button.component';
import {AssociatedDocumentFormComponent} from './components/associated-document-form/associated-document-form.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SuperAreaComponent} from './components/super-area/super-area.component';
import {TinyMceModule} from 'angular-tinymce';
import {miTinymceDefaultSettings} from './utils/miTinymceDefaultSettings';
import {FooterComponent} from './components/footer/footer.component';
import {NoteFormComponent} from './components/note-form/note-form.component';
import {NoteListDirective} from './components/note-list/note-list.directive';
import {SectionTitleComponent} from './components/section-title/section-title.component';
import {FilterGroupOverlayComponent} from './components/advanced-search/filter-group-overlay/filter-group-overlay.component';
import {AdvancedSearchFilterNumberComponent} from './components/advanced-search/advanced-search-filter-number/advanced-search-filter-number.component';
import {AdvancedSearchFilterDateComponent} from './components/advanced-search/advanced-search-filter-date/advanced-search-filter-date.component';
import {SideNoticeComponent} from './components/side-notice/side-notice.component';
import {DownloadExcelButtonComponent} from './components/excel-button/excel-button.component';
import {ToggleComponent} from './components/toggle/toggle.component';
import {ModalModule} from '@independer/ng-modal';
import {ModalSimpleMessageComponent} from './components/modal-simple-message/modal-simple-message.component';
import {SaveSearchTrayComponent} from './components/save-search-tray/save-search-tray.component';
import {CascadeNeedReplaceComponent} from './components/cascade-need-replace/cascade-need-replace.component';
import {ProductFieldStatisItemComponent} from './components/product/product-field-static-item/product-field-static-item.component';
import {ProductFormComponent} from './components/product/product-form/product-form.component';
import {ProductSearchComponent} from './components/product/product-search/product-search.component';
import {ProductVendorHeaderComponent} from './components/product/product-vendor-header/product-vendor-header.component';
import {FlatpickrModule} from 'angularx-flatpickr';
import {AdvancedSearchServiceDetailsComponent} from './components/advanced-search/advanced-search-service-details/advanced-search-service-details.component';
import {AdvancedSearchParentServiceDetailsComponent} from './components/advanced-search/advanced-search-parent-service-details/advanced-search-parent-service-details.component';
import {AdvancedSearchEquipmentResultsComponent} from './components/advanced-search/advanced-search-equipment-results/advanced-search-equipment-results.component';
import {DownloadsComponent} from './pages/downloads/downloads.component';
import {ChevronContainerComponent} from './components/chevron-container/chevron-container.component';
import {AdvancedSearchVendorResultsComponent} from './components/advanced-search/advanced-search-vendor-results/advanced-search-vendor-results.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {ListLimitComponent} from './components/list-limit/list-limit.component';
import {AdvancedSearchFilterRequestorComponent} from './components/advanced-search/advanced-search-filter-requestor/advanced-search-filter-requestor.component';
import {ShortcutMenuComponent} from './components/shortcut-menu/shortcut-menu.component';
import {AdvancedSearchContactResultsComponent} from './components/advanced-search/advanced-search-contact-results/advanced-search-contact-results.component';
import {AdvancedSearchAddressResultsComponent} from './components/advanced-search/advanced-search-address-results/advanced-search-address-results.component';
import {OpacityLoaderComponent} from './components/opacity-loader/opacity-loader.component';
import {CollapsableContentComponent} from './components/collapsable-content/collapsable-content.component';
import {CollapsableContentCustomComponent} from './components/collapsable-content-custom/collapsable-content-custom.component';
import {AdvancedSearchServiceGroupResultsComponent} from './components/advanced-search/advanced-search-service-group-results/advanced-search-service-group-results.component';
import {AdvancedSearchFilterSingleCheckboxComponent} from './components/advanced-search/advanced-search-filter-single-checkbox/advanced-search-filter-single-checkbox.component';
import { MiUploadDragDrop } from './components/mi-upload-drag-drop/mi-upload-drag-drop.directive';
import { MiUserUploadDragDrop } from './components/mi-user-upload-drag-drop/mi-user-upload-drag-drop.component';
import {UserStoreModule} from '../libs/user-store/user-store.module';
import {ApiModule} from '../libs/api/api.module';
import {FormComponentsModule} from '../libs/form-components/form-components.module';
import { TopNavComponent } from './components/main-nav/top-nav/top-nav.component';
import {SimpleLocationComponent} from './components/simple-location/simple-location.component';
import { SimpleBusinessUnitComponent } from './components/simple-business-unit/simple-business-unit.component';
import { SimpleVendorComponent } from './components/simple-vendor/simple-vendor.component';
import { SimpleServiceTermComponent } from './components/simple-service-term/simple-service-term.component';
import { SortableDraggableTable } from './components/sortable-draggable-table/sortable-draggable-table.component';
import { SortableTableColumnSelectModalComponent } from './components/sortable-table-column-select-modal/sortable-table-column-select-modal.component';
import { AdvancedSearchFilterTableHeaderComponent } from './components/advanced-search/advanced-search-filter-table-header/advanced-search-filter-table-header.component';
import { AdvancedSearchTenantUserResultsComponent } from './components/advanced-search/advanced-search-tenant-user-results/advanced-search-tenant-user-results.component';
import { UsersAccessFormModalComponent } from './components/users-management-form/users-access-form-modal/users-access-form-modal.component';
import { UsersManagementFormComponent } from './components/users-management-form/users-management-form.component';
import { UsersManagementFormModalComponent } from './components/users-management-form-modal/users-management-form-modal.component';
import { AdvancedSearchServiceResultsTableComponent } from './components/advanced-search/advanced-search-service-results-table/advanced-search-service-results-table.component';
import { ExcelEmailNotificationModalComponent } from './components/excel-email-notification-modal/excel-email-notification-modal.component';
import { FieldsToDownloadSelectComponent } from './components/fields-to-download/fields-to-download-select/fields-to-download-select.component';
import { FieldsToDownloadTemplateComponent } from './components/fields-to-download/fields-to-download-template/fields-to-download-template.component';
import { ReportFilterComponent } from './components/fields-to-download/report-filter/report-filter.component';
import { DownloadExcelButtonWithFilterComponent } from './components/excel-button-with-filter/excel-button-with-filter.component';
import { CreateReportOrViewModalComponent } from './components/advanced-search/action-dropdown-buttons/create-report-or-view-modal/create-report-or-view-modal.component';
import { HeaderActionDropdownComponent } from './components/advanced-search/action-dropdown-buttons/action-dropdown-buttons.component';
import { RunReportOrViewModalComponent } from './components/advanced-search/action-dropdown-buttons/run-report-or-view-modal/run-report-or-view-modal.component';
import { ServiceDownloadModalComponent } from './components/service-download-modal/service-download-modal.component';
import { ReportEditFormComponent } from './components/report-edit-form/report-edit-form.component';
import { SectionDataCurrencyComponent } from './components/section-data-currency/section-data-currency.component';
import { CurrencyFormModalComponent } from './components/currency-form-modal/currency-form-modal.component';
import { CurrencyFormComponent } from './components/currency-form/currency-form.component';
import { AuditFormComponent } from './components/audit-form/audit-form.component';
import { AdvancedSearchFilterModalComponent } from './components/advanced-search-filter-modal/advanced-search-filter-modal.component';
import { TenantContactSearchFiltersComponent } from './components/tenant-contact-search-filters/tenant-contact-search-filters.component';
import { StandardTenantSearchFiltersComponent } from './components/standard-tenant-search-filters/standard-tenant-search-filters.component';
import { TenantUserSearchFiltersComponent } from './components/tenant-user-search-filters/tenant-user-search-filters.component';
import { AdvancedSearchAccountIdResultsComponent } from './components/advanced-search/advanced-search-account-id-results/advanced-search-account-id-results.component';
import { AdvancedSearchDocumentResultsComponent } from './components/advanced-search/advanced-search-document-results/advanced-search-document-results.component';
import { AdvancedSearchProductResultsComponent } from './components/advanced-search/advanced-search-product-results/advanced-search-product-results.component';
import { ChartComponent } from './components/chart/chart.component';
import { MiDocumentUploadDragDrop } from './components/mi-document-upload-drag-drop/mi-document-upload-drag-drop.directive';
import { FindOrCreateAccountIdComponent } from './components/find-or-create-account-id/find-or-create-account-id.component';
import { FindOrCreateVendorModalComponent } from './components/find-or-create-vendor-modal/find-or-create-vendor-modal.component';
import { VendorSearchComponent } from './components/vendor-search/vendor-search.component';
import { FindOrCreateVendorComponent } from './components/find-or-create-vendor/find-or-create-vendor.component';
import { DocumentUploadModalComponent } from './components/document-upload-modal/document-upload-modal.component';
import { DocumentFindAndSelectComponent } from './components/document-find-and-select/document-find-and-select.component';
import { VendorTenantSearchComponent } from './components/vendor-tenant-search/vendor-tenant-search.component';
import { TwoButtonNotificationModalComponent } from './components/two-button-notification-modal/two-button-notification-modal.component';
import { AdvancedSearchSubscriptionResultsComponent } from './components/advanced-search/advanced-search-subscription-results/advanced-search-subscription-results.component';
import { DropdownSelectComponent } from './components/dropdown-select/dropdown-select.component';
import { ModalCustomComponent } from './components/modal-custom/modal-custom.component';
import { ReverseTableComponent } from './components/reverse-table/reverse-table.component';
import { RemovableTableComponent } from './components/removable-table/removable-table.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { AccountPlanCardComponent } from './components/account-plan-card/account-plan-card.component';
import { AdvancedSearchHeaderV2Component } from './components/advanced-search/advanced-search-header-v2/advanced-search-header-v2.component';
import { AdvancedSearchSortV2Component } from './components/advanced-search/advanced-search-sort-v2/advanced-search-sort-v2.component';
import { AppliedSearchFiltersV2Component } from './components/applied-search-filters-v2/applied-search-filters-v2.component';
import { AdvancedSearchServiceResultsTableV2Component } from './components/advanced-search/advanced-search-service-results-table-v2/advanced-search-service-results-table-v2.component';
import { FilterGroupOverlayV2Component } from './components/advanced-search/filter-group-overlay-v2/filter-group-overlay-v2.component';
import { AdvancedSearchFilterTableHeaderV2Component } from './components/advanced-search/advanced-search-filter-table-header-v2/advanced-search-filter-table-header-v2.component';
import { reducer as serviceV2Reducers } from './state/service-v2/reducers';
import { AdvancedSearchFilterNumberV2Component } from './components/advanced-search/advanced-search-filter-number-v2/advanced-search-filter-number-v2.component';
import { SortableDraggableTableV2Component } from './components/sortable-draggable-table-v2/sortable-draggable-table-v2.component';
import { AppliedSearchFilterItemV2Component } from './components/applied-search-filter-item-v2/applied-search-filter-item-v2.component';
import { AdvancedSearchLoadMoreV2Component } from './components/advanced-search/advanced-search-load-more-v2/advanced-search-load-more-v2.component';
import { AdvancedSearchFilterDateV2Component } from './components/advanced-search/advanced-search-filter-date-v2/advanced-search-filter-date-v2.component';
import { FieldsToDownloadTemplateV2Component } from './components/fields-to-download/fields-to-download-template-v2/fields-to-download-template-v2.component';
import { ExcelButtonV2Component } from './components/excel-button-v2/excel-button-v2.component';
import { ExcelButtonWithFilterV2Component } from './components/excel-button-with-filter-v2/excel-button-with-filter-v2.component';
import { HeaderActionDropdownV2Component } from './components/advanced-search/action-dropdown-buttons-v2/action-dropdown-buttons-v2.component';
import { SortableTableColumnSelectModalV2Component } from './components/sortable-table-column-select-modal-v2/sortable-table-column-select-modal-v2.component';
import { RunReportOrViewModalV2Component } from './components/advanced-search/action-dropdown-buttons-v2/run-report-or-view-modal-v2/run-report-or-view-modal-v2.component';
import { ServiceDownloadModalV2Component } from './components/service-download-modal-v2/service-download-modal-v2.component';
import { reducer as documentV2Reducers } from './state/document-v2/reducers';
import { reducer as tenantCreationV2Reducers } from './state/tenant-creation-v2/reducers';
import { reducer as tenantBillingAccountReducers } from './state/tenant-billing-account/reducers';
import { MiPaginationComponent } from './components/mi-pagination/mi-pagination';
import { ValidationFlagComponent } from './components/validation-flag/validation-flag.component';
import { SearchHeaderComponent } from './components/advanced-search/search-header/search-header.component';
import { LoadingButtonComponent } from './components/loader-button/loading-animation.component';
import { UserProfileDropdownComponent } from './components/user-profile-dropdown/user-profile-dropdown.component';
import { AdvancedSearchDocumentResultsV2Component } from './components/advanced-search/advanced-search-document-results-v2/advanced-search-document-results-v2.component';
import { SelectCurrencyModalComponent } from './components/select-currency-modal/select-currency-modal.component';
import { AccountPlanCardV2Component } from './components/account-plan-card-v2/account-plan-card-v2.component';
import { MiRouletteComponent } from './components/mi-roulette/mi-roulette.component';
import { MiProgressBarComponent } from './components/mi-progress-bar/mi-progress-bar.component';
import { OnboardingErrorModalComponent } from './components/onboarding-error-modal/onboarding-error-modal.component';
import { PaymentCardDisplayComponent } from './components/payment-card-display/payment-card-display.component';
import { BarLineChartComponent } from './components/bar-line-chart/bar-line-chart.component';
import { AddOrEditTagComponent } from './components/add-new-tag/add-or-edit-tag.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MetabaseComponent } from './components/metabase/metabase.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    UserStoreModule,
    ApiModule,
    FormComponentsModule,
    StoreModule.forFeature('serviceEmailList', emailListReducers),
    StoreModule.forFeature('noteList', noteListReducers),
    StoreModule.forFeature('pageAlert', pageAlertReducers),
    StoreModule.forFeature('appData', appDataReducers),
    StoreModule.forFeature('advancedSearch', advancedSearchReducers),
    StoreModule.forFeature('mainNav', mainNavReducers),
    StoreModule.forFeature('router', routerReducer),
    StoreModule.forFeature('page', pageReducer),
    StoreModule.forFeature('alertList', alertReducer),
    EffectsModule.forFeature([AppDataEffects]),
    EffectsModule.forFeature([AdvancedSearchEffects]),
    EffectsModule.forFeature([NoteListEffects]),
    EffectsModule.forFeature([EmailListEffects]),
    EffectsModule.forFeature([AlertListEffects]),
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    ModalModule,
    FlatpickrModule.forRoot(),
    TinyMceModule.forRoot(miTinymceDefaultSettings()),
    StoreModule.forFeature('serviceV2', serviceV2Reducers),
    StoreModule.forFeature('documentV2', documentV2Reducers),
    StoreModule.forFeature('tenantCreationV2', tenantCreationV2Reducers),
    StoreModule.forFeature('tenantBillingAccount', tenantBillingAccountReducers)
  ],
  declarations: [
    ChartComponent,
    BarLineChartComponent,
    DownloadsComponent,
    MainNavComponent,
    MainNavStandardFlyoutComponent,
    MainNavLinkComponent,
    MiRestrictInput,
    MiSelectOnFocus,
    MiButton,
    MiUpload,
    DocumentFindAndSelectComponent,
    MiUploadDragDrop,
    MiUserUploadDragDrop,
    MiAlertCloseableComponent,
    BasePageComponent,
    AbstractPageComponent,
    NotFoundComponent,
    ForbiddenComponent,
    TranslateSelectorPipe,
    MiAlertItemComponent,
    MiPaginationComponent,
    MiPageAlertComponent,
    MiPrimaryH1Component,
    MiServerErrorComponent,
    BorderedContainerComponent,
    SelectableContainerHeaderComponent,
    SelectableContainerContentComponent,
    TestComponent,
    UserAvatar,
    ExtendedInputComponent,
    TabsContainerComponent,
    TabContainerComponent,
    SearchComponent,
    SortableTable,
    SortableColumn,
    VendorLogoComponent,
    ProgressDisplayComponent,
    LoadingAnimationComponent,
    LoadingButtonComponent,
    RadioList,
    RadioArrayList,
    RadioObjectList,
    DatePickerComponent,
    AccountTileComponent,
    TelephoneInputComponent,
    BandwidthInputComponent,
    SimpleFooterComponent,
    CollapsableContentComponent,
    CollapsableContentCustomComponent,
    RoundPipe,
    SafeHtmlPipe,
    AbsolutePipe,
    SafeSourceUrlPipe,
    LimitTextPipe,
    ReversePipe,
    FileSizePipe,
    CurrencySymbolPipe,
    TenantCurrencyPipe,
    TenantCurrencyUsdPipe,
    TrackingPageCodeValuePipe,
    PageHeaderComponent,
    StaticMapComponent,
    HeaderDataComponent,
    SectionDataComponent,
    SectionDataContactDetailsComponent,
    SectionDataContactsListingComponent,
    SectionDataLocationsComponent,
    SectionDataAddressesComponent,
    AssociatedDocumentFormComponent,
    NoteListComponent,
    NoteItemComponent,
    NoteFormComponent,
    EventItemComponent,
    SectionDataTermsAndConditionsComponent,
    SectionDataAttachmentsComponent,
    ModalComponent,
    ModalCustomComponent,
    SimpleNameComponent,
    AdvancedSearchServiceResultsComponent,
    AdvancedSearchFilterGroupComponent,
    AdvancedSearchFilterConfigureComponent,
    AdvancedSearchHeaderComponent,
    AdvancedSearchFilterAssignedToComponent,
    AdvancedSearchFilterCategoryComponent,
    AdvancedSearchFilterMrcComponent,
    AdvancedSearchFilterNumberComponent,
    AdvancedSearchFilterDateComponent,
    AdvancedSearchSortComponent,
    AdvancedSearchFilterSingleCheckboxComponent,
    AdvancedSearchLoadMoreComponent,
    AdvancedSearchEventResultsComponent,
    AdvancedSearchTimeRangeComponent,
    AdvancedSearchServiceDetailsComponent,
    AdvancedSearchAccountIdResultsComponent,
    AdvancedSearchSubscriptionResultsComponent,
    MiyagiAdvancedSearchServiceResultsComponent,
    MiyagiAdvancedSearchServiceDetailsComponent,
    MiyagiAdvancedSearchTimeRangeComponent,
    FilterGroupOverlayComponent,
    TransCopyComponent,
    IdLinkComponent,
    AppliedSearchFiltersComponent,
    AppliedSearchFilterItemComponent,
    SuperAreaComponent,
    PrintButtonComponent,
    FooterComponent,
    NoteListDirective,
    SectionTitleComponent,
    SideNoticeComponent,
    DownloadExcelButtonComponent,
    ToggleComponent,
    ModalSimpleMessageComponent,
    SaveSearchTrayComponent,
    CascadeNeedReplaceComponent,
    ProductFieldStatisItemComponent,
    ProductFormComponent,
    ProductSearchComponent,
    ProductVendorHeaderComponent,
    AdvancedSearchServiceDetailsComponent,
    AdvancedSearchServiceGroupResultsComponent,
    AdvancedSearchParentServiceDetailsComponent,
    AdvancedSearchEquipmentResultsComponent,
    ChevronContainerComponent,
    SearchHeaderComponent,
    AdvancedSearchVendorResultsComponent,
    DropdownComponent,
    UserProfileDropdownComponent,
    DropdownSelectComponent,
    ListLimitComponent,
    AdvancedSearchFilterRequestorComponent,
    ShortcutMenuComponent,
    AdvancedSearchDocumentResultsComponent,
    AdvancedSearchContactResultsComponent,
    AdvancedSearchServiceResultsTableComponent,
    AdvancedSearchAddressResultsComponent,
    AdvancedSearchProductResultsComponent,
    OpacityLoaderComponent,
    TopNavComponent,
    SimpleLocationComponent,
    SimpleBusinessUnitComponent,
    SimpleVendorComponent,
    SortableDraggableTable,
    SortableTableColumnSelectModalComponent,
    AdvancedSearchFilterTableHeaderComponent,
    AdvancedSearchTenantUserResultsComponent,
    SimpleServiceTermComponent,
    UsersAccessFormModalComponent,
    ExcelEmailNotificationModalComponent,
    FieldsToDownloadSelectComponent,
    FieldsToDownloadTemplateComponent,
    ReportFilterComponent,
    DownloadExcelButtonWithFilterComponent,
    CreateReportOrViewModalComponent,
    RunReportOrViewModalComponent,
    ServiceDownloadModalComponent,
    HeaderActionDropdownComponent,
    ReportEditFormComponent,
    SectionDataCurrencyComponent,
    CurrencyFormModalComponent,
    CurrencyFormComponent,
    AuditFormComponent,
    AdvancedSearchFilterModalComponent,
    TenantContactSearchFiltersComponent,
    StandardTenantSearchFiltersComponent,
    TenantUserSearchFiltersComponent,
    UsersManagementFormComponent,
    UsersManagementFormModalComponent,
    MiDocumentUploadDragDrop,
    FindOrCreateAccountIdComponent,
    FindOrCreateVendorModalComponent,
    VendorTenantSearchComponent,
    VendorSearchComponent,
    TwoButtonNotificationModalComponent,
    FindOrCreateVendorComponent,
    DocumentUploadModalComponent,
    ReverseTableComponent,
    RemovableTableComponent,
    InfoTooltipComponent,
    AccountPlanCardComponent,
    AdvancedSearchHeaderV2Component,
    AdvancedSearchSortV2Component,
    AppliedSearchFiltersV2Component,
    AdvancedSearchServiceResultsTableV2Component,
    FilterGroupOverlayV2Component,
    AdvancedSearchFilterTableHeaderV2Component,
    AdvancedSearchFilterNumberV2Component,
    SortableDraggableTableV2Component,
    AppliedSearchFilterItemV2Component,
    AdvancedSearchLoadMoreV2Component,
    AdvancedSearchFilterDateV2Component,
    FieldsToDownloadTemplateV2Component,
    ExcelButtonV2Component,
    ExcelButtonWithFilterV2Component,
    HeaderActionDropdownV2Component,
    SortableTableColumnSelectModalV2Component,
    RunReportOrViewModalV2Component,
    ServiceDownloadModalV2Component,
    ValidationFlagComponent,
    AdvancedSearchDocumentResultsV2Component,
    SelectCurrencyModalComponent,
    AccountPlanCardV2Component,
    MiRouletteComponent,
    MiProgressBarComponent,
    NumberToTextPipe,
    OnboardingErrorModalComponent,
    PaymentCardDisplayComponent,
    AddOrEditTagComponent,
    ConfirmationModalComponent,
    MetabaseComponent
  ],
  providers: [
    MiModalService,
    RecentContactsStorageService,
    TranslateSelectorPipe,
    DecimalPipe
  ],
  exports: [
    ChartComponent,
    BarLineChartComponent,
    DownloadsComponent,
    TranslateSelectorPipe,
    BasePageComponent,
    AbstractPageComponent,
    NotFoundComponent,
    ForbiddenComponent,
    MainNavComponent,
    MiRestrictInput,
    MiSelectOnFocus,
    MiButton,
    MiUpload,
    DocumentFindAndSelectComponent,
    MiUploadDragDrop,
    MiUserUploadDragDrop,
    MiAlertCloseableComponent,
    MiAlertItemComponent,
    MiPaginationComponent,
    MiPageAlertComponent,
    MiPrimaryH1Component,
    MiServerErrorComponent,
    BorderedContainerComponent,
    SelectableContainerHeaderComponent,
    SelectableContainerContentComponent,
    TestComponent,
    UserAvatar,
    ExtendedInputComponent,
    TabsContainerComponent,
    TabContainerComponent,
    SearchComponent,
    SortableTable,
    SortableColumn,
    VendorLogoComponent,
    ProgressDisplayComponent,
    LoadingAnimationComponent,
    LoadingButtonComponent,
    RadioList,
    RadioArrayList,
    RadioObjectList,
    DatePickerComponent,
    AccountTileComponent,
    TelephoneInputComponent,
    BandwidthInputComponent,
    SimpleFooterComponent,
    CollapsableContentComponent,
    CollapsableContentCustomComponent,
    RoundPipe,
    SafeHtmlPipe,
    AbsolutePipe,
    SafeSourceUrlPipe,
    LimitTextPipe,
    ReversePipe,
    FileSizePipe,
    CurrencySymbolPipe,
    TenantCurrencyPipe,
    TenantCurrencyUsdPipe,
    TrackingPageCodeValuePipe,
    PageHeaderComponent,
    StaticMapComponent,
    HeaderDataComponent,
    SectionDataComponent,
    SectionDataContactDetailsComponent,
    SectionDataContactsListingComponent,
    SectionDataLocationsComponent,
    SectionDataAddressesComponent,
    AssociatedDocumentFormComponent,
    NoteListComponent,
    NoteItemComponent,
    NoteFormComponent,
    EventItemComponent,
    HeaderDataComponent,
    HeaderDataComponent,
    SectionDataTermsAndConditionsComponent,
    SectionDataAttachmentsComponent,
    ModalComponent,
    ModalCustomComponent,
    SimpleNameComponent,
    AdvancedSearchServiceResultsComponent,
    AdvancedSearchServiceGroupResultsComponent,
    AdvancedSearchFilterGroupComponent,
    AdvancedSearchFilterConfigureComponent,
    AdvancedSearchHeaderComponent,
    AdvancedSearchFilterAssignedToComponent,
    AdvancedSearchFilterCategoryComponent,
    AdvancedSearchFilterMrcComponent,
    AdvancedSearchFilterNumberComponent,
    AdvancedSearchFilterDateComponent,
    AdvancedSearchSortComponent,
    AdvancedSearchFilterSingleCheckboxComponent,
    AdvancedSearchLoadMoreComponent,
    AdvancedSearchEventResultsComponent,
    AdvancedSearchTimeRangeComponent,
    AdvancedSearchAccountIdResultsComponent,
    AdvancedSearchSubscriptionResultsComponent,
    MiyagiAdvancedSearchServiceResultsComponent,
    MiyagiAdvancedSearchServiceDetailsComponent,
    MiyagiAdvancedSearchTimeRangeComponent,
    FilterGroupOverlayComponent,
    TransCopyComponent,
    IdLinkComponent,
    AppliedSearchFiltersComponent,
    AppliedSearchFilterItemComponent,
    SuperAreaComponent,
    PrintButtonComponent,
    FooterComponent,
    NoteListDirective,
    SectionTitleComponent,
    SideNoticeComponent,
    DownloadExcelButtonComponent,
    ToggleComponent,
    ModalSimpleMessageComponent,
    SaveSearchTrayComponent,
    CascadeNeedReplaceComponent,
    ProductFieldStatisItemComponent,
    ProductFormComponent,
    ProductSearchComponent,
    ProductVendorHeaderComponent,
    AdvancedSearchServiceDetailsComponent,
    AdvancedSearchParentServiceDetailsComponent,
    AdvancedSearchEquipmentResultsComponent,
    ChevronContainerComponent,
    SearchHeaderComponent,
    AdvancedSearchVendorResultsComponent,
    DropdownComponent,
    UserProfileDropdownComponent,
    DropdownSelectComponent,
    ListLimitComponent,
    AdvancedSearchFilterRequestorComponent,
    ShortcutMenuComponent,
    AdvancedSearchContactResultsComponent,
    AdvancedSearchDocumentResultsComponent,
    AdvancedSearchServiceResultsTableComponent,
    AdvancedSearchAddressResultsComponent,
    AdvancedSearchProductResultsComponent,
    OpacityLoaderComponent,
    TopNavComponent,
    SimpleLocationComponent,
    SimpleBusinessUnitComponent,
    SimpleVendorComponent,
    SortableDraggableTable,
    SortableTableColumnSelectModalComponent,
    AdvancedSearchFilterTableHeaderComponent,
    AdvancedSearchTenantUserResultsComponent,
    SimpleServiceTermComponent,
    UsersAccessFormModalComponent,
    ExcelEmailNotificationModalComponent,
    FieldsToDownloadSelectComponent,
    FieldsToDownloadTemplateComponent,
    ReportFilterComponent,
    DownloadExcelButtonWithFilterComponent,
    CreateReportOrViewModalComponent,
    RunReportOrViewModalComponent,
    ServiceDownloadModalComponent,
    HeaderActionDropdownComponent,
    ReportEditFormComponent,
    AuditFormComponent,
    AdvancedSearchFilterModalComponent,
    TenantContactSearchFiltersComponent,
    StandardTenantSearchFiltersComponent,
    TenantUserSearchFiltersComponent,
    UsersManagementFormComponent,
    UsersManagementFormModalComponent,
    SectionDataCurrencyComponent,
    CurrencyFormModalComponent,
    CurrencyFormComponent,
    MiDocumentUploadDragDrop,
    FindOrCreateAccountIdComponent,
    FindOrCreateVendorModalComponent,
    VendorTenantSearchComponent,
    VendorSearchComponent,
    TwoButtonNotificationModalComponent,
    FindOrCreateVendorComponent,
    DocumentUploadModalComponent,
    ReverseTableComponent,
    RemovableTableComponent,
    InfoTooltipComponent,
    AccountPlanCardComponent,
    AdvancedSearchHeaderV2Component,
    AdvancedSearchSortV2Component,
    AppliedSearchFiltersV2Component,
    AdvancedSearchServiceResultsTableV2Component,
    FilterGroupOverlayV2Component,
    AdvancedSearchFilterTableHeaderV2Component,
    AdvancedSearchFilterNumberV2Component,
    SortableDraggableTableV2Component,
    AppliedSearchFilterItemV2Component,
    AdvancedSearchLoadMoreV2Component,
    AdvancedSearchFilterDateV2Component,
    FieldsToDownloadTemplateV2Component,
    ExcelButtonV2Component,
    ExcelButtonWithFilterV2Component,
    HeaderActionDropdownV2Component,
    SortableTableColumnSelectModalV2Component,
    RunReportOrViewModalV2Component,
    ServiceDownloadModalV2Component,
    ValidationFlagComponent,
    AdvancedSearchDocumentResultsV2Component,
    SelectCurrencyModalComponent,
    AccountPlanCardV2Component,
    MiRouletteComponent,
    MiProgressBarComponent,
    NumberToTextPipe,
    OnboardingErrorModalComponent,
    PaymentCardDisplayComponent,
    AddOrEditTagComponent,
    ConfirmationModalComponent,
    MetabaseComponent
  ]
})

export class SharedModule {
}
