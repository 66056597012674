const HAS_NUMBER = /[0-9]/;
const LOWER_CASE = /[a-z]/;
const MIN_LENGTH = 8;
const SPECIAL_CHARACTER = /[!@#$%^&*()_\-+=\[\]{}\?]/;
const UPPER_CASE = /[A-Z]/;

function hasLowerCase(s = '') {
  return LOWER_CASE.test(s);
}

function hasSpecialCharacters(s = '') {
  return SPECIAL_CHARACTER.test(s);
}

function hasMinLength(s = '') {
  return s ? s.length >= MIN_LENGTH : false;
}

function hasNumber(s = '') {
  return HAS_NUMBER.test(s);
}

function hasUpperCase(s = '') {
  return UPPER_CASE.test(s);
}

function isEqual(s_one, s_two) {
  return s_one === s_two;
}

function isValid(s = '') {
  return hasLowerCase(s) && hasMinLength(s) && hasNumber(s) && hasSpecialCharacters(s) && hasUpperCase(s);
}

const PasswordUtil = {
  hasLowerCase, 
  hasSpecialCharacters,
  hasMinLength,
  hasNumber,
  hasUpperCase,
  isEqual,
  isValid
};

export default PasswordUtil; 
