import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddressPayload } from 'src/shared/ng-models/Address';

@Injectable()
export class AddressResolutionService {
  private getAddressUrl = "_MICRO_SERVICE_/upload/property/address/resolution/search";
  private getAllAddressUrl = "_MICRO_SERVICE_/upload/property/address/unresolved";
  private addressUpdate = "_MICRO_SERVICE_/upload/updateServiceAddress";
  private updateAddressUrl = "_MICRO_SERVICE_/upload/property/address/resolution";
  private bulkIgnoreAddressUrl = "_MICRO_SERVICE_/upload/property/address/bulk_resolution";

  constructor(private http: HttpClient) { }

  list(tenant_id: string, search: string, pageNumber: number, pageSize: number, statuses: string[]): Observable<any> {
    return this.http.get(`${this.getAddressUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&tenantId=${tenant_id}&statuses=${statuses}`);
  }

  getAllUnresolvedAddress( search: string, pageNumber: number, pageSize: number, is_resolved: boolean = false): Observable<any> {
    return this.http.get(`${this.getAllAddressUrl}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&is_resolved=${is_resolved}`);
  }


  updateAddress(payload: AddressPayload): Observable<any> {
    return this.http
      .put(`${this.updateAddressUrl}`, payload)
      .map((response: any) => response);
  }


  bulkIgnoreAddress(payload: any[]): Observable<any> {
    return this.http
      .put(`${this.bulkIgnoreAddressUrl}`, payload)
      .map((response: any) => response);
  }

  addressUpdatesInResolved(payload: AddressPayload): Observable<any> {
    return this.http
      .post(`${this.addressUpdate}`, payload)
      .map((response: any) => response);
  }

}
