import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AccountPlansResponse, CreateTenantResponse, DraftAccountRequest, DraftAccountResponse, ValidationResponse } from "src/shared/models/TenantCreation";

@Injectable()
export class TenantCreationService {

  private apiUrlCompanyIdValidation = "_MICRO_SERVICE_/tenant/identifier/verify";
  private apiUrlCreateDraftAccount = "_MICRO_SERVICE_/tenant/draft";
  private apiUrlVerifyEmailId = "_MICRO_SERVICE_/tenant/email/confirm";
  private apiUrlGetAccountPlans = "_MICRO_SERVICE_/tenant/subscription/list";
  private apiUrlAssignPlanToAccount = "_MICRO_SERVICE_/tenant/draft/tier";
  private apiUrlResendEmailCode = "_MICRO_SERVICE_/tenant/email/confirmation/code";
  private apiUrlCreateTenant = "_MICRO_SERVICE_/tenant/register-tenant"

  constructor(private http: HttpClient) {
  }

  verifyCompanyCode(code: string): Observable<ValidationResponse> {
    return this.http
      .post<ValidationResponse>(this.apiUrlCompanyIdValidation, {identifier: code});
  }

  createDraftAccount(data: DraftAccountRequest): Observable<DraftAccountResponse> {
    return this.http
      .post<DraftAccountResponse>(this.apiUrlCreateDraftAccount, {...data});
  }

  verifyEmailCode(code: string, id: string): Observable<ValidationResponse> {
    return this.http
      .post<ValidationResponse>(this.apiUrlVerifyEmailId + `?code=${code}&id=${id}`, {});
  }

  loadAccountPlans(): Observable<AccountPlansResponse> {
    return this.http
      .get<AccountPlansResponse>(this.apiUrlGetAccountPlans);
  }

  assignPlanToAccount(id: string, tier: string): Observable<ValidationResponse> {
    return this.http
      .put<ValidationResponse>(this.apiUrlAssignPlanToAccount + `?id=${id}&tier=${tier}`, {});
  }

  resendEmailCode(id: string): Observable<ValidationResponse> {
    return this.http
      .post<ValidationResponse>(this.apiUrlResendEmailCode + `?id=${id}`, {});
  }

  createTenant(id: string): Observable<CreateTenantResponse> {
    return this.http
      .post<CreateTenantResponse>(this.apiUrlCreateTenant, {draft_account_id: id});
  }
}