import {Action} from '@ngrx/store';

export const ActionTypes = {
  LOAD_SERVICE_DETAILS_SUCCESS: '[Miso:ServiceDetails] Load Service Details',
  SAVE_SERVICE_DETAILS_SUCCESS: '[Miso:ServiceDetails] Save Service Details',
  SERVICE_DETAILS_SHOW_FORM: '[Miso:ServiceDetails] Show Form',
  SERVICE_DETAILS_RESET: '[Miso:ServiceDetais] Reset'
};

export const FormTypes = {
  ACTIVATION_FORM: 'ActivationForm',
  CANCELLATION_FORM: 'CancellationForm',
  CONFIRM_CANCELLATION_FORM: 'ConfirmCancellationForm',
  REMOVE_CANCELLATION_FORM: 'RemoveCancellationForm',
  RETERM_FORM: 'RetermForm',
  GROUP_FORM: 'GroupForm'
};

export class SDLoadSuccess implements Action {
  type = ActionTypes.LOAD_SERVICE_DETAILS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SDSaveSuccess implements Action {
  type = ActionTypes.SAVE_SERVICE_DETAILS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SDShowForm implements Action {
  type = ActionTypes.SERVICE_DETAILS_SHOW_FORM;

  constructor(public payload: any) {
  }
}

export class SDReset implements Action {
  type = ActionTypes.SERVICE_DETAILS_RESET;

  constructor(public payload: any) {
  }
}

export type Actions
  = SDLoadSuccess
  | SDSaveSuccess
  | SDShowForm
  | SDReset
