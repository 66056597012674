<ng-container *ngIf="loaded; else loading">
<form  (ngSubmit)="onFormSubmit()" [(formGroup)]="docForm">
    <div class="row">
      <mi-extended-input [errorDefs]="{required: 'You don\'t have a permission to this division! Please select a team for it'}"
                         [labelRequired]="companyOwner.role === 999 && this.selectedDivision == 'null'"
                         [inputControl]="docForm.controls['division_id']"
                         [inputErrors]="docForm.controls['division_id'].errors"
                         [inputTouched]="docForm.controls['division_id'].touched"
                         class="half-column"
                         labelText="Division">
        <select formControlName="division_id"
                (change)="changeDivision($event.target.value)">
          <option [value]="null">{{ this.companyOwner.role === 999 ? 'Select a division' : 'Company Wide' }}</option>
          <option *ngFor="let division of divisions" [value]="division.id">
            {{ division.name }}
          </option>
        </select>
      </mi-extended-input>

      <mi-extended-input [errorDefs]="{required: 'Please select a team for the division, as you don\'t have permission for this one'}"
                         [labelRequired]="isTeamRequired"
                         [inputControl]="docForm.controls['team_id']"
                         [inputErrors]="docForm.controls['team_id'].errors"
                         [inputTouched]="docForm.controls['team_id'].touched"
                         class="half-column"
                         labelText="Team">
        <select formControlName="team_id"
                (change)="changeTeam($event.target.value)">
          <option [value]="null">Select a team</option>
          <option *ngFor="let team of teams" [value]="team.id">
            {{ team.name }}
          </option>
        </select>
      </mi-extended-input>
    </div>
    <section>
      <mi-extended-input [labelRequired]="true"
                         class="label--large"
                         hint="{{ 'DATA_IMPORT.UPLOAD_DOCUMENT_VENDOR_HINT' | translate }}"
                         labelText="{{ 'DATA_IMPORT.UPLOAD_DOCUMENT_VENDOR_TITLE' | translate }}">
      </mi-extended-input>

      <mi-simple-vendor *ngIf="selectedVendor"
                        [vendor]="selectedVendor">
      </mi-simple-vendor>
      <button (click)="openSelectVendor($event)" *ngIf="!vendorLocked"
                  mi-button>
            {{ (selectedVendor ? 'DATA_IMPORT.CHANGE_VENDOR' : 'DATA_IMPORT.CHOOSE_VENDOR') | translate }}
          </button>
    </section>

    <section *ngIf="selectedVendor">
      <br/>
      <mi-extended-input
      class="label--large"
      labelText="Enter Vendor Account ID"
      [labelRequired]="true"
      [(inputControl)]="docForm.controls['account_id']"
      [(inputErrors)]="docForm.controls['account_id'].errors"
      [(inputTouched)]="docForm.controls['account_id'].touched"
      [errorDefs]='{required: "Please select or enter your Vendor Account ID."}'>

      <mi-find-or-create-account-id
        [vendor]="selectedVendor"
        [currentAccountId]="currentAccountId"
        (accountSelected)="onAccountIdSelected($event)"
        [commandGetAccountId]="commandGetAccountId">
      </mi-find-or-create-account-id>

    </mi-extended-input>
    </section>
    
    <section>
      <mi-extended-input [labelRequired]="true"
                         class="label--large"
                         hint="{{'DATA_IMPORT.UPLOAD_TYPE_HINT' | translate}}"
                         labelText="{{'DATA_IMPORT.UPLOAD_DOCUMENT_TYPE' | translate}}">
        <select formControlName="type"
        (change)="changeUploadType($event.target.value)">
          <option [value]="null" disabled>
            {{'DATA_IMPORT.UPLOAD_TYPE_OPTION_ONE' | translate}}
          </option>
          <option *ngFor="let type of objectKeys(DOC_TYPES)" [value]="type">
            {{"UPLOAD_DOC_TYPES." + DOC_TYPES[type] | translate }}
          </option>
        </select>
      </mi-extended-input>
    </section>

    <section *ngIf="showInvoiceDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['invoice_date']"
                           [inputErrors]="docForm.controls['invoice_date'].errors"
                           [inputTouched]="docForm.controls['invoice_date'].touched"
                           class="half-column"
                           labelText="Invoice Date">
          <mi-date-picker [currentDate]="docForm.get('invoice_date').value"
                          (dateChange)="onDateChanged($event, 'invoice_date')">
          </mi-date-picker>
        </mi-extended-input>

        <mi-extended-input [errorDefs]=""
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['invoice_number']"
                           [inputErrors]="docForm.controls['invoice_number'].errors"
                           [inputTouched]="docForm.controls['invoice_number'].touched"
                           class="half-column"
                           labelText="Invoice Number">
          <input formControlName="invoice_number"
                 name="invoice_number"
                 placeholder="Invoice Number"
                 type="text" />
          <div class="error_label">{{errorInvoiceNumber}}</div>
        </mi-extended-input>

      </div>

      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['invoice_from_date']"
                           [inputErrors]="docForm.controls['invoice_from_date'].errors"
                           [inputTouched]="docForm.controls['invoice_from_date'].touched"
                           class="half-column"
                           labelText="Invoice From Date">
        <mi-date-picker
          [currentDate]="docForm.get('invoice_from_date').value"
          (dateChange)="onDateChanged($event, 'invoice_from_date')">
        </mi-date-picker>
        </mi-extended-input>
  
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['invoice_to_date']"
                           [inputErrors]="docForm.controls['invoice_to_date'].errors"
                           [inputTouched]="docForm.controls['invoice_to_date'].touched"
                           class="half-column"
                           labelText="Invoice To Date">
          <mi-date-picker
            [currentDate]="docForm.get('invoice_to_date').value"
            (dateChange)="onDateChanged($event, 'invoice_to_date')">
          </mi-date-picker>
        </mi-extended-input>

      </div>

      <div class="row">

        <mi-extended-input labelText="Currency"
          [(inputControl)]="docForm.controls['invoice_tenant_currency_id']"
          [(inputErrors)]="docForm.controls['invoice_tenant_currency_id'].errors"
          [(inputTouched)]="docForm.controls['invoice_tenant_currency_id'].touched"          
          class="half-column">
          <select id="invoice_tenant_currency_id"
                  name="invoice_tenant_currency_id"
                  placeholder=""
                  formControlName="invoice_tenant_currency_id"
                  type="text"
                  autocomplete="none">
                  <option *ngFor="let currency of tenantCurrencies" [value]="currency.id">
                    {{ currency.name + ' (' + currency.code +')'}}
                  </option>
          </select>
        </mi-extended-input>

        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['invoice_value']"
                           [inputErrors]="docForm.controls['invoice_value'].errors"
                           [inputTouched]="docForm.controls['invoice_value'].touched"
                           class="half-column"
                           labelText="Invoice Value">
                           <input formControlName="invoice_value"
                           name="invoice_value"
                           placeholder="Invoice Value"
                           type="text"
                           mi-restrict-input miRestrictType="PositiveNumber"/>
        </mi-extended-input>
      </div>

      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['invoice_status']"
                           [inputErrors]="docForm.controls['invoice_status'].errors"
                           [inputTouched]="docForm.controls['invoice_status'].touched"
                           class="half-column"
                           labelText="Invoice Status">
                           <select formControlName="invoice_status">
                             <option [value]="null" disabled>
                               {{'DATA_IMPORT.UPLOAD_INVOICE_STATUS_ONE' | translate}}
                             </option>
                             <option value="PAID">
                              Paid
                            </option>
                            <option value="UNPAID">
                              Unpaid
                            </option>
                           </select>
        </mi-extended-input>


      </div>
    </section>

    <section *ngIf="showReceiptDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['receipt_date']"
                           [inputErrors]="docForm.controls['receipt_date'].errors"
                           [inputTouched]="docForm.controls['receipt_date'].touched"
                           class="half-column"
                           labelText="Receipt Date">
        <mi-date-picker
          [currentDate]="docForm.get('receipt_date').value"
          (dateChange)="onDateChanged($event, 'receipt_date')">
        </mi-date-picker>
        </mi-extended-input>
  
        <mi-extended-input [errorDefs]=""
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['receipt_number']"
                           [inputErrors]="docForm.controls['receipt_number'].errors"
                           [inputTouched]="docForm.controls['receipt_number'].touched"
                           class="half-column"
                           labelText="Receipt Number">
                           <input formControlName="receipt_number"
                           name="receipt_number"
                           placeholder="Receipt Number"
                           type="text" />
        </mi-extended-input>
      </div>

      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['receipt_from_date']"
                           [inputErrors]="docForm.controls['receipt_from_date'].errors"
                           [inputTouched]="docForm.controls['receipt_from_date'].touched"
                           class="half-column"
                           labelText="Receipt From Date">
        <mi-date-picker
          [currentDate]="docForm.get('receipt_from_date').value"
          (dateChange)="onDateChanged($event, 'receipt_from_date')">
        </mi-date-picker>
        </mi-extended-input>
  
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['receipt_to_date']"
                           [inputErrors]="docForm.controls['receipt_to_date'].errors"
                           [inputTouched]="docForm.controls['receipt_to_date'].touched"
                           class="half-column"
                           labelText="Receipt To Date">
          <mi-date-picker
            [currentDate]="docForm.get('receipt_to_date').value"
            (dateChange)="onDateChanged($event, 'receipt_to_date')">
          </mi-date-picker>
        </mi-extended-input>

      </div>

      <div class="row">
        <mi-extended-input labelText="Currency"
          [(inputControl)]="docForm.controls['receipt_tenant_currency_id']"
          [(inputErrors)]="docForm.controls['receipt_tenant_currency_id'].errors"
          [(inputTouched)]="docForm.controls['receipt_tenant_currency_id'].touched"          
          class="half-column">
          <select id="receipt_tenant_currency_id"
                  name="receipt_tenant_currency_id"
                  placeholder=""
                  formControlName="receipt_tenant_currency_id"
                  type="text"
                  autocomplete="none">
                  <option *ngFor="let currency of tenantCurrencies" [value]="currency.id">
                    {{ currency.name + ' (' + currency.code +')'}}
                  </option>
          </select>
        </mi-extended-input>

        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['receipt_value']"
                           [inputErrors]="docForm.controls['receipt_value'].errors"
                           [inputTouched]="docForm.controls['receipt_value'].touched"
                           class="half-column"
                           labelText="Receipt Value">
                           <input formControlName="receipt_value"
                           name="receipt_value"
                           placeholder="receipt Value"
                           type="text"
                           mi-restrict-input miRestrictType="PositiveNumber"/>
        </mi-extended-input>
      </div>
    </section>

    <section *ngIf="showOrderDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['order_date']"
                           [inputErrors]="docForm.controls['order_date'].errors"
                           [inputTouched]="docForm.controls['order_date'].touched"
                           class="half-column"
                           labelText="Order Date">
        <mi-date-picker
          [currentDate]="docForm.get('order_date').value"
          (dateChange)="onDateChanged($event, 'order_date')">
        </mi-date-picker>
        </mi-extended-input>
  
        <mi-extended-input [errorDefs]=""
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['order_number']"
                           [inputErrors]="docForm.controls['order_number'].errors"
                           [inputTouched]="docForm.controls['order_number'].touched"
                           class="half-column"
                           labelText="Order Number">
                           <input formControlName="order_number"
                           name="order_number"
                           placeholder="Order Number"
                           type="text" />
        </mi-extended-input>
      </div>

    </section>

    <section *ngIf="showOtherDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['other_date']"
                           [inputErrors]="docForm.controls['other_date'].errors"
                           [inputTouched]="docForm.controls['other_date'].touched"
                           class="half-column"
                           labelText="Other Date">
        <mi-date-picker
          [currentDate]="docForm.get('other_date').value"
          (dateChange)="onDateChanged($event, 'other_date')">
        </mi-date-picker>
        </mi-extended-input>
      </div>

    </section>

    <section *ngIf="showAgreementDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['agreement_date']"
                           [inputErrors]="docForm.controls['agreement_date'].errors"
                           [inputTouched]="docForm.controls['agreement_date'].touched"
                           class="half-column"
                           labelText="Agreement Date">
        <mi-date-picker
          [currentDate]="docForm.get('agreement_date').value"
          (dateChange)="onDateChanged($event, 'agreement_date')">
        </mi-date-picker>
        </mi-extended-input>
      </div>

    </section>

    <section *ngIf="showAmendmentDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['amendment_date']"
                           [inputErrors]="docForm.controls['amendment_date'].errors"
                           [inputTouched]="docForm.controls['amendment_date'].touched"
                           class="half-column"
                           labelText="Amendment Date">
        <mi-date-picker
          [currentDate]="docForm.get('amendment_date').value"
          (dateChange)="onDateChanged($event, 'amendment_date')">
        </mi-date-picker>
        </mi-extended-input>
      </div>

    </section>
    
    <section *ngIf="showNdaDetails">
      <div class="row">
        <mi-extended-input [errorDefs]="{}"
                           [labelRequired]="false"
                           [inputControl]="docForm.controls['nda_date']"
                           [inputErrors]="docForm.controls['nda_date'].errors"
                           [inputTouched]="docForm.controls['nda_date'].touched"
                           class="half-column"
                           labelText="NDA Date">
        <mi-date-picker
          [currentDate]="docForm.get('nda_date').value"
          (dateChange)="onDateChanged($event, 'nda_date')">
        </mi-date-picker>
        </mi-extended-input>
      </div>

    </section>

    <section *ngIf="uploadUrl">
      <mi-extended-input
                         [errorDefs]="{required: 'A file is required.'}"
                         [labelRequired]="true"
                         class="label--large"
                         labelText="Enter the file link">
        <input formControlName="file_name"
               name="file_name"
               placeholder="File Link"
               type="text" />
      </mi-extended-input>
      <div class="error_label">{{errorLink}}</div>
    </section>

    <section *ngIf="!uploadUrl&&document">
      <mi-extended-input
                         [errorDefs]="{required: 'A file is required.'}"
                         [labelRequired]="true"
                         class="label--large"
                         labelText="File Name">
                         <a *ngIf="document.is_v1"href="{{getV1FileUrl(document)}}" target="_blank">{{ document.file_name }}</a>
                         <a *ngIf="!document.is_v1" (click)="downloadDocument(document.id)">{{ document.file_name }}</a>
         
      </mi-extended-input>
    </section>

    <section>
      <mi-extended-input [errorDefs]="{required: 'A document name is required.'}"
                         [inputControl]="docForm.controls['name']"
                         [inputErrors]="docForm.controls['name'].errors"
                         [inputTouched]="docForm.controls['name'].touched"
                         [labelRequired]="true"
                         class="label--large"
                         labelText="Document Name">
        <input formControlName="name"
               name="name"
               placeholder="Document Name"
               type="text"/>
      </mi-extended-input>
    </section>

    <section>
      <mi-extended-input class="label--large"
                         hint="{{ 'DATA_IMPORT.ADDITIONAL_INSTRUCTIONS_HINT' | translate }}"
                         labelText="{{ 'DATA_IMPORT.ADDITIONAL_INSTRUCTIONS_TITLE' | translate }}">
        <angular-tinymce
          formControlName="description">
        </angular-tinymce>
      </mi-extended-input>
    </section>

    <mi-server-error [error]="submitError"></mi-server-error>

    <div class="button-container">
      <ng-container *ngIf="editId&&!hideDelete">
        <a mi-button
          class="button-alternate"
          buttonTheme="alert-light-theme"
          routerLink="../delete">
          Delete
        </a>
      </ng-container>
  
      <a mi-button
         class="button--cancel"
         buttonTheme="light-theme"
         (click)="onCancel($event)">
        Cancel
      </a>
  
      <button mi-button
              type="submit"
              [disabled]="!docForm.valid">
          Save Document
      </button>
    </div>

  </form>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template> 
