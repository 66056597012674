import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import FrontEndSettingsUtil from "../../../shared/utils/frontEndSettings.util";
import { Cacheable } from 'ngx-cacheable';

const log = createLogger(LOG_LEVELS.HTTP_API);

export interface FrontEndSettings {
  MICRO_SERVICE_BASE_URL: string,
  MICRO_SERVICE_UPLOAD_URL: string,
  CURRENT_PRIVACY_POLICY_URL: string,
  CURRENT_TERMS_AND_CONDITIONS_URL: string,
  MISO3_TENANT_DOMAIN?: string
  TENANT_REPORTS?: ITenantReport[]
  MISO3_TENANT_CURRENCY?: any;
  ACCOUNTS_URL?: string;
  MIYAGI_URL?: string;
  STRIPE_PUBLISHABLE_KEY?: string;
  MISO3_TENANT_CODE?: string;
}

export interface ITenantReport {
  tenantId: string;
  reportName: string;
  metabaseDashboardId: number;
}

export interface ITenantConfig {
  config_id: string;
  tenant_id1: string;
  tenant_id2: string;
  config_name: string;
}


export interface CvlSettings {
  CVL_INVOKE_URL: string;
  CVL_SERVER_URL_GET: string;
  CVL_SERVER_URL_POST: string;
  CVL_REGION: string;
  CVL_SERVICE_NAME: string;
  CVL_SECRET_KEY: string;
  CVL_ACCESS_KEY: string;
}

@Injectable()
export class SettingsService {
  private apiGet = '/settings';
  private cvlApiGet = '/cvlsettings';

  constructor(private http: HttpClient) {
  }
  @Cacheable()
  get(): Observable<FrontEndSettings> {
    console.log("SettingsService get");
    return this.http.get(this.apiGet)
      .map((response: any) => {
        const fe:FrontEndSettings = response.data.settings as FrontEndSettings;
        FrontEndSettingsUtil.set(fe);
        return fe;
      });
  }

  @Cacheable()
  getCvl(): Observable<CvlSettings> {
    return this.http.get(this.cvlApiGet)
      .map((response: any) => {
        const cvl:CvlSettings = response.data.settings as CvlSettings;
        FrontEndSettingsUtil.setCvl(cvl);
        return cvl;
      });
  }

}
