<div *ngIf="(pageLoaded$ | async); else loading" class="data-section">

  <section class="half-col">

    <mi-opacity-loader [ready]="!editing">

      <form [formGroup]="editForm" (submit)="onParentServiceUpdate(editForm.value)">
        <mi-extended-input labelText="Parent Service Nickname"
                           [labelRequired]="true"
                           [inputControl]="editForm.controls['nickname']"
                           [inputErrors]="editForm.controls['nickname'].errors"
                           [inputTouched]="editForm.controls['nickname'].touched">
          <input type="text"
                 class="form-control"
                 formControlName="nickname"
                 placeholder="Nickname"
                 autofocus>
        </mi-extended-input>

        <mi-extended-input labelText="Parent Service M3 Owner"
                           [labelRequired]="true"
                           [inputControl]="editForm.controls['assigned_email']"
                           [inputErrors]="editForm.controls['assigned_email'].errors"
                           [inputTouched]="editForm.controls['assigned_email'].touched">
          <mi-select-service-ownership 
            [selectedDivision]="parentService.division"
            [selectedTeam]="parentService.team"
            [selectedUser]="parentService.assigned_email"
            (outPutUser)="onAssignedEmail($event)"
            (outPutBusinessUnit)="onBusinessUnit($event)">
          </mi-select-service-ownership>
        </mi-extended-input>

        <div class="button-container">
          <a mi-button
            class="button-alternate"
            buttonTheme="alert-light-theme"
            routerLink="../delete">
            Delete
          </a>

          <a routerLink="../details" mi-button buttonTheme="light-theme">
            Cancel
          </a>

          <button type="submit"
                  mi-button buttonTheme="dark-theme"
                  [disabled]="!valuesChanged || !editForm.valid">
            Save
          </button>

        </div>

        <mi-server-error [error]="error"></mi-server-error>

        <div *ngIf="saveComplete" class="saveMessage">

          {{'PARENT_SERVICE_UPDATED_SUCCESS' | translate:{display_id: parentService.display_id} }}

        </div>

      </form>

    </mi-opacity-loader>

  </section>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
