<div class="filter-group" *ngIf="isVisible">
  <h2>{{title}}</h2>


  <form *ngIf="form !== null" [(formGroup)]="form" (ngSubmit)="onFormSubmit(form.value)">

    <div class="clear-fix">
      <div class="half">
        <label for="{{minname}}">{{min_label}}</label>
        <input class="min-number"
               formControlName="{{minname}}"
               id="{{minname}}"
               min="0"
               placeholder="{{min_placeholder}}"
               type="number" mi-restrict-input miRestrictType="PositiveNumber">
      </div>

      <div class="half">
        <label for="{{maxname}}">{{max_label}}</label>
        <input class="max-number"
               formControlName="{{maxname}}"
               id="{{maxname}}"
               min="0"
               placeholder="{{max_placeholder}}"
               type="number" mi-restrict-input miRestrictType="PositiveNumber">
      </div>
    </div>

    <div *ngIf="!form.valid"
         class="mi-alert mi-alert--error"
         style="margin: 10px 0 0;">
         {{min_label}} should be less than {{max_label}}.
    </div>

    <div class="button-container">

      <button mi-button
              type="submit"
              [disabled]="!form.valid">
        {{filter_button_label}}
      </button>
    </div>

  </form>

</div>



