import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {NO_DATA} from '../../../shared/ng-models/SectionDataTable.model';
import {Equipment} from '../../../shared/models/Equipment';
import {SectionDataTableColumn} from '../../../shared/ng-models/SectionDataColumn.interface';
import {SortableTemplatesEnum} from '../../../shared/ng-models/SortableTableColumn.interface';

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-equipments-service-static',
  templateUrl: './equipments-service-static.component.html',
  styleUrls: ['./equipments-service-static.component.scss']
})

export class EquipmentsServiceStaticComponent extends AbstractPageComponent implements OnInit {


  @Input() currentEquipments: Equipment[] = [];
  @Input() showLinks: boolean = true;
  loading: boolean = true;
  loadError: string = '';
  readonly NO_DATA: string = NO_DATA;
  currentEquipmentsColumns: SectionDataTableColumn[] = [];
  @ViewChild('vendorTmpl', {static: true}) vendorTmpl: TemplateRef<any>;

  constructor() {
    super();
  }

  ngOnInit() {
    this.currentEquipmentsColumns = [
      {
        name: "MISO ID",
        prop:'display_id',
        cellTemplate: this.showLinks ? SortableTemplatesEnum.idLink2 : null,
        sortable: true
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: true
      },
      {
        name: "Manufacturer",
        prop: 'equipment_catalog.vendor.vendor_name',
        cellTemplate: this.showLinks ? this.vendorTmpl : null,
        sortable: true
      },
      {
        name: 'Model #',
        prop: 'equipment_catalog.category_name',
        sortable: true
      },
      {
        name: 'Serial Number or Tag',
        prop: 'serial',
        sortable: false,
        className: "serial-number-or-tag",
        cellTemplate: SortableTemplatesEnum.truncate
      },
      {
        name: 'Price $',
        prop: 'price',
        sortable: true,
        cellTemplate: SortableTemplatesEnum.currency
      }    
    ];
    this.loading = false;
  }

}

