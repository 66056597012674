<ng-container *ngIf="orderState; else loading">

  <ng-container *ngIf="activeIndex; else static">

    <form [formGroup]="accountForm"
          (submit)="onFormSubmit(accountForm.value)">

      <mi-extended-input
        labelText="Enter Vendor Account ID"
        [labelRequired]="true"
        [(inputControl)]="accountForm.controls['account_id']"
        [(inputErrors)]="accountForm.controls['account_id'].errors"
        [(inputTouched)]="accountForm.controls['account_id'].touched"
        [errorDefs]='{required: "Please select or enter your Vendor Account ID."}'>

        <mi-find-or-create-account-id
          [vendor]="orderState.vendor"
          [currentAccountId]="currentAccountId"
          (accountSelected)="onAccountIdSelected($event)"
          [commandGetAccountId]="commandGetAccountId">
        </mi-find-or-create-account-id>

      </mi-extended-input>

      <div class="button-container">
        <button mi-button
                buttonTheme="dark-theme"
                type="submit">
          Next
        </button>
      </div>

    </form>

  </ng-container>

  <ng-template #static>
    <dl>
      <dt>
        Vendor Account ID
      </dt>
      <dd>
        <ng-container *ngIf="currentAccountId">
          {{currentAccountId.label}}
        </ng-container>
        <ng-container *ngIf="currentAccountIdNotProvided">
          {{'Account Id Not Provided'}}
        </ng-container>
        <ng-container *ngIf="!currentAccountIdNotProvided && !currentAccountId">
          {{NO_DATA}}
        </ng-container>
      </dd>
    </dl>
  </ng-template>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
