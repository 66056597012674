<ng-container [ngSwitch]="bootReady">
  <ng-container *ngSwitchCase="true">
    <mi-main-nav *ngIf="isNavPath"></mi-main-nav>
    <mi-top-nav 
      *ngIf="isNavPath"
      [structure]="topNavStructure"
      [isNavPath]="isNavPath"
      [showDashboardButton]="true">
    </mi-top-nav>
    <main [ngClass]="{'nav-removed': !isNavPath, 'nav-open': !navIsOpen}" [@toggleNav]="isNavPath ? navIsOpen : null">
      <div [ngClass]="[baseTemplateClass, template]">
        <router-outlet></router-outlet>
      </div>
    </main>
    <!-- <mi-footer></mi-footer> -->
  </ng-container>
  <ng-container *ngSwitchCase="false">
    Loading &#133;
  </ng-container>
</ng-container>
