import {Component, Input} from "@angular/core";

import {select, Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {takeUntil} from "rxjs/operators";



@Component({
  selector: 'mi-advanced-search-single-checkbox',
  templateUrl: './advanced-search-filter-single-checkbox.component.html',
  styleUrls: ['./advanced-search-filter-single-checkbox.component.scss']
})

export class AdvancedSearchFilterSingleCheckboxComponent extends AbstractPageComponent {

  @Input() param: string = 'need_to_set';
  @Input() label: string = 'need to set label';

  checked: boolean = false;

  constructor(
              private store: Store<ASReducer.State>) {
    super()
  }


  ngOnInit() {
    this.store.pipe(
        select(ASReducer.getASData),
        takeUntil(this.destroy$)
      )
      .subscribe((asData) => {

        if (asData.resultsLoaded) {
          this.checked = asData.results.meta.params[this.param] === 'true';
        }
      });
  }

  toggle(evt) {
    let newValue = '';

    if(evt.target.checked) {
      //we want to remove the filter
      newValue='true'
    }

    const newFilters = {};
    newFilters[this.param] = newValue;

    this.store.dispatch(new ASActions.AsFilterUpdate(newFilters));

  }

}
