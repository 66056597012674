import {createFeatureSelector, createSelector,} from '@ngrx/store';
import * as ParentServiceDetailActions from './actions';
import {MiParentService} from '../../../../../shared/models/MiParentService';
import {MiService} from '../../../../../shared/models/MiService';

export interface State {
  currentForm?: string;
  pageLoaded: boolean,
  parentService: MiParentService,
  serviceToAdd?: MiService,
  serviceToRemove?: MiService,
  update: boolean
}

const initialState: State = {
  parentService: null,
  pageLoaded: false,
  update: false
};

export function reducer(state = initialState, action: ParentServiceDetailActions.Actions): State {
  switch (action.type) {
    case ParentServiceDetailActions.ActionTypes.PARENT_SERVICE_DETAILS_RESET: {
      return {
        ...initialState
      }
    }
    case ParentServiceDetailActions.ActionTypes.LOAD_PARENT_SERVICE_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        pageLoaded: true
      }
    }
    case ParentServiceDetailActions.ActionTypes.UPDATE_PARENT_SERVICE:{

      return {
        ...state,
        serviceToAdd: null,
        serviceToRemove: null,
        update: true
      }
    }
    case ParentServiceDetailActions.ActionTypes.PARENT_SERVICE_ADD_SERVICE:{

      return {
        ...state,
        serviceToAdd: action.payload,
      }
    }
    case ParentServiceDetailActions.ActionTypes.PARENT_SERVICE_REMOVE_SERVICE:{

      return {
        ...state,
        serviceToRemove: action.payload
      }
    }
    case ParentServiceDetailActions.ActionTypes.PARENT_SERVICE_SHOW_FORM:{
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
}

export const getParentServiceData = createFeatureSelector<State>('parentServiceDetails');
export const ParentServicePageLoaded = createSelector(getParentServiceData, (data) => data.pageLoaded);

