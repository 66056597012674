<ng-container [ngSwitch]="pageType">
  <ng-container *ngSwitchCase="'EVENT'">
    <div class="mi-grid-aside-basic">
      <div class="col-all">    
        <mi-advanced-search-time-range></mi-advanced-search-time-range>

        <mi-advanced-search-header
          searchType="SERVICE"
          saveSearch="true">
        </mi-advanced-search-header>
    
        <ng-container *ngIf="searchLoaded$ | async; else loading">
          <mi-applied-search-filters></mi-applied-search-filters>
          <mi-advanced-search-service-results-table></mi-advanced-search-service-results-table>
          <mi-advanced-search-load-more></mi-advanced-search-load-more>
        </ng-container>
    
        <ng-template #loading>
          <mi-loading-animation></mi-loading-animation>
        </ng-template>
    
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'EQUIPMENT'">
    <div class="mi-grid-aside-basic">
      <div class="col-all">
    
        <mi-advanced-search-header
          searchTypeTitle="Search Equipment"
          searchType="EQUIPMENT"
          [showTotalMrc]="false">
        </mi-advanced-search-header>
    
        <mi-opacity-loader [ready]="searchLoaded$ | async">
    
          <mi-applied-search-filters></mi-applied-search-filters>
          <mi-advanced-search-equipment-results></mi-advanced-search-equipment-results>
          <mi-advanced-search-load-more></mi-advanced-search-load-more>
    
        </mi-opacity-loader>
    
      </div>
    </div>
  </ng-container>
</ng-container>