import { Action } from '@ngrx/store';

export const ActionTypes = {
  USER_VERIFY: '[Miso:User] Verify',
  USER_VERIFY_ERROR: '[Miso:User] Verify error',
  USER_VERIFY_SUCCESS: '[Miso:User] Verify Success',
  USER_LOGOUT: '[Miso:User] Logout',
  USER_LOGOUT_SUCCESS: '[Miso:User] Logout success',
};

export class UserVerifyAction implements Action {
  type = ActionTypes.USER_VERIFY;

  constructor(public payload) { }
}

export class UserVerifySuccessAction implements Action {
  type = ActionTypes.USER_VERIFY_SUCCESS;

  constructor(public payload) { }
}

export class UserVerifyErrorAction implements Action {
  type = ActionTypes.USER_VERIFY_ERROR;

  constructor(public payload) { }
}

export class UserLogoutAction implements Action {
  type = ActionTypes.USER_LOGOUT;

  constructor(public payload) { }
}

export class UserLogoutSuccessAction implements Action {
  type = ActionTypes.USER_LOGOUT_SUCCESS;

  constructor(public payload) { }
}

export type Actions
  = UserVerifyAction
  | UserVerifySuccessAction
  | UserVerifyErrorAction
  | UserLogoutAction
  | UserLogoutSuccessAction;
