<ng-container *ngIf="orderState;else loading">

  <dl>
      <mi-section-data-attachments
      [docs]="documents"
      noResultsText="If you have any attachments, files or documents associated to this order, please add them here.">
    </mi-section-data-attachments>
  </dl>

  <ng-container *ngIf="activeIndex">

    <button mi-button
            buttonTheme="dark-theme"
            (click)="onAddDocumentsClicked()"
            *ngIf="!addingDocuments">
      Add Documents
    </button>

    <form [formGroup]="documentsForm"
          (submit)="onFormSubmit(documentsForm.value)">

      <mi-extended-input
        *ngIf="addingDocuments"
        labelText="Associated Documents"
        [labelRequired]="true"
        [(inputControl)]="documentsForm.controls['associated_documents']"
        [(inputErrors)]="documentsForm.controls['associated_documents'].errors"
        [(inputTouched)]="documentsForm.controls['associated_documents'].touched"
        [errorDefs]="{required: 'n/a'}">

        <mi-find-or-create-vendor-documents
          [vendor]="orderState.vendor"
          (selectedVendorDocuments)="onDocumentSelect($event)">

        </mi-find-or-create-vendor-documents>

      </mi-extended-input>

      <div class="button-container">
        <button mi-button
                buttonTheme="dark-theme"
                type="submit"
                [disabled]="addingDocuments">
          Next
        </button>
      </div>
    </form>
  </ng-container>

</ng-container>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>


