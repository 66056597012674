import {Component, Input} from "@angular/core";


import * as ASActions from '../../../state/advanced-search/actions';

import {Store} from '@ngrx/store';
import * as ASReducer from '../../../state/advanced-search/reducers';
import {FilterBucket} from "../../../ng-models/search/FilterBucket.interface";
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import { Contact } from "../../../models/Contact";
import { TenantContactsService } from "../../../../libs/api/tenant/tenantContacts.service";
import {combineLatest, Observable} from "rxjs";

@Component({
  selector: 'mi-advanced-search-filter-requestor',
  templateUrl: './advanced-search-filter-requestor.component.html',
  styleUrls: ['./advanced-search-filter-requestor.component.scss'],
  preserveWhitespaces: true
})

export class AdvancedSearchFilterRequestorComponent extends AbstractPageComponent {

  @Input() filter_name: string;
  @Input() title: string;
  @Input() includeCount: boolean = true;
  @Input() translatePrefix: boolean = false;
  @Input() isCustomField: boolean = false;
  @Input() ignoreVisibleConfig: boolean = false;

  filters: FilterBucket[] = [];
  selectedFilters: FilterBucket[] = [];
  showAll: boolean = false;
  isVisible:boolean = true;
  contacts: any = {};

  constructor(
    private store: Store<ASReducer.State>,
    private tenantContactService:TenantContactsService
  ) {
    super()
  }

  ngOnInit() {
    const asDataData = this.store.select(ASReducer.getASData).takeUntil(this.destroy$);
    const allContacts: Observable<Contact[]> = this.tenantContactService.getAllContacts().takeUntil(this.destroy$);

    combineLatest(asDataData, allContacts)
      .subscribe((allValues) => {
        //log('received all assigned to data', allValues);

          const data = allValues[0];
          const allContacts: Contact[] = allValues[1];

          allContacts.map(contact => this.contacts[contact.id] = contact);

          if (data.results && data.results.aggregations && data.results.aggregations[this.filter_name]) {
            this.filters = data.results.aggregations[this.filter_name].buckets as FilterBucket[];
            this.selectedFilters = this.filters.filter(bucket => bucket.selected);
            this.showAll = this.filters.length > 5;

            if(this.filters.length > 5){
              this.filters = this.filters.slice(0, 5);
            }

            if (this.selectedFilters.length) {
              this.showAll = true;
              this.filters = this.selectedFilters;
            }

          } else {
            this.filters = [];
            this.showAll = false;
          }

          //needs to be reactive to the asData state, similar to:
          this.isVisible = this.ignoreVisibleConfig || data.visibleFilters[this.filter_name];
        }
      );
  }

  toggle(evt, key) {
    let newFilters: string[] = this.selectedFilters.map(bucket => bucket.key);

    if (evt.target.checked) {
      //we want to remove the filter
      newFilters.push(key);
    } else {
      newFilters = newFilters.filter(item => item !== key);
    }

    let newQuery = {};
    newQuery[this.filter_name] = newFilters;

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }

  onShowAllClicked() {
    this.store.dispatch(new ASActions.AsFilterGroupShowAll({filter: this.filter_name, selectedFilters: this.selectedFilters, isCustomField: this.isCustomField, filterLabel: this.title}));
  }
}
