<a (click)="currentModeNull();inlineModal.open();" mi-button buttonTheme="dark-theme">Save Search <span *ngIf="saveSearches.length">({{saveSearches.length}})</span></a>
<modal #inlineModal>
  <modal-header>
    <h4>Save Searches</h4>
  </modal-header>


  <!-- list -->
  <modal-content *ngIf=!currentMode>

      <div class="mi-alert mi-alert--info left-text" role="alert" *ngIf="message">
          <span>{{message}}</span>
      </div>
      <mi-page-alert></mi-page-alert>

      <mi-server-error [error]="error"></mi-server-error>
    <ng-container *ngIf="saveSearches && saveSearches.length">
      <div class="set-height">
          <table class="my-table">
              <tr *ngFor="let saveSearch of saveSearches">
                <td class=link><a (click)="moveToSaveSearch(saveSearch.query);inlineModal.close();">{{saveSearch.label}}</a></td>
                <td>
                  <a class="micon-icon"
                    (click)="onRemoveClick(saveSearch)">
                  </a>
                </td>
              </tr>
            </table>
      </div>

    </ng-container>
    <ng-container *ngIf="!saveSearches.length">
      <p class="left-text">You do not have any saved searches.</p>

    </ng-container>
    <div class= "button-container">
      <div *ngIf="canSave">
        <a mi-button buttonTheme="light-theme" (click)="inlineModal.close()">Close</a>
        <button mi-button buttonTheme="dark-theme" (click)="onSaveSearch()">Save Current Search</button>
      </div>
      <div *ngIf="!canSave">
        <a mi-button buttonTheme="light-theme" (click)="inlineModal.close()">Close</a>
        <button mi-button buttonTheme="dark-theme" (click)="onSaveSearch()" [disabled]=true>No Filter or Already Saved</button>
      </div>
    </div>
  </modal-content>

  <!-- create -->
  <modal-content *ngIf="currentMode==='CREATE'">
      <mi-server-error [error]="error"></mi-server-error>
    <form [formGroup]="form" (ngSubmit)="submit(form.value)" *ngIf="!loading && form !== null" autocomplete="off">

        <mi-extended-input [labelText]="'Property Name'"
                           [labelRequired]="true"
                           [inputControl]="form.controls['label']"
                           [inputErrors]="form.controls['label'].errors"
                           [inputTouched]="form.controls['label'].touched"
                           [errorDefs]="{
                             required: 'Save Search Name is required in order to proceed!',
                             maxlength: 'Save Search Name cannot be longer than 100 characters long.',
                             minlength: 'Save Search Name must be at least 2 characters long'}">
           <input id="saveSearch_label"
                 name="saveSearch_label"
                 formControlName="label"
                 placeholder="Enter the Save Search Name"
                 type="text">
        </mi-extended-input>

      <div class=button-container>
        <a mi-button
          class="button--cancel"
          buttonTheme="light-theme"
          (click)="onCancel()">
          Cancel
        </a>
        <button mi-button
                buttonTheme="dark-theme"
                class="login-button"
                type="submit"
                [disabled]="!form.valid">
          Save
        </button>
      </div>
    </form>
</modal-content>


<!-- delete -->
<modal-content *ngIf="currentMode==='DELETE' && selectedSearch && selectedSearch.label">
    <mi-server-error [error]="error"></mi-server-error>
  <mi-side-notice noticeType="WARNING">
    <strong>Do you want to delete Save Search "{{selectedSearch.label}}"?</strong>
    <p>Remember, this action cannot be reversed.</p>
    <p>Press delete to remove this Save Search permanently</p>

    <mi-server-error [error]="deleteError"></mi-server-error>
    <div class="button-container">
      <a mi-button
        class="button--cancel"
        buttonTheme="alert-light-theme"
        (click)="onCancel()">
        Cancel
      </a>
      <a mi-button
        buttonTheme="alert-theme"
        (click)="permanentlyDelete()">
        Delete
      </a>
    </div>
  </mi-side-notice>
</modal-content>

</modal>
