<h2>{{ 'TENANT.SERVICE_DETAILS.CONFIRM_ACTIVATION_HEADLINE' | translate }}</h2>

<mi-server-error [error]="error"></mi-server-error>

<ng-container *ngIf="confirmationFormGroup">
  <form
    [formGroup]="confirmationFormGroup"
    (submit)="onFormSubmit(confirmationFormGroup.value)">
    <div>
      <mi-extended-input
        labelText="Activation Date"
        class="service-details"
        [labelRequired]="true"
        [inputControl]="confirmationFormGroup.controls['activation_date']"
        [inputErrors]="confirmationFormGroup.controls['activation_date'].errors"
        [inputTouched]="confirmationFormGroup.controls['activation_date'].touched">
        <mi-date-picker
          [maxDate]="defaultActivationDate"
          [currentDate]="defaultActivationDate"
          (dateChange)="onDateChanged($event)">
        </mi-date-picker>
      </mi-extended-input>
    </div>
    <div class="button-container">
      <button
        mi-button
        buttonTheme="light-theme"
        (click)="onCancelClicked()">
        {{'TENANT.SERVICE_DETAILS.CTA.CANCEL_WITHOUT_MODIFICATION' | translate }}
      </button>
      <button
        mi-button
        buttonTheme="dark-theme"
        [disabled]="confirmationFormGroup.invalid"
        type="submit">
        {{ 'TENANT.SERVICE_DETAILS.CTA.SAVE' | translate }}
      </button>
    </div>
  </form>
</ng-container>
