import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Vendor} from '../../../shared/models/Vendor';
import {createLogger, LOG_LEVELS} from '../../../shared/logger';
import {VendorSearchResults} from '../../../shared/models/SearchResults';

const log = createLogger(LOG_LEVELS.HTTP_API);

@Injectable()
export class VendorService {

  private apiUrlIgnore = '/miyagi/vendors/ignore';
  private apiUrlCrud = '/miyagi/vendors';
  private apiUrlUnNormalized = '/miyagi/vendors/un-normalized';
  private apirUrlSearchMiyagiVendors = '/miyagi/search/tenantVendors';

  constructor(private http: HttpClient) {
  }

  createVendor(vendor: Vendor): Promise<Vendor> {
    return this.http
      .post(this.apiUrlCrud, vendor)
      .toPromise()
      .then((response: any) => {
        return response.data.vendor as Vendor;
      });
  }

  editVendor(vendor: Vendor): Promise<Vendor> {
    return this.http
      .put(`${this.apiUrlCrud}/${vendor._id}`, vendor)
      .toPromise()
      .then((response: any) => {
        return response.data.vendor as Vendor;
      });
  }

  deleteVendor(id: String): Observable<Vendor> {
    return this.http
      .delete(`${this.apiUrlCrud}/${id}`)
      .map((response: any) => {
        return response.data.vendor as Vendor;
      });
  }

  ignoreVendor(vendorId: string): Promise<Vendor> {
    return this.http
      .put(`${this.apiUrlIgnore}/${vendorId}`, {})
      .toPromise()
      .then((response: any) => {
        return response.data.vendor as Vendor;
      });
  }

  getVendor(vendorId: string): Promise<Vendor> {
    return this.http
      .get(`${this.apiUrlCrud}/${vendorId}`)
      .toPromise()
      .then((response: any) => {
        return response.data.vendor as Vendor;
      });
  }

  listVendors(): Promise<Array<Vendor>> {
    return this.http
      .get(this.apiUrlCrud)
      .toPromise()
      .then((response: any) => {
        return response.data.vendors as Array<Vendor>;
      });
  }

  listUnNormalized(start: Number, limit: Number, created_at: Number, status: String): Promise<Array<Vendor>> {
    let submissionUrl = this.apiUrlCrud + '?unNormalized=true';

    if (start) {
      submissionUrl += `&start=${start}`;
    }
    if (limit) {
      submissionUrl += `&limit=${limit}`;
    }
    if (created_at) {
      submissionUrl += `&created_at=${created_at}`;
    }
    if (status) {
      submissionUrl += `&status=${status}`;
    }

    return this.http
      .get(submissionUrl)
      .toPromise()
      .then((response: any) => {
        return response.data.vendors.map((vendor, index) => {
          vendor.count = index + start + 1;
          vendor.status = vendor.status || (vendor.normalized ? 'Resolved' : 'Open');
          return vendor;
        }) as Array<Vendor>;
      });
  }

  getUnNormalized(): Promise<Array<Vendor>> {
    return this.http
      .get(this.apiUrlUnNormalized)
      .toPromise()
      .then((response: any) => {
        return response.data.vendors as Array<Vendor>;
      });
  }

  searchMiyagiVendors(q: string = '', from: number = 0, size: number = 6): Promise<VendorSearchResults> {
    return this.http
      .get(this.apirUrlSearchMiyagiVendors, {
        params: new HttpParams({
          fromString: `q=${q}&from=${from}&size=${size}`
        })
      })
      .toPromise()
      .then((response: any) => {
        return response.data as VendorSearchResults;
      });
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
