<div class="dragDropUpload input" [ngClass]="{'dragging': isDrag, 'invalid': isInvalidDrag}">
  <input id="ImageUpload"
         type="file"
         (change)="fileChanged($event)"
         (click)="$event.target.value=''"
         name="file"
         accept="{{accept}}" multiple="multiple"/>
<div>
  <h2 *ngIf="!loading">Drag Item or Browse to Upload.</h2>
  <h2 *ngIf="loading">Uploading {{currentUpload}}/{{totalUpload}}</h2>
</div>
  <div class="status" *ngIf="loading">
    <div class="status-item">
      <div>{{fileName}}</div>
    </div>
    <div class="status-item light-background" *ngIf="loading">
      <div class="progress-container" *ngIf="loading"><div class="progress" [ngStyle]="{width: progress + '%'}" *ngIf="progress>0">Uploading ({{progress}}%)</div>Uploading ({{progress}}%)</div>
    </div>
    <div class="status-item" *ngIf="loaded">
      <span>Upload Complete</span>
    </div>
  </div>
  <div>
    <div>
      <a mi-button 
      buttonTheme="light-theme" 
      class="button-alternate"
      (click)="this.createDocumentLink()">
      Upload a Document Link
    </a>&nbsp;&nbsp;&nbsp;&nbsp;
      
    <label *ngIf="!loading" class="button darkTheme" for="ImageUpload">{{'FILE_UPLOAD_BROWSE'|translate}}</label>
  </div>
</div>
</div>

<div *ngIf="uploadedDocs.length>0">
  <mi-opacity-loader [ready]="!deleting">
    <mi-sortable-draggable-table [rows]="uploadedDocs" [columns]="uploadedDocsColumns"></mi-sortable-draggable-table>
  </mi-opacity-loader>
</div>
<mi-server-error [error]="error"></mi-server-error>
<ng-container *ngFor="let errorDoc of duplicateError">
  <mi-server-error [error]="errorDoc"></mi-server-error>
</ng-container>
<ng-template #editLink let-row="row" let-value="value">
  <a  (click)="openDocumentEditModal(row.id)" class="id-link">
    <ng-container>
      {{row.display_id}}
    </ng-container>
  </a>
</ng-template>

<ng-template #deleteLink let-row="row" let-value="value">
  <a *ngIf="value"
        class="deleteDocument"
        (click)="permanentlyDelete(value)">
      </a>
</ng-template>

