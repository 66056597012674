import {Action} from '@ngrx/store';

export const ActionTypes = {
  RESET_MISSING_VENDOR_DATA: 'Miso: RESET_MISSING_VENDOR_DATA',
  STARTUP_MISSING_VENDOR_DATA: 'Miso: STARTUP_MISSING_VENDOR_DATA',
  REQUEST_FAILED_MISSING_VENDOR_DATA: 'Miso: REQUEST_FAILED_MISSING_VENDOR_DATA',
  MISSING_DATA_UPDATE_ACCOUNT_CONTACT: 'Miso: MISSING_DATA_UPDATE_ACCOUNT_CONTACT',
  MISSING_DATA_UPDATE_ACCOUNT_CONTACT_COMPLETE: 'Miso: MISSING_DATA_UPDATE_ACCOUNT_CONTACT_COMPLETE',
  MISSING_DATA_ACCOUNT_IDS_LOAD_COMPLETE: 'Miso: LOAD_ACCOUNT_IDS_MISSING_DATA',
  MISSING_DATA_VENDOR_DATA_LOAD_COMPLETE: 'Miso: LOAD_MISSING_VENDOR_DATA',
  MISSING_DATA_DEEPLINK_CHANGE: 'Miso: MISSING_DATA_DEEPLINK_CHANGE'
};


export class ResetMissingVendorDataAction implements Action {
  type = ActionTypes.RESET_MISSING_VENDOR_DATA;
  constructor(public payload) {}
}

export class StartupMissingVendorDataAction implements Action {
  type = ActionTypes.STARTUP_MISSING_VENDOR_DATA;
  constructor(public payload) {}
}

export class MissingDataVendorLoadComplete implements Action {
  type = ActionTypes.MISSING_DATA_VENDOR_DATA_LOAD_COMPLETE;
  constructor(public payload) {}
}

export class MissingDataAccountIdLoadComplete implements Action {
  type = ActionTypes.MISSING_DATA_ACCOUNT_IDS_LOAD_COMPLETE;
  constructor(public payload) {}
}

export class MissingDataDeeplinkChange implements Action {
  type = ActionTypes.MISSING_DATA_DEEPLINK_CHANGE;
  constructor(public payload) {}
}

export class RequestFailedMissingVendorData implements Action {
  type = ActionTypes.REQUEST_FAILED_MISSING_VENDOR_DATA;
  constructor(public payload) {}
}

export class MissingDataUpdateAccountContact implements Action {
  type = ActionTypes.MISSING_DATA_UPDATE_ACCOUNT_CONTACT;
  constructor(public payload) {}
}

export class MissingDataUpdateAccountContactComplete implements Action {
  type = ActionTypes.MISSING_DATA_UPDATE_ACCOUNT_CONTACT_COMPLETE;
  constructor(public payload) {}
}

export type Actions
  = ResetMissingVendorDataAction
    | StartupMissingVendorDataAction
    | MissingDataVendorLoadComplete
    | MissingDataAccountIdLoadComplete
    | MissingDataDeeplinkChange
    | MissingDataUpdateAccountContact
    | MissingDataUpdateAccountContactComplete
    | RequestFailedMissingVendorData
