<div class="layers-container note-info-1">
  <div class="select-sub-container">
    <div class="select-label-container ">
      <label>Date Range</label>
    </div>
    <div class="select-dropdown-container">
      <ng-select
        [items]="selectDropdownItems"
        bindLabel="name"
        bindValue="id"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="dropdownSelectedValue"
        (change)="dropdownChanged()">
      </ng-select>
    </div>
    <!-- <div class="custom-range-container" *ngIf="customRange">
      <label class="from-label">From</label>
      <mi-date-picker
        (dateChange)="onFromChange($event)"
        [placeholder]="filterFromValue ? filterFromValue : 'MM/DD/YYYY'">
      </mi-date-picker>
      <label class="to-label">To</label>
      <mi-date-picker
        (dateChange)="onToChange($event)"
        [placeholder]="filterToValue ? filterToValue : 'MM/DD/YYYY'">
      </mi-date-picker>
      <button mi-button [disabled]="filterDisabled" (click)="filterData()">Filter</button>
    </div> -->
  </div>
  <!-- <div class="layers-sub-container">
    <div class="layer-header">
      <label>Layers</label>
    </div>
    <div class="layer-toggle">
      <mi-toggle toggleTitle="Cost change"
                        [titleOnRight]="true"
                        [showToggleText]="false"
                        [selected]="costChangeSelected"
                        [sliderColor]="'purple'"
                        (onChange)="onToggleNotification($event,'cost_change')"></mi-toggle>
    </div>
    <div class="layer-toggle">
      <mi-toggle toggleTitle="Owner change"
                        [titleOnRight]="true"
                        [showToggleText]="false"
                        [selected]="ownerChangeSelected"
                        [sliderColor]="'orange'"
                        (onChange)="onToggleNotification($event,'owner_change')"></mi-toggle>
    </div>
    <div class="layer-toggle">
      <mi-toggle toggleTitle="Subscriber change"
                        [titleOnRight]="true"
                        [showToggleText]="false"
                        [selected]="subscriberChangeSelected"
                        [sliderColor]="'apple-green'"
                        (onChange)="onToggleNotification($event,'subscriber_change')"></mi-toggle>
    </div>
    <div class="layer-toggle">
      <mi-toggle toggleTitle="Product change"
                        [titleOnRight]="true"
                        [showToggleText]="false"
                        [selected]="productChangeSelected"
                        [sliderColor]="'red'"
                        (onChange)="onToggleNotification($event,'product_change')"></mi-toggle>
    </div>
  </div> -->
</div>

<!-- <div class="additional-info-container">
  <div class="additional-info-sub-container" *ngFor="let field of additionalInfoFields">
    <div class="additional-info-label-container">
      <label>{{field.label}}</label>
    </div>
    <div class="additional-info-value-container">
      <label>{{field.value}}</label>
    </div>
  </div>
</div> -->

<div class="additional-table-container card-1">
  <mi-reverse-table [columns]="columns" [rows]="rows" [dataLayersSelected]="dataLayersSelected"
    [toggleDataLayerTrigger]="toggleDataLayerTrigger" [changeDataTrigger]="changeDataTrigger">
  </mi-reverse-table>
</div>

<div class="spacer"></div>