export enum TypeReferenceEnum {
  ACCOUNT_IDS = 'AccountIds',
  CONTACTS = 'Contacts',
  LOCATIONS = 'Locations',
  PRODUCTS = 'Products',
  SERVICES = 'MiServices',
  PARENT_SERVICES = 'MiParentServices',
  VENDORS = 'Vendors',
  ADDRESSES = 'Addresses',
  EQUIPMENT = 'Equipment',
  SERVICE_GROUPS = 'MiServiceGroups',
  SUBSCRIPTIONS = 'Subscriptions'
}

export function switchTypeReferenceEnum(friendlyName: string): TypeReferenceEnum {

  let ret: TypeReferenceEnum;

  switch (friendlyName) {
    case 'account-id':
    case 'account-ids':
      ret = TypeReferenceEnum.ACCOUNT_IDS;
      break;
    case 'contact':
    case 'contacts':
      ret = TypeReferenceEnum.CONTACTS;
      break;
    case 'location':
    case 'locations':
      ret = TypeReferenceEnum.LOCATIONS;
      break;
    case 'address':
    case 'addresses':
      ret = TypeReferenceEnum.ADDRESSES;
      break;
    case 'product':
    case 'products':
      ret = TypeReferenceEnum.PRODUCTS;
      break;
    case 'service':
    case 'services':
      ret = TypeReferenceEnum.SERVICES;
      break;
    case 'parent-service':
    case 'parent-services':
      ret = TypeReferenceEnum.PARENT_SERVICES;
      break;
    case 'service-group':
    case 'service-groups':
      ret = TypeReferenceEnum.SERVICE_GROUPS;
      break;
    case 'vendor':
    case 'vendors':
      ret = TypeReferenceEnum.VENDORS;
      break;
    case 'equipment':
      ret = TypeReferenceEnum.EQUIPMENT;
      break;
    case 'subscriptions':
      ret = TypeReferenceEnum.SUBSCRIPTIONS;
      break;
  }

  return ret;
}

export function slugFromTypeReferenceEnum(typeReference: TypeReferenceEnum): string {

  let ret: string;

  switch (typeReference) {
    case  TypeReferenceEnum.ACCOUNT_IDS:
      ret = 'account-ids';
      break;
    case TypeReferenceEnum.CONTACTS:
      ret = 'contacts';
      break;
    case TypeReferenceEnum.LOCATIONS:
      ret = 'locations';
      break;
    case TypeReferenceEnum.PRODUCTS:
      ret = 'products';
      break;
    case TypeReferenceEnum.SERVICES:
      ret = 'services';
      break;
    case TypeReferenceEnum.PARENT_SERVICES:
      ret = 'parent-services';
      break;
    case TypeReferenceEnum.SERVICE_GROUPS:
      ret = 'service-groups';
      break;
    case TypeReferenceEnum.VENDORS:
      ret = 'vendors';
      break;
    case TypeReferenceEnum.ADDRESSES:
      ret = 'addresses';
      break;
    case TypeReferenceEnum.EQUIPMENT:
      ret = 'equipment';
      break;

  }

  return ret;
}
