import {Component, Input, OnInit} from '@angular/core';
import {LocationV2} from '../../../libs/api2/types/LocationV2.interface';
import {AddressV2Service} from '../../../libs/api2/address-v2/address-v2.service';

@Component({
  selector: 'mi-simple-location',
  templateUrl: './simple-location.component.html',
  styleUrls: ['./simple-location.component.scss']
})

export class SimpleLocationComponent implements OnInit {

  @Input() location: LocationV2;
  @Input() id: string;
  @Input() showMap: boolean = true;
  @Input() compact: boolean = false;

  readonly MAP_W: number = 380;
  readonly MAP_H: number = 240;
  loaded: boolean = false;
  locationLabel: string="";

  error:any;

  constructor(
    private tenantAddressV2Service: AddressV2Service
  ) {
  }

  ngOnInit() {
    if (!this.location) {
      // we need to load data.
      this.tenantAddressV2Service.getLocationById(this.id)
        .subscribe(
          (location) => {
            this.location = location;
            this.setLocationLabel();
            this.loaded = true;
          }, (e) => this.error = e
        );
    } else {
      this.setLocationLabel();
      this.loaded = true;
    }
  }

  setLocationLabel(){
    if(this.location.label === "Default" ){
      if(this.location.nick_name){
        this.locationLabel = `${this.location.nick_name}, `;
      }
    }
    else if(this.location.nick_name){
      this.locationLabel = `${this.location.label} (${this.location.nick_name}), `;
    }
    else{
      this.locationLabel = `${this.location.label}, `;
    }
  }
}
