import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TenantComponentsModule} from "../../../components/tenant-components.module";
import {SharedModule} from "../../../../shared/shared.module";
import {LocationInstanceWrapComponent} from "./location-instance-wrap.component";
import {LocationDetailComponent} from "./detail/location-details.component";
import {LocationSearchEventsComponent} from "./search-events/location-search-events.component";
import {LocationSearchMiServicesComponent} from "./search-mi-services/location-search-mi-services.component";
import {LocationsEditComponent} from "./edit/locations-edit.component";
import {LocationDeleteComponent} from './delete/location-delete.component';
import {LocationSearchEquipmentComponent} from './search-equipment/location-search-equipment.component';

@NgModule({
  declarations: [
    LocationInstanceWrapComponent,
    LocationDetailComponent,
    LocationSearchEventsComponent,
    LocationSearchMiServicesComponent,
    LocationsEditComponent,
    LocationDeleteComponent,
    LocationSearchEquipmentComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule
  ],
  providers: [],
  exports: [
    LocationInstanceWrapComponent,
    LocationDetailComponent,
    LocationSearchEventsComponent,
    LocationSearchMiServicesComponent,
    LocationsEditComponent,
    LocationDeleteComponent,
    LocationSearchEquipmentComponent
  ]
})
export class LocationInstanceWrapModule {
}
