import {Component, Input, Output, EventEmitter} from "@angular/core";

import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";
import {createLogger, LOG_LEVELS} from "../../../logger";

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { lessThenValidator } from "../../../shared.validators";

const log = createLogger(LOG_LEVELS.COMPONENT);


@Component({
  selector: 'mi-advanced-search-filter-number',
  templateUrl: './advanced-search-filter-number.component.html',
  styleUrls: ['./advanced-search-filter-number.component.scss']
})

export class AdvancedSearchFilterNumberComponent extends AbstractPageComponent {


  @Input() title: string = 'Number Filter';
  @Input() filter_name: string='Number';
  @Input() min_label: string='Min';
  @Input() max_label: string='Max';
  @Input() min_placeholder: string='Min';
  @Input() max_placeholder: string='Max';
  @Input() filter_button_label: string='Apply';
  @Output() filterApplied: EventEmitter<void> = new EventEmitter<void>();

  min: number;
  max: number;
  maxname:string=this.filter_name+"_max";
  minname:string=this.filter_name+"_min";
  form: FormGroup = null;
  isVisible:boolean=true;

  constructor(private formBuilder: FormBuilder,
              private store: Store<ASReducer.State>) {
    super();
  }


  ngOnInit() {

    //get the range control names
    this.maxname = this.filter_name+"_max";
    this.minname =this.filter_name+"_min";

    //create range controls
    let formgroup = {};
    formgroup[this.maxname]=[null];
    formgroup[this.minname]=[null];
    this.form = this.formBuilder.group(formgroup, {
      validator: lessThenValidator(this.minname, this.maxname)
    });

    //fill range controls from store
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe((asData) => {

        if (asData.resultsLoaded) {
          let formgroupdata = {};
          formgroupdata[this.maxname]=asData.results.meta.params[this.maxname]||null;
          formgroupdata[this.minname]=asData.results.meta.params[this.minname]||null;
          this.form.setValue({...formgroupdata});
        }

        if(asData.searchType !=='SERVICE' && asData.searchType !=='EVENT'){
          this.isVisible = asData.visibleFilters[this.filter_name];
        }
      });
      
  }

  onFormSubmit(data) {

    //get data
    let formgroupdata = {};
    formgroupdata[this.maxname]=data[this.maxname];
    formgroupdata[this.minname]=data[this.minname];
  
    //update store
    this.store.dispatch(new ASActions.AsFilterUpdate({...formgroupdata}));
    this.filterApplied.emit();
  }

}
