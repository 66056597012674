import {MiServiceEvent} from "../models/MiServiceEvent";

import {
  EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_RE_TERM,
  EVENT_TYPE_REPLACE,
  SERVICE_CURRENT,
  SERVICE_ARCHIVED,
  SERVICE_UNKNOWN
} from '../../common/utils/sharedConstants';


export enum NOTIFICATION_SEVERITY {
  LOW = 'LOW',
  WARNING = 'WARNING',
  ALERT = 'ALERT',
  ARCHIVED = 'ARCHIVED'
};

export const getNotificationSeverity = function (notification: MiServiceEvent): NOTIFICATION_SEVERITY {

  let severity = NOTIFICATION_SEVERITY.ARCHIVED;

  if(notification && notification.status){
    switch (notification.status) {
      case SERVICE_UNKNOWN:
        severity = NOTIFICATION_SEVERITY.ALERT;
        break;
      case SERVICE_ARCHIVED:
        severity = NOTIFICATION_SEVERITY.ARCHIVED;
        break;
      default:
        severity = calculateActiveSeverity(notification);
    }
  }

  return severity;
};

function calculateActiveSeverity(notification: MiServiceEvent): NOTIFICATION_SEVERITY {

  let severity = null;

  switch (notification.notification_event_type) {

    case EVENT_TYPE_RE_TERM:
      severity = NOTIFICATION_SEVERITY.LOW;
      break;
    case EVENT_TYPE_ACTIVATION:
      if(notification.notification_days_until_event <= 0) {
        severity = NOTIFICATION_SEVERITY.ALERT;
      }else if(notification.notification_days_until_event === 1){
        severity = NOTIFICATION_SEVERITY.WARNING;
      }else{
        severity = NOTIFICATION_SEVERITY.LOW;
      }
      break;
    case EVENT_TYPE_AUTO_CANCELLATION:
    case EVENT_TYPE_CANCELLATION:
      if (notification.notification_days_until_event > 60) {
        severity = NOTIFICATION_SEVERITY.LOW;
      } else if (notification.notification_days_until_event >= 30 && notification.notification_days_until_event <= 60) {
        severity = NOTIFICATION_SEVERITY.WARNING;
      }else{
        severity = NOTIFICATION_SEVERITY.ALERT;
      }
      break;
    default:
      if (notification.notification_days_until_event > 60) {
        severity = NOTIFICATION_SEVERITY.LOW;
      } else if (notification.notification_days_until_event >= 30 && notification.notification_days_until_event <= 60) {
        severity = NOTIFICATION_SEVERITY.WARNING;
      } else if (notification.notification_days_until_event < 30) {
        severity = NOTIFICATION_SEVERITY.ALERT;
      }

  }

  return severity;

}
