<ng-container>

  <mi-server-error [error]="error"></mi-server-error>

  <ng-container *ngIf="loaded; else loading">

    <div class="sections">

      <section class="data-section">

        <h2>Details</h2>

        <dl *ngIf="associatedDocument" class="section-details">
          <div>
            <dt>Vendor</dt>
            <dd>
              <mi-id-link
                [id]="vendor.display_id"
                title="{{vendor.display_id}} - {{vendor.vendor.vendor_name}}">

              </mi-id-link>
              {{vendor.vendor.vendor_name}}
            </dd>
          </div>
          <div>
            <dt>Account Number</dt>
            <dd >{{associatedDocument.account_number}}</dd>
          </div>
          <div>
            <dt>Document Name</dt>
            <dd>{{associatedDocument.name}}</dd>
          </div>
          <div>
            <dt>{{(associatedDocument.is_url ? 'Link' : 'File Name')}}</dt>
            <dd>
                <a *ngIf="associatedDocument.is_v1"href="{{getV1FileUrl(associatedDocument)}}" target="_blank">{{ shortenFileName(associatedDocument.file_name) }}</a>
                <a *ngIf="!associatedDocument.is_v1&&!associatedDocument.is_url" (click)="downloadDocument(associatedDocument.id)">{{ shortenFileName(associatedDocument.file_name) }}</a>
                <a *ngIf="!associatedDocument.is_v1&&associatedDocument.is_url" [href]="associatedDocument.file_name" target="_blank">{{ shortenFileName(associatedDocument.file_name) }}</a>
            </dd>
          </div>
          <div>
              <dt>File Type</dt>
              <dd >{{ 'UPLOAD_DOC_TYPES.' + DOC_TYPES[associatedDocument.type] | translate }}</dd>
          </div>          
          <div>
              <dt>File Uploaded</dt>
              <dd >{{convertTimeZone(associatedDocument.created_on_utc) | date:'short' }}</dd>
          </div>
          <div>
              <dt>Uploaded By</dt>
              <dd >{{associatedDocument.created_by_user_name}}</dd>
          </div>
          <div>
              <dt>Division/Team</dt>
              <dd >{{associatedDocument.business_unit_name}}</dd>
          </div>
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.INVOICE">
            <div>
                <dt>Invoice Date</dt>
                <dd >{{associatedDocument.invoice_date}}</dd>
            </div>
            <div>
              <dt>Invoice Number</dt>
              <dd >{{associatedDocument.invoice_number}}</dd>
            </div>
            <div>
              <dt>Invoice From Date</dt>
              <dd >{{associatedDocument.invoice_from_date}}</dd>
            </div>
            <div>
              <dt>Invoice To Date</dt>
              <dd >{{associatedDocument.invoice_to_date}}</dd>
            </div>          
            <div>
              <dt>Invoice Value</dt>
              <dd >{{associatedDocument.invoice_value | currency:(associatedDocument.invoice_tenant_currency_code||'USD'):'symbol':'1.2-2'}}</dd>
            </div>
            <div>
              <dt>Invoice Currency</dt>
              <dd >{{(associatedDocument.invoice_tenant_currency_code||'USD')}}</dd>
            </div>
            <div>
              <dt>Invoice Status</dt>
              <dd >{{(associatedDocument.invoice_status)}}</dd>
            </div>
          </ng-container>
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.RECEIPT">
            <div>
                <dt>Receipt Date</dt>
                <dd >{{associatedDocument.receipt_date}}</dd>
            </div>
            <div>
              <dt>Receipt Number</dt>
              <dd >{{associatedDocument.receipt_number}}</dd>
            </div>
            <div>
              <dt>Receipt From Date</dt>
              <dd >{{associatedDocument.receipt_from_date}}</dd>
            </div>
            <div>
              <dt>Receipt To Date</dt>
              <dd >{{associatedDocument.receipt_to_date}}</dd>
            </div>          
            <div>
              <dt>Receipt Value</dt>
              <dd >{{associatedDocument.receipt_value | currency:(associatedDocument.receipt_tenant_currency_code||'USD'):'symbol':'1.2-2'}}</dd>
            </div>
            <div>
              <dt>Receipt Currency</dt>
              <dd >{{(associatedDocument.receipt_tenant_currency_code||'USD')}}</dd>
            </div>
          </ng-container>
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.SERVICE_ORDER">
            <div>
                <dt>Order Date</dt>
                <dd >{{associatedDocument.order_date}}</dd>
            </div>
            <div>
              <dt>Order Number</dt>
              <dd >{{associatedDocument.order_number}}</dd>
            </div>
          </ng-container>
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.OTHER">
            <div>
                <dt>Other Date</dt>
                <dd >{{associatedDocument.other_date}}</dd>
            </div>
          </ng-container>
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.MASTER_AGREEMENT">
            <div>
                <dt>Agreement Date</dt>
                <dd >{{associatedDocument.agreement_date}}</dd>
            </div>
          </ng-container>
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.AMENDMENT">
            <div>
                <dt>Amendment Date</dt>
                <dd >{{associatedDocument.amendment_date}}</dd>
            </div>
          </ng-container> 
          <ng-container *ngIf="associatedDocument.type==DOC_TYPES.NDA">
            <div>
                <dt>NDA Date</dt>
                <dd >{{associatedDocument.nda_date}}</dd>
            </div>
          </ng-container>                                                                  
    </dl>
      </section>
      <div class="button-container">
        <a mi-button *ngIf="associatedDocument.is_v1"
          buttonTheme="dark-theme"
          href="{{getV1FileUrl(associatedDocument)}}" 
          target="_blank">
          {{'Download'}}
        </a>
        <a mi-button *ngIf="!associatedDocument.is_v1&&associatedDocument.is_url"
          buttonTheme="dark-theme"
          href="{{associatedDocument.file_name}}" 
          target="_blank">
          {{'Open Link'}}
        </a>
        <a mi-button *ngIf="!associatedDocument.is_v1&&!associatedDocument.is_url"
          buttonTheme="dark-theme"
          (click)="downloadDocument(associatedDocument.id)">
          {{'Download'}}
        </a>
      </div>
    </div>

  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</ng-container>
