import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";
import { loadStripe } from "@stripe/stripe-js";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: './add-payment-method-modal.component.html',
  styleUrls: ['./add-payment-method-modal.component.scss']
})

export class AddPaymentMethodModalComponent implements OnInit {

  //error: string = "";
  title: string;
  addText: string;
  stripe: any;
  radarSessionId: any;
  elements: any;
  payment: any;
  cardValid: boolean = false;
  paymentMethodId: string;
  loading: boolean = false;
  setNewPaymentAsDefault: boolean = false;
  defaultLocked: boolean;

  constructor(
    private modal: ModalRef
  ) {
  }

  ngOnInit(): void {
    this.initializeStripe();
  }

  onModalClose() {
    this.modal.close({reason: 'User closed', result: false});
  }

  addPaymentMethod() {
    console.log("addPaymentMethod", this.paymentMethodId);
    this.modal.close({reason: 'User closed', result: true, paymentMethodId: this.paymentMethodId, setNewPaymentAsDefault: this.setNewPaymentAsDefault});
  }

  async initializeStripe() {
    this.stripe = await loadStripe(environment.STRIPE_PUBLISHABLE_KEY);
    console.log("stripe", this.stripe);

    let billingAmount = 100;
    
    this.stripe.createRadarSession().then(result => {
      console.log("createRadarSession result", result);
      this.radarSessionId = result.radarSession.id;
    });
    
    this.elements = this.stripe.elements(
      {
        mode: 'payment',
        currency: 'usd',
        amount: billingAmount,
        setupFutureUsage: 'off_session',
        captureMethod: 'manual',
        paymentMethodTypes: ['card'],
        paymentMethodCreation: 'manual'
      }
    );

    this.createStripePaymentContainer();
    //this.dataLoaded = true;
  }

  createStripePaymentContainer(): void {
    if(!this.payment) {
      console.log("payment not found");
      this.payment = this.elements.create('payment');
    }    
    this.payment.mount("#add-payment-container");

    this.payment.on("change", $event => {
      //console.log("payment change event", $event);
      if($event.complete == true) {
        this.cardValid = true;
      }
      else {
        this.cardValid = false;
      }
    });
  }

  createPaymentMethod(): void {
    this.loading = true;
    
    this.elements.submit();

    this.stripe.createPaymentMethod({
      elements: this.elements
    })
    .then(result => {
      console.log("createPaymentMethod result", result);
      this.paymentMethodId = result.paymentMethod.id;

      this.addPaymentMethod();
    });
  }

  toggleDefaultPaymentSelection($event): void {
    if($event.target.checked) {
      this.setNewPaymentAsDefault = true;
    }
    else {
      this.setNewPaymentAsDefault = false;
    }
  }
}