import {Component, Input, OnInit} from "@angular/core";
import {TenantLocationService} from "../../../libs/api/tenant/tenantLocation.service";
import {Vendor} from "../../../shared/models/Vendor";
import {TenantVendors} from "../../../libs/api/tenant/tenantVendors";

@Component({
  selector: 'mi-simple-vendor',
  templateUrl: './simple-vendor.component.html',
  styleUrls: ['./simple-vendor.component.scss']
})

export class SimpleVendorComponent implements OnInit {

  @Input() vendor: Vendor;
  @Input() id: string;
  error:any;

  constructor(private tenantVendorsService: TenantVendors){

  }

  ngOnInit() {
    if(!this.vendor) {
      // we need to load data.
      this.tenantVendorsService.getVendorByDisplayId(this.id)
        .then((vendor) => this.vendor = vendor)
        .catch(e => this.error = e);
    }
  }
}
