import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import { CanDelete } from "../../../../../shared/models/CanDelete";
import { PageAlertAddAction } from "../../../../../shared/state/page-alert/actions";
import { Store } from "@ngrx/store";
import * as ASReducer from '../../../../../shared/state/advanced-search/reducers';
import { TenantAssociatedDocumentsService } from "src/libs/api/tenant/tenantAssociatedDocument.service";
import { AssociatedDocument } from "src/shared/models/AssociatedDocument";
import { TenantDocumentManagementService } from 'src/libs/api/tenant/tenantDocumentManagement.service';
import { Document } from 'src/libs/api2/types/Document.interface';

@Component({
  templateUrl: './associated-documents-delete.component.html',
  styleUrls: ['./associated-documents-delete.component.scss']
})

export class AssociatedDocDeleteComponent extends AbstractPageComponent implements OnInit {

  assoDocId: string;
  error: string;
  exitRoute: string;
  canDelete: any;
  canDeleteMode: boolean = true;
  loaded:boolean = true;
  associatedDocument: Document;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantAssociatedDocumentsService: TenantAssociatedDocumentsService,
              private tenantDocumentsService: TenantDocumentManagementService,
              private router: Router,
              private store: Store<ASReducer.State>) {
    super();
  }

  ngOnInit() {
    this.assoDocId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    this.exitRoute = `/associated-documents/${this.assoDocId}/edit`;

    this.tenantDocumentsService.getDocumentById(this.assoDocId).toPromise()
      .then((res: Document) => {
        this.associatedDocument = res;
        return this.tenantAssociatedDocumentsService.canDelete(res.id).toPromise()})
      .then((res: CanDelete) => {
        this.canDelete = res;
        if(res.dependents.length){
          this.canDeleteMode = false;
        }
      })
      .catch(e => this.error = e)
  }

  onCancel() {
    this.router.navigate([this.exitRoute]);
  }

  permanentlyDelete(){
    this.tenantDocumentsService.delete( this.associatedDocument.id, [this.associatedDocument.id] )
    .subscribe(() =>{
      this.loaded = false;
      this.store.dispatch(
        new PageAlertAddAction({
          message: `Associated Document was successfully deleted.`,
          alertType: "SUCCESS"
        })
      );
        setTimeout(()=>{
          this.router.navigate([`/associated-documents`]);
        }, 1000);
      },
      e=> this.error = e
    )
  }

}
