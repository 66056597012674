<mi-page-header
  title="{{ 'TENANT.LOCATION_DETAIL_TITLE' | translate:{display_id: ''} }}"
  [breadcrumbs]="breadcrumbs"
  *ngIf="address">
  <ng-container>
    <a href="{{supportLink}}" class="link--support" target="_blank">Get MISO Support</a>
  </ng-container>
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<div class="details-header-nav">
  <div class="details-header-nav__graphic">

    <mi-static-map
      [address]="address"
      [width]="300"
      [height]="200">
    </mi-static-map>

  </div>

  <div class="details-header-nav__info">

    <mi-details-header-nav
      [equipmentCount]="equipmentCount"
      [data]="detailHeaderNavData">
    </mi-details-header-nav>

    <mi-detail-alert [display_id]="display_id" [self_alert]="true" ></mi-detail-alert>

  </div>

</div>

<router-outlet></router-outlet>