import { ToastrService } from 'ngx-toastr';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmEOTDeleteModalComponent } from '../confirm-eot-delete-modal/confirm-eot-delete-modal.component';
import { ModalService } from '@independer/ng-modal';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { EditVendorEndOfTermModal } from '../edit-vendor-end-of-term-modal/edit-vendor-end-of-term-modal.component';

@Component({
  selector: 'action-vendor-accounts-end-of-term',
  template: `
  <div  *ngIf="rowData.vendorTerm.service_term_event_type !== null">
  <button class="btn btn-sm btn-danger text-white" (click)="confirmDeleteHandler(rowData)">Remove</button>
</div>
<div  *ngIf="rowData.vendorTerm.service_term_event_type === null">
<button class="btn btn-sm btn-primary" (click)="editVendorEOTClickHandler(rowData)">Update</button>
</div>
`,
})

export class ActionVendorAccountEndOfTermComponent {
  @Input() rowData: any;
  @Output() customEvent: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private serviceTermService: ServiceTermService,
    private toastr: ToastrService,
    private modalService: ModalService,
  ) { }

  confirmDeleteHandler(vendor: any) {
    const modalRef = this.modalService.open(ConfirmEOTDeleteModalComponent, m => {
      m.modalTitle = vendor.vendor_name
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      console.log("here result", result)
      // return
      if (result[0]) {
        const payload = {
          accountid_id: vendor.vendorTerm.id,
          is_deleted: true,
          service_id: null,
          service_display_id: null,
          service_term_id: null,
          tenant_vendor_id: this.rowData.vendor_id,
        }

        this.serviceTermService.updateTenantServiceTerms(payload)
          .toPromise()
          .then((data: any) => {
            this.customEvent.emit();
            setTimeout(() => {
              this.toastr.success(`${vendor.vendor_name} terms removed successfully`);
            }, 3000)
          })
          .catch((e) => console.warn(e));
      }
    });

  }

  editVendorEOTClickHandler(rowData: any) {
    console.log("here tenantVendor", rowData)
    const modalRef = this.modalService.open(EditVendorEndOfTermModal, modalRef => {
      modalRef.selectedVendor = rowData.vendor_name + ' ' + rowData.vendor_dsp_id
      modalRef.tenantServiceTerms = rowData.tenantServiceTerms
      modalRef.showEOTList = false;
    });

    modalRef.closed.subscribe(({ reason, result = [] }) => {
      if (result) {
        console.log("here result", result)

        const payload = {
          tenant_vendor_id: this.rowData.vendor_id,
          service_term_id: result[0].data.id
        }

        console.log("here payload", payload)
        this.serviceTermService
          .updateTenantServiceTerms(payload)
          .toPromise()
          .then((data: any) => {
            this.customEvent.emit();
            this.toastr.success(`${rowData.vendor_name} term updated successfully`);
          })
          .catch((e) => console.warn(e))
      }
    });
  }

}