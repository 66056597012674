import {Component, OnInit} from '@angular/core';
import {OrderState, OrderProgression, OrderSteps} from '../../state/reducer';
import {Store} from '@ngrx/store';
import * as OrderReducers from '../../state/reducer';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {TenantMiOrderService} from '../../../../../../../libs/api/tenant/tenantMiOrder.service';
import {MiOrder} from '../../../../../../../shared/models/MiOrder';
import {Router} from '@angular/router';

@Component({
  selector: 'mi-service-order-add-order',
  styles: ['.button-container { margin: 0 0 40px; }'],
  templateUrl: './service-order-add-order.component.html'
})
export class ServiceOrderAddOrderComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  error: string;
  index: number;
  orderState: OrderState;
  step: OrderSteps = OrderSteps.AddOrder;

  constructor(
    private store: Store<OrderState>,
    private orderService: TenantMiOrderService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {

    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state) => {
          this.orderState = state as OrderState;
          // Find the index belonging to the state's current step:
          this.index = OrderProgression.findIndex((a) => {
            return a === this.step;
          });
          // Switch whether we're active or not:
          this.activeIndex = this.index === this.orderState.index;
        },
        (e) => {
          this.error = e;
        });
  }

  onAddOrder(): void {

    const order: MiOrder = {
      vendor: this.orderState.vendor,
      order_id: this.orderState.order_id,
      order_date: this.orderState.order_date,
      account_id: this.orderState.account_id,
      associated_documents: this.orderState.associated_documents,
      requestor: this.orderState.requestor,
      custom_data: this.orderState.custom_data
    };

    this.orderService.createOrder(order)
      .toPromise()
      .then((order: MiOrder) => {
        this.router.navigateByUrl(`/services/orders/summary/${order.display_id}`);
      })
      .catch((e) => {
        this.error = e;
      });
  }

}
