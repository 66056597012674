import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {SelectContactModalComponent} from "../../components/select-contact-modal/select-contact-modal.component";
import {ModalService, ModalRef} from "@independer/ng-modal";
import {AccountId} from "../../../shared/models/AccountId";
import {takeUntil} from "rxjs/operators";
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Contact, ServiceAssociationType } from '../../../shared/models/Contact';
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";
import { CustomField } from '../../../shared/models/CustomField';
import { TenantCustomFieldService } from '../../../libs/api/tenant/tenantCustomFields.service';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { VendorContact } from 'src/libs/api2/types/VendorContact.interface';
import { TenantAccountIdService } from '../../../libs/api2/account-id-v2/account-id-v2.service';

@Component({
  selector: 'mi-manage-service-contacts',
  templateUrl: './manage-service-contacts.component.html',
  styleUrls: ['./manage-service-contacts.component.scss']
})

export class ManageServiceContactsComponent extends AbstractPageComponent implements OnInit {

  @Input() account_id: AccountId;
  @Input() custom_data: any[];
  @Input() hideCancel: boolean;

  @Input() vendorName: string;

  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() serviceContactsComplete: EventEmitter<any> = new EventEmitter<any>();

  contactForm: FormGroup;
  contacts_custom_fields: CustomField[] = [];
  error: any;
  loaded: boolean = false;
  accountrep: VendorContact;

  readonly NO_DATA: string = NO_DATA;
  service_contacts: any = {};

  constructor(private modalService: ModalService,
    private formBuilder: FormBuilder,
    private tenantCustomFieldsService: TenantCustomFieldService,
    private tenantAccountIdService: TenantAccountIdService,
    private contactV2Service: ContactV2Service) {
    super();
  }

  ngOnInit() {
    /* everything below this we may want to move to a render-er */
    this.tenantCustomFieldsService.listCustomFields()
      .toPromise()
      .then(res => {
        if(res)
          this.contacts_custom_fields = res.filter(c => c.type == 'CONTACT');
        this.service_contacts = { custom_data: [] };
        this.contacts_custom_fields.map(cd =>
          {
            this.service_contacts.custom_data.push({value: [], custom_field: cd});
          });
        this.loadAccountIdRep();
      })
      .catch(e => this.error = e);
  }

  loadAccountIdRep(){
    if(this.account_id && this.account_id.contact_id){
      this.contactV2Service.getContactById(this.account_id.contact_id)
      .toPromise()
      .then(contact => {
        this.accountrep=contact;
        this.initForm();
        this.loaded = true;
      });
      

    }
    else{
      this.initForm();
      this.loaded = true;
    }
  }

  initForm(){
    this.contactForm =  this.formBuilder.group({
      contacts: this.formBuilder.array(this.initContacts())
    });
  }

  initContacts(): FormGroup[]{
    let formGroup: FormGroup[]=[];
    if(this.account_id){
      formGroup.push(this.addContact(this.accountrep, ServiceAssociationType.ACCOUNT_REP));
    }

    if(this.custom_data && this.custom_data.length){
      this.custom_data.map(cd => {
        if(cd.custom_field.type == "CONTACT"){
          if(!Array.isArray(cd.value)){
            cd.value=[];
          }
          if(cd.value && cd.value.length){
            cd.value.map(c => {
              formGroup.push(this.addContact(c, ServiceAssociationType.ADDITIONAL_CONTACT, cd.custom_field));
            });
          }
        }
        else{
          //push non contact custom fiels
          this.service_contacts.custom_data.push(cd);
        }
      });
    }
    return formGroup;
  }

  addContact(contact: VendorContact, association_type: ServiceAssociationType, custom_field: any = null){
    return this.formBuilder.group({
      contact: [contact],
      association_type: [association_type],
      custom_field: association_type == ServiceAssociationType.ADDITIONAL_CONTACT ? [custom_field, Validators.required] : [null]
    });
  }

  deleteContact(association_type: ServiceAssociationType, row_index: number){
      const control = <FormArray>this.contactForm.controls['contacts'];
      control.removeAt(row_index);
  }

  removeAccountRep(association_type: ServiceAssociationType, row_index: number){
    const control = <FormArray>this.contactForm.controls['contacts'];

    if(association_type === 'Acc Rep'){
      this.loaded = false;
      this.account_id.contact_id = null;
      return this.tenantAccountIdService.update(this.account_id, this.account_id.id)
          .toPromise()
          .then(res=>{
            this.account_id = res;
            (<FormGroup>control.controls[row_index]).controls['contact'].setValue(res.contact)
            this.loaded = true;
          })
    }
    control.removeAt(row_index);
}

  openAddContactModal(association_type: ServiceAssociationType, row_index: number){
    this.openContactModal(association_type == ServiceAssociationType.ACCOUNT_REP ? "Select Account Rep" : "Select Contact", association_type == ServiceAssociationType.ACCOUNT_REP)
    .closed
    .pipe(takeUntil(this.destroy$))
    .subscribe(args => {
      this.loaded = false;
      if(args.result && args.result.length && args.result[0].contact) {
        const control = <FormArray>this.contactForm.controls['contacts'];

        if(association_type == ServiceAssociationType.ACCOUNT_REP){
          this.account_id.contact_id=args.result[0].contact.id;
          this.tenantAccountIdService.update(this.account_id, this.account_id._id)
          .toPromise()
          .then(res=>{
            this.loaded = true;
            return (<FormGroup>control.controls[row_index]).controls['contact'].setValue(args.result[0].contact)
          });
        }
        else{
          control.push(this.addContact(args.result[0].contact, ServiceAssociationType.ADDITIONAL_CONTACT, this.contacts_custom_fields[0]));
          this.loaded = true;
        }
      }
      else{
        this.loaded = true;
      }
    });
  }

  openContactModal(title, showVendorTab): ModalRef<SelectContactModalComponent> {
    return this.modalService.open(SelectContactModalComponent, m => {
      m.data = {};
      m.showVendorTab = showVendorTab;
      m.title = title;
      m.vendorName = this.vendorName;
    });
  }

  onCancel($event) {
    $event.preventDefault();
    this.canceled.emit();
  }

  onSaveContacts(value){
    if (value && Array.isArray(value.contacts)) {
      value.contacts.forEach(newContact => {
        if (newContact.association_type === "Additional Contact") {

          if (newContact.contact && newContact.custom_field) {
            let custom_field = this.service_contacts.custom_data.find(cd => cd && cd.custom_field._id.toString() === newContact.custom_field._id.toString());
            if (custom_field){
              if(!custom_field.value.some(c => c._id.toString() == newContact.contact._id.toString())){
                custom_field.value.push(newContact.contact);
              }
            }
          }
        }

      })
    }

    this.serviceContactsComplete.emit(this.service_contacts);
  }
}

