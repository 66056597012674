import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CanDelete} from '../../../shared/models/CanDelete';
import {TenantVendorSearch} from 'src/shared/models/Vendor';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { VendorV2Response } from 'src/shared/models/VendorV2';

@Injectable()
export class TenantVendorService {
  private apiUrl = '_MICRO_SERVICE_/vendor';
  private apiUrlSearchTenantVendors = this.apiUrl + '/search/approved';

  constructor(private http: HttpClient) {}

  searchTenantVendors(pageNumber: number = 0, pageSize: number = 20, search: string = ""): Observable<TenantVendorSearch> {
    const params = new HttpParams({ 
      fromObject: { 
        tenantId: FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN,
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
        search: search
      } 
    });
    return this.http
      .get(`${this.apiUrlSearchTenantVendors}`, {params})
      .map((response: any) => {
        //response as TenantVendorSearch
        response.data.map(vendor => {
          vendor.tenant_id = FrontEndSettingsUtil.get().MISO3_TENANT_DOMAIN;
        });
        return response as TenantVendorSearch;
      });
  }

  getVendorById(vendor_id: string): Observable<VendorV2Response> {
    return this.http
      .get(`${this.apiUrl}/vendors/${vendor_id}`)
      .map((response: any) => response as VendorV2Response);
  }

  getVendorOrTenantVendorById(id: string): Observable<VendorV2Response> {
    return this.http
      .get(`${this.apiUrl}/tenants/vendors/${id}?tenantId=${FrontEndSettingsUtil.getDomain()}`)
      .map((response: any) => response as VendorV2Response);
  }
}