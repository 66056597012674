<modal>
  <modal-header>
    <h4>{{title}}</h4>
  </modal-header>
  <modal-content>
    <p>
      {{message}}
    </p>    
  </modal-content>
  <modal-footer>
    <div class="cta">
      <a mi-button buttonTheme="dark-theme" (click)="onCancel()">
        {{closeButtonLabel}}
      </a>
    </div>
  </modal-footer>
</modal>
