import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MiServiceDetails } from "../../../../../../shared/models/MiService";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as SDReducer from "../../state/reducers";
import * as SDActions from "../../state/actions";
import { Store } from "@ngrx/store";
import { EVENT_TYPE_ACTIVATION } from '../../../../../../common/utils/sharedConstants';
import FeDateUtil from "../../../../../../shared/utils/FeDate.util";
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mi-service-details-form-confirm-cancellation-request',
  templateUrl: './confirm-cancellation-form.component.html',
  styleUrls: ['./cancellation-forms.component.scss'],
})

export class ConfirmCancellationFormComponent implements OnInit {

  readonly FORM_CONFIRM: string = '[form] confirm';
  readonly FORM_EDIT: string = '[form] edit';
  readonly EVENT_TYPES: any = {
    EVENT_TYPE_ACTIVATION: EVENT_TYPE_ACTIVATION
  };

  @Input() serviceDetails: MiServiceDetails;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();
  @Output() updateServiceDetailsStore: EventEmitter<any> = new EventEmitter<any>();

  confirmCancellationFormGroup: FormGroup;
  error: string = '';
  mode: string = this.FORM_EDIT;
  cancelDateIsBeforeToday: boolean = false;
  updating: boolean = false;

  constructor(private serviceV2Service: ServiceV2Service,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private store: Store<SDReducer.State>) {
    // Nothing.
  }

  ngOnInit() {
    this.confirmCancellationFormGroup = this.formBuilder.group({
      service_id: this.serviceDetails.general.service_display_id,
      cancellation_date: [FeDateUtil.toFormat(this.serviceDetails.service_data.termination_date, 'MM/dd/yyyy') , Validators.required]
    });
  }

  onDateChange(e): void {
    this.confirmCancellationFormGroup.get('cancellation_date').setValue(e);
    this.cancelDateIsBeforeToday = FeDateUtil.isBeforeToday((new Date(e)));
  }

  onCancelClicked(e): void {
    e.stopPropagation();
    e.preventDefault();
    this.confirmCancellationFormGroup
      .get('cancellation_date')
      .setValue(this.serviceDetails.service_data.termination_date);
    this.canceled.emit();
  }

  isBeforeToday(str: string): boolean {
    const today = new Date();
    const then = new Date(str);
    today.setHours(0, 0, 0, 0);
    return today.getTime() > then.getTime();
  }

  onEditCancellationRequestFormSubmit(data: any) {
    this.updating = true;
    this.error = '';

    const cancellationDate = this.confirmCancellationFormGroup.get('cancellation_date').value;
    this.serviceV2Service
      .servicePlanCancelation(
        this.serviceDetails.service_data.service_id,
        cancellationDate,
      )
      .toPromise()
      .then(res => {
        this.toastr.success(`Service cancellation request add successfully`);
        this.updateServiceDetailsStore.emit()
        this.canceled.emit();
      })
      .catch(e => this.error = e)
      .finally(() => {
        this.updating = false;
      });
  }

  onContinueClick() {
    this.mode = this.FORM_CONFIRM;
  }

  onBackClick() {
    this.mode = this.FORM_EDIT;
  }


}
