<form>

  <mi-select-service-group
    [selectedServiceGroups]="currentServiceGroups"
    (serviceGroupSelected)="onServiceGroupSelected($event)"
    [isDetailPage]="isDetailPage"
    (serviceGroupRemoved)="onServiceGroupRemoved($event)">
  </mi-select-service-group>

  <div class="button-container">

    <a mi-button buttonTheme="light-theme" (click)="onCancel($event)">
      {{cancelCTA}}
    </a>

    <button type="submit" mi-button buttonTheme="dark-theme" (click)="onServiceGroupComplete($event)">
      {{submitCTA}}
    </button>

  </div>

</form>  
