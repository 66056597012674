import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";

@Component({
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})

export class ConfirmationModalComponent extends AbstractPageComponent implements OnInit {
  title: string = "Confirmation";
  isDanger: boolean = false;
  confirmationText: string = "Do you confirm?";
  buttonLabel: string = "Yes";

  constructor(
    private modal: ModalRef
  ) {
    super();
  }

  ngOnInit(): void {
    
  }

  confirm(): void {
    this.modal.close("confirm");
  }

  onModalClose(): void {
    this.modal.close("cancel");
  }
}