import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CanDelete} from '../../../shared/models/CanDelete';
import { TenantUser, TenantUserAccess } from '../types/TenantUser.interface';
import { VendorContact } from '../types/VendorContact.interface';
import { Vendor } from 'src/shared/models/Vendor';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ContactV2Service {
  private apiUrl = '_MICRO_SERVICE_/contact';
  private tenantApiUrl = '_MICRO_SERVICE_/tenant';
  private tenantUrl = '/{tenant}';
  private apirUrlVendorById = '/{tenant}/vendors/id';
  // private apiUrlUpdateContact = '/{tenant}/contacts';
  private apiUrlDeleteContact = '/{tenant}/contacts/delete';

  constructor(private http: HttpClient) {}

  saveContact(contact: VendorContact): Observable<VendorContact> {
    if (contact.tenant_vendor_id == null) {
      contact.tenant_vendor_id = contact.vendor._id
    }
    return this.http
      .post(this.apiUrl + '/contact', contact)
      .map((response: any) => {
        return response
        // return this.updateContact(response);
      });
  }


  // updateContact(contact: VendorContact): Observable<VendorContact> {
  //   return this.http
  //     .put(`${this.apiUrlUpdateContact}/${contact.id}`, contact)
  //     .map((response: any) =>{ 
  //       console.log("here is updateContact response", response)

  //       return contact as VendorContact});
  // }

  getContactsByVendor(tenant_vendor_id: string, q: string = '', page_number: number = 0, size: number = 10): Observable<any> {
    const params = new HttpParams({ fromObject: { tenant_vendor_id, q, page_number: page_number.toString(), size: size.toString() } });
    return this.http.get(`${this.apiUrl}/contacts/`, { params });
  }

  getContactById(id: string): Observable<VendorContact> {
    const data = {
      params: {
        contact_id: id
      }
    };
    return this.http
      .get(this.apiUrl + '/contact', data)
      .switchMap((response: any) => {
        return this.populateVendor(response);
      });
      //.map((response: any) => response as VendorContact);
  }

  populateVendor(contact: VendorContact): Observable<VendorContact> {
    ///fixme check this
    return this.http
      //.get(`${this.apirUrlVendorById}/${contact.vendor_id}`)
      //.get(`${this.apirUrlVendorById}/${contact.tenant_vendor_id}`)
      .get(`${this.apirUrlVendorById}/${contact.vendor_id}`)
      .map((response: any) => {
        contact.vendor=response.data.vendor as Vendor;
        contact.role = contact.function;
        //console.log(contact);
        return contact;
      });
  }

  deleteContact(id: string) {
    return this.http
      .post(this.apiUrl + '/delete-contact', [id])
      .switchMap((response: any) => {
        return this.deleteContactIndex(id);
      });
  }

  deleteContactIndex(id: String) {
    return this.http
      .delete(`${this.apiUrlDeleteContact}/${id}`)
      .map((response: any) => response);
  }

  //for nodejs api
  indexContact(id: string) {
    return this.http
      .put(this.tenantUrl + '/contacts/' + id, {})
      .map((response) => response);
  }

  indexDeleteContact(id: string) {
    return this.http
      .delete(this.tenantUrl + '/contacts/' + id, {})
      .map((response) => response);
  }

  canDeleteContact(id: String): Observable<CanDelete> {
    return this.http
      .get(`${this.tenantUrl}/Contacts/${id}/can-delete`)
      .map((response: any) => response.data as CanDelete);
  }

  saveTenantUser(tenantUser: TenantUser): Observable<TenantUser> {
    return this.http
      .post(this.tenantApiUrl + '/tenant-user', tenantUser)
      .map((response: TenantUser) => {
      //.switchMap((response: any) => {
        //return this.indexTenantUser(response);
        return response;
      });
  }

  getTenantUser(id: string, tenant_id: string = ''): Observable<TenantUser> {
    const data = {
      params: {
        id: id,
        tenant_id: tenant_id
      }
    };
    return this.http
      .get(this.tenantApiUrl + '/tenant-user', data)
      .map((response: any) => response as TenantUser);
  }

  getAllTenantUsers(tenant_id: string): Observable<any> {
    const data = {
      params: {
        id: tenant_id
      }
    };
    return this.http
      .get(this.tenantApiUrl + '/tenant-users', data)
      .map((response: any) => response as any);
  }

  saveTenantUserAccess(tenantUser: TenantUserAccess): Observable<TenantUserAccess> {
    return this.http
      .post(this.tenantApiUrl + '/user-tenant-access', tenantUser)
      /*
      .switchMap((response: any) => {
        return this.indexTenantUser(response);
      });
      */
      .map((response: any) => response as any);
  }

  getTenantUserAccess(id: string): Observable<TenantUserAccess> {
    const data = {
      params: {
        id: id
      }
    };
    return this.http
      .get(this.tenantApiUrl + '/user-tenant-access', data)
      .map((response: any) => response as TenantUserAccess);
  }

  indexTenantUser(tenantUser: any) {
    return this.http
      .put(`${this.tenantUrl}/users/${tenantUser.id}/index`, {})
      .map((response: any) => tenantUser);
  }

  indexVendorContact(contact: any) {
    return this.http
      .put(`${this.tenantUrl}/contacts/${contact.id}/index`, {})
      .map((response: any) => contact);
  }
}
