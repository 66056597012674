import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITenantServiceTerm } from 'src/shared/models/Vendor';
import { ITableAction } from 'src/shared/models/ITableAction';
import { Store, select } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { AbstractPageComponent } from 'src/shared/pages/abstract-page/abstract-page.component';
import { Component, OnInit } from '@angular/core';
import { TenantService } from 'src/libs/api/account/tenant.service';
import { Tenant } from 'src/shared/models/Tenant';
import * as appDataReducer from '../../../../shared/state/app-data/reducers';
import { ModalRef } from '@independer/ng-modal';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';

@Component({
  selector: 'edit-vendor-end-of-term-modal',
  templateUrl: './edit-vendor-end-of-term-modal.component.html'
})

export class EditVendorEndOfTermModal extends AbstractPageComponent implements OnInit {
  selectedVendor: string
  showAddEOTForm: boolean = false
  tenantServiceTerms: ITenantServiceTerm[]
  showEOTList: boolean = true
  source: LocalDataSource = new LocalDataSource();
  sourceData: any[] = []
  addEOTForm: FormGroup
  isAddEOTInProgress: boolean = false
  termsColumnsSettings = {
    pager: {
      display: true,
      perPage: 6
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      custom: [
        {
          name: ITableAction.USER_SELECTION,
          title: 'Select',
        }
      ],
    },
    rowClassFunction: (row) => 'cursor-pointer',
    noDataMessage: 'Loading...',
    columns: {},
  }

  tenantData: Tenant = null;
  isDataLoaded: boolean = true

  constructor(
    public tenantService: TenantService,
    public serviceTermService: ServiceTermService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private store: Store<any>,
    private modal: ModalRef

  ) {
    super();
  }

  ngOnInit(): void {
    this.store.pipe(
      select(appDataReducer.getAppData),
      takeUntil(this.destroy$),
      map((appData) => {
        this.tenantData = appData.tenantData;
        this.initializeTable()


      })
    ).subscribe();

    this.addEOTForm = this.formBuilder.group({
      auto_renew_day: [null, Validators.compose([Validators.required])],
      notification_day: [null, Validators.compose([Validators.required])],
      type: ['EVENT_TYPE_AUTO_RENEW', Validators.compose([Validators.required])],
    });

    console.log("here showEOTList", this.showEOTList)
    console.log("here selectedVendor", this.selectedVendor)
    console.log("here tenantServiceTerms", this.tenantServiceTerms)
    this.source = new LocalDataSource(this.tenantServiceTerms);
  }



  getTenantServiceTerm() {
    this.isDataLoaded = false
    this.serviceTermService
      .getTenantRetermListService(this.tenantData.domain)
      .toPromise()
      .then((data: any) => {
        this.isDataLoaded = true

        this.tenantServiceTerms = data
        this.source = new LocalDataSource(this.tenantServiceTerms);

      })
      .catch((e) => console.warn(e));
  }


  togglerAddTermFormHandler() {
    this.showAddEOTForm = !this.showAddEOTForm
  }

  initializeTable() {
    this.termsColumnsSettings = {
      pager: {
        display: true,
        perPage: 6
      },
      actions: {
        add: false,
        delete: false,
        edit: false,
        position: 'right',
        custom: [
          {
            name: ITableAction.USER_SELECTION,
            title: 'Select',
          }
        ],
      },
      noDataMessage: "No records found",
      rowClassFunction: (row) => 'cursor-pointer',
      columns: {
        display_id: {
          title: 'Term ID',
          editable: false,
          type: 'html',
          filter: true,
          width: '14%',
          sortDirection: 'asc',
          valuePrepareFunction: (cell: any, row: any) => row.display_id
        },
        event_type: {
          title: 'End of Term Action',
          editable: false,
          type: 'html',
          filter: {
            type: 'list',
            config: {
              selectText: 'All',
              list: [
                {
                  value: 'EVENT_TYPE_AUTO_RENEW',
                  title: 'Auto Renew'
                },
                {
                  value: 'EVENT_TYPE_AUTO_CANCELLATION',
                  title: 'Cancel'
                }
              ],
            }
          },
          width: '18%',
          valuePrepareFunction: (cell: any, row: any) => {
            switch (row.event_type) {
              case 'EVENT_TYPE_AUTO_RENEW':
                return "Auto Renew";
                break;
              case 'EVENT_TYPE_AUTO_CANCELLATION':
                return "Cancel";
                break;
              default:
                return "-";
            }
          }
        },
        auto_renew_length: {
          title: 'Auto-Renew Length (Months)',
          editable: false,
          type: 'html',
          filter: true,
          width: '30%',
          valuePrepareFunction: (cell: any, row: any) => row.auto_renew_length ?? '-'
        },
        days_to_notify: {
          title: 'Advanced Notification (Days)',
          editable: false,
          type: 'html',
          filter: true,
          width: '30%',
          valuePrepareFunction: (cell: any, row: any) => row.days_to_notify ?? '-'
        }
      }
    };
  }

  onSelect(event) {
    // this.activeModal.close(event);
    this.modal.close(event);
  }

  onSubmitHandler(form: any) {
    this.addEOTForm.disable()
    this.isAddEOTInProgress = true
    let createsService = { data: {} }
    const filteredTerms = this.tenantServiceTerms.filter((term: ITenantServiceTerm) => {
      if (term.days_to_notify === form.notification_day && term.auto_renew_length === form.auto_renew_day && form.type === term.event_type) {
        return term
      }
    })

    if (filteredTerms.length > 0) {
      createsService.data = filteredTerms[0]
      // this.activeModal.close(createsService);
    } else {
      const payload = {
        tenant_id: this.tenantData.domain,
        event_type: form.type,
        auto_renew_length: form.auto_renew_day,
        days_to_notify: form.notification_day
      }

      this.serviceTermService
        .saveServiceTerm(payload)
        .toPromise()
        .then((response: any) => {
         
          setTimeout(() => {
            this.getTenantServiceTerm()
            this.showAddEOTForm = false

          }, 3000);
          // this.addEOTForm.reset();
          createsService.data = response
          // this.activeModal.close(createsService);
          this.toastr.success(`Term created successfully`);
        })
        .catch((e) => console.warn(e));
    }
  }


  onSelectChange() {
    if (this.addEOTForm.get('type').value === 'EVENT_TYPE_AUTO_CANCELLATION') {
      this.addEOTForm.get('auto_renew_day').setValue(0);
    } else {
      this.addEOTForm.get('auto_renew_day').setValue(null);
    }
  }

}
