import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Settings as luxonSettings } from 'luxon';

@Injectable()
export class ServiceReportsV2Service {
  private apiUrl = '_MICRO_SERVICE_/upload';

  constructor(private http: HttpClient) {}

  printServices(columns: any[], filters: any[], tenantId: string, search: string = ""): Observable<any> {
    const userDate = new Date();
    const userTimezone = luxonSettings.defaultZoneName;
    return this.http.post(`${this.apiUrl}/report/print`, {columns, filters, "q": search, "tenant_id": tenantId, "user_date": userDate, "user_timezone": userTimezone})
    .map((response: any) => {
      return response;
    });
  }

  bulkChangeServices(columns: any[], filters: any[], tenantId: string, search: string = ""): Observable<any> {
    const userDate = new Date();
    const userTimezone = luxonSettings.defaultZoneName;
    return this.http.post(`${this.apiUrl}/service/download`, {columns, filters, "q": search, "tenant_id": tenantId, "user_date": userDate, "user_timezone": userTimezone})
    .map((response: any) => {
      return response;
    });
  }
}