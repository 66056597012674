import { Action } from '@ngrx/store';

export const ActionTypes = {
  SET_RESULTS: '[Miso: DocumentV2] SET_RESULTS',
  GET_DATA: '[Miso: DocumentV2] GET_DATA',
  SEARCH_LOADING: '[Miso: DocumentV2] SEARCH_LOADING',
  SEARCH_LOADED: '[Miso: DocumentV2] SEARCH_LOADED'
};


export class SetDocumentResults implements Action {
  type = ActionTypes.SET_RESULTS;

  constructor(public payload) { }
}

export class GetDocumentData implements Action {
  type = ActionTypes.GET_DATA;

  constructor(public payload) { }
}

export class SetSearchLoading implements Action {
  type = ActionTypes.SEARCH_LOADING;

  constructor(public payload) { }
}

export class SetSearchLoaded implements Action {
  type = ActionTypes.SEARCH_LOADED;

  constructor(public payload) { }
}

export type Actions
  = SetDocumentResults
  | GetDocumentData
  | SetSearchLoading
  | SetSearchLoaded
