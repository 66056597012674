import {Component, Input} from "@angular/core";



import * as ASActions from '../../../../shared/state/advanced-search/actions';

import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {FilterBucket, TimeRangeBucket} from "../../../ng-models/search/FilterBucket.interface";
import {AbstractPageComponent} from "../../../pages/abstract-page/abstract-page.component";

@Component({
  selector: 'mi-advanced-search-time-range',
  templateUrl: './advanced-search-time-range.component.html',
  styleUrls: ['./advanced-search-time-range.component.scss']
})

export class AdvancedSearchTimeRangeComponent extends AbstractPageComponent {

  filter_name: string = 'time_range';
  filters: TimeRangeBucket[] = [];
  selectedIndex: number;

  constructor(private store: Store<ASReducer.State>) {
    super()
  }

  ngOnInit() {
    this.store.select(ASReducer.getASData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.results && data.results.aggregations && data.results.aggregations[this.filter_name]) {

            // get all the time ranges
            this.filters = data.results.aggregations[this.filter_name].buckets as TimeRangeBucket[];


            // find the selected time range
            this.selectedIndex = 0;
            this.filters.forEach( (range, index) => {
              if (range.selected) {
                this.selectedIndex = index;
              }
            });

          } else {
            this.filters = [];
          }
        }
      )
  }

  toggle(index) {
    const time_range = this.filters[index].key;
    this.store.dispatch(new ASActions.AsFilterUpdate({time_range}));
  }
}
