import {Component, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter} from '@angular/core';
import {createLogger, LOG_LEVELS} from "../../../shared/logger";
import {AbstractPageComponent} from "../../../shared/pages/abstract-page/abstract-page.component";
import {NO_DATA} from "../../../shared/ng-models/SectionDataTable.model";
import { ServiceAssociationType } from '../../../shared/models/Contact';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';
import { ModalService } from '@independer/ng-modal';
// import { SelectServiceOwnershipModalComponent } from '../select-service-ownership-modal/select-service-ownership-modal.component';
import { SelectEmployeeModalComponent } from '../select-employee-modal/select-employee-modal.component';
// import { Store } from '@ngrx/store';
// import { TenantMiServiceService } from 'src/libs/api/tenant/tenantMiService.service';

const log = createLogger(LOG_LEVELS.CORE);


@Component({
  selector: 'mi-service-ownership-static',
  templateUrl: './service-ownership-static.component.html',
  styleUrls: ['./service-ownership-static.component.scss']
})

export class ServiceOwnershipStaticComponent extends AbstractPageComponent implements OnInit {

  @Input() requestor: any;
  @Input() endUser: any;
  // @Input() assignee: TenantUser;
  @Input() miMisoUser: any;
  @Input() tenant_id: string;
  @Input() division: string;
  @Input() team: string;
  @Input() editMode: boolean = false;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeEmployee: EventEmitter<any> = new EventEmitter<any>();

  contactRows: any[] = [];
  contactColumns: any[];
  error: string = '';
  @ViewChild('emailTmpl', {static: true}) emailTmpl: TemplateRef<any>;
  @ViewChild('buTmpl', {static: true}) buTmpl: TemplateRef<any>;
  @ViewChild('editOwnershipTmpl', {static: true}) editOwnershipTmpl: TemplateRef<any>;

  readonly NO_DATA: string = NO_DATA;

  ngOnInit() {
    this.buildContactsTable();
  }

  constructor(
    private modalService: ModalService,
    // private tenantMiService: TenantMiServiceService,
    // private store: Store<any>
  ) {
    super();
  }

  getServiceAssociationRow(user: TenantUser, service_association_type: ServiceAssociationType,
    service_association: string, service_association_display_id?: string){
      if(user){
        return {
          name: user.first_name + ' ' + user.last_name,
          company: '',
          division: '',
          division_label: '',
          team: '',
          email: user.email,
          service_association,
          service_association_type,
        }
      }
      else{
        return {
          name: NO_DATA,
          company: NO_DATA,
          division: NO_DATA,
          team: NO_DATA,
          email: NO_DATA,
          service_association,
          service_association_type,
        }
      }
  }

  buildContactsTable() {
    this.contactRows = [];
    //console.log("here this.assignee", this.assignee)
    console.log("here this.requestor", this.requestor)
    console.log("here this.endUser", this.endUser)
    //add Assignee to user table
    // this.contactRows.push({
    //   name: this.assignee.first_name + ' ' + this.assignee.last_name,
    //   company: this.tenant_id,
    //   division: this.division,
    //   division_label: '',
    //   team: this.team,
    //   email: this.assignee.email,
    //   service_association: ServiceAssociationType.ASSIGNEE
    // });

    

    //add mi owner to user table
    if(this.miMisoUser){
      this.contactRows.push({
        name: this.miMisoUser.first_name + ' ' + this.miMisoUser.last_name,
        company: this.tenant_id,
        division: this.division,
        division_label: '',
        team: this.team,
        email: this.miMisoUser.email,
        origin: this.miMisoUser.origin,
        phone: this.miMisoUser.mobile_phone ? this.miMisoUser.mobile_phone : '-',
        service_association: ServiceAssociationType.MI_OWNER
      });
    } else {
      this.contactRows.push({
        name: NO_DATA,
        company: '',
        division: '',
        team: '',
        email: '',
        phone: '-',
        service_association: ServiceAssociationType.MI_OWNER
      });
    }


    //add Requestor to user table
    if(this.requestor){
      let division_label = '';

      if(this.requestor.cost_center){
        let cost_center_array = this.requestor.cost_center.split('/');
        if(cost_center_array.length === 2){
          this.requestor.division = this.requestor.cost_center_id;
        } else
        if(cost_center_array.length === 3){
          division_label = cost_center_array[1];
          this.requestor.team = this.requestor.cost_center_id;
        }
      }

      this.contactRows.push({
        name: this.requestor.first_name + ' ' + this.requestor.last_name,
        company: this.tenant_id,
        division: this.requestor.division,
        division_label: division_label? division_label: NO_DATA,
        team: this.requestor.team,
        email: this.requestor.email,
        phone: this.requestor.mobile_phone ? this.requestor.mobile_phone : '-',
        service_association: ServiceAssociationType.REQUESTOR
      });
    } else {
      this.contactRows.push({
        name: NO_DATA,
        company: '',
        division: '',
        team: '',
        email: '',
        phone: '-',
        service_association: ServiceAssociationType.REQUESTOR
      });
    }

    if(this.endUser){
      let division_label = '';

      if(this.endUser.cost_center){
        let cost_center_array = this.endUser.cost_center.split('/');
        if(cost_center_array.length === 2){
          this.endUser.division = this.endUser.cost_center_id;
        } else
        if(cost_center_array.length === 3){
          division_label = cost_center_array[1];
          this.endUser.team = this.endUser.cost_center_id;
        }
      }

      this.contactRows.push({
        name: this.endUser.first_name + ' ' + this.endUser.last_name,
        company: this.tenant_id,
        division: this.endUser.division,
        division_label: division_label? division_label: NO_DATA,
        team: this.endUser.team,
        email: this.endUser.email,
        phone: this.endUser.mobile_phone ? this.endUser.mobile_phone : '-',
        service_association: ServiceAssociationType.ENDUSER
      });
    } else {
      this.contactRows.push({
        name: NO_DATA,
        company: '',
        division: '',
        team: '',
        email: '',
        phone: '-',
        service_association: ServiceAssociationType.ENDUSER
      });
    }


    this.contactColumns = [
      // {
      //   name: 'Company',
      //   prop: 'company',
      //   cellTemplate: this.buTmpl,
      //   sortable: false
      // },
      // {
      //   name: 'Division',
      //   prop: 'division',
      //   cellTemplate: this.buTmpl,
      //   sortable: false
      // },
      // {
      //   name: 'Team',
      //   prop: 'team',
      //   cellTemplate: this.buTmpl,
      //   sortable: false
      // },
      {
        name: 'Name',
        prop: 'name',
        sortable: false
      },
      {
        name: 'Email',
        prop: 'email',
        cellTemplate: this.emailTmpl,
        sortable: false
      },
      {
        name: 'Phone',
        prop: 'phone',
        sortable: false
      },
      {
        name: 'Origin',
        prop: 'origin',
        sortable: false
      },
      {
        name: 'Service Association',
        prop: 'service_association',
        sortable: false
      }
    ];

    if(this.editMode){
      this.contactColumns.push(
        {
          name: 'Action',
          prop: 'email',
          cellTemplate: this.editOwnershipTmpl,
          sortable: false
        }
      )
    }
  }
  
  onEdit(row){
    console.log("here row", row.service_association)
    switch (row.service_association) {
      // case ServiceAssociationType.ASSIGNEE:
      //   const modalRef = this.modalService.open(SelectServiceOwnershipModalComponent, m => {
      //     m.division = row.division;
      //     m.team = row.team;
      //     m.assigned_email = row.email;
      //   });

      //   modalRef.closed.subscribe(({reason, result = []}) => {
      //     console.log("SelectServiceOwnershipModalComponent Result: ", result)
      //     if(result[0] && result[0].ownership){         
      //       this.updateData.emit(result[0].ownership)  
      //     }
  
      //   });
      //   break;
      case ServiceAssociationType.REQUESTOR:
        const modalRef2 = this.modalService.open(SelectEmployeeModalComponent, m => {
        });

        modalRef2.closed.subscribe(({reason, result = []}) => {
          console.log("SelectEmployeeModalComponent Result: ", result)
          if(result[0] && result[0].tenantUser){

            this.updateData.emit({
              requestor_email: result[0].tenantUser.email
            })  
          }
    
        });
        break;

        case ServiceAssociationType.MI_OWNER:
        const modalRef4 = this.modalService.open(SelectEmployeeModalComponent, m => {
        });
        modalRef4.closed.subscribe(({reason, result = []}) => {
          console.log("here result[0]", result[0])
          if(result[0] && result[0].tenantUser){
            this.updateData.emit({
              mi_user_email: result[0].tenantUser.email,
              userId: result[0].tenantUser.id
            })  
          }
    
        });
        break;

      case ServiceAssociationType.ENDUSER:
        const modalRef3 = this.modalService.open(SelectEmployeeModalComponent, m => {
        });

        modalRef3.closed.subscribe(({reason, result = []}) => {
          console.log("SelectEmployeeModalComponent Result: ", result)
          if(result[0] && result[0].tenantUser){

            this.updateData.emit({
              end_user_email: result[0].tenantUser.email
            })  
          }
    
        });
        break;
    
      default:
        break;
    }
  }

  onRemoveEmployee(row){
    this.removeEmployee.emit(row);
  }

}

