import {Component, Input} from "@angular/core";
import { DetailHeaderNavData, HeaderNavSpecTypes, HeaderNavSubNavTypes } from 'src/shared/ng-models/DetailHeaderNavData.interface';
import {Contact} from '../../../../../../shared/models/Contact';

@Component({
  selector: 'mi-contact-details-header-items',
  templateUrl: './contact-details-header-items.component.html',
  styleUrls: ['./contact-details-header-items.component.scss']
})

export class ContactDetailsHeaderItemsComponent{
  @Input() contact: Contact;
  @Input() data: DetailHeaderNavData;
  
  DATE = HeaderNavSpecTypes.DATE;
  CURRENCY = HeaderNavSpecTypes.CURRENCY;
  NAV_TYPE_STANDARD = HeaderNavSubNavTypes.STANDARD;
}
