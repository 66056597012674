<modal class="extra-wide" [closeOnEscape]="true" [closeOnOutsideClick]="false" [showCloseButton]="true">
  <modal-header>
    <h4>Download Field Select</h4>
  </modal-header>
  <modal-content>
    <ng-container *ngIf="fields;">
      <mi-fields-to-download-template 
        [reference]="type_reference_enum" 
        [fields]="fields"     
        [showCancel]="true"
        (canceled)="onCancel($event)"
        downloadButtonName='Email Download'
        (fieldsSelected)="onFieldsSelected($event)">
      </mi-fields-to-download-template>

    </ng-container>
  </modal-content>
</modal>