import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
// import {StoreModule} from "@ngrx/store";
//
// import {reducer as createOrderReducers} from './state/reducer';
// import {EffectsModule} from "@ngrx/effects";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {TenantComponentsModule} from "../../components/tenant-components.module";
import {SharedModule} from "../../../shared/shared.module";
import { MissingOpportunitiesVendorComponent } from './missing-opportunities-vendor/missing-opportunities-vendor.component';

@NgModule({
  declarations: [
    MissingOpportunitiesVendorComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    // StoreModule.forFeature('order-create-store', createOrderReducers),
    // EffectsModule.forFeature([ContainedStoreEffects])
  ],
  providers: [],
  exports: [
    MissingOpportunitiesVendorComponent
  ]
})
export class MissingOpportunitiesModule {
}
