<div class="filter-group" *ngIf="isVisible">
  <h2>{{title}}</h2>
  <select [ngModel]="selected" (change)="toggle($event)">
    <option value="">{{'ASSIGNED_TO_ANYONE'|translate}}</option>
    <option value="{{myEmail}}">{{'ASSIGNED_TO_ME'|translate}}</option>
    <option *ngFor="let filter of filters;" value="{{filter.key}}">
      {{filter.key}}
    </option>
  </select>

</div>



