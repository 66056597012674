<div class="imageUpload input">
  <img *ngIf="fileUrl && fileUrl.full_path && isImage" src="{{fileUrl.full_path}}" />
  <a *ngIf="fileUrl && fileUrl.full_path && !isImage" href="{{fileUrl.full_path}}" target="_blank">{{fileUrl.file_name}}</a>
  <input id="ImageUpload"
         type="file"
         (change)="fileChanged($event)"
         name="file"
         accept="{{accept}}"/>
  <span *ngIf="loading">Uploading...</span>
  <span *ngIf="loaded">Upload Complete</span>
  <span *ngIf="!loading && !loaded && !fileUrl">{{ 'UPLOADS.'+upload_type | translate}}</span>
  <label class="button darkTheme" for="ImageUpload">{{'FILE_UPLOAD_BROWSE'|translate}}</label>
</div>
<mi-server-error [error]="error"></mi-server-error>

