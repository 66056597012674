

<ng-container *ngIf="step===SEARCH_STATE">
  <mi-server-error [error]="searchCreateError"></mi-server-error>
  <mi-loading-animation *ngIf="loading"></mi-loading-animation>
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="!activeVendorOnly">
      <mi-vendor-search
        searchString = "{{searchString}}"
        (itemSelected)="onVendorSelected($event)"
        (searchChange)="updateSearchString($event)"
        placeholder="Start Typing">
        <ng-container> <!-- Put yer error stuff here -->
          <p class="error">No Vendors Found Matching {{this.searchString}}</p>
        </ng-container>
      </mi-vendor-search>
    </ng-container>

    <ng-container *ngIf="activeVendorOnly">
      <mi-vendor-tenant-search
        searchString = "{{searchString}}"
        (itemSelected)="onVendorSelected($event)"
        (searchChange)="updateSearchString($event)"
        placeholder="Start Typing">
        <ng-container> <!-- Put yer error stuff here -->
          <p class="error">No Vendors Found Matching {{this.searchString}}</p>
        </ng-container>
      </mi-vendor-tenant-search>
    </ng-container>

    <div class="add-vendor" *ngIf='showCreateVendor && searchString && searchString.length > 1'>
      <p class="cannot-find-vendor">Can't Find Your Vendor?</p>
      <ul >
        <li >
          <a (click)="swapTo(CREATE_STATE)" class="link--after">Add new</a>
        </li>
      </ul>
    </div>

  </ng-container>
</ng-container>

<ng-container *ngIf="step===CREATE_STATE">
  <h3 class="in-form">Create a new Vendor</h3>
  <form
    *ngIf="createPurchasingVendorForm"
    [formGroup]="createPurchasingVendorForm"
    (submit)="onVendorCreate(createPurchasingVendorForm.value)">
    <mi-extended-input [labelText]="'Company Name'"
                       [labelRequired]="true"
                       [inputControl]="createPurchasingVendorForm.controls['vendor_name']"
                       [inputErrors]="createPurchasingVendorForm.controls['vendor_name'].errors"
                       [inputTouched]="createPurchasingVendorForm.controls['vendor_name'].touched"
                       [errorDefs]='{required: "Please enter the vendor name."}'>
      <input type="text"
             class="form-control"
             formControlName="vendor_name"
             placeholder="Company name goes here"
             autofocus>
    </mi-extended-input>
    <!-- <mi-extended-input [labelText]="'Website Address'"
                       [labelRequired]="true"
                       [inputControl]="createPurchasingVendorForm.controls['vendor_url']"
                       [inputErrors]="createPurchasingVendorForm.controls['vendor_url'].errors"
                       [inputTouched]="createPurchasingVendorForm.controls['vendor_url'].touched"
                       [errorDefs]='{required: "Website is required.", url: "Please enter a valid URL."}'>
      <input type="text"
             class="form-control"
             formControlName="vendor_url"
             placeholder="Web address goes here"
             autofocus>
    </mi-extended-input> -->
    
    <mi-extended-input [labelText]="'Website Address'" [labelRequired]="true"
      [inputControl]="createPurchasingVendorForm.controls['vendor_url']"
      [inputErrors]="createPurchasingVendorForm.controls['vendor_url'].errors"
      [inputTouched]="createPurchasingVendorForm.controls['vendor_url'].touched"
      [errorDefs]='{required: "Website is required.", url: "Please enter a valid URL."}'>
      <div class="label-combo-input">
        <label class="label-combo-input-label">https://www.</label>
        <input id="vendor_url" class="form-control" name="vendor_url" placeholder="Web address goes here"
          formControlName="vendor_url" type="text" autocomplete="off" autofocus>
      </div>
    </mi-extended-input>

    <mi-server-error [error]="error"></mi-server-error>

    <div class="button-container">
      <button
        mi-button
        buttonTheme="light-theme"
        type="button"
        (click)="swapTo(SEARCH_STATE)">
        Cancel
      </button>
      <button mi-button
              type="submit"
              [disabled]="!this.createPurchasingVendorForm.valid">
        Create Vendor
      </button>
    </div>
  </form>
</ng-container>
