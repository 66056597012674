
import {TransCopyData} from "./TransCopyData.interface";

export enum HeaderNavSpecTypes {
  DATE,
  CURRENCY
}

export enum HeaderNavSubNavTypes {
  NONE,
  STANDARD
}

export interface DetailHeaderNavSpec {
  label: string,
  value: any,
  value_type?: HeaderNavSpecTypes;
}

export interface DetailHeaderNavData {
  headline?: string | TransCopyData,
  byline?: string | TransCopyData,
  specs?: DetailHeaderNavSpec[]
}
