import {NgModule} from '@angular/core';
import {ServiceSimpleCreateComponent} from './service-simple-create.component';
import {SharedModule} from '../../../../../shared/shared.module';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {StoreModule} from "@ngrx/store";
import {reducer as createOrderReducers} from './state/reducer';
import {EffectsModule} from "@ngrx/effects";
import {ServiceCreateEffects} from "./state/effects";
import {HelloWorldService} from '../../../../../libs/api/hello-world.service';
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ConfirmPurchasingVendorComponent} from "./vendor-product/confirm-purchasing-vendor/confirm-purchasing-vendor.component";
// import {OrderContactComponent} from "./order-details/contact/order-contact.component";
import {TenantComponentsModule} from '../../../../components/tenant-components.module';
import { ServiceFinancialsComponent } from './service-finanicals/service-financials.component';
import { ServiceVendorComponent } from './vendor-product/service-vendor.component';
import { ServiceProductComponent } from './vendor-product/service-product.component';
import { ServiceOwnershipComponent } from './service-ownership/service-ownership.component';

@NgModule({
  declarations: [
    ServiceVendorComponent,
    ServiceProductComponent,
    ServiceSimpleCreateComponent,
    ConfirmPurchasingVendorComponent,
    ServiceFinancialsComponent,
    ServiceOwnershipComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    TranslateModule,
    TenantComponentsModule,
    StoreModule.forFeature('simple-create-store', createOrderReducers),
    EffectsModule.forFeature([ServiceCreateEffects])
  ],
  providers: [HelloWorldService],
  exports: [
    ServiceVendorComponent,
    ServiceProductComponent,
    ServiceSimpleCreateComponent,
    ConfirmPurchasingVendorComponent,
    ServiceFinancialsComponent,
    ServiceOwnershipComponent
  ]
})
export class ServiceSimpleCreateModule {
}
