
function formatUsPhone(phoneNum) {

  const digits = ( phoneNum + '').replace(/[^\d]+/g, '');

  let formattedNum = phoneNum;
  if (digits.length > 10) {
    formattedNum = digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3 x');
  } else if (digits.length > 5) {
    formattedNum = digits.replace(/(\d{3})(\d{3})/, '($1) $2-');
  } else if (digits.length > 2) {
    formattedNum = digits.replace(/(\d{3})/, '($1) ');
  } else if (digits.length > 0){
    formattedNum = '(' + digits;
  } else {
    formattedNum = '';
  }

  return formattedNum;
}

function formatNoneUsPhone(phoneNum) {

    const digits = ( phoneNum + '').replace(/[^\d]+/g, '');

    let formattedNum = phoneNum;

    if (digits.length > 0 && formattedNum[0]!='+'){
      formattedNum = '+' + digits;
    } else if (formattedNum == '') {
      formattedNum = '';
    }

    return formattedNum;
  }

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export {
  formatUsPhone,
  formatNoneUsPhone,
  numberWithCommas
};
