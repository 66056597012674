<div class="mi-grid-aside-basic">
  <div class="col-all">

    <mi-advanced-search-header
      searchType="SERVICE"
      saveSearch="true">
      <mi-save-search-tray></mi-save-search-tray>
    </mi-advanced-search-header>

    <mi-opacity-loader [ready]="searchLoaded$ | async">
      <mi-applied-search-filters></mi-applied-search-filters>
      <mi-advanced-search-service-results-table></mi-advanced-search-service-results-table>
      <mi-advanced-search-load-more></mi-advanced-search-load-more>
    </mi-opacity-loader>

  </div>
</div>