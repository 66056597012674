import {Component, OnInit} from "@angular/core";
import {AbstractPageComponent} from "../../../../../shared/pages/abstract-page/abstract-page.component";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {AccountId} from "../../../../../shared/models/AccountId";
import { Vendor } from "../../../../../shared/models/Vendor";
import { TenantVendors } from "../../../../../libs/api/tenant/tenantVendors";
import {Link} from '../../../../../shared/ng-models/Link.interface';
import { Title } from "@angular/platform-browser";
import linkFormatter from "src/common/utils/linkFormatter";


@Component({
  templateUrl: './vendor-account-id-create.component.html',
  styleUrls: ['./vendor-account-id-create.component.scss']
})

export class VendorAccountIdCreateComponent extends AbstractPageComponent implements OnInit {

  breadcrumbs: Link[];
  error: string;
  supportLink: string =linkFormatter.getSupportLink();
  vendor: Vendor;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantVendors: TenantVendors,
              private router: Router,
              private titleService: Title
            ) {
    super();
  }

  ngOnInit() {

    const vendorId: string = this.activatedRoute.snapshot.params.vendor_display_id;

    this.breadcrumbs = [
      {
        label: 'Dashboard',
        url: '/',
        internal: true
      },
      {
        label: 'Vendors',
        url: '/vendors',
        internal: true
      },
      {
        label: `Vendor Details ${vendorId}`,
        url: `/vendors/${vendorId}/account-id`,
        internal: true
      },
      {
        label: `Create Account Id`,
        url: `/vendors/${vendorId}/account-id/create`,
        internal: true
      }
    ];

    this.activatedRoute.params
    .takeUntil(this.destroy$)
    .switchMap(params => this.tenantVendors.getVendorByDisplayId(vendorId))
    .subscribe(vendor => {
      this.vendor=vendor
      this.titleService.setTitle(`Create Account Id - ${vendor.vendor_name} - Vendor ${vendor.display_id} - MISO3`);
    });
  }

  onCanceled() {
    this.router.navigateByUrl(`/vendors/${this.vendor.display_id}/account-id`);
  }

  onUpdateAccountId(accountId:AccountId):void {
    // The change has been added.
    this.router.navigateByUrl(`/vendors/${this.vendor.display_id}/account-id`);
  }

}
