import { Component, Input } from '@angular/core';

@Component({
  selector: 'document-icon',
  template: `
 <button mi-button class="text-white">
        Download
    </button>
  `
})

export class DocumentIconComponent {
  @Input() rowData: any;

}