<div class="parent-service-details"
     *ngIf="(pageLoaded$ | async); else loading">

  <div class="parent-service-details-item">
    <h2>
      {{this.productTitle}}
    </h2>
    <div class="categories bold">
      {{this.categories}}
    </div>
  </div>

  <div class="parent-service-details-item">
    <div>
      <ng-container *ngIf="parentService.services.length; else noServices">

        <ng-container *ngIf="parentService.current_notification"
                      [ngSwitch]="parentService.current_notification.status">
          <ng-container *ngSwitchCase="'UNKNOWN'">
            <h2>
              Next Event
            </h2>
            <div>
              {{'TENANT.SERVICE_DETAILS.HEADER.EVENT_UNKNOWN'|translate}}
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'ARCHIVED'">
            <ng-container *ngIf="parentServiceStatus.allArchived">
              <h2>
                Next Event
              </h2>
              <div>
                {{'TENANT.SERVICE_DETAILS.HEADER.EVENT_ARCHIVED'|translate}}
              </div>
            </ng-container>
            <ng-container *ngIf="!parentServiceStatus.allArchived">
              <h2>
                Next Event
              </h2>
              <div>
              {{'TENANT.SERVICE_DETAILS.HEADER.BLEND_ARCHIVE_UNKNOWN'|translate}}
              </div>
              <div class="aside" *ngIf="!parentServiceStatus.allArchived && parentServiceStatus.hasAnyStatusUnknown">
                {{'TENANT.SERVICE_DETAILS.HEADER.ARCHIVED_OR_BAD_DATA_INTEGRITY'|translate}}
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <h2>
              Next Event: {{'EVENTS.' + parentService.current_notification.event_type | translate}}
            </h2>
            <div [ngClass]="daysRemainingStyle">
              {{'NATURAL_DAYS'
              | translate:{count: daysRemaining, financial_commitment: parentService.financial_commitment}
              | translateSelector: parentService.current_notification.notification_days_until_event}}
              <ng-container *ngIf="parentService.financial_commitment">
                <span title="Total Value of Services Scheduled to Auto-Renew">
                ({{parentService.financial_commitment | round | currency:'USD':'symbol':'1.2-2'}})
                  </span>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!parentService.current_notification">
          <span class="aside">All service notifications are muted.</span>
        </ng-container>
      </ng-container>
      <ng-template #noServices>
        <h2>
          Next Event
        </h2>
        <div>
          {{"TENANT.SERVICE_DETAILS.HEADER.EVENT_UNKNOWN"|translate}}
        </div>
      </ng-template>
    </div>
  </div>

  <div class="parent-service-details-item">
    <h2 title="Calculated Monthly Recurring Cost">CMRC</h2>
    <div class="mrc">
      {{parentService.mrc | round | currency:'USD':'symbol':'1.2-2'}}
    </div>
  </div>

</div>

<div class="links">
  <ul>
    <li>
      <a class="details"
         routerLink="./details"
         routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
        Details
      </a>
    </li>
    <li>
      <a class="edit"
         routerLink="./edit"
         routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
        Edit
      </a>
    </li>
    <li>
      <a class="add-service"
         routerLink="./add-service"
         routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
        Add Service
      </a>
    </li>
    <li>
      <a class="remove-service"
         routerLink="./remove-service"
         routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
        Remove Service
      </a>
    </li>
  </ul>
</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
