import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MiService} from '../../../../../../../shared/models/MiService';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as PSReducer from '../../../state/reducers';
import {Store} from '@ngrx/store';
import {EVENT_TYPE_ACTIVATION} from '../../../../../../../common/utils/sharedConstants';
import {MisoCollection} from '../../../../../../../shared/models/MisoCollection.enum';
import {NoteHtml} from '../../../../../../../shared/models/NoteHtml.enum';
import {MiParentService} from '../../../../../../../shared/models/MiParentService';
import {TenantMiParentServiceService} from '../../../../../../../libs/api/tenant/tenantMiParentService.service';
import {ParentServiceLoadSuccess} from '../../../state/actions';
import {Note} from '../../../../../../../shared/models/Note.interface';
import {TenantNoteService} from '../../../../../../../libs/api/tenant/tenantNote.service';

@Component({
  selector: 'mi-parent-service-details-form-remove-cancellation',
  templateUrl: './parent-service-remove-cancellation-form.component.html'
})

export class ParentServiceRemoveCancellationFormComponent implements OnInit {

  @Input() parentService: MiParentService;
  @Output() canceled: EventEmitter<null> = new EventEmitter<null>();

  cancellationFormGroup: FormGroup;
  error: string = '';

  readonly EVENT_TYPES: any = {
    EVENT_TYPE_ACTIVATION: EVENT_TYPE_ACTIVATION
  };

  constructor(
    private tenantMiParentService: TenantMiParentServiceService,
    private formBuilder: FormBuilder,
    private store: Store<PSReducer.State>,
    private noteService: TenantNoteService
  ) {
    // Nothing.
  }

  ngOnInit() {
    this.cancellationFormGroup = this.formBuilder.group({
      service_id: this.parentService.display_id
    });
  }

  onCancelClicked(e): void {
    e.stopPropagation();
    e.preventDefault();
    this.canceled.emit();
  }

  onCancellationFormSubmit(data: any) {

    let updatedParentService: MiParentService;

    this.error = '';
    this.tenantMiParentService
      .removeCancellation(this.parentService.display_id)
      .toPromise()
      .then(res => {
        updatedParentService = res;
        let promises = res.services.map((service: MiService) => {
          const note: Note = {
              connections: [{
                document_id: service._id,
                document_collection: MisoCollection.SERVICES
              }],
              html: NoteHtml.CANCELLATION_REMOVAL_NOTE_HTML
            }
          ;
          return this.noteService.createNote(note)
            .toPromise();
        });
        return Promise.all(promises);
      })
      .then(()=>{
        this.store.dispatch(new ParentServiceLoadSuccess(
          {
            parentService: updatedParentService
          }
          ));
        this.canceled.emit();
      })
      .catch(e => this.error = e);

  }

}
