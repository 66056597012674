import { Component, Input } from '@angular/core';

@Component({
  selector: 'cmrc-value',
  template: `{{rowData.mrc | currency:(rowData.currencyDetails.code||'USD'):'symbol':'1.0-0'}}`
})

export class DocumentCmrcComponent {
  @Input() rowData: any;

}