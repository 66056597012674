import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as OrderReducers from '../../../create/state/reducer';
import {NO_DATA} from '../../../../../../../shared/ng-models/SectionDataTable.model';
import {AbstractPageComponent} from '../../../../../../../shared/pages/abstract-page/abstract-page.component';
import {OrderState, OrderSteps} from '../../../create/state/reducer';
import {Router} from '@angular/router';
import {UpdateOrderAction} from '../../../create/state/actions';
import {Financial} from "../../../../../../../shared/models/Financial";


@Component({
  selector: 'mi-service-costs',
  templateUrl: './service-cost.component.html',
  styleUrls: ['./service-cost.component.scss']
})

export class ServiceCostComponent extends AbstractPageComponent implements OnInit {

  activeIndex: boolean = false;
  activeStep: boolean = false;
  error: string;
  index: number;
  financial: Financial;
  orderState: OrderReducers.OrderState;
  orderProgression: any[] = OrderReducers.OrderProgression;
  step: string = OrderSteps.Cost;
  ready: boolean = false;

  readonly NO_DATA: string = NO_DATA;

  constructor(
    private store: Store<OrderState>
  ) {
    super();
  }

  ngOnInit() {

    // Listen to the Order State store:
    this.store.select(OrderReducers.getOrderData)
      .takeUntil(this.destroy$)
      .subscribe(
        (state: OrderState) => {
          this.orderState = state;
          // Switch whether we're active or not:
          this.index = this.orderProgression.indexOf(this.step);
          this.activeIndex = this.index === this.orderState.index;

          if(state.service.billing_frequency) {
            this.ready = true;
          }
          this.financial = {
            billing_frequency: state.service.billing_frequency,
            cost: state.service.cost,
            quantity: state.service.quantity,
            non_recurring_cost: state.service.non_recurring_cost,
            allow_zero_mrc: state.service.allow_zero_mrc
          };

        },
        (e) => {
          this.error = e;
        });

  }

  onFormSubmit(data: any) {

    this.store.dispatch(new UpdateOrderAction(
      {
        index: this.index + 1,
        maxIndex: Math.max(this.orderState.maxIndex, this.index + 1),
        service: {
          ...this.orderState.service,
          ...data
        }
      }
    ));

    this.store.dispatch(new UpdateOrderAction({
    }));

  }

}
