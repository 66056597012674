const LOG_LEVELS = {
  CORE: 'core',
  BASE_PAGE: 'base-page',
  FILE_UPLOAD: 'file-upload',
  HTTP_API: 'api',
  HTTP_API_RESPONSE: 'api-response',
  AUTH_GUARD: 'auth-guard',
  PAGE: 'page',
  COMPONENT: 'component',
  MAIN_NAV: 'main-nav',
  PRIMARY_H1: 'primary-h1',
  APP_STATE_DATA: 'app-state',
  REDUX: 'redux',
  REDUX_DEBUG: 'redux:debug',
  REDUX_ADVANCED_SEARCH: 'redux:as',
  REDUX_BULK_UPLOAD: 'redux:bu',
  REDUX_USER: 'redux:user'
};

const colors= [
  '#ff6666',
  '#ff8c66',
  '#ffb366',
  '#ffcc66',
  '#ffd966',
  '#8cff66',
  '#66ff66',
  '#66ff8c',
  '#66ffb3',
  '#66ffd9',
  '#66b3ff',
  '#668cff',
  '#6666ff',
  '#8c66ff',
  '#b366ff',
  '#ff66b3',
  '#ff668c',
];

const instances={};
const supportColors = !!window['chrome'] || !!(/firefox/i.test(navigator.userAgent));

function createLogger(realLevel) {

  if (!instances[realLevel]) {
    const color = getColor();
    let level = (`${realLevel}          `).substring(0,10);
    const log:any = wrapConsoleLog(level, color);
    log.error = wrapConsoleError(level);

    instances[realLevel] = log;
  }

  return instances[realLevel];
}

function wrapConsoleLog(level, color){
  let newArgs = [level];
  if(supportColors) {
    newArgs = [`%c${level}`, `color: ${color}; font-weight: bold`];
  }
  return console.log.bind(window.console, ...newArgs);
}

function wrapConsoleError(level){
  let newArgs = [`${level}:error`];
  return console.error.bind(window.console, ...newArgs);
}

let lastColor=0;
function getColor(){
  lastColor++;
  lastColor = lastColor >= colors.length? 0 : lastColor;
  return colors[lastColor];
}

export {
  createLogger,
  LOG_LEVELS
};

