<div class="toggle" [ngClass]="{'title-right': titleOnRight}">
  <span *ngIf="toggleTitle" class="switch-copy">{{toggleTitle}}</span>
  <label class="switch">
    <input type="checkbox" [ngClass]="{'disabled':disabled}" [checked]="_selected" (change)="onToggle($event)"
           [disabled]="disabled">
    <span class="slider round" [ngClass]="{'disabled':disabled, 'slider-color-red':sliderColor=='red', 'slider-color-apple-green':sliderColor=='apple-green', 'slider-color-mid-blue':sliderColor=='mid-blue', 'slider-color-orange':sliderColor=='orange', 'slider-color-purple':sliderColor=='purple'}">
      <ng-container *ngIf="showToggleText">
        <ng-container [ngSwitch]="_selected">
          <ng-container *ngSwitchCase="true">
            {{toggleOnText}}
          </ng-container>
          <ng-container *ngSwitchCase="false">
            {{toggleOffText}}
          </ng-container>
        </ng-container>
      </ng-container>
    </span>
  </label>
</div>
