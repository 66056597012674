import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {LOG_LEVELS, createLogger} from '../../logger';

import {TranslateService} from "@ngx-translate/core";
import * as linkFormatter from '../../../common/utils/linkFormatter';

const log = createLogger(LOG_LEVELS.COMPONENT);

@Component({
  selector: 'mi-server-error',
  template: `
    <mi-alert-item *ngIf="error" [alertType]="'ERROR'"><span [innerHtml]="knownError"></span></mi-alert-item>
  `
})

export class MiServerErrorComponent implements OnChanges, OnInit {

  @Input() error: any;
  knownError:String = "";
  unknownError:String = "";

  constructor(private translate:TranslateService) {}

  ngOnInit() {

    this.translate.get('GLOBAL.SERVER_ERROR',{supportLink:linkFormatter.getSupportLink()}).first().subscribe((res: string) => {
      this.unknownError = res;
    });
  }

  ngOnChanges() {
    let knownError:String = this.unknownError;
    if (this.error) {
      log.error(this.error);
      if (typeof this.error === 'string') {
        knownError = this.error;
      } else if (this.error.status && this.error.status.error) {
        knownError = this.error.status.error.description;
      } else if (this.error.message) {
        knownError = this.error.message;
      }
    }

    this.knownError = knownError;
  }

}
