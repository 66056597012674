import { Action } from '@ngrx/store';

export const ActionTypes = {
  AS_RESET: '[Miso:AdvancedSearch] AS_RESET',
  AS_APPLY_VISIBLE_FILTERS: '[Miso:AdvancedSearch] AS_APPLY_VISIBLE_FILTERS',
  AS_DEEP_LINK_CHANGED: '[Miso:AdvancedSearch] AS_DEEP_LINK_CHANGED',
  AS_DATA_LOAD_ERROR:  '[Miso:AdvancedSearch] AS_DATA_LOAD_ERROR',
  AS_DATA_LOAD_SUCCESS:  '[Miso:AdvancedSearch] AS_DATA_LOAD_SUCCESS',
  AS_FILTER_UPDATE:  '[Miso:AdvancedSearch] AS_FILTER_UPDATE',
  AS_FILTER_REPLACE:  '[Miso:AdvancedSearch] AS_FILTER_REPLACE',
  AS_LOAD_MORE:  '[Miso:AdvancedSearch] AS_LOAD_MORE',
  AS_LOAD_MORE_SUCCESS:  '[Miso:AdvancedSearch] AS_LOAD_MORE_SUCCESS',
  AS_FILTER_GROUP_SHOW_ALL: '[Miso:AdvancedSearch] AS_FILTER_GROUP_SHOW_ALL',
  AS_FILTER_CLEAR_FILTER_NAME: '[Miso:AdvancedSearch] AS_FILTER_CLEAR_FILTER_NAME',
  AS_SIMPLE_STATE_UPDATE: '[Miso:AdvancedSearch] AS_SIMPLE_STATE_UPDATE',
};

export class AsReset implements Action {
  type = ActionTypes.AS_RESET;
  constructor(public payload: any) { }
}

export class AsAppliedVisibleFilters implements Action {
  type = ActionTypes.AS_APPLY_VISIBLE_FILTERS;
  constructor(public payload: any) { }
}

export class AsDeeplinkChanged implements Action {
  type = ActionTypes.AS_DEEP_LINK_CHANGED;
  constructor(public payload: any) { }
}

export class AsDataLoadError implements Action {
  type = ActionTypes.AS_DATA_LOAD_ERROR;
  constructor(public payload: any) { }
}

export class AsDataLoadSuccess implements Action {
  type = ActionTypes.AS_DATA_LOAD_SUCCESS;
  constructor(public payload: any) { }
}

export class AsFilterUpdate implements Action {
  type = ActionTypes.AS_FILTER_UPDATE;
  constructor(public payload: any) { }
}

export class AsFilterReplace implements Action {
  type = ActionTypes.AS_FILTER_REPLACE;
  constructor(public payload: any) { }
}

export class AsLoadMore implements Action {
  type = ActionTypes.AS_LOAD_MORE;
  constructor(public payload: any) { }
}

export class AsLoadMoreSuccess implements Action {
  type = ActionTypes.AS_LOAD_MORE_SUCCESS;
  constructor(public payload: any) { }
}

export class AsFilterGroupShowAll implements Action {
  type = ActionTypes.AS_FILTER_GROUP_SHOW_ALL;
  constructor(public payload: any) { }
}

export class AsFilterClearName implements Action {
  type = ActionTypes.AS_FILTER_CLEAR_FILTER_NAME;
  constructor(public payload: any) { }
}

export class AsSimpleStateUpdate implements Action {
  type = ActionTypes.AS_SIMPLE_STATE_UPDATE;
  constructor(public payload: any) { }
}

export type Actions
  = AsReset
  | AsAppliedVisibleFilters
  | AsDeeplinkChanged
  | AsDataLoadError
  | AsDataLoadSuccess
  | AsFilterUpdate
  | AsLoadMore
  | AsLoadMoreSuccess
  | AsFilterGroupShowAll
  | AsSimpleStateUpdate
  | AsFilterReplace
