<mi-page-header
  title="{{ 'TENANT.MIPARENT_SERVICE_CREATE_TITLE' | translate }}"
  [breadcrumbs]="breadcrumbs">
</mi-page-header>

<mi-server-error [error]="error"></mi-server-error>
<mi-page-alert></mi-page-alert>

<div *ngIf="loaded && !error;else loading">

  <mi-order-create-section
    id="section_0"
    [index]="0"
    [segment]="parentServiceSteps.Product"
    title="What product would you like to place {{service.display_id}} under?">

    <mi-parent-service-product></mi-parent-service-product>

  </mi-order-create-section>

  <mi-order-create-section
    id="section_1"
    [index]="1"
    [segment]="parentServiceSteps.Nickname"
    title="Give your Service Group a unique Nickname to easily identify it later."
    [changeable]="true">

    <mi-parent-service-nickname></mi-parent-service-nickname>

  </mi-order-create-section>

  <mi-order-create-section
    id="section_2"
    [index]="2"
    [segment]="parentServiceSteps.AssignedTo"
    title="M3 Owner"
    [changeable]="true">

    <mi-parent-service-assignee></mi-parent-service-assignee>

  </mi-order-create-section>

  <mi-parent-service-add></mi-parent-service-add>

</div>

<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
