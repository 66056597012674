import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractPageComponent} from '../../../../shared/pages/abstract-page/abstract-page.component';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { ServiceTerm } from 'src/libs/api2/types/ServiceTerm.interface';
import { EVENT_TYPE_ACTIVATION,
  EVENT_TYPE_AUTO_RENEW,
  EVENT_TYPE_AUTO_CANCELLATION,
  EVENT_TYPE_CANCELLATION,
  EVENT_TYPE_RE_TERM,
  EVENT_TYPE_REPLACE} from '../../../../common/utils/sharedConstants';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'mi-service-term-form',
  templateUrl: './service-term-form.component.html',
  styleUrls: ['./service-term-form.component.scss']
})

export class ServiceTermFormComponent extends AbstractPageComponent implements OnInit {

  @Input() owner: string = null;
  @Output() serviceTermCreated: EventEmitter<ServiceTerm> = new EventEmitter<ServiceTerm>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = false;
  serviceTerm: ServiceTerm;
  error: string = '';
  serviceTermForm: FormGroup;
  autoRenewSelected: boolean;
  EVENT_TYPE_AUTO_RENEW: string = EVENT_TYPE_AUTO_RENEW;
  EVENT_TYPE_AUTO_CANCELLATION: string = EVENT_TYPE_AUTO_CANCELLATION;

  titles: any = {
    select: "End of Term Action",
    notification_period: "Auto-Renew Length (Months)",
    requred_notification: "Advanced Notification (Days)"
  };

  constructor(
      private formBuilder: FormBuilder,
      private serviceTermService: ServiceTermService,
    ) {
    super();
  }

  ngOnInit() {
    this.serviceTermForm = this.formBuilder.group({
      event_type: [EVENT_TYPE_AUTO_RENEW, [Validators.required]],
      auto_renew_length: [null, [Validators.required]],
      days_to_notify: [null, [Validators.required]]
    });
  }

  onBackClick() {
    this.canceled.emit();
  }

  onFormSubmit(data: ServiceTerm) {
    this.error = '';
    data.tenant_id = this.owner;
    this.serviceTermService.saveServiceTerm(data as ServiceTerm)
      .toPromise()
      .then( (terms: ServiceTerm) => this.serviceTermCreated.emit(terms))
      .catch( e => this.error = e)
  }
  
  onEOTSelect(e) {
    this.autoRenewSelected = e ? e.target.value === this.EVENT_TYPE_AUTO_RENEW : true;
    if (!this.autoRenewSelected) {
      // if this is not auto-renew make sure to get ride of days.
      this.serviceTermForm.get('days_to_notify').setValue(0);
      this.serviceTermForm.get('auto_renew_length').setValue(0);
    }

    this.updateValidation();
  }

  onMonthChange(e) {
    this.onEOTSelect(null);
  }

  updateValidation() {
    if (this.autoRenewSelected) {
      this.serviceTermForm.get('auto_renew_length').setValidators([Validators.required]);
      this.serviceTermForm.get('days_to_notify').setValidators([Validators.required]);
    } else {
      this.serviceTermForm.get('auto_renew_length').setValidators([]);
      this.serviceTermForm.get('days_to_notify').setValidators([]);
    }
    this.updateValidity(this.serviceTermForm);
  }

  updateValidity(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.controls[key].updateValueAndValidity();
    });
  }

}
