import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalRef } from "@independer/ng-modal";
import { TagsService } from "src/libs/api2/tags/tags.service";
import { ServiceTag, TagDefinition, TagOption } from "src/shared/models/Tags";
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";

export enum TagTypes {
  TEXT = 'TEXT',
  OPTION = 'OPTION'
}

export enum TagOptionEditMode {  
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

@Component({
  styleUrls: ['./add-or-edit-tag.component.scss'],
  templateUrl: './add-or-edit-tag.component.html'
})

export class AddOrEditTagComponent extends AbstractPageComponent implements OnInit{
  title: string = "Add New Tag";
  buttonLabel: string = "Create";
  mode: string = TagOptionEditMode.CREATE;
  tenantId: string;
  existingTags: ServiceTag[];
  currentTag: ServiceTag;
  currentTagArray: ServiceTag[] = [];
  
  editTagDataValid: boolean = false;
  
  allTags: TagDefinition[];
  allTagOptions: TagOption[];

  pageLoaded: boolean = false;
  tagSelected: boolean = false;
  isMultiValue: boolean = false;

  form: FormGroup = null;

  selectedTagId: string = "";
  selectedTag: TagDefinition = null;

  constructor(
    private modal: ModalRef,
    private tagsService: TagsService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.mode == TagOptionEditMode.CREATE) {
      this.getAllTags();
    }
    else {
      this.currentTagArray.push(this.currentTag);
      this.createEditForm();
    }    
  }

  getAllTags(): void {
    this.tagsService.getTags(this.tenantId)
    .subscribe(res => {
      console.log("getTags", res);
      this.allTags = res;
      
      this.createNewForm();
      this.pageLoaded = true;
    });
  }

  createNewForm(): void {
    this.form = this.formBuilder.group({      
      tag_id: [null, Validators.required],
      tag_value: [null, Validators.required]
    });
  }

  createEditForm(): void {
    this.form = this.formBuilder.group({      
      tag_id: [this.currentTag.tag_id, Validators.required],
      tag_value: [this.currentTag.tag_value, Validators.required]
    });

    this.form.controls.tag_id.disable();

    this.selectTagEdit();
    this.pageLoaded = true;
  }

  selectTag(): void {
    //console.log("selectTag", $event);
    this.tagSelected = true;
    this.selectedTagId = this.form.controls["tag_id"].value;
    this.form.controls["tag_value"].setValue(null);

    //console.log("selectedTagId", this.selectedTagId);
    this.allTags.map(tag => {
      if(tag.tagId == this.selectedTagId) {
        this.selectedTag = tag;
      }
    });

    //console.log("selectedTag", this.selectedTag);
    if(this.selectedTag.tagType == TagTypes.OPTION) {
      this.isMultiValue = true;
      this.getTagOptions(this.selectedTagId);
    }
    else {
      this.isMultiValue = false;
    }
    
    this.validateData();
  }

  selectTagEdit(): void {
    this.tagSelected = true;
    this.selectedTagId = this.currentTag.tag_id;

    if(this.currentTag.tag_type == TagTypes.OPTION) {
      this.isMultiValue = true;
      this.getTagOptions(this.selectedTagId);
    }
    else {
      this.isMultiValue = false;
    }
    
    this.validateData();
  }

  getTagOptions(selectedTagId: string): void {
    this.tagsService.getTagOptions(selectedTagId)
    .subscribe(res => {
      this.allTagOptions = res;
    });
  }

  validateData(): void {
    if(this.form.valid) {
      this.editTagDataValid = true;
    }
    else {
      this.editTagDataValid = false;
    }
  }

  onModalClose(): void {
    this.modal.close('User closed');
  }

  submit(): void {
    this.modal.close('Submit', this.form);
  }
}