import {StoreModule} from "@ngrx/store";
import {NgModule} from '@angular/core';
import {EffectsModule} from "@ngrx/effects";
import {reducer as userReducers} from "./state/reducers";
import {UserEffects} from "./state/effects";
import {ApiModule} from "../api/api.module";
import {Api2Module} from "../api2/api2.module";
import {UserStoreService} from "./user-store.service";

@NgModule({
  imports: [
    ApiModule,
    Api2Module,
    StoreModule.forFeature('user', userReducers),
    EffectsModule.forFeature([UserEffects])
  ],
  declarations: [],
  providers: [
    UserStoreService
  ],
  exports: []
})
export class UserStoreModule {
}
