import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomFields, MiService } from "../../../../../../shared/models/MiService";
import { NO_DATA } from "../../../../../../shared/ng-models/SectionDataTable.model";
import * as SDReducer from "../../state/reducers";
import { AbstractPageComponent } from "../../../../../../shared/pages/abstract-page/abstract-page.component";
import { Store } from "@ngrx/store";
import { VendorCustomField } from "../../../../../../shared/models/CustomFieldProperty";
import { ServiceV2Service } from 'src/libs/api2/service-v2/service-v2.service';

@Component(
  {
    selector: 'mi-subsection-custom-fields',
    templateUrl: './subsection-custom-field-details.component.html',
    styleUrls: ['./subsection.component.scss']
  }
)

export class SubsectionCustomFieldDetailsComponent extends AbstractPageComponent implements OnInit {

  @Input() editable: boolean = true;
  @Input() title: string;
  @Output() dataUpdate: EventEmitter<MiService> = new EventEmitter<MiService>();

  error: any = '';
  pageLoaded: boolean = false;
  customFieldData: VendorCustomField[] = []
  readonly NO_DATA: string = NO_DATA;

  constructor(
    private serviceV2Service: ServiceV2Service,
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            const customFieldIds = data.serviceDetails.custom_fields.map((data: CustomFields) => data.id)
            if (customFieldIds.length > 0) {
              this.serviceV2Service.getServiceVendorCustomFieldsByIds(customFieldIds)
                .toPromise()
                .then(res => {
                  const labelValue = res.map((data) => ({
                    label: data.customFieldLabel,
                    id: data.id,
                  }))
                  this.customFieldData = this.getCustomFieldValues(labelValue, data.serviceDetails.custom_fields)
                  this.pageLoaded = true
                }
                )
                .catch(e => {
                  this.error = e;
                });
            } else {
              this.pageLoaded = true
            }
          }
        }
      )
  }

  getCustomFieldValues(arr1, arrData) {
    return arr1.map(obj => {
      const index = arrData.findIndex(el => el["id"] == obj["id"]);
      const address: any = index !== -1 ? arrData[index] : {};
      return {
        ...obj,
        ...address
      };
    });
  }

}