import {Component, Input, OnChanges, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../pages/abstract-page/abstract-page.component';
import {SectionDataTable} from '../../ng-models/SectionDataTable.interface';
import {addressesTableData} from '../../ng-models/SectionDataTable.model';

@Component({
  selector: 'mi-section-data-addresses',
  styleUrls: ['../section-data/section-data.component.scss'],
  templateUrl: './section-data-addresses.component.html'
})

export class SectionDataAddressesComponent extends AbstractPageComponent implements OnInit, OnChanges {

  @Input() addresses: any[] = [];
  @Input() noResultsText: string;
  @Input() title: string;

  tableData: SectionDataTable = addressesTableData;

  @ViewChild('currencyTmpl', {static: true}) currencyTmpl: TemplateRef<any>;
  @ViewChild('addressLink', {static: true}) addressLink: TemplateRef<any>;

  constructor() {
    super();
  }

  ngOnInit() {

  }

  ngOnChanges() {
    console.log('this.addresses', this.addresses);
    this.tableData.loaded = false;

    this.tableData.rows = this.addresses;

    const hasMultiple = this.tableData.rows.length > 1;

    this.tableData.columns.forEach(col => {
      if (col.prop === 'total_mrc') {
        col.cellTemplate = this.currencyTmpl;
      }

      if (col.prop === 'formatted_address') {
        col.cellTemplate = this.addressLink;
      }
      col.sortable = hasMultiple;
    });

    this.tableData.loaded = true;

  }

}
