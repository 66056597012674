import { Component, OnInit } from "@angular/core";
import { ModalRef } from "@independer/ng-modal";
import { AccountPlan } from "src/shared/models/TenantCreationV2";

@Component({
  templateUrl: './change-plan-modal.component.html',
  styleUrls: ['./change-plan-modal.component.scss']
})

export class ChangePlanModalComponent implements OnInit {

  //error: string = "";
  title: string = "Change Plan";
  newAccountPlan: AccountPlan;
  billingInterval: string;

  constructor(
    private modal: ModalRef
  ) {
  }

  ngOnInit(): void {
    console.log("newAccountPlan", this.newAccountPlan);
  }

  onModalClose() {
    this.modal.close({reason: 'User closed', result: false});
  }

  changePlan() {
    this.modal.close({reason: 'User closed', result: true, selectedPlanCode: this.newAccountPlan.planCode});
  }
}