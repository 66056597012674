<ng-container *ngIf="step===SEARCH_STATE">

  <mi-server-error [error]="loadError"></mi-server-error>

  <ng-container>

    <div class="break-row">
      <form [formGroup]="selectionForm" (ngSubmit)="onFormSubmit(selectionForm.value)">
        <mi-find-or-create-service-term
          [serviceTerm]= "selectedItem"
          (selectedServiceTerm)="onOptionSelected($event)"
          [allowNull]="allowNull"
        ></mi-find-or-create-service-term>
        <div class="button-container">
          <button *ngIf="showCancel" type="button" mi-button buttonTheme="light-theme" (click)="onCancel()">Cancel</button>
          <button mi-button
                  type="submit"
                  [disabled]="!selectionForm.valid">
            Continue
          </button>
        </div>

      </form>
    </div>
  </ng-container>

  <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
  </ng-template>

</ng-container>
