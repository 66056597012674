function getTableView (searchType, tableView){
  switch (searchType) {
    case 'SERVICE':
      return getServiceTable(tableView);
  
    default:
      break;
  }
}

function getServiceTable (type){
  let tableColumn = []
  switch (type) {
    case 'pie_product_category':
    case 'pie_state_long':
    case 'pie_vendor_name':
      tableColumn = [
          {"name": "Parent Group", "hidden": false, "is_custom_data": false},
          {"name": "P-cMRC", "hidden": false, "is_custom_data": false},
          {
              "name": "MISO ID",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Purchasing)",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Manufacturer)",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Number",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Product",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service Category",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Account ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Currency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Quantity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Billing Frequency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Non-Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "cMRC",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location A",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location B",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Start Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "End of Term",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Current Term",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Event Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Days to Event",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Next Event",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Next Event Value",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Number of Auto Renew",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Data Integrity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Status",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "M3 Owner",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Owner",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Subscriber",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Division",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Team",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Group",
              "hidden": true,
              "is_custom_data": false
          }]
      break;
    case 'calculated_mrc':
    case 'renewal_value':
      tableColumn = [
          {"name": "Parent Group", "hidden": false, "is_custom_data": false},
          {"name": "P-cMRC", "hidden": false, "is_custom_data": false},
          {
              "name": "MISO ID",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Purchasing)",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Manufacturer)",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Number",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Product",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service Category",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Account ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Currency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Quantity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Billing Frequency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Non-Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "cMRC",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location A",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location B",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Start Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "End of Term",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Current Term",
              "hidden": true,
              "is_custom_data": false
          }, {
            "name": "Next Event Value",
            "hidden": false,
            "is_custom_data": false
          }, {
            "name": "Next Event",
            "hidden": false,
            "is_custom_data": false
          }, {
            "name": "Event Date",
            "hidden": false,
            "is_custom_data": false
          }, {
              "name": "Days to Event",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Number of Auto Renew",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Data Integrity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Status",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "M3 Owner",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Owner",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Subscriber",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Division",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Team",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Group",
              "hidden": true,
              "is_custom_data": false
          }]
      break;
    case 'upcoming_event':
      tableColumn = [
          {"name": "Parent Group", "hidden": false, "is_custom_data": false},
          {"name": "P-cMRC", "hidden": false, "is_custom_data": false},
          {
              "name": "MISO ID",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Purchasing)",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Manufacturer)",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Number",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Product",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service Category",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Account ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Currency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Quantity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Billing Frequency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Non-Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "cMRC",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location A",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location B",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Start Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "End of Term",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Current Term",
              "hidden": true,
              "is_custom_data": false
          }, {
            "name": "Next Event Value",
            "hidden": false,
            "is_custom_data": false
          }, {
            "name": "Next Event",
            "hidden": false,
            "is_custom_data": false
          }, {
              "name": "Event Date",
              "hidden": true,
              "is_custom_data": false
          }, {
            "name": "Days to Event",
            "hidden": false,
            "is_custom_data": false
          }, {
              "name": "Number of Auto Renew",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Data Integrity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Status",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "M3 Owner",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Owner",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Subscriber",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Division",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Team",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Group",
              "hidden": true,
              "is_custom_data": false
          }]
      break;
    case 'untracked_spending':
      tableColumn = [
          {"name": "Parent Group", "hidden": false, "is_custom_data": false},
          {"name": "P-cMRC", "hidden": false, "is_custom_data": false},
          {
              "name": "MISO ID",
              "hidden": false,
              "is_custom_data": false
          }, {
            "name": "Data Integrity",
            "hidden": false,
            "is_custom_data": false
          }, {
            "name": "Current Term",
            "hidden": false,
            "is_custom_data": false
          }, {
              "name": "Vendor (Purchasing)",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Vendor (Manufacturer)",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Order Number",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Product",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service Category",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Service ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Account ID",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Currency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Quantity",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Billing Frequency",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Unit Non-Recurring Cost",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "cMRC",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location A",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Location B",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Start Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "End of Term",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Event Date",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Days to Event",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Next Event",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Next Event Value",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Number of Auto Renew",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Status",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "M3 Owner",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Owner",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Subscriber",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Division",
              "hidden": false,
              "is_custom_data": false
          }, {
              "name": "Team",
              "hidden": true,
              "is_custom_data": false
          }, {
              "name": "Service Group",
              "hidden": true,
              "is_custom_data": false
          }]
      break;
    default:
      break;
  }
  return tableColumn;
}

export {
  getTableView
}