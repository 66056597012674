import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as ParentServicesReducer from '../state/reducers';
import * as ParentServiceActions from '../state/actions';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {Observable} from 'rxjs';
import {MiParentService} from '../../../../../shared/models/MiParentService';
import {NOTIFICATION_SEVERITY} from '../../../../../shared/utils/notificationSeverity';
import {MiService} from '../../../../../shared/models/MiService';
import {ParentServiceTileMode} from '../components/parent-service-tile/parent-service-tile.component';
import * as NoteListActions from '../../../../../shared/state/note-list/actions';
import {MisoCollection} from 'src/shared/models/MisoCollection.enum';

@Component({
  selector: 'mi-parent-service-details',
  templateUrl: './parent-service-details.component.html',
  styleUrls: ['./parent-service-details.component.scss']
})

export class ParentServiceDetailsComponent extends AbstractPageComponent implements OnInit {

  @ViewChild('serviceGroupMrcTmpl', {static: true}) serviceGroupMrcTmpl: TemplateRef<any>;

  readonly ARCHIVED: string = NOTIFICATION_SEVERITY.ARCHIVED;
  readonly MODE: string = ParentServiceTileMode.NORMAL;

  error: string = '';
  pageLoaded$: Observable<boolean>;
  parentService: MiParentService;
  serviceList: MiService[] = [];

  filterFunction = function(service){return true};

  constructor(
    private store: Store<any>
  ) {
    super();
  }

  ngOnInit() {

    this.pageLoaded$ = this.store.select(ParentServicesReducer.ParentServicePageLoaded);
    this.store
      .select(ParentServicesReducer.getParentServiceData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.parentService) {
            this.parentService = data.parentService;
            this.store.dispatch(
              new NoteListActions.LoadNotesAction({
                document_id: this.parentService._id,
                document_collection: MisoCollection.PARENT_SERVICES
              })
            );
            this.serviceList = this.parentService.services.sort((a, b) => {
              return a.display_id > b.display_id ? 1 : -1;
            });
          }
        }
      );
  }

  toggleFilterZeroCheck(checked) :void {
    if(checked) {
      this.filterFunction = ((service) => service.mrc);
    } else {
      this.filterFunction = ((service) => {return true});
    }
  }

  updateStore(updatedParent: MiParentService): void {
    this.store.dispatch(new ParentServiceActions.ParentServiceLoadSuccess({
      parentService: updatedParent
    }));
  }

}
