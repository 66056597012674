

<div class="mi-grid-aside-basic">
  <div class="col-all">
    <mi-advanced-search-time-range></mi-advanced-search-time-range>

    <mi-advanced-search-header
      [showTotalMrc]="false"
      searchType="SERVICE"
      saveSearch="true">
    </mi-advanced-search-header>

    <ng-container *ngIf="pageLoaded$ | async; else loading">
      <mi-applied-search-filters></mi-applied-search-filters>
      <mi-advanced-search-service-results-table></mi-advanced-search-service-results-table>
      <mi-advanced-search-load-more></mi-advanced-search-load-more>
    </ng-container>

    <ng-template #loading>
      <mi-loading-animation></mi-loading-animation>
    </ng-template>

  </div>
</div>


<div style="display: none;">
  <mi-applied-search-filters></mi-applied-search-filters>
</div>