import {Component, Input, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Params} from '@angular/router';
import {NO_DATA} from '../../../shared/ng-models/SectionDataTable.model';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../shared/state/advanced-search/reducers';
import {ADVANCED_SEARCH_TYPES} from '../../../shared/state/advanced-search/reducers';
import * as ASActions from '../../../shared/state/advanced-search/actions';
import {combineLatest, Observable} from "rxjs";
import {TenantVendors} from '../../../libs/api/tenant/tenantVendors';
import {TenantProductsService} from '../../../libs/api/tenant/tenantProducts';
import {TenantMiServiceGroupService} from '../../../libs/api/tenant/tenantMiServiceGroup.service';
import {AddressV2Service} from '../../../libs/api2/address-v2/address-v2.service';
import { TenantDocumentManagementService } from '../../../libs/api/tenant/tenantDocumentManagement.service';
import { ServiceTermService } from 'src/libs/api2/service-term/service-term.service';
import { ContactV2Service } from 'src/libs/api2/contact-v2/contact-v2.service';
import { TenantAccountIdService } from 'src/libs/api2/account-id-v2/account-id-v2.service';

@Component({
  selector: 'mi-detail-search',
  templateUrl: 'detail-search.component.html'
})

export class DetailSearchComponent extends AbstractPageComponent implements OnInit {

  @Input() searchType: string;
  @Input() pageType: string = ADVANCED_SEARCH_TYPES.EVENT;
  @Input() externalQueryParams: any = {
    'time_range': 'ALL_UNLIMITED'
  };

  readonly NO_DATA: string = NO_DATA;

  display_id: string;
  error: string = '';
  searchLoaded$: Observable<boolean>;

  constructor(private activatedRoute: ActivatedRoute,
              private tenantAccountIdService: TenantAccountIdService,
              private tenantContactV2Service: ContactV2Service,
              private tenantProductService: TenantProductsService,
              private tenantVendorService: TenantVendors,
              private serviceTermService: ServiceTermService,
              private tenantAssociatedDocumentService: TenantDocumentManagementService,
              private tenantServiceGroupService: TenantMiServiceGroupService,
              private tenantAddressV2Service: AddressV2Service,
              private store: Store<ASReducer.State>) {
    super();
  }

  ngOnInit() {
    // Clear Search:
    this.store.dispatch(new ASActions.AsReset({searchType: this.pageType}));
    // listen for page loaded
    this.searchLoaded$ = this.store.select(ASReducer.ASSearchLoaded);
    this.activatedRoute
      .parent
      .params
      .switchMap((queryParams: Params) => {
        this.display_id = queryParams.display_id;    
        // get the address data
        return combineLatest(
          this.getObservableByType(this.searchType),
          this.activatedRoute.queryParams
        );
      })
      .catch(e => {
        return this.error = e;
      })
      .takeUntil(this.destroy$)
      .subscribe(([responseData, queryParams]: any[]) => {
        const params = this.updateAfterResponse(responseData, queryParams, this.externalQueryParams);
        this.store.dispatch(new ASActions.AsDeeplinkChanged(params));
      });

    const pageLockFilters = ['bu_access_lock'];
    if (this.pageType === ADVANCED_SEARCH_TYPES.EVENT) {
      pageLockFilters.push('time_range');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_VENDORS) {
      pageLockFilters.push('vendor_name');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_SERVICE_GROUP) {
      pageLockFilters.push('service_group_id');
      pageLockFilters.push('parents_and_services');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_LOCATIONS) {
      pageLockFilters.push('location_id');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_EQUIPMENT) {
      pageLockFilters.push('equipments');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_SERVICETERM) {
      pageLockFilters.push('service_term');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_ACCOUNT_IDS) {
      pageLockFilters.push('account_id');
    }

    if (this.searchType === SEARCH_TYPES.SEARCH_TYPE_EMPLOYEE) {
      pageLockFilters.push('employee');
      pageLockFilters.push('end_user_email');
    }

    this.store.dispatch(new ASActions.AsReset(
      {
        searchType: this.pageType,
        pageLockFilters: pageLockFilters
      }));

  }

  updateAfterResponse(responseData, queryParams, externalParams): any {

    Object.keys(externalParams).forEach((key) => {
      if (externalParams[key] == ID_KEY) {
        externalParams[key] = responseData._id||responseData.id;
      } else if (externalParams[key] == VENDOR_NAME_KEY) {
        externalParams[key] = responseData.vendor_name;
      } else if (externalParams[key] == EMAIL_KEY) {
        externalParams[key] = responseData.email.toLowerCase();
      }
    });

    return {
      ...queryParams,
      ...externalParams
    };
  }

  getObservableByType(searchType): any {
    switch (searchType) {
      case SEARCH_TYPES.SEARCH_TYPE_ACCOUNT_IDS:
        return this.tenantAccountIdService.getByDisplayId(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_ADDRESSES:
        return this.tenantAddressV2Service.getAddressById(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_EMPLOYEE:
        return this.tenantContactV2Service.getTenantUser(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_CONTACTS:
        return this.tenantContactV2Service.getContactById(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_LOCATIONS:
        return this.tenantAddressV2Service.getLocationById(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_PRODUCTS:
        return this.tenantProductService.getProductByDisplayId(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_VENDORS:
        return this.tenantVendorService.getVendorByDisplayId(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_SERVICETERM:
        return this.serviceTermService.getServiceTermById(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_ASSOCIATEDDOCUMENTS:
        return this.tenantAssociatedDocumentService.getDocumentById(this.display_id);
      case SEARCH_TYPES.SEARCH_TYPE_SERVICE_GROUP:
        return this.tenantServiceGroupService.getByDisplayId(this.display_id);
    }
  }
}

export enum SEARCH_TYPES {
  SEARCH_TYPE_ACCOUNT_IDS = 'Search Type::Account ID',
  SEARCH_TYPE_ADDRESSES = 'Search Type::Addresses',
  SEARCH_TYPE_EMPLOYEE = 'Search Type::Employee',
  SEARCH_TYPE_CONTACTS = 'Search Type::Contacts',
  SEARCH_TYPE_LOCATIONS = 'Search Type::Locations',
  SEARCH_TYPE_VENDORS = 'Search Type::Vendors',
  SEARCH_TYPE_PRODUCTS = 'Search Type::Product ID',
  SEARCH_TYPE_SERVICETERM = 'Search Type::Service Term ID',
  SEARCH_TYPE_ASSOCIATEDDOCUMENTS = 'Search Type::Associated Documents ID',
  SEARCH_TYPE_EQUIPMENT = 'Search Type::Equipment ID',
  SEARCH_TYPE_SERVICE_GROUP = 'Search Type::Service Group ID',

}

export const SEARCH_TYPE_ACCOUNT_IDS: string = SEARCH_TYPES.SEARCH_TYPE_ACCOUNT_IDS;
export const SEARCH_TYPE_ADDRESSES: string = SEARCH_TYPES.SEARCH_TYPE_ADDRESSES;
export const SEARCH_TYPE_CONTACTS: string = SEARCH_TYPES.SEARCH_TYPE_CONTACTS;
export const SEARCH_TYPE_EMPLOYEE: string = SEARCH_TYPES.SEARCH_TYPE_EMPLOYEE;
export const SEARCH_TYPE_LOCATIONS: string = SEARCH_TYPES.SEARCH_TYPE_LOCATIONS;
export const SEARCH_TYPE_PRODUCTS: string = SEARCH_TYPES.SEARCH_TYPE_PRODUCTS;
export const SEARCH_TYPE_VENDORS: string = SEARCH_TYPES.SEARCH_TYPE_VENDORS;
export const SEARCH_TYPE_SERVICETERM: string = SEARCH_TYPES.SEARCH_TYPE_SERVICETERM;
export const SEARCH_TYPE_ASSOCIATEDDOCUMENTS: string = SEARCH_TYPES.SEARCH_TYPE_ASSOCIATEDDOCUMENTS;
export const SEARCH_TYPE_EQUIPMENT: string = SEARCH_TYPES.SEARCH_TYPE_EQUIPMENT;
export const SEARCH_TYPE_SERVICE_GROUP: string = SEARCH_TYPES.SEARCH_TYPE_SERVICE_GROUP;

export const ID_KEY: string = '{id}';
export const EMAIL_KEY: string = '{email}';
export const VENDOR_NAME_KEY: string = '{vendor_name}';
