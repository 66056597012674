<div class="card-container">
  <!--
  <div class="custom-plan-header-container" *ngIf="accountPlan.isCustomPlan">
    <label>Curated for You</label>
  </div>
  -->
  <div class="custom-plan-header-container" *ngIf="accountPlan.allowTrial">
    <label *ngIf="viewMode == 'select'">Trial Available</label>
    <label *ngIf="viewMode == 'view'">Trial Included</label>
  </div>
  <div class="top">
    <div class="divider"></div>
    <div class="header-container">
      <label>{{accountPlan.planName}}</label>
    </div>
    <div class="divider"></div>
    <div class="description-container">
      <label>{{accountPlan.planDescription}}</label>
    </div>
  </div>
  <div class="bottom">
    <div class="list-container">
      <div class="list-dot"></div>
      <label class="list-item"><b>{{accountPlan.templatesIncluded | numberToText}}</b> Templates Included</label>
    </div>
    <div class="list-container">
      <div class="list-dot"></div>
      <label class="list-item"><b>{{accountPlan.servicesIncluded | numberToText}}</b> Services Included</label>
    </div>
    <div class="list-container">
      <div class="list-dot"></div>
      <label class="list-item"><b>{{(accountPlan.templateSetupFee? accountPlan.templateSetupFee : 0) | tenantcurrencyusd:'1.0'}}</b> Setup Fee / Template</label>
    </div>
    <div class="price-container">
      <!--<label>{{accountPlan.planBaseCost | tenantcurrency:'1.0'}} / <sub>{{billingDisplayText}}</sub></label>-->
      <label *ngIf="billingPlanModeSelection == 'month'">{{accountPlan.planBaseCostMonthly | tenantcurrencyusd:'1.0'}} / <sub>{{billingPlanModeSelection}}</sub></label>
      <label *ngIf="billingPlanModeSelection == 'year'">{{accountPlan.planBaseCostYearly | tenantcurrencyusd:'1.0'}} / <sub>{{billingPlanModeSelection}}</sub></label>
    </div>
    <div class="card-button-container" *ngIf="viewMode == 'select'">
      <button (click)="selectPlan()">Select</button>
    </div>
    <div class="card-button-container-light" *ngIf="viewMode == 'view'">
      <button>Active</button>
    </div>
  </div>
</div>