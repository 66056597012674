import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../../../../shared/pages/abstract-page/abstract-page.component';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationV2} from '../../../../../libs/api2/types/LocationV2.interface';

@Component({
  templateUrl: './locations-edit.component.html',
  styleUrls: ['./locations-edit.component.scss']
})

export class LocationsEditComponent extends AbstractPageComponent implements OnInit {

  locationId: string;
  error: string;
  exitRoute: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.locationId = this.activatedRoute.parent.snapshot.paramMap.get('display_id');
    if (!this.locationId) {
      this.error = 'No location ID.';
      return;
    }
    this.exitRoute = '../details';
  }

  onCanceled() {
    this.router.navigate([this.exitRoute], {relativeTo: this.activatedRoute});
  }

  onUpdateLocation(location: LocationV2): void {
    // The change has been added.
    this.router.navigate([this.exitRoute], {
      queryParams: {update: new Date().getTime()},
      relativeTo: this.activatedRoute
    });
  }

}
